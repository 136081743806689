export interface UserContext {
  userType?: UserType;
  privateLabelInformation?: PrivateLabelInformation;
  spid?: string;
  referrer?: string;
  initiatedBy?: string;
  retrieve?: boolean;
  retrievedBy?: UserType;
  producer?: Producer;
  gtm?: string;
  telephoneNumber?: string;
  monopauseAgentPhoneNo?: string;
  noplaystateAgentPhoneNo?: string;
  guid?: string;
  otp?: string;
  prequalifiedPartnerScoreId?: string;
  vendorId?: string;
  carrierId?: string;
  state?: string;
  lob?: string;
  privateLabelPreview?: boolean;
  redirectUrl?: string;
  thirdPartyId?: string;
  utm_medium?: string;
  partialQuoteId?: string;
  utm_source?: string;
  utm_content?: string;
}

export interface PrivateLabelInformation {
  type: PrivateLabelType;
  key: string;
}

export interface Producer {
  type: string;
  stateCode: string;
  agencyCode: string;
  producerId: string;
  producerCode: string;
}

export enum UserType {
  DIRECT = 'Direct',
  EA = 'EA',
  IA = 'IA',
  NSS = 'NSS',
  DIRECT_PRIVATELABEL = 'Direct Private Label',
  IA_PRIVATELABEL = 'IA Private Label',
}

export enum PrivateLabelType {
  DOMAIN = 'Domain',
  QUERYPARAMETER = 'QueryParameter',
  SPID = 'SPID',
}

export enum InitiatedByType {
  IOS_MOBILE = 'NWX_iOS_Mobile',
  ANDROID_MOBILE = 'NWX_Android_Mobile',
  COMPRATER = 'COMPRATER',
}
