import { AddressService } from './address.service';
import { AgencyService } from './agency.service';
import { AppConfigService } from './app-config.service';
import { AutoService } from './auto.service';
import { BillingService } from './billing.service';
import { CaptchaService } from './captcha.service';
import { HomeownersService } from './homeowners.service';
import { CondoService } from './condo.service';
import { LendersService } from './lenders.service';
import { LoadingService } from './loading.service';
import { LoggingService } from './logging.service';
import { MetadataService } from './metadata.service';
import { PersonsService } from './persons.service';
import { PolicyService } from './policy.service';
import { PowersportsService } from './powersports.service';
import { ProductsMetadataService } from './products-metadata.service';
import { ProductsService } from './products.service';
import { RentersService } from './renters.service';
import { SessionService } from './session.service';
import { PrivateLabelService } from './private-label.service';
import { ContentfulService } from './contentful.service';
import { SequencerService } from './sequencer.service';
import { EuaHelper } from './helpers/eua-helper.service';
import { UserContextService } from './user-context.service';
import { CmaService } from './cma.service';
import { PrivateLabelCoverageHelper } from './helpers/private-label-coverage.helper';
import { AutoFocusUtil } from './util/auto-focus.util';
import { PageAlertService } from './page-alert.service';
import { AlertService } from './alert.service';
import { GTMAnalyticsService } from './gtm-analytics.service';
import { FeatureService } from './feature.service';
import { QuoteLetterService } from './quote-letter.service';
import { DriverLicenseService } from './driver-license.service';
import { ConsentValuesService } from './consent-values.service';
import { FullStoryService } from './fullstory.service';
import { CompRaterNewsService } from './comp-rater-news.service';
import { DistributionPartnerManagementService } from './distribution-partner-management.service';
import { KafkaService } from './kafka.service';
import { PlaidService } from './plaid.service';
import { TermLifeService } from './termlife.service';

export const services: any[] = [
  AddressService,
  AgencyService,
  AppConfigService,
  AutoService,
  BillingService,
  CaptchaService,
  HomeownersService,
  CondoService,
  LendersService,
  LoadingService,
  LoggingService,
  MetadataService,
  PersonsService,
  PolicyService,
  PowersportsService,
  ProductsMetadataService,
  ProductsService,
  RentersService,
  SessionService,
  PrivateLabelService,
  ContentfulService,
  SequencerService,
  EuaHelper,
  UserContextService,
  CmaService,
  PrivateLabelCoverageHelper,
  AutoFocusUtil,
  PageAlertService,
  AlertService,
  GTMAnalyticsService,
  FeatureService,
  QuoteLetterService,
  DriverLicenseService,
  ConsentValuesService,
  FullStoryService,
  CompRaterNewsService,
  DistributionPartnerManagementService,
  KafkaService,
  PlaidService,
  TermLifeService,
];

export * from './address.service';
export * from './agency.service';
export * from './app-config.service';
export * from './auto.service';
export * from './billing.service';
export * from './captcha.service';
export * from './homeowners.service';
export * from './condo.service';
export * from './lenders.service';
export * from './loading.service';
export * from './logging.service';
export * from './metadata.service';
export * from './persons.service';
export * from './policy.service';
export * from './powersports.service';
export * from './products-metadata.service';
export * from './products.service';
export * from './plaid.service';
export * from './renters.service';
export * from './session.service';
export * from './helpers/eua-helper.service';
export * from './private-label.service';
export * from './contentful.service';
export * from './sequencer.service';
export * from './user-context.service';
export * from './cma.service';
export * from './helpers/private-label-coverage.helper';
export * from './util/auto-focus.util';
export * from './page-alert.service';
export * from './alert.service';
export * from './gtm-analytics.service';
export * from './feature.service';
export * from './quote-letter.service';
export * from './driver-license.service';
export * from './consent-values.service';
export * from './pre-bind-documents.service';
export * from './fullstory.service';
export * from './comp-rater-news.service';
export * from './distribution-partner-management.service';
export * from './kafka.service';
export * from './termlife.service';
