import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  UntypedFormArray,
} from '@angular/forms';
import { CustomValidators } from '@core/validators/custom-validators';
import { ActivatedRoute, Params } from '@angular/router';
import {
  LoggingService,
  CaptchaService,
  AppConfigService,
} from '@core/services';
import { delay, take } from 'rxjs/operators';
import {
  RetrieveModel,
  SubmissionDetailsModel,
} from '@core/models/retrieve/retrieve.model';
import { Animations } from '@shared/animations/animations';
import {
  ProductNames,
  ProductTypes,
  RetrieveProductTypes,
  RETRIEVE_FORM_TEXT_ENG,
} from '@shared/constants/app-constants';
import { Observable, Subject } from 'rxjs';
import { DateUtils } from '@shared/utils/date.utils';
import {
  BypassFields,
  BypassRetrieveResponse,
} from '@core/models/bypass-retrieve/bypass-retrieve.model';

@Component({
  selector: 'mq-retrieve-form',
  templateUrl: './retrieve-form.component.html',
  styleUrls: ['./retrieve-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.slideIn, Animations.create],
})
export class RetrieveFormComponent implements OnInit, OnDestroy {
  retrieveForm: UntypedFormGroup;
  @Input() isAppLoading = false;
  @Input() errorMessage = '';
  @Input() isAgencyExperience: boolean;
  @Input() bypassRetrieveResponse: Observable<BypassRetrieveResponse>;

  formSubmit = new EventEmitter<RetrieveModel>();
  @Output()
  formSubmitOutput = this.formSubmit.pipe(delay(1));

  @Output()
  clearErrors = new EventEmitter<RetrieveModel>();

  bypassRetrieveQuote = new EventEmitter<BypassFields>();
  @Output()
  bypassRetrieveQuoteOutput = this.bypassRetrieveQuote.pipe(delay(1));

  showRecaptcha = true;
  isRetrieveByQuoteId = false;
  private remoteIP: string;
  multipleSubmissionDetailsMatch = false;
  missingSubmissionId = false;
  retrieveFormText = RETRIEVE_FORM_TEXT_ENG;
  formWasSubmitted$ = new Subject<boolean>();
  submissionDetails: SubmissionDetailsModel[] = [];
  propertyProducts = ['Homeowners', 'Renters', 'Condo'];
  customMaskPatterns = {
    Z: { pattern: /[0-9*]/ },
    '0': { pattern: /\d/ },
  };
  productChecks: Array<any> = [
    { description: ProductNames['1'], value: '1' },
    { description: ProductNames['7'], value: '7' },
  ];
  constructor(
    private _fb: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _captchaService: CaptchaService,
    private _loggingService: LoggingService,
    private _appConfigService: AppConfigService
  ) {}

  ngOnInit(): void {
    this._captchaService
      .getIP()
      .pipe(take(1))
      .subscribe(resp => (this.remoteIP = resp.ip));

    this.buildForm();
    // ReCaptcha Enabled for Prod and Stage
    if (!this._appConfigService.config.captchaEnabled) {
      this.showRecaptcha = false;
    }

    this._route.queryParams.subscribe((params: Params) => {
      if (
        params.autoQuoteId ||
        params.powersportsQuoteId ||
        params.rentersQuoteId ||
        params.homeownersQuoteId ||
        params.condoQuoteId
      ) {
        this.isRetrieveByQuoteId = true;
      }
      if (params.bridgeId && params.otp) {
        this.triggerBypassRetrieveQuote(params.bridgeId, params.otp);
      }
      this.updateZipCodeValidatorToFiveDigits();
      this.populateFormAndSubmitIfComplete(params);
    });
    this.updateRequiredValidators();
  }

  ngOnDestroy(): void {
    this.formWasSubmitted$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bypassRetrieveResponse?.currentValue) {
      const retrieveParams = this.createParamsForRetrieve(
        changes.bypassRetrieveResponse.currentValue
      );
      this.populateFormAndSubmitIfComplete(retrieveParams);
    }
  }

  buildForm(): void {
    this.retrieveForm = this._fb.group({
      lastName: this._fb.control('', [
        Validators.required,
        CustomValidators.SpecialCharacterStringOnly,
      ]),
      postalCode: this._fb.control('', [
        Validators.required,
        CustomValidators.ValidateZipCode,
      ]),
      emailId: this._fb.control('', [
        Validators.required,
        CustomValidators.email,
      ]),
      dateOfBirth: this._fb.control('', [
        Validators.required,
        CustomValidators.DateOfBirthValid,
      ]),
      allChoices: this._fb.group({
        preventMultipleProperty: this._fb.control('', []),
        productChoices: new UntypedFormArray([], []),
      }),
    });
  }

  onSubmit(form) {
    this.showRecaptcha = false;
    this.formWasSubmitted$.next(true);
    this.onCheckFinal(this.allChoices.get('preventMultipleProperty').value);
    this.updateRequiredValidators();
    this.validateSubmissionDetails();
    if (form.valid) {
      for (const model of this.generateRetrieveModelsFromForm(form.value)) {
        this.formSubmit.emit(model);
      }
    } else {
      CustomValidators.MarkFormGroupTouched(this.retrieveForm);
    }
  }

  getSubHeading(item: SubmissionDetailsModel): string {
    return 'ProductType: ' + ProductNames[item.productType];
  }

  onsubmissionDetailsSubmit(event: SubmissionDetailsModel): void {
    if (event.eventType == 'create') {
      this.submissionDetails.push(event);
    } else {
      const index = this.submissionDetails.findIndex(x => x.id === event.id);
      this.submissionDetails[index] = event;
    }
    this.validateSubmissionDetails();
  }

  onSubmissionDetailsDeleted(event: SubmissionDetailsModel): void {
    const index = this.submissionDetails.findIndex(
      x => x.productType === event.productType
    );
    this.submissionDetails.splice(index, 1);
    this.validateSubmissionDetails();
  }
  validateSubmissionId(event: boolean): void {
    this.retrieveForm.setErrors(null);
    this.missingSubmissionId = event;
    if (event) {
      this.retrieveForm.setErrors({ missingSubmissionId: true });
    }
  }
  onMultipleSubmissionDetailsMatch(event: boolean): void {
    this.retrieveForm.setErrors(null);
    this.multipleSubmissionDetailsMatch = event;
    if (event) {
      this.retrieveForm.setErrors({ multipleSubmissionDetailsMatch: true });
    }
  }

  hasValidSubmissionDetailscount(): boolean {
    if (this.submissionDetails.length < 4) return true;
    return false;
  }

  updateZipCodeValidatorToFiveDigits() {
    this.retrieveForm.controls['postalCode'].setValidators(null);
    if (!this.isAgencyExperience) {
      this.retrieveForm.controls['postalCode'].setValidators([
        Validators.required,
        CustomValidators.ValidateZipCode,
      ]);
    }
  }

  changeIsRetrieveByQuoteIdFlag() {
    this.clearErrors.emit();
    if (this.isRetrieveByQuoteId === true) {
      this.isRetrieveByQuoteId = false;
    } else {
      this.isRetrieveByQuoteId = true;
    }
    this.updateRequiredValidators();
  }

  updateRequiredValidators() {
    this.retrieveForm.clearValidators();
    this.retrieveForm.markAsUntouched();
    if (this.isRetrieveByQuoteId) {
      this.retrieveForm.controls['dateOfBirth'].setErrors(null);
      this.retrieveForm.controls['emailId'].setErrors(null);
      if (this.isAgencyExperience) {
        this.retrieveForm.get('lastName').setValidators(null);
        this.retrieveForm.get('lastName').setErrors(null);
        this.retrieveForm.get('postalCode').setValidators(null);
        this.retrieveForm.get('postalCode').setErrors(null);
      }
    } else {
      this.retrieveForm.controls['emailId'].setValidators([
        Validators.required,
        CustomValidators.email,
      ]);
      this.retrieveForm.controls['dateOfBirth'].setValidators([
        Validators.required,
        CustomValidators.DateOfBirthValid,
      ]);
    }
    this.retrieveForm.updateValueAndValidity();
  }

  getProductType(form: any) {
    if (form.propertyProductName === 'Homeowners') {
      return ProductTypes.HOMEOWNERS;
    } else if (form.propertyProductName === 'Renters') {
      return ProductTypes.RENTERS;
    } else if (form.propertyProductName === 'Condo') {
      return ProductTypes.CONDO;
    }
  }

  generateRetrieveModelsFromForm(form: any): RetrieveModel[] {
    const models = [];
    if (this.submissionDetails.length > 0 && this.isRetrieveByQuoteId) {
      this.submissionDetails.forEach(value => {
        models.push({
          productType: value.productType,
          quoteId: value.submissionId,
          lastName: form.lastName,
          postalCode: form.postalCode,
        });
      });
      return models;
    } else {
      models.push({
        productType: ProductTypes.AUTO,
        lastName: form.lastName,
        postalCode: form.postalCode,
        emailId: form.emailId,
        dateOfBirth: DateUtils.formatPlainDateToDSM(form.dateOfBirth),
        productCode: RetrieveProductTypes.PERSONAL_AUTO,
      });
      models.push({
        productType: ProductTypes.POWERSPORTS,
        lastName: form.lastName,
        postalCode: form.postalCode,
        emailId: form.emailId,
        dateOfBirth: DateUtils.formatPlainDateToDSM(form.dateOfBirth),
        productCode: RetrieveProductTypes.POWERSPORTS,
      });
      models.push({
        productType: ProductTypes.HOMEOWNERS,
        lastName: form.lastName,
        postalCode: form.postalCode,
        emailId: form.emailId,
        dateOfBirth: DateUtils.formatPlainDateToDSM(form.dateOfBirth),
        productCode: RetrieveProductTypes.HOMEOWNER,
      });
      models.push({
        productType: ProductTypes.CONDO,
        lastName: form.lastName,
        postalCode: form.postalCode,
        emailId: form.emailId,
        dateOfBirth: DateUtils.formatPlainDateToDSM(form.dateOfBirth),
        productCode: RetrieveProductTypes.CONDO,
      });
      models.push({
        productType: ProductTypes.RENTERS,
        lastName: form.lastName,
        postalCode: form.postalCode,
        emailId: form.emailId,
        dateOfBirth: DateUtils.formatPlainDateToDSM(form.dateOfBirth),
        productCode: RetrieveProductTypes.TENANT,
      });
      return models.filter(m =>
        this.allChoices.get('productChoices')?.value.includes(m.productType)
      );
    }
  }

  onForward(value: any) {
    this._loggingService.logToSplunk({
      event: 'CAPTCHA_RESPONSE',
      message: {
        recaptcha: value ? value.captchaResponse : null,
        ip: this.remoteIP,
      },
    });
    this._captchaService
      .post(value.captchaResponse, this.remoteIP)
      .pipe(take(1))
      .subscribe(resp => {
        if (resp.tokenProperties.valid) {
          this.onSubmit(this.retrieveForm);
        }
      });
  }

  get lastName() {
    return this.retrieveForm.get('lastName');
  }

  get postalCode() {
    return this.retrieveForm.get('postalCode');
  }

  get emailId() {
    return this.retrieveForm.get('emailId');
  }

  get dateOfBirth() {
    return this.retrieveForm.get('dateOfBirth');
  }

  get allChoices() {
    return this.retrieveForm.get('allChoices');
  }

  get productChoices(): UntypedFormArray {
    return this.allChoices.get('productChoices') as UntypedFormArray;
  }

  get preventMultipleProperty() {
    return this.allChoices.get('preventMultipleProperty');
  }

  populateFormAndSubmitIfComplete(params: Params) {
    let complete = true;
    // Last name and postal code are required and have no default.
    for (const key of ['lastName', 'postalCode']) {
      if (params[key]) {
        this.retrieveForm.patchValue({ [key]: params[key] });
      } else {
        complete = false;
      }
    }
    // At least one of the quote IDs must be provided.
    let quoteIdCount = 0;
    const p = {
      autoQuoteId: ProductTypes.AUTO,
      powersportsQuoteId: ProductTypes.POWERSPORTS,
      homeownersQuoteId: ProductTypes.HOMEOWNERS,
      condoQuoteId: ProductTypes.CONDO,
      rentersQuoteId: ProductTypes.RENTERS,
    };
    for (const key in p) {
      if (params[key]) {
        const entity = {
          submissionId: params[key],
          productType: p[key],
        };
        if (
          !this.submissionDetails.find(
            value => value.productType === entity.productType
          )
        ) {
          this.submissionDetails.push(entity);
        }

        quoteIdCount++;
      }
    }

    if (!quoteIdCount) {
      complete = false;
    }
    if (complete || (this.isAgencyExperience && quoteIdCount)) {
      this.onSubmit(this.retrieveForm);
    }
  }

  triggerBypassRetrieveQuote(bridgeId: string, otp: string): void {
    this.isRetrieveByQuoteId = true;
    this.bypassRetrieveQuote.emit({
      bridgeId: bridgeId,
      otp: otp,
    });
  }

  createParamsForRetrieve(
    bypassRetrieveResponse: BypassRetrieveResponse
  ): Params {
    const params: Params = {
      postalCode: bypassRetrieveResponse.postalCode,
      lastName: bypassRetrieveResponse.lastName,
    };
    const quotesBlock = bypassRetrieveResponse.quotes;
    if (quotesBlock.autoQuoteId) {
      params['autoQuoteId'] = quotesBlock.autoQuoteId;
    }
    if (quotesBlock.homeownersQuoteId) {
      params['homeownersQuoteId'] = quotesBlock.homeownersQuoteId;
    }
    if (quotesBlock.condoQuoteId) {
      params['condoQuoteId'] = quotesBlock.condoQuoteId;
    }
    if (quotesBlock.rentersQuoteId) {
      params['rentersQuoteId'] = quotesBlock.rentersQuoteId;
    }
    if (quotesBlock.powersportsQuoteId) {
      params['powersportsQuoteId'] = quotesBlock.powersportsQuoteId;
    }

    return params;
  }

  validateSubmissionDetails(): void {
    if (this.isRetrieveByQuoteId) {
      if (this.submissionDetails.length == 0) {
        this.retrieveForm.setErrors({ submissionDetailsRequired: true });
      } else {
        if (this.multipleSubmissionDetailsMatch) {
          this.retrieveForm.setErrors({
            multipleSubmissionDetailsMatch: true,
          });
        }
        if (this.missingSubmissionId) {
          this.retrieveForm.setErrors({
            missingSubmissionId: true,
          });
        }
      }
    }
  }

  onCheckChange(event) {
    const formArray: UntypedFormArray = this.allChoices.get(
      'productChoices'
    ) as UntypedFormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray?.push(new UntypedFormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i = 0;

      formArray?.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray?.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  togglePreventMultipleProperty(event): void {
    this.allChoices.patchValue({
      preventMultipleProperty:
        this.allChoices.get('preventMultipleProperty').value ===
        event.target.value
          ? ''
          : event.target.value,
    });
  }

  onCheckFinal(value) {
    const formArray: UntypedFormArray = this.allChoices.get(
      'productChoices'
    ) as UntypedFormArray;
    formArray?.push(new UntypedFormControl(value));
  }
}
