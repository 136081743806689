import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  PreBindDocumentEntity,
  PreBindDocumentRequest,
  PreBindDocumentUploadRequest,
  PreBindDocumentUploadResponse,
  PreBindDocumentEmailRequest,
  PreBindDocumentEmailResponse,
} from '@core/models/entities/pre-bind-document.entity';
import { ProductTypes } from '@shared/constants/app-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '@core/services/app-config.service';
import { HeadersUtils } from '@shared/utils/headers.utils';
import { HttpParamsUtil } from '@shared/utils/http-params.util';
import { RetryOn503Operator } from '@shared/operators/retryOn-503.operator';

@Injectable({
  providedIn: 'root',
})
export class PreBindDocumentsAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfigService: AppConfigService,
    private _retry: RetryOn503Operator
  ) {}

  getPreBindDocuments(
    request: PreBindDocumentRequest
  ): Observable<PreBindDocumentEntity[]> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/documents`;

    const headers = HeadersUtils.buildHeaderForDSMCalls(
      this._appConfigService.config.apiKey,
      request.accessToken,
      request.sessionId,
      this._appConfigService.isTest()
        ? this._appConfigService.config.targetEnv
        : '',
      request.userContext
    );
    const params = HttpParamsUtil.buildHttpParamsWithPartnerName(
      request.userContext
    );
    const options = { headers, params };
    return this._http.get<PreBindDocumentEntity[]>(url, options).pipe(
      map(response =>
        response.map(res => {
          res.productId = request.productId;
          return res;
        })
      )
    );
  }

  getPreBindDocument(
    request: PreBindDocumentRequest
  ): Observable<PreBindDocumentEntity> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/documents/${request.documentId}`;
    const RETRY_MS = 500;
    const headers = HeadersUtils.buildHeaderForDSMCalls(
      this._appConfigService.config.apiKey,
      request.accessToken,
      request.sessionId,
      this._appConfigService.isTest()
        ? this._appConfigService.config.targetEnv
        : '',
      request.userContext
    );
    const params = HttpParamsUtil.buildHttpParamsWithPartnerName(
      request.userContext
    );
    const options = { headers, params };
    return this._http.get<PreBindDocumentEntity>(url, options).pipe(
      this._retry.retryOn503(RETRY_MS),
      map(response => {
        return { ...response, productId: request.productId };
      })
    );
  }

  uploadDocuments(
    request: PreBindDocumentUploadRequest,
    accessToken: string,
    sessionId: string
  ): Observable<PreBindDocumentUploadResponse> {
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfigService.config.apiKey)
      .set('Authorization', 'Bearer ' + accessToken)
      .set('X-NW-Message-ID', sessionId);

    const url = `${this._appConfigService.config.multiproductApi}/pre-bind-documents`;

    return this._http.post<PreBindDocumentUploadResponse>(url, request, {
      headers,
    });
  }

  email(
    request: PreBindDocumentEmailRequest,
    accessToken: string,
    sessionId: string
  ): Observable<PreBindDocumentEmailResponse> {
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfigService.config.apiKey)
      .set('Authorization', 'Bearer ' + accessToken)
      .set('X-NW-Message-ID', sessionId);

    const url = `${this._appConfigService.config.multiproductApi}/pre-bind-documents-emails`;

    return this._http.post<PreBindDocumentEmailResponse>(url, request, {
      headers,
    });
  }

  private _getUrlPrefixForProduct(productId) {
    switch (productId) {
      case ProductTypes.AUTO: {
        return this._appConfigService.config.pcEdgeAutoUrl;
      }

      case ProductTypes.HOMEOWNERS: {
        return this._appConfigService.config.pcEdgeHomeownersUrl;
      }

      case ProductTypes.CONDO: {
        return this._appConfigService.config.pcEdgeCondoUrl;
      }

      case ProductTypes.RENTERS: {
        return this._appConfigService.config.pcEdgeRentersUrl;
      }

      case ProductTypes.POWERSPORTS: {
        return this._appConfigService.config.pcEdgePowersportsUrl;
      }
    }
  }
}
