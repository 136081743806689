import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromVehicleExposure from '@core/store/actions/vehicle-exposure.action';

import { VehicleExposureEntity } from '@core/models/entities/vehicle-exposure.entity';

export type VehicleExposureState = EntityState<VehicleExposureEntity>;

export const adapter: EntityAdapter<VehicleExposureEntity> =
  createEntityAdapter<VehicleExposureEntity>({
    selectId: vehicle => vehicle.vehicleExposureId,
  });

export function reducer(
  state = adapter.getInitialState(),
  action: fromVehicleExposure.VehicleExposureActions
): VehicleExposureState {
  switch (action.type) {
    case fromVehicleExposure.VehicleExposureActionTypes
      .ADD_VEHICLE_EXPOSURE_SUCCESS: {
      return adapter.addOne(action.payload, state);
    }
    case fromVehicleExposure.VehicleExposureActionTypes
      .ADD_ALL_VEHICLE_EXPOSURE_SUCCESS: {
      return adapter.setAll(action.payload, state);
    }

    case fromVehicleExposure.VehicleExposureActionTypes
      .DELETE_VEHICLE_EXPOSURE_SUCCESS: {
      return adapter.removeOne(action.payload, state);
    }

    default: {
      return state;
    }
  }
}
