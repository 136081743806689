import { Pipe, PipeTransform } from '@angular/core';
import { RetrieveProductTypes } from '@shared/constants/app-constants';

@Pipe({
  name: 'retrieveProductNames',
})
export class RetrieveProductNamesPipe implements PipeTransform {
  transform(value: string): string {
    let productName: string;
    switch (value) {
      case RetrieveProductTypes.PERSONAL_AUTO:
        productName = 'Auto';
        break;
      default:
        productName = value;
    }
    return productName;
  }
}
