<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Choose a program</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body vw-25">
  <h1 class="heading-h6">
    <strong
      >{{ vehicle?.year }} {{ vehicle?.make }} {{ vehicle?.model }}</strong
    >
  </h1>
  <form [formGroup]="form">
    <label class="col-12" *ngIf="shouldOfferSmartMiles()">
      <p>
        <input
          type="radio"
          value="SmartMiles"
          formControlName="selectedProgram"
        />
        Pay-per-Mile program, SmartMiles&reg;<br />
        (<em
          >For vehicles driven less than
          {{ typicalAnnualMileage | number }} miles a year</em
        >)
      </p>
    </label>
    <label class="col-12" *ngIf="shouldOfferSMconnectedCar()">
      <p>
        <input
          type="radio"
          value="SmartMilesConnectedCar"
          formControlName="selectedProgram"
        />
        SmartMiles&reg;with a connected car<br />
        <em>Based on the mileage collected by your car</em>
      </p>
    </label>
    <label class="col-12" *ngIf="shouldOfferPreQualifiedVehicle()">
      <p>
        <input
          type="radio"
          value="VerifiedScore"
          formControlName="selectedProgram"
        />
        Our pre-approved {{ discount }} safe-driving behavior discount<br />
        (<em>Based on connected car data</em>)
      </p>
    </label>
    <label class="col-12" *ngIf="shouldOfferSmartRide()">
      <p [ngSwitch]="isVehicleMiles">
        <input
          type="radio"
          value="SmartRide"
          formControlName="selectedProgram"
        />
        <span [style.color]="'black'" *ngSwitchCase="true">
          SmartRide&reg; low mileage program (plug-in device)<br
        /></span>
        <span [style.color]="'black'" *ngSwitchDefault>
          Safe Driving Behavior program, SmartRide&reg;<br
        /></span>
        (<em
          >For vehicles driven more than
          {{ typicalAnnualMileage | number }} miles a year</em
        >)
      </p>
    </label>
    <label class="col-12" *ngIf="!shouldOfferSmartRide() && !isDeviceOnlyState">
      <p [ngSwitch]="isVehicleMiles">
        <input
          type="radio"
          value="SmartRideMobile"
          formControlName="selectedProgram"
        />
        <span [style.color]="'black'" *ngSwitchCase="true">
          SmartRide&reg; low mileage program (plug-in device)<br
        /></span>
        <span [style.color]="'black'" *ngSwitchDefault>
          Safe Driving Behavior program, SmartRide&reg;<br
        /></span>
        (<em
          >For vehicles driven more than
          {{ typicalAnnualMileage | number }} miles a year</em
        >)
      </p>
    </label>
    <label class="col-12" *ngIf="shouldOfferConnectedCar()">
      <p>
        <input
          type="radio"
          value="ConnectedCar"
          formControlName="selectedProgram"
        />
        Connected car safe-driving behavior program, SmartRide&reg;<br />
        (<em>Based on connected car data</em>)
      </p>
    </label>
    <label class="col-12" *ngIf="shouldOfferSelfReported()">
      <input
        type="radio"
        value="SelfReported"
        formControlName="selectedProgram"
      />
      Self-reported low-mileage program<br />
      <ul>
        <li>
          Send us a photo or recent service receipt showing the current odometer
          reading, and provide updates before each policy renewal.
        </li>
        <li>
          Keep the discount for as long as the vehicles are insured with us, and
          proof is provided.
        </li>
      </ul>
    </label>
    <label class="col-12">
      <input
        type="radio"
        value="NotEnrolled"
        formControlName="selectedProgram"
      />
      No program
    </label>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="modal.dismiss()"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="modal.close(form.value)"
  >
    Save
  </button>
</div>
