import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsDirectOrNssAgentDirective } from './is-direct-or-nss-agent.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsDirectOrNssAgentDirective],
  exports: [IsDirectOrNssAgentDirective],
})
export class IsDirectOrNssAgentModule {}
