import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromHouseholdMember from '@core/store/actions/household-member.action';
import { HouseholdMemberEntity } from '@core/models/entities/household-member.entity';

export type HouseholdMemberState = EntityState<HouseholdMemberEntity>;

export const adapter: EntityAdapter<HouseholdMemberEntity> =
  createEntityAdapter<HouseholdMemberEntity>({
    selectId: member => member.householdMemberId,
  });

export function reducer(
  state = adapter.getInitialState(),
  action: fromHouseholdMember.HouseholdMemberActions
): HouseholdMemberState {
  switch (action.type) {
    case fromHouseholdMember.HouseholdMemberActionTypes
      .ADD_HOUSEHOLD_MEMBER_SUCCESS: {
      return adapter.addOne(action.payload, state);
    }

    case fromHouseholdMember.HouseholdMemberActionTypes
      .ADD_ALL_HOUSEHOLD_MEMBERS_SUCCESS: {
      return adapter.addMany(action.paylaod, state);
    }

    case fromHouseholdMember.HouseholdMemberActionTypes
      .UPDATE_HOUSEHOLD_MEMBER_SUCCESS: {
      return adapter.updateOne(action.payload, state);
    }

    case fromHouseholdMember.HouseholdMemberActionTypes
      .DELETE_HOUSEHOLD_MEMBER_SUCCESS: {
      return adapter.removeOne(action.payload, state);
    }

    default: {
      return state;
    }
  }
}
