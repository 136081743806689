import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PowersportsVehicleModelMakesResponse,
  VehicleModelMakesResponse,
} from '@core/models/auto/vehicle-make.model';
import { AutoYearsResponse } from '@core/models/auto/vehicle-years.model';
import {
  PowersportsVehicleModelsWithDescriptionResponse,
  VehicleModelsWithDescriptionResponse,
} from '@core/models/auto/vehicle-model.model';
import { AntiTheftDevices } from '@core/models/auto/vehicle-antiTheftDevice-states.model';
import { AutoAdapter } from '@core/adapters/auto.adapter';
import { LoggingService } from './logging.service';
import {
  GetVinDetailsRequest,
  PowersportsVehicleVinDetailsResponse,
  VehicleVinDetailsResponse,
} from '@core/models/auto/vehicle-vin.model';
import { VehicleSeriesRatesOdbiResponse } from '@core/models/auto/vehicle-seriesRates.model';
import { tap, map } from 'rxjs/operators';
import { VehicleTypesResponse } from '@core/models/auto/vehicle-types.model';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { VehicleModelSeries } from '@core/models/response/vehicle-series.model';
import { MetadataAdapter } from '@core/adapters/metadata.adapter';
import { VehicleService } from './vehicle.service';
import { VehiclePrimaryUseOptions } from '@core/models/auto/vehicle-primary-use.model';

@Injectable()
export class AutoService {
  constructor(
    private _autoAdapter: AutoAdapter,
    private _metadataAdapter: MetadataAdapter,
    private _loggingService: LoggingService,
    private _vehicleService: VehicleService
  ) {}

  getYears(): Observable<AutoYearsResponse> {
    return this._autoAdapter.getYears();
  }

  getVehicleTypes(): Observable<VehicleTypesResponse> {
    return this._autoAdapter.getVehicleTypes();
  }

  getPowersportsPrimaryUse(): Observable<VehiclePrimaryUseOptions> {
    return this._autoAdapter.getPowersportsPrimaryUse();
  }

  getMakesByYear(modelYear: string): Observable<VehicleModelMakesResponse> {
    return this._autoAdapter.getMakesByYear(modelYear);
  }

  getPowersportsMakesByYear(
    modelYear: string
  ): Observable<PowersportsVehicleModelMakesResponse> {
    return this._autoAdapter.getPowersportsMakesByYear(modelYear);
  }

  getModelsByYearAndMake(
    modelYear: string,
    make: string
  ): Observable<VehicleModelsWithDescriptionResponse> {
    return this._autoAdapter.getModelsByYearAndMake(modelYear, make).pipe(
      map(response => {
        // If there's only one, it's not wrapped in an array. Ouch. Repair that if so.
        if (response && response.retrieveVehicleModelsWithDescriptionResponse) {
          const outer = response.retrieveVehicleModelsWithDescriptionResponse;
          if (
            outer.modelWithDescription &&
            !(outer.modelWithDescription instanceof Array)
          ) {
            outer.modelWithDescription = [outer.modelWithDescription];
          }
        }
        return response;
      })
    );
  }

  getPowersportsModelsByYearAndMake(
    modelYear: string,
    make: string
  ): Observable<PowersportsVehicleModelsWithDescriptionResponse> {
    return this._autoAdapter
      .getPowersportsModelsByYearAndMake(modelYear, make)
      .pipe(
        map(response => {
          // If there's only one, it's not wrapped in an array. Ouch. Repair that if so.
          if (response && response.models) {
            const outer = response;

            if (outer.models && !(outer.models instanceof Array)) {
              outer.models = [outer.models];
            }
          }
          return response;
        })
      );
  }

  getAntiTheftDevices(): Observable<AntiTheftDevices> {
    return this._metadataAdapter.getAntiTheftDevices();
  }

  getVehicleEligibleDiscounts(): Observable<EligibleDiscountEntity[]> {
    return this._metadataAdapter.getVehicleEligibleDiscounts();
  }

  getVinDetails(
    getVinDetailsRequest: GetVinDetailsRequest
  ): Observable<VehicleVinDetailsResponse> {
    return this._autoAdapter.getVinDetails(getVinDetailsRequest);
  }

  isPowersportsVehicleType(vehicleType: string): boolean {
    return this._vehicleService.isPowersportsVehicleType(vehicleType);
  }

  getPowersportsVinDetails(
    vin: string
  ): Observable<PowersportsVehicleVinDetailsResponse> {
    return this._autoAdapter.getPowersportsVinDetails(vin);
  }

  retrieveVehicleSeriesRatesOdbi(
    year: string,
    make: string,
    model: string
  ): Observable<VehicleSeriesRatesOdbiResponse> {
    return this._autoAdapter
      .retrieveVehicleSeriesRatesOdbi(year, make, model)
      .pipe(
        tap(response =>
          this._loggingService.logToConsole(
            'vehicleModelService Response',
            response
          )
        )
      );
  }

  retrieveVehicleModelSeries(
    year: string,
    make: string,
    model: string
  ): Observable<VehicleModelSeries | VehicleModelSeries[]> {
    return this._autoAdapter
      .retrieveVehicleModelSeries(year, make, model)
      .pipe(
        tap(response =>
          this._loggingService.log('vehicleModelService Response', response)
        )
      );
  }
}
