import { Injectable } from '@angular/core';
import { LoggingService, SessionService } from '@core/services';
import * as fromSelectors from '@core/store/selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SendSessionLogEntry } from '@core/store/actions';
import { of } from 'rxjs';

@Injectable()
export class SessionEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _sessionService: SessionService
  ) {}
  @Effect()
  sendSessionLogEntry$ = this._actions$.pipe(
    ofType<SendSessionLogEntry>(
      fromActions.SessionActionTypes.SEND_SESSION_LOG_ENTRY
    ),
    map((action: fromActions.SendSessionLogEntry) => action.payload),
    withLatestFrom(this._store.select(fromSelectors.getSessionLogEntries)),
    switchMap(([sessionEventName, sessionEntries]) => {
      const nextActions: Action[] = [];
      if (sessionEventName && sessionEntries?.length) {
        this._loggingService.log(sessionEventName, sessionEntries);
        nextActions.push(new fromActions.ClearSessionLogEntry());
      }
      return nextActions;
    })
  );
}
