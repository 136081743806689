import { Injectable } from '@angular/core';
import { HouseholdMemberEntity } from '@core/models/entities/household-member.entity';
import { CRUDService } from './crud.service';
import { Observable } from 'rxjs';
import { HouseholdMemberAdapter } from '@core/adapters/household-member.adapter';
import { HouseholdMemberDao } from '@core/dao/household-member.dao';
import { HouseholdMemberRequest } from '@core/models/umbrella/household-member-request';

@Injectable({
  providedIn: 'root',
})
export class HouseholdMemberService
  implements CRUDService<HouseholdMemberEntity>
{
  constructor(
    private _householdMemberAdapter: HouseholdMemberAdapter,
    private _householdMemberDao: HouseholdMemberDao
  ) {}

  add(entity: HouseholdMemberEntity): void {
    this._householdMemberDao.add(entity);
  }

  addAll?(entity: HouseholdMemberEntity[]): void {
    throw new Error('Method not implemented.');
  }

  delete(entityId: string): void {
    this._householdMemberDao.delete(entityId);
  }

  update(entity: HouseholdMemberEntity): void {
    this._householdMemberDao.update(entity);
  }

  get?(entityId: string): Observable<HouseholdMemberEntity> {
    throw new Error('Method not implemented.');
  }

  getAll?(): Observable<HouseholdMemberEntity[]> {
    throw new Error('Method not implemented.');
  }

  addHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<HouseholdMemberEntity> {
    return this._householdMemberAdapter.addHouseholdMember(request);
  }

  updateHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<HouseholdMemberEntity> {
    return this._householdMemberAdapter.updateHouseholdMember(request);
  }

  deleteHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<HouseholdMemberEntity> {
    return this._householdMemberAdapter.deleteHouseholdMember(request);
  }
}
