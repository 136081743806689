import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaptchaButtonComponent } from './captcha-button.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxCaptchaModule],
  declarations: [CaptchaButtonComponent],
  exports: [CaptchaButtonComponent],
})
export class CaptchaButtonModule {}
