import { Injectable } from '@angular/core';
import { QuoteAdapter } from '@core/adapters/quote.adapter';
import { TermLifeDao } from '@core/dao/termlife.dao';
import {
  TermLifeEmailRequest,
  TermLifeEmailResponse,
  TermLifeEntity,
} from '@core/models/termlife/termlife.entity';
import {
  ClickToChatParameters,
  DriverRelationToPNI,
  PolicyholderTypes,
  ProductTypes,
} from '@shared/constants/app-constants';
import {
  EMAIL_QUOTE_ESTIMATE_CLICK,
  PEOPLE_PAGE_TERMLIFE_SUBMIT,
  QUOTE_COVERAGES_TERMLIFE_SUBMIT,
  SCHEDULE_CALL_BUTTON_CLICK,
} from '@shared/constants/term-life-constants';
import { combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { CoverageHelper } from './helpers/coverage.helper';
import { LoggingService } from './logging.service';
import { PeopleService } from './people.service';
import { ProductsService } from './products.service';

@Injectable({
  providedIn: 'root',
})
export class TermLifeService {
  constructor(
    private _termLifeDao: TermLifeDao,
    private _quoteAdapter: QuoteAdapter,
    private _productService: ProductsService,
    private _peopleService: PeopleService,
    private _loggingService: LoggingService,
    private _productsService: ProductsService,
    private _coverageHelper: CoverageHelper
  ) {}

  add(entity: TermLifeEntity): void {
    this._termLifeDao.add(entity);
  }

  delete(entityId: string): void {
    this._termLifeDao.delete(entityId);
  }

  remove(entity: TermLifeEntity): void {
    this._termLifeDao.remove(entity);
  }

  update(entity: TermLifeEntity): void {
    this._termLifeDao.update(entity);
  }

  getAll(): Observable<TermLifeEntity[]> {
    return this._termLifeDao.getAll();
  }

  getTermLifeEligiblePeople(): Observable<TermLifeEntity[]> {
    return this._termLifeDao.getTermLifeEligiblePeople();
  }

  dispatchEmailQuoteEstimate(event: any): void {
    return this._termLifeDao.emailQuoteEstimate(event);
  }

  emailQuoteEstimate(
    request: TermLifeEmailRequest
  ): Observable<TermLifeEmailResponse> {
    return this._quoteAdapter.emailQuoteEstimate(request);
  }

  getCostEstimate(
    coverageTerm: string,
    coverageAmount: string,
    termLife: TermLifeEntity
  ): TermLifeEntity {
    return this._coverageHelper.getCostEstimate(
      coverageTerm,
      coverageAmount,
      termLife
    );
  }

  logTermLife(event: any, object?: any): void {
    combineLatest([
      this._productService.getLifeQuoteId(),
      this._peopleService.getAll(),
      this.getAll(),
      this._productsService.isProductSelectedWithoutError(ProductTypes.AUTO),
    ])
      .pipe(take(1))
      .subscribe(([lifeQuoteId, people, termLifePeople, isAutoSelected]) => {
        let message = [];
        switch (event) {
          case PEOPLE_PAGE_TERMLIFE_SUBMIT: {
            if (people.length > 0) {
              people.forEach(lifePerson => {
                const logMessage = {
                  quoteId: lifeQuoteId,
                  firstName: lifePerson.person.firstName,
                  lastName: lifePerson.person.lastName,
                  gender: lifePerson.person.gender,
                  lifeQuoteQuestion: lifePerson.person.termLife,
                  height: lifePerson.person.termLifeHeightFeet,
                  weight: lifePerson.person.termLifeWeight,
                  health: lifePerson.person.termLifeHealth,
                  tobacco_use: lifePerson.person.termLifeNico,
                  email_address:
                    lifePerson.relationToPrimaryNamedInsured ===
                      DriverRelationToPNI.PNI ||
                    lifePerson.policyHolderType ===
                      PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
                      ? lifePerson.emailAddress
                      : null,
                  termLifeEligible: lifePerson.person.termLifeEligible,
                };
                message = [...message, logMessage];
              });
              this._loggingService.log(event, message);
            }
            break;
          }
          case QUOTE_COVERAGES_TERMLIFE_SUBMIT: {
            if (termLifePeople.length > 0) {
              termLifePeople.forEach(termLifePerson => {
                const logMessage = {
                  quoteId: lifeQuoteId,
                  coverage_amount: termLifePerson.coverageAmount,
                  coverage_term: termLifePerson.coverageTerm,
                  coverage_cost: termLifePerson.costEstimate,
                };
                message = [...message, logMessage];
              });

              this._loggingService.log(event, message);
            }

            break;
          }
          case SCHEDULE_CALL_BUTTON_CLICK: {
            if (termLifePeople.length > 0) {
              termLifePeople.forEach(termLifePerson => {
                if (object && object.id === termLifePerson.id) {
                  const logMessage = {
                    quoteId: lifeQuoteId,
                    lob: ClickToChatParameters.LOB,
                    app_id: ClickToChatParameters.APP_ID,
                    channel: ClickToChatParameters.CHANNEL,
                    coverage_amount: termLifePerson.coverageAmount,
                    coverage_term: termLifePerson.coverageTerm,
                    coverage_cost: termLifePerson.costEstimate,
                    email_address: termLifePerson.emailAddress,
                  };
                  message = [...message, logMessage];
                }
              });
              this._loggingService.log(event, message);
            }

            break;
          }
          case EMAIL_QUOTE_ESTIMATE_CLICK: {
            if (termLifePeople.length > 0) {
              termLifePeople.forEach(termLifePerson => {
                if (object && object.id === termLifePerson.id) {
                  const logMessage = {
                    quoteId: lifeQuoteId,
                    coverage_amount: termLifePerson.coverageAmount,
                    coverage_term: termLifePerson.coverageTerm,
                    coverage_cost: termLifePerson.costEstimate,
                    email_address: object.emailAddress,
                  };
                  message = [...message, logMessage];
                }
              });

              this._loggingService.log(event, message);
            }

            break;
          }
        }
      });
  }

  getUpdatedTermLifePeople(): Observable<TermLifeEntity[]> {
    return this._termLifeDao.getUpdatedTermLifePeople();
  }
}
