import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { LoggingAdapter } from '@core/adapters/logging.adapter';
import { LoggingCloudAdapter } from '@core/adapters/logging-cloud.adapter';
import { SessionService } from '@core/services/session.service';
import { AppConfigService } from '@core/services/app-config.service';
import { Product } from '@core/models/products/product.model';
import {
  ProductDisplay,
  PackageDetailDisplay,
} from '@core/models/display/quote-coverages/products/product-display.model';
import { AgencyModel } from '@core/models/agency/agency.model';
import { ProducerUtils } from '@shared/utils/producer.utils';
import { PrivateLabelService } from './private-label.service';
import { RetrieveModel } from '@core/models/retrieve/retrieve.model';
import { AgencyService } from './agency.service';
import { SearchModel, LineOfBusiness } from '@core/models/search/search.model';
import { UserContextService } from './user-context.service';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { AddressEntity } from '@core/models/entities/address.entity';
import { ProducerRequest } from '@core/models/request/producer-request.model';
import { DiscountEntity } from '@core/models/entities/discount.entity';
import { CoverageEntity } from '@core/models/entities/coverage.entity';
import { PremiumEntity } from '@core/models/entities/premium.entity';
import { DownPaymentResponseModel } from '@core/models/down-payment/down-payment-response.model';
import { EscrowAccount } from '@core/models/entities/escrow-account.entity';
import { UAParser } from 'ua-parser-js';

export interface ISplunkLog {
  app?: string;
  env?: string;
  event?: string;
  sessionId?: string;
  spid?: string;
  referrer?: string;
  initiatedBy?: string;
  gtm?: string;
  timestamp?: string;
  products?: ISelectedProducts;
  packages?: ProductDisplay[];
  agent?: IAgent;
  retrieve?: IRetrieve;
  search?: ISearch;
  message?: any;
}

export interface IPackages {
  packages: PackageDetailDisplay[];
}

export interface ISelectedProducts {
  auto?: boolean;
  motorcycle: boolean;
  homeowners: boolean;
  condo: boolean;
  pet: boolean;
}

export interface IAgent {
  producerCode?: string;
  producerId?: string;
  agencyCode?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  state?: string;
  quoteState?: string;
  agencyChannel?: string;
  domain?: string;
  isPrivateLabel?: boolean;
}

export interface IRetrieve {
  quoteId: string;
  lastName: string;
  postalCode: string;
}

export interface IBusinessLog {
  policyHolder?: PolicyholderEntity;
  address?: AddressEntity;
  producer?: ProducerRequest;
  channel: string;
  products: IProductLog[];
  escrowAccount: EscrowAccount;
  payments?: DownPaymentResponseModel[];
  paymentPlan?: string;
  paymentMethod?: string;
  recurring?: boolean;
  paymentError?: any;
}

export interface IProductLog {
  productId?: string;
  submissionNumber?: string;
  policyNumber?: string;
  premium?: PremiumEntity;
  coverages: CoverageEntity[];
  quoteStatus: string;
  discounts: DiscountEntity[];
  errorMessage?: any;
  effectiveDate: string;
}

export interface ISearch {
  lastName: string;
  postalCode: string;
  phoneNumber: string;
  quoteAge: number;
  linesOfBusiness: LineOfBusiness[];
}

@Injectable()
export class LoggingService {
  private userAgent: any = null;

  constructor(
    private _loggingAdapter: LoggingAdapter,
    private _loggingCloudAdapter: LoggingCloudAdapter,
    private _sessionService: SessionService,
    private _appConfig: AppConfigService,
    private _privateLabelService: PrivateLabelService,
    private _userContextService: UserContextService,
    private _agentService: AgencyService,
    @Inject('UAParser') private _uaParser: UAParser
  ) {}

  logToConsole(message: string, object: any): void;

  logToConsole(object: any): void;

  logToConsole(message: string, object?: any): void {
    if (this._appConfig.isTest()) {
      // We can only allow console.log here
      if (object) {
        // eslint-disable-next-line no-console
        console.log(
          message,
          JSON.stringify(object, this.stripNewRelicCruftFromErrorEvents)
        );
      } else {
        // eslint-disable-next-line no-console
        console.log(message);
      }
    }
  }

  log(event: string, object: any): void {
    this.logToConsole(event, object);
    this.logToSplunk({
      event: event,
      message: object,
    });
  }

  logToSplunk(log: ISplunkLog): void {
    combineLatest([
      this._sessionService.getSessionId(),
      this._privateLabelService.getPrivateLabelConfiguration(),
      this._userContextService.getUserContext(),
      this._sessionService.getQuoteState(),
      this._agentService.getAgency(),
    ])
      .pipe(take(1))
      .subscribe(([sessionId, privateLabel, userContext, state, agent]) => {
        const timeEST = new Date();
        const MINUTES_IN_HOUR = 60;

        timeEST.setHours(
          timeEST.getHours() - timeEST.getTimezoneOffset() / MINUTES_IN_HOUR
        );

        const spid = userContext && userContext.spid ? userContext.spid : null;
        const referrer =
          userContext && userContext.referrer ? userContext.referrer : null;
        const initiatedBy =
          userContext && userContext.initiatedBy
            ? userContext.initiatedBy
            : null;
        const gtm = userContext && userContext.gtm ? userContext.gtm : null;

        const body = {
          app: this._appConfig.config.appName,
          env: this._appConfig.config.environmentName,
          event: log.event,
          isPrivateLabel: !!privateLabel,
          products: log.products,
          packages: log.packages,
          agent: log.agent || agent,
          sessionId,
          spid,
          referrer,
          initiatedBy,
          gtm,
          timestamp: timeEST.toISOString().replace('Z', ''),
          message: log.message,
          quoteState: state,
          userAgent: this._getUserAgent(),
        };

        this._loggingCloudAdapter.logToSplunkCloud(body);
      });
  }

  logSelectedProducts(event: string, products: Product[]): void {
    const selectedProducts = <ISelectedProducts>{};
    products.forEach(product => {
      selectedProducts[product.name.toLocaleLowerCase()] = true;
    });

    this.logToSplunk({ event, products: selectedProducts });
  }

  logCoveragePackages(event: string, packages: ProductDisplay[]): void {
    this.logToSplunk({ event, packages });
  }

  logSelectedBindProducts(event: string, products: Product[]): void {
    const selectedProducts = <ISelectedProducts>{};
    products.forEach(product => {
      selectedProducts[product.name.toLocaleLowerCase()] = true;
    });

    this.logToSplunk({ event, products: selectedProducts });
  }

  logAgent(agent: AgencyModel) {
    const logAgent = {} as IAgent;

    const producer = ProducerUtils.getAgencyProducer(
      agent.agencyChannel,
      ProducerUtils.getProducerCode(agent)
    );

    logAgent.agencyChannel = agent.agencyChannel;
    logAgent.isPrivateLabel = !!agent.isPrivateLabel;
    logAgent.agencyCode = producer.agencyCode;
    logAgent.producerId = producer.producerId;
    logAgent.email = agent.email;
    logAgent.firstName = agent.firstName;
    logAgent.lastName = agent.lastName;
    logAgent.producerCode = producer.producerCode;
    logAgent.state = agent.state;
    logAgent.quoteState = agent.quoteState;
    logAgent.userId = agent.userId;
    logAgent.domain = agent.domain;

    this.logToSplunk({ event: 'AGENCY_SUBMIT', agent: logAgent });
  }

  logRetrieve(eventName: string, retrieve: RetrieveModel) {
    const logRetrieve = {} as IRetrieve;

    logRetrieve.quoteId = retrieve.quoteId;
    logRetrieve.lastName = retrieve.lastName;
    logRetrieve.postalCode = retrieve.postalCode;

    this.logToSplunk({
      event: eventName,
      message: { retrieve: logRetrieve },
    });
  }

  logSearch(search: SearchModel) {
    const logSearch = {} as ISearch;

    logSearch.lastName = search.lastName;
    logSearch.postalCode = search.postalCode;
    logSearch.phoneNumber = search.phoneNumber;
    logSearch.quoteAge = search.quoteAge;
    logSearch.linesOfBusiness = search.linesOfBusiness;

    this.logToSplunk({
      event: 'SEARCH_SUBMIT',
      message: { search: logSearch },
    });
  }

  private maskDoB(dob: string) {
    return '**/**/' + dob.split('/')[2];
  }

  private stripNewRelicCruftFromErrorEvents = (key, value) => {
    switch (key) {
      case '__previousCurrentTarget':
      case '__composed':
      case '__composedPath':
      case '__nrNode':
      case '__target':
        return null;
    }
    return value;
  };

  private _getUserAgent(): any {
    if (!this.userAgent) {
      try {
        this.userAgent = this._uaParser.getResult();
      } catch (e) {
        this.userAgent = 'unavailable';
      }
    }
    return this.userAgent;
  }
}
