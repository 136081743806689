import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AutoFocusUtil {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  reAutoFocus() {
    window.setTimeout(() => this.reAutoFocusImmediately(), 10);
  }

  reAutoFocusImmediately() {
    const focusable = <HTMLElement>this._document.querySelector('*[autofocus]');
    if (!focusable || !focusable.focus) {
      return;
    }
    focusable.focus();
  }
}
