import { Injectable } from '@angular/core';

import { QuoteLetterDao } from '@core/dao';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuoteLetterService {
  _coverageDao: any;
  constructor(private _quoteLetterDao: QuoteLetterDao) {}

  dispatchLoadQuoteLetter(): void {
    this._quoteLetterDao.dispatchLoadQuoteLetterPdf();
  }

  getQuoteLetter(): Observable<string> {
    return this._quoteLetterDao.getQuoteLetter();
  }

  getQuoteLetterLoaded(): Observable<boolean> {
    return this._quoteLetterDao.getQuoteLetterLoaded();
  }
}
