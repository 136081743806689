import { Injectable } from '@angular/core';
import { PolicyNumberAdapter } from '@core/adapters/policy-number.adapter';
import { PolicyNumberDao } from '@core/dao/policy-number.dao';
import { PolicyNumberRequest } from '@core/models/policy-number/policy-number-request.model';
import { PolicyNumberResponse } from '@core/models/policy-number/policy-number-response.model';
import { PolicyNumberStatus } from '@core/models/products/policynumber-status';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PolicyNumberService {
  constructor(
    private _policyNumberAdapter: PolicyNumberAdapter,
    private _policyNumberDao: PolicyNumberDao
  ) {}

  generatePolicyNumber(
    request: PolicyNumberRequest
  ): Observable<PolicyNumberResponse> {
    return this._policyNumberAdapter.generatePolicyNumber(request);
  }

  dispatchGeneratePolicyNumber(): void {
    this._policyNumberDao.dispatchGeneratePolicy();
  }

  isPolicyNumberLoaded(): Observable<boolean> {
    return this._policyNumberDao.isPolicyNumberLoaded();
  }
  getPolicyNumberStatusOfAllProducts(): Observable<PolicyNumberStatus[]> {
    return this._policyNumberDao.getPolicyNumberStatusOfAllProducts();
  }
}
