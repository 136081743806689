import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Params } from '@angular/router';

import {
  tap,
  take,
  filter,
  switchMap,
  catchError,
  withLatestFrom,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import {
  PrivateLabelService,
  AgencyService,
  LoggingService,
} from '@core/services';
import { PrivateLabel } from '@core/models/private-label/private-label.model';
import { ProducerUtils } from '@shared/utils/producer.utils';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { AgencyModel } from '@core/models/agency/agency.model';

@Injectable()
export class PrivateLabelGuard implements CanActivate {
  constructor(
    private _privateLabelService: PrivateLabelService,
    private _agencyService: AgencyService,
    private _loggingService: LoggingService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() =>
        this._privateLabelService
          .getPrivateLabelConfiguration()
          .pipe(withLatestFrom(this._agencyService.getAgency()))
      ),
      switchMap(([config, agency]) => {
        if (config) {
          this.setPrivateLabelAgency(config, agency, route.queryParams);
        }
        return of(true);
      }),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this._privateLabelService.getPrivateLabelConfigurationLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._privateLabelService.loadPrivateLabelConfiguration();
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }

  private setPrivateLabelAgency(
    config: PrivateLabel,
    existAgent: AgencyModel,
    queryParams: Params
  ): void {
    const producerCode = existAgent
      ? ProducerUtils.getProducerCode(existAgent)
      : queryParams['producerCode'];
    const agency = PrivateLabelContentHelper.buildPrivateLabelAgent(
      config,
      producerCode
    );
    const updatedAgency = { ...existAgent, ...agency };
    this._agencyService.updateAgency(updatedAgency);
    this._loggingService.logAgent(updatedAgency);
  }
}
