import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DocumentsDao } from '@core/dao/documents.dao';
import { DocumentsAdapter } from '@core/adapters/documents.adapter';
import { DocumentsEntity } from '@core/models/entities/document.entity';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    private _documentsAdapter: DocumentsAdapter,
    private _documentsDao: DocumentsDao
  ) {}

  documents(request: any): Observable<DocumentsEntity> {
    return this._documentsAdapter.documents(request);
  }

  dispatchGetDocuments(): void {
    this._documentsDao.dispatchGetDocuments();
  }

  getDocumentsLoaded(): Observable<boolean> {
    return this._documentsDao.getDocumentsLoaded();
  }

  getAll(): Observable<any[]> {
    return this._documentsDao.getAll();
  }
}
