export const NatGenGenderCodes = {
  M: 'male',
  F: 'female',
};

export const NatGenRelationshipToPNI = {
  PrimaryNamedInsured: 'insured',
};

export const NatGenCoverageIds = {
  COMP: 'COMP',
  COLL: 'COLL',
  BI: 'BI',
  PIP: 'PIP',
  PD: 'PD',
  MP: 'MP',
  UMBI: 'UM',
  UMPD: 'UMPD',
  UIMBI: 'UNDUM',
  UIMPD: 'UNDPD',
  TL: 'TL',
};

export const NatGenAntiTheftDevices = {
  A: 'Alarm',
  D: 'Active Disabling',
  K: 'Recovery Device',
  P: 'Passive Disabling',
  V: 'VIN Etching',
};

export const NatGenAddtionalInterests = {
  LIEN: 'LIEN',
  LESSOR: 'LEASD',
};

export const NatGenValues = {
  control: {
    agentID: '0030030',
    channelSource: 'Internet',
  },
  customer: {
    roleOnPolicy: 'insured',
  },
  quote: {
    lineOfBusiness: 'NWX',
    subLineOfBusiness: 'PPA',
    term: '6',
  },
  driver: {
    driversLicenseStatus: 'Active',
    nationwideApplication: 'Internet',
  },
  vehicle: {
    type: 'privatePassenger',
    usage: 'Pleasure',
    coverage: {
      limit1AppliesTo: 'PerPerson',
      limit2AppliesTo: 'PerOcc',
    },
  },
};

export const DefaultNonStandardErrorCode = 'Non_Standard_Error';

export const NonStandardErrorCodes = [
  'A0106',
  'A0111',
  'A0105',
  'NWX9998',
  'ADR002',
  DefaultNonStandardErrorCode,
];

export const BridgeWaitTime = 3000;

export const AdditionalProductsText = {
  default: 'a Nationwide Policy',
  multiple: 'coverage for other available products',
  Homeowners: 'a Homeowner Policy from Nationwide',
  Renters: 'a Renter Policy from Nationwide',
  Condo: 'a Condo Policy from Nationwide',
  Umbrella: 'an Umbrella Policy from Nationwide',
  TermLife: 'a Term Life Policy from Nationwide',
  Powersports: 'a Powersports Policy from Nationwide',
};
