import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { RenovationEntity } from '@core/store/reducers/renovation.reducer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RenovationsDao {
  constructor(private _store: Store<fromStore.AppState>) {}
  getAllRenovations() {
    this._store.dispatch(new fromActions.GetAllRenovation());
  }

  getAllRenovationsLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getAllRenovationsLoaded);
  }

  // getRenovation(request) {
  //   this._store.dispatch(new fromActions.GetRenovation(request));
  // }

  addRenovation(request: any) {
    this._store.dispatch(new fromActions.AddRenovation(request));
  }

  updateRenovation(request) {
    this._store.dispatch(new fromActions.UpdateRenovation(request));
  }

  deleteRenovation(request) {
    this._store.dispatch(new fromActions.DeleteRenovation(request));
  }

  getRenovationState(): Observable<any> {
    return this._store.select(fromSelectors.selectAllRenovations);
  }
}
