<div class="help nw-text-sm">
  <div class="help-phone">
    <div *ngIf="!agent?.agencySource">
      <span>
        <strong>Need help?</strong>
      </span>
      <span>
        <span *ngIf="!additionalPhone">Call &nbsp;</span> {{ phoneNumberToShow }}
      </span>
      <span>{{ additionalPhone }}</span>
    </div>
    <div *ngIf="agent?.agencySource">
      <span>
        <strong>{{ agent?.agencyName }}</strong>
      </span>
      <span>
        <span *ngIf="agent?.phoneNumber">Agency Phone: &nbsp;</span>
        {{ agent?.phoneNumber }}
      </span>
    </div>

    <div *ngFor="let product of dsmProducts">
      <span *ngIf="product.quoteId && product.show"><strong>{{ product.name }} Quote ID: &nbsp;</strong>
        {{ product.quoteId }}</span>
    </div>
    <div *ngIf="isTest && awsRouterCookieValue">
      <span><strong>NWX Env: &nbsp;</strong> {{ awsRouterCookieValue }}</span>
      <span><strong>PLS Env: &nbsp;</strong> {{ pcEnv }}</span>
    </div>
  </div>
</div>
