import { Action } from '@ngrx/store';
import { LendersModel } from '@core/models/lenders/lender.model';

export const ADD_LENDER = '[Core] Add Lender';
export const ADD_DSM_LENDER = '[Core] DSM Add Lender';
export const ADD_LENDER_FAIL = '[Core] Add Lender Fail';
export const ADD_LENDER_SUCCESS = '[Core] Add Lender Success';

export const DELETE_LENDER = '[Core] Delete Lender';
export const DELETE_LENDER_FAIL = '[Core] Delete Lender Fail';
export const DELETE_LENDER_SUCCESS = '[Core] Delete Lender Success';
export const TOGGLE_LENDER_SELECTED = '[Core] Toggle Lender Selected';
export const RETRIEVE_MORTGAGES = '[Core] Retrieve Mortgages';

export const UPDATE_DSM_LENDER = '[Core] Update Dsm Mortgage Lender';
export const UPDATE_DSM_LENDER_SUCCESS =
  '[Core] Update Dsm Mortgage Lender Success';
export const UPDATE_DSM_LENDER_FAIL = '[Core] Update Dsm Mortgage Lender Fail';

export class AddLender implements Action {
  readonly type = ADD_LENDER;
  constructor(public payload: any) {}
}

export class AddDsmLender implements Action {
  readonly type = ADD_DSM_LENDER;
  constructor(public payload: any) {}
}

export class AddLenderSuccess implements Action {
  readonly type = ADD_LENDER_SUCCESS;
  constructor(public payload: any) {}
}

export class AddLenderFail implements Action {
  readonly type = ADD_LENDER_FAIL;
  constructor(public payload: any) {}
}

export class DeleteLender implements Action {
  readonly type = DELETE_LENDER;
  constructor(public payload: any) {}
}

export class DeleteLenderSuccess implements Action {
  readonly type = DELETE_LENDER_SUCCESS;
  constructor(public payload: LendersModel) {}
}

export class DeleteLenderFail implements Action {
  readonly type = DELETE_LENDER_FAIL;
  constructor(public payload: any) {}
}

export class ToggleLenderSelected implements Action {
  readonly type = TOGGLE_LENDER_SELECTED;
  constructor(public payload: any) {}
}

export class RetrieveMortgages implements Action {
  readonly type = RETRIEVE_MORTGAGES;
  constructor(public payload: any) {}
}

export class UpdateDsmMortgageLender implements Action {
  readonly type = UPDATE_DSM_LENDER;
  constructor(public payload: any) {}
}

export class UpdateDsmMortgageLenderSuccess implements Action {
  readonly type = UPDATE_DSM_LENDER_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateDsmMortgageLenderFail implements Action {
  readonly type = UPDATE_DSM_LENDER_FAIL;
  constructor(public payload: any) {}
}

export type LendersActions =
  | AddLender
  | AddDsmLender
  | AddLenderFail
  | AddLenderSuccess
  | DeleteLender
  | DeleteLenderFail
  | DeleteLenderSuccess
  | ToggleLenderSelected
  | RetrieveMortgages
  | UpdateDsmMortgageLender
  | UpdateDsmMortgageLenderSuccess
  | UpdateDsmMortgageLenderFail;
