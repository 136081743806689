export class CaptchaApiRequest {
  token: string;
  remoteIP: string;

  constructor() {
    this.token = '';
    this.remoteIP = '';
  }
}

export interface ReCaptchav2Request {
  event: ReCaptchaRequestEvent;
}

export interface ReCaptchav2Response {
  name: string;
  event: ReCaptchaRequestEvent;
  tokenProperties: TokenProperties;
}

export interface ReCaptchaRequestEvent {
  token: string;
  siteKey: string;
  userIpAddress?: string;
  userAgent?: string;
  expectedAction?: string;
}

export interface TokenProperties {
  valid: boolean;
  invalidReason: string;
  hostname: string;
  action: string;
  createTime: string;
}
