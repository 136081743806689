export class MockProductsService {
  updateSelectedProducts() {}
  loadProducts() {}
  getProducts() {}
  getLifeQuoteId() {}
  getAddonProducts() {}
  getProductsLoaded() {}
  getProductsLoading() {}

  getSelectedProducts() {}
  getSelectedProductsForHeader() {}
  getSelectedProductsForDirectHeader() {}

  getSelectedCoverageProducts() {}
  getVehicleProducts() {}
  isProductSelectedWithoutError() {}

  isProductsSelectedWithoutError() {}
  isProductSelected() {}
  isHomeownersSelected() {}
  isCondoSelected() {}
  isAutoSelected() {}
  isRentersSelected() {}
  isPowersportsSelected() {}
  getSelectedBindProducts() {}
  isProductBindSelected() {}

  isHomeownersBindSelected() {}
  isNonRentalPropertyBindSelected() {}

  isAnyPropertyBindSelected() {}

  isAnyPropertyBinded() {}

  isCondoBindSelected() {}

  isAutoBindSelected() {}
  isRentersBindSelected() {}

  isPowersportsBindSelected() {}
  isNonRentalPropertyBindOnlySelected() {}

  isEligibleForUmbrella() {}

  getProduct() {}
  updateProduct() {}
  getQuoteLoaded() {}

  getAnyQuoteLoaded() {}
  getQuoteLoading() {}

  getAutoTokenLoaded() {}

  getAutoTokenLoading() {}

  getPowersportsTokenLoaded() {}

  getPowersportsTokenLoading() {}
  getSelectedProductIds() {}

  getNonRentalPropertyProductSelectedWithoutError() {}
  getPropertyProductSelectedWithoutError() {}

  isAnyPropertyProductSelected() {}

  isDsmProductSelected() {}
  getQuoteRated() {}

  getQuoteCompleted() {}
  getQuoteCoverageLoading() {}
  getQuoteRating() {}

  getQuoteUpdateLoading() {}

  isQuoteLoaded() {}

  isBindLoaded() {}
  isIssueLoaded() {}
  isIssueLoadedOrFailed() {}
  getQuoteCoverageLoaded() {}

  getAllQuoteCoverageLoaded() {}
  getQuoteBound() {}

  getBindCompleted() {}
  getQuoteBinding() {}
  getSelectedQuoteProductsWithoutErrors() {}
  getSelectedQuoteProducts() {}

  getSelectedProductsWithoutErrors() {}

  isDsmProductBindSelected() {}
  getNeedConstructionInfo() {}
  setLeadsSentToCma() {}
  getStatusOfAllProducts() {}

  getStatusOfAllProductsWithoutErrors() {}

  getAnyProductRating() {}

  failProduct() {}
  softfall() {}

  failAllProducts() {}
  arePreBindDocumentsAcknowledged() {}
  areAllProductsFailed() {}

  getSelectedPropertyProduct() {}

  anyProductIsInitiating() {}

  disqualifyUmbrella() {}

  isMonoQuoteAllowed() {}
  updateShowerrorExceptAProduct() {}
}
