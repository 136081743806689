import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnChanges,
} from '@angular/core';
import { CoverageEntity } from '@core/models/entities/coverage.entity';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';
import {
  ProductTypes,
  CoverageDisplayGrouping,
  VehicleViewTypes,
  DESKTOP_VIEW,
} from '@shared/constants/app-constants';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { Product } from '@core/models/products/product.model';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import { CoverageGrouping } from '../edit-coverage-table/coverage-grouping.model';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { PremiumEntity } from '@core/models/entities/premium.entity';
import { CoverageUtils } from '@shared/utils/coverage.utils';
import {
  TelematicsEnrollmentResponse,
  PreQualifiedVehicleInfo,
} from '@core/models/telematics/telematics.model';
import { ConnectedCarCriteria } from '@core/models/private-label/private-label.model';
import { TermLifeEntity } from '@core/models/termlife/termlife.entity';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'mq-edit-coverages',
  templateUrl: './edit-coverages.component.html',
  styleUrls: ['./edit-coverages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCoveragesComponent implements OnChanges {
  constructor(private scroller: ViewportScroller) {}

  _showPropertyInfoModal = false;
  vehicleMake: string;

  @Input() form: UntypedFormGroup;
  @Input() showCoveragePrices: boolean;
  @Input() selectedProduct: Product;
  @Input() termMonths = 1;
  @Input() coverageChanges: CoverageChange[];
  @Input() coverages: CoverageEntity[];
  @Input() coverageErrors: CoverageError[];
  @Input() vehicles: VehicleEntity[];
  @Input() powersportsVehicles: VehicleEntity[];
  @Input() drivers: DriverEntity[];
  @Input() quoteState: string;
  @Input() isQuote: boolean;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() extraUpdatedCoverage: string;
  @Input() smartride: EligibleDiscountEntity;
  @Input() contactNumber: string;
  @Input() dwellingCoverageConsent: boolean;
  @Input() additionalPolicyNotification: string;
  @Input() isCompRater: boolean;
  @Input() telematicsEnrollments: TelematicsEnrollmentResponse;
  @Input() preQualifiedVehicleInfo: PreQualifiedVehicleInfo[];
  @Input() connectedCarCriteria: ConnectedCarCriteria[];
  @Input() isConnectedCarEnabled: boolean;
  @Input() coveragePremiums: PremiumEntity[];
  @Input() loadProgramsOpen: boolean;
  @Input() policyHolder: PolicyholderEntity;
  @Input() termLifePeople: TermLifeEntity[];
  @Input() privateLabelIdentifier: string;
  @Input() isAnyPropertyProductSelected: boolean;
  @Input() isMobile: boolean;
  @Input() isAutoSelected: boolean;
  @Input() isHomeownersSelected: boolean;
  @Input() isUmbrellaSelected: boolean;

  @Output()
  coverageUpdated: EventEmitter<CoverageChange[]> = new EventEmitter<
    CoverageChange[]
  >();
  @Output() consentToggled: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() telematicsResetRequested = new EventEmitter<void>();
  @Output() propertyInfoModalToggled = new EventEmitter<void>();
  @Output() telematicsChangeRequested = new EventEmitter<void>();
  @Output() isAnnualMileError = new EventEmitter<boolean>();

  autoCoverageGroupings: {
    policy?: CoverageGrouping;
    additionalPolicy?: CoverageGrouping;
    vehicles?: CoverageGrouping[];
    drivers?: CoverageGrouping[];
    other?: CoverageGrouping;
  } = { vehicles: [] };

  powersportsCoverageGroupings: {
    policy?: CoverageGrouping;
    additionalPolicy?: CoverageGrouping;
    vehicles?: CoverageGrouping[];
    drivers?: CoverageGrouping[];
    other?: CoverageGrouping;
  } = { vehicles: [] };

  homeownersCoverageGroupings: {
    propertyLiability?: CoverageGrouping;
    deductibles?: CoverageGrouping;
    additionalProtection?: CoverageGrouping;
    specialLimitsOfLiability?: CoverageGrouping;
  } = {};

  condoCoverageGroupings: {
    propertyLiability?: CoverageGrouping;
    deductibles?: CoverageGrouping;
    additionalProtection?: CoverageGrouping;
    specialLimitsOfLiability?: CoverageGrouping;
  } = {};

  rentersCoverageGroupings: {
    propertyLiability?: CoverageGrouping;
    deductibles?: CoverageGrouping;
    additionalProtection?: CoverageGrouping;
    specialLimitsOfLiability?: CoverageGrouping;
  } = {};

  umbrellaCoverageGroupings: {
    policy?: CoverageGrouping;
  } = {};

  dwellingReplacementCostRequired = false;

  ngOnChanges() {
    this.initAutoCoverageTables();
    this.initHomeownersCoverageTables();
    this.initCondoCoverageTables();
    this.initRentersCoverageTables();
    this.initUmbrellaCoverageTables();
    this.initPowersportsCoverageTables();
  }

  coverageChanged(coverageChange: CoverageChange[]): void {
    this.coverageUpdated.emit(coverageChange);
  }

  onConsentToggled(event: Event) {
    this.consentToggled.emit(event);
  }

  private initAutoCoverageTables() {
    if (!this.coverages || !this.selectedProduct) {
      return;
    }
    this.autoCoverageGroupings = {};

    if (this.selectedProduct.id === ProductTypes.AUTO) {
      const autoCoverages = this.coverages.filter(
        coverage => coverage.productId === this.selectedProduct.id
      );
      const autoPolicyCoverages = autoCoverages.filter(coverage =>
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          CoverageDisplayGrouping.Policy,
          this.quoteState,
          this.selectedProduct.id
        )
      );
      autoPolicyCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.autoCoverageGroupings.policy = {
        coverages: autoPolicyCoverages,
        title: CoverageDisplayGrouping.Policy,
      };
      const vehicleIdsWithoutAnnualMiles = [];

      this.vehicles.forEach(vehicle => {
        if (vehicle.annualMiles == undefined) {
          vehicleIdsWithoutAnnualMiles.push(vehicle.vehicleId);
        }
      });
      this.autoCoverageGroupings.vehicles = [];
      this.vehicles.forEach(vehicle => {
        const vehicleCoverages = autoCoverages.filter(coverage => {
          return (
            // eslint-disable-next-line eqeqeq
            vehicle.vehicleId == coverage.coverableId &&
            CoverageUtils.filterCoverageByGrouping(
              coverage,
              CoverageDisplayGrouping.Vehicle,
              this.quoteState,
              this.selectedProduct.id
            )
          );
        });
        vehicleCoverages.sort(
          CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
        );
        this.vehicleMake = vehicle.prefillId
          ? vehicle.make
          : vehicle.makeDescription;
        const title =
          vehicle.year + ' ' + this.vehicleMake + ' ' + vehicle.model;
        const icon = 'assets/images/VehicleSide.svg';
        this.autoCoverageGroupings.vehicles.push({
          title,
          icon,
          coverages: vehicleCoverages,
          annualMiles: vehicle.annualMiles,
          vehicleId: vehicle.vehicleId,
        });
        this.isAnnualMileError.emit(vehicle.annualMiles ? false : true);
        if (
          this.form.invalid &&
          vehicle.annualMiles == undefined &&
          vehicleIdsWithoutAnnualMiles?.[0] == vehicle.vehicleId
        ) {
          this.scrollDownToElementById(`annualMiles-${vehicle.vehicleId}`);
        }
      });

      this.autoCoverageGroupings.drivers = [];
      this.drivers.forEach(driver => {
        const driverCoverages = autoCoverages.filter(coverage => {
          return (
            // eslint-disable-next-line eqeqeq
            driver.driverId == coverage.coverableId &&
            CoverageUtils.filterCoverageByGrouping(
              coverage,
              CoverageDisplayGrouping.Driver,
              this.quoteState,
              this.selectedProduct.id
            )
          );
        });
        if (driverCoverages.length > 0) {
          driverCoverages.sort(
            CoverageUtils.coverageSorter(
              this.quoteState,
              this.selectedProduct.id
            )
          );
          const title = driver.person.firstName + ' ' + driver.person.lastName;
          this.autoCoverageGroupings.drivers.push({
            title,
            coverages: driverCoverages,
          });
        }
      });

      const autoAdditionalPolicyCoverages = autoCoverages.filter(coverage =>
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          CoverageDisplayGrouping.AdditionalPolicy,
          this.quoteState,
          this.selectedProduct.id
        )
      );
      autoAdditionalPolicyCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.autoCoverageGroupings.additionalPolicy = {
        title: CoverageDisplayGrouping.AdditionalPolicy,
        coverages: autoAdditionalPolicyCoverages,
      };

      const autoOtherCoverages = autoCoverages.filter(coverage =>
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          CoverageDisplayGrouping.OtherCoverages,
          this.quoteState,
          this.selectedProduct.id
        )
      );
      autoOtherCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.autoCoverageGroupings.other = {
        title: CoverageDisplayGrouping.OtherCoverages,
        coverages: autoOtherCoverages,
      };
      if (this.isAnyPropertyProductSelected) {
        const autoOtherCoveragesValidationForIDFR = autoCoverages.filter(
          coverage =>
            CoverageUtils.coverageValidationForIDFR(
              coverage,
              this.quoteState,
              coverage.productId
            )
        );
        autoOtherCoveragesValidationForIDFR.sort(
          CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
        );
        this.autoCoverageGroupings.other = {
          title: CoverageDisplayGrouping.OtherCoverages,
          coverages: autoOtherCoveragesValidationForIDFR,
        };
      }
    }
  }

  private scrollDownToElementById(id) {
    const element = document.getElementById(id);
    const bounds = element.getBoundingClientRect();
    const x = bounds.left;
    const y = bounds.top;
    if (window.innerWidth < DESKTOP_VIEW) {
      const halfWidth = Math.floor(x / 4);
      const halfHeight = Math.floor(y / 4);
      const bannerWidth = Math.floor(window.innerWidth / 4);
      const bannerHeight = Math.floor(window.innerHeight / 4);
      this.scroller.scrollToPosition([
        x - halfWidth + bannerWidth,
        y - halfHeight + bannerHeight,
      ]);
    } else {
      const halfWidth = Math.floor(window.innerWidth / 2);
      const halfHeight = Math.floor(window.innerHeight / 2);
      const bannerWidth = Math.floor(window.innerWidth / 4);
      const bannerHeight = Math.floor(window.innerHeight / 4);
      this.scroller.scrollToPosition([
        x - halfWidth - bannerWidth,
        y - halfHeight - bannerHeight,
      ]);
    }
  }
  private initPowersportsCoverageTables() {
    if (!this.coverages || !this.selectedProduct) {
      return;
    }
    this.powersportsCoverageGroupings = {};

    if (this.selectedProduct.id === ProductTypes.POWERSPORTS) {
      const powersportsCoverages = this.coverages.filter(
        coverage => coverage.productId === this.selectedProduct.id
      );
      const powersportsPolicyCoverages = powersportsCoverages.filter(coverage =>
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          CoverageDisplayGrouping.Policy,
          this.quoteState,
          this.selectedProduct.id
        )
      );
      powersportsPolicyCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.powersportsCoverageGroupings.policy = {
        coverages: powersportsPolicyCoverages,
        title: CoverageDisplayGrouping.Policy,
      };

      this.powersportsCoverageGroupings.vehicles = [];
      this.powersportsVehicles.forEach(vehicle => {
        const vehicleCoverages = powersportsCoverages.filter(coverage => {
          return (
            // tslint:disable-next-line:triple-equals
            vehicle.vehicleId == coverage.coverableId &&
            CoverageUtils.filterCoverageByGrouping(
              coverage,
              CoverageDisplayGrouping.Vehicle,
              this.quoteState,
              this.selectedProduct.id
            )
          );
        });
        vehicleCoverages.sort(
          CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
        );
        const title = vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model;

        let icon = 'assets/images/powersports_icon.svg';
        if (vehicle.vehicleViewType === VehicleViewTypes.Motorcycle) {
          icon = 'assets/images/Motorcycle_Blue.svg';
        }
        if (vehicle.vehicleViewType === VehicleViewTypes.GolfCart) {
          icon = 'assets/images/GolfCart_Blue.svg';
        }
        if (vehicle.vehicleViewType === VehicleViewTypes.ATV) {
          icon = 'assets/images/ATV.svg';
        }
        if (vehicle.vehicleViewType === VehicleViewTypes.Snowmobile) {
          icon = 'assets/images/Snowmobile_Blue.svg';
        }

        if (vehicleCoverages.length > 0) {
          this.powersportsCoverageGroupings.vehicles.push({
            title,
            icon,
            coverages: vehicleCoverages,
            annualMiles: vehicle.annualMiles,
            vehicleId: vehicle.vehicleId,
          });
        }
      });

      this.powersportsCoverageGroupings.drivers = [];
      this.drivers.forEach(driver => {
        const driverCoverages = powersportsCoverages.filter(coverage => {
          return (
            // tslint:disable-next-line:triple-equals
            driver.driverId == coverage.coverableId &&
            CoverageUtils.filterCoverageByGrouping(
              coverage,
              CoverageDisplayGrouping.Driver,
              this.quoteState,
              this.selectedProduct.id
            )
          );
        });
        if (driverCoverages.length > 0) {
          driverCoverages.sort(
            CoverageUtils.coverageSorter(
              this.quoteState,
              this.selectedProduct.id
            )
          );
          const title = driver.person.firstName + ' ' + driver.person.lastName;
          this.powersportsCoverageGroupings.drivers.push({
            title,
            coverages: driverCoverages,
          });
        }
      });

      const powersportsAdditionalPolicyCoverages = powersportsCoverages.filter(
        coverage =>
          CoverageUtils.filterCoverageByGrouping(
            coverage,
            CoverageDisplayGrouping.AdditionalPolicy,
            this.quoteState,
            this.selectedProduct.id
          )
      );
      powersportsAdditionalPolicyCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.powersportsCoverageGroupings.additionalPolicy = {
        title: CoverageDisplayGrouping.AdditionalPolicy,
        coverages: powersportsAdditionalPolicyCoverages,
      };

      const powersportsOtherCoverages = powersportsCoverages.filter(coverage =>
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          CoverageDisplayGrouping.OtherCoverages,
          this.quoteState,
          this.selectedProduct.id
        )
      );
      powersportsOtherCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.powersportsCoverageGroupings.other = {
        title: CoverageDisplayGrouping.OtherCoverages,
        coverages: powersportsOtherCoverages,
      };
    }
  }

  private initHomeownersCoverageTables() {
    if (!this.coverages || !this.selectedProduct) {
      return;
    }
    this.homeownersCoverageGroupings = {};

    if (this.selectedProduct.id === ProductTypes.HOMEOWNERS) {
      const homeownersCoverages = this.coverages.filter(
        coverage => coverage.productId === this.selectedProduct.id
      );

      const propertyLiabilityCoverages = homeownersCoverages.filter(coverage =>
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          CoverageDisplayGrouping.PropertyLiability,
          this.quoteState,
          this.selectedProduct.id
        )
      );
      propertyLiabilityCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.homeownersCoverageGroupings.propertyLiability = {
        coverages: propertyLiabilityCoverages,
        title: CoverageDisplayGrouping.PropertyLiability,
      };

      const additionalProtectionCoverages = homeownersCoverages.filter(
        coverage =>
          CoverageUtils.filterCoverageByGrouping(
            coverage,
            CoverageDisplayGrouping.AdditionalProtection,
            this.quoteState,
            this.selectedProduct.id
          )
      );
      additionalProtectionCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.homeownersCoverageGroupings.additionalProtection = {
        coverages: additionalProtectionCoverages,
        title: CoverageDisplayGrouping.AdditionalProtection,
      };

      const specialLimitsOfLiabilityCoverages = homeownersCoverages.filter(
        coverage =>
          CoverageUtils.filterCoverageByGrouping(
            coverage,
            CoverageDisplayGrouping.SpecialLimitsOfLiability,
            this.quoteState,
            this.selectedProduct.id
          )
      );
      specialLimitsOfLiabilityCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.homeownersCoverageGroupings.specialLimitsOfLiability = {
        coverages: specialLimitsOfLiabilityCoverages,
        title: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      };
    }
    if (this.isQuote && this.stateSpecificFlags.dwellingReplacementCost) {
      this.dwellingReplacementCostRequired = true;
      this.form.addControl(
        'dwellingReplacementCostCheckbox',
        new UntypedFormControl(this.dwellingCoverageConsent)
      );
    }
  }

  private initCondoCoverageTables() {
    if (!this.coverages || !this.selectedProduct) {
      return;
    }
    this.condoCoverageGroupings = {};

    if (this.selectedProduct.id === ProductTypes.CONDO) {
      const homeownersCoverages = this.coverages.filter(
        coverage => coverage.productId === this.selectedProduct.id
      );

      const propertyLiabilityCoverages = homeownersCoverages.filter(coverage =>
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          CoverageDisplayGrouping.PropertyLiability,
          this.quoteState,
          this.selectedProduct.id
        )
      );
      propertyLiabilityCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.condoCoverageGroupings.propertyLiability = {
        coverages: propertyLiabilityCoverages,
        title: CoverageDisplayGrouping.PropertyLiability,
      };

      const additionalProtectionCoverages = homeownersCoverages.filter(
        coverage =>
          CoverageUtils.filterCoverageByGrouping(
            coverage,
            CoverageDisplayGrouping.AdditionalProtection,
            this.quoteState,
            this.selectedProduct.id
          )
      );
      additionalProtectionCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.condoCoverageGroupings.additionalProtection = {
        coverages: additionalProtectionCoverages,
        title: CoverageDisplayGrouping.AdditionalProtection,
      };

      const specialLimitsOfLiabilityCoverages = homeownersCoverages.filter(
        coverage =>
          CoverageUtils.filterCoverageByGrouping(
            coverage,
            CoverageDisplayGrouping.SpecialLimitsOfLiability,
            this.quoteState,
            this.selectedProduct.id
          )
      );
      specialLimitsOfLiabilityCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.condoCoverageGroupings.specialLimitsOfLiability = {
        coverages: specialLimitsOfLiabilityCoverages,
        title: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      };
    }
    if (this.isQuote && this.stateSpecificFlags.dwellingReplacementCost) {
      this.dwellingReplacementCostRequired = true;
      this.form.addControl(
        'dwellingReplacementCostCheckbox',
        new UntypedFormControl(this.dwellingCoverageConsent)
      );
    }
  }

  private initRentersCoverageTables() {
    if (!this.coverages || !this.selectedProduct) {
      return;
    }
    this.rentersCoverageGroupings = {};

    if (this.selectedProduct.id === ProductTypes.RENTERS) {
      const homeownersCoverages = this.coverages.filter(
        coverage => coverage.productId === this.selectedProduct.id
      );

      const propertyLiabilityCoverages = homeownersCoverages.filter(coverage =>
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          CoverageDisplayGrouping.PropertyLiability,
          this.quoteState,
          this.selectedProduct.id
        )
      );
      propertyLiabilityCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.rentersCoverageGroupings.propertyLiability = {
        coverages: propertyLiabilityCoverages,
        title: CoverageDisplayGrouping.PropertyLiability,
      };

      const additionalProtectionCoverages = homeownersCoverages.filter(
        coverage =>
          CoverageUtils.filterCoverageByGrouping(
            coverage,
            CoverageDisplayGrouping.AdditionalProtection,
            this.quoteState,
            this.selectedProduct.id
          )
      );
      additionalProtectionCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.rentersCoverageGroupings.additionalProtection = {
        coverages: additionalProtectionCoverages,
        title: CoverageDisplayGrouping.AdditionalProtection,
      };

      const specialLimitsOfLiabilityCoverages = homeownersCoverages.filter(
        coverage =>
          CoverageUtils.filterCoverageByGrouping(
            coverage,
            CoverageDisplayGrouping.SpecialLimitsOfLiability,
            this.quoteState,
            this.selectedProduct.id
          )
      );
      specialLimitsOfLiabilityCoverages.sort(
        CoverageUtils.coverageSorter(this.quoteState, this.selectedProduct.id)
      );
      this.rentersCoverageGroupings.specialLimitsOfLiability = {
        coverages: specialLimitsOfLiabilityCoverages,
        title: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      };
    }
  }

  private initUmbrellaCoverageTables() {
    if (!this.coverages || !this.selectedProduct) {
      return;
    }
    this.umbrellaCoverageGroupings = {};

    if (this.selectedProduct.id === ProductTypes.UMBRELLA) {
      const umbrellaCoverages = this.coverages.filter(
        coverage => coverage.productId === this.selectedProduct.id
      );

      const availableCoverages = umbrellaCoverages.filter(
        coverage => coverage.available
      );

      const umbrellaPolicyCoverages = availableCoverages.filter(coverage =>
        this.filterCoverageByGrouping(coverage, CoverageDisplayGrouping.Policy)
      );
      umbrellaPolicyCoverages.sort(this.coverageSorter());
      this.umbrellaCoverageGroupings.policy = {
        coverages: umbrellaPolicyCoverages,
        title: CoverageDisplayGrouping.Policy,
      };
    }
  }

  private coverageSorter() {
    return (a: CoverageEntity, b: CoverageEntity) => {
      const coverageAMetadata = this.getMetadata(a);
      const sequenceA = coverageAMetadata
        ? coverageAMetadata.coverageSequence
        : 0;
      const coverageBMetadata = this.getMetadata(b);
      const sequenceB = coverageBMetadata
        ? coverageBMetadata.coverageSequence
        : 0;
      return sequenceA - sequenceB;
    };
  }

  private filterCoverageByGrouping(
    coverage: CoverageEntity,
    grouping
  ): boolean {
    const metadata = this.getMetadata(coverage);

    return metadata && metadata.displayGrouping === grouping;
  }

  private getMetadata(coverage: CoverageEntity): any {
    return CoverageUtils.getMetadata(
      coverage,
      this.quoteState,
      this.selectedProduct.id
    );
  }

  get autoProductId() {
    return ProductTypes.AUTO;
  }

  get homeownersProductId() {
    return ProductTypes.HOMEOWNERS;
  }

  get condoProductId() {
    return ProductTypes.CONDO;
  }

  get rentersProductId() {
    return ProductTypes.RENTERS;
  }

  get lifeProductId() {
    return ProductTypes.TERMLIFE;
  }

  get umbrellaProductId() {
    return ProductTypes.UMBRELLA;
  }

  get powersportsProductId() {
    return ProductTypes.POWERSPORTS;
  }

  getStateSpecific(stateSpecificFlag: string) {
    return this.stateSpecificFlags
      ? this.stateSpecificFlags[stateSpecificFlag]
      : null;
  }
}
