import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import {
  ProductTypes,
  VehicleTypes,
  VehiclePrimaryUseCodes,
  MARKET_VALUE_EXCEEDED_VALUE,
  MARKET_VALUE_NOT_EXCEEDED_VALUE,
} from '@shared/constants/app-constants';
import { VehicleResponse } from '@core/models/response/vehicle-response.model';
import { AdditionalInterestEntity } from '@core/models/entities/additional-interest.entity';
import { VehicleRequest } from '@core/models/request/vehicle-request.model';
import { DriverVehiclePrefillResponseVehicle } from '@core/models/driver-vehicle-prefill/driver-vehicle-prefill-response.model';
import { DateUtils } from '../date.utils';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { StringUtils } from '../string.utils';

export class VehicleBuilder {
  static buildVehicleEntityFromModel(vehicle: any): VehicleEntity {
    let splitAddress = [];
    let newGarageAddress;
    if (
      vehicle.parkingEnteredAddress !== null &&
      vehicle.parkingEnteredAddress !== undefined &&
      vehicle.parkingEnteredAddress !== ''
    ) {
      splitAddress = vehicle.parkingEnteredAddress.split(',');
      if (
        splitAddress[0] &&
        splitAddress[1] &&
        splitAddress[2] &&
        splitAddress[3]
      ) {
        newGarageAddress = {
          addressLine1: splitAddress[0].trim(),
          addressLine2: '',
          city: splitAddress[1].trim(),
          state: splitAddress[2].trim(),
          postalCode: splitAddress[3].trim(),
        };
      }
    }
    const entity: VehicleEntity = {
      productId: VehicleBuilder.setVehicleProductId(vehicle.type),
      vehicleId: vehicle.id,
      make: vehicle.make.make,
      model: vehicle.model.model,
      makeDescription: vehicle.make.makeDescription,
      modelDescription: vehicle.model.modelDescription,
      year: vehicle.year,
      vehicleType: vehicle.type,
      vehicleViewType: vehicle.vehicleType,
      vin: vehicle.vin,
      maskedVin: vehicle.vin,
      // Sending default value for other states apart from 'CA'
      primaryUse:
        vehicle.primaryUse === 'Please Select' || !vehicle.primaryUse
          ? 'Work'
          : VehiclePrimaryUseCodes[vehicle.primaryUse],
      bodyStyle: vehicle.bodyStyle,
      bodyType: vehicle.bodyType,
      prefillId: vehicle.prefillId,
      odometerReading: this.setOdomReading(vehicle.odometerReading),
      annualMiles: this.setAnnualMiles(vehicle.annualMiles?.toString()) || '',
      previouslyOwned: vehicle.previouslyOwned,
      isPurchasedNew: false,
      isRegisteredInState: vehicle.isRegisteredInState,
    };
    if (newGarageAddress) {
      entity.garageLocation = newGarageAddress;
    }
    if (vehicle.antiTheftDevices) {
      entity.antiTheftDevices = vehicle.antiTheftDevices;
    }

    if (vehicle.eligibleDiscounts) {
      entity.eligibleDiscounts = vehicle.eligibleDiscounts;
    }

    if (vehicle.previouslyOwned === 'false' && vehicle.purchasedNewDate) {
      entity.isPurchasedNew = true;
      entity.purchasedNewDate = vehicle.purchasedNewDate;
    }
    // If the vehicle is within 2 years of current year then set as newly purchased.
    if (
      Math.abs(+vehicle.year - DateUtils.getYear()) <= 2 &&
      (vehicle.previouslyOwned === null ||
        vehicle.previouslyOwned === undefined)
    ) {
      entity.isPurchasedNew = true;
      entity.purchasedNewDate = DateUtils.getTodayOrYesterdaysDateAsStr();
    }

    const additionalInterest: AdditionalInterestEntity = {
      additionalInterestType:
        vehicle.loanLease === 'Lease' ? 'LESSOR' : vehicle.loanLease,
    };

    if (vehicle.registeredOwners) {
      entity.registeredOwners = vehicle.registeredOwners;
    }
    if (vehicle.isRegisteredOwnerNotListed) {
      entity.isRegisteredOwnerNotListed = vehicle.isRegisteredOwnerNotListed;
    }
    if (vehicle.loanLease !== 'none') {
      entity.additionalInterests = [additionalInterest];
    }
    if (vehicle.daysCommutingPerWeek) {
      entity.daysCommutingPerWeek = vehicle.daysCommutingPerWeek;
    }
    if (vehicle.milesOneWay) {
      entity.milesOneWay = vehicle.milesOneWay;
    }
    if (vehicle.vehicleIdentificationPattern) {
      entity.vehicleIdentificationPattern =
        vehicle.vehicleIdentificationPattern;
    }
    if (vehicle.prepopulatedInsuredId && vehicle.vehicleTempId) {
      entity.prepopulatedInsuredId = vehicle.prepopulatedInsuredId;
      entity.vehicleTempId = vehicle.vehicleTempId;
    }
    if (vehicle.partialQuoteId && vehicle.vehicleTempId) {
      entity.partialQuoteId = vehicle.partialQuoteId;
      entity.vehicleTempId = vehicle.vehicleTempId;
      if (vehicle.prequalifiedPartnerScoreId) {
        entity.prequalifiedPartnerScoreId = vehicle.prequalifiedPartnerScoreId;
      }
    }
    return entity;
  }

  static buildPowersportsVehicleEntityFromModel(vehicle: any): VehicleEntity {
    let splitAddress = [];
    let newGarageAddress;
    if (
      vehicle.parkingEnteredAddress !== null &&
      vehicle.parkingEnteredAddress !== undefined &&
      vehicle.parkingEnteredAddress !== ''
    ) {
      splitAddress = vehicle.parkingEnteredAddress.split(',');
      if (
        splitAddress[0] &&
        splitAddress[1] &&
        splitAddress[2] &&
        splitAddress[3]
      ) {
        newGarageAddress = {
          addressLine1: splitAddress[0].trim(),
          addressLine2: '',
          city: splitAddress[1].trim(),
          state: splitAddress[2].trim(),
          postalCode: splitAddress[3].trim(),
        };
      }
    }
    const entity: VehicleEntity = {
      productId: VehicleBuilder.setVehicleProductId(vehicle.type),
      vehicleId: vehicle.id,
      make:
        vehicle.type === VehicleTypes.UTILITYTRAILER
          ? vehicle.make
          : vehicle.make.make,
      model:
        vehicle.type === VehicleTypes.UTILITYTRAILER
          ? vehicle.model
          : vehicle.model.model,
      year: vehicle.year,
      vehicleType: vehicle.type,
      vehicleViewType: vehicle.vehicleType,
      vin: vehicle.vin,
      maskedVin: vehicle.vin,
      // Sending default value for other states apart from 'CA'
      primaryUse:
        vehicle.primaryUse === 'Please Select' || !vehicle.primaryUse
          ? 'Work'
          : VehiclePrimaryUseCodes[vehicle.primaryUse],
      bodyStyle: vehicle.bodyStyle,
      bodyType: vehicle.bodyType,
      prefillId: vehicle.prefillId,
      odometerReading: this.setOdomReading(vehicle.odometerReading),
      previouslyOwned: vehicle.previouslyOwned,
      isPurchasedNew: vehicle.type !== 'UtilityTrailer' ? false : null,
      isRegisteredInState: vehicle.isRegisteredInState,
      isRegisteredOwnerNotListed: vehicle.isRegisteredOwnerNotListed,
    };
    if (newGarageAddress) {
      entity.garageLocation = newGarageAddress;
    }
    if (vehicle.type === VehicleTypes.MOTORCYCLE) {
      if (
        vehicle &&
        vehicle.additionalInformation &&
        vehicle.additionalInformation.isVehicleRegisteredAsHistorical ===
          false &&
        !vehicle.hasVehicleConvertedToTrike
      ) {
        entity.additionalInformation = {
          hasVehicleBeenConvertedToTrike: vehicle.hasVehicleConvertedToTrike,
          isVehicleRegisteredAsHistorical: false,
        };
      } else if (
        vehicle &&
        vehicle.additionalInformation &&
        vehicle.additionalInformation.isVehicleRegisteredAsHistorical ===
          false &&
        vehicle.hasVehicleConvertedToTrike
      ) {
        entity.additionalInformation = {
          hasVehicleBeenConvertedToTrike: vehicle.hasVehicleConvertedToTrike,
          isVehicleRegisteredAsHistorical: false,
        };
        entity.agreedValue = 1500;
      } else if (vehicle.hasVehicleConvertedToTrike) {
        entity.additionalInformation = {
          hasVehicleBeenConvertedToTrike: vehicle.hasVehicleConvertedToTrike,
        };
        entity.agreedValue = 1500;
      } else {
        entity.additionalInformation = {
          hasVehicleBeenConvertedToTrike: vehicle.hasVehicleConvertedToTrike,
        };
      }
    } else if (vehicle.type !== VehicleTypes.UTILITYTRAILER) {
      entity.additionalInformation = {
        hasVehicleBeenConvertedToTrike: null,
      };
    }
    if (vehicle.type === VehicleTypes.UTILITYTRAILER) {
      entity.value = this.setUtilityTrailerCost(vehicle.utilityTrailerCost);
    }
    if (vehicle.vehicleType === VehicleTypes.DUNEBUGGY) {
      entity.marketValue = vehicle.isMarketValueExceeded
        ? MARKET_VALUE_EXCEEDED_VALUE
        : MARKET_VALUE_NOT_EXCEEDED_VALUE;
    }
    if (vehicle.cubicCentimeters) {
      entity.cubicCentimeters = vehicle.cubicCentimeters;
    }
    if (vehicle.cubicCentimeters) {
      entity.cubicCentimeters = vehicle.cubicCentimeters;
    }
    if (vehicle.antiTheftDevices) {
      entity.antiTheftDevices = vehicle.antiTheftDevices;
    }

    if (vehicle.eligibleDiscounts) {
      entity.eligibleDiscounts = vehicle.eligibleDiscounts;
    }

    if (
      vehicle.previouslyOwned === 'false' &&
      vehicle.purchasedNewDate &&
      vehicle.type !== VehicleTypes.UTILITYTRAILER
    ) {
      entity.isPurchasedNew = true;
      entity.purchasedNewDate = vehicle.purchasedNewDate;
    } else if (vehicle.previouslyOwned === 'true') {
      entity.isPurchasedNew = false;
    }

    // If the vehicle is within 2 years of current year then set as newly purchased.
    if (
      Math.abs(+vehicle.year - DateUtils.getYear()) <= 2 &&
      vehicle.previouslyOwned === null &&
      vehicle.type !== VehicleTypes.UTILITYTRAILER
    ) {
      entity.isPurchasedNew = true;
      entity.purchasedNewDate = DateUtils.getTodayOrYesterdaysDateAsStr();
    }

    const additionalInterest: AdditionalInterestEntity = {
      additionalInterestType:
        vehicle.loanLease === 'Lease' ? 'LESSOR' : vehicle.loanLease,
    };

    if (vehicle.registeredOwners) {
      entity.registeredOwners = vehicle.registeredOwners;
    }
    if (vehicle.isRegisteredOwnerNotListed) {
      entity.isRegisteredOwnerNotListed = vehicle.isRegisteredOwnerNotListed;
    }
    if (vehicle.loanLease !== 'none') {
      entity.additionalInterests = [additionalInterest];
    }
    if (vehicle.daysCommutingPerWeek) {
      entity.daysCommutingPerWeek = vehicle.daysCommutingPerWeek;
    }
    if (vehicle.milesOneWay) {
      entity.milesOneWay = vehicle.milesOneWay;
    }
    if (vehicle.vehicleIdentificationPattern) {
      entity.vehicleIdentificationPattern =
        vehicle.vehicleIdentificationPattern;
    }
    if (vehicle.prepopulatedInsuredId && vehicle.vehicleTempId) {
      entity.prepopulatedInsuredId = vehicle.prepopulatedInsuredId;
      entity.vehicleTempId = vehicle.vehicleTempId;
    }
    return entity;
  }

  static buildVehicleEntityFromResponse(
    vehicle: VehicleResponse,
    request: VehicleRequest
  ): VehicleEntity {
    const entity: VehicleEntity = { ...vehicle };
    entity.vin = this.betterVin(request.vehicle.vin, vehicle.vin);
    entity.odometerReading = request.vehicle.odometerReading;
    entity.annualMiles =
      request.vehicle.annualMiles !== 'NaN' ? request.vehicle.annualMiles : '';
    entity.previouslyOwned = request.vehicle.previouslyOwned;
    entity.purchasedNewDate = request.vehicle.purchasedNewDate;
    entity.primaryUse = request.vehicle.primaryUse;
    entity.maskedVin = request.vehicle.vin;
    entity.productId = request.productId;
    entity.selected = true;
    entity.additionalInterestIds = vehicle.additionalInterests
      ? vehicle.additionalInterests.map(
          interests => interests.additionalInterestId
        )
      : [];
    entity.additionalInterests = [];
    entity.prefillId = request.vehicle.prefillId;
    entity.vehicleViewType = request.vehicle.vehicleViewType;
    entity.isRegisteredOwnerNotListed =
      request.vehicle.isRegisteredOwnerNotListed;

    if (vehicle.vin === undefined) {
      entity.makeDescription = request.vehicle.makeDescription;
      entity.modelDescription = request.vehicle.modelDescription;
    }

    if (vehicle.vin && vehicle.vin.startsWith('*')) {
      entity.make = vehicle.make || request.vehicle.make;
      entity.makeDescription = request.vehicle.makeDescription;
      entity.model = vehicle.model || request.vehicle.model;
      entity.modelDescription = request.vehicle.modelDescription;
    }
    // If make or model changed, replace its "description" with the new data.
    if (
      vehicle.make &&
      !(<any>vehicle).makeDescription &&
      vehicle.make !== request.vehicle.make
    ) {
      entity.makeDescription = vehicle.make;
    }
    if (
      vehicle.model &&
      !(<any>vehicle).modelDescription &&
      vehicle.model !== request.vehicle.model
    ) {
      entity.modelDescription = vehicle.model;
    }
    if (request.vehicle.vehicleIdentificationPattern) {
      entity.vehicleIdentificationPattern =
        request.vehicle.vehicleIdentificationPattern;
    }
    return entity;
  }

  static buildPowersportsVehicleEntityFromResponse(
    vehicle: VehicleResponse,
    request: VehicleRequest
  ): VehicleEntity {
    const entity: VehicleEntity = {
      ...vehicle,
      powersportsVehicleId: vehicle.vehicleId,
    };
    entity.vin = request.vehicle.vin;
    entity.odometerReading = request.vehicle.odometerReading;
    entity.annualMiles =
      request.vehicle.annualMiles !== 'NaN' ? request.vehicle.annualMiles : '';
    entity.primaryUse = request.vehicle.primaryUse;
    entity.maskedVin = request.vehicle.vin;
    entity.productId = request.productId;
    entity.selected = true;
    entity.additionalInterestIds = vehicle.additionalInterests
      ? vehicle.additionalInterests.map(
          interests => interests.additionalInterestId
        )
      : [];
    entity.additionalInterests = [];
    entity.prefillId = request.vehicle.prefillId;
    entity.vehicleViewType = request.vehicle.vehicleViewType;
    if (
      request?.vehicle?.additionalInformation
        ?.hasVehicleBeenConvertedToTrike !== null
    ) {
      entity.additionalInformation.hasVehicleBeenConvertedToTrike =
        request?.vehicle?.additionalInformation?.hasVehicleBeenConvertedToTrike;
    }
    if (
      request?.vehicle?.additionalInformation
        ?.isVehicleRegisteredAsHistorical === false
    ) {
      entity.additionalInformation.isVehicleRegisteredAsHistorical =
        request?.vehicle?.additionalInformation?.isVehicleRegisteredAsHistorical;
    }
    if (vehicle.vin === undefined) {
      entity.makeDescription = request.vehicle.makeDescription;
      entity.modelDescription = request.vehicle.modelDescription;
    }

    if (vehicle.vin && vehicle.vin.startsWith('*')) {
      entity.make = vehicle.make || request.vehicle.make;
      entity.makeDescription = request.vehicle.makeDescription;
      entity.model = vehicle.model || request.vehicle.model;
      entity.modelDescription = request.vehicle.modelDescription;
    }

    // If make or model changed, replace its "description" with the new data.
    if (
      vehicle.make &&
      !(<any>vehicle).makeDescription &&
      vehicle.make !== request.vehicle.make
    ) {
      entity.makeDescription = vehicle.make;
    }
    if (
      vehicle.model &&
      !(<any>vehicle).modelDescription &&
      vehicle.model !== request.vehicle.model
    ) {
      entity.modelDescription = vehicle.model;
    }

    if (request.vehicle.vehicleIdentificationPattern) {
      entity.vehicleIdentificationPattern =
        request.vehicle.vehicleIdentificationPattern;
    }
    return entity;
  }

  static betterVin(request: string, response: string): string {
    if (!request) {
      return response;
    }
    if (!response) {
      return request;
    }
    const IDEAL_LENGTH = 17;
    if (request.length === IDEAL_LENGTH && response.length !== IDEAL_LENGTH) {
      return request;
    }
    if (request.length !== IDEAL_LENGTH && response.length === IDEAL_LENGTH) {
      return response;
    }
    if (request.indexOf('*') >= 0) {
      return response;
    }
    if (response.indexOf('*') >= 0) {
      return request;
    }
    if (request.length > response.length) {
      return request;
    }
    return response;
  }

  static setOdomReading(odomReading: string) {
    if (odomReading) {
      return parseFloat(odomReading.toString().replace(/,/g, '')).toString();
    }
    return null;
  }

  static setUtilityTrailerCost(utilityTrailerCost: string) {
    if (utilityTrailerCost) {
      return parseFloat(utilityTrailerCost.toString().replace(/,/g, ''));
    }
    return null;
  }

  static setAnnualMiles(annualMiles: string): string {
    if (annualMiles) {
      return parseFloat(annualMiles.replace(/,/g, '')).toString();
    }
    return '';
  }

  static setVehicleProductId(type: string): string {
    if (StringUtils.areEqual(type, VehicleTypes.AUTO)) {
      return ProductTypes.AUTO;
    } else {
      return ProductTypes.POWERSPORTS;
    }
  }

  static buildVehicleEntitiesFromPrefillResponse(
    prefill: DriverVehiclePrefillResponseVehicle[]
  ): VehicleEntity[] {
    if (!prefill) {
      return null;
    }
    const entities: VehicleEntity[] = prefill.map(vehicle => {
      const entity: VehicleEntity = {
        prefillId: vehicle.prefillId,
        bodyStyle: vehicle.bodyStyle,
        make: vehicle.makeCode,
        makeDescription: vehicle.make,
        model: vehicle.modelCode,
        modelDescription: vehicle.model,
        odometerReading: vehicle.odometerReading,
        annualMiles: vehicle.annualMiles,
        previouslyOwned: vehicle.previouslyOwned,
        primaryUse: vehicle.primaryUse,
        year: vehicle.modelYear,
        vin: vehicle.vin,
        maskedVin: vehicle.vin,
        vehicleType: VehicleTypes.AUTO,
      };
      return entity;
    });
    if (prefill.find(vehicle => vehicle.vehicleType !== 'Auto')) {
      return null;
    } else return entities;
  }

  static buildPowerpsortsVehicleEntitiesFromPrefillResponse(
    prefill: DriverVehiclePrefillResponseVehicle[]
  ): VehicleEntity[] {
    if (!prefill) {
      return null;
    }
    const entities: VehicleEntity[] = prefill.map(vehicle => {
      const entity: VehicleEntity = {
        prefillId: vehicle.prefillId,
        bodyStyle: vehicle.bodyStyle,
        make: vehicle.makeCode,
        makeDescription: vehicle.make,
        model: vehicle.modelCode,
        modelDescription: vehicle.model,
        odometerReading: vehicle.odometerReading,
        annualMiles: vehicle.annualMiles,
        previouslyOwned: vehicle.previouslyOwned,
        primaryUse: vehicle.primaryUse,
        year: vehicle.modelYear,
        vin: vehicle.vin,
        maskedVin: vehicle.vin,
        type: vehicle.type,
        vehicleType: VehicleTypes.MOTORCYCLE,
      };
      return entity;
    });
    if (prefill.find(vehicle => vehicle.vehicleType === 'Auto')) {
      return null;
    } else return entities;
  }

  static entityFromVehicle(
    input: VehicleEntity,
    productType: string
  ): VehicleEntity {
    const eligibleDiscounts = (input.eligibleDiscounts || []).map(discount => ({
      ...discount,
      productId: productType,
    }));

    const output: VehicleEntity = {
      selected: true,
      productId: ProductTypes.AUTO,
      quoteId: input.quoteId,
      vehicleId: input.vehicleId,
      year: String(input.year),
      make: input.make,
      makeDescription: input.makeDescription || input.make,
      model: input.model,
      modelDescription: input.model, // TODO this field is retrieved on vehicle page from VIN service
      // on retrieve flows WHEN/IF user lands on vehicle page... maybe we should move that call to the retrieve flow?
      odometerReading: input.odometerReading,
      annualMiles: input.annualMiles,
      previouslyOwned: input.previouslyOwned,
      bodyType: input.bodyType,
      bodyStyle: input.bodyStyle,
      vin: input.vin,
      maskedVin: input.vin,
      seriesDescription: undefined,
      primaryUse: input.primaryUse,
      vehicleType: input.vehicleType,
      garageLocationId: undefined, // TODO We have (garageLocation); need an ID for it
      costNew: undefined,
      antiTheftDevices: undefined, // TODO We have string (antiTheftDevice)
      eligibleDiscounts: input.eligibleDiscounts ? input.eligibleDiscounts : [],
      registeredOwners: input.registeredOwners, // TODO may require mapping to RegisteredOwnerEntity[]
      isPurchasedNew: false,
      isSmartRideDeviceEligible: input.isSmartRideDeviceEligible,
      isRegisteredOwnerNotListed: input.isRegisteredOwnerNotListed,
      isRetrieve: input.isRetrieve,
      additionalInterests: input.additionalInterests,
      daysCommutingPerWeek: input.daysCommutingPerWeek,
      milesOneWay: input.milesOneWay,
      garageLocation: input.garageLocation,
      isConnectable: input.isConnectable,
    };
    // If the vehicle is within 2 years of current year then set as newly purchased.
    if (Math.abs(+output.year - DateUtils.getYear()) <= 2) {
      output.isPurchasedNew = true;
      output.purchasedNewDate = DateUtils.getCurrentDateAsStr();
    }

    // (input.annualMiles) will never actually exist. Read it from the SmartMiles discount if possible.
    if (!output.annualMiles) {
      output.annualMiles = this.findAnnualMilesInDiscounts(
        input.eligibleDiscounts
      );
    }

    return output;
  }

  private static findAnnualMilesInDiscounts(
    discounts: EligibleDiscountEntity[] | undefined
  ): string | undefined {
    if (discounts) {
      const smartMiles = discounts.find(
        d => d.eligibleDiscountId === 'SmartMiles'
      );
      if (smartMiles) {
        if (smartMiles.qualifyingInformation) {
          return smartMiles.qualifyingInformation.annualMiles || undefined;
        }
      }
    }
    return undefined;
  }
}
