import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';

@Injectable({
  providedIn: 'root',
})
export class HomeownerPropertyInfoDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  addPickList(pickList) {
    this._store.dispatch(new fromActions.LoadPickList(pickList));
  }

  addCondoPickList(pickList) {
    this._store.dispatch(new fromActions.LoadPickListCondo(pickList));
  }

  addRentersPickList(pickList) {
    this._store.dispatch(new fromActions.LoadPickListRenters(pickList));
  }

  getPickList() {
    return this._store.select(fromSelectors.getPickListValue);
  }
}
