import {
  createSelector,
  createSelectorFactory,
  defaultMemoize,
} from '@ngrx/store';

import * as fromFeature from '@bind/store/reducers';
import * as fromNavigation from '@bind/store/reducers/navigation.reducer';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromDrivers from '@core/store/selectors/driver.selector';
import * as fromVehicles from '@core/store/selectors/vehicle.selector';
import * as fromAdditionalInterests from '@core/store/selectors/additional-interest.selector';
import * as fromHomeowners from '@core/store/selectors/homeowners.selector';
import * as fromCondo from '@core/store/selectors/condo.selector';
import * as fromCoveredLocation from '@core/store/selectors/covered-location.selector';
import * as fromRetrieve from '@core/store/selectors/retrieve.selector';

import { BindPageTitles, ProductTypes } from '@shared/constants/app-constants';
import { NavigationPath } from '@core/models/navigation/navigation.model';
import { Product } from '@core/models/products/product.model';

export const getNavigationState = createSelector(
  fromFeature.getBindState,
  (state: fromFeature.BindState) => state.navigation
);

export const getNavigationBasePath = createSelector(
  getNavigationState,
  fromNavigation.getNavigationBasePath
);

export const getNavigationCurrentPath = createSelector(
  getNavigationState,
  fromNavigation.getNavigationCurrentPath
);

export const getNavigationLoaded = createSelector(
  getNavigationState,
  fromNavigation.getNavigationLoaded
);

export const getNavigationLoading = createSelector(
  getNavigationState,
  fromNavigation.getNavigationLoading
);

export const dsmUpdateNavigationPath = () => {
  return createSelectorFactory(defaultMemoize)(
    getNavigationBasePath,
    getNavigationCurrentPath,
    fromProducts.getSelectedProductsWithoutErrors,
    fromDrivers.selectAllDrivers,
    fromVehicles.selectAllDsmVehicles,
    fromAdditionalInterests.selectAdditionalInterestsForProductsWithoutError,
    fromHomeowners.getHomeowners,
    fromCondo.getCondo,
    fromCoveredLocation.getCoveredLocationData,
    fromRetrieve.isCompRater,
    (
      paths,
      oldPath,
      products,
      drivers,
      vehicles,
      additionalInterests,
      homeowners,
      condominium,
      coveredLocation,
      isCompRater
    ) => {
      const newPath: NavigationPath = { ...paths };
      newPath.items = paths.items
        .map(path => {
          const copy = { ...path };
          switch (copy.title) {
            case BindPageTitles.ADDITIONAL_INFORMATION: {
              const auto: Product = products.find(
                product =>
                  product.id === ProductTypes.AUTO ||
                  product.id === ProductTypes.POWERSPORTS
              );

              if (!auto || (auto && auto.hasError)) {
                return;
              }

              copy.enabled = true;

              break;
            }

            case BindPageTitles.PROPERTY_INFO: {
              const home: Product = products.find(
                product => product.id === ProductTypes.HOMEOWNERS
              );

              if (
                home &&
                (!coveredLocation ||
                  !coveredLocation.constructionInfo ||
                  !coveredLocation.constructionInfo.squareFootage ||
                  !coveredLocation.constructionInfo.numberOfStories ||
                  !coveredLocation.constructionInfo.yearBuilt)
              ) {
                copy.enabled = true;
              }

              break;
            }
            case BindPageTitles.LENDERS: {
              const home: Product = products.find(
                product => product.id === ProductTypes.HOMEOWNERS
              );

              const condo: Product = products.find(
                product => product.id === ProductTypes.CONDO
              );

              const auto: Product = products.find(
                product => product.id === ProductTypes.AUTO
              );

              if (additionalInterests.length > 0) {
                copy.enabled = true;
              }

              if ((home || auto) && isCompRater) {
                copy.enabled = true;
              }

              if (home && homeowners && homeowners.hasMortgage) {
                copy.enabled = true;
              }

              if (condo && condominium && condominium.hasMortgage) {
                copy.enabled = true;
              }
              break;
            }

            case BindPageTitles.PRODUCT_DETAILS: {
              const home: Product = products.find(
                product => product.id === ProductTypes.HOMEOWNERS
              );
              const condo: Product = products.find(
                product => product.id === ProductTypes.CONDO
              );
              const renters: Product = products.find(
                product => product.id === ProductTypes.RENTERS
              );

              if (home && !home.hasError) {
                copy.enabled = true;
              }

              if (condo && !condo.hasError) {
                copy.enabled = true;
              }

              if (renters && !renters.hasError) {
                copy.enabled = true;
              }

              break;
            }
          }
          return copy;
        })
        .filter(item => (item ? item.enabled : null));

      newPath.currentIndex = findIndexInNewPath(newPath, oldPath);

      return newPath;
    }
  );
};

function findIndexInNewPath(newPath, oldPath) {
  if (!oldPath || !oldPath.items) {
    return 0;
  }
  if (
    oldPath.currentIndex < 0 ||
    oldPath.currentIndex >= oldPath.items.length
  ) {
    return 0;
  }
  const oldUrl = oldPath.items[oldPath.currentIndex].url;
  for (let i = 0; i < newPath.items.length; i++) {
    if (newPath.items[i].url === oldUrl) {
      return i;
    }
  }
  return 0;
}
