import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@core/validators/custom-validators';
import { RentersModel } from '@core/models/renters/renters.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { HO_PROP_INFO_FORM_ENG } from '@shared/constants/app-constants';

@Component({
  selector: 'mq-renters-property-info-form',
  templateUrl: './renters-property-info-form.component.html',
  styleUrls: ['./renters-property-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RentersPropertyInfoFormComponent implements OnInit {
  @Input() renters: RentersModel;
  @Input() form: UntypedFormGroup;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;

  @Input() set _pickList(value) {
    if (value) {
      this.pickList = value;
    }
  }

  pickList: any;
  homeownerText = HO_PROP_INFO_FORM_ENG;

  constructor() {}

  ngOnInit(): void {
    this.unitsInBuilding.setValidators([
      Validators.required,
      CustomValidators.PicklistRequired,
    ]);

    if (this.form && this.renters.unitsInBuilding) {
      this.form.patchValue({
        unitsInBuilding: this.renters.unitsInBuilding.toString(),
      });
    }
  }

  get structuralMaterial(): AbstractControl {
    return this.form.get('structuralMaterial');
  }

  get unitsInBuilding(): AbstractControl {
    return this.form.get('unitsInBuilding');
  }
}
