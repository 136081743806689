import * as fromPrepopulatedInsured from '@core/store/actions/prepopulated-insured.action';
import { PrepopulatedInsured } from '@core/models/prepopulated-insured/prepopulated-insured.model';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';

export interface PrepopulatedInsuredState {
  prepopulatedInsured: PrepopulatedInsured;
  drivers: DriverEntity[];
  policyHolders: PolicyholderEntity[];
  vehicles: VehicleEntity[];
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
}

export const initialState: PrepopulatedInsuredState = {
  prepopulatedInsured: null,
  drivers: [],
  policyHolders: [],
  vehicles: [],
  loaded: false,
  loading: false,
  hasError: false,
};

export function reducer(
  state = initialState,
  action: fromPrepopulatedInsured.PrepopulatedInsuredActions
): PrepopulatedInsuredState {
  switch (action.type) {
    case fromPrepopulatedInsured.PrepopulatedInsuredActionTypes
      .LOAD_PREPOPULATED_INSURED_DATA: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromPrepopulatedInsured.PrepopulatedInsuredActionTypes
      .LOAD_PREPOPULATED_INSURED_DATA_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        hasError: true,
      };
    }

    case fromPrepopulatedInsured.PrepopulatedInsuredActionTypes
      .LOAD_PREPOPULATED_INSURED_DATA_SUCCESS: {
      return {
        ...state,
        prepopulatedInsured: action.payload,
        loaded: true,
        loading: false,
      };
    }

    case fromPrepopulatedInsured.PrepopulatedInsuredActionTypes
      .ADD_PREPOPULATED_INSURED_POLICYHOLDERS: {
      return {
        ...state,
        policyHolders: action.payload,
      };
    }

    case fromPrepopulatedInsured.PrepopulatedInsuredActionTypes
      .ADD_PREPOPULATED_INSURED_DRIVERS: {
      return {
        ...state,
        drivers: action.payload,
      };
    }

    case fromPrepopulatedInsured.PrepopulatedInsuredActionTypes
      .ADD_PREPOPULATED_INSURED_VEHICLES: {
      return {
        ...state,
        vehicles: action.payload,
      };
    }

    case fromPrepopulatedInsured.PrepopulatedInsuredActionTypes
      .UPDATE_PREPOPULATED_VEHICLE_SELECTED: {
      const vehicle = action.payload;
      const prePopVehicles = state.vehicles;
      const updatedVehicles = prePopVehicles.map(prepopVehicle => {
        if (prepopVehicle.vehicleTempId === vehicle.vehicleTempId) {
          return { ...prepopVehicle, selected: vehicle.selected };
        }
        return prepopVehicle;
      });
      return {
        ...state,
        vehicles: updatedVehicles,
      };
    }

    case fromPrepopulatedInsured.PrepopulatedInsuredActionTypes
      .UPDATE_PREPOPULATED_DRIVER_SELECTED: {
      const driver = action.payload;
      const prePopDrivers = state.drivers;
      const updatedDrivers = prePopDrivers.map(prepopDriver => {
        if (prepopDriver.driverTempId === driver.driverTempId) {
          return { ...prepopDriver, selected: driver.selected };
        }
        return prepopDriver;
      });
      return {
        ...state,
        drivers: updatedDrivers,
      };
    }

    case fromPrepopulatedInsured.PrepopulatedInsuredActionTypes
      .UPDATE_PREPOPULATED_INSURED_VEHICLE_SUCCESS: {
      const vehicle = action.payload;
      const prePopVehicles = state.vehicles;
      const updatedVehicles = prePopVehicles.map(prepopVehicle => {
        if (prepopVehicle.vehicleTempId === vehicle.vehicleTempId) {
          return {
            ...prepopVehicle,
            ...vehicle,
          };
        }
        return prepopVehicle;
      });
      return {
        ...state,
        vehicles: updatedVehicles,
      };
    }

    case fromPrepopulatedInsured.PrepopulatedInsuredActionTypes
      .REMOVE_PREPOPULATED_INSURED_VEHICLE: {
      const vehicle = action.payload;
      const filteredVehicles = state.vehicles.filter(
        veh => veh.vehicleTempId !== vehicle.vehicleTempId
      );
      return {
        ...state,
        vehicles: filteredVehicles,
      };
    }
  }
  return state;
}
