import {
  ChangeDetectorRef,
  Directive,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ProductsService } from '@core/services';
import { ProductTypes } from '@shared/constants/app-constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[isLife]',
})
export class IsLifeDirective implements OnInit, OnDestroy {
  private _ngUnsubscribe = new Subject();

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _productsService: ProductsService,
    private _changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._productsService
      .getSelectedQuoteProducts()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(products => {
        this._updateView(
          products
            ? !!products.find(product => product.id === ProductTypes.TERMLIFE)
            : false
        );
        this._changeDetection.markForCheck();
      });
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  private _updateView(islife: boolean) {
    this._viewContainer.clear();
    if (islife) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    }
  }
}
