import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import * as fromStore from '@core/store';

import { CoverageEntity } from '@core/models/entities/coverage.entity';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import { Product } from '@core/models/products/product.model';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';

@Injectable({
  providedIn: 'root',
})
export class CoverageDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getCoverageChanges(): Observable<CoverageChange[]> {
    return this._store.select(fromSelectors.getCoverageChanges);
  }

  getExtraUpdatedCoverage(): Observable<string> {
    return this._store.select(fromSelectors.getExtraUpdatedCoverage);
  }

  getCoverages(): Observable<CoverageEntity[]> {
    return this._store.select(fromSelectors.selectAllCoverages);
  }

  isLifeCoverageProductSelected(): Observable<boolean> {
    return this._store.select(fromSelectors.isLifeCoverageProductSelected);
  }

  isAutoCoverageProductSelected(): Observable<boolean> {
    return this._store.select(fromSelectors.isAutoCoverageProductSelected);
  }

  getCoveragesFor(productId: string): Observable<CoverageEntity[]> {
    return this._store.select(fromSelectors.getCoveragesFor(productId));
  }

  loadAllCoverages(productId: string): void {
    this._store.dispatch(new fromActions.LoadCoverages(productId));
  }

  updateCoverage(productId: string): void {
    this._store.dispatch(new fromActions.UpdateCoverage(productId));
  }

  updateCoverageFail(productId: string, error: CoverageError[]): void {
    this._store.dispatch(new fromActions.UpdateCoverageFail(productId, error));
  }

  updateCoverageChanges(coverageChanges: CoverageChange[]): void {
    this._store.dispatch(new fromActions.UpdateCoverageChange(coverageChanges));
  }

  updateSelectedProductCoverage(productId: string): void {
    this._store.dispatch(
      new fromActions.UpdateSelectedProductCoverage(productId)
    );
  }

  updateSelectedCoverageProducts(productIds: string[]): void {
    this._store.dispatch(
      new fromActions.UpdateSelectedCoverageProducts(productIds)
    );
  }

  getSelectedProductCoverage(): Observable<Product> {
    return this._store.select(fromSelectors.getSelectedProductCoverage);
  }

  setDwellingConsentChecked(value: boolean): void {
    return this._store.dispatch(new fromActions.UpdateDwellingConsent(value));
  }

  getDwellingCoverageConsent(): Observable<boolean> {
    return this._store.select(fromSelectors.getDwellingCoverageConsent);
  }
}
