import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Entry } from 'contentful';
import * as _ from 'lodash';
import {
  PrivateLabel,
  ProducerInformation,
  AgencyProduct,
  CoverageConfiguration,
  CustomUIConfiguration,
} from '@core/models/private-label/private-label.model';
import { ContentfulService } from '@core/services/contentful.service';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PrivateLabelAdapter {
  private CONTENT_TYPE = 'privateLabel';

  constructor(
    private _contentfulService: ContentfulService,
    private _http: HttpClient
  ) {}

  getConfigurationForIdentifier(
    identifier: string,
    isPreview: boolean
  ): Observable<PrivateLabel> {
    const query = {
      'fields.identifier': identifier
        ? identifier.toLocaleLowerCase()
        : identifier,
      include: 2,
    };

    return from(
      this._contentfulService
        .getEntries(this.CONTENT_TYPE, query, isPreview)
        .then((response: Entry<any>[]) => {
          if (response.length === 0) {
            return null;
          }
          // Private Label identifier is unique, only one should ever be returned
          const content = response[0].fields;

          return {
            identifier: content.identifier,
            residentState: content.residentState,
            agencyName: content.agencyName,
            phoneNumber: content.phoneNumber,
            additionalPhoneNumber: content.additionalPhoneNumber,
            websiteUrl: content.websiteUrl,
            logoUrl: this.extractAssetUrl(content.logo),
            logoWidth: content.logoWidth ? content.logoWidth + 'px' : null,
            favicon: this.extractAssetUrl(content.favicon),
            emailAddress: content.emailAddress,
            channel: content.channel,
            producerInformation: this.extractProducerInformation(
              content.producerInformation
            ),
            customUIConfig: this.extractCustomUIConfig(
              content.customUIConfiguration
            ),
            isThirdPartyPartner: !!content.isThirdPartyPartner,
          } as PrivateLabel;
        })
    );
  }

  getPrivateLabelLogo(url: string): Observable<any> {
    return this._http.get(url, { responseType: 'blob' });
  }

  private extractProducerInformation(
    rawProducerData: any
  ): ProducerInformation[] {
    return _.map(rawProducerData, producer => {
      const producerFields = _.find(producer, this.extractFields);
      return {
        producerIdentifier: producerFields.producerIdentifier,
        producerCode: producerFields.producerCode,
        agencyIdentifier: producerFields.agencyIdentifier,
        state: producerFields.state,
        nameFiledWithCommissioner: producerFields.nameFiledWithCommissioner,
        agencyLicenseNumber: producerFields.agencyLicenseNumber,
        residentState: producerFields.residentState,
        products: this.extractProducts(producerFields.productPackages),
        coverages: this.extractCoverages(producerFields.coverages),
        isDefault: !!producerFields.isDefault,
        phoneNumber: producerFields.phoneNumber,
        email: producerFields.email,
      } as ProducerInformation;
    });
  }

  private extractProducts(products: any): AgencyProduct[] {
    return _.map(products, product => {
      return this.buildProduct(_.find(product, this.extractFields));
    });
  }

  private extractCoverages(coverages: any): CoverageConfiguration[] {
    return _.map(coverages, coverage => {
      return PrivateLabelContentHelper.buildCoverage(
        _.find(coverage, this.extractFields)
      );
    });
  }

  private extractCustomUIConfig(rawData: any): CustomUIConfiguration[] {
    return _.map(rawData, data => {
      const configFields = _.find(data, this.extractFields);
      return {
        element: configFields.element,
        identifier: configFields.identifier,
        value: configFields.value,
      } as CustomUIConfiguration;
    });
  }

  private buildProduct(productFields: any): AgencyProduct {
    return { product: productFields.product };
  }

  private extractFields(fields: any, key: string): boolean {
    return key === 'fields';
  }

  private extractAssetUrl(content: any): string {
    if (content && content['fields']['file']['url']) {
      return 'https:' + content['fields']['file']['url'];
    }
    return null;
  }
}
