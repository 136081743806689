import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';

import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromServices from '@core/services';
import * as fromActions from '@core/store/actions';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable()
export class RentersEffects {
  constructor(
    private _actions$: Actions,
    private _rentersService: fromServices.RentersService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  loadSeparateUnits$ = this._actions$.pipe(
    ofType(fromActions.LOAD_RENTERS_SPE_UNITS),
    switchMap(() => {
      return this._rentersService.getSeparateUnits().pipe(
        map(
          separateUnits =>
            new fromActions.LoadRentersSeparateUnitsSuccess(
              separateUnits['separateUnits']
            )
        ),
        catchError(error =>
          of(
            new fromActions.LoadRentersSeparateUnitsFail(
              this._errorHelper.sanitizeError(error)
            )
          )
        )
      );
    })
  );

  @Effect()
  loadStructuralMaterials$ = this._actions$.pipe(
    ofType(fromActions.LOAD_RENTERS_STR_MATERIALS),
    switchMap(() => {
      return this._rentersService.getStructuralMaterials().pipe(
        map(
          structuralMaterials =>
            new fromActions.LoadRentersStructuralMaterialsSuccess(
              structuralMaterials['structuralMaterials']
            )
        ),
        catchError(error =>
          of(
            new fromActions.LoadRentersSeparateUnitsFail(
              this._errorHelper.sanitizeError(error)
            )
          )
        )
      );
    })
  );
}
