import * as fromAgency from '@core/store/actions/agency.action';

import { AgencyModel } from '@core/models/agency/agency.model';
import { ProducerSearch } from '@core/models/agency/producer-search.model';
import { AgencyHelper } from '@core/services/helpers/agency.helper';

export interface AgencyState {
  agentJwt?: any;
  accessToken?: string;
  agency: AgencyModel;
  loaded: boolean;
  loading: boolean;
  producerCodes?: ProducerSearch;
}

export const initialState: AgencyState = {
  agentJwt: null,
  agency: null,
  loaded: false,
  loading: false,
  accessToken: null,
};

export function reducer(
  state = initialState,
  action: fromAgency.AgencyActions
): AgencyState {
  switch (action.type) {
    case fromAgency.LOAD_AGENCY: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromAgency.LOAD_AGENCY_SUCCESS: {
      const agency = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        agency,
      };
    }

    case fromAgency.LOAD_AGENCY_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    }

    case fromAgency.UPDATE_AGENCY: {
      const agency = AgencyHelper.adjustAgencyAndProducerCodes(action.payload);
      return {
        ...state,
        agency: { ...state.agency, ...agency },
        loaded: true,
        loading: false,
      };
    }

    case fromAgency.UPDATE_AGENT_JWT: {
      const jwtContents = action.payload.jwtContents;
      const accessToken = action.payload.accessToken;
      return {
        ...state,
        agentJwt: jwtContents,
        accessToken: accessToken,
      };
    }

    case fromAgency.PRODUCER_CODE_SEARCH_SUCCESS: {
      const producerCodes = action.payload;

      return {
        ...state,
        producerCodes,
      };
    }
  }

  return state;
}

export const getAgencyState = (state: AgencyState) => state.agency;

export const getAgencyLoading = (state: AgencyState) => state.loading;

export const getAgencyLoaded = (state: AgencyState) => state.loaded;
