import { Injectable } from '@angular/core';
import { AccountResponse } from '@core/models/response/account-response.model';
import { AccountRequest } from '@core/models/request/account-request.model';
import { Observable } from 'rxjs';
import { HeadersUtils } from '@shared/utils/headers.utils';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { HttpParamsUtil } from '@shared/utils/http-params.util';

@Injectable({
  providedIn: 'root',
})
export class AccountAdapter extends BaseDsmAdapter {
  createAccount(request: AccountRequest): Observable<AccountResponse> {
    const RETRY_MS = 500;
    const url = `${this._appConfig.config.quoteAccountManagement}/accounts`;
    const headers = HeadersUtils.buildHeaderWithMessageId(
      this._appConfig.config.apiKey,
      this._appConfig.isTest() ? this._appConfig.config.targetEnv : '',
      request.userContext
    );

    const params = HttpParamsUtil.buildHttpParamsWithPartnerName(
      request.userContext
    );

    const newRequest: AccountRequest = {
      accountHolder: request.accountHolder,
      producer: request.producer,
    };
    return this._http
      .post<AccountResponse>(url, newRequest, {
        headers,
        params,
      })
      .pipe(this._retry.retryOn503(RETRY_MS));
  }
}
