import * as fromCondoInfo from '@core/store/actions/condo-propertyInfo.action';
import { CondoPropertyInfo } from '@core/models/metadata-picklist/condo/condo-propertyInfo.model';

export interface CondoPropertyInfoState {
  pickListValue: {
    data: any;
    loaded: boolean;
    loading: boolean;
  };
}

export const initialState: CondoPropertyInfo = {
  pickListValue: {
    data: [],
    loaded: false,
    loading: false,
  },
};

export function reducer(
  state = initialState,
  action: fromCondoInfo.CondoPropertyInfoActions
): CondoPropertyInfoState {
  switch (action.type) {
    case fromCondoInfo.condoPropertyInfoActionsTypes.LOAD_PICKLIST:
      const pickListValue = { ...state.pickListValue };
      pickListValue.data = action.payload;
      pickListValue.loaded = true;
      return { ...state, pickListValue };
  }

  return state;
}
