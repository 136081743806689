import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'mq-renovation-update-form',
  templateUrl: './renovation-update-form.component.html',
  styleUrls: ['./renovation-update-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RenovationUpdateFormComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
