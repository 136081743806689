import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressTrackerComponent } from './progress-tracker.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, NgbProgressbarModule],
  declarations: [ProgressTrackerComponent],
  exports: [ProgressTrackerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProgressTrackerModule {}
