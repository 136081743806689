import { Injectable } from '@angular/core';

export interface DriverLicenseValidationResponse {
  invalid?: true;
  invalidState?: true;
}

@Injectable({ providedIn: 'root' })
export class DriverLicenseService {
  /* https://ntsi.com/drivers-license-format/
   * Amended for NH: https://www.nh.gov/safety/divisions/dmv/news-events/2017/20171206-driver-license-number.htm
   */
  static readonly regexesByState = {
    AL: /^\d{7,8}$/,
    AK: /^\d{1,7}$/,
    AZ: /^[A-Za-z]\d{8}$|^\d{9}$/,
    AR: /^\d{8,9}$/,
    CA: /^[A-Za-z]\d{7}$/,
    CO: /^\d{9}$/,
    CT: /^\d{9}$/,
    DE: /^\d{1,7}$/,
    DC: /^\d{7}$|^\d{9}$|^[A-Za-z]{2}\d{8}$/,
    FL: /^[A-Za-z]\d{12}$/,
    GA: /^\d{9}$/,
    HI: /^(h\d{8})$|^(H\d{8})$|^\d{9}$/,
    ID: /^([A-Za-z]{2}\d{6}[A-Za-z])$|^\d{9}$/,
    IL: /^[A-Za-z]\d{11}$/,
    IN: /^\d{10}$/,
    IA: /^\d{3}[A-Za-z]{2}\d{4}$|^\d{9}$/,
    KS: /^\d{9}$|^(k\d{8})$|^(K\d{8})$|^[A-Za-z]\d{1}[A-Za-z]\d{1}[A-Za-z]\d{1}$/,
    KY: /^\d{9}$|^[A-Za-z]\d{8}$/,
    LA: /^\d{1,9}$/,
    ME: /^\d{7}$/,
    MD: /^[A-Za-z]\d{12}|^(md\d{11})$|^(MD\d{11})$/,
    MA: /^\d{9}$|^(s\d{8})$|^(sa\d{7})$|^(S\d{8})$|^(SA\d{7})$/,
    MI: /^[A-Za-z]\d{12}$/,
    MN: /^[A-Za-z]\d{12}$/,
    MS: /^\d{9}$/,
    MO: /^[A-Za-z]\d{15,16}$|^[A-Za-z]\d{5,9}$|^\d{9,10}$|^(\d{8}ma)$|^(\d{8}ac)$|^(\d{8}MA)$|^(\d{8}AC)$|^(\d{9}[A-Za-z])$|^([A-Za-z]\d{15}[A-Za-z])$|^(\d{3}[A-Za-z]\d{6})$/,
    MT: /^\d{9}$|^\w{3}\d{10}$|^([A-Za-z]\d\w\d{2}\w{3}\d)$|^([A-Za-z]\d\w\d{2}([- ]*){3}\d)$/,
    NE: /^[A-Za-z]\d{3,8}$/,
    NV: /^\d{10}$|^\d{12}$/,
    NH: /^(\d{2}[A-Za-z]{3}\d{4}[1-9])$|^(nhl\d{8})$|^(NHL\d{8})$/,
    NJ: /^[A-Za-z]\d{14}$/,
    NM: /^\d{8,9}$/,
    NY: /^\d{9}$/,
    NC: /^\d{1,12}$/,
    ND: /^[A-Za-z]{3}\d{6}$|^[A-Za-z]\d{8}$|^\d{9}$/,
    OH: /^[A-Za-z]{2}\d{6}$/,
    OK: /^\d{9}$|^([A-Za-z]\d{9})$/,
    OR: /^\d{1,7}$|^(0\d{8})$|^(00\d{9})$|^\w\d{6}$|^(0\w\d{6})$|^(00\w\d{6})$/,
    PA: /^\d{8}$/,
    RI: /^\d{7}$|^\d{8}$|^(V\d{6}$)|^(v\d{6}$)/,
    SC: /^\d{1,10}$/,
    SD: /^\d{6}$|^\d{8,9}$/,
    TN: /^\d{8,9}$/,
    TX: /^\d{8}$/,
    UT: /^\d{4,9}$|^(0\d{9})$/,
    VT: /^\d{8}$|^(\d{7}A)$|^(\d{7}a)$/,
    VA: /^\d{12}$|^\d{9}$|^[A-Za-z]\d{8}$/,
    WA: /^\w{12}$/,
    WV: /^[A-Za-z]\d{6}$|^(0\d{6})$|^(1x\d{5})$|^(xx\d{5})$|^(1X\d{5})$|^(XX\d{5})$/,
    WI: /^[A-Za-z]\d{13}$/,
    WY: /^\d{9}$/,
  };

  validate(
    licenseNumber: string,
    state: string
  ): DriverLicenseValidationResponse {
    const value = this.normalizeLicense(licenseNumber);

    if (value.startsWith('*')) {
      // It's masked, assume it's ok.
      return null;
    }

    const regex = DriverLicenseService.regexesByState[state];
    if (!regex) {
      return { invalidState: true };
    }
    return value.match(regex) ? null : { invalid: true };
  }

  normalizeLicense(input: string): string {
    return input.replace(/[ -]/g, '');
  }

  stateLicensesAreUnique(state: string): boolean {
    if (state === 'IL') {
      return false;
    }
    return true;
  }

  // Imperfect but usually correct, true if the license number could be made valid by appending something.
  // Caller must treat the license as invalid either way, but this may inform messaging.
  licenseNumberIsValidButIncomplete(
    licenseNumber: string,
    state: string
  ): boolean {
    const OBVIOUSLY_SHORT = 3;
    const OBVIOUSLY_LONG = 15;
    if (licenseNumber.length <= OBVIOUSLY_SHORT) {
      return true;
    }
    if (licenseNumber.length >= OBVIOUSLY_LONG) {
      return false;
    }
    const regex = DriverLicenseService.regexesByState[state];
    if (!regex) {
      return false;
    }
    while (licenseNumber.length < OBVIOUSLY_LONG) {
      licenseNumber += '1';
      if (licenseNumber.match(regex)) {
        return true;
      }
    }
    return false;
  }
}
