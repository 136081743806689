import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { SortDirection } from '@core/models/sort/sort.model';
import { SearchEntity } from '@core/models/entities/search.entity';
import { tap, debounceTime, switchMap, delay, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SearchService } from '@core/services/search.service';
import { formatDate } from '@angular/common';

interface SearchResult {
  quotes: SearchEntity[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
}

const compare = (v1, v2) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

const sort = (
  quotes: SearchEntity[],
  column: string,
  direction: string
): SearchEntity[] => {
  if (direction === '') {
    return quotes;
  } else {
    return [...quotes].sort((a, b) => {
      if (column.indexOf('.') > -1) {
        const keys = column.split('.');
        const res = compare(
          a[keys[0]][keys[1]] ? a[keys[0]][keys[1]] : '',
          b[keys[0]][keys[1]] ? b[keys[0]][keys[1]] : ''
        );
        return direction === 'asc' ? res : -res;
      } else {
        const res = compare(
          a[column] ? a[column] : '',
          b[column] ? b[column] : ''
        );
        return direction === 'asc' ? res : -res;
      }
    });
  }
};

const matches = (quote: SearchEntity, term: string) => {
  if (quote.phoneNumber) {
    return (
      quote.firstName.toLowerCase().includes(term.toLowerCase()) ||
      quote.lastName.toLowerCase().includes(term.toLowerCase()) ||
      quote.quoteId.includes(term) ||
      quote.lineOfBusiness.toLowerCase().includes(term.toLowerCase()) ||
      quote.address.postalCode.includes(term) ||
      formatDate(quote.creationTime, 'short', 'en-US').includes(term) ||
      quote.phoneNumber.includes(term)
    );
  } else {
    return (
      quote.firstName.toLowerCase().includes(term.toLowerCase()) ||
      quote.lastName.toLowerCase().includes(term.toLowerCase()) ||
      quote.quoteId.includes(term) ||
      quote.lineOfBusiness.toLowerCase().includes(term.toLowerCase()) ||
      quote.address.postalCode.includes(term) ||
      formatDate(quote.creationTime, 'short', 'en-US').includes(term)
    );
  }
};

@Injectable({ providedIn: 'root' })
export class SearchTableUtils {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _quotes$ = new BehaviorSubject<SearchEntity[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 6,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
  };

  constructor(private _searchService: SearchService) {
    const DEBOUNCE_TIME = 200;
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(DEBOUNCE_TIME),
        switchMap(() => this._search()),
        delay(DEBOUNCE_TIME),
        tap(() => this._loading$.next(false))
      )
      .subscribe(result => {
        this._quotes$.next(result.quotes);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get quotes$(): Observable<SearchEntity[]> {
    return this._quotes$.asObservable();
  }
  get total$(): Observable<number> {
    return this._total$.asObservable();
  }
  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }
  get page(): number {
    return this._state.page;
  }
  set page(page: number) {
    this._set({ page });
  }
  get pageSize(): number {
    return this._state.pageSize;
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  get searchTerm(): string {
    return this._state.searchTerm;
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: string) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }
  private _set(patch: Partial<State>): void {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;

    return this._searchService.getAllSearchQuotes().pipe(
      map(searchQuotes => {
        // 1. sort
        let quotes = sort(searchQuotes, sortColumn, sortDirection);

        // 2. filter
        quotes = quotes.filter(quote => matches(quote, searchTerm));
        const total = quotes.length;

        // 3. paginate
        quotes = quotes.slice(
          (page - 1) * pageSize,
          (page - 1) * pageSize + pageSize
        );

        return { quotes, total };
      })
    );
  }
}
