import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';

import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromConsentValues from '@core/store/selectors/consent-values.selector';
import * as fromSession from '@core/store/selectors/session.selector';
import * as fromPolicyHolder from '@core/store/selectors/policyholder.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import * as fromAgency from '@core/store/selectors/agency.selector';

import { DateUtils } from '@shared/utils/date.utils';
import { DigitalIdCardsTextRequest } from '@core/models/digital-id-cards/digital-id-cards-text.model';
import { ProducerUtils } from '@shared/utils/producer.utils';

export const getDigitalIdCardsTextState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.digitalIdCardsText
);

export const getDigitalIDCardsTextLoaded = createSelector(
  getDigitalIdCardsTextState,
  state => state.loaded
);

export const buildDigitalIdCardsTextRequest = (productId: string) =>
  createSelector(
    fromProducts.getIssuedProduct(productId),
    fromTokens.getTokenByProduct(productId),
    fromConsentValues.getAutoIdCardsTextConsent,
    fromSession.getSessionId,
    fromPolicyHolder.getPrimaryNamedInsured,
    fromPolicyAddress.selectAllPolicyAddresses,
    fromAgency.getAgency,
    (
      product,
      token,
      consentValue,
      sessionId,
      pni,
      policyAddressEntities,
      agency
    ) => {
      const policyAddress = policyAddressEntities[pni.addressId];
      const producerCode = ProducerUtils.getQuoteProducerCode(
        agency,
        policyAddress.state
      );
      const suffix = pni.person.suffix ? ` ${pni.person.suffix}` : '';
      const middleName = pni.person.middleName
        ? ` ${pni.person.middleName}`
        : '';
      const fullName = `${pni.person.lastName}${suffix}, ${pni.person.firstName}${middleName}`;
      const daysInFuture = 5;
      return {
        body: {
          submissionNumber: product.quoteId,
          policyHolderId: pni.policyHolderIds[product.id],
          policyNumber: product.policyNumber,
          effectiveDate: product.effectiveDate,
          zipcode: policyAddress.postalCode.split('-')[0],
          lastName: pni.person.lastName,
          expiry: DateUtils.getXDaysInFuture(daysInFuture),
          mobileNumber: consentValue.mobileNumber,
          producerCode,
          producerCodeSource:
            agency && agency.agencyChannel === 'IA' ? 'AMF' : 'PDS',
          fullName,
        } as DigitalIdCardsTextRequest,
        accessToken: token.accessToken,
        sessionId,
      };
    }
  );
