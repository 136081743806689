import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';

import { PreBindDocumentsAcknowledgement } from '@core/models/entities/pre-bind-document.entity';
import { Observable } from 'rxjs';
import { ConnectedCarConsent } from '@core/models/private-label/private-label.model';
import { AutoIdCardsTextConsent } from '@core/models/digital-id-cards/digital-id-cards-text.model';
import { SmartrideInstantConsent } from '@core/models/private-label/private-label.model';

@Injectable()
export class ConsentValuesDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  updatePrebindDocumentsAcknowledgement(
    ack: PreBindDocumentsAcknowledgement
  ): void {
    this._store.dispatch(
      new fromActions.UpdatePreBindDocumentsAcknowledgement(ack)
    );
  }

  updateConnectedCarConsent(consent: ConnectedCarConsent): void {
    this._store.dispatch(new fromActions.UpdateConnectedCarConsent(consent));
  }

  updateSmartrideInstantConsent(consent: SmartrideInstantConsent): void {
    this._store.dispatch(
      new fromActions.UpdateSmartrideInstantConsent(consent)
    );
  }

  updateAutoIdCardsTextConsent(consent: AutoIdCardsTextConsent): void {
    this._store.dispatch(new fromActions.UpdateAutoIDCardsTextConsent(consent));
  }

  getPreBindDocumentsAcknowledgement(): Observable<PreBindDocumentsAcknowledgement> {
    return this._store.select(fromSelectors.getPreBindDocumentsAcknowledgement);
  }

  getConnectedCarConsent(): Observable<ConnectedCarConsent> {
    return this._store.select(fromSelectors.getConnectedCarConsent);
  }

  getSmartrideInstantConsent(): Observable<SmartrideInstantConsent> {
    return this._store.select(fromSelectors.getSmartrideInstantConsent);
  }

  getAutoIdCardsTextConsent(): Observable<AutoIdCardsTextConsent> {
    return this._store.select(fromSelectors.getAutoIdCardsTextConsent);
  }

  getConsentValuesLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getConsentValuesLoaded);
  }

  hasAutoIdCardsTextConsent(): Observable<boolean> {
    return this._store.select(fromSelectors.hasAutoIdCardsTextConsent);
  }
}
