import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';

export const getHomeownersState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.homeowners
);

export const getHomeowners = createSelector(
  getHomeownersState,
  state => state.homeowners
);

export const hasHomeMortgage = createSelector(
  getHomeowners,
  home => home.hasMortgage
);

export const getOfferingType = createSelector(
  getHomeownersState,
  type => type.offeringType
);
