/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';

export const getAllSearchQuotes = () =>
  createSelector(fromFeature.getAppState, (state: fromFeature.AppState) =>
    Object.values(state.search.entities)
  );

export const getSearchQuotesError = () =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) => state.search.error
  );
