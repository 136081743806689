import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@quote/store';
import * as fromActions from '@quote/store/actions';
import * as fromSelectors from '@quote/store/selectors';
import * as fromCoreActions from '@core/store/actions';

import {
  NavigationPath,
  NavigationItem,
} from '@core/models/navigation/navigation.model';
import { NavigationDirection } from '@quote/store/reducers/navigation.reducer';

@Injectable({ providedIn: 'root' })
export class NavigationDao {
  constructor(private _store: Store<fromStore.QuotingState>) {}

  public buildBaseNavigationPath(): Observable<NavigationPath> {
    const path = new NavigationPath();

    const gettingStarted = new NavigationItem();
    gettingStarted.pages = ['getting-started'];
    gettingStarted.url = '/multi-quote/getting-started';
    gettingStarted.enabled = true;
    gettingStarted.title = 'Bundled MultiQuote';

    const moreDetails = new NavigationItem();
    moreDetails.pages = ['more-details'];
    moreDetails.url = '/multi-quote/more-details';
    moreDetails.enabled = true;
    moreDetails.title = 'Bundled MultiQuote';

    const propertyInfo = new NavigationItem();
    propertyInfo.pages = ['Renters', 'Homeowners', 'Condo'];
    propertyInfo.url = '/multi-quote/property-info';
    propertyInfo.enabled = false;
    propertyInfo.title = 'Property Details';

    const vehicles = new NavigationItem();
    vehicles.pages = ['Auto', 'Powersports'];
    vehicles.url = '/multi-quote/vehicles';
    vehicles.enabled = false;
    vehicles.title = 'Vehicles';

    const renters = new NavigationItem();
    renters.pages = ['Renters'];
    renters.url = '/multi-quote/renters';
    renters.enabled = false;
    renters.title = 'Property Info';

    const additionalDrivers = new NavigationItem();
    additionalDrivers.pages = [
      'Auto',
      'Powersports',
      'Homeowners',
      'Condo',
      'Renters',
    ];
    additionalDrivers.url = '/multi-quote/people';
    additionalDrivers.enabled = false;
    additionalDrivers.title = 'Drivers';

    /* Match Driver And Vehicle Assignment Route Const */
    const matchDriverVehicle = new NavigationItem();
    matchDriverVehicle.pages = ['MatchDriver'];
    matchDriverVehicle.url = '/multi-quote/match-driver-vehicle';
    matchDriverVehicle.enabled = false;
    matchDriverVehicle.title = 'Match Driver And Vehicle';

    const pet = new NavigationItem();
    pet.pages = ['Pet'];
    pet.url = '/multi-quote/pets';
    pet.enabled = false;
    pet.title = 'Pets';

    const life = new NavigationItem();
    life.pages = ['Life'];
    life.url = '/multi-quote/life';
    life.enabled = false;
    life.title = 'Life';

    const coverages = new NavigationItem();
    coverages.pages = ['coverages'];
    coverages.url = '/multi-quote/coverages';
    coverages.enabled = true;
    coverages.title = 'Bundled MultiQuote';

    path.items = [
      gettingStarted,
      moreDetails,
      propertyInfo,
      life,
      vehicles,
      // renters,
      additionalDrivers,
      matchDriverVehicle,
      pet,
      coverages,
    ];
    path.currentIndex = 0;

    return of(path);
  }

  loadNavigation(): void {
    this._store.dispatch(new fromActions.LoadRoutes());
  }

  getBasePath(): Observable<NavigationPath> {
    return this._store.select(fromSelectors.getNavigationBasePath);
  }

  getCurrentPath(): Observable<NavigationPath> {
    return this._store.select(fromSelectors.getNavigationCurrentPath);
  }

  updateNavigationPath(navigationPath: NavigationPath): void {
    this._store.dispatch(new fromActions.UpdateRoutePath(navigationPath));
  }

  getNavigationLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getNavigationLoading);
  }

  getNavigationLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getNavigationLoaded);
  }

  navigateForward(navigationPath: NavigationPath): void {
    this._store.dispatch(new fromActions.NavigateForward(navigationPath));
  }

  navigateBack(navigationPath: NavigationPath): void {
    this._store.dispatch(new fromActions.NavigateBack(navigationPath));
  }

  forceNavigationIndex(path: string): void {
    this._store.dispatch(new fromActions.ForceNavigationIndex(path));
  }

  getNavigationDirection(): Observable<NavigationDirection> {
    return this._store.select(fromSelectors.getNavigationDirection);
  }

  go(path: any) {
    return this._store.dispatch(new fromCoreActions.Go(path));
  }

  skipCurrentPageNavigation(url: string): void {
    this._store.dispatch(new fromActions.SkipCurrentPageNavigation(url));
  }

  skipNextPageNavigation(url: string): void {
    this._store.dispatch(new fromActions.SkipNextPageNavigation(url));
  }
}
