import { Injectable } from '@angular/core';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import * as fromStore from '@core/store';
import { Observable } from 'rxjs';
import { PremiumEntity } from '@core/models/entities/premium.entity';
import { Store } from '@ngrx/store';
import { TermAmount } from '@core/models/entities/coverage.entity';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class PremiumDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getAllPremiums(): Observable<PremiumEntity[]> {
    return this._store.select(fromSelectors.selectAllPremiums);
  }

  getPremiumLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getPremiumLoaded);
  }

  getPremiumLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getPremiumLoading);
  }

  updatePremium(productid: string, total: TermAmount): void {
    this._store.dispatch(
      new fromActions.UpdatePremium({
        productId: productid,
        total: total,
      })
    );
    if (productid === ProductTypes.TERMLIFE) {
      this._store.dispatch(new fromActions.LoadQuoteLetter());
    }
  }
}
