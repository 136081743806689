<ng-container class="row">
  <div class="col-12">
    <h1 class="heading-h4">
      The {{ selectedProduct?.name }} quote includes these discounts:
    </h1>
    <ul class="row pb-5 discount-list">
      <li class="col-4" *ngFor="let discount of discounts">
        {{ discount.description }}
      </li>
    </ul>
  </div>
  <!-- start policy level Accept Both Forgiveness -->
  <div class="col-12" *ngIf="showSmartRideAfmvfNotice">
    <ngb-alert
      type="info"
      [dismissible]="false"
      class="alert-display nw-container"
    >
      <a class="icon info"></a>
      <span>
        Accident Forgiveness and Minor Violation Forgiveness have been selected
        from the Additional Policy section below, to be eligible for Smartride.
      </span>
    </ngb-alert>
  </div>
  <div *ngIf="discounts && discounts.length > 0">
    <div class="col-12 d-flex flex-wrap">
      <inline-help
        [fieldId]="selectedProduct.id + displayType"
        [labelInnerHTML]="discountsAvailabilitylabelText"
        [helpTextInnerHTML]="getAllAvailableDiscounts(selectedProduct?.name)"
      ></inline-help>
    </div>
  </div>
</ng-container>
<!-- end policy level Accept Both Forgiveness -->
