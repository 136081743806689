import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mq-address-long-input',
  templateUrl: './address-long-input.component.html',
  styleUrls: ['./address-long-input.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressLongInputComponent implements OnInit {
  @Input()
  form: UntypedFormGroup;

  @Input()
  readOnly: boolean;

  constructor() {}

  ngOnInit(): void {}

  get address() {
    return this.form.get('address');
  }

  get unitNumber() {
    return this.form.get('unitNumber');
  }

  get city() {
    return this.form.get('city');
  }

  get state() {
    return this.form.get('state');
  }

  get zip() {
    return this.form.get('zip');
  }
}
