import { AdditionalInterestResponse } from '@core/models/auto/additional-interest/additional-interest-response.model';
import { AdditionalInterestEntity } from '@core/models/entities/additional-interest.entity';
import { AdditionalInterestRequest } from '@core/models/auto/additional-interest/additional-interest-request.model';
import { LendersModel } from '@core/models/lenders/lender.model';
import { AddressEntity } from '@core/models/entities/address.entity';
import { DateUtils } from '../date.utils';

export class AdditionalInterestBuilder {
  static buildAdditionalInterestEntityFromResponse(
    response: AdditionalInterestResponse,
    request: AdditionalInterestRequest
  ): AdditionalInterestEntity {
    const entity: AdditionalInterestEntity = {
      ...response,
      productId: request.productId,
      vehicleId: request.additionalInterest.vehicleId,
    };

    return entity;
  }

  static buildAdditionalInterestLienEntityFromModel(
    model: LendersModel,
    interest: AdditionalInterestEntity
  ): AdditionalInterestEntity {
    const loan = model.loan;
    const address = model.loan.standardizedAddresses.standardizedAddresses[0];

    const addressEntity: AddressEntity = {
      addressLine1: address.street,
      addressLine2: address.unitNumber,
      city: address.city,
      state: address.state,
      postalCode: address.zipCode,
      country: 'US',
      addressId: interest.address ? interest.address.addressId : null,
    };

    const entity: AdditionalInterestEntity = {
      additionalInterestId: interest.additionalInterestId,
      additionalInterestType: 'LIEN',
      companyName: loan.name,
      expirationDate: `${loan.expYear}-${
        loan.expMonth
      }-${DateUtils.getDaysInMonth(
        Number(loan.expMonth),
        Number(loan.expYear)
      )}`,
      isCertRequired: true,
      vehicleId: interest.vehicleId,
      productId: interest.productId,
      address: addressEntity,
    };

    return entity;
  }

  static buildAdditionalInterestLeaseEntityFromModel(
    model: LendersModel,
    interest: AdditionalInterestEntity
  ): AdditionalInterestEntity {
    const lease = model.lease;
    const address = model.lease.standardizedAddresses.standardizedAddresses[0];

    const addressEntity: AddressEntity = {
      addressLine1: address.street,
      addressLine2: address.unitNumber,
      city: address.city,
      state: address.state,
      postalCode: address.zipCode,
      country: 'US',
      addressId: interest.address ? interest.address.addressId : null,
    };

    const entity: AdditionalInterestEntity = {
      additionalInterestId: interest.additionalInterestId,
      additionalInterestType: 'LESSOR',
      companyName: lease.name,
      isCertRequired: true,
      vehicleId: interest.vehicleId,
      productId: interest.productId,
      address: addressEntity,
    };

    return entity;
  }
}
