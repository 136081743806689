
<div class="modal-body vw-50">
    <button
    type="button"
    class="close"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="modal.dismiss()"
     >
     <span aria-hidden="true">&times;</span>
    </button>
    <br>
    <div class="row">
        <div class="col-md-5">
            <div>
            <strong>How the device works.</strong>
            <p class="md-body-wrapper">We send you a device that plugs into a port below your vehicle's dashboard. The device measures mileage and driving trends.</p>
            </div>
        </div> 
        <div class="col-md-7 col-lg-5 ml-auto d-flex img-div-custom">
            <div>
            <img class="tm-icon img-fluid img-custom" src="assets/images/Plugin_Device.svg" alt="Device-Image" />
            </div>
        </div>
    </div>
</div>