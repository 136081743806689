import { Product } from '@core/models/products/product.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class DateUtils {
  static getCurrentDate(): Date {
    return new Date();
  }

  static getDayOfWeek(): number {
    return this.getCurrentDate().getDay();
  }

  static getDay(): number {
    return this.getCurrentDate().getDate();
  }
  static getMonth(): number {
    return this.getCurrentDate().getMonth();
  }

  static getYear(): number {
    return this.getCurrentDate().getFullYear();
  }

  static getToday(): number {
    return this.getCurrentDate().getDate();
  }

  static getFutureDate(date: string, num: number): Date {
    const future = new Date(date);
    future.setDate(future.getDate() + num);
    return future;
  }

  static getFurthestOutEffDateFromProducts(
    baseEffectiveDate: string,
    products: Product[]
  ): string {
    let furthestDate = baseEffectiveDate;
    if (products.length) {
      products.forEach(product => {
        if (
          product.effectiveDate &&
          new Date(furthestDate) < new Date(product.effectiveDate)
        ) {
          furthestDate = product.effectiveDate;
        }
      });
      return furthestDate;
    }
    return furthestDate;
  }

  static isWeekend(): boolean {
    const friday = 5;
    const saturday = 6;
    const sunday = 0;

    const today = this.getDayOfWeek();

    if (today === friday || today === saturday || today === sunday) {
      return true;
    }

    return false;
  }

  // i am not a proud man
  static buildCCYYMMDD(date: Date): string {
    // YY -- auto's is YYYY not CCYY
    let dateString = date.getFullYear().toString();

    // MM
    dateString += this.padZero((date.getMonth() + 1).toString());

    // DD
    dateString += this.padZero(date.getDate().toString());
    return dateString;
  }

  static buildPaymentCreditCardExpirationDate(
    year: string,
    month: string
  ): string {
    return `${year}${month}`;
  }

  static padZero(date: string): string {
    if (date.length < 2) {
      date = '0' + date;
    }
    return date;
  }

  static YearsArray(numYears: number): string[] {
    const years = [];
    let currentYear = new Date().getFullYear();

    for (let i = 0; i <= numYears; i++) {
      years.push((currentYear++).toString());
    }

    return years;
  }

  static MonthsArray(): string[] {
    const months = [];
    const monthsInYear = 12;
    for (let i = 1; i <= monthsInYear; i++) {
      months.push(this.padZero(i.toString()));
    }

    return months;
  }

  static DaysArray(): string[] {
    const days = [];
    const daysInMonth = 30;
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(this.padZero(i.toString()));
    }

    return days;
  }

  static setInitialPolicyEffectiveDate(date?): string {
    if (date) {
      let day = date.day.toString();
      let month = date.month.toString();
      const year = date.year.toString();
      if (day.length === 1) {
        day = '0' + day;
      }
      if (month.length === 1) {
        month = '0' + month;
      }
      return [year, month, day].join('-');
    } else {
      const now = this.getCurrentDate();
      let day = now.getDate().toString();
      let month = (now.getMonth() + 1).toString();
      const year = now.getFullYear().toString();
      if (day.length === 1) {
        day = '0' + day;
      }
      if (month.length === 1) {
        month = '0' + month;
      }
      return [month, day, year].join('/');
    }
  }

  static FormatNgbDate(ngbDate: NgbDateStruct): string {
    if (!ngbDate) {
      return null;
    }
    if (ngbDate && ngbDate.month && ngbDate.day && ngbDate.year) {
      return `${DateUtils.padZero(
        ngbDate.month.toString()
      )}/${DateUtils.padZero(ngbDate.day.toString())}/${ngbDate.year}`;
    }
    return null;
  }

  static UnFormatNgbDate(date: string): NgbDateStruct {
    if (!date) {
      return null;
    }
    const split = date.split('/');
    const ngbDate: NgbDateStruct = {
      day: +split[1],
      month: +split[0],
      year: +split[2],
    };

    return ngbDate;
  }

  static ngbDateFromYear(year: string): NgbDateStruct {
    const LOG10_10000 = 4; // tslint thinks this is better than just '4'
    if (!year || year.length < LOG10_10000) {
      return null;
    }
    const n = +year;
    return {
      day: 1,
      month: 1,
      year: n,
    };
  }

  static ngbDateFromMmYyyy(mmyyyy: string): NgbDateStruct {
    const TWO_PLUS_SEPARATOR_PLUS_FOUR = 7;
    if (!mmyyyy || mmyyyy.length < TWO_PLUS_SEPARATOR_PLUS_FOUR) {
      return null;
    }
    const split = mmyyyy.split('/');
    if (split.length !== 2) {
      return null;
    }
    return {
      day: 1,
      month: +split[0],
      year: +split[1],
    };
  }

  static formatDateToDSM(old: string): string {
    if (!old) {
      return null;
    }
    const split = old.split('/');

    return `${split[2]}-${split[0]}-${split[1]}`;
  }

  // Turns MMDDYYYY into YYYY-MM-DD
  static formatPlainDateToDSM(dateOfBirth: string): string {
    return (
      // eslint-disable-next-line no-magic-numbers
      dateOfBirth.slice(4) +
      '-' +
      // eslint-disable-next-line no-magic-numbers
      dateOfBirth.slice(0, 2) +
      '-' +
      // eslint-disable-next-line no-magic-numbers
      dateOfBirth.slice(2, 4)
    );
  }

  static formatDateToOld(date: string): string {
    if (!date) {
      return null;
    }

    const maskMatch = date.match(/^(\d{4})[*-]+$/);
    if (maskMatch) {
      return `**/**/${maskMatch[1]}`;
    }

    const split = date.split('T')[0].split('-');

    return `${split[1]}/${split[2]}/${split[0]}`;
  }

  static clearMaskedRetrieveDate(date: string): string {
    if (!date) {
      return null;
    }
    const maskMatch = date.match(/^(\d{4})[*-]+$/);
    if (maskMatch) {
      return ``;
    }
    const split = date.split('T')[0].split('-');
    return `${split[1]}/${split[2]}/${split[0]}`;
  }

  static januaryFirstIfMasked(date: string): string {
    if (date && date.match(/^\d{4}-\*\*-\*\*$/)) {
      return date.substr(0, 4) + '-01-01';
    }
    return date;
  }

  /**
   * receives date like MM/YYYY and transforms to YYYY-MM-01
   */
  static transformMonthYearSlashToYearMonthDaySlash(input: unknown): string {
    if (!input) {
      return '';
    }
    const stringInput = input as string;
    const split = stringInput.split('/');
    if (split.length !== 2) {
      return stringInput;
    }
    return split[1] + '-' + split[0] + '-01';
  }

  /**
   * receives date like YYYY-MM-01 and transforms to MM/YYYY
   */
  static transformYearMonthDayDashToMonthYearSlash(input: unknown): string {
    if (!input) {
      return '';
    }
    const stringInput = input as string;
    const split = stringInput.split('-');
    const THE_NUMBER_THREE_BUT_DENUDED_OF_MAGIC = 3; // To appease tslint.
    if (split.length !== THE_NUMBER_THREE_BUT_DENUDED_OF_MAGIC) {
      return stringInput;
    }
    return split[1] + '/' + split[0];
  }

  static expiryDateFromInterval(expires_in: string): Date {
    const MILLISECONDS_PER_SECOND = 1000;
    const durationSeconds = parseInt(expires_in, 10) || 0;
    const durationMilliseconds = durationSeconds * MILLISECONDS_PER_SECOND;
    return new Date(Date.now() + durationMilliseconds);
  }

  static getCurrentDateAsStr(): string {
    return this.getCurrentDate().toISOString().replace(/T.*$/, '');
  }

  static getTodayOrYesterdaysDateAsStr(): string {
    const TWELVE_HOURS = 43200000; // 1000 * 60 * 60 * 12
    const twelveHoursAgo = new Date(
      this.getCurrentDate().getTime() - TWELVE_HOURS
    );
    return twelveHoursAgo.toISOString().replace(/T.*$/, '');
  }

  static getDaysInMonth(month: number, year: number): string {
    return new Date(year, month, 0).getDate().toString();
  }

  static convertDSMDateStringToDate(dateString: string): Date {
    const [year, month, day] = dateString.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  static getXDaysInFuture(days: number): string {
    const dt = new Date();
    dt.setDate(dt.getDate() + days);
    let day = dt.getDate().toString();
    let month = (dt.getMonth() + 1).toString();
    const year = dt.getFullYear();
    const offset = dt.getTimezoneOffset();
    const minutesInHour = 60;
    const timeOffset =
      (offset < 0 ? '+' : '-') +
      DateUtils.padZero(Math.abs(offset / minutesInHour) + '') +
      DateUtils.padZero(Math.abs(offset % minutesInHour) + '');
    let hours = dt.getHours().toString();
    let minutes = dt.getMinutes().toString();
    let seconds = dt.getSeconds().toString();
    if (day.length === 1) {
      day = '0' + day;
    }
    if (month.length === 1) {
      month = '0' + month;
    }
    if (hours.length === 1) {
      hours = '0' + hours;
    }
    if (minutes.length === 1) {
      minutes = '0' + minutes;
    }
    if (seconds.length === 1) {
      seconds = '0' + seconds;
    }

    return (
      year +
      '-' +
      month +
      '-' +
      day +
      'T' +
      hours +
      ':' +
      minutes +
      ':' +
      seconds +
      timeOffset
    );
  }

  static compareNgbDates(a: NgbDateStruct, b: NgbDateStruct): number {
    if (a.year < b.year) {
      return -1;
    }
    if (a.year > b.year) {
      return 1;
    }
    if (a.month < b.month) {
      return -1;
    }
    if (a.month > b.month) {
      return 1;
    }
    if (a.day < b.day) {
      return -1;
    }
    if (a.day > b.day) {
      return 1;
    }
    return 0;
  }

  static todayOrLaterMMDDYYYY(input: string): string {
    const inBits = (input || '').split('/').map(s => +s);
    if (inBits.length === 3) {
      const now = new Date();
      const thisYear = now.getFullYear();
      if (inBits[2] > thisYear) {
        return input;
      }
      if (inBits[2] === thisYear) {
        const thisMonth = now.getMonth() + 1;
        if (inBits[0] > thisMonth) {
          return input;
        }
        if (inBits[0] === thisMonth) {
          const thisDay = now.getDate();
          if (inBits[1] >= thisDay) {
            return input;
          }
        }
      }
    }
    const now = new Date();
    return this.mmddyyyyFromDate(now);
  }

  static mmddyyyyFromDate(date: Date): string {
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    const year = date.getFullYear().toString();
    return `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year.padStart(
      4,
      '0'
    )}`;
  }

  static getMaxDate = datesArray => {
    const sorted = datesArray.slice().sort((a, b) => {
      return <any>new Date(a) - <any>new Date(b);
    });
    // Below gives you Max date from an array
    const maxDateValues = sorted.pop().split('-');
    return {
      year: maxDateValues[0],
      month: maxDateValues[1],
      day: maxDateValues[2],
    };
    // Below gives you Max date from an array
    // return sorted.shift();
  };
}
