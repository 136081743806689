import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { DiscountEntity } from '@core/models/entities/discount.entity';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { Product } from '@core/models/products/product.model';
import {
  DiscountsAvailability,
  DiscountsAvailabilityHelpText,
} from '@shared/constants/discounts-availability-constants';
import { PrivateLabelService } from '@core/services';
import { take } from 'rxjs/operators';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

@Component({
  selector: 'mq-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountsComponent implements OnInit {
  discountsAvailabilitylabelText: string;

  @Input() discounts: DiscountEntity[];
  @Input() smartride: EligibleDiscountEntity;
  @Input() showSmartRideAfmvfNotice: boolean;
  @Input() selectedProduct: Product;
  @Input() quoteState: string;
  @Input() agent: boolean;
  @Input() infractions: string[];
  @Input() contactNumber: string;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() displayType: string;

  @Output() discountToggled = new EventEmitter<EligibleDiscountEntity>();

  constructor(private _privatelabelservice: PrivateLabelService) {}

  ngOnInit(): void {
    this.discountsAvailabilitylabelText =
      DiscountsAvailabilityHelpText.labelInnerHTML;
    this._privatelabelservice
      .getContactNumber()
      .pipe(take(1))
      .subscribe(contactNumber => {
        this.contactNumber = contactNumber;
      });
  }

  onPillToggled(smartride: EligibleDiscountEntity, event) {
    this.discountToggled.emit({
      ...smartride,
      selectedOptionValue: event.value + '',
    });
  }

  getAllAvailableDiscounts(product): string {
    const isAgent = this.agent ? 'AGENT' : 'DIRECT';
    let discountHelpTextValue = '';
    DiscountsAvailability.filter(
      row =>
        row.product === product && row.availability.includes(this.quoteState)
    ).forEach(row => {
      discountHelpTextValue += row.helptext;
    });
    if (product === 'Auto') {
      discountHelpTextValue += DiscountsAvailabilityHelpText[isAgent];
    }
    return this.contactNumber
      ? discountHelpTextValue.replace('{phoneNumber}', this.contactNumber)
      : '';
  }
}
