import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  CoveredLocation,
  CoveredLocationUpdateStatus,
} from '@core/store/reducers/covered-location.reducer';
import { CoveredLocationDao } from '@core/dao/covered-location.dao';
import { switchMap, take, filter, pairwise, map } from 'rxjs/operators';
import { ProductTypes } from '@shared/constants/app-constants';
import { PolicyholderService } from './policyholder.service';
@Injectable({
  providedIn: 'root',
})
export class CoveredLocationService {
  DEFAULT_SQUARE_FOOTAGE = 2000;

  constructor(
    private _coveredLocationDao: CoveredLocationDao,
    private _policyholderService: PolicyholderService
  ) {}

  addCoveredLocation(coveredLocation: CoveredLocation): void {
    this._coveredLocationDao.addCoveredLocation(
      this.checkForGarages(coveredLocation)
    );
  }

  updateCoveredLocation(
    productId: string,
    coveredLocation: CoveredLocation
  ): void {
    this._coveredLocationDao.updateCoveredLocation(
      productId,
      this.checkForGarages(coveredLocation)
    );
  }

  addCleanCoveredLocation(coveredLocation: CoveredLocation): void {
    this._coveredLocationDao.addCleanCoveredLocation(
      this.checkForGarages(coveredLocation)
    );
  }

  getCoveredLocation(): Observable<CoveredLocation> {
    return this._coveredLocationDao.getCoveredLocation();
  }

  resetCoveredLocationLoaded(): void {
    this._coveredLocationDao.resetCoveredLocationLoaded();
  }

  getCoveredLocationLoaded(): Observable<boolean> {
    return this._coveredLocationDao.getCoveredLocationLoaded();
  }

  getCoveredLocationUpdateStatus(): Observable<CoveredLocationUpdateStatus> {
    return this._coveredLocationDao.getCoveredLocationUpdateStatus();
  }

  getMSBHitCoveredLocation(productId: string): void {
    this._coveredLocationDao.getMSBHitCoveredLocation(productId);
  }

  removeCoveredLocation(): void {
    this._coveredLocationDao.removeCoveredLocation();
  }

  getReconstructionCost(): Observable<number> {
    return this._coveredLocationDao.getReconstructionCost();
  }

  // This will emit only after the call is complete.
  updateNumberOfOccupants(productId: string): Observable<any> {
    if (
      productId === ProductTypes.HOMEOWNERS ||
      productId === ProductTypes.RENTERS ||
      productId === ProductTypes.CONDO
    ) {
      return this._policyholderService.countCallsInFlight().pipe(
        filter(callCount => callCount === 0),
        take(1),
        switchMap(() =>
          this._coveredLocationDao.getRequestIfNumberOfOccupantsChanged()
        ),
        take(1),
        switchMap(coveredLocation => {
          if (coveredLocation) {
            this.updateCoveredLocation(productId, coveredLocation);
            return this._coveredLocationDao.getCoveredLocationUpdating().pipe(
              pairwise(),
              filter(([previous, current]) => previous && !current),
              map(_ => null), // just for consistency
              take(1)
            );
          } else {
            return of(null);
          }
        })
      );
    } else {
      return of(null);
    }
  }

  checkForGarages(coveredLocation: CoveredLocation): CoveredLocation {
    if (
      coveredLocation.constructionInfo &&
      coveredLocation.constructionInfo.garages
    ) {
      return {
        ...coveredLocation,
        constructionInfo: {
          ...coveredLocation.constructionInfo,
          garages: coveredLocation.constructionInfo.garages.filter(
            garage =>
              garage.garageType !== 'Not Applicable' && garage.numberOfCars > 0
          ),
        },
      };
    } else {
      return coveredLocation;
    }
  }
}
