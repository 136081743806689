import { DriverVehiclePrefillResponseDriver } from '@core/models/driver-vehicle-prefill/driver-vehicle-prefill-response.model';
import {
  DriverEntity,
  PersonViewModel,
} from '@core/models/entities/driver.entity';
import {
  DriverRelationToPNI,
  PolicyholderTypes,
  DEFAULT_ID,
  ProductTypes,
  ModifierNames,
} from '@shared/constants/app-constants';
import { PersonEntity } from '@core/models/entities/person.entity';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { StringUtils } from '../string.utils';

export class DriverBuilder {
  static buildDriverEntitiesFromPrefillResponse(
    prefill: DriverVehiclePrefillResponseDriver[]
  ): PersonViewModel[] {
    if (!prefill) {
      return null;
    }
    const entities: PersonViewModel[] = prefill.map(driver => {
      const entity: PersonViewModel = {
        prefillId: driver.prefillId,
        person: {
          firstName: driver.firstName,
          middleName: driver.middleName ? driver.middleName.slice(0, 1) : null,
          lastName: driver.lastName,
          dateOfBirth: driver.dateOfBirth,
          gender: driver.genderCode,
          maritalStatus: driver.maritalstatus,
        },
        licenseNumber: driver.driversLicenseNumber,
        licenseState: driver.state,
        relationToPrimaryNamedInsured: DriverRelationToPNI.OTHER,
        policyHolderType: PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED,
        emailAddress: null,
        addressId: DEFAULT_ID,
      };

      return entity;
    });

    return entities;
  }

  static entityFromDriver(input): DriverEntity {
    const output: DriverEntity = {
      ...input,
      // Temp fix until DSM returns 'healthcarePlan' instead of 'healthCarePlan'
      healthcarePlan: input.healthCarePlan,
      productId: ProductTypes.AUTO,
    };
    if (input.person) {
      output.person = DriverBuilder.readPersonFromPolicyHolder(input.person);
    }
    if (input.eligibleDiscounts) {
      for (const discount of input.eligibleDiscounts) {
        if (discount.eligibleDiscountId === ModifierNames.GOOD_STUDENT) {
          output.goodStudent = discount.selected;
        }
      }
    }
    return output;
  }

  static entityFromPowersportsDriver(input): DriverEntity {
    const output: DriverEntity = {
      ...input,
      healthcarePlan: input.healthCarePlan,
      productId: ProductTypes.POWERSPORTS,
    };
    if (input.person) {
      output.person = DriverBuilder.readPersonFromPolicyHolder(input.person);
    }
    if (input.eligibleDiscounts) {
      for (const discount of input.eligibleDiscounts) {
        if (discount.eligibleDiscountId === ModifierNames.GOOD_STUDENT) {
          output.goodStudent = discount.selected;
        }
      }
    }
    return output;
  }

  static readPersonFromPolicyHolder(input): PersonEntity {
    if (input.person) {
      input = input.person;
    }
    return <PersonEntity>{
      firstName: input.firstName,
      middleName: input.middleName || input.middleInitial,
      lastName: input.lastName,
      suffix: input.suffix,
      gender: input.gender,
      maritalStatus: input.maritalStatus,
      driverOccupation: input.driverOccupation,
      infractionDesc: input.infractionDesc,
      dateOfBirth: input.dateOfBirth,
      yearsOfMotorcycleAndOffRoadExp: input.yearsOfMotorcycleAndOffRoadExp,
      hasMotorcycleEndorsement: input.hasMotorcycleEndorsement,
      endorsementDate: input.endorsementDate,
    };
  }

  static driverMatchesPolicyholder(driver, policyholder: PolicyholderEntity) {
    if (!driver.person || !policyholder.person) {
      return false;
    }
    if (
      !StringUtils.areEqual(
        driver.person.firstName,
        policyholder.person.firstName
      ) ||
      !StringUtils.areEqual(
        driver.person.middleName,
        policyholder.person.middleName
      ) ||
      !StringUtils.areEqual(
        driver.person.lastName,
        policyholder.person.lastName
      ) ||
      !StringUtils.areEqual(driver.person.gender, policyholder.person.gender)
    ) {
      return false;
    }
    return true;
  }
}
