import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import * as fromLocationExposures from '@core/store/reducers/location-exposure.reducer';

import { DEFAULT_ID } from '@shared/constants/app-constants';
import { LocationExposureRequest } from '@core/models/umbrella/location-exposure-request.model';
import { LocationExposureEntity } from '@core/models/entities/location-exposure.entity';

export const getLocationExposureState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.locationExposures
);

export const {
  selectAll: selectAllLocationExposures,
  selectEntities: selectLocationExposureEntities,
} = fromLocationExposures.adapter.getSelectors(getLocationExposureState);

export const getLocationExposureLoaded = createSelector(
  getLocationExposureState,
  state => state.loaded
);

export const buildLocationExposureRequest = (
  location: LocationExposureEntity
) =>
  createSelector(
    fromProducts.getProduct(location.productId),
    fromTokens.getAccessToken(location.productId),
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    (product, accessToken, address) => {
      return {
        productId: location.productId,
        quoteId: product.quoteId,
        sessionId: product.sessionId,
        accessToken,
        locationExposure: {
          ...location,
          locationAddress: {
            ...address,
          },
        },
      } as LocationExposureRequest;
    }
  );
