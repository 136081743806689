import * as fromTokens from '@core/store/actions/token.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProductTypes } from '@shared/constants/app-constants';

export interface AccessToken {
  accessToken: string;
  expiresIn: string;
  productId: string;
  expiryDate: Date;
}
export interface TokenState extends EntityState<AccessToken> {
  autoTokenLoading: boolean;
  autoTokenLoaded: boolean;
  homeOwnersTokenLoaded: boolean;
  homeOwnersTokenLoading: boolean;
  condoTokenLoaded: boolean;
  condoTokenLoading: boolean;
  umbrellaTokenLoading: boolean;
  umbrellaTokenLoaded: boolean;
  powersportsTokenLoading: boolean;
  powersportsTokenLoaded: boolean;
}

export const adapter: EntityAdapter<AccessToken> = createEntityAdapter({
  selectId: token => token.productId,
});

export function reducer(
  state = {
    ...adapter.getInitialState(),
    autoTokenLoading: false,
    autoTokenLoaded: false,
    homeOwnersTokenLoading: false,
    homeOwnersTokenLoaded: false,
    condoTokenLoading: false,
    condoTokenLoaded: false,
    umbrellaTokenLoading: false,
    umbrellaTokenLoaded: false,
    powersportsTokenLoading: false,
    powersportsTokenLoaded: false,
  },
  action: fromTokens.TokenActions
): TokenState {
  switch (action.type) {
    case fromTokens.TokenActionTypes.GENERATE_ACCESS_TOKEN: {
      return action.payload === ProductTypes.UMBRELLA
        ? {
            ...state,
            umbrellaTokenLoading: true,
            umbrellaTokenLoaded: false,
          }
        : state;
    }
    case fromTokens.TokenActionTypes.SET_ACCESS_TOKEN:
    case fromTokens.TokenActionTypes.GENERATE_ACCESS_TOKEN_SUCCESS: {
      return action.payload.productId === ProductTypes.UMBRELLA
        ? adapter.upsertOne(action.payload, {
            ...state,
            umbrellaTokenLoading: false,
            umbrellaTokenLoaded: true,
          })
        : adapter.upsertOne(action.payload, state);
    }
    case fromTokens.TokenActionTypes.GENERATE_ACCESS_TOKEN_FAIL: {
      return state;
    }
    case fromTokens.TokenActionTypes.DROP_ACCESS_TOKEN: {
      return adapter.removeOne(action.payload, state);
    }
    case fromTokens.TokenActionTypes.INSERT_ACCESS_TOKEN: {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return adapter.addMany(
        [
          {
            accessToken: action.payload,
            expiresIn: '4000',
            productId: '1',
            expiryDate: tomorrow,
          },
          {
            accessToken: action.payload,
            expiresIn: '4000',
            productId: '2',
            expiryDate: tomorrow,
          },
          {
            accessToken: action.payload,
            expiresIn: '4000',
            productId: '3',
            expiryDate: tomorrow,
          },
          {
            accessToken: action.payload,
            expiresIn: '4000',
            productId: '4',
            expiryDate: tomorrow,
          },
          {
            accessToken: action.payload,
            expiresIn: '4000',
            productId: '5',
            expiryDate: tomorrow,
          },
        ],
        state
      );
    }
    default: {
      return state;
    }
  }
}
