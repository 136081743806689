import { ConnectedCarComponent } from '@help-content/components/connected-car/connected-car.component';
import { SmartRideComponent } from './smart-ride/smart-ride.component';
import { VehicleSmartRideComponent } from './vehicle-smart-ride/vehicle-smart-ride.component';
import { SelfReportedComponent } from './self-reported/self-reported.component';
import { UnderwritingDisclaimerComponent } from './underwriting-disclaimer/underwriting-disclaimer.component';
import { DogBreedsModalComponent } from './dog-breeds-modal/dog-breeds-modal.component';
import { UmbrellaComponent } from './umbrella/umbrella.component';
import { UmbrellaOccupationsComponent } from './umbrella-occupations/umbrella-occupations.component';
import { CreditNoticeComponent } from './credit-notice/credit-notice.component';
import { ShareDrivingDataComponent } from './share-driving-data/share-driving-data.component';
import { SmartrideInstantConsentHelpContentComponent } from './smartride-instant-consent-help-content/smartride-instant-consent-help-content.component';
import { SmartrideInstantConsentComponent } from './smartride-instant-consent/smartride-instant-consent.component';

export const components: any[] = [
  SmartRideComponent,
  VehicleSmartRideComponent,
  SelfReportedComponent,
  ConnectedCarComponent,
  UnderwritingDisclaimerComponent,
  DogBreedsModalComponent,
  UmbrellaComponent,
  UmbrellaOccupationsComponent,
  CreditNoticeComponent,
  ShareDrivingDataComponent,
  SmartrideInstantConsentHelpContentComponent,
  SmartrideInstantConsentComponent,
];

export * from './smart-ride/smart-ride.component';
export * from './vehicle-smart-ride/vehicle-smart-ride.component';
export * from './self-reported/self-reported.component';
export * from './underwriting-disclaimer/underwriting-disclaimer.component';
export * from './dog-breeds-modal/dog-breeds-modal.component';
export * from './umbrella/umbrella.component';
export * from './umbrella-occupations/umbrella-occupations.component';
export * from './credit-notice/credit-notice.component';
export * from './share-driving-data/share-driving-data.component';
export * from './smartride-instant-consent-help-content/smartride-instant-consent-help-content.component';
export * from './smartride-instant-consent/smartride-instant-consent.component';
