import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { InitiateNewBusinessRequest } from '@core/models/auto/quotes/initiate-new-business-request.model';
import { InitiateNewBusinessResponse } from '@core/models/auto/quotes/initiate-new-business-response.model';
import {
  UpdateQuoteRequest,
  QuoteRequest,
  TermLifeQuoteRequest,
} from '@core/models/auto/quotes/update-quote-request.model';
import { UpdateQuoteResponse } from '@core/models/auto/quotes/update-quote-response.model';
import { UpdatePolicyholderRequest } from '@core/models/auto/quotes/update-policyholder-request.model';
import { PolicyholderResponse } from '@core/models/response/policyholder-response.model';
import { HeadersUtils } from '@shared/utils/headers.utils';
import {
  UpdateCurrentCarrierRequest,
  GetCurrentCarrierRequest,
  UpdatePowersportsCurrentCarrierRequest,
  GetPowersportsCurrentCarrierRequest,
} from '@core/models/auto/quotes/update-current-carrier-request.model';
import { CurrentCarrier } from '@core/store/reducers/current-carrier.reducer';
import {
  UpdateVehiclesRequest,
  GetVehiclesRequest,
} from '@core/models/auto/quotes/update-vehicles-request.model';
import {
  QuoteRatingResponseModel,
  TermLifeQuoteRatingResponseModel,
} from '@core/models/auto/quotes/quote-rating-response.model';
import {
  UpdateCoverageRequestModel,
  CoverageChangeResponseModel,
} from '@core/models/auto/quotes/update-coverages-request.model';
import { TitleCasePipe } from '@angular/common';
import {
  ProductTypes,
  PET_CROSS_SELL_EMAIL_TEMPLATE,
} from '@shared/constants/app-constants';
import { SequencerService } from '@core/services/sequencer.service';
import { TokenService } from '@core/services/token.service';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { RetrieveCoverageRequestModel } from '@core/models/auto/quotes/retrieve-coverage-request.model';
import { RetrieveCoverageResponseModel } from '@core/models/auto/quotes/rertrieve-coverage-response.model';
import { ProducerUtils } from '@shared/utils/producer.utils';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { ModifyContactPointResponse } from '@core/models/modify-contact-point/modify-contact-point-response.model';
import { ModifyContactPointRequest } from '@core/models/modify-contact-point/modify-contact-point-request.model';
import { StringUtils } from '@shared/utils/string.utils';
import { HttpParamsUtil } from '@shared/utils/http-params.util';
import { InitiateNewBusinessUmbrellaRequest } from '@core/models/umbrella/initiate-new-business-umbrella.request';
import { RetryOn503Operator } from '@shared/operators/retryOn-503.operator';
import { retryOnStatus0 } from '@shared/operators/retry-on-Status0.operator';

import { TermLifeEmailRequest } from '@core/models/termlife/termlife.entity';
import { AppConfigService } from '@core/services/app-config.service';
import { LoggingService } from '@core/services/logging.service';
import { ProductsService } from '@core/services/products.service';
import { UserContextService } from '@core/services/user-context.service';
import { PowersportsCurrentCarrier } from '@core/store/reducers/powersports-current-carrier.reducer';

@Injectable({
  providedIn: 'root',
})
export class QuoteAdapter extends BaseDsmAdapter {
  constructor(
    // Is there a better way to inherit from a class with dependencies?
    private _http2: HttpClient,
    private _appConfig2: AppConfigService,
    private _titleCasePipe: TitleCasePipe,
    private _loggingService: LoggingService,
    private _sequencerService2: SequencerService,
    private _tokenService2: TokenService,
    private _productsService2: ProductsService,
    _userContextService: UserContextService,
    _retryOn503: RetryOn503Operator
  ) {
    super(
      _http2,
      _appConfig2,
      _sequencerService2,
      _tokenService2,
      _productsService2,
      _userContextService,
      _retryOn503
    );
  }

  public RETRY_MS = 500;

  /* We don't have an access token yet, so this call does not use the shared adapter plumbing.
   */
  initiateNewBusiness(
    request: InitiateNewBusinessRequest
  ): Observable<InitiateNewBusinessResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes`;
    let headers = HeadersUtils.buildHeaderWithMessageId(
      this._appConfig.config.apiKey,
      this._appConfig.isTest() ? this._appConfig.config.targetEnv : '',
      request.userContext
    );

    if (request.accessToken) {
      headers = headers.set('Authorization', `Bearer ${request.accessToken}`);
    }

    if (request.prepopulatedInsuredId) {
      headers = headers.set(
        'Prepopulated-Insured-Id',
        request.prepopulatedInsuredId
      );
      headers = headers.set('Policy-Holder-Id', '1');
    }

    const params = HttpParamsUtil.buildHttpParamsWithPartnerName(
      request.userContext
    );

    if (
      this._appConfig.isTest() &&
      this._appConfig.config.targetEnvPCType === 'TrueNorth'
    ) {
      request = {
        ...request,
        producer: ProducerUtils.getTrueNorthProducer(
          request.policyAddress.state
        ),
      };
    }

    if (request.partialQuoteId) {
      headers = headers.set('Partial-Quote-Id', request.partialQuoteId);
    }

    return this._sequencerService.performOperation('products', () => {
      return this._http
        .post<InitiateNewBusinessResponse>(url, request, {
          headers,
          params,
        })
        .pipe(this._retry.retryOn503(this.RETRY_MS));
    });
  }

  updateQuote(request: UpdateQuoteRequest): Observable<UpdateQuoteResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }`;
    return this.patch<UpdateQuoteResponse>(url, request.body, {
      productId: request.productId,
    }).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }

  addPolicyholder(
    request: UpdatePolicyholderRequest
  ): Observable<PolicyholderResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/policy-holders`;

    return this.post<PolicyholderResponse>(url, request.body, {
      productId: request.productId,
      partialQuoteId: request?.body?.policyHolder?.partialQuoteId || null,
    }).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }

  updatePolicyholder(
    request: UpdatePolicyholderRequest
  ): Observable<PolicyholderResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/policy-holders/${request.body.policyHolderId}`;

    return this.put<PolicyholderResponse>(
      url,
      {
        ...request.body,
      },
      {
        productId: request.productId,
      }
    ).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }

  deletePolicyholder(
    request: UpdatePolicyholderRequest
  ): Observable<PolicyholderResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/policy-holders/${request.body.policyHolderId}`;

    return this.delete<PolicyholderResponse>(url, {
      productId: request.productId,
    });
  }

  retrieveCurrentCarrier(
    request: GetCurrentCarrierRequest
  ): Observable<CurrentCarrier> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/current-carrier/primary`;

    return this.get<CurrentCarrier>(url, {
      productId: request.productId,
    }).pipe(retryOnStatus0(this.RETRY_MS));
  }

  retrievePowersportsCurrentCarrier(
    request: GetPowersportsCurrentCarrierRequest
  ): Observable<PowersportsCurrentCarrier> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/current-carrier/primary`;

    return this.get<PowersportsCurrentCarrier>(url, {
      productId: request.productId,
    });
  }

  updateCurrentCarrier(
    request: UpdateCurrentCarrierRequest
  ): Observable<CurrentCarrier> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/current-carrier/primary`;

    return this.put<CurrentCarrier>(url, request.body, {
      productId: request.productId,
    });
  }

  updatePowersportsCurrentCarrier(
    request: UpdatePowersportsCurrentCarrierRequest
  ): Observable<PowersportsCurrentCarrier> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/current-carrier/primary`;

    return this.put<PowersportsCurrentCarrier>(url, request.body, {
      productId: request.productId,
    });
  }

  retrieveVehicles(request: GetVehiclesRequest): Observable<VehicleEntity[]> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/vehicles`;

    return this.get<VehicleEntity[]>(url, {
      productId: request.productId,
    });
  }

  updateVehicles(request: UpdateVehiclesRequest): Observable<VehicleEntity[]> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/vehicles`;

    return this.put<VehicleEntity[]>(url, request.body, {
      productId: request.productId,
    });
  }

  quote(request: QuoteRequest): Observable<QuoteRatingResponseModel> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }?rate=${request.ratingType}`;
    return this.patch<QuoteRatingResponseModel>(
      url,
      {},
      {
        productId: request.productId,
        ignoreRiskFilter:
          this._appConfig.isTest() &&
          request.productId != ProductTypes.POWERSPORTS &&
          request.productId != ProductTypes.UMBRELLA
            ? this._appConfig.config.ignoreRiskFilter
            : 'false',
        ignoreAutoUWRules:
          this._appConfig.isTest() &&
          request.productId != ProductTypes.POWERSPORTS &&
          request.productId != ProductTypes.UMBRELLA
            ? this._appConfig.config.ignoreAutoUWRules
            : 'false',
      }
    ).pipe(
      map(response => {
        response.productId = request.productId;
        response.ratingType = request.ratingType;

        return response;
      })
    );
  }

  termLifeQuote(
    request: TermLifeQuoteRequest
  ): Observable<TermLifeQuoteRatingResponseModel> {
    const url = this._appConfig.config.pcEdgeTermLifeUrl;
    const messageID = StringUtils.generateUUID();
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);
    return this._http
      .post<TermLifeQuoteRatingResponseModel>(url, request, {
        headers,
      })
      .pipe(this._retry.retryOn503(this.RETRY_MS));
  }

  emailQuoteEstimate(request: TermLifeEmailRequest): Observable<any> {
    const url = this._appConfig.config.emailQuoteEstimateUrl;

    const messageID = StringUtils.generateUUID();
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);
    return this._http2
      .post(url, request, {
        headers: headers,
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  ratedQuotes(
    request: InitiateNewBusinessUmbrellaRequest
  ): Observable<QuoteRatingResponseModel> {
    const url = `${this._getUrlPrefixForProduct(
      request.productId
    )}/rated-quotes`;
    const headers = HeadersUtils.buildHeaderWithMessageId(
      this._appConfig.config.apiKey,
      this._appConfig.isTest() ? this._appConfig.config.targetEnv : ''
    );
    return this._sequencerService.performOperation(request.productId, () => {
      return this._http
        .post<QuoteRatingResponseModel>(url, request, {
          headers,
        })
        .pipe(
          map(response => {
            response.productId = request.productId;
            return response;
          })
        );
    });
  }

  retrieveAllCoverages(
    request: RetrieveCoverageRequestModel
  ): Observable<RetrieveCoverageResponseModel[]> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/coverages?refreshCoverages=true`;

    return this.get<RetrieveCoverageResponseModel[]>(url, {
      productId: request.productId,
    });
  }

  updateCoverages(
    request: UpdateCoverageRequestModel
  ): Observable<CoverageChangeResponseModel> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/coverages`;

    const body = {
      coverages: request.coverageChanges,
    };

    return this.patch<CoverageChangeResponseModel>(url, body, {
      productId: request.productId,
    });
  }

  loadModifyContactPoint(request: ModifyContactPointRequest) {
    const url = `${this._appConfig.config.multiproductApi}/email`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID());

    return this._http.post<ModifyContactPointResponse>(url, request, {
      headers,
    });
  }

  sendPetPromoEmail(recipientEmailAddress: string): Observable<any> {
    this._loggingService.log('Sending pet promo to ', recipientEmailAddress);
    const url = `${this._appConfig.config.multiproductApi}/pre-bind-template-email`;
    const petRequestBody = {
      url: 'nationwide.com',
      recipientEmailAddress: recipientEmailAddress,
      templateName: PET_CROSS_SELL_EMAIL_TEMPLATE,
    };
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID());

    return this._http.post(url, petRequestBody, {
      headers,
    });
  }

  // generateTermLifeQuoteId(): Observable<string> {
  //   const quoteId = new Observable<string>(observer => {
  //     observer.next(StringUtils.getQuoteIdForTermLife());
  //   });
  //   return quoteId;
  // }

  generateTermLifeQuoteId(): Observable<string> {
    return of(StringUtils.getQuoteIdForTermLife());
  }

  private _getUrlPrefixForProduct(productId) {
    switch (productId) {
      case ProductTypes.AUTO: {
        return this._appConfig.config.pcEdgeAutoUrl;
      }

      case ProductTypes.HOMEOWNERS: {
        return this._appConfig.config.pcEdgeHomeownersUrl;
      }

      case ProductTypes.CONDO: {
        return this._appConfig.config.pcEdgeCondoUrl;
      }

      case ProductTypes.RENTERS: {
        return this._appConfig.config.pcEdgeRentersUrl;
      }

      case ProductTypes.UMBRELLA: {
        return this._appConfig.config.pcEdgeUmbrellaUrl;
      }

      case ProductTypes.POWERSPORTS: {
        return this._appConfig.config.pcEdgePowersportsUrl;
      }

      case ProductTypes.TERMLIFE: {
        return this._appConfig.config.pcEdgeTermLifeUrl;
      }
    }
  }
}
