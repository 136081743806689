import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';

export const enum VehicleActionTypes {
  ADD_VEHICLE = '[Vehicle] Add Vehicle',
  ADD_VEHICLE_FAIL = '[Vehicle] Add Vehicle Fail',
  ADD_VEHICLE_SUCCESS = '[Vehicle] Add Vehicle Success',

  ADD_VEHICLE_TO_STORE = '[Vehicle] Add Vehicle to Store',

  UPDATE_VEHICLE = '[Vehicle] Update Vehicle',
  UPDATE_VEHICLE_FAIL = '[Vehicle] Update Vehicle Fail',
  UPDATE_VEHICLE_SUCCESS = '[Vehicle] Update Vehicle Success',
  UPDATE_VEHICLE_DIRECTLY = '[Vehicle] Update Vehicle Directly',

  UPDATE_VEHICLE_DETAILS = '[Vehicle] Update Vehicle Details',
  UPDATE_VEHICLE_DETAILS_FAIL = '[Vehicle] Update Vehicle Details Fail',
  UPDATE_VEHICLE_DETAILS_SUCCESS = '[Vehicle] Update Vehicle Details Success',

  UPDATE_ALL_VEHICLES = '[Vehicle] Update All Vehicles',
  UPDATE_ANNUAL_MILES_FOR_ALL_VEHICLES = '[Vehicle] Update Annual Miles for All Vehicles',

  DELETE_VEHICLE = '[Vehicle] Delete Vehicle',
  DELETE_VEHICLE_FAIL = '[Vehicle] Delete Vehicle Fail',
  DELETE_VEHICLE_SUCCESS = '[Vehicle] Delete Vehicle Success',

  UNSET_VEHICLE_DISCOUNT_LOADED = '[Vehicle] UnSet Vehicle Discount Loaded',
  SET_VEHICLE_DISCOUNT_LOADED = '[Vehicle] Set Vehicle Discount Loaded',

  LOAD_VEHICLE_DISCOUNTS = '[Vehicle] Load Vehicle Discounts',
  LOAD_VEHICLE_DISCOUNTS_SUCCESS = '[Vehicle] Load Vehicle Discounts Success',
  LOAD_VEHICLE_DISCOUNTS_FAIL = '[Vehicle] Load Vehicle Discounts Fail',

  UPDATE_VEHICLE_FROM_RETRIEVE = '[Retrieve] Update Vehicle From Retrieve',

  ADD_POWERSPORTS_VEHICLE = '[Powersports Vehicle] Add Powersports Vehicle',
  ADD_POWERSPORTS_VEHICLE_FAIL = '[Powersports Vehicle] Add Powersports Vehicle Fail',
  ADD_POWERSPORTS_VEHICLE_SUCCESS = '[Powersports Vehicle] Add Powersports Vehicle Success',

  ADD_POWERSPORTS_VEHICLE_TO_STORE = '[Powersports Vehicle] Add Powersports Vehicle to Store',
  ADD_ALL_POWERSPORTS_MULTIPLE_VIN_MATCH_VEHICLES = '[Powersports Vehicle] Add All Powersports Multiple Vin Match Vehicles',

  UPDATE_POWERSPORTS_VEHICLE = '[Powersports Vehicle] Update Powersports Vehicle',
  UPDATE_POWERSPORTS_VEHICLE_FAIL = '[Powersports Vehicle] Update Powersports Vehicle Fail',
  UPDATE_POWERSPORTS_VEHICLE_SUCCESS = '[Powersports Vehicle] Update Powersports Vehicle Success',

  UPDATE_POWERSPORTS_VEHICLE_DETAILS = '[Powersports Vehicle] Update Powersports Vehicle Details',
  UPDATE_POWERSPORTS_VEHICLE_DETAILS_FAIL = '[Powersports Vehicle] Update Powersports Vehicle Details Fail',
  UPDATE_POWERSPORTS_VEHICLE_DETAILS_SUCCESS = '[Powersports Vehicle] Update Powersports Vehicle Details Success',

  UPDATE_ALL_POWERSPORTS_VEHICLES = '[Powersports Vehicle] Update All Vehicles',
  UPDATE_ANNUAL_MILES_FOR_ALL_POWERSPORTS_VEHICLES = '[Powersports Vehicle] Update Annual Miles for All Vehicles',

  DELETE_POWERSPORTS_VEHICLE = '[Powersports Vehicle] Delete Powersports Vehicle',
  DELETE_POWERSPORTS_VEHICLE_FAIL = '[Powersports Vehicle] Delete Powersports Vehicle Fail',
  DELETE_POWERSPORTS_VEHICLE_SUCCESS = '[Powersports Vehicle] Delete Powersports Vehicle Success',

  UNSET_POWERSPORTS_VEHICLE_DISCOUNT_LOADED = '[Powersports Vehicle] UnSet Powersports Vehicle Discount Loaded',
  SET_POWERSPORTS_VEHICLE_DISCOUNT_LOADED = '[Powersports Vehicle] Set Powersports Vehicle Discount Loaded',

  LOAD_POWERSPORTS_VEHICLE_DISCOUNTS = '[Powersports Vehicle] Load Powersports Vehicle Discounts',
  LOAD_POWERSPORTS_VEHICLE_DISCOUNTS_SUCCESS = '[Powersports Vehicle] Load Powersports Vehicle Discounts Success',
  LOAD_POWERSPORTS_VEHICLE_DISCOUNTS_FAIL = '[Powersports Vehicle] Load Powersports Vehicle Discounts Fail',

  UPDATE_POWERSPORTS_VEHICLE_FROM_RETRIEVE = '[Retrieve] Update Powersports Vehicle From Retrieve',
}

export class AddVehicle implements Action {
  readonly type = VehicleActionTypes.ADD_VEHICLE;
  constructor(public payload: VehicleEntity) {}
}

export class AddVehicleToStore implements Action {
  readonly type = VehicleActionTypes.ADD_VEHICLE_TO_STORE;
  constructor(public payload: VehicleEntity) {}
}

export class AddVehicleFail implements Action {
  readonly type = VehicleActionTypes.ADD_VEHICLE_FAIL;
  constructor(public payload: any) {}
}

export class AddVehicleSuccess implements Action {
  readonly type = VehicleActionTypes.ADD_VEHICLE_SUCCESS;
  constructor(public payload: VehicleEntity) {}
}

export class UpdateVehicle implements Action {
  readonly type = VehicleActionTypes.UPDATE_VEHICLE;
  constructor(public payload: VehicleEntity) {}
}

export class UpdateVehicleFromRetrieve implements Action {
  readonly type = VehicleActionTypes.UPDATE_VEHICLE_FROM_RETRIEVE;
  constructor(public payload: VehicleEntity) {}
}

export class UpdateVehicleFail implements Action {
  readonly type = VehicleActionTypes.UPDATE_VEHICLE_FAIL;
  constructor(public payload: any) {}
}

export class UpdateVehicleSuccess implements Action {
  readonly type = VehicleActionTypes.UPDATE_VEHICLE_SUCCESS;
  constructor(public payload: Update<VehicleEntity>) {}
}

export class UpdateVehicleDirectly implements Action {
  readonly type = VehicleActionTypes.UPDATE_VEHICLE_DIRECTLY;
  constructor(public payload: Update<VehicleEntity>) {}
}

export class UpdateVehicleDetails implements Action {
  readonly type = VehicleActionTypes.UPDATE_VEHICLE_DETAILS;
  constructor(public payload: VehicleEntity) {}
}

export class UpdateVehicleDetailsFail implements Action {
  readonly type = VehicleActionTypes.UPDATE_VEHICLE_DETAILS_FAIL;
  constructor(public payload: any) {}
}

export class UpdateVehicleDetailsSuccess implements Action {
  readonly type = VehicleActionTypes.UPDATE_VEHICLE_DETAILS_SUCCESS;
  constructor(public payload: Update<VehicleEntity>) {}
}

export class UpdateAllVehicles implements Action {
  readonly type = VehicleActionTypes.UPDATE_ALL_VEHICLES;
  constructor(public payload: Update<VehicleEntity>[]) {}
}

export class UpdateAnnualMilesForAllVehicles implements Action {
  readonly type = VehicleActionTypes.UPDATE_ANNUAL_MILES_FOR_ALL_VEHICLES;
  constructor(public payload: VehicleEntity[]) {}
}

export class DeleteVehicle implements Action {
  readonly type = VehicleActionTypes.DELETE_VEHICLE;
  constructor(public payload: string) {}
}

export class DeleteVehicleFail implements Action {
  readonly type = VehicleActionTypes.DELETE_VEHICLE_FAIL;
  constructor(public payload: any) {}
}

export class DeleteVehicleSuccess implements Action {
  readonly type = VehicleActionTypes.DELETE_VEHICLE_SUCCESS;
  constructor(public payload: string) {}
}

export class UnSetVehicleDiscountsLoaded implements Action {
  readonly type = VehicleActionTypes.UNSET_VEHICLE_DISCOUNT_LOADED;
  constructor(public payload: string) {}
}

export class SetVehicleDiscountsLoaded implements Action {
  readonly type = VehicleActionTypes.SET_VEHICLE_DISCOUNT_LOADED;
  constructor(public payload: string) {}
}

export class LoadVehicleDiscounts implements Action {
  readonly type = VehicleActionTypes.LOAD_VEHICLE_DISCOUNTS;
  constructor(public payload: any) {}
}

export class LoadVehicleDiscountsSuccess implements Action {
  readonly type = VehicleActionTypes.LOAD_VEHICLE_DISCOUNTS_SUCCESS;
  constructor(
    public vehicleId: string,
    public payload: EligibleDiscountEntity[]
  ) {}
}

export class LoadVehicleDiscountsFail implements Action {
  readonly type = VehicleActionTypes.LOAD_VEHICLE_DISCOUNTS_FAIL;
  constructor(public vehicleId: string, public payload: any) {}
}

export class AddPowersportsVehicle implements Action {
  readonly type = VehicleActionTypes.ADD_POWERSPORTS_VEHICLE;
  constructor(public payload: VehicleEntity) {}
}

export class AddPowersportsVehicleToStore implements Action {
  readonly type = VehicleActionTypes.ADD_POWERSPORTS_VEHICLE_TO_STORE;
  constructor(public payload: VehicleEntity) {}
}

export class AddPowersportsVehicleFail implements Action {
  readonly type = VehicleActionTypes.ADD_POWERSPORTS_VEHICLE_FAIL;
  constructor(public payload: any) {}
}

export class AddPowersportsVehicleSuccess implements Action {
  readonly type = VehicleActionTypes.ADD_POWERSPORTS_VEHICLE_SUCCESS;
  constructor(public payload: VehicleEntity) {}
}
export class AddAllPowersportsMultipleVinMatchVehicles implements Action {
  readonly type =
    VehicleActionTypes.ADD_ALL_POWERSPORTS_MULTIPLE_VIN_MATCH_VEHICLES;
  constructor(public payload: VehicleEntity[]) {}
}

export class UpdatePowersportsVehicle implements Action {
  readonly type = VehicleActionTypes.UPDATE_POWERSPORTS_VEHICLE;
  constructor(public payload: VehicleEntity) {}
}

export class UpdatePowersportsVehicleFromRetrieve implements Action {
  readonly type = VehicleActionTypes.UPDATE_POWERSPORTS_VEHICLE_FROM_RETRIEVE;
  constructor(public payload: VehicleEntity) {}
}

export class UpdatePowersportsVehicleFail implements Action {
  readonly type = VehicleActionTypes.UPDATE_POWERSPORTS_VEHICLE_FAIL;
  constructor(public payload: any) {}
}

export class UpdatePowersportsVehicleSuccess implements Action {
  readonly type = VehicleActionTypes.UPDATE_POWERSPORTS_VEHICLE_SUCCESS;
  constructor(public payload: Update<VehicleEntity>) {}
}

export class UpdatePowersportsVehicleDetails implements Action {
  readonly type = VehicleActionTypes.UPDATE_POWERSPORTS_VEHICLE_DETAILS;
  constructor(public payload: VehicleEntity) {}
}

export class UpdatePowersportsVehicleDetailsFail implements Action {
  readonly type = VehicleActionTypes.UPDATE_POWERSPORTS_VEHICLE_DETAILS_FAIL;
  constructor(public payload: any) {}
}

export class UpdatePowersportsVehicleDetailsSuccess implements Action {
  readonly type = VehicleActionTypes.UPDATE_POWERSPORTS_VEHICLE_DETAILS_SUCCESS;
  constructor(public payload: Update<VehicleEntity>) {}
}

export class UpdateAllPowersportsVehicles implements Action {
  readonly type = VehicleActionTypes.UPDATE_ALL_POWERSPORTS_VEHICLES;
  constructor(public payload: Update<VehicleEntity>[]) {}
}

export class UpdateAnnualMilesForAllPowersportsVehicles implements Action {
  readonly type =
    VehicleActionTypes.UPDATE_ANNUAL_MILES_FOR_ALL_POWERSPORTS_VEHICLES;
  constructor(public payload: VehicleEntity[]) {}
}

export class DeletePowersportsVehicle implements Action {
  readonly type = VehicleActionTypes.DELETE_POWERSPORTS_VEHICLE;
  constructor(public payload: VehicleEntity) {}
}

export class DeletePowersportsVehicleFail implements Action {
  readonly type = VehicleActionTypes.DELETE_POWERSPORTS_VEHICLE_FAIL;
  constructor(public payload: any) {}
}

export class DeletePowersportsVehicleSuccess implements Action {
  readonly type = VehicleActionTypes.DELETE_POWERSPORTS_VEHICLE_SUCCESS;
  constructor(public payload: string) {}
}

export class UnSetPowersportsVehicleDiscountsLoaded implements Action {
  readonly type = VehicleActionTypes.UNSET_POWERSPORTS_VEHICLE_DISCOUNT_LOADED;
  constructor(public payload: string) {}
}

export class SetPowersportsVehicleDiscountsLoaded implements Action {
  readonly type = VehicleActionTypes.SET_POWERSPORTS_VEHICLE_DISCOUNT_LOADED;
  constructor(public payload: string) {}
}

export class LoadPowersportsVehicleDiscounts implements Action {
  readonly type = VehicleActionTypes.LOAD_POWERSPORTS_VEHICLE_DISCOUNTS;
  constructor(public payload: any) {}
}

export class LoadPowersportsVehicleDiscountsSuccess implements Action {
  readonly type = VehicleActionTypes.LOAD_POWERSPORTS_VEHICLE_DISCOUNTS_SUCCESS;
  constructor(
    public vehicleId: string,
    public payload: EligibleDiscountEntity[]
  ) {}
}

export class LoadPowersportsVehicleDiscountsFail implements Action {
  readonly type = VehicleActionTypes.LOAD_POWERSPORTS_VEHICLE_DISCOUNTS_FAIL;
  constructor(public vehicleId: string, public payload: any) {}
}

export type DsmVehicleActions =
  | AddVehicle
  | AddVehicleToStore
  | AddVehicleFail
  | AddVehicleSuccess
  | UpdateVehicle
  | UpdateVehicleFail
  | UpdateVehicleSuccess
  | UpdateVehicleDirectly
  | UpdateVehicleDetails
  | UpdateVehicleDetailsFail
  | UpdateVehicleDetailsSuccess
  | UpdateAllVehicles
  | UpdateAnnualMilesForAllVehicles
  | DeleteVehicle
  | DeleteVehicleFail
  | DeleteVehicleSuccess
  | UnSetVehicleDiscountsLoaded
  | SetVehicleDiscountsLoaded
  | LoadVehicleDiscounts
  | LoadVehicleDiscountsSuccess
  | LoadVehicleDiscountsFail
  | UpdateVehicleFromRetrieve
  | UpdatePowersportsVehicleFromRetrieve
  | AddPowersportsVehicle
  | AddPowersportsVehicleToStore
  | AddPowersportsVehicleFail
  | AddPowersportsVehicleSuccess
  | AddAllPowersportsMultipleVinMatchVehicles
  | UpdatePowersportsVehicle
  | UpdatePowersportsVehicleFail
  | UpdatePowersportsVehicleSuccess
  | UpdatePowersportsVehicleDetails
  | UpdatePowersportsVehicleDetailsFail
  | UpdatePowersportsVehicleDetailsSuccess
  | UpdateAllPowersportsVehicles
  | UpdateAnnualMilesForAllPowersportsVehicles
  | DeletePowersportsVehicle
  | DeletePowersportsVehicleFail
  | DeletePowersportsVehicleSuccess
  | UnSetPowersportsVehicleDiscountsLoaded
  | SetPowersportsVehicleDiscountsLoaded
  | LoadPowersportsVehicleDiscounts
  | LoadPowersportsVehicleDiscountsSuccess
  | LoadPowersportsVehicleDiscountsFail;
