import { Action } from '@ngrx/store';

export const LOAD_QUOTE_LETTER = '[Quote] Load Quote Letter';
export const LOAD_QUOTE_LETTER_SUCCESS = '[Quote] Load Quote Letter Success';
export const LOAD_QUOTE_LETTER_FAIL = '[Quote] Load Quote Letter Fail';

export class LoadQuoteLetter implements Action {
  readonly type = LOAD_QUOTE_LETTER;
  constructor() {}
}

export class LoadQuoteLetterSuccess implements Action {
  readonly type = LOAD_QUOTE_LETTER_SUCCESS;
  constructor(public payload: string) {}
}

export class LoadQuoteLetterFail implements Action {
  readonly type = LOAD_QUOTE_LETTER_FAIL;
  constructor(public payload: string) {}
}

export type QuoteLetterActions =
  | LoadQuoteLetter
  | LoadQuoteLetterSuccess
  | LoadQuoteLetterFail;
