import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import { state } from '@angular/animations';

export const getSessionState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => {
    return state.session;
  }
);

export const getSessionId = createSelector(getSessionState, state => {
  return state.sessionId;
});

export const getQuoteState = createSelector(getSessionState, state => {
  return state.quoteState;
});

export const getSessionPerson = createSelector(getSessionState, state => {
  return state.person;
});

export const getSessionFirstName = createSelector(getSessionState, state => {
  return state.firstName;
});

export const getSessionLastName = createSelector(getSessionState, state => {
  return state.lastName;
});

export const getSessionEmail = createSelector(getSessionState, state => {
  return state.email;
});

export const getRequestedProducts = createSelector(getSessionState, state => {
  return state.requestedProducts;
});

export const getReferrer = createSelector(getSessionState, state => {
  return state.referrer;
});

export const getHasSentPetEmail = createSelector(getSessionState, state => {
  return state.hasSentPetEmail;
});

export const getSessionProgressTrackerEligibility = createSelector(
  getSessionState,
  state => {
    return state.isProgressTrackerEligible;
  }
);

export const getProgressTrackerFlowName = createSelector(
  getSessionState,
  state => {
    return state.progressTrackerFlowName;
  }
);

export const getIgnoreProductDisablementState = createSelector(
  getSessionState,
  state => {
    return state.ignoreProductDisablement;
  }
);

export const getIgnoreRiskFilter = createSelector(getSessionState, state => {
  return state.ignoreRiskFilter;
});

export const getIgnoreAutoUWRules = createSelector(getSessionState, state => {
  return state.ignoreAutoUWRules;
});

export const getIgnoreCurrentCarrierValidation = createSelector(
  getSessionState,
  state => {
    return state.ignoreCurrentCarrierValidation;
  }
);
export const getPCEnv = createSelector(getSessionState, state => {
  return state.pcEnv;
});

export const getBillingEnv = createSelector(getSessionState, state => {
  return state.billingEnv;
});

export const getSessionLogEntries = createSelector(getSessionState, state => {
  return state.sessionLogs;
});

export const getSessionClaimsReviewsLoadingSpinnerEligibility = createSelector(
  getSessionState,
  state => {
    return state.isClaimsReviewsLoadingSpinnerEligible;
  }
);

export const getSessionClaimsReviewsLoadingSpinnerActive = createSelector(
  getSessionState,
  state => {
    return state.isClaimsReviewsLoadingSpinnerActive;
  }
);

export const getSessionClaimsReviewsLoadingSpinnerUpperMessage = createSelector(
  getSessionState,
  state => {
    return state.claimsReviewsLoadingSpinnerUpperMessage;
  }
);

export const getSessionClaimsReviewsLoadingSpinnerLowerMessage = createSelector(
  getSessionState,
  state => {
    return state.claimsReviewsLoadingSpinnerLowerMessage;
  }
);

export const getSessionClaimsReviewsLoadingSpinnerReviewerName = createSelector(
  getSessionState,
  state => {
    return state.claimsReviewsLoadingSpinnerReviewerName;
  }
);
