import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ofType, Effect, Actions } from '@ngrx/effects';
import * as fromActions from '@core/store/actions';
import { switchMap, take, tap, catchError } from 'rxjs/operators';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services';
import { AccountService } from '@core/services/account.service';
import { from } from 'rxjs';

@Injectable()
export class AccountEffect {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _accountService: AccountService
  ) {}

  @Effect()
  createAccount$ = this._actions$.pipe(
    ofType(fromActions.AccountActionType.CREATE_ACCOUNT),
    switchMap(() =>
      this._store.select(fromSelectors.buildCreateAccountRequest).pipe(take(1))
    ),
    tap(request => this._loggingService.log('createAccount Request', request)),
    switchMap(request => {
      return this._accountService.createAccount(request).pipe(
        tap(response =>
          this._loggingService.log('createAccount Response', response)
        ),
        switchMap(response =>
          from([
            new fromActions.CreateAccountSuccess(
              response.accountId,
              request.accountHolder
            ),
          ])
        ),
        catchError(error => {
          this._loggingService.log('createAccount Error', error);
          return from([new fromActions.CreateAccountFail(error)]);
        })
      );
    })
  );
}
