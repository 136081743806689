import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { SortDirection, SortEvent } from '@core/models/sort/sort.model';

@Directive({
  selector: 'th[sortable]',
  // eslint-disable-next-line
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class SortableHeaderDirective {
  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotateDirection: { [key: string]: SortDirection } = {
    asc: 'desc',
    desc: '',
    '': 'asc',
  };

  rotate() {
    this.direction = this.rotateDirection[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}
