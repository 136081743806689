import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsCondoDirective } from './is-condo.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsCondoDirective],
  exports: [IsCondoDirective],
})
export class IsCondoModule {}
