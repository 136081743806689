import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AddressLongInputComponent } from './address-long-input.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [AddressLongInputComponent],
  exports: [AddressLongInputComponent],
})
export class AddressLongInputModule {}
