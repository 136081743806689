import { Action } from '@ngrx/store';
import { ReassignDvaApiResponse } from '@core/models/auto/driver-vehicle-assignment.model';
import { DriverVehicleAssignmentEntity } from '@core/models/entities/driver-vehicle-assignment.entity';

export const enum DriverVehicleAssignmentActionTypes {
  GET_DRIVER_VEHICLE_ASSIGNMENTS = '[DVA] Get Driver Vehicle Assignments',
  GET_DRIVER_VEHICLE_ASSIGNMENTS_FAIL = '[DVA] Get Driver Vehicle Assignments Fail',
  GET_DRIVER_VEHICLE_ASSIGNMENTS_SUCCESS = '[DVA] Get Driver Vehicle Assignments Success',

  SEND_DVA_STORE = '[DVA] Send Store',
  SEND_DVA_STORE_FAIL = '[DVA] Send Store Fail',
  SEND_DVA_STORE_SUCCESS = '[DVA] Send Store Success',

  REASSIGN_DRIVER_SUCCESS = '[DVA] Reassign Driver Success',

  DVA_REPLACE_STORE = '[DVA] Replace Store',
  DVA_REMOVE_AUTO_GENERATED_ASSIGNMENTS = '[DVA] Remove Auto Generated Assignments',
  CLEAR_DVA_STORE = '[DVA] Clear Store',
}

export class GetDriverVehicleAssignments implements Action {
  readonly type =
    DriverVehicleAssignmentActionTypes.GET_DRIVER_VEHICLE_ASSIGNMENTS;
}
export class GetDriverVehicleAssignmentsFail implements Action {
  readonly type =
    DriverVehicleAssignmentActionTypes.GET_DRIVER_VEHICLE_ASSIGNMENTS;
  constructor(public error: any) {}
}
export class GetDriverVehicleAssignmentsSuccess implements Action {
  readonly type =
    DriverVehicleAssignmentActionTypes.GET_DRIVER_VEHICLE_ASSIGNMENTS;
  constructor(public payload: any) {}
}

export class SendDvaStore implements Action {
  readonly type = DriverVehicleAssignmentActionTypes.SEND_DVA_STORE;
  constructor(public payload?: any) {}
}

export class SendDvaStoreFail implements Action {
  readonly type = DriverVehicleAssignmentActionTypes.SEND_DVA_STORE_FAIL;
  constructor(public errorCode: string) {}
}

export class SendDvaStoreSuccess implements Action {
  readonly type = DriverVehicleAssignmentActionTypes.SEND_DVA_STORE_SUCCESS;
  constructor(public payload?: any) {}
}

export class ReassignDvaDriverSuccess implements Action {
  readonly type = DriverVehicleAssignmentActionTypes.REASSIGN_DRIVER_SUCCESS;
  constructor(public payload: ReassignDvaApiResponse) {}
}

export class DvaReplaceStore implements Action {
  readonly type = DriverVehicleAssignmentActionTypes.DVA_REPLACE_STORE;
  constructor(public payload: DriverVehicleAssignmentEntity[]) {}
}

export class DvaRemoveAutoGeneratedAssignments implements Action {
  readonly type =
    DriverVehicleAssignmentActionTypes.DVA_REMOVE_AUTO_GENERATED_ASSIGNMENTS;
  constructor(public payload?: any) {}
}

export class ClearDvaStore implements Action {
  readonly type = DriverVehicleAssignmentActionTypes.CLEAR_DVA_STORE;
}

export type DriverVehicleAssignmentActions =
  | GetDriverVehicleAssignments
  | GetDriverVehicleAssignmentsFail
  | GetDriverVehicleAssignmentsSuccess
  | SendDvaStore
  | SendDvaStoreFail
  | SendDvaStoreSuccess
  | ReassignDvaDriverSuccess
  | DvaReplaceStore
  | ClearDvaStore;
