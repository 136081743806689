import * as fromHomeownerInfo from '@core/store/actions/homeowner-propertyInfo.action';
import { HomeownerPropertyInfo } from '@core/models/metadata-picklist/home/homeowner-propertyInfo.model';

export interface HomeownerPropertyInfoState {
  pickListValue: {
    data: any;
    loaded: boolean;
    loading: boolean;
  };
}

export const initialState: HomeownerPropertyInfo = {
  pickListValue: {
    data: [],
    loaded: false,
    loading: false,
  },
};

export function reducer(
  state = initialState,
  action: fromHomeownerInfo.HomeownerPropertyInfoActions
): HomeownerPropertyInfoState {
  switch (action.type) {
    case fromHomeownerInfo.homeownerPropertyInfoActionsTypes.LOAD_PICKLIST:
      const pickListValue = { ...state.pickListValue };
      pickListValue.data = action.payload;
      pickListValue.loaded = true;
      return { ...state, pickListValue };
  }

  return state;
}
