import { Injectable } from '@angular/core';
import { StringUtils } from '@shared/utils/string.utils';

import {
  throwError as observableThrowError,
  Observable,
  combineLatest,
} from 'rxjs';
import { map, take, catchError } from 'rxjs/operators';

import { CmaAdapter } from '@core/adapters/cma.adapter';
import { CmaDao } from '@core/dao/cma.dao';

import { AddressService } from '@core/services/address.service';
import { PolicyholderService } from '@core/services/policyholder.service';
import { ProductsService } from '@core/services/products.service';

import { CmaRequest } from '@core/models/cma/cma-request.model';
import { CmaResponse } from '@core/models/cma/cma-response.model';
import { BaseCmaRequest } from '@core/models/cma/base-cma-request.model';

import { ProductTypes } from '@shared/constants/app-constants';

const randomNumber = 10000000;
const PARTY_TYPE = 'P';
const SEARCH_TYPE = 'NameDOB';

@Injectable()
export class CmaService {
  constructor(
    private _cmaDao: CmaDao,
    private _cmaAdapter: CmaAdapter,
    private _policyHolderService: PolicyholderService,
    private _addressService: AddressService,
    private _productsService: ProductsService
  ) {}

  customerManagementActionResponse(
    request: CmaRequest
  ): Observable<CmaResponse> {
    return this._cmaAdapter
      .customerManagementAction(request)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  loadCustomerManagementAction(): void {
    this._cmaDao.loadCustomerManagementAction();
    this._productsService.setLeadsSentToCma();
  }

  getCustomerManagementActionLoaded(): Observable<boolean> {
    return this._cmaDao.getCustomerManagementActionLoaded();
  }

  getCustomerManagementActionLoading(): Observable<boolean> {
    return this._cmaDao.getCustomerManagementActionLoading();
  }

  getCustomerManagementAction(): Observable<CmaResponse> {
    return this._cmaDao.getCustomerManagementAction();
  }

  buildCustomerManagementActionRequest(): Observable<CmaRequest> {
    return this.buildDSMCmaRequest();
  }

  // DSM
  private buildDSMCmaRequest(): Observable<CmaRequest> {
    const policyholder$ = this._policyHolderService.getNamedInsured();
    const address$ = this._addressService.getStandardizedAddresses();
    const products$ = this._productsService.getSelectedProducts();

    return combineLatest([policyholder$, address$, products$]).pipe(
      take(1),
      map(([policyholder, address, products]) => {
        const request = {} as CmaRequest;
        const auto = {} as BaseCmaRequest;
        const homeowners = {} as BaseCmaRequest;
        const condo = {} as BaseCmaRequest;
        const renters = {} as BaseCmaRequest;

        request.birthDate = policyholder.person.dateOfBirth;
        request.firstName = policyholder.person.firstName;
        request.lastName = policyholder.person.lastName;
        request.middleName = policyholder.person.middleName;
        request.suffix = policyholder.person.suffix;
        request.gender = policyholder.person.gender;
        request.phone = policyholder.homeNumber;
        request.email = policyholder.emailAddress;

        if (address !== null) {
          const addr = address.standardizedAddresses[0];
          request.unitNumber = addr.unitNumber;
          request.street = addr.street;
          request.city = addr.city;
          request.state = addr.state;
          request.postalCode = addr.zipCode;
        }

        request.consent = policyholder.tcpaConsent
          ? policyholder.tcpaConsent
          : false;
        request.partyType = PARTY_TYPE;
        request.searchType = SEARCH_TYPE;

        products.forEach(product => {
          if (
            product.quoteStatus !== 'issued' &&
            !product.issueCompleted &&
            !product.leadSentToCma
          ) {
            if (product.id === ProductTypes.AUTO) {
              auto.quoteId = product.quoteId
                ? product.quoteId
                : this._generateQuoteID();
              request.auto = auto;
            } else if (product.id === ProductTypes.HOMEOWNERS) {
              homeowners.quoteId = product.quoteId
                ? product.quoteId
                : this._generateQuoteID();
              request.homeowners = homeowners;
            } else if (product.id === ProductTypes.CONDO) {
              condo.quoteId = product.quoteId
                ? product.quoteId
                : this._generateQuoteID();
              request.condo = condo;
            } else if (product.id === ProductTypes.RENTERS) {
              renters.quoteId = product.quoteId
                ? product.quoteId
                : this._generateQuoteID();
              request.renters = renters;
            }
          }
        });

        return request;
      })
    );
  }

  private _generateQuoteID(): string {
    return 'NWX' + StringUtils.generateRandomNumberString(randomNumber);
  }
}
