import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, filter, take } from 'rxjs/operators';
import { ProductsService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class SelectedProductsLoadedGuard implements CanActivate {
  constructor(private productsService: ProductsService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.areAllSelectedProductsLoaded();
  }

  private areAllSelectedProductsLoaded(): Observable<boolean> {
    return this.productsService.getSelectedProducts().pipe(
      switchMap(selectedProducts => {
        let loaded = true;
        selectedProducts.forEach(product => {
          if (!product.quoteLoaded && !product.hasError) {
            loaded = false;
          }
        });
        return of(loaded);
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
