import { Injectable } from '@angular/core';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { HeadersUtils } from '@shared/utils/headers.utils';
import { StringUtils } from '@shared/utils/string.utils';
import { Observable } from 'rxjs';

export interface ComparativeQuotesResponse {
  comparativeQuoteId: string;
  destination: string;
}

export interface ComparativeQuotesRequest {
  comparativeQuoteId: string;
  accessToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class ComparativeQuotesAdapter extends BaseDsmAdapter {
  comparativeQuotes(
    request: ComparativeQuotesRequest
  ): Observable<ComparativeQuotesResponse[]> {
    const url = `${this._appConfig.config.pcEdgePersonalLinesUtility}/comparative-quotes/${request.comparativeQuoteId}`;
    const headers = HeadersUtils.buildHeaderForDSMCalls(
      this._appConfig.config.apiKey,
      request.accessToken,
      StringUtils.generateUUID(),
      this._appConfig.isTest() ? this._appConfig.config.targetEnv : ''
    );
    return this._http.get<ComparativeQuotesResponse[]>(url, { headers });
  }
}
