import { Action } from '@ngrx/store';

import { StandardizedAddresses } from '@core/models/address/standardized-address.model';

export const LOAD_STANDARDIZED_ADDRESS = '[Core] Load Standardized Addresses';
export const LOAD_STANDARDIZED_ADDRESS_FAIL =
  '[Core] Load Standardized Addresses Fail';
export const LOAD_STANDARDIZED_ADDRESS_SUCCESS =
  '[Core] Load Standardized Addresses Success';

export class LoadStandardizedAddress implements Action {
  readonly type = LOAD_STANDARDIZED_ADDRESS;
  constructor(public payload: any) {}
}

export class LoadStandardizedAddressFail implements Action {
  readonly type = LOAD_STANDARDIZED_ADDRESS_FAIL;
  constructor(public payload: any) {}
}

export class LoadStandardizedAddressSuccess implements Action {
  readonly type = LOAD_STANDARDIZED_ADDRESS_SUCCESS;
  constructor(public payload: StandardizedAddresses) {}
}

export const UPDATE_UNIT_NUMBER = '[Core] Update Unit Number';

export class UpdateUnitNumber implements Action {
  readonly type = UPDATE_UNIT_NUMBER;
  constructor(public payload: any) {}
}

export const RESET_ADDRESS_LOADED = '[Core] Reset Address Loaded';

export class ResetAddressLoaded implements Action {
  readonly type = RESET_ADDRESS_LOADED;
}

export type AddressActions =
  | LoadStandardizedAddress
  | LoadStandardizedAddressFail
  | LoadStandardizedAddressSuccess
  | UpdateUnitNumber
  | ResetAddressLoaded;
