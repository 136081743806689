import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import {
  map,
  tap,
  mergeMap,
  catchError,
  take,
  switchMap,
} from 'rxjs/operators';
import { LoggingService } from '@core/services';
import { from } from 'rxjs';
import { DriverVehicleAssignmentAdapter } from '@core/adapters/driver-vehicle-assignment.adapter';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable()
export class DriverVehicleAssignmentEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _dsmDriverVehicleAssignmentAdapter: DriverVehicleAssignmentAdapter,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  getDriverVehicleAssignments$ = this._actions$.pipe(
    ofType(
      fromActions.DriverVehicleAssignmentActionTypes
        .GET_DRIVER_VEHICLE_ASSIGNMENTS
    ),
    switchMap(() =>
      this._store
        .select(
          fromSelectors.buildDriverVehicleAssignmentRequest(ProductTypes.AUTO)
        )
        .pipe(take(1))
    ),
    tap(request => this._loggingService.log('getAssignments request', request)),
    switchMap(request =>
      this._dsmDriverVehicleAssignmentAdapter.getAssignments(request).pipe(
        tap(response =>
          this._loggingService.log('getAssignments response', response)
        ),
        map(response => new fromActions.DvaReplaceStore(response)),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('DVA Error', safeError);
          return from([
            new fromActions.SendDvaStoreFail(
              this._errorHelper.extractErrorCodeFromAnything(safeError)
            ),
          ]);
        })
      )
    )
  );

  @Effect()
  sendStore$ = this._actions$.pipe(
    ofType(fromActions.DriverVehicleAssignmentActionTypes.SEND_DVA_STORE),
    mergeMap(action =>
      this._store.select(fromSelectors.makeApiRequestForSendStore).pipe(take(1))
    ),
    tap(request => this._loggingService.log('DVA Request', request)),

    mergeMap(request =>
      this._dsmDriverVehicleAssignmentAdapter.putAssignments(request).pipe(
        tap(response => this._loggingService.log('DVA Response', response)),
        map(response => new fromActions.SendDvaStoreSuccess(response)),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('DVA Error', safeError);
          return from([
            new fromActions.SendDvaStoreFail(
              this._errorHelper.extractErrorCodeFromAnything(safeError)
            ),
          ]);
        })
      )
    )
  );
}
