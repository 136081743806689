import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromPolicyAddress from '@core/store/actions/policy-address.action';

import { AddressEntity } from '@core/models/entities/address.entity';
import { DEFAULT_ID } from '@shared/constants/app-constants';

export type PolicyAddressState = EntityState<AddressEntity>;
export const adapter: EntityAdapter<AddressEntity> =
  createEntityAdapter<AddressEntity>({
    selectId: address => address.addressId,
  });

export function reducer(
  state = adapter.getInitialState(),
  action: fromPolicyAddress.PolicyAddressActions
): PolicyAddressState {
  switch (action.type) {
    case fromPolicyAddress.PolicyAddressActionTypes.ADD_POLICY_ADDRESS: {
      let policyAddress = action.payload;
      if (policyAddress.addressId === DEFAULT_ID) {
        policyAddress = createPolicyAddressForDefaultId(policyAddress, state);
      }
      policyAddress = removeLineOneUnitNumber(policyAddress);
      return adapter.upsertOne(policyAddress, state);
    }

    case fromPolicyAddress.PolicyAddressActionTypes.DELETE_POLICY_ADDRESS: {
      return adapter.removeOne(action.payload, state);
    }

    case fromPolicyAddress.PolicyAddressActionTypes.UPDATE_POLICY_ADDRESS: {
      const id = action.payload.id.toString();
      let changes = <AddressEntity>action.payload.changes;
      if (id === DEFAULT_ID) {
        changes = createPolicyAddressForDefaultId(changes, state);
      }
      changes = removeLineOneUnitNumber(changes);
      return adapter.updateOne({ id, changes }, state);
    }

    default: {
      return state;
    }
  }
}

/**
 * Appends the current state addressIds to the input
 * policyAddress.addressIds for the DEFAULT_ID policyAddress
 * @param policyAddress
 * @param state
 */
function createPolicyAddressForDefaultId(
  policyAddress: AddressEntity,
  state: PolicyAddressState
): AddressEntity {
  const newPolicyAddress = { ...policyAddress };
  if (newPolicyAddress.addressIds && state.entities[DEFAULT_ID]) {
    newPolicyAddress.addressIds = {
      ...state.entities[DEFAULT_ID].addressIds,
      ...newPolicyAddress.addressIds,
    };
  }
  return newPolicyAddress;
}

function removeLineOneUnitNumber(input: AddressEntity): AddressEntity {
  if (
    !input.addressLine1 ||
    !input.addressLine2 ||
    !input.addressLine1.endsWith(input.addressLine2)
  ) {
    return input;
  }
  return {
    ...input,
    addressLine1: input.addressLine1
      .substr(0, input.addressLine1.length - input.addressLine2.length)
      .trim(),
  };
}
