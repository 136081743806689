import * as fromLoading from '@core/store/actions/loading.action';

export interface LoadingState {
  loading: boolean;
  upperMessage?: string;
  lowerMessage?: string;
}

export const initialState: LoadingState = {
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromLoading.LoadingActions
): LoadingState {
  switch (action.type) {
    case fromLoading.APP_LOADING_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromLoading.APP_LOADING_END: {
      return {
        ...state,
        loading: false,
        upperMessage: null,
        lowerMessage: null,
      };
    }

    case fromLoading.APP_LOADING_MESSAGES: {
      return {
        ...state,
        upperMessage: action.upperMessage,
        lowerMessage: action.lowerMessage,
      };
    }
  }
  return state;
}

export const getLoadingState = (state: LoadingState) => state.loading;
