import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
} from '@angular/core';
import {
  ProgressTrackerLabels,
  ProgressTrackerPercentages,
  ProgressTrackerFlowNames,
} from '@shared/constants/app-constants';

@Component({
  selector: 'mq-progress-tracker',
  templateUrl: './progress-tracker.component.html',
  styleUrls: ['./progress-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressTrackerComponent implements AfterViewInit {
  progressTrackerPercentages: any = {};
  labels = [''];
  pageNumberTotal = 0;
  trackerLabels = ProgressTrackerLabels.AUTO_AND_HOME;

  @Input() progressTrackerPageName: string;
  @Input() progressTrackerFlowName: string;

  setProgressTrackerExperience(progressTrackerFlowName: string): void {
    if (progressTrackerFlowName === ProgressTrackerFlowNames.AUTO) {
      this.labels = ProgressTrackerLabels.AUTO;
      this.progressTrackerPercentages = ProgressTrackerPercentages.AUTO;
      this.pageNumberTotal = this.labels.length;
    } else if (
      progressTrackerFlowName === ProgressTrackerFlowNames.AUTO_AND_HOME
    ) {
      this.labels = ProgressTrackerLabels.AUTO_AND_HOME;
      this.progressTrackerPercentages =
        ProgressTrackerPercentages.AUTO_AND_HOME;
      this.pageNumberTotal = this.labels.length;
    }
  }

  ngOnInit(): void {
    this.setProgressTrackerExperience(this.progressTrackerFlowName);
  }

  ngAfterViewInit(): void {
    setTimeout(this.setProgressBarAriaInfo, 250);
  }

  setProgressBarAriaInfo(): void {
    const progressBar = document.getElementsByClassName('progress-bar')[0];
    progressBar.setAttribute('aria-label', 'Auto and Home Quote progress bar');
    progressBar.ariaValueText =
      'Page: ' + ProgressTrackerLabels.AUTO_AND_HOME[this.getPageNumber() - 1];
  }

  getPageNumber(): number {
    const keys = Object.keys(this.progressTrackerPercentages);
    return keys.indexOf(this.progressTrackerPageName) + 1;
  }
}
