import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { PrivateLabelService } from '@core/services/private-label.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomUIElements } from '@shared/constants/private-label-constants';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mq-underwriting-disclaimer',
  templateUrl: './underwriting-disclaimer.component.html',
  styleUrls: ['./underwriting-disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnderwritingDisclaimerComponent implements OnInit {
  privateLabelDisclaimer$: Observable<string>;

  constructor(
    public modal: NgbActiveModal,
    private _privateLabelService: PrivateLabelService
  ) {}

  ngOnInit(): void {
    this.privateLabelDisclaimer$ = this._privateLabelService
      .getPrivateLabelConfiguration()
      .pipe(
        map(config =>
          PrivateLabelContentHelper.getConfigValue(
            config,
            CustomUIElements.ADDTL_FOOTER_DISCLAIMER
          )
        )
      );
  }
}
