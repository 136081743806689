import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';
import * as fromTermLife from '@core/store/reducers/term-life.reducer';
import { TermLifeEmailRequest } from '@core/models/termlife/termlife.entity';
import {
  TermLifeCvgAmtCodes,
  TermLifeYearTerms,
} from '@shared/constants/app-constants';
import { StringUtils } from '@shared/utils/string.utils';

export const getTermLifeState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.termLife
);

export const {
  selectAll: selectAllTermLifePeople,
  selectEntities: selectTermLifeEntities,
  selectTotal: selectTermLifePeopleTotal,
} = fromTermLife.adapter.getSelectors(getTermLifeState);

export const getTermLifeEligiblePeople = createSelector(
  selectAllTermLifePeople,
  termLifePeople =>
    termLifePeople.filter(termLifePerson => termLifePerson.termLifeEligible)
);

export const getUpdatedTermLifePeople = createSelector(
  selectAllTermLifePeople,
  termLifePeople =>
    termLifePeople.filter(person => person.hasTermLifeDataChanged)
);

export const getLifeTermPeopleLoading = createSelector(
  getTermLifeState,
  state => state.loading
);

export const getTermLifeEntityById = (termLifePersonId: string) =>
  createSelector(selectAllTermLifePeople, termLifePeople =>
    termLifePeople.find(
      termLifePerson => termLifePerson.id === termLifePersonId
    )
  );

export const buildEmailQuoteEstimateRequest = (event: any) =>
  createSelector(
    getTermLifeEntityById(event.termLifePerson.id),
    termLifePerson => {
      const termLifeEmailRequest: TermLifeEmailRequest = {
        coverageTerm: TermLifeYearTerms[termLifePerson.coverageTerm],
        coverageAmount: TermLifeCvgAmtCodes[termLifePerson.coverageAmount],
        toAddress: [event.termLifeEmail],
        costPerMonth: StringUtils.generateDollarAmountFromNumber(
          termLifePerson.costEstimate
        ),
      };
      const request = {
        id: event.termLifePerson.id,
        termLifeEmailRequest: termLifeEmailRequest,
        termLifePerson: termLifePerson,
      };
      return request;
    }
  );
