import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AddressInputComponent } from './address-input.component';

import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, NgbTypeaheadModule],
  declarations: [AddressInputComponent],
  exports: [AddressInputComponent],
})
export class AddressInputModule {}
