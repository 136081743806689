import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { AgencyModel } from '@core/models/agency/agency.model';
import { Product } from '@core/models/products/product.model';
import { Store } from '@ngrx/store';
import * as RouterActions from '@core/store/actions/router.action';
import { AppState } from '@core/store';
import { AgencyService, LoggingService } from '@core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nw-agency-card',
  templateUrl: './agency-card.component.html',
  styleUrls: ['./agency-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyCardComponent implements OnInit, OnDestroy {
  @Input()
  agent: AgencyModel;

  @Input() dsmProducts: Product[];
  @Input() routeUrl: string;

  private _ngUnsubscribe = new Subject();

  showSearchQuote = false;

  constructor(
    private _store: Store<AppState>,
    private _agencyService: AgencyService,
    private _loggingService: LoggingService
  ) {}

  startNewQuote() {
    this._store.dispatch(new RouterActions.NewQuote());
  }

  ngOnInit(): void {
    this._agencyService
      .getAgency()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(agency => {
        if (agency) {
          if (
            (agency.agencyChannel === 'IA' || agency.agencyChannel === 'EA') &&
            !agency.isPrivateLabel
          ) {
            this.showSearchQuote = true;
          }
        }
      });
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  deepLaunchToPC(quoteId: string) {
    return this._agencyService.buildPCDeepLaunchUrl(quoteId);
  }

  logDeepLaunchClickEvent(quoteId: string, product: string) {
    this._loggingService.log('DEEP_LAUNCH_CLICKED', {
      quoteId: quoteId,
      product: product,
    });
  }
}
