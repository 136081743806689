import { Action } from '@ngrx/store';
import { PrepopulatedInsured } from '@core/models/prepopulated-insured/prepopulated-insured.model';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import {
  DriverEntity,
  PersonViewModel,
} from '@core/models/entities/driver.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';

export const enum PrepopulatedInsuredActionTypes {
  LOAD_PREPOPULATED_INSURED_DATA = '[Prepopulated Insured] Load Prepopulated Insured',
  LOAD_PREPOPULATED_INSURED_DATA_FAIL = '[Prepopulated Insured] Load Prepopulated Insured Fail',
  LOAD_PREPOPULATED_INSURED_DATA_SUCCESS = '[Prepopulated Insured] Load Prepopulated Insured Success',

  ADD_PREPOPULATED_INSURED_POLICYHOLDERS = '[Prepopulated Insured] Add Prepopulated Insured Policy Holders',
  ADD_PREPOPULATED_INSURED_DRIVERS = '[Prepopulated Insured] Add Prepopulated Insured Drivers',
  ADD_PREPOPULATED_INSURED_VEHICLES = '[Prepopulated Insured] Add Prepopulated Insured Vehicles',

  UPDATE_PREPOPULATED_INSURED_VEHICLE = '[Prepopulated Insured] Update Prepopulated Vehicle',
  UPDATE_PREPOPULATED_INSURED_VEHICLE_SUCCESS = '[Prepopulated Insured] Update Prepopulated Vehicle Success',
  REMOVE_PREPOPULATED_INSURED_VEHICLE = '[Prepopulated Insured] Remove Prepopulated Vehicle',

  UPDATE_PREPOPULATED_VEHICLE_SELECTED = '[Prepopulated Insured] Update Prepopulated Vehicle Selected',
  UPDATE_PREPOPULATED_DRIVER_SELECTED = '[Prepopulated Insured] Update Prepopulated Driver Selected',
}

export class LoadPrepopulatedInsuredData implements Action {
  readonly type = PrepopulatedInsuredActionTypes.LOAD_PREPOPULATED_INSURED_DATA;
}

export class LoadPrepopulatedInsuredDataFail implements Action {
  readonly type =
    PrepopulatedInsuredActionTypes.LOAD_PREPOPULATED_INSURED_DATA_FAIL;
  constructor(public error: any) {}
}

export class LoadPrepopulatedInsuredDataSuccess implements Action {
  readonly type =
    PrepopulatedInsuredActionTypes.LOAD_PREPOPULATED_INSURED_DATA_SUCCESS;
  constructor(public payload: PrepopulatedInsured) {}
}

export class AddPrepopulatedInsuredPolicyHolders implements Action {
  readonly type =
    PrepopulatedInsuredActionTypes.ADD_PREPOPULATED_INSURED_POLICYHOLDERS;
  constructor(public payload: PolicyholderEntity[]) {}
}

export class AddPrepopulatedInsuredDrivers implements Action {
  readonly type =
    PrepopulatedInsuredActionTypes.ADD_PREPOPULATED_INSURED_DRIVERS;
  constructor(public payload: DriverEntity[]) {}
}

export class AddPrepopulatedInsuredVehicles implements Action {
  readonly type =
    PrepopulatedInsuredActionTypes.ADD_PREPOPULATED_INSURED_VEHICLES;
  constructor(public payload: VehicleEntity[]) {}
}

export class UpdatePrepopulatedInsuredVehicle implements Action {
  readonly type =
    PrepopulatedInsuredActionTypes.UPDATE_PREPOPULATED_INSURED_VEHICLE;
  constructor(public payload: VehicleEntity) {}
}

export class UpdatePrepopulatedInsuredVehicleSuccess implements Action {
  readonly type =
    PrepopulatedInsuredActionTypes.UPDATE_PREPOPULATED_INSURED_VEHICLE_SUCCESS;
  constructor(public payload: VehicleEntity) {}
}

export class RemovePrepopulatedInsuredVehicle implements Action {
  readonly type =
    PrepopulatedInsuredActionTypes.REMOVE_PREPOPULATED_INSURED_VEHICLE;
  constructor(public payload: VehicleEntity) {}
}

export class UpdatePrepopulatedVehicleSelected implements Action {
  readonly type =
    PrepopulatedInsuredActionTypes.UPDATE_PREPOPULATED_VEHICLE_SELECTED;
  constructor(public payload: VehicleEntity) {}
}

export class UpdatePrepopulatedDriverSelected implements Action {
  readonly type =
    PrepopulatedInsuredActionTypes.UPDATE_PREPOPULATED_DRIVER_SELECTED;
  constructor(public payload: PersonViewModel) {}
}

export type PrepopulatedInsuredActions =
  | LoadPrepopulatedInsuredData
  | LoadPrepopulatedInsuredDataFail
  | LoadPrepopulatedInsuredDataSuccess
  | AddPrepopulatedInsuredPolicyHolders
  | AddPrepopulatedInsuredDrivers
  | AddPrepopulatedInsuredVehicles
  | UpdatePrepopulatedVehicleSelected
  | UpdatePrepopulatedDriverSelected
  | UpdatePrepopulatedInsuredVehicle
  | UpdatePrepopulatedInsuredVehicleSuccess
  | RemovePrepopulatedInsuredVehicle;
