import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RequiredIfDirective } from './required-if.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [RequiredIfDirective],
  exports: [RequiredIfDirective],
})
export class RequiredIfModule {}
