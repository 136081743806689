import * as fromPlaid from '@core/store/actions/plaid.action';

export interface PlaidState {
  linkToken: string;
  accountType: string;
  accountNumber: string;
  balance: string;
  bankName: string;
  routingNumber: string;
  plaidRetrieveCount: number;
  isGetLinkTokenCalled: boolean;
  loading: boolean;
  isMostRecentPlaidExchangeTokenFail: boolean;
}

export const initialState: PlaidState = {
  linkToken: null,
  accountType: null,
  accountNumber: null,
  balance: null,
  bankName: null,
  routingNumber: null,
  plaidRetrieveCount: 0,
  isGetLinkTokenCalled: false,
  loading: false,
  isMostRecentPlaidExchangeTokenFail: false,
};

export function reducer(
  state = initialState,
  action: fromPlaid.PlaidActions
): PlaidState {
  switch (action.type) {
    case fromPlaid.CREATE_PLAID_LINK_TOKEN_SUCCESS: {
      const linkToken = action.payload.link_token;
      return {
        ...state,
        linkToken,
        isGetLinkTokenCalled: true,
      };
    }

    case fromPlaid.CREATE_PLAID_LINK_TOKEN_FAIL: {
      const linkToken = action.payload.link_token;
      return {
        ...state,
        isGetLinkTokenCalled: true,
      };
    }

    case fromPlaid.PLAID_EXCHANGE_TOKEN_SUCCESS: {
      const accountNumber = action.payload.accountNumber;
      const routingNumber = action.payload.routingNumber;
      const balance = action.payload.balance;
      const accountType = action.payload.accountType;
      const bankName = action.payload.bankName;
      const plaidRetrieveCount = state.plaidRetrieveCount + 1;
      const isMostRecentPlaidExchangeTokenFail = false;
      return {
        ...state,
        accountNumber,
        routingNumber,
        balance,
        accountType,
        bankName,
        plaidRetrieveCount,
        isMostRecentPlaidExchangeTokenFail,
      };
    }

    case fromPlaid.PLAID_EXCHANGE_TOKEN_FAIL: {
      const isMostRecentPlaidExchangeTokenFail = true;
      return {
        ...state,
        isMostRecentPlaidExchangeTokenFail,
      };
    }

    case fromPlaid.GET_BANK_NAME_SUCCESS: {
      let bankName = 'BankName';
      if (action.payload) {
        bankName = action.payload.fullName;
      }
      return {
        ...state,
        bankName,
      };
    }
  }
  return state;
}
