import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  switchMap,
  take,
  tap,
  catchError,
  mergeMap,
  map,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services/logging.service';
import { PartialQuoteService } from '@core/services/partial-quote.service';
import { PartialQuoteRequest } from '@core/models/partial-quote/partial-quote.model';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { AutoService } from '@core/services';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { PrivateLabel } from '@core/models/private-label/private-label.model';
import { GetVinDetailsRequest } from '@core/models/auto/vehicle-vin.model';

@Injectable()
export class PartialQuoteEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _partialQuoteService: PartialQuoteService,
    private _autoService: AutoService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  loadPartialQuoteData$ = this._actions$.pipe(
    ofType(fromActions.PartialQuoteActionTypes.LOAD_PARTIAL_QUOTE_DATA),
    mergeMap(() => {
      return this._store
        .select(fromSelectors.buildPartialQuoteRequest())
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('loadPartialQuoteData Request', request)
    ),
    switchMap((data: { request: PartialQuoteRequest; config: PrivateLabel }) =>
      this._partialQuoteService.getPartialQuote(data.request).pipe(
        take(1),
        tap(response =>
          this._loggingService.log('loadPartialQuoteData Response', response)
        ),
        switchMap(response => {
          return this._partialQuoteService.getPartialQuoteActions(
            response,
            data.config
          );
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('loadPartialQuoteData Error', safeError);
          return of(new fromActions.LoadPartialQuoteDataFail(safeError));
        })
      )
    )
  );

  @Effect()
  updatePartialQuoteVehicle$ = this._actions$.pipe(
    ofType(fromActions.PartialQuoteActionTypes.UPDATE_PARTIAL_QUOTE_VEHICLE),
    map((action: fromActions.UpdatePartialQuoteVehicle) => action.payload),
    tap(payload => {
      this._loggingService.log('updatePartialQuoteVehicle Request', payload);
    }),
    mergeMap(payload =>
      this._autoService
        .getVinDetails({
          vehicleIdentificationNumber: payload.vin,
          partialQuoteId: payload.partialQuoteId,
          vehicleId: payload.vehicleTempId?.toString(),
        } as GetVinDetailsRequest)
        .pipe(
          take(1),
          tap(vinResponse => {
            this._loggingService.log(
              'updatePartialQuoteVehicle Response',
              vinResponse
            );
          }),
          switchMap(vinResponse => {
            return this._autoService
              .getMakesByYear(
                vinResponse?.retrieve17DigitVehicleIdentificationNumberOdbiResponse.vehicleModel.modelYear.toString()
              )
              .pipe(take(1))
              .pipe(
                switchMap(makeDetailsResponse => {
                  if (!vinResponse) {
                    return of(
                      new fromActions.RemovePartialQuoteVehicle(payload)
                    );
                  }
                  let makeDescription;
                  const vehicleResponse =
                    vinResponse
                      ?.retrieve17DigitVehicleIdentificationNumberOdbiResponse
                      .vehicleModel;

                  if (
                    makeDetailsResponse.retrieveVehicleModelMakesResponse.vehicleMake.find(
                      makeDetails => makeDetails.make === vehicleResponse.make
                    )
                  ) {
                    makeDescription =
                      makeDetailsResponse.retrieveVehicleModelMakesResponse.vehicleMake.find(
                        makeDetails => makeDetails.make === vehicleResponse.make
                      ).makeDescription;
                  }
                  const vehicle: VehicleEntity = {
                    ...payload,
                    vehicleTempId: payload.vehicleTempId,
                    partialQuoteId: payload.partialQuoteId,
                    year: vehicleResponse.modelYear.toString(),
                    make: vehicleResponse.make,
                    makeDescription: makeDescription,
                    model: vehicleResponse.model,
                    modelDescription: vehicleResponse.seriesDescription,
                  };
                  return of(
                    new fromActions.UpdatePartialQuoteVehicleSuccess(vehicle)
                  );
                })
              );
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log(
              'updatePartialQuoteVehicle Error',
              safeError
            );
            return of(new fromActions.RemovePartialQuoteVehicle(payload));
          })
        )
    )
  );
}
