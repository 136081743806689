import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { SelectOption } from '@core/models/select-option.model';
import { AntiTheftDevices } from '@core/models/auto/vehicle-antiTheftDevice-states.model';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { VehiclePrimaryUseOptions } from '@core/models/auto/vehicle-primary-use.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

@Injectable()
export class MetadataAdapter {
  constructor(private _http: HttpClient) {}

  getSuffix(): Observable<{ suffix: SelectOption[] }> {
    return this._http
      .get<any>('assets/api/metadata/suffix.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getMaritalStatus(): Observable<string[]> {
    return this._http
      .get<string[]>('assets/api/metadata/marital-status.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getOfferingType(): Observable<string[]> {
    return this._http
      .get<string[]>('assets/api/metadata/offering-type.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getStateSpecificFlags(): Observable<StateSpecificFlagsObject> {
    return this._http.get<StateSpecificFlagsObject>(
      'assets/api/metadata/stateSpecificFlags.json'
    );
  }

  getThirdParties(): Observable<string[]> {
    return this._http
      .get<string[]>('assets/api/metadata/third-parties.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getStateAbbreviations(): Observable<string[]> {
    return this._http
      .get<string[]>('assets/api/metadata/state-abbreviations.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getAgencyChannel(): Observable<string[]> {
    return this._http
      .get<string[]>('assets/api/metadata/agency-channel.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getFeatures(): Observable<any[]> {
    return this._http
      .get<any[]>('assets/api/metadata/features.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getConnectedCarCriteria(): Observable<any> {
    return this._http
      .get<any>('assets/api/metadata/connected-car-criteria.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getAntiTheftDevices(): Observable<AntiTheftDevices> {
    const url = '../../../assets/api/metadata/auto/auto-antiTheftDevices.json';

    return this._http
      .get<AntiTheftDevices>(url)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getVehicleEligibleDiscounts(): Observable<EligibleDiscountEntity[]> {
    const url = '../../../assets/api/metadata/auto/auto-eligibleDiscounts.json';

    return this._http
      .get<EligibleDiscountEntity[]>(url)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getVehiclePrimaryUseOptions(): Observable<VehiclePrimaryUseOptions> {
    const url = '../../../assets/api/metadata/auto/auto-primary-use.json';

    return this._http
      .get<VehiclePrimaryUseOptions>(url)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }
}
