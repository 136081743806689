import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { PageAlertService, PrivateLabelService } from '@core/services';
import { PageAlertIDs } from '@shared/constants/app-constants';
import { Observable } from 'rxjs';

@Component({
  selector: 'mq-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceComponent implements OnInit {
  isPrivateLabel$: Observable<boolean>;

  constructor(
    private _privateLabelService: PrivateLabelService,
    private _pageAlertService: PageAlertService
  ) {}

  ngOnInit(): void {
    this._pageAlertService.dispatchActiveAlert(PageAlertIDs.maintenance);
    this.isPrivateLabel$ = this._privateLabelService.isPrivateLabel();
  }
}
