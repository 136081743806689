import { DriverService } from '@core/services/driver.service';
import { PolicyholderService } from '@core/services/policyholder.service';
import { forkJoin } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { PolicyAddressService } from '@core/services/policy-address.service';
import { LoggingService, ProductsService } from '@core/services';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable()
export class DriverAsPolicyholderGuard implements CanActivate {
  constructor(
    private _driverService: DriverService,
    private _policyholderService: PolicyholderService,
    private _policyAddressService: PolicyAddressService,
    private _loggingService: LoggingService,
    private _productsService: ProductsService
  ) {}

  canActivate() {
    return forkJoin([
      this._driverService.getAll().pipe(take(1)),
      this._policyholderService.getAll().pipe(take(1)),
      this._policyAddressService.getAll().pipe(take(1)),
      this._productsService
        .isProductSelectedWithoutError(ProductTypes.AUTO)
        .pipe(take(1)),
    ]).pipe(
      map(([drivers, policyholders, policyAddresses, isAutoSelected]) => {
        if (isAutoSelected && policyholders.length && !drivers.length) {
          for (const policyholder of policyholders) {
            const driver = this.synthesizeDriverFromPolicyholder(policyholder);
            if (policyAddresses.length > 0) {
              driver.licenseState = policyAddresses[0].state;
            }
            this._driverService.add(driver);
          }
        }

        return true;
      })
    );
  }

  synthesizeDriverFromPolicyholder(
    policyholder: PolicyholderEntity
  ): DriverEntity {
    const driver = <DriverEntity>{
      driverId: policyholder.driverId, // Probably unset, if we ended up here.
      person: policyholder.person,
      driverType: 'Driver',
      relationToPrimaryNamedInsured: this.relationToPniFromPolicyholderType(
        policyholder.policyHolderType
      ),
      isFinancialFilingRequired: false,
      productId: ProductTypes.AUTO,
      policyHolderId: policyholder.policyHolderId,
    };

    if (driver.person.dateOfBirth.indexOf('*') >= 0) {
      this._loggingService.logToConsole(
        "Inserting fake DOB in Driver because Policyholder's is masked."
      );
      driver.person = {
        ...driver.person,
        dateOfBirth: '1980-03-28', // XXX This must be fixed somehow!
      };
    }

    return driver;
  }

  /* Valid outputs are: Spouse, Parent, Child, HouseholdMember, PrimaryNamedInsured, Other
   * Valid inputs are: PolicyPriNamedInsured, PolicySecNamedInsured, PolicyAddlNamedInsured
   */
  relationToPniFromPolicyholderType(input: string): string {
    switch (input) {
      case 'PolicyPriNamedInsured':
        return 'PrimaryNamedInsured';
      case 'PolicySecNamedInsured':
        return 'Other';
      case 'PolicyAddlNamedInsured':
        return 'Other';
    }
    return 'Other';
  }
}
