import { Injectable } from '@angular/core';
import { of, from } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services/logging.service';
import { AdditionalInterestService } from '@core/services/additional-interest.service';
import { AdditionalInterestBuilder } from '@shared/utils/builders/additional-interest.builder';
import { AdditionalInterestEntity } from '@core/models/entities/additional-interest.entity';
import { ProductTypes } from '@shared/constants/app-constants';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable()
export class AdditionalInterestEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _additionalInterestService: AdditionalInterestService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  addAdditionalInterest$ = this._actions$.pipe(
    ofType(fromActions.AdditionalInterestActionTypes.ADD_ADDITIONAL_INTEREST),
    map((actions: fromActions.AddAdditionalInterest) => actions.payload),
    switchMap(entity => {
      return this._store
        .select(fromSelectors.buildAdditionalInterestRequest(entity))
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('addAdditionalInterest request', request)
    ),
    switchMap(request =>
      this._additionalInterestService.addAdditionalInterest(request).pipe(
        tap(response =>
          this._loggingService.log('addAdditionalInterest Response', response)
        ),
        switchMap(response => {
          const entity =
            AdditionalInterestBuilder.buildAdditionalInterestEntityFromResponse(
              response,
              request
            );

          return from([
            new fromActions.AddAdditionalInterestSuccess(entity),
            new fromActions.InvalidateQuote(ProductTypes.AUTO),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('addAdditionalInterest Error', safeError);
          return of(new fromActions.AddAdditionalInterestFail(safeError));
        })
      )
    )
  );

  @Effect()
  updateAdditionalInterest$ = this._actions$.pipe(
    ofType(
      fromActions.AdditionalInterestActionTypes.UPDATE_ADDITIONAL_INTEREST
    ),
    map((actions: fromActions.UpdateAdditionalInterest) => actions.payload),
    switchMap(entity => {
      return this._store
        .select(fromSelectors.buildAdditionalInterestRequest(entity))
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('updateAdditionalInterest request', request)
    ),
    switchMap(request =>
      this._additionalInterestService.updateAdditionalInterest(request).pipe(
        tap(response =>
          this._loggingService.log(
            'updateAdditionalInterest Response',
            response
          )
        ),
        switchMap(response => {
          const entity =
            AdditionalInterestBuilder.buildAdditionalInterestEntityFromResponse(
              response,
              request
            );

          const updated: Update<AdditionalInterestEntity> = {
            id: entity.additionalInterestId,
            changes: entity,
          };
          if (request.productId === ProductTypes.AUTO) {
            return from([
              new fromActions.UpdateAdditionalInterestSuccess(updated),
              new fromActions.InvalidateQuote(ProductTypes.AUTO),
            ]);
          } else {
            return from([
              new fromActions.UpdateAdditionalInterestSuccess(updated),
              new fromActions.InvalidateQuote(ProductTypes.POWERSPORTS),
            ]);
          }
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updateAdditionalInterest Error', safeError);
          return of(new fromActions.AddAdditionalInterestFail(safeError));
        })
      )
    )
  );

  @Effect()
  deleteAdditionalInterest$ = this._actions$.pipe(
    ofType(
      fromActions.AdditionalInterestActionTypes.DELETE_ADDITIONAL_INTEREST
    ),
    map((actions: fromActions.DeleteAdditionalInterest) => actions.payload),
    switchMap((additionalInterestId: string) =>
      this._store
        .select(fromSelectors.getAdditionalInterestEntity(additionalInterestId))
        .pipe(take(1))
    ),
    switchMap(entity =>
      this._store
        .select(fromSelectors.buildAdditionalInterestRequest(entity))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('deleteAdditionalInterest request', request)
    ),
    switchMap(request =>
      this._additionalInterestService.deleteAdditionalInterest(request).pipe(
        tap(response =>
          this._loggingService.log(
            'deleteAdditionalInterest Response',
            response
          )
        ),
        switchMap(response => {
          return from([
            new fromActions.DeleteAdditionalInterestSuccess(
              request.additionalInterest
            ),
            new fromActions.InvalidateQuote(ProductTypes.AUTO),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('deleteAdditionalInterest Error', safeError);
          return of(new fromActions.DeleteAdditionalInterestFail(safeError));
        })
      )
    )
  );
}
