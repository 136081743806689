import {
  NgModule,
  ModuleWithProviders,
  APP_INITIALIZER,
  InjectionToken,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

// ngrx
import { StoreModule, MetaReducer, ActionReducer } from '@ngrx/store';
import { RouterStateSerializer } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';

// services
import * as fromServices from './services';

// adapters
import * as fromAdapters from './adapters';

// daos
import * as fromDaos from './dao';

// guards
import * as fromGuards from './guards';

// store
import {
  appReducers,
  routerReducers,
  effects,
  CustomSerializer,
} from './store';

import { environment } from '../../environments/environment';
import {
  PrivateLabelService,
  AppConfigService,
  UserContextService,
  PageAlertService,
  PolicyService,
  AgencyService,
} from './services';
import {
  DEFAULT_SPID,
  DSM_STAGE_TO_TEST_TARGET_ENV,
  NationwideContactNumber,
  RIVIAN_THIRDPARTY_ID,
  VERISK_THIRDPARTY_IDs,
} from '@shared/constants/app-constants';
import { AppRouteUtils } from '@shared/utils/app-route.utils';
import { QuoteStartDateFetcher } from '@admin/containers/quote-start/quote-start-date-fetcher';
import { take } from 'rxjs/operators';
import * as RouterActions from '@core/store/actions/router.action';
import { UAParser } from 'ua-parser-js';
import { StringUtils } from '@shared/utils/string.utils';

export const metaReducers: MetaReducer<any>[] = environment.production
  ? [clearState]
  : [clearState, storeFreeze];

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === RouterActions.NEW_QUOTE) {
      state = {
        app: {
          agency: state.app.agency,
          userContext: state.app.userContext,
          pageAlerts: state.app.pageAlerts,
          policy: state.app.policy,
          privateLabel: state.app.privateLabel,
        },
      };
    }
    return reducer(state, action);
  };
}

const AppConfigDeps = new InjectionToken<(() => any)[]>('configDeps');

const appInitializerFn = (
  appConfig: fromServices.AppConfigService,
  configDeps: (() => any)[]
) => {
  return (): Promise<any> => {
    return appConfig.loadAppConfig().then(() => {
      return Promise.all(configDeps.map(dep => dep()));
    });
  };
};

const privateLabelInitializerFn = (
  privateLabelService: PrivateLabelService
) => {
  return () => {
    const agencyIdentifier = AppRouteUtils.getBaseHref();
    agencyIdentifier !== ''
      ? privateLabelService.loadPrivateLabelConfiguration(
          agencyIdentifier.split('/')[1]
        )
      : privateLabelService.loadPrivateLabelConfiguration();
  };
};

const pathParamPartnerInitializerFn = (
  _userContextService: UserContextService
) => {
  return () => {
    const spidIdentifier = AppRouteUtils.getBaseHref();
    VERISK_THIRDPARTY_IDs.forEach(key => {
      if (
        StringUtils.strToLower(spidIdentifier.split('/')[1]) ===
        StringUtils.strToLower(key)
      ) {
        _userContextService.updateUserContext({
          spid: key,
        });
        return;
      }
    });
  };
};

const userContextInitializerFn = (
  _appConfigService: AppConfigService,
  _userContextService: UserContextService,
  _agencyService: AgencyService
) => {
  return () => {
    const params = _appConfigService.getUrlParameters();
    if (!params) {
      _userContextService.updateUserContext({
        spid: DEFAULT_SPID,
      });
      return;
    }
    let spid = null;
    const thirdPartyId = params.get('thirdPartyId');
    if (params.get('SPID')) {
      spid = params.get('SPID');
    } else if (thirdPartyId !== RIVIAN_THIRDPARTY_ID) {
      spid = params.get('thirdPartyId');
    }
    const referrer = params.get('referrer');
    const initiatedBy = params.get('initiatedBy');
    const gtm = params.get('gtm');
    const telephoneNumber = params.get('TFN');
    const agencyName = params.get('agencyName');
    const agencyPhoneNum = params.get('agencyPhNo');
    const producerCode = params.get('agencyCode');
    const agencySource = params.get('agencySource');
    const agencyChannel = params.get('agencyChannel');
    const state = params.get('agencyState');
    const guid = params.get('GUID');
    const otp = params.get('OTP') || params.get('otp');
    const privateLabelPreview = params.get('plPreview');
    const prequalifiedPartnerScoreId = params.get('prequalifiedPartnerScoreId');
    const redirectUrl = params.get('redirectUrl');
    const utm_medium = params.get('utm_medium') || params.get('UTM_medium');
    const utm_source = params.get('utm_source') || params.get('UTM_source');
    const utm_content = params.get('utm_content') || params.get('UTM_content');
    const partialQuoteId = params.get('partialQuoteId');

    if (agencySource) {
      const newPhoneNumber = StringUtils.formatTelephoneNumber(agencyPhoneNum);
      _agencyService.updateAgency({
        agencyName,
        phoneNumber:
          newPhoneNumber && newPhoneNumber === NationwideContactNumber
            ? agencyPhoneNum
            : newPhoneNumber,
        producerCode,
        agencySource,
        agencyChannel,
        state,
      });
    }
    _userContextService.updateUserContext({
      spid: !!spid ? spid : DEFAULT_SPID,
      referrer,
      initiatedBy,
      gtm,
      telephoneNumber:
        telephoneNumber && telephoneNumber.length >= 10
          ? telephoneNumber
          : null,
      guid,
      otp,
      prequalifiedPartnerScoreId,
      privateLabelPreview: privateLabelPreview === 'true',
      redirectUrl,
      thirdPartyId,
      utm_medium,
      utm_source,
      utm_content,
      partialQuoteId,
    });
  };
};

const pageAlertsInitializerFn = (_pageAlertService: PageAlertService) => {
  return () => {
    _pageAlertService.loadPageAlerts();
  };
};

const effectiveDateInitializerFn = (
  _appConfigService: AppConfigService,
  _dateFetcher: QuoteStartDateFetcher,
  _policyService: PolicyService
) => {
  return () => {
    if (_appConfigService.isTest() && _appConfigService.config.targetEnv) {
      const env = DSM_STAGE_TO_TEST_TARGET_ENV[
        _appConfigService.config.targetEnv
      ]
        ? DSM_STAGE_TO_TEST_TARGET_ENV[_appConfigService.config.targetEnv]
        : _appConfigService.config.targetEnv;
      _dateFetcher
        .fetchDate(env)
        .pipe(take(1))
        .subscribe(() => {
          _dateFetcher
            .streamResults()
            .pipe(take(1))
            .subscribe(date => {
              _policyService.setPolicyEffectiveDate({
                initialized: true,
                autoEffectiveDate: date,
                homeownersEffectiveDate: date,
                condoEffectiveDate: date,
                rentersEffectiveDate: date,
                baseEffectiveDate: date,
              });
            });
        });
    }
  };
};

export function appConfigDependencyFactory(
  appConfigService: AppConfigService,
  userContextService: UserContextService,
  privateLabelService: PrivateLabelService,
  pageAlertService: PageAlertService,
  dateFetcher: QuoteStartDateFetcher,
  policyService: PolicyService,
  agencyService: AgencyService
) {
  // additional dependencies can be added here
  return [
    userContextInitializerFn(
      appConfigService,
      userContextService,
      agencyService
    ),
    privateLabelInitializerFn(privateLabelService),
    pageAlertsInitializerFn(pageAlertService),
    effectiveDateInitializerFn(appConfigService, dateFetcher, policyService),
    pathParamPartnerInitializerFn(userContextService),
  ];
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(routerReducers, { metaReducers }),
    StoreModule.forFeature('app', appReducers),
    EffectsModule.forRoot(effects),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    TitleCasePipe,
    { provide: 'UAParser', useFactory: () => new UAParser() },
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        Title,
        { provide: RouterStateSerializer, useClass: CustomSerializer },
        ...fromServices.services,
        ...fromAdapters.adapters,
        ...fromDaos.daos,
        ...fromGuards.guards,
        {
          provide: APP_INITIALIZER,
          useFactory: appInitializerFn,
          multi: true,
          deps: [fromServices.AppConfigService, AppConfigDeps],
        },
        {
          provide: AppConfigDeps,
          // Use a factory that return an array of dependant functions to be executed
          // App Config can be injected here if dependency needs it
          useFactory: appConfigDependencyFactory,
          deps: [
            AppConfigService,
            UserContextService,
            PrivateLabelService,
            PageAlertService,
            QuoteStartDateFetcher,
            PolicyService,
            AgencyService,
          ],
        },
      ],
    };
  }
}
