import { Action } from '@ngrx/store';

export const enum NonStandardActionTypes {
  NON_STANDARD_BRIDGE = '[Rating] Non-Standard Rating',
}

export class NonStandardBridge implements Action {
  readonly type = NonStandardActionTypes.NON_STANDARD_BRIDGE;
  constructor(public productId: string, public reason: string) {}
}

export type NonStandardActions = NonStandardBridge;
