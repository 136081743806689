import { Action } from '@ngrx/store';
import { HelpContentModel } from '@help-content/models/help-content.model';

export const SET_HELP_CONTENT = '[Help Content] Set Help Content';

export class SetHelpContent implements Action {
  readonly type = SET_HELP_CONTENT;
  constructor(public payload: HelpContentModel) {}
}

export type Actions = SetHelpContent;
