import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { PropertyInfoProxy } from '@quote/components/property-info/proxies/property-info.proxy';
import { combineLatest, Observable, Subject } from 'rxjs';
import { HomeownersModel } from '@core/models/homeowners/homeowners.model';
import { ProductTypes } from '@shared/constants/app-constants';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { CondoModel } from '@core/models/condo/condo.model';
import { RentersModel } from '@core/models/renters/renters.model';
import { ProtectiveDevice } from '@core/store/reducers/protective-devices.reducer';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Animations } from '@shared/animations/animations';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { PropertyHelper } from '@core/services/helpers/property.helper';
import { CustomValidators } from '@core/validators/custom-validators';
import { take, takeUntil } from 'rxjs/operators';
import { PolicyEffectiveDates } from '@core/models/policy/policy-effective-date.model';
import { RecoverableErrorService } from '@core/services/recoverable-error.service';
import { LoggingService } from '@core/services';
import { Product } from '@core/models/products/product.model';
import { RetrieveService } from '@core/services/retrieve.service';
import { DateUtils } from '@shared/utils/date.utils';

declare let Bootstrapper: any;

@Component({
  selector: 'mq-property-info',
  templateUrl: './property-info.component.html',
  styleUrls: ['./property-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [Animations.slideIn, Animations.create],
})
export class PropertyInfoComponent implements OnInit, OnDestroy {
  readonly progressTrackerPageName = 'homeDetails';
  form: UntypedFormGroup;
  isAppLoading$: Observable<boolean>;
  policyholder$: Observable<PolicyholderEntity>;
  homeowners$: Observable<HomeownersModel>;
  condo$: Observable<CondoModel>;
  renters$: Observable<RentersModel>;
  options$: Observable<any>;
  policyEffectiveDates$: Observable<PolicyEffectiveDates>;
  isSessionProgressTrackerEligible$: Observable<boolean>;
  isPropertyOnlySelected$: Observable<boolean>;
  sessionProgressTrackerFlowName$: Observable<string>;
  isProgressTrackerEligible: boolean;
  progressTrackerFlowName: string;

  isAgent$: Observable<boolean>;

  coveredLocation$: Observable<CoveredLocation>;
  productId: string;

  protectiveDeviceOptions$: Observable<any>;
  protectiveDevices$: Observable<ProtectiveDevice[]>;

  formSubmitted$ = new Subject<boolean>();
  yearBuilt$: Observable<number>;
  requiredOilTank: Observable<boolean>;
  stateSpecificFlags$: Observable<any>;
  smartHomeDiscountForHome$: Observable<EligibleDiscountEntity>;
  smartHomeDiscountForCondo$: Observable<EligibleDiscountEntity>;
  homeownersProduct$: Observable<Product>;
  yearsWithPriorCarrierCurrent: string;
  yearsWithPriorCarrierOptions = ['0', '1', '2', '3', '4', '5 or more'];
  retrievedQuotes$: Observable<any>;
  retrievedYearsWithPriorCarrier: any;
  yearsWithPriorCarrierRetrieved;
  estimatedYearPurchasedRetrieved;
  isAuto: boolean;
  isPowersports: boolean;
  isHome: boolean;
  isCondo: boolean;
  isRenters: boolean;
  smartHomeExperience: string;
  hasMortgageForRetrieve$: Observable<boolean>;
  hasMortgageForRetrieve: boolean;
  partialQuoteId$: Observable<string>;
  partialQuoteCoveredLocation$: Observable<CoveredLocation>;
  isPropertyOnlySelected: boolean;

  isRoofConditionForCondoApplicable: boolean;

  private _ngUnsubscribe = new Subject();

  constructor(
    private _proxy: PropertyInfoProxy,
    private _propertyHelper: PropertyHelper,
    private _fb: UntypedFormBuilder,
    private _cd: ChangeDetectorRef,
    private _recoverableErrorService: RecoverableErrorService,
    private _loggingService: LoggingService,
    private _retrieveService: RetrieveService
  ) {}

  ngOnInit(): void {
    this._proxy.logPageLoaded();
    this._proxy
      .getSelectedProperty()
      .pipe(take(1))
      .subscribe(productId => (this.productId = productId));
    this._proxy
      .isProductSelectedWithoutError(ProductTypes.AUTO)
      .pipe(take(1))
      .subscribe(product => (this.isAuto = product));
    this._proxy
      .isProductSelectedWithoutError(ProductTypes.POWERSPORTS)
      .pipe(take(1))
      .subscribe(product => (this.isPowersports = product));
    if (!this.isAuto && !this.isPowersports) {
      this._proxy.skipNextPageNavigation('/multi-quote/vehicles');
    }
    // TODO Maybe get to this

    this.isAppLoading$ = this._proxy.isAppLoading();
    this.isAgent$ = this._proxy.isAgent();

    this.protectiveDevices$ = this._proxy.getAllProtectiveDevices();
    this.protectiveDeviceOptions$ = this._proxy.getProtectiveDevicesOptions();

    this.stateSpecificFlags$ = this._proxy.getStateSpecificFlags();

    this.policyholder$ = this._proxy.getPrimaryNamedInsured();
    this.policyEffectiveDates$ = this._proxy.getPolicyEffectiveDate();
    this.isPropertyOnlySelected$ = this._proxy.isPropertyOnlySelected();
    this.isPropertyOnlySelected$.subscribe(response => {
      this.isPropertyOnlySelected = response;
    });

    if (this.productId === ProductTypes.HOMEOWNERS) {
      this.isHome = true;
      this.form = this._fb.group({
        homeownersForm: this._propertyHelper.buildHomeownerForm(this._fb),
        protectiveDevicesForm: this._fb.group({}),
        yearsWithPriorCarrier: this._fb.control('', Validators.required),
        smarthomeForm: this._fb.group({
          optIn: this._fb.control(false, []),
        }),
      });
      this.homeowners$ = this._proxy.getHomeowners();
      this.coveredLocation$ = this._proxy.getCoveredLocation();
      this._proxy.getAllRenovation();
      this.options$ = this._proxy.getPropertyInfoHomeownerPicklistData();

      this.smartHomeDiscountForHome$ = this._proxy.getSmartHomeDiscount(
        'SmartDevice',
        ProductTypes.HOMEOWNERS
      );

      this.homeownersProduct$ = this._proxy.getProduct(ProductTypes.HOMEOWNERS);
      this.homeownersProduct$.subscribe(
        p => (this.yearsWithPriorCarrierCurrent = p.yearsWithPriorCarrier)
      );
      if (this.yearsWithPriorCarrierCurrent) {
        this.form.patchValue({
          yearsWithPriorCarrier: this.yearsWithPriorCarrierCurrent,
        });
      }
      this.retrievedQuotes$ = this._retrieveService.getRetrievedEntities(
        ProductTypes.HOMEOWNERS
      );
      this.retrievedQuotes$.subscribe(q => {
        this.yearsWithPriorCarrierRetrieved =
          q[+ProductTypes.HOMEOWNERS]?.response.yearsWithPriorCarrier;
        this.estimatedYearPurchasedRetrieved =
          q[+ProductTypes.HOMEOWNERS]?.response.coveredLocation.datePurchased;
      });
      if (
        this.yearsWithPriorCarrierRetrieved &&
        this.estimatedYearPurchasedRetrieved
      ) {
        this.form.patchValue({
          yearsWithPriorCarrier: this.yearsWithPriorCarrierRetrieved,
        });
      }
      this.hasMortgageForRetrieve$ = this._retrieveService
        .getHasMortgageForRetrieve()
        ?.pipe(take(1));
      this.hasMortgageForRetrieve$?.subscribe(
        hasMortgageForRetrieve =>
          (this.hasMortgageForRetrieve = hasMortgageForRetrieve)
      );
      if (this.homeownersForm.value.hasMortgage.value === undefined) {
        this.homeownersForm.patchValue({
          hasMortgage: this.hasMortgageForRetrieve,
        });
      }
    }

    this.isSessionProgressTrackerEligible$ =
      this._proxy.getSessionProgressTrackerEligibility();
    this.sessionProgressTrackerFlowName$ =
      this._proxy.getProgressTrackerFlowName();

    this.isSessionProgressTrackerEligible$.subscribe(progressTrackerCheck => {
      this.isProgressTrackerEligible = progressTrackerCheck === true;
    });
    this.sessionProgressTrackerFlowName$.subscribe(flowNameCheck => {
      this.progressTrackerFlowName = flowNameCheck;
    });

    if (this.productId === ProductTypes.CONDO) {
      this.isCondo = true;
      this.form = this.form = this._fb.group({
        condoForm: this._propertyHelper.buildCondoForm(this._fb),
        protectiveDevicesForm: this._fb.group({}),
        smarthomeForm: this._fb.group({
          optIn: this._fb.control(false, []),
        }),
        // oilTankForm: this._propertyHelper.buildOilTankForm(this._fb),
      });
      this.condo$ = this._proxy.getCondo();
      this.coveredLocation$ = this._proxy.getCoveredLocation();
      this.options$ = this._proxy.getPropertyInfoCondoPicklistData();

      this.options$.subscribe(response =>
        this._proxy.addCondoPickList(response)
      );

      this.smartHomeDiscountForCondo$ = this._proxy.getSmartHomeDiscount(
        'SmartDevice',
        ProductTypes.CONDO
      );
      this.hasMortgageForRetrieve$ = this._retrieveService
        .getHasMortgageForRetrieve()
        ?.pipe(take(1));
      this.hasMortgageForRetrieve$?.subscribe(
        hasMortgageForRetrieve =>
          (this.hasMortgageForRetrieve = hasMortgageForRetrieve)
      );
      if (this.condoForm.value.hasMortgage.value === undefined) {
        this.condoForm.patchValue({ hasMortgage: this.hasMortgageForRetrieve });
      }
    }

    if (this.productId === ProductTypes.RENTERS) {
      this.isRenters = true;
      this.renters$ = this._proxy.getRenters();
      this.form = this._fb.group({
        rentersForm: this._propertyHelper.buildRenterForm(this._fb),
        protectiveDevicesForm: this._fb.group({}),
      });
      this.options$ = this._proxy.getPropertyInfoRentersPicklistData();

      this.options$.subscribe(response =>
        this._proxy.addRentersPickList(response)
      );
    }

    this.form?.statusChanges
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(status => {
        this._cd.detectChanges();
      });
    this.partialQuoteId$ = this._proxy.getPartialQuoteId();
    this.partialQuoteCoveredLocation$ =
      this._proxy.getPartialQuoteCoveredLocation();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  onBack(): void {
    this._proxy.navigateBack();
  }

  onSubmit() {
    this.formSubmitted$.next(true);
    this._recoverableErrorService.clearRecoverableErrors();

    if (!this.form.valid) {
      CustomValidators.MarkFormGroupTouched(this.form);
      this._cd.detectChanges();
      return;
    }
    if (this.form.valid && this.isPropertyOnlySelected) {
      this._proxy.failProductWith9996(
        this.isHome
          ? ProductTypes.HOMEOWNERS
          : this.isRenters
          ? ProductTypes.RENTERS
          : ProductTypes.CONDO
      );
      this._proxy.softfall(
        this.isHome
          ? ProductTypes.HOMEOWNERS
          : this.isRenters
          ? ProductTypes.RENTERS
          : ProductTypes.CONDO,
        'HO323'
      );
      return;
    }
    if (this.isHome) {
      if (this.isRoofAgeGreaterthan9() && this.isPropertyOnlySelected) {
        this._proxy.failProductWith9996(ProductTypes.HOMEOWNERS);
        this._proxy.softfall(ProductTypes.HOMEOWNERS, 'HO322');
        return;
      }
      this._proxy.addHomeowners({
        hasMortgage: this.homeownersForm.value.hasMortgage,
        primary: true,
        // prettier-ignore
        estimatedYearPurchased: this.homeownersForm.value.estimatedYearPurchased,
      });
      this._proxy.updateRenovations(
        this.homeownersForm.value.renovationsForm.renovations
      );
      this._proxy.updateCoveredLocation(
        ProductTypes.HOMEOWNERS,
        this._propertyHelper.formatCoveredLocation(
          this.homeownersForm.value,
          this._propertyHelper.serializeProtectiveDevices(
            this.protectiveDevicesForm.value
          )
        )
      );
      this.submitYearsWithPriorCarrier();
    }
    if (this.isCondo) {
      this._proxy.addCondo({
        hasMortgage: this.condoForm.value.hasMortgage,
        primary: true,
        roofCondition: this.condoForm.value.roofCondition,
        estimatedYearPurchased: this.condoForm.value.estimatedYearPurchased,
      });
      this._proxy.updateCoveredLocation(
        ProductTypes.CONDO,
        this._propertyHelper.formatCondoCoveredLocation(
          this.condoForm.value,
          this._propertyHelper.serializeProtectiveDevices(
            this.protectiveDevicesForm.value
          )
        )
      );
      this.submitRoofConditionForCondoApplicable();
    }
    if (this.isRenters) {
      const form = this.rentersForm.value;
      this._proxy.updateRenters({
        id: '0',
        // prettier-ignore
        personalPropertyCoverage: this.rentersForm.value.personalPropertyCoverage,
        numberOfOccupants: this.rentersForm.value.numberOfOccupants,
        unitsInBuilding: this.rentersForm.value.unitsInBuilding,
        structuralMaterial: this.rentersForm.value.structuralMaterial,
      });
      this._proxy.updateCoveredLocation(ProductTypes.RENTERS, {
        numberOfOccupants: this.rentersForm.value.numberOfOccupants,
        unitsInBuilding: +this.rentersForm.value.unitsInBuilding,
        constructionInfo: {
          constructionType: 'F',
        },
        protectiveDevices: this._propertyHelper.formatProtectiveDevicesRequest(
          this._propertyHelper.serializeProtectiveDevices(
            this.protectiveDevicesForm.value
          )
        ),
      });
    }
    if (this.smarthomeForm && this.smarthomeForm.dirty) {
      this.submitSmartHome();
    }
    this._proxy.logPageSubmit();
    this._proxy.navigateIfAnyProductValid();
  }

  setSmartHomeExperience(event: string) {
    this.smartHomeExperience = event;
  }

  private submitSmartHome() {
    const optInChoice = this.smarthomeForm.value.optIn
      ? 'Participating'
      : 'Declined';
    this._loggingService.logToSplunk({
      event: 'SMARTHOME_PO_FORM_SUBMITTED_PROPERTYINFO',
      message: JSON.stringify({
        optInChoice: optInChoice,
      }),
    });
    this._proxy.dispatchUpdateSmartHomeDiscount(optInChoice);
    this.sendSmartHomeOptInChoiceToCelebrus();
  }
  private sendSmartHomeOptInChoiceToCelebrus(): void {
    if (this.productId === ProductTypes.HOMEOWNERS) {
      if (
        Bootstrapper?.Celebrus &&
        Bootstrapper?.Celebrus?.sendCelebrusCustomAttribute
      ) {
        const key = 'Digex_534_smarthome';
        const smartHomeCelebrusAttributeValue =
          (this.smarthomeForm.value.optIn ? 'yes' : 'no') +
          '|' +
          this.smartHomeExperience;

        Bootstrapper.Celebrus.sendCelebrusCustomAttribute(
          key,
          smartHomeCelebrusAttributeValue
        );

        this._loggingService.logToSplunk({
          event: 'SMARTHOME_PO_PARTICIPATIONCHOICE_LOGGED',
          message: JSON.stringify({
            key: key,
            value: smartHomeCelebrusAttributeValue,
          }),
        });
      } else {
        this._loggingService.logToSplunk({
          event: 'SMARTHOME_PO_BOOTSTRAPPERCELEBRUS_UNDEFINED_PROPERTYINFO',
        });
      }
    }
  }

  private submitYearsWithPriorCarrier() {
    combineLatest([this.homeownersProduct$])
      .pipe(take(1))
      .subscribe(([homeownersProduct]) => {
        const updatedProduct = Object.assign({}, homeownersProduct, {
          yearsWithPriorCarrier: this.yearsWithPriorCarrier.value,
        });
        this._proxy.updateProduct(updatedProduct);
        if (!this.yearsWithPriorCarrier.value.startsWith('0')) {
          this._proxy.dispatchPatchQuote(ProductTypes.HOMEOWNERS, {
            yearsWithPriorCarrier: this.yearsWithPriorCarrier.value,
          });
        }
      });
  }

  submitRoofConditionForCondoApplicable() {
    this._proxy
      .isRoofConditionForCondoApplicable()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(
        isRoofConditionForCondoApplicable =>
          (this.isRoofConditionForCondoApplicable =
            isRoofConditionForCondoApplicable)
      );
    if (this.isRoofConditionForCondoApplicable) {
      this._proxy.removeCurrentPageNavigation('/multi-quote/property-info');
      this._proxy.failProduct(ProductTypes.CONDO);
    }
  }

  isRoofAgeGreaterthan9(): boolean {
    if (
      Number(DateUtils.getYear()) -
        this.form.value.homeownersForm.renovationsForm.renovations[0].year >=
      10
    ) {
      return true;
    }
    return false;
  }

  get homeownersForm() {
    return this.form.get('homeownersForm');
  }

  get condoForm() {
    return this.form.get('condoForm');
  }

  get rentersForm() {
    return this.form.get('rentersForm');
  }

  get smarthomeForm() {
    return this.form.get('smarthomeForm');
  }

  get protectiveDevicesForm() {
    return this.form?.get('protectiveDevicesForm');
  }

  get yearsWithPriorCarrier() {
    return this.form.get('yearsWithPriorCarrier');
  }
}
