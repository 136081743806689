<div *ngIf="name" class="col-xs-12">
  <div
    *ngIf="
      name === 'SmartMiles' &&
      dataCollectionMethod !== 'ConnectedCar' &&
      !(showSmartMilesCA$ | async) &&
      !shouldOfferSmartMiles()
    "
  >
    <div class="col-sm-auto">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/0gr3h00f07"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
  *ngIf="
    name === 'SmartMiles' &&
    dataCollectionMethod !== 'ConnectedCar' &&
    !(showSmartMilesCA$ | async) &&
    shouldOfferSmartMiles()
  "
>
  <div class="col-sm-auto">
    <iframe
      id="kaltura_player"
      src="https://fast.wistia.net/embed/iframe/sz3evovhsk"
      width="225"
      height="130"
      allowfullscreen
      webkitallowfullscreen
      mozAllowFullScreen
      allow="autoplay *; fullscreen *; encrypted-media *"
      sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
      title="Kaltura Player"
    ></iframe>
  </div>
</div>
  <div
    *ngIf="
      name === 'SmartMiles' &&
      dataCollectionMethod !== 'ConnectedCar' &&
      (showSmartMilesCA$ | async) &&
      !shouldOfferSmartMiles()
    "
  >
    <div class="col-sm-auto">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/wx339ur1nh"
        width="225"
        height="130"
        allow="autoplay *; fullscreen *; encrypted-media *"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
    *ngIf="
      name === 'SmartMiles' &&
      dataCollectionMethod !== 'ConnectedCar' &&
      (showSmartMilesCA$ | async) &&
      shouldOfferSmartMiles()
    "
  >
    <div class="col-sm-auto">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/sz3evovhsk"
        width="225"
        height="130"
        allow="autoplay *; fullscreen *; encrypted-media *"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
  *ngIf="
    name === 'SmartMiles' &&
    dataCollectionMethod !== 'ConnectedCar' &&
    (showSmartMilesCA$ | async) &&
    !shouldOfferSmartMiles()
  "
>
  <div class="col-sm-auto">
    <iframe
      id="kaltura_player"
      src="https://fast.wistia.net/embed/iframe/wx339ur1nh"
      width="225"
      height="130"
      allow="autoplay *; fullscreen *; encrypted-media *"
      allowfullscreen
      webkitallowfullscreen
      mozAllowFullScreen
      sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
      title="Kaltura Player"
    ></iframe>
  </div>
</div>
  <div
    *ngIf="
      name === 'SmartMiles' &&
      dataCollectionMethod === 'ConnectedCar' &&
      !(showSmartMilesCA$ | async) &&
      !shouldOfferSmartMiles()
    "
  >
    <div class="col-sm-auto">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/qhct0inzsu"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
    *ngIf="
      name === 'SmartMiles' &&
      dataCollectionMethod === 'ConnectedCar' &&
      !(showSmartMilesCA$ | async) &&
      shouldOfferSmartMiles()
    "
  >
    <div class="col-sm-auto">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/to0c60pa94"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
    *ngIf="
      name === 'SmartMiles' &&
      dataCollectionMethod === 'ConnectedCar' &&
      (showSmartMilesCA$ | async) &&
      !shouldOfferSmartMiles()
    "
  >
    <div class="col-sm-auto">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/d0y67fkkla"
        width="225"
        height="130"
        allow="autoplay *; fullscreen *; encrypted-media *"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
  *ngIf="
    name === 'SmartMiles' &&
    dataCollectionMethod === 'ConnectedCar' &&
    (showSmartMilesCA$ | async) &&
    shouldOfferSmartMiles()
  "
>
  <div class="col-sm-auto">
    <iframe
      id="kaltura_player"
      src="https://fast.wistia.net/embed/iframe/to0c60pa94"
      width="225"
      height="130"
      allow="autoplay *; fullscreen *; encrypted-media *"
      allowfullscreen
      webkitallowfullscreen
      mozAllowFullScreen
      sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
      title="Kaltura Player"
    ></iframe>
  </div>
</div>
  <div
    *ngIf="
      name === 'SmartRide' &&
      !isDeviceOnlyState &&
      !mobileAppEnrolled &&
      isSmartRideEarlyRewardApplicable()
    "
  >
    <div class="col-sm-auto">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/bo1d4q2qh2"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
    *ngIf="
      name === 'SmartRide' &&
      isDeviceOnlyState &&
      !mobileAppEnrolled &&
      isSmartRideEarlyRewardApplicable()
    "
  >
    <div class="col-sm-auto">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/y4ny6n0242"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
    *ngIf="
      name === 'SmartRide' &&
      !isDeviceOnlyState &&
      mobileAppEnrolled &&
      isSmartRideEarlyRewardApplicable()
    "
  >
    <div class="col-sm-auto" *ngIf="initialDiscount === '10%'">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/y4ny6n0242"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
    <div class="col-sm-auto" *ngIf="initialDiscount === '15%'">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/8bdf3qrmik"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
    *ngIf="
      name === 'SmartRide' &&
      !isDeviceOnlyState &&
      !mobileAppEnrolled &&
      !isSmartRideEarlyRewardApplicable()
    "
  >
    <div class="col-sm-auto" *ngIf="initialDiscount === '10%'">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/ogpab41xtf"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
    <div class="col-sm-auto" *ngIf="initialDiscount === '15%'">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/kcy7jkn4pm"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
    *ngIf="
      name === 'SmartRide' &&
      isDeviceOnlyState &&
      !mobileAppEnrolled &&
      !isSmartRideEarlyRewardApplicable()
    "
  >
    <div class="col-sm-auto" *ngIf="initialDiscount === '5%'">
      <iframe
        id="kaltura_player"
        src=" https://fast.wistia.net/embed/iframe/tudcww8rpg"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
    <div class="col-sm-auto" *ngIf="initialDiscount === '10%'">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/pi6ykjrq8b"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div
    *ngIf="
      name === 'SmartRide' &&
      !isDeviceOnlyState &&
      mobileAppEnrolled &&
      !isSmartRideEarlyRewardApplicable()
    "
  >
    <div class="col-sm-auto" *ngIf="initialDiscount === '10%'">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/m9alb1a91v"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
    <div class="col-sm-auto" *ngIf="initialDiscount === '15%'">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/kcy7jkn4pm"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div *ngIf="name === 'PreQualified'">
    <div class="col-sm-auto">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/7endo69yvu"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
  <div *ngIf="name === 'ConnectedCar'">
    <div class="col-sm-auto">
      <iframe
        id="kaltura_player"
        src="https://fast.wistia.net/embed/iframe/msyxqpqkt1"
        width="225"
        height="130"
        allowfullscreen
        webkitallowfullscreen
        mozAllowFullScreen
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        title="Kaltura Player"
      ></iframe>
    </div>
  </div>
</div>
