import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { Effect, ofType, Actions } from '@ngrx/effects';
import {
  switchMap,
  take,
  catchError,
  map,
  tap,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { TelematicsEnrollmentsService } from '@core/services/telematics-enrollments.service';
import {
  ProductTypes,
  TelematicsEnrollmentStatus,
  TelematicsProgram,
} from '@shared/constants/app-constants';
import { Product } from '@core/models/products/product.model';
import {
  LoggingService,
  ProductsService,
  UserContextService,
} from '@core/services';
import { InitiatedByType } from '@core/models/user-context/user-context.model';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable()
export class TelematicsEnrollmentsEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _telematicsEnrollmentsService: TelematicsEnrollmentsService,
    private _loggingService: LoggingService,
    private _userContextService: UserContextService,
    private _errorHelper: ErrorHelper,
    private _productsService: ProductsService
  ) {}

  @Effect()
  getTelematicsRecommendation$ = this._actions$.pipe(
    ofType(
      fromActions.TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION
    ),
    map((action: fromActions.GetTelematicsRecommendation) => action.payload),
    switchMap(getTelematicsResponse =>
      this._store
        .select(fromSelectors.buildGetTelematicsRecommendationRequest())
        .pipe(
          take(1),
          map(request => [request, getTelematicsResponse])
        )
    ),
    tap(request =>
      this._loggingService.log('getTelematicsRecommendation Request', request)
    ),
    withLatestFrom(
      this._userContextService.getUserContext(),
      this._store.select(fromSelectors.getAllVehicles),
      this._store.select(fromSelectors.getProduct(ProductTypes.AUTO))
    ),
    switchMap(
      ([
        [request, getTelematicsResponse],
        userContext,
        dsmVehicles,
        product,
      ]) => {
        return this._telematicsEnrollmentsService
          .getTelematicsRecommendation(request, product.quoteId)
          .pipe(
            tap(response =>
              this._loggingService.log(
                'getTelematicsRecommendation Response',
                response
              )
            ),
            switchMap(response => {
              const actions: any[] = [];
              if (!getTelematicsResponse?.enrollmentId) {
                actions.push(
                  new fromActions.GetTelematicsRecommendationSuccess(response)
                );
              }
              if (
                userContext.prequalifiedPartnerScoreId &&
                response.recommendedProgram &&
                response.qualifyingInformation.vehicles &&
                response.qualifyingInformation.vehicles.length <
                  dsmVehicles.length
              ) {
                const vehicles = response.qualifyingInformation.vehicles;
                actions.push(
                  new fromActions.UpdateTelematicsEnrollmentForPreQualifiedPartnerIdFlow(
                    {
                      vehicles,
                    }
                  )
                );
              } else if (response.recommendedProgram) {
                // add the best fit program as default enrollment selection
                actions.push(new fromActions.AddTelematicsEnrollment(response));
              } else {
                actions.push(
                  new fromActions.GetTelematicsRecommendationWithLoadedSuccess(
                    response
                  )
                );
              }
              return from(actions);
            }),
            catchError(error => {
              const safeError = this._errorHelper.sanitizeError(error);
              this._loggingService.log(
                'getTelematicsRecommendation Error',
                safeError
              );
              return of(
                new fromActions.GetTelematicsRecommendationFail(safeError)
              );
            })
          );
      }
    )
  );

  @Effect()
  addTelematicsEnrollment$ = this._actions$.pipe(
    ofType(
      fromActions.TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT
    ),
    map((action: fromActions.AddTelematicsEnrollment) => action.payload),
    switchMap(payload =>
      this._store
        .select(fromSelectors.buildAddTelematicsEnrollmentRequest(payload))
        .pipe(take(1))
    ),
    tap(request => {
      if (
        request.enrollment.mobileEnrollment === null &&
        request.enrollment.vehicleEnrollment === null
      ) {
        return;
      } else
        this._loggingService.log('addTelematicsEnrollment Request', request);
    }),
    switchMap(request => {
      if (
        request.enrollment.mobileEnrollment === null &&
        request.enrollment.vehicleEnrollment === null
      ) {
        return of(new fromActions.AddTelematicsEnrollmentFail(request));
      } else
        return this._telematicsEnrollmentsService
          .addTelematicsEnrollment(request)
          .pipe(
            tap(response => {
              this._loggingService.log(
                'addTelematicsEnrollment Response',
                response
              );
            }),
            switchMap(response => {
              return from([
                new fromActions.InvalidateQuote(ProductTypes.AUTO),
                new fromActions.InvalidateBind(ProductTypes.AUTO),
                new fromActions.AddTelematicsEnrollmentSuccess(response),
                new fromActions.SetInitializePaymentPlanRequired(true),
              ]);
            }),
            catchError(error => {
              const safeError = this._errorHelper.sanitizeError(error);
              this._productsService.failProduct(ProductTypes.AUTO);
              this._loggingService.log(
                'addTelematicsEnrollment Error',
                safeError
              );
              return of(new fromActions.AddTelematicsEnrollmentFail(safeError));
            })
          );
    })
  );

  @Effect()
  updateTelematicsEnrollment$ = this._actions$.pipe(
    ofType(
      fromActions.TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_ENROLLMENT
    ),
    map((action: fromActions.UpdateTelematicsEnrollment) => action.payload),
    switchMap(payload =>
      this._store
        .select(fromSelectors.buildUpdateTelematicsEnrollmentRequest(payload))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('updateTelematicsEnrollment Request', request)
    ),
    switchMap(request => {
      return this._telematicsEnrollmentsService
        .updateTelematicsEnrollment(request)
        .pipe(
          tap(response =>
            this._loggingService.log(
              'updateTelematicsEnrollment Response',
              response
            )
          ),
          switchMap(response => {
            const actions = [];
            actions.push(new fromActions.InvalidateQuote(ProductTypes.AUTO));
            actions.push(new fromActions.InvalidateBind(ProductTypes.AUTO));
            actions.push(
              new fromActions.UpdateTelematicsEnrollmentSuccess(response)
            );
            if (
              response.vehicleEnrollment &&
              response.vehicleEnrollment.vehicles.length
            ) {
              if (
                response.vehicleEnrollment.vehicles.find(vehicle => {
                  return (
                    vehicle.enrollmentStatus ===
                      TelematicsEnrollmentStatus.ENROLLED &&
                    vehicle.vehicleProgram === TelematicsProgram.SMART_MILES
                  );
                })
              ) {
                actions.push(
                  new fromActions.SetInitializePaymentPlanRequired(true)
                );
              }
            }
            return from(actions);
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log(
              'updateTelematicsEnrollment Error',
              safeError
            );
            return of(
              new fromActions.UpdateTelematicsEnrollmentFail(safeError)
            );
          })
        );
    })
  );

  @Effect()
  updateTelematicsEnrollmentForPreQualifiedPartnerIdFlow$ = this._actions$.pipe(
    ofType(
      fromActions.TelematicsEnrollmentsActionTypes
        .ADD_TELEMATICS_ENROLLMENT_FOR_PREQUALIFIEDPARTNERID_FLOW
    ),
    map(
      (
        action: fromActions.UpdateTelematicsEnrollmentForPreQualifiedPartnerIdFlow
      ) => action.payload
    ),
    switchMap(payload =>
      this._store
        .select(
          fromSelectors.buildUpdateTelematicsEnrollmentRequestForPreQualifiedPartnerIdFlow(
            payload
          )
        )
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('updateTelematicsEnrollment Request', request)
    ),
    switchMap(request => {
      return this._telematicsEnrollmentsService
        .updateTelematicsEnrollment(request)
        .pipe(
          tap(response =>
            this._loggingService.log(
              'updateTelematicsEnrollment Response',
              response
            )
          ),
          switchMap(response => {
            return from([
              new fromActions.InvalidateQuote(ProductTypes.AUTO),
              new fromActions.InvalidateBind(ProductTypes.AUTO),
              new fromActions.UpdateTelematicsEnrollmentSuccess(response),
            ]);
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log(
              'updateTelematicsEnrollment Error',
              safeError
            );
            return of(
              new fromActions.UpdateTelematicsEnrollmentFail(safeError)
            );
          })
        );
    })
  );

  @Effect()
  updateTelematicsEnrollmentForMobileNoInfo$ = this._actions$.pipe(
    ofType(
      fromActions.TelematicsEnrollmentsActionTypes
        .UPDATE_MOBILE_INFO_TELEMATICS_ENROLLEMENT
    ),
    map(
      (action: fromActions.UpdateMobileInfoTelematicsEnrollment) =>
        action.payload
    ),
    switchMap(payload =>
      this._store
        .select(
          fromSelectors.buildUpdateMobileInfoTelematicsEnrollmentRequest(
            payload
          )
        )
        .pipe(take(1))
    ),
    switchMap(request => {
      return this._telematicsEnrollmentsService
        .updateMobileInfoTelematicsEnrollment(request)
        .pipe(
          tap(response =>
            this._loggingService.log(
              'UpdateMobileInfoTelematicsEnrollment Response',
              response
            )
          ),
          switchMap(response => {
            return from([
              new fromActions.UpdateMobileInfoTelematicsEnrollmentSuccess(
                response
              ),
            ]);
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log(
              'UpdateMobileInfoTelematicsEnrollment Error',
              safeError
            );
            return of(
              new fromActions.UpdateMobileInfoTelematicsEnrollmentFail(
                safeError
              )
            );
          })
        );
    })
  );

  @Effect()
  removeTelematicsEnrollment$ = this._actions$.pipe(
    ofType(
      fromActions.TelematicsEnrollmentsActionTypes.REMOVE_TELEMATICS_ENROLLMENT
    ),
    switchMap(() =>
      this._store
        .select(fromSelectors.buildRemoveTelematicsEnrollmentRequest)
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('removeTelematicsEnrollment Request', request)
    ),
    switchMap(request => {
      if (request.enrollmentId)
        return this._telematicsEnrollmentsService
          .removeTelematicsEnrollment(request)
          .pipe(
            tap(response =>
              this._loggingService.log(
                'removeTelematicsEnrollment Response',
                response
              )
            ),
            switchMap(response => {
              return from([
                new fromActions.InvalidateQuote(ProductTypes.AUTO),
                new fromActions.InvalidateBind(ProductTypes.AUTO),
                new fromActions.RemoveTelematicsEnrollmentSuccess(response),
              ]);
            }),
            catchError(error => {
              const safeError = this._errorHelper.sanitizeError(error);
              this._loggingService.log(
                'removeTelematicsEnrollment Error',
                safeError
              );
              return of(
                new fromActions.RemoveTelematicsEnrollmentFail(safeError)
              );
            })
          );
    })
  );

  @Effect()
  getTelematicsEnrollments$ = this._actions$.pipe(
    ofType(
      fromActions.TelematicsEnrollmentsActionTypes.GET_TELEMATICS_ENROLLMENTS
    ),
    switchMap(() =>
      this._store
        .select(fromSelectors.getProduct(ProductTypes.AUTO))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('getTelematicsEnrollments Request', request)
    ),
    map((product: Product) => product.quoteId),
    tap(quoteId =>
      this._loggingService.log('getTelematicsEnrollments Request', quoteId)
    ),
    switchMap(quoteId =>
      this._telematicsEnrollmentsService
        .getTelematicsEnrollments({ quoteId: quoteId })
        .pipe(
          tap(response =>
            this._loggingService.log(
              'getTelematicsEnrollments Response',
              response
            )
          ),
          withLatestFrom(
            this._userContextService.getUserContext(),
            this._store.select(fromSelectors.getAllVehicles)
          ),
          switchMap(([response, userContext, vehicles]) => {
            const actions = [];
            let preQualVehicleInfo = [];
            const notYetEnrolledPrequalifiedVehicles = [];
            if (
              userContext.initiatedBy !== InitiatedByType.COMPRATER
              // If Comp Rater retrieval, we want to bypass any telematics assignment.
            ) {
              if (response.vehicleEnrollment) {
                preQualVehicleInfo = response.vehicleEnrollment.vehicles
                  ? response.vehicleEnrollment.vehicles
                      .filter(
                        veh =>
                          !!veh.discountPercentage &&
                          veh.enrollmentStatus ===
                            TelematicsEnrollmentStatus.PRE_QUALIFIED
                      )
                      .map(veh => ({
                        vehicleId: veh.vehicleId,
                        discount: veh.discountPercentage,
                      }))
                  : [];
                let unenrolledVehicles;
                if (!userContext.prequalifiedPartnerScoreId) {
                  unenrolledVehicles =
                    vehicles.length -
                    response.vehicleEnrollment.vehicles.length -
                    notYetEnrolledPrequalifiedVehicles.length;
                } else {
                  unenrolledVehicles =
                    response.vehicleEnrollment.vehicles.filter(
                      veh =>
                        veh.enrollmentStatus ===
                        TelematicsEnrollmentStatus.NOT_ENROLLED
                    );
                }
                if (unenrolledVehicles.length > 0) {
                  actions.push(
                    new fromActions.GetTelematicsRecommendation(response)
                  );
                } else {
                  actions.push(
                    new fromActions.GetTelematicsEnrollmentsSuccess(response)
                  );
                  actions.push(
                    new fromActions.GetTelematicsRecommendation(response)
                  );
                }
              } else if (
                (!response.vehicleEnrollment ||
                  !Object.keys(response.vehicleEnrollment).length) &&
                vehicles.length - notYetEnrolledPrequalifiedVehicles.length > 0
              ) {
                actions.push(
                  new fromActions.GetTelematicsRecommendation(response)
                );
              } else if (notYetEnrolledPrequalifiedVehicles.length > 0) {
                this._telematicsEnrollmentsService.dispatchSetTelematicsEnrollment(
                  response
                );
                actions.push(
                  new fromActions.GetTelematicsRecommendation(response)
                );
                actions.push(new fromActions.AddTelematicsEnrollment({}));
              } else {
                actions.push(
                  new fromActions.GetTelematicsEnrollmentsSuccess(response)
                );
                actions.push(
                  new fromActions.GetTelematicsRecommendation(response)
                );
              }
            } else {
              actions.push(
                new fromActions.GetTelematicsEnrollmentsSuccess(response)
              );
              actions.push(
                new fromActions.GetTelematicsRecommendation(response)
              );
            }
            this._store.dispatch(
              new fromActions.UpdatePreQualifiedVehicleInfo(preQualVehicleInfo)
            );
            return from(actions);
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log(
              'getTelematicsEnrollments Error',
              safeError
            );
            return from([
              new fromActions.GetTelematicsRecommendation(null),
              new fromActions.GetTelematicsEnrollmentsFail(safeError),
            ]);
          })
        )
    )
  );
}
