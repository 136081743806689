import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Product } from '@core/models/products/product.model';
import { LoggingService, PrivateLabelService } from '@core/services';
import { DistributedDocumentsService } from '@core/services/distributed-documents.service';
import { CoverageHelper } from '@core/services/helpers/coverage.helper';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { TokenService } from '@core/services/token.service';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DiscountNameHelper } from '@shared/utils/discount-name.helper';
import { FileSystemUtils } from '@shared/utils/file-system.utils';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { QuoteLetterDetails } from '@core/models/quote-letter/quote-letter-details.model';

@Injectable()
export class QuoteLetterEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _distributedDocumentsService: DistributedDocumentsService,
    private _currencyPipe: CurrencyPipe,
    private _privateLabelService: PrivateLabelService,
    private _fileSystemUtils: FileSystemUtils,
    private _errorHelper: ErrorHelper,
    private _discountNameHelper: DiscountNameHelper,
    private _coverageHelper: CoverageHelper,
    private _tokenService: TokenService
  ) {}

  @Effect()
  loadQuoteLetterPdf$ = this._actions$.pipe(
    ofType(fromActions.LOAD_QUOTE_LETTER),
    switchMap(() =>
      this._store
        .select(fromSelectors.getSelectedQuoteProductsWithoutErrors)
        .pipe(take(1))
    ),
    switchMap((products: Product[]) =>
      this._tokenService.getAccessTokenForProductId(products[0]?.id).pipe(
        filter(token => !!token),
        take(1)
      )
    ),
    map(token => {
      return { accessToken: token.accessToken };
    }),
    switchMap((quoteLetterDetails: QuoteLetterDetails) =>
      this._store.select(fromSelectors.getPrivateLabelConfiguration).pipe(
        take(1),
        map(config => {
          quoteLetterDetails.privateLabelConfig = config;
          return quoteLetterDetails;
        })
      )
    ),
    switchMap((quoteLetterDetails: QuoteLetterDetails) => {
      const config = quoteLetterDetails.privateLabelConfig;
      const shouldSupressLogo = PrivateLabelContentHelper.shouldExcludeLogo(
        quoteLetterDetails.privateLabelConfig
      );
      if (config && config.logoUrl && !shouldSupressLogo) {
        quoteLetterDetails.logoFormat = config.logoUrl.slice(
          config.logoUrl.lastIndexOf('.') + 1
        );
        return this._privateLabelService
          .getPrivateLabelLogo(config.logoUrl)
          .pipe(
            take(1),
            switchMap(blob => {
              return this._fileSystemUtils.readFile(blob).pipe(
                take(1),
                map(base64File => {
                  quoteLetterDetails.base64Logo = base64File.slice(
                    base64File.indexOf(',') + 1
                  );
                  return quoteLetterDetails;
                })
              );
            })
          );
      } else {
        return of(quoteLetterDetails);
      }
    }),
    withLatestFrom(this._store.select(fromSelectors.getQuoteState)),
    switchMap(([quoteLetterDetails, quoteState]) =>
      this._store
        .select(
          fromSelectors.buildQuoteLetterRequest(
            quoteLetterDetails,
            this._currencyPipe,
            quoteState,
            this._coverageHelper,
            this._discountNameHelper
          )
        )
        .pipe(
          take(1),
          map(request => {
            quoteLetterDetails.request = request;
            return quoteLetterDetails;
          })
        )
    ),
    tap((quoteLetterDetails: any) => {
      this._loggingService.log(
        'createQuoteLetter Request',
        quoteLetterDetails.request
      );
    }),
    switchMap((quoteLetterDetails: QuoteLetterDetails) => {
      return this._distributedDocumentsService
        .documents(quoteLetterDetails.request)
        .pipe(
          map(documentsResponse => {
            quoteLetterDetails.response = documentsResponse;
            return quoteLetterDetails;
          }),
          tap((quoteLetterDetails: any) =>
            this._loggingService.log(
              'createQuoteLetter Response',
              quoteLetterDetails.response
            )
          ),
          map(
            (quoteLetterDetails: any) =>
              new fromActions.LoadQuoteLetterSuccess(
                quoteLetterDetails.response.fileOutput
              )
          ),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log('loadQuoteLetter Error', safeError);
            return of(
              new fromActions.LoadQuoteLetterFail(
                this._errorHelper.extractErrorCodeFromAnything(safeError)
              )
            );
          })
        );
    })
  );
}
