<div class="nw-container">
  <div class="row" style="width: 100%">
    <div class="app-brand col">
      <h1>
        <ng-container *ngIf="!!privateLabelConfig; else default">{{
          bannerHeader
        }}</ng-container>
        <ng-template #default>Nationwide Express&nbsp;&#174;</ng-template>
      </h1>
    </div>
  </div>
</div>
