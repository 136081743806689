import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { AppConfigService } from '@core/services/app-config.service';
import { LoggingService } from '@core/services/logging.service';

import { OneTimePasswordResponse } from '@core/models/one-time-password/one-time-password-response.model';
import {
  PciV2Response,
  PciV2CardResponse,
} from '@core/models/pci-v2/pci-v2-response.model';
import { StringUtils } from '@shared/utils/string.utils';
import { parseString } from 'xml2js';
import { BankNameResponseModel } from '@core/models/billing/bank-name-response.model';
import { DownPaymentExperienceResponse } from '@core/models/billing/down-payment/down-payment-expereience-response.model';
import { SetupAccountExperienceRequest } from '@core/models/billing/setup-account/setup-account-experience-request.model';
import { SetupAccountExperienceResponse } from '@core/models/billing/setup-account/setup-account-experience-response.model';
import { AccountVerifyResponse } from '@core/models/billing/billing-response.model';
import { AccountVerifyRequest } from '@core/models/billing/billing-request.model';
import { DownPaymentExperienceRequest } from '@core/models/billing/down-payment/down-payment-experience-request.model';
import { RetryOn503Operator } from '@shared/operators/retryOn-503.operator';

@Injectable()
export class BillingAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService,
    private _loggingService: LoggingService,
    private _retry: RetryOn503Operator
  ) {}

  public RETRY_MS = 1500;

  dsmDownPaymentExperience(
    request: DownPaymentExperienceRequest
  ): Observable<DownPaymentExperienceResponse> {
    const url = `${this._appConfig.config.personalLinesBillingApiUrl}/billing-accounts/1/down-payment-experience`;
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID())
      .set('Authorization', 'Bearer ' + request.accessToken);

    if (!this._appConfig.config.production) {
      headers = headers
        .set('connectionType', this._appConfig.config.billingTargetEnv)
        .set('policyCenterEnv', this._appConfig.config.targetEnv);
    }

    return this._http
      .post<DownPaymentExperienceResponse>(url, request.body, {
        headers,
      })
      .pipe(this._retry.retryOn503(this.RETRY_MS));
  }

  getBankName(routingNumber: string, token: string) {
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-MessageID', StringUtils.generateUUID())
      .set('Authorization', `Bearer ${token}`);

    const url =
      `${this._appConfig.config.moneyProcessingUrl}/banks/` + routingNumber;

    return this._http
      .get<BankNameResponseModel>(url, { headers })
      .pipe(
        tap(response =>
          this._loggingService.logToConsole('Bank Name response:', response)
        )
      );
  }

  oneTimePassword(token: string) {
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID())
      .set('Authorization', `Bearer ${token}`);

    const url = `${this._appConfig.config.multiproductApi}/oneTimePassword`;

    return this._http
      .post<OneTimePasswordResponse>(url, {}, { headers })
      .pipe(
        tap(response =>
          this._loggingService.logToConsole(
            'One-time password response:',
            response
          )
        )
      );
  }

  pciV2(request: string): Observable<PciV2Response> {
    const headers = new HttpHeaders().set(
      'X-NWD-MessageId',
      StringUtils.generateUUID()
    );

    const url = `${this._appConfig.config.pciV2Url}`;

    return this._http
      .post(url, request, { headers, responseType: 'text' })
      .pipe(
        tap(response =>
          this._loggingService.logToConsole('PCIv2 response:', response)
        ),
        map(response => {
          const pciResponse = {} as PciV2Response;
          const card = {} as PciV2CardResponse;
          parseString(response, function (err, result) {
            card.encryptedNumber = result.card.encryptedNumber.toString();
            card.last4Digits = result.card.last4Digits.toString();
            card.recipientCertificateFingerprint =
              result.card.recipientCertificateFingerprint.toString();
            card.sessionKey = result.card.sessionKey.toString();
            card.type = result.card.type.toString();
            pciResponse.card = card;
          });

          return pciResponse;
        })
      );
  }

  setupAccount(
    request: SetupAccountExperienceRequest
  ): Observable<SetupAccountExperienceResponse> {
    this._loggingService.logToConsole('Setup account request:', request);
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-MessageID', StringUtils.generateUUID())
      .set('Authorization', `Bearer ${request.accessToken}`);

    if (!this._appConfig.config.production) {
      headers = headers
        .set('connectionType', this._appConfig.config.billingTargetEnv)
        .set('policyCenterEnv', this._appConfig.config.targetEnv);
    }

    const url = `${this._appConfig.config.moneyProcessingApiUrl}/personal-lines-accounts/1/setup-account-experience`;

    return this._http
      .post<SetupAccountExperienceResponse>(url, request.body, { headers })
      .pipe(
        tap(response => {
          const logger = {};
          logger[`BillingAccountNumber`] = response.accountNumber;
          logger[`ReceiptId`] = response.receiptId;

          this._loggingService.logToSplunk({
            event: `SETUP_ACCOUNT_RESPONSE_SUCCESS`,
            message: logger,
          });

          this._loggingService.logToConsole(
            `Setup account individual response`,
            response
          );
        })
      );
  }

  verifyGiactAccount(
    request: AccountVerifyRequest,
    token: string
  ): Observable<AccountVerifyResponse> {
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID())
      .set('Authorization', `Bearer ${token}`);

    const url = `${this._appConfig.config.giactVerifyUrl}/bank-accounts/verify`;

    return this._http.post<AccountVerifyResponse>(url, request, {
      headers,
    });
  }
}
