/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
export class StateUtils {
  static states = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  };

  static defaultProducerCodes = {
    AL: '0030090',
    AR: '0030090',
    AZ: '0030090',
    CA: '0040459',
    CO: '0030090',
    DC: '0030090',
    DE: '0030090',
    FL: '0030090',
    GA: '0030090',
    IA: '0030090',
    ID: '0030090',
    IL: '0030090',
    IN: '0030090',
    KS: '0030090',
    KY: '0030090',
    MD: '0030090',
    MI: '0030090',
    MT: '0030090',
    ME: '0030090',
    MN: '0030090',
    MO: '0030090',
    MS: '0030090',
    NE: '0030090',
    NC: '0030090',
    ND: '0030090',
    NJ: '0030090',
    NY: '0030090',
    OH: '0030090',
    PA: '0030090',
    RI: '0030090',
    TN: '0030090',
    TX: '0030090',
    UT: '0030090',
    VT: '0030090',
    CT: '0030090',
    NH: '0030090',
    NM: '0030090',
    NV: '0030090',
    WA: '0030090',
    VA: '0030090',
    SC: '0030090',
    SD: '0030090',
    OR: '0030090',
    WI: '0030090',
    WV: '0030090',
    WY: '0030090',
  };

  static trueNorthDefaultProducerCodes = {
    AL: '00012349 - 005',
    AR: '00012349 - 029',
    AZ: '00012349 - 006',
    CA: '00012349 - 021',
    CO: '00012349 - 022',
    CT: '00012349 - 013',
    DC: '00012349 - 023',
    DE: '00012349 - 030',
    FL: '00012349 - 040',
    GA: '00012349 - 015',
    IA: '00012349 - 018',
    ID: '00012349 - 032',
    IL: '00012349 - 004',
    IN: '00012349 - 001',
    KS: '00012349 - 025',
    KY: '00012349 - 019',
    MD: '00012349 - 014',
    ME: '00012349 - 026',
    MI: '00012349 - 042',
    MN: '00012349 - 035',
    MO: '00012349 - 036',
    MS: '00012349 - 037',
    NC: '00012349 - 033',
    NH: '00012349 - 027',
    NM: '00012349 - 038',
    NJ: '00012349 - 049',
    NY: '00012349 - 016',
    OH: '00012349 - 007',
    OK: '00012349 - 039',
    OR: '00012349 - 008',
    PA: '00012349 - 034',
    RI: '00012349 - 031',
    SC: '00012349 - 009',
    TN: '00012349 - 003',
    TX: '00012349 - 010',
    UT: '00012349 - 045',
    VA: '00012349 - 011',
    VT: '00012349 - 028',
    WA: '00012349 - 012',
    WV: '00012349 - 020',
    WY: '00012349 - 047',
  };

  static stateCodes = {
    '01': 'AL',
    '02': 'AZ',
    '03': 'AR',
    '04': 'CA',
    '05': 'CO',
    '06': 'CT',
    '07': 'DE',
    '08': 'DC',
    '09': 'FL',
    '10': 'GA',
    '11': 'ID',
    '12': 'IL',
    '13': 'IN',
    '14': 'IA',
    '15': 'KS',
    '16': 'KY',
    '17': 'LA',
    '18': 'ME',
    '19': 'MD',
    '20': 'MA',
    '21': 'MI',
    '22': 'MN',
    '23': 'MS',
    '24': 'MO',
    '25': 'MT',
    '26': 'NE',
    '27': 'NV',
    '28': 'NH',
    '29': 'NJ',
    '30': 'NM',
    '31': 'NY',
    '32': 'NC',
    '33': 'ND',
    '34': 'OH',
    '35': 'OK',
    '36': 'OR',
    '37': 'PA',
    '38': 'RI',
    '39': 'SC',
    '40': 'SD',
    '41': 'TN',
    '42': 'TX',
    '43': 'UT',
    '44': 'VT',
    '45': 'VA',
    '46': 'WA',
    '47': 'WV',
    '48': 'WI',
    '49': 'WY',
    '52': 'HI',
    '54': 'AK',
    '58': 'PR',
  };

  static stateCodeByState = {
    AL: '01',
    AZ: '02',
    AR: '03',
    CA: '04',
    CO: '05',
    CT: '06',
    DE: '07',
    DC: '08',
    FL: '09',
    GA: '10',
    ID: '11',
    IL: '12',
    IN: '13',
    IA: '14',
    KS: '15',
    KY: '16',
    LA: '17',
    ME: '18',
    MD: '19',
    MA: '20',
    MI: '21',
    MN: '22',
    MS: '23',
    MO: '24',
    MT: '25',
    NE: '26',
    NV: '27',
    NH: '28',
    NJ: '29',
    NM: '30',
    NY: '31',
    NC: '32',
    ND: '33',
    OH: '34',
    OK: '35',
    OR: '36',
    PA: '37',
    RI: '38',
    SC: '39',
    SD: '40',
    TN: '41',
    TX: '42',
    UT: '43',
    VT: '44',
    VA: '45',
    WA: '46',
    WV: '47',
    WI: '48',
    WY: '49',
    HI: '52',
    AK: '54',
    PR: '58',
  };

  static defaultBillingAgencyCode = '63821';

  static mapAbbreviationToFullName(abbr: string): string {
    return StateUtils.states[abbr];
  }

  static defaultProducerCode(state: string): string {
    return StateUtils.defaultProducerCodes[state];
  }

  static mapStateCodeToState(code: string): string {
    return StateUtils.stateCodes[code];
  }

  static mapStateToStateCode(stateAbbrv: string): string {
    return Object.keys(StateUtils.stateCodes).find(
      key => StateUtils.stateCodes[key] === stateAbbrv
    );
  }
}
