import { Injectable } from '@angular/core';

import { PowersportsAdapter } from '@core/adapters/powersports.adapter';

@Injectable()
export class PowersportsService {
  constructor(private _powersportsAdapter: PowersportsAdapter) {}

  validateLicense(licenseNumber: string, licenseState: string) {
    return this._powersportsAdapter.validateLicense(
      licenseNumber,
      licenseState
    );
  }
}
