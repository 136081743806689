import * as fromFeature from '@core/store/reducers';

import { createSelector } from '@ngrx/store';
import * as fromCoverageErrors from '@core/store/reducers/coverage-error.reducer';

export const getCoverageErrorState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.coverageErrors
);

export const {
  selectAll: selectAllCoverageErrors,
  selectEntities: selectCoverageErrorEntities,
  selectTotal: selectTotalCoverageErrors,
} = fromCoverageErrors.adapter.getSelectors(getCoverageErrorState);
