import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { Observable } from 'rxjs';

import { HomeownersModel } from '@core/models/homeowners/homeowners.model';
@Injectable()
export class HomeownersDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  addHomeowners(value: HomeownersModel): void {
    this._store.dispatch(new fromActions.AddHomeowners(value));
  }

  updateHomeowners(value: HomeownersModel): void {
    this._store.dispatch(new fromActions.AddHomeowners(value));
  }

  getHomeowners(): Observable<HomeownersModel> {
    return this._store.select(fromSelectors.getHomeowners);
  }

  hasHomeMortgage(): Observable<boolean> {
    return this._store.select(fromSelectors.hasHomeMortgage);
  }

  getOfferingType(): Observable<string> {
    return this._store.select(fromSelectors.getOfferingType);
  }

  updateOfferingType(offeringType: string) {
    return this._store.dispatch(
      new fromActions.UpdateOfferingType(offeringType)
    );
  }
}
