import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromAction from '@core/store/actions/driver-vehicle-assignment.action';
import * as fromModel from '@core/models/auto/driver-vehicle-assignment.model';
import * as fromSelector from '@core/store/selectors/driver-vehicle-assignment.selector';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DriverVehicleAssignmentEntity } from '@core/models/entities/driver-vehicle-assignment.entity';

@Injectable({
  providedIn: 'root',
})
export class DriverVehicleAssignmentDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  reassignDriverInStore(request: fromModel.ReassignDvaDriverRequest): void {
    this._store
      .select(fromSelector.reassignDriver(request))
      .pipe(take(1))
      .subscribe(state => {
        this._store.dispatch(
          new fromAction.ReassignDvaDriverSuccess({
            entities: state,
          })
        );
      });
  }

  sendStore(): void {
    this._store.dispatch(new fromAction.SendDvaStore());
  }

  getEntireStore(): Observable<DriverVehicleAssignmentEntity[]> {
    return this._store.select(fromSelector.selectAllDriverVehicleAssignments);
  }

  replaceEntireStore(entities: DriverVehicleAssignmentEntity[]): void {
    this._store.dispatch(new fromAction.DvaReplaceStore(entities));
  }

  removeAutoGeneratedAssignments(): void {
    this._store.dispatch(new fromAction.DvaRemoveAutoGeneratedAssignments());
  }

  getLoading(): Observable<boolean> {
    return this._store.select(fromSelector.getDriverVehicleAssignmentsLoading);
  }

  getErrorCode(): Observable<string> {
    return this._store.select(
      fromSelector.getDriverVehicleAssignmentsErrorCode
    );
  }

  getCallStatus(): Observable<fromSelector.DvaCallStatus> {
    return this._store.select(
      fromSelector.getDriverVehicleAssignmentCallStatus
    );
  }

  clearStore(): void {
    this._store.dispatch(new fromAction.ClearDvaStore());
  }
}
