import {
  DOLLAR,
  NationwideContactNumber,
} from '@shared/constants/app-constants';
import { UUID } from 'angular2-uuid';

export class StringUtils {
  static generateUUID(): string {
    return UUID.UUID();
  }

  static generateNoDashUUID(): string {
    const uniqueId = UUID.UUID().toUpperCase();
    const split = uniqueId.split('-');
    return split.join('');
  }

  static generateRandomNumberString(length: number): string {
    return Math.floor(Math.random() * length + 1).toString();
  }

  static areEqual(str1: string, str2: string): boolean {
    return !str1 && !str2
      ? true
      : StringUtils.strToLower(str1) === StringUtils.strToLower(str2);
  }

  static strToLower(str: string): string {
    return !str ? null : str.toLocaleLowerCase();
  }

  // eslint-disable-next-line
  static isEmpty(str: any): boolean {
    return str === null || (typeof str === 'string' ? str.trim() === '' : !str);
  }

  static formatTelephoneNumber = (phone: string): string => {
    const standardPhoneLength = 10,
      phoneLengthWithOneInFront = 11,
      phoneLengthWithOneInFrontAndDashes = 14,
      phoneLengthWithDashes = 12,
      firstDashIndex = 3,
      secondDashInLongPhoneNumber = 5,
      secondDashIndex = 7,
      thirdDashInLongPhoneNumber = 9;
    if (phone.length === standardPhoneLength) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    if (phone.length === phoneLengthWithOneInFront) {
      return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
    }
    if (
      (phone.length === phoneLengthWithDashes &&
        phone.indexOf('-', firstDashIndex) >= 0 &&
        phone.indexOf('-', secondDashIndex) >= 0) ||
      (phone.length === phoneLengthWithOneInFrontAndDashes &&
        phone.indexOf('-', 1) >= 0 &&
        phone.indexOf('-', secondDashInLongPhoneNumber) >= 0 &&
        phone.indexOf('-', thirdDashInLongPhoneNumber) >= 0)
    ) {
      return phone;
    }
    return NationwideContactNumber;
  };

  static getQuoteIdForTermLife(): string {
    const lifeIDLength = 99999999;
    return StringUtils.generateRandomNumberString(lifeIDLength);
  }

  static generateDollarAmountFromNumber(amount: number): string {
    if (amount) {
      return DOLLAR + amount.toString();
    }
  }

  static forceValidCssIdentifier(input: string): string {
    if (!input) {
      input = 'a';
    }
    input = input.replace(/[^a-zA-Z0-9_-]/g, '-');
    if (input.match(/^\d|^--/)) {
      input = 'a' + input;
    }
    return input;
  }

  static getAsterisks(length: number): string {
    let asteriskString = '';
    for (let i = 0; i < length; i++) {
      asteriskString += '*';
    }
    return asteriskString;
  }

  static isJSON(message: string): boolean {
    let jsonString;

    if (typeof message !== 'string') {
      jsonString = JSON.stringify(message);
    } else {
      jsonString = message;
    }

    try {
      jsonString = JSON.parse(message);
    } catch (e) {
      return false;
    }

    return typeof jsonString === 'object' && jsonString !== null;
  }
}
