import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap } from 'rxjs/operators';
import { ProductsService } from '@core/services';
import { TelematicsEnrollmentsService } from '@core/services/telematics-enrollments.service';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable()
export class TelematicsEnrollmentsGuard implements CanActivate {
  constructor(
    private _productsService: ProductsService,
    private _telematicsEnrollmentsService: TelematicsEnrollmentsService
  ) {}
  canActivate(): Observable<boolean> {
    return this._productsService
      .isProductSelectedWithoutError(ProductTypes.AUTO)
      .pipe(
        take(1),
        switchMap(isAutoSelected => {
          return isAutoSelected ? this.checkStore() : of(true);
        })
      );
  }

  checkStore(): Observable<boolean> {
    return this._telematicsEnrollmentsService.getEnrollmentsLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._telematicsEnrollmentsService.dispatchTelematicsEnrollment();
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
