import { RetrieveService } from '@core/services/retrieve.service';
import { Injectable } from '@angular/core';
import { ModifiersDao } from '@core/dao/modifiers.dao';
import { EligibleDiscountRequest } from '@core/models/auto/modifiers/modifier-request.model';
import { Observable, from, combineLatest } from 'rxjs';
import { ModifiersAdapter } from '@core/adapters/modifiers.adapter';
import { EligibleDiscountResponse } from '@core/models/auto/modifiers/modifier-response.model';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { switchMap, map, take } from 'rxjs/operators';
import { Product } from '@core/models/products/product.model';
import {
  ProductTypes,
  ModifierValues,
  ModifierNames,
  BillingPaymentPlan,
} from '@shared/constants/app-constants';
import { Action } from '@ngrx/store';
import * as fromModifiersActions from '@core/store/actions/modifiers.action';

@Injectable({
  providedIn: 'root',
})
export class ModifiersService {
  constructor(
    private _modifiersDao: ModifiersDao,
    private _modifiersAdapter: ModifiersAdapter,
    private _retrieveService: RetrieveService
  ) {}

  dispatchUpdateDriverDiscount(entity: EligibleDiscountEntity): void {
    this._modifiersDao.updateDriverDiscount(entity);
  }

  dispatchUpdatePowersportsDriverDiscount(
    entity: EligibleDiscountEntity
  ): void {
    this._modifiersDao.updatePowersportsDriverDiscount(entity);
  }

  getPowersportsDriverDiscountByDriverId(
    eligibleDiscountId: string,
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._modifiersDao.getPowersportsDriverDiscountByDriverId(
      eligibleDiscountId,
      driverId
    );
  }

  updateDriverDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    return this._modifiersAdapter.updateDriverDiscount(request);
  }

  updatePowersportsDriverDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    return this._modifiersAdapter.updatePowersportsDriverDiscount(request);
  }

  getDriverDiscountByDriverId(
    eligibleDiscountId: string,
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._modifiersDao.getDriverDiscountByDriverId(
      eligibleDiscountId,
      driverId
    );
  }

  getSmartHomeDiscount(name, productId): Observable<EligibleDiscountEntity> {
    return this._modifiersDao.getSmartHomeDiscount(name, productId);
  }

  dispatchUpdateSmartHomeDiscount(selection: string): void {
    return this._modifiersDao.dispatchUpdateSmartHomeDiscount(selection);
  }

  updatePolicyLine(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    return this._modifiersAdapter.updatePolicyLine(request);
  }

  dispatchUpdatePolicyLine(request: EligibleDiscountEntity): void {
    this._modifiersDao.dispatchUpdatePolicyLine(request);
  }

  dispatchGetPolicyLine(productId: string): void {
    this._modifiersDao.getPolicyLine(productId);
  }

  getPolicyLine(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse[]> {
    return this._modifiersAdapter.getPolicyLine(request);
  }

  getPolicyLineModifier(
    name: string,
    productId: string
  ): Observable<EligibleDiscountEntity> {
    return this._modifiersDao.getPolicyLineModifier(name, productId);
  }

  getSmartRideLoaded(): Observable<boolean> {
    return this._modifiersDao.getSmartRideLoaded();
  }

  dispatchUnsetSmartrideLoaded() {
    this._modifiersDao.dispatchUnsetSmartrideLoaded();
  }

  updateVehicleDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    return this._modifiersAdapter.updateVehicleDiscount(request);
  }

  updatePowersportsVehicleDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    return this._modifiersAdapter.updatePowersportsVehicleDiscount(request);
  }

  updateSmartHomeDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    return this._modifiersAdapter.updateSmartHomeDiscount(request);
  }

  getVehicleDiscounts(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse[]> {
    return this._modifiersAdapter.getVehicleDiscounts(request);
  }

  dispatchUpdateVehicleDiscount(request: EligibleDiscountEntity): void {
    this._modifiersDao.dispatchUpdateVehicleDiscount(request);
  }

  areModifiersLoading(): Observable<boolean> {
    return this._modifiersDao.areModifiersLoading();
  }

  updateAssociateDiscount(discount: EligibleDiscountEntity): void {
    this._modifiersDao.updateAssociateDiscount(discount);
  }

  getPrimaryAssociateDiscount(): Observable<EligibleDiscountEntity> {
    return this._modifiersDao.getPrimaryAssociateDiscount();
  }

  makeMultiproductActionsIfApplicable(products: Product[]): Observable<Action> {
    const selectedProducts = {};
    for (const product of products) {
      if (!product.hasError) {
        selectedProducts[product.id] = true;
      }
    }
    return this.getExistingMultiproductDiscounts(selectedProducts).pipe(
      take(1),
      switchMap(existingModifiers => {
        const actions = [];
        this.addAutoPropertyMultiproductActions(
          actions,
          selectedProducts,
          existingModifiers
        );
        this.addMotoPropertyMultiproductActions(
          actions,
          selectedProducts,
          existingModifiers
        );
        return from(actions);
      })
    );
  }

  private getExistingMultiproductDiscounts(selectedProducts: {
    [id: string]: true;
  }): Observable<EligibleDiscountEntity[]> {
    const lookups: Observable<EligibleDiscountEntity>[] = [];

    if (selectedProducts[ProductTypes.AUTO]) {
      lookups.push(
        this._modifiersDao.getPolicyLineModifier(
          ModifierNames.HOMEOWNER_PP,
          ProductTypes.AUTO
        )
      );
      lookups.push(
        this._modifiersDao.getPolicyLineModifier(
          ModifierNames.TENANTS_PP,
          ProductTypes.AUTO
        )
      );
      lookups.push(
        this._modifiersDao.getPolicyLineModifier(
          ModifierNames.CONDO_PP,
          ProductTypes.AUTO
        )
      );
    }

    // Modifiers for Homeowner, Renter, and Condo happen to have the same names.
    for (const productId of [
      ProductTypes.HOMEOWNERS,
      ProductTypes.RENTERS,
      ProductTypes.CONDO,
    ]) {
      if (selectedProducts[productId]) {
        lookups.push(
          this._modifiersDao.getPolicyLineModifier(
            ModifierNames.HOME_CAR_INDICATOR_I,
            productId
          )
        );
        lookups.push(
          this._modifiersDao.getPolicyLineModifier(
            ModifierNames.POWERSPORTS,
            productId
          )
        );
      }
    }

    if (selectedProducts[ProductTypes.POWERSPORTS]) {
      lookups.push(
        this._modifiersDao.getPolicyLineModifier(
          ModifierNames.HOMEOWNER_PP,
          ProductTypes.POWERSPORTS
        )
      );
      lookups.push(
        this._modifiersDao.getPolicyLineModifier(
          ModifierNames.TENANTS_PP,
          ProductTypes.POWERSPORTS
        )
      );
      lookups.push(
        this._modifiersDao.getPolicyLineModifier(
          ModifierNames.CONDO_PP,
          ProductTypes.POWERSPORTS
        )
      );
    }

    return combineLatest(lookups).pipe(
      map(discounts => discounts.filter(discount => !!discount))
    );
  }

  private addAutoPropertyMultiproductActions(
    actions: Action[],
    selectedProducts: { [id: string]: true },
    existingModifiers: EligibleDiscountEntity[]
  ): void {
    if (selectedProducts[ProductTypes.AUTO]) {
      if (selectedProducts[ProductTypes.HOMEOWNERS]) {
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.AUTO,
          ModifierNames.HOMEOWNER_PP,
          'true'
        );
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.HOMEOWNERS,
          ModifierNames.HOME_CAR_INDICATOR_I,
          ModifierValues.NATIONWIDE_AUTO_POLICY
        );
      } else {
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.AUTO,
          ModifierNames.HOMEOWNER_PP,
          'false'
        );
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.HOMEOWNERS,
          ModifierNames.HOME_CAR_INDICATOR_I,
          ModifierValues.NONE
        );
      }
      if (selectedProducts[ProductTypes.RENTERS]) {
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.AUTO,
          ModifierNames.TENANTS_PP,
          'true'
        );
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.RENTERS,
          ModifierNames.HOME_CAR_INDICATOR_I,
          ModifierValues.NATIONWIDE_AUTO_POLICY
        );
      } else {
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.AUTO,
          ModifierNames.TENANTS_PP,
          'false'
        );
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.RENTERS,
          ModifierNames.HOME_CAR_INDICATOR_I,
          ModifierValues.NONE
        );
      }
      if (selectedProducts[ProductTypes.CONDO]) {
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.AUTO,
          ModifierNames.CONDO_PP,
          'true'
        );
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.CONDO,
          ModifierNames.HOME_CAR_INDICATOR_I,
          ModifierValues.NATIONWIDE_AUTO_POLICY
        );
      } else {
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.AUTO,
          ModifierNames.CONDO_PP,
          'false'
        );
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.CONDO,
          ModifierNames.HOME_CAR_INDICATOR_I,
          ModifierValues.NONE
        );
      }
    } else if (selectedProducts[ProductTypes.HOMEOWNERS]) {
      this.removeIfPresent(
        actions,
        existingModifiers,
        ProductTypes.HOMEOWNERS,
        ModifierNames.HOME_CAR_INDICATOR_I,
        ModifierValues.NONE
      );
    } else if (selectedProducts[ProductTypes.RENTERS]) {
      this.removeIfPresent(
        actions,
        existingModifiers,
        ProductTypes.RENTERS,
        ModifierNames.HOME_CAR_INDICATOR_I,
        ModifierValues.NONE
      );
    } else if (selectedProducts[ProductTypes.CONDO]) {
      this.removeIfPresent(
        actions,
        existingModifiers,
        ProductTypes.CONDO,
        ModifierNames.HOME_CAR_INDICATOR_I,
        ModifierValues.NONE
      );
    }
  }

  private addMotoPropertyMultiproductActions(
    actions: Action[],
    selectedProducts: { [id: string]: true },
    existingModifiers: EligibleDiscountEntity[]
  ): void {
    if (selectedProducts[ProductTypes.POWERSPORTS]) {
      if (selectedProducts[ProductTypes.HOMEOWNERS]) {
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.POWERSPORTS,
          ModifierNames.HOMEOWNER_PP,
          'true'
        );
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.HOMEOWNERS,
          ModifierNames.POWERSPORTS,
          'true'
        );
      } else {
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.POWERSPORTS,
          ModifierNames.HOMEOWNER_PP,
          'false'
        );
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.HOMEOWNERS,
          ModifierNames.POWERSPORTS,
          'false'
        );
      }
      if (selectedProducts[ProductTypes.RENTERS]) {
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.POWERSPORTS,
          ModifierNames.TENANTS_PP,
          'true'
        );
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.RENTERS,
          ModifierNames.POWERSPORTS,
          'true'
        );
      } else {
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.POWERSPORTS,
          ModifierNames.TENANTS_PP,
          'false'
        );
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.RENTERS,
          ModifierNames.POWERSPORTS,
          'false'
        );
      }
      if (selectedProducts[ProductTypes.CONDO]) {
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.POWERSPORTS,
          ModifierNames.CONDO_PP,
          'true'
        );
        this.addIfMissing(
          actions,
          existingModifiers,
          ProductTypes.CONDO,
          ModifierNames.POWERSPORTS,
          'true'
        );
      } else {
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.POWERSPORTS,
          ModifierNames.CONDO_PP,
          'false'
        );
        this.removeIfPresent(
          actions,
          existingModifiers,
          ProductTypes.CONDO,
          ModifierNames.POWERSPORTS,
          'false'
        );
      }
    } else if (selectedProducts[ProductTypes.HOMEOWNERS]) {
      this.removeIfPresent(
        actions,
        existingModifiers,
        ProductTypes.HOMEOWNERS,
        ModifierNames.POWERSPORTS,
        'false'
      );
    } else if (selectedProducts[ProductTypes.RENTERS]) {
      this.removeIfPresent(
        actions,
        existingModifiers,
        ProductTypes.RENTERS,
        ModifierNames.POWERSPORTS,
        'false'
      );
    } else if (selectedProducts[ProductTypes.CONDO]) {
      this.removeIfPresent(
        actions,
        existingModifiers,
        ProductTypes.CONDO,
        ModifierNames.POWERSPORTS,
        'false'
      );
    }
  }

  private addIfMissing(
    actions: Action[],
    existingModifiers: EligibleDiscountEntity[],
    productId: string,
    modifierName: string,
    value: string
  ): void {
    if (
      existingModifiers.find(
        m =>
          m.productId === productId &&
          m.eligibleDiscountId === modifierName &&
          m.selectedOptionValue === value
      )
    ) {
      return;
    }
    actions.push(
      new fromModifiersActions.UpdatePolicyLine({
        productId,
        eligibleDiscountId: modifierName,
        selectedOptionValue: value,
      })
    );
  }

  private removeIfPresent(
    actions: Action[],
    existingModifiers: EligibleDiscountEntity[],
    productId: string,
    modifierName: string,
    unselectedValue: string
  ): void {
    const existingModifier = existingModifiers.find(
      m => m.productId === productId && m.eligibleDiscountId === modifierName
    );
    if (!existingModifier) {
      return;
    }
    if (existingModifier.selectedOptionValue === unselectedValue) {
      return;
    }
    actions.push(
      new fromModifiersActions.UpdatePolicyLine({
        productId,
        eligibleDiscountId: modifierName,
        selectedOptionValue: unselectedValue,
      })
    );
  }

  discountNeedsUpdate(
    request: EligibleDiscountRequest,
    existing: EligibleDiscountEntity | null
  ): boolean {
    if (!existing) {
      // unlikely. normal case, we have all of the discounts before trying to set any.
      if (request.eligibleDiscount.selectedOptionValue === 'false') {
        return false;
      }
      return true;
    }

    // If it was "false", ie not applying, and still "false", no need to check anything else. Discard it.
    if (
      existing.selectedOptionValue === 'false' &&
      request.eligibleDiscount.selectedOptionValue === 'false'
    ) {
      return false;
    }

    //TODO We could pick off redundant true=>true cases, but not attempting that now
    return true;
  }

  beginCheckEasyPayDiscount(
    paymentPlan: string,
    paymentMethod: string,
    recurring: boolean
  ): void {
    this._modifiersDao.beginCheckEasyPayDiscount(
      paymentPlan,
      paymentMethod,
      recurring
    );
  }

  checkEasyPayDiscount(
    paymentPlan: string,
    paymentMethod: string,
    recurring: boolean,
    currentValue: string | null
  ): 'add' | 'remove' | null {
    if (currentValue === 'true') {
      if (
        paymentMethod === 'EFT' &&
        paymentPlan === BillingPaymentPlan.MONTHLY &&
        recurring
      ) {
        return null;
      }
      return 'remove';
    } else {
      if (
        !(
          paymentMethod === 'EFT' && paymentPlan === BillingPaymentPlan.MONTHLY
        ) ||
        !recurring
      ) {
        return null;
      }
      return 'add';
    }
  }

  updateMembershipDiscount(discount: EligibleDiscountEntity): void {
    this._modifiersDao.updateMembershipDiscount(discount);
  }

  updateBillingPaymentMethod(discount: EligibleDiscountEntity): void {
    this._modifiersDao.updateBillingPaymentMethod(discount);
  }

  getBillingPaymentMethodModifier(): Observable<EligibleDiscountEntity> {
    return this._modifiersDao.getBillingPaymentMethodModifier();
  }

  getEasyPayModifier(): Observable<EligibleDiscountEntity> {
    return this._modifiersDao.getEasyPayModifier();
  }

  isBillingPaymentMethodApplicable(): Observable<boolean> {
    return this._modifiersDao.getBillingPaymentMethodModifier().pipe(
      map(billingPaymentMethodModifier => {
        if (billingPaymentMethodModifier?.selectedOptionValue) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  isEasyPayModifierApplicable(): Observable<boolean> {
    return this._modifiersDao.getEasyPayModifier().pipe(
      map(easyPayModifier => {
        if (easyPayModifier?.selectedOptionValue) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  getPaidInFullPowersportsModifier(): Observable<EligibleDiscountEntity> {
    return this._modifiersDao.getPaidInFullPowersportsModifier();
  }

  checkPaidInFullDiscountForPS(
    paymentPlan: string,
    currentValue: string | null
  ): 'add' | 'remove' | null {
    if (currentValue === 'true') {
      if (paymentPlan === BillingPaymentPlan.FULL_PAY) {
        return null;
      }
      return 'remove';
    } else if (
      currentValue === 'false' &&
      paymentPlan === BillingPaymentPlan.FULL_PAY
    ) {
      return 'add';
    } else return null;
  }
}
