import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'nw-display-panel-action-buttons',
  templateUrl: './display-panel-action-buttons.component.html',
  styleUrls: ['./display-panel-action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayPanelActionButtonsComponent {
  @Input() id?: string;
  @Output()
  cancel = new EventEmitter();
}
