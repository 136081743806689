import { NavigationPath } from '@core/models/navigation/navigation.model';

import * as fromNavigation from '@quote/store/actions/navigation.action';

export type NavigationDirection = -1 | 0 | 1;

export interface NavigationState {
  basePath: NavigationPath;
  currentPath: NavigationPath;
  loaded: boolean;
  loading: boolean;
  direction: NavigationDirection;
}

export const initialState: NavigationState = {
  basePath: null,
  currentPath: null,
  loaded: false,
  loading: false,
  direction: 0,
};

export function reducer(
  state = initialState,
  action: fromNavigation.NavigationActions
): NavigationState {
  switch (action.type) {
    case fromNavigation.LOAD_ROUTES: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromNavigation.LOAD_ROUTES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromNavigation.LOAD_ROUTES_SUCCESS: {
      const basePath = action.payload;

      return {
        ...state,
        loaded: true,
        loading: false,
        basePath,
        currentPath: basePath,
      };
    }

    // TODO continue
    case fromNavigation.UPDATE_ROUTE_PATH: {
      const currentPath = action.payload;
      return {
        ...state,
        currentPath,
      };
    }

    case fromNavigation.NAVIGATE_FORWARD: {
      const currentPath = action.payload;
      return {
        ...state,
        currentPath,
        direction: 1,
      };
    }

    case fromNavigation.NAVIGATE_BACK: {
      const currentPath = action.payload;
      return {
        ...state,
        currentPath,
        direction: -1,
      };
    }

    case fromNavigation.FORCE_NAVIGATION_INDEX: {
      let newIndex = state.currentPath.currentIndex;
      for (let i = 0; i < state.currentPath.items.length; i++) {
        if (action.url === state.currentPath.items[i].url) {
          newIndex = i;
          break;
        }
      }
      return {
        ...state,
        currentPath: {
          ...state.currentPath,
          currentIndex: newIndex,
        },
      };
    }

    case fromNavigation.SKIP_CURRENT_PAGE_NAVIGATION: {
      return {
        ...state,
        currentPath: {
          items: state.currentPath.items.filter(item => {
            if (item.url !== action.url) {
              return item;
            }
          }),
          currentIndex: state.currentPath.currentIndex - 1,
        },
      };
    }

    case fromNavigation.SKIP_NEXT_PAGE_NAVIGATION: {
      return {
        ...state,
        currentPath: {
          items: state.currentPath.items.filter(item => {
            if (item.url !== action.url) {
              return item;
            }
          }),
          currentIndex: state.currentPath.currentIndex,
        },
      };
    }
  }

  return state;
}

export const getNavigationBasePath = (state: NavigationState) => state.basePath;
export const getNavigationCurrentPath = (state: NavigationState) =>
  state.currentPath;
export const getNavigationLoading = (state: NavigationState) => state.loading;
export const getNavigationLoaded = (state: NavigationState) => state.loaded;
