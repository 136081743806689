import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromMailingAddress from '@core/store/actions/mailing-address.action';

import { AddressEntity } from '@core/models/entities/address.entity';
import { DEFAULT_ID } from '@shared/constants/app-constants';

export type MailingAddressState = EntityState<AddressEntity>;
export const adapter: EntityAdapter<AddressEntity> =
  createEntityAdapter<AddressEntity>({
    selectId: address => address.addressId,
  });

export function reducer(
  state = adapter.getInitialState(),
  action: fromMailingAddress.MailingAddressActions
): MailingAddressState {
  switch (action.type) {
    case fromMailingAddress.MailingAddressActionTypes.ADD_MAILING_ADDRESS: {
      let mailingAddress = action.payload;
      if (mailingAddress.addressId === DEFAULT_ID) {
        mailingAddress = createMailingAddressForDefaultId(
          mailingAddress,
          state
        );
      }
      mailingAddress = removeLineOneUnitNumber(mailingAddress);
      return adapter.upsertOne(mailingAddress, state);
    }

    case fromMailingAddress.MailingAddressActionTypes.DELETE_MAILING_ADDRESS: {
      return adapter.removeAll(state);
    }

    case fromMailingAddress.MailingAddressActionTypes.UPDATE_MAILING_ADDRESS: {
      const id = action.payload.id.toString();
      let changes = <AddressEntity>action.payload.changes;
      if (id === DEFAULT_ID) {
        changes = createMailingAddressForDefaultId(changes, state);
      }
      changes = removeLineOneUnitNumber(changes);
      return adapter.updateOne({ id, changes }, state);
    }

    default: {
      return state;
    }
  }
}

/**
 * Appends the current state addressIds to the input
 * policyAddress.addressIds for the DEFAULT_ID policyAddress
 * @param mailingAddress
 * @param state
 */
function createMailingAddressForDefaultId(
  mailingAddress: AddressEntity,
  state: MailingAddressState
): AddressEntity {
  const newMailingAddress = { ...mailingAddress };
  if (newMailingAddress.addressIds && state.entities[DEFAULT_ID]) {
    newMailingAddress.addressIds = {
      ...state.entities[DEFAULT_ID].addressIds,
      ...newMailingAddress.addressIds,
    };
  }
  return newMailingAddress;
}

function removeLineOneUnitNumber(input: AddressEntity): AddressEntity {
  if (
    !input.addressLine1 ||
    !input.addressLine2 ||
    !input.addressLine1.endsWith(input.addressLine2)
  ) {
    return input;
  }
  return {
    ...input,
    addressLine1: input.addressLine1
      .substr(0, input.addressLine1.length - input.addressLine2.length)
      .trim(),
  };
}
