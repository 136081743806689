<form [formGroup]="form" *ngIf="showSmartHomeDiscount$ | async">
  <div class="row">
    <div
      class="form-group smart-home col-sm-12"
      id="inline-help-{{ smartHomeFieldId }}"
      [style.color]="'black'"
    >
      <inline-help
        [labelInnerHTML]="smartHomelabelText"
        [helpTextInnerHTML]="smartHomeHelpText"
        [fieldId]="smartHomeFieldId"
      ></inline-help>
    </div>
  </div>
  <div class="row">
    <p class="col smartHome">
      Participate in this program for an extra layer of safety, security and
      savings.
    </p>
  </div>
  <div class="row">
    <div class="col-12" id="optIn-error">
      <label
        for="optIn-checkbox"
        class="form-group col-sm-12 checkbox-input-label"
        >Yes, I will participate in the SmartHome program
        <input
          id="optIn-checkbox"
          type="checkbox"
          aria-describedby="optIn-error"
          formControlName="optIn"
          [checked]="smartHomeDiscount.selectedOptionValue === 'Participating'"
        />
        <span class="checkmark-input"></span>
      </label>
    </div>
    <div
      class="form-group col-12 smarthome-learn-more"
      (click)="openSmartHomeContentWindow()"
    >
      <button class="btn btn-default">Learn more</button>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-8 col-sm-12">
      <p class="smartHome-disclaimer">
        <em>
          Purchase of a smart home monitoring system is required. Watch for an
          email with program details and a link to our smart home provider.
        </em>
      </p>
    </div>
  </div>
</form>
