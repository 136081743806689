import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import {
  DriverRequest,
  PowersportsDriverRequest,
} from '@core/models/auto/quotes/driver-request.model';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { ProductTypes } from '@shared/constants/app-constants';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { DriverIncidentEntity } from '@core/models/entities/driver-incident.entity';
import { DriverIncidentRequest } from '@core/models/auto/quotes/driver-incident-request.model';
import { catchError } from 'rxjs/operators';
import { DriverRelationToPni } from '@core/models/auto/driver-relationToPni.model';
import { DriverHealthCarePlan } from '@core/models/auto/driver-heathCarePlan.model';
import { DriverReasonForCoverageLapse } from '@core/models/auto/driver-reasonForCoverageLapse.model';

@Injectable({
  providedIn: 'root',
})
export class DriverAdapter extends BaseDsmAdapter {
  addDriver(request: DriverRequest): Observable<DriverEntity> {
    const url = `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}/drivers`;

    const options = {
      productId: ProductTypes.AUTO,
      prePopulatedInsuredId: null,
      partialQuoteId: null,
    };
    if (request.driver.prepopulatedInsuredId) {
      options.prePopulatedInsuredId = request.driver.prepopulatedInsuredId;
    }
    if (request.driver.partialQuoteId) {
      options.partialQuoteId = request.driver.partialQuoteId;
    }
    return this.post(
      url,
      {
        driver: request.driver,
      },
      options
    );
  }

  addPowersportsDriver(
    request: PowersportsDriverRequest
  ): Observable<DriverEntity> {
    const url = `${this._appConfig.config.pcEdgePowersportsUrl}/quotes/${request.quoteId}/drivers`;

    return this.post(
      url,
      {
        driver: request.driver,
      },
      {
        productId: ProductTypes.POWERSPORTS,
      }
    );
  }

  updateDriver(request: DriverRequest): Observable<DriverEntity> {
    const url = `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}/drivers/${request.driver.driverId}`;

    return this.put(
      url,
      {
        driver: request.driver,
      },
      {
        productId: ProductTypes.AUTO,
      }
    );
  }

  deleteDriver(request: DriverRequest): Observable<DriverEntity> {
    const url = `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}/drivers/${request.driver.driverId}`;

    return this.delete(url, {
      productId: ProductTypes.AUTO,
    });
  }

  updatePowersportsDriver(
    request: PowersportsDriverRequest
  ): Observable<DriverEntity> {
    const url = `${this._appConfig.config.pcEdgePowersportsUrl}/quotes/${request.quoteId}/drivers/${request.driver.driverId}`;

    return this.put(
      url,
      {
        driver: request.driver,
      },
      {
        productId: ProductTypes.POWERSPORTS,
      }
    );
  }

  deletePowersportsDriver(
    request: PowersportsDriverRequest
  ): Observable<DriverEntity> {
    const url = `${this._appConfig.config.pcEdgePowersportsUrl}/quotes/${request.quoteId}/drivers/${request.driver.driverId}`;

    return this.delete(url, {
      productId: ProductTypes.POWERSPORTS,
    });
  }

  deleteDriverIncident(
    request: DriverIncidentRequest
  ): Observable<DriverIncidentEntity> {
    const url = `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}/drivers/${request.driverId}/incidents`;

    return this.delete(url, {
      productId: ProductTypes.AUTO,
    });
  }

  getRelationToPni(): Observable<DriverRelationToPni> {
    return this._http
      .get<DriverRelationToPni>(
        'assets/api/metadata/auto/driver-relationToPni.json'
      )
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getHealthCarePlans(): Observable<DriverHealthCarePlan> {
    return this._http
      .get<DriverHealthCarePlan>(
        'assets/api/metadata/auto/driver-healthCarePlan.json'
      )
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getReasonForCoverageLapseOptions(): Observable<DriverReasonForCoverageLapse> {
    return this._http
      .get<DriverReasonForCoverageLapse>(
        'assets/api/metadata/auto/driver-reasonForCoverageLapse.json'
      )
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }
}
