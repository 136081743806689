import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, combineLatest } from 'rxjs';

import { switchMap, catchError, tap, mergeMap, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import {} from '@shared/constants/app-constants';

import { LoggingService } from '@core/services/logging.service';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { NonStandardBridgeService } from '@core/services/non-standard-bridge.service';
import { DefaultNonStandardErrorCode } from '@shared/constants/non-standard-constants';

@Injectable()
export class NonStandardBridgeEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _nonStandardBridgeService: NonStandardBridgeService,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  nonStandardBridge$ = this._actions$.pipe(
    ofType(fromActions.NonStandardActionTypes.NON_STANDARD_BRIDGE),
    switchMap((action: fromActions.NonStandardBridge) => {
      return combineLatest([
        this._store.select(fromSelectors.getAccessToken(action.productId)),
        this._store
          .select(fromSelectors.getNonStandardBridgeRequest)
          .pipe(
            tap(request =>
              this._loggingService.log('NatGen Bridge Request ', request)
            )
          ),
        of(action.reason),
        this._store.select(fromSelectors.getSessionId),
      ]);
    }),
    switchMap(([accessToken, bridgeData, reason, sessionId]) =>
      this._nonStandardBridgeService
        .nonStandardBridge(bridgeData, accessToken, sessionId)
        .pipe(
          take(1),
          tap(response =>
            this._loggingService.log('NatGen Bridge Response ', response)
          ),
          mergeMap(response => {
            if (response.Successful) {
              return of(new fromActions.Bridge(reason, response.BridgeUrl));
            } else {
              return of(
                new fromActions.Bridge(
                  reason,
                  'https://nationalgeneral.com/nationwide-quote/'
                )
              );
            }
          })
        )
    ),
    catchError(error => {
      const safeError = this._errorHelper.sanitizeError(error);
      this._loggingService.log('Non Standard Bridge error', safeError);
      return of(
        new fromActions.Bridge(
          DefaultNonStandardErrorCode,
          'https://nationalgeneral.com/nationwide-quote/'
        )
      );
    })
  );
}
