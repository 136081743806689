import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProducerSearch } from '@core/models/agency/producer-search.model';
import { Observable } from 'rxjs';
import { HeadersUtils } from '@shared/utils/headers.utils';
import { AppConfigService } from '@core/services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class AgencyAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  producerCodeSearch(token: string): Observable<ProducerSearch> {
    const url = `${this._appConfig.config.pcEdgeAgentAccountManagementUrl}/producercodes/search`;
    const headers = HeadersUtils.buildHeaderForDSMCalls(
      this._appConfig.config.apiKey,
      token,
      '',
      this._appConfig.isTest() ? this._appConfig.config.targetEnv : ''
    );

    return this._http.get<ProducerSearch>(url, { headers });
  }
}
