<div class="row">
  <nw-display-item-list class="dispcol">
    <nw-display-item *ngFor="let person of termLifePeople">
      <nw-display-panel
        [heading]="person?.firstName + ' ' + person?.lastName"
        [item]="person"
        openText="Close"
        closedText="Open"
        [loadOpen]="true"
        (productDeleted)="onTermPersonDeleted($event)"
        [isDeletable]="true"
      >
        <div class="alert header alert-info" role="alert">
          <a class="icon alert_blue"></a>
          <span
            >Term life insurance requires additional steps. Continue for
            follow-up instructions.
          </span>
        </div>
      </nw-display-panel>
    </nw-display-item>
  </nw-display-item-list>
</div>
