import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromAdditionalInterest from '@core/store/actions/additional-interest.action';

import { AdditionalInterestEntity } from '@core/models/entities/additional-interest.entity';

export type AdditionalInterestState = EntityState<AdditionalInterestEntity>;

export const adapter: EntityAdapter<AdditionalInterestEntity> =
  createEntityAdapter<AdditionalInterestEntity>({
    selectId: additionalInterest => additionalInterest.additionalInterestId,
  });

export function reducer(
  state = adapter.getInitialState(),
  action: fromAdditionalInterest.AdditionalInterestActions
): AdditionalInterestState {
  switch (action.type) {
    case fromAdditionalInterest.AdditionalInterestActionTypes
      .ADD_ADDITIONAL_INTEREST_SUCCESS: {
      return adapter.addOne(action.payload, state);
    }

    case fromAdditionalInterest.AdditionalInterestActionTypes
      .ADD_ALL_ADDITIONAL_INTERESTS: {
      return adapter.addMany(action.payload, state);
    }

    case fromAdditionalInterest.AdditionalInterestActionTypes
      .UPDATE_ADDITIONAL_INTEREST_SUCCESS: {
      return adapter.updateOne(action.payload, state);
    }

    case fromAdditionalInterest.AdditionalInterestActionTypes
      .DELETE_ADDITIONAL_INTEREST_SUCCESS: {
      return adapter.removeOne(action.payload.additionalInterestId, state);
    }

    case fromAdditionalInterest.AdditionalInterestActionTypes
      .DELETE_ALL_ADDITIONAL_INTERESTS: {
      return adapter.removeMany(action.payload, state);
    }

    case fromAdditionalInterest.AdditionalInterestActionTypes
      .DELETE_ADDITIONAL_INTERESTS_BY_VEHCILE_ID: {
      const vehicleId = action.payload;
      const entities = state.entities;

      const additionalInterestIds = Object.keys(entities).filter(
        // eslint-disable-next-line eqeqeq
        id => entities[id].vehicleId == vehicleId
      );

      return adapter.removeMany(additionalInterestIds, state);
    }

    default: {
      return state;
    }
  }
}
