import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
  selector: 'nw-display-item',
  templateUrl: './display-item.component.html',
  styleUrls: ['./display-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayItemComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
