import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DistributionPartnerManagementAdapter } from '@core/adapters/distribution-partner-management.adapter';
import {
  DistributionPartnerRoleRequest,
  DistributionPartnerRoleResponse,
} from '@core/models/distribution-partner-management/distribution-partner-role.model';

@Injectable()
export class DistributionPartnerManagementService {
  constructor(
    private _distributionPartnerManagementAdapter: DistributionPartnerManagementAdapter
  ) {}

  getDistributionPartnerRoles(
    request: DistributionPartnerRoleRequest
  ): Observable<DistributionPartnerRoleResponse> {
    return this._distributionPartnerManagementAdapter.getDistributionPartnerRoles(
      request
    );
  }
}
