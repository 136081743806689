import { Action } from '@ngrx/store';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { PersonViewModel } from '@core/models/entities/driver.entity';
import { Update } from '@ngrx/entity';

export const enum DriverVehiclePrefillActionTypes {
  GET_CUSTOMER_DATA = '[DriverVehiclePrefill] Get Customer Data',
  GET_CUSTOMER_DATA_FAIL = '[DriverVehiclePrefill] Get Customer Data Fail',
  GET_CUSTOMER_DATA_SUCCESS = '[DriverVehiclePrefill] Get Customer Data Success',

  ADD_ALL_PREFILL_VEHICLES = '[DriverVehiclePrefill] Add All Prefill Vehicles',
  ADD_ALL_PREFILL_PEOPLE = '[DriverVehiclePrefill] Add All Prefill People',

  UPDATE_PREFILL_DRIVER_SELECTED = '[DriverVehiclePrefill] Update Prefill Driver Selected',
  UPDATE_PREFILL_VEHICLE_SELECTED = '[DriverVehiclePrefill] Update Prefill Vehicle Selected',

  NO_HIT = '[DriverVehiclePrefill] No Hit',

  GET_CUSTOMER_POWERSPORTS_DATA = '[DriverPowersportsVehiclePrefill] Get Customer Powersports Data',
  GET_CUSTOMER_POWERSPORTS_DATA_FAIL = '[DriverPowersportsVehiclePrefill] Get Customer Powersports Data Fail',
  GET_CUSTOMER_POWERSPORTS_DATA_SUCCESS = '[DriverPowersportsVehiclePrefill] Get Customer Powersports Data Success',

  ADD_ALL_PREFILL_POWERSPORTS_VEHICLES = '[DriverPowersportsVehiclePrefill] Add All Prefill Powersports Vehicles',
  ADD_ALL_PREFILL_POWERSPORTS_PEOPLE = '[DriverPowersportsVehiclePrefill] Add All Prefill Powersports People',

  UPDATE_PREFILL_POWERSPORTS_DRIVER_SELECTED = '[DriverPowersportsVehiclePrefill] Update Prefill Powersports Driver Selected',
  UPDATE_PREFILL_POWERSPORTS_VEHICLE_SELECTED = '[DriverPowersportsVehiclePrefill] Update Prefill Powersports Vehicle Selected',

  NO_POWERSPORTS_PREFILL_HIT = '[DriverPowersportsVehiclePrefill] No Powersports Prefill Hit',
}

export class GetCustomerData implements Action {
  readonly type = DriverVehiclePrefillActionTypes.GET_CUSTOMER_DATA;
}

export class GetCustomerDataFail implements Action {
  readonly type = DriverVehiclePrefillActionTypes.GET_CUSTOMER_DATA_FAIL;
  constructor(public error: any) {}
}

export class GetCustomerDataSuccess implements Action {
  readonly type = DriverVehiclePrefillActionTypes.GET_CUSTOMER_DATA_SUCCESS;
}

export class AddAllPrefillVehicles implements Action {
  readonly type = DriverVehiclePrefillActionTypes.ADD_ALL_PREFILL_VEHICLES;
  constructor(public payload: VehicleEntity[]) {}
}

export class AddAllPrefillPeople implements Action {
  readonly type = DriverVehiclePrefillActionTypes.ADD_ALL_PREFILL_PEOPLE;
  constructor(public payload: PersonViewModel[]) {}
}

export class UpdatePrefillPersonSelected implements Action {
  readonly type =
    DriverVehiclePrefillActionTypes.UPDATE_PREFILL_DRIVER_SELECTED;
  constructor(public payload: Update<PersonViewModel>) {}
}

export class UpdatePrefillVehicleSelected implements Action {
  readonly type =
    DriverVehiclePrefillActionTypes.UPDATE_PREFILL_VEHICLE_SELECTED;
  constructor(public payload: Update<VehicleEntity>) {}
}

export class DriverVehiclePrefillNoHit implements Action {
  readonly type = DriverVehiclePrefillActionTypes.NO_HIT;
}

export class GetCustomerPowersportsData implements Action {
  readonly type = DriverVehiclePrefillActionTypes.GET_CUSTOMER_POWERSPORTS_DATA;
}

export class GetCustomerPowersportsDataFail implements Action {
  readonly type =
    DriverVehiclePrefillActionTypes.GET_CUSTOMER_POWERSPORTS_DATA_FAIL;
  constructor(public error: any) {}
}

export class GetCustomerPowersportsDataSuccess implements Action {
  readonly type =
    DriverVehiclePrefillActionTypes.GET_CUSTOMER_POWERSPORTS_DATA_SUCCESS;
}

export class AddAllPrefillPowersportsVehicles implements Action {
  readonly type =
    DriverVehiclePrefillActionTypes.ADD_ALL_PREFILL_POWERSPORTS_VEHICLES;
  constructor(public payload: VehicleEntity[]) {}
}

export class AddAllPrefillPowersportsPeople implements Action {
  readonly type =
    DriverVehiclePrefillActionTypes.ADD_ALL_PREFILL_POWERSPORTS_PEOPLE;
  constructor(public payload: PersonViewModel[]) {}
}

export class UpdatePrefillPowersportsPersonSelected implements Action {
  readonly type =
    DriverVehiclePrefillActionTypes.UPDATE_PREFILL_POWERSPORTS_DRIVER_SELECTED;
  constructor(public payload: Update<PersonViewModel>) {}
}

export class UpdatePrefillPowersportsVehicleSelected implements Action {
  readonly type =
    DriverVehiclePrefillActionTypes.UPDATE_PREFILL_POWERSPORTS_VEHICLE_SELECTED;
  constructor(public payload: Update<VehicleEntity>) {}
}

export class DriverPowersportsVehiclePrefillNoHit implements Action {
  readonly type = DriverVehiclePrefillActionTypes.NO_POWERSPORTS_PREFILL_HIT;
}

export type DriverVehiclePrefillActions =
  | GetCustomerData
  | GetCustomerDataFail
  | GetCustomerDataSuccess
  | AddAllPrefillPeople
  | AddAllPrefillVehicles
  | UpdatePrefillPersonSelected
  | UpdatePrefillVehicleSelected
  | DriverVehiclePrefillNoHit
  | GetCustomerPowersportsData
  | GetCustomerPowersportsDataFail
  | GetCustomerPowersportsDataSuccess
  | AddAllPrefillPowersportsPeople
  | AddAllPrefillPowersportsVehicles
  | UpdatePrefillPowersportsPersonSelected
  | UpdatePrefillPowersportsVehicleSelected
  | DriverPowersportsVehiclePrefillNoHit;
