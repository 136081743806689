import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  PreBindDocumentEntity,
  PreBindDocumentRequest,
  PreBindDocumentUploadRequest,
  PreBindDocumentUploadResponse,
  PreBindDocumentEmailRequest,
  PreBindDocumentEmailResponse,
} from '@core/models/entities/pre-bind-document.entity';
import { PreBindDocumentsAdapter } from '@core/adapters/pre-bind-documents.adapter';
import { PreBindDocumentsDao } from '@core/dao/pre-bind-documents.dao';

@Injectable({
  providedIn: 'root',
})
export class PreBindDocumentsService {
  constructor(
    private _preBindDocumentsAdapter: PreBindDocumentsAdapter,
    private _preBindDocumentsDao: PreBindDocumentsDao
  ) {}

  getPreBindDocuments(
    request: PreBindDocumentRequest
  ): Observable<PreBindDocumentEntity[]> {
    return this._preBindDocumentsAdapter.getPreBindDocuments(request);
  }

  getPreBindDocument(
    request: PreBindDocumentRequest
  ): Observable<PreBindDocumentEntity> {
    return this._preBindDocumentsAdapter.getPreBindDocument(request);
  }

  uploadDocuments(
    request: PreBindDocumentUploadRequest,
    accessToken: string,
    sessionId: string
  ): Observable<PreBindDocumentUploadResponse> {
    return this._preBindDocumentsAdapter.uploadDocuments(
      request,
      accessToken,
      sessionId
    );
  }

  sendEmail(
    request: PreBindDocumentEmailRequest,
    accessToken: string,
    sessionId: string
  ): Observable<PreBindDocumentEmailResponse> {
    return this._preBindDocumentsAdapter.email(request, accessToken, sessionId);
  }

  dispatchUploadPreBindDocuments(): void {
    this._preBindDocumentsDao.dispatchUploadPreBindDocuments();
  }

  dispatchUploadPowersportsPreBindDocuments(): void {
    this._preBindDocumentsDao.dispatchUploadPowersportsPreBindDocuments();
  }

  dispatchDocumentUrlEmail(): void {
    this._preBindDocumentsDao.dispatchDocumentUrlEmail();
  }

  getPreBindDocumentsUploaded(): Observable<boolean> {
    return this._preBindDocumentsDao.getPreBindDocumentsUploaded();
  }

  getPreBindDocumentUploadResponse(): Observable<PreBindDocumentUploadResponse> {
    return this._preBindDocumentsDao.getPreBindDocumentUploadResponse();
  }

  arePrebindDocumentsAcknowledgementRequired(): Observable<boolean> {
    return this._preBindDocumentsDao.arePrebindDocumentsAcknowledgementRequired();
  }

  arePrebindDocumentsAcknowledgementRequiredPowersports(): Observable<boolean> {
    return this._preBindDocumentsDao.arePrebindDocumentsAcknowledgementRequiredPowersports();
  }

  shouldResetAcknowledgementFlags(): Observable<boolean> {
    return this._preBindDocumentsDao.shouldResetAcknowledgementFlags();
  }

  isPreBindDocumentEmailSent(): Observable<boolean> {
    return this._preBindDocumentsDao.isPreBindDocumentEmailSent();
  }

  getPowersportsPreBindDocuments(): void {
    this._preBindDocumentsDao.dispatchGetPowersportsPreBindDocuments();
  }

  getPowersportsPreBindDocumentsUploadResponse(): Observable<PreBindDocumentUploadResponse> {
    return this._preBindDocumentsDao.getPowersportsPreBindDocumentsUploadResponse();
  }
}
