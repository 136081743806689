import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { LoadingService, SessionService } from '@core/services';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'nw-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() show: boolean;
  upperMessage$: Observable<string | null>;
  lowerMessage$: Observable<string | null>;
  isClaimsReviewsSpinnerEligible$: Observable<boolean | null>;
  isClaimsReviewsSpinnerActive$: Observable<boolean | null>;
  upperMessageReviews$: Observable<string | null>;
  lowerMessageReviews$: Observable<string | null>;
  reviewerName$: Observable<string | null>;
  nWXClaimsReviewsApostrophe: string;

  constructor(
    private _loadingService: LoadingService,
    private _sessionService: SessionService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.upperMessage$ = this._loadingService.getUpperMessage();
    this.lowerMessage$ = this._loadingService.getLowerMessage();
    this.isClaimsReviewsSpinnerEligible$ =
      this._sessionService.getSessionClaimsReviewsLoadingSpinnerEligibility();
    this.isClaimsReviewsSpinnerActive$ =
      this._sessionService.getSessionClaimsReviewsLoadingSpinnerActive();
    this.upperMessageReviews$ =
      this._sessionService.getSessionClaimsReviewsLoadingSpinnerUpperMessage();
    this.lowerMessageReviews$ =
      this._sessionService.getSessionClaimsReviewsLoadingSpinnerLowerMessage();
    this.reviewerName$ =
      this._sessionService.getSessionClaimsReviewsLoadingSpinnerReviewerName();
    this.nWXClaimsReviewsApostrophe =
      '../../../../assets/images/NWX-spinner-review-apostrophe.svg';
  }

  checkIfOnCoveragesPage(): boolean {
    return (
      this._route.snapshot['_routerState'].url === '/multi-quote/coverages'
    );
  }
}
