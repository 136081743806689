import { Action } from '@ngrx/store';
import {
  PreBindDocumentEntity,
  PreBindDocumentUploadResponse,
  PreBindDocumentEmailResponse,
} from '@core/models/entities/pre-bind-document.entity';

export const enum PreBindDocumentActionTypes {
  UPLOAD_POWERSPORTS_PREBIND_DOCUMENTS = '[PreBind Documents] Get Powerports Pre-Bind Documents',
  UPLOAD_POWERSPORTS_PREBIND_DOCUMENTS_FAIL = '[PreBind Documents] Get Powersports Pre-Bind Documents Fail',
  UPLOAD_POWERSPORTS_PREBIND_DOCUMENTS_SUCCESS = '[PreBind Documents] Get Powersports Pre-Bind Documents Success',
  UPLOAD_PREBIND_DOCUMENTS = '[PreBind Documents] Get Pre-Bind Documents',
  UPLOAD_PREBIND_DOCUMENTS_FAIL = '[PreBind Documents] Get Pre-Bind Documents Fail',
  UPLOAD_PREBIND_DOCUMENTS_SUCCESS = '[PreBind Documents] Get Pre-Bind Documents Success',
  ADD_ALL_PREBIND_DOCUMENTS = '[PreBind Documents] Add All Pre-Bind Documents',
  RESEND_DOCUMENT_URL_EMAIL = '[PreBind Documents] Resend Document URL Email',
  RESEND_DOCUMENT_URL_EMAIL_SUCCESS = '[PreBind Documents] Resend Document URL Email Success',
  RESEND_DOCUMENT_URL_EMAIL_FAIL = '[PreBind Documents] Resend Document URL Email Fail',
  GET_POWERSPORTS_PRE_BIND_DOCUMENTS = '[PreBind Documents] Get Powersports Pre bind documents',
}

export class UploadPreBindDocuments implements Action {
  readonly type = PreBindDocumentActionTypes.UPLOAD_PREBIND_DOCUMENTS;
}

export class UploadPreBindDocumentsFail implements Action {
  readonly type = PreBindDocumentActionTypes.UPLOAD_PREBIND_DOCUMENTS_FAIL;
  constructor(public error: any) {}
}

export class UploadPreBindDocumentsSuccess implements Action {
  readonly type = PreBindDocumentActionTypes.UPLOAD_PREBIND_DOCUMENTS_SUCCESS;
  constructor(
    public preBindDocuments: PreBindDocumentEntity[],
    public uploadResponse: PreBindDocumentUploadResponse
  ) {}
}

export class UploadPowersportsPreBindDocuments implements Action {
  readonly type =
    PreBindDocumentActionTypes.UPLOAD_POWERSPORTS_PREBIND_DOCUMENTS;
}

export class UploadPowersportsPreBindDocumentsFail implements Action {
  readonly type =
    PreBindDocumentActionTypes.UPLOAD_POWERSPORTS_PREBIND_DOCUMENTS_FAIL;
  constructor(public error: any) {}
}

export class UploadPowersportsPreBindDocumentsSuccess implements Action {
  readonly type =
    PreBindDocumentActionTypes.UPLOAD_POWERSPORTS_PREBIND_DOCUMENTS_SUCCESS;
  constructor(
    public preBindDocuments: PreBindDocumentEntity[],
    public uploadResponse: PreBindDocumentUploadResponse
  ) {}
}
export class AddAllPreBindDocuments implements Action {
  readonly type = PreBindDocumentActionTypes.ADD_ALL_PREBIND_DOCUMENTS;
  constructor(public documents: PreBindDocumentEntity[]) {}
}

export class ResendDocumentUrlEmail implements Action {
  readonly type = PreBindDocumentActionTypes.RESEND_DOCUMENT_URL_EMAIL;
}

export class ResendDocumentUrlEmailSuccess implements Action {
  readonly type = PreBindDocumentActionTypes.RESEND_DOCUMENT_URL_EMAIL_SUCCESS;
  constructor(public response: PreBindDocumentEmailResponse) {}
}

export class ResendDocumentUrlEmailFail implements Action {
  readonly type = PreBindDocumentActionTypes.RESEND_DOCUMENT_URL_EMAIL_FAIL;
  constructor(public error: any) {}
}

export class GetPowersportsPreBindDocuments implements Action {
  readonly type = PreBindDocumentActionTypes.GET_POWERSPORTS_PRE_BIND_DOCUMENTS;
}

export type PreBindDocumentActions =
  | UploadPreBindDocuments
  | UploadPreBindDocumentsFail
  | UploadPreBindDocumentsSuccess
  | AddAllPreBindDocuments
  | ResendDocumentUrlEmail
  | ResendDocumentUrlEmailSuccess
  | ResendDocumentUrlEmailFail
  | UploadPowersportsPreBindDocuments
  | UploadPowersportsPreBindDocumentsSuccess
  | UploadPowersportsPreBindDocumentsFail
  | GetPowersportsPreBindDocuments;
