import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mq-smart-ride',
  templateUrl: './smart-ride.component.html',
  styleUrls: ['./smart-ride.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartRideComponent implements OnInit {
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}
