import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { AppConfigService } from '@core/services/app-config.service';
import { StandardizedAddresses } from '@core/models/address/standardized-address.model';
import {
  Suggestion,
  Suggestions,
} from '@core/models/address/suggestions.model';

@Injectable()
export class AddressAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  loadStandardizedAddress(street: string): Observable<StandardizedAddresses> {
    const params = new HttpParams({
      fromObject: {
        street,
        apikey: this._appConfig.config.apiKey,
        partnerId: this._appConfig.config.standardizedAddressPartnerId,
      },
    });
    return this._http
      .get<StandardizedAddresses>(
        this._appConfig.config.standardizedAddressUrl,
        {
          params,
        }
      )
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  suggestAddress(text: string): Observable<Suggestion[]> {
    // TODO pull state from
    const params = new HttpParams({
      fromObject: {
        text,
      },
    });
    const headers = new HttpHeaders().set(
      'client_id',
      this._appConfig.config.apiKey
    );
    const url = this._appConfig.config.suggestAddressUrl;

    return this._http
      .get<Suggestions>(url, {
        params,
        headers,
      })
      .pipe(map(response => response.suggestions));
  }

  suggestAddresswithState(
    text: string,
    state: string
  ): Observable<Suggestion[]> {
    // TODO pull state from
    const params = new HttpParams({
      fromObject: {
        text,
        state,
      },
    });
    const headers = new HttpHeaders().set(
      'client_id',
      this._appConfig.config.apiKey
    );
    const url = this._appConfig.config.suggestAddressUrl;

    return this._http
      .get<Suggestions>(url, {
        params,
        headers,
      })
      .pipe(map(response => response.suggestions));
  }
}
