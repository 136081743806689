export class NavigationPath {
  items: NavigationItem[];
  currentIndex: number;
}

export class NavigationItem {
  pages: string[];
  url: string;
  enabled: boolean;
  title: string;
}
