import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ProtectiveDevice } from '@core/store/reducers/protective-devices.reducer';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ProtectiveDevicesHelpText } from '@shared/constants/help-text-constants';
import { PropertyHelper } from '@core/services/helpers/property.helper';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

@Component({
  selector: 'mq-protective-devices-form',
  templateUrl: './protective-devices-form.component.html',
  styleUrls: ['./protective-devices-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProtectiveDevicesFormComponent implements OnInit, OnChanges {
  @Input() protectiveDevices: ProtectiveDevice[];
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() form: UntypedFormGroup;
  @Input() options: any;

  protectiveDevicesHelpText: string;
  protectiveDevicesFieldId: string;
  protectiveDeviceslabelText: string;
  windProtectiveDevicesHelpText: string;

  restrictWindstormProtectiveDeviceToHomeowners: boolean;
  showAdditionalProtectionDevices: boolean;

  protectiveDeviceOptions: any = {};

  constructor(
    private _propertyHelper: PropertyHelper,
    private _fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.restrictWindstormProtectiveDeviceToHomeowners =
      this.stateSpecificFlags.restrictWindstormProtectiveDeviceToHomeowners;

    this.protectiveDevicesHelpText =
      ProtectiveDevicesHelpText.ProtectiveDevices;
    this.protectiveDevicesFieldId = ProtectiveDevicesHelpText.assignedFieldId;
    this.protectiveDeviceslabelText = ProtectiveDevicesHelpText.labelInnerHTML;
    this.windProtectiveDevicesHelpText =
      ProtectiveDevicesHelpText.WindProtectiveDevices;

    if (this.stateSpecificFlags.windMitigationDiscountApplicable) {
      this.protectiveDevicesHelpText += this.windProtectiveDevicesHelpText;
    }

    this.protectiveDevices.forEach(device => {
      const selectedDeviceValue =
        device.value !== undefined ? device.value : 'None';

      const replacedSelectedDeviceValue =
        this._propertyHelper.convertProtectiveDeviceSelectedValueBackToCorrectFormat(
          device,
          selectedDeviceValue
        );

      this.form.addControl(
        device.type,
        this._fb.control(replacedSelectedDeviceValue, [])
      );

      this.protectiveDeviceOptions[device.type] =
        this.filterProtectiveDeviceOptions(device);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.protectiveDevices) {
      // don't rebuild the form, just repopulate it
      this.populateProtectiveDevices();
    }
  }

  populateProtectiveDevices(): void {
    const value = {};
    for (const device of this.protectiveDevices) {
      const selectedDeviceValue =
        device.value !== undefined ? device.value : 'None';
      const deviceValue =
        this._propertyHelper.convertProtectiveDeviceSelectedValueBackToCorrectFormat(
          device,
          selectedDeviceValue
        );
      value[device.type] = deviceValue;
    }
    this.form.patchValue(value);
  }

  dumpFormControlNames(): string {
    const result = Object.keys(this.form.controls).join(',');
    return result;
  }

  filterProtectiveDeviceOptions(device: ProtectiveDevice): string[] {
    return device.selectedValues.filter(value => value !== 'None');
  }

  getProtectiveDeviceOptions(deviceType: string): string[] {
    return this.protectiveDeviceOptions[deviceType];
  }
}
