import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VehicleRequest } from '@core/models/request/vehicle-request.model';
import { VehicleResponse } from '@core/models/response/vehicle-response.model';
import { HeadersUtils } from '@shared/utils/headers.utils';
import { VehicleDetailsResponse } from '@core/models/response/vehicle-details-response.model';
import { VehicleDetailsRequest } from '@core/models/request/vehicle-details-request.model';
import { ProductTypes } from '@shared/constants/app-constants';
import { BaseDsmAdapter } from '@core/adapters/base-dsm.adapter';
import { StringUtils } from '@shared/utils/string.utils';
import { PowersportsVehicleVinDetailsResponse } from '@core/models/auto/vehicle-vin.model';

@Injectable({
  providedIn: 'root',
})
export class VehicleAdapter extends BaseDsmAdapter {
  addVehicle(request: VehicleRequest): Observable<VehicleResponse> {
    const url = `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}/vehicles`;

    const outboundRequestBody = { vehicle: { ...request.vehicle } };

    return this.post(url, outboundRequestBody, {
      productId: ProductTypes.AUTO,
      prePopulatedInsuredId: outboundRequestBody.vehicle.prepopulatedInsuredId,
      partialQuoteId: outboundRequestBody.vehicle.partialQuoteId,
    });
  }

  addPowersportsVehicle(request: VehicleRequest): Observable<VehicleResponse> {
    const url = `${this._appConfig.config.pcEdgePowersportsUrl}/quotes/${request.quoteId}/vehicles`;

    const outboundRequestBody = { vehicle: { ...request.vehicle } };

    return this.post(url, outboundRequestBody, {
      productId: ProductTypes.POWERSPORTS,
    });
  }

  updateVehicle(request: VehicleRequest): Observable<VehicleResponse> {
    const url = `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}/vehicles/${request.vehicle.vehicleId}`;

    const outboundRequestBody = { vehicle: { ...request.vehicle } };

    return this.put(url, outboundRequestBody, {
      productId: ProductTypes.AUTO,
    });
  }

  updatePowersportsVehicle(
    request: VehicleRequest
  ): Observable<VehicleResponse> {
    const url = `${this._appConfig.config.pcEdgePowersportsUrl}/quotes/${request.quoteId}/vehicles/${request.vehicle.vehicleId}`;

    const outboundRequestBody = { vehicle: { ...request.vehicle } };

    return this.put(url, outboundRequestBody, {
      productId: ProductTypes.POWERSPORTS,
    });
  }

  vehicleDetails(
    request: VehicleDetailsRequest
  ): Observable<VehicleDetailsResponse> {
    const url = `${this._appConfig.config.vehicleDetailsUrl}/vehicledetails/${request.vin}`;

    const headers = HeadersUtils.buildHeaderForVehicleDetails(
      request.accessToken
    );

    const params = new HttpParams()
      .set('quoteId', request.quoteId)
      .set('vehicleId', request.entity.vehicleId)
      .set('state', request.state)
      .set('client_id', this._appConfig.config.apiKey);

    return this._http.get<VehicleDetailsResponse>(url, {
      headers,
      params,
    });
  }

  powersportsVehicleDetails(
    request: VehicleDetailsRequest
  ): Observable<PowersportsVehicleVinDetailsResponse> {
    const url = `${this._appConfig.config.powersportsVehicleInquiryUrl}/motorcycle-models/vin`;

    const messageID = StringUtils.generateUUID();
    const params = new HttpParams({
      fromObject: {
        vin: request.vin,
      },
    });

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);

    return this._http.get<PowersportsVehicleVinDetailsResponse>(url, {
      headers,
      params,
    });
  }

  deleteVehicle(request: VehicleRequest): Observable<VehicleResponse> {
    const url = `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}/vehicles/${request.vehicle.vehicleId}`;

    return this.delete(url, {
      productId: ProductTypes.AUTO,
    });
  }

  deletePowerportsVehicle(
    request: VehicleRequest
  ): Observable<VehicleResponse> {
    const url = `${this._appConfig.config.pcEdgePowersportsUrl}/quotes/${request.quoteId}/vehicles/${request.vehicle.vehicleId}`;

    return this.delete(url, {
      productId: ProductTypes.POWERSPORTS,
    });
  }
}
