import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  ViewContainerRef,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { style, animate, AnimationBuilder, query } from '@angular/animations';
import { ProductDisplay } from '@core/models/display/quote-coverages/products/product-display.model';
import { Subject } from 'rxjs';
import { PrivateLabel } from '@core/models/private-label/private-label.model';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { Product } from '@core/models/products/product.model';
import {
  CustomUIElements,
  PRODUCT_ICON_BG_COLOR_PROP,
} from '@shared/constants/private-label-constants';
import { Animations } from '@shared/animations/animations';
import {
  TermType,
  DESKTOP_VIEW,
  ProductTypes,
  INTERNAL_LINKS,
} from '@shared/constants/app-constants';
import { StringUtils } from '@shared/utils/string.utils';

@Component({
  selector: 'mq-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    Animations.selected,
    Animations.slideUpDownProductParent,
    Animations.slideUpDownProductChild,
  ],
})
export class ProductComponent implements AfterViewInit, OnDestroy {
  private readonly timeDelay = 300;

  @Input() product: ProductDisplay | Product;
  @Input() coverageProduct: ProductDisplay;
  @Input() index;
  @Input() gettingStartedProduct: boolean;
  @Input() totalCostEstimate: number;
  @Input() showDisabled: boolean;
  @Input() costEstimate: number;
  @Input() isBindCoverages: boolean;
  @Input() disabled: boolean;
  @Input() isNoPlayStateAnyProductSelectedWithOutTermLife: boolean;
  @Input() isNoPlayStateAnyProductSelectedWithOutPet: boolean;
  @Input() isNoPlayStatePetRestricted: boolean;
  @Input() isPetUnavailableState: boolean;
  @Input() set isNoPlayStateTermLifeRestricted(value: boolean) {
    if (
      this._viewContainerRef.element.nativeElement.classList.contains(
        'selected'
      ) &&
      value
    ) {
      this.selectProduct(false);
    }
    this.isTermLifeRestrictedForNoPlayState = value;
  }
  @Input() hide: boolean;
  @Input() set privateLabelConfig(config: PrivateLabel) {
    const configVal = PrivateLabelContentHelper.getConfigValue(
      config,
      CustomUIElements.PRODUCT_ICON_BG_COLOR
    );

    if (configVal) {
      this._elem.nativeElement.style.setProperty(
        PRODUCT_ICON_BG_COLOR_PROP,
        configVal
      );
    }

    this.isPrivateLabel = !!config;
  }

  @Output() selectedProduct = new EventEmitter<any>();
  @Output() selectedProductCoverages = new EventEmitter<any>();

  @ViewChild('component') component: ProductComponent;
  @ViewChild('animationWrapper')
  animationWrapper: ElementRef;

  isMobile = window.innerWidth < DESKTOP_VIEW;
  isSelected: string;
  disableLifeCheckbox: boolean;
  totalPremium: number;
  termMonths: number;
  isTermLifeRestrictedForNoPlayState: boolean;
  hideForDsm: boolean;
  touchEventInProgress = false;
  isTermLife: boolean;
  hyperlinkedText = INTERNAL_LINKS.autoQuoteHelpText;
  url = INTERNAL_LINKS.autoQuoteHelpURL;
  private isPrivateLabel = false;

  private _ngUnsubscribe = new Subject();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < DESKTOP_VIEW;
  }

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _builder: AnimationBuilder,
    private _elem: ElementRef,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    if (
      this._viewContainerRef.element.nativeElement.classList.contains(
        'selected'
      )
    ) {
      this.isSelected = 'true';
    } else {
      this.isSelected = 'false';
    }
    this.setTotalPremium();
    this._changeDetector.detectChanges();
    this.disableLifeCheckbox = true;
    this.isTermLife = StringUtils.areEqual(
      this.product.id,
      ProductTypes.TERMLIFE
    );
  }

  onTouch(): void {
    this.touchEventInProgress = true;
  }

  slideUp(): void {
    if (
      this.showDisabled ||
      this.product.hasError ||
      this.isTermLifeRestrictedForNoPlayState ||
      this.isPetUnavailableState
    ) {
      return;
    }

    // If this "mouse" event was caused by a touch, let the event propagate but suppress the animation.
    if (this.touchEventInProgress) {
      this.touchEventInProgress = false;
    } else if (window.innerWidth >= DESKTOP_VIEW) {
      const animation = this._builder.build([
        query(
          '.animation-wrapper',
          animate(this.timeDelay, style({ transform: 'translateY(-26px)' })),
          { optional: true }
        ),
      ]);

      const player = animation.create(this._elem.nativeElement);
      player.play();
    }
  }

  slideDown(): void {
    if (
      this.showDisabled ||
      this.product.hasError ||
      this.isTermLifeRestrictedForNoPlayState
    ) {
      return;
    }

    if (window.innerWidth >= DESKTOP_VIEW) {
      const animation = this._builder.build([
        query(
          '.animation-wrapper',
          animate(this.timeDelay, style({ transform: 'translateY(0px)' })),
          { optional: true }
        ),
      ]);

      const player = animation.create(this._elem.nativeElement);
      player.play();
    }
  }

  selectProduct(toggle: boolean, triggeringEvent?: any): void {
    if (triggeringEvent) {
      // Only necessary for IE; because the <img> element sends events on its own.
      triggeringEvent.stopPropagation();
      triggeringEvent.preventDefault();
    }
    if (
      this.showDisabled ||
      this.hideForDsm ||
      this.disabled ||
      this.isTermLifeRestrictedForNoPlayState ||
      this.isPetUnavailableState ||
      (this.product.hasError && !this.product.isConditional)
    ) {
      return;
    }

    this.isSelected = this.isSelected === 'false' ? 'true' : 'false';
    this.selectedProduct.emit({ product: this.product, toggle });
  }

  learnMore(event?: KeyboardEvent): void {
    if (event && event.key !== 'Enter' && event.key !== ' ') {
      return;
    }
    this.selectedProduct.emit({ product: this.product });
  }

  selectProductCoverages(event): void {
    this.selectedProductCoverages.emit(this.product);
    event.stopPropagation();
    event.preventDefault();
  }

  selectProductCoveragesIfEnterOrSpace(event): void {
    if (
      !this.disabled &&
      event &&
      (event.key === 'Enter' || event.key === ' ')
    ) {
      this.selectProductCoverages(event);
    }
  }

  isCoverageSelected(): boolean {
    if (this.coverageProduct) {
      return this.coverageProduct.id === this.product.id;
    }
  }

  setTotalPremium(): void {
    if (this.product && this.product.id === ProductTypes.TERMLIFE) {
      if (this.product.monthlyPremium) {
        this.termMonths = TermType.Annual;
        this.totalPremium = this.product.monthlyPremium * this.termMonths;
      }
    } else {
      if (this.product.monthlyPremium) {
        this.termMonths = TermType[this.product.termType];
        this.totalPremium = this.product.monthlyPremium * this.termMonths;
      }
    }
  }

  updateTermLifeMonthlyPremium(costEstimate: number): void {
    if (this.product.id === ProductTypes.TERMLIFE && costEstimate) {
      this.product.monthlyPremium = costEstimate;
    }
  }

  showMonthlyEstTermLife(product: Product): boolean {
    if (
      StringUtils.areEqual(product.id, ProductTypes.TERMLIFE) &&
      product.quoteRated
    ) {
      return true;
    } else {
      return (
        product.monthlyPremium && (product.quoteRated || product.bindRated)
      );
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  shouldShowWhatYouNeedLink(): boolean {
    return (
      this.product.type === 'auto' &&
      this.isMobile &&
      this.gettingStartedProduct &&
      !this.isPrivateLabel
    );
  }
}
