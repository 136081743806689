import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import {
  PrivateLabel,
  ProducerInformation,
  CoverageConfiguration,
} from '@core/models/private-label/private-label.model';
import { PrivateLabelAdapter } from '@core/adapters/private-label.adapter';
import { PrivateLabelDao } from '@core/dao/private-label.dao';
import { AppConfigService } from './app-config.service';
import { UserContextService } from './user-context.service';
import {
  PrivateLabelType,
  UserType,
  UserContext,
} from '@core/models/user-context/user-context.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class PrivateLabelService {
  constructor(
    private _privateLabelAdapter: PrivateLabelAdapter,
    private _privateLabelDao: PrivateLabelDao,
    private _appConfig: AppConfigService,
    private _userContextService: UserContextService
  ) {}

  establishPrivateLabelConfiguration(
    identifier?: string,
    isPreview?: boolean
  ): Observable<PrivateLabel> {
    const agencyIdentifier = identifier
      ? identifier
      : this._appConfig.agencyIdentifier;
    return identifier || this._appConfig.isPrivateLabelDomain()
      ? this.getConfiguration(
          agencyIdentifier,
          UserType.DIRECT_PRIVATELABEL,
          PrivateLabelType.DOMAIN,
          isPreview
        )
      : of(null);
  }

  loadPrivateLabelConfiguration(identifier?: string): void {
    this._privateLabelDao.loadPrivateLabelConfiguration(identifier);
  }

  getPrivateLabelConfiguration(): Observable<PrivateLabel> {
    return this._privateLabelDao.getPrivateLabelConfiguration();
  }

  getPrivateLabelConfigurationLoaded(): Observable<boolean> {
    return this._privateLabelDao.getPrivateLabelConfigurationLoaded();
  }

  getStates(): Observable<string[]> {
    return this._privateLabelDao.getStates();
  }

  getProducerInformationBy(state: string): Observable<ProducerInformation> {
    return this._privateLabelDao.getProducerInformationBy(state);
  }

  getCoverageConfiguration(): Observable<CoverageConfiguration[]> {
    return this._privateLabelDao.getCoverageConfiguration();
  }

  getContactNumber(page?: string): Observable<string> {
    return this._privateLabelDao.getContactNumber(page);
  }

  isPrivateLabel(): Observable<boolean> {
    return this._privateLabelDao.isPrivateLabel();
  }

  getProducerContactNumber(page?: string): Observable<string> {
    return this._privateLabelDao.getProducerContactNumber(page);
  }

  getPrivateLabelLogo(url: string): Observable<any> {
    return this._privateLabelAdapter.getPrivateLabelLogo(url);
  }

  getProducerInformation(): Observable<ProducerInformation> {
    return this._privateLabelDao.getProducerInformation();
  }

  getAgencyProducts(): Observable<string[]> {
    return this._privateLabelDao.getAgencyProducts();
  }

  isConnectedCarEnabled(): Observable<boolean> {
    return this._privateLabelDao.isConnectedCarEnabled();
  }

  private getConfiguration(
    identifier: string,
    userType: UserType,
    type: PrivateLabelType,
    isPreview: boolean
  ): Observable<PrivateLabel> {
    return this._privateLabelAdapter
      .getConfigurationForIdentifier(identifier, isPreview)
      .pipe(
        tap(config => {
          if (config) {
            const userContext = {
              userType,
              privateLabelInformation: {
                type,
                key: identifier,
              },
            } as UserContext;
            if (config.isThirdPartyPartner) {
              userContext.spid = identifier;
            }
            this._userContextService.updateUserContext(userContext);
          }
        })
      );
  }
}
