import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, filter, take } from 'rxjs/operators';
import { PartialQuoteService } from '@core/services/partial-quote.service';

@Injectable()
export class PartialQuoteGuard implements CanActivate {
  constructor(private _partialQuoteService: PartialQuoteService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (route.queryParams['partialQuoteId'] && route.queryParams['otp']) {
      return this.checkStore();
    }
    return of(true);
  }

  checkStore(): Observable<boolean> {
    return this._partialQuoteService.getPartialQuoteLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._partialQuoteService.loadPartialQuoteData();
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
