import { TARGET_ENVS } from '@admin/containers/pls-environment-tester/pls-environment-tester.component';
import {
  DSM_STAGE_TO_TEST_TARGET_ENV,
  NWX_ENVS,
} from '@shared/constants/app-constants';

import { QuoteStartDateFetcher } from '@admin/containers/quote-start/quote-start-date-fetcher';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Product } from '@core/models/products/product.model';
import {
  AppConfigService,
  PolicyService,
  SessionService,
} from '@core/services';
import { DebugModel } from 'app/debug/debug.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nwx-admin-overlay',
  templateUrl: './admin-overlay.component.html',
  styleUrls: ['./admin-overlay.component.scss'],
})
export class AdminOverlayComponent implements OnInit, OnDestroy {
  @Input() selectedProducts: Product[];
  readonly billingEnvs = ['CIBD', 'CIB1', 'CIAA'];
  targetEnvs: string[] = TARGET_ENVS;
  showTargetPCEnv = '';
  nwxEnvs: string[] = NWX_ENVS;
  open = false;
  form!: UntypedFormGroup;
  effectiveDate: string;
  private unsubscribe$ = new Subject<void>();
  adminIgnoreCurrentCarrierValidation: boolean;
  adminIgnoreProductDisablement: boolean;
  adminIgnoreRiskFilter: boolean;
  adminIgnoreAutoUWRules: boolean;
  adminPCEnv: string;
  currentNwxEnv: string;
  plsEnv: string;
  constructor(
    private appConfigService: AppConfigService,
    private _sessionService: SessionService,
    private _dateFetcher: QuoteStartDateFetcher,
    private _policyService: PolicyService
  ) {}

  ngOnInit(): void {
    this.plsEnv = this.appConfigService.config.targetEnv;
    this.findRouterCookie();
    this.form = this.buildForm();
    this.readValueFromAppConfigService();
    combineLatest([
      this._sessionService.getIgnoreRiskFilter(),
      this._sessionService.getPCEnv(),
      this._sessionService.getIgnoreAutoUWRules(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([adminIgnoreRiskFilter, adminPCEnv, adminIgnoreAutoUWRules]) => {
          this.form.patchValue({
            ignoreRiskFilter: adminIgnoreRiskFilter
              ? adminIgnoreRiskFilter
              : '',
            ignoreAutoUWRules: adminIgnoreAutoUWRules
              ? adminIgnoreAutoUWRules
              : '',
            pcEnv: adminPCEnv
              ? adminPCEnv
              : this.appConfigService.config.targetEnv,
          });
        }
      );
    this._sessionService
      .getIgnoreCurrentCarrierValidation()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(adminIgnoreCurrentCarrierValidation => {
        this.adminIgnoreCurrentCarrierValidation =
          adminIgnoreCurrentCarrierValidation;
        if (this.adminIgnoreCurrentCarrierValidation)
          this.form.patchValue({
            ignoreCurrentCarrierValidation:
              this.adminIgnoreCurrentCarrierValidation,
          });
      });
    this._sessionService
      .getIgnoreProductDisablement()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(adminIgnoreProductDisablement => {
        this.adminIgnoreProductDisablement = adminIgnoreProductDisablement;
        if (this.adminIgnoreProductDisablement)
          this.form.patchValue({
            ignoreProductDisablement: this.adminIgnoreProductDisablement,
          });
      });
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.onChange(value));
  }

  ngOnDestroy(): void {
    this.form.disable();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onToggle(): void {
    this.open = !this.open;
  }

  onChange(value: DebugModel): void {
    this.writeValueToAppConfigService(value);
  }

  buildForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      billingEnv: new UntypedFormControl('', []),
      pcEnv: new UntypedFormControl('', []),
      ignoreRiskFilter: new UntypedFormControl(null, []),
      ignoreAutoUWRules: new UntypedFormControl(null, []),
      ignoreCurrentCarrierValidation: new UntypedFormControl(null, []),
      ignoreProductDisablement: new UntypedFormControl(null, []),
      nwxTestEnv: new UntypedFormControl('', []),
    });
  }

  private readValueFromAppConfigService(): void {
    const src = this.appConfigService.config;
    const v: DebugModel = {
      nwxTestEnv: this.currentNwxEnv,
      billingEnv: src.billingTargetEnv,
      pcEnv: src.targetEnv,
      ignoreRiskFilter: !!src.ignoreRiskFilter,
      ignoreAutoUWRules: !!src.ignoreAutoUWRules,
      ignoreCurrentCarrierValidation: !!src.ignoreCurrentCarrierValidation,
      ignoreProductDisablement: !!src.ignoreProductDisablement,
    };
    this.form.patchValue(v);
  }

  private writeValueToAppConfigService(value: DebugModel): void {
    this._sessionService.setIgnoreRiskFilter(value.ignoreRiskFilter);
    this.appConfigService.config.ignoreRiskFilter =
      value.ignoreRiskFilter === true ? 'true' : 'false';
    this._sessionService.setIgnoreAutoUWRules(value.ignoreAutoUWRules);
    this.appConfigService.config.ignoreAutoUWRules =
      value.ignoreAutoUWRules === true ? 'true' : 'false';
    this._sessionService.setIgnoreProductDisablement(
      value.ignoreProductDisablement
    );
    this.appConfigService.config.ignoreProductDisablement =
      value.ignoreProductDisablement === true ? 'true' : 'false';
    this._sessionService.setIgnoreCurrentCarrierValidation(
      value.ignoreCurrentCarrierValidation
    );
    this.appConfigService.config.ignoreCurrentCarrierValidation =
      value.ignoreCurrentCarrierValidation === true ? 'true' : 'false';
    this._sessionService.setBillingEnv(value.billingEnv);
    this.appConfigService.config.billingTargetEnv = value.billingEnv;
    this.appConfigService.config.targetEnv = value.pcEnv;
    this._sessionService.setPCEnv(value.pcEnv);
  }

  disableBillingEnv(): string {
    if (this.selectedProducts?.some(product => product?.policyNumber)) {
      return 'disable';
    }
    return null;
  }

  disablePCEnv(): boolean {
    const selectedNwxEnv = this.form.get('nwxTestEnv').value;
    if (
      selectedNwxEnv === 'Stage' ||
      selectedNwxEnv === 'Stage2' ||
      selectedNwxEnv === 'Stage3'
    ) {
      return true;
    } else {
      return false;
    }
  }
  showPCEnv(): boolean {
    const selectedNwxEnv = this.form.get('nwxTestEnv').value;
    if (
      selectedNwxEnv === undefined ||
      selectedNwxEnv === 'ProdLike' ||
      this.selectedProducts?.some(product => product?.quoteId)
    ) {
      return false;
    } else {
      return true;
    }
  }

  onPCEnvChanged(event): void {
    this._dateFetcher
      .fetchDate(event.target.value)
      .pipe(take(1))
      .subscribe(() => {
        this._dateFetcher
          .streamResults()
          .pipe(take(1))
          .subscribe(date => {
            this._policyService.setPolicyEffectiveDate({
              initialized: true,
              autoEffectiveDate: date,
              homeownersEffectiveDate: date,
              condoEffectiveDate: date,
              rentersEffectiveDate: date,
              baseEffectiveDate: date,
            });
          });
      });
  }

  findRouterCookie(): string {
    const kv = document.cookie
      .split(';')
      .map(kv1 => kv1.split('='))
      .find(([key, _value]) => key.trim() === 'aws_router');

    if (kv && kv.length > 0) {
      this.currentNwxEnv = kv[1];
      return kv[1];
    } else {
      this.currentNwxEnv = 'ProdLike';
      return '';
    }
  }

  isStagePCEnv(): string {
    const apiKey = this.appConfigService.config.targetEnv;
    const pcEnv = this.form.get('pcEnv').value;
    if (pcEnv.startsWith('pls')) {
      return pcEnv + ' === ' + apiKey;
    } else {
      return apiKey + ' => ' + DSM_STAGE_TO_TEST_TARGET_ENV[apiKey];
    }
  }

  get pcEnv(): AbstractControl {
    return this.form.get('pcEnv');
  }

  get ignoreRiskFilter(): AbstractControl {
    return this.form.get('ignoreRiskFilter');
  }

  get ignoreAutoUWRules(): AbstractControl {
    return this.form.get('ignoreAutoUWRules');
  }

  get ignoreCurrentCarrierValidation(): AbstractControl {
    return this.form.get('ignoreCurrentCarrierValidation');
  }

  get nwxTestEnv(): AbstractControl {
    return this.form.get('nwxTestEnv');
  }

  get billingEnv(): AbstractControl {
    return this.form.get('billingEnv');
  }
}
