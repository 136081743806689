import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PageAlertDao } from '@core/dao/page-alert.dao';
import { PageAlertAdapter } from '@core/adapters/page-alert.adapter';
import { PageAlert, Alert } from '@core/models/page-alerts/page-alert.model';
import { PageAlertIDs, ChannelNames } from '@shared/constants/app-constants';
import { map } from 'rxjs/operators';

@Injectable()
export class PageAlertService {
  constructor(
    private _pageAlertAdapter: PageAlertAdapter,
    private _pageAlertDao: PageAlertDao
  ) {}

  establishPageAlerts(): Observable<PageAlert[]> {
    return this._pageAlertAdapter.getPageAlerts();
  }

  loadPageAlerts(): void {
    this._pageAlertDao.loadPageAlerts();
  }

  dispatchActiveAlert(pageId: string): void {
    this._pageAlertDao.dispatchActiveAlert(pageId);
  }

  getActiveAlert(): Observable<Alert> {
    return this._pageAlertDao.getActiveAlert();
  }

  getActiveAlerts(): Observable<Alert[]> {
    return this._pageAlertDao.getActiveAlerts();
  }

  getActiveAlertTextOfType(
    type: 'primary' | 'warning' | 'error',
    limit?: number
  ): Observable<string[]> {
    return this.getActiveAlerts().pipe(
      map(alerts => {
        const filtered = alerts
          .filter(alert => alert.type === type)
          .map(alert => alert.message);
        if (limit) {
          return filtered.slice(0, limit);
        } else {
          return filtered;
        }
      })
    );
  }

  clear(): void {
    this._pageAlertDao.clear();
  }

  getPageAlertLoaded(): Observable<boolean> {
    return this._pageAlertDao.getPageAlertLoaded();
  }

  getPageAlertIdFromUrl(url: string): string {
    let pageId = null;
    const [urlBasePath] = url.split('?');

    Object.keys(PageAlertIDs).forEach(key => {
      if (urlBasePath.includes(key)) {
        pageId = PageAlertIDs[key];
      }
    });

    return pageId;
  }

  addAlert(
    pageName: string,
    pageId: string,
    message: string,
    channels?: string[],
    dismissable?: boolean,
    icon?: string,
    type?: string,
    states?: string[],
    priority?: number
  ): void {
    this._pageAlertDao.addAlert({
      id: '',
      pageName: pageName,
      pageId: pageId,
      message: message,
      priority: priority || 0,
      appliedChannels: channels || [
        'Direct',
        'Mobile',
        ChannelNames.EA,
        ChannelNames.IA,
        ChannelNames.NSS,
        ChannelNames.PRIVATE_LABEL,
      ],
      appliedStates: states || [],
      dismissible: dismissable || false,
      icon: icon || 'icon notification',
      type: type || 'primary',
    });
  }
}
