import { createSelector } from '@ngrx/store';

import * as fromFeature from '@quote/store/reducers';
import * as fromNavigation from '@quote/store/reducers/navigation.reducer';

export const getNavigationState = createSelector(
  fromFeature.getQuotingState,
  (state: fromFeature.QuotingState) => state.navigation
);

export const getNavigationBasePath = createSelector(
  getNavigationState,
  fromNavigation.getNavigationBasePath
);

export const getNavigationCurrentPath = createSelector(
  getNavigationState,
  fromNavigation.getNavigationCurrentPath
);

export const getNavigationLoaded = createSelector(
  getNavigationState,
  fromNavigation.getNavigationLoaded
);

export const getNavigationLoading = createSelector(
  getNavigationState,
  fromNavigation.getNavigationLoading
);

export const getNavigationDirection = createSelector(
  getNavigationState,
  state => state.direction
);
