import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { Observable } from 'rxjs';
import {
  TelematicsEnrollmentResponse,
  TelematicsEnrollmentRecommendationResponse,
  PreQualifiedVehicleInfo,
} from '@core/models/telematics/telematics.model';

@Injectable()
export class TelematicsEnrollmentsDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  dispatchTelematicsEnrollment(): void {
    this._store.dispatch(new fromActions.GetTelematicsEnrollments());
  }

  dispatchAddTelematicsEnrollment(request) {
    this._store.dispatch(new fromActions.AddTelematicsEnrollment(request));
  }

  dispatchUpdateTelematicsEnrollment(request) {
    this._store.dispatch(new fromActions.UpdateTelematicsEnrollment(request));
  }

  dispatchRemoveTelematicsEnrollment() {
    this._store.dispatch(new fromActions.RemoveTelematicsEnrollment());
  }

  dispatchSetTelematicsEnrollment(enrollment: TelematicsEnrollmentResponse) {
    this._store.dispatch(new fromActions.SetTelematicsEnrollment(enrollment));
  }

  dispatchUnsetTelematicsEnrollmentLoaded() {
    this._store.dispatch(new fromActions.UnsetTelematicsEnrollmentLoaded());
  }

  getEnrollmentsLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getEnrollmentsLoaded);
  }

  getEnrollmentsLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getEnrollmentsLoading);
  }

  getEnrollments(): Observable<TelematicsEnrollmentResponse> {
    return this._store.select(fromSelectors.getEnrollments);
  }

  getRecommendation(): Observable<TelematicsEnrollmentRecommendationResponse> {
    return this._store.select(fromSelectors.getRecommendation);
  }

  hasAnyEnrollment(): Observable<boolean> {
    return this._store.select(fromSelectors.hasAnyEnrollment);
  }

  isVehicleEnrolled(vehicleId: string): Observable<boolean> {
    return this._store.select(fromSelectors.isVehicleEnrolled(vehicleId));
  }

  isConnectedCarEligibleAndHasAnyEnrollment(): Observable<boolean> {
    return this._store.select(
      fromSelectors.isConnectedCarEligibleAndHasAnyEnrollment
    );
  }

  getPreQualifiedVehicleInfo(): Observable<PreQualifiedVehicleInfo[]> {
    return this._store.select(fromSelectors.getPreQualifiedVehicleInfo);
  }
  dispatchUpdateMobileInfoTelematicsEnrollment(request: {
    isTextNotificationRequired: boolean;
    phoneNumber: string;
  }): void {
    this._store.dispatch(
      new fromActions.UpdateMobileInfoTelematicsEnrollment(request)
    );
  }

  isSmartMilesEnrolled(): Observable<boolean> {
    return this._store.select(fromSelectors.isSmartMilesEnrolled);
  }
  getPolicyHolderPhoneNumber(): Observable<string> {
    return this._store.select(fromSelectors.getPolicyHolderPhoneNumber);
  }
}
