import * as fromBilling from '@core/store/actions/billing.action';
import { BillingModel } from '@core/models/billing/billing.model';
import { ProductPayPlanSearchResponse } from '@core/models/billing/payment-plan/payment-plan-response.model';
import { DownPaymentExperienceResponse } from '@core/models/billing/down-payment/down-payment-expereience-response.model';
import { SetupAccountExperienceResponse } from '@core/models/billing/setup-account/setup-account-experience-response.model';
import { GIACTAPI_RESPONSE_CODES } from '@shared/constants/app-constants';
import { PolicyNumberTypes } from '../actions/policy-number.action';

export interface BillingState {
  billing: BillingModel;
  paymentPlan: ProductPayPlanSearchResponse;
  dsmDownPayment: DownPaymentExperienceResponse;
  accountSetup: {
    account: SetupAccountExperienceResponse;
    loaded: boolean;
    loading: boolean;
    error: boolean;
    errorMessage?: any;
  };
  loaded: boolean;
  loading: boolean;
  error: boolean;
  accountResponseCode: string;
  accountVerifyHitCount: number;
  isInvalidAccountNachaResponseCode: boolean;
  dsmDownPaymentsFullPayMultiProducts: DownPaymentExperienceResponse[];
  accountSetupFullPayMultiProducts: {
    account: SetupAccountExperienceResponse;
    loaded: boolean;
    loading: boolean;
    error: boolean;
    errorMessage?: any;
  }[];
  isInitializePaymentPlanRequired: boolean;
  rebindWhenInvalidOnBillingChange?: boolean;
  triggerEditBillingPopup: boolean;
}

export const initialState: BillingState = {
  billing: null,
  paymentPlan: null,
  dsmDownPayment: null,
  accountSetup: {
    account: null,
    loaded: false,
    loading: false,
    error: false,
  },
  loaded: false,
  loading: false,
  error: false,
  accountResponseCode: null,
  accountVerifyHitCount: 0,
  isInvalidAccountNachaResponseCode: false,
  dsmDownPaymentsFullPayMultiProducts: [],
  accountSetupFullPayMultiProducts: [],
  isInitializePaymentPlanRequired: true,
  triggerEditBillingPopup: false,
};

export function reducer(
  state = initialState,
  action: fromBilling.BillingActions
): BillingState {
  switch (action.type) {
    case fromBilling.UPDATE_BILLING_NAME: {
      const name = action.payload;
      const billing = { ...state.billing, name };
      return {
        ...state,
        billing,
      };
    }

    case fromBilling.UPDATE_BILLING_ADDRESS: {
      const address = action.payload;
      const billing = { ...state.billing, address };

      return {
        ...state,
        billing,
      };
    }

    case fromBilling.UPDATE_BILLING_UNIT_NUMBER: {
      const unitNumber = action.payload;
      const address = { ...state.billing.address, unitNumber };

      const billing = { ...state.billing, address };
      return {
        ...state,
        billing,
      };
    }

    case fromBilling.UPDATE_PCI_BANKCARD_DATA_SUCCESS: {
      const pciV2Response = action.payload;
      const bankCard = {
        ...state.billing.payment.bankCard,
        ...pciV2Response.card,
        pciLoaded: true,
      };
      const payment = { ...state.billing.payment, bankCard };
      const billing = { ...state.billing, payment };

      return {
        ...state,
        billing,
      };
    }

    case fromBilling.RESET_PCI_V2_LOADED: {
      const pciLoaded = action.payload;
      const bankCard = {
        ...state.billing.payment.bankCard,
        pciLoaded,
      };
      const payment = { ...state.billing.payment, bankCard };
      const billing = { ...state.billing, payment };

      return {
        ...state,
        billing,
      };
    }

    case fromBilling.UPDATE_PCI_BANKCARD_DATA_FAIL: {
      const bankCard = {
        ...state.billing.payment.bankCard,
        pciLoaded: true,
      };

      const payment = { ...state.billing.payment, bankCard };
      const billing = { ...state.billing, payment };
      return { ...state, billing };
    }

    case fromBilling.UPDATE_BILLING_BANKACCOUNT_INFO:
    case fromBilling.UPDATE_BILLING_BANKCARD_INFO: {
      const updatedInfo = action.payload;
      const payment = { ...state.billing.payment, ...updatedInfo };
      const billing = { ...state.billing, payment };
      return { ...state, billing };
    }

    case fromBilling.SETUP_ACCOUNT_FAIL: {
      const accountSetup = {
        ...state.accountSetup,
        loaded: true,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
      return {
        ...state,
        accountSetup,
      };
    }

    case fromBilling.SETUP_ACCOUNT_SUCCESS: {
      const account = action.payload;
      const accountSetup = {
        account: account,
        loading: false,
        loaded: true,
        error: false,
      };

      return {
        ...state,
        accountSetup,
      };
    }

    // Drop setupAccount response if a policy number changes (unusual back-nav cases)
    case PolicyNumberTypes.GENERATE_POLICY_NUMBER as any: {
      return {
        ...state,
        accountSetup: {
          account: null,
          loading: false,
          loaded: false,
          error: false,
        },
      };
    }

    case fromBilling.LOAD_DSM_DOWN_PAYMENT: {
      return {
        ...state,
        loaded: false,
        loading: true,
        error: false,
      };
    }

    case fromBilling.DSM_DOWN_PAYMENT_SUCCESS: {
      const dsmDownPaymentResponse = action.payload;
      return {
        ...state,
        dsmDownPayment: dsmDownPaymentResponse,
        loaded: true,
        loading: false,
        error: false,
        dsmDownPaymentsFullPayMultiProducts: [],
      };
    }

    case fromBilling.LOAD_DSM_DOWN_PAYMENT_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false,
        error: true,
      };
    }

    case fromBilling.DOWN_PAYMENT_NOT_NEEDED: {
      return {
        ...state,
        dsmDownPayment: {
          eligiblePayplansAndPayMethods: [],
          policyLevel: [],
          productId: null,
        },
        loaded: true,
        loading: false,
      };
    }

    case fromBilling.RESET_DSM_DOWN_PAYMENT_LOADED: {
      const dsmDownPaymentLoaded = action.payload;

      return {
        ...state,
        loaded: dsmDownPaymentLoaded,
        loading: false,
        error: false,
      };
    }

    case fromBilling.LOAD_DSM_DOWN_PAYMENT_FULL_PAY_MULTIPRODUCT: {
      return {
        ...state,
        loaded: false,
        loading: true,
        error: false,
      };
    }

    case fromBilling.DSM_DOWN_PAYMENT_FULL_PAY_MULTIPRODUCT_SUCCESS: {
      const dsmDownPaymentsFullPayProduct = action.payload;
      const downPaymentsFullPayMultiProducts = [
        ...state.dsmDownPaymentsFullPayMultiProducts,
      ];
      downPaymentsFullPayMultiProducts.unshift(dsmDownPaymentsFullPayProduct);
      return {
        ...state,
        dsmDownPaymentsFullPayMultiProducts: downPaymentsFullPayMultiProducts,
        loaded: true,
        loading: false,
        error: false,
        dsmDownPayment: null,
      };
    }

    case fromBilling.LOAD_DSM_DOWN_PAYMENT_FULL_PAY_MULTIPRODUCT_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false,
        error: true,
      };
    }

    case fromBilling.UPDATE_ACCOUNTVERIFY_STATUS: {
      const accountVerifyResponse = action.payload;
      // TODO separate success and error into its own reducers,
      // when the Nacha call is moved into an effect.
      if (
        GIACTAPI_RESPONSE_CODES[accountVerifyResponse.Verification.Code] ===
        'SUCCESS'
      ) {
        return {
          ...state,
          accountResponseCode: accountVerifyResponse.Verification.Code,
          accountVerifyHitCount: 0,
        };
      } else if (
        GIACTAPI_RESPONSE_CODES[accountVerifyResponse.Verification.Code] ===
        'INVALID_ACCOUNT'
      ) {
        return {
          ...state,
          accountResponseCode: accountVerifyResponse.Verification.Code,
          accountVerifyHitCount: 0,
          isInvalidAccountNachaResponseCode: true,
        };
      } else {
        return {
          ...state,
          accountResponseCode: accountVerifyResponse.Verification.Code,
          accountVerifyHitCount: state.accountVerifyHitCount + 1,
        };
      }
    }

    case fromBilling.SETUP_ACCOUNT_FULL_PAY_MULTIPRODUCT_FAIL: {
      const accountSetup = {
        ...state.accountSetup,
        loaded: true,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
      return {
        ...state,
        accountSetupFullPayMultiProducts: [
          ...state.accountSetupFullPayMultiProducts,
          accountSetup,
        ],
      };
    }

    case fromBilling.SETUP_ACCOUNT_FULL_PAY_MULTIPRODUCT_SUCCESS: {
      const account = action.payload;
      const accountSetup = {
        account: account,
        loading: false,
        loaded: true,
        error: false,
      };

      return {
        ...state,
        accountSetupFullPayMultiProducts: [
          ...state.accountSetupFullPayMultiProducts,
          accountSetup,
        ],
      };
    }

    case fromBilling.INITIALIZE_BILLING_PAYMENT_PLAN_SUCCESS: {
      const payment = {
        ...state.billing.payment,
        paymentMethod: action.payload.paymentMethod,
        paymentPlan: action.payload.paymentPlan,
        frequency: action.payload.frequency,
        recurring: action.payload.recurring,
      };
      const billing = { ...state.billing, payment };
      return {
        ...state,
        billing,
      };
    }

    case fromBilling.SET_INITIALIZE_PAYMENT_PLAN_REQUIRED: {
      return {
        ...state,
        isInitializePaymentPlanRequired: action.payload,
      };
    }
    case fromBilling.SET_TRIGGER_EDIT_BILLING_POPUP: {
      return {
        ...state,
        triggerEditBillingPopup: action.payload,
      };
    }

    case fromBilling.SET_REBIND_WHEN_INVALID_ON_BILLING_CHANGE: {
      return {
        ...state,
        rebindWhenInvalidOnBillingChange: action.payload,
      };
    }
  }

  return state;
}

export const getBillingState = (state: BillingState) => state.billing;
export const getPaymentPlan = (state: BillingState) => state.paymentPlan;
export const getPaymentPlanLoading = (state: BillingState) => state.loading;
export const getPaymentPlanLoaded = (state: BillingState) => state.loaded;
export const getPaymentPlanError = (state: BillingState) => state.error;
export const getDownPaymentLoading = (state: BillingState) => state.loading;
export const getDownPaymentLoaded = (state: BillingState) => state.loaded;
export const getAccountSetupState = (state: BillingState) => state.accountSetup;
export const getDsmDownPaymentState = (state: BillingState) =>
  state.dsmDownPayment;
export const getAccountSetupFullPayMultiProducts = (state: BillingState) =>
  state.accountSetupFullPayMultiProducts;
