import * as _ from 'lodash';

import {
  PrivateLabel,
  ProducerInformation,
  CoverageConfiguration,
  MinComparison,
  CoverageComparisonType,
  MaxComparison,
  MinPercentValue,
  CustomUIConfiguration,
  ConnectedCarCriteria,
  DefaultComparison,
} from '@core/models/private-label/private-label.model';
import { ProductTypes } from '@shared/constants/app-constants';
import { AgencyModel } from '@core/models/agency/agency.model';
import { ProducerUtils } from './producer.utils';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { CustomUIElements } from '@shared/constants/private-label-constants';

export class PrivateLabelContentHelper {
  static getPhoneNumber(
    config: PrivateLabel,
    producerInfo: ProducerInformation
  ): string {
    if (producerInfo && producerInfo.phoneNumber) {
      return producerInfo.phoneNumber.trim();
    } else {
      return config.additionalPhoneNumber
        ? config.phoneNumber.trim() +
            ' or ' +
            config.additionalPhoneNumber.trim()
        : config.phoneNumber.trim();
    }
  }

  static getContactInformationFor(
    componentName: string,
    config: PrivateLabel,
    producerInformation: ProducerInformation
  ): string {
    if (config) {
      return PrivateLabelContentHelper.getCustomContactText(
        componentName,
        config,
        producerInformation
      );
    }
    return null;
  }

  static getCustomContactText(
    componentName: string,
    config: PrivateLabel,
    producerInfo: ProducerInformation
  ): string {
    switch (componentName) {
      case 'PRODUCT_DETAILS':
        return (
          ' your local Agent at ' +
          PrivateLabelContentHelper.getPhoneNumber(config, producerInfo) +
          ' '
        );
      case 'BIND_COVERAGES':
        return (
          'your Agent at ' +
          PrivateLabelContentHelper.getPhoneNumber(config, producerInfo)
        );
      case 'POLICY_SUMMARY':
        return (
          ' at ' +
          PrivateLabelContentHelper.getPhoneNumber(config, producerInfo) +
          '.'
        );
      case 'HEADER_FOOTER':
        return producerInfo && producerInfo.phoneNumber
          ? producerInfo.phoneNumber.trim()
          : config.phoneNumber.trim();
      default:
        return PrivateLabelContentHelper.getPhoneNumber(config, producerInfo);
    }
  }

  static buildCoverage(coverageFields: any): CoverageConfiguration {
    const coverage = {
      id: coverageFields.coverageId,
      name: coverageFields.coverageName,
      value: coverageFields.coverageLimit,
      productId: PrivateLabelContentHelper.buildProductId(
        coverageFields.productName
      ),
      mandatory: coverageFields.mandatory,
    } as CoverageConfiguration;

    PrivateLabelContentHelper.addAdditionalProperties(
      coverageFields.comparisonMethod,
      coverage
    );

    return coverage;
  }

  static buildProductId(productName: string): string {
    let productId = '';

    // If we wanted to remove the switch
    // return Object.keys(ProductNames).find(
    //   key => ProductNames[key] === productName
    // );

    switch (productName) {
      case 'Auto':
        productId = ProductTypes.AUTO;
        break;
      case 'Homeowners':
        productId = ProductTypes.HOMEOWNERS;
        break;
      case 'Renters':
        productId = ProductTypes.RENTERS;
        break;
      case 'Condo':
        productId = ProductTypes.CONDO;
        break;
      case 'Umbrella':
        productId = ProductTypes.UMBRELLA;
        break;
      case 'Term Life':
        productId = ProductTypes.TERMLIFE;
        break;
    }
    return productId;
  }

  static addAdditionalProperties(
    method: string,
    coverage: CoverageConfiguration
  ): void {
    switch (method) {
      case 'Minimum':
        coverage.coverageComparison =
          PrivateLabelContentHelper.minComparison as MinComparison;
        coverage.type = CoverageComparisonType.Minimum;
        break;
      case 'Maximum':
        coverage.coverageComparison =
          PrivateLabelContentHelper.maxComparison as MaxComparison;
        coverage.type = CoverageComparisonType.Maximum;
        break;
      case 'Default':
        coverage.coverageComparison =
          PrivateLabelContentHelper.defaultComparison as DefaultComparison;
        coverage.type = CoverageComparisonType.Default;
        break;
      case 'Percentage - Minimum':
        coverage.coveragePercentValue =
          PrivateLabelContentHelper.minPercentValue as MinPercentValue;
        coverage.coverageComparison =
          PrivateLabelContentHelper.minComparison as MinComparison;
        coverage.type = CoverageComparisonType.PercentageMinimum;
        break;
    }
  }

  static maxComparison(configVal: number, actual: number): boolean {
    return configVal >= actual;
  }

  static minComparison(configVal: number, actual: number): boolean {
    return configVal <= actual;
  }

  static defaultComparison(configVal: number, actual: number): boolean {
    return configVal === actual;
  }

  static minPercentValue(configDecimal: number, currentVal: number): number {
    return Math.floor(configDecimal * currentVal);
  }

  static buildPrivateLabelAgent(
    config: PrivateLabel,
    producerCode: string
  ): AgencyModel {
    const agency = {} as AgencyModel;
    agency.agencyChannel = config.channel;
    agency.email = config.emailAddress;
    agency.isPrivateLabel = true;
    agency.domain = config.identifier;
    if (config.residentState) {
      agency.state = config.residentState;
    }

    if (producerCode) {
      const producer = ProducerUtils.getAgencyProducer(
        config.channel,
        producerCode
      );
      agency.producerCode = producer.producerCode;
    }

    return agency;
  }

  static getConfigValue(config: PrivateLabel, element: string): string {
    if (!config) {
      return null;
    }
    const configItem = _.find(config.customUIConfig, {
      element,
    }) as CustomUIConfiguration;
    return configItem ? configItem.value : null;
  }

  static shouldExcludeLogo(config: PrivateLabel): boolean {
    const connectedCarEnabledConfigVal =
      PrivateLabelContentHelper.getConfigValue(
        config,
        CustomUIElements.EXCLUDE_LOGO_QUOTE_PDF
      );
    return connectedCarEnabledConfigVal === 'true';
  }

  static shouldEnableConnectedCar(config: PrivateLabel): boolean {
    const connectedCarEnabledConfigVal =
      PrivateLabelContentHelper.getConfigValue(
        config,
        CustomUIElements.CONNECTED_CAR_ENABLED
      );
    return connectedCarEnabledConfigVal === 'true';
  }

  static isConnectedCarEligible = (
    vehicle: VehicleEntity,
    connectedCarCriteria: ConnectedCarCriteria[],
    privateLabelIdentifier: string
  ): boolean => {
    const vehicleMake =
      vehicle.makeDescription && vehicle.makeDescription.toLocaleLowerCase();
    if (connectedCarCriteria && connectedCarCriteria.length) {
      return (
        vehicle.isConnectable &&
        connectedCarCriteria &&
        connectedCarCriteria.length &&
        connectedCarCriteria.some(
          criteria => criteria.make.toLocaleLowerCase() === vehicleMake
        )
      );
    } else {
      return vehicle.isConnectable;
    }
  };

  static areAnyVehiclesConnectedCarEligible = (
    vehicles: VehicleEntity[],
    connectedCarCriteria: ConnectedCarCriteria[],
    privateLabelIdentifier: string
  ): boolean => {
    return vehicles.some(vehicle =>
      PrivateLabelContentHelper.isConnectedCarEligible(
        vehicle,
        connectedCarCriteria,
        privateLabelIdentifier
      )
    );
  };
}
