import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { InitiateExtraParameters } from '@core/models/auto/quotes/initiate-auto-extra-parameters';
import { ProductTypes } from '@shared/constants/app-constants';
import {
  QuoteRequest,
  TermLifeEligible,
  TermLifeRequest,
  UpdateQuoteRequestBody,
} from '@core/models/auto/quotes/update-quote-request.model';
import { MoreDetailsViewModel } from '@core/models/view/more-details.model';
@Injectable({
  providedIn: 'root',
})
export class QuoteDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  dispatchInitiateNewBusiness(
    productId: string,
    extra?: InitiateExtraParameters
  ): void {
    switch (productId) {
      case ProductTypes.AUTO: {
        this._store.dispatch(new fromActions.InitiateNewBusinessAuto(extra));
        break;
      }

      case ProductTypes.HOMEOWNERS: {
        this._store.dispatch(
          new fromActions.InitiateNewBusinessHomeowners(extra)
        );
        break;
      }

      case ProductTypes.CONDO: {
        this._store.dispatch(new fromActions.InitiateNewBusinessCondo(extra));
        break;
      }

      case ProductTypes.RENTERS: {
        this._store.dispatch(new fromActions.InitiateNewBusinessRenters(extra));
        break;
      }
      case ProductTypes.UMBRELLA: {
        this._store.dispatch(new fromActions.InitiateNewBusinessUmbrella());
        break;
      }
      case ProductTypes.POWERSPORTS: {
        this._store.dispatch(
          new fromActions.InitiateNewBusinessPowersports(extra)
        );
        break;
      }
      case ProductTypes.TERMLIFE: {
        this._store.dispatch(new fromActions.GenerateTermLifeQuoteId());
        break;
      }

      default: {
        throw new Error(`Invalid ProductId: ${productId}`);
      }
    }
  }

  dispatchInitiateAllNewBusiness(
    moreDetailsViewModel: MoreDetailsViewModel
  ): void {
    this._store.dispatch(
      new fromActions.InitiateAllNewBusiness(moreDetailsViewModel)
    );
  }

  dispatchUpdateQuote(productId: string): void {
    this._store.dispatch(new fromActions.UpdateQuote(productId));
  }

  dispatchPatchQuote(productId: string, request: UpdateQuoteRequestBody): void {
    this._store.dispatch(new fromActions.PatchQuote(productId, request));
  }

  dispatchRateQuote(
    productId: string,
    ratingType: string,
    suppressSoftfall?: boolean
  ): void {
    this._store.dispatch(
      new fromActions.RateQuote(productId, ratingType, suppressSoftfall)
    );
  }

  dispatchTermLifeRateQuote(termLifeEligible: TermLifeEligible): void {
    this._store.dispatch(new fromActions.TermLifeRateQuote(termLifeEligible));
  }

  getTermLifeQuoteId(productId: string): Observable<string> {
    return this._store.select(fromSelectors.getTermLifeQuoteId(productId));
  }

  getQuoteRating(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteRating(productId));
  }

  getQuoteRated(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteRated(productId));
  }

  getQuoteCompleted(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteCompleted(productId));
  }

  getQuoteBinding(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteBinding(productId));
  }

  getQuoteBound(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteBound(productId));
  }

  getBindCompleted(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getBindCompleted(productId));
  }

  invalidateQuote(productId: string): void {
    this._store.dispatch(new fromActions.InvalidateQuote(productId));
  }

  invalidateBind(productId: string): void {
    this._store.dispatch(new fromActions.InvalidateBind(productId));
  }

  invalidateQuoteCompletion(productId: string): void {
    this._store.dispatch(new fromActions.InValidateQuoteCompletion(productId));
  }

  validateQuoteCompletion(productId: string): void {
    this._store.dispatch(new fromActions.ValidateQuoteCompletion(productId));
  }

  getQuoteUpdateLoaded(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteUpdateLoaded(productId));
  }

  buildRateQuoteRequest(
    productId: string,
    ratingType: string
  ): Observable<QuoteRequest> {
    return this._store.select(
      fromSelectors.buildRateQuoteRequest(productId, ratingType)
    );
  }
  buildQuoteTermLifeRequest(
    termLifeEligible: TermLifeEligible
  ): Observable<TermLifeRequest> {
    return this._store.select(
      fromSelectors.buildTermLifeRateQuoteRequest(termLifeEligible)
    );
  }
}
