import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import { CRUDService } from '@core/services/crud.service';
import { VehicleExposureEntity } from '@core/models/entities/vehicle-exposure.entity';

@Injectable({
  providedIn: 'root',
})
export class VehicleExposureDao implements CRUDService<VehicleExposureEntity> {
  constructor(private _store: Store<fromStore.AppState>) {}
  add(entity: VehicleExposureEntity): void {
    this._store.dispatch(new fromActions.AddVehicleExposure(entity));
  }
  addAll?(entity: VehicleExposureEntity[]): void {
    throw new Error('Method not implemented.');
  }
  delete(entityId: string): void {
    throw new Error('Method not implemented.');
  }
  update(entity: VehicleExposureEntity): void {
    throw new Error('Method not implemented.');
  }
  get?(entityId: string): Observable<VehicleExposureEntity> {
    throw new Error('Method not implemented.');
  }
  getAll?(): Observable<VehicleExposureEntity[]> {
    throw new Error('Method not implemented.');
  }
}
