import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import {
  DriverEntity,
  PersonViewModel,
} from '@core/models/entities/driver.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';

@Injectable({
  providedIn: 'root',
})
export class PartialQuoteDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  loadPartialQuoteData(): void {
    this._store.dispatch(new fromActions.LoadPartialQuoteData());
  }

  getPartialQuoteLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getPartialQuoteLoadedOrErrored);
  }

  getPartialQuotePolicyHolders(): Observable<PersonViewModel[]> {
    return this._store.select(fromSelectors.getPartialQuotePolicyHolders);
  }

  getPartialQuoteDrivers(): Observable<PersonViewModel[]> {
    return this._store.select(fromSelectors.getPartialQuoteDrivers);
  }

  getPartialQuoteVehicles(): Observable<VehicleEntity[]> {
    return this._store.select(fromSelectors.getPartialQuoteVehicles);
  }

  updatePartialQuoteVehicleSelected(vehicle: VehicleEntity): void {
    this._store.dispatch(
      new fromActions.UpdatePartialQuoteVehicleSelected(vehicle)
    );
  }

  updatePartialQuoteDriverSelected(driver: PersonViewModel): void {
    this._store.dispatch(
      new fromActions.UpdatePartialQuoteDriverSelected(driver)
    );
  }

  updatePartialQuotePolicyHolderSelected(policyHolder: PersonViewModel): void {
    this._store.dispatch(
      new fromActions.UpdatePartialQuotePolicyHolderSelected(policyHolder)
    );
  }

  getPartialQuoteId(): Observable<string> {
    return this._store.select(fromSelectors.getPartialQuoteId);
  }

  getPartialQuotePniDriver(): Observable<DriverEntity> {
    return this._store.select(fromSelectors.getPartialQuotePniDriver);
  }

  getPartialQuoteCoveredLocation(): Observable<CoveredLocation> {
    return this._store.select(fromSelectors.getPartialQuoteCoveredLocation);
  }
}
