import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';
import * as fromMetadata from '@core/store/reducers/metadata.reducer';
import { getSessionState } from '@core/store/selectors/session.selector';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

export const getMetadataState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.metadata
);

export const getMaritalStatusState = createSelector(
  getMetadataState,
  fromMetadata.getMaritalStatus
);

export const getMaritalStatusData = createSelector(
  getMaritalStatusState,
  state => state.data
);

export const getMaritalStatusLoading = createSelector(
  getMaritalStatusState,
  state => state.loading
);

export const getMaritalStatusLoaded = createSelector(
  getMaritalStatusState,
  state => state.loaded
);

export const getStateSpecificFlags = createSelector(
  getMetadataState,
  state => state.stateSpecificFlags
);

export const getStateSpecificFlagsLoaded = createSelector(
  getStateSpecificFlags,
  flags => flags.loaded
);

export const getStateSpecificFlagsLoading = createSelector(
  getStateSpecificFlags,
  flags => flags.loading
);

export const getStateSpecificFlag = (name: string) =>
  createSelector(getMetadataState, getSessionState, (metadata, session) => {
    if (!metadata.stateSpecificFlags.hasOwnProperty(name)) {
      return false;
    }
    return metadata.stateSpecificFlags[name].includes(session.quoteState);
  });

export const getStateSpecificFlagsObject = createSelector(
  getMetadataState,
  getSessionState,
  (metadata, session) => {
    const flagReturnObject: StateSpecificFlagsObject = {};
    for (const flag of Object.keys(metadata.stateSpecificFlags.data)) {
      const states = metadata.stateSpecificFlags[flag];
      if (states && states.includes(session.quoteState)) {
        flagReturnObject[flag] = true;
      }
    }
    return flagReturnObject;
  }
);

export const getSuffixState = createSelector(
  getMetadataState,
  fromMetadata.getSuffix
);

export const getSuffixData = createSelector(
  getSuffixState,
  state => state.data
);

export const getSuffixLoading = createSelector(
  getSuffixState,
  state => state.loading
);

export const getSuffixLoaded = createSelector(
  getSuffixState,
  state => state.loaded
);

export const getStateAbbreviationsState = createSelector(
  getMetadataState,
  fromMetadata.getStateAbbreviationsState
);

export const getActiveStateAbbreviationsState = createSelector(
  getMetadataState,
  fromMetadata.getActiveStateAbbreviationsState
);

export const getStateAbbreviations = createSelector(
  getStateAbbreviationsState,
  state => state.data
);

export const getStateAbbreviationsLoading = createSelector(
  getStateAbbreviationsState,
  state => state.loading
);

export const getStateAbbreviationsLoaded = createSelector(
  getStateAbbreviationsState,
  state => state.loaded
);

export const getActiveStateAbbreviations = createSelector(
  getActiveStateAbbreviationsState,
  state => state.data
);

export const getActiveStateAbbreviationsLoading = createSelector(
  getActiveStateAbbreviationsState,
  state => state.loading
);

export const getActiveStateAbbreviationsLoaded = createSelector(
  getActiveStateAbbreviationsState,
  state => state.loaded
);

export const getAgencyChannelState = createSelector(
  getMetadataState,
  fromMetadata.getAgencyChannel
);

export const getAgencyChannelData = createSelector(
  getAgencyChannelState,
  state => state.data
);

export const getAgencyChannelLoading = createSelector(
  getAgencyChannelState,
  state => state.loading
);

export const getAgencyChannelLoaded = createSelector(
  getAgencyChannelState,
  state => state.loaded
);

export const getFeaturesState = createSelector(
  getMetadataState,
  fromMetadata.getFeatures
);

export const getFeaturesLoaded = createSelector(
  getFeaturesState,
  state => state.loaded
);

export const getFeaturesData = createSelector(
  getFeaturesState,
  state => state.data
);

export const getConnectedCarCriteriaState = createSelector(
  getMetadataState,
  state => state.connectedCarCriteria
);

export const getConnectedCarCriteriaLoaded = createSelector(
  getConnectedCarCriteriaState,
  state => state.loaded
);

export const getConnectedCarCriteriaData = createSelector(
  getConnectedCarCriteriaState,
  state => state.data
);

export const getAntiTheftDevicesState = createSelector(
  getMetadataState,
  state => state.antiTheftDevices
);

export const getAntiTheftDevicesLoaded = createSelector(
  getAntiTheftDevicesState,
  state => state.loaded
);

export const getAntiTheftDevices = createSelector(
  getAntiTheftDevicesState,
  state => state.data
);

export const getVehicleEligibleDiscountsState = createSelector(
  getMetadataState,
  state => state.vehicleEligibleDiscounts
);

export const getVehicleEligibleDiscountsLoaded = createSelector(
  getVehicleEligibleDiscountsState,
  state => state.loaded
);

export const getVehicleEligibleDiscountsLoading = createSelector(
  getVehicleEligibleDiscountsState,
  state => state.loading
);

export const getVehicleEligibleDiscounts = createSelector(
  getVehicleEligibleDiscountsState,
  state => state.data
);

export const getVehiclePrimaryUseOptionsState = createSelector(
  getMetadataState,
  state => state.vehiclePrimaryUseOptions
);

export const getVehiclePrimaryUseOptionsLoaded = createSelector(
  getVehiclePrimaryUseOptionsState,
  state => state.loaded
);

export const getVehiclePrimaryUseOptions = createSelector(
  getVehiclePrimaryUseOptionsState,
  state => state.data
);
