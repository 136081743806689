import { TelematicsEnrollmentsService } from '@core/services/telematics-enrollments.service';
import {
  AgencyService,
  BillingService,
  LoadingService,
  ProductsService,
  SessionService,
} from '@core/services';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BillingPaymentPlanModel } from '@core/models/billing/billing.model';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { ModifiersService } from '@core/services/modifiers.service';
import { SessionLog } from '@core/models/session-log.model';
import { Product } from '@core/models/products/product.model';
import { ProductStatus } from '@core/models/products/product-status';

@Injectable()
export class BaseBillingProxy {
  constructor(
    private _telematicsEnrollmentsService: TelematicsEnrollmentsService,
    private _billingService: BillingService,
    private _productsService: ProductsService,
    private _modifiersService: ModifiersService,
    private _loadingService: LoadingService,
    private _sessionService: SessionService,
    private _agencyService: AgencyService
  ) {}
  isSmartMilesEnrolled(): Observable<boolean> {
    return this._telematicsEnrollmentsService.isSmartMilesEnrolled();
  }

  setRebindWhenInvalidOnBillingChange(rebindWhenInvalid: boolean): void {
    this._billingService.setRebindWhenInvalidOnBillingChange(rebindWhenInvalid);
  }

  initializeBillingPaymentPlan(): void {
    this._billingService.initializeBillingPaymentPlan();
  }

  dispatchUpdateBillingPaymentPlan(payload: BillingPaymentPlanModel): void {
    this._billingService.dispatchUpdateBillingPaymentPlan(payload);
  }

  dispatchUpdateBillingPaymentMethod(discount: EligibleDiscountEntity): void {
    this._modifiersService.updateBillingPaymentMethod(discount);
  }

  getBillingPaymentMethodModifier(): Observable<EligibleDiscountEntity> {
    return this._modifiersService.getBillingPaymentMethodModifier();
  }

  getEasyPayModifier(): Observable<EligibleDiscountEntity> {
    return this._modifiersService.getEasyPayModifier();
  }
  isBillingPaymentMethodApplicable(): Observable<boolean> {
    return this._modifiersService.isBillingPaymentMethodApplicable();
  }

  isEasyPayModifierApplicable(): Observable<boolean> {
    return this._modifiersService.isEasyPayModifierApplicable();
  }

  isEasyPayDiscountUpdateRequired(
    paymentPlan: string,
    paymentMethod: string,
    recurring: boolean,
    currentValue: string | null
  ): 'add' | 'remove' | null {
    return this._modifiersService.checkEasyPayDiscount(
      paymentPlan,
      paymentMethod,
      recurring,
      currentValue
    );
  }

  checkEasyPayDiscount(
    paymentPlan: string,
    paymentMethod: string,
    recurring: boolean
  ): void {
    this._modifiersService.beginCheckEasyPayDiscount(
      paymentPlan,
      paymentMethod,
      recurring
    );
  }

  triggerBindRateCall(payload: boolean): void {
    this._billingService.triggerBindRateCall(payload);
  }

  areModifiersLoading(): Observable<boolean> {
    return this._modifiersService.areModifiersLoading();
  }

  getAnyProductRating(): Observable<boolean> {
    return this._productsService.getAnyProductRating();
  }

  loadingBegin(upperMessage?: string, lowerMessage?: string): void {
    this._loadingService.loadingBegin(upperMessage, lowerMessage);
  }

  loadingEnd(): void {
    this._loadingService.loadingEnd();
  }

  updateSessionLogEntry(sessionLog: SessionLog): void {
    this._sessionService.updateSessionLogEntry(sessionLog);
  }
  sendSessionLogEntry(code: string): void {
    this._sessionService.sendSessionLogEntry(code);
  }
  isAgentExperience(): Observable<boolean> {
    return this._agencyService.isAgent();
  }

  isRemoveEftOptionRequired(): Observable<boolean> {
    return this._billingService.isRemoveEftOptionRequired();
  }

  getProductsWithPolicyNumber(): Observable<Product[]> {
    return this._billingService.getProductsWithPolicyNumber();
  }

  setTriggerEditBillingPopup(triggerEditBillingPopup: boolean): void {
    this._billingService.setTriggerEditBillingPopup(triggerEditBillingPopup);
  }

  getTriggerEditBillingPopup(): Observable<boolean> {
    return this._billingService.getTriggerEditBillingPopup();
  }

  dispatchUpdatePolicyLine(request: EligibleDiscountEntity): void {
    this._modifiersService.dispatchUpdatePolicyLine(request);
  }

  isPaidInFullDiscountForPSUpdateRequired(
    paymentPlan: string,
    currentValue: string | null
  ): 'add' | 'remove' | null {
    return this._modifiersService.checkPaidInFullDiscountForPS(
      paymentPlan,
      currentValue
    );
  }

  getStatusOfAllProductsWithoutErrors(): Observable<ProductStatus[]> {
    return this._productsService.getStatusOfAllProductsWithoutErrors();
  }
}
