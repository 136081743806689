import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'nw-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationButtonsComponent {
  @Input() forwardOnly: boolean;
  @Input() backOnly: boolean;
  @Input() isAppLoading: boolean;
  @Input() forwardDisabled: boolean;
  @Input() submitButtonText: string;
  @Input() isReordered?: boolean;
  @Input() gettingStarted: boolean;
  @Input() isDirectUser: boolean;

  @Output() back = new EventEmitter();
  @Output() forward = new EventEmitter();
}
