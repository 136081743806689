import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  RetrieveQuoteResponse,
  HydrateQuoteResponse,
} from '@core/models/auto/quotes/retrieve-quote-response.model';
import { ProductTypes } from '@shared/constants/app-constants';
import { map } from 'rxjs/operators';
import {
  RetrieveModel,
  HydrateModel,
} from '@core/models/retrieve/retrieve.model';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { HeadersUtils } from '@shared/utils/headers.utils';
import {
  BypassFields,
  BypassRetrieveResponse,
} from '@core/models/bypass-retrieve/bypass-retrieve.model';

@Injectable({
  providedIn: 'root',
})
export class RetrieveAdapter extends BaseDsmAdapter {
  retrieve(request: RetrieveModel): Observable<RetrieveQuoteResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productType)}/quotes/${
      request.quoteId
    }`;

    const params = {
      lastName: request.lastName,
      postalCode: request.postalCode,
    };

    return this.get<RetrieveQuoteResponse>(url, {
      productId: request.productType,
      params: params,
      quoteIdForToken: request.quoteId,
    }).pipe(
      map(response => {
        response.productType = request.productType;
        return response;
      })
    );
  }

  nonQuoteIdRetrieve(
    request: RetrieveModel
  ): Observable<RetrieveQuoteResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productType)}/quotes`;

    const params = {
      lastName: request.lastName,
      postalCode: request.postalCode,
      dateOfBirth: request.dateOfBirth,
      emailId: request.emailId,
      productCode: request.productCode,
    };

    return this.get<RetrieveQuoteResponse>(url, {
      productId: request.productType,
      params: params,
      withCredentials: false,
    }).pipe(
      map(response => {
        response.productType = request.productType;
        return response;
      })
    );
  }

  private _getUrlPrefixForProduct(productId) {
    switch (productId) {
      case ProductTypes.AUTO: {
        return this._appConfig.config.pcEdgeAutoUrl;
      }

      case ProductTypes.POWERSPORTS: {
        return this._appConfig.config.pcEdgePowersportsUrl;
      }

      case ProductTypes.HOMEOWNERS: {
        return this._appConfig.config.pcEdgeHomeownersUrl;
      }

      case ProductTypes.CONDO: {
        return this._appConfig.config.pcEdgeCondoUrl;
      }

      case ProductTypes.RENTERS: {
        return this._appConfig.config.pcEdgeRentersUrl;
      }

      case ProductTypes.UMBRELLA: {
        return this._appConfig.config.pcEdgeUmbrellaUrl;
      }
    }
  }

  composeAutoUrl(request) {
    return `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}`;
  }

  composePowersportsUrl(request) {
    return `${this._appConfig.config.pcEdgePowersportsUrl}/quotes/${request.quoteId}`;
  }

  composeHomeownersUrl(request) {
    return `${this._appConfig.config.pcEdgeHomeownersUrl}/quotes/${request.quoteId}`;
  }

  composeCondoUrl(request) {
    return `${this._appConfig.config.pcEdgeCondoUrl}/quotes/${request.quoteId}`;
  }

  composeRentersUrl(request) {
    return `${this._appConfig.config.pcEdgeRentersUrl}/quotes/${request.quoteId}`;
  }

  composeBypassRetrieveQuoteUrl(bridgeId: string): string {
    return `${this._appConfig.config.partnerBridgingUtilityUrl}/quote-bridges/${bridgeId}`;
  }

  hydrateQuote(
    request: HydrateModel
  ): Observable<HydrateQuoteResponse[] | HydrateQuoteResponse> {
    const RETRY_MS = 500;
    const url = this._appConfig.config.pcEdgeCompraterRetrieveUrl;
    let producerCode = null;

    if (request.agencyCode && request.agencyCode.length > 0) {
      producerCode = `${request.agencyCode} - ${request.producerId}`;
    } else {
      producerCode = request.producerId;
    }

    const requestBody = {
      producerCode: producerCode,
      comparativeQuoteId: request.deepLaunchId,
    };

    return this._http
      .post<HydrateQuoteResponse[] | HydrateQuoteResponse>(url, requestBody, {
        headers: HeadersUtils.buildHeaderForDSMCalls(
          this._appConfig.config.apiKey,
          request.accessToken,
          'test-session-id',
          this._appConfig.isTest() ? this._appConfig.config.targetEnv : ''
        ),
      })
      .pipe(this._retry.retryOn503(RETRY_MS));
  }

  bypassRetrieve(fields: BypassFields): Observable<BypassRetrieveResponse> {
    const url = this.composeBypassRetrieveQuoteUrl(fields.bridgeId);
    const params = {
      otp: fields.otp,
    };
    const headers = HeadersUtils.buildHeaderWithMessageId(
      this._appConfig.config.apiKey
    );
    return this._http.get<BypassRetrieveResponse>(url, {
      params: params,
      headers: headers,
      withCredentials: true,
    });
  }
}
