import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '@core/services/app-config.service';
import { LoggingService } from '@core/services/logging.service';
import { StringUtils } from '@shared/utils/string.utils';

@Injectable()
export class PlaidAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService,
    private _loggingService: LoggingService
  ) {}

  plaidCreateLinkToken(request): Observable<any> {
    const url = `${this._appConfig.config.multiproductApi}/plaid/createLinkToken`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID());
    return this._http.post<any>(url, request, {
      headers,
    });
  }

  plaidExchangeToken(request): Observable<any> {
    const url = `${this._appConfig.config.multiproductApi}/plaid/exchangeToken`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID())
      .set('account_id', request.accountId)
      .set('public_token', request.publicToken);
    return this._http.get<any>(url, { headers });
  }
}
