import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

export const TARGET_ENVS: string[] = [
  'dev',
  'deva',
  'dev1',
  'dev3',
  'dev4',
  'dsm',
  'devintegtest1',
  'docker',
  'dockernov',
  'dsm',
  'it',
  'pls01',
  'pls02',
  'pls03',
  'pls04',
  'pls05',
  'pls06',
  'pls07',
  'pls08',
  'pls09',
  'pls12',
  'pls13',
  'pls14',
  'pls15',
  'pls17',
  'pls18',
  'pls19',
  'pls20',
  'pls30',
  'pls42',
  'pls43',
  'pls44',
  'pls45',
  'pls46',
  'pls48',
  'pls54',
  'pls57',
  'pls58',
  'pls97',
  'pls98',
  'pls99',
  'plspt',
  'pt',
  'sm',
  'smdev',
  'st',
  'stci',
  'test1',
  'test2',
  'test3',
  'test4',
  'test5',
  'test6',
  'test7',
  'test8',
  'test9',
];
@Component({
  selector: 'mq-pls-environment-tester',
  templateUrl: './pls-environment-tester.component.html',
  styleUrls: ['./pls-environment-tester.component.scss'],
})
export class PLSEnvironmentTesterComponent implements OnInit {
  TARGET_ENV = '';
  HEADERS = {
    'Content-Type': 'application/json',
    client_id: 'yGCdTLB5jieIjKxqBPOWfRDCseaS7lyR',
    'X-NW-Message-ID': 'calling-from-nwx-test-page',
  };

  URL = 'https://api-int-test.nwie.net/customer-acquisition/clocks/v1/clocks';
  responses$: Array<Observable<any>> = [];

  constructor(private _http: HttpClient) {}

  ngOnInit(): void {
    for (const env of TARGET_ENVS) {
      this.responses$.push(
        this._http
          .get<any>(this.URL, {
            headers: {
              ...this.HEADERS,
              'X-NW-Target-Env': env,
            },
          })
          .pipe(
            map(this.makeSuccessHandler(env)),
            catchError(this.makeErrorHandler(env))
          )
      );
    }
  }

  makeSuccessHandler(env) {
    return res => {
      const result: any = { env: env };
      result.successful = 'Up and running';
      result.effectiveDate = res.effectiveDate;
      return result;
    };
  }

  makeErrorHandler(env) {
    return err => {
      const result: any = { env: env };
      result.successful = 'This Environment is Down';
      return of(result);
    };
  }
}
