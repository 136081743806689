import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { MailingAddressDao } from '@core/dao/mailing-address.dao';

import { AddressEntity } from '@core/models/entities/address.entity';
import { CRUDService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class MailingAddressService implements CRUDService<AddressEntity> {
  constructor(private _dsmMailingAddressDao: MailingAddressDao) {}

  add(address: AddressEntity): void {
    this._dsmMailingAddressDao.add(address);
  }

  update(address: AddressEntity): void {
    this._dsmMailingAddressDao.update(address);
  }

  addOrUpdate(address: AddressEntity): void {
    this._dsmMailingAddressDao.addOrUpdateAddress(address);
  }

  delete(): void {
    this._dsmMailingAddressDao.delete();
  }

  getMailingAddress(id: string): Observable<AddressEntity> {
    return this._dsmMailingAddressDao.getMailingAddress(id);
  }

  getAll(): Observable<AddressEntity[]> {
    return this._dsmMailingAddressDao.getAll();
  }
}
