import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RenovationAdapter } from '@core/adapters/renovation.adapter';
import { Product } from '@core/models/products/product.model';
import { RenovationEntity } from '@core/store/reducers/renovation.reducer';
import { RenovationsDao } from '../dao/renovations.dao';

@Injectable({
  providedIn: 'root',
})
export class RenovationService {
  constructor(
    private _renovationAdapter: RenovationAdapter,
    private _renovatioHoDao: RenovationsDao
  ) {}

  getAllRenovations(product: Product) {
    return this._renovationAdapter.getAllRenovations(product);
  }

  getAllRenovationsLoaded(): Observable<boolean> {
    return this._renovatioHoDao.getAllRenovationsLoaded();
  }

  getRenovation(product: Product, renovation: RenovationEntity) {
    return this._renovationAdapter.getRenovation(product, renovation);
  }

  addRenovation(product: Product, request: any) {
    return this._renovationAdapter.addRenovation(product, request);
  }

  updateRenovation(
    product: Product,
    renovation: RenovationEntity
  ): Observable<RenovationEntity[]> {
    return this._renovationAdapter.updateRenovation(product, renovation);
  }

  deleteRenovation(product: Product, request: any) {
    return this._renovationAdapter.deleteRenovation(product, request);
  }

  dispatchGetAllRenovations() {
    this._renovatioHoDao.getAllRenovations();
  }

  // dispatchGetRenovation(request) {
  //   this._renovatioHoDao.getRenovation(request);
  // }

  dispatchAddRenovation(request: any) {
    this._renovatioHoDao.addRenovation(request);
  }

  dispatchUpdateRenovations(renovations: RenovationEntity[]) {
    renovations.forEach(renovation => {
      if (renovation.year) {
        this._renovatioHoDao.updateRenovation(renovation);
      }
    });
  }

  dispatchDeleteRenovation(request) {
    this._renovatioHoDao.deleteRenovation(request);
  }

  getRenovationStateData(): Observable<any> {
    return this._renovatioHoDao.getRenovationState();
  }
}
