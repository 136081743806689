<div class="nw-container" *ngIf="configLoaded">
  <div class="logo">
    <a [href]="websiteUrl">
      <img
        class="nw-img-xl"
        [style.width]="privateLabelConfig?.logoWidth || defaultLogoWidth"
        [src]="logoUrl"
        [alt]="logoLabel"
        [attr.aria-label]="logoLabel"
      />
    </a>
  </div>

  <nw-agency-card
    *isAgent
    [agent]="agent$ | async"
    [dsmProducts]="agentProducts$ | async"
    [routeUrl]="routeUrl$ | async"
    class="d-none d-md-flex"
  >
  </nw-agency-card>

  <nw-need-help
    *isCustomer
    [dsmProducts]="directProducts$ | async"
    [phoneNumber]="phoneNumber"
    [additionalPhone]="additionalPhone"
    [privateLabelConfig]="privateLabelConfig"
    [agent]="agent$ | async"
    [isTest]="isTest"
    [awsRouterCookieValue]="awsRouterCookieValue"
    [targetEnv]="targetEnv"
    class="d-none d-md-flex"
  ></nw-need-help>
</div>
