import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  withLatestFrom,
  map,
  mergeMap,
  take,
  filter,
  tap,
} from 'rxjs/operators';
import { TokenService } from '@core/services/token.service';
import { HeadersUtils } from '@shared/utils/headers.utils';
import { AccessToken } from '@core/store/reducers/token.reducer';
import { HttpParamsUtil } from '@shared/utils/http-params.util';
import { RetryOn503Operator } from '@shared/operators/retryOn-503.operator';
import { ProductTypes } from '@shared/constants/app-constants';
import { AppConfigService } from '@core/services/app-config.service';
import { SequencerService } from '@core/services/sequencer.service';
import { ProductsService } from '@core/services/products.service';
import { UserContextService } from '@core/services/user-context.service';

export interface HttpOptions {
  productId: string;
  observe?: 'body' | 'events' | 'response';
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json';
  withCredentials?: boolean;
  quoteIdForToken?: string;
  policyHolderId?: string;
  prePopulatedInsuredId?: string;
  ignoreRiskFilter?: string;
  partialQuoteId?: string;
  ignoreAutoUWRules?: string;
}

type AngularHttpOptions = any;

@Injectable()
export class BaseDsmAdapter {
  constructor(
    protected _http: HttpClient,
    protected _appConfig: AppConfigService,
    protected _sequencerService: SequencerService,
    protected _tokenService: TokenService,
    protected _productsService: ProductsService,
    protected _userContextService: UserContextService,
    protected _retry: RetryOn503Operator
  ) {}

  delete<T>(url: string, options: HttpOptions): Observable<T> {
    return this._request(options, angularOptions =>
      this._http.delete(url, angularOptions)
    );
  }

  get<T>(url: string, options: HttpOptions): Observable<T> {
    return this._request(options, angularOptions =>
      this._http.get(url, angularOptions)
    );
  }

  patch<T>(url: string, body: any, options: HttpOptions): Observable<T> {
    return this._request(options, angularOptions =>
      this._http.patch(url, body, angularOptions)
    );
  }

  post<T>(url: string, body: any, options: HttpOptions): Observable<T> {
    return this._request(options, angularOptions =>
      this._http.post(url, body, angularOptions)
    );
  }

  put<T>(url: string, body: any, options: HttpOptions): Observable<T> {
    return this._request(options, angularOptions =>
      this._http.put(url, body, angularOptions)
    );
  }

  private _request(
    options: HttpOptions,
    callback
  ): Observable<AngularHttpOptions> {
    const RETRY_MS = 500;
    return this._sequencerService.performOperation(options.productId, () => {
      return this._getTokenIfRequired(options).pipe(
        take(1),
        withLatestFrom(
          this._productsService.getProduct(options.productId),
          this._userContextService.getUserContext()
        ),
        map(([token, product, userContext]) => {
          const output: AngularHttpOptions = {
            headers: HeadersUtils.buildHeaderForDSMCalls(
              this._appConfig.config.apiKey,
              token,
              (product && product.sessionId) || '',
              this._appConfig.isTest() ? this._appConfig.config.targetEnv : '',
              userContext,
              options.policyHolderId,
              options.prePopulatedInsuredId,
              options.ignoreRiskFilter,
              options.ignoreAutoUWRules,
              options.partialQuoteId
            ),
            params: HttpParamsUtil.buildHttpParamsWithPartnerName(
              userContext,
              options.params
            ),
          };
          if (options.observe) {
            output.observe = options.observe;
          }
          if (options.responseType) {
            output.responseType = options.responseType;
          }
          if (options.withCredentials) {
            output.withCredentials = options.withCredentials;
          }

          return output;
        }),
        mergeMap(callback),
        this._retry.retryOn503(RETRY_MS)
      );
    });
  }

  private _getTokenIfRequired(options: HttpOptions): Observable<string | null> {
    if (options.hasOwnProperty('withCredentials') && !options.withCredentials) {
      return of(null);
    }
    return this._tokenService
      .getAccessTokenForProductId(options.productId)
      .pipe(
        tap(token => {
          if (!token) {
            if (options.quoteIdForToken) {
              this._tokenService.getAccessTokenForRequest({
                quoteId: options.quoteIdForToken,
                productId: options.productId,
                lastName: options.params['lastName'],
                postalCode: options.params['postalCode'],
              });
            } else {
              this._tokenService.refresh(options.productId);
            }
          }
        }),
        filter(token => !!token),
        mergeMap(token => {
          if (this._accessTokenIsExpired(token)) {
            return this._tokenService.refresh(options.productId);
          } else {
            return of(token);
          }
        }),
        map(token => token.accessToken)
      );
  }

  getUrlPrefixForProduct(productId) {
    switch (productId) {
      case ProductTypes.AUTO: {
        return this._appConfig.config.pcEdgeAutoUrl;
      }

      case ProductTypes.HOMEOWNERS: {
        return this._appConfig.config.pcEdgeHomeownersUrl;
      }

      case ProductTypes.RENTERS: {
        return this._appConfig.config.pcEdgeRentersUrl;
      }

      case ProductTypes.UMBRELLA: {
        return this._appConfig.config.pcEdgeUmbrellaUrl;
      }
    }
  }

  private _accessTokenIsExpired(token: AccessToken): boolean {
    return token.expiryDate.getTime() <= Date.now();
  }
}
