<form [formGroup]="form" class="container">
  <div
    class="row"
    *ngIf="
      requiredOilTank && (coveredLocation.hasOilTank || needConstructionInfo)
    "
  >
    <div class="form-group col-sm-auto">
      <legend>Is there an oil tank on the premises?</legend>
      <div
        class="btn-group btn-group-toggle d-flex"
        formControlName="hasOilTank"
        ngDefaultControl
      >
        <input
          class="btn-check"
          type="radio"
          aria-describedby="hasOilTank-error"
          [value]="true"
          name="hasOilTank"
          formControlName="hasOilTank"
          id="pi-has-oilTank-yes"
          (change)="showHasOilTankOptions()"
        />
        <label
          for="pi-has-oilTank-yes"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle: form.value.hasOilTank == true
          }"
        >
          Yes
        </label>
        <input
          class="btn-check"
          id="false"
          type="radio"
          aria-describedby="hasOilTank-error"
          [value]="false"
          name="hasOilTank"
          formControlName="hasOilTank"
          id="pi-has-oilTank-no"
          (change)="showHasOilTankOptions()"
        />
        <label
          for="pi-has-oilTank-no"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle: form.value.hasOilTank == false
          }"
        >
          No
        </label>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="showHasOilTankOptions()">
    <div class="form-group col-sm-4">
      <label for="pi-year-tank-installed" class="fixed-height"
        >Year Tank Installed</label
      >
      <div class="input-group">
        <input
          id="pi-year-tank-installed"
          type="text"
          class="form-control"
          placeholder="YYYY"
          mask="0000"
          aria-describedby="yearTankInstalled-error"
          formControlName="yearTankInstalled"
        />
        <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-4">
      <label for="pi-tank-material" class="fixed-height">Tank Material</label>
      <div class="input-group">
        <select
          id="pi-tank-material"
          class="form-control form-select"
          attr.aria-describedby="tankMaterial-error"
          formControlName="tankMaterial"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let material of options?.tankMaterial"
            value="{{ material.value }}"
          >
            {{ material.label }}
          </option>
        </select>
        <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-4">
      <label for="pi-tankLocation" class="fixed-height"
        >Location of the tank</label
      >
      <div class="input-group">
        <select
          id="pi-tankLocation"
          class="form-control form-select"
          attr.aria-describedby="tankLocation-error"
          formControlName="tankLocation"
          (change)="showIsClosureFillCertified()"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let location of options?.tankLocation"
            value="{{ location.value }}"
          >
            {{ location.label }}
          </option>
        </select>
        <div class="input-group-text"></div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="showIsClosureFillCertified()">
    <div class="form-group col-sm-auto">
      <legend>
        Has a satisfactory closure and fill been completed and certified?
      </legend>
      <div
        class="btn-group btn-group-toggle d-flex"
        formControlName="isClosureFillCertified"
        ngDefaultControl
      >
        <input
          type="radio"
          aria-describedby="isClosureFillCertified-error"
          [value]="true"
          name="isClosureFillCertified"
          formControlName="isClosureFillCertified"
          id="pi-isClosure-FillCertified-yes"
        />
        <label
          for="pi-isClosure-FillCertified-yes"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle: form.value.isClosureFillCertified == true
          }"
        >
          Yes
        </label>
        <input
          class="btn-check"
          id="false"
          type="radio"
          aria-describedby="isClosureFillCertified-error"
          [value]="false"
          name="isClosureFillCertified"
          formControlName="isClosureFillCertified"
          id="pi-isClosure-FillCertified-no"
        />
        <label
          for="pi-isClosure-FillCertified-no"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle: form.value.isClosureFillCertified == false
          }"
        >
          No
        </label>
      </div>
    </div>
  </div>
</form>
