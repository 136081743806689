import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Product } from '@core/models/products/product.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  PrivateLabelService,
  ProductsService,
  SessionService,
} from '@core/services';
import { PrivateLabel } from '@core/models/private-label/private-label.model';
import { AgencyModel } from '@core/models/agency/agency.model';
import { DSM_STAGE_TO_TEST_TARGET_ENV } from '@shared/constants/app-constants';
@Component({
  selector: 'nw-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.scss'],
})
export class NeedHelpComponent implements OnDestroy, OnInit {
  @Input() phoneNumber: string;
  @Input() additionalPhone: string;

  @Input() dsmProducts: Product[];
  @Input() privateLabelConfig: PrivateLabel;
  @Input() agent: AgencyModel;
  @Input() isTest: boolean;
  @Input() awsRouterCookieValue: string;
  @Input() targetEnv: string;
  pcEnv: string;
  phoneNumberToShow: string;
  private _ngUnsubscribe = new Subject();

  constructor(
    private _privateLabelService: PrivateLabelService,
    private _productsService: ProductsService,
    private _sessionService: SessionService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._privateLabelService
      .getProducerContactNumber('HEADER_FOOTER')
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(contactNumber => {
        this.phoneNumberToShow = contactNumber
          ? contactNumber
          : this.phoneNumber;
        this.updatePhoneNumber();
      });
    this._sessionService
      .getPCEnv()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(adminPCEnv => {
        const env = DSM_STAGE_TO_TEST_TARGET_ENV[this.targetEnv]
          ? DSM_STAGE_TO_TEST_TARGET_ENV[this.targetEnv]
          : this.targetEnv;
        if (this.awsRouterCookieValue?.toLowerCase().startsWith('stage')) {
          this.pcEnv = this.targetEnv + '-' + env;
        } else {
          this.pcEnv = adminPCEnv;
          this.ref.detectChanges();
        }
      });
  }

  private updatePhoneNumber(): void {
    this._productsService.getTFN().subscribe((tfn: string) => {
      this.phoneNumberToShow = tfn ? tfn : this.phoneNumberToShow;
    });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }
}
