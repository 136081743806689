import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '@core/models/products/product.model';
import { RenovationEntity } from '@core/store/reducers/renovation.reducer';
import { BaseDsmAdapter } from './base-dsm.adapter';
@Injectable({
  providedIn: 'root',
})
export class RenovationAdapter extends BaseDsmAdapter {
  getAllRenovations(product: Product): Observable<any> {
    const url = `${this._appConfig.config.pcEdgeHomeownersUrl}/quotes/${product.quoteId}/covered-locations/primary/renovations`;
    return this.get(url, { productId: product.id });
  }
  getRenovation(product: Product, renovation: any): Observable<any> {
    // eslint-disable-next-line max-len
    const url = `${this._appConfig.config.pcEdgeHomeownersUrl}/quotes/${product.quoteId}/covered-locations/primary/renovations/${renovation.renovationId}`;
    return this.get(url, { productId: product.id });
  }
  addRenovation(product: Product, request: any): Observable<any> {
    const url = `${this._appConfig.config.pcEdgeHomeownersUrl}/quotes/${product.quoteId}/covered-locations/primary/renovations`;
    const payload = {
      renovation: request,
    };
    return this.post(url, payload, { productId: product.id });
  }
  updateRenovation(
    product: Product,
    renovation: RenovationEntity
  ): Observable<any> {
    // eslint-disable-next-line max-len
    const url = `${this._appConfig.config.pcEdgeHomeownersUrl}/quotes/${product.quoteId}/covered-locations/primary/renovations/${renovation.renovationId}`;
    const payload = {
      renovation: {
        type: renovation.type,
        year: renovation.year,
      },
    };
    return this.put(url, payload, { productId: product.id });
  }
  deleteRenovation(
    product: Product,
    renovation: RenovationEntity
  ): Observable<any> {
    // eslint-disable-next-line max-len
    const url = `${this._appConfig.config.pcEdgeHomeownersUrl}/quotes/${product.quoteId}/covered-locations/primary/renovations/${renovation.renovationId}`;
    return this.delete(url, { productId: product.id });
  }
}
