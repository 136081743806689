import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsHomeOwnerDirective } from './is-homeowner.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsHomeOwnerDirective],
  exports: [IsHomeOwnerDirective],
})
export class IsHomeOwnerModule {}
