import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentsEntity } from '@core/models/entities/document.entity';
import { BaseDsmAdapter } from './base-dsm.adapter';

@Injectable({
  providedIn: 'root',
})
export class DocumentsAdapter extends BaseDsmAdapter {
  documents(request: any): Observable<DocumentsEntity> {
    const url = `${this._appConfig.config.pcEdgePolicyIssueUrl}/policies/${request.policyNumber}/documents`;

    return this.get<DocumentsEntity>(url, {
      productId: request.productId,
    }).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }
}
