import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, tap, switchMap, take, filter } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { LoggingService, ProductsService } from '@core/services';
import { VehicleExposureService } from '@core/services/vehicle-exposure.service';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable()
export class VehicleExposureEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _vehicleExposureService: VehicleExposureService,
    private _productsService: ProductsService
  ) {}

  @Effect()
  addVehicleExposure$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.ADD_VEHICLE_SUCCESS),
    map((actions: fromActions.AddVehicleSuccess) => actions.payload),
    switchMap(vehicle => {
      return this._store.select(fromSelectors.getSelectedProducts).pipe(
        take(1),
        switchMap(products => of({ vehicle, products }))
      );
    }),
    filter(products => {
      // this._productsService.isEligibleForUmbrella(products.products)
      const umbrella = products.products.find(
        product => product.id === ProductTypes.UMBRELLA
      );
      return !!umbrella;
    }),
    switchMap(vehicle => {
      return this._store
        .select(fromSelectors.buildVehicleExposureRequest(vehicle.vehicle))
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('addVehicleExposure Request', request)
    ),
    switchMap(request =>
      this._vehicleExposureService.vehicleExposures(request).pipe(
        tap(response =>
          this._loggingService.log('addVehicleExposure Response', response)
        ),
        map(response => {
          const exposure = {
            ...response,
            vehicleId: request.vehicleExposure.vehicleId,
          };
          return new fromActions.AddVehicleExposureSuccess(exposure);
        }),
        catchError(error => {
          this._loggingService.log('addVehicleExposure error', error);
          return of(new fromActions.AddVehicleExposureFail(error));
        })
      )
    )
  );

  @Effect()
  deleteVehicleExposure$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.DELETE_VEHICLE),
    map((actions: fromActions.DeleteVehicle) => actions.payload),
    switchMap(vehicleId =>
      this._store
        .select(fromSelectors.getVehicleEntity(vehicleId))
        .pipe(take(1))
    ),
    switchMap(vehicle => {
      return this._store.select(fromSelectors.getSelectedProducts).pipe(
        take(1),
        switchMap(products => of({ vehicle, products }))
      );
    }),
    filter(products => {
      // this._productsService.isEligibleForUmbrella(products.products)
      const umbrella = products.products.find(
        product => product.id === ProductTypes.UMBRELLA
      );
      return !!umbrella;
    }),
    switchMap(vehicle => {
      return this._store
        .select(fromSelectors.buildVehicleExposureRequest(vehicle.vehicle))
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('deleteVehicleExposure Request', request)
    ),
    switchMap(request =>
      this._vehicleExposureService.deleteVehicleExposure(request).pipe(
        tap(response =>
          this._loggingService.log('deleteVehicleExposure Response', response)
        ),
        map(() => {
          return new fromActions.DeleteVehicleExposureSuccess(
            request.vehicleExposure.vehicleExposureId
          );
        }),
        catchError(error => {
          this._loggingService.log('deleteVehicleExposure error', error);
          return of(new fromActions.DeleteVehicleExposureFail(error));
        })
      )
    )
  );
}
