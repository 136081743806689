/* eslint-disable */
/*

================ Const StateSpecificCoverageHelpText ================>
Condition 1: Coverage.Id + Product-Name
Ex. PIPPowersports
Help text is displayed based on state selection as well as product type.

Condition 2: Coverage.Id
State specific help text.

<================ Const CoverageHelpText ================>
Condition 3: Coverage.Id + Product-Name
Ex. BIPowersports
Help text differs only by product type.
(BI coverage is offered as part of both auto and powersports)

Condition 4: Coverage.Id
Ex. BI
Generic help text that is shown only if all other conditions are false (numbers: 1-3 above; no distinction by product type or state).
 */

export const CoverageHelpText = {
  TREXOption2:
    'Rental Days Plus -The limit for non-owned autos is not capped by a dollar amount, but it is limited to 30 days.',

  TREXOption:
    'Covers expenses incurred as a result of loss to a your covered auto and expenses incurred as a result of loss to a non-owned auto. Up to the limit of liability stated in the Declarations, not less than $600',
  RSA:
    '<p>Roadside Assistance provides 24/7 emergency help if you need fuel delivery, ' +
    'a tire change, jumpstart or tow. All of your vehicles and drivers are covered, ' +
    "even when they are passengers in someone else's vehicle.</p>",
  AD: 'This provides a lump sum due to death in a covered loss.',
  AF:
    '<p>Avoid a rate increase following the first at-fault accident on your policy. ' +
    "Accident Forgiveness keeps your premium from increasing if you're involved in an accident " +
    'as long as you have been accident-free for the past 3 years.</p>',
  MVF:
    '<p>Minor Violation Forgiveness will keep your insurance premium from increasing ' +
    'if you receive a citation for a minor traffic violation within a 3 year period.</p>',
  VD:
    '<p>The last thing you want to worry about after being in an accident is coming up ' +
    'with hundreds of dollars to fix your car. Vanishing Deductible&reg; will lower your ' +
    'Comprehensive and/or Collision deductible by $100 for every year of safe driving - up to $500 total. ' +
    'Initial $100 credit is added when this feature is purchased.</p>',
  TLDW:
    '<p>If your car is declared a total loss after an accident, Total Loss Deductible Waiver ' +
    'will pay the deductible for your Comprehensive or Collision insurance.</p>',
  IDFR:
    '<p>If you or anyone in your household are a victim of identity theft or fraud, ' +
    'Identity Theft Expense Reimbursement pays up to $25,000 for expenses.</p>',
  UMPD:
    '<p>This coverage reimburses you for damages to your vehicle caused by a driver ' +
    "who doesn't have any insurance.</p>",
  PD:
    "<p>If you cause an accident resulting in damage to another person's vehicle or property, " +
    'Property Damage (PD) Liability pays for those repairs up to your coverage limit. ' +
    'It will also provide for your legal defense if a suit is brought against you as a result of the accident.</p>',
  BI:
    '<p>If you cause an accident resulting in injuries, then Bodily Injury (BI) protects you ' +
    'by paying for damages you may become legally liable for as a result of an automobile accident. ' +
    "This includes paying for an injured party's medical bills and loss of income up to your coverage limit.</p> ",
  BIPowersports:
    '<p>If you cause an accident resulting in injuries, then Bodily Injury (BI) protects you ' +
    'by paying for damages you may become legally liable for as a result of an automobile accident. ' +
    "This includes paying for an injured party's medical bills and loss of income up to your coverage limit.</p> " +
    '<p>Bodily injury liability automatically includes coverage for guest passengers.</p>',
  UMBI:
    '<p>This coverage reimburses you for damages resulting from injuries caused by a driver ' +
    'who does not have any applicable liability insurance. ' +
    'The definition of an uninsured motorist varies from state to state.',
  UIMBI:
    '<p>If you are in an accident caused by an underinsured driver, ' +
    'this coverage protects you, your family members, and other passengers ' +
    'by paying for medical expense, loss of income and other damages owed to you ' +
    'by the underinsured motorist. Generally, an underinsured motorist is a motorist ' +
    'who does not have enough insurance to pay for your damages. However, the definition ' +
    'of an underinsured motorist varies from state to state.</p>',
  UMUIMBI:
    '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
    'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
    'reimburse you for damages they caused.</p>',
  UIMPD:
    '<p>Covers property damage claims if you are in ' +
    'an auto accident caused by a driver who is insured, ' +
    'but who has less coverage than your underinsured motorist coverage </p>',
  COMP:
    '<p>Comprehensive coverage can help pay for damage to your vehicle from ' +
    'vandalism, theft, weather events and accidents involving animals. ' +
    "You're responsible for paying the deductible at the time of the claim.</p>",
  COLL:
    '<p>Regardless of who is at fault, Collision pays for damage to your vehicle if you ' +
    'hit another vehicle or object, another vehicle hits you, or your vehicle rolls over. ' +
    "You're responsible for paying the deductible.</p>",

  COLLOption:
    '<p><strong>Regular Collision Option</strong> - for damage to your vehicle resulting from collision with another vehicle or standing object. This coverage is subject to deductible.</p>' +
    '<p><strong>Limited Collision</strong> - (defaults when 0 Deductible selected) - for damage to your vehicle resulting from collision with another vehicle or standing object. If you are 50% or less at fault for the accident, your insurance provides Collision coverage less your deductible. If you are more than 50% at fault, no coverage is provided. </p>' +
    '<p><strong>Broad Collision Option</strong> - for damage to your vehicle resulting from collision with another vehicle or standing object. If your are 50% or less at fault for the accident, your insurance provides Collision coverage and your deductible is waived. If you are more than 50% at fault, your insurance provides Collision coverage less your deductible.</p>',
  COMPSafetyGlass:
    '<p>This differs from glass coverage included in Comprehensive in that there ' +
    'is no deductible for cracks or for full glass replacements.</p>',
  WCD:
    '<p>Waiver of Collision Deductible waives the deductible for Collision if the ' +
    'insured is hit by an identified uninsured motorist. A hit and run does not ' +
    'qualify as an uninsured motorist.</p>',
  OEM:
    "<p>Specifies that parts from your vehicle's manufacturer - Original Equipment " +
    'Manufacturer (OEM) parts - are used, when available, to repair your vehicle ' +
    'after a collision or comprehensive claim.</p>',
  RENTRE:
    '<p>Rental Car Reimbursement saves you time and hassle by providing access to a rental car ' +
    'or other transportation while your car is in the repair shop after a covered accident, ' +
    "in accordance with the terms of your policy. Without it, you'll need to find another way to work, " +
    'the store or around town.</p>',
  MP:
    '<p>Regardless of who is at fault, Medical Payments coverage pays medical expenses, ' +
    'up to your coverage limit, for you, your family household members and your passengers arising ' +
    'from motor vehicle accidents. Also known as Personal Injury Protection (PIP), ' +
    'this coverage could help pay for certain medical costs, such as dental or specialized nursing care, ' +
    'which your health insurance may not cover.</p>',
  WL: 'This provides a portion of income lost from being unable to work due to a covered loss.',
  MDCL: 'This pays reasonable expenses for medical, hospital, nursing, ambulance services incurred in a covered loss.',
  GAPCOLL:
    '<p>If your car is a total loss after an accident, Gap coverage may pay the difference ' +
    'between the actual cash value and what you owe on the lease or loan.</p>',
  NCRPCOLL:
    '<p>If your covered vehicle is declared a total loss, this coverage provides Gap coverage, ' +
    'plus protection against depreciation to replace your covered vehicle, 0-2 years old, with a ' +
    'new vehicle (up to 110% of MSRP on totaled vehicle), or a vehicle 3-5 years old, with vehicle in ' +
    'ready-to-sell condition. Ends at renewal when vehicle is 6 years old. This coverage does not apply to ' +
    'leased vehicles.</p>',
  BasicCoverage:
    '<p>Covers the house and attached structures for accidental direct physical loss and includes built-in or attached items,' +
    ' such as built-in appliances or wall-to-wall carpet. The Dwelling amount is directly related to the reconstruction cost,' +
    ' which is the cost to rebuild your house completely at the current labor rate with materials of similar kind and quality.' +
    ' We determined this amount based on the interior and exterior details of your home. We want to provide you with the ' +
    'best possible protection by making sure your home is insured to 100% of the replacement cost. If your home has ' +
    'increased or decreased in value, or you’ve made improvements, then please call us to request a change to this amount.</p>',
  IncreasedOtherStructures:
    '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos or ' +
    'detached garages and carports. We automatically provide this at 10% of your Dwelling amount. If you would ' +
    'like more coverage then please call Nationwide.</p>',
  DwellingReplacementCost:
    'Pays an additional amount if more money is needed to rebuild after ' +
    'a covered loss, 150% of the Dwelling amount. This coverage helps ' +
    'offset increased costs that exceed your main Dwelling amount, which ' +
    "is insured to 100% of the home's value.",
  DwellingReplacementCost200:
    'Pays an additional amount if more money is needed to rebuild after ' +
    'a covered loss, 200% of the Dwelling amount. This coverage helps ' +
    'offset increased costs that exceed your main Dwelling amount, which ' +
    "is insured to 100% of the home's value.",
  GuaranteedReplacementCost:
    'Pays to rebuild after a covered loss. This coverage helps offset ' +
    'increased costs that exceed your main Dwelling amount, which is ' +
    "insured to 100% of the home's value.",
  // otherstructureswithincreasedlimits:
  InflationProtectionCoverage:
    '<p>A coverage that automatically increases the policy limits for ' +
    'Dwelling, Other Structures and Personal Property ' +
    'coverages during the policy term to keep pace with inflation.</p>',
  BuildingAdditionsAlterations:
    '<p> For the best possible protection, consider insuring your condo for ' +
    'an amount that would cover the cost to replace everything inside your ' +
    'unit such as built-ins and attached items. This means the drywall, ' +
    'carpeting, cabinets, fixtures, lighting, and anything else related to ' +
    'the construction of the unit. The owner of the building itself will have ' +
    'coverage for the exterior of the structure, including the roof and any areas ' +
    'shared by all the unit owners.</p>',
  BuildingAdditionsAlterationsRenters:
    '<p>Pays for covered damages to additions, alterations, fixtures, improvements or installations ' +
    'that you make to your home.</p>',
  BrandNewBelongings:
    '<p>Pays the full cost to replace or repair covered personal ' +
    'property, whichever costs less, without a deduction ' +
    'for depreciation. This coverage may also be referred to as Personal ' +
    'Property Replacement Cost.</p><p>If ' +
    'replacement cost is provided in lieu of the cost to repair, this ' +
    'covers the cost, at the time of the loss, of ' +
    'a new item identical to the one damaged or stolen. When an identical ' +
    'item is no longer available, we cover the ' +
    'cost of a new item similar to the one covered in the loss.</p>' +
    '<p>For example, with this coverage, if you ' +
    'purchased a TV five years ago for $500, you could be paid the ' +
    'current market value of an equivalent TV today. ' +
    'However, without replacement coverage, you would be paid the ' +
    'depreciated amount for the TV, which may only be $50. ' +
    'Any special limits of liability in the policy will apply to this ' +
    'coverage.</p>',
  UMBIOption:
    '<p><strong>Statutory Uninsured Motorists Protection</strong> has a limit of 25/50 ' +
    'only and provides coverage for accidents occurring within the state of New York.</p>' +
    '<p><strong>Supplementary Uninsured Motorists Protection</strong> provides coverage for ' +
    'accidents occurring in and outside the state of New York, and is available ' +
    'up to the BI limits of the policy.</p>',
  UnscheduledPersonalProperty:
    '<p>Covers your personal property, such as furniture and clothing, ' +
    'and items that are not attached to or built into ' +
    'the residence. Covers these personal items for loss due to specific ' +
    'causes such as: fire, smoke, wind, theft and ' +
    'other causes as described in the policy. Taking inventory helps ' +
    "estimate the value of your home's contents.</p>",
  IncreasedLossOfUse:
    '<p>Provides reimbursement for additional living expenses when a ' +
    'covered loss prevents you from living in your home. ' +
    'This coverage limit is automatically included. Payment will not ' +
    'exceed the limit on the policy or Dwelling amount, ' +
    'and will be for the shortest time required to repair or replace the ' +
    'premises, whichever occurs first, or up to the ' +
    'policy limit of maximum months. This coverage may also be referred ' +
    'to as Actual Loss Sustained.</p>',
  PersonalLiability:
    '<p>Covers bodily injury and property damage for which you are held ' +
    'legally liable except as limited or excluded by the ' +
    'policy. It will not apply to you or regular residents of your household.</p>',
  PersonalInjury:
    '<p>Provides coverage for damages to others due to injuries such as ' +
    'false arrest, libel, slander and invasion of privacy ' +
    'for which you are legally liable.</p>',
  MedicalPayments:
    '<p>Pays for medical or funeral expenses of others who are injured ' +
    'on your property or caused by your activities on or ' +
    'off your property. It will not apply to you or regular residents of ' +
    'your household.</p>',
  MineSubsidence:
    '<p>Covers damage due to collapse or movement of man made underground ' +
    'mines, such as coal mines. Helps pay for excavation ' +
    'or grading, as well as damage to foundations, boilers, engines, ' +
    'underground supports, pipes, flues and drains. Damage ' +
    'caused by earthquakes, strip/surface mines, drains or rapid transit ' +
    'tunnels are not covered.</p>',
  WindHailPerilDeductible:
    '<p>This is a separate deductible to be paid in the event that a windstorm or hail causes the covered loss.</p>',
  HurricanePerilDeductible:
    '<p>This is a separate deductible to be paid in the event that a hurricane causes the covered loss.</p>',
  allperils: '',
  UnscheduledTools:
    '<p>Tools have a special limit of liability for a theft loss. ' +
    'For maximum protection, you can increase the theft limit ' +
    'for tools.</p>',
  LossAssessment:
    '<p>Pays for fees charged by a homeowners association for damages to ' +
    'a shared space such as a gym, pool or community room.</p>',
  IdentityTheft:
    '<p>Pays for expenses up to the policy limits resulting from any ' +
    'identity theft or fraud discovered during the policy period. For the ' +
    'additional premium, you will enjoy a host of identity theft services ' +
    'that will help stop the damage to your credit within minutes of your ' +
    'authorization. We will:</p><ul><li>Quickly determine if fraud has ' +
    'occurred</li><li>Make all required calls to banks, creditors and ' +
    'agencies</li><li>Assist you in replacing important documents and I.D. ' +
    'cards</li><li>Provide up to $25,000 in expense reimbursement</li><li>' +
    'Provide FREE credit monitoring with email alerts if your credit report ' +
    'changes</li></ul>',
  WaterBackupLimited:
    '<p>Protects you against losses caused by water that backed up through ' +
    'sewers or drains, or overflows a sump pump, sump pump well, or other ' +
    'system designed to remove water from the foundation area. This is not ' +
    'flood insurance.</p>',
  BuildingOrdinanceOrLaw:
    '<p>Pays to bring your residence into compliance with the latest ' +
    'building codes and standards in the event that your residence needs ' +
    'repairs following an insured loss.</ul>',
  BetterRoofReplacement:
    '<p>Rebuild your roof with stronger, safer roofing materials to better ' +
    'withstand wind, rain, and hail in the event of a covered loss that ' +
    'results in the replacement of your entire roof. This optional coverage ' +
    'pays the difference between a standard roof replacement and one with ' +
    'fortified construction, including nails, flashing, underlayment and ' +
    'shingles.</p>',
  EquipmentBreakdown:
    '<p>Covers expensive appliances such as furnaces, air conditioners ' +
    'and refrigerators that need repaired or replaced due to ' +
    'mechanical, electrical, or electronic breakdown. A per occurrence ' +
    '$50,000 limit and a $500 deductible applies. Also pays up to 125% of ' +
    'the value for like kind and quality equipment to replace it with ' +
    'equipment that is better for the environment. Up to $3,000 is ' +
    'available for additional costs to clean/remove pollutants or ' +
    'repair/replace equipment with energy efficient models.</p>',
  ProtectionBoost:
    '<p>Protection Boost increases specified special limits of liability ' +
    'throughout the policy, including, but not limited to, theft of ' +
    'jewelry and watches. These coverages are subject to the policy ' +
    'deductible.</p>',
  PersonalPropOtherRes:
    '<p>Covers the loss of your personal property while it is at another ' +
    'residence up to 10% of your personal property limits or $1,000 ' +
    'maximum.</p><p>Personal property that is moved from your ' +
    'residence for repair or renovation, or stored in an unfit location ' +
    'is not covered. Personal property that is moved to a newly acquired ' +
    'residence for the first 30 days is also not covered.</p>',
  UnscheduledMoney:
    '<p>Pays up to the special limit on the declarations page of the ' +
    'policy for the physical loss of money, bank notes, bullion, gold ' +
    'other than goldware, silver other than silverware, platinum, coins ' +
    'and medals.</p>',
  UnscheduledSecurities:
    '<p>Pays up to the special limit on the declarations page of the ' +
    'policy for the physical loss of securities, accounts, bills, deeds ' +
    'documents, evidences of debt, letters of credit, notes other than ' +
    'bank notes, passports, stamps and tickets.</p>',
  WatercraftCoverage:
    '<p>Pays up to a specified limit for watercraft of all types, ' +
    'including their trailers, furnishings, equipment and outboard ' +
    'engines or motors.</p>',
  Trailers:
    '<p>Protects against damage to the trailer, not used with ' +
    'watercrafts. Requires Physical Damage coverage for the item on the ' +
    'trailer. In addition, the deductible must be the same amount or ' +
    'percentage as the deductible selected for Physical Damage coverage.' +
    '</p>',
  JewelryWatches:
    '<p>Pays for losses to jewelry, watches, furs, precious and ' +
    'semi-precious stones, caused by a covered peril as named within your ' +
    "policy contract. Pays up to your policy's stated limit, which is the " +
    'total limit for each occurrence. A special maximum limit of ' +
    'liability applies to theft losses. For maximum protection, items ' +
    'should be insured under a scheduled personal property endorsement or ' +
    'Valuables Plus policy.</p>',
  JewelryInVault:
    '<p>Pays for theft losses to jewelry, watches, furs, precious and ' +
    'semi-precious stones, from within a vault at a federal or state ' +
    'chartered bank, savings and loan association or thrift institution.' +
    '</p>',
  Firearms:
    '<p>Covers direct physical loss to firearms and accessories. Pays up ' +
    'to the stated limit named in your policy, which is the total limit ' +
    'for each occurrence. A maximum special limit of liability applies to ' +
    'theft losses. For maximum protection, firearms and accessories ' +
    'should be insured on a Valuables Plus policy.</p>',
  UnscheduledSilverware:
    '<p>Pays up to your selected limit for physical loss to silverware, ' +
    'goldware, and pewterware. There is a special limit of liability for ' +
    'a theft loss. For maximum protection, items should be insured under ' +
    'a scheduled personal property endorsement or Valuables Plus policy.' +
    '</p>',
  BusinessPropOn:
    '<p>Coverage that insures your business property while on the ' +
    'residence premises. This coverage is limited to $2,500.</p>',
  BusinessPropOff:
    '<p>The special limit of liability on business property is increased ' +
    'to the amount shown on the Declarations Page. It includes up to $500 ' +
    'of coverage for business property off the residence premises.</p>',
  UnscheduledCamera:
    '<p>Cameras and accessories are covered up to the stated limit in ' +
    'your policy declaration. A special limit of liability for theft ' +
    'losses applies. For maximum protection, cameras and accessories ' +
    'should be insured under a scheduled personal property endorsement ' +
    'or valuables plus policy.</p>',
  FireDepartmentServiceCharge:
    '<p>Pays up to the limit stated in your policy for customary charges ' +
    'assessed by the fire department when they are called to save or ' +
    'protect your insured property from a covered peril.</p>',
  CreditCard:
    '<p>Pays up to your selected limit for unauthorized transactions on ' +
    'your credit cards and bank debit/ATM card. It also applies to forged ' +
    'checks and counterfeit money. This does not provide Identity Theft ' +
    'coverage.</p>',
  LandlordFurnishings:
    '<p>Covers damage or loss of appliances, carpeting and other ' +
    'household furnishings in a rental unit. This is the maximum amount ' +
    'paid in any one loss. This coverage does not apply to losses due to ' +
    'theft. This coverage does not increase the limit of liability ' +
    'applying to the damaged property. In the event of a loss, this ' +
    'coverage is applied after other valid and collectible insurance.</p>',
  RefrigeratedPersonalProperty:
    '<p>Covers up to $500 for spoilage of food or other perishable items ' +
    'caused by power outage or interruption that occurs off the ' +
    'residences premises. You must use all reasonable means to protect ' +
    'the perishables from damage for this coverage to apply.</p>',
  BioDeteriorationDamageCoverage:
    '<p>Covers specified clean up and removal costs and expenses, as a ' +
    'result of an otherwise covered loss to your property, due to living ' +
    'organisms such as molds, algae, lichens, bacteria, fungi, and wet ' +
    'and dry rot.</p>',
  OutboardMotor:
    '<p>Covers outboard engines or motors with 25 total horsepower or ' +
    'less. 100 total horsepower with Protection Boost Coverage.</p>',
  DebrisRemovalTree:
    '<p>This additional coverage pays up to $1,000 to remove fallen trees ' +
    'and debris, and up to $500 to remove one tree. It also pays to ' +
    'remove debris that has damaged covered property as specified in your ' +
    'policy as part of your liability limits. If the amount to be paid ' +
    'for damaged property and debris removal exceeds your liability ' +
    'limits, an additional 5% is available to remove debris.</p>',
  TreesShrubsOtherMaxPercentCovA:
    '<p>Pays up to 5% of your Dwelling Coverage limit for the loss of ' +
    'trees, shrubs, plants or lawns at your residence. Causes include ' +
    'vandalism, theft, fire, vehicles other than your own, or collapse ' +
    'of a building. No more than $500 will be paid for any one tree, ' +
    'shrub or plant. This does not cover plants grown for business.</p>',
  SectionIDeductibles:
    "<p>The dollar amount you're responsible for paying as part of a " +
    "covered loss. Be sure to choose a deductible you're comfortable " +
    'paying.</p>',
  ServiceLine:
    '<p>Covers damage to and repair of exterior underground piping and wiring providing ' +
    'one of the following services to your residence:  cable or data transmission, compressed air, ' +
    'drainage, electrical power, heating, waste disposal, or water.',
  AAC:
    '<p>Coverage for charges incurred for necessary products, services, and accommodations for ' +
    'an injured person’s care in excess of their PIP Medical coverage.</p>',
  WorkLossBenefitExclusion:
    '<p>For 60+ customers, this is an option to waive coverage for lost wages from ' +
    'a covered accident that leaves the policy holder unable to work.</p>',
  PIP:
    '<p>Personal Injury Protection coverage pays for the Member, their family members ' +
    "or non-related passenger's medical expenses in the event of an accident, regardless " +
    'of who is at fault. This coverage may also pay for funeral cost, death benefit, ' +
    'lost wages, and costs for household services.</p>',
  PIPOption:
    '<p>Personal Injury Protection Option – Coordination of Medical Expenses (excluding Medicare or ' +
    'Medicaid benefits provided by the federal government) applies to you or any “family member”.</p>',
  LPD:
    '<p>This coverage pays when you are at more than 50% at fault for an accident and the other driver sues for damage to a motor vehicle not covered by insurance. ' +
    'This coverage pays up to the state maximum of $3000.</p>',
  LOI:
    '<p>Loss of Income is a required coverage when liability is present on a policy. Loss of Income provides ' +
    'coverage for Work Loss, Replacement Services, and Survivor’s Loss Benefits.</p>',
  APIP:
    '<p>Pays for expenses exceeding the limits of basic Personal Injury Protection required by law. ' +
    'Pays additional benefits for Medical Expenses, Loss of Earnings and Substitute Service Expenses, ' +
    'up to the limits of Additional Personal Injury Protection.</p>',
  OBEL:
    '<p>New York residents can choose to purchase Optional Basic Economic Loss (OBEL) in addition ' +
    'to the state-mandated personal injury protection (or no-fault) coverage. New Yorks state-mandated ' +
    'coverage insures you up to $50,000 if you have an auto accident. OBEL adds $25,000 in addition to ' +
    'this coverage for injuries, lost wages or other loss. OBEL can cover the driver, family members, ' +
    'passengers or any pedestrians who are injured.</p>' +
    '<p>A few important notes about OBEL:</p>' +
    '<p>This additional insurance will go into effect after the $50,000 of personal injury protection is exhausted.' +
    'As the policyholder, you can decide where to direct the $25,000, whether it goes toward lost wages, ' +
    'physical rehabilitation or psychiatric therapy.' +
    'OBEL is available for purchase in addition to your state-mandated personal injury protection. ' +
    'It is optional insurance intended to provide an additional $25,000 in coverage.</p>',
  DI: '<p>Coverage for the death or dismemberment of a driver as a result of an automobile accident.</p>',
  TDI:
    '<p>This is liability insurance that provides benefits in scenarios that result in a complete loss of ability to earn income. ' +
    'This coverage is contingent upon selecting the automobile death benefit coverage.</p>',
  RENTAL:
    '<p>This provides comprehensive and collision coverage to rental cars and coverage for loss of use.</p>',
  TREX:
    '<strong>Optional Limits Transportation Expenses,</strong> saves you time and hassle by providing access to a rental car or other transportation ' +
    'while your car is in the repair shop after a covered accident, ' +
    "in accordance with the terms of your policy. Without it, you'll need to find another way to work, the store or around town.",
  PortableEquip:
    '<p>Electronic equipment that could be insured while in the residence or in/on a motor vehicle ' +
    '(speakers, sensors, generators, etc.).</p>',
  ResidenceRentalTheft:
    '<p>coverage to insure against loss by theft to covered property when all or part of the residence, ' +
    'usually occupied by the insured, is occasionally rented to others.</p>',
  UnscheduledJewelryWatchesFurs:
    '<p>Non-specific coverage for jewelry/watches and fur garments/garments lined with fur.</p>',
  WorkersCompensation:
    "<p>Provides a workers' compensation policy up to a fixed liability limit if a person on your property is injured while working. " +
    'This coverage is included for one occasional employee, there is an additional charge for full and part time employees. </strong></p>',
  COMBL:
    'This coverage is a combination of Funeral Expenses, Loss of Income, and Accidental Death. Instead of selecting limits for the individual ' +
    'coverages, you can choose this coverage to combine them. Please note that if Combined Loss Benefit is selected, individual coverages cannot be customized. ' +
    'In the policy, this may be referred to as Combination First Party Benefits.',
  EXMDCL:
    "<p>Pays for Named Insured or family members' additional first-party medical coverage for injuries resulting from an automobile accident, " +
    'regardless of fault. In the policy, this may be referred to as Extraordinary Medical Benefits.</p>',
  Sinkhole:
    '<p>Damage to covered property caused by sudden settlement or collapse of the ground from collapse of subterranean voids.</p>',
  AM: '<p>Automobile Death Benefits pays $10,000 in the event of death of the Named Insured.</p>',
  ACDD: '<p>Accidental Disability Benefit pays up to $60 per week, for up to 1 year, if the Named Insured is disabled and unable to work.</p>',
  TORT:
    '<p>Coverage for legal fees/lawsuits brought by the policyholder in case of a serious injury. ' +
    '<strong>Full</strong> means coverage ability to recover all damages brought in a lawsuit, ' +
    '<strong>Limited</strong> is for coverage to recover <em>pain and suffering</em> incurred.</p>',
  FUNRL:
    '<p>Coverage for any drivers or passengers for funeral expenses if there’s a car-accident related death.</p>',
  UIMBIStacked:
    '<p>Stacking coverage allows you to increase the amount of underinsured motorist coverage by the number of vehicles on your auto insurance policy.</p>',
  UMBIStacked:
    '<p>Stacking coverage allows you to increase the amount of uninsured motorist coverage by the number of vehicles on your auto insurance policy.</p>',
  PIPGuestOnly:
    '<p>Guest PIP provides protection to the guest passengers in your vehicle in the event of an accident. It provides $10,000 coverage for medical expenses, ' +
    'lost wages and othe out-of-pocket costs. In Kentucky, if you choose to reject PIP for yourself, you must purchase Guest PIP.</p>',
  Foundation:
    'Foundation Coverage provides coverage for settling, cracking, bulging, shrinking or expansion of foundations, ' +
    'floor slabs or footings that support the dwelling caused by seepage or leakage of water or steam from within a ' +
    'plumbing, heating, air conditioning or automatic fire protective sprinkler system. The liability limit will be 15% of Coverage A – Dwelling.',
  LimitedRoofLoss:
    '<p>Limited Roof Surfaces Settlement Windstorm or Hail Losses Roof endorsement is now available. ' +
    'This optional endorsement provides limited loss settlement for your roof surfaces for covered damage caused by windstorm or hail. ' +
    'If you select to add this endorsement, your loss settlement payment will be based on a pre-determined schedule using your roof type and roof age.</p>',
};

export const StateSpecificCoverageHelpText = {
  KY: {
    PIP:
      '<p>Personal Injury Protection coverage pays for the Member, their family members ' +
      "or non-related passenger's medical expenses in the event of an accident, regardless " +
      'of who is at fault. This coverage may also pay for funeral cost, death benefit, ' +
      'lost wages, and costs for household services.</p>' +
      '<p>This coverage is mandatory unless you reject, in writing the limitation upon tort ' +
      'rights as allowed by Kentucky law. In Kentucky, a person who accepts PIP gives up the right ' +
      'to sue for bodily injury except under certain circumstances and up to the extent of the PIP ' +
      'benefits payable. A person may refuse to consent to the limitations of his/her tort rights by ' +
      'signing a PIP rejection that must be filed with the Department of Insurance.</p>',
    PIPPowersports:
      '<p>Personal Injury Protection (PIP) covers medical expenses to you or other persons who are covered ' +
      "under your vehicle's policy, in the event of an accident, regardless of who is at fault. PIP may also " +
      'pay a certain amount for funeral costs, lost wages, and costs for household services. PIP has a $10,000 limit, ' +
      'regardless of the deductible you choose.</p>' +
      '<p>Guest PIP provides protection to guest passengers in your vehicle in the event of an accident. ' +
      'It provides $10,000 coverage for medical expenses, lost wages and other out-of-pocket costs. ' +
      'Basic PIP provides coverage for you and your guest passengers. Guest PIP provides coverage for guest passengers only. ' +
      'If you select Guest PIP instead of Basic PIP, you will not have coverage for your own medical expenses, lost wages, ' +
      'or other out-of-pocket costs.</p>' +
      '<p>Pedestrian PIP of $10,000 is included and covers pedestrians other than you or a household member, ' +
      'who are injured after being struck by your motorcycle.</p>' +
      '<p>This coverage is mandatory unless you reject, in writing the limitation upon tort rights as allowed by Kentucky law. ' +
      'In Kentucky, a person who accepts PIP gives up the right to sue for bodily injury except under certain circumstances ' +
      'and up to the extent of the PIP benefits payable. A person may refuse to consent to the limitations of his/her tort ' +
      'rights by signing a PIP rejection that must be filed with the Department of Insurance</p>',

    PIPGuestOnlyPowersports:
      '<p>Guest PIP provides protection to the guest passengers in your vehicle in the event of an accident. ' +
      'It provides $10,000 coverage for medical expenses, lost wages and other out-of-pocket costs. In Kentucky, ' +
      'if you choose to reject PIP for yourself, you must purchase Guest PIP.</p>' +
      'Pedestrian PIP of $10,000 is included and covers pedestrians other than you or a household member, who are ' +
      'injured after being struck by your motorcycle.</p>',

    PEDPIPPowersports:
      '<p>Pedestrian PIP provides protection to pedestrians (other than you or a household member) who are injured after being ' +
      'struck by your motorcycle. It provides $10,000 in coverage for medical expenses, lost wages, and other out of pocket costs.</p>',
    TORT:
      '<p>This optional coverage allows you to seek compensation for you and people in your ' +
      'household for medical and other out-of-pocket expenses due to injuries caused by ' +
      'other drivers.</p>',
    TORTPowersports:
      '<p>This optional coverage allows you to seek compensation for you and people in your ' +
      'household for medical and other out-of-pocket expenses due to injuries caused by ' +
      'other drivers. This coverage applies to both Personal Injury Protection (PIP) and Additional Personal Injury Protection (APIP).</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  OH: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  AL: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  TN: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  TX: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  VA: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',

    UMPD:
      '<p>This coverage reimburses you for damages to your vehicle caused by a driver ' +
      "who either doesn't have enough, or doesn't have any, insurance.</p>",

    UMBIOption:
      '<p>Allows your Underinsured Motorist coverage limit to be added together with the at-fault driver’s limit, ' +
      'rather than being offset against it. For example, an at-fault driver’s liability limit is $50,000 and yours is $200,000. ' +
      'Depending on the damage, you would collect $250,000. Without this optional coverage, ' +
      'the at-fault driver’s limit would be offset against yours for a maximum of $150,000.</p> ',

    UMPDOption:
      '<p>Allows your Underinsured Motorist coverage limit to be added together with the at-fault driver’s limit, ' +
      'rather than being offset against it. For example, an at-fault driver’s liability limit is $50,000 and yours is $200,000. ' +
      'Depending on the damage, you would collect $250,000. Without this optional coverage, ' +
      'the at-fault driver’s limit would be offset against yours for a maximum of $150,000.</p> ',
  },
  CO: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  KS: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  ME: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    VD:
      '<p>The last thing you want to worry about after being in an accident is coming up ' +
      'with hundreds of dollars to fix your car. Diminishing Deductible&reg; will lower your ' +
      'Comprehensive and/or Collision deductible by $100 for every year of safe driving - up to $500 total. ' +
      'Initial $100 credit is added when this feature is purchased.</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  VT: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  IL: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  CT: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    UMBIOption:
      '<p><strong>Underinsured Motorist Bodily Injury Conversion</strong></p> ' +
      '<p>The entire limit of Underinsured Motorist Coverage is available in "excess" of any amount payable under available ' +
      'liability limits of insurance. Any insurance payment received from the at-fault party will not be counted against your ' +
      'limit of Underinsured Motorist Coverage (there is no "off-set").</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  GA: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',

    UMBIOption:
      '<p>Reduced By ("Traditional" Uninsured Motorist Coverage) - means the limit of Uninsured Motorist Coverage that ' +
      'you carry is reduced or "off-set" by any amounts payable to you under any other available bodily injury or property damage liability insurance.' +
      ' Therefore, if an insurance payment is received from the at-fault party, it will count towards your  benefit amount.</p>' +
      '<p>Added To ("New" Uninsured Motorist Coverage) - means the entire limit of Uninsured Motorist Coverage is made available to you in' +
      ' "excess" of any amounts payable under available bodily injury or property damage liability insurance. An insurance payment received from' +
      ' the at-fault party will not be counted against your limit of Uninsured Motorist Coverage (there is no "off-set").</p>',

    UMPDOption:
      '<p>Reduced By ("Traditional" Uninsured Motorist Coverage) - means the limit of Uninsured Motorist Coverage that ' +
      'you carry is reduced or "off-set" by any amounts payable to you under any other available bodily injury or property damage liability insurance.' +
      ' Therefore, if an insurance payment is received from the at-fault party, it will count towards your  benefit amount.</p>' +
      '<p>Added To ("New" Uninsured Motorist Coverage) - means the entire limit of Uninsured Motorist Coverage is made available to you in' +
      ' "excess" of any amounts payable under available bodily injury or property damage liability insurance. An insurance payment received from ' +
      ' the at-fault party will not be counted against your limit of Uninsured Motorist Coverage (there is no "off-set").</p>',

    UMPD:
      '<p>This coverage reimburses you for damages to your vehicle caused by a driver ' +
      "who either doesn't have enough, or doesn't have any, insurance.</p>",

    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  IN: {
    UMPD:
      '<p>This coverage reimburses you for damages to your vehicle caused by a driver ' +
      "who either doesn't have enough, or doesn't have any, insurance.</p>",
  },
  WA: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  NH: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  MD: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    UMBIOption:
      '<p>Allows your Underinsured Motorist coverage limit to be added together with the at-fault driver’s limit, ' +
      'rather than being offset against it. For example, an at-fault driver’s liability limit is $50,000 and yours is $200,000. ' +
      'Depending on the damage, you would collect $250,000. Without this optional coverage, ' +
      'the at-fault driver’s limit would be offset against yours for a maximum of $150,000.</p> ',

    UMPDOption:
      '<p>Allows your Underinsured Motorist coverage limit to be added together with the at-fault driver’s limit, ' +
      'rather than being offset against it. For example, an at-fault driver’s liability limit is $50,000 and yours is $200,000. ' +
      'Depending on the damage, you would collect $250,000. Without this optional coverage, ' +
      'the at-fault driver’s limit would be offset against yours for a maximum of $150,000.</p> ',

    UMPD:
      '<p>This coverage reimburses you for damages to your vehicle caused by a driver ' +
      "who either doesn't have enough, or doesn't have any, insurance.</p>",
  },
  CA: {
    BIPowersports:
      '<p>If you cause an accident resulting in injuries, then Bodily Injury (BI) protects you ' +
      'by paying for damages you may become legally liable for as a result of an automobile accident. ' +
      "This includes paying for an injured party's medical bills and loss of income up to your coverage limit.</p> " +
      '<p>Bodily injury liability automatically includes coverage for guest passengers.</p>' +
      '<p> <b>Combined Single Limit (CSL) </b></p>' +
      '<p>This optional limit is a single limit of liability coverage that includes both Bodily Injury and Property Damage Coverage.</p>',
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  NY: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    PEDPIPPowersports:
      '<p>Pedestrian PIP provides protection to pedestrians (other than you or a household member) who are injured after being ' +
      'struck by your motorcycle. It provides $10,000 in coverage for medical expenses, lost wages, and other out of pocket costs.</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  NC: {
    UMBI:
      '<p>This coverage reimburses you for damages to your vehicle caused by a driver ' +
      'who is carrying less than the minimum liability coverage required under the state financial ' +
      "responsibility statute, or doesn't have any insurance.</p>",

    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  PA: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    PEDPIPPowersports:
      '<p>If your motorcycle or other vehicle you use for recreation strikes an uninsured pedestrian, then Pedestrian First Party ' +
      'pays for their medical bills, up to your coverage limit. The coverage is called "first-party" because any claim would be ' +
      'between the accident victim and the insurance company. When a claim is filed directly against a driver, it is referred to as a ' +
      'third-party lawsuit.</p>',
  },
  DE: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  MS: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  NM: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',

    UMPD:
      '<p>This coverage reimburses you for damages to your vehicle caused by a driver ' +
      "who either doesn't have enough, or doesn't have any, insurance.</p>",
  },
  OK: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  RI: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',

    UMPD:
      '<p>This coverage reimburses you for damages to your vehicle caused by a driver ' +
      "who either doesn't have enough, or doesn't have any, insurance.</p>",
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  FL: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  MI: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  NJ: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  NV: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  NE: {
    UMBI:
      '<p>Covers damage due to bodily injury, including covered medical costs, resulting from a covered accident that you ' +
      'and others are legally entitled to receive from a driver who is not insured or whose insurance limits are not enough to ' +
      'reimburse you for damages they caused.</p>',
  },
  AR: {
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  AZ: {
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  DC: {
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  OR: {
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  UT: {
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  WI: {
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
  WV: {
    IncreasedOtherStructures:
      '<p>Covers property not attached to your house, including pools, hot tubs, fences, sheds, barns, gazebos and carports. ' +
      'We automatically provide 10% of your Dwelling amount. Coverage can be increased up to 30% of the Dwelling amount. ' +
      'If more than 30% is needed, please contact an agent.</p>',
  },
};

export const AnnualMilesHelpText = {
  ALL:
    'The estimated annual mileage for this vehicle is suggested to the right. ' +
    'Please verify and adjust if needed. See help content for more details.',
  CA:
    '<p>California regulations require the applicant to provide us with the ' +
    'estimated number of miles the applicant expects this vehicle will be ' +
    'driven during the 12 month period following policy issuance.</p>',
};

export const MSBDisclaimerMessage = {
  Customer:
    "To review or edit any of the home's features including:  Site Access, Wall Heights, " +
    'HVAC, Interior Walls, Interior Flooring, or Interior Ceilings, please call {phoneNumber}.',

  Agent:
    "To review or edit any of the home's features including:  Site Access, Wall Heights, " +
    'HVAC, Interior Walls, Interior Flooring, or Interior Ceilings, please pivot to PolicyCenter.',
};

export const EarthquakeCoverageMessage = {
  Customer:
    'You will be receiving an Earthquake offer in the mail, ' +
    "if you'd like to purchase today, please call {phoneNumber}. " +
    'The offering of Earthquake coverage is subject to eligibility and underwriting guidelines.',
  Agent:
    "An Earthquake policy offering will be sent to the insured via mail, if you'd like to write " +
    'a policy today, please transfer to PolicyCenter.' +
    'The offering of Earthquake coverage is subject to eligibility and underwriting guidelines.',
};

export const PIPWorkLossWaiver = {
  Customer:
    'PIP Work Loss benefits are included.  If you are 65 years old or 60 years old and receiving ' +
    'a pension you may waive work loss benefits by contacting us at {phoneNumber}.',
  Agent:
    'PIP Work Loss benefits are included.  If you would like to waive PIP Work Loss, please transfer to PolicyCenter.',
};

export const PIPEntireHouseholdExcluded = {
  Customer:
    'To exclude Personal Injury Protection for the entire household, please call {phoneNumber}.',
  Agent:
    'To exclude Personal Injury Protection for the entire household, please pivot to PolicyCenter.',
};

// Dwelling Replacement Cost Options BEGIN
export const DwellingReplacementCostDisclaimer = {
  Customer:
    'I have been offered all dwelling replacement cost options and agree that limits for other structures are sufficient.',
  Agent:
    'I have offered the applicant dwelling replacement cost options H 03 34 CA (150%) and H 03 35 CA (200%) if eligible for these coverages. Also, I have explained Coverage B to applicant, including the limits available, for applicant to determine if the amount of such coverage selected is sufficient for their needs',
};

export const WorkersCompensationHelpText = {
  Customer:
    'This coverage assumes 1 occasional employee. Please call {phoneNumber} to add full or part time employees.',
  Agent:
    'This coverage assumes 1 occasional employee. Please pivot to PolicyCenter to add full or part time employees.',
};

export const AdditionalCoveragesDisplayHelpText = {
  UnscheduledMoney:
    'Coverage limit can be increased in increments of $100 up to $1,000.',
  UnscheduledSecurities:
    'Coverage limit can be increased in increments of $100 up to $3,000.',
  UnscheduledJewelryWatchesFurs:
    'Coverage limit can be increased in increments of $500 up to $6,500.',
  UnscheduledSilverware:
    'Coverage limit can be increased in increments of $500 up to $10,000.',
};

// MSB HelpText BEGIN
export const MSBHelpText = {
  constructionTypeInnerHTML:
    '<strong><font color=black> Construction type </strong></font>',
  constructionTypeFieldId: 'constructionType',
  ConstructionType:
    '<p><strong>Brick, stone or masonry </strong>- Load-bearing exterior walls are made of brick, stone or masonry.</p>' +
    '<p><strong>Brick, stone or masonry veneer </strong>- The brick, stone or masonry is attached to a wood or metal ' +
    'stud frame, or cinder blocks.</p>' +
    '<p><strong>Frame </strong>- walls, floors and roofs made of wood.</p>' +
    '<p><strong>Fire resistive </strong>- walls, floors and roofs made of concrete and steel.</p>',
  exteriorWallsInnerHTML:
    '<strong><font color=black> Exterior walls </strong></font>',
  exteriorWallsFieldId: 'exteriorWalls',
  ExteriorWalls:
    '<p><strong>Adobe </strong>- is a soft, sun-dried brick formed from local clay.</p>' +
    '<p><strong>Brick </strong>- Veneer face brick attached to a wood or metal stud frame structure using corrugated steel wall ties.</p>' +
    '<p><strong>Concrete </strong>- A framing system that creates a continuous outer wall that supports the house.</p>' +
    '<p><strong>Wood shakes </strong>- Typically made of cedar, wood shakes are placed in overlapping layers on the exterior.</p>' +
    '<p><strong>Vinyl </strong>- is a durable plastic like material that can be used as siding.</p>' +
    '<p><strong>Stucco </strong>- is a cement plaster system used as an exterior wall surface finish that is usually ' +
    'applied over a masonry block base.</p>',
  roofMaterialInnerHTML:
    '<strong><font color=black> Roof material </strong></font>',
  roofMaterialFieldId: 'roofMaterial',
  RoofMaterial:
    '<p><strong>Architectural shingle </strong>- referred to as laminated or three-dimensional shingles.</p>' +
    '<p><strong>Composite shingle </strong>- Also called composition or asphalt shingles, composite shingles ' +
    'are made of felt or inorganic fiberglass saturated with asphalt and surfaced with mineral granules.</p>' +
    '<p><strong>Foam </strong>- A sprayed polyurethane foam (SPUF) that is sprayed on the roof substrate.</p>' +
    '<p><strong>Metal </strong>- roof is made of copper, aluminum, steel or iron coated with a terne alloy.</p>' +
    '<p><strong>Rubber </strong>- covering that uses flexible elastomeric plastic/rubberized materials applied in rolls.</p>' +
    '<p><strong>Slate </strong>- “shingles” are cut from dense, fine-grained, metamorphic rock.</p>' +
    '<p><strong>Tar and gravel </strong>- consists of three to five layers of roofing felt laminated with coal tar, ' +
    'pitch or asphalt, and topped with a layer of gravel or other aggregate material.</p>' +
    '<p><strong>Wood shingles </strong>- roof cover made of wood shakes/shingles that are treated with a fire-retarding chemical.</p>' +
    '<p><strong>Spanish tile </strong>- covered in S-shaped clay tiles with interlocking side laps or side joints.</p>',
  foundationTypesInnerHTML:
    '<strong><font color=black> Select the foundation types that apply </strong></font>',
  foundationTypesFieldId: 'foundationTypes',
  FoundationTypes:
    '<p><strong>Slab </strong>- concrete slab is several inches thick and is reinforced with steel rods.</p>' +
    '<p><strong>Crawl space </strong>- is an elevated foundation that uses footings and block walls around the outside to ' +
    'support the house.</p>' +
    '<p><strong>Basement </strong>- Typically dug at a minimum depth of eight feet, a basement foundation is built using a ' +
    'concrete perimeter of pillars that support the building’s above-ground structure.</p>' +
    '<p><strong>Basement walkout </strong>- A walkout is like a basement foundation. However, because the building site is ' +
    'sloped, the basement is at ground level, with accesses to the back yard.</p>' +
    '<p><strong>Suspended house </strong>- Best for flood- or earthquake-prone areas.</p>',
  descriptionOfHalfBathsInnerHTMLOne:
    '<strong><font color=black> Description of half bath </strong></font>',
  descriptionOfHalfBathsInnerHTMLOther:
    '<strong><font color=black> Description of half baths </strong></font>',
  descriptionOfHalfBathsFieldId: 'descriptionOfHalfBaths',
  DescriptionOfHalfBaths:
    '<p><strong>Basic </strong>- Includes fixtures, countertops, cabinets and hardware that are made of the lowest quality ' +
    'materials and only meet minimum building code requirements. Stock cabinets are often painted or ' +
    'coated. Countertops might be stock laminate.</p>' +
    '<p><strong>Builder’s grade </strong>- Includes fixtures, countertops and cabinets that you could buy off the shelf from a big ' +
    'box hardware store. Stock cabinets might have solid wood overlay with standard hardware. Countertops ' +
    'might be made of plastic laminate.</p>' +
    '<p><strong>Semi-custom </strong>- Includes fixtures, countertops and cabinets that you could custom-order from a big box ' +
    'hardware store. Semi-custom cabinets might have solid wood doors and drawer fronts, as well as high-' +
    'quality hardware. Countertops might be made of manufactured stone or cultured marble.</p>' +
    '<p><strong>Custom </strong>- Includes fixtures, countertops and cabinets that can only be ordered from high-end specialty ' +
    'shops. Custom cabinets might have solid wood doors and drawer fronts, as well as high-quality  ' +
    'hardware. Countertops might be made of ceramic, marble or granite.</p>' +
    '<p><strong>Designer </strong>- Includes high-end fixtures, countertops and cabinets. Handmade cabinets might include ' +
    'exotic wood, specialty hardware, hand-painted flourishes, gold leafing or marble. Countertops might be ' +
    'granite, marble or other natural stone.</p>' +
    '<p><strong>Luxury </strong>- Includes fixtures, countertops and cabinets of the highest-quality craftmanship and materials ' +
    'available. Handmade, solid wood cabinets might have dovetailed joints and self-closing drawers. ' +
    'Countertops are unique and might be made of glass, concrete or natural stone. The bathroom might ' +
    'also have a bidet and/or spa tub.</p>',
  descriptionOfFullBathsInnerHTMLOne:
    '<strong><font color=black> Description of full bath </strong></font>',
  descriptionOfFullBathsInnerHTMLOther:
    '<strong><font color=black> Description of full baths </strong></font>',
  descriptionOfFullBathsFieldId: 'descriptionOfFullBaths',
  DescriptionOfFullBaths:
    '<p><strong>Basic </strong>- Includes fixtures, countertops, cabinets and hardware that are made of the lowest quality ' +
    'materials and only meet minimum building code requirements. Stock cabinets are often painted or ' +
    'coated. Countertops might be stock laminate.</p>' +
    '<p><strong>Builder’s grade </strong>- Includes fixtures, countertops and cabinets that you could buy off the shelf from a big ' +
    'box hardware store. Stock cabinets might have solid wood overlay with standard hardware. Countertops ' +
    'might be made of plastic laminate.</p>' +
    '<p><strong>Semi-custom </strong>- Includes fixtures, countertops and cabinets that you could custom-order from a big box ' +
    'hardware store. Semi-custom cabinets might have solid wood doors and drawer fronts, as well as high-' +
    'quality hardware. Countertops might be made of manufactured stone or cultured marble.</p>' +
    '<p><strong>Custom </strong>- Includes fixtures, countertops and cabinets that can only be ordered from high-end specialty ' +
    'shops. Custom cabinets might have solid wood doors and drawer fronts, as well as high-quality  ' +
    'hardware. Countertops might be made of ceramic, marble or granite.</p>' +
    '<p><strong>Designer </strong>- Includes high-end fixtures, countertops and cabinets. Handmade cabinets might include ' +
    'exotic wood, specialty hardware, hand-painted flourishes, gold leafing or marble. Countertops might be ' +
    'granite, marble or other natural stone.</p>' +
    '<p><strong>Luxury </strong>- Includes fixtures, countertops and cabinets of the highest-quality craftmanship and materials ' +
    'available. Handmade, solid wood cabinets might have dovetailed joints and self-closing drawers. ' +
    'Countertops are unique and might be made of glass, concrete or natural stone. The bathroom might ' +
    'also have a bidet and/or spa tub.</p>',
  descriptionOfKitchensInnerHTMLOne:
    '<strong><font color=black> Description of kitchen </strong></font>',
  descriptionOfKitchensInnerHTMLOther:
    '<strong><font color=black> Description of kitchens </strong></font>',
  descriptionOfKitchensFieldId: 'descriptionOfKitchens',
  DescriptionOfKitchens:
    '<p><strong>Basic </strong>- Includes fixtures, appliances, countertops and cabinets that are made of the lowest quality ' +
    'materials and only meet minimum building code requirements. Stock cabinets are often painted or ' +
    'coated. Countertops might be stock laminate.</p>' +
    '<p><strong>Builder’s grade </strong>- Includes fixtures, appliances, countertops and cabinets that you could buy off the shelf ' +
    'from a big box hardware store. Stock cabinets might have solid wood overlay with standard hardware. ' +
    'Countertops might be made of plastic laminate.</p>' +
    '<p><strong>Commercial </strong>- Characterized by large work spaces, open shelving and stainless-steel cabinets and ' +
    'countertops, commercial design focuses more on utility than style.</p>' +
    '<p><strong>Semi-custom </strong>- Includes fixtures, appliances, countertops and cabinets that you could custom-order from ' +
    'a big box hardware store. Countertops might be made of manufactured stone or cultured marble.</p>' +
    '<p><strong>Custom </strong>- Includes fixtures, appliances, countertops and cabinets that can only be ordered from high-end ' +
    'specialty shops. Countertops might be made of granite, marble, soapstone or other natural stone.</p>' +
    '<p><strong>Designer </strong>- Includes high-end fixtures, appliances, countertops and cabinets. Handmade cabinets might ' +
    'include exotic wood, specialty hardware, hand-painted flourishes, gold leafing or marble. Countertops ' +
    'might be granite, marble or other natural stone.</p>' +
    '<p><strong>Luxury </strong>- Includes fixtures, appliances, countertops, cabinets and flooring of the highest-quality ' +
    'craftmanship and materials available. Handmade, solid wood cabinets might have dovetailed joints and ' +
    'self-closing drawers. Countertops are unique and might be made of glass, concrete or natural stone. The ' +
    'kitchen might also have additional features, such as a wood-burning pizza oven, ice machine or trash ' +
    'compactor.</p>',
  typeOfGarageInnerHTML:
    '<strong><font color=black> Type of garage </strong></font>',
  typeOfGarageFieldId: 'typeOfGarage',
  TypeOfGarage:
    '<p><strong>Attached garage </strong>- is an extension of the home and can be accessed from the house without having to go outside.</p>' +
    '<p><strong>Basement garage </strong>- found on a basement walkout, where the building site is sloped.</p>' +
    '<p><strong>Built-in garage </strong>- attached to the house and has a finished living area above it, such as a bedroom or ' +
    'family room.</p>' +
    '<p><strong>Carport </strong>- An open garage with a roof, but fewer than four walls.</p>' +
    '<p><strong>Detached garage </strong>- a free-standing structure that isn’t attached to the house.</p>',
  additionalStructuresInnerHTML:
    '<strong><font color=black> Select the structures that apply </strong>(optional)</font>',
  additionalStructuresFieldId: 'additionalStructures',
  AdditionalStructures:
    '<p><strong>Open porch </strong>- provides shelter from the elements. An open porch is elevated with steps and covered by ' +
    'a roof.</p>' +
    '<p><strong>Screen porch </strong>- porch that is screened in.</p>' +
    '<p><strong>Sun/solar room </strong>- Also known as a three- or four-season room, a sunroom is a room enclosed with glass ' +
    'that provides plenty of natural light.</p>' +
    '<p><strong>Wood deck </strong>- flat surface capable of supporting weight, like a floor. Typically, decks are elevated and ' +
    'connected to a home’s exterior.</p>' +
    '<p><strong>Breezeway </strong>- Like a hallway, a breezeway is a covered outdoor passageway that connects two buildings – ' +
    'connected to a home’s exterior.</p>' +
    'typically a house and a detached garage.</p>',
  additionalHeatingInnerHTML:
    '<strong><font color=black> Additional heating </strong></font>',
  additionalHeatingFieldId: 'additionalHeating',
  AdditionalHeating:
    '<p><strong>Fireplace </strong>- Brick or stone installed into a frame wall with a flue and surrounding brick, stone or ' +
    'simulating masonry chimney.</p>' +
    '<p><strong>Fireplace with portable unit </strong>- combination of a fireplace and portable heating appliance, other than a ' +
    'space heater.</p>' +
    '<p><strong>Fireplace with insert </strong>- A masonry firebox that burns hotter than a metal stove and has lower emissions.</p>' +
    '<p><strong>Franklin stove </strong>- A freestanding metal stove with a U-shaped flue and metal stove pipe.</p>' +
    '<p><strong>Freestanding metal </strong>- metal stove that sits at least a few inches above the floor and a few inches from the ' +
    'wall.</p>' +
    '<p><strong>Portable unit </strong>- A portable heating appliance, other than a space heater. Includes wall-mounted units, ' +
    'free-standing units and floor furnaces that lack ductwork and produce a relatively small amount of heat.</p>',
};
// MSB HelpText END

export const ProtectiveDevicesHelpText = {
  labelInnerHTML:
    '<strong><font color=black>Protective Devices</strong></font> (optional)',
  assignedFieldId: 'protective-devices',
  ProtectiveDevices:
    '<p><strong>Fire Alarm/Burglar Alarm</strong></p>' +
    '<p><strong>Local:</strong> Alarm Sounds on Premises only.</p>' +
    '<p><strong>Central:</strong> Alerts a security company that may send someone to investigate or call the ' +
    'police/fire department.</p>' +
    '<p><strong>Direct:</strong> Alerts the police or fire department directly.</p>' +
    '<p><strong>Sprinkler System</strong></p>' +
    '<p><strong>Partial:</strong>  Automatic Sprinklers in major areas of the home but not in bathrooms, attics, ' +
    'closets, attached structures etc..</p>' +
    '<p><strong>Full:</strong> Automatic Sprinklers in all areas of the home.</p>',
  WindProtectiveDevices:
    '<p><strong>Wind Protective Devices:</strong> Loss avoidance home features like impact-resistant doors/windows, ' +
    'storm shutters, hurricane straps, or clips connecting your roof to your walls. For example, ' +
    'Engineered or Non-Engineered shutters installed on all roof and exterior wall openings.</p>',
};

export const SmartHomeHelpText = {
  labelInnerHTML:
    '<strong><font color=black>Smart home program</strong></font> (optional)',
  assignedFieldId: 'smart-home-program',
  SmartHome:
    '<p>A home monitoring system can alert you of a potential issue before ' +
    'it becomes a bigger problem, such as a water leak, fire or intruder.</p>' +
    '<p><strong>Requires:</strong></p>' +
    '<font color=#575757><ul><li>The purchase of a smart home monitoring system from a third-party ' +
    'provider at a discount price</li><li>A mobile device, Wi-Fi, and third-party ' +
    'app</li><li>Installation and activation within 55 days of enrolling</li></ul></font>',
};

export const PageHelperText = {
  UNDERWRITING_DISCLAIMER:
    'Products underwritten by Nationwide Mutual Insurance Company and ' +
    'Affiliated Companies. Not all Nationwide affiliated companies are ' +
    'mutual companies, and not all Nationwide members are insured by a ' +
    'mutual company. Subject to underwriting guidelines, review, and ' +
    'approval. Products and discounts not available to all persons in all ' +
    'states. Nationwide Investment Services Corporation, member FINRA. Home ' +
    'Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide ' +
    'N and Eagle, and other marks displayed on this page are service marks ' +
    'of Nationwide Mutual Insurance Company, unless otherwise disclosed.',
  UNDERWRITING_DISCLAIMER__ADDON_DOUBLE_ASTERIK: '** ',
  UNDERWRITING_DISCLAIMER_ADDON:
    'Guarantees are subject to the claims paying ability of Insurance Company ' +
    'and Nationwide Life and Annuity Insurance Company. ' +
    'As personal situations change (i.e., marriage, birth of a child or job promotion), ' +
    'so will life insurance needs. Care should be taken to ensure this product ' +
    'is suitable for long-term life insurance needs. You should weigh any associated ' +
    'costs before making a purchase. Life insurance has fees and charges associated ' +
    'with it that includes costs of insurance that vary with such characteristics ' +
    'of the insured as gender, health and age, and have additional charges for ' +
    'riders that customize a policy to fit individual needs.',
};

export const UnderWritingDisclaimerReplacement: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  WA: {
    Auto:
      'Products underwritten by Nationwide Mutual Insurance Company, Columbus, Ohio and ' +
      'Affiliated Companies. Not all Nationwide affiliated companies are ' +
      'mutual companies, and not all Nationwide members are insured by a ' +
      'mutual company. Subject to underwriting guidelines, review, and ' +
      'approval. Products and discounts not available to all persons in all ' +
      'states. Nationwide Investment Services Corporation, member FINRA. Home ' +
      'Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide ' +
      'N and Eagle, and other marks displayed on this page are service marks ' +
      'of Nationwide Mutual Insurance Company, unless otherwise disclosed.',

    Property:
      'Products underwritten by National General Insurance Company, Columbus, Ohio and ' +
      'Affiliated Companies. Not all Nationwide affiliated companies are ' +
      'mutual companies, and not all Nationwide members are insured by a ' +
      'mutual company. Subject to underwriting guidelines, review, and ' +
      'approval. Products and discounts not available to all persons in all ' +
      'states. Nationwide Investment Services Corporation, member FINRA. Home ' +
      'Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide ' +
      'N and Eagle, and other marks displayed on this page are service marks ' +
      'of Nationwide Mutual Insurance Company, unless otherwise disclosed.',

    BothProducts:
      'Products underwritten by Nationwide Mutual Insurance Company and/or ' +
      'National General Insurance Company, Columbus, Ohio and ' +
      'Affiliated Companies. Not all Nationwide affiliated companies are ' +
      'mutual companies, and not all Nationwide members are insured by a ' +
      'mutual company. Subject to underwriting guidelines, review, and ' +
      'approval. Products and discounts not available to all persons in all ' +
      'states. Nationwide Investment Services Corporation, member FINRA. Home ' +
      'Office: One Nationwide Plaza, Columbus, OH. Nationwide, the Nationwide ' +
      'N and Eagle, and other marks displayed on this page are service marks ' +
      'of Nationwide Mutual Insurance Company, unless otherwise disclosed.',
  },
};

export const CoveragesDisclaimerReplacement: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  DC: {
    PropertyWaterBackUp:
      ' you acknowledge that your Property policy does ' +
      'not cover losses from water and sewer-line backups. ' +
      'Sewer-line backup insurance may be available for ' +
      'an additional premium from a licensed agent ' +
      'at 1-855-550-0768.',

    PropertyFloodInsurance:
      ' you acknowledge that your Property policy does not ' +
      'cover losses from floods, surface water or ' +
      'rising water.',
  },
};

export const DrivingHistoryHelpText = {
  labelInnerHTML: '<strong><font color=black>Driving history</strong>',
  drivingHistoryFieldId: 'drivingHistory',
  DrivingHistory:
    '<p><strong>Excellent</strong> – Driver has not had any accidents, violations or claims in the last 5 years.<br>' +
    '<strong> Pretty Good </strong> – Driver has not had more than one minor accident, violation or claim in the last ' +
    '3 years and has not had any major accidents, violations or claims in the last 3 years.<br>' +
    '<strong> Needs Work</strong> – Driver has had 2 or more minor accidents, violations, or claims in ' +
    'the last 3 years and/or driver has had one or more major accident, claim, or violation in the last 3 years.<br></p>',
};

export const AssignedRiskPlanHelpText = {
  labelInnerHTML:
    '<strong><font color=black>Is the current auto policy a State Automobile Insurance Plan?</strong>',
  assignedRiskPlanFieldId: 'assignedRiskPlan',
  assignedRiskPlan:
    "<p>New York's Automobile Insurance Plan (NYAIP) offers auto liability and physical damage coverages to drivers " +
    'unable to obtain in the voluntary market.</p>',
};
export const ReceiveTextNotification = `<strong><font color=black>Click yes to receive device reminder and messaging regarding your SmartRide/Miles program enrollment</strong>`;
export const TcpaConsentTextMessagesPart1 =
  'By selecting "Yes", providing your ' +
  'phone number, and clicking "Continue", you are consenting to receive confirmation ' +
  'codes and ongoing servicing text messages from Nationwide using automated technology.';
export const TcpaConsentTextMessagesPart2 =
  'Multiple messages may be received. Message and data rates may apply.';
export const TcpaConsentTextMessagesPart3 =
  'Your text reply is needed to confirm your preference.';
export const TcpaConsentTextMessagesPart4 =
  'Once you receive our text, please reply "Yes" within one hour to confirm your preference to receive text messages.';
export const PreBindDocumentsContent = {
  PAGE_HEADER: '{state} Required Notices',
  AGENT: {
    HEADER:
      'In the state of Pennsylvania the customer must be presented with the following and/or discuss these notices prior to binding new business:',
    FOOTER:
      '<p>The customer has been sent the required PA pre-bind documents at <strong>{pniEmail}</strong>. Please share the following password with the customer ' +
      'so they are able to access the documents: <strong>{docPassword}</strong></p>',
    RECEIVED_DOCS_TEXT:
      'I have confirmed that the customer has received the Pennsylvania notices.',
    VIEWED_DOCS_TEXT:
      'I have confirmed that the customer was given the opportunity to review and discuss any questions they had on the documents prior to binding.',
  },
  DIRECT: {
    HEADER:
      'In the state of Pennsylvania we must provide the following notices prior to binding new business:',
    FOOTER:
      '<p>We have sent the required PA pre-bind documents to <strong>{pniEmail}</strong>. Please use the following password to access the documents: ' +
      '<strong>{docPassword}</strong></p> <div>If you have questions regarding these documents, please call: {phoneNumber}.</div>',
    RECEIVED_DOCS_TEXT:
      'I confirm that I have received the Pennsylvania notices.',
    VIEWED_DOCS_TEXT:
      'I confirm that I was given the opportunity to review the documents prior to binding and I do not have any questions.',
    FOOTER_AUTO_PS:
      '<p>We have sent the required PA pre-bind documents to <strong>{pniEmail}</strong>. Please use the following password to access the documents for Auto: ' +
      '<strong>{docPassword}</strong> and for Powersports: ' +
      '<strong>{psdocPassword}</strong></p> <div>If you have questions regarding these documents, please call: {phoneNumber}.</div>',
  },
};

export const HealthCarePlanHelpText = {
  labelInnerHTML:
    '<strong><font color="black">Type of Health Care Plan</strong>',
  healthCarePlanFieldId: 'healthCarePlan',
  HealthCarePlan:
    '<p>Qualified health coverage* means either of the following:<br>' +
    '(i) Other health or accident coverage to which both of the following apply:<br>' +
    '&emsp;(A) The coverage does not exclude or limit coverage for injuries related to motor vehicle accidents.<br>' +
    '&emsp;(B) Any annual deductible for the coverage is $6,000.00 or less per individual. The director shall adjust the amount in ' +
    'this sub-subparagraph on July 1 of each year by the percentage change in the medical component of the Consumer Price Index ' +
    'for the preceding calendar year. However, the director shall not make the adjustment unless the adjustment, or the total ' +
    'of the adjustment and previous unadded adjustments, is $500.00 or more.<br>' +
    '(ii) Coverage under parts A and B of the federal Medicare program established under subchapter XVIII of the social security ' +
    'act, 42 USC 1395 to 1395lll.</p>',
};

export const PipExcludedHelpText = {
  labelInnerHTML:
    '<strong><font color="black">Should Personal Injury Protection be excluded for this driver?</strong>',
  healthCarePlanFieldId: 'pipExcluded',
  pipExcluded:
    '<p>In order to exclude an individual driver Option 4 on the PIP Selection form must be selected.<br>' +
    'This includes selecting a PIP Medical limit of $250,000.<br>' +
    'The Named insured who wishes to exclude PIP Medical must have Qualified Health Coverage that is not Medicare.<br>' +
    'Any resident relative or spouse who wishes to exclude PIP Medical must have Qualified Health Coverage.<br>' +
    'The PIP selection form and proof of Qualified Health Coverage is required if any driver is excluded.</p>',
};

export const ANNUAL_MILEAGE_HELP_TEXT = `<strong>For vehicles in the pay-per-mile program, SmartMiles,</strong> your estimated mileage is used to calculate your
     average monthly cost. You can update your estimated annual mileage for this vehicle by changing the mileage above.`;

export const TRANSPORTATION_EXPENSE_HELP_TEXT = `<strong>Optional Limits Transportation Expenses,</strong> saves you time and hassle by providing access to a rental car or other transportation while your car is in the repair shop after a covered accident, in accordance with the terms of your policy. Without it, you'll need to find another way to work, the store or around town.`;

export const SafetyCourseHelpText = {
  labelInnerHTML:
    '<strong><font color="black">Has the driver taken a state approved safety course?</strong>',
  safetyCourseFieldId: 'safetyCourse',
  safetyCourse:
    '<p>A discount may be available if you have taken a state-approved safety course designed for motorcycle, ATV or snowmobile operators.</p>',
};
export const OfferingTypeHelpText = {
  labelInnerHTML: '<strong><font color=black>Offering Type</strong>',
  offeringType:
    '<p><strong>HE-7 Home</strong> includes:<br>Personal Property Replacement cost is included<br>Personal Property losses are all-risk.<br>Increased sub-limits: money, securities, jewelry/furs, firearms, silverware, Trees, shrubs and plants, Fire<br>Dept Serv Charge, Debris removal and Ordinance or Law.<br>Added Coverages: Lock Replacement and Personal Injury<br><br>' +
    '<strong>HE-7/HE-20 Expanded:</strong> in addition to the coverages in HE-7 are:<br>Increased sub limits: Other structures, watercraft/trailer, trailers/non boat, Credit Card<br>Damage to Property of Others<br><br>' +
    '<strong>HE-7/HE-21 Extended:</strong> in addition to the coverages in HE-7 and HE-7/HE-20 are:<br>Increase limits Business Property on Premises and Off Premises and Loss assessments<br>Add the following: Mortgage payments, Water back up, Land, Reward Coverage, Witness Coverage.<br></p>',
};
export const GarageInfoHelpText = {
  labelInnerHTML:
    '<strong><font color=black>Will this vehicle be parked here?</strong>',
  garageInfo:
    '<p>This is the address where the vehicle is usually parked or garaged. In many cases, this is different than the policyholder’s primary residence. Students living greater than 100 miles from home should use their school address as their garaging address.</p>',
};
