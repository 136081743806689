import { Injectable } from '@angular/core';
import { RetrieveModel } from '@core/models/retrieve/retrieve.model';
import { Store } from '@ngrx/store';
import * as fromActions from '@core/store/actions';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors/retrieve.selector';
import { Observable } from 'rxjs';
import {
  RetrieveEntity,
  RetrieveFailure,
} from '@core/models/entities/retrieve.entity';
import {
  BypassFields,
  BypassRetrieveResponse,
} from '@core/models/bypass-retrieve/bypass-retrieve.model';

@Injectable({
  providedIn: 'root',
})
export class RetrieveDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  retrieveQuote(model: RetrieveModel) {
    this._store.dispatch(new fromActions.RetrieveQuote(model));
  }

  getRetrievedQuote(productId: string): Observable<RetrieveEntity> {
    return this._store.select(fromSelectors.getRetrievedQuote(productId));
  }

  getAllRetrievedQuotes(): Observable<RetrieveEntity[]> {
    return this._store.select(fromSelectors.getAllRetrievedQuotes);
  }

  declareRetrieveQuoteFailure(message: RetrieveFailure) {
    this._store.dispatch(new fromActions.RetrieveQuoteFailure(message));
  }

  declareAllRetrieveQuotesFailed() {
    this._store.dispatch(new fromActions.AllRetrievesFailed());
  }

  getRetrieveSuccess(): Observable<boolean> {
    return this._store.select(fromSelectors.getRetrieveSuccess());
  }

  getRetrieveLoading(): Observable<number> {
    return this._store.select(fromSelectors.getRetrieveLoading());
  }

  getAllFailedRetrieves(): Observable<RetrieveFailure[]> {
    return this._store.select(fromSelectors.getAllFailedRetrieves());
  }

  getAllRetrievesFailedFlag(): Observable<boolean> {
    return this._store.select(fromSelectors.getAllRetrievesFailedFlag());
  }

  clearFailures() {
    this._store.dispatch(new fromActions.RetrieveQuoteClearFailures());
  }

  updateCompRaterQuoteId(newDeepLaunchId) {
    this._store.dispatch(new fromActions.UpdateCompRaterId(newDeepLaunchId));
  }

  getCompRaterQuoteId() {
    return this._store.select(fromSelectors.getCompRaterId());
  }

  hydrateQuote(form) {
    this._store.dispatch(new fromActions.HydrateQuote(form));
  }

  setCompRaterError(errorMsg: string) {
    this._store.dispatch(new fromActions.HydrateQuoteFailure(errorMsg));
  }

  getCompRaterError() {
    return this._store.select(fromSelectors.getCompRaterError());
  }

  isCompRater(): Observable<boolean> {
    return this._store.select(fromSelectors.isCompRater);
  }

  getGettingStartedInformationalMessages(): Observable<string[]> {
    return this._store.select(
      fromSelectors.getGettingStartedInformationalMessages()
    );
  }
  getCoveragesInformationalMessages(): Observable<string[]> {
    return this._store.select(
      fromSelectors.getCoveragesInformationalMessages()
    );
  }

  hasBeenPreviouslyHydrated(): Observable<boolean> {
    return this._store.select(fromSelectors.hasBeenPreviouslyHydrated);
  }

  getTargetPage(): Observable<string> {
    return this._store.select(fromSelectors.targetPage);
  }

  updateRedirectTarget(target: string): void {
    this._store.dispatch(new fromActions.UpdateRedirectTarget(target));
  }

  isNewProductSelected(): Observable<boolean> {
    return this._store.select(fromSelectors.isNewProductSelected);
  }

  bypassRetrieve(bypassFields: BypassFields): void {
    this._store.dispatch(new fromActions.BypassRetrieveQuote(bypassFields));
  }

  getBypassRetrieve(): Observable<BypassRetrieveResponse> {
    return this._store.select(fromSelectors.getBypassRetrieve);
  }

  getRetrievedEntities(productId: string): Observable<any> {
    return this._store.select(fromSelectors.getRetrievedEntities(productId));
  }

  getHasMortgageForRetrieve(): Observable<boolean> {
    return this._store.select(fromSelectors.getHasMortgageForRetrieve());
  }

  getEscrowForRetrieve(): Observable<boolean> {
    return this._store.select(fromSelectors.getEscrowForRetrieve());
  }
}
