import { Action } from '@ngrx/store';
import { DigitalIdCardsTextResponse } from '@core/models/digital-id-cards/digital-id-cards-text.model';

export const enum DigitalIdCardsTextActionTypes {
  SEND_AUTO_ID_CARDS = '[Digital ID Cards] Send Digital ID Cards',
  SEND_AUTO_ID_CARDS_FAIL = '[Digital ID Cards] Send Digital ID Cards Fail',
  SEND_AUTO_ID_CARDS_SUCCESS = '[Digital ID Cards] Send Digital ID Cards Success',
}

export class SendAutoIdCards implements Action {
  readonly type = DigitalIdCardsTextActionTypes.SEND_AUTO_ID_CARDS;
}

export class SendAutoIdCardsSuccess implements Action {
  readonly type = DigitalIdCardsTextActionTypes.SEND_AUTO_ID_CARDS_SUCCESS;
  constructor(public payload: DigitalIdCardsTextResponse) {}
}

export class SendAutoIdCardsFail implements Action {
  readonly type = DigitalIdCardsTextActionTypes.SEND_AUTO_ID_CARDS_FAIL;
  constructor(public payload: any) {}
}

export type DigitalIdCardsActions =
  | SendAutoIdCards
  | SendAutoIdCardsSuccess
  | SendAutoIdCardsFail;
