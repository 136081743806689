import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProductsDao } from '@core/dao/products.dao';

import { Product } from '@core/models/products/product.model';
import {
  map,
  withLatestFrom,
  take,
  distinctUntilChanged,
} from 'rxjs/operators';
import {
  ProductSimpleStatus,
  ProductStatus,
} from '@core/models/products/product-status';
import { ProductTypes, QuoteStatus } from '@shared/constants/app-constants';

@Injectable()
export class ProductsService {
  constructor(private _productsDao: ProductsDao) {}

  updateSelectedProducts(products: Product[]): void {
    this._productsDao.updateSelectedProducts(products);
  }

  loadProducts(): void {
    this._productsDao.loadProducts();
  }

  getProducts(): Observable<Product[]> {
    return this._productsDao.getProducts();
  }

  getLifeQuoteId(): Observable<string> {
    return this._productsDao.getLifeQuoteId();
  }

  getAddonProducts(): Observable<Product[]> {
    return this._productsDao.getAddonProducts();
  }

  getProductsLoaded(): Observable<boolean> {
    return this._productsDao.getProductsLoaded();
  }

  getProductsLoading(): Observable<boolean> {
    return this._productsDao.getProductsLoading();
  }

  getSelectedProducts(): Observable<Product[]> {
    return this._productsDao.getSelectedProducts();
  }

  getSelectedProductsForHeader(): Observable<Product[]> {
    return this._productsDao.getSelectedProductsForHeader();
  }

  getSelectedProductsForDirectHeader(): Observable<Product[]> {
    return this._productsDao.getSelectedProductsForDirectHeader();
  }

  getSelectedCoverageProducts(): Observable<Product[]> {
    return this._productsDao.getSelectedCoverageProducts();
  }

  getVehicleProducts(): Observable<Product[]> {
    return this._productsDao.getVehicleProducts();
  }

  isProductSelectedWithoutError(productType: string): Observable<boolean> {
    return this._productsDao.isProductSelectedWithoutError(productType);
  }

  isProductsSelectedWithoutError(productTypes: string[]): Observable<boolean> {
    return this._productsDao.isProductsSelectedWithoutError(productTypes);
  }

  isProductSelected(productName: string): Observable<boolean> {
    return this._productsDao.isProductSelected(productName);
  }

  isHomeownersSelected(): Observable<boolean> {
    return this.isProductSelected('Homeowners');
  }

  isCondoSelected(): Observable<boolean> {
    return this.isProductSelected('Condo');
  }

  isAutoSelected(): Observable<boolean> {
    return this.isProductSelected('Auto');
  }

  isRentersSelected(): Observable<boolean> {
    return this.isProductSelected('Renters');
  }

  isPowersportsSelected(): Observable<boolean> {
    return this.isProductSelected('Powersports');
  }

  getSelectedBindProducts(): Observable<Product[]> {
    return this._productsDao.getSelectedProductsWithoutErrors().pipe(
      take(1),
      map(bindProducts =>
        bindProducts.filter(product => product.id !== ProductTypes.TERMLIFE)
      )
    );
  }

  isProductBindSelected(product: string): Observable<boolean> {
    return this.getSelectedBindProducts().pipe(
      map(products => {
        if (!products) {
          return null;
        }
        return products.find(p => p.name === product) ? true : false;
      })
    );
  }

  isHomeownersBindSelected(): Observable<boolean> {
    return this.isProductBindSelected('Homeowners');
  }

  isNonRentalPropertyBindSelected(): Observable<boolean> {
    return this.getSelectedBindProducts().pipe(
      map(products => {
        if (!products) {
          return null;
        }
        return products.find(
          p => p.id === ProductTypes.HOMEOWNERS || p.id === ProductTypes.CONDO
        )
          ? true
          : false;
      })
    );
  }

  isAnyPropertyBindSelected(): Observable<boolean> {
    return this.getSelectedBindProducts().pipe(
      map(products => {
        if (!products) {
          return null;
        }
        return products.find(
          p =>
            (p.id === ProductTypes.HOMEOWNERS && !p.hasError) ||
            (p.id === ProductTypes.CONDO && !p.hasError) ||
            (p.id === ProductTypes.RENTERS && !p.hasError)
        )
          ? true
          : false;
      })
    );
  }

  isAnyPropertyBinded(): Observable<boolean> {
    return this.getSelectedBindProducts().pipe(
      map(products => {
        if (!products) {
          return null;
        }
        return products.find(
          p =>
            (p.id === ProductTypes.HOMEOWNERS &&
              p.quoteStatus === QuoteStatus.BINDING) ||
            (p.id === ProductTypes.CONDO &&
              p.quoteStatus === QuoteStatus.BINDING) ||
            (p.id === ProductTypes.RENTERS &&
              p.quoteStatus === QuoteStatus.BINDING)
        )
          ? true
          : false;
      })
    );
  }

  isCondoBindSelected(): Observable<boolean> {
    return this.isProductBindSelected('Condo');
  }

  isAutoBindSelected(): Observable<boolean> {
    return this.isProductBindSelected('Auto');
  }

  isRentersBindSelected(): Observable<boolean> {
    return this.isProductBindSelected('Renters');
  }

  isPowersportsBindSelected(): Observable<boolean> {
    return this.isProductBindSelected('Powersports');
  }

  isNonRentalPropertyBindOnlySelected(): Observable<boolean> {
    return this.isNonRentalPropertyBindSelected().pipe(
      withLatestFrom(
        this.isAutoBindSelected(),
        this.isPowersportsBindSelected()
      ),
      map(
        ([isNonRentalSelected, isAutoSelected, isPowersportsSelected]) =>
          isNonRentalSelected && !isAutoSelected && !isPowersportsSelected
      ),
      take(1)
    );
  }

  isEligibleForUmbrella(
    products: Product[],
    isQuoteRetrieve?: boolean
  ): boolean {
    const validIds = products
      .filter(product => !product.hasError)
      .map(product => product.id);
    const vehiclePolicy = validIds.includes(ProductTypes.AUTO);
    const propertyPolicy =
      validIds.includes(ProductTypes.CONDO) ||
      validIds.includes(ProductTypes.RENTERS) ||
      validIds.includes(ProductTypes.HOMEOWNERS);
    return vehiclePolicy && propertyPolicy;
  }

  getProduct(id: string): Observable<Product> {
    return this._productsDao.getProduct(id);
  }

  updateProduct(entity: Product): void {
    this._productsDao.updateProduct(entity);
  }

  getQuoteLoaded(productId: string): Observable<boolean> {
    return this._productsDao.getQuoteLoaded(productId);
  }

  getAnyQuoteLoaded(): Observable<boolean> {
    return this._productsDao.getAnyQuoteLoaded();
  }

  getQuoteLoading(productId: string): Observable<boolean> {
    return this._productsDao.getQuoteLoading(productId);
  }

  getAutoTokenLoaded(): Observable<boolean> {
    return this._productsDao.getAutoTokenLoaded();
  }

  getAutoTokenLoading(): Observable<boolean> {
    return this._productsDao.getAutoTokenLoading();
  }

  getPowersportsTokenLoaded(): Observable<boolean> {
    return this._productsDao.getPowersportsTokenLoaded();
  }

  getPowersportsTokenLoading(): Observable<boolean> {
    return this._productsDao.getPowersportsTokenLoading();
  }

  getSelectedProductIds(): Observable<string[]> {
    return this._productsDao.getSelectedProductsIds();
  }

  getNonRentalPropertyProductSelectedWithoutError(): Observable<string> {
    return this._productsDao.getNonRentalPropertyProductSelectedWithoutError();
  }

  getPropertyProductSelectedWithoutError(): Observable<string> {
    return this._productsDao.getPropertyProductSelectedWithoutError();
  }

  isAnyPropertyProductSelected(): Observable<boolean> {
    return this._productsDao.isAnyPropertyProductSelected();
  }

  isDsmProductSelected(productId: string): Observable<boolean> {
    return this._productsDao
      .getSelectedProductsIds()
      .pipe(map(ids => ids.includes(productId)));
  }

  getQuoteRated(productId: string): Observable<boolean> {
    return this._productsDao.getQuoteRated(productId);
  }

  getQuoteCompleted(productId: string): Observable<boolean> {
    return this._productsDao.getQuoteCompleted(productId);
  }

  getQuoteCoverageLoading(productId: string): Observable<boolean> {
    return this._productsDao.getQuoteCoverageLoading(productId);
  }

  getQuoteRating(productId: string): Observable<boolean> {
    return this._productsDao.getQuoteRating(productId);
  }

  getQuoteUpdateLoading(productId: string): Observable<boolean> {
    return this._productsDao.getQuoteUpdateLoading(productId);
  }

  isQuoteLoaded(): Observable<boolean> {
    return this._productsDao.isQuoteLoaded();
  }

  isBindLoaded(): Observable<boolean> {
    return this._productsDao.isBindLoaded();
  }

  isIssueLoaded(): Observable<boolean> {
    return this._productsDao.isIssueLoaded();
  }

  isIssueLoadedOrFailed(): Observable<boolean> {
    return this._productsDao.isIssueLoadedOrFailed();
  }

  getQuoteCoverageLoaded(productId: string): Observable<boolean> {
    return this._productsDao.getQuoteCoverageLoaded(productId);
  }

  getAllQuoteCoverageLoaded(productId: string): Observable<boolean> {
    return this._productsDao.getAllQuoteCoverageLoaded(productId);
  }

  getQuoteBound(productId: string): Observable<boolean> {
    return this._productsDao.getQuoteBound(productId);
  }

  getBindCompleted(productId: string): Observable<boolean> {
    return this._productsDao.getBindCompleted(productId);
  }

  getQuoteBinding(productId: string): Observable<boolean> {
    return this._productsDao.getQuoteBinding(productId);
  }

  getSelectedQuoteProductsWithoutErrors(): Observable<Product[]> {
    return this._productsDao.getSelectedQuoteProductsWithoutErrors();
  }

  getSelectedQuoteProducts(): Observable<Product[]> {
    return this._productsDao.getSelectedQuoteProducts();
  }

  getSelectedProductsWithoutErrors(): Observable<Product[]> {
    return this._productsDao.getSelectedProductsWithoutErrors();
  }

  isDsmProductBindSelected(id: string): Observable<boolean> {
    return this._productsDao.isDsmProductBindSelected(id);
  }

  getNeedConstructionInfo(productId: string): Observable<boolean> {
    return this._productsDao.getNeedConstructionInfo(productId);
  }

  setLeadsSentToCma(): void {
    this._productsDao.setLeadsSentToCma();
  }

  getStatusOfAllProducts(): Observable<ProductStatus[]> {
    return this._productsDao.getStatusOfAllProducts();
  }

  getStatusOfAllProductsWithoutErrors(): Observable<ProductStatus[]> {
    return this._productsDao.getStatusOfAllProductsWithoutErrors();
  }

  getAnyProductRating(): Observable<boolean> {
    return this.getStatusOfAllProductsWithoutErrors().pipe(
      distinctUntilChanged(),
      map(statuses => {
        return !!statuses.find(s => s.status === ProductSimpleStatus.PENDING);
      })
    );
  }

  failProduct(productId: string, errorCode?: string): void {
    this._productsDao.failProduct(productId, errorCode);
  }

  softfall(productId: string, errorCode?: string): void {
    this._productsDao.softfall(productId, errorCode);
  }

  failAllProducts(errorCode?: string): void {
    this._productsDao.failAllProducts(errorCode);
  }

  arePreBindDocumentsAcknowledged(): Observable<boolean> {
    return this._productsDao.arePreBindDocumentsAcknowledged();
  }

  areAllProductsFailed(): Observable<boolean> {
    return this._productsDao.areAllProductsFailed();
  }

  getSelectedPropertyProduct(): Observable<Product> {
    return this._productsDao.getSelectedPropertyProduct();
  }

  anyProductIsInitiating(): Observable<boolean> {
    return this._productsDao.anyProductIsInitiating();
  }

  disqualifyUmbrella(): void {
    this._productsDao.disqualifyUmbrella();
  }

  isMonoQuoteAllowed(): Observable<boolean> {
    return this._productsDao.isMonoQuoteAllowed();
  }

  isCAorNYStateQuoteAllowed(): Observable<boolean> {
    return this._productsDao.isCAorNYStateQuoteAllowed();
  }

  isNonGenderSpecifiedAutoOnly(): Observable<boolean> {
    return this._productsDao.isNonGenderSpecifiedAutoOnly();
  }

  isNonGenderSpecifiedNonProperty(): Observable<boolean> {
    return this._productsDao.isNonGenderSpecifiedNonProperty();
  }

  isNoPlayStateTermLifeRestricted(): Observable<boolean> {
    return this._productsDao.isNoPlayStateTermLifeRestricted();
  }

  isNoPlayStatePetRestricted(): Observable<boolean> {
    return this._productsDao.isNoPlayStatePetRestricted();
  }

  isNoPlayStateAllProductsRestricted(): Observable<boolean> {
    return this._productsDao.isNoPlayStateAllProductsRestricted();
  }

  isPauseStatePetRestricted(): Observable<boolean> {
    return this._productsDao.isPauseStatePetRestricted();
  }

  isPetUnavailableState(): Observable<boolean> {
    return this._productsDao.isPetUnavailableState();
  }
  updateShowerrorExceptAProduct(productId: string): void {
    this.getSelectedProducts()
      .pipe(take(1))
      .subscribe(products => {
        products.forEach(product => {
          if (product.id != productId) {
            this.updateProduct({ ...product, showError: false });
          }
        });
      });
  }

  isRoofConditionForCondoApplicable(): Observable<boolean> {
    return this._productsDao.isRoofConditionForCondoApplicable();
  }
  getMonolineTFN(): Observable<string> {
    return this._productsDao.getMonolineTFN();
  }

  arePreBindPowersportsDocumentsAcknowledged(): Observable<boolean> {
    return this._productsDao.arePreBindPowersportsDocumentsAcknowledged();
  }
  getTFN(): Observable<string> {
    return this._productsDao.getTFN();
  }

  isPropertyOnlySelected(): Observable<boolean> {
    return this._productsDao.isPropertyOnlySelected();
  }

  isHomeOwnersOnlySelected(): Observable<boolean> {
    return this._productsDao.isHomeOwnersOnlySelected();
  }

  thirdPartyPropertyPause(): Observable<boolean> {
    return this._productsDao.thirdPartyPropertyPause();
  }
}
