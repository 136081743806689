import {
  OnInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { EligibleDiscount } from '@core/models/auto/modifiers/modifier-request.model';
import { BillingModel } from '@core/models/billing/billing.model';
import { DiscountEntity } from '@core/models/entities/discount.entity';
import { Product } from '@core/models/products/product.model';
import { TelematicsEnrollmentResponse } from '@core/models/telematics/telematics.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BillingPaymentMethod,
  BillingPaymentPlan,
  PaymentPlans,
  ProductTypes,
} from '@shared/constants/app-constants';
import { BaseBillingProxy } from '@shared/proxies/base-billing.proxy';
import { EditBillingPlanModalComponent } from '../edit-billing-plan-modal/edit-billing-plan-modal.component';
@Component({
  selector: 'mq-billing-banner',
  templateUrl: './billing-banner.component.html',
  styleUrls: ['./billing-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingBannerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isPayingThroughEscrow: boolean;
  @Input() isPayingThroughEscrowFromRetrieve: boolean;
  @Input() selectedProducts: Product[];
  @Input() billingInfo: BillingModel;
  @Input() telematicsEnrollments: TelematicsEnrollmentResponse;
  @Input() discounts: DiscountEntity[];
  @Input() isBillingPaymentMethodApplicable: boolean;
  @Input() easyPayModifier: EligibleDiscount;
  @Input() isEditBillingPlanRequired: boolean;
  @Input() isSmartMilesEnrolled: boolean;
  @Input() triggerEditBillingPopup: boolean;
  @Input() isRivian: boolean;
  @Input() paidInFullPowersportsModifier: EligibleDiscount;
  @Output() editBillingPaymentModal = new EventEmitter<Event>();
  isAutoSelected: boolean;
  isPowersportsSelected: boolean;
  paymentPlan: string;
  isBillingInfoAvailable: boolean;
  isRemoveEftOptionRequired: boolean;
  isAgentExperience: boolean;
  removeEftOption$: any;
  isAgent$: any;

  constructor(
    private _modalService: NgbModal,
    private _billingProxy: BaseBillingProxy
  ) {}

  ngOnInit(): void {
    this.isAutoSelected = this.selectedProducts.some(product => {
      if (product.id === ProductTypes.AUTO && !product.hasError) {
        return true;
      } else return false;
    });
    this.isPowersportsSelected = this.selectedProducts.some(product => {
      if (product.id === ProductTypes.POWERSPORTS && !product.hasError) {
        return true;
      } else return false;
    });
    this.removeEftOption$ = this._billingProxy
      .isRemoveEftOptionRequired()
      .subscribe(eftPaymentOption => {
        this.isRemoveEftOptionRequired = eftPaymentOption;
      });
    this.isAgent$ = this._billingProxy
      .isAgentExperience()
      .subscribe(isAgent => {
        this.isAgentExperience = isAgent;
      });
  }

  ngOnDestroy(): void {
    this.removeEftOption$?.unsubscribe();
    this.isAgent$?.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.billingInfo) {
      if (this.billingInfo?.payment) {
        this.isBillingInfoAvailable = true;
        if (
          this.billingInfo?.payment?.paymentMethod ===
            BillingPaymentMethod.EFT &&
          this.billingInfo?.payment?.paymentPlan === BillingPaymentPlan.FULL_PAY
        ) {
          this.paymentPlan = PaymentPlans.PAY_IN_FULL_WITH_BANKACCOUNT;
        } else if (
          this.billingInfo?.payment?.paymentMethod ===
            BillingPaymentMethod.BANKCARD &&
          this.billingInfo?.payment?.paymentPlan === BillingPaymentPlan.FULL_PAY
        ) {
          this.paymentPlan = PaymentPlans.PAY_IN_FULL_WITH_BANKCARD;
        } else if (
          this.billingInfo?.payment?.paymentMethod ===
            BillingPaymentMethod.EFT &&
          this.billingInfo?.payment?.paymentPlan ===
            BillingPaymentPlan.MONTHLY &&
          this.billingInfo?.payment?.recurring
        ) {
          this.paymentPlan = PaymentPlans.RECURRING_EFT;
        } else if (
          (this.billingInfo?.payment?.paymentMethod ===
            BillingPaymentMethod.EFT ||
            this.billingInfo?.payment?.paymentMethod ===
              BillingPaymentMethod.BANKCARD) &&
          this.billingInfo?.payment?.paymentPlan ===
            BillingPaymentPlan.MONTHLY &&
          !this.billingInfo?.payment?.recurring
        ) {
          this.paymentPlan = PaymentPlans.MONTHLY_DIRECT_BILL;
        } else if (
          this.billingInfo?.payment?.paymentMethod ===
            BillingPaymentMethod.BANKCARD &&
          this.billingInfo?.payment?.paymentPlan ===
            BillingPaymentPlan.MONTHLY &&
          this.billingInfo?.payment?.recurring
        ) {
          this.paymentPlan = PaymentPlans.RECURRING_BANKCARD;
        }
      } else this.isBillingInfoAvailable = false;
    }
    if (changes.triggerEditBillingPopup?.currentValue) {
      this.editBillingPlanModal();
      this._billingProxy.setTriggerEditBillingPopup(false);
    }
  }

  editBillingPlanModal(event?: any): void {
    const modal = this._modalService.open(EditBillingPlanModalComponent, {
      size: 'lg',
      ariaLabelledBy: 'modal-title',
      backdrop: 'static',
      keyboard: false,
    });
    modal.componentInstance.telematicsEnrollments = this.telematicsEnrollments;
    modal.componentInstance.products = this.selectedProducts;
    modal.componentInstance.billingInfo = this.billingInfo;
    modal.componentInstance.isBillingPaymentMethodApplicable =
      this.isBillingPaymentMethodApplicable;
    modal.componentInstance.easyPayModifier = this.easyPayModifier;
    modal.componentInstance.isSmartMilesEnrolled = this.isSmartMilesEnrolled;
    modal.componentInstance.isRivian = this.isRivian;
    modal.componentInstance.isRemoveEftOptionRequired =
      this.isRemoveEftOptionRequired;
    modal.componentInstance.isAgent = this.isAgentExperience;
    modal.componentInstance.paidInFullPowersportsModifier =
      this.paidInFullPowersportsModifier;
    modal.result.then(
      response => {},

      () => {}
    );
  }
}
