import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HomeownerPropertyInfoMetadataAdapter {
  constructor(private _http: HttpClient) {}

  getPropertyInfoHomeOwnerPicklist(): Observable<any> {
    return this._http
      .get('assets/api/metadata/home/propertyInfo-homeowner-picklist.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getPropertyInfoCondoPicklist(): Observable<any> {
    return this._http
      .get('assets/api/metadata/condo/propertyInfo-condo-picklist.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getPropertyInfoRentersPicklist(): Observable<any> {
    return this._http
      .get('assets/api/metadata/renters/propertyInfo-renters-picklist.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }
}
