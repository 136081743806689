import {
  OnInit,
  Component,
  Input,
  OnDestroy,
  HostBinding,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { EligibleDiscount } from '@core/models/auto/modifiers/modifier-request.model';
import {
  BillingModel,
  BillingPaymentPlanModel,
} from '@core/models/billing/billing.model';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { ProductSimpleStatus } from '@core/models/products/product-status';
import { Product } from '@core/models/products/product.model';
import { SessionLog } from '@core/models/session-log.model';
import { TelematicsEnrollmentResponse } from '@core/models/telematics/telematics.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BillingPaymentMethod,
  BillingPaymentPlan,
  ModifierNames,
  ModifierValues,
  PaymentPlans,
  ProductTypes,
} from '@shared/constants/app-constants';
import { BaseBillingProxy } from '@shared/proxies/base-billing.proxy';
import { distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'mq-edit-billing-plan-modal',
  templateUrl: './edit-billing-plan-modal.component.html',
  styleUrls: ['./edit-billing-plan-modal.component.scss'],
})
export class EditBillingPlanModalComponent implements OnInit, OnDestroy {
  @Input() billingInfo: BillingModel;
  @Input() products: Product[];
  @Input() telematicsEnrollments: TelematicsEnrollmentResponse;
  @Input() isBillingPaymentMethodApplicable: boolean;
  @Input() easyPayModifier: EligibleDiscount;
  @Input() isSmartMilesEnrolled: boolean;
  @Input() isRivian: boolean;
  @Input() isRemoveEftOptionRequired: boolean;
  @Input() isAgent: boolean;
  @Input() paidInFullPowersportsModifier: EligibleDiscount;
  @HostBinding('attr.role') role = 'main';

  form: UntypedFormGroup;
  isSmartMilesAvailable = false;
  isAutoProductSelected = false;
  isPowersportsSelected = false;
  isPropertyProductSelected = false;
  isTriggerBindRateCallRequired = true;
  getStatusOfAllProductsWithoutErrors$: any;
  rerateRequired = false;
  formSubmitted: boolean;
  recalculatedStarted: boolean;
  areModifiersLoading$: any;
  getProductsWithPolicyNumbers$: any;
  getProductWithPolicyNumber: Product[];

  constructor(
    private _fb: UntypedFormBuilder,
    public modal: NgbActiveModal,
    private _billingProxy: BaseBillingProxy
  ) {}
  ngOnInit(): void {
    this.formSubmitted = false;
    this.recalculatedStarted = false;
    this.buildForm();
    this.getBillingInfo();
    this.isMonolinePropertyProductFullPaySelected();
    this.getStatusOfAllProductsWithoutErrors$ = this._billingProxy
      .getStatusOfAllProductsWithoutErrors()
      .pipe(distinctUntilChanged())
      .subscribe(products => {
        if (this.formSubmitted) {
          const isNotBoundStatus = !!products.find(
            product => product.status !== ProductSimpleStatus.BOUND
          );
          if (
            isNotBoundStatus &&
            !this.recalculatedStarted &&
            this.rerateRequired
          ) {
            this._billingProxy.loadingBegin('Recalculating premium');
            this.recalculatedStarted = true;
            this._billingProxy.sendSessionLogEntry('BIND_RATE_INITIATED');
          } else if (!isNotBoundStatus) {
            this._billingProxy.loadingEnd();
            this.recalculatedStarted = false;
            this.formSubmitted = false;
            this.rerateRequired = false;
            this.modal.close();
          }
        }
      });

    this.areModifiersLoading$ = this._billingProxy
      .areModifiersLoading()
      .pipe(distinctUntilChanged())
      .subscribe(areModifiersLoading => {
        if (this.formSubmitted) {
          if (areModifiersLoading) {
            this._billingProxy.loadingBegin();
          } else if (!this.recalculatedStarted) {
            this._billingProxy.loadingEnd();
          }
        }
      });

    this.isAutoProductSelected = this.products.some(product => {
      return product.id === ProductTypes.AUTO && !product.hasError
        ? true
        : false;
    });

    this.getProductsWithPolicyNumbers$ = this._billingProxy
      .getProductsWithPolicyNumber()
      .subscribe(product => (this.getProductWithPolicyNumber = product));

    this.isPowersportsSelected = this.products.some(product => {
      return product.id === ProductTypes.POWERSPORTS && !product.hasError
        ? true
        : false;
    });
    this.isPropertyProductSelected = this.products.some(
      product =>
        product.id === ProductTypes.HOMEOWNERS ||
        product.id === ProductTypes.CONDO ||
        product.id === ProductTypes.RENTERS
    );
  }

  ngOnDestroy(): void {
    this.getStatusOfAllProductsWithoutErrors$.unsubscribe();
    this.areModifiersLoading$.unsubscribe();
    this.getProductsWithPolicyNumbers$.unsubscribe();
    this._billingProxy.setRebindWhenInvalidOnBillingChange(false);
  }

  getBillingInfo(): void {
    if (this.billingInfo.payment.paymentMethod) {
      this.form.patchValue({
        paymentMethod: this.billingInfo.payment.paymentMethod,
      });
    }
    if (this.billingInfo.payment.recurring != null) {
      this.form.patchValue({
        recurring: this.billingInfo.payment.recurring,
      });
    }
    if (
      this.billingInfo.payment.paymentPlan === BillingPaymentPlan.MONTHLY &&
      this.billingInfo.payment.paymentMethod === BillingPaymentMethod.EFT &&
      this.billingInfo.payment.recurring
    )
      this.form.patchValue({
        paymentPlan: PaymentPlans.RECURRING_EFT,
      });

    if (
      this.billingInfo.payment.paymentPlan === BillingPaymentPlan.MONTHLY &&
      this.billingInfo.payment.paymentMethod ===
        BillingPaymentMethod.BANKCARD &&
      this.billingInfo.payment.recurring
    )
      this.form.patchValue({
        paymentPlan: PaymentPlans.RECURRING_BANKCARD,
      });

    if (
      this.billingInfo.payment.paymentPlan === BillingPaymentPlan.MONTHLY &&
      !this.billingInfo.payment.recurring
    )
      this.form.patchValue({
        paymentPlan: PaymentPlans.MONTHLY_DIRECT_BILL,
      });

    if (this.billingInfo.payment.paymentPlan === BillingPaymentPlan.FULL_PAY)
      this.form.patchValue({
        paymentPlan: BillingPaymentPlan.FULL_PAY,
      });
  }

  isMonolinePropertyProductFullPaySelected(): boolean {
    if (
      (this.isPropertyProductSelected &&
        this.products.length === 1 &&
        this.paymentPlan.value === BillingPaymentPlan.FULL_PAY) ||
      this.isSmartMilesAvailable
    ) {
      return true;
    } else return false;
  }

  private buildForm(): void {
    if (this.form) {
      return;
    }
    this.form = this._fb.group({
      paymentPlan: this._fb.control('', [Validators.required]),
      paymentMethod: this._fb.control('', [Validators.required]),
      recurring: this._fb.control(true, [Validators.required]),
    });
  }

  get recurring(): AbstractControl {
    return this.form.get('recurring');
  }
  get paymentPlan(): AbstractControl {
    return this.form.get('paymentPlan');
  }
  get paymentMethod(): AbstractControl {
    return this.form.get('paymentMethod');
  }

  isBankaccountDisabled(): boolean {
    return this.paymentPlan.value === PaymentPlans.RECURRING_BANKCARD ||
      this.isRemoveEftOptionRequired
      ? true
      : false;
  }

  isBankcardDisabled(): boolean {
    return this.paymentPlan.value === PaymentPlans.RECURRING_EFT ? true : false;
  }

  updateBillingPaymentPlan(
    paymentPlan: string,
    paymentMethod: string,
    recurring: boolean
  ): BillingPaymentPlanModel {
    let billingPaymentPlanModel: BillingPaymentPlanModel;

    if (paymentPlan === PaymentPlans.RECURRING_EFT) {
      billingPaymentPlanModel = {
        paymentPlan: BillingPaymentPlan.MONTHLY,
        recurring: recurring,
        paymentMethod: paymentMethod,
      };
    }

    if (paymentPlan === PaymentPlans.RECURRING_BANKCARD) {
      billingPaymentPlanModel = {
        paymentPlan: BillingPaymentPlan.MONTHLY,
        recurring: recurring,
        paymentMethod: paymentMethod,
      };
    }
    if (paymentPlan === PaymentPlans.MONTHLY_DIRECT_BILL) {
      billingPaymentPlanModel = {
        paymentPlan: BillingPaymentPlan.MONTHLY,
        recurring: recurring,
        paymentMethod: paymentMethod,
      };
    }

    if (
      paymentPlan === BillingPaymentPlan.FULL_PAY &&
      this.isMonolinePropertyProductFullPaySelected()
    ) {
      billingPaymentPlanModel = {
        paymentPlan: BillingPaymentPlan.FULL_PAY,
        recurring: false,
        paymentMethod: paymentMethod,
      };
    }

    if (
      paymentPlan === BillingPaymentPlan.FULL_PAY &&
      !this.isMonolinePropertyProductFullPaySelected()
    ) {
      billingPaymentPlanModel = {
        paymentPlan: BillingPaymentPlan.FULL_PAY,
        recurring: recurring,
        paymentMethod: paymentMethod,
      };
    }

    return billingPaymentPlanModel;
  }

  updatePaymentTotal(recurring: boolean, paymentMethod?: string): void {
    if (paymentMethod != null) {
      this.form.patchValue({
        paymentMethod: paymentMethod,
      });
    }
    if (recurring != null) {
      this.form.patchValue({
        recurring: recurring,
      });
    }
    this.form.updateValueAndValidity();
  }

  onSubmit(form: UntypedFormGroup): void {
    this.formSubmitted = true;
    if (form.valid) {
      const response = this.updateBillingPaymentPlan(
        this.paymentPlan.value,
        this.paymentMethod.value,
        this.recurring.value
      );

      if (this.isPowersportsSelected && !this.isAutoProductSelected) {
        this._billingProxy.dispatchUpdateBillingPaymentPlan(response);
        if (this.paidInFullPowersportsModifier?.selectedOptionValue) {
          if (
            this._billingProxy.isPaidInFullDiscountForPSUpdateRequired(
              response.paymentPlan,
              this.paidInFullPowersportsModifier?.selectedOptionValue
            )
          ) {
            this.rerateRequired = true;
            this._billingProxy.setRebindWhenInvalidOnBillingChange(true);
            this.updatePaidInFullPowersportsModifier(response);
          }
        }
      } else if (this.isBillingPaymentMethodApplicable) {
        this._billingProxy.dispatchUpdateBillingPaymentPlan(response);
        if (
          this.billingInfo.payment.paymentPlan !== response.paymentPlan ||
          (this.billingInfo.payment.paymentMethod !== response.paymentMethod &&
            (this.paymentPlan.value === PaymentPlans.RECURRING_BANKCARD ||
              this.paymentPlan.value === PaymentPlans.RECURRING_EFT)) ||
          (this.billingInfo.payment.recurring !== response.recurring &&
            this.billingInfo.payment.paymentPlan != BillingPaymentPlan.FULL_PAY)
        ) {
          this.rerateRequired = true;
          this._billingProxy.setRebindWhenInvalidOnBillingChange(true);
          this.updateBillingPaymentMethodModifier(
            response.paymentPlan,
            response.paymentMethod,
            response.recurring
          );
          if (this.paidInFullPowersportsModifier?.selectedOptionValue) {
            if (
              this._billingProxy.isPaidInFullDiscountForPSUpdateRequired(
                response.paymentPlan,
                this.paidInFullPowersportsModifier?.selectedOptionValue
              )
            ) {
              this.rerateRequired = true;
              this._billingProxy.setRebindWhenInvalidOnBillingChange(true);
              this.updatePaidInFullPowersportsModifier(response);
            }
          }
        }
      } else if (this.easyPayModifier?.selectedOptionValue) {
        if (this.paidInFullPowersportsModifier?.selectedOptionValue) {
          if (
            this._billingProxy.isPaidInFullDiscountForPSUpdateRequired(
              response.paymentPlan,
              this.paidInFullPowersportsModifier?.selectedOptionValue
            )
          ) {
            this.rerateRequired = true;
            this._billingProxy.setRebindWhenInvalidOnBillingChange(true);
            this.updatePaidInFullPowersportsModifier(response);
          }
        }
        if (
          this._billingProxy.isEasyPayDiscountUpdateRequired(
            response.paymentPlan,
            response.paymentMethod,
            response.recurring,
            this.easyPayModifier?.selectedOptionValue
          )
        ) {
          this._billingProxy.checkEasyPayDiscount(
            response.paymentPlan,
            response.paymentMethod,
            response.recurring
          );

          this.rerateRequired = true;
          this._billingProxy.setRebindWhenInvalidOnBillingChange(true);
          this.isTriggerBindRateCallRequired = false;
          this.updateSessionLog(response, this.easyPayModifier);
        } else {
          this.isTriggerBindRateCallRequired = true;
        }

        this._billingProxy.dispatchUpdateBillingPaymentPlan(response);
        if (
          this.getProductWithPolicyNumber.length > 1 &&
          this.getProductWithPolicyNumber.find(
            product => product.id === ProductTypes.AUTO
          )
        ) {
          if (this.isTriggerBindRateCallRequired) {
            this.rerateRequired = true;
            this._billingProxy.triggerBindRateCall(true);
            this._billingProxy.setRebindWhenInvalidOnBillingChange(true);
          }
        } else {
          this.isTriggerBindRateCallRequired = true;
        }
      } else {
        this._billingProxy.dispatchUpdateBillingPaymentPlan(response);

        if (
          this.getProductWithPolicyNumber.length > 1 &&
          this.getProductWithPolicyNumber.find(
            product => product.id === ProductTypes.POWERSPORTS
          )
        ) {
          this.rerateRequired = true;
          this._billingProxy.triggerBindRateCall(true);

          this._billingProxy.setRebindWhenInvalidOnBillingChange(true);
        }
      }
      if (!this.rerateRequired) {
        this.modal.close();
      }
    }
  }
  private updateBillingPaymentMethodModifier(
    paymentPlan: string,
    paymentMethod: string,
    recurring: boolean
  ): void {
    let billingPaymentMethod;
    if (paymentPlan === BillingPaymentPlan.MONTHLY) {
      if (paymentMethod !== BillingPaymentMethod.BANKCARD)
        billingPaymentMethod = recurring
          ? ModifierValues.RECURRINGEFT
          : ModifierValues.DIRECTBILL;
      else
        billingPaymentMethod = recurring
          ? ModifierValues.RECURRINGBANKCARD
          : ModifierValues.DIRECTBILL;
    } else if (paymentPlan === BillingPaymentPlan.FULL_PAY) {
      billingPaymentMethod = ModifierValues.FULLPAY;
    }
    const billingPaymentMethodModifier: EligibleDiscountEntity = {
      eligibleDiscountId: ModifierNames.BILLINGPAYMENTMETHOD,
      productId: ProductTypes.AUTO,
      selectedOptionValue: billingPaymentMethod + '',
    };
    this.updateSessionLog(
      { paymentPlan, paymentMethod, recurring },
      null,
      billingPaymentMethodModifier
    );
    this._billingProxy.dispatchUpdateBillingPaymentMethod(
      billingPaymentMethodModifier
    );
  }

  updateSessionLog(
    response: BillingPaymentPlanModel,
    EasyPayDiscount?: EligibleDiscount,
    BillingPaymentMethod?: EligibleDiscountEntity,
    paidInFullPowersportsDiscount?: EligibleDiscount
  ): void {
    if (this.billingInfo.payment.recurring !== response.recurring) {
      const sessionLog: SessionLog = {
        type: 'Recalculate Premium',
        code: 'Billing Change',
        reason: 'recurring',
        value: <string>(<unknown>response.recurring),
      };
      this._billingProxy.updateSessionLogEntry(sessionLog);
    }
    if (this.billingInfo.payment.paymentPlan !== response.paymentPlan) {
      const sessionLog: SessionLog = {
        type: 'Recalculate Premium',
        code: 'Billing Change',
        reason: 'Payment Plan',
        value: response.paymentPlan,
      };
      this._billingProxy.updateSessionLogEntry(sessionLog);
    }
    if (this.billingInfo.payment.paymentMethod !== response.paymentMethod) {
      const sessionLog: SessionLog = {
        type: 'Recalculate Premium',
        code: 'Billing Change',
        reason: 'Payment Method',
        value: response.paymentMethod,
      };
      this._billingProxy.updateSessionLogEntry(sessionLog);
    }
    if (EasyPayDiscount?.selectedOptionValue) {
      const isEasyPayDiscountApplied =
        EasyPayDiscount?.selectedOptionValue === 'true' ? 'false' : 'true';
      const sessionLog: SessionLog = {
        type: 'Recalculate Premium',
        code: 'Billing Change',
        reason: 'EasyPay Discount',
        value: isEasyPayDiscountApplied,
      };
      this._billingProxy.updateSessionLogEntry(sessionLog);
    }
    if (BillingPaymentMethod?.selectedOptionValue) {
      const sessionLog: SessionLog = {
        type: 'Recalculate Premium',
        code: 'Billing Change',
        reason: 'BillingPaymentMethod Modifier',
        value: BillingPaymentMethod?.selectedOptionValue,
      };
      this._billingProxy.updateSessionLogEntry(sessionLog);
    }
    if (paidInFullPowersportsDiscount?.selectedOptionValue) {
      const isPaidInFullPowersportsDiscountApplied =
        paidInFullPowersportsDiscount?.selectedOptionValue === 'true'
          ? 'false'
          : 'true';
      const sessionLog: SessionLog = {
        type: 'Recalculate Premium',
        code: 'Billing Change',
        reason: 'Paid In Full Discount For Powersports',
        value: isPaidInFullPowersportsDiscountApplied,
      };
      this._billingProxy.updateSessionLogEntry(sessionLog);
    }
  }

  isAgentExperience(): boolean {
    if (
      this.isAgent &&
      this.paymentMethod.value === BillingPaymentMethod.BANKCARD
    ) {
      this.recurring.setValue(false);
      return true;
    }
    return false;
  }

  isFullPayRecurring(): boolean {
    if (
      this.paymentPlan.value === BillingPaymentPlan.FULL_PAY &&
      !this.isMonolinePropertyProductFullPaySelected() &&
      !this.isAgentExperience()
    ) {
      return true;
    }
    return false;
  }

  isSmartMilesHelpTextApplicable(): boolean {
    if (this.isAutoProductSelected && !this.isRivian) {
      return true;
    }
    return false;
  }

  updatePaidInFullPowersportsModifier(response: BillingPaymentPlanModel): void {
    const selectedOptionValue =
      response.paymentPlan === BillingPaymentPlan.FULL_PAY ? 'true' : 'false';
    if (
      this.paidInFullPowersportsModifier?.selectedOptionValue !==
      selectedOptionValue
    ) {
      const powersportsModifier = {
        eligibleDiscountId: ModifierNames.PAIDINFULL,
        productId: ProductTypes.POWERSPORTS,
        selectedOptionValue: selectedOptionValue,
      };
      this.updateSessionLog(
        response,
        null,
        null,
        this.paidInFullPowersportsModifier
      );
      this._billingProxy.dispatchUpdatePolicyLine(powersportsModifier);
    }
  }
}
