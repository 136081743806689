import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserContextService } from './user-context.service';
import { take } from 'rxjs/operators';

@Injectable()
export class GTMAnalyticsService {
  constructor(
    private _router: Router,
    private _userContextService: UserContextService
  ) {}

  pageView(overridePath?: string) {
    this._userContextService
      .getUserContext()
      .pipe(take(1))
      .subscribe(userContext => {
        if (userContext && userContext.gtm) {
          (<any>window).dataLayer.push({
            event: 'Pageview',
            pagePath: overridePath || this._router.url,
            pageTitle: overridePath || this._router.url,
          });
        }
      });
  }
}
