import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromUserContext from '@core/store/reducers/user-context.reducer';
import { InitiatedByType } from '@core/models/user-context/user-context.model';

export const getUserContextState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.userContext
);

export const getUserContext = createSelector(
  getUserContextState,
  fromUserContext.getUserContext
);

export const isQuoteRetrieve = createSelector(
  getUserContext,
  userContext => userContext && userContext.retrieve
);

export const isCompRaterInitiated = createSelector(
  getUserContext,
  userContext =>
    userContext ? userContext.initiatedBy === InitiatedByType.COMPRATER : false
);

export const isMobileInitiated = createSelector(getUserContext, userContext =>
  userContext
    ? userContext.initiatedBy === InitiatedByType.IOS_MOBILE ||
      userContext.initiatedBy === InitiatedByType.ANDROID_MOBILE
    : false
);

export const isPrivateLabelPreview = createSelector(
  getUserContext,
  userContext => (userContext ? userContext.privateLabelPreview : false)
);

export const getUserContextLoaded = createSelector(
  getUserContextState,
  fromUserContext.getUserContextLoaded
);
