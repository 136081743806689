<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Credit Notice</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    Nationwide collects information from you and other third-party sources and
    may share your information with third parties without your authorization, as
    permitted or required by law. We review your credit report and the credit
    report of any individual listed in the application or obtain or use an
    insurance credit score based on the information contained in the credit
    report, where permitted by law. We may use a third party in connection with
    the development of your insurance credit score. You may request that your
    credit information be updated and if you question the accuracy of the credit
    information, we will, upon your request, re-evaluate you based on corrected
    credit information from a consumer reporting agency. Examples of the type of
    information collected are driving records, driving history, insurance claims
    histories, credit reports and credit-based insurance scores. Once you become
    a customer, Nationwide may continue to use your consumer reports to update
    or renew your insurance policy. Please review our
    <a
      href="http://www.nationwide.com/privacy-security.jsp"
      target="_blank"
      rel="noopener"
      >Privacy Policy</a
    >, which describes Nationwide’s use of collected information, and our
    <a
      href="https://www.nationwide.com/personal/about-us/terms-conditions"
      target="_blank"
      rel="noopener"
      >Terms and Conditions</a
    >.
  </p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Close click')"
  >
    Close
  </button>
</div>
