import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromAddress from '@core/store/reducers/address.reducer';

export const getAddressState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.address
);

export const getStandardizedAddresses = createSelector(
  getAddressState,
  fromAddress.getStandardizedAddresses
);

export const getStandardizedAddressLoaded = createSelector(
  getAddressState,
  fromAddress.getStandardizedAddressLoaded
);

export const getStandardizedAddressLoading = createSelector(
  getAddressState,
  fromAddress.getStandardizedAddressLoading
);
