import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import {
  ContentfulService,
  LoggingService,
  MetadataService,
} from '@core/services';
import { from, Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SmartHomeExperience } from '@quote/models/smart-home-experience.model';
import { Entry } from 'contentful';
import { MoreDetailsProxy } from '@quote/proxies/more-details.proxy';
import { SHE_ACondo } from '@shared/constants/app-constants';
declare let Bootstrapper: any;

@Component({
  selector: 'mq-smarthome-price-points',
  templateUrl: './smarthome-property-info-price-points-form.component.html',
  styleUrls: ['./smarthome-property-info-price-points-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SmarthomePropertyInfoPricePointsFormComponent
  implements OnInit, OnChanges
{
  quoteState: string;
  smartHomeExperiencesContent: SmartHomeExperience[];
  experience: SmartHomeExperience;
  pricingTextAllGroups: string;
  showSmartHomeDiscount$: Observable<boolean>;
  isSmartHomeExperienceEnabled = true;
  refreshVisibilityFlag$ = new ReplaySubject<void>(1);

  @Input() form: UntypedFormGroup;
  @Input() smartHomeDiscount: EligibleDiscountEntity;
  @Output() smartHomeExperience = new EventEmitter<string>();
  @Input() isCondo: boolean;
  @Input() isHome: boolean;

  constructor(
    private _metadataService: MetadataService,
    private _contentfulService: ContentfulService,
    private _proxy: MoreDetailsProxy,
    private _loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this.showSmartHomeDiscount$ = this.refreshVisibilityFlag$.pipe(
      map(value => !!this.smartHomeDiscount)
    );
    this.form.updateValueAndValidity();

    if (this.isHome)
      this._loggingService.logToSplunk({
        event: 'SMARTHOME_PO_IS_HOME',
      });
    else if (this.isCondo)
      this._loggingService.logToSplunk({
        event: 'SMARTHOME_PO_IS_CONDO',
      });

    if (this.smartHomeDiscount) {
      this._loggingService.logToSplunk({
        event: 'SMARTHOME_PO_ELIGIBLE',
      });
      // prettier-ignore
      this.form.setValue({ 'optIn': null });
      this._proxy
        .getQuoteState()
        .pipe(take(1))
        .subscribe(state => {
          this.quoteState = state;

          this._loggingService.logToSplunk({
            event: 'SMARTHOME_PO_RETRIEVED_QUOTE_STATE',
            message: JSON.stringify({
              quoteState: this.quoteState,
            }),
          });

          this.retrieveSmartHomeContent()
            .pipe(take(1))
            .subscribe((response: SmartHomeExperience[]) => {
              this.smartHomeExperiencesContent = response;
              this._loggingService.logToSplunk({
                event: 'SMARTHOME_PO_RETRIEVED_CONTENT',
                message: JSON.stringify({
                  content: this.smartHomeExperiencesContent,
                }),
              });
              this.experience = this.getSmartHomeExperience();
              this._loggingService.logToSplunk({
                event: 'SMARTHOME_PO_FOUND_EXPERIENCE',
                message: JSON.stringify({
                  experienceName: this.experience?.experienceName,
                }),
              });
              this.smartHomeExperience.emit(this.experience?.experienceName);
              this.sendExperienceToCelebrus();
              this.ngOnChanges();
            });
        });
    }
  }

  ngOnChanges(): void {
    if (this.smartHomeDiscount && this.getSmartHomeExperience()) {
      this.form.get('optIn').setValidators([Validators.required]);
    } else {
      this.form.get('optIn').setValidators([]);
    }
    this.form.updateValueAndValidity();
    this.refreshVisibilityFlag$.next();
  }

  get optIn() {
    return this.form.get('optIn');
  }

  retrieveSmartHomeContent(): Observable<SmartHomeExperience[]> {
    return from(
      this._contentfulService
        .getEntries('smartHomeExperiences')
        .then((response: Entry<any>[]) => {
          return response.map(experience => {
            return {
              ...experience.fields,
            } as SmartHomeExperience;
          }) as SmartHomeExperience[];
        })
    );
  }

  getSmartHomeExperience(): SmartHomeExperience | undefined {
    let experience;
    const matchedExperiences = this.smartHomeExperiencesContent?.filter(e =>
      e.states.includes(this.quoteState)
    );
    const matchedCondoExperiences = this.smartHomeExperiencesContent
      ?.filter(e => e.states.includes(this.quoteState))
      .filter(e => e.experienceName.includes(SHE_ACondo));

    if (matchedExperiences?.length > 0) {
      if (this.isCondo) {
        experience = matchedCondoExperiences[0];
      } else {
        experience = matchedExperiences[0];
      }
    }

    return experience;
  }

  sendExperienceToCelebrus(): void {
    if (
      Bootstrapper?.Celebrus &&
      Bootstrapper?.Celebrus?.sendCelebrusCustomAttribute
    ) {
      const key = 'Digex_534';
      const value = this.experience?.experienceName;

      Bootstrapper.Celebrus.sendCelebrusCustomAttribute(key, value);

      this._loggingService.logToSplunk({
        event: 'SMARTHOME_PO_EXPERIENCE_LOGGED',
        message: JSON.stringify({
          key: key,
          value: value,
        }),
      });
    } else {
      this._loggingService.logToSplunk({
        event: 'SMARTHOME_PO_BOOTSTRAPPERCELEBRUS_UNDEFINED_SMARTHOME',
      });
    }
  }
}
