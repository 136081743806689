export class MockCoverageService {
  getCoverages() {}

  getCoveragesFor() {}

  loadAllCoverages() {}

  updateCoverage() {}

  updateCoverageFail() {}

  updateCoverageChanges() {}

  getCoverageChanges() {}

  isLifeCoverageProductSelected() {}
  isAutoCoverageProductSelected() {}

  getExtraUpdatedCoverage() {}
  updateSelectedProductCoverage() {}

  updateSelectedCoverageProducts() {}
  getSelectedProductCoverage() {}
  setDwellingConsentChecked() {}
  getDwellingCoverageConsent() {}
}
