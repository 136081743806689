import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeownerPropertyInfoDao } from '@core/dao/ho-propertyInfo.dao';
import { HomeownerPropertyInfoMetadataAdapter } from '@core/adapters/ho-propertyInfo-metadata.adapter';

@Injectable({
  providedIn: 'root',
})
export class HomeownerPropertyInfoMetadataService {
  constructor(
    private _homeownerInfoDao: HomeownerPropertyInfoDao,
    private _homeownerInfoAdapter: HomeownerPropertyInfoMetadataAdapter
  ) {}

  addPickList(pickList) {
    this._homeownerInfoDao.addPickList(pickList);
  }

  addCondoPickList(pickList) {
    this._homeownerInfoDao.addCondoPickList(pickList);
  }

  addRentersPickList(pickList) {
    this._homeownerInfoDao.addRentersPickList(pickList);
  }

  getPickList() {
    return this._homeownerInfoDao.getPickList();
  }

  getPropertyInfoPicklistData(): Observable<any> {
    return this._homeownerInfoAdapter.getPropertyInfoHomeOwnerPicklist();
  }

  getPropertyInfoCondoPicklistData(): Observable<any> {
    return this._homeownerInfoAdapter.getPropertyInfoCondoPicklist();
  }

  getPropertyInfoRentersPicklistData(): Observable<any> {
    return this._homeownerInfoAdapter.getPropertyInfoRentersPicklist();
  }
}
