import { AgencyModel } from '@core/models/agency/agency.model';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { StringUtils } from '@shared/utils/string.utils';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
declare type Params = {
  [key: string]: any;
};

@Injectable()
export class EuaHelper {
  NONCE_LENGTH = 32;
  IA_AUTH_TYPE = 'ping-racf-ia';
  DEFAULT_AUTH_TYPE = 'ping';
  IA_IDENTITY_METHOD = 'ac';
  DEFAULT_IDENTITY_METHOD = 'nwie';
  IA_REALM = 'distribution-partner';
  EMPLOYEE_REALM = 'employee';

  constructor(private _appConfig: AppConfigService) {}

  buildEUAUrlForAgencyRoute(params: Params, route = null) {
    const EUA_URL = this._appConfig.config.euaUrl,
      CLIENT_ID = this._appConfig.config.apiKey,
      SCOPE = 'openid pci',
      RESPONSE_TYPE = 'token+id_token',
      redirect_uri =
        window.location.protocol + '//' + window.location.host + '/',
      message_id = StringUtils.generateUUID(),
      NONCE = this.nonce(this.NONCE_LENGTH),
      auth_method = this.getAuthMethodByChannel(params.agencyChannel),
      identity_method = this.getIdentityMethodByChannel(params.agencyChannel),
      realm = this.getRealmByChannel(params.agencyChannel);

    let queryString = this._paramsToQueryString(params);

    if (route) {
      queryString += `&route=${route}`;
    }

    queryString = encodeURIComponent(queryString);

    const query = {
      client_id: CLIENT_ID,
      redirect_uri: redirect_uri,
      state: queryString,
      response_type: RESPONSE_TYPE,
      scope: SCOPE,
      nonce: NONCE,
      auth_method: auth_method,
      realm: realm,
      message_id: message_id,
      identity_method: identity_method,
    };

    /**
     * auth_id_user_type is an indicator to EUA2 to give us back NWIE ID for agents that have them.
     * This is because "transitioned" EA's have both a RACF and a NWIE ID, but their RACF will not be configured in PC until 2021.
     * So we want to authenticate through Agent Center using NWIE or RACF, but then have the id_token that PolicyCenter uses use
     * the NWIE ID.
     */
    if (params.agencyChannel === 'EA') {
      query['auth_id_user_type'] = 'nwie';
    }

    const pairs = [];
    for (const key of Object.keys(query)) {
      pairs.push(`${key}=${query[key]}`);
    }

    const qs = pairs.join('&');
    const euaUrl = EUA_URL + '?' + qs;
    return euaUrl;
  }

  getEUAUrl(
    agentModel: AgencyModel,
    route: string,
    effectiveDate: string,
    targetEnv: string
  ): string {
    route = route || '';
    effectiveDate = effectiveDate || '';
    targetEnv = targetEnv || '';
    const EUA_URL = this._appConfig.config.euaUrl,
      CLIENT_ID = this._appConfig.config.apiKey,
      SCOPE = 'openid pci',
      RESPONSE_TYPE = 'token+id_token',
      redirect_uri =
        window.location.protocol + '//' + window.location.host + '/',
      message_id = StringUtils.generateUUID(),
      NONCE = this.nonce(this.NONCE_LENGTH),
      auth_method = this.getAuthMethod(agentModel),
      identity_method = this.getIdentityMethod(agentModel),
      realm = this.getRealm(agentModel);

    let state =
      'producerCode=' +
      agentModel.producerCode +
      '&agencyCode=' +
      agentModel.agencyCode +
      '&agencyChannel=' +
      agentModel.agencyChannel +
      '&firstName=' +
      agentModel.firstName +
      '&lastName=' +
      agentModel.lastName +
      '&email=' +
      agentModel.email +
      '&userId=' +
      agentModel.userId +
      '&agentState=' +
      agentModel.state;
    if (route) {
      state += '&route=' + route;
    }
    if (effectiveDate) {
      state += '&effectiveDate=' + effectiveDate;
    }
    if (targetEnv) {
      state += '&targetEnv=' + targetEnv;
    }
    if (this._appConfig.config.kubernetesEndpoint) {
      state +=
        '&kubernetesEndpoint=' + this._appConfig.config.kubernetesEndpoint;
    }
    state = encodeURIComponent(state);
    const query = {
      client_id: CLIENT_ID,
      redirect_uri: redirect_uri,
      state: state,
      response_type: RESPONSE_TYPE,
      scope: SCOPE,
      nonce: NONCE,
      auth_method: auth_method,
      realm: realm,
      message_id: message_id,
      identity_method: identity_method,
    };

    /**
     * auth_id_user_type is an indicator to EUA2 to give us back NWIE ID for agents that have them.
     * This is because "transitioned" EA's have both a RACF and a NWIE ID, but their RACF will not be configured in PC until 2021.
     * So we want to authenticate through Agent Center using NWIE or RACF, but then have the id_token that PolicyCenter uses use
     * the NWIE ID.
     */
    if (agentModel.agencyChannel === 'EA') {
      query['auth_id_user_type'] = 'nwie';
    }
    const pairs = [];
    for (const key of Object.keys(query)) {
      pairs.push(`${key}=${query[key]}`);
    }

    const qs = pairs.join('&');
    const euaUrl = EUA_URL + '?' + qs;
    return euaUrl;
  }

  nonce(length: number) {
    let nonce = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      nonce += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return nonce;
  }

  getAuthMethod(agentModel: AgencyModel): string {
    if (
      agentModel.agencyChannel === 'IA' ||
      agentModel.agencyChannel === 'EA'
    ) {
      return this.IA_AUTH_TYPE;
    } else {
      return this.DEFAULT_AUTH_TYPE;
    }
  }

  getAuthMethodByChannel(channel: string) {
    if (channel === 'IA' || channel === 'EA') {
      return this.IA_AUTH_TYPE;
    } else {
      return this.DEFAULT_AUTH_TYPE;
    }
  }

  getIdentityMethod(agentModel: AgencyModel): string {
    if (
      agentModel.agencyChannel === 'IA' ||
      agentModel.agencyChannel === 'EA'
    ) {
      return this.IA_IDENTITY_METHOD;
    } else {
      return this.DEFAULT_IDENTITY_METHOD;
    }
  }

  getIdentityMethodByChannel(channel: string) {
    if (channel === 'IA' || channel === 'EA') {
      return this.IA_IDENTITY_METHOD;
    } else {
      return this.DEFAULT_IDENTITY_METHOD;
    }
  }

  getRealm(agentModel: AgencyModel): string {
    if (
      agentModel.agencyChannel === 'IA' ||
      agentModel.agencyChannel === 'EA'
    ) {
      return this.IA_REALM;
    } else {
      return this.EMPLOYEE_REALM;
    }
  }

  getRealmByChannel(channel: string) {
    if (channel === 'IA' || channel === 'EA') {
      return this.IA_REALM;
    } else {
      return this.EMPLOYEE_REALM;
    }
  }

  private _paramsToQueryString(params: Params) {
    return Object.keys(params).reduce((prev, current) => {
      if (prev === '') {
        return current + '=' + params[current];
      } else {
        return prev + '&' + current + '=' + params[current];
      }
    }, '');
  }

  getEUAUrlForCompRater(deepLaunchId: string): string {
    const EUA_URL = this._appConfig.config.euaUrl,
      CLIENT_ID = this._appConfig.config.apiKey,
      SCOPE = 'openid pci',
      RESPONSE_TYPE = 'token+id_token',
      REDIRECT_URI = `${window.document.location.protocol}//${window.document.location.host}/retrieve/compRater`,
      AUTH_METHOD = 'ping-racf-ia',
      message_id = StringUtils.generateUUID(),
      NONCE = this.nonce(this.NONCE_LENGTH),
      REALM = 'distribution-partner',
      IDENTITY_METHOD = 'ac';
    let state = 'DeepLaunchId=' + deepLaunchId;
    state = encodeURIComponent(state);
    const query = {
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      state: state,
      response_type: RESPONSE_TYPE,
      scope: SCOPE,
      nonce: NONCE,
      auth_method: AUTH_METHOD,
      realm: REALM,
      message_id: message_id,
      identity_method: IDENTITY_METHOD,
      auth_id_user_type: 'nwie',
    };
    const pairs = [];
    Object.keys(query).forEach(key => pairs.push(`${key}=${query[key]}`));

    const qs = pairs.join('&');
    const euaUrl = EUA_URL + '?' + qs;
    return euaUrl;
  }
}
