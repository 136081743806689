import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RequiredIfTrueDirective } from './required-if-true.direcive';

@NgModule({
  imports: [CommonModule],
  declarations: [RequiredIfTrueDirective],
  exports: [RequiredIfTrueDirective],
})
export class RequiredIfTrueModule {}
