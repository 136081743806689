import { CurrencyPipe } from '@angular/common';
import {
  CoverageEntity,
  CoverageTerm,
  SelectedValue,
} from '@core/models/entities/coverage.entity';
import { DiscountEntity } from '@core/models/entities/discount.entity';
import { CoverageHelper } from '@core/services/helpers/coverage.helper';
import * as fromFeature from '@core/store/reducers';
import * as fromQuoteLetter from '@core/store/reducers/quote-letter.reducer';
import * as fromAgency from '@core/store/selectors/agency.selector';
import * as fromCoverage from '@core/store/selectors/coverage.selector';
import * as fromDiscount from '@core/store/selectors/discount.selector';
import * as fromMetaData from '@core/store/selectors/metadata.selector';
import * as fromPolicyholder from '@core/store/selectors/policyholder.selector';
import * as fromPowersportsVehicle from '@core/store/selectors/powersports-vehicle.selector';
import * as fromPremium from '@core/store/selectors/premium.selector';
import * as fromPrivateLabel from '@core/store/selectors/private-label.selector';
import * as fromDsmProducts from '@core/store/selectors/products.selector';
import * as fromRetrieve from '@core/store/selectors/retrieve.selector';
import * as fromTermLife from '@core/store/selectors/termlife.selector';
import * as fromVehicle from '@core/store/selectors/vehicle.selector';
import { createSelector } from '@ngrx/store';
import {
  CoverageDisplayGrouping,
  ProductTypes,
  DiscountDescriptions,
} from '@shared/constants/app-constants';
import { CoverageUtils } from '@shared/utils/coverage.utils';
import { DiscountNameHelper } from '@shared/utils/discount-name.helper';
import * as xml2js from 'xml2js';
import { QuoteLetterDetails } from '@core/models/quote-letter/quote-letter-details.model';

export const getQuoteLetterState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.quoteLetter
);

export const getQuoteLetter = createSelector(
  getQuoteLetterState,
  fromQuoteLetter.getQuoteLetter
);

export const getQuoteLetterLoaded = createSelector(
  getQuoteLetterState,
  fromQuoteLetter.getQuoteLetterLoaded
);

export const getStaticData = createSelector(
  fromMetaData.getStateSpecificFlagsObject,
  fromPrivateLabel.getContactNumber(),
  fromAgency.getAgency,
  fromPowersportsVehicle.getAllPowersportsVehicles,
  fromTermLife.selectAllTermLifePeople,
  (
    stateSpecificFlags,
    privateLabelContactNumber,
    agency,
    powersportsVehicles,
    termLifePeople
  ) => ({
    stateSpecificFlags,
    privateLabelContactNumber,
    agency,
    powersportsVehicles,
    termLifePeople,
  })
);

export const buildQuoteLetterRequest = (
  quoteLetterDetails: QuoteLetterDetails,
  currencyPipe: CurrencyPipe,
  quoteState: string,
  coverageHelper: CoverageHelper,
  discountNameHelper: DiscountNameHelper
) =>
  createSelector(
    fromDsmProducts.getSelectedProductsWithoutErrors,
    fromPolicyholder.getPrimaryNamedInsured,
    fromRetrieve.isCompRater,
    fromVehicle.getAllVehicles,
    fromCoverage.selectAllCoverages,
    fromPremium.selectAllPremiums,
    getStaticData,
    fromDiscount.getSelectedDiscounts,
    (
      selectedProducts,
      policyHolder,
      isCompRater,
      vehicles,
      coverages,
      premiums,
      staticData,
      discounts
    ) => {
      const xmlObject = {
        DocumentContent: {
          FirstName: policyHolder.person.firstName,
          LastName: policyHolder.person.lastName,
          contactInfo: 'Nationwide at 1-855-550-0768',
          AgentAttached: 'N',
          AgentName: null,
          AgencyName: null,
          AgencyAddress: null,
          AgencyEmail: null,
          AgencyWebsite: null,
          AgencyLogo: null,
          AgencyLogoFormat: null,
          Products: {
            Product: [],
          },
        },
      };

      if (staticData.agency) {
        xmlObject.DocumentContent.AgentAttached = 'Y';

        if (staticData.agency.firstName && staticData.agency.lastName) {
          xmlObject.DocumentContent.AgentName =
            staticData.agency.firstName + ' ' + staticData.agency.lastName;
        }

        if (!staticData.agency.isPrivateLabel && staticData.agency.email) {
          xmlObject.DocumentContent.AgencyEmail = staticData.agency.email;
        }
        if (['EA', 'IA'].includes(staticData.agency.agencyChannel)) {
          xmlObject.DocumentContent.contactInfo = 'your agent';
        }
      }

      if (!staticData.privateLabelContactNumber.includes('1-855')) {
        xmlObject.DocumentContent.contactInfo =
          'your agent at ' + staticData.privateLabelContactNumber;
        xmlObject.DocumentContent.AgencyLogo = quoteLetterDetails.base64Logo;
        xmlObject.DocumentContent.AgencyLogoFormat =
          quoteLetterDetails.logoFormat;
      }
      const selectedProductsWithoutErrors = selectedProducts.filter(product => {
        return staticData.termLifePeople.length === 0 && product
          ? !product.hasError && product.id !== ProductTypes.TERMLIFE
          : !product.hasError;
      });
      selectedProductsWithoutErrors.forEach(selectedProduct => {
        const premium = premiums.find(
          prem => prem.productId === selectedProduct.id
        );
        const isPaidInFullDiscountApplied = getAppliedDiscount(
          discounts,
          DiscountDescriptions.PaidInFullDiscount
        );
        if (premium) {
          const paymentTerm: string =
            selectedProduct.id === ProductTypes.AUTO &&
            isPaidInFullDiscountApplied
              ? 'F'
              : 'M';
          const paymentTermAmount: number =
            paymentTerm === 'F'
              ? premium?.total?.amount
              : premium?.total?.amount / premium?.termMonths;
          const product = {
            $: { type: selectedProduct.name },
            Premium: currencyPipe.transform(
              paymentTermAmount,
              'USD',
              'symbol',
              '1.2-2'
            ),
            Term: paymentTerm,
            QuoteId: selectedProduct.quoteId,
            Discounts: {
              Discount: [],
            },
            Coverages: {
              Coverage: [],
            },
            Vehicles: {
              Vehicle: [],
            },
          };
          let coverageGroups = [];
          if (
            selectedProduct.id === ProductTypes.AUTO ||
            selectedProduct.id === ProductTypes.POWERSPORTS
          ) {
            coverageGroups = [
              CoverageDisplayGrouping.Policy,
              CoverageDisplayGrouping.AdditionalPolicy,
              CoverageDisplayGrouping.OtherCoverages,
            ];
          } else if (
            selectedProduct.id === ProductTypes.HOMEOWNERS ||
            selectedProduct.id === ProductTypes.CONDO ||
            selectedProduct.id === ProductTypes.RENTERS
          ) {
            coverageGroups = [
              CoverageDisplayGrouping.PropertyLiability,
              CoverageDisplayGrouping.AdditionalProtection,
              CoverageDisplayGrouping.SpecialLimitsOfLiability,
            ];
          } else if (selectedProduct.id === ProductTypes.UMBRELLA) {
            coverageGroups = [CoverageDisplayGrouping.Policy];
          }

          const qlDisconts = buildQuoteLetterDiscounts(
            discounts,
            selectedProduct.id,
            discountNameHelper
          );
          qlDisconts.forEach(qlDiscount =>
            product.Discounts.Discount.push(qlDiscount)
          );
          let newVehicles = vehicles;
          if (staticData.powersportsVehicles?.length > 0) {
            newVehicles = newVehicles.concat(staticData.powersportsVehicles);
          }
          const updatedCoverages = coverageHelper.runCoverageRules(
            coverages,
            newVehicles,
            quoteState,
            isCompRater,
            staticData.stateSpecificFlags
          );
          const covEntities = extractCoverages(
            updatedCoverages,
            coverageGroups,
            selectedProduct.id,
            quoteState,
            coverageHelper
          );
          const qlCoverages = buildQuoteLetterCoverages(covEntities);
          qlCoverages.forEach(qlCov => product.Coverages.Coverage.push(qlCov));

          if (selectedProduct.id === ProductTypes.AUTO) {
            const autoCoverages = updatedCoverages.filter(
              cov => cov.productId === selectedProduct.id
            );
            let count = 0;
            vehicles.forEach(veh => {
              count++;
              const vehicle = {
                $: { id: count },
                Year: veh.year,
                Make: veh.make,
                Model: veh.model,
                Coverages: {
                  Coverage: [],
                },
              };
              coverageGroups = [CoverageDisplayGrouping.Vehicle];
              const covEntities = extractVehicleCoverages(
                autoCoverages,
                coverageGroups,
                veh.vehicleId,
                quoteState,
                ProductTypes.AUTO
              );
              const qlCoverages = buildQuoteLetterCoverages(covEntities);
              qlCoverages.forEach(qlCov =>
                vehicle.Coverages.Coverage.push(qlCov)
              );
              product.Vehicles.Vehicle.push(vehicle);
            });
          } else if (selectedProduct.id === ProductTypes.POWERSPORTS) {
            const powersportsCoverages = updatedCoverages.filter(
              cov => cov.productId === selectedProduct.id
            );
            let count = 0;
            staticData.powersportsVehicles.forEach(veh => {
              count++;
              const vehicle = {
                $: { id: count },
                Year: veh.year,
                Make: veh.make,
                Model: veh.model,
                Coverages: {
                  Coverage: [],
                },
              };
              coverageGroups = [CoverageDisplayGrouping.Vehicle];
              const covEntities = extractVehicleCoverages(
                powersportsCoverages,
                coverageGroups,
                veh.vehicleId,
                quoteState,
                ProductTypes.POWERSPORTS
              );
              const qlCoverages = buildQuoteLetterCoverages(covEntities);
              qlCoverages.forEach(qlCov =>
                vehicle.Coverages.Coverage.push(qlCov)
              );
              product.Vehicles.Vehicle.push(vehicle);
            });
          } else {
            delete product.Vehicles;
          }

          xmlObject.DocumentContent.Products.Product.push(product);
        }
      });

      const builder = new xml2js.Builder();
      const documentContentXml = builder.buildObject(xmlObject);

      const base64EncodedXml = btoa(documentContentXml);

      const quoteLetterRequestBody = {
        pubName: 'SalesQuotes.pub',
        referenceArea: '',
        fileName: 'INPUT',
        driverFile: base64EncodedXml,
      };

      const quoteLetterRequest = {
        accessToken: quoteLetterDetails.accessToken,
        body: quoteLetterRequestBody,
      };

      return quoteLetterRequest;
    }
  );
function getAppliedDiscount(
  discounts: DiscountEntity[],
  discountDescription: string
): boolean {
  return discounts.some(discount => {
    return discount.description === discountDescription &&
      discount.isDiscountApplied === true
      ? true
      : false;
  });
}
function extractCoverages(
  coverages: CoverageEntity[],
  coverageGroups: string[],
  productId: string,
  quoteState: string,
  coverageHelper: CoverageHelper
) {
  let quoteLetterCoverages: CoverageEntity[] = [];
  const productCoverages = coverages.filter(
    cov => cov.productId === productId && cov.available
  );
  coverageGroups.forEach(group => {
    const productGroupCoverages = productCoverages.filter(coverage =>
      CoverageUtils.filterCoverageByGrouping(
        coverage,
        group,
        quoteState,
        productId
      )
    );
    productGroupCoverages.sort(
      CoverageUtils.coverageSorter(quoteState, productId)
    );
    quoteLetterCoverages = quoteLetterCoverages.concat(productGroupCoverages);
  });
  quoteLetterCoverages =
    coverageHelper.mergeSpreadableCoverages(quoteLetterCoverages);
  return quoteLetterCoverages;
}

function extractVehicleCoverages(
  coverages: CoverageEntity[],
  coverageGroups: string[],
  vehicleId: string,
  quoteState: string,
  productId: string
) {
  let quoteLetterCoverages: CoverageEntity[] = [];
  coverageGroups.forEach(group => {
    const productGroupCoverages = coverages.filter(
      coverage =>
        coverage.coverableId === vehicleId &&
        CoverageUtils.filterCoverageByGrouping(
          coverage,
          group,
          quoteState,
          productId
        )
    );
    productGroupCoverages.sort(
      CoverageUtils.coverageSorter(quoteState, productId)
    );
    quoteLetterCoverages = quoteLetterCoverages.concat(productGroupCoverages);
  });

  return quoteLetterCoverages;
}

function buildQuoteLetterDiscounts(
  discounts: DiscountEntity[],
  productId: string,
  discountNameHelper: DiscountNameHelper
) {
  const quoteLetterDiscounts = [];
  const selectedProductDiscounts = discounts.filter(
    discount => discount.productId === productId
  );
  for (const discount of selectedProductDiscounts) {
    quoteLetterDiscounts.push(
      discountNameHelper.helpDiscountName(discount).description
    );
  }
  return quoteLetterDiscounts;
}

function buildQuoteLetterCoverages(coverages: CoverageEntity[]) {
  const quoteLetterCoverages = [];
  coverages.forEach(cov => {
    let limitOrDed: string;
    if (!cov.available) {
      limitOrDed = 'Not Available';
    } else {
      if (!cov.terms) {
        limitOrDed = getLimitOrDedWithoutCoverageTerms(cov);
      } else if (cov.terms) {
        limitOrDed = getLimitOrDedWithCoverageTerms(
          cov.terms[0],
          cov.selectedValue[0]
        );
      }
    }

    if (limitOrDed) {
      const coverage = {
        Name: cov.name,
        LimitOrDed: limitOrDed,
      };
      quoteLetterCoverages.push(coverage);

      if (
        cov.terms &&
        cov.terms.length > 1 &&
        !(
          cov.selectedValue &&
          cov.selectedValue.find(
            selectedValue => selectedValue.code === 'selected'
          ) &&
          cov.selectedValue.find(
            selectedValue => selectedValue.code === 'selected'
          ).value === 'false'
        )
      ) {
        for (const [index, term] of cov.terms.entries()) {
          if (index > 0) {
            const selectedValue = cov.selectedValue
              ? cov.selectedValue[index]
              : null;
            const coverage = {
              Name: term.type,
              LimitOrDed: getLimitOrDedWithCoverageTerms(term, selectedValue),
            };
            quoteLetterCoverages.push(coverage);
          }
        }
      }
    }
  });
  return quoteLetterCoverages;
}

function getLimitOrDedWithoutCoverageTerms(cov: CoverageEntity) {
  let limitOrDed: string;
  if (cov.editable && !cov.mandatory) {
    if (cov.selectedValue) {
      if (cov.selectedValue[0].value === 'true') {
        limitOrDed = 'Accept';
      } else {
        limitOrDed = 'Decline';
      }
    }
  } else if (!!cov.mandatory) {
    limitOrDed = 'Included';
  }
  return limitOrDed;
}

function getLimitOrDedWithCoverageTerms(
  coverageTerm: CoverageTerm,
  coverageSelectedValue: SelectedValue
) {
  let limitOrDed: string;
  if (coverageTerm.options && coverageSelectedValue) {
    const selectedCoverageOption = coverageTerm.options.find(
      option => option.value === coverageSelectedValue.value
    );
    if (selectedCoverageOption) {
      limitOrDed = selectedCoverageOption.description;
    } else {
      if (coverageSelectedValue.value === 'true') {
        limitOrDed = 'Accept';
      } else {
        limitOrDed = 'Decline';
      }
    }
  } else if (!coverageTerm.options && coverageSelectedValue) {
    limitOrDed =
      coverageSelectedValue.description || coverageSelectedValue.value;
  }
  return limitOrDed;
}
