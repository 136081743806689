import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { Update } from '@ngrx/entity';
import { PersonViewModel } from '@core/models/entities/driver.entity';

@Injectable({
  providedIn: 'root',
})
export class DriverVehiclePrefillDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getCustomerData(): void {
    this._store.dispatch(new fromActions.GetCustomerData());
  }

  getCustomerPowersportsData(): void {
    this._store.dispatch(new fromActions.GetCustomerPowersportsData());
  }

  getDriverVehiclePrefillLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getDriverVehiclePrefillLoaded);
  }

  getPowersportsDriverVehiclePrefillLoaded(): Observable<boolean> {
    return this._store.select(
      fromSelectors.getPowersportsDriverVehiclePrefillLoaded
    );
  }

  getAllPrefillPeople(): Observable<PersonViewModel[]> {
    return this._store.select(fromSelectors.getAllPrefillPeople);
  }

  getAllPrefillVehicles(): Observable<VehicleEntity[]> {
    return this._store.select(fromSelectors.getAllPrefillVehicles);
  }

  getAllPowersportsPrefillPeople(): Observable<PersonViewModel[]> {
    return this._store.select(fromSelectors.getAllPowersportsPrefillPeople);
  }

  getAllPowersportsPrefillVehicles(): Observable<VehicleEntity[]> {
    return this._store.select(fromSelectors.getAllPowersportsPrefillVehicles);
  }

  updatePrefillPersonSelected(prefillId: string, selected: boolean): void {
    const updated: Update<PersonViewModel> = {
      changes: { selected },
      id: prefillId,
    };
    this._store.dispatch(new fromActions.UpdatePrefillPersonSelected(updated));
  }

  updatePrefillVehicleSelected(prefillId: string, selected: boolean): void {
    const updated: Update<VehicleEntity> = {
      changes: { selected },
      id: prefillId,
    };
    this._store.dispatch(new fromActions.UpdatePrefillVehicleSelected(updated));
  }

  updatePowersportsPrefillPersonSelected(
    prefillId: string,
    selected: boolean
  ): void {
    const updated: Update<PersonViewModel> = {
      changes: { selected },
      id: prefillId,
    };
    this._store.dispatch(
      new fromActions.UpdatePrefillPowersportsPersonSelected(updated)
    );
  }

  updatePowersportsPrefillVehicleSelected(
    prefillId: string,
    selected: boolean
  ): void {
    const updated: Update<VehicleEntity> = {
      changes: { selected },
      id: prefillId,
    };
    this._store.dispatch(
      new fromActions.UpdatePrefillPowersportsVehicleSelected(updated)
    );
  }
}
