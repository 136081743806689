import * as fromUserContext from '@core/store/actions/user-context.action';

import { UserContext } from '@core/models/user-context/user-context.model';

export interface UserContextState {
  userContext: UserContext;
  loaded: boolean;
  loading: boolean;
}

export const initialState: UserContextState = {
  userContext: null,
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromUserContext.UserContextActions
): UserContextState {
  switch (action.type) {
    case fromUserContext.LOAD_USER_CONTEXT: {
      return { ...state, loading: true };
    }

    case fromUserContext.LOAD_USER_CONTEXT_FAIL: {
      return { ...state, loading: false, loaded: false };
    }

    case fromUserContext.UPDATE_USER_CONTEXT: {
      const updatedContext = { ...state.userContext, ...action.payload };
      return {
        ...state,
        userContext: updatedContext,
        loaded: true,
        loading: false,
      };
    }
  }
  return state;
}

export const getUserContext = (state: UserContextState) => state.userContext;
export const getUserContextLoaded = (state: UserContextState) => state.loaded;
