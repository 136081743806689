import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  AfterContentInit,
  ElementRef,
  QueryList,
  ViewChildren,
  ViewChild,
  AfterViewInit,
  OnInit,
  OnChanges,
  SimpleChanges,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { style, animate, query, AnimationBuilder } from '@angular/animations';
import * as _ from 'lodash';
import { Product } from '@core/models/products/product.model';
import { ProductComponent } from '@shared/components/products/product/product.component';
import { ProductDisplay } from '@core/models/display/quote-coverages/products/product-display.model';
import { PrivateLabel } from '@core/models/private-label/private-label.model';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { CustomUIElements } from '@shared/constants/private-label-constants';
import { Animations } from '@shared/animations/animations';
import {
  DESKTOP_VIEW,
  INTERNAL_LINKS,
  ProductTypes,
} from '@shared/constants/app-constants';
import { StringUtils } from '@shared/utils/string.utils';
import { ProductUtilService } from '@core/services/product-util.service';

const PRODUCT_SELECTION_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => ProductsListComponent),
  multi: true,
};

@Component({
  selector: 'mq-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PRODUCT_SELECTION_ACCESSOR],
  animations: [Animations.slideUp],
})
export class ProductsListComponent
  implements
    ControlValueAccessor,
    OnInit,
    OnChanges,
    AfterContentInit,
    AfterViewInit
{
  @Input() products: Product[];
  @Input() addons: Product[] = [];
  @Input() selectedProductsWithoutErrors: ProductDisplay[];
  @Input() coverageProduct: Product;
  @Input() gettingStarted: boolean;
  @Input() privateLabelProducts: string[];
  @Input() quoteState: string;
  @Input() costEstimate: number;
  @Input() totalCostEstimate: number;
  @Input() disabled: boolean;
  @Input() appLoading: boolean;
  @Input() isBindCoverages: boolean;
  @Input() isLifeCoverageRequired: boolean;
  @Input() isNoPlayStateAnyProductSelectedWithOutTermLife: boolean;
  @Input() isNoPlayStateAnyProductSelectedWithOutPet: boolean;
  @Input() set isCAorNYStatePaused(value: boolean) {
    this.isStatePaused = value;
  }
  @Input() isNoPlayStateTermLifeRestricted: boolean;
  @Input() isNoPlayStatePetRestricted: boolean;
  @Input() isPetUnavailableState: boolean;
  @Input() hasSentPetEmail: boolean;
  @Input() isFLStatePaused: boolean;
  private isPrivateLabel = false;
  _config: PrivateLabel;
  @Input() set privateLabelConfig(config: PrivateLabel) {
    this._config = config;
    const configVal = PrivateLabelContentHelper.getConfigValue(
      config,
      CustomUIElements.COVERAGE_PAGE_DIVIDER_COLOR
    );
    if (configVal) {
      this.defaultArrowColor = configVal;
    }
    this.isPrivateLabel = !!config;
  }

  get privateLabelConfig(): PrivateLabel {
    return this._config;
  }

  @Output() selectedProductCoverages = new EventEmitter<Product>();
  @Output() productSelected = new EventEmitter<{
    product: Product;
    products: Product[];
  }>();
  @Output() addonSelected = new EventEmitter<{
    product: Product;
    products: Product[];
  }>();
  @Output() direction = new EventEmitter<string>();

  @ViewChild('arrowUp') arrowUp: ElementRef;
  @ViewChild('productContainer')
  productContainer: ElementRef;
  isNoPlayStatePowersportsOnlySelected = false;
  isStatePaused = false;
  @ViewChildren(ProductComponent) productsList: QueryList<ProductComponent>;

  bigPos = 0;
  smallPos = 84;

  state = 'hidden';
  value: Product[] = [];
  isIEOrEdge: boolean;
  isMobile = window.innerWidth < DESKTOP_VIEW;
  defaultArrowColor = '#336093';
  theEstimate: number;
  hyperlinkedText = INTERNAL_LINKS.autoQuoteHelpText;
  url = INTERNAL_LINKS.autoQuoteHelpURL;
  hideForFirstTime: boolean;

  private onTouch: any;
  private onModelChange: any;

  showAutoProduct(): boolean {
    return !!this.products.find(product => product.id === ProductTypes.AUTO);
  }

  showPropertyProduct(): boolean {
    if (this.products.find(product => product.id === ProductTypes.HOMEOWNERS)) {
      return true;
    } else if (
      this.products.find(product => product.id === ProductTypes.RENTERS)
    ) {
      return true;
    } else if (
      this.products.find(product => product.id === ProductTypes.CONDO)
    ) {
      return true;
    } else {
      return false;
    }
  }

  showLifeProduct(): boolean {
    return !!this.products.find(
      product => product.id === ProductTypes.TERMLIFE
    );
  }

  showPowersportsProduct(): boolean {
    return !!this.products.find(
      product => product.id === ProductTypes.POWERSPORTS
    );
  }

  showPetProduct(): boolean {
    return (
      !!this.products.find(product => product.id === ProductTypes.PET) &&
      !this.hasSentPetEmail
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    if (this.productContainer) {
      this.calculateFlag();
      this.animateFlag(0);
    }
    this.isMobile = window.innerWidth < DESKTOP_VIEW;
  }

  constructor(
    private _builder: AnimationBuilder,
    private _elem: ElementRef,
    private _changeDetector: ChangeDetectorRef,
    private productUtilService: ProductUtilService
  ) {}

  ngOnInit(): void {
    this.productUtilService.loaded.subscribe((status: boolean) => {
      this.hideForFirstTime = status && this.isPrivateLabel;
    });
    this.productUtilService.selectNJ$.subscribe((state: string) => {
      this.isNoPlayStatePowersportsOnlySelected =
        state === 'NJ' && this.isPrivateLabel ? true : false;
    });
    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );
    this.checkSelectedProductsWithoutErrors();
    this.filterLifeFromProducts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.privateLabelProducts &&
      this.privateLabelProducts.length < this.products.length
    ) {
      this.resetAllSelectedProducts();
      this.isNoPlayStatePowersportsOnlySelected =
        this.privateLabelProducts?.indexOf('Powersports') < 0;
    }
    this.checkSelectedProductsWithoutErrors();

    if (this.productContainer && this.products) {
      const LOAD_IN_TIME_MS = 300;
      const loadInTime = this.appLoading ? 0 : LOAD_IN_TIME_MS;
      this.calculateFlag();
      this.animateFlag(loadInTime);
    }

    this.filterLifeFromProducts();
  }

  ngAfterContentInit(): void {
    this.state = 'visible';
    if (!this.products) {
      return;
    }
  }

  ngAfterViewInit(): void {
    if (this.productContainer && this.products) {
      this.calculateFlag();
      this.animateFlag(0);
    }
    if (
      this.gettingStarted &&
      this.productsList &&
      this.productsList.length === 1
    ) {
      this.productsList.first.selectProduct(true);
    }
    if (
      this.selectedProductsWithoutErrors &&
      this.productsList &&
      this.productsList.length === 1
    ) {
      this.productsList.first.selectProduct(true);
    }
  }

  registerOnChange(fn): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouch = fn;
  }

  writeValue(value: Product[]): void {
    this.value = value;
  }

  checkSelectedProductsWithoutErrors(): void {
    if (
      this.selectedProductsWithoutErrors &&
      this.selectedProductsWithoutErrors.length
    ) {
      this.value = this.value.filter(val =>
        this.selectedProductsWithoutErrors.some(
          product => product.id === val.id
        )
      );
    }
  }

  selectProduct(event: any): void {
    const { product, toggle } = event;

    if (this.isProductSelected(product)) {
      this.value = this.value.filter(item => item.id !== product.id);
      this.checkConditionalProducts(product, ProductTypes.UMBRELLA);
    } else {
      this.value = [...this.value, product];
    }

    if (toggle) {
      this._swapLinkedProducts(
        product.name,
        'Homeowners',
        'Renters',
        'Condo',
        'Auto',
        'Powersports'
      );
    }

    this.productSelected.emit({ product: product, products: this.value });

    this.onTouch();
    this.onModelChange(this.value);
  }

  selectAddon(event: any): void {
    const { product } = event;

    if (this.isProductSelected(product)) {
      this.value = this.value.filter(item => item.id !== product.id);
    } else {
      this.value = [...this.value, product];
    }

    this.addonSelected.emit({ product: product, products: this.value });

    this.onTouch();
    this.onModelChange(this.value);
  }

  updateTermLifeMonthlyPremium(costEstimate: number): void {
    if (this.productsList) {
      const lifeProduct = this.productsList.find(
        productComponent =>
          productComponent.product.id === ProductTypes.TERMLIFE
      );
      if (lifeProduct) {
        lifeProduct.updateTermLifeMonthlyPremium(costEstimate);
      }
    }
    this._changeDetector.detectChanges();
  }

  unselectProductUi(productId: string): void {
    this.value = this.value.filter(item => item.id !== productId);
    this.onTouch();
    this.onModelChange(this.value);
    this._changeDetector.detectChanges();
  }

  isProductSelected(product: Product): boolean {
    return this.value.some(
      val => val.id === product.id && !product['hasError']
    );
  }

  selectProductCoverages(product: Product): void {
    if (this.coverageProduct) {
      const loadInTime = 300,
        smallProductWidth = 79;
      const productIndex = this.findProductIndex(product);
      const selectedCoverageProductIndex = this.findProductIndex(
        this.coverageProduct
      );
      const diff = Math.abs(selectedCoverageProductIndex - productIndex);
      const productWidth =
        this.productContainer.nativeElement.offsetWidth /
        (this.products.length + this.addons.length);
      if (this.coverageProduct.id > product.id) {
        this.direction.emit('left');
        this.bigPos = this.bigPos - productWidth * diff;
        this.smallPos = this.smallPos - smallProductWidth * diff;
        this.animateFlag(loadInTime);
      } else if (this.coverageProduct.id < product.id) {
        this.direction.emit('right');
        this.bigPos = this.bigPos + productWidth * diff;
        this.smallPos = this.smallPos + smallProductWidth * diff;
        this.animateFlag(loadInTime);
      }
    }

    this.selectedProductCoverages.emit(product);
  }

  calculateFlag(): void {
    let coverageProductIndex = this.coverageProduct
      ? this.findProductIndex(this.coverageProduct)
      : -1;
    if (coverageProductIndex < 0) {
      coverageProductIndex = 0;
    }
    this.bigPos =
      (this.productContainer.nativeElement.offsetWidth /
        (this.products.length + this.addons.length)) *
        coverageProductIndex +
      this.productContainer.nativeElement.offsetWidth /
        (this.products.length + this.addons.length) /
        2 -
      10;
  }

  animateFlag(delay): void {
    const animation = this._builder.build([
      query('.arrow-up', animate(delay, style({ left: `${this.bigPos}px` })), {
        optional: true,
      }),
    ]);

    const player = animation.create(this._elem.nativeElement);
    player.play();
  }

  trackByFn(index, item: ProductDisplay): number {
    return item.monthlyPremium;
  }

  isReadOnlyWithOneProduct(product: Product): boolean {
    if (this.products.length === 1) {
      return true;
    } else if (this.products.length > 1) {
      return false;
    }
  }

  showProductDisabled(product: Product): boolean {
    return this.privateLabelProducts
      ? this.privateLabelProducts.indexOf(product.name) < 0
      : false;
  }

  disableForFirstTime(product: Product): boolean {
    return this.hideForFirstTime && product.id == '7';
  }

  private _swapLinkedProducts(
    target: string,
    comp1: string,
    comp2: string,
    comp3: string,
    comp5: string,
    comp6: string
  ) {
    if (target === comp2) {
      const component = this.productsList.find(
        component => component.product.name === comp1
      );
      const condoComponent = this.productsList.find(
        component => component.product.name === comp3
      );
      const targetComponent = this.productsList.find(
        component => component.product.name === comp2
      );
      const lifeComponent = this.productsList.find(
        component => component.product.name === 'Term Life'
      );
      const rentersComponent = this.productsList.find(
        component => component.product.name === 'Renters'
      );
      const autoComponent = this.productsList.find(
        component => component.product.name === 'Auto'
      );
      const powerSportsComponent = this.productsList.find(
        component => component.product.name === 'Powersports'
      );
      const petComponent = this.productsList.find(
        component => component.product.name === 'Pet'
      );
      if (component && component.isSelected === 'true') {
        component.selectProduct(false);
      } else if (condoComponent && condoComponent.isSelected === 'true') {
        condoComponent.selectProduct(false);
      }
      if (
        targetComponent &&
        targetComponent.isSelected !== 'true' &&
        autoComponent &&
        autoComponent.isSelected !== 'true' &&
        powerSportsComponent &&
        powerSportsComponent.isSelected !== 'true' &&
        lifeComponent &&
        lifeComponent.isSelected === 'true'
      ) {
        lifeComponent.selectProduct(false);
      }
      if (
        targetComponent &&
        targetComponent.isSelected !== 'true' &&
        autoComponent &&
        autoComponent.isSelected !== 'true' &&
        powerSportsComponent &&
        powerSportsComponent.isSelected !== 'true' &&
        petComponent &&
        petComponent.isSelected === 'true'
      ) {
        petComponent.selectProduct(false);
      }
    } else if (target === comp1) {
      const component = this.productsList.find(
        component => component.product.name === comp2
      );
      const condoComponent = this.productsList.find(
        component => component.product.name === comp3
      );
      const targetComponent = this.productsList.find(
        component => component.product.name === comp1
      );
      const autoComponent = this.productsList.find(
        component => component.product.name === 'Auto'
      );
      const powerSportsComponent = this.productsList.find(
        component => component.product.name === 'Powersports'
      );
      const lifeComponent = this.productsList.find(
        component => component.product.name === 'Term Life'
      );
      const petComponent = this.productsList.find(
        component => component.product.name === 'Pet'
      );
      if (component && component.isSelected === 'true') {
        component.selectProduct(false);
      } else if (condoComponent && condoComponent.isSelected === 'true') {
        condoComponent.selectProduct(false);
      }
      if (
        targetComponent &&
        targetComponent.isSelected !== 'true' &&
        autoComponent &&
        autoComponent.isSelected !== 'true' &&
        powerSportsComponent &&
        powerSportsComponent.isSelected !== 'true' &&
        lifeComponent &&
        lifeComponent.isSelected === 'true'
      ) {
        lifeComponent.selectProduct(false);
      }
      if (
        targetComponent &&
        targetComponent.isSelected !== 'true' &&
        autoComponent &&
        autoComponent.isSelected !== 'true' &&
        powerSportsComponent &&
        powerSportsComponent.isSelected !== 'true' &&
        petComponent &&
        petComponent.isSelected === 'true'
      ) {
        petComponent.selectProduct(false);
      }
    } else if (target === comp3) {
      const component = this.productsList.find(
        component => component.product.name === comp1
      );
      const rentersComponent = this.productsList.find(
        component => component.product.name === comp2
      );
      const targetComponent = this.productsList.find(
        component => component.product.name === comp3
      );
      const autoComponent = this.productsList.find(
        component => component.product.name === 'Auto'
      );
      const powerSportsComponent = this.productsList.find(
        component => component.product.name === 'Powersports'
      );
      const lifeComponent = this.productsList.find(
        component => component.product.name === 'Term Life'
      );
      const petComponent = this.productsList.find(
        component => component.product.name === 'Pet'
      );
      if (component && component.isSelected === 'true') {
        component.selectProduct(false);
      } else if (rentersComponent && rentersComponent.isSelected === 'true') {
        rentersComponent.selectProduct(false);
      }
      if (
        targetComponent &&
        targetComponent.isSelected !== 'true' &&
        autoComponent &&
        autoComponent.isSelected !== 'true' &&
        powerSportsComponent &&
        powerSportsComponent.isSelected !== 'true' &&
        component &&
        component.isSelected !== 'true'
      ) {
        if (lifeComponent && lifeComponent.isSelected === 'true') {
          lifeComponent.selectProduct(false);
        }
        if (petComponent && petComponent.isSelected === 'true') {
          petComponent.selectProduct(false);
        }
      }
    }
    if (target === comp5) {
      const component = this.productsList.find(
        component => component.product.name === comp1
      );
      const rentersComponent = this.productsList.find(
        component => component.product.name === comp2
      );
      const lifeComponent = this.productsList.find(
        component => component.product.name === 'Term Life'
      );
      const targetComponent = this.productsList.find(
        component => component.product.name === comp5
      );
      const condoComponent = this.productsList.find(
        component => component.product.name === comp3
      );
      const powerSportsComponent = this.productsList.find(
        component => component.product.name === 'Powersports'
      );
      const petComponent = this.productsList.find(
        component => component.product.name === 'Pet'
      );
      if (
        targetComponent &&
        targetComponent.isSelected !== 'true' &&
        component &&
        component.isSelected !== 'true' &&
        rentersComponent &&
        rentersComponent.isSelected !== 'true' &&
        condoComponent &&
        condoComponent.isSelected !== 'true' &&
        powerSportsComponent &&
        powerSportsComponent.isSelected !== 'true'
      ) {
        if (lifeComponent && lifeComponent.isSelected === 'true') {
          lifeComponent.selectProduct(false);
        }
        if (petComponent && petComponent.isSelected === 'true') {
          petComponent.selectProduct(false);
        }
      }
    }
    if (target === comp6) {
      const component = this.productsList.find(
        component => component.product.name === comp1
      );
      const rentersComponent = this.productsList.find(
        component => component.product.name === comp2
      );
      const lifeComponent = this.productsList.find(
        component => component.product.name === 'Term Life'
      );
      const targetComponent = this.productsList.find(
        component => component.product.name === 'Powersports'
      );
      const condoComponent = this.productsList.find(
        component => component.product.name === comp3
      );
      const autoComponent = this.productsList.find(
        component => component.product.name === 'Auto'
      );
      const petComponent = this.productsList.find(
        component => component.product.name === 'Pet'
      );
      if (
        targetComponent &&
        targetComponent.isSelected !== 'true' &&
        component &&
        component.isSelected !== 'true' &&
        rentersComponent &&
        rentersComponent.isSelected !== 'true' &&
        condoComponent &&
        condoComponent.isSelected !== 'true' &&
        autoComponent &&
        autoComponent.isSelected !== 'true'
      ) {
        if (lifeComponent && lifeComponent.isSelected === 'true') {
          lifeComponent.selectProduct(false);
        }
        if (petComponent && petComponent.isSelected === 'true') {
          petComponent.selectProduct(false);
        }
      }
    }
    if (target === 'Term Life') {
      const component = this.productsList.find(
        component => component.product.name === comp1
      );
      const rentersComponent = this.productsList.find(
        component => component.product.name === comp2
      );
      const condoComponent = this.productsList.find(
        component => component.product.name === comp3
      );
      const targetComponent = this.productsList.find(
        component => component.product.name === 'Term Life'
      );
      const autoComponent = this.productsList.find(
        component => component.product.name === 'Auto'
      );
      const powerSportsComponent = this.productsList.find(
        component => component.product.name === 'Powersports'
      );
      if (
        (component && component.isSelected === 'true') ||
        (rentersComponent && rentersComponent.isSelected === 'true') ||
        (condoComponent && condoComponent.isSelected === 'true') ||
        (autoComponent && autoComponent.isSelected === 'true') ||
        (powerSportsComponent && powerSportsComponent.isSelected === 'true')
      ) {
        // do nothing
      } else {
        targetComponent.selectProduct(false);
      }
    }
    if (target === 'Pet') {
      const component = this.productsList.find(
        component => component.product.name === comp1
      );
      const rentersComponent = this.productsList.find(
        component => component.product.name === comp2
      );
      const condoComponent = this.productsList.find(
        component => component.product.name === comp3
      );
      const targetComponent = this.productsList.find(
        component => component.product.name === 'Pet'
      );
      const autoComponent = this.productsList.find(
        component => component.product.name === 'Auto'
      );
      const powerSportsComponent = this.productsList.find(
        component => component.product.name === 'Powersports'
      );
      if (
        (component && component.isSelected === 'true') ||
        (rentersComponent && rentersComponent.isSelected === 'true') ||
        (condoComponent && condoComponent.isSelected === 'true') ||
        (autoComponent && autoComponent.isSelected === 'true') ||
        (powerSportsComponent && powerSportsComponent.isSelected === 'true')
      ) {
        // do nothing
      } else {
        targetComponent.selectProduct(false);
      }
    }
  }

  private checkConditionalProducts(product: Product, productId: string) {
    switch (product.id) {
      case ProductTypes.AUTO: {
        const conditional = this.productsList.find(
          component =>
            component.product.id === ProductTypes.HOMEOWNERS ||
            component.product.id === ProductTypes.RENTERS ||
            component.product.id === ProductTypes.CONDO
        );
        if (conditional && conditional.isSelected === 'true') {
          const component = this.productsList.find(
            component => component.product.id === productId
          );
          if (component && component.isSelected === 'true') {
            component.selectProduct(false);
          }
        }
        break;
      }
      case ProductTypes.CONDO:
      case ProductTypes.HOMEOWNERS:
      case ProductTypes.RENTERS: {
        const conditional = this.productsList.find(
          component => component.product.id === ProductTypes.AUTO
        );
        if (conditional && conditional.isSelected === 'true') {
          const component = this.productsList.find(
            component => component.product.id === productId
          );
          if (component && component.isSelected === 'true') {
            component.selectProduct(false);
          }
        }
        break;
      }
    }
  }

  showLifeText(product): boolean {
    return (
      StringUtils.areEqual(product.id, ProductTypes.TERMLIFE) &&
      !this.isBindCoverages
    );
  }

  filterLifeFromProducts(): void {
    if (
      !!this.products?.find(product => product.id === ProductTypes.TERMLIFE)
    ) {
      if (
        !this.isLifeCoverageRequired &&
        !this.isBindCoverages &&
        !this.gettingStarted
      ) {
        this.products = this.products.filter(
          product => product.id !== ProductTypes.TERMLIFE
        );
      } else if (this.isBindCoverages && !this.gettingStarted) {
        this.products = this.products.filter(
          product => product.id !== ProductTypes.TERMLIFE
        );
      }
    }
  }

  private resetAllSelectedProducts(): void {
    this.productsList.forEach(component => {
      if (component && component.isSelected === 'true') {
        this.value = this.value.filter(
          item => item.id !== component.product.id
        );
      }
    });
  }

  private findProductIndex(product: Product) {
    const foundProduct = this.products
      .map((p, index) => {
        return { ...p, index };
      })
      .find(e => e.id === product.id);
    return foundProduct ? foundProduct.index : -1;
  }

  shouldShowWhatYouNeedLink(): boolean {
    return !this.isMobile && this.gettingStarted && !this._config;
  }
}
