import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  HostBinding,
  AfterViewInit,
} from '@angular/core';
import {
  LoggingService,
  PageAlertService,
  PrivateLabelService,
  ProductsService,
  UserContextService,
} from '@core/services';
import { Observable, Subject, combineLatest } from 'rxjs';
import {
  PageAlertIDs,
  RIVIAN_THIRDPARTY_ID,
} from '@shared/constants/app-constants';
import { map, takeUntil, tap, switchMap, filter, take } from 'rxjs/operators';
import { ErrorMessageService } from '@core/services/error-message.service';
import { NonStandardBridgeService } from '@core/services/non-standard-bridge.service';
import { Product } from '@core/models/products/product.model';
import { ProductRatingError } from '@core/models/error/error.model';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'mq-softfall',
  templateUrl: './softfall.component.html',
  styleUrls: ['./softfall.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftfallComponent implements OnInit, OnDestroy {
  isPrivateLabel$: Observable<boolean>;
  selectedProducts$: Observable<Product[]>;
  isNonStandardBridge$: Observable<string>;
  contactNumber$: Observable<string>;
  unformattedContactNumber$: Observable<string>;
  _unsubscribe = new Subject<any>();
  errorMessages: ProductRatingError[] = [];
  quoteIdsExist = false;
  rivianRedirect: string;

  @HostBinding('attr.role') role = 'main';

  constructor(
    private _loggingService: LoggingService,
    private _privateLabelService: PrivateLabelService,
    private _productsService: ProductsService,
    private _changeDetector: ChangeDetectorRef,
    private _errorMessageService: ErrorMessageService,
    private _nonStandardBridgeService: NonStandardBridgeService,
    private _userContextService: UserContextService,
    private _pageAlertService: PageAlertService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._pageAlertService.dispatchActiveAlert(PageAlertIDs.softfall);
    this.isPrivateLabel$ = this._privateLabelService.isPrivateLabel();
    this.selectedProducts$ = this._productsService.getSelectedProducts();
    this.contactNumber$ = this._privateLabelService.getContactNumber();
    this.unformattedContactNumber$ = this._privateLabelService
      .getContactNumber()
      .pipe(
        filter(phone => !!phone),
        map(phone => phone.split('-').join(''))
      );
    this._userContextService
      .getUserContext()
      .pipe(take(1))
      .subscribe(userContext => {
        if (
          userContext &&
          userContext.thirdPartyId &&
          userContext.thirdPartyId === RIVIAN_THIRDPARTY_ID
        ) {
          this.rivianRedirect = userContext.redirectUrl;
        }
      });
    // Ensure we have something, even if no products or errors exist.
    this._replaceErrorList([]);

    this.isNonStandardBridge$ =
      this._nonStandardBridgeService.isNonStandardBridge();

    combineLatest([
      this._productsService.getProducts(),
      this._productsService.getSelectedProducts(),
    ])
      .pipe(
        takeUntil(this._unsubscribe),
        tap(([products, selectedProducts]) => {
          this.quoteIdsExist = !!selectedProducts.find(p => !!p.quoteId);
        }),
        map(([products, selectedProducts]) =>
          this._combineDisplayableProducts(products, selectedProducts)
        ),
        switchMap(products =>
          this._errorMessageService.addErrorMessageToProducts(products)
        ),
        tap(products =>
          this._replaceErrorList(
            products
              .filter(p => p.errorMessage)
              .map(p => ({
                product: p.name,
                message: p.errorMessage,
              }))
          )
        )
      )
      .subscribe();

    this._router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart &&
            event.navigationTrigger === 'popstate'
        )
      )
      .subscribe(() => {
        // hb-1522 stop browser from back navigation
        let isPopStateAllowed = false;
        let isNonStandardBridge = false;

        this.isNonStandardBridge$
          .pipe(filter(value => value != null))
          .subscribe(() => {
            isNonStandardBridge = true;
          });

        if (!isNonStandardBridge) {
          this.selectedProducts$.subscribe((products: Product[]): void => {
            products.forEach((product: Product) => {
              if (!product.hasError) {
                isPopStateAllowed = true;
              }
            });
          });
        }

        if (!isPopStateAllowed) {
          let url = '/softfall';
          if (isNonStandardBridge) {
            url = '/nonStandardBridge';
          }
          this._router.navigate([url], {
            replaceUrl: true,
          });
        }
      });
  }

  ngOnDestroy(): void {
    if (this._unsubscribe) {
      this._unsubscribe.next();
      this._unsubscribe.complete();
      this._unsubscribe = null;
    }
  }

  private _combineDisplayableProducts(
    allProducts: Product[],
    selectedProducts: Product[]
  ): Product[] {
    const output = selectedProducts.filter(p => p.hasError);
    if (output.length < 1) {
      const anyProductWithError = allProducts.find(p => p.hasError);
      if (anyProductWithError) {
        output.push(<any>{
          id: -1,
          hasError: true,
          errorCode: anyProductWithError.errorCode,
        });
      }
    }
    return output;
  }

  private _replaceErrorList(messagesByProductId: ProductRatingError[]) {
    if (messagesByProductId.length < 1) {
      this.addFallbackMessage(messagesByProductId);
    }
    this._logErrorMessages(messagesByProductId);
    this.errorMessages = messagesByProductId;
    this._changeDetector.detectChanges();
  }

  private addFallbackMessage(productNamesAndMessages) {
    if (productNamesAndMessages.length < 1) {
      productNamesAndMessages.push({
        product: '',
        message: this._errorMessageService.getDefaultErrorMessage(),
      });
    }
  }

  private _logErrorMessages(messages: { product: string; message: string }[]) {
    this._loggingService.logToSplunk({
      event: 'SOFTFALL_MESSAGES',
      message: messages,
    });
  }
}
