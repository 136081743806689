<form [formGroup]="form" *ngIf="showSmartHomeDiscount$ | async">
  <div id="smart-home-content" *ngIf="experience">
    <div class="row foot-room">
      <div class="alert alert-info media" role="alert" tabindex="0">
        <div class="row alert-row">
          <div class="col-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path
                fill="#6d6e71"
                d="M15.973 0.729c-8.347 0-15.111 6.764-15.111 15.111s6.764 15.111 15.111 15.111 15.111-6.764 15.111-15.111-6.773-15.111-15.111-15.111zM15.884 5.849c1.556 0 2.818 1.262 2.818 2.818s-1.262 2.818-2.818 2.818-2.818-1.262-2.818-2.818c-0.009-1.564 1.253-2.818 2.818-2.818zM21.191 25.307h-10.444v-2.729h2.738v-7.573h-2.738v-2.551h7.876v10.124h2.578v2.729z"
              ></path>
            </svg>
          </div>
          <div class="col-11">
            <div
              id="smart-home-alert-label"
              class="media-body"
              [innerHTML]="experience.smartHomeAlertLabel"
            ></div>
          </div>
        </div>
        <div class="row alert-row">
          <div class="col-1"></div>
          <div class="col-11">
            <div id="smart-home-program-overview" class="media-body">
              {{ experience.programOverview }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row foot-room">
      <div class="col-md-8 col-sm-12" tabindex="0">
        <p id="smart-home-pricing-header">
          When you
          <a
            id="smarthome-program-learnmore"
            [href]="experience.moreInfoUrl"
            target="_blank"
            aria-label="add this program"
            >add this program</a
          >
          to your policy, you get:
        </p>
      </div>
      <div
        id="smart-home-pricing-text-all-groups"
        class="col-6"
        [innerHTML]="experience.pricingTextAllGroups"
        tabindex="0"
      ></div>
      <div
        id="smart-home-pricing-text"
        class="col-6"
        [innerHTML]="experience.pricingText"
        tabindex="0"
      ></div>
    </div>
    <div class="row">
      <div class="form-group col-sm-auto">
        <fieldset attr.aria-required="true">
          <legend id="smart-home-participation-question-label">
            {{ experience.participationQuestion }}
          </legend>
          <div
            class="btn-group btn-group-toggle d-flex"
            formControlName="optIn"
            ngDefaultControl
          >
            <input
              class="btn-check"
              type="radio"
              aria-describedby="smarthome-yes-error"
              [value]="true"
              name="optIn"
              formControlName="optIn"
              id="smarthome-yes"
            />
            <label
              for="smarthome-yes"
              class="btn btn-default col-6 col-sm-3"
              [ngClass]="{
                activetoggle: form.value.optIn == true
              }"
            >
              Yes
            </label>
            <input
              class="btn-check"
              type="radio"
              aria-describedby="smarthome-no-error"
              [value]="false"
              name="optIn"
              formControlName="optIn"
              id="smarthome-no"
            />
            <label
              for="smarthome-no"
              class="btn btn-default col-6 col-sm-3"
              [ngClass]="{
                activetoggle: form.value.optIn == false
              }"
            >
              No
            </label>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row">
      <div
        id="smart-home-disclaimer"
        class="form-group col-md-8 col-sm-12"
        [innerHTML]="experience.disclaimerText"
        tabindex="0"
      ></div>
    </div>
  </div>
</form>
