import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Alert } from '@core/models/page-alerts/page-alert.model';
import { PageAlertService } from './page-alert.service';

@Injectable()
export class AlertService {
  constructor(
    private router: Router,
    private _pageAlertService: PageAlertService
  ) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // clear alert message
        this.clear();
      }
    });
  }

  getAlert(): Observable<Alert> {
    return this._pageAlertService.getActiveAlert();
  }

  clear() {
    this._pageAlertService.clear();
  }
}
