import { createSelector } from '@ngrx/store';

import * as fromHelpContent from '../reducers/help-content.reducer';
import * as fromFeature from '../reducers';

export const getHelpContentState = createSelector(
  fromFeature.getHelpContentState,
  (state: fromFeature.HelpContentState) => state.helpContent
);

export const {
  selectAll: selectAllHelpContent,
  selectEntities: selectHelpContentEntities,
  selectTotal: selectTotalHelpContent,
} = fromHelpContent.adapter.getSelectors(getHelpContentState);

export const isHelpContentDismissed = (name: string) =>
  createSelector(selectHelpContentEntities, entities => {
    const content = entities[name];
    return content ? content.dismissed : false;
  });
