import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@bind/store';
import * as fromActions from '@bind/store/actions';
import * as fromSelectors from '@bind/store/selectors';

import {
  NavigationPath,
  NavigationItem,
} from '@core/models/navigation/navigation.model';
import { ForceNavigationIndex } from '@bind/store/actions';

@Injectable({ providedIn: 'root' })
export class NavigationDao {
  constructor(private _store: Store<fromStore.BindState>) {}

  public buildBaseNavigationPath(): Observable<NavigationPath> {
    const path = new NavigationPath();

    const policyInformation = new NavigationItem();
    policyInformation.pages = ['policy-information'];
    policyInformation.url = '/multi-bind/policy-information';
    policyInformation.enabled = true;
    policyInformation.title = 'Policy Information';

    const additionalInformation = new NavigationItem();
    additionalInformation.pages = ['additional-information'];
    additionalInformation.url = '/multi-bind/additional-information';
    additionalInformation.enabled = false;
    additionalInformation.title = 'Additional Information';

    const propertyInfo = new NavigationItem();
    propertyInfo.pages = ['Renters', 'Homeowners', 'Condo'];
    propertyInfo.url = '/multi-bind/property-info';
    propertyInfo.enabled = false;
    propertyInfo.title = 'Property Information';

    const lenders = new NavigationItem();
    lenders.pages = ['lenders'];
    lenders.url = '/multi-bind/lenders';
    lenders.enabled = false;
    lenders.title = 'Lenders';

    const productDetails = new NavigationItem();
    productDetails.pages = ['product-details'];
    productDetails.url = '/multi-bind/product-details';
    productDetails.enabled = false;
    productDetails.title = 'Product Details';

    const renters = new NavigationItem();
    renters.pages = ['renters'];
    renters.url = '/multi-bind/renters';
    renters.enabled = false;
    renters.title = 'Renters';

    const coverages = new NavigationItem();
    coverages.pages = ['coverages'];
    coverages.url = '/multi-bind/coverages';
    coverages.enabled = true;
    coverages.title = 'Coverages';

    const billing = new NavigationItem();
    billing.pages = ['billing'];
    billing.url = '/multi-payment/billing';
    billing.enabled = true;
    billing.title = 'Billing';

    path.items = [
      policyInformation,
      additionalInformation,
      propertyInfo,
      lenders,
      productDetails,
      renters,
      coverages,
      billing,
    ];
    path.currentIndex = 0;

    return of(path);
  }

  loadNavigation() {
    this._store.dispatch(new fromActions.LoadRoutes());
  }

  // DSM
  dsmUpdateNavigationPath(): void {
    this._store.dispatch(new fromActions.DsmUpdateNavigationPath());
  }

  getBasePath(): Observable<NavigationPath> {
    return this._store.select(fromSelectors.getNavigationBasePath);
  }

  getCurrentPath(): Observable<NavigationPath> {
    return this._store.select(fromSelectors.getNavigationCurrentPath);
  }

  updateNavigationPath(navigationPath: NavigationPath) {
    this._store.dispatch(new fromActions.UpdateRoutePath(navigationPath));
  }

  getNavigationLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getNavigationLoading);
  }

  getNavigationLoaded() {
    return this._store.select(fromSelectors.getNavigationLoaded);
  }

  navigateForward(navigationPath: NavigationPath) {
    this._store.dispatch(new fromActions.NavigateForward(navigationPath));
  }

  navigateBack(navigationPath: NavigationPath) {
    this._store.dispatch(new fromActions.NavigateBack(navigationPath));
  }

  forceNavigationIndex(url: string) {
    this._store.dispatch(new ForceNavigationIndex(url));
  }

  removeCurrentPageNavigation(url: string): void {
    this._store.dispatch(new fromActions.RemoveCurrentPageNavigation(url));
  }
}
