import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromDiscounts from '@core/store/actions/discount.action';

import { DiscountEntity } from '@core/models/entities/discount.entity';

export type DiscountState = EntityState<DiscountEntity>;
export const adapter: EntityAdapter<DiscountEntity> =
  createEntityAdapter<DiscountEntity>({
    selectId: discount => discount.description,
  });

export function reducer(
  state = adapter.getInitialState(),
  action: fromDiscounts.DiscountActions
): DiscountState {
  switch (action.type) {
    case fromDiscounts.DiscountActionTypes.UPDATE_ALL_DISCOUNTS: {
      return adapter.upsertMany(action.payload, state);
    }
    case fromDiscounts.DiscountActionTypes.ADD_MANY_DISCOUNTS: {
      return adapter.addMany(action.payload, {
        ...state,
      });
    }
    default: {
      return state;
    }
  }
}
