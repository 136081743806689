import { StateUtils } from './state.utils';
import { AgencyModel } from '@core/models/agency/agency.model';
import { ProducerTypes } from '@shared/constants/app-constants';
import { ProducerRequest } from '@core/models/request/producer-request.model';

export interface AgencyProducer {
  producerCode: string;
  agencyCode: string;
  producerId: string;
  stateCode?: string;
}

export class ProducerUtils {
  static getAgencyProducer(
    channel: string,
    producerCode: string
  ): AgencyProducer {
    const producer = {} as AgencyProducer;
    if (!producerCode) {
      return producer;
    }
    producer.producerCode = producerCode;

    if (channel === 'IA') {
      const [agencyCode, pc] = producerCode.split('-');
      if (!agencyCode || !pc) {
        return producer;
      }

      producer.agencyCode = agencyCode.trim();
      producer.producerId = pc.trim();

      const expectedAgencyLength = 8;
      const length = producer.agencyCode.length;
      if (length < expectedAgencyLength) {
        producer.agencyCode =
          '0'.repeat(expectedAgencyLength - length) + producer.agencyCode;
      } else if (length > expectedAgencyLength) {
        producer.agencyCode = producer.agencyCode.slice(
          length - expectedAgencyLength
        );
      }

      const expectedProducerLength = 3;
      if (producer.producerId.length > expectedProducerLength) {
        producer.producerId = producer.producerId.slice(
          producer.producerId.length - expectedProducerLength
        );
      } else {
        producer.producerId =
          '0'.repeat(expectedProducerLength - producer.producerId.length) +
          producer.producerId;
      }
      producer.producerCode = producer.agencyCode + ' - ' + producer.producerId;
    } else if (channel === 'EA' || channel === 'NSS') {
      const producerCodeLength = producer.producerCode.length;
      const expectedAgencyLength = 5;
      const expectedProducerIdLength = 2;

      if (producer.producerCode.includes('-')) {
        const [stateCode, pc] = producer.producerCode.split('-');
        producer.producerCode = pc;
        producer.stateCode = stateCode.match('^[A-z]+$')
          ? StateUtils.mapStateToStateCode(stateCode)
          : stateCode;

        producer.agencyCode = producer.producerCode.slice(
          pc.length - expectedAgencyLength
        );
        producer.producerId = producer.producerCode.slice(
          0,
          expectedProducerIdLength
        );
      } else {
        const producerCodeWithStateLength = 9;
        const producerCodeOnlyLength = 7;
        if (producerCodeLength === producerCodeWithStateLength) {
          const expectedStateCodeLength = 2;

          const stateCode = producer.producerCode.slice(
            0,
            expectedStateCodeLength
          );
          producer.stateCode = stateCode.match('^[A-z]+$')
            ? StateUtils.mapStateToStateCode(stateCode)
            : stateCode;

          producer.producerId = producerCode.slice(
            expectedStateCodeLength,
            expectedStateCodeLength + expectedProducerIdLength
          );
          producer.agencyCode = producerCode.slice(
            expectedStateCodeLength + expectedProducerIdLength
          );
          producer.producerCode = producer.producerId + producer.agencyCode;
        } else if (producerCodeLength === producerCodeOnlyLength) {
          producer.agencyCode = producerCode.slice(2);
          producer.producerId = producerCode.slice(0, 2);
        }
      }
    }
    return producer;
  }

  static getProducerCode(
    agent: AgencyModel,
    formatIAForLookup?: boolean
  ): string {
    const iaDelimiter = formatIAForLookup ? '-' : ' - ';
    return agent.agencyChannel === 'IA' &&
      agent.producerCode &&
      agent.agencyCode &&
      !agent.producerCode.includes('-')
      ? agent.agencyCode + iaDelimiter + agent.producerCode
      : agent.producerCode;
  }

  static getFullProducerCode(agent: AgencyModel): string {
    const producerCode = ProducerUtils.getProducerCode(agent);
    const producerCodeWithStateLength = 9;
    return agent.agencyChannel === 'IA' ||
      producerCode.length === producerCodeWithStateLength
      ? producerCode
      : StateUtils.stateCodeByState[agent.state] + producerCode;
  }

  static getChannelByProducerType(type: string): string {
    switch (type) {
      case ProducerTypes.EA:
        return 'EA';
      case ProducerTypes.NSS:
        return 'NSS';
      case ProducerTypes.IA:
        return 'IA';
      default:
        return type;
    }
  }

  static getProducerType(agent: AgencyModel): string {
    if (!agent) {
      return ProducerTypes.DIRECT;
    }

    switch (agent.agencyChannel) {
      case 'IA': {
        return ProducerTypes.IA;
      }

      case 'EA': {
        return ProducerTypes.EA;
      }

      case 'NSS': {
        return ProducerTypes.NSS;
      }

      default: {
        return ProducerTypes.DIRECT;
      }
    }
  }

  static getQuoteProducerCode(agent: AgencyModel, state: string): string {
    if (agent && agent.producerCode) {
      if (agent.agencyChannel === 'IA') {
        return agent.agencyCode + ' - ' + agent.producerCode;
      } else {
        const producerCodeWithStateLength = 9;
        if (agent.producerCode.length === producerCodeWithStateLength) {
          return agent.producerCode;
        } else {
          return `${StateUtils.stateCodeByState[state]}${agent.producerCode}`;
        }
      }
    } else {
      return '00063821 - 693';
    }
  }

  static getTrueNorthProducer(state: string): ProducerRequest {
    return {
      producerCode: `${StateUtils.trueNorthDefaultProducerCodes[state]}`,
      type: ProducerTypes.DIRECT,
    };
  }
}
