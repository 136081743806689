import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CompRaterNewsService } from '@core/services';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class CompRaterNewsGuard implements CanActivate {
  constructor(private _compRaterNewsService: CompRaterNewsService) {}

  canActivate(): Observable<boolean> {
    return this._compRaterNewsService.getCompRaterNewsLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._compRaterNewsService.loadCompRaterNews();
        }
      }),
      filter(loaded => loaded)
    );
  }
}
