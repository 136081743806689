import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { SessionDao } from '@core/dao';
import { AppConfigService } from './app-config.service';
import { SessionLog } from '@core/models/session-log.model';

@Injectable()
export class SessionService {
  constructor(
    private _sessionDao: SessionDao,
    private _appConfigService: AppConfigService
  ) {}

  setSessionId(sessionId: string): void {
    this._sessionDao.setSessionId(sessionId);
  }

  getSessionId(): Observable<string> {
    return this._sessionDao.getSessionId();
  }

  setQuoteState(quoteState: string): void {
    this._sessionDao.setQuoteState(quoteState);
  }

  setIgnoreProductDisablement(ignoreProductDisablement: boolean): void {
    if (this._appConfigService.isTest())
      this._sessionDao.setIgnoreProductDisablement(ignoreProductDisablement);
  }

  getIgnoreProductDisablement(): Observable<boolean> {
    return this._sessionDao.getIgnoreProductDisablement();
  }

  setIgnoreCurrentCarrierValidation(
    setignoreCurrentCarrierValidation: boolean
  ): void {
    if (this._appConfigService.isTest())
      this._sessionDao.setIgnoreCurrentCarrierValidation(
        setignoreCurrentCarrierValidation
      );
  }
  getIgnoreCurrentCarrierValidation(): Observable<boolean> {
    return this._sessionDao.getIgnoreCurrentCarrierValidation();
  }

  setIgnoreRiskFilter(ignoreRiskFilter: boolean): void {
    if (this._appConfigService.isTest())
      this._sessionDao.setIgnoreRiskFilter(ignoreRiskFilter);
  }

  setIgnoreAutoUWRules(ignoreAutoUWRules: boolean): void {
    if (this._appConfigService.isTest())
      this._sessionDao.setIgnoreAutoUWRules(ignoreAutoUWRules);
  }

  getIgnoreRiskFilter(): Observable<boolean> {
    return this._sessionDao.getIgnoreRiskFilter();
  }

  getIgnoreAutoUWRules(): Observable<boolean> {
    return this._sessionDao.getIgnoreAutoUWRules();
  }

  setPCEnv(pcEnv: string): void {
    if (this._appConfigService.isTest()) this._sessionDao.setPCEnv(pcEnv);
  }

  getPCEnv(): Observable<string> {
    return this._sessionDao.getPCEnv();
  }

  setBillingEnv(billingEnv: string): void {
    if (this._appConfigService.isTest())
      this._sessionDao.setBillingEnv(billingEnv);
  }

  getBillingEnv(): Observable<string> {
    return this._sessionDao.getBillingEnv();
  }

  getQuoteState(): Observable<string> {
    return this._sessionDao.getQuoteState();
  }

  setFirstName(firstName: string): void {
    this._sessionDao.setFirstName(firstName);
  }

  getFirstName(): Observable<string> {
    return this._sessionDao.getFirstName();
  }

  setLastName(lastName: string): void {
    this._sessionDao.setLastName(lastName);
  }

  getLastName(): Observable<string> {
    return this._sessionDao.getLastName();
  }

  setEmail(email: string): void {
    this._sessionDao.setEmail(email);
  }

  getEmail(): Observable<string> {
    return this._sessionDao.getEmail();
  }

  setRequestedProducts(products: string[]): void {
    this._sessionDao.setRequestedProducts(products);
  }

  getRequestedProducts(): Observable<string[]> {
    return this._sessionDao.getRequestedProducts();
  }

  setReferrer(referrer: string): void {
    this._sessionDao.setReferrer(referrer);
  }

  getReferrer(): Observable<string> {
    return this._sessionDao.getReferrer();
  }

  setHasSentPetEmail(hasSentPetEmail: boolean): void {
    this._sessionDao.setHasSentPetEmail(hasSentPetEmail);
  }

  getHasSentPetEmail(): Observable<boolean> {
    return this._sessionDao.getHasSentPetEmail();
  }

  setSessionProgressTrackerEligibility(
    isProgressTrackerEligible: boolean
  ): void {
    this._sessionDao.setSessionProgressTrackerEligibility(
      isProgressTrackerEligible
    );
  }

  getSessionProgressTrackerEligibility(): Observable<boolean> {
    return this._sessionDao.getSessionProgressTrackerEligibility();
  }

  setProgressTrackerFlowName(progressTrackerFlowName: string): void {
    this._sessionDao.setProgressTrackerFlowName(progressTrackerFlowName);
  }

  getProgressTrackerFlowName(): Observable<string> {
    return this._sessionDao.getProgressTrackerFlowName();
  }

  updateSessionLogEntry(sessionlog: SessionLog): void {
    this._sessionDao.updateSessionLogEntry(sessionlog);
  }

  sendSessionLogEntry(sessionEventName: string): void {
    this._sessionDao.sendSessionLogEntry(sessionEventName);
  }

  setSessionClaimsReviewsLoadingSpinnerEligibility(eligible: boolean): void {
    this._sessionDao.setSessionClaimsReviewsLoadingSpinnerEligibility(eligible);
  }

  getSessionClaimsReviewsLoadingSpinnerEligibility(): Observable<boolean> {
    return this._sessionDao.getSessionClaimsReviewsLoadingSpinnerEligibility();
  }

  setSessionClaimsReviewsLoadingSpinnerActive(active: boolean): void {
    this._sessionDao.setSessionClaimsReviewsLoadingSpinnerActive(active);
  }

  getSessionClaimsReviewsLoadingSpinnerActive(): Observable<boolean> {
    return this._sessionDao.getSessionClaimsReviewsLoadingSpinnerActive();
  }

  setSessionClaimsReviewsLoadingSpinnerUpperMessage(message: string): void {
    this._sessionDao.setSessionClaimsReviewsLoadingSpinnerUpperMessage(message);
  }

  getSessionClaimsReviewsLoadingSpinnerUpperMessage(): Observable<string> {
    return this._sessionDao.getSessionClaimsReviewsLoadingSpinnerUpperMessage();
  }

  setSessionClaimsReviewsLoadingSpinnerLowerMessage(message: string): void {
    this._sessionDao.setSessionClaimsReviewsLoadingSpinnerLowerMessage(message);
  }

  getSessionClaimsReviewsLoadingSpinnerLowerMessage(): Observable<string> {
    return this._sessionDao.getSessionClaimsReviewsLoadingSpinnerLowerMessage();
  }

  setSessionClaimsReviewsLoadingSpinnerReviewerName(name: string): void {
    this._sessionDao.setSessionClaimsReviewsLoadingSpinnerReviewerName(name);
  }

  getSessionClaimsReviewsLoadingSpinnerReviewerName(): Observable<string> {
    return this._sessionDao.getSessionClaimsReviewsLoadingSpinnerReviewerName();
  }
}
