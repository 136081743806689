import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';

import {
  AgencyService,
  LoadingService,
  LoggingService,
  MetadataService,
  SessionService,
} from '@core/services';

import { AgencyModel } from '@core/models/agency/agency.model';

@Injectable()
export class AgencyProxy {
  constructor(
    private _agencyService: AgencyService,
    private _title: Title,
    private _loadingService: LoadingService,
    private _loggingService: LoggingService,
    private _metadataService: MetadataService,
    private _sessionService: SessionService
  ) {}

  setTitle(title: string): void {
    this._title.setTitle(title);
  }

  getAgency(): Observable<AgencyModel> {
    return this._agencyService.getAgency();
  }

  updateAgency(agency: AgencyModel): void {
    this._agencyService.updateAgency(agency);
  }

  isAppLoading(): Observable<boolean> {
    return this._loadingService.getAppLoading();
  }

  getActiveStatesData(): Observable<string[]> {
    return this._metadataService.getActiveStateAbbreviationsData();
  }

  getStatesData(): Observable<string[]> {
    return this._metadataService.getStateAbbreviationsData();
  }

  getAgencyChannelsData(): Observable<string[]> {
    return this._metadataService.getAgencyChannelsData();
  }

  logPageLoaded(): void {
    this._loggingService.logToSplunk({
      event: 'AGENCY_LOAD',
    });
  }

  logPageSubmit(agent: AgencyModel): void {
    this._loggingService.logAgent(agent);
  }

  updateQuoteState(state: string): void {
    this._sessionService.setQuoteState(state);
  }
}
