import * as fromAddress from '../actions/address.action';

import { StandardizedAddresses } from '@core/models/address/standardized-address.model';

export interface AddressState {
  standardizedAddresses: StandardizedAddresses;
  loaded: boolean;
  loading: boolean;
}

export const initialState: AddressState = {
  standardizedAddresses: null,
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromAddress.AddressActions
): AddressState {
  switch (action.type) {
    case fromAddress.LOAD_STANDARDIZED_ADDRESS: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromAddress.LOAD_STANDARDIZED_ADDRESS_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    }

    case fromAddress.LOAD_STANDARDIZED_ADDRESS_SUCCESS: {
      const standardizedAddresses = action.payload;

      return {
        ...state,
        loaded: true,
        loading: false,
        standardizedAddresses,
      };
    }

    case fromAddress.UPDATE_UNIT_NUMBER: {
      const unitNumber = action.payload;

      const standardizedAddress = {
        ...state.standardizedAddresses.standardizedAddresses[0],
        unitNumber,
      };
      const array = {
        ...state.standardizedAddresses.standardizedAddresses,
        ...[standardizedAddress],
      };
      const standardizedAddresses = {
        ...state.standardizedAddresses,
        standardizedAddresses: array,
      };

      const final = {
        ...state,
        standardizedAddresses,
      };
      return {
        ...state,
        ...final,
      };
    }

    case fromAddress.RESET_ADDRESS_LOADED: {
      return {
        ...state,
        loaded: false,
      };
    }
  }
  return state;
}

export const getStandardizedAddresses = (state: AddressState) =>
  state.standardizedAddresses;
export const getStandardizedAddressLoaded = (state: AddressState) =>
  state.loaded;
export const getStandardizedAddressLoading = (state: AddressState) =>
  state.loading;
