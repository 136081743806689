import { createSelector } from '@ngrx/store';

import {
  getSelectedCoverageProducts,
  getSelectedProducts,
} from './products.selector';
import { getAgency } from './agency.selector';
import { getBillingState } from './billing.selector';
import { getEscrowState } from './escrow.selector';
import { getPrimaryNamedInsured } from './policyholder.selector';
import { selectPolicyAddressEntities } from './policy-address.selector';
import { selectAllCoverages } from './coverage.selector';
import { getAllSelectedDiscounts } from './discount.selector';
import { selectAllPremiums } from './premium.selector';
import { IProductLog, IBusinessLog } from '@core/services/logging.service';
import { Product } from '@core/models/products/product.model';
import { CoverageEntity } from '@core/models/entities/coverage.entity';
import { PremiumEntity } from '@core/models/entities/premium.entity';
import { AgencyModel } from '@core/models/agency/agency.model';
import { ProducerUtils } from '@shared/utils/producer.utils';
import { ProducerRequest } from '@core/models/request/producer-request.model';
import { ChannelNames } from '@shared/constants/app-constants';
import { DiscountEntity } from '@core/models/entities/discount.entity';

const getProducer = (agency: AgencyModel, state: string): ProducerRequest => {
  return {
    type: ProducerUtils.getProducerType(agency),
    producerCode: ProducerUtils.getQuoteProducerCode(agency, state),
  };
};

const getChannel = (agency: AgencyModel): string => {
  return !agency
    ? ChannelNames.INTERNET
    : agency.isPrivateLabel
    ? ChannelNames.PRIVATE_LABEL
    : agency.agencyChannel;
};

const buildProductLog = (
  products: Product[],
  discounts?: DiscountEntity[],
  coverages?: CoverageEntity[],
  premiums?: PremiumEntity[]
): IProductLog[] => {
  return products.map(product => {
    const productDiscounts = discounts
      ? discounts.filter(
          discount =>
            discount.productId === product.id && discount.isDiscountApplied
        )
      : null;
    const productCoverages = coverages
      ? coverages.filter(coverage => coverage.productId === product.id)
      : null;
    const productPremium = premiums
      ? premiums.find(premium => premium.productId === product.id)
      : null;
    return {
      productId: product.id,
      submissionNumber: product.quoteId,
      policyNumber: product.policyNumber,
      quoteStatus: product.quoteStatus,
      discounts: productDiscounts,
      coverages: productCoverages,
      premium: productPremium,
      errorMessage: product.errorCode,
      effectiveDate: product.effectiveDate,
    } as IProductLog;
  }) as IProductLog[];
};

export const getBillingData = createSelector(
  getBillingState,
  getEscrowState,
  (billing, escrow) => ({ billing, escrow })
);

export const getLoggingData = createSelector(
  getSelectedProducts,
  getAgency,
  getPrimaryNamedInsured,
  selectPolicyAddressEntities,
  selectAllCoverages,
  getAllSelectedDiscounts,
  selectAllPremiums,
  getBillingData,
  (
    products,
    agency,
    policyHolder,
    policyAddressEntities,
    coverages,
    discounts,
    premiums,
    billingDataState
  ) => {
    const address = policyAddressEntities
      ? policyAddressEntities[policyHolder.addressId]
      : null;
    const account =
      billingDataState.billing && billingDataState.billing.accountSetup
        ? billingDataState.billing.accountSetup.account
        : null;
    const paymentMethod =
      billingDataState.billing &&
      billingDataState.billing.billing &&
      billingDataState.billing.billing.payment
        ? billingDataState.billing.billing.payment.paymentMethod
        : null;
    const paymentPlan =
      billingDataState.billing &&
      billingDataState.billing.billing &&
      billingDataState.billing.billing.payment
        ? billingDataState.billing.billing.payment.paymentPlan
        : null;
    const recurring =
      billingDataState.billing &&
      billingDataState.billing.billing &&
      billingDataState.billing.billing.payment
        ? billingDataState.billing.billing.payment.recurring
        : null;
    const accountError =
      billingDataState.billing && billingDataState.billing.accountSetup
        ? billingDataState.billing.accountSetup.errorMessage
        : null;
    return {
      products: buildProductLog(products, discounts, coverages, premiums),
      channel: getChannel(agency),
      policyHolder,
      address: policyAddressEntities
        ? policyAddressEntities[policyHolder.addressId]
        : null,
      producer: getProducer(agency, address.state),
      account,
      escrowAccount: billingDataState.escrow,
      paymentMethod,
      paymentPlan,
      recurring,
      accountError,
    } as IBusinessLog;
  }
);

export const getLoggingDataForIssue = createSelector(getLoggingData, log => {
  const boundProducts = log.products.filter(
    product => product.quoteStatus === 'Binding'
  );
  return { ...log, products: boundProducts };
});

export const areAllProductsInitiated = createSelector(
  getSelectedProducts,
  products =>
    products.length > 0 &&
    products.every(
      product =>
        product.quoteLoaded || (product.hasError && !!product.errorCode)
    )
);

export const areAllProductsQuoted = createSelector(
  getSelectedProducts,
  getAllSelectedDiscounts,
  selectAllCoverages,
  selectAllPremiums,
  (products, discounts, coverages, premiums) =>
    products.length > 0 &&
    discounts.length > 0 &&
    products.every(product => {
      return (
        ((product.quoteRated || product.bindRated) &&
          !!coverages.find(coverage => coverage.productId === product.id) &&
          !!premiums.find(premium => premium.productId === product.id)) ||
        (product.hasError && !!product.errorCode)
      );
    })
);

export const areAllProductsIssuedOrErrored = createSelector(
  getSelectedCoverageProducts,
  getBillingState,
  getEscrowState,
  (products, billingState, escrowState) =>
    (products.length > 0 &&
      products.every(
        product =>
          (!product.issueLoading &&
            product.bindRated &&
            product.bindCompleted &&
            product.issueCompleted) ||
          (!product.issueCompleted && product.hasError)
      )) ||
    (billingState.accountSetup &&
      billingState.accountSetup.error &&
      !!billingState.accountSetup.errorMessage) ||
    escrowState.status === 'error'
);
