import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { CurrentCarrier } from '@core/store/reducers/current-carrier.reducer';

@Injectable({
  providedIn: 'root',
})
export class CurrentCarrierDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getCurrentCarrierLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getCurrentCarrierLoading);
  }

  getCurrentCarrierLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getCurrentCarrierLoaded);
  }

  getPowersportsCurrentCarrierLoading(): Observable<boolean> {
    return this._store.select(
      fromSelectors.getPowersportsCurrentCarrierLoading
    );
  }

  getPowersportsCurrentCarrierLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getPowersportsCurrentCarrierLoaded);
  }

  getPowersportsCurrentCarrierHit(): Observable<boolean> {
    return this._store.select(fromSelectors.getPowersportsCurrentCarrierHit);
  }

  isCoverageLapsePresent(): Observable<boolean> {
    return this._store.select(fromSelectors.isCoverageLapsePresent);
  }

  getCurrentCarrierDecision(): Observable<string> {
    return this._store.select(fromSelectors.getCurrentCarrierDecision);
  }

  loadCurrentCarrier(): void {
    this._store.dispatch(new fromActions.GetCurrentCarrier());
  }

  loadPowersportsCurrentCarrier(): void {
    this._store.dispatch(new fromActions.GetPowersportsCurrentCarrier());
  }
  dispatchUpdateCurrentCarrier(
    reasonForCoverageLapse?: string,
    currentCarrier?: CurrentCarrier
  ): void {
    this._store.dispatch(
      new fromActions.UpdateCurrentCarrier(
        reasonForCoverageLapse,
        currentCarrier
      )
    );
  }
}
