import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { AgencyService } from '@core/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[isExclusiveAgent]',
})
export class IsExclusiveAgentDirective implements OnInit, OnDestroy {
  private _ngUnsubscribe = new Subject();

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _agencyService: AgencyService,
    private _changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._agencyService
      .getAgency()
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(isAgent => {
        if (
          isAgent &&
          isAgent.agencyChannel === 'EA' &&
          !isAgent.isPrivateLabel
        ) {
          this._updateView(true);
          this._changeDetection.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  private _updateView(isAgent: boolean) {
    this._viewContainer.clear();
    if (isAgent) {
      this._viewContainer.createEmbeddedView(this._templateRef);
    }
  }
}
