import { Injectable } from '@angular/core';
import { PreBindDocumentsAcknowledgement } from '@core/models/entities/pre-bind-document.entity';
import { ConsentValuesDao } from '@core/dao';
import { Observable } from 'rxjs';
import { ConnectedCarConsent } from '@core/models/private-label/private-label.model';
import { SmartrideInstantConsent } from '@core/models/private-label/private-label.model';
import { AutoIdCardsTextConsent } from '@core/models/digital-id-cards/digital-id-cards-text.model';

@Injectable({ providedIn: 'root' })
export class ConsentValuesService {
  constructor(private _consentValuesDao: ConsentValuesDao) {}

  updatePrebindDocumentsAcknowledgement(
    ack: PreBindDocumentsAcknowledgement
  ): void {
    this._consentValuesDao.updatePrebindDocumentsAcknowledgement(ack);
  }

  updateConnectedCarConsent(consent: ConnectedCarConsent): void {
    this._consentValuesDao.updateConnectedCarConsent(consent);
  }

  updateSmartrideInstantConsent(consent: SmartrideInstantConsent): void {
    this._consentValuesDao.updateSmartrideInstantConsent(consent);
  }

  updateAutoIdCardsTextConsent(consent: AutoIdCardsTextConsent): void {
    this._consentValuesDao.updateAutoIdCardsTextConsent(consent);
  }

  getPreBindDocumentsAcknowledgement(): Observable<PreBindDocumentsAcknowledgement> {
    return this._consentValuesDao.getPreBindDocumentsAcknowledgement();
  }

  getConnectedCarConsent(): Observable<ConnectedCarConsent> {
    return this._consentValuesDao.getConnectedCarConsent();
  }

  getSmartrideInstantConsent(): Observable<SmartrideInstantConsent> {
    return this._consentValuesDao.getSmartrideInstantConsent();
  }

  getAutoIdCardsTextConsent(): Observable<AutoIdCardsTextConsent> {
    return this._consentValuesDao.getAutoIdCardsTextConsent();
  }

  hasAutoIdCardsTextConsent(): Observable<boolean> {
    return this._consentValuesDao.hasAutoIdCardsTextConsent();
  }
}
