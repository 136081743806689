import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import * as fromComponents from './components';
import * as fromContainers from './containers';
import * as fromProxies from './proxies';
import * as fromGuards from './guards';
import * as fromShared from '../shared';

import { AgencyModule } from '../agency/agency.module';

import { RetrieveRoutingModule } from './retrieve-routing.module';
import { RetrieveComponent } from './containers/retrieve/retrieve.component';
import { RetrieveFormComponent } from './components/retrieve-form/retrieve-form.component';
import { RetrieveFailureMessagePipe } from './pipes/retrieve-failure-message.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { SubmissionDetailsFormComponent } from './components/submission-details-form/submission-details-form.component';
@NgModule({
  imports: [
    CommonModule,
    RetrieveRoutingModule,
    ReactiveFormsModule,
    AgencyModule,
    NgxMaskModule.forRoot(),
    ...fromShared.modules,
  ],
  declarations: [
    ...fromComponents.components,
    ...fromContainers.containers,
    RetrieveComponent,
    RetrieveFormComponent,
    RetrieveFailureMessagePipe,
    SubmissionDetailsFormComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...fromProxies.proxies, ...fromGuards.guards],
  exports: [RetrieveFailureMessagePipe],
})
export class RetrieveModule {}
