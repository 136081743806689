import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromPageAlert from '@core/store/actions/page-alert.action';
import { PageAlert, Alert } from '@core/models/page-alerts/page-alert.model';

export interface PageAlertState extends EntityState<PageAlert> {
  activeAlert: Alert;
  activeAlerts: Alert[];
  currentPageId: string;
  loaded: boolean;
  loading: boolean;
}

function idFromPageAlert(alert: PageAlert): string {
  if (alert.id) {
    return alert.id;
  }
  if (alert.appliedStates.length > 0) {
    return `${alert.pageId}_${alert.priority}_${alert.appliedChannels.join(
      '-'
    )}_${alert.appliedStates.join('-')}`;
  } else {
    return `${alert.pageId}_${alert.priority}_${alert.appliedChannels.join(
      '-'
    )}`;
  }
}

export const adapter: EntityAdapter<PageAlert> = createEntityAdapter<PageAlert>(
  {
    selectId: pageAlert => idFromPageAlert(pageAlert),
  }
);

export function reducer(
  state = adapter.getInitialState({
    activeAlert: null,
    activeAlerts: [],
    currentPageId: '',
    loaded: false,
    loading: false,
  }),
  action: fromPageAlert.PageAlertActions
): PageAlertState {
  switch (action.type) {
    case fromPageAlert.LOAD_ALL_PAGE_ALERTS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromPageAlert.LOAD_ALL_PAGE_ALERTS_SUCCESS: {
      return adapter.addMany(action.payload, {
        ...state,
        loaded: true,
        loading: false,
      });
    }

    case fromPageAlert.LOAD_ALL_PAGE_ALERTS_FAIL: {
      return { ...state, loading: false, loaded: false };
    }

    case fromPageAlert.LOAD_ACTIVE_ALERT: {
      return {
        ...state,
        currentPageId: action.payload,
      };
    }

    case fromPageAlert.LOAD_ACTIVE_ALERT_SUCCESS: {
      const alert = action.payload;
      const id = idFromPageAlert(alert);
      const activeAlert = {
        id,
        type: alert.type,
        message: alert.message,
        icon: alert.icon,
        dismissible: alert.dismissible,
      };
      return {
        ...state,
        activeAlert: activeAlert,
        activeAlerts: [],
      };
    }

    case fromPageAlert.LOAD_ACTIVE_ALERTS_SUCCESS: {
      const alerts = (<PageAlert[]>action.payload).map(alert => ({
        id: idFromPageAlert(alert),
        type: alert.type,
        message: alert.message,
        icon: alert.icon,
        dismissible: alert.dismissible,
      }));
      return {
        ...state,
        activeAlert: null,
        activeAlerts: alerts,
      };
    }

    case fromPageAlert.CLEAR_ACTIVE_ALERT: {
      return {
        ...state,
        activeAlert: null,
        activeAlerts: [],
      };
    }

    case fromPageAlert.ADD_PAGE_ALERT: {
      const alert = action.payload;
      const id = idFromPageAlert(alert);
      let updatedAlert;
      const existAlert = state.entities[id];
      if (existAlert) {
        updatedAlert = { ...existAlert, message: alert.message };
      } else {
        updatedAlert = { ...alert };
      }

      return adapter.upsertOne(updatedAlert, state);
    }
  }
  return state;
}

export const getPageAlertsLoaded = (state: PageAlertState) => state.loaded;
