import { Action } from '@ngrx/store';
import { EscrowAccount } from '@core/models/entities/escrow-account.entity';
import { EstablishEscrowAccountResponse } from '@core/models/billing/establish-escrow-account.response';
import { EstablishEscrowAccountRequest } from '@core/models/billing/establish-escrow-account.request';

export const enum EscrowActionTypes {
  ESTABLISH_ESCROW_ACCOUNT = '[Escrow] Establish Escrow Account',
  ESTABLISH_ESCROW_ACCOUNT_SUCCESS = '[Escrow] Establish Escrow Account Success',
  ESTABLISH_ESCROW_ACCOUNT_FAILURE = '[Escrow] Establish Escrow Account Failure',
  ADD_ESCROW_ACCOUNT = '[Escrow] Add Escrow Account',
}

export class EstablishEscrowAccount implements Action {
  readonly type = EscrowActionTypes.ESTABLISH_ESCROW_ACCOUNT;
  constructor(public payload: EstablishEscrowAccountRequest) {}
}

export class EstablishEscrowAccountSuccess implements Action {
  readonly type = EscrowActionTypes.ESTABLISH_ESCROW_ACCOUNT_SUCCESS;
  constructor(public payload: EstablishEscrowAccountResponse) {}
}

export class EstablishEscrowAccountFailure implements Action {
  readonly type = EscrowActionTypes.ESTABLISH_ESCROW_ACCOUNT_FAILURE;
  constructor(public payload?: any) {}
}

export class AddEscrowAccount implements Action {
  readonly type = EscrowActionTypes.ADD_ESCROW_ACCOUNT;
  constructor(public payload: EscrowAccount) {}
}

export type EscrowActions =
  | AddEscrowAccount
  | EstablishEscrowAccount
  | EstablishEscrowAccountSuccess
  | EstablishEscrowAccountFailure;
