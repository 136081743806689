<form>
  <div [formGroup]="form">
    <ng-container>
      <div class="document-checkbox">
        <label
          for="dwellingReplacementCostCheckbox"
          class="consent-label checkbox-input-label"
          >{{ dwellingReplacementCostDisclaimer }}
          <input
            id="dwellingReplacementCostCheckbox"
            type="checkbox"
            aria-describedby="dwellingReplacementCostCheckbox-error"
            formControlName="dwellingReplacementCostCheckbox"
            (change)="onConsentToggled($event)"
          />
          <span class="checkmark-input"></span>
        </label>
        <div
          *ngIf="
            dwellingReplacementCostCheckbox &&
            !dwellingReplacementCostCheckbox.value
          "
          class="custom-alert-dismissable-messages"
        >
          <a class="icon error consent-error"></a>
          <span id="dwellingReplacementCost-error"
            >Please acknowledge to proceed</span
          >
        </div>
      </div>
    </ng-container>
  </div>
</form>
