import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromActions from '@core/store/actions';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors/search.selector';
import { Observable } from 'rxjs';
import { SearchModel } from '@core/models/search/search.model';
import { SearchEntity } from '@core/models/entities/search.entity';

@Injectable({
  providedIn: 'root',
})
export class SearchDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  searchQuotes(model: SearchModel): void {
    this._store.dispatch(new fromActions.SearchQuotes(model));
  }

  getAllSearchQuotes(): Observable<SearchEntity[]> {
    return this._store.select(fromSelectors.getAllSearchQuotes());
  }

  declareSearchQuoteError(message: string): void {
    this._store.dispatch(new fromActions.SearchQuotesError(message));
  }

  clearError(): void {
    this._store.dispatch(new fromActions.SearchQuotesClearError());
  }

  getError(): Observable<string> {
    return this._store.select(fromSelectors.getSearchQuotesError());
  }
}
