import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';
import * as fromPolicyholder from '@core/store/reducers/policyholder.reducer';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromMailingAddress from '@core/store/selectors/mailing-address.selector';
import * as fromPrepopulatedInsured from '@core/store/selectors/prepopulated-insured.selector';
import * as fromPartialQuote from '@core/store/selectors/partial-quote.selector';

import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { UpdatePolicyholderRequest } from '@core/models/auto/quotes/update-policyholder-request.model';
import {
  PolicyholderTypes,
  DEFAULT_ID,
  MaritalStatusToDsmCodes,
} from '@shared/constants/app-constants';
import { PolicyholderBuilder } from '@shared/utils/builders/policyholder.builder';
import {
  McpContactPoint,
  ModifyContactPointRequest,
} from '@core/models/modify-contact-point/modify-contact-point-request.model';

export const getPolicyholderState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.policyholders
);

export const {
  selectAll: selectAllPolicyholders,
  selectEntities: selectPolicyholderEntities,
} = fromPolicyholder.adapter.getSelectors(getPolicyholderState);

export const getPrimaryNamedInsured = createSelector(
  selectAllPolicyholders,
  (policyholders): PolicyholderEntity =>
    policyholders.find(
      policyholder =>
        policyholder.policyHolderType ===
        PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
    )
);

export const getPrimaryInsuredName = createSelector(
  getPrimaryNamedInsured,
  (policyholder): string => {
    return policyholder &&
      policyholder.person &&
      !!policyholder.person.firstName &&
      !!policyholder.person.lastName
      ? policyholder.person.firstName + ' ' + policyholder.person.lastName
      : null;
  }
);

export const getPolicyholder = (id?: string) =>
  createSelector(
    selectPolicyholderEntities,
    getPrimaryNamedInsured,
    (entities, namedInsured): PolicyholderEntity => {
      if (!id) {
        return namedInsured;
      }
      if (entities[id]) {
        return entities[id];
      }
      for (const policyholder of Object.values(entities).filter(
        ph => ph.policyHolderIds
      )) {
        for (const phid of Object.values(policyholder.policyHolderIds)) {
          if (phid === id) {
            return policyholder;
          }
        }
      }
      return undefined;
    }
  );

export const getPolicyHolderAddress = createSelector(
  getPrimaryNamedInsured,
  fromPolicyAddress.getAllPolicyAddresses(),
  (pni, addressEntities) =>
    addressEntities.find(entity => entity.addressId === pni.addressId)
);

export const getAllPolicyholders = createSelector(
  selectAllPolicyholders,
  policyholders => policyholders
);

export const buildAddPolicyholderRequest = (
  policyholderEntity: PolicyholderEntity
) =>
  createSelector(
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    fromProducts.getProduct(policyholderEntity.productId),
    getPolicyHolderType(policyholderEntity.person),
    (address, product, policyHolderType) => {
      const policyHolderRequest =
        PolicyholderBuilder.buildPolicyholderRequestFromEntity(
          policyholderEntity,
          address
        );
      policyHolderRequest.policyHolderType = policyHolderType;
      const request: UpdatePolicyholderRequest = {
        quoteId: product.quoteId,
        productId: product.id,
        body: {
          policyHolder: policyHolderRequest,
          policyHolderId: policyHolderRequest.policyHolderId,
        },
      };

      return request;
    }
  );

export const buildUpdatePolicyholderRequest = (
  policyholderEntity: PolicyholderEntity
) =>
  createSelector(
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    fromMailingAddress.getAllMailingAddresses(),
    fromProducts.getProduct(policyholderEntity.productId),
    (policyAddress, mailingAddresses, product) => {
      let address = policyAddress;
      if (mailingAddresses.length > 0) {
        address = mailingAddresses[0];
      }

      const policyHolderRequest =
        PolicyholderBuilder.buildPolicyholderRequestFromEntity(
          policyholderEntity,
          address
        );

      const request: UpdatePolicyholderRequest = {
        quoteId: product.quoteId,
        productId: product.id,
        body: {
          policyHolder: policyHolderRequest,
          policyHolderId: policyHolderRequest.policyHolderId,
        },
      };

      return request;
    }
  );

export const getPolicyHolderType = policyHolder =>
  createSelector(
    getPrimaryNamedInsured,
    getAllPolicyholders,
    (pni, allPolicyHolders) => {
      if (!pni) {
        return PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED;
      }

      if (
        policyHolder.roleOnPolicy ===
        PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
      ) {
        return PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED;
      }

      const secondaryNamedInsuredExists = allPolicyHolders.some(
        policyHolder =>
          policyHolder.policyHolderType ===
          PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED
      );
      if (
        !secondaryNamedInsuredExists &&
        ((pni.person.maritalStatus === MaritalStatusToDsmCodes.Married &&
          (MaritalStatusToDsmCodes[policyHolder.maritalStatus] ===
            MaritalStatusToDsmCodes.Married ||
            policyHolder.maritalStatus === MaritalStatusToDsmCodes.Married)) ||
          (pni.person.maritalStatus === MaritalStatusToDsmCodes.Separated &&
            (MaritalStatusToDsmCodes[policyHolder.maritalStatus] ===
              MaritalStatusToDsmCodes.Separated ||
              policyHolder.maritalStatus ===
                MaritalStatusToDsmCodes.Separated)))
      ) {
        return PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED;
      } else {
        const secondaryNameInsuredPh = allPolicyHolders.find(
          ph =>
            ph.policyHolderId === policyHolder.policyHolderId &&
            ph.policyHolderType ===
              PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED
        );

        if (secondaryNameInsuredPh) {
          if (
            (secondaryNameInsuredPh.person.maritalStatus ===
              MaritalStatusToDsmCodes.Married &&
              MaritalStatusToDsmCodes[policyHolder.maritalStatus] ===
                MaritalStatusToDsmCodes.Married) ||
            (secondaryNameInsuredPh.person.maritalStatus ===
              MaritalStatusToDsmCodes.Separated &&
              MaritalStatusToDsmCodes[policyHolder.maritalStatus] ===
                MaritalStatusToDsmCodes.Separated)
          ) {
            return PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED;
          }
        } else {
          return PolicyholderTypes.POLICY_ADDITIONAL_NAMED_INSURED;
        }
      }
    }
  );

export const buildUpdateContactPointRequest = (contactPoint: McpContactPoint) =>
  createSelector(
    fromPrepopulatedInsured.getPrepopulatedInsuredId,
    fromPartialQuote.getPartialQuoteId,
    fromProducts.getSelectedProducts,
    getPrimaryNamedInsured,
    (prepopulatedInsuredId, partialQuoteId, selectedProducts, pni) => {
      const nssEmailRequest = <ModifyContactPointRequest>{};
      nssEmailRequest.mcpContactPoint = contactPoint;
      // In prepopulated flows DSM is caching on the prepopulatedInsuredId and
      // defaulting the policyHolderId to 1
      if (prepopulatedInsuredId) {
        nssEmailRequest.prepopulatedInsuredId = prepopulatedInsuredId;
        nssEmailRequest.policyHolderId = '1';
      } else if (partialQuoteId) {
        nssEmailRequest.partialQuoteId = partialQuoteId;
        nssEmailRequest.policyHolderId = '1';
      } else if (
        pni &&
        selectedProducts &&
        pni.policyHolderIds[selectedProducts[0].id]
      ) {
        nssEmailRequest.policyHolderId =
          pni.policyHolderIds[selectedProducts[0].id];
        nssEmailRequest.submissionNumber = selectedProducts[0].quoteId;
      }
      return nssEmailRequest;
    }
  );

export const getLoading = createSelector(
  getPolicyholderState,
  state => state.loading
);
