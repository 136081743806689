import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  HostBinding,
} from '@angular/core';
import { BillingModel } from '@core/models/billing/billing.model';
import { CoverageEntity } from '@core/models/entities/coverage.entity';
import { DiscountEntity } from '@core/models/entities/discount.entity';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { Product } from '@core/models/products/product.model';
import { TelematicsEnrollmentResponse } from '@core/models/telematics/telematics.model';
import {
  ProductTypes,
  DiscountDescriptions,
  BillingPaymentPlan,
} from '@shared/constants/app-constants';

@Component({
  selector: 'mq-coverage-premium-display',
  templateUrl: './coverage-premium-display.component.html',
  styleUrls: ['./coverage-premium-display.component.scss'],
})
export class CoveragePremiumDisplayComponent implements OnChanges {
  constructor() {}

  @HostBinding('class.col')
  iscol = true;

  @Input() premium: number;

  @Input() selectedProducts: Product[];

  @Input() isQuoteValid: boolean;

  @Input() isFormValid: boolean;

  @Input() forwardDisabled: boolean;

  @Input() quoteLetter: string;

  @Input() quoteLetterValid: boolean;

  @Input() selectedProduct: Product;

  @Input() quoteLetterHidden: boolean;

  @Input() discounts: DiscountEntity[];

  @Input() fullPayPremium: number;

  @Input() telematicsEnrollments: TelematicsEnrollmentResponse;

  @Input() coverages: CoverageEntity[];

  @Input() agent: boolean;

  @Input() quoteState: string;

  @Input() isBillingPaymentMethodApplicable: boolean;
  @Input() isFordMultiLineRedirectExperiment: boolean;
  @Input() paidInFullPowersportsModifier: EligibleDiscountEntity;
  @Input() billingInfo: BillingModel;

  @Input() isMonoQuoteAllowed: boolean;

  @Output() forward = new EventEmitter();
  @Output() stateSCLowBILimit = new EventEmitter<boolean>();

  monthly: any;

  isPaidInFullDiscountAppliedForAuto: boolean;
  isReftDiscountApplied: boolean;
  isEasyPayDiscountApplied: boolean;
  isSmartRideDiscountApplied: boolean;
  isSCLowBILimit: boolean;
  discountName: string;
  isPaidInFullDiscountAppliedForPS: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.isSCLowBILimit = this.checkSCLowBILimit();
    if (changes.selectedProducts || changes.discounts) {
      this.isPaidInFullDiscountAppliedForAuto = this.getAppliedDiscountForAuto(
        DiscountDescriptions.PaidInFullDiscount
      );
      this.isPaidInFullDiscountAppliedForPS =
        this.getAppliedDiscountForPowersports(
          DiscountDescriptions.PaidInFullDiscount
        );
      this.isReftDiscountApplied = this.getAppliedDiscountForAuto(
        DiscountDescriptions.RecurringEFTDiscount
      );
      this.isEasyPayDiscountApplied = this.getAppliedDiscountForAuto(
        DiscountDescriptions.EasyPayDiscount
      );
      this.isSmartRideDiscountApplied = this.getAppliedDiscountForAuto(
        DiscountDescriptions.Smartride
      );

      if (this.isPaidInFullDiscountAppliedForAuto) {
        this.discountName = DiscountDescriptions.PaidInFullDiscount;
      } else if (this.isReftDiscountApplied) {
        this.discountName = DiscountDescriptions.RecurringEFTDiscount;
      } else if (this.isEasyPayDiscountApplied) {
        this.discountName = DiscountDescriptions.EasyPayDiscount;
      } else if (this.isPaidInFullDiscountAppliedForPS) {
        this.discountName = DiscountDescriptions.PaidInFullDiscount;
      } else {
        this.discountName = '';
      }
    }
  }
  getAppliedDiscountForAuto(discountDescription: string): boolean {
    return this.discounts.some(discount => {
      return discount.description === discountDescription &&
        discount.isDiscountApplied === true &&
        discount.productId === ProductTypes.AUTO
        ? true
        : false;
    });
  }

  getAppliedDiscountForPowersports(discountDescription: string): boolean {
    return this.discounts.some(discount => {
      return discount.description === discountDescription &&
        discount.isDiscountApplied === true &&
        discount.productId === ProductTypes.POWERSPORTS
        ? true
        : false;
    });
  }

  isDiscountAppliedForAuto(): boolean {
    const autoProduct = this.selectedProducts.find(
      product => product.id == ProductTypes.AUTO
    );
    if (autoProduct && !autoProduct.hasError) {
      return (
        this.isPaidInFullDiscountAppliedForAuto ||
        this.isReftDiscountApplied ||
        this.isEasyPayDiscountApplied
      );
    } else return false;
  }

  isDiscountAppliedForPowersports(): boolean {
    const powersportsProduct = this.selectedProducts.find(
      product => product.id == ProductTypes.POWERSPORTS
    );
    if (powersportsProduct && !powersportsProduct.hasError) {
      return this.isPaidInFullDiscountAppliedForPS;
    } else return false;
  }

  isSmartMilesEnrollment(): boolean {
    const autoProduct = this.selectedProducts.find(
      product => product.id == ProductTypes.AUTO
    );
    let isEnrolled;
    if (
      this.telematicsEnrollments &&
      this.telematicsEnrollments.vehicleEnrollment &&
      this.telematicsEnrollments.vehicleEnrollment.vehicles.length !== 0
    ) {
      isEnrolled = this.telematicsEnrollments.vehicleEnrollment.vehicles.some(
        v => {
          return v.enrollmentStatus === 'Enrolled' &&
            v.vehicleProgram === 'SmartMiles'
            ? true
            : false;
        }
      );
    } else {
      isEnrolled = false;
    }
    if (autoProduct && !autoProduct.hasError && isEnrolled) {
      return true;
    } else return false;
  }
  checkSCLowBILimit(): boolean {
    if (
      this.selectedProducts.length === 1 &&
      this.selectedProduct.id === ProductTypes.AUTO &&
      !this.agent &&
      this.quoteState === 'SC' &&
      this.coverages.find(coverage => coverage.coverageId == 'BI')
        .selectedValue[0].value == '25000/50000'
    ) {
      return true;
    }

    return false;
  }

  isPaidInFullApplicableForAutoPS(): boolean {
    return this.discounts.some(discount => {
      return (
        discount.description === DiscountDescriptions.PaidInFullDiscount &&
        discount.isDiscountApplied === true &&
        this.isBillingPaymentMethodApplicable &&
        this.paidInFullPowersportsModifier?.selectedOptionValue === 'true'
      );
    });
  }

  showFullTermPremiumAmount(): boolean {
    if (
      !this.billingInfo &&
      this.isBillingPaymentMethodApplicable &&
      !this.isSmartMilesEnrollment()
    ) {
      return true;
    } else if (
      this.billingInfo?.payment?.paymentPlan === BillingPaymentPlan.FULL_PAY
    ) {
      return true;
    } else return false;
  }
}
