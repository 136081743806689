import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (window.location.hash.indexOf('state=eua-redirect') >= 0) {
  // This is an EUA response. Do nothing, and let OauthIframeService read our location.
} else {
  if (environment.production) {
    enableProdMode();
  }

  const bootstrapModule = () => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      // Allowing console.log since we don't have logging service available to use
      // eslint-disable-next-line no-console
      .catch(err => console.log(err));
  };

  bootstrapModule();
}
