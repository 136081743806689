import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';

import { RentersService } from '@core/services/renters.service';

@Injectable()
export class SeparateUnitsGuard implements CanActivate {
  constructor(private _rentersService: RentersService) {}
  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this._rentersService.getSeparateUnitsLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._rentersService.loadRentersSeparateUnits();
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
