<div [formGroup]="form">
  <div class="row">
    <div class="form-group col-9 col-md-6">
      <label for="street">Street Address, City, State, Zip</label>
      <input
        id="street"
        type="text"
        class="form-control"
        aria-describedby="street-error"
        formControlName="street"
        [ngbTypeahead]="search"
        autofocus
      />
      <div *ngIf="street.invalid && (street.dirty || street.touched)">
        <div
          *ngIf="street.errors.required"
          class="alert alert-danger"
          role="alert"
        >
          <a class="icon error"></a>
          <span id="street-error">Address is required</span>
        </div>
        <div
          *ngIf="street.errors.incomplete && !street.errors.required"
          class="alert alert-danger"
          role="alert"
        >
          <a class="icon error"></a>
          <span id="street-error">Incomplete address. Please try again. </span>
        </div>
      </div>
    </div>
    <div class="form-group col-2">
      <label for="unitNumber">Unit #</label>
      <input
        id="unitNumber"
        type="text"
        class="form-control"
        formControlName="unitNumber"
      />
    </div>
  </div>
</div>
