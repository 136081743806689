import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromNavigation from './navigation.reducer';

export interface BindState {
  navigation: fromNavigation.NavigationState;
}

export const reducers: ActionReducerMap<BindState> = {
  navigation: fromNavigation.reducer,
};

export const getBindState = createFeatureSelector<BindState>('bind');
