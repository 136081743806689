import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LoadingDao } from '@core/dao/loading.dao';

@Injectable()
export class LoadingService {
  constructor(private _loadingDao: LoadingDao) {}

  loadingBegin(upperMessage?: string, lowerMessage?: string): void {
    this._loadingDao.setMessages(upperMessage, lowerMessage);
    this._loadingDao.loadingBegin();
  }

  loadingEnd(): void {
    this._loadingDao.loadingEnd();
  }

  getAppLoading(): Observable<boolean> {
    return this._loadingDao.getAppLoading();
  }

  setMessages(upperMessage: string, lowerMessage: string) {
    this._loadingDao.setMessages(upperMessage, lowerMessage);
  }

  getUpperMessage(): Observable<string | null> {
    return this._loadingDao.getUpperMessage();
  }

  getLowerMessage(): Observable<string | null> {
    return this._loadingDao.getLowerMessage();
  }
}
