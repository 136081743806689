import { Injectable } from '@angular/core';
import { CoverageErrorDao } from '@core/dao/coverage-error.dao';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CoverageErrorService {
  constructor(private _coverageErrorDao: CoverageErrorDao) {}

  getCoverageErrors(): Observable<CoverageError[]> {
    return this._coverageErrorDao.getCoverageErrors();
  }

  removeCoverageErrors(): void {
    this._coverageErrorDao.removeCoverageErrors();
  }
}
