import { Action } from '@ngrx/store';

import { LocationExposureEntity } from '@core/models/entities/location-exposure.entity';
import { Update } from '@ngrx/entity';

export const enum LocationExposureActionTypes {
  ADD_LOCATION_EXPOSURE = '[Location Exposure] Add Location Exposure',
  ADD_LOCATION_EXPOSURE_FAIL = '[Location Exposure] Add Location Exposure Fail',
  ADD_LOCATION_EXPOSURE_SUCCESS = '[Location Exposure] Add Location Exposure Success',
  ADD_ALL_LOCATION_EXPOSURE_SUCCESS = '[Location Exposure] Add All Location Exposure Success',

  UPDATE_LOCATION_EXPOSURE = '[Location Exposure] Update Location Exposure',
  UPDATE_LOCATION_EXPOSURE_FAIL = '[Location Exposure] Update Location Exposure Fail',
  UPDATE_LOCATION_EXPOSURE_SUCCESS = '[Location Exposure] Update Location Exposure Success',

  DELETE_LOCATION_EXPOSURE = '[Location Exposure] Delete Location Exposure',
  DELETE_LOCATION_EXPOSURE_FAIL = '[Location Exposure] Delete Location Exposure Fail',
  DELETE_LOCATION_EXPOSURE_SUCCESS = '[Location Exposure] Delete Location Exposure Success',
}

export class AddLocationExposure implements Action {
  readonly type = LocationExposureActionTypes.ADD_LOCATION_EXPOSURE;
  constructor(public payload: LocationExposureEntity) {}
}

export class AddLocationExposureFail implements Action {
  readonly type = LocationExposureActionTypes.ADD_LOCATION_EXPOSURE_FAIL;
  constructor(public error: any) {}
}

export class AddLocationExposureSuccess implements Action {
  readonly type = LocationExposureActionTypes.ADD_LOCATION_EXPOSURE_SUCCESS;
  constructor(public payload: LocationExposureEntity) {}
}
export class AddAllLocationExposureSuccess implements Action {
  readonly type = LocationExposureActionTypes.ADD_ALL_LOCATION_EXPOSURE_SUCCESS;
  constructor(public payload: LocationExposureEntity[]) {}
}
export class UpdateLocationExposure implements Action {
  readonly type = LocationExposureActionTypes.UPDATE_LOCATION_EXPOSURE;
  constructor(public payload: LocationExposureEntity) {}
}

export class UpdateLocationExposureFail implements Action {
  readonly type = LocationExposureActionTypes.UPDATE_LOCATION_EXPOSURE_FAIL;
  constructor(public error: any) {}
}

export class UpdateLocationExposureSuccess implements Action {
  readonly type = LocationExposureActionTypes.UPDATE_LOCATION_EXPOSURE_SUCCESS;
  constructor(public payload: Update<LocationExposureEntity>) {}
}
export class DeleteLocationExposure implements Action {
  readonly type = LocationExposureActionTypes.DELETE_LOCATION_EXPOSURE;
  constructor(public payload: LocationExposureEntity) {}
}

export class DeleteLocationExposureFail implements Action {
  readonly type = LocationExposureActionTypes.DELETE_LOCATION_EXPOSURE_FAIL;
  constructor(public error: any) {}
}

export class DeleteLocationExposureSuccess implements Action {
  readonly type = LocationExposureActionTypes.DELETE_LOCATION_EXPOSURE_SUCCESS;
  constructor(public payload: LocationExposureEntity) {}
}

export type LocationExposureActions =
  | AddLocationExposure
  | AddLocationExposureFail
  | AddLocationExposureSuccess
  | AddAllLocationExposureSuccess
  | UpdateLocationExposure
  | UpdateLocationExposureFail
  | UpdateLocationExposureSuccess
  | DeleteLocationExposure
  | DeleteLocationExposureFail
  | DeleteLocationExposureSuccess;
