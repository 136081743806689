import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RemoveIfFeatureNotEffectiveDirective } from './remove-if-feature-not-effective.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [RemoveIfFeatureNotEffectiveDirective],
  exports: [RemoveIfFeatureNotEffectiveDirective],
})
export class RemoveIfFeatureNotEffectiveModule {}
