import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
} from '@angular/core';
import {
  AgencyService,
  AppConfigService,
  ProductsService,
} from '@core/services';
import { Observable } from 'rxjs';
import { AgencyModel } from '@core/models/agency/agency.model';
import { PrivateLabel } from '@core/models/private-label/private-label.model';
import { Product } from '@core/models/products/product.model';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'mq-nw-header',
  templateUrl: './nw-header.component.html',
  styleUrls: ['./nw-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NwHeaderComponent implements OnInit, OnChanges {
  @Input() configLoaded: boolean;
  @Input() privateLabelConfig: PrivateLabel;
  @Input() phoneNumber: string;

  agent$: Observable<AgencyModel>;
  agent: AgencyModel;
  agentProducts$: Observable<Product[]>;
  directProducts$: Observable<Product[]>;
  routeUrl$: Observable<string>;

  websiteUrl = 'https://www.nationwide.com';
  logoUrl = 'assets/site/images/NandEagleHorz.svg';
  logoLabel = 'Nationwide Insurance';
  additionalPhone: string;
  defaultLogoWidth = '180px';
  isTest = false;
  awsRouterCookieValue: string;
  targetEnv: string;

  constructor(
    private _agencyService: AgencyService,
    private _productsService: ProductsService,
    private _router: Router,
    private _appConfigService: AppConfigService
  ) {}

  ngOnInit(): void {
    this.agent$ = this._agencyService.getAgency();
    this.agentProducts$ = this._productsService.getSelectedProductsForHeader();
    this.directProducts$ =
      this._productsService.getSelectedProductsForDirectHeader();
    this.routeUrl$ = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        return event.urlAfterRedirects;
      })
    );
    this.isTest = this._appConfigService.isTest();
    this.awsRouterCookieValue =
      this._appConfigService.getAwsRouterCookieValue();
    this.targetEnv = this._appConfigService.config.targetEnv;
  }

  ngOnChanges() {
    if (this.privateLabelConfig) {
      this.updateBranding();
    }
  }

  private updateBranding(): void {
    this.websiteUrl = this.privateLabelConfig.websiteUrl
      ? this.privateLabelConfig.websiteUrl
      : this.websiteUrl;
    if (this.privateLabelConfig.logoUrl) {
      this.logoUrl = this.privateLabelConfig.logoUrl;
      this.logoLabel = this.privateLabelConfig.agencyName;
    }
    this.phoneNumber = this.privateLabelConfig.phoneNumber
      ? this.privateLabelConfig.phoneNumber.trim()
      : this.phoneNumber;
    this.additionalPhone = this.privateLabelConfig.additionalPhoneNumber
      ? this.privateLabelConfig.additionalPhoneNumber.trim()
      : undefined;
  }
}
