import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';

import * as fromSelectors from '@core/store/selectors';
import * as fromStore from '@core/store';
import { RemoveAllCoverageErrors } from '@core/store';

@Injectable({
  providedIn: 'root',
})
export class CoverageErrorDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getCoverageErrors(): Observable<CoverageError[]> {
    return this._store.select(fromSelectors.selectAllCoverageErrors);
  }

  removeCoverageErrors(): void {
    this._store.dispatch(new RemoveAllCoverageErrors());
  }
}
