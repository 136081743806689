import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
  selector: 'nw-display-item-list',
  templateUrl: './display-item-list.component.html',
  styleUrls: ['./display-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayItemListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
