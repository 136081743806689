import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService, LoggingService } from '@core/services';
import { catchError, map } from 'rxjs/operators';
import {
  PrepopulatedInsured,
  PrepopulatedInsuredRequest,
} from '@core/models/prepopulated-insured/prepopulated-insured.model';
import { Observable, of } from 'rxjs';
import { HeadersUtils } from '@shared/utils/headers.utils';
import { HttpParamsUtil } from '@shared/utils/http-params.util';

@Injectable({
  providedIn: 'root',
})
export class PrepopulatedInsuredAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfigService: AppConfigService,
    private _loggingService: LoggingService
  ) {}

  getPrepopulatedInsured(
    request: PrepopulatedInsuredRequest
  ): Observable<PrepopulatedInsured> {
    const url = `${this._appConfigService.config.pcEdgeAutoUrl}/prepopulated-insureds/${request?.guid}`;

    const headers = HeadersUtils.buildHeaderForDSMCalls(
      this._appConfigService.config.apiKey,
      request?.accessToken,
      request?.sessionId,
      this._appConfigService.isTest()
        ? this._appConfigService.config.targetEnv
        : '',
      request?.userContext
    );
    const params = HttpParamsUtil.buildHttpParamsWithPartnerName(
      request?.userContext
    );
    return this._http
      .get<PrepopulatedInsured>(url, { headers, params })
      .pipe(map(response => response))
      .pipe(
        catchError((error: any) => {
          this._loggingService.log('getPrepopulatedInsured Error', error);
          return of(null);
        })
      );
  }
}
