import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductTypes } from '@shared/constants/app-constants';
import { BaseDsmAdapter } from './base-dsm.adapter';

export interface AddMortgageRequest {
  accessToken: string;
  sessionId: string;
  quoteId: string;

  address: {
    address: string;
    unitNumber: string;
    city: string;
    state: string;
    zip: string;
  };
  escrow: boolean;
  loanNumber: string;
  name: string;
  companyId?: string;
  mortgageType?: string;
  mortgageId?: string;
  type?: string;
}

export interface AddMortgageResponse {
  mortgageId: string;
  mortgageType: string;
  type: string;
  companyId: string;
  loanNumber: string;
  escrow: boolean;
  address?: {
    addressId: string;
    addressLine1: string;
    city: string;
    state: string;
    postalCode: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class MortgageAdapter extends BaseDsmAdapter {
  getMortgages(
    quoteId: string,
    productId: string
  ): Observable<AddMortgageResponse[]> {
    return this.get(this.getAddMortgageUrl(quoteId, productId), {
      productId,
    });
  }

  addOrUpdateMortgage(
    request: AddMortgageRequest,
    productId: string
  ): Observable<AddMortgageResponse> {
    return this.lenderAlreadyExists(request)
      ? this.updateMortgage(request, productId)
      : this.addMortgage(request, productId);
  }

  addMortgage(
    request: AddMortgageRequest,
    productId: string
  ): Observable<AddMortgageResponse> {
    return this.post(
      this.getAddMortgageUrl(request.quoteId, productId),
      this.composeRequestBody(request),
      {
        productId: productId,
      }
    ).pipe(map(inResponse => this.mapResponse(inResponse, request)));
  }

  updateMortgage(
    request: AddMortgageRequest,
    productId: string
  ): Observable<AddMortgageResponse> {
    return this.put(
      this.getUpdateMortgageUrl(request.quoteId, productId, request.mortgageId),
      this.composeRequestBody(request, true),
      {
        productId: productId,
      }
    ).pipe(map(inResponse => this.mapResponse(inResponse, request)));
  }

  deleteMortgage(request: any, productId: string) {
    return this.delete(
      this.getDeleteMortgageUrl(
        request.quoteId,
        productId,
        request.lender.mortgage.mortgageId
      ),
      {
        productId: request.productId,
      }
    );
  }

  private composeRequestBody(request: AddMortgageRequest, forUpdate?: boolean) {
    const body = <any>{
      mortgageType: request.mortgageType || 'Mortgagee_Ext',
      type: request.type,
      loanNumber: request.loanNumber,
      address: {
        addressLine1: request.address.address,
        city: request.address.city,
        state: request.address.state,
        postalCode: request.address.zip,
      },
      companyId: request.companyId,
      companyName: request.name,
    };
    if (request.mortgageId) {
      body.mortgageId = request.mortgageId;
    }
    if (forUpdate) {
      return {
        mortgage: body,
      };
    } else {
      return {
        mortgages: [body],
      };
    }
  }

  private mapResponse(input: any, request: AddMortgageRequest) {
    const output = <any>{};
    if (input && input.length) {
      for (const mortgage of input) {
        if (mortgage.mortgageId) {
          output.mortgageId = mortgage.mortgageId;
          output.companyId = mortgage.companyId;
          output.companyName = mortgage.companyName;
          output.mortgageType = mortgage.mortgageType;
          output.type = mortgage.type;
          output.loanNumber = mortgage.loanNumber;
          output.escrow = request.escrow;
          if (mortgage.address) {
            output.address = {
              addressId: mortgage.address.addressId,
              addressLine1: mortgage.address.addressLine1,
              city: mortgage.address.city,
              state: mortgage.address.state,
              postalCode: mortgage.address.postalCode,
            };
          }
        }
      }
    } else if (input) {
      output.mortgageId = input.mortgageId;
      output.companyId = input.companyId;
      output.companyName = input.companyName;
      output.mortgageType = input.mortgageType;
      output.type = input.type;
      output.loanNumber = input.loanNumber;
      output.escrow = request.escrow;
      if (input.address) {
        output.address = {
          addressId: input.address.addressId,
          addressLine1: input.address.addressLine1,
          city: input.address.city,
          state: input.address.state,
          postalCode: input.address.postalCode,
        };
      }
    }
    if (!output.mortgageId) {
      throw input;
    }
    return output;
  }

  private lenderAlreadyExists(request: AddMortgageRequest): boolean {
    if (request.mortgageId) {
      return true;
    }
    return false;
  }

  private getAddMortgageUrl(quoteId, productId): string {
    return productId === ProductTypes.HOMEOWNERS
      ? this._appConfig.config.pcEdgeHomeownersUrl +
          `/quotes/${quoteId}/mortgages`
      : this._appConfig.config.pcEdgeCondoUrl + `/quotes/${quoteId}/mortgages`;
  }

  private getUpdateMortgageUrl(quoteId, productId, mortgageId) {
    return productId === ProductTypes.HOMEOWNERS
      ? this._appConfig.config.pcEdgeHomeownersUrl +
          `/quotes/${quoteId}/mortgages/${mortgageId}`
      : this._appConfig.config.pcEdgeCondoUrl +
          `/quotes/${quoteId}/mortgages/${mortgageId}`;
  }

  private getDeleteMortgageUrl(quoteId, productId, mortgageId): string {
    return productId === ProductTypes.HOMEOWNERS
      ? this._appConfig.config.pcEdgeHomeownersUrl +
          `/quotes/${quoteId}/mortgages/${mortgageId}`
      : this._appConfig.config.pcEdgeCondoUrl +
          `/quotes/${quoteId}/mortgages/${mortgageId}`;
  }
}
