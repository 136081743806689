import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  AbstractControl,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomValidators } from '@core/validators/custom-validators';
import { AgencyModel } from '@core/models/agency/agency.model';

@Component({
  selector: 'mq-agency-form',
  templateUrl: './agency-form.component.html',
  styleUrls: ['./agency-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyFormComponent implements OnInit {
  isAppLoading$: Observable<boolean>;
  @Input() states: string[];
  @Input() activeStates: string[];
  @Input() agencyChannels: string[];
  @Input() isAppLoading: boolean;
  @Input() agent: AgencyModel;

  @Output() back = new EventEmitter<any>();
  @Output() formSubmit = new EventEmitter<AgencyModel>();

  agencyForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
    this.agencyForm.patchValue(this.agent);
  }

  buildForm(): void {
    this.agencyForm = this._fb.group({
      userId: this._fb.control('', [Validators.required]),
      firstName: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.SpecialCharacterStringOnly,
      ]),
      lastName: this._fb.control('', [
        Validators.required,
        CustomValidators.SpecialCharacterStringOnly,
      ]),
      producerCode: this._fb.control('', [Validators.required]),
      agencyCode: this._fb.control('', []),
      email: this._fb.control('', [Validators.required, Validators.email]),
      quoteState: this._fb.control('', [Validators.required]),
      state: this._fb.control('', [Validators.required]),
      agencyChannel: this._fb.control('', [Validators.required]),
    });
  }

  get userId(): AbstractControl {
    return this.agencyForm.get('userId');
  }

  get firstName(): AbstractControl {
    return this.agencyForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.agencyForm.get('lastName');
  }

  get producerCode(): AbstractControl {
    return this.agencyForm.get('producerCode');
  }

  get agencyCode(): AbstractControl {
    return this.agencyForm.get('agencyCode');
  }

  get email(): AbstractControl {
    return this.agencyForm.get('email');
  }

  get quoteState(): AbstractControl {
    return this.agencyForm.get('quoteState');
  }

  get state(): AbstractControl {
    return this.agencyForm.get('state');
  }

  get agencyChannel(): AbstractControl {
    return this.agencyForm.get('agencyChannel');
  }

  onSubmit(form: UntypedFormGroup): void {
    const { valid, value } = form;
    if (valid) {
      this.formSubmit.emit(value);
    } else {
      CustomValidators.MarkFormGroupTouched(this.agencyForm);
    }
  }
}
