import { Injectable } from '@angular/core';
import { Product } from '@core/models/products/product.model';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { take, tap, map, withLatestFrom } from 'rxjs/operators';
import { RecoverableErrorEntity } from '@core/models/recoverable-error/recoverable-error.entity';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';
import { NrErrorUtils } from '@shared/utils/nr-error.utils';
import { AgencyService } from '../agency.service';

interface ErrorMessageData {
  // This is the order of columns in the data file:
  serviceName?: string;
  errorCode: string;
  developerMessage?: string;
  action?: string;
  userMessage?: string;
  channel?: string;
}

export const ERROR_CHANNEL_AGENT = 'Agent';
export const ERROR_CHANNEL_DIRECT = 'Direct';
export const ERROR_CHANNEL_EVERYONE = 'Everyone';

export const ERROR_CODE_DEFAULT = 'Null/Blank';

export const KEY_RULE_ID = 'ruleId';
export const KEY_CODE = 'code';
export const KEY_USER_MESSAGE = 'userMessage';
export const KEY_DEVELOPER_MESSAGE = 'developerMessage';
export const ERROR_CHANNEL_PRIVATE_LABEL = 'Private Label';

@Injectable({
  providedIn: 'root',
})
export class ErrorHelper {
  static readonly RECURSION_LIMIT = 8;
  static readonly LONGEST_REALISTIC_ERROR_CODE = 9;
  static readonly WEB_SERVICE_STRING_LIMIT = 500;

  static readonly ERR_GENERAL_BILLING = 'NWX9965';
  static readonly ERR_GENERAL_EUA = 'NWX9964';
  static readonly ERR_GENERAL_SEARCH = 'NWX9954';
  static readonly ERR_GENERAL_RETRIEVE = 'NWX9953';
  static readonly ERR_GENERAL_KNOCKOUT = 'NWX9952';
  static readonly ERR_PREBIND_DOCS = 'NWX9982';
  static readonly ERR_RISK_FILTER = 'NWX9991';
  static readonly ERR_DOWNPAYMENT = 'NWX9958';
  static readonly ERR_QUOTE_BLOCK = 'NWX9996';

  messageData: ErrorMessageData[] = [];
  defaultErrorMessage = 'Sorry, an error occurred'; // Can be overwritten by data file.
  isPrivateLabel: boolean;

  constructor(
    private _httpClient: HttpClient,
    private _agencyService: AgencyService
  ) {
    // Pre-fetch the data as soon as we instantiate.
    this._requireMessageData().pipe(take(1)).subscribe();
  }

  getErrorMessageForProduct(
    product: Product,
    isAgent: boolean
  ): Observable<string> {
    if (!product || !product.hasError) {
      return of('');
    }
    if (!product.errorCode) {
      return of(this.defaultErrorMessage);
    }
    return this._getDataRow(product.errorCode, isAgent).pipe(
      map(dataRow => {
        if (dataRow && dataRow.userMessage) {
          return dataRow.userMessage;
        }
        return this.defaultErrorMessage;
      })
    );
  }

  getErrorMessageForCode(
    errorCode: string,
    isAgent: boolean,
    useDefault = true
  ): Observable<string> {
    if (!errorCode) {
      return of(useDefault ? this.defaultErrorMessage : null);
    }
    if (errorCode.length > ErrorHelper.LONGEST_REALISTIC_ERROR_CODE) {
      return of(useDefault ? errorCode : null);
    }
    return this._getDataRow(errorCode, isAgent).pipe(
      map(dataRow => {
        if (dataRow && dataRow.userMessage) {
          return dataRow.userMessage;
        }
        return useDefault ? this.defaultErrorMessage : null;
      })
    );
  }

  getRecoverableErrorForCode(
    code: string,
    productId: string,
    isAgent: boolean,
    pageId?: string
  ): Observable<RecoverableErrorEntity> {
    return this._getDataRow(code, isAgent).pipe(
      map(dataRow =>
        this.composeRecoverableErrorFromDataRow(dataRow, productId, pageId)
      )
    );
  }

  private _getDataRow(
    code: string,
    isAgent: boolean
  ): Observable<ErrorMessageData | null> {
    return this._requireMessageData().pipe(
      withLatestFrom(this._agencyService.isPrivateLabel()),
      map(([_, isPrivateLabel]) => {
        const channelName = isAgent
          ? ERROR_CHANNEL_AGENT
          : isPrivateLabel
          ? ERROR_CHANNEL_PRIVATE_LABEL
          : ERROR_CHANNEL_DIRECT;
        return this.messageData.find(
          row =>
            row.errorCode === code &&
            (row.channel === ERROR_CHANNEL_EVERYONE ||
              row.channel === channelName)
        );
      }),
      take(1)
    );
  }

  composeRecoverableErrorFromDataRow(
    dataRow: ErrorMessageData | null,
    productId: string,
    pageId: string | null
  ): RecoverableErrorEntity {
    if (!dataRow) {
      return {
        message: this.defaultErrorMessage,
        productId: productId,
        page: pageId,
      };
    }
    return {
      code: dataRow.errorCode,
      message: dataRow.userMessage || this.defaultErrorMessage,
      productId: productId,
      page: pageId,
      // TODO field from dataRow
    };
  }

  minDwellingAmount(input: any): CoverageError[] {
    const minDwellingAmountErrorArray: CoverageError[] = [];
    const extractedErrorMessage = this.extractErrorMessageFromAnything(
      input.error
    );
    const regex = /\d{4,6}/g;
    const result = extractedErrorMessage.match(regex);
    const minDwellingAmount = `Dwelling (Building Additions & Alterations) limit cannot be less than $${result}.`;
    if (extractedErrorMessage.includes('[PDW050]')) {
      minDwellingAmountErrorArray.push({
        ruleId: this.extractErrorCodeFromAnything(input.error),
        message: minDwellingAmount,
        level: input.error.code,
      });
    }
    return minDwellingAmountErrorArray;
  }

  extractCoverageErrorFromAnything(input: any): CoverageError[] {
    let returnCoverageErrorArray: CoverageError[] = [];
    if (input && input.error) {
      returnCoverageErrorArray = this.minDwellingAmount(input);
      if (!returnCoverageErrorArray.length) {
        returnCoverageErrorArray.push({
          ruleId: this.extractErrorCodeFromAnything(input.error),
          message: this.extractErrorMessageFromAnything(input.error),
          level: input.error.code,
        });
      }
    }
    if (!returnCoverageErrorArray.length) {
      returnCoverageErrorArray.push({
        ruleId: this.extractErrorCodeFromAnything(input),
        message: this.extractErrorMessageFromAnything(input),
        level: '',
      });
    }
    return returnCoverageErrorArray;
  }

  extractErrorCodeFromAnything(input: any): string {
    try {
      return this._extractFromErrorMessageRecursively(
        input,
        ErrorHelper.RECURSION_LIMIT,
        false
      );
    } catch (whatever) {
      return '';
    }
  }

  extractErrorMessageFromAnything(input: any): string {
    try {
      return this._extractFromErrorMessageRecursively(
        input,
        ErrorHelper.RECURSION_LIMIT,
        true
      );
    } catch (whatever) {
      return '';
    }
  }

  isNatGenError(errorCode: string): boolean {
    return false;
  }

  sanitizeError<T>(input: T): T {
    if (input && typeof input === 'object') {
      input = NrErrorUtils.sanitizeError(input);
      const code = this.extractErrorCodeFromAnything(input);
      if (code) {
        input = this.addErrorCodeToObject(input, code);
      }
      if ((input as any).error) {
        (input as any).error = this.truncateWebServiceErrorStrings(
          (input as any).error
        );
      }
    }
    return input;
  }

  truncateWebServiceErrorStrings(input: any): any {
    if (input) {
      input = this.truncateWebServiceString(input, 'userMessage');
      input = this.truncateWebServiceString(input, 'developerMessage');
    }
    return input;
  }

  truncateWebServiceString(input: any, key: string): any {
    if (input && input[key]) {
      if (input[key].length > ErrorHelper.WEB_SERVICE_STRING_LIMIT) {
        input = {
          ...input,
          [key]:
            input[key].substr(0, ErrorHelper.WEB_SERVICE_STRING_LIMIT) + '...',
        };
      }
    }
    return input;
  }

  addErrorCodeToObject<T>(input: T, code: string): T {
    if (input['developerMessage'] || input['userMessage']) {
      input['nwxErrorCode'] = code;
    } else {
      if (input['error']) {
        input['error'] = this.addErrorCodeToObject(input['error'], code);
      }
    }
    return input;
  }

  private _extractFromErrorMessageRecursively(
    input: any,
    recursionLimit: number,
    returnMessage: boolean
  ): string {
    if (--recursionLimit < 0) {
      return '';
    }
    if (!input) {
      return '';
    }
    if (typeof input === 'string') {
      return this._extractFromErrorString(input, recursionLimit, returnMessage);
    }
    if (typeof input === 'object') {
      return this._extractErrorCodeFromObject(
        input,
        recursionLimit,
        returnMessage
      );
    }
    return '';
  }

  private _extractFromErrorString(
    input: string,
    recursionLimit: number,
    returnMessage: boolean
  ): string {
    // DSM sometimes includes JSON-encoded error objects inside their error objects.
    try {
      const decoded = JSON.parse(input);
      return this._extractFromErrorMessageRecursively(
        decoded,
        recursionLimit,
        returnMessage
      );
    } catch (e) {}
    if (input.match(/^[A-Z]{1,3}\d{4}$/)) {
      return input;
    }
    const matched = input.match(/.*\[([a-zA-Z0-9_-]+)\][^\w]*$/);
    if (!matched && returnMessage) {
      return input;
    }
    if (!matched) {
      return '';
    }
    return (returnMessage ? matched[0] : matched[1]) || '';
  }

  private _extractErrorCodeFromObject(
    input: any,
    recursionLimit: number,
    returnMessage: boolean
  ): string {
    for (const key of Object.keys(input)) {
      const value = input[key];
      if (key === KEY_RULE_ID && this.looksLikeErrorCode(value)) {
        return value;
      }
      if (key === KEY_CODE && this.looksLikeErrorCode(value)) {
        return value;
      }
      const code = this._extractFromErrorMessageRecursively(
        value,
        recursionLimit,
        returnMessage
      );
      if (code) {
        return code;
      }
    }
    return this._lastResortCheckKnownUntaggedErrors(input);
  }

  private _lastResortCheckKnownUntaggedErrors(input: any): string {
    let result = '';
    const developerMessage = input[KEY_DEVELOPER_MESSAGE];
    if ((result = this._errorCodeFromMessage(developerMessage))) {
      return result;
    }
    const userMessage = input[KEY_USER_MESSAGE];
    if ((result = this._errorCodeFromMessage(userMessage))) {
      return result;
    }
    return '';
  }

  private _errorCodeFromMessage(message: string): string {
    if (message) {
      if (message['issues']) {
        // just use the first message because this should never happen.
        message = message['issues'][0].message.toLowerCase().trim();
      } else {
        message = message.toLowerCase().trim();
      }

      if (
        message.startsWith(
          'nationwide is not returning a rate for this risk via this interface.'
        )
      ) {
        return 'NWX9991';
      }
      if (message.startsWith('we were unable to find your address')) {
        return 'NWX9999';
      }
      if (
        message ===
        'due to information obtained from reports, our system is unable to continue with a quote or offer a premium.'
      ) {
        return 'NWX9998';
      }
      if (message === 'system failure') {
        return 'NWX9997';
      }
      if (
        message ===
        'an error occurred during quote. the submission cannot be quoted.'
      ) {
        return 'NWX9996';
      }
      if (
        message ===
        'the submission has already been purchased and cannot be updated.'
      ) {
        return 'NWX9995';
      }
      if (message.startsWith('report ordering services are down')) {
        return 'NWX9994';
      }
      if (message === 'duplicate driver.') {
        return 'NWX9990';
      }
      if (message === 'bad request') {
        return 'NWX9987';
      }
      if (
        message === 'driver modifications would conflict with another driver.'
      ) {
        return 'NWX9986';
      }
      if (message === 'invalid session') {
        return 'NWX9985';
      }
      if (message.indexOf('license number format is incorrect') >= 0) {
        return 'NWX9984';
      }
      if (message === 'server error') {
        return 'NWX9982';
      }
      if (message === 'producer license is not active for this state') {
        return 'NWX9978';
      }
      if (message === 'producer code is invalid.') {
        return 'NWX9977';
      }
      if (
        message.endsWith('vehicle is primarily used for business purposes.')
      ) {
        return 'NWX9967';
      }
      if (message.includes('each driver must be assigned')) {
        return 'NWX9951';
      }
      if (message.includes('each vehicle must be assigned')) {
        return 'NWX9950';
      }
      // Removing this, but the error code is reserved, the includes statement may return with a slightly adjusted text match.
      // if (message.includes('uw issues block binding')) {
      //   return 'NWX9949';
      // }
      if (message.includes('tsunami binding suspension in your area')) {
        return 'NWX9948';
      }
      if (
        message.includes('volcanic activity binding suspension in your area')
      ) {
        return 'NWX9947';
      }
      if (message.includes('wildfire binding suspension in your area')) {
        return 'NWX9946';
      }
      if (
        message.includes(
          'hurricane/tropical storm binding suspension in your area'
        )
      ) {
        return 'NWX9945';
      }
      if (
        message.includes(
          'the policyholder will need to call nationwide to get a quote for an existing product'
        )
      ) {
        return 'NWX9944';
      }
      if (message.includes('trike')) {
        return 'NWX9966';
      }

      const matchValidationError = message.match(
        /^validation error\. the request provided had invalid input for \[(.*)\]$/
      );
      if (matchValidationError) {
        const fields = matchValidationError[1]
          .split(/, */)
          .map(s => s.replace(/^.*\./, ''));
        if (fields.includes('dateofbirth')) {
          return 'NWX9993';
        }
        if (fields.includes('licensenumber')) {
          return 'NWX9992';
        }
        if (fields.includes('agefirstlicensed')) {
          return 'NWX9988';
        }
        if (fields.includes('effectivedate')) {
          return 'NWX9983';
        }
        if (fields.includes('addressline1')) {
          return 'NWX9981';
        }
        if (fields.includes('firstname')) {
          return 'NWX9980';
        }
        if (fields.includes('lastname')) {
          return 'NWX9979';
        }
      }
    }
    return '';
  }

  looksLikeErrorCode(value: any): boolean {
    if (typeof value !== 'string') {
      return false;
    }
    return !!value.match(/^[A-Z]{1,}\d{3,}$/);
  }

  private _requireMessageData(): Observable<ErrorMessageData[]> {
    if (this.messageData.length > 0) {
      return of(this.messageData);
    }
    return this._httpClient
      .get('/assets/api/metadata/error-codes.tsv', { responseType: 'text' })
      .pipe(
        map(response => this._parseMessageData(response)),
        tap(messageData => (this.messageData = messageData))
      );
  }

  private _parseMessageData(input: string): ErrorMessageData[] {
    const output = [];
    let inputPosition = 0;
    while (inputPosition < input.length) {
      let newlinePosition = input.indexOf('\n', inputPosition);
      if (newlinePosition < 0) {
        newlinePosition = input.length;
      }
      const line = input.substring(inputPosition, newlinePosition);
      this._parseMessageDataLine(output, line);
      inputPosition = newlinePosition + 1;
    }
    return output;
  }

  private _parseMessageDataLine(output: ErrorMessageData[], input: string) {
    const cells = input.split('\t').map(s => s.trim());
    /* eslint-disable no-magic-numbers */
    if (cells.length >= 2) {
      if (cells[1] === ERROR_CODE_DEFAULT) {
        this.defaultErrorMessage = cells[4];
      } else {
        output.push({
          serviceName: cells[0],
          errorCode: cells[1],
          developerMessage: cells[2],
          action: cells[3],
          userMessage: cells[4],
          channel: cells[5],
        });
      }
    }
    /* eslint-enable no-magic-numbers */
  }
}
