import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RetrieveModel } from '@core/models/retrieve/retrieve.model';
import { RetrieveService } from '@core/services/retrieve.service';
import { Store, Action } from '@ngrx/store';
import * as fromSelector from '@core/store/selectors/retrieve.selector';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions/quote.action';

import {
  LoggingService,
  LoadingService,
  UserContextService,
  PageAlertService,
  AgencyService,
  ProductsService,
} from '@core/services';
import { QuoteService } from '@core/services/quote.service';
import { tap, take, switchMap, withLatestFrom } from 'rxjs/operators';
import { ofType, Actions } from '@ngrx/effects';
import {
  RetrieveEntity,
  RetrieveFailure,
} from '@core/models/entities/retrieve.entity';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { PageAlertIDs, RatingTypes } from '@shared/constants/app-constants';
import { UserContext } from '@core/models/user-context/user-context.model';
import { Product } from '@core/models/products/product.model';
import {
  BypassFields,
  BypassRetrieveResponse,
} from '@core/models/bypass-retrieve/bypass-retrieve.model';

@Injectable()
export class RetrieveProxy {
  constructor(
    private _loggingService: LoggingService,
    private _retrieveService: RetrieveService,
    private _router: Router,
    private _store: Store<fromStore.AppState>,
    private _loadingService: LoadingService,
    private _quoteService: QuoteService,
    private actions$: Actions,
    private _errorHelper: ErrorHelper,
    private _agencyService: AgencyService,
    private _userContextService: UserContextService,
    private _pageAlertService: PageAlertService,
    private _productsService: ProductsService
  ) {}

  submitRetrievalRequest(model: RetrieveModel) {
    this._retrieveService.retrieveQuote(model);
  }

  logPageLoaded() {
    this._loggingService.logToSplunk({
      event: 'RETRIEVE_LOAD',
    });
  }

  logPageSubmit(eventName: string, model: RetrieveModel) {
    this._loggingService.logRetrieve(eventName, model);
  }

  extractErrorCodeFromAnything(anything: any): string {
    return this._errorHelper.extractErrorCodeFromAnything(anything);
  }

  clearRetrieveFailures() {
    this._retrieveService.clearFailures();
  }

  setRetrieveError(message: RetrieveFailure) {
    this._retrieveService.declareRetrieveQuoteFailure(message);
  }

  setAllRetrieveQuotesFailedFlag() {
    this._retrieveService.declareAllRetrieveQuotesFailed();
  }

  isAgent(): Observable<boolean> {
    return this._agencyService.isAgent();
  }

  getRetrieveErrorMessage(): Observable<string> {
    return this._store.select(fromSelector.getRetrieveErrorCode()).pipe(
      withLatestFrom(this.isAgent()),
      switchMap(([code, isAgent]) => {
        return code
          ? this._errorHelper.getErrorMessageForCode(code, isAgent)
          : of('');
      })
    );
  }

  getRetrieveSuccess(): Observable<boolean> {
    return this._store.select(fromSelector.getRetrieveSuccess());
  }

  getRetrieveLoading(): Observable<number> {
    return this._store.select(fromSelector.getRetrieveLoading());
  }

  getAppLoading(): Observable<boolean> {
    return this._loadingService.getAppLoading();
  }

  loadingBegin() {
    this._loadingService.loadingBegin();
  }

  loadingEnd() {
    this._loadingService.loadingEnd();
  }

  navigateToGettingStarted(): Promise<boolean> {
    this._pageAlertService.addAlert(
      'PNI',
      PageAlertIDs['getting-started'],
      'Please review prefilled information for accuracy.'
    );
    return this._router.navigate(['/multi-quote/getting-started']);
  }

  navigateToQuoteCoverages(): Promise<boolean> {
    return this._router.navigate(['/multi-quote/coverages']);
  }

  navigateToBindCoverages(): Promise<boolean> {
    return this._router.navigate(['/multi-bind/coverages']);
  }

  navigateToBilling(): Promise<boolean> {
    return this._router.navigate(['/multi-payment/billing']);
  }

  getRetrievedQuote(productId: string): Observable<RetrieveEntity> {
    return this._retrieveService.getRetrievedQuote(productId);
  }

  getAllRetrievedQuotes(): Observable<RetrieveEntity[]> {
    return this._retrieveService.getAllRetrievedQuotes();
  }

  updateQuoteAndWaitForConfirmation(quote): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._quoteService.dispatchUpdateQuote(quote.productId);

      this.actions$
        .pipe(
          ofType(
            fromActions.QuoteActionTypes.UPDATE_QUOTE_SUCCESS,
            fromActions.QuoteActionTypes.UPDATE_QUOTE_FAIL
          ),
          take(1),
          tap((result: Action) => {
            if (
              result.type === fromActions.QuoteActionTypes.UPDATE_QUOTE_SUCCESS
            ) {
              resolve(result);
            } else {
              reject();
            }
          })
        )
        .subscribe();
    });
  }

  rateQuoteAndWaitForConfirmation(quote): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._quoteService.dispatchRateQuote(
        quote.productId,
        RatingTypes.QUOTE,
        true
      );

      this.actions$
        .pipe(
          ofType(
            fromActions.QuoteActionTypes.RATE_QUOTE_SUCCESS,
            fromActions.QuoteActionTypes.RATE_QUOTE_FAIL
          ),
          take(1),
          tap((result: Action) => {
            if (
              result.type === fromActions.QuoteActionTypes.RATE_QUOTE_SUCCESS
            ) {
              resolve(result);
            } else {
              reject();
            }
          })
        )
        .subscribe();
    });
  }

  bindQuoteAndWaitForConfirmation(quote): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._quoteService.dispatchRateQuote(
        quote.productId,
        RatingTypes.BIND,
        true
      );

      this.actions$
        .pipe(
          ofType(
            fromActions.QuoteActionTypes.RATE_BIND_SUCCESS,
            fromActions.QuoteActionTypes.RATE_BIND_FAIL
          ),
          take(1),
          tap((result: Action) => {
            if (
              result.type === fromActions.QuoteActionTypes.RATE_BIND_SUCCESS
            ) {
              resolve(true);
            } else {
              reject();
            }
          })
        )
        .subscribe();
    });
  }

  hydrateQuote(request) {
    this._loadingService.loadingBegin();
    this._retrieveService.hydrateQuote(request);
  }

  getCompRaterQuoteId() {
    return this._retrieveService.getCompRaterQuoteId();
  }

  setCompRaterError(errorMsg: string) {
    return this._retrieveService.setCompRaterError(errorMsg);
  }

  getCompRaterError() {
    return this._retrieveService.getCompRaterError();
  }

  getUserContext(): Observable<UserContext> {
    return this._userContextService.getUserContext();
  }

  hasBeenPreviouslyHydrated(): Observable<boolean> {
    return this._retrieveService.hasBeenPreviouslyHydrated();
  }

  getSelectedProducts(): Observable<Product[]> {
    return this._productsService.getSelectedProducts();
  }

  updateRedirectTarget(target: string): void {
    this._retrieveService.updateRedirectTarget(target);
  }

  triggerBypassRetrieve(bypassFields: BypassFields): void {
    this._retrieveService.triggerBypassRetrieve(bypassFields);
  }

  getBypassRetrieve(): Observable<BypassRetrieveResponse> {
    return this._retrieveService.getBypassRetrieve();
  }

  getHasMortgageForRetrieve(): Observable<boolean> {
    return this._retrieveService.getHasMortgageForRetrieve();
  }

  getEscrowForRetrieve(): Observable<boolean> {
    return this._retrieveService.getEscrowForRetrieve();
  }
}
