import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';

import * as _ from 'lodash';

import { PrivateLabel } from '@core/models/private-label/private-label.model';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { CustomUIElements } from '@shared/constants/private-label-constants';

@Component({
  selector: 'mq-app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBannerComponent implements OnInit {
  @HostBinding('style.background')
  bannerBackground: string;

  @Input() set privateLabelConfig(config: PrivateLabel) {
    if (config) {
      this._config = config;
      this.setCustomPageConfiguration();
    }
  }

  get privateLabelConfig(): PrivateLabel {
    return this._config;
  }

  _config: PrivateLabel;
  bannerHeader = 'Express';

  ngOnInit(): void {}

  private setCustomPageConfiguration(): void {
    this.bannerBackground = PrivateLabelContentHelper.getConfigValue(
      this.privateLabelConfig,
      CustomUIElements.BANNER_BG_COLOR
    );
    const headerText = PrivateLabelContentHelper.getConfigValue(
      this.privateLabelConfig,
      CustomUIElements.BANNER_HEADER_TEXT
    );
    this.bannerHeader = headerText ? headerText : this.bannerHeader;
  }
}
