import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromVehicleExposures from '@core/store/reducers/vehicle-exposure.reducer';

import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { ProductTypes } from '@shared/constants/app-constants';
import { VehicleExposureRequest } from '@core/models/umbrella/vehicle-exposure-request.model';

export const getVehicleExposureState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.vehicleExposures
);

export const { selectAll: selectAllVehicleExposures } =
  fromVehicleExposures.adapter.getSelectors(getVehicleExposureState);

export const getExposureByVehicleId = (vehicleId: string) =>
  createSelector(selectAllVehicleExposures, exposures =>
    exposures.find(exposure => exposure.vehicleId === vehicleId)
  );

export const buildVehicleExposureRequest = (vehicle: VehicleEntity) =>
  createSelector(
    fromProducts.getProduct(vehicle.productId),
    fromProducts.getProduct(ProductTypes.UMBRELLA),
    fromTokens.getAccessToken(ProductTypes.UMBRELLA),
    getExposureByVehicleId(vehicle.vehicleId),
    (product, umbrella, accessToken, exposure) => {
      return {
        productId: umbrella.id,
        quoteId: umbrella.quoteId,
        sessionId: umbrella.sessionId,
        accessToken,
        vehicleExposure: {
          vehicleId: vehicle.vehicleId,
          hasCompOnly: false,
          underlyingPolicyNumber: product.policyNumber || product.quoteId,
          productId: product.id,
          vehicleType: 'PrivatePass_Ext',
          vehicleExposureId: exposure ? exposure.vehicleExposureId : null,
        },
      } as VehicleExposureRequest;
    }
  );
