<!-- Softfall Message for Nat Gen -->
<div>
  <non-standard-bridge
    *ngIf="(isNonStandardBridge$ | async) !== null"
    [errorMessages]="errorMessages"
    [contactNumber]="contactNumber$ | async"
    [selectedProducts]="selectedProducts$ | async"
    [unformattedContactNumber]="unformattedContactNumber$ | async"
  >
  </non-standard-bridge>
</div>
<!-- Softfall -->
<div
  class="nw-container nw-inner-bun"
  *ngIf="!((isNonStandardBridge$ | async) !== null)"
>
  <div class="row">
    <h1 class="heading-h3 col-sm-12 thank-you">
      Thank you for using
      <span *ngIf="!(isPrivateLabel$ | async)">Nationwide</span> Express
    </h1>
    <h1 class="heading-h4 heading-unfortunately col-sm-12 sub-thank-you">
      Unfortunately, we ran into one or more issues.
    </h1>
  </div>

  <div class="row" *ngFor="let error of errorMessages">
    <div class="col-12">
      <div class="custom-message alert alert-danger" role="alert">
        <a class="icon error"></a>
        <div>
          <div class="product">{{ error.product }}</div>
          <div class="message">{{ error.message }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 how-to-proceed">How to proceed</div>
  </div>

  <div class="row" *isCustomer>
    <div class="col-sm-12">
      <div *ngIf="!rivianRedirect">
        <span *ngIf="isPrivateLabel$ | async; else notPrivateLabel"
          >Your agent</span
        >
        <ng-template #notPrivateLabel>A Nationwide representative</ng-template>
        can still help get an insurance policy.
      </div>
      <div *ngIf="rivianRedirect">
        A Rivian Insurance Advisor can still help get an insurance policy.
      </div>
    </div>
  </div>

  <div class="row" *isAgent>
    <div class="col-sm-12" *ngIf="!quoteIdsExist">
      There was not a Submission ID generated for this quote. Please try again
      or use PolicyCenter for more information.
    </div>
    <div class="col-sm-12" *ngIf="quoteIdsExist">
      For more information, please use PolicyCenter. You may reference the
      Submission ID in the upper-right corner of your screen.
    </div>
  </div>

  <div class="row" *isCustomer>
    <div class="col-sm-12">
      <div *ngIf="!rivianRedirect">
        <p *ngIf="isPrivateLabel$ | async; else default">
          Please contact us directly at {{ contactNumber$ | async }}
        </p>
        <ng-template #default>
          <p>
            Please contact us directly at
            <a href="tel:+{{ unformattedContactNumber$ | async }}">{{
              contactNumber$ | async
            }}</a
            >.
          </p>
          <ul>
            <li>Monday &ndash; Friday: 9 am &ndash; 8 pm ET</li>
            <li>Saturday: Closed</li>
            <li>Sunday: Closed</li>
          </ul>
        </ng-template>
      </div>
      <div *ngIf="rivianRedirect">
        <p>
          Please contact us directly at
          <a href="tel:+{{ unformattedContactNumber$ | async }}">{{
            contactNumber$ | async
          }}</a
          >.
        </p>
        <ul>
          <li>Monday &ndash; Friday: : 9 am &ndash; 8 pm ET</li>
          <li>Saturday: Closed</li>
          <li>Sunday: Closed</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!(isPrivateLabel$ | async)">
    <div class="col-sm-12">
      You may also leave us feedback. To get started, click "Feedback" on the
      right side of your screen. Your feedback helps us continuously improve our
      platform &mdash; and your experience.
    </div>
  </div>
</div>
