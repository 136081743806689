import { Directive, HostListener, OnInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[phoneNumber]',
})
export class PhoneNumberDirective implements OnInit {
  private _el: HTMLInputElement;

  constructor(private _elementRef: ElementRef) {}

  ngOnInit(): void {
    this._el = this._elementRef.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    const key = event.keyCode || event.charCode;

    // block the delete key
    const deleteKey = 46;
    if (key === deleteKey) {
      return false;
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event) {
    const { value } = event.target;
    const key = event.keyCode || event.charCode;
    const backSpaceKey = 8,
      first3Numbers = 3,
      next4Numbers = 7;

    if (
      key === backSpaceKey &&
      (value.length === first3Numbers || value.length === next4Numbers)
    ) {
      this._el.value = value.substring(0, value.length - 1);
    } else if (
      value.length === first3Numbers ||
      value.length === next4Numbers
    ) {
      this._el.value = value + '-';
    } else {
      this._el.value = value;
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    this._el.value = (<HTMLInputElement>event.currentTarget).value.replace(
      /[^0-9\-]/g,
      ''
    );
  }
}
