import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import {
  TelematicsVehiclePremium,
  TelematicsVehicle,
} from '@core/models/telematics/telematics.model';
import { TelematicsEnrollmentUtils } from '@shared/utils/telematics-enrollment.utils';
import { PremiumEntity } from '@core/models/entities/premium.entity';
import { Observable } from 'rxjs';
import { PremiumService } from '@core/services/premium.service';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { StateSpecificFlagKeys } from '@shared/constants/app-constants';

@Component({
  selector: 'mq-telematics-vehicle-premium',
  templateUrl: './telematics-vehicle-premium.component.html',
  styleUrls: ['../../../../../../assets/scss/telematics-panel.scss'],
})
export class TelematicsVehiclePremiumComponent implements OnInit {
  @Input() vehicles: VehicleEntity[];
  @Input() vehiclePremiums: TelematicsVehiclePremium[];
  @Input() enrollmentVehicles: TelematicsVehicle[];
  @Input() isIneligible?: boolean;
  @Input() isPrequalified?: boolean;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;

  @Output() enrollmentChanged = new EventEmitter<VehicleEntity>();

  premiums$: Observable<PremiumEntity[]>;

  constructor(private _premiumService: PremiumService) {}

  ngOnInit(): void {
    this.premiums$ = this._premiumService.getAllPremiums();
  }

  changeEnrollment(vehicle: VehicleEntity) {
    this.enrollmentChanged.emit(vehicle);
  }

  getVehiclePremium(vehicleId: string): number {
    return TelematicsEnrollmentUtils.getPremiumById(
      this.vehiclePremiums,
      vehicleId
    );
  }

  getTotalPremium(premiums: PremiumEntity[]): number {
    return TelematicsEnrollmentUtils.getAutoPolicyTotalPremium(premiums);
  }

  getEnrollmentVehicleById(vehicleId): TelematicsVehicle {
    return this.enrollmentVehicles.find(
      ev => ev.vehicleId === vehicleId.toString()
    );
  }

  isSmartRideEarlyRewardApplicable(): boolean {
    if (
      this.stateSpecificFlags &&
      this.stateSpecificFlags[StateSpecificFlagKeys.smartRideEarlyReward]
    ) {
      return true;
    } else return false;
  }
}
