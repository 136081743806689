import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromProducts from '@core/store/selectors/products.selector';

// TODO - When no arguments are taken, export const getRetrieve = createSelector(... is sufficient

export const getRetrieve = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.retrieve
);

export const getRetrieveSuccess = () =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) =>
      state.retrieve.loaded && !state.retrieve.loading
  );

export const getRetrieveLoading = () =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) => state.retrieve.loading
  );

export const getRetrieveErrorCode = () =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) => state.retrieve.errorCode
  );

export const getRetrievedQuote = productId =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) => state.retrieve.entities[productId]
  );

export const getAllRetrievedQuotes = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => _getObjectValues(state.retrieve.entities)
);

export const getAllFailedRetrieves = () =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) => state.retrieve.failures
  );

export const getAllRetrievesFailedFlag = () =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) => state.retrieve.allRetrievesFailed
  );

function _getObjectValues(object) {
  const values = [];
  for (const key of Object.keys(object)) {
    values.push(object[key]);
  }
  return values;
}
export const getCompRaterId = () =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) => state.retrieve.compRaterId
  );

export const getCompRaterError = () =>
  createSelector(
    fromFeature.getAppState,
    (appState: fromFeature.AppState) => appState.retrieve.compRaterFailure
  );

export const isCompRater = createSelector(getRetrieve, retrieve => {
  if (!retrieve) {
    return false;
  }

  return !!retrieve.compRaterId;
});

export const getHydrateResponse = () =>
  createSelector(getRetrieve, retrieve => retrieve.hydrateResponse);

export const getGettingStartedInformationalMessages = () =>
  createSelector(getHydrateResponse(), responses => {
    const responseArray = [];
    responses.forEach(response => {
      const messages = response.informationalMessages;
      messages.forEach(message => {
        const wrning = message.warnings;
        wrning.forEach(filter =>
          responseArray.push(`${response.productType} - ${filter.message}`)
        );
      });
    });

    return responseArray;
  });

export const getCoveragesInformationalMessages = () =>
  createSelector(getHydrateResponse(), responses => {
    const responseArray = [];
    responses.forEach(response => {
      const messages = response.informationalMessages;
      messages.forEach(message => {
        const wrning = message.warnings;
        wrning.forEach(filter =>
          responseArray.push(`${response.productType} - ${filter.message}`)
        );
      });
    });

    return responseArray;
  });

export const hasBeenPreviouslyHydrated = createSelector(
  getGettingStartedInformationalMessages(),
  messages => {
    if (!messages || messages.length < 1) {
      return false;
    }
    return messages.some(message =>
      message.includes('This submission has been previously hydrated')
    );
  }
);

export const targetPage = createSelector(
  getRetrieve,
  state => state.targetPage
);

export const isNewProductSelected = createSelector(
  getRetrieve,
  fromProducts.getSelectedProducts,
  (retrieveState, selectedProducts) => {
    if (
      !retrieveState.loaded ||
      retrieveState.loading ||
      !selectedProducts ||
      selectedProducts.length === 0
    ) {
      return false;
    }
    let newProductSelected = false;
    selectedProducts.forEach(selectedProduct => {
      if (!retrieveState.entities[selectedProduct.id]) {
        newProductSelected = true;
      }
    });
    return newProductSelected;
  }
);

export const getBypassRetrieve = createSelector(
  getRetrieve,
  state => state.bypassRetrieveResponse
);

export const getRetrievedEntities = productId =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) => state.retrieve.entities
  );

export const getHasMortgageForRetrieve = () =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) => state.retrieve.hasMortgageForRetrieve
  );

export const getEscrowForRetrieve = () =>
  createSelector(
    fromFeature.getAppState,
    (state: fromFeature.AppState) => state.retrieve.escrowForRetrieve
  );
