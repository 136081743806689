<form [formGroup]="form">
  <legend>
    {{ legendText }}
  </legend>
  <div class="input-group form-group">
    <select
      id="componentId"
      class="form-control form-select"
      attr.aria-describedby="{{ componentId }}-error"
      formControlName="{{ formInnerControlName }}"
    >
      <option [value]="selectedValue" [selected]="true">{{
        selectedValue
      }}</option>
      <option *ngFor="let option of options" value="{{ option.value }}"
        >{{ option.label }}
      </option>
    </select>
      <div class="input-group-text"></div>
  </div>
</form>
