import * as fromCurrentCarrier from '@core/store/actions/current-carrier.action';

export interface CurrentCarrier {
  currentCarrierName: string;
  coverageLapse: string;
  currentBodilyInjuryLimit: string;
  currentCarrierTerms: string;
  isNationwideWinBack?: boolean;
  informationalMessages?: string[];
  reasonForCoverageLapse?: string;
}
export interface CarrierState {
  currentCarrierLoading: boolean;
  currentCarrierLoaded: boolean;
  currentCarrier: CurrentCarrier;
  currentCarrierDecision?: string;
}

export function reducer(
  state = {
    currentCarrierLoading: false,
    currentCarrierLoaded: false,
    currentCarrier: null,
  },
  action: fromCurrentCarrier.CurrentCarrierActions
): CarrierState {
  switch (action.type) {
    case fromCurrentCarrier.CurrentCarrierActionTypes.UPDATE_CURRENT_CARRIER: {
      return {
        ...state,
        currentCarrierLoading: true,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .UPDATE_CURRENT_CARRIER_SUCCESS: {
      return {
        ...state,
        currentCarrier: action.payload,
        currentCarrierLoading: false,
        currentCarrierLoaded: true,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .UPDATE_CURRENT_CARRIER_FAIL: {
      return {
        ...state,
        currentCarrierLoading: false,
        currentCarrierLoaded: false,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .GET_CURRENT_CARRIER_SUCCESS: {
      return {
        ...state,
        currentCarrier: action.payload,
        currentCarrierLoading: false,
        currentCarrierLoaded: true,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .ADD_CURRENT_CARRIER_DECISION: {
      return {
        ...state,
        currentCarrierDecision: action.payload,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .GET_CURRENT_CARRIER_FAIL: {
      return {
        ...state,
        currentCarrierLoading: false,
        currentCarrierLoaded: false,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .UPDATE_CURRENT_CARRIER_FROM_QUOTE: {
      return {
        ...state,
        currentCarrier: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
