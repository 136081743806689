import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

import * as fromDocuments from '@core/store/actions/documents.action';
import { DocumentsEntity } from '@core/models/entities/document.entity';

export interface DocumentsState extends EntityState<DocumentsEntity> {
  loading: boolean;
  loaded: boolean;
}

export const adapter: EntityAdapter<DocumentsEntity> =
  createEntityAdapter<DocumentsEntity>({
    selectId: document => document.productId,
  });

export function reducer(
  state = adapter.getInitialState({ loaded: false, loading: false }),
  action: fromDocuments.DocumentActions
): DocumentsState {
  switch (action.type) {
    case fromDocuments.DocumentActionTypes.GET_DOCUMENTS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromDocuments.DocumentActionTypes.GET_DOCUMENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case fromDocuments.DocumentActionTypes.GET_DOCUMENTS_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }
  }

  return state;
}
