import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsAgentDirective } from './is-agent.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsAgentDirective],
  exports: [IsAgentDirective],
})
export class IsAgentModule {}
