import { Action } from '@ngrx/store';

export const ADD_CONDO = '[Core] Add Condo';
export const SET_CONDO_MORTGAGE_FLAG = '[Core] Set Condo Mortgage Flag';

export class AddCondo implements Action {
  readonly type = ADD_CONDO;
  constructor(public payload: any) {}
}

export class SetCondoMortgageFlag implements Action {
  readonly type = SET_CONDO_MORTGAGE_FLAG;
  constructor(public hasMortgage: boolean) {}
}

export type CondoActions = AddCondo | SetCondoMortgageFlag;
