<div class="ng-progress-bar">
  <ngb-progressbar
    type="info"
    [value]="progressTrackerPercentages[progressTrackerPageName]"
  ></ngb-progressbar>
  <div id="progressbar-description" tabindex="0" class="sr-only">
    Form progress: Page: {{ trackerLabels[(getPageNumber() - 1)] }}
    Step {{getPageNumber()}} of {{pageNumberTotal}} 
  </div>
</div>
<div class="container" aria-hidden="true">
  <div class="labels" *ngFor="let label of labels">
    <span>{{ label }}</span>
  </div>
</div>
