import { Action } from '@ngrx/store';
import { AccessToken } from '@core/store/reducers/token.reducer';
import { AccessTokenRequest } from '@core/models/auto/quotes/access-token-request.model';

export const enum TokenActionTypes {
  GENERATE_ACCESS_TOKEN = '[Token] Generate Access Token',
  GENERATE_ACCESS_TOKEN_FOR_REQUEST = '[Token] Generate Access Token For Request',
  GENERATE_ACCESS_TOKEN_SUCCESS = '[Token] Generate Access Token Success',
  GENERATE_ACCESS_TOKEN_FAIL = '[Token] Generate Access Token Fail',
  REFRESH_ACCESS_TOKEN = '[Token] Refresh Access Token',
  DROP_ACCESS_TOKEN = '[Token] Drop Access Token',
  SET_ACCESS_TOKEN = '[Token] Set Access Token',
  REFRESH_ALL_ACCESS_TOKENS = '[Token] Refresh All Access Tokens',
  INSERT_ACCESS_TOKEN = '[Token] Insert Product Agnostic Access Tokens',
}

export class GenerateAccessToken implements Action {
  readonly type = TokenActionTypes.GENERATE_ACCESS_TOKEN;
  constructor(public payload: string) {}
}

export class GenerateAccessTokenForRequest implements Action {
  readonly type = TokenActionTypes.GENERATE_ACCESS_TOKEN_FOR_REQUEST;
  constructor(public payload: AccessTokenRequest) {}
}

export class GenerateAccessTokenSuccess implements Action {
  readonly type = TokenActionTypes.GENERATE_ACCESS_TOKEN_SUCCESS;
  constructor(public payload: AccessToken) {}
}

export class GenerateAccessTokenFail implements Action {
  readonly type = TokenActionTypes.GENERATE_ACCESS_TOKEN_FAIL;
  constructor() {}
}

export class RefreshAccessToken implements Action {
  readonly type = TokenActionTypes.REFRESH_ACCESS_TOKEN;
  constructor(public payload: string) {}
}

export class DropAccessToken implements Action {
  readonly type = TokenActionTypes.DROP_ACCESS_TOKEN;
  constructor(public payload: string) {}
}

export class SetAccessToken implements Action {
  readonly type = TokenActionTypes.SET_ACCESS_TOKEN;
  constructor(public payload: AccessToken) {}
}

export class RefreshAllAccessTokens implements Action {
  readonly type = TokenActionTypes.REFRESH_ALL_ACCESS_TOKENS;
  constructor() {}
}

export class InsertAccessToken implements Action {
  readonly type = TokenActionTypes.INSERT_ACCESS_TOKEN;
  constructor(public payload: string) {}
}

export type TokenActions =
  | GenerateAccessToken
  | GenerateAccessTokenForRequest
  | GenerateAccessTokenFail
  | GenerateAccessTokenSuccess
  | RefreshAccessToken
  | DropAccessToken
  | SetAccessToken
  | RefreshAllAccessTokens
  | InsertAccessToken;
