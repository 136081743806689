import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as fromContainers from './containers';
import * as fromCoreGuards from '@core/guards';
import { CompRaterAuthenticationGuard } from '@agency/guards';
import { CompRaterNewsGuard } from './guards/comp-rater-news.guard';

const ROUTES: Routes = [
  {
    path: '',
    component: fromContainers.RetrieveComponent,
    canActivate: [
      // TODO probably not all of these necessary.... copied from quoting-routing.module.ts
      fromCoreGuards.ProductsGuard,
      fromCoreGuards.SuffixGuard,
      fromCoreGuards.ActiveStatesGuard,
      fromCoreGuards.StateSpecificFlagsGuard,
      fromCoreGuards.PageAlertGuard,
    ],
  },
  {
    path: 'compRater',
    component: fromContainers.CompRaterRetrieveComponent,
    canActivate: [
      fromCoreGuards.ProductsGuard,
      CompRaterAuthenticationGuard,
      fromCoreGuards.PageAlertGuard,
      CompRaterNewsGuard,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class RetrieveRoutingModule {}
