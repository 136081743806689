<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    SmartRide
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    SmartRide rewards safe driving. Safe drivers could earn higher discount –
    <strong>up to 40%.</strong>
  </p>
  <p>
    Download and activate an easy-to-use app, which measures the driver’s
    behavior for about six months and gives the driver personalized feedback.
  </p>
  <p>
    Keep the final discount as long as all participating drivers on the policy
    are insured with us.
  </p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Close click')"
  >
    Close
  </button>
</div>
