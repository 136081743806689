import { catchError, map, switchMap } from 'rxjs/operators';
import { ofType, Effect, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  LOAD_COMP_RATER_NEWS,
  LoadCompRaterNewsSuccess,
  LoadCompRaterNewsFail,
} from '../actions/comp-rater-news.action';
import { CompRaterNewsService } from '@core/services/comp-rater-news.service';
import { of } from 'rxjs';

@Injectable()
export class CompRaterNewsEffects {
  constructor(
    private _compRaterNewsService: CompRaterNewsService,
    private _actions: Actions
  ) {}

  @Effect()
  loadCompRaterNews$ = this._actions.pipe(
    ofType(LOAD_COMP_RATER_NEWS),
    switchMap(() => this._compRaterNewsService.fetch()),
    map(html => new LoadCompRaterNewsSuccess(html)),
    catchError(() => of(new LoadCompRaterNewsFail()))
  );
}
