import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrivateLabelService } from '@core/services';
import { take } from 'rxjs/operators';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { CustomUIElements } from '@shared/constants/private-label-constants';

@Component({
  selector: 'mq-connected-car',
  templateUrl: './connected-car.component.html',
  styleUrls: ['./connected-car.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectedCarComponent implements OnInit {
  helpText: string;

  constructor(
    public modal: NgbActiveModal,
    private _privateLabelService: PrivateLabelService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._privateLabelService
      .getPrivateLabelConfiguration()
      .pipe(take(1))
      .subscribe(config => {
        this.helpText = PrivateLabelContentHelper.getConfigValue(
          config,
          CustomUIElements.CONNECTED_CAR
        );
        this._cd.detectChanges();
      });
  }
}
