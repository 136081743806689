import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromRenters from '@core/store/reducers/renters.reducer';

export const getRentersState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.renters
);

export const getRentersEntities = createSelector(
  getRentersState,
  fromRenters.getRentersEntities
);

export const getRenters = createSelector(getRentersEntities, entities => {
  return Object.keys(entities).map(id => entities[parseInt(id, 10)])[0];
});

export const getRentersLoaded = createSelector(
  getRentersState,
  fromRenters.getRentersLoaded
);

export const getRentersLoading = createSelector(
  getRentersState,
  fromRenters.getRentersLoading
);

export const getSeparateUnitsState = createSelector(
  getRentersState,
  fromRenters.getSeparateUnits
);

export const getSeparateUnitsData = createSelector(
  getSeparateUnitsState,
  state => state.data
);

export const getSeparateUnitsLoading = createSelector(
  getSeparateUnitsState,
  state => state.loading
);

export const getSeparateUnitsLoaded = createSelector(
  getSeparateUnitsState,
  state => state.loaded
);

export const getStructuralMaterialsState = createSelector(
  getRentersState,
  fromRenters.getStructuralMaterials
);

export const getStructuralMaterialsData = createSelector(
  getStructuralMaterialsState,
  state => state.data
);

export const getStructuralMaterialsLoading = createSelector(
  getStructuralMaterialsState,
  state => state.loading
);

export const getStructuralMaterialsLoaded = createSelector(
  getStructuralMaterialsState,
  state => state.loaded
);
