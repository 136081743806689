import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

@Injectable({
  providedIn: 'root',
})
export class QuoteLetterDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  dispatchLoadQuoteLetterPdf(): void {
    this._store.dispatch(new fromActions.LoadQuoteLetter());
  }

  getQuoteLetter(): Observable<string> {
    return this._store.select(fromSelectors.getQuoteLetter);
  }

  getQuoteLetterLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteLetterLoaded);
  }
}
