import * as fromFeature from '@core/store/reducers';
import * as fromTokenReducer from '@core/store/reducers/token.reducer';
import * as fromProduct from '@core/store/selectors/products.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import { createSelector } from '@ngrx/store';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { AccessTokenRequest } from '@core/models/auto/quotes/access-token-request.model';
import { DEFAULT_ID, ProductTypes } from '@shared/constants/app-constants';

export const getTokenState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.tokens
);

export const {
  selectEntities: selectTokenEntities,
  selectAll: selectAllTokens,
} = fromTokenReducer.adapter.getSelectors(getTokenState);

export const getAccessToken = (id: string) =>
  createSelector(selectTokenEntities, entities =>
    entities[id] ? entities[id].accessToken : null
  );

export const getTokenByProduct = (productId: string) =>
  createSelector(selectTokenEntities, entities => entities[productId]);

export const areAllTokensLoaded = createSelector(
  fromProduct.getSelectedProducts,
  selectTokenEntities,
  (products, tokens) => {
    return products.every(product => !!tokens[product.id]);
  }
);

export const getAutoAccessTokenLoaded = createSelector(
  selectTokenEntities,
  (entities): boolean => !!entities[ProductTypes.AUTO]
);

export const getPowersportsAccessTokenLoaded = createSelector(
  selectTokenEntities,
  (entities): boolean => !!entities[ProductTypes.POWERSPORTS]
);

export const getVehicleAccessTokenLoaded = createSelector(
  selectTokenEntities,
  (entities): boolean =>
    !!entities[ProductTypes.POWERSPORTS] || !!entities[ProductTypes.AUTO]
);

export const getPowersportsAccessTokenLoading = createSelector(
  getTokenState,
  (state): boolean => state.powersportsTokenLoading
);

export const getUmbrellaAccessTokenLoading = createSelector(
  getTokenState,
  (state): boolean => state.umbrellaTokenLoading
);

export const getAutoAccessTokenLoading = createSelector(
  getTokenState,
  (state): boolean => state.autoTokenLoading
);

export const buildEuaRequest = (policyholder: PolicyholderEntity) =>
  createSelector(
    fromProduct.getProduct(policyholder.productId),
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    (product, policyAddress): AccessTokenRequest => {
      const request: AccessTokenRequest = {
        quoteId: product.quoteId,
        postalCode: policyAddress.postalCode,
        lastName: policyholder.person.lastName,
        productId: policyholder.productId,
      };

      return request;
    }
  );
