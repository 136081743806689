import {
  RenovationAction,
  RenovationActionTypes,
} from '@core/store/actions/renovation.action';

export interface RenovationEntity {
  renovationId?: string;
  type?: string;
  year?: number;
}

export interface RenovationsState {
  entities: RenovationEntity[];
  loaded: boolean;
}

const initialState: RenovationsState = {
  entities: null,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: RenovationAction
): RenovationsState {
  switch (action.type) {
    case RenovationActionTypes.GET_ALL_RENOVATION_SUCCESS:
      return { ...state, entities: action.payload, loaded: true };

    // case RenovationHoActionTypes.GET_RENOVATION_SUCCESS:
    //   return { ...state, data: action.payload, loaded: true };

    case RenovationActionTypes.ADD_RENOVATION_SUCCESS:
      return { ...state, entities: action.payload, loaded: true };

    case RenovationActionTypes.UPDATE_RENOVATION_SUCCESS: {
      return { ...state, entities: action.payload, loaded: true };
    }

    case RenovationActionTypes.DELETE_RENOVATION_SUCCESS:
      return { ...state, entities: action.payload, loaded: true };

    default:
      return { ...state };
  }
}
