import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { BillingBannerComponent } from './billing-banner/billing-banner.component';
import { EditBillingPlanModalComponent } from './edit-billing-plan-modal/edit-billing-plan-modal.component';
import { BaseBillingProxy } from '@shared/proxies/base-billing.proxy';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, NgbTypeaheadModule],
  declarations: [BillingBannerComponent, EditBillingPlanModalComponent],
  exports: [BillingBannerComponent, EditBillingPlanModalComponent],
  providers: [BaseBillingProxy],
})
export class BillingPaymentPlanModule {}
