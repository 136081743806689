import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Inject,
  OnDestroy,
} from '@angular/core';

import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { AgencyService } from '../../../core/services/agency.service';
import { RetrieveProxy } from 'app/retrieve/proxies';
import {
  take,
  filter,
  withLatestFrom,
  takeUntil,
  tap,
  map,
} from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { RetrieveEntity } from '@core/models/entities/retrieve.entity';
import { RedirectTargets } from '@shared/constants/app-constants';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { Producer } from '@core/models/agency/producer-search.model';
import { LoggingService, CompRaterNewsService } from '@core/services';
import { DOCUMENT } from '@angular/common';
import { Product } from '@core/models/products/product.model';
import { ErrorSettableComponent } from '@shared/components/error-settable-component';
import { AgencyModel } from '@core/models/agency/agency.model';

@Component({
  selector: 'mq-comp-rater-retrieve',
  templateUrl: './comp-rater-retrieve.component.html',
  styleUrls: ['./comp-rater-retrieve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompRaterRetrieveComponent
  implements OnInit, OnDestroy, ErrorSettableComponent
{
  private _window: Window;

  constructor(
    private _agencyService: AgencyService,
    private _fb: UntypedFormBuilder,
    private _retrieveProxy: RetrieveProxy,
    private _loggingService: LoggingService,
    private _compRaterNewsService: CompRaterNewsService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._window = _document.defaultView;
  }

  compRaterRetrieveForm: UntypedFormGroup;
  agencyCodes = [];
  errorMessage$: Observable<string>;
  loading$: Observable<boolean>;
  private retrieveCompleteSubscription = null;
  private unsubscribe = new Subject();

  agencyCodes$: Observable<string[]>;
  producerIds$: Observable<Producer[]>;
  submitted$ = new EventEmitter<boolean>();
  newsHtml$: Observable<string>;
  isEATP$: Observable<boolean>;
  pdsCodes$: Observable<Producer[]>;

  ngOnInit(): void {
    this._buildForm();
    this.loading$ = this._retrieveProxy.getAppLoading();
    this.isEATP$ = this._agencyService
      .getAgency()
      .pipe(map((agency: AgencyModel) => agency.agencyChannel === 'EA'));

    this.errorMessage$ = this._retrieveProxy
      .getCompRaterError()
      .pipe(filter(errMsg => !!errMsg));

    this.agencyCodes$ = this._agencyService
      .getAgencyCodes()
      .pipe(tap(agencyCodes => this.selectAgencyIfUnset(agencyCodes[0])));

    this.pdsCodes$ = this._agencyService.getPDSCodes();

    this.newsHtml$ = this._compRaterNewsService.getNewsHtml();

    this._loggingService.log('COMPRATER_LOAD', {});
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setErrorFromGuard(err: any): void {
    this._retrieveProxy.setCompRaterError(err);
  }

  _buildForm(): void {
    this.compRaterRetrieveForm = this._fb.group({
      agencyCode: this._fb.control('', []),
      producerId: this._fb.control('', [Validators.required]),
    });

    this.agencyCode.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(agencyCode => this.getProducerIds(agencyCode));
  }

  get agencyCode() {
    return this.compRaterRetrieveForm.get('agencyCode');
  }

  get producerId() {
    return this.compRaterRetrieveForm.get('producerId');
  }

  // TODO - massive refactor once retrieve works
  onSubmit(form) {
    this.submitted$.next(true);
    if (!form.valid) {
      return;
    }

    const agencyCode = this.agencyCode.value;
    const [producerCode, agentState] = this.producerId.value.split(':');
    const compRaterQuoteId$ = this._retrieveProxy
      .getCompRaterQuoteId()
      .pipe(take(1));
    const agentAccessToken$ = this._agencyService.getAgentJwt().pipe(take(1));

    combineLatest([compRaterQuoteId$, agentAccessToken$]).subscribe(
      ([compRaterQuoteId, jwt]) => {
        this._agencyService.updateAgency({
          firstName: jwt.jwt.firstName,
          lastName: jwt.jwt.lastName,
          userId: jwt.jwt.userId,
          state: agentState,
          agencyCode: agencyCode,
          producerCode: producerCode,
        });
        const request = {
          agencyCode,
          producerId: producerCode,
          deepLaunchId: compRaterQuoteId,
          accessToken: jwt.accessToken,
        };

        this._loggingService.log('COMPRATER_NWX_CLICK', {
          userId: jwt.jwt.userId,
          agencyCode: agencyCode,
          producerCode: producerCode,
          compRaterQuoteId: compRaterQuoteId,
        });

        this._retrieveProxy.hydrateQuote(request);
      }
    );

    if (this.retrieveCompleteSubscription) {
      this.retrieveCompleteSubscription.unsubscribe();
    }

    this.retrieveCompleteSubscription = this._retrieveProxy
      .getRetrieveSuccess()
      .pipe(
        filter(success => success),
        take(1),
        withLatestFrom(this._retrieveProxy.getAllRetrievedQuotes())
      )
      .subscribe(([sucess, quotes]) => {
        this.checkForHydrationError(quotes)
          .catch(() => {})
          .then(() => {
            this.navigateToSalesFlow(quotes)
              .then(navigated => {
                this._retrieveProxy.loadingEnd();
              })
              .catch(error => {
                this._retrieveProxy.setRetrieveError({
                  quoteId: '',
                  productType: '',
                  errorCode:
                    this._retrieveProxy.extractErrorCodeFromAnything(error) ||
                    ErrorHelper.ERR_GENERAL_RETRIEVE,
                });
                this._retrieveProxy.loadingEnd();
              });
          });
      });
  }

  checkForHydrationError(quotes: RetrieveEntity[]) {
    return this._retrieveProxy
      .hasBeenPreviouslyHydrated()
      .pipe(
        take(1),
        map(previouslyHydrated => {
          return new Promise<void>((resolve, reject) => {
            if (previouslyHydrated) {
              this.rateQuotesThatNeedIt(quotes)
                .then(() => {
                  return resolve();
                })
                .catch(() => {
                  return reject();
                });
            } else {
              return resolve();
            }
          });
        })
      )
      .toPromise();
  }

  navigateToSalesFlow(quotes: RetrieveEntity[]): Promise<boolean> {
    let products;

    this._retrieveProxy
      .getSelectedProducts()
      .pipe(take(1))
      .subscribe(p => (products = p));
    if (this.canNavigateToQuoteCoverages(products)) {
      return this.updateQuotesThatNeedIt(quotes).then(() => {
        this._retrieveProxy.updateRedirectTarget(
          RedirectTargets.QUOTE_COVERAGES
        );
        this._loggingService.log('COMPRATER_COVERAGES_REDIRECT', {});
        return this._retrieveProxy.navigateToQuoteCoverages();
      });
    } else {
      this._retrieveProxy.updateRedirectTarget(RedirectTargets.PNI);
      this._loggingService.log('COMPRATER_INITIATE_REDIRECT', {});
      return this._retrieveProxy.navigateToGettingStarted();
    }
  }

  canNavigateToQuoteCoverages(entities: Product[]): boolean {
    if (entities.length < 1) {
      return false;
    }

    return entities.every(entity => entity.quoteStatus === 'Quoted');
  }

  updateQuotesThatNeedIt(entities: RetrieveEntity[]): Promise<any> {
    const updates = [];
    for (const entity of entities) {
      if (!entity.response.updateRequired) {
        continue;
      }
      updates.push(
        this._retrieveProxy.updateQuoteAndWaitForConfirmation(entity)
      );
    }
    if (updates.length < 1) {
      return Promise.resolve();
    }
    if (updates.length === 1) {
      return updates[0];
    }
    return Promise.all(updates);
  }

  selectAgencyIfUnset(agencyCode: string) {
    if (agencyCode && this.compRaterRetrieveForm) {
      const control = this.agencyCode;
      if (!control.value) {
        control.setValue(agencyCode);
      }
    }
  }

  rateQuotesThatNeedIt(entities: RetrieveEntity[]): Promise<any> {
    const updates = [];
    for (const entity of entities) {
      if (entity.response.quoteStatus !== 'Draft') {
        continue;
      }
      updates.push(this._retrieveProxy.rateQuoteAndWaitForConfirmation(entity));
    }
    if (updates.length < 1) {
      return Promise.resolve();
    }
    if (updates.length === 1) {
      return updates[0];
    }
    return Promise.all(updates);
  }

  getProducerIds(code: string) {
    this.producerIds$ = this._agencyService.getProducerIdsByAgencyCode(code);
  }

  bridgeToPolicyCenter(event) {
    event.preventDefault();
    this._retrieveProxy
      .getCompRaterQuoteId()
      .pipe(take(1))
      .subscribe(quoteId => {
        const url = this._agencyService.buildPCHydrationUrl(quoteId);
        this._loggingService.log('COMPRATER_POLICYCENTER_REDIRECT', {
          url: url,
          quoteId: quoteId,
        });
        this._window.location.href = url;
      });
  }
}
