<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Share Driving Data</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    If your vehicle transmits electronic driving data, you could be eligible for
    a SmartRide discount based on your driving. Smartride availability varies;
    program criteria differs in California. Enrollment discount applies during
    data collection; final discount is calculated on driving behavior and could
    be zero. Final discount applies at the next policy renewal and is subject to
    change based upon actuarial support at subsequent renewals or with changes
    in drivers or vehicles on the policy.
    <strong
      >By clicking “Agree and Continue” you agree to share your driving data
      from the original equipment manufacturer and third parties to calculate a
      <a
        href="https://www.nationwide.com/personal/insurance/auto/discounts/smartride/"
        target="_blank"
        rel="noopener"
        >SmartRide</a
      >
      discount.</strong
    >
  </p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Close click')"
  >
    Close
  </button>
</div>
