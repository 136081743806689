import { Pipe, PipeTransform } from '@angular/core';
import { RetrieveFailure } from '@core/models/entities/retrieve.entity';
import { ProductTypes } from '@shared/constants/app-constants';

@Pipe({
  name: 'retrieveFailureMessage',
})
export class RetrieveFailureMessagePipe implements PipeTransform {
  transform(
    value: RetrieveFailure[],
    hasAuto?: boolean,
    hasHomeowners?: boolean,
    hasRenters?: boolean
  ): string {
    return this.generateRetrieveFailureMessage(
      value,
      hasAuto,
      hasHomeowners,
      hasRenters
    );
  }

  // Approved verbiage: We're unable to retrieve your <homeowners> quote but can continue with your <auto> purchase.
  generateRetrieveFailureMessage(
    retrieveFailures: RetrieveFailure[],
    hasAuto: boolean,
    hasHomeowners: boolean,
    hasRenters: boolean
  ) {
    if (!retrieveFailures || retrieveFailures.length < 1) {
      return '';
    }

    const failedProductNames = [];
    for (const failure of retrieveFailures) {
      switch (failure.productType) {
        case ProductTypes.AUTO:
          {
            if (hasAuto) {
              continue;
            }
            failedProductNames.push('auto');
          }
          break;
        case ProductTypes.HOMEOWNERS:
          {
            if (hasHomeowners) {
              continue;
            }
            failedProductNames.push('homeowners');
          }
          break;
        case ProductTypes.RENTERS:
          {
            if (hasRenters) {
              continue;
            }
            failedProductNames.push('renters');
          }
          break;
      }
    }
    if (failedProductNames.length < 1) {
      return '';
    }

    const okProductNames = [];
    if (hasAuto) {
      okProductNames.push('auto');
    }
    if (hasHomeowners) {
      okProductNames.push('homeowners');
    }

    if (hasRenters) {
      okProductNames.push('renters');
    }

    if (okProductNames.length === 0) {
      return this.generateAllRetrievesFailedMessage();
    }
    return (
      'We\u2019re unable to retrieve your ' + // U+2019 == &rsquo; (curly apostrophe)
      this.productListAsString(failedProductNames, 'quote') +
      ' but can continue with your ' +
      this.productListAsString(okProductNames) +
      ' purchase.'
    );
  }

  generateAllRetrievesFailedMessage() {
    return (
      'We\u2019re unable to retrieve your quote(s)' + // U+2019 == &rsquo; (curly apostrophe)
      ' but you can continue with a new quote.'
    );
  }

  productListAsString(products: string[], subject?): string {
    if (products.length < 1) {
      return subject || '';
    }
    if (subject) {
      if (products.length > 1) {
        subject = ` ${subject}s`;
      } else {
        subject = ' ' + subject;
      }
    } else {
      subject = '';
    }
    if (products.length === 1) {
      return `${products[0]}${subject}`;
    }
    if (products.length === 2) {
      return `${products[0]} and ${products[1]}${subject}`;
    }
    let message = '';
    for (let i = 0; i < products.length - 1; i++) {
      message += products[i];
      message += ', ';
    }
    message += 'and ';
    message += products[products.length - 1];
    message += subject;
    return message;
  }
}
