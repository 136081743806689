import { DriverVehicleAssignmentService } from '@core/services/driver-vehicle-assignment.service';
import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class RemoveAutoGeneratedDvaGuard implements CanActivate {
  constructor(private _dsmDvaService: DriverVehicleAssignmentService) {}

  canActivate(): boolean {
    this._dsmDvaService.removeAutoGeneratedAssignments();
    return true;
  }
}
