import { Action } from '@ngrx/store';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { Update } from '@ngrx/entity';

export const enum ModifierActionTypes {
  ADD_ALL_MODIFIERS = '[Modifiers] Add All Modifiers',

  UPDATE_DRIVER_DISCOUNT = '[Modifiers] Update Driver Discount',
  UPDATE_DRIVER_DISCOUNT_FAIL = '[Modifiers] Update Driver Discount Fail',
  UPDATE_DRIVER_DISCOUNT_SUCCESS = '[Modifiers] Update Driver Discount Success',

  UPDATE_POWERSPORTS_DRIVER_DISCOUNT = '[Modifiers] Update Powersports Driver Discount',
  UPDATE_POWERSPORTS_DRIVER_DISCOUNT_FAIL = '[Modifiers] Update Powersports Driver Discount Fail',
  UPDATE_POWERSPORTS_DRIVER_DISCOUNT_SUCCESS = '[Modifiers] Update Powersports Driver Discount Success',

  GET_ALL_POLICY_LINE = '[Modifiers] Get All Policy Line',
  GET_ALL_POLICY_LINE_FAIL = '[Modifiers] Get All Policy Line Fail',
  GET_ALL_POLICY_LINE_SUCCESS = '[Modifiers] Get All Policy Line Success',

  GET_POLICY_LINE = '[Modifiers] Get Policy Line',
  GET_POLICY_LINE_FAIL = '[Modifiers] Get Policy Line Fail',
  GET_POLICY_LINE_SUCCESS = '[Modifiers] Get Policy Line Success',

  UPDATE_POLICY_LINE = '[Modifiers] Update Policy Line',
  UPDATE_POLICY_LINE_FAIL = '[Modifiers] Update Policy Line Fail',
  UPDATE_POLICY_LINE_SUCCESS = '[Modifiers] Update Policy Line Success',

  SET_SMARTRIDE_LOADED = '[Modifiers] Set SmartRide Loaded',
  DEFAULT_SMARTRIDE_TRUE = '[Modifiers] Default SmartRide True',
  SMARTRIDE_ALREADY_SET = '[Modifiers] SmartRide Already Set',
  CHECK_SMARTRIDE_SET = '[Modifiers] Check Smartride Set',
  UNSET_SMARTRIDE_LOADED = '[Modifiers] Unset Smartride loaded',

  UPDATE_VEHICLE_DISCOUNT = '[Modifiers] Update Vehicle Discount',
  UPDATE_VEHICLE_DISCOUNT_SUCCESS = '[Modifiers] Update Vehicle Discount Success',
  UPDATE_VEHICLE_DISCOUNT_FAIL = '[Modifiers] Update Vehicle Discount Fail',

  UPDATE_POWERSPORTS_VEHICLE_DISCOUNT = '[Modifiers] Update Powersports Vehicle Discount',
  UPDATE_POWERSPORTS_VEHICLE_DISCOUNT_SUCCESS = '[Modifiers] Update Powersports Vehicle Discount Success',
  UPDATE_POWERSPORTS_VEHICLE_DISCOUNT_FAIL = '[Modifiers] Update Powersports Vehicle Discount Fail',

  UPDATE_SMARTHOME_DISCOUNT = '[SmartHome] Update SmartHome',
  UPDATE_SMARTHOME_DISCOUNT_SUCCESS = '[SmartHome] Update SmartHome Success',
  UPDATE_SMARTHOME_DISCOUNT_FAIL = '[SmartHome] Update SmartHome Fail',

  DISABLE_VEHICLE_DISCOUNT = '[Modifiers] Disable Vehicle Discount',

  UPDATE_ASSOCIATE_DISCOUNT = '[Modifiers] Update Associate Discount',
  UPDATE_ASSOCIATE_DISCOUNT_SUCCESS = '[Modifiers] Update Associate Discount Success',
  UPDATE_ASSOCIATE_DISCOUNT_FAIL = '[Modifiers] Update Associate Discount Fail',

  MODIFIER_ACTION_CANCELLED = '[Modifiers] Modifier Action Cancelled',

  CHECK_EASY_PAY_DISCOUNT = '[Modifiers] Check EasyPay Discount',
  REMOVE_EASY_PAY_DISCOUNT = '[Modifiers] Remove EasyPay Discount',
  ADD_EASY_PAY_DISCOUNT = '[Modifiers] Add EasyPay Discount',

  UPDATE_MEMBERSHIP_DISCOUNT = '[Modifiers] Update Membership Discount',
  UPDATE_MEMBERSHIP_DISCOUNT_SUCCESS = '[Modifiers] Update Membership Discount Success',
  UPDATE_MEMBERSHIP_DISCOUNT_FAIL = '[Modifiers] Update Membership Discount Fail',

  UPDATE_BILLING_PAYMENT_METHOD = '[Modifiers] Update Billing Payment Method',
}

export class AddAllModifiers implements Action {
  readonly type = ModifierActionTypes.ADD_ALL_MODIFIERS;
  constructor(public payload: EligibleDiscountEntity[]) {}
}

export class UpdateDriverDiscount implements Action {
  readonly type = ModifierActionTypes.UPDATE_DRIVER_DISCOUNT;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdateDriverDiscountFail implements Action {
  readonly type = ModifierActionTypes.UPDATE_DRIVER_DISCOUNT_FAIL;
  constructor(public payload: any) {}
}

export class UpdateDriverDiscountSuccess implements Action {
  readonly type = ModifierActionTypes.UPDATE_DRIVER_DISCOUNT_SUCCESS;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdatePowersportsDriverDiscount implements Action {
  readonly type = ModifierActionTypes.UPDATE_POWERSPORTS_DRIVER_DISCOUNT;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdatePowersportsDriverDiscountFail implements Action {
  readonly type = ModifierActionTypes.UPDATE_POWERSPORTS_DRIVER_DISCOUNT_FAIL;
  constructor(public payload: any) {}
}

export class UpdatePowersportsDriverDiscountSuccess implements Action {
  readonly type =
    ModifierActionTypes.UPDATE_POWERSPORTS_DRIVER_DISCOUNT_SUCCESS;
  constructor(public payload: EligibleDiscountEntity) {}
}
export class GetAllPolicyLine implements Action {
  readonly type = ModifierActionTypes.GET_ALL_POLICY_LINE;
}

export class GetAllPolicyLineFail implements Action {
  readonly type = ModifierActionTypes.GET_ALL_POLICY_LINE_FAIL;
  constructor(public error: any) {}
}

export class GetAllPolicyLineSuccess implements Action {
  readonly type = ModifierActionTypes.GET_ALL_POLICY_LINE_SUCCESS;
  constructor(public payload: EligibleDiscountEntity[]) {}
}

export class GetPolicyLine implements Action {
  readonly type = ModifierActionTypes.GET_POLICY_LINE;
  constructor(public payload: any) {}
}

export class GetPolicyLineFail implements Action {
  readonly type = ModifierActionTypes.GET_POLICY_LINE_FAIL;
  constructor(public error: any) {}
}

export class GetPolicyLineSuccess implements Action {
  readonly type = ModifierActionTypes.GET_POLICY_LINE_SUCCESS;
  constructor(public payload: EligibleDiscountEntity[]) {}
}
export class UpdatePolicyLine implements Action {
  readonly type = ModifierActionTypes.UPDATE_POLICY_LINE;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdatePolicyLineFail implements Action {
  readonly type = ModifierActionTypes.UPDATE_POLICY_LINE_FAIL;
  constructor(public error: any) {}
}

export class UpdatePolicyLineSuccess implements Action {
  readonly type = ModifierActionTypes.UPDATE_POLICY_LINE_SUCCESS;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdateSmartHomeDiscount implements Action {
  readonly type = ModifierActionTypes.UPDATE_SMARTHOME_DISCOUNT;
  constructor(public payload: any) {}
}

export class UpdateSmartHomeDiscountFail implements Action {
  readonly type = ModifierActionTypes.UPDATE_SMARTHOME_DISCOUNT_FAIL;

  constructor(public error: any) {}
}

export class UpdateSmartHomeDiscountSuccess implements Action {
  readonly type = ModifierActionTypes.UPDATE_SMARTHOME_DISCOUNT_SUCCESS;
  constructor(public payload: Update<EligibleDiscountEntity>) {}
}

export class SetSmartRideLoaded implements Action {
  readonly type = ModifierActionTypes.SET_SMARTRIDE_LOADED;
  constructor(public payload: string) {}
}

export class DefaultSmartRideTrue implements Action {
  readonly type = ModifierActionTypes.DEFAULT_SMARTRIDE_TRUE;
  constructor(public payload: any) {}
}

export class CheckSmartRideSet implements Action {
  readonly type = ModifierActionTypes.CHECK_SMARTRIDE_SET;
  constructor(public payload: any) {}
}

export class SmartRideAlreadySet implements Action {
  readonly type = ModifierActionTypes.SMARTRIDE_ALREADY_SET;
}

export class UnsetSmartrideLoaded implements Action {
  readonly type = ModifierActionTypes.UNSET_SMARTRIDE_LOADED;
}

export class UpdateVehicleDiscount implements Action {
  readonly type = ModifierActionTypes.UPDATE_VEHICLE_DISCOUNT;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdateVehicleDiscountFail implements Action {
  readonly type = ModifierActionTypes.UPDATE_VEHICLE_DISCOUNT_FAIL;

  constructor(public vehicleId: string, public error: any) {}
}

export class UpdateVehicleDiscountSuccess implements Action {
  readonly type = ModifierActionTypes.UPDATE_VEHICLE_DISCOUNT_SUCCESS;
  constructor(
    public vehicleId: string,
    public payload: EligibleDiscountEntity
  ) {}
}

export class UpdatePowersportsVehicleDiscount implements Action {
  readonly type = ModifierActionTypes.UPDATE_POWERSPORTS_VEHICLE_DISCOUNT;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdatePowersportsVehicleDiscountFail implements Action {
  readonly type = ModifierActionTypes.UPDATE_POWERSPORTS_VEHICLE_DISCOUNT_FAIL;

  constructor(public vehicleId: string, public error: any) {}
}

export class UpdatePowersportsVehicleDiscountSuccess implements Action {
  readonly type =
    ModifierActionTypes.UPDATE_POWERSPORTS_VEHICLE_DISCOUNT_SUCCESS;
  constructor(
    public vehicleId: string,
    public payload: EligibleDiscountEntity
  ) {}
}

export class DisableVehicleDiscount implements Action {
  readonly type = ModifierActionTypes.DISABLE_VEHICLE_DISCOUNT;
  constructor(public vehicleId: string, public payload: string) {}
}

export class UpdateAssociateDiscount implements Action {
  readonly type = ModifierActionTypes.UPDATE_ASSOCIATE_DISCOUNT;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdateAssociateDiscountSuccess implements Action {
  readonly type = ModifierActionTypes.UPDATE_ASSOCIATE_DISCOUNT_SUCCESS;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdateAssociateDiscountFail implements Action {
  readonly type = ModifierActionTypes.UPDATE_ASSOCIATE_DISCOUNT_FAIL;
  constructor(public error: any) {}
}

export class ModifierActionCancelled implements Action {
  readonly type = ModifierActionTypes.MODIFIER_ACTION_CANCELLED;
  constructor() {}
}

export class CheckEasyPayDiscount implements Action {
  readonly type = ModifierActionTypes.CHECK_EASY_PAY_DISCOUNT;
  constructor(
    public paymentPlan: string,
    public paymentMethod: string,
    public recurring: boolean
  ) {}
}

export class RemoveEasyPayDiscount implements Action {
  readonly type = ModifierActionTypes.REMOVE_EASY_PAY_DISCOUNT;
  constructor() {}
}

export class AddEasyPayDiscount implements Action {
  readonly type = ModifierActionTypes.ADD_EASY_PAY_DISCOUNT;
  constructor() {}
}

export class UpdateMembershipDiscount implements Action {
  readonly type = ModifierActionTypes.UPDATE_MEMBERSHIP_DISCOUNT;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdateMembershipDiscountSuccess implements Action {
  readonly type = ModifierActionTypes.UPDATE_MEMBERSHIP_DISCOUNT_SUCCESS;
  constructor(public payload: EligibleDiscountEntity) {}
}

export class UpdateMembershipDiscountFail implements Action {
  readonly type = ModifierActionTypes.UPDATE_MEMBERSHIP_DISCOUNT_FAIL;
  constructor(public error: any) {}
}

export class UpdateBillingPaymentMethod implements Action {
  readonly type = ModifierActionTypes.UPDATE_BILLING_PAYMENT_METHOD;
  constructor(public payload: EligibleDiscountEntity) {}
}

export type ModifierActions =
  | AddAllModifiers
  | UpdateDriverDiscount
  | UpdateDriverDiscountFail
  | UpdateDriverDiscountSuccess
  | UpdatePowersportsDriverDiscount
  | UpdatePowersportsDriverDiscountFail
  | UpdatePowersportsDriverDiscountSuccess
  | GetAllPolicyLine
  | GetAllPolicyLineFail
  | GetAllPolicyLineSuccess
  | GetPolicyLine
  | GetPolicyLineFail
  | GetPolicyLineSuccess
  | UpdatePolicyLine
  | UpdatePolicyLineFail
  | UpdatePolicyLineSuccess
  | SetSmartRideLoaded
  | DefaultSmartRideTrue
  | SmartRideAlreadySet
  | UnsetSmartrideLoaded
  | UpdateVehicleDiscount
  | UpdateVehicleDiscountSuccess
  | UpdateVehicleDiscountFail
  | UpdatePowersportsVehicleDiscount
  | UpdatePowersportsVehicleDiscountSuccess
  | UpdatePowersportsVehicleDiscountFail
  | DisableVehicleDiscount
  | UpdateSmartHomeDiscountSuccess
  | UpdateSmartHomeDiscount
  | UpdateSmartHomeDiscountFail
  | UpdateAssociateDiscount
  | UpdateAssociateDiscountSuccess
  | UpdateAssociateDiscountFail
  | ModifierActionCancelled
  | CheckEasyPayDiscount
  | RemoveEasyPayDiscount
  | AddEasyPayDiscount
  | UpdateMembershipDiscount
  | UpdateMembershipDiscountSuccess
  | UpdateMembershipDiscountFail
  | UpdateBillingPaymentMethod;
