import { Action } from '@ngrx/store';
import {
  TelematicsEnrollmentResponse,
  TelematicsEnrollmentRecommendationResponse,
  PreQualifiedVehicleInfo,
} from '@core/models/telematics/telematics.model';

export const enum TelematicsEnrollmentsActionTypes {
  GET_TELEMATICS_RECOMMENDATION = '[Telematics Enrollments] Get Telematics Recommendation',
  GET_TELEMATICS_RECOMMENDATION_SUCCESS = '[Telematics Enrollments] Get Telematics Recommendation Success',
  GET_TELEMATICS_RECOMMENDATION_WITHLOADED_SUCCESS = '[Telematics Enrollments] Get Telematics Recommendation Success With Loaded',
  GET_TELEMATICS_RECOMMENDATION_FAIL = '[Telematics Enrollments] Get Telematics Recommendation Fail',
  ADD_TELEMATICS_ENROLLMENT = '[Telematics Enrollments] Add Telematics Enrollment',
  ADD_TELEMATICS_ENROLLMENT_SUCCESS = '[Telematics Enrollments] Add Telematics Enrollment Success',
  ADD_TELEMATICS_ENROLLMENT_FAIL = '[Telematics Enrollments] Add Telematics Enrollment Fail',
  UPDATE_TELEMATICS_ENROLLMENT = '[Telematics Enrollments] Update Telematics Enrollment',
  ADD_TELEMATICS_ENROLLMENT_FOR_PREQUALIFIEDPARTNERID_FLOW = '[Telematics Enrollments] Update Telematics ' +
    'Enrollment For PREQUALIFIEDPARTNERID FLOW',
  UPDATE_TELEMATICS_ENROLLMENT_SUCCESS = '[Telematics Enrollments] Update Telematics Enrollment Success',
  UPDATE_TELEMATICS_ENROLLMENT_FAIL = '[Telematics Enrollments] Update Telematics Enrollment Fail',
  REMOVE_TELEMATICS_ENROLLMENT = '[Telematics Enrollments] Remove Telematics Enrollment',
  REMOVE_TELEMATICS_ENROLLMENT_SUCCESS = '[Telematics Enrollments] Remove Telematics Enrollment Success',
  REMOVE_TELEMATICS_ENROLLMENT_FAIL = '[Telematics Enrollments] Remove Telematics Enrollment Fail',
  GET_TELEMATICS_ENROLLMENTS = '[Telematics Enrollments] Get Telematics Enrollment',
  GET_TELEMATICS_ENROLLMENTS_SUCCESS = '[Telematics Enrollments] Get Telematics Enrollment Success',
  GET_TELEMATICS_ENROLLMENTS_FAIL = '[Telematics Enrollments] Get Telematics Enrollment Fail',
  UNSET_TELEMATICS_ENROLLMENT_LOADED = '[Telematics Enrollments] Unset Telematics Enrollement loaded',
  SET_TELEMATICS_ENROLLMENT = '[Telematics Enrollments] Set Telematics Enrollment',
  UPDATE_PREQUALIFIED_VEHICLE_INFO = '[Telematics Enrollments] Update PreQualified Vehicle Info',
  UPDATE_MOBILE_INFO_TELEMATICS_ENROLLEMENT = '[Telematics Enrollments] Update MobileNo Info For Vehicle Enrollment',
  UPDATE_MOBILE_INFO_TELEMATICS_ENROLLEMENT_SUCCESS = '[Telematics Enrollments] Update MobileNo Info For Vehicle Enrollment Success',
  UPDATE_MOBILE_INFO_TELEMATICS_ENROLLEMENT_FAIL = '[Telematics Enrollments] Update MobileNo Info For Vehicle Enrollment Fail',
}

export class GetTelematicsEnrollments implements Action {
  readonly type = TelematicsEnrollmentsActionTypes.GET_TELEMATICS_ENROLLMENTS;
  constructor() {}
}

export class GetTelematicsEnrollmentsSuccess implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.GET_TELEMATICS_ENROLLMENTS_SUCCESS;
  constructor(public payload: TelematicsEnrollmentResponse) {}
}

export class GetTelematicsEnrollmentsFail implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.GET_TELEMATICS_ENROLLMENTS_FAIL;
  constructor(public payload: any) {}
}

export class GetTelematicsRecommendation implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION;
  constructor(public payload: any) {}
}

export class GetTelematicsRecommendationSuccess implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION_SUCCESS;
  constructor(public payload: TelematicsEnrollmentRecommendationResponse) {}
}

export class GetTelematicsRecommendationWithLoadedSuccess implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION_WITHLOADED_SUCCESS;
  constructor(public payload: TelematicsEnrollmentRecommendationResponse) {}
}
export class GetTelematicsRecommendationFail implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION_FAIL;
  constructor(public payload: any) {}
}

export class AddTelematicsEnrollment implements Action {
  readonly type = TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT;
  constructor(public payload: any) {}
}

export class AddTelematicsEnrollmentSuccess implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT_SUCCESS;
  constructor(public payload: TelematicsEnrollmentResponse) {}
}

export class AddTelematicsEnrollmentFail implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT_FAIL;
  constructor(public payload: any) {}
}

export class UpdateTelematicsEnrollment implements Action {
  readonly type = TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_ENROLLMENT;
  constructor(public payload: any) {}
}

export class UpdateTelematicsEnrollmentSuccess implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_ENROLLMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateTelematicsEnrollmentForPreQualifiedPartnerIdFlow
  implements Action
{
  readonly type =
    TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT_FOR_PREQUALIFIEDPARTNERID_FLOW;
  constructor(public payload: any) {}
}

export class UpdateTelematicsEnrollmentFail implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_ENROLLMENT_FAIL;
  constructor(public payload: any) {}
}

export class RemoveTelematicsEnrollment implements Action {
  readonly type = TelematicsEnrollmentsActionTypes.REMOVE_TELEMATICS_ENROLLMENT;
  constructor(public payload?: any) {}
}

export class RemoveTelematicsEnrollmentSuccess implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.REMOVE_TELEMATICS_ENROLLMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class RemoveTelematicsEnrollmentFail implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.REMOVE_TELEMATICS_ENROLLMENT_FAIL;
  constructor(public payload: any) {}
}

export class SetTelematicsEnrollment implements Action {
  readonly type = TelematicsEnrollmentsActionTypes.SET_TELEMATICS_ENROLLMENT;
  constructor(public payload: TelematicsEnrollmentResponse) {}
}

export class UnsetTelematicsEnrollmentLoaded implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.UNSET_TELEMATICS_ENROLLMENT_LOADED;
  constructor() {}
}

export class UpdatePreQualifiedVehicleInfo implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.UPDATE_PREQUALIFIED_VEHICLE_INFO;
  constructor(public payload: PreQualifiedVehicleInfo[]) {}
}

export class UpdateMobileInfoTelematicsEnrollment implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.UPDATE_MOBILE_INFO_TELEMATICS_ENROLLEMENT;
  constructor(public payload: any) {}
}
export class UpdateMobileInfoTelematicsEnrollmentSuccess implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.UPDATE_MOBILE_INFO_TELEMATICS_ENROLLEMENT_SUCCESS;
  constructor(public payload: any) {}
}
export class UpdateMobileInfoTelematicsEnrollmentFail implements Action {
  readonly type =
    TelematicsEnrollmentsActionTypes.UPDATE_MOBILE_INFO_TELEMATICS_ENROLLEMENT_FAIL;
  constructor(public payload: any) {}
}

export type TelematicsEnrollmentsAction =
  | GetTelematicsRecommendation
  | GetTelematicsRecommendationSuccess
  | GetTelematicsRecommendationWithLoadedSuccess
  | GetTelematicsRecommendationFail
  | AddTelematicsEnrollment
  | AddTelematicsEnrollmentSuccess
  | AddTelematicsEnrollmentFail
  | UpdateTelematicsEnrollment
  | UpdateTelematicsEnrollmentForPreQualifiedPartnerIdFlow
  | UpdateTelematicsEnrollmentSuccess
  | UpdateTelematicsEnrollmentFail
  | RemoveTelematicsEnrollment
  | RemoveTelematicsEnrollmentSuccess
  | RemoveTelematicsEnrollmentFail
  | GetTelematicsEnrollments
  | GetTelematicsEnrollmentsSuccess
  | GetTelematicsEnrollmentsFail
  | UnsetTelematicsEnrollmentLoaded
  | SetTelematicsEnrollment
  | UpdatePreQualifiedVehicleInfo
  | UpdateMobileInfoTelematicsEnrollment
  | UpdateMobileInfoTelematicsEnrollmentSuccess
  | UpdateMobileInfoTelematicsEnrollmentFail;
