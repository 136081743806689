import { Action } from '@ngrx/store';

export const enum condoPropertyInfoActionsTypes {
  LOAD_PICKLIST = '[condo propertyInfo] Load condo pick list values',
}

export class LoadPickListCondo implements Action {
  readonly type = condoPropertyInfoActionsTypes.LOAD_PICKLIST;
  constructor(public payload: any) {}
}

export type CondoPropertyInfoActions = LoadPickListCondo;
