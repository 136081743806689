import { Injectable } from '@angular/core';
import { PremiumDao } from '@core/dao/premium.dao';
import { TermAmount } from '@core/models/entities/coverage.entity';
import { PremiumEntity } from '@core/models/entities/premium.entity';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PremiumService {
  constructor(private _premiumDao: PremiumDao) {}

  getAllPremiums(): Observable<PremiumEntity[]> {
    return this._premiumDao.getAllPremiums();
  }

  getPremiumLoaded(): Observable<boolean> {
    return this._premiumDao.getPremiumLoaded();
  }

  getPremiumLoading(): Observable<boolean> {
    return this._premiumDao.getPremiumLoading();
  }

  updatePremium(productid: string, total: TermAmount): void {
    this._premiumDao.updatePremium(productid, total);
  }
}
