import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AgencyDao } from '@core/dao/agency.dao';

import { AgencyModel } from '@core/models/agency/agency.model';
import {
  ProducerSearch,
  Producer,
} from '@core/models/agency/producer-search.model';
import { AgencyAdapter } from '@core/adapters/agency.adapter';
import { AppConfigService } from './app-config.service';
import { take } from 'rxjs/operators';
import { PC_INTERNAL_EXTERNAL_MAPPING } from '@shared/constants/app-constants';
import { UserContextService } from './user-context.service';

@Injectable()
export class AgencyService {
  constructor(
    private _agencyDao: AgencyDao,
    private _agencyAdapter: AgencyAdapter,
    private _appConfig: AppConfigService,
    private _userContextService: UserContextService
  ) {}

  getAgency(): Observable<AgencyModel> {
    return this._agencyDao.getAgency();
  }

  updateAgency(agent: AgencyModel) {
    this._agencyDao.updateAgency(agent);
  }

  isAgent(): Observable<boolean> {
    return this._agencyDao.isAgent();
  }

  isNSSAgent(): Observable<boolean> {
    return this._agencyDao.isNSSAgent();
  }

  isDirectUser(): Observable<boolean> {
    return this._agencyDao.isDirectUser();
  }

  isPrivateLabel(): Observable<boolean> {
    return this._agencyDao.isPrivateLabel();
  }

  isCompRater(): Observable<boolean> {
    return this._userContextService.isCompRater();
  }

  updateAgentJwt(jwtContents: any, accessToken: string = null) {
    this._agencyDao.updateAgentJwt(jwtContents, accessToken);
  }

  getAgentJwt() {
    return this._agencyDao.getAgentJwt();
  }

  producerCodeSearch(token: string): Observable<ProducerSearch> {
    return this._agencyAdapter.producerCodeSearch(token);
  }

  dispatchProducerCodeSearch(): void {
    this._agencyDao.dispatchProducerCodeSearch();
  }

  getAgencyCodes(): Observable<string[]> {
    return this._agencyDao.getAgencyCodes();
  }

  getPDSCodes(): Observable<Producer[]> {
    return this._agencyDao.getPDSCodes();
  }

  getProducerIdsByAgencyCode(agencyCode: string): Observable<Producer[]> {
    return this._agencyDao.getProducerIdsByAgencyCode(agencyCode);
  }

  buildPCDeepLaunchUrl(quoteId: string): string {
    const url = this.buildBasePolicyCenterUrl();
    return url + '?JobNumber=' + quoteId;
  }

  private buildBasePolicyCenterUrl(): string {
    let url: string;
    let useExternal: boolean;

    this.getAgency()
      .pipe(take(1))
      .subscribe(agency => {
        if (agency) {
          if (
            (agency.agencyChannel === 'IA' || agency.agencyChannel === 'EA') &&
            !agency.isPrivateLabel
          ) {
            useExternal = true;
          }
        }
      });
    let targetEnv = this._appConfig.config.targetEnv;
    if (useExternal) {
      url = this._appConfig.config.pcExternalDeepLaunchUrl;
      targetEnv =
        PC_INTERNAL_EXTERNAL_MAPPING[this._appConfig.config.targetEnv];
    } else {
      url = this._appConfig.config.pcInternalDeepLaunchUrl;
    }
    url = url.replace('<targetEnv>', targetEnv);

    return url;
  }

  buildPCHydrationUrl(quoteId: string): string {
    const url = this.buildBasePolicyCenterUrl();
    return `${url}?LaunchingAppId=AC&Source=CompRater&ExternalQuoteID=${quoteId}`;
  }

  isEAOrIAAgentQuoting(): Observable<boolean> {
    return this._agencyDao.isEAOrIAAgentQuoting();
  }
}
