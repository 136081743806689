<form
  [formGroup]="compRaterRetrieveForm"
  (ngSubmit)="onSubmit(compRaterRetrieveForm)"
>
  <mq-field-validation-message
    [form]="compRaterRetrieveForm"
    [complainAboutRequiredFields]="submitted$ | async"
  ></mq-field-validation-message>

  <div class="nw-container nw-inner-bun">
    <div class="row">
      <div class="col-12">
        <h1>Congratulations!</h1>
        <div class="top-subtitle">
          This customer is eligible for Nationwide Express, our fastest sales
          experience.
        </div>
      </div>
    </div>

    <div
      *ngIf="errorMessage$ | async as errorMessage"
      class="row error-alert"
    >
      <div class="header alert alert-danger col" role="alert">
        <a class="icon error"></a>
        <span>{{ errorMessage }}</span>
      </div>
    </div>

    <!-- For Transitioning Exclusive Agents (NWIE/PDS) -->
    <div *ngIf="isEATP$ | async">
      <div class="row form-group top-space">
        <div class="col-xs-12 col-sm-6">
          <label for="producer-id">Producer ID</label>
          <div class="input-group">
            <select
              id="producer-id"
              class="form-control form-select"
              label="Producer ID"
              required="required"
              ngDefaultControl
              formControlName="producerId"
            >
              <option
                *ngFor="let pdsCode of pdsCodes$ | async"
                value="{{ pdsCode.producerCode }}:{{ pdsCode.agentState }}"
              >
                {{ pdsCode.agentName }} - {{ pdsCode.producerCode }}
              </option>
            </select>
            <div class="input-group-text"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- For Independent Agents (RACF/AMF) -->
    <div *ngIf="!(isEATP$ | async)">
      <div class="row form-group top-space">
        <div class="col-xs-12 col-sm-6">
          <label for="agency-code">Agency Code</label>
          <div class="input-group">
            <select
              id="agency-code"
              class="form-control form-select"
              label="Agency code"
              required="required"
              ngDefaultControl
              formControlName="agencyCode"
            >
              <option
                *ngFor="let agencyCode of agencyCodes$ | async"
                [value]="agencyCode"
              >
                {{ agencyCode }}
              </option>
            </select>
            <div class="input-group-text"></div>
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-xs-12 col-sm-6">
          <label for="producer-id2">Producer ID</label>
          <div class="input-group">
            <select
              id="producer-id2"
              class="form-control form-select"
              label="Producer ID"
              required="required"
              ngDefaultControl
              formControlName="producerId"
            >
              <option
                *ngFor="let producerId of producerIds$ | async"
                value="{{ producerId.producerCode }}:{{
                  producerId.agentState
                }}"
              >
                {{ producerId.agentName }} - {{ producerId.producerCode }}
              </option>
            </select>
            <div class="input-group-text"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row vertical-space form-group">
      <div class="col-12 button-container">
        <button class="btn btn-primary" size="lg">
          Go to Nationwide Express
        </button>
      </div>
    </div>

    <div class="row vertical-space form-group">
      <div class="col-12 unemphasized-link">
        <a href="#" (click)="bridgeToPolicyCenter($event)">
          Go to PolicyCenter
        </a>
      </div>
    </div>

    <hr style="border: 1px solid gray" />

    <div class="row news">
      <div class="col-12" [innerHTML]="newsHtml$ | async"></div>
    </div>
  </div>
</form>
