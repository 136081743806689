import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DistributedDocumentsAdapter } from '@core/adapters/distributed-documents.adapter';
import { DocumentsResponse } from '@core/models/distributed-documents/documents-response.model';

@Injectable({
  providedIn: 'root',
})
export class DistributedDocumentsService {
  constructor(
    private _distributedDocumentsAdapter: DistributedDocumentsAdapter
  ) {}

  documents(request: any): Observable<DocumentsResponse> {
    return this._distributedDocumentsAdapter.documents(request);
  }
}
