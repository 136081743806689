<div class="connected-car" *ngIf="vehicles.length">
  <nw-display-panel
    heading="Enrolled in our connected car, safe-driving behavior program, SmartRide&reg;"
    [alwaysOpen]="true"
    [openText]="headerIcon"
    class="program-panel"
  >
    <div class="container tm-content-container">
      <p>
        Save {{ initialDiscount }} just for signing up. The safer the vehicle is
        driven the higher the discount - up to {{ maximumDiscount }}.

        <inline-help
          [labelInnerHTML]="''"
          [fieldId]="'srcc-discount-fieldId'"
          [helpTextInnerHTML]="getDiscounthelpText()"
        ></inline-help>
      </p>
      <div class="row">
        <div class="col-1">
          <img
            class="tm-icon"
            src="assets/images/telematics/no-device-dk-blue.svg"
            alt="Sign-Up Now and Qualify for an Immediate Discount - No Device Required."
          />
        </div>
        <div class="col-md-3 col-sm-10 offset-sm-1 offset-md-0 col-xs-12">
          <p class="font-weight-bold">{{ getLeftSignupContent() }}</p>
        </div>
        <div class="col-1">
          <img
            class="tm-icon"
            src="assets/images/telematics/dollar-lock-dk-blue.svg"
            alt="Dollar with a Lock to Represent How Discount is Finalized after 6 months"
          />
        </div>
        <div class="col-md-3 col-sm-10 offset-sm-1 offset-md-0 col-xs-12">
          <p class="font-weight-bold">
            In about 6 months, we calculate the final discount.
          </p>
        </div>
        <mq-telematics-video
          *ngIf="isNotSRCCForCA()"
          [name]="'ConnectedCar'"
          [isDeviceOnlyState]="isDeviceOnlyState"
          [mobileAppEnrolled]="mobileAppEnrolled"
          [stateSpecificFlags]="stateSpecificFlags"
        ></mq-telematics-video>
      </div>
    </div>
    <mq-telematics-vehicle-premium
      [vehicles]="vehicles"
      [vehiclePremiums]="vehiclePremiums"
      (enrollmentChanged)="changeEnrollment($event)"
    ></mq-telematics-vehicle-premium>
  </nw-display-panel>
</div>
