import { Action } from '@ngrx/store';

export const enum PolicyIssueTypes {
  ISSUE_POLICY = '[Policy Issue] Issue Policy',
  ISSUE_POLICY_FAIL = '[Policy Issue] Issue Policy Fail',
  ISSUE_POLICY_SUCCESS = '[Policy Issue] Issue Policy Success',
  MARK_PRODUCT_ISSUING = '[Policy Issue] Mark Product Issuing',
}

export class IssuePolicy implements Action {
  readonly type = PolicyIssueTypes.ISSUE_POLICY;
}

export class IssuePolicyFail implements Action {
  readonly type = PolicyIssueTypes.ISSUE_POLICY_FAIL;
  constructor(public productId: string, public error: any) {}
}

export class IssuePolicySuccess implements Action {
  readonly type = PolicyIssueTypes.ISSUE_POLICY_SUCCESS;
  constructor(public payload: any) {}
}

export class MarkProductIssuing implements Action {
  readonly type = PolicyIssueTypes.MARK_PRODUCT_ISSUING;
  constructor(public productId: string) {}
}

export type PolicyIssueActions =
  | IssuePolicy
  | IssuePolicyFail
  | IssuePolicySuccess
  | MarkProductIssuing;
