import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors/protective-devices.selector';
import { ProtectiveDevice } from '@core/store/reducers/protective-devices.reducer';

@Injectable({
  providedIn: 'root',
})
export class ProtectiveDevicesDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getProtectiveDevices(): Observable<ProtectiveDevice[]> {
    return this._store.select(fromSelectors.getProtectiveDevicesData);
  }

  getProtectiveDevicesLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getProtectiveDevicesLoaded);
  }

  dispatchGetProtectiveDevices() {
    this._store.dispatch(new fromActions.GetProtectiveDevices());
  }

  updateProtectiveDevices(devices: ProtectiveDevice[]) {
    this._store.dispatch(new fromActions.UpdateProtectiveDevices(devices));
  }
}
