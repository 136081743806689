import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromPreBindDocuments from '@core/store/actions/pre-bind-documents.action';
import {
  PreBindDocumentEntity,
  PreBindDocumentUploadResponse,
} from '@core/models/entities/pre-bind-document.entity';

export interface PreBindDocumentsState
  extends EntityState<PreBindDocumentEntity> {
  uploadResponse?: PreBindDocumentUploadResponse;
  uploadPowersportsResponse?: PreBindDocumentUploadResponse;
  loading: boolean;
  loaded: boolean;
  emailResending: boolean;
  emailResent: boolean;
}

export const adapter: EntityAdapter<PreBindDocumentEntity> =
  createEntityAdapter<PreBindDocumentEntity>({
    selectId: document => document.documentId,
  });

export function reducer(
  state = adapter.getInitialState({
    uploadResponse: null,
    uploadPowersportsResponse: null,
    loaded: false,
    loading: false,
    emailResending: false,
    emailResent: false,
  }),
  action: fromPreBindDocuments.PreBindDocumentActions
): PreBindDocumentsState {
  switch (action.type) {
    case fromPreBindDocuments.PreBindDocumentActionTypes
      .UPLOAD_PREBIND_DOCUMENTS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromPreBindDocuments.PreBindDocumentActionTypes
      .UPLOAD_POWERSPORTS_PREBIND_DOCUMENTS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromPreBindDocuments.PreBindDocumentActionTypes
      .UPLOAD_PREBIND_DOCUMENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromPreBindDocuments.PreBindDocumentActionTypes
      .UPLOAD_POWERSPORTS_PREBIND_DOCUMENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromPreBindDocuments.PreBindDocumentActionTypes
      .UPLOAD_PREBIND_DOCUMENTS_SUCCESS: {
      return adapter.setAll(action.preBindDocuments, {
        ...state,
        uploadResponse: action.uploadResponse,
        loading: false,
        loaded: true,
      });
    }

    case fromPreBindDocuments.PreBindDocumentActionTypes
      .UPLOAD_POWERSPORTS_PREBIND_DOCUMENTS_SUCCESS: {
      return adapter.setAll(action.preBindDocuments, {
        ...state,
        uploadPowersportsResponse: action.uploadResponse,
        loading: false,
        loaded: true,
      });
    }

    case fromPreBindDocuments.PreBindDocumentActionTypes
      .ADD_ALL_PREBIND_DOCUMENTS: {
      return adapter.setAll(action.documents, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case fromPreBindDocuments.PreBindDocumentActionTypes
      .RESEND_DOCUMENT_URL_EMAIL: {
      return {
        ...state,
        emailResending: true,
        emailResent: false,
      };
    }

    case fromPreBindDocuments.PreBindDocumentActionTypes
      .RESEND_DOCUMENT_URL_EMAIL_SUCCESS: {
      const uploadResponse = {
        ...state.uploadResponse,
        emailSuccess: !action.response.hasError,
      };
      return {
        ...state,
        uploadResponse,
        emailResending: false,
        emailResent: true,
      };
    }

    case fromPreBindDocuments.PreBindDocumentActionTypes
      .RESEND_DOCUMENT_URL_EMAIL_FAIL: {
      const uploadResponse = {
        ...state.uploadResponse,
        emailSuccess: false,
      };
      return {
        ...state,
        uploadResponse,
        emailResending: false,
        emailResent: true,
      };
    }
  }
  return state;
}
