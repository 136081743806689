import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  QueryList,
  ViewChildren,
  OnDestroy,
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SearchModel } from '@core/models/search/search.model';
import { SearchProxy } from 'app/search/proxies';
import { SearchEntity, SearchLob } from '@core/models/entities/search.entity';
import { RetrieveModel } from '@core/models/retrieve/retrieve.model';
import {
  ProductTypes,
  RetrieveProductTypes,
} from '@shared/constants/app-constants';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { RetrieveProxy } from 'app/retrieve/proxies';
import { SortableHeaderDirective } from '@shared/directives/sortable-header/sortable-header.directive';
import { SortEvent } from '@core/models/sort/sort.model';
import { SearchTableUtils } from '@shared/utils/search-table.utils';
import { ProductsService } from '@core/services';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Component({
  selector: 'mq-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
  loading$: Observable<boolean> = null;
  error$: Observable<string> = null;
  searchQuotes$: Observable<SearchEntity[]>;
  searchQuotesResult$: Observable<SearchEntity[]>;
  total$: Observable<number>;
  selectedQuotes: SearchEntity[] = [];
  disabledQuotes: SearchEntity[] = [];
  selectedQuotesError$: ReplaySubject<string> = new ReplaySubject<string>();

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  private retrieveCompleteSubscription = null;

  constructor(
    private _searchProxy: SearchProxy,
    private _retrieveProxy: RetrieveProxy,
    public service: SearchTableUtils,
    private _productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this._searchProxy.dispatchActiveAlert();
    this._productsService.updateSelectedProducts([]);
    this.error$ = this._searchProxy.getSearchQuotesErrorMessage();
    this.searchQuotesResult$ = this._searchProxy.getAllSearchQuotes();
    this.searchQuotes$ = this.service.quotes$;
    this.total$ = this.service.total$;
    this.loading$ = this._retrieveProxy.getAppLoading();
    this.selectedQuotesError$.subscribe();
    this._searchProxy.logPageLoaded();
  }

  ngOnDestroy(): void {
    if (this.retrieveCompleteSubscription) {
      this.retrieveCompleteSubscription.unsubscribe();
    }
  }

  onSubmit(model: SearchModel): void {
    this._searchProxy.clearSearchQuotesError();
    this.selectedQuotes = [];
    this.disabledQuotes = [];
    this.selectedQuotesError$.next('');

    this._searchProxy.loadingBegin();
    this._searchProxy.submitSearchRequest(model);
    this._searchProxy.logSearchSubmit(model);
  }

  onForward(): void {
    const message = this.describeErrorInSelectedQuotes();
    if (message) {
      this.selectedQuotesError$.next(message);
      return;
    }
    this._retrieveProxy.clearRetrieveFailures();
    this._retrieveProxy.loadingBegin();

    this.selectedQuotes.forEach(quote => {
      const retrieveQuoteRequest = this.buildRetrieveQuoteRequest(quote);
      this._retrieveProxy.submitRetrievalRequest(retrieveQuoteRequest);
      this._retrieveProxy.logPageSubmit(
        'SEARCH_RETRIEVE_SUBMIT',
        retrieveQuoteRequest
      );
    });

    if (this.retrieveCompleteSubscription) {
      this.retrieveCompleteSubscription.unsubscribe();
    }

    this.retrieveCompleteSubscription = this._retrieveProxy
      .getRetrieveLoading()
      .pipe(
        filter(loaded => !loaded),
        take(1),
        withLatestFrom(this._retrieveProxy.getAllRetrievedQuotes())
      )
      .subscribe(() => {
        this._retrieveProxy
          .navigateToGettingStarted()
          .then(() => {
            this._retrieveProxy.loadingEnd();
          })
          .catch(error => {
            this._retrieveProxy.setRetrieveError({
              quoteId: '',
              productType: '',
              errorCode:
                this._retrieveProxy.extractErrorCodeFromAnything(error) ||
                ErrorHelper.ERR_GENERAL_SEARCH,
            });
            this._retrieveProxy.loadingEnd();
          });
      });
  }

  /**
   * Return null if selectedQuotes looks valid, otherwise say why not.
   */
  private describeErrorInSelectedQuotes(): string | null {
    if (this.selectedQuotes.length < 1) {
      return 'At least one quote must be selected to retrieve.';
    }
    if (this.selectedQuotes.length > 2) {
      return 'Only one auto and one property quote can be retrieved together.';
    }
    let autoCount = 0,
      propertyCount = 0;
    for (const quote of this.selectedQuotes) {
      if (!this.productIsRetrievable(quote.lineOfBusiness)) {
        return `${quote.lineOfBusiness} quotes are not currently retrievable.`;
      }
      if (this.lineOfBusinessIsAuto(quote.lineOfBusiness)) {
        autoCount++;
      } else if (this.lineOfBusinessIsProperty(quote.lineOfBusiness)) {
        propertyCount++;
      }
    }
    if (this.selectedQuotes.length === 2) {
      if (autoCount !== 1 || propertyCount !== 1) {
        return 'Only one auto and one property quote can be retrieved together.';
      }
    }
    return null;
  }

  private productIsRetrievable(lineOfBusiness: SearchLob): boolean {
    switch (lineOfBusiness) {
      case 'PersonalAuto':
        return true;
      case 'Homeowner':
        return true;
      case 'Tenant':
        return true;
      case 'Condominium':
        return true;
    }
    return false;
  }

  private lineOfBusinessIsAuto(lineOfBusiness: SearchLob): boolean {
    return lineOfBusiness === 'PersonalAuto';
  }

  private lineOfBusinessIsProperty(lineOfBusiness: SearchLob): boolean {
    return (
      lineOfBusiness === 'Homeowner' ||
      lineOfBusiness === 'Tenant' ||
      lineOfBusiness === 'Condominium'
    );
  }

  quoteSelected(selectedQuote: SearchEntity): void {
    const ZIP_LEN = 5;
    this.selectedQuotesError$.next('');
    const index = this.selectedQuotes.indexOf(selectedQuote);
    if (index > -1) {
      this.selectedQuotes.splice(index, 1);
      if (!this.selectedQuotes.length) {
        this.searchQuotesResult$
          .subscribe(results => {
            const filteredResults = results.filter(
              result =>
                result.lineOfBusiness === selectedQuote.lineOfBusiness ||
                result.firstName.toLowerCase() !==
                  selectedQuote.firstName.toLowerCase() ||
                result.lastName.toLowerCase() !==
                  selectedQuote.lastName.toLowerCase() ||
                result.address.postalCode.slice(0, ZIP_LEN) !==
                  selectedQuote.address.postalCode.slice(0, ZIP_LEN)
            );
            filteredResults.forEach(result => {
              const index = this.disabledQuotes.indexOf(result);
              if (index > -1) {
                this.disabledQuotes.splice(index, 1);
              }
            });
          })
          .unsubscribe();
      }
    } else {
      if (this.selectedQuotes.length > 1) {
        const checkbox = document.getElementById(
          selectedQuote.quoteId
        ) as HTMLInputElement;
        checkbox.checked = false;
        this.selectedQuotesError$.next(
          'Only one auto and one property quote can be retrieved together.'
        );
      } else {
        this.selectedQuotes.push(selectedQuote);
        this.searchQuotesResult$
          .subscribe(results => {
            const filteredResults = results.filter(
              result =>
                (result.lineOfBusiness === selectedQuote.lineOfBusiness ||
                  !(
                    result.firstName.toLowerCase() ===
                      selectedQuote.firstName.toLowerCase() &&
                    result.lastName.toLowerCase() ===
                      selectedQuote.lastName.toLowerCase() &&
                    result.address.postalCode.slice(0, ZIP_LEN) ===
                      selectedQuote.address.postalCode.slice(0, ZIP_LEN)
                  )) &&
                !this.disabledQuotes.includes(result) &&
                !this.selectedQuotes.includes(result)
            );
            filteredResults.forEach(result => {
              if (result.quoteId !== selectedQuote.quoteId) {
                this.disabledQuotes.push(result);
              }
            });
          })
          .unsubscribe();
      }
    }
  }

  checkAutoQuotes(quote: SearchEntity): boolean {
    return ['PersonalAuto'].includes(quote.lineOfBusiness);
  }

  checkPropertyQuotes(quote: SearchEntity): boolean {
    return ['Homeowner', 'Tenant', 'Condominium'].includes(
      quote.lineOfBusiness
    );
  }

  buildRetrieveQuoteRequest(quote: SearchEntity): RetrieveModel {
    let productType;
    switch (quote.lineOfBusiness) {
      case RetrieveProductTypes.PERSONAL_AUTO:
        productType = ProductTypes.AUTO;
        break;
      case RetrieveProductTypes.HOMEOWNER:
        productType = ProductTypes.HOMEOWNERS;
        break;
      case RetrieveProductTypes.TENANT:
        productType = ProductTypes.RENTERS;
        break;
      case RetrieveProductTypes.CONDOMINIUM:
        productType = ProductTypes.CONDO;
        break;
      default:
        productType = ProductTypes.AUTO;
    }
    const retrieveQuoteRequest = {
      productType: productType,
      quoteId: quote.quoteId,
      lastName: quote.lastName,
      postalCode: quote.address.postalCode,
      accessToken: null,
    };

    return retrieveQuoteRequest;
  }

  onSort({ column, direction }: SortEvent): void {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  isChecked(quote: SearchEntity): boolean {
    if (this.selectedQuotes.includes(quote)) {
      return true;
    } else {
      return false;
    }
  }

  isDisabled(quote: SearchEntity): boolean {
    if (this.disabledQuotes.includes(quote)) {
      return true;
    } else {
      return false;
    }
  }

  deselectAll(): void {
    this.selectedQuotes = [];
    this.disabledQuotes = [];
  }
}
