<ng-container *ngIf="!hide">
  <label
    for="checkbox-{{ product.name }}"
    [ngClass]="{
      'getting-started-product': gettingStartedProduct,
      'coverages-product': !gettingStartedProduct,
      product: !gettingStartedProduct
    }"
    (touchstart)="onTouch()"
    (mouseenter)="slideUp()"
    (mouseleave)="slideDown()"
    *ngIf="!showDisabled"
  >
    <div class="heading-wrapper" [id]="product.name">
      <div class="product-wrapper">
        <div *ngIf="isMobile">
          <div #animationWrapper class="animation-wrapper">
            <div 
              [ngClass]="{
                'product-image': !isTermLifeRestrictedForNoPlayState || !isPetUnavailableState,
                'product-mobile-image-disabled': isTermLifeRestrictedForNoPlayState || isPetUnavailableState
              }" 
              [@selected]="isSelected"
            >
              <img 
                draggable="false" 
                [src]="product.imageUrl" 
                [alt]="product.name"
                (click)="selectProduct(true, $event)" 
              />
            </div>
          </div>
        </div>
        <div *ngIf="!isMobile">
          <div #animationWrapper class="animation-wrapper">
            <div [ngClass]="{
                'product-image': !isTermLifeRestrictedForNoPlayState || !isPetUnavailableState,
                'product-image-disabled': isTermLifeRestrictedForNoPlayState || isPetUnavailableState
              }" 
              [@selected]="isSelected"
            >
              <img 
                draggable="false" 
                [src]="product.imageUrl" 
                [alt]="product.name"
                (click)="selectProduct(true, $event)" 
              />
            </div>
          </div>
        </div>
        <span class="product-text" *ngIf="!isTermLifeRestrictedForNoPlayState && !isPetUnavailableState"
          ><span
            *ngIf="product.type === 'addon' && !product.isQualified" 
            style="color: #000"
            >Add
          </span>
          {{ product.name }}
        </span>

        <span
          class="product-text"
          *ngIf="
            product.id === '6' &&
            isTermLifeRestrictedForNoPlayState &&
            !isMobile
          "
          ><span
            *ngIf="product.type === 'addon' && !product.isQualified"
            style="color: #000"
            >Add
          </span>
          {{ product.name }} <br />unavailable
        </span>
        <span
          class="product-text"
          *ngIf="
            product.id === '6' && isTermLifeRestrictedForNoPlayState && isMobile
          "
          ><span
            *ngIf="product.type === 'addon' && !product.isQualified"
            style="color: #000"
            >Add
          </span>
          {{ product.name }} unavailable
        </span>

        <span class="product-text" *ngIf="
            product.id === '8' && isPetUnavailableState && !isMobile
          "><span *ngIf="product.type === 'addon' && !product.isQualified" style="color: #000">Add
          </span>
          {{ product.name }} <br />unavailable
        </span>
        <span class="product-text" *ngIf="
            product.id === '8' && isPetUnavailableState && isMobile
          "><span *ngIf="product.type === 'addon' && !product.isQualified" style="color: #000">Add
          </span>
          {{ product.name }} unavailable
        </span>

        <ng-container *isCustomer>
          <span *ngIf="shouldShowWhatYouNeedLink()" class="auto-only">
            <a
              id="url-help"
              href="{{ url }}"
              target="_blank"
              rel="noopener noreferrer"
              >{{ hyperlinkedText }}</a
            >
          </span>
        </ng-container>
      </div>

      <div class="premium-wrapper" *ngIf="product.isQualified">
        <div
          *ngIf="
            product.monthlyPremium && (product.quoteRated || product.bindRated)
          "
          [ngClass]="{
            changed:
              product.previousMonthlyPremium &&
              product?.previousMonthlyPremium !== product.monthlyPremium
          }"
          class="coverage-price"
        >
          <strong
            >{{ product?.monthlyPremium | currency : 'USD' : 'symbol' }}
            <span *ngIf="product?.monthlyPremium">/&nbsp;mo</span>
          </strong>
        </div>
        <div
          *ngIf="
            product.monthlyPremium && (product.quoteRated || product.bindRated)
          "
          [ngClass]="{
            changed:
              product.previousMonthlyPremium &&
              product?.previousMonthlyPremium !== product.monthlyPremium
          }"
          class="coverage-price"
        >
          <strong
            >{{ totalPremium | currency : 'USD' : 'symbol' }}
            <span *ngIf="product?.monthlyPremium"
              >/&nbsp;{{ termMonths }}&nbsp;mo</span
            >
          </strong>
        </div>
        <div *ngIf="product.hasError" class="coverage-error">
          <a class="icon warn"></a>
        </div>
      </div>
    </div>
    <div
      class="checkbox-wrapper"
      *ngIf="
        !product.hasError &&
        product.isQualified &&
        !isTermLifeRestrictedForNoPlayState &&
        !isPetUnavailableState
      ">
      <div class="checkbox" *ngIf="!product.hasError">
        <input
          type="checkbox"
          [attr.aria-label]="product.name"
          [attr.aria-checked]="isSelected"
          *ngIf="!product.hasError"
          [checked]="isSelected === 'true'"
          (change)="selectProduct(true, $event)"
          id="checkbox-{{ product.name }}"
        />
      </div>
    </div>
    <div class="spacer"></div>
    <div class="details-wrapper" *ngIf="!gettingStartedProduct">
      <div
        class="details"
        *ngIf="!product.hasError"
        (click)="selectProductCoverages($event)"
        (keydown)="selectProductCoveragesIfEnterOrSpace($event)"
        tabindex="0"
      >
        Coverage details
      </div>
      <div
        class="details"
        *ngIf="product.hasError"
        (click)="selectProductCoverages($event)"
        (keydown)="selectProductCoveragesIfEnterOrSpace($event)"
        tabindex="0"
      >
        See details
      </div>
      <div
        class="details"
        *ngIf="product.type === 'addon' && !product.isQualified"
        (click)="learnMore()"
        (keydown)="learnMore($event)"
        tabindex="0"
      >
        Learn more
      </div>
    </div>
  </label>

  <div *ngIf="isMobile" class="d-block d-md-none projected-content">
    <div *ngIf="isCoverageSelected()">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
