import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
} from '@angular/core';
import { ProductDisplay } from '@core/models/display/quote-coverages/products/product-display.model';
import { ErrorMessageService } from '@core/services/error-message.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'mq-coverage-error',
  templateUrl: './coverage-error.component.html',
  styleUrls: ['./coverage-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageErrorComponent implements OnChanges {
  @Input()
  product: ProductDisplay;

  constructor(private _errorMessageService: ErrorMessageService) {}

  ngOnChanges() {
    this._errorMessageService
      .addErrorMessageToProducts([this.product])
      .pipe(take(1))
      .subscribe(([product]) => {
        this.product = <ProductDisplay>{
          name: product.name,
          errorCode: product.errorCode,
          errorMessage: product.errorMessage,
        };
      });
  }
}
