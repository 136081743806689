import {
  ProtectiveDevicesAction,
  ProtectiveDevicesActionTypes,
} from '@core/store/actions/protective-devices.action';
import { Product } from '@core/models/products/product.model';

// get from Policy Center
export interface ProtectiveDevice {
  type?: string;
  value?: string;
  selectedValues?: string[];
  category?: string;
}

// Send a request to Patch
export interface ProtectiveDevicesRequest {
  product: Product;
  devices: ProtectiveDevice[];
}

export interface ProtectiveDevicesInfo {
  sprinklerSystem?: string;
  burglarAlarm?: string;
  fireOrSmokeAlarm?: string;
  hasWroughtIronBar?: boolean;
  hasSecondaryWaterResistance?: true;
  fireProtectiveDevice?: string;
}

export interface ProtectiveDevicesState {
  data: any[];
  form: any;
  loaded: boolean;
}

const initialState: ProtectiveDevicesState = {
  data: null,
  form: null,
  loaded: false,
};

export function reducer(
  state = initialState,
  action: ProtectiveDevicesAction
): ProtectiveDevicesState {
  switch (action.type) {
    case ProtectiveDevicesActionTypes.UPDATE_PROTECTIVE_DEVICES_SUCCESS:
      return { ...state, data: action.payload, loaded: true };

    case ProtectiveDevicesActionTypes.GET_PROTECTIVE_DEVICES_SUCCESS:
      return { ...state, data: action.payload, loaded: true };

    case ProtectiveDevicesActionTypes.GET_PROTECTIVE_DEVICES_FAIL: {
      return { ...state, data: action.payload, loaded: true };
    }

    default:
      return { ...state };
  }
}
