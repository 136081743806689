import { Action } from '@ngrx/store';

import { Product } from '@core/models/products/product.model';
import { Update } from '@ngrx/entity';

// load products
export const LOAD_PRODUCTS = '[Core] Load Products';
export const LOAD_PRODUCTS_FAIL = '[Core] Load Products Fail';
export const LOAD_PRODUCTS_SUCCESS = '[Core] Load Products Success';
export const LOAD_ACCESS_TOKEN = '[Core] Load Access Token';
export const LOAD_ACCESS_TOKEN_SUCCESS = '[Core] Load Access Token Success';
export const LOAD_ACCESS_TOKEN_FAIL = '[Core] Load Access Token Fail';
export const ADD_PRODUCT = '[Product] Add Product';
export const UPDATE_PRODUCT = '[Product] Update Product';
export const SET_LEADS_SENT_TO_CMA = '[Product] Set Leads Sent to CMA';
export const FAIL_PRODUCT = '[Product] Fail Product';
export const FAIL_ALL_PRODUCTS = '[Product] Fail All Products';
export const INITIATE_PRODUCT_BEGIN = '[Product] Initiate Product Begin';
export const INITIATE_PRODUCT_END = '[Product] Initiate Product End';
export const CHECK_UMBRELLA_ELIGIBILITY =
  '[Product] Check Umbrella Eligibility';
export const DISQUALIFY_UMBRELLA = '[Product] Disqualify Umbrella';

export class LoadProducts implements Action {
  readonly type = LOAD_PRODUCTS;
}

export class LoadProductsFail implements Action {
  readonly type = LOAD_PRODUCTS_FAIL;
  constructor(public payload: any) {}
}

export class LoadProductsSuccess implements Action {
  readonly type = LOAD_PRODUCTS_SUCCESS;
  constructor(public payload: Product[]) {}
}

export class UpdateProduct implements Action {
  readonly type = UPDATE_PRODUCT;
  constructor(public payload: Update<Product>) {}
}

export class UpdateAllProducts implements Action {
  readonly type = UPDATE_ALL_PRODUCTS;
  constructor(public payload: Update<Product>[]) {}
}

export class SetLeadsSentToCma implements Action {
  readonly type = SET_LEADS_SENT_TO_CMA;
  constructor() {}
}

// selected products
export const LOAD_SELECTED_PRODUCTS = '[Core] Load Selected Products';
export const UPDATE_SELECTED_PRODUCTS = '[Core] Update Selected Products';
export const ADD_TO_SELECTED_PRODUCTS = '[Core] Add to Selected Products';
export const REMOVE_FROM_SELECTED_PRODUCTS =
  '[Core] Remove from Selected Products';

export const UPDATE_ALL_PRODUCTS = '[product] UPDATE_ALL_PRODUCTS';

export class LoadSelectedProducts implements Action {
  readonly type = LOAD_SELECTED_PRODUCTS;
}

export class UpdateSelectedProducts implements Action {
  readonly type = UPDATE_SELECTED_PRODUCTS;
  constructor(public payload: Product[]) {}
}

export class AddToSelectedProducts implements Action {
  readonly type = ADD_TO_SELECTED_PRODUCTS;
  constructor(public payload: Product) {}
}

export class RemoveFromSelectedProducts implements Action {
  readonly type = REMOVE_FROM_SELECTED_PRODUCTS;
  constructor(public payload: Product) {}
}

export class LoadAccessToken implements Action {
  readonly type = LOAD_ACCESS_TOKEN;
  constructor(public payload: any) {}
}

export class LoadAccessTokenSuccess implements Action {
  readonly type = LOAD_ACCESS_TOKEN_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadAccessTokenFail implements Action {
  readonly type = LOAD_ACCESS_TOKEN_FAIL;
  constructor(public payload: any) {}
}

export class FailProduct implements Action {
  readonly type = FAIL_PRODUCT;
  constructor(public productId: string, public errorCode?: string) {}
}

export class FailAllProducts implements Action {
  readonly type = FAIL_ALL_PRODUCTS;
  constructor(public errorCode?: string) {}
}

export class InitiateProductBegin implements Action {
  readonly type = INITIATE_PRODUCT_BEGIN;
  constructor(public productId: string) {}
}

export class InitiateProductEnd implements Action {
  readonly type = INITIATE_PRODUCT_END;
  constructor(public productId: string) {}
}

export class CheckUmbrellaEligibility implements Action {
  readonly type = CHECK_UMBRELLA_ELIGIBILITY;
  constructor() {}
}

export class DisqualifyUmbrella implements Action {
  readonly type = DISQUALIFY_UMBRELLA;
  constructor() {}
}

export type ProductsAction =
  | LoadProducts
  | LoadProductsFail
  | LoadProductsSuccess
  | LoadSelectedProducts
  | UpdateSelectedProducts
  | AddToSelectedProducts
  | RemoveFromSelectedProducts
  | LoadAccessToken
  | LoadAccessTokenSuccess
  | LoadAccessTokenFail
  | UpdateProduct
  | SetLeadsSentToCma
  | FailProduct
  | FailAllProducts
  | InitiateProductBegin
  | InitiateProductEnd
  | CheckUmbrellaEligibility
  | DisqualifyUmbrella
  | UpdateAllProducts;
