<form class="payment-form" [formGroup]="form" (ngSubmit)="onSubmit(form)">
  <div class="payment modal-header">
    <h1 class="modal-title heading-h4" id="modal-title">Billing plans</h1>
  </div>

  <div class="modal-body">
    <div class="container">
      <div class="col-12">
        <div class="row">
          <div class="col-6 payment-plan-border">
            <div
              class="payment-plans"
              name="paymentPlan"
              [ngClass]="{
                paymentPlanSelected: paymentPlan.value === 'Full Pay'
              }"
            >
              <fieldset
                [disabled]="isSmartMilesEnrolled"
                [ngClass]="{
                  disablePaymentMethod: isSmartMilesEnrolled
                }"
              >
                <legend>Pay-in-full</legend>

                <input
                  class="btn-radio radio-buttons"
                  type="radio"
                  name="paymentPlan"
                  formControlName="paymentPlan"
                  value="Full Pay"
                  id="full-pay-plan"
                  aria-label="Pay In Full"
                />
              </fieldset>
            </div>
            <div
              class="payment-plans"
              name="paymentPlan"
              [ngClass]="{
                paymentPlanSelected: paymentPlan.value === 'Recurring EFT'
              }"
            >
              <fieldset
                [disabled]="isRemoveEftOptionRequired"
                [ngClass]="{
                  disablePaymentMethod: isRemoveEftOptionRequired
                }"
              >
                <legend>Recurring EFT</legend>

                <input
                  class="btn-radio radio-buttons"
                  type="radio"
                  name="paymentPlan"
                  formControlName="paymentPlan"
                  (click)="updatePaymentTotal(true, 'EFT')"
                  value="Recurring EFT"
                  id="recurring-eft-radio-button"
                  aria-label="Recurring EFT"
                />
              </fieldset>
            </div>
            <div
              class="payment-plans"
              name="paymentPlan"
              [ngClass]="{
                paymentPlanSelected: paymentPlan.value === 'Recurring bank card'
              }"
            >
              <fieldset
                [disabled]="isAgent"
                [ngClass]="{ disablePaymentMethod: isAgent }"
              >
                <legend>Recurring bank card</legend>

                <input
                  class="btn-radio radio-buttons"
                  type="radio"
                  name="paymentPlan"
                  formControlName="paymentPlan"
                  (click)="updatePaymentTotal(true, 'BANKCARD')"
                  value="Recurring bank card"
                  id="recurring-card-radio-button"
                  aria-label="Recurring Bankcard"
                />
              </fieldset>
            </div>
            <div
              class="payment-plans"
              name="paymentPlan"
              [ngClass]="{
                paymentPlanSelected: paymentPlan.value === 'Monthly Direct Bill'
              }"
            >
              <fieldset
                [disabled]="isSmartMilesEnrolled"
                [ngClass]="{ disablePaymentMethod: isSmartMilesEnrolled }"
              >
                <legend>Monthly direct bill</legend>

                <input
                  class="btn-radio radio-buttons"
                  type="radio"
                  name="paymentPlan"
                  formControlName="paymentPlan"
                  (click)="updatePaymentTotal(false)"
                  value="Monthly Direct Bill"
                  id="direct-bill-radio-button"
                  aria-label="Monthly Direct Bill"
                />
              </fieldset>
            </div>
          </div>
          <div class="col-6 paymeny-plan-right-container">
            <div class="help-text-message-box">
              <strong
                *ngIf="
                  paymentPlan.value === 'Monthly Direct Bill' ||
                  paymentPlan.value === 'Full Pay'
                "
                >Pay With</strong
              >
              <div
                *ngIf="
                  (paymentPlan.value === 'Monthly Direct Bill' ||
                    paymentPlan.value === 'Full Pay') &&
                  !isRemoveEftOptionRequired
                "
              >
                Bank account or card
              </div>
              <strong *ngIf="paymentPlan.value === 'Recurring EFT'"
                >Pay With</strong
              >
              <div *ngIf="paymentPlan.value === 'Recurring EFT'">
                Bank account
              </div>
              <strong *ngIf="paymentPlan.value === 'Recurring bank card'"
                >Pay With</strong
              >
              <div
                *ngIf="
                  paymentPlan.value === 'Recurring bank card' ||
                  isRemoveEftOptionRequired
                "
              >
                Credit/Debit card
              </div>
              <strong *ngIf="paymentPlan.value === 'Monthly Direct Bill'"
                >Bill Delivery</strong
              >
              <div *ngIf="paymentPlan.value === 'Monthly Direct Bill'">
                Email
              </div>
              <strong
                *ngIf="
                  paymentPlan.value === 'Recurring EFT' ||
                  paymentPlan.value === 'Recurring bank card'
                "
                >Frequency</strong
              >
              <div
                *ngIf="
                  paymentPlan.value === 'Recurring EFT' ||
                  paymentPlan.value === 'Recurring bank card'
                "
              >
                Billed Monthly
              </div>
              <strong>Service Fee</strong>
              <div>May apply</div>
              <strong *ngIf="isSmartMilesHelpTextApplicable()"
                >SmartMiles&#174;</strong
              >
              <div
                *ngIf="
                  isSmartMilesHelpTextApplicable() &&
                  paymentPlan.value !== 'Full Pay'
                "
              >
                If enrolled, the monthly amount due may vary
              </div>
              <div
                *ngIf="
                  isSmartMilesHelpTextApplicable() &&
                  paymentPlan.value === 'Full Pay'
                "
              >
                Pay-in full is not available if vehicles are enrolled in
                Smartmiles
              </div>
            </div>
          </div>
        </div>

        <br />
      </div>

      <h2 class="heading-h5">Pay with</h2>
      <div class="col-12 pay-mode">
        <div class="pay-wrapper">
          <div
            class="col-xs-6 payment-method"
            [ngClass]="{
              paymentMethodSelected:
                paymentMethod.value === 'EFT' ||
                paymentMethod.value === 'BANKCARD'
            }"
          >
            <div class="row">
              <div class="col-md-6">
                <fieldset
                  [disabled]="isBankaccountDisabled()"
                  [ngClass]="{ disablePaymentMethod: isBankaccountDisabled() }"
                >
                  <legend>Bank account</legend>

                  <input
                    class="btn-radio radio-buttons"
                    type="radio"
                    name="paymentMethod"
                    formControlName="paymentMethod"
                    value="EFT"
                    (click)="updatePaymentTotal(null, 'EFT')"
                    id="bankaccount-radio-button"
                    aria-label="Bankaccount"
                  />
                </fieldset>
              </div>

              <div class="col-md-6">
                <fieldset
                  [disabled]="isBankcardDisabled()"
                  [ngClass]="{ disablePaymentMethod: isBankcardDisabled() }"
                >
                  <legend>Bank card</legend>

                  <input
                    class="btn-radio radio-buttons"
                    type="radio"
                    name="paymentMethod"
                    formControlName="paymentMethod"
                    value="BANKCARD"
                    (click)="updatePaymentTotal(null, 'BANKCARD')"
                    id="bankcard-radio-button"
                    aria-label="Bankcard"
                  />
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <div class="col-12">
        <div class="row">
          <div class="col-6 payment-checkbox" *ngIf="isFullPayRecurring()">
            <input
              type="checkbox"
              name="recurring"
              formControlName="recurring"
              id="recurring-checkbox"
              aria-label="Save the payment information for ongoing recurring payment"
            />
            <span
              >Save the payment information for ongoing recurring payment</span
            >
          </div>
          <div class="col-6 recurring-payment-message-wrapper">
            <div
              class="recurring-payment-message-box"
              *ngIf="isFullPayRecurring()"
            >
              <strong>Recurring Payments</strong>
              <div>
                We will use today's payment information for today's full payment
                and future automatic payments upon renewal
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>

  <div class="modal-footer tm-modal-footer-bg">
    <button
      type="button"
      class="btn btn-outline-secondary cancel-button"
      (click)="modal.dismiss()"
    >
      Cancel
    </button>

    <button class="btn btn-primary save-button" type="Submit" id="submitButton">
      Save
    </button>
  </div>
</form>
