import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mq-credit-notice',
  templateUrl: './credit-notice.component.html',
  styleUrls: ['./credit-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditNoticeComponent implements OnInit {
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}
