// Makes Organizing Code via Alphabetizing so much easier
// https://wordcounter.net/alphabetize
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
export const PC_INTERNAL_EXTERNAL_MAPPING = {
  pls01: 'pls01',
  pls02: 'pls02',
  pls03: 'pls03',
  pls04: 'pls04',
  pls05: 'pls05',
  pls06: 'pls06',
  pls07: 'pls07',
  pls08: 'pls08',
  pls09: 'pls09',
  pls10: 'pls10',
  pls11: 'pls11',
  pls12: 'pls12',
  pls13: 'pls13',
  pls14: 'pls14',
  pls15: 'pls15',
  pls16: 'pls16',
  pls17: 'pls17',
  pls18: 'pls18',
  pls19: 'pls19',
  pls30: 'pls30',
};

export const DSM_STAGE_TO_TEST_TARGET_ENV = {
  jan23a: 'pls15',
  jan23b: 'pls09',
  feb23a: 'pls02',
  feb23b: 'pls12',
  mar23a: 'pls04',
  mar23b: 'pls03',
  apr23a: 'pls14',
  apr23b: '',
  may23a: 'pls15',
  may23b: '',
  jun23a: 'pls02',
  jun23b: '',
  jul23a: 'pls04',
  jul23b: '',
  aug23a: 'pls14',
  aug23b: '',
  sep23a: 'pls15',
  sep23b: '',
  oct23a: 'pls12',
  oct23b: '',
  nov23a: 'pls03',
  nov22b: '',
  dec23a: 'pls14',
  dec22b: 'pls17',
  mar24a: 'pls03',
  mar24b: 'pls04',
  apr24a: 'pls14',
  apr24b: 'pls17',
};

export const NWX_ENVS = [
  'ProdLike',
  'Dev',
  'Dev2',
  'Dev3',
  'Test',
  'Test2',
  'Stage',
  'Stage2',
  'Stage3',
];

export const SSL_COVERAGE_STATE = {
  NY: 'NY',
};

export const NWX_ENVS_S3_MAPPINGS = {
  Dev: 'dev',
  Dev2: 'dev',
  Dev3: 'dev',
  Test: 'test',
  Test2: 'test',
  Stage: 'stage',
  Stage2: 'stage2',
  Stage3: 'stage3',
  ProdLike: 'prodlike',
};

export const URL_MAP = {
  'localhost:4200': 'localdesktop',
  'multiproducttest.awspubliccloud.nationwide.com': 'stage2',
  'multiproductstage.nationwide.com': 'stage',
  'multiproductstage2.nationwide.com': 'stage2',
  'multiproductstage3.nationwide.com': 'stage3',
  'multiproduct.nationwide.com': 'prod',
  'multiproduct.awspubliccloud.nationwide.com': 'prod',
};

export const PRIVATE_LABEL_DOMAIN_MAP = {
  'multiproducttest.awspubliccloud.nationwide.com/toyotaconnectedcar/multi-quote/getting-started':
    'test',
  'nwexpress.com/wildwest': 'prod',
  'nwexpress.com/affordableamericaninsurance': 'prod',
  'nwexpress.com/alliant': 'prod',
  'nwexpress.com/allinsurance4u': 'prod',
  'nwexpress.com/braunagency': 'prod',
  'nwexpress.com/choiceins': 'prod',
  'nwexpress.com/fireflyagency': 'prod',
  'nwexpress.com/flanaganinsuranceagency': 'prod',
  'nwexpress.com/fordinsure': 'prod',
  'nwexpress.com/ganleyinsurance': 'prod',
  'nwexpress.com/gerberinsuranceagency': 'prod',
  'nwexpress.com/insurance-loft': 'prod',
  'nwexpress.com/iscential': 'prod',
  'nwexpress.com/malhotrainsurance': 'prod',
  'nwexpress.com/quotewithamy': 'prod',
  'nwexpress.com/ruddyinsurancegroup': 'prod',
  'nwexpress.com/selectquote': 'prod',
  'nwexpress.com/thestetnishagency': 'prod',
  'nwexpress.com/toyotabrightdrive': 'prod',
  'nwexpress.com/toyotaconnectedcar': 'prod',
  'nwexpress.com/watkinsinsurancegroup': 'prod',
};

export const PRIVATE_LABEL_PATH_URL_MAP = {
  'nwexpress.com': 'prod',
  'www.nwexpress.com': 'prod',
  'multiproducttest.awspubliccloud.nationwide.com': 'stage2',
};

export const SocialLinks = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/nationwide',
    icon: 'facebook',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/nationwide',
    icon: 'twitter',
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/nationwide/',
    icon: 'instagram',
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/nationwide/',
    icon: 'linkedIn',
  },
  {
    name: 'YouTube',
    url: 'https://www.youtube.com/nationwide',
    icon: 'youtube',
  },
];

export const INTERNAL_LINKS = {
  autoQuoteHelpText: 'What you need for an auto quote',
  autoQuoteHelpURL:
    'https://www.nationwide.com/lc/resources/auto-insurance/articles/what-you-need-for-a-quote',
};

export const RIVIAN_THIRDPARTY_ID = 'rivian';

export const QuoteStatus = {
  DRAFT: 'Draft',
  QUOTED: 'Quoted',
  BINDING: 'Binding',
};

export const MORE_DETAILS_DISCLAIMER_TEXTS = {
  DEFAULT_PRIVATE_LABEL_TCPA:
    'I authorize the Nationwide Family of Companies and its agents to ' +
    'contact me for sales and marketing purposes using live operators, ' +
    'automated telephone dialing systems, or prerecorded messages. I am ' +
    'not required to consent as a condition of purchasing goods or ' +
    'services. I may revoke this consent at any time.',
  TCPA_DIRECT_AGENTS:
    'By providing your telephone number and clicking "Agree and Continue," ' +
    'you authorize the Nationwide Family of Companies, its agents, and its business ' +
    ' partners (including but not limited to QuoteLab, LLC) to ' +
    'contact you for sales and marketing purposes using live operators, ' +
    'automated telephone dialing systems, text messages, or prerecorded messages. You are ' +
    'not required to provide your telephone number as a condition of purchasing ' +
    'goods or services. You may revoke your consent at any time. If you ' +
    'choose not to provide your telephone number to Nationwide for sales and ' +
    'marketing purposes, please reach out to a Nationwide independent ' +
    'agent for service or call Nationwide Sales Solutions at ',
};

export const ALL_OTHER_TCPA =
  'for assistance in' +
  'completing your quote. You can find a local Nationwide agent by' +
  'visiting' +
  '<a' +
  'href="https://agency.nationwide.com/?utm_medium=cpc&utm_source=google&utm_campaign=ni&utm_content=brand:desktop:google:na:um:na:agent-locator&utm_term=nationwide%20agent%20locator&engine=GoogleBrandGeneric&gclid=EAIaIQobChMI7cfmgpDh6wIVgfGzCh29ewtHEAAYASAAEgL7RvD_BwE"' +
  'target="_blank"' +
  '>' +
  'Nationwide.com</a' +
  '>';

export const SAVE_QUOTE_DISCLAIMER = {
  MORE_DETAILS:
    ' We automatically save your quote when you click "Agree and Continue", ' +
    'which is available to view/complete on nationwide.com ' +
    'for the next 60 days.',
  QUOTE_COVERAGES:
    ' We automatically save your quote when you click ' +
    '"Continue", which is available to view/complete on nationwide.com ' +
    'for the next 60 days. Refer to the quote ID in the top right corner.',
};

export const ADD_THIRD_PARTY_DESIGNEE_DISCLAIMER_TEXT = {
  MORE_DETAILS:
    'If a third party needs be designated to receive bills and other notices ' +
    'related to the insurance coverage due to active military personnel or ' +
    'senior citizen on this policy, please contact an Agent at 1-855-550-0768.',
};

export const ADDITIONAL_PERILS_DISCLAIMER = {
  DIRECT: ` you acknowledge that your
  Property policy does not include flood, earthquake, mudslide, mudflow or
  landslide coverages. Windstorm or hail coverage may not be available depending
  upon the location of your property. If you have questions on how you may
  obtain coverages for these exposures, please call `,
  AGENT:
    'I acknowledge that I have explained to the customer that the Property policy does not ' +
    'include flood, earthquake, mudslide, mudflow or landslide coverages. Windstorm or hail ' +
    'coverage may not be available depending upon the location of the property.',
};

export const PolicyholderTypes = {
  POLICY_PRIMARY_NAMED_INSURED: 'PolicyPriNamedInsured',
  POLICY_SECONDARY_NAMED_INSURED: 'PolicySecNamedInsured',
  POLICY_ADDITIONAL_NAMED_INSURED: 'PolicyAddlNamedInsured',
};

export const MaritalStatusCodes = {
  M: 'Married',
  S: 'Single',
  P: 'Separated',
  D: 'Divorced',
  W: 'Widowed',
};

export const OfferingTypeCodes = {
  HO3: 'Basic (HO-3)',
  HE7: 'Home (HE-7)',
  HE7_20: 'Expanded (HE-7 w/ HE-20)',
  HE7_21: 'Extended (HE-7 w/ HE-21)',
};

export const DEFAULT_OFFERING_TYPE = 'HO3';

export const ChannelNames = {
  IA: 'IndependentAgent',
  NSS: 'NSS',
  EA: 'ExclusiveAgent',
  INTERNET: 'Internet',
  PRIVATE_LABEL: 'Private Label',
};

export const MaritalStatusToDsmCodes = {
  Married: 'M',
  Single: 'S',
  Separated: 'P',
  Divorced: 'D',
  Widowed: 'W',
};

export const GenderCodes = {
  M: 'Male',
  F: 'Female',
};

export const ProductTypes = {
  ADDRESS: '0',
  AUTO: '1',
  HOMEOWNERS: '2',
  RENTERS: '3',
  CONDO: '4',
  UMBRELLA: '5',
  TERMLIFE: '6',
  POWERSPORTS: '7',
  PET: '8',
};

export const ProductNames = {
  0: 'Address',
  1: 'Auto',
  2: 'Homeowners',
  3: 'Renters',
  4: 'Condo',
  5: 'Umbrella',
  6: 'Term Life',
  7: 'Powersports',
  8: 'Pet',
};

export const ProductGroups = {
  AUTO: 'auto',
  PROPERTY: 'property',
  ADDON: 'addon',
};

export const VehicleTypes = {
  AUTO: 'auto',
  MOTORCYCLE: 'Motorcycle',
  DUNEBUGGY: 'Dune Buggy / Lawn Tractor',
  UTILITYTRAILER: 'UtilityTrailer',
  LOWSPEEDVEHICLE: 'Low Speed Vehicle',
  SNOWMOBILE: 'Snowmobile',
};

export const CurrentCarrierHit = {
  FULL_HIT: 'Full Hit',
  NO_HIT: 'No Hit',
};

export const MARKET_VALUE_EXCEEDED_VALUE = 15010;

export const MARKET_VALUE_NOT_EXCEEDED_VALUE = 13010;

export const VehicleViewTypes = {
  Auto: 'auto',
  Motorcycle: 'Motorcycle / Trike',
  ATV: 'ATV / Utility Vehicle',
  Snowmobile: 'Snowmobile',
  GolfCart: 'Golf Cart',
  //       "Moped / Scooter",
  //       "Dirt Bike",
  //       "Dune Buggy",
  //       "Personal Transporter",
  //       "Utility Trailer"
};
export const VehicleTypesToDsm = {
  auto: 'auto',
  'Motorcycle / Trike': 'Motorcycle',
  'ATV / Utility Vehicle': 'OffRoad',
  Snowmobile: 'Snowmobile',
  'Golf Cart': 'OffRoad',
  'Low Speed Vehicle': 'Motorcycle',
  'Moped / Scooter': 'Motorcycle',
  'Dirt Bike': 'OffRoad',
  'Dune Buggy / Lawn Tractor': 'OffRoad',
  'Personal Transporter': 'OffRoad',
  'Utility Trailer': 'UtilityTrailer',
};

export const DsmVehicleTypesToVinVehicleTypes = {
  Motorcycle: 'CYCLE',
  OffRoad: 'OFFRD',
  Snowmobile: 'SNOWM',
  UtilityTrailer: 'UtilityTrailer',
};

export const SubTypeSymbols = {
  'Motorcycle / Trike': [
    110, 120, 130, 140, 150, 160, 210, 220, 630, 650, 550, 560,
  ],
  'ATV / Utility Vehicle': [710, 720],
  Snowmobile: [910, 920, 940],
  'Golf Cart': [820, 830],
  'Low Speed Vehicle': [835],
  'Moped / Scooter': [610, 620],
  'Dirt Bike': [810],
  'Dune Buggy / Lawn Tractor': [840, 880],
  'Personal Transporter': [640],
  'Utility Trailer': [999],
};

export const VehicleTypeSymbols = {
  Motorcycle: [210, 220, 610, 620, 630, 650, 550, 560, 835],
  OffRoad: [640, 710, 720, 810, 820, 830, 840, 880],
  Snowmobile: [910, 920, 940],
  UtilityTrailer: [999],
};

export const DistributionPartnerSearchConstants = {
  LEGACY_INDIVIDUAL_ROLE_NUMBER: 'Legacy Individual Role Number',
  LEGACY_PRODUCER_ORGANIZATION_ROLE_NUMBER:
    'Legacy Producer Organization Role Number',
  AMF_ADMIN_SYSTEM: 'AMF',
};

export const VehicleLending = {
  LIEN: 'lien',
  LESSOR: 'lessor',
};

export const ModifierNames = {
  SMARTRIDE: 'MobileApp',
  VEHICLE_SMARTRIDE: 'SmartRide',
  VEHICLE_SELFREPORTED: 'SelfReported',
  VEHICLE_ANTITHEFT: 'AntiTheft',
  VEHICLE_ANTILOCKBRAKES: 'AntiLockBrakes',
  VEHICLE_DAYTIMERUNNINGLAMPS: 'DaytimeRunningLamps',
  VEHICLE_PASSIVERESTRAINT: 'PassiveRestraint',
  HOME_CAR_INDICATOR_I: 'HomeCarIndicator_I',
  HOMEOWNER_PP: 'HomeownerPP',
  CONDO_PP: 'CondoPP',
  TENANTS_PP: 'TenantsPP',
  GOOD_STUDENT: 'GoodStudent',
  DRIVER_TRAINING: 'DriverTraining',
  EASY_PAY: 'EasyPay',
  ADVANCED_DRIVER_TRAINING: 'AdvancedDriverTraining',
  US_ARMED_FORCES: 'USArmedForces',
  GROUP_OCCUPATION: 'GroupOccupation',
  SMARTHOMEDEVICE: 'SmartDevice',
  ASSOCIATE_DISCOUNT: 'Associate',
  SAFETY_COURSE: 'SafetyCourse',
  RIDING_ASSOCIATION_DISCOUNT: 'AssociationMember',
  POWERSPORTS: 'Powersports',
  AUXILLARY_LIGHTING_SYSTEM: 'AuxillaryLightingSystem',
  MEMBERSHIP: 'Membership',
  BILLINGPAYMENTMETHOD: 'BillingPaymentMethod',
  PAIDINFULL: 'PaidInFull',
};

export const TelematicsProgram = {
  SMART_MILES: 'SmartMiles',
  SMARTRIDE_DEVICE: 'SmartRide',
  SELF_REPORTING: 'SelfReported',
  SMARTRIDE_MOBILE: 'MobileApp',
  SELF_REPORTED: 'SelfReported',
  CONNECTED_CAR: 'ConnectedCar',
  SMARTRIDE_INSTANT: 'SmartRideInstant',
};

export const TelematicsEnrollmentStatus = {
  ENROLLED: 'Enrolled',
  NOT_ENROLLED: 'NotEnrolled',
  PRE_QUALIFIED: 'VerifiedScore',
};

export const CONNECTED_CAR_TERMS_AND_CONDITIONSS_URL =
  'https://www.nationwide.com/cps/cps-pdf/smartride-terms.pdf';

export const AGENT_SOURCE = 'agent_web_page';

export const VehiclePrimaryUseCodeStrings = {
  VEHICLE_CODE_WORK: 'Work',
  VEHICLE_CODE_PLEASURE: 'Pleasure',
  VEHICLE_CODE_FARM: 'Farm',
  VEHICLE_CODE_BUSINESS: 'IndividualBusiness',
};

export const VehiclePrimaryUseStrings = {
  VEHICLE_USE_WORK: 'Work/School (daily commute, errands)',
  VEHICLE_USE_PLEASURE: 'Pleasure (recreational driving)',
  VEHICLE_USE_FARM: 'Farm (used strictly on a farm)',
  VEHICLE_USE_BUSINESS: 'Business (deliveries, taxi, sales calls)',
  VEHICLE_USE_RACING: 'Racing or Speed contest',
};

export const VehiclePrimaryUseCodes = {
  'Work/School (daily commute, errands)': 'Work',
  'Pleasure (recreational driving)': 'Pleasure',
  'Farm (used strictly on a farm)': 'Farm',
  'Business (deliveries, taxi, sales calls)': 'IndividualBusiness',
  'Racing or Speed contest': 'RaceSpeed',
};

export const DiscountLabels = {
  VEHICLE_SMARTRIDE: 'SmartRide',
  VEHICLE_SELFREPORTED: 'Self-Reported Mileage',
  VEHICLE_ANTITHEFT: 'Anti Theft',
  VEHICLE_ANTILOCKBRAKES: 'Anti-Lock Brakes',
  VEHICLE_DAYTIMERUNNINGLAMPS: 'Daytime Running Lamps',
  VEHICLE_PASSIVERESTRAINT: 'Passive Restraint',
  VEHICLE_SMARTMILES: 'SmartMiles',
};

export const ModifierValues = {
  NONE: 'None',
  NATIONWIDE_AUTO_POLICY: 'NationwideAutoPolicy',
  NW_FARM_AUTO: 'NWFarmAuto',
  NW_AUTO_AND_NW_FARM_AUTO: 'NWAutoAndNWFarmAuto',
  FULLPAY: 'FullPay',
  RECURRINGEFT: 'RecurringEFT',
  DIRECTBILL: 'DirectBill',
  RECURRINGBANKCARD: 'RecurringBankCard',
};

export const BillingPaymentPlan = {
  MONTHLY: 'Monthly',
  FULL_PAY: 'Full Pay',
};

export const BillingPaymentMethod = {
  EFT: 'EFT',
  BANKCARD: 'BANKCARD',
};

export const PaymentPlans = {
  PAY_IN_FULL_WITH_BANKACCOUNT: 'Pay In Full with bank account',
  PAY_IN_FULL_WITH_BANKCARD: 'Pay In Full with bank card',
  RECURRING_EFT: 'Recurring EFT',
  RECURRING_BANKCARD: 'Recurring bank card',
  MONTHLY_DIRECT_BILL: 'Monthly Direct Bill',
};

export const eligiblePayplansAndPayMethods = {
  MONTHLY_EFT: 'MONTHLY EFT',
  MONTHLY_DIRECT: 'MONTHLY DIRECT',
  MONTHLY_RECURRING_BANKCARD: 'MONTHLY RECURRING BANKCARD',
  COMBINED_MONTHLY: 'COMBINED MONTHLY',
  FULL_PAYMENT_PLAN: 'FULL PAYMENT PLAN',
};

// Key relates to ProductTypes values
export const BillingProductTypes = {
  1: 'Auto',
  2: 'Fire',
  3: 'Fire',
  4: 'Auto',
};

export const CoverageProductTypes = {
  Auto: 'Auto',
  Condo: 'Condo',
  Homeowners: 'Homeowners',
  Renters: 'Renters',
};

export const TermType = {
  HalfYear: 6,
  FullYear: 12,
  Annual: 12,
};

export const DriverRelationToPNI = {
  PNI: 'PrimaryNamedInsured',
  SPOUSE: 'Spouse',
  OTHER: 'Other',
  CHILD: 'Child',
  CO_OWNER: 'Co-Owner',
  EMPLOYEE: 'Employee',
  OTHER_RELATIVE: 'Other - Relative',
  OTHER_NON_RELATIVE: 'Other - Non-Relative',
};

export const ProgressTrackerFlowNames = {
  AUTO: 'auto',
  AUTO_AND_HOME: 'autoAndHome',
};

export const ProgressTrackerLabels = {
  AUTO: ['Your info', 'Vehicles', 'Drivers', 'Get quote'],
  AUTO_AND_HOME: ['You', 'Property', 'Vehicles', 'Drivers', 'Quote'],
};

export const ProgressTrackerPercentages = {
  AUTO: {
    moreDetails: 2,
    vehicles: 33.5,
    drivers: 64.5,
    coverages: 100,
  },
  AUTO_AND_HOME: {
    moreDetails: 2,
    homeDetails: 25,
    vehicles: 50,
    drivers: 74,
    coverages: 100,
  },
};

export const DOLLAR = '$';
export const DEFAULT_ID = '0';
export const DEFAULT_SPID = 'Default';
export const APPLICATION_NAME = 'Multiproduct Quote and Bind';
export const PRIVATE_LABEL_APPLICATION_NAME = 'NWX Private Label';
export const DEFAULT_FACEAMOUNT = '100000';

export const ClickToChatParameters = {
  LOB: 'Life',
  APP_ID: 'life-quote',
  CHANNEL: 'NationwideInternet',
};

export const DSM_ANNUAL_MILES_LIMIT = 60000;
export const ANNUAL_MILES_MIN = 3000;
export const WEEKS_PER_YEAR = 52;
export const MIN_MILES_PERWEEK = 8;
export const MIN_MILES_PERYEAR = 365;

export const MOBILE_APPLICATION_NAME = 'NW Express Mobile';

export const MobilePartnerIDs = {
  IOS: 'NWX_iOS_Mobile',
  ANDROID: 'NWX_Android_Mobile',
};

export const BindPageTitles = {
  ADDITIONAL_INFORMATION: 'Additional Information',
  LENDERS: 'Lenders',
  PRODUCT_DETAILS: 'Product Details',
  RENTERS: 'Renters',
  PROPERTY_INFO: 'Property Information',
};

export const ProducerTypes = {
  IA: 'IndependentAgent',
  EA: 'ExclusiveAgent',
  NSS: 'NSS',
  DIRECT: 'Internet',
};

export const DriversOccupationCodes = {
  AllOther: 'All Other',
  ScientistOrEngineer: 'Scientist or Engineer',
  Educator: 'Educator',
  PhysicianRegisteredNurse: 'California Licensed Physician or Registered Nurse',
  LicensedPharmacist: 'California Licensed Pharmacist',
  Veterinarian: 'Veterinarian',
  Dentist: 'Dentist',
  Accountant: 'Accountant',
  Firefighter: 'Firefighter',
  Paramedic: 'Paramedic',
  Pilot: 'Pilot',
  LawEnforcementOfficer: 'Law Enforcement Officer',
  BusinessOwner: 'Business Owner',
  NationwideEmployeeAgent:
    'Current or Retired Nationwide (or subsidiary) Employee or Agent',
  AccreditedCollegeAlumni: 'Accredited College Alumni',
};

// TODO Refactor constants(below) between lines with this comment by moving them into term-life-constants.ts

export const TERM_LIFE_HEIGHT_FEET_LENGTH = 1;
export const TERM_LIFE_HEIGHT_FEET_MAX = 9;
export const TERM_LIFE_HEIGHT_FEET_MIN = 1;
export const TERM_LIFE_HEIGHT_INCHES_LENGTH = 2;
export const TERM_LIFE_HEIGHT_INCHES_MAX = 11;
export const TERM_LIFE_HEIGHT_INCHES_MIN = 0;
export const TERM_LIFE_WEIGHT_LENGTH = 3;
export const TERM_LIFE_WEIGHT_MAX = 999;
export const TERM_LIFE_WEIGHT_MIN = 10;
export const TERM_LIFE_DEFAULT_FACE = 100000;

export const TermLifeHealthCodes = {
  Excellent: 'Excellent',
  Good: 'Good',
  Average: 'Average',
  Fair: 'Fair',
  Poor: 'Poor',
};

export const TermLifeNicotineCodes = {
  Never: 'Never used',
  Year: 'Used within the past year',
  Five: 'Used over one year ago but less than 5 years ago',
  Fivepast: 'Used over 5 years ago',
};

export const TermLifeCvgAmtCodes = {
  100000: '$100,000',
  150000: '$150,000',
  200000: '$200,000',
  250000: '$250,000',
  300000: '$300,000',
  350000: '$350,000',
  400000: '$400,000',
  450000: '$450,000',
  500000: '$500,000',
  550000: '$550,000',
  600000: '$650,000',
  650000: '$600,000',
  700000: '$700,000',
  750000: '$750,000',
  800000: '$800,000',
  850000: '$850,000',
  900000: '$900,000',
  950000: '$950,000',
  1000000: '$1,000,000',
};

export const TermLifeYearTerms = {
  'Nationwide 10-year Term GLT': '10 years',
  'Nationwide 15-year Term GLT': '15 years',
  'Nationwide 20-year Term GLT': '20 years',
  'Nationwide 30-year Term GLT': '30 years',
};

export const UrboNumber = {
  'loading-page': 'NPR-1295AO.1 (11/19), NFW-9599AO',
  '/admin/quote-start': 'NPR-1295AO.1 (11/19), NFW-9599AO',
  '/multi-quote/getting-started': 'NPR-1295AO.1 (11/19), NFW-9599AO',
  '/multi-quote/more-details': 'NPR-1295AO.1 (11/19)',
  '/multi-quote/property-info': 'NPR-1295AO.1 (11/19)',
  '/multi-quote/vehicles': 'NPR-1295AO.1 (11/19)',
  '/multi-quote/people': 'NPR-1295AO.1 (11/19), NFW-9600AO',
  '/multi-quote/coverages': 'NPR-1295AO.1 (11/19), NFW-9258AO',
  '/multi-bind/additional-information': 'NPR-1295AO.1 (11/19), NFW-9687AO',
  '/multi-bind/product-details': 'NPR-1295AO.1 (11/19), NFW-9687AO',
  '/multi-bind/coverages': '',
  '/multi-bind/policy-information': '',
  '/multi-payment/billing': '',
  '/multi-payment/summary': '',
};

export const TERM_LIFE_DEFAULT_TEN_YEAR = 'Nationwide 10-year Term GLT';

export const ANNUAL_MILES_DEFAULT_CA = '6000';

// Refactor constants (above) between lines with this comment by moving them into term-life-constants.ts

export const medicalBenefit = {
  MDCL: 'Medical Expense Benefits',
};

export const GoodDriverDisplayByKey = {
  Excellent: '',
  Prettygood: '070',
  Needswork: '140',
};

export const Infractiontype = {
  Excellent: 'Excellent',
  Prettygood: 'Violation',
  Needswork: 'Accident',
};

export const GoodDriverDescValue = {
  Excellent: 'Excellent',
  '070': 'Prettygood',
  140: 'Needswork',
};

export const RatingTypes = {
  QUOTE: 'quote',
  BIND: 'bind',
  ISSUE: 'issue',
};

export const PageAlertIDs = {
  'getting-started': 'GETTING_STARTED',
  'multi-payment/summary': 'BILLING_SUMMARY',
  'multi-payment/billing': 'BILLING_FORM',
  'additional-information': 'ADDITIONAL_INFORMATION',
  lenders: 'LENDERS',
  'policy-information': 'POLICY_INFORMATION',
  'product-details': 'PRODUCT_DETAILS',
  renters: 'RENTERS',
  'multi-policy/account': 'POLICY_FORM',
  'multi-policy/summary': 'POLICY_SUMMARY',
  people: 'ADDITIONAL_DRIVERS',
  'match-driver-vehicle': 'MATCH_DRIVER_VEHICLE',
  'more-details': 'MORE_DETAILS',
  'property-info': 'PROPERTY_INFO',
  vehicles: 'VEHICLES',
  retrieve: 'RETRIEVE',
  'multi-quote/coverages': 'COVERAGES_QUOTE',
  'multi-bind/coverages': 'COVERAGES_BIND',
  'agency-route': 'AGENCY',
  'retrieve/compRater': 'COMP_RATER_LANDING',
  maintenance: 'MAINTENANCE',
  nonStandardBridge: 'NON_STANDARD_BRIDGE',
  search: 'SEARCH',
  softfall: 'SOFTFALL',
};

export const VERISK_THIRDPARTY_IDs = [
  'hondaverisk',
  'hyundaiverisk',
  'VeriskIoT',
  'toyotacc',
  'everquote',
];

export const PagesSupportingMultipleAlerts = ['COMP_RATER_LANDING'];

export const PAGE_ALERT_DISMISS_EXPIRY = 14; // number of days to store cookie

export const DEFAULT_YEARS_WITH_PRIOR_CARRIER = '0';

export const DESKTOP_VIEW = 768;

export const BODY_TYPES = {
  0: 'VAN',
  1: '2 DOOR',
  2: '4 DOOR',
  5: 'CONVERTIBLE',
  6: 'WAGON',
  12: '4 DOOR 100 IN',
  16: 'WAGON 100 IN',
  20: '20',
  21: 'VAN 100 IN',
  23: 'SMALL P/U',
  24: 'LARGE P/U',
  '-124': 'Large P/U',
  '-123': 'Small P/U',
  '-121': 'VAN 100IN',
  '-312': 'Four Door 100in',
  '-116': 'WAGON 100IN',
  '-112': '4 DOOR 100IN',
  '-100': 'Unavailable',
  '-224': 'Large pickup',
  '-223': 'small Pickup',
  '-221': 'Van 100 in',
  '-216': 'Wagon 100in',
  '-212': '4 Door 100in',
  '-16': 'Wagon',
  '-15': 'Convertible',
  '-12': '4 Door',
  '-11': '2 Door',
  '-10': 'Van',
};

export const CoverageErrorCodes = {
  // Includes errors beyond just OH, missing covg codes marked with TODO.  Fill in as we onboard those states
  ALN001: ['BI', 'UMBI'],
  APV002: ['BI', 'PD'],
  APV003: ['BI', 'UMBI'],
  APV004: ['UIMBI', 'BI'],
  APV005: ['COMP', 'COLL', 'PD', 'BI'],
  APV006: ['COMP', 'COLL'],
  APV051: ['UMPD', 'PD'],
  APV054: ['MP'],
  APV055: ['UIMBI'],
  APV056: ['UMBI'],
  APV057: ['UMPD'],
  APV068: ['NCRPCOLL'],
  APV069: ['GAPCOLL'],
  APV070: ['UMBI', 'UIMBI'],
  APV071: ['COLL', 'UMPD'],
  APV072: ['UMBI', 'UIMBI'],
  APV073: ['UMPD'],
  APV300: ['PIP', 'MP'],
  APV301: ['PIP'],
  APV302: ['BI', 'UIMBI'],
  APV303: ['LOI'],
  APV304: ['UMPD', 'BI'],
  APV305: ['PIP', 'BI'],
  APV306: ['UIMPD', 'PD'],
  ALN313: ['IDFR'],
  APV400: ['UMBI', 'BI'],
  ALN401: ['BI', 'OBEL'],
  ALN402: ['APIP', 'BI'],
  ALN403: ['BI'], // TODO: What is the coverage code for supplemental spousal liability?
  APV404: ['UMBI', 'BI'],
  APV405: ['PIP', 'BI'],
  APV406: ['COMP', 'COLL'],
  APV408: ['UMPD', 'UMBI'],
  ALN501: ['BI', 'PD'],
  ALN503: ['BI'], // TODO: What is the coverage code for medical benefits?
  ALN504: ['UMPD', 'BI'],
  APV504: ['BI'], // TODO: What is the coverage code for work loss benefits?
  ALN505: ['UIMPD', 'BI'],
  APV505: ['BI'], // TODO: What is the coverage code for funeral benefits?
  ALN506: ['UMBI', 'BI'],
  APV506: ['MP', 'BI'],
  APV508: ['UIMPD', 'UMPD'],
  APV509: ['UIMBI', 'UMBI'],
  APV510: ['UMBI', 'BI'],
  ALN511: ['BI'],
  APV511: ['UMBI', 'BI'],
  ALN512: ['PD'],
  APV513: ['UIMPD', 'UIMBI'],
  ALN515: ['UIMPD', 'UIMBI'],
  ALN516: ['UIMPD', 'UIMBI'],
};

export const PERSONAL_PROPERTY_MAX = 199999;

export const DWELLING_MAX = PERSONAL_PROPERTY_MAX;

// eslint-disable-next-line no-magic-numbers
// export const DWELLING_MIN = PERSONAL_PROPERTY_MAX * 0.1;

export const ValidCoverageWarningCodes = ['PAC0100', 'PAC0080'];

export const CoveragesThatCannotDecline = ['BI', 'PD'];

export const OtherCoverages = ['RSA', 'AF', 'MVF', 'VD', 'TLDW', 'IDFR'];

export const OrderedCoverages = [
  'BI',
  'PD',
  'UMBI',
  'UIMBI',
  'MP',
  'UMPD',
  'UIMPD',
  'COMP',
  'COLL',
  'WCD',
  'OEM',
  'RENTRE',
  'RSA',
  'AF',
  'MVF',
  'VD',
  'TLDW',
  'GAPCOLL',
  'NCRPCOLL',
  'IDFR',
  'AD',
  'WorkersCompensation',
];

export const CoverageGroupType = {
  Policy: 'Policy',
  Vehicle: 'Vehicle',
  Other: 'Other',
};

export const CoverageGroupOrder = [
  CoverageGroupType.Policy,
  CoverageGroupType.Vehicle,
  CoverageGroupType.Other,
];

export const DiscountDescriptions = {
  Smartride: 'SmartRide Discount',
  RecurringEFTDiscount: 'Recurring EFT Discount',
  PaidInFullDiscount: 'Paid In Full Discount',
  AuxillaryLightingSystem: 'Auxiliary Lighting System Discount',
  EasyPayDiscount: 'Easy Pay Discount',
};

export const CoverageDisplayGrouping = {
  // Homeowners
  PropertyLiability: 'Property / Liability',
  Deductibles: 'Deductibles',
  AdditionalProtection: 'Additional Protection to Purchase',
  SpecialLimitsOfLiability: 'Special Limits of Liability',
  // Auto
  Policy: 'Policy Coverages',
  Vehicle: 'Vehicle Coverages',
  Driver: 'Driver Coverages',
  AdditionalPolicy: 'Additional Policy Options',
  OtherCoverages: 'Other Coverages',
};

export const CoverageCodes = {
  BasicCoverageDeductible: 'BasicCoverageDeductible',
  BasicCoverageLimit: 'BasicCoverageLimit',
  BuildingAdditionsAlterationsLimit: 'BuildingAdditionsAlterationsLimit',
  AllPerilDeductible: 'AllPerilDeductible',
  WindHailPerilDeductible: 'WindHailPerilDeductible',
  HurricanePerilDeductible: 'HurricanePerilDeductible',
  LossSettlementProvision: 'LossSettlementProvision',
  IncreasedLossOfUseALS: 'IncreasedLossOfUseALS',
  UnscheduledPersonalPropertyDeductible:
    'UnscheduledPersonalPropertyDeductible',
  MineSubsidenceLimit: 'MineSubsidenceLimit',
  UMPDDeductible: 'UMPDDeductible',
  UMPDOption: 'UMPDOption',
  UIMPDPackageDeductible: 'UIMPDPackageDeductible',
  UIMPDLimit: 'UIMPDLimit',
  PersonalInjuryLimit: 'PersonalInjuryLimit',
  LimitedPropertyDamageLimit: 'LimitedPropertyDamageLimit',
  LPDLimit: 'LPDLimit',
  PIPDeductible: 'PIPDeductible',
  PIPLimit: 'PIPLimit',
  PEDPIPLimit: 'PEDPIPLimit',
  PIPGuestOnly: 'PIPGuestOnly',
  PIPOption: 'PIPOption',
  PropertyProtectionInsurance: 'PropertyProtectionInsurance',
  PPILimit: 'PPILimit',
  ServiceLineDeductible: 'ServiceLineDeductible',
  TORTOption: 'TORTOption',
  BioDeteriorationDamageCoverageLimit: 'BioDeteriorationDamageCoverageLimit',
  MoldLimit: 'MoldLimit',
  UnscheduledMoneyLimit: 'UnscheduledMoneyLimit',
  UnscheduledSecuritiesLimit: 'UnscheduledSecuritiesLimit',
  UnscheduledJewelryWatchesFursLimit: 'UnscheduledJewelryWatchesFursLimit',
  UnscheduledSilverwareLimit: 'UnscheduledSilverwareLimit',
  BetterRoofReplacement: 'BetterRoofReplacement',
  LimitedRoofLoss: 'LimitedRoofLoss',
  BISuppSpousalLiability: 'BISuppSpousalLiability',
};

export const CoverageIds = {
  IncreasedLossOfUse: 'IncreasedLossOfUse',
  UnscheduledPersonalProperty: 'UnscheduledPersonalProperty',
  SectionIDeductibles: 'SectionIDeductibles',
  ProtectionBoost: 'ProtectionBoost',
  PIP: 'PIP',
  GSTBI: 'GSTBI',
  PEDPIP: 'PEDPIP',
  MineSubsidence: 'MineSubsidence',
  BasicCoverage: 'BasicCoverage',
  BuildingAdditionsAlterations: 'BuildingAdditionsAlterations',
  BioDeteriorationDamageCoverage: 'BioDeteriorationDamageCoverage',
  BodilyInjury: 'BI',
  PropertyDamage: 'PD',
  UninsuredMotoristsBodilyInjury: 'UMBI',
  UnderinsuredMotoristBodilyInjury: 'UIMBI',
  PersonalLiability: 'PersonalLiability',
  LimitedPropertyDamageLimit: 'LPD',
  UMPD: 'UMPD',
  UnderinsuredMotoristPropertyDamage: 'UIMPD',
  UMBI: 'UMBI',
  UMUIMBI: 'UMUIMBI',
  ServiceLine: 'ServiceLine',
  TORT: 'TORT',
  MVF: 'MVF',
  AF: 'AF',
  Mold: 'Mold',
  TDI: 'TDI',
  UnscheduledMoney: 'UnscheduledMoney',
  UnscheduledSecurities: 'UnscheduledSecurities',
  UnscheduledJewelryWatchesFurs: 'UnscheduledJewelryWatchesFurs',
  UnscheduledSilverware: 'UnscheduledSilverware',
  WaterBackupLimited: 'WaterBackupLimited',
  WaterBackupFull: 'WaterBackupFull',
  MDCL: 'MDCL',
  LOI: 'LOI',
  RSA: 'RSA',
  MP: 'MP',
  PropertyProtectionInsurance: 'PPI',
  IncreasedOtherStructures: 'IncreasedOtherStructures',
};

/**
 * define metadata for coverages. key is the coverageId
 */
export const CoveragesMetadata: {
  [key: string]: {
    displayGrouping: string;
    coverageSequence: number;
    spreadable?: boolean;
  };
} = {
  //
  // START Homeowners metadata
  //
  // property liability
  BasicCoverage: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 1,
  },
  DwellingReplacementCost: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 2,
  },
  DwellingReplacementCost200: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 2,
  },
  GuaranteedReplacementCost: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 2,
  },
  IncreasedOtherStructures: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 3,
  },
  InflationProtectionCoverage: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 5,
  },
  BrandNewBelongings: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 6,
  },
  BuildingAdditionsAlterations: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 7,
  },
  UnscheduledPersonalProperty: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 8,
  },
  IncreasedLossOfUse: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 9,
  },
  PersonalLiability: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 10,
  },
  PersonalInjury: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 11,
  },
  MedicalPayments: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 12,
  },
  MineSubsidence: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 13,
  },
  SectionIDeductibles: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 14,
  },
  WindHailPerilDeductible: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 15,
  },
  WorkersCompensation: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 16,
  },
  WaterDamage: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 17,
  },
  Sinkhole: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 20,
  },
  Foundation: {
    displayGrouping: CoverageDisplayGrouping.PropertyLiability,
    coverageSequence: 21,
  },
  /*
  Per Nick, unless state required, these can be excluded from display
  FarmLiability: {
    displayGrouping: CoverageDisplayGrouping.,
    coverageSequence: 50,
  },
  SpecialCoveragePersonalProperty: {
    displayGrouping: CoverageDisplayGrouping.,
    coverageSequence: 55,
  },
  BuildingAdditionsAlterations: {
    displayGrouping: CoverageDisplayGrouping.,
    coverageSequence: 60,
  },
  ResidenceRentalTheft: {
    displayGrouping: CoverageDisplayGrouping.,
    coverageSequence: 65,
  },
  TreesShrubsOtherMaxPercentCovC: {
    displayGrouping: CoverageDisplayGrouping.,
    coverageSequence: 70,
  },
  ActualCashValueRoof: {
    displayGrouping: CoverageDisplayGrouping.,
    coverageSequence: 75,
  },
  SinkholeOtherStructures: {
    displayGrouping: CoverageDisplayGrouping.,
    coverageSequence: 80,
  },
  SinkholePersonalProperty: {
    displayGrouping: CoverageDisplayGrouping.,
    coverageSequence: 85,
  },
  SinkholeStructuresRented: {
    displayGrouping: CoverageDisplayGrouping.,
    coverageSequence: 90,
  },
  */
  //
  // additional protection to purchase
  UnscheduledTools: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 15,
  },
  LossAssessment: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 16,
  },
  IdentityTheft: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 17,
  },
  WaterBackupLimited: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 18,
  },
  BuildingOrdinanceOrLaw: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 19,
  },
  LimitedRoofLoss: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 20,
  },
  BetterRoofReplacement: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 21,
  },
  EquipmentBreakdown: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 22,
  },
  ServiceLine: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 24,
  },
  ProtectionBoost: {
    displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
    coverageSequence: 30,
  },
  //
  // special limits
  // Intentionally omitted: PersonalPropOtherRes [HB-1827]
  UnscheduledMoney: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 24,
  },
  UnscheduledSecurities: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 25,
  },
  WatercraftCoverage: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 26,
  },
  Trailers: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 27,
  },
  JewelryWatches: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 28,
  },
  JewelryInVault: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 29,
  },
  Firearms: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 30,
  },
  UnscheduledSilverware: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 31,
  },
  BusinessPropOn: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 32,
  },
  BusinessPropOff: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 33,
  },
  UnscheduledCamera: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 34,
  },
  FireDepartmentServiceCharge: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 35,
  },
  CreditCard: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 36,
  },
  LandlordFurnishings: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 37,
  },
  RefrigeratedPersonalProperty: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 38,
  },
  BioDeteriorationDamageCoverage: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 39,
  },
  Mold: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 39,
  },
  OutboardMotor: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 40,
  },
  DebrisRemovalTree: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 41,
  },
  TreesShrubsOtherMaxPercentCovA: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 42,
  },
  PortableEquip: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 43,
  },
  UnscheduledJewelryWatchesFurs: {
    displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
    coverageSequence: 43,
  },
  //
  // START Auto & Powersports metadata
  //
  BI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 1,
    spreadable: true,
  },
  PD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 2,
    spreadable: true,
  },
  UMBI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 3,
    spreadable: true,
  },
  UIMBI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 4,
    spreadable: true,
  },
  UMUIMBI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 18,
  },
  RENTAL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 7,
  },
  TL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 8,
    spreadable: true,
  },
  MP: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 15,
    spreadable: true,
  },
  DI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 16,
  },
  TDI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 17,
  },
  UMPD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 19,
    spreadable: true,
  },
  UIMPD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 25,
    spreadable: true,
  },
  MDCL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 70,
    spreadable: true,
  },
  WL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 71,
    spreadable: true,
  },
  FUNRL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 72,
    spreadable: true,
  },
  LOI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 75,
    spreadable: true,
  },
  PIP: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 79,
    spreadable: true,
  },
  PPI: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 80,
    spreadable: true,
  },
  LPD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 81,
    spreadable: true,
  },
  PEDPIP: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 82,
    spreadable: true,
  },
  APIP: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 85,
    spreadable: true,
  },
  OBEL: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 90,
    spreadable: true,
  },
  AD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 91,
    spreadable: true,
  },
  TORT: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 95,
    spreadable: true,
  },
  ACDD: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 64,
  },
  AM: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 65,
  },
  AAC: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 66,
  },
  COMP: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 9,
  },
  COLL: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 10,
  },
  WCD: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 11,
  },
  OEM: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 12,
  },
  RENTRE: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 13,
  },
  GAPCOLL: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 14,
  },
  NCRPCOLL: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 15,
  },
  EEE: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 25,
    spreadable: true,
  },
  TREX: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 26,
    spreadable: true,
  },
  WorkLossBenefitExclusion: {
    displayGrouping: CoverageDisplayGrouping.Driver,
    coverageSequence: 35,
  },
  EXMDCL: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 40,
    spreadable: true,
  },
  COMBL: {
    displayGrouping: CoverageDisplayGrouping.Vehicle,
    coverageSequence: 50,
    spreadable: true,
  },
  AF: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 51,
  },
  MVF: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 52,
  },
  VD: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 53,
  },
  TLDW: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 54,
  },
  RSA: {
    displayGrouping: CoverageDisplayGrouping.AdditionalPolicy,
    coverageSequence: 59,
    spreadable: true,
  },
  IDFR: {
    displayGrouping: CoverageDisplayGrouping.OtherCoverages,
    coverageSequence: 60,
  },

  // Umbrella
  PULLiability: {
    displayGrouping: CoverageDisplayGrouping.Policy,
    coverageSequence: 1,
  },
};

export const UmpdCoverageCashOption = {
  Code: '5',
  Value: 'Actual Cash Value',
};
export const UIMPDPackageDeductible = {
  code: 'UIMPDPackageDeductible',
  value: '100/300',
};

export const HomeownersKnockoutQuestions = {
  AllStates: [
    'The home is in foreclosure, rented as a vacation or short-term rental, or deeded to someone other than the primary resident.',
    'The home is under construction, being renovated or vacant, or has been converted from non-residential to residential.',
    'The home is occupied by someone other than the primary homeowner.',
    'The home has a supplemental heat source other than a builder-installed prefabricated fireplace, such as a woodstove.',
    'The property is accessible to fire equipment only part of the year.',
    'The home has an unfenced pool or trampoline without safety netting.',
    "The home's heat is controlled manually, rather than using a thermostat.",
  ],
  FL: [
    'The home is currently under the course of construction, extensive remodeling, or rehabilitation.',
    'The Dwelling has been converted from non-residential to residential.',
    'The owner has been told that this property has experienced damage or loss resulting from sinkhole activity or collapse or' +
      ' been the subject of any ground movement investigation.',
    'The owner has been told that this property does or has ever had a sinkhole activity or any other movement, whether confirmed' +
      ' or not, regardless if it was tested or not.',
    'The applicant has ever reported any potential sinkhole, settlement or cracking damage or loss to this property or has' +
      ' knowledge that it exists.',
    'The applicant has any knowledge that any prior owner or renter has reported any sinkhole, settlement or cracking' +
      ' damage regarding this property.',
  ],
  MD: ['A member of the home has been convicted of Arson in the past 5 years.'],
};

export const CondoKnockoutQuestions = {
  AllStates: [
    'The condo is in foreclosure, rented as a vacation or short-term rental, or deeded to someone other than the primary resident.',
    'The condo is under construction, being renovated or vacant, or has been converted from non-residential to residential.',
    'The condo is occupied by someone other than the primary condo owner.',
    'The condo has a supplemental heat source other than a builder-installed prefabricated fireplace, such as a woodstove.',
    'The condo is accessible only part of the year.',
    'The condo has an unfenced pool or trampoline without safety netting.',
    "The condo's heat is controlled manually, rather than using a thermostat.",
  ],
  FL: [
    'The condo is currently under the course of construction, extensive remodeling, or rehabilitation.',
    'The Dwelling has been converted from non-residential to residential.',
    'The owner has been told that this property has experienced damage or loss resulting from sinkhole activity or collapse or' +
      ' been the subject of any ground movement investigation.',
    'The owner has been told that this property does or has ever had a sinkhole activity or any other movement, whether confirmed' +
      ' or not, regardless if it was tested or not.',
    'The applicant has ever reported any potential sinkhole, settlement or cracking damage or loss to this property or has' +
      ' knowledge that it exists.',
    'The applicant has any knowledge that any prior owner or renter has reported any sinkhole, settlement or cracking' +
      ' damage regarding this property.',
  ],
  MD: ['A member of the home has been convicted of Arson in the past 5 years.'],
};

export const TenantsKnockoutQuestions = {
  AllStates: [
    'There is a commercial business within the same building as your residence.',
    'The home is in foreclosure, rented as a vacation or short-term rental, or deeded to someone other than the primary.',
    'This individual rented property is under construction, is being renovated, is vacant, or has been converted from a' +
      ' non-residential property to a residential property.',
    'This rented property is occupied by someone other than this potential policyholder.',
    'The home has a supplemental heat source other than a builder-installed prefabricated fireplace, such as a woodstove.',
    'The property is accessible to fire equipment only part of the year.',
    'The home has an unfenced pool or trampoline without safety netting.',
    "The home's heat is controlled manually, rather than using a thermostat.",
  ],
  FL: [
    'The home is currently under the course of construction, extensive remodeling, or rehabilitation.',
    'The Dwelling has been converted from non-residential to residential.',
    'The owner has been told that this property has experienced damage or loss resulting from sinkhole activity or collapse or' +
      ' been the subject of any ground movement investigation.',
    'The owner has been told that this property does or has ever had a sinkhole activity or any other movement, whether confirmed' +
      ' or not, regardless if it was tested or not.',
    'The applicant has ever reported any potential sinkhole, settlement or cracking damage or loss to this property or has' +
      ' knowledge that it exists.',
    'The applicant has any knowledge that any prior owner or renter has reported any sinkhole, settlement or cracking' +
      ' damage regarding this property.',
  ],
  MD: ['A member of the home has been convicted of Arson in the past 5 years.'],
};

export const UmbrellaKnockoutOccupations = [
  'Electronic Media',
  'Entertainers, Script and Screen Writers, Business Managers and Agents',
  'Foreign Diplomats and Foreign Nationals',
  'Celebrity or Considered as Celebrity (Local/National)',
  'Work Out of the Residence/Customers Regularly Come Into the Residence',
  'Police and Patrol Officers, Law Enforcement Officers',
  'Politician, Political Appointee, Lobbyist, Judge, Running for Public Office',
  'Broadcaster, Radio, TV Personality, Reporter, Editor, Columnist, Publisher',
  'Full or Part-time Public Lecturer',
  'Self Employed',
];

export const KnockoutDisclaimer = {
  homeowner: [
    `*This quote assumes the following: The home is not a townhouse,
       second home, vacant home, modular home, or registered as historic;
       the home is not an operational farm or business; the
       policyholder’s property insurance has not been cancelled or
       nonrenewed for any reason, other than nonpayment of premium, in
       the past 5 years; the
       insured does not have an animal that has caused injury.`,
  ],
  condo: [
    `*This quote assumes the following: The condo is not a second home,
       vacant home, modular home, or registered as historic;
       the home is not an operational farm or business; the
       policyholder’s property insurance has not been cancelled or
       nonrenewed for any reason, other than nonpayment of premium, in
       the past 5 years; the insured does not have an animal that
       has caused injury. `,
  ],
  renters: [
    `*This quote assumes the following: The tenant dwelling is not a second home,
     vacant home, modular home, or in a building registered as historic;
     the policyholder's property insurance has not been cancelled or nonrenewed
     for any reason other than nonpayment of premium in the past 5 years.`,
  ],
  canine: [
    `the insured does not have an animal from any of the following
       breeds commonly known as Pit Bulls, Doberman Pinschers,
       Rottweilers, Chow Chows, Presa Canarios, or wolf hybrid. `,
  ],
  animalBiteDisclaimerforMD_MI: [
    `*This quote assumes the following: The tenant dwelling is not a second home,
      vacant home, modular home or in a building registered as historic;
      the policyholder's property insurance has not been cancelled or nonrenewed
      for any reason other than nonpayment of premium in the past 5 years;
      the insured does not have an animal that has caused injury.`,
  ],
  showAdoptAccordLanguage: [
    `NORTH DAKOTA LAW ALSO REQUIRES US TO INFORM YOU THAT WE WILL CONSIDER YOUR
    CLAIMS HISTORY IN DETERMINING WHETHER TO DECLINE, CANCEL, NON-RENEW, OR
    SURCHARGE A POLICY. ALSO, A CLAIM INCURRED BY YOU WILL BE REPORTED TO AN
    INSURANCE SUPPORT ORGANIZATION.`,
  ],
};

export const StateArrayReplacement: {
  [key: string]: {
    [key: string]: {
      replacement: string[];
    };
  };
} = {
  FL: {
    HomeownersQuestions: {
      replacement: HomeownersKnockoutQuestions.AllStates.concat(
        HomeownersKnockoutQuestions.FL
      ),
    },
    TenantsQuestions: {
      replacement: TenantsKnockoutQuestions.AllStates.concat(
        TenantsKnockoutQuestions.FL
      ),
    },
  },
  MD: {
    HomeOwnersQuestions: {
      replacement: HomeownersKnockoutQuestions.AllStates.concat(
        HomeownersKnockoutQuestions.MD
      ),
    },
    CondoQuestions: {
      replacement: CondoKnockoutQuestions.AllStates.concat(
        CondoKnockoutQuestions.MD
      ),
    },
    TenantsQuestions: {
      replacement: TenantsKnockoutQuestions.AllStates.concat(
        TenantsKnockoutQuestions.MD
      ),
    },
  },
};

export const CoverageLimitValidationValues: {
  [key: string]: {
    [key: string]: {
      value: string;
    };
  };
} = {
  UnscheduledMoneyLimit: {
    Min: {
      value: '200',
    },
    Max: {
      value: '1000',
    },
  },
  UnscheduledSecuritiesLimit: {
    Min: {
      value: '1500',
    },
    Max: {
      value: '3000',
    },
  },
  UnscheduledJewelryWatchesFursLimit: {
    Min: {
      value: '1500',
    },
    Max: {
      value: '6500',
    },
  },
  UnscheduledSilverwareLimit: {
    Max: {
      value: '10000',
    },
  },
};

export const StateStringReplacement: {
  [key: string]: {
    [key: string]: {
      replacement: string;
    };
  };
} = {
  AR: {
    'Medical Benefits': {
      replacement: 'Medical Expense Benefits',
    },
  },
  CA: {
    Dwelling: {
      replacement: 'Dwelling Amount including Debris Removal',
    },
    'Workers Compensation': {
      replacement: "Worker's Compensation - Resident Employees",
    },
  },
  WV: {
    'Uninsured Motorist Bodily Injury': {
      replacement: 'Uninsured Motorists Bodily Injury Liability',
    },
    'Underinsured Motorist Bodily Injury': {
      replacement: 'Underinsured Motorists Bodily Injury Liability',
    },
    'Uninsured Motorist Property Damage': {
      replacement: 'Uninsured Motorists Property Damage Liability',
    },
    'Underinsured Motorist Property Damage': {
      replacement: 'Underinsured Motorists Property Damage Liability',
    },
  },
  NC: {
    Silverware: {
      replacement: 'Theft of Silverware',
    },
    'Jewelry, Watches, Furs': {
      replacement: 'Theft of Jewelry, Watches, Furs',
    },
  },
};

export const StateExceptionCoverageLimit: {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        value: string;
      };
    };
  };
} = {
  NY: {
    BioDeteriorationDamageCoverage: {
      BioDeteriorationDamageCoverageLimit: {
        value: '20000',
      },
    },
    Mold: {
      MoldLimit: {
        value: '50000',
      },
    },
  },
  TX: {
    BioDeteriorationDamageCoverage: {
      BioDeteriorationDamageCoverageLimit: {
        value: '10000',
      },
    },
    Mold: {
      MoldLimit: {
        value: '25',
      },
    },
  },
};

export const StateExceptionCoverageMetadata: {
  [key: string]: {
    [key: string]: {
      displayGrouping: string;
      coverageSequence: number;
      spreadable?: boolean;
    };
  };
} = {
  TX: {
    PIP: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 10,
      spreadable: true,
    },
    BioDeteriorationDamageCoverage: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 44,
      spreadable: true,
    },
    Mold: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 45,
      spreadable: true,
    },
  },
  WA: {
    PIP: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 10,
      spreadable: true,
    },
    UIMPD: {
      displayGrouping: CoverageDisplayGrouping.Policy,
      coverageSequence: 25,
      spreadable: true,
    },
  },
  KS: {
    PIP: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 1,
      spreadable: true,
    },
  },
  ME: {
    PIP: {
      displayGrouping: CoverageDisplayGrouping.Vehicle,
      coverageSequence: 1,
      spreadable: true,
    },
  },
  GA: {
    BioDeteriorationDamageCoverage: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 44,
      spreadable: true,
    },
    Mold: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 45,
      spreadable: true,
    },
  },
  NY: {
    BioDeteriorationDamageCoverage: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 44,
      spreadable: true,
    },
    Mold: {
      displayGrouping: CoverageDisplayGrouping.SpecialLimitsOfLiability,
      coverageSequence: 45,
      spreadable: true,
    },
  },
  VA: {
    WaterBackupLimited: {
      displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
      coverageSequence: 45,
      spreadable: true,
    },
    WaterBackupFull: {
      displayGrouping: CoverageDisplayGrouping.AdditionalProtection,
      coverageSequence: 46,
      spreadable: true,
    },
  },
};

export const ProductExceptionCoverageMetadata: {
  [key: string]: {
    [key: string]: {
      displayGrouping: string;
      coverageSequence: number;
      spreadable?: boolean;
    };
  };
} = {};

export const ProtectiveDeviceSelectedValueReplace: {
  [key: string]: {
    [key: string]: {
      replacement: string;
    };
  };
} = {
  OpeningProtection_Ext: {
    None: {
      replacement: 'None',
    },
  },
};

export const RecurringPaymentConfirmation = {
  Customer: `I have read and agreed to the terms of the Recurring
   ACH Payments Authorization Agreement and accept the
  `,
  Agent: `I confirm that I have gotten proper authorization from the customer who
   has agreed to the above terms and the `,
};

export const SECONDS_BEFORE_EXPIRE_TO_REFRESH = 30; // Refresh 30 seconds before the access token expires
export const VEHICLES_ALLOWED_ON_QUOTE = 9;

export const NationwideContactNumber = '1-855-550-0768';

export const NationwideAgentContactNumber = '1-877-267-6292';

export const NationwideBrokerContactNumber = '1-866-750-9133';

export const NationwidePartnerContactNumber = '1-855-572-7883';

export const FindAnAgentUrl = 'https://agency.nationwide.com/search';

export const GetInsurifyUrl = 'http://uat.nwinsurify.com/?next=car_make&state=';

export const GetAQuoteUrl =
  'https://choice.nationwide.com/?next=car_make&state=';

export const PartialQuoteProductTypes = {
  HOMEOWNER: 'Homeowner',
  CONDO: 'Condo',
  RENTERS: 'Renters',
};

export enum RetrieveProductTypes {
  PERSONAL_AUTO = 'PersonalAuto',
  POWERSPORTS = 'Powersports',
  // comp rater
  HOMEOWNERS = 'Homeowners',
  // search quote
  HOMEOWNER = 'Homeowner',
  // retrieve
  CONDO = 'Condo',
  // search
  CONDOMINIUM = 'Condominium',
  TENANT = 'Tenant',
  UMBRELLA = 'PersonalUmbrella',
}
export enum StateSpecificFlagKeys {
  accidentPreventionCourseApplicable = 'accidentPreventionCourseApplicable',
  additionalEligibleDiscount = 'additionalEligibleDiscount',
  ageFirstLicensed = 'ageFirstLicensed',
  animalBiteDisclaimer = 'animalBiteDisclaimer',
  annualMileageUpdate = 'annualMileageUpdate',
  antiTheftDevices = 'antiTheftDevices',
  applicationLanguage = 'applicationLanguage',
  assignedRiskPlan = 'assignedRiskPlan',
  combineHurricaneOptions = 'combineHurricaneOptions',
  coveragesDisclaimer = 'coveragesDisclaimer',
  defaultEstimatedAnnualMiles = 'defaultEstimatedAnnualMiles',
  driverOccupation = 'driverOccupation',
  driverRelationToPNI = 'driverRelationToPNI',
  driverVehicleAssignment = 'driverVehicleAssignment',
  dwellingReplacementCost = 'dwellingReplacementCost',
  earthquakeCoverageEligible = 'earthquakeCoverageEligible',
  earthquakeCoverageMessage = 'earthquakeCoverageMessage',
  eligibleDiscountApplicable = 'eligibleDiscountApplicable',
  footerUnderwritingDisclaimer = 'footerUnderwritingDisclaimer',
  fullSafetyGlassSelectboxRemovableState = 'fullSafetyGlassSelectboxRemovableState',
  goodStudent = 'goodStudent',
  healthCarePlan = 'healthCarePlan',
  increasedOtherStructures = 'increasedOtherStructures',
  infractionDesc = 'infractionDesc',
  isAdditionalPerilsDisclaimer = 'isAdditionalPerilsDisclaimer',
  isThirdPartyDesigneeDisclaimer = 'isThirdPartyDesigneeDisclaimer',
  isBiologicalDeteriorationCoverageApplicable = 'isBiologicalDeteriorationCoverageApplicable',
  isDeclinePIPLimitCoverageApplicable = 'isDeclinePIPLimitCoverageApplicable',
  isDeviceOnlyState = 'isDeviceOnlyState',
  isEligibleDiscountDriverRequest = 'isEligibleDiscountDriverRequest',
  isExcludeTDIcoverageIfUnavailable = 'isExcludeTDIcoverageIfUnavailable',
  isMonoLineBrokerRestriction = 'isMonoLineBrokerRestriction',
  isNoPlayStateAllProductsRestricted = 'isNoPlayStateAllProductsRestricted',
  isPipExcluded = 'isPipExcluded',
  isPreviouslyOwned = 'isPreviouslyOwned',
  isUIMPDCoverageApplicable = 'isUIMPDCoverageApplicable',
  loiNotMandatory = 'loiNotMandatory',
  motoEndorseApplicable = 'motoEndorseApplicable',
  msbDisclaimerMessage = 'msbDisclaimerMessage',
  nonSpecifiedGenderApplicable = 'nonSpecifiedGenderApplicable',
  nonStandardBridgeAllowed = 'nonStandardBridgeAllowed',
  nonStandardBridgeCoveragesNotAllowed = 'nonStandardBridgeCoveragesNotAllowed',
  offeringTypeApplicable = 'offeringTypeApplicable',
  passiveDisablingOnly = 'passiveDisablingOnly',
  pipWorkLossWaiver = 'pipWorkLossWaiver',
  primaryUseApplicable = 'primaryUseApplicable',
  powersportsProductApplicable = 'powersportsProductApplicable',
  preBindDocuments = 'preBindDocuments',
  removeContinuousInsuranceQuestion = 'removeContinuousInsuranceQuestion',
  removeEftPaymentOption = 'removeEftPaymentOption',
  removeManualEftPaymentOption = 'removeManualEftPaymentOption',
  removeMedicalBenefitsDeclineOption = 'removeMedicalBenefitsDeclineOption',
  requireAdvancedAccidentPrevention = 'requireAdvancedAccidentPrevention',
  requiredOilTank = 'requiredOilTank',
  requiredUSArmedForces = 'requiredUSArmedForces',
  requiredNationalGaurd = 'requiredNationalGaurd',
  restrictAllProductsForDirect = 'restrictAllProductsForDirect',
  restrictHOProduct = 'restrictHOProduct',
  restrictRentersProductForNSS = 'restrictRentersProductForNSS',
  restrictWindstormProtectiveDeviceToHomeowners = 'restrictWindstormProtectiveDeviceToHomeowners',
  reviewConstructionInfo = 'reviewConstructionInfo',
  ridingAssociationQuestion = 'ridingAssociationQuestion',
  selfReportedProgram = 'selfReportedProgram',
  seperateWindandHailorHurricanesandotherperils = 'seperateWindandHailorHurricanesandotherperils',
  showAdditionalHelpTextForCoverages = 'showAdditionalHelpTextForCoverages',
  showAdditionalProtectionDevices = 'showAdditionalProtectionDevices',
  showAdoptAccordLanguage = 'showAdoptAccordLanguage',
  showDogsInPropertyDisqualifier = 'showDogsInPropertyDisqualifier',
  showDwellingReplacementCost = 'showDwellingReplacementCost',
  showPersonalUmbrellaLiabilityApplicable = 'showPersonalUmbrellaLiabilityApplicable',
  sinkhole = 'sinkhole',
  smartHomeDiscountApplicable = 'smartHomeDiscountApplicable',
  smartMiles = 'smartMiles',
  smartrideAcceptAFMVF = 'smartrideAcceptAFMVF',
  smartRideEarlyReward = 'smartRideEarlyReward',
  srccIneligible = 'srccIneligible',
  srccForCA = 'srccForCA',
  sriIneligible = 'sriIneligible',
  stateApprovedSafetyCourse = 'stateApprovedSafetyCourse',
  surchargeDisclosureStatement = 'surchargeDisclosureStatement',
  umpdOption = 'umpdOption',
  umbiLimitDoubleBi = 'umbiLimitDoubleBi',
  vehicleDailyUseQuestions = 'vehicleDailyUseQuestions',
  vehicleHistoricalQuestionApplicable = 'vehicleHistoricalQuestionApplicable',
  vehicleMiles = 'vehicleMiles',
  vehicleOdometerReading = 'vehicleOdometerReading',
  vehicleRegisteredInState = 'vehicleRegisteredInState',
  vehicleRegisteredOwners = 'vehicleRegisteredOwners',
  vehicleRegisteredOwnersForPowersports = 'vehicleRegisteredOwnersForPowersports',
  windMitigationDiscountApplicable = 'windMitigationDiscountApplicable',
  workersCompensationHelpText = 'workersCompensationHelpText',
  umbiValidOption = 'umbiValidOption',
  thirdPartyPropertyPause = 'thirdPartyPropertyPause',
}

export enum CurrentEmploymentValues {
  GE12MoStudent_Ext = 'GE12MoStudent_Ext',
  Retired_Ext = 'Retired_Ext',
  GE12MoNotInclStudent_Ext = 'GE12MoNotInclStudent_Ext',
  GE_12mo = 'GE_12mo',
  Other = 'other',
}

export const ALL_OTHER_OCCUPATION = 'AllOther';
export const MAX_AGE_FOR_STUDENT = 22;
export const MIN_AGE_FOR_RETIRED = 65;
export const CHAR_CODE_SPECIAL = 47;
export const CHAR_CODE_SYMBOL = 58;

export const RENOVATION = {
  ALL: 'When were the following installed, last renovated, or replaced?',
  SINGLE: 'Year {renovationType} was last replaced?',
};

export const COVERAGES_PAGE_DISCLAIMER_TEXTS = {
  QUOTE_COVERAGES_ASTERIK_DISCLAIMER:
    '* These are only some of the coverage options and discounts we offer. ' +
    'You may qualify for more discounts.',
  QUOTE_COVERAGES_GENERAL_DISCLAIMER:
    "Once you finalize your purchase, you'll be able to access " +
    'your account online, by phone or with your agent to make any changes.',
  QUOTE_COVERAGES_REQUIRED_AMOUNTS:
    "We're required to include certain coverage amounts in your insurance " +
    'package. You may be able to change the amount or waive these minimum ' +
    'coverages or seek other coverage options once you finalize your ' +
    'purchase.',
  QUOTE_COVERAGES_UNDERWRITTING_DISCOUNTS:
    'Products underwritten by Nationwide Mutual Insurance Company and ' +
    'Affiliates. Columbus, Ohio. Availability varies. Discounts do not apply ' +
    'to all coverage elements; actual savings vary by state, coverage ' +
    'selections, rating factors and policy changes. Enrollment discount ' +
    'applies during data collection; final discount is calculated on driving ' +
    'behavior and could be zero. Final discount applies at the next policy ' +
    'renewal and remains until drivers or vehicles on the policy change.',
  BIND_COVERAGES_PRODUCTS_FEATURES_DISCOUNTS:
    'This quote is not a reflection of contractual language. For specific ' +
    'coverage information, please refer to the policy and/or declarations ' +
    'page. This quote does not guarantee or bind coverage. Additional ' +
    'products, features and discounts may be available through a licensed ' +
    'Nationwide professional.',
  BIND_COVERAGES_PRODUCTS_FEATURES_DISCLAIMER:
    ' This quote is an estimate and is subject to change if your ' +
    'circumstances are different than the information provided, and/or any ' +
    'information provided is inaccurate or incomplete.',
  BIND_COVERAGES_SELECTION_RESPONSIBILITY:
    'Coverage packages are provided for informational purposes only. If ' +
    'your unique circumstances warrant modifications to these packages, you ' +
    'are responsible for selecting the appropriate coverage options. Please ' +
    'do not hesitate to contact a Nationwide agent or licensed insurance ' +
    'representative for assistance.',
};

export const MORE_DETAILS_FORM_TEXT_ENG = {
  INVALID_ACCIDENT_PREVENTION_COURSE_COMPLETION_DATE:
    'Completion Date of the Accident Prevention Course is not valid. ' +
    'State requirements require the course to be completed within ' +
    'the past 2 to 5 years depending on your state.',
};

export const RETRIEVE_FORM_TEXT_ENG = {
  ADD_SUBMISSION_DETAILS:
    'At least one submission detail is required in order to continue.',
  MULTIPLE_SUBMISSION_DETAILS:
    'Multiple submission details for the same product type is not allowed.',
  MISSING_SUBMISSION_ID: 'Submission Id is required',
};

export const AUTO_FORM_TEXT_ENG = {
  VEHICLES: 'Vehicles',
  FOUND_IN_PUBLIC_RECORD_SEARCH:
    'We found these vehicles in a public records search',
  INCLUDE_IN_POLICY: 'To include on the policy, click add.',
  ADD_VEHICLES: 'Add vehicles',
  ADD_ONE_AUTO: 'Please add at least one Auto.',
  ADD_ONE_MOTORCYCLE: 'Please add at least one Motorcycle.',
  EXCEEDED_LIMIT: 'Exceed vehicles limit.',
  MAXIMUM_REACHED: 'The maximum number of vehicles has been added.',
  INCORRECT_VEHICLETYPE: 'Please provide correct VehicleType',
  AUTO_DISCLAIMER:
    'Assumes vehicles are: not used for business, speed or racing; are ' +
    'not garaged in AK, HI, LA, MA, NJ and OK; do not have aftermarket add-ons ' +
    'or accessories installed on the vehicles; garaged at the same ' +
    'location as the residence, unless otherwise documented within the application. ',
  VEHICLE_DISCLAIMER:
    'Assumes vehicles are: not used for business, speed or racing; are ' +
    'not garaged in AK, HI, LA, MA, NJ and OK; garaged at the same location as ' +
    'the residence, unless otherwise documented within the application; have no unrepaired damage or are partially assembled. ',
  PS_VEHICLE_DISCLAIMER:
    'Assumes vehicles are: not garaged in AK, HI, LA, MA, NJ and OK; ' +
    'garaged at the same location as the residence; have no unrepaired damage ' +
    'or are partially assembled. ' +
    'the residence, unless otherwise documented within the application; have no unrepaired damage or are partially assembled. ',
  PLEASE_CALL: 'If a vehicle is used for business purposes, please call',
  CUSTOM_QUOTE:
    'A <business_quote> commercial auto quote</a> ' +
    'can be obtained if the vehicle is used for business or a ' +
    '<nationwide_url> customized quote</a> can be ' +
    'obtained if the vehicle has aftermarket add-ons.',
  CUSTOM_POWERSPORTS_QUOTE:
    'A <business_quote> quote</a> ' +
    'can be obtained if the vehicle is used for business or a ' +
    '<nationwide_url> customized quote</a> can be ' +
    'obtained if the vehicle has aftermarket add-ons.',
  BUSINESS_AUTO_QUOTE_URL:
    'https://www.nationwide.com/business-insurance-quotes.jsp',
  NATIONWIDE_URL: 'https://www.nationwide.com/',
  MISSING_CONTENT: 'Please complete all required fields and save any changes',
  ONLY_UTILITY_TRAILER_ERROR:
    'Must have supporting Powersports vehicle in order to continue',
};

export const HO_PROP_INFO_FORM_ENG = {
  PROPERTY_INFO: {
    TELL_US: 'Tell us about your home',
    PLEASE_CORRECT: 'Please correct the following errors',
    CORRECT_ADDITIONAL_DETAILS:
      'Please correct the additional property details.',
    CORRECT_YEAR: 'Please correct year of home purchased or the year built.',
    CONSTRUCTION_INFO:
      'We were unable to find construction information on this home in a public records search. Please complete the following details.',
    CONSTRUCTION_INFO_MSB:
      'We have found the following construction information on this home in a ' +
      'public records search. Please verify the information is correct.',
  },
  GET_RENO_ERROR: {
    AFTER_YEAR_BUILT: 'Must be after year built',
    FUTURE_NOT_ALLOWED: 'Future dates not allowed',
    INCORRECT_FORMAT: 'Must be in YYYY format',
  },
};

export const ADDITIONAL_PEOPLE_ENG = {
  AUTO_MULTI: {
    ADD_EVERYONE_ELSE:
      'Add everyone else that lives in the home with a drivers license',
  },
  HO_RENTER: {
    ADD_EVERYONE_ELSE: 'Add everyone else who lives in the home',
  },
  EXCLUDE_DRIVERS: {
    AGENT:
      'Assumes all drivers were licensed at age 16 and have not been convicted of insurance fraud. ' +
      'To exclude a driver, pivot to PolicyCenter.',
    DIRECT:
      'Assumes all drivers were licensed at age 16 and have not been convicted of insurance fraud. ' +
      'For more information on how to exclude a driver, please call ',
  },
  MISSING_CONTENT: 'Please complete all required fields and save any changes',
};

export const MortgageeTypes = {
  PRIMARY_MORTGAGEE: 'Mortgagee1',
  MORTGAGEE: 'Mortgagee',
  PRIMARY_LOSS_PAYEE: 'LossPayee1',
  LOSS_PAYEE: 'LossPayee',
};

export const MortgageeDeletedValue = {
  NULL: 'null',
};

export const AFMVF_SMARTRIDE_NOTICE_VERBIAGE =
  'Smartride discount has been deselected. Both Accident Forgiveness and Minor Violation Forgiveness are required.';

export const GarageTypeAllowedCars = {
  'Attached Garage': 5,
  'Basement Garage': 3,
  'Built-in Garage': 3,
  Carport: 3,
  'Detached Garage': 5,
  'Not Applicable': 'Please Select',
};

// UMBRELLA
export const PropertyPolicyTypes = {
  HOMEOWNERS: 'HO3',
  RENTERS: 'HO4',
  CONDO: 'HO6',
};

export const LocationUseage = {
  PRIMARY: 'prim',
};

export const requiredFieldValidationKeys = [
  'required',
  'picklistRequired',
  'productsRequired',
  'phoneNumberRequired',
  'emailIncomplete',
];

export const fieldValidationMessageMap = {
  required: 'Field is required.',
  picklistRequired: 'Field is required.',
  productsRequired: 'Please select the desired product(s)',
  phoneNumberRequired: 'Format should be 555-555-5555',
  invalid: 'Field is invalid.',
  illegalCharacter: 'Invalid character "{}".',
  stringFormat: 'Field is invalid.',
  invalidDays: 'Days per week is invalid.',
  invalidMiles: 'Value must be greater than 0.',
  invalidAnnualMiles: 'Value must be greater than 0.',
  dateFormat: 'Must be MM/DD/YYYY format.',
  dateFormatMmYyyy: 'Must be MM/YYYY format.',
  phoneNumberFormat: 'Field is invalid.',
  dateOfBirthInvalid: 'Please enter MM/DD/YYYY',
  dateOfBirthTooOld: 'Field is invalid.',
  dateOfBirthTooYoung: 'Must be at least 16 years old.',
  dateOfBirthFuture: 'Date of birth cannot be in the future.',
  TooYoung: 'Field is invalid.',
  zipFormat: 'Field is invalid.',
  postalCodeInvalid: 'Must be #####-#### format.',
  mustMatch: "Account numbers don't match.",
  greaterThanAge: 'Age first licensed can not be more than current age.',
  greaterThanAgeFirstLicense: 'Invalid value, based on age first licensed.',
  min: 'Below minimum ({min}).',
  max: 'Above maximum ({max}).',
  minlength: 'Minimum length {requiredLength}.',
  maxlength: 'Maximum length {requiredLength}.',
  incomplete: 'Incomplete address. Please try again.',
  multipleAddresses: 'Incomplete address. Please try again.',
  wrongState: 'Address must be in {}.',
  poBoxNotAllowed: 'Enter a residential street address.',
  giserror: 'Failed to validate address. Please try again.',
  email: 'Invalid email address.',
  emailIncomplete: 'Format should be your@address.com',
  beforeYearBuilt: 'Must be after year built ({}).',
  afterCurrentDate: 'Must be before current date.',
  afterFutureDate: 'Must be within 60 days from current date.',
  futurePurchaseDate: 'Purchase date cannot be in the future',
  duplicateLicense: 'License "{}" already in use.',
  tooManyRegisteredOwners:
    'Please select between 1 and 2 Registered Owners per vehicle.',
  futureDate: 'Date cannot be in future',
  requiredBefore: 'Date cannot be prior to {month}/{day}/{year}',
  requiredAfter: 'Date cannot be after {month}/{day}/{year}',
  invalidMonth: 'Invalid month',
  thisYearOrEarlier: 'The year needs to be this year or earlier.',
  invalidAssociateNumber: 'Must be 6 digits',
  invalidRoutingNumber: 'Please enter valid Routing number',
  invalidAccountNumber: 'Please enter valid Account number',
};
/* TODO ad-hoc messages that will need new keys:
 * Invalid VIN [auto-form]
 * Year is required [auto-form]
 * Make is required [auto-form]
 * Model is required [auto-form]
 * Vehicle use is required [auto-form]
 * Miles one way is required [auto-form]
 * Days per week is required [auto-form]
 * Days per week is invalid [auto-form]
 * Current odometer reading is required [auto-form]
 */

export const StateExceptionTelematicsDiscountMetadata: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  AR: {
    initialDiscount: '15%',
  },
  AL: {
    initialDiscount: '15%',
  },
  AZ: {
    initialDiscount: '15%',
  },
  CA: {
    initialDiscount: '5%',
    maximumDiscount: '15%',
  },
  CO: {
    initialDiscount: '15%',
  },

  CT: {
    initialDiscount: '15%',
  },
  DC: {
    initialDiscount: '15%',
  },
  DE: {
    initialDiscount: '15%',
  },
  FL: {
    initialDiscount: '15%',
  },
  GA: {
    initialDiscount: '15%',
  },
  IA: {
    initialDiscount: '15%',
  },
  ID: {
    initialDiscount: '15%',
  },
  IL: {
    initialDiscount: '15%',
  },
  IN: {
    initialDiscount: '15%',
  },
  KS: {
    initialDiscount: '15%',
  },
  KY: {
    initialDiscount: '15%',
  },
  MD: {
    initialDiscount: '15%',
  },
  ME: {
    initialDiscount: '15%',
  },
  MI: {
    initialDiscount: '15%',
  },
  MN: {
    initialDiscount: '15%',
  },
  MO: {
    initialDiscount: '15%',
  },
  MS: {
    initialDiscount: '15%',
  },
  MT: {
    initialDiscount: '15%',
  },
  NE: {
    initialDiscount: '15%',
  },
  NH: {
    initialDiscount: '15%',
  },
  NM: {
    initialDiscount: '15%',
  },
  NV: {
    initialDiscount: '15%',
  },
  NY: {
    maximumDiscount: '25%',
  },
  OH: {
    initialDiscount: '15%',
  },
  OR: {
    initialDiscount: '15%',
  },
  RI: {
    initialDiscount: '15%',
  },
  SC: {
    initialDiscount: '15%',
  },
  SD: {
    initialDiscount: '15%',
  },
  TN: {
    initialDiscount: '15%',
  },
  TX: {
    initialDiscount: '15%',
  },
  UT: {
    initialDiscount: '15%',
  },
  VA: {
    initialDiscount: '15%',
  },
  VT: {
    initialDiscount: '15%',
  },
  WA: {
    initialDiscount: '15%',
  },
  WI: {
    initialDiscount: '15%',
  },
  WV: {
    initialDiscount: '15%',
  },
  WY: {
    initialDiscount: '15%',
  },
};

export const RedirectDestinations = {
  PC: 'PC',
  NWX: 'NWX',
};

export const RedirectTargets = {
  PNI: 'PNI',
  QUOTE_COVERAGES: 'QUOTE_COVERAGES',
};

export const ANNUAL_MILEAGE_HELP_TEXT = `<strong>For vehicles in the pay-per-mile program, SmartMiles,</strong> your estimated mileage is used to calculate your
     average monthly cost. You can update your estimated annual mileage for this vehicle by changing the mileage above.`;
export const FeatureNames = {
  Michigan: 'Michigan',
};

export const WindProtectiveDeviceNames = [
  'windProtectiveDeviceRI',
  'windProtectiveDeviceCTNY',
  'windProtectiveDeviceMD',
];

export const SQUARE_FOOTAGE_MIN = 100;
export const SQUARE_FOOTAGE_MAX = 9950;

// TODO Refactor constants between lines with this comment by moving them into term-life-constants.ts
export const TermLifeConstants = {
  maxHeightFeet: 9,
  maxHeightInches: 11,
  maxWeightChars: 3,
  maxWeightTotal: 999,
  // UMBRELLA
};
// TODO Refactor constants between lines with this comment by moving them into term-life-constants.ts
export const MAX_GIACT_HIT_COUNT = 3;

export const GIACTAPI_RESPONSE_CODES = {
  1111: 'SUCCESS',
  3333: 'SUCCESS',
  5555: 'SUCCESS',
  GN01: 'INVALID_ACCOUNT',
  ND00: 'SUCCESS',
  RT03: 'INVALID_ACCOUNT',
  GS01: 'INVALID_ROUTING_NUM',
  GN05: 'INVALID_ROUTING_NUM',
  GS02: 'INVALID_ACCOUNT_NUM',
  GP01: 'INVALID_ACCOUNT',
  RT00: 'INVALID_ACCOUNT',
  RT01: 'INVALID_ACCOUNT',
  RT02: 'INVALID_ACCOUNT',
  RT04: 'INVALID_ACCOUNT',
  ND01: 'INVALID_ACCOUNT',
};
// TODO Refactor constants between lines with this comment by moving them into term-life-constants.ts
export const TERMLIFE_TERM_MONTHS = 12;
// TODO Refactor constants between lines with this comment by moving them into term-life-constants.ts
export const KYPIPPowersportsTermTypes = {
  DEDUCTIBLE: 'Deductible',
  LIMIT: 'Limit',
  GUEST: 'Guest Only',
};
export const KYPIPPowersportsTermCodes = {
  DEDUCTIBLE: 'PIPDeductible',
  LIMIT: 'PIPLimit',
  GUEST: 'PIPGuestOnly',
};

export const ProtectiveDeviceTypes = {
  BURGLARALARM: 'Burglar Alarm',
  SPRINKLERSYSTEM: 'Sprinkler System',
  FIREORSMOKEALARM: 'Fire/Smoke Alarm',
};

export const AgencyIdentityMethod = {
  IA_IDENTITY_METHOD: 'ac',
  DEFAULT_IDENTITY_METHOD: 'nwie',
};

export const DVA_STATES = ['CA', 'DE', 'MN', 'NC', 'NY', 'VA'];

export const ADOBE_TARGET_MBOXES = {
  PROGRESS_TRACKER_MBOX: 'ab-nwx-progress-tracker',
  PROGRESS_TRACKER_AUTO_AND_HOME_MBOX: 'ab-nws-progress-tracker-phase-two',
  CLAIMS_REVIEW_MBOX: 'ab-nwx-claims-review',
};

export const PROPERTY_FOR_NON_PRIVATE_LABEL_TRAFFIC_RESTRICTED_PHONE =
  '1-866-750-9133';

export const SCLOWBILIMIT_PRIVATELABEL =
  "We aren't able to continue with this product online. Please contact your agent for assistance.";

export const SmartrideInstantConsentText = {
  SMARTRIDE_INSTANT_CONSENT_TITLE:
    'You may qualify for savings on insurance products and services.',
  SMARTRIDE_INSTANT_CONSENT_BODY:
    'Nationwide Insurance partners with vehicle manufacturers and third parties ' +
    'to collect vehicle driving data to apply discounts when available. By accepting, ' +
    'you are consenting to allow the vehicle manufacturer or third party to provide ' +
    'driving data for up to the most recent year where available. Driving data includes ' +
    'attributes such as braking, acceleration and mileage.',
};
export const NoPlayStatesInOnlyDirectFlow = [
  'AK',
  'FL',
  'HI',
  'LA',
  'MA',
  'OK',
];

export const SHE_ACondo = 'A-Condo';

export const RoofConditionOptions = {
  Excellent: 'Excellent',
  Good: 'Good',
  PoorOrNeedsRepair: 'PoorOrNeedsRepair',
};

export const UtmMedium = {
  organic: 'organic',
  cpc: 'cpc',
  email: 'email',
};

export const UtmSource = {
  ford: 'ford',
  lincoln: 'lincoln',
  exacttarget: 'exacttarget',
};

export const MonolineTFN = {
  TFN1: '1-888-891-0153',
  TFN2: '1-855-241-8085',
  TFN3: '1-844-207-7609',
  TFN4: '1-844-207-7612',
  TFN5: '1-844-826-4765',
  TFN6: '1-855-590-9783',
  TFN7: '1-855-723-6173',
  TFN8: '1-855-258-9440',
  TFN9: '1-855-241-9719',
  TFN10: '1-855-241-7628',
  TFN11: '1-855-241-9715',
  TFN12: '1-855-723-6179',
  TFN13: '1-855-832-1313',
  TFN14: '1-855-832-1314',
  TFN15: '1-866-238-0618',
  TFN16: '1-866-207-9158',
  TFN17: '1-866-633-5751',
  TFN18: '1-888-231-6301',
  TFN19: '1-888-490-1561',
  TFN20: '1-888-889-4322',
};

export const SmartMilesMethods = {
  ConnectedCar: 'ConnectedCar',
  Device: 'Device',
};

export const SmartMiles_ConnectedCar = 'SmartMilesConnectedCar';

export const DEFAULT_EXTERIOR_WALLS = [
  'Adobe',
  'BrickSolid',
  'ConcretePouredInPlace4To6',
  'ShakesWood',
  'SidingVinyl',
  'StuccoOnFrame',
  'StoneOnFrame',
];

export const CONDO_RENTERS_DWELLING_MINIMUM = 15000;

export const NWX_CLAIMS_REVIEWS_SPINNER_UPPER_MESSAGE =
  'See what others say about their claims experience while we prepare your quote.';

export const NWX_CLAIMS_REVIEWS = [
  {
    comment: `Because of this experience, I plan to bundle our new home with Nationwide.`,
    reviewer: 'Craig & Christen',
  },
  {
    comment: `Very timely communications and the work was approved within a week which is fantastic! Could not ask for anything more.`,
    reviewer: 'Bruce',
  },
  {
    comment: `It was handled very professionally, with speed, and seamlessly. Great job and appreciate the high level of service.`,
    reviewer: 'Therese',
  },
  {
    comment: `Everyone I have spoken with has been knowledgeable, friendly, prompt in responding and reaching out to me. I am very impressed with the service. Thank you.`,
    reviewer: 'Douglas',
  },
  {
    comment: `Extremely easy and quick. I shared this experience with many people, and they are surprised how quick you all completed this.`,
    reviewer: 'John',
  },
  {
    comment: `Best claim handling I have seen in years, and I am a coverage lawyer, so I see a lot. I’d have given it an 11 if I could.`,
    reviewer: 'Peter',
  },
];

export const PET_CROSS_SELL_EMAIL_TEMPLATE = 'TL-NW-PET-CROSS-SELL-1';
