import { Injectable } from '@angular/core';
import {
  AccessTokenRequest,
  PrepopulatedInsuredAccessTokenRequest,
} from '@core/models/auto/quotes/access-token-request.model';
import { Observable } from 'rxjs';
import { EuaResponse } from './oauth-iframe.service';
import { EuaApiAdapter } from '@core/adapters/eua-api.adapter';

@Injectable({
  providedIn: 'root',
})
export class EuaApiService {
  constructor(private _euaApiAdapter: EuaApiAdapter) {}

  createAccessToken(
    request: AccessTokenRequest | PrepopulatedInsuredAccessTokenRequest
  ): Observable<EuaResponse> {
    return this._euaApiAdapter.createAccessToken(request);
  }
}
