import { Injectable } from '@angular/core';
import { TelematicsEnrollmentsAdapter } from '@core/adapters/telematics-enrollments.adapter';
import { Observable } from 'rxjs';
import { TelematicsEnrollmentsDao } from '@core/dao/telematics-enrollments.dao';
import {
  TelematicsEnrollmentRecommendationResponse,
  TelematicsEnrollmentResponse,
  TelematicsEnrollmentRequest,
  PreQualifiedVehicleInfo,
} from '@core/models/telematics/telematics.model';
import { TelematicsRecommendationRequest } from '@core/models/request/telematics-recommendation-request.model';

@Injectable({
  providedIn: 'root',
})
export class TelematicsEnrollmentsService {
  constructor(
    private _telematicsEnrollmentsAdapter: TelematicsEnrollmentsAdapter,
    private _telematicsEnrollmentDao: TelematicsEnrollmentsDao
  ) {}

  getTelematicsEnrollments(request): Observable<TelematicsEnrollmentResponse> {
    return this._telematicsEnrollmentsAdapter.getTelematicsEnrollments(request);
  }

  getTelematicsRecommendation(
    request: TelematicsRecommendationRequest,
    quoteId: string
  ): Observable<TelematicsEnrollmentRecommendationResponse> {
    return this._telematicsEnrollmentsAdapter.getTelematicsRecommendation(
      request,
      quoteId
    );
  }

  addTelematicsEnrollment(
    request: TelematicsEnrollmentRequest
  ): Observable<TelematicsEnrollmentResponse> {
    return this._telematicsEnrollmentsAdapter.addTelematicsEnrollment(request);
  }

  updateTelematicsEnrollment(request): Observable<any> {
    return this._telematicsEnrollmentsAdapter.updateTelematicsEnrollment(
      request
    );
  }

  removeTelematicsEnrollment(request): Observable<any> {
    return this._telematicsEnrollmentsAdapter.removeTelematicsEnrollment(
      request
    );
  }

  getEnrollmentsLoaded(): Observable<boolean> {
    return this._telematicsEnrollmentDao.getEnrollmentsLoaded();
  }

  getEnrollmentsLoading(): Observable<boolean> {
    return this._telematicsEnrollmentDao.getEnrollmentsLoading();
  }

  getEnrollments(): Observable<TelematicsEnrollmentResponse> {
    return this._telematicsEnrollmentDao.getEnrollments();
  }

  getRecommendation(): Observable<TelematicsEnrollmentRecommendationResponse> {
    return this._telematicsEnrollmentDao.getRecommendation();
  }

  dispatchTelematicsEnrollment(): void {
    this._telematicsEnrollmentDao.dispatchTelematicsEnrollment();
  }

  dispatchAddTelematicsEnrollments(request): void {
    this._telematicsEnrollmentDao.dispatchAddTelematicsEnrollment(request);
  }

  dispatchUpdateTelematicsEnrollment(request: {
    enrollmentVehicle;
    selectedProgram;
  }) {
    this._telematicsEnrollmentDao.dispatchUpdateTelematicsEnrollment(request);
  }

  dispatchRemoveTelematicsEnrollment() {
    this._telematicsEnrollmentDao.dispatchRemoveTelematicsEnrollment();
  }

  dispatchSetTelematicsEnrollment(enrollment: TelematicsEnrollmentResponse) {
    this._telematicsEnrollmentDao.dispatchSetTelematicsEnrollment(enrollment);
  }

  dispatchUnsetTelematicsEnrollment() {
    this._telematicsEnrollmentDao.dispatchUnsetTelematicsEnrollmentLoaded();
  }

  hasAnyEnrollment(): Observable<boolean> {
    return this._telematicsEnrollmentDao.hasAnyEnrollment();
  }

  isVehicleEnrolled(vehicleId: string): Observable<boolean> {
    return this._telematicsEnrollmentDao.isVehicleEnrolled(vehicleId);
  }

  isConnectedCarEligibleAndHasAnyEnrollment(): Observable<boolean> {
    return this._telematicsEnrollmentDao.isConnectedCarEligibleAndHasAnyEnrollment();
  }

  getPreQualifiedVehicleInfo(): Observable<PreQualifiedVehicleInfo[]> {
    return this._telematicsEnrollmentDao.getPreQualifiedVehicleInfo();
  }

  dispatchUpdateMobileInfoTelematicsEnrollment(request: {
    isTextNotificationRequired: boolean;
    phoneNumber: string;
  }): void {
    this._telematicsEnrollmentDao.dispatchUpdateMobileInfoTelematicsEnrollment(
      request
    );
  }

  updateMobileInfoTelematicsEnrollment(request): Observable<any> {
    return this._telematicsEnrollmentsAdapter.updateMobileInfoTelematicsEnrollment(
      request
    );
  }

  isSmartMilesEnrolled(): Observable<boolean> {
    return this._telematicsEnrollmentDao.isSmartMilesEnrolled();
  }

  getPolicyHolderPhoneNumber(): Observable<string> {
    return this._telematicsEnrollmentDao.getPolicyHolderPhoneNumber();
  }
}
