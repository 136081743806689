import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HelpContentItem } from '@help-content/help-content-item';

@Component({
  selector: 'nw-toggle-pill',
  templateUrl: './toggle-pill.component.html',
  styleUrls: ['./toggle-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TogglePillComponent implements OnInit, OnChanges {
  @Input()
  label: string;

  @Input()
  eventName: string;

  @Input()
  trueValue: any;

  @Input()
  falseValue: any;

  @Input()
  defaultValue: any;

  @Input()
  helpContent: HelpContentItem;

  @Output()
  pillToggled = new EventEmitter<any>();

  @Input()
  showHelp?: boolean;

  @Input()
  disabled?: boolean;

  selected: boolean;

  constructor(private _modalService: NgbModal) {}

  ngOnInit(): void {
    if (this.defaultValue === this.trueValue) {
      this.selected = true;
    }
    this.showHelp = !this.showHelp ? this.showHelp : true;
    this.disabled = this.disabled ? this.disabled : false;
  }

  ngOnChanges() {
    this.selected = this.defaultValue === this.trueValue;
  }

  toggleDiscount() {
    if (!this.disabled) {
      this.selected = !this.selected;
      if (this.selected) {
        this.pillToggled.emit({
          eventName: this.eventName,
          value: this.trueValue,
        });
      } else {
        this.pillToggled.emit({
          eventName: this.eventName,
          value: this.falseValue,
        });
      }
    }
  }

  onHelpContent() {
    this._modalService.open(this.helpContent.component);
  }
}
