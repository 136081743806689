import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import * as fromVehicles from '@core/store/selectors/vehicle.selector';
import * as fromPolicyholder from '@core/store/selectors/policyholder.selector';
import * as fromPrepopulatedInsured from '@core/store/selectors/prepopulated-insured.selector';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromMetadata from '@core/store/selectors/metadata.selector';
import * as fromPrivateLabel from '@core/store/selectors/private-label.selector';
import { TelematicsRecommendationRequest } from '@core/models/request/telematics-recommendation-request.model';
import { TelematicsEnrollmentUtils } from '@shared/utils/telematics-enrollment.utils';
import {
  ProductTypes,
  SmartMilesMethods,
  SmartMiles_ConnectedCar,
  TelematicsEnrollmentStatus,
  TelematicsProgram,
} from '@shared/constants/app-constants';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import {
  TelematicsEnrollment,
  TelematicsEnrollmentRecommendationResponse,
  TelematicsEnrollmentRequest,
} from '@core/models/telematics/telematics.model';
import { UserContext } from '@core/models/user-context/user-context.model';
import * as fromPartialQuote from '@core/store/selectors/partial-quote.selector';

export const getTelematicsEnrollmentsState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.telematicsEnrollments
);

export const getEnrollmentsLoaded = createSelector(
  getTelematicsEnrollmentsState,
  (state): boolean => state.loaded
);

export const getEnrollmentsLoading = createSelector(
  getTelematicsEnrollmentsState,
  (state): boolean => state.loading
);

export const getEnrollments = createSelector(
  getTelematicsEnrollmentsState,
  state => state.enrollment
);

export const getRecommendation = createSelector(
  getTelematicsEnrollmentsState,
  state => state.recommendation
);

export const hasSomeDeviceCompatibleVehicles = createSelector(
  fromVehicles.getAllVehicles,
  vehicles => vehicles.some(vehicle => vehicle.isSmartRideDeviceEligible)
);

export const getPreQualifiedVehicleInfo = createSelector(
  getTelematicsEnrollmentsState,
  state => state.preQualifiedInfo
);

export const buildGetTelematicsRecommendationRequest = () =>
  createSelector(
    fromUserContext.getUserContext,
    fromVehicles.getAllVehicles,
    fromMetadata.getStateSpecificFlag('defaultEstimatedAnnualMiles'),
    getEnrollments,
    (
      userContext: UserContext,
      vehicles: VehicleEntity[],
      defaultAnnualMiles: boolean,
      enrollment
    ) => {
      const unenrolledVehicles = [];
      for (const vehicle of vehicles) {
        if (
          userContext.prequalifiedPartnerScoreId &&
          enrollment &&
          enrollment.vehicleEnrollment &&
          enrollment.vehicleEnrollment.vehicles
        ) {
          const unenrolledVehiclesPrequalifiedPartnerScoreIdFlow =
            enrollment.vehicleEnrollment.vehicles.filter(
              veh =>
                veh.enrollmentStatus === TelematicsEnrollmentStatus.NOT_ENROLLED
            );

          unenrolledVehiclesPrequalifiedPartnerScoreIdFlow.forEach(veh => {
            if (veh.vehicleId === vehicle.vehicleId.toString()) {
              unenrolledVehicles.push(vehicle);
            }
          });
        }

        if (
          enrollment &&
          ((enrollment.vehicleEnrollment &&
            enrollment.vehicleEnrollment.vehicles &&
            enrollment.vehicleEnrollment.vehicles.some(
              enrollVehicle =>
                enrollVehicle.vehicleId === vehicle.vehicleId.toString()
            )) ||
            (enrollment?.informationalMessages &&
              enrollment.informationalMessages.some(
                message =>
                  message.warnings &&
                  message.warnings.some(
                    warning =>
                      warning.ruleId === 'PAR613' &&
                      warning.entity &&
                      warning.entity.fixedId === vehicle.vehicleId
                  )
              )))
        ) {
          // do not add to unenrolledVehicle and not preqaulified list
        } else {
          unenrolledVehicles.push(vehicle);
        }
      }
      const request: TelematicsRecommendationRequest = {
        qualifyingInformation: {
          vehicles: unenrolledVehicles.map(vehicle => {
            return {
              vehicleId: vehicle.vehicleId,
              estimatedAnnualMileage: !defaultAnnualMiles
                ? vehicle.annualMiles || '0'
                : '12001',
            };
          }),
        },
      };

      return request;
    }
  );

export const getPrivateLabelConnectedCarData = createSelector(
  fromPrivateLabel.getPrivateLabelConfiguration,
  fromMetadata.getConnectedCarCriteriaData,
  (privateLabelConfig, connectedCarData) => ({
    privateLabelConfig,
    connectedCarData,
  })
);

export const getStateSpecificFlagsData = createSelector(
  fromMetadata.getStateSpecificFlag('isDeviceOnlyState'),
  fromMetadata.getStateSpecificFlag('smartMiles'),
  fromMetadata.getStateSpecificFlag('srccIneligible'),
  fromMetadata.getStateSpecificFlag('selfReportedProgram'),
  (
    isDeviceOnlyState,
    isSmartMilesEligible,
    srccIneligible,
    isSelfReportedProgramEligible
  ) => ({
    isDeviceOnlyState,
    isSmartMilesEligible,
    srccIneligible,
    isSelfReportedProgramEligible,
  })
);

export const buildAddTelematicsEnrollmentRequest = (
  recommendationResponse: TelematicsEnrollmentRecommendationResponse
) =>
  createSelector(
    fromProducts.getProduct(ProductTypes.AUTO),
    fromPolicyholder.getPrimaryNamedInsured,
    getEnrollments,
    fromVehicles.getAllVehicles,
    getPrivateLabelConnectedCarData,
    getStateSpecificFlagsData,
    fromPrepopulatedInsured.getPrepopulatedInsuredId,
    fromPartialQuote.getPartialQuoteId,
    (
      product,
      policyHolder,
      enrollment,
      vehicles,
      config,
      stateSpecificFlags,
      prePopulatedInsuredId,
      partialQuoteId
    ) => {
      if (
        !stateSpecificFlags.srccIneligible &&
        recommendationResponse.recommendedProgram &&
        recommendationResponse.qualifyingInformation &&
        recommendationResponse.qualifyingInformation.vehicles.length > 0
      ) {
        const areAnyConnectedCarEligible =
          recommendationResponse.qualifyingInformation.vehicles.some(
            qualVehInf =>
              qualVehInf.availablePrograms.some(
                telematicsAvailProg =>
                  telematicsAvailProg.name ===
                    TelematicsProgram.CONNECTED_CAR &&
                  telematicsAvailProg.recommended == true
              )
          )
            ? true
            : false;
        return {
          quoteId: product.quoteId,
          enrollment: {
            vehicleEnrollment:
              TelematicsEnrollmentUtils.buildConnectedCarVehicleEnrollments(
                recommendationResponse,
                vehicles,
                stateSpecificFlags.isDeviceOnlyState,
                stateSpecificFlags.isSmartMilesEligible,
                areAnyConnectedCarEligible,
                enrollment,
                stateSpecificFlags.isSelfReportedProgramEligible
              ),
            mobileEnrollment: TelematicsEnrollmentUtils.buildMobileEnrollment(
              recommendationResponse,
              policyHolder,
              stateSpecificFlags.isDeviceOnlyState,
              areAnyConnectedCarEligible
            ),
            enrollmentConsents:
              TelematicsEnrollmentUtils.buildEnrollmentConsents(
                recommendationResponse,
                policyHolder
              ),
          },
          policyHolderId: policyHolder.policyHolderIds[ProductTypes.AUTO],
          prePopulatedInsuredId: prePopulatedInsuredId,
          partialQuoteId: partialQuoteId,
        } as TelematicsEnrollmentRequest;
      } else if (recommendationResponse.recommendedProgram) {
        return {
          quoteId: product.quoteId,
          enrollment: {
            vehicleEnrollment:
              TelematicsEnrollmentUtils.buildVehicleEnrollments(
                recommendationResponse,
                stateSpecificFlags.isDeviceOnlyState,
                stateSpecificFlags.isSmartMilesEligible,
                enrollment,
                stateSpecificFlags.isSelfReportedProgramEligible
              ),
            mobileEnrollment: TelematicsEnrollmentUtils.buildMobileEnrollment(
              recommendationResponse,
              policyHolder,
              stateSpecificFlags.isDeviceOnlyState
            ),
            enrollmentConsents:
              TelematicsEnrollmentUtils.buildEnrollmentConsents(
                recommendationResponse,
                policyHolder
              ),
          },
          policyHolderId: policyHolder.policyHolderIds[ProductTypes.AUTO],
          prePopulatedInsuredId: prePopulatedInsuredId,
          partialQuoteId: partialQuoteId,
        } as TelematicsEnrollmentRequest;
      } else {
        return {
          quoteId: product.quoteId,
          enrollment: {
            vehicleEnrollment:
              TelematicsEnrollmentUtils.buildPrequalifiedVehicleEnrollments(
                enrollment.informationalMessages
              ),
            mobileEnrollment: null,
          },
          policyHolderId: policyHolder.policyHolderIds[ProductTypes.AUTO],
          prePopulatedInsuredId: prePopulatedInsuredId,
          partialQuoteId: partialQuoteId,
        };
      }
    }
  );

export const buildUpdateTelematicsEnrollmentRequest = ({
  enrollmentVehicle,
  selectedProgram,
}) =>
  createSelector(
    fromProducts.getProduct(ProductTypes.AUTO),
    fromPolicyholder.getPrimaryNamedInsured,
    getEnrollments,
    fromVehicles.getAllVehicles,
    fromMetadata.getStateSpecificFlag('defaultEstimatedAnnualMiles'),
    fromMetadata.getStateSpecificFlag('isDeviceOnlyState'),
    fromPrepopulatedInsured.getPrepopulatedInsuredId,
    fromPartialQuote.getPartialQuoteId,
    (
      product,
      policyHolder,
      enrollment,
      vehicles,
      defaultAnnualMiles,
      isDeviceOnlyState,
      prePopulatedInsuredId,
      partialQuoteId
    ) => {
      let enrollmentDetails;
      if (selectedProgram === 'SmartRideMobile' && !isDeviceOnlyState) {
        enrollmentDetails = {
          mobileEnrollment: { enrollmentStatus: 'Enrolled' },
        };
      } else if (enrollmentVehicle) {
        const actualProgram =
          selectedProgram === TelematicsEnrollmentStatus.PRE_QUALIFIED
            ? TelematicsProgram.SMARTRIDE_INSTANT
            : selectedProgram !== TelematicsEnrollmentStatus.NOT_ENROLLED
            ? selectedProgram === SmartMiles_ConnectedCar
              ? TelematicsProgram.SMART_MILES
              : selectedProgram
            : null;
        const dataCollectionMethod =
          selectedProgram === SmartMiles_ConnectedCar
            ? SmartMilesMethods.ConnectedCar
            : selectedProgram === TelematicsProgram.SMART_MILES
            ? SmartMilesMethods.Device
            : null;
        const actualEnrollmentStatus =
          selectedProgram === TelematicsEnrollmentStatus.PRE_QUALIFIED
            ? TelematicsEnrollmentStatus.PRE_QUALIFIED
            : selectedProgram !== TelematicsEnrollmentStatus.NOT_ENROLLED
            ? TelematicsEnrollmentStatus.ENROLLED
            : TelematicsEnrollmentStatus.NOT_ENROLLED;
        enrollmentDetails = {
          vehicleEnrollment: {
            vehicles: [
              {
                vehicleId: enrollmentVehicle.vehicleId,
                enrollmentStatus: actualEnrollmentStatus,
                vehicleProgram: actualProgram,
                dataCollectionMethod: dataCollectionMethod,
                annualMileage: !defaultAnnualMiles
                  ? TelematicsEnrollmentUtils.getVehicleAnnualMiles(
                      vehicles,
                      enrollmentVehicle.vehicleId
                    )
                  : '12001',
              },
            ],
          },
        };
      }
      return {
        quoteId: product.quoteId,
        enrollmentId: enrollment.enrollmentId,
        enrollment: enrollmentDetails,
        policyHolderId: policyHolder.policyHolderIds[ProductTypes.AUTO],
        prePopulatedInsuredId: prePopulatedInsuredId,
        partialQuoteId: partialQuoteId,
      };
    }
  );

export const buildUpdateTelematicsEnrollmentRequestForPreQualifiedPartnerIdFlow =
  enrollmentVehicles =>
    createSelector(
      fromProducts.getProduct(ProductTypes.AUTO),
      getEnrollments,
      fromPolicyholder.getPrimaryNamedInsured,
      fromMetadata.getStateSpecificFlag('defaultEstimatedAnnualMiles'),
      fromMetadata.getStateSpecificFlag('isDeviceOnlyState'),
      fromPrepopulatedInsured.getPrepopulatedInsuredId,
      fromPartialQuote.getPartialQuoteId,
      (
        product,
        enrollment,
        policyHolder,
        defaultAnnualMiles,
        isDeviceOnlyState,
        prePopulatedInsuredId,
        partialQuoteId
      ) => {
        const vehicles = [];
        enrollmentVehicles.vehicles.forEach(enrollmentVehicle => {
          const recommendedVehicleProgram =
            enrollmentVehicle.availablePrograms.filter(
              avilableProgram => avilableProgram.recommended === true
            );
          vehicles.push({
            vehicleId: enrollmentVehicle.vehicleId,
            enrollmentStatus: TelematicsEnrollmentStatus.ENROLLED,
            vehicleProgram: recommendedVehicleProgram[0].name,
            dataCollectionMethod:
              recommendedVehicleProgram[0]?.dataCollectionMethod,
            annualMileage: enrollmentVehicle.estimatedAnnualMileage,
          });
        });
        const enrollmentDetails = {
          vehicleEnrollment: {
            vehicles: vehicles,
          },
        };

        return {
          quoteId: product.quoteId,
          enrollmentId: enrollment.enrollmentId,
          enrollment: enrollmentDetails,
          policyHolderId: policyHolder.policyHolderIds[ProductTypes.AUTO],
          prePopulatedInsuredId: prePopulatedInsuredId,
          partialQuoteId: partialQuoteId,
        };
      }
    );

export const buildRemoveTelematicsEnrollmentRequest = createSelector(
  fromProducts.getProduct(ProductTypes.AUTO),
  getEnrollments,
  (product, enrollment) => {
    return {
      quoteId: product.quoteId,
      enrollmentId: enrollment?.enrollmentId,
    };
  }
);

export const hasAnyEnrollment = createSelector(getEnrollments, enrollment => {
  if (!enrollment) {
    return false;
  }
  if (enrollment.mobileEnrollment) {
    return true;
  }
  if (
    enrollment.vehicleEnrollment &&
    enrollment.vehicleEnrollment.vehicles.length
  ) {
    return true;
  }
  return false;
});

// TODO: consolidate this with the other 2 vehicle enrollment selector methods
export const isVehicleEnrolled = (vehicleId: string) =>
  createSelector(getEnrollments, enrollment => {
    if (!enrollment) {
      return false;
    }
    const enrolledVehicles = enrollment.vehicleEnrollment.vehicles?.filter(
      vehicle =>
        vehicle.vehicleId === vehicleId.toString() &&
        vehicle.enrollmentStatus !== 'NotEnrolled'
    );
    if (
      enrollment.vehicleEnrollment &&
      enrollment.vehicleEnrollment.vehicles &&
      enrolledVehicles.length > 0
    ) {
      return true;
    }
    return false;
  });

export const isConnectedCarEligibleAndHasAnyEnrollment = createSelector(
  fromPrivateLabel.isConnectedCarEnabled,
  getEnrollments,
  (connectedCarEnabled, enrollment) => {
    if (!connectedCarEnabled || !enrollment) {
      return false;
    }
    if (enrollment.mobileEnrollment) {
      return true;
    }
    return enrollment.vehicleEnrollment &&
      enrollment.vehicleEnrollment.vehicles &&
      enrollment.vehicleEnrollment.vehicles.length
      ? !!enrollment.vehicleEnrollment.vehicles.find(
          vehEnrollment =>
            vehEnrollment.enrollmentStatus ===
            TelematicsEnrollmentStatus.ENROLLED
        )
      : false;
  }
);

export const buildUpdateMobileInfoTelematicsEnrollmentRequest = request =>
  createSelector(
    fromProducts.getProduct(ProductTypes.AUTO),
    getEnrollments,
    fromPolicyholder.getPrimaryNamedInsured,
    fromPrepopulatedInsured.getPrepopulatedInsuredId,
    fromPartialQuote.getPartialQuoteId,
    (
      product,
      enrollment,
      policyHolder,
      prePopulatedInsuredId,
      partialQuoteId
    ) => {
      let enrollmentDetails = {} as TelematicsEnrollment;
      if (enrollment.mobileEnrollment) {
        enrollmentDetails = request.isTextNotificationRequired
          ? {
              mobileEnrollment: {
                enrollmentStatus: 'Enrolled',
              },
              enrollmentConsents: {
                phoneNumber: request.phoneNumber,
                hasConsentToOneTimeTextActivation: true,
                hasConsentToOngoingTexts: true,
              },
            }
          : {
              mobileEnrollment: {
                enrollmentStatus: 'Enrolled',
              },
              enrollmentConsents: {
                phoneNumber: request.phoneNumber,
                hasConsentToOneTimeTextActivation: true,
                hasConsentToOngoingTexts: false,
              },
            };
      }
      if (enrollment.vehicleEnrollment) {
        enrollmentDetails = request.isTextNotificationRequired
          ? {
              vehicleEnrollment: {
                vehicles: [...enrollment.vehicleEnrollment.vehicles],
              },
              enrollmentConsents: {
                phoneNumber: request.phoneNumber,
                hasConsentToOneTimeTextActivation: true,
                hasConsentToOngoingTexts: true,
              },
            }
          : {
              vehicleEnrollment: {
                vehicles: [...enrollment.vehicleEnrollment.vehicles],
              },
              enrollmentConsents: {
                phoneNumber: request.phoneNumber,
                hasConsentToOneTimeTextActivation: true,
                hasConsentToOngoingTexts: false,
              },
            };
      }

      return enrollmentDetails
        ? {
            quoteId: product.quoteId,
            enrollmentId: enrollment.enrollmentId,
            enrollment: enrollmentDetails,
            policyHolderId: policyHolder.policyHolderIds[ProductTypes.AUTO],
            prePopulatedInsuredId: prePopulatedInsuredId,
            partialQuoteId: partialQuoteId,
          }
        : null;
    }
  );

export const isSmartMilesEnrolled = createSelector(
  fromProducts.getSelectedProducts,
  getEnrollments,
  (products, telematicsEnrollments) => {
    const isAutoProductSelected = products.some(
      product => product.id === ProductTypes.AUTO
    );

    let isSmartMilesAvailable = false;

    if (
      isAutoProductSelected &&
      telematicsEnrollments &&
      telematicsEnrollments.vehicleEnrollment &&
      telematicsEnrollments.vehicleEnrollment.vehicles.length
    ) {
      if (
        telematicsEnrollments.vehicleEnrollment.vehicles.find(vehicle => {
          return (
            vehicle.enrollmentStatus === TelematicsEnrollmentStatus.ENROLLED &&
            vehicle.vehicleProgram === TelematicsProgram.SMART_MILES
          );
        })
      ) {
        isSmartMilesAvailable = true;
      }
    }
    return isSmartMilesAvailable;
  }
);

export const getPolicyHolderPhoneNumber = createSelector(
  fromPolicyholder.getPrimaryNamedInsured,
  policyHolder => {
    return policyHolder.homeNumber;
  }
);
