import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';

export const getCondoState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.condo
);

export const getCondo = createSelector(getCondoState, state => state.condo);

export const hasCondoMortgage = createSelector(
  getCondo,
  condo => condo.hasMortgage
);
