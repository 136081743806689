import * as fromPrivateLabel from '@core/store/actions/private-label.action';

import { PrivateLabel } from '@core/models/private-label/private-label.model';

export interface PrivateLabelState {
  privateLabel: PrivateLabel;
  loaded: boolean;
  loading: boolean;
}

export const initialState: PrivateLabelState = {
  privateLabel: null,
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromPrivateLabel.PrivateLabelActions
): PrivateLabelState {
  switch (action.type) {
    case fromPrivateLabel.LOAD_PRIVATE_LABEL_CONFIGURATION: {
      return { ...state, loading: true };
    }

    case fromPrivateLabel.LOAD_PRIVATE_LABEL_CONFIGURATION_FAIL: {
      return { ...state, loading: false, loaded: false };
    }

    case fromPrivateLabel.LOAD_PRIVATE_LABEL_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        privateLabel: action.payload,
        loaded: true,
        loading: false,
      };
    }
  }
  return state;
}
