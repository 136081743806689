import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { PackageDetailDisplay } from '@core/models/display/quote-coverages/products/product-display.model';

@Component({
  selector: 'mq-coverages-discounts',
  templateUrl: './coverages-discounts.component.html',
  styleUrls: ['./coverages-discounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoveragesDiscountsComponent implements OnInit {
  @Input() name: string;
  @Input() discountRemoved: boolean;
  @Input() package: PackageDetailDisplay;

  constructor() {}

  ngOnInit(): void {}
}
