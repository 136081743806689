import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import { CondoModel } from '@core/models/condo/condo.model';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { Subject, Observable } from 'rxjs';
import { PropertyHelper } from '@core/services/helpers/property.helper';
import { ProtectiveDevice } from '@core/store/reducers/protective-devices.reducer';
import { CustomValidators } from '@core/validators/custom-validators';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { PolicyEffectiveDates } from '@core/models/policy/policy-effective-date.model';
import { HO_PROP_INFO_FORM_ENG } from '@shared/constants/app-constants';
import { DateUtils } from '@shared/utils/date.utils';
@Component({
  selector: 'mq-condo-property-info-form',
  templateUrl: './condo-property-info-form.component.html',
  styleUrls: ['./condo-property-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CondoPropertyInfoFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() condo: CondoModel;
  @Input() coveredLocation: CoveredLocation;
  @Input() allProtectiveDevices: ProtectiveDevice[];
  @Input() form: UntypedFormGroup;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() options: any;
  @Input() policyEffectiveDates: PolicyEffectiveDates;
  @Input() partialQuoteId: string;
  @Input() partialQuoteCoveredLocation: CoveredLocation;

  // pickList: any;
  currentYear: number = new Date().getFullYear();

  // needConstructionInfo$: Observable<boolean>;

  protectiveDevicesHelpText: string;
  protectiveDeviceslabelText: string;
  showWindMitigationDiscount: boolean;
  showWindowProtectionDiscount: boolean;
  showWindstormProtectiveDeviceCreditCTNY$: Observable<boolean>;
  showWindstormProtectiveDeviceForHO$: Observable<boolean>;
  showWindstormProtectiveDeviceCreditMD$: Observable<boolean>;
  showWindstormProtectiveDeviceCreditRI$: Observable<boolean>;
  windProtectiveDeviceNames: string[];
  // does below variable apply to Condo?  getOilTank in property.helper & references homeownersForm
  requiredOilTank: boolean;
  showAdditionalProtectionDevices: boolean;
  homeownerText = HO_PROP_INFO_FORM_ENG;
  unsubscribe = new Subject();
  // options$: Observable<any>;

  constructor(private _propertyHelper: PropertyHelper) {}

  ngOnInit(): void {
    this.setValidators();
    this.populateForm();
  }

  ngOnChanges(changes: any): void {
    if (changes.yearBuilt) {
      this.estimatedYearPurchased.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribe) {
      this.unsubscribe.next();
    }
  }

  private setValidators() {
    const picklist = control => {
      control.setValidators([
        Validators.required,
        CustomValidators.PicklistRequired,
      ]);
    };
    this.hasMortgage.setValidators([Validators.required]);
    this.estimatedYearPurchased.setValidators([
      Validators.required,
      this.validateMaxEstimatedYearPurchased.bind(this),
      // also validate after year built
    ]);
    this.yearBuilt.setValidators([
      Validators.required,
      Validators.max(new Date().getFullYear()),
    ]);
    this.form
      .get('roofCondition')
      .setValidators([Validators.required, CustomValidators.PicklistRequired]);
    this.form.get('roofCondition').updateValueAndValidity();
    this.form
      .get('constructionType')
      .setValidators([Validators.required, CustomValidators.PicklistRequired]);
    this.form.get('constructionType').updateValueAndValidity();
    this.form
      .get('unitsInBuilding')
      .setValidators([Validators.required, CustomValidators.PicklistRequired]);
    this.form.get('unitsInBuilding').updateValueAndValidity();
    this.form.get('numberOfHalfBathrooms').setValidators([]);
  }

  private populateForm() {
    if (this.coveredLocation && this.coveredLocation.datePurchased) {
      this.form.patchValue({
        hasMortgage: this.condo.hasMortgage,
        estimatedYearPurchased: this.condo.estimatedYearPurchased,
        yearBuilt:
          this.coveredLocation.constructionInfo.yearBuilt || 'Please Select',
        roofCondition: this.coveredLocation.roofCondition || 'Please Select',
        constructionType:
          this.coveredLocation.constructionInfo.constructionType ||
          'Please Select',
        unitsInBuilding:
          this.coveredLocation.unitsInBuilding.toString() || 'Please Select',
        fireAlarm: this.condo.fireAlarm || 'None',
        burglarAlarm: this.condo.burglarAlarm || 'None',
        sprinkler: this.condo.sprinkler || 'None',
      });
    }
    if (this.coveredLocation) {
      this.form.patchValue({
        numberOfHalfBathrooms:
          this.coveredLocation.constructionInfo?.numberOfHalfBathrooms > 0
            ? this.coveredLocation.constructionInfo.numberOfHalfBathrooms
            : '',
        numberOfFullBathrooms:
          this.coveredLocation.constructionInfo?.numberOfFullBathrooms > 0
            ? this.coveredLocation.constructionInfo.numberOfFullBathrooms
            : '',
      });
    }
    if (this.coveredLocation && this.partialQuoteId) {
      this.form.patchValue({
        estimatedYearPurchased:
          DateUtils.transformYearMonthDayDashToMonthYearSlash(
            this.partialQuoteCoveredLocation.datePurchased
          ),
        numberOfHalfBathrooms:
          this.coveredLocation.constructionInfo?.numberOfHalfBathrooms > 0
            ? this.coveredLocation.constructionInfo.numberOfHalfBathrooms
            : '',
        numberOfFullBathrooms:
          this.coveredLocation.constructionInfo?.numberOfFullBathrooms > 0
            ? this.coveredLocation.constructionInfo.numberOfFullBathrooms
            : '',
        yearBuilt:
          this.coveredLocation?.constructionInfo?.yearBuilt || 'Please Select',
        roofCondition: this.coveredLocation?.roofCondition || 'Please Select',
        constructionType:
          this.coveredLocation?.constructionInfo?.constructionType ||
          'Please Select',
      });
    }
  }

  validateEstimatedYearPurchased(field: AbstractControl) {
    if (!field.value || field.value === '') {
      return null;
    }
    const purchaseDateComponents = field.value.toString().split(/\//);
    if (purchaseDateComponents.length !== 2) {
      return { required: true };
    }
    const purchaseYear = parseInt(purchaseDateComponents[1], 10);
    const LOWEST_FOUR_DIGIT_NUMBER = 1000;
    if (isNaN(purchaseYear) || purchaseYear < LOWEST_FOUR_DIGIT_NUMBER) {
      return { required: true };
    }
    if (purchaseYear < this.yearBuilt.value) {
      return {
        beforeYearBuilt: this.yearBuilt.value,
      };
    }
    return null;
  }

  validateAfterYearBuilt(field: AbstractControl): any {
    if (!field.value) {
      return null;
    }
    const inputYear = parseInt(field.value, 10);
    if (isNaN(inputYear)) {
      return { invalid: true };
    }
    if (inputYear < this.yearBuilt.value) {
      return { beforeYearBuilt: this.yearBuilt.value };
    }
    return null;
  }

  private validateMaxEstimatedYearPurchased(field: AbstractControl) {
    return this._propertyHelper.validateMaxEstimatedYearPurchased(
      field,
      this.policyEffectiveDates.baseEffectiveDate
    );
  }

  get hasMortgage(): AbstractControl {
    return this.form.get('hasMortgage');
  }

  get estimatedYearPurchased(): AbstractControl {
    return this.form.get('estimatedYearPurchased');
  }

  get yearBuilt(): AbstractControl {
    return this.form.get('yearBuilt');
  }

  get numberOfHalfBathrooms(): AbstractControl {
    return this.form.get('numberOfHalfBathrooms');
  }

  get numberOfFullBathrooms(): AbstractControl {
    return this.form.get('numberOfFullBathrooms');
  }

  get roofCondition(): AbstractControl {
    return this.form.get('roofCondition');
  }

  get constructionType(): AbstractControl {
    return this.form.get('constructionType');
  }

  get unitsInBuilding(): AbstractControl {
    return this.form.get('unitsInBuilding');
  }
}
