import * as fromCoverages from '@core/store/actions/coverage.action';
import * as fromQuote from '@core/store/actions/quote.action';
import * as fromCoverageError from '@core/store/actions/coverage-error.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export type CoverageErrorState = EntityState<CoverageError>;

export interface CoverageError {
  ruleId: string;
  message: string;
  level: string;
}

export const adapter: EntityAdapter<CoverageError> = createEntityAdapter({
  selectId: error => (error.ruleId ? error.ruleId : error.message),
});

export function reducer(
  state = adapter.getInitialState({}),
  action:
    | fromCoverages.CoverageActions
    | fromQuote.QuoteActions
    | fromCoverageError.CoverageErrorActions
): CoverageErrorState {
  switch (action.type) {
    case fromCoverages.CoverageActionTypes.UPDATE_COVERAGE_FAIL: {
      return adapter.setAll(action.error, state);
    }
    case fromCoverageError.CoverageErrorActionTypes.ADD_COVERAGE_ERRORS: {
      return adapter.setAll(action.errors || [], state);
    }
    case fromCoverageError.CoverageErrorActionTypes.REMOVE_ALL_COVERAGE_ERRORS:
    case fromCoverages.CoverageActionTypes.UPDATE_COVERAGE_SUCCESS: {
      return adapter.removeAll(state);
    }
    default: {
      return state;
    }
  }
}
