import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import {
  CoverageEntity,
  CoverageOption,
  CoverageTerm,
} from '@core/models/entities/coverage.entity';
import {
  CoverageComparisonType,
  CoverageConfiguration,
} from '@core/models/private-label/private-label.model';
import { CoverageCodes } from '@shared/constants/app-constants';
import * as _ from 'lodash';

import { CoverageHelper } from '../helpers/coverage.helper';

@Injectable({ providedIn: 'root' })
export class PrivateLabelCoverageHelper {
  constructor(private _coverageHelper: CoverageHelper) {}

  createCoverageChanges(
    coverages: CoverageEntity[],
    configCoverages: CoverageConfiguration[]
  ): CoverageChange[] {
    let coverageChanges: CoverageChange[] = null;
    for (let i = 0; i < coverages.length; i++) {
      const coverage = coverages[i];
      if (!coverage.selectedValue || !coverage.editable) {
        continue;
      }

      let coverageConfig: CoverageConfiguration;
      let selectedCoverageOption: CoverageOption;
      let configCoverageOption: CoverageOption;

      // iterate over selected values to find corresponding private label coverage
      for (let idx = 0; idx < coverage.selectedValue.length; idx++) {
        const selected = coverage.selectedValue[idx];
        // If there are no terms, this is an Accept/Decline coverage
        if (!coverage.terms) {
          coverageConfig = _.find(configCoverages, {
            id: coverage.coverageId,
            productId: coverage.productId,
          });
          // when no coverage config found
          // or when config value is same as currently selected then skip
          if (!coverageConfig || coverageConfig.value === selected.value) {
            continue;
          }

          // create the change for this coverage
          coverageChanges = this.createPrivateLabelCoverageChanges(
            coverages,
            coverage,
            coverageConfig.id,
            coverageConfig.value,
            coverageChanges
          );
          continue;
        }

        // when selected code is 'selected' it is an optional coverage
        if (selected.code === 'selected') {
          // since theres no selected value, must iterate terms
          for (let y = 0; y < coverage.terms.length; y++) {
            const term = coverage.terms[y];
            // find the private label coverage for corresponding productId
            coverageConfig = _.find(configCoverages, {
              id: term.code,
              productId: coverage.productId,
            });
            // if config option isnt found skip
            if (!coverageConfig) {
              continue;
            }

            // if no options, this is an input value which should be mandatory
            // and never fall into this block
            if (term.options && term.options.length) {
              // use the found private label coverage to find the appropiate coverage term option
              configCoverageOption = _.find(term.options, {
                value: coverageConfig.value,
              });

              if (!configCoverageOption) {
                continue;
              }

              // create the change for this coverage
              coverageChanges = this.createPrivateLabelCoverageChanges(
                coverages,
                coverage,
                coverageConfig.id,
                configCoverageOption.value,
                coverageChanges
              );
            }
          }
        } else {
          // find the private label coverage
          coverageConfig = _.find(configCoverages, {
            id: selected.code,
            productId: coverage.productId,
          });
          // if config option isnt found skip
          if (!coverageConfig) {
            continue;
          }

          // find the corresponding coverage term
          const coverageTerm = _.find(coverage.terms, {
            code: selected.code,
          });

          // if no options, this is an input value
          if (!coverageTerm.options || !coverageTerm.options.length) {
            // if percent value method is set, then get the min value and create change
            if (coverageConfig.coveragePercentValue) {
              const defaultValue = coverageConfig.coveragePercentValue(
                +coverageConfig.value,
                +selected.value
              );

              if (
                !defaultValue ||
                coverageConfig.coverageComparison(defaultValue, +selected.value)
              ) {
                continue;
              }

              coverageChanges = this.createPrivateLabelCoverageChanges(
                coverages,
                coverage,
                coverageConfig.id,
                defaultValue + '',
                coverageChanges
              );
            } else {
              // if no percent value then it is a min/max config
              // currently this only applies to renters personal property amount
              if (
                coverageConfig.coverageComparison &&
                !coverageConfig.coverageComparison(
                  +coverageConfig.value,
                  +selected.value
                )
              ) {
                coverageChanges = this.createPrivateLabelCoverageChanges(
                  coverages,
                  coverage,
                  coverageConfig.id,
                  coverageConfig.value,
                  coverageChanges
                );
              }
            }
          } else {
            // use the found private label coverage to find the appropiate coverage term option
            configCoverageOption = _.find(coverageTerm.options, {
              value: coverageConfig.value,
            });
            // use the current selected value to find appropiate coverage term option
            selectedCoverageOption = _.find(coverageTerm.options, {
              value: selected.value,
            });

            // if comparison method returns false, selected coverage needs to be changed
            if (
              configCoverageOption &&
              selectedCoverageOption &&
              coverageConfig.coverageComparison &&
              !coverageConfig.coverageComparison(
                +configCoverageOption.priority,
                +selectedCoverageOption.priority
              )
            ) {
              coverageChanges = this.createPrivateLabelCoverageChanges(
                coverages,
                coverage,
                coverageConfig.id,
                configCoverageOption.value,
                coverageChanges
              );
            }
          }
        }
      }
    }
    return coverageChanges;
  }

  filterCoverageTermOptionsByConfiguredCoverages(
    coverages: CoverageEntity[],
    configCoverages: CoverageConfiguration[]
  ): CoverageEntity[] {
    if (!configCoverages) {
      return coverages;
    }
    let coverageMandatoryOption = null;

    return coverages.map(coverage => {
      if (!coverage.terms) {
        const configCoverage = _.find(configCoverages, {
          id: coverage.coverageId,
          productId: coverage.productId,
        }) as CoverageConfiguration;

        if (configCoverage) {
          return {
            ...coverage,
            mandatory: coverage.mandatory
              ? coverage.mandatory
              : configCoverage.mandatory,
          };
        }
        return coverage;
      }

      const terms = coverage.terms.map(term => {
        const configCoverage = _.find(configCoverages, {
          id: term.code,
          productId: coverage.productId,
        }) as CoverageConfiguration;

        if (!configCoverage || !term.options) {
          return term;
        }

        if (!coverageMandatoryOption) {
          coverageMandatoryOption = {
            mandatory: coverage.mandatory
              ? coverage.mandatory
              : configCoverage.mandatory,
          };
        }

        const coverageOption = _.find(term.options, {
          value: configCoverage.value,
        }) as CoverageOption;

        let options;
        if (coverageOption) {
          options = term.options.filter(option =>
            // If Default Coverage, do not filter lower any limits
            configCoverage.coverageComparison &&
            configCoverage.type === CoverageComparisonType.Default
              ? true
              : configCoverage.coverageComparison &&
                configCoverage.coverageComparison(
                  +coverageOption.priority,
                  +option.priority
                )
          );
        } else {
          options = term.options;
        }

        return { ...term, options };
      });
      const updatedCoverage = {
        ...coverage,
        ...coverageMandatoryOption,
        terms,
      };
      coverageMandatoryOption = null;

      return updatedCoverage;
    });
  }

  buildFormControl(
    _fb: UntypedFormBuilder,
    term: CoverageTerm,
    configCoverages: CoverageConfiguration[],
    coverage: CoverageEntity,
    formValue: string
  ): UntypedFormControl {
    const configCoverage = _.find(configCoverages, {
      id: term.code,
      productId: coverage.productId,
    });
    if (
      !configCoverage ||
      (!configCoverage.coveragePercentValue &&
        !configCoverage.coverageComparison)
    ) {
      return null;
    }

    switch (configCoverage.type) {
      case CoverageComparisonType.PercentageMinimum:
        // we know the formvalue is what it should be, so set min validation
        return _fb.control(formValue, Validators.min(+formValue));
      case CoverageComparisonType.Minimum:
        return this.buildMinValidationFormControl(
          _fb,
          configCoverage,
          formValue
        );
      // currently no max input val configured
      case CoverageComparisonType.Maximum:
      case CoverageComparisonType.Default:
      default:
        return null;
    }
  }

  private buildMinValidationFormControl(
    _fb: UntypedFormBuilder,
    configCoverage: CoverageConfiguration,
    currentValue: string
  ): UntypedFormControl {
    // Min should only apply on this cvg limit
    return !isNaN(+configCoverage.value) &&
      configCoverage.coverageComparison(+configCoverage.value, +currentValue) &&
      configCoverage.id === CoverageCodes.BasicCoverageLimit
      ? _fb.control(currentValue, Validators.min(+currentValue))
      : null;
  }

  private createPrivateLabelCoverageChanges(
    coverages: CoverageEntity[],
    coverage: CoverageEntity,
    coverageCode: string,
    value: string,
    coverageChanges: CoverageChange[]
  ): CoverageChange[] {
    const changes = this._coverageHelper.createCoverageChanges(
      coverages,
      coverage,
      coverageCode,
      value
    );
    return coverageChanges ? _.union(changes, coverageChanges) : changes;
  }
}
