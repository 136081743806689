<div class="modal-body">
  <br />
  <div class="smartride-instant-consent-border">
    <b>{{ smartrideInstantConsentText.SMARTRIDE_INSTANT_CONSENT_TITLE }}</b>
    <div class="row smartride-instant-consent-padding">
      {{ smartrideInstantConsentText.SMARTRIDE_INSTANT_CONSENT_BODY }}
    </div>
    <div class="row smartride-instant-consent-margin">
      <div class="col-9">
        <div>
          <label
            for="smartride-instant-consent-checkbox"
            class="checkbox-input-label"
            >Yes, I accept
            <input
              id="smartride-instant-consent-checkbox"
              type="checkbox"
              (click)="toggleSmartrideInstantConsent($event)"
              [checked]="smartrideInstantConsentChecked"
              aria-describedby="smartride-instant-consent-error"
            />
            <span class="checkmark-input"></span>
          </label>
        </div>
      </div>
      <div class="smartride-instant-consent-learn-more col-3">
        <a href="#" (click)="onLearnMoreClicked()" class="text-center">
          Learn more</a
        >
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="
      modal.close(updateSmartrideInstantConsent(smartrideInstantConsentChecked))
    "
  >
    Close
  </button>
</div>
