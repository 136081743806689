import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
  from,
  of,
  interval,
  Observable,
  throwError,
  combineLatest,
} from 'rxjs';
import {
  switchMap,
  take,
  catchError,
  tap,
  delayWhen,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { EuaApiService } from '@core/services/eua-api.service';
import { LoggingService, ProductsService, AgencyService } from '@core/services';
import { SECONDS_BEFORE_EXPIRE_TO_REFRESH } from '@shared/constants/app-constants';
import { DateUtils } from '@shared/utils/date.utils';
import { GenerateAccessToken, RefreshAccessToken } from '@core/store/actions';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { AccessTokenRequest } from '@core/models/auto/quotes/access-token-request.model';
import { ErrorHelper } from '@core/services/helpers/error.helper';

// For safety's sake (eg if EUA does not include the 'expires_in' field), wait at least so long before refreshing.
const MINIMUM_EUA_RECALL_TIME = 5000;

@Injectable()
export class EuaEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _euaApiService: EuaApiService,
    private _loggingService: LoggingService,
    private _productsService: ProductsService,
    private _agencyService: AgencyService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  generateAccessToken$ = this._actions$.pipe(
    ofType(fromActions.TokenActionTypes.GENERATE_ACCESS_TOKEN),
    switchMap((action: GenerateAccessToken) =>
      combineLatest([
        this._store.select(fromSelectors.getPolicyholder()).pipe(
          map(policyHolder => {
            return { ...policyHolder, productId: action.payload };
          }),
          take(1)
        ),
        this._agencyService.getAgentJwt(),
      ])
    ),
    switchMap(([policyholder, agentJwt]) => {
      if (agentJwt.jwt) {
        this._loggingService.log(
          'generateAccessToken$(): Agent JWT is present. Skipping creating new access tokens',
          {}
        );
        return from([]);
      } else {
        return this._store
          .select(
            fromSelectors.buildEuaRequest(policyholder as PolicyholderEntity)
          )
          .pipe(take(1));
      }
    }),
    tap(request => this._loggingService.log('eua Request', request)),
    mergeMap(request => this._generateAccessToken(request))
  );

  @Effect()
  generateAccessTokenForRequest$ = this._actions$.pipe(
    ofType(fromActions.TokenActionTypes.GENERATE_ACCESS_TOKEN_FOR_REQUEST),
    map((action: fromActions.GenerateAccessTokenForRequest) => action.payload),
    tap(request => this._loggingService.log('eua Request', request)),
    mergeMap(request => this._generateAccessToken(request))
  );

  @Effect()
  refreshAccessToken$ = this._actions$.pipe(
    ofType(fromActions.TokenActionTypes.REFRESH_ACCESS_TOKEN),
    switchMap((action: RefreshAccessToken) =>
      this._store
        .select(fromSelectors.getTokenByProduct(action.payload))
        .pipe(take(1))
    ),
    delayWhen(token => {
      const milisecondsInSecond = 1000;
      return interval(
        (parseInt(token.expiresIn, 10) - SECONDS_BEFORE_EXPIRE_TO_REFRESH) *
          milisecondsInSecond || MINIMUM_EUA_RECALL_TIME
      );
    }),
    tap(() => {
      this._loggingService.log('Refreshing Access Token', {});
    }),
    switchMap(token => of(new fromActions.GenerateAccessToken(token.productId)))
  );

  @Effect()
  setAccessToken$ = this._actions$.pipe(
    ofType(fromActions.TokenActionTypes.SET_ACCESS_TOKEN),
    map(
      (action: fromActions.SetAccessToken) =>
        new fromActions.RefreshAccessToken(action.payload.productId)
    )
  );

  @Effect()
  refreshAllAccessTokens$ = this._actions$.pipe(
    ofType(fromActions.TokenActionTypes.REFRESH_ALL_ACCESS_TOKENS),
    withLatestFrom(this._productsService.getSelectedProducts()),
    switchMap(([action, products]) => {
      return from(
        products.map(product => {
          return new GenerateAccessToken(product.id);
        })
      );
    })
  );

  private _generateAccessToken(
    request: AccessTokenRequest
  ): Observable<Action> {
    return this._euaApiService.createAccessToken(request).pipe(
      take(1),
      tap(response => this._loggingService.log('eua Response', response)),
      switchMap(response => {
        if (!response.access_token) {
          this._loggingService.log(
            'eua Error',
            response.error || 'Failed to aquire access token'
          );
          return throwError(response.error || 'Failed to acquire access token');
        }
        return from([
          new fromActions.GenerateAccessTokenSuccess({
            accessToken: response.access_token,
            expiresIn: response.expires_in,
            productId: request.productId,
            expiryDate: DateUtils.expiryDateFromInterval(response.expires_in),
          }),
          new fromActions.RefreshAccessToken(request.productId),
        ]);
      }),
      catchError(error => {
        const safeError = this._errorHelper.sanitizeError(error);
        this._loggingService.logToSplunk({
          event: 'GENERATE_ACCESS_TOKEN_FAILED',
          message: safeError,
        });
        return of(new fromActions.FailAllProducts(ErrorHelper.ERR_GENERAL_EUA));
      })
    );
  }
}
