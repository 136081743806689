import { RouterEffects } from './router.effect';
import { AddressEffects } from './address.effect';
import { MetadataEffects } from './metadata.effect';
import { ProductsEffects } from './products.effect';
import { RentersEffects } from './renters.effect';
import { BillingEffects } from './billing.effect';
import { PlaidEffects } from './plaid.effect';
import { PrivateLabelEffect } from './private-label.effect';
import { CmaEffects } from './cma.effect';
import { QuoteLetterEffects } from './quote-letter.effect';
import { CompRaterNewsEffects } from './comp-rater-news.effect';
import { DriverEffects } from './driver.effect';
import { EuaEffects } from './eua.effect';
import { PolicyholderEffects } from './policyholder.effect';
import { QuoteEffects } from './quote.effect';
import { VehicleEffects } from './vehicle.effect';
import { CurrentCarrierEffects } from './current-carrier.effect';
import { AdditionalInterestEffects } from './additional-interest.effect';
import { ModifiersEffects } from './modifiers.effect';
import { RetrieveEffects } from './retrieve.effect';
import { SearchEffects } from './search.effect';
import { PolicyIssueEffects } from './policy-issue.effect';
import { CoveredLocationEffects } from './covered-location.effect';
import { MortgageEffects } from './mortgage.effect';
import { DriverVehiclePrefillEffects } from './driver-vehicle-prefill.effect';
import { DocumentEffects } from './documents.effect';
import { EscrowEffects } from './escrow.effect';
import { RenovationEffects } from './renovation.effects';
import { DriverVehicleAssignmentEffects } from './driver-vehicle-assignment.effect';
import { PageAlertEffect } from './page-alert.effect';
import { ProtectiveDevicesEffects } from './protective-devices.effect';
import { BusinessLoggingEffect } from './business-logging.effect';
import { LocationExposureEffects } from './location-exposure.effect';
import { UnderlyingPolicyEffects } from './underlying-policy.effect';
import { VehicleExposureEffects } from './vehicle-exposure.effect';
import { HouseholdMemberEffects } from './household-member.effect';
import { AgencyEffects } from './agency.effect';
import { TelematicsEnrollmentsEffects } from './telematics-enrollments.effect';
import { NonStandardBridgeEffects } from './non-standard-bridge.effect';
import { PreBindDocumentEffects } from './pre-bind-documents.effect';
import { PrepopulatedInsuredEffects } from './prepopulated-insured.effect';
import { DigitalIdCardsTextEffects } from './digital-id-cards-text.effect';
import { AccountEffect } from './account.effect';
import { PolicyNumberEffects } from './policy-number.effect';
import { SessionEffects } from './session.effect';
import { PartialQuoteEffects } from './partial-quote.effect';

export const effects: any[] = [
  AccountEffect,
  AdditionalInterestEffects,
  AddressEffects,
  AgencyEffects,
  AgencyEffects,
  BillingEffects,
  BusinessLoggingEffect,
  CmaEffects,
  CompRaterNewsEffects,
  CoveredLocationEffects,
  CurrentCarrierEffects,
  DigitalIdCardsTextEffects,
  DocumentEffects,
  DriverEffects,
  DriverVehicleAssignmentEffects,
  DriverVehiclePrefillEffects,
  EscrowEffects,
  EuaEffects,
  HouseholdMemberEffects,
  LocationExposureEffects,
  MetadataEffects,
  ModifiersEffects,
  MortgageEffects,
  NonStandardBridgeEffects,
  PageAlertEffect,
  PlaidEffects,
  PolicyholderEffects,
  PolicyIssueEffects,
  PolicyNumberEffects,
  PreBindDocumentEffects,
  PrepopulatedInsuredEffects,
  PrivateLabelEffect,
  ProductsEffects,
  ProtectiveDevicesEffects,
  QuoteEffects,
  QuoteLetterEffects,
  RenovationEffects,
  RentersEffects,
  RetrieveEffects,
  RouterEffects,
  SessionEffects,
  SearchEffects,
  TelematicsEnrollmentsEffects,
  UnderlyingPolicyEffects,
  VehicleEffects,
  VehicleExposureEffects,
  PartialQuoteEffects,
];

export * from './router.effect';
export * from './address.effect';
export * from './metadata.effect';
export * from './products.effect';
export * from './renters.effect';
export * from './billing.effect';
export * from './private-label.effect';
export * from './cma.effect';
export * from './quote-letter.effect';
export * from './comp-rater-news.effect';
export * from './driver.effect';
export * from './policyholder.effect';
export * from './quote.effect';
export * from './vehicle.effect';
export * from './current-carrier.effect';
export * from './eua.effect';
export * from './additional-interest.effect';
export * from './modifiers.effect';
export * from './retrieve.effect';
export * from './search.effect';
export * from './policy-issue.effect';
export * from './covered-location.effect';
export * from './mortgage.effect';
export * from './driver-vehicle-prefill.effect';
export * from './documents.effect';
export * from './escrow.effect';
export * from './renovation.effects';
export * from './driver-vehicle-assignment.effect';
export * from './protective-devices.effect';
export * from './page-alert.effect';
export * from './business-logging.effect';
export * from './location-exposure.effect';
export * from './underlying-policy.effect';
export * from './vehicle-exposure.effect';
export * from './household-member.effect';
export * from './agency.effect';
export * from './telematics-enrollments.effect';
export * from './non-standard-bridge.effect';
export * from './pre-bind-documents.effect';
export * from './agency.effect';
export * from './prepopulated-insured.effect';
export * from './digital-id-cards-text.effect';
export * from './account.effect';
export * from './policy-number.effect';
export * from './session.effect';
export * from './partial-quote.effect';
