import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RentersDao } from '@core/dao';
import { RentersAdapter } from '@core/adapters/renters.adapter';

import { RentersModel } from '@core/models/renters/renters.model';
import { SeparateUnit } from '@core/models/renters/separateunits.model';
import { StructuralMaterial } from '@core/models/renters/structuralmaterials.model';

@Injectable()
export class RentersService {
  constructor(
    private _rentersDao: RentersDao,
    private _rentersAdapter: RentersAdapter
  ) {}

  getRenters(): Observable<RentersModel> {
    return this._rentersDao.getRenters();
  }

  getRentersLoading(): Observable<boolean> {
    return this._rentersDao.getRentersLoading();
  }

  getRentersLoaded(): Observable<boolean> {
    return this._rentersDao.getRentersLoaded();
  }

  updateRenters(renters: RentersModel): void {
    this._rentersDao.updateRenters(renters);
  }

  getSeparateUnits(): Observable<SeparateUnit[]> {
    return this._rentersAdapter.getNumOfSeparateUnits();
  }

  loadRentersSeparateUnits(): void {
    this._rentersDao.loadRentersSeparateUnits();
  }

  getSeparateUnitsData(): Observable<SeparateUnit[]> {
    return this._rentersDao.getSeparateUnitsData();
  }

  getSeparateUnitsLoaded(): Observable<boolean> {
    return this._rentersDao.getSeparateUnitsLoaded();
  }

  getSeparateUnitsLoading(): Observable<boolean> {
    return this._rentersDao.getSeparateUnitsLoading();
  }

  getStructuralMaterials(): Observable<StructuralMaterial[]> {
    return this._rentersAdapter.getStructuralMaterials();
  }

  loadRentersStructuralMaterials(): void {
    this._rentersDao.loadRentersStructuralMaterials();
  }

  getStructuralMaterialsData(): Observable<StructuralMaterial[]> {
    return this._rentersDao.getStructuralMaterialsData();
  }

  getStructuralMaterialsLoaded(): Observable<boolean> {
    return this._rentersDao.getStructuralMaterialsLoaded();
  }

  getStructuralMaterialsLoading(): Observable<boolean> {
    return this._rentersDao.getStructuralMaterialsLoading();
  }
}
