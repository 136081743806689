import { Injectable } from '@angular/core';
import { DiscountEntity } from '@core/models/entities/discount.entity';

@Injectable({
  providedIn: 'root',
})
export class DiscountNameHelper {
  readonly NAME_REPLACEMENTS = [
    ['AgeofConstruction', 'Age of Construction'],
    ['HomeAndCarDiscount', 'Home and Car'],
    ['ClaimFree', 'Claim Free Discount'],
    ['NationwideAssociateDiscount', 'Associate Discount'],
    ['ProtectiveDevice', 'Protective Device Discount'],
    ['SmartHomeDiscount', 'Smart Home Discount'],
    ['HomeRenovationDiscount', 'Home Renovation Discount'],
    ['WindMitigation', 'Windstorm Mitigation Credit'],
    ['FortifiedHome', 'Fortified Home Discount'],
    ['HomeFinancialDiscount', 'Home Financial Discount'],
    ['MultiLineDiscount', 'Multi Line Discount'],
    ['WindStormProtectiveDevice', 'Windstorm Protective Device Credit'],
    ['HomePurchaseDiscount', 'Home Purchase Discount'],
    ['FarmBureau', 'Farm Bureau'],
    ['IntraAgency', 'Intra Agency'],
    ['GatedCommunity', 'Gated Community'],
    ['PriorInsurance', 'Prior Insurance'],
    ['MaritalStatus', 'Marital Status'],
    ['MembershipDiscount', 'Membership Discount'],
  ];

  helpDiscountName(discount: DiscountEntity): DiscountEntity {
    for (const replacement of this.NAME_REPLACEMENTS) {
      if (discount.description === replacement[0]) {
        return {
          ...discount,
          description: replacement[1],
        };
      }
    }
    return discount;
  }
}
