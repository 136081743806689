import { Action } from '@ngrx/store';

export const enum homeownerPropertyInfoActionsTypes {
  LOAD_PICKLIST = '[homeowner propertyInfo] Load homeowners pick list values',
}

export class LoadPickList implements Action {
  readonly type = homeownerPropertyInfoActionsTypes.LOAD_PICKLIST;
  constructor(public payload: any) {}
}

export type HomeownerPropertyInfoActions = LoadPickList;
