import { Component, Input } from '@angular/core';

@Component({
  selector: 'inline-help',
  templateUrl: './inline-help.component.html',
  styleUrls: ['./inline-help.component.scss'],
})
export class InlineHelpComponent {
  openHelpText = false;
  @Input() fieldId;
  @Input() labelInnerHTML;
  @Input() labelHTMLClass;
  @Input() helpTextInnerHTML;
}
