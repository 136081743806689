import * as fromSession from '@core/store/actions/session.action';
import { PersonEntity } from '@core/models/entities/person.entity';
import { SessionLog } from '@core/models/session-log.model';

export interface SessionState {
  sessionId: string;
  quoteState: string;
  person: PersonEntity;
  firstName: string;
  lastName: string;
  email: string;
  requestedProducts: string[];
  referrer: string;
  hasSentPetEmail: boolean;
  isProgressTrackerEligible: boolean;
  progressTrackerFlowName: string;
  ignoreProductDisablement: boolean;
  ignoreRiskFilter: boolean;
  ignoreAutoUWRules: boolean;
  pcEnv: string;
  billingEnv: string;
  sessionLogs: SessionLog[];
  ignoreCurrentCarrierValidation: boolean;
  isClaimsReviewsLoadingSpinnerEligible: boolean;
  isClaimsReviewsLoadingSpinnerActive: boolean;
  claimsReviewsLoadingSpinnerUpperMessage: string;
  claimsReviewsLoadingSpinnerLowerMessage: string;
  claimsReviewsLoadingSpinnerReviewerName: string;
}

export const initialState: SessionState = {
  sessionId: null,
  quoteState: null,
  person: null,
  firstName: null,
  lastName: null,
  email: null,
  requestedProducts: [],
  referrer: null,
  hasSentPetEmail: false,
  isProgressTrackerEligible: null,
  progressTrackerFlowName: null,
  ignoreProductDisablement: false,
  ignoreRiskFilter: false,
  ignoreAutoUWRules: false,
  pcEnv: null,
  billingEnv: null,
  sessionLogs: [],
  ignoreCurrentCarrierValidation: false,
  isClaimsReviewsLoadingSpinnerEligible: null,
  isClaimsReviewsLoadingSpinnerActive: null,
  claimsReviewsLoadingSpinnerUpperMessage: null,
  claimsReviewsLoadingSpinnerLowerMessage: null,
  claimsReviewsLoadingSpinnerReviewerName: null,
};

export function reducer(
  state = initialState,
  action: fromSession.SessionActions
): SessionState {
  switch (action.type) {
    case fromSession.SessionActionTypes.INITIALIZE_SESSION: {
      const sessionId = action.payload;
      return { ...state, sessionId };
    }
    case fromSession.SessionActionTypes.INITIALIZE_STATE: {
      const quoteState = action.payload;
      return { ...state, quoteState };
    }
    case fromSession.SessionActionTypes.INITIALIZE_SESSION_NAME: {
      const person = action.payload;

      return {
        ...state,
        person,
      };
    }
    case fromSession.SessionActionTypes.INITIALIZE_SESSION_FIRSTNAME: {
      const firstName = action.payload;
      return { ...state, firstName };
    }
    case fromSession.SessionActionTypes.INITIALIZE_SESSION_LASTNAME: {
      const lastName = action.payload;
      return { ...state, lastName };
    }
    case fromSession.SessionActionTypes.INITIALIZE_SESSION_EMAIL: {
      const email = action.payload;
      return { ...state, email };
    }
    case fromSession.SessionActionTypes.INITIALIZE_REQUESTED_PRODUCTS: {
      const requestedProducts = action.payload;
      return { ...state, requestedProducts };
    }
    case fromSession.SessionActionTypes.INITIALIZE_SESSION_REFERRER: {
      const referrer = action.payload;
      return { ...state, referrer };
    }
    case fromSession.SessionActionTypes.INITIALIZE_HAS_SENT_PET_EMAIL: {
      const hasSentPetEmail = action.payload;
      return { ...state, hasSentPetEmail };
    }
    case fromSession.SessionActionTypes
      .INITIALIZE_SESSION_PROGRESS_TRACKER_ELIGIBILITY: {
      const isProgressTrackerEligible = action.payload;
      return { ...state, isProgressTrackerEligible };
    }
    case fromSession.SessionActionTypes
      .INITIALIZE_SESSION_PROGRESS_TRACKER_FLOW_NAME: {
      const progressTrackerFlowName = action.payload;
      return { ...state, progressTrackerFlowName };
    }
    case fromSession.SessionActionTypes
      .INITIALIZE_SESSION_IGNORE_PRODUCT_DISABLEMENT: {
      const ignoreProductDisablement = action.payload;
      return { ...state, ignoreProductDisablement };
    }
    case fromSession.SessionActionTypes
      .INITIALIZE_SESSION_IGNORE_CURRENT_CARRIER_VALIADTION: {
      const ignoreCurrentCarrierValidation = action.payload;
      return { ...state, ignoreCurrentCarrierValidation };
    }
    case fromSession.SessionActionTypes.INITIALIZE_SESSION_IGNORE_RISK_FILTER: {
      const ignoreRiskFilter = action.payload;
      return { ...state, ignoreRiskFilter };
    }
    case fromSession.SessionActionTypes
      .INITIALIZE_SESSION_IGNORE_AUTO_UW_RULES: {
      const ignoreAutoUWRules = action.payload;
      return { ...state, ignoreAutoUWRules };
    }
    case fromSession.SessionActionTypes.INITIALIZE_SESSION_PC_ENV: {
      const pcEnv = action.payload;
      return { ...state, pcEnv };
    }
    case fromSession.SessionActionTypes.INITIALIZE_SESSION_BILLING_ENV: {
      const billingEnv = action.payload;
      return { ...state, billingEnv };
    }
    case fromSession.SessionActionTypes.UPDATE_SESSION_LOG_ENTRY: {
      const sessionLogs = [...state.sessionLogs, action.payload];

      return { ...state, sessionLogs: sessionLogs };
    }

    case fromSession.SessionActionTypes.CLEAR_SESSION_LOG_ENTRY: {
      return { ...state, sessionLogs: [] };
    }
    case fromSession.SessionActionTypes
      .INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_ELIGIBILITY: {
      const isClaimsReviewsLoadingSpinnerEligible = action.payload;
      return { ...state, isClaimsReviewsLoadingSpinnerEligible };
    }
    case fromSession.SessionActionTypes
      .INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_ACTIVE: {
      const isClaimsReviewsLoadingSpinnerActive = action.payload;
      return { ...state, isClaimsReviewsLoadingSpinnerActive };
    }
    case fromSession.SessionActionTypes
      .INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_UPPER_MESSAGE: {
      const claimsReviewsLoadingSpinnerUpperMessage = action.payload;
      return { ...state, claimsReviewsLoadingSpinnerUpperMessage };
    }
    case fromSession.SessionActionTypes
      .INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_LOWER_MESSAGE: {
      const claimsReviewsLoadingSpinnerLowerMessage = action.payload;
      return { ...state, claimsReviewsLoadingSpinnerLowerMessage };
    }
    case fromSession.SessionActionTypes
      .INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_REVIEWER_NAME: {
      const claimsReviewsLoadingSpinnerReviewerName = action.payload;
      return { ...state, claimsReviewsLoadingSpinnerReviewerName };
    }
  }
  return state;
}

export const getSessionState = (state: SessionState) => state;
