<form [formGroup]="agencyForm" (ngSubmit)="onSubmit(agencyForm)">
  <div class="nw-container nw-inner-bun">
    <div class="row">
      <h3 class="col-sm-auto">Agency Prefill Page</h3>
    </div>

    <div class="row">
      <h4 class="col-sm-auto">
        Please fill the mandatory information to proceed...
      </h4>
    </div>

    <div class="row mb-4 g-2">
      <div class="mb-3 col-sm-7 col-lg-4">
        <label for="agentUserId">Agent User Id</label>
        <input
          id="agentUserId"
          type="text"
          class="form-control"
          aria-describedby="agentUserId-error"
          formControlName="userId"
          required
          autofocus
        />
        <div *ngIf="userId.invalid && (userId.dirty || userId.touched)">
          <div
            *ngIf="userId.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="agentUserId-error">Agent UserId is required</span>
          </div>
        </div>
      </div>

      <div class="mb-3 col-sm-7 col-lg-4">
        <label for="firstName">Agent First Name</label>
        <input
          id="firstName"
          type="text"
          class="form-control"
          aria-describedby="firstName-error"
          formControlName="firstName"
          required
          autofocus
        />
        <div
          *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
        >
          <div
            *ngIf="firstName.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="agentFirstName-error">Agent First Name is required</span>
          </div>
          <div
            *ngIf="firstName.errors.stringFormat && !firstName.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="agentFirstName-error">Field is invalid.</span>
          </div>
        </div>
      </div>

      <div class="mb-3 col-sm-7 col-lg-4">
        <label for="lastName">Agent Last Name</label>
        <input
          id="lastName"
          type="text"
          class="form-control"
          aria-describedby="lastName-error"
          formControlName="lastName"
        />
        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
          <div
            *ngIf="lastName.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="lastName-error">Agent Last Name is required</span>
          </div>
          <div
            *ngIf="lastName.errors.stringFormat && !lastName.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="agentLastName-error">Field is invalid.</span>
          </div>
        </div>
      </div>

      <div class="mb-3 col-sm-7 col-lg-4">
        <label for="producerCode">Agent Producer Code</label>
        <input
          id="producerCode"
          type="text"
          class="form-control"
          aria-describedby="producerCode-error"
          formControlName="producerCode"
        />
        <div
          *ngIf="
            producerCode.invalid && (producerCode.dirty || producerCode.touched)
          "
        >
          <div
            *ngIf="producerCode.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="producerCode-error">Agent Producer Code is required</span>
          </div>
          <div
            *ngIf="
              producerCode.errors.stringFormat && !producerCode.errors.required
            "
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="producerCode-error">Field is invalid.</span>
          </div>
        </div>
      </div>

      <div class="mb-3 col-sm-7 col-lg-4">
        <label for="agencyCode"
          >Agency Code
          <span>(optional)</span>
        </label>
        <input
          id="agencyCode"
          type="text"
          class="form-control"
          aria-describedby="agencyCode-error"
          formControlName="agencyCode"
        />
        <div
          *ngIf="agencyCode.invalid && (agencyCode.dirty || agencyCode.touched)"
        >
          <div
            *ngIf="agencyCode.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="agency-code-error">Agency Code is required</span>
          </div>
          <div
            *ngIf="agencyCode.stringFormat && !agencyCode.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="agencyCode-error">Field is invalid.</span>
          </div>
        </div>
      </div>

      <div class="mb-3 col-sm-7 col-lg-4">
        <label for="email">Agent Email</label>
        <input
          id="email"
          type="email"
          class="form-control"
          aria-describedby="email-error"
          formControlName="email"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$"
          placeholder="agent@address.com"
          required
        />
        <div *ngIf="email.invalid && (email.dirty || email.touched)">
          <div
            *ngIf="email.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="email-error">Agent Email is required</span>
          </div>
          <div
            *ngIf="!email.errors.required && email.errors.pattern"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="email-error">Invalid email address.</span>
          </div>
        </div>
      </div>
      <div class="mb-3 col-3 col-md-2">
        <label>Agency Channel</label>
        <select
          class="form-control form-select"
          formControlName="agencyChannel"
        >
          <option
            *ngFor="let agencyChannel of agencyChannels"
            value="{{ agencyChannel }}"
          >
            {{ agencyChannel }}
          </option>
        </select>
        <div
          *ngIf="
            agencyChannel.invalid &&
            (agencyChannel.dirty || agencyChannel.touched)
          "
        >
          <div
            *ngIf="agencyChannel.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="agencyChannel-error">Agency channel is required</span>
          </div>
        </div>
      </div>
      <div class="mb-3 col-3 col-md-2">
        <label for="state">Customer State</label>
        <select
          id="state"
          class="form-control form-select"
          formControlName="quoteState"
          aria-describedby="state-error"
        >
          <option *ngFor="let state of activeStates" value="{{ state }}">
            {{ state }}
          </option>
        </select>
        <div
          *ngIf="quoteState.invalid && (quoteState.dirty || quoteState.touched)"
        >
          <div
            *ngIf="quoteState.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="state-error">Customer State is required</span>
          </div>
        </div>
      </div>
      <div class="mb-3 col-3 col-md-2">
        <label for="agentState">Agent State</label>
        <select
          class="form-control form-select"
          formControlName="state"
          id="agentState"
        >
          <option *ngFor="let state of states" value="{{ state }}">
            {{ state }}
          </option>
        </select>
        <div *ngIf="state.invalid && (state.dirty || state.touched)">
          <div
            *ngIf="state.errors.required"
            class="alert alert-danger"
            role="alert"
          >
            <a class="icon error"></a>
            <span id="state-error">Agent State is required</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--form nw-inner-bun -->

  <nw-navigation-buttons
    [forwardOnly]="true"
    [isAppLoading]="isAppLoading"
    submitButtonText="Continue"
  ></nw-navigation-buttons>
</form>
