<form [formGroup]="form">
  <div class="row">
    <div
      class="protective-devices col-sm-12"
      id="inline-help-{{ protectiveDevicesFieldId }}"
      [style.color]="'black'"
    >
      <inline-help
        [labelInnerHTML]="protectiveDeviceslabelText"
        [helpTextInnerHTML]="protectiveDevicesHelpText"
        [fieldId]="protectiveDevicesFieldId"
      ></inline-help>
    </div>
    <div
      class="form-group col-sm-4 col-lg-3"
      *ngFor="let control of form.controls | keyvalue; index as i"
    >
      <label for="prodev-{{ control.key }}">
        {{ control.key | protectiveDevicesType }}
      </label>
      <select
        id="prodev-{{ control.key }}"
        [formControlName]="control.key"
        attr.formcontrolname="control.key"
        class="form-control form-select"
      >
        <option value="None">None</option>
        <option
          *ngFor="let option of getProtectiveDeviceOptions(control.key)"
          value="{{ option }}"
        >
          {{ option | protectiveDevicesType }}
        </option>
      </select>
    </div>
  </div>
</form>
