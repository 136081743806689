<div
  class="billing-plan-banner row"
  *ngIf="
    isBillingInfoAvailable &&
    !isPayingThroughEscrow &&
    !isPayingThroughEscrowFromRetrieve
  "
>
  <div class="billing-plan-text col-11">
    <strong>Billing Plan: </strong>&nbsp; {{ paymentPlan }}
  </div>

  <div class="edit-button col-1" *ngIf="isEditBillingPlanRequired">
    <button type="button" class="btn" (click)="editBillingPlanModal($event)">
      Edit
    </button>
  </div>
</div>
<div
  class="billing-plan-banner row"
  *ngIf="
    isBillingInfoAvailable &&
    (isAutoSelected || isPowersportsSelected) &&
    (isPayingThroughEscrow || isPayingThroughEscrowFromRetrieve)
  "
>
  <div class="billing-plan-text col-11">
    <strong>Billing Plan: </strong>&nbsp;Third Party Escrow &
    {{ paymentPlan }}
  </div>
  <div class="edit-button col-1" *ngIf="isEditBillingPlanRequired">
    <button type="button" class="btn" (click)="editBillingPlanModal($event)">
      Edit
    </button>
  </div>
</div>
<div
  class="billing-plan-banner col"
  *ngIf="
    !(isAutoSelected || isPowersportsSelected) &&
    (isPayingThroughEscrow || isPayingThroughEscrowFromRetrieve)
  "
>
  <div class="billing-plan-text">
    <strong>Billing Plan: </strong>&nbsp; Third Party Escrow
  </div>
</div>
