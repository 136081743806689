import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import { DwellingReplacementCostDisclaimer } from '@shared/constants/help-text-constants';

@Component({
  selector: 'mq-edit-coverages-consent',
  templateUrl: './edit-coverages-consent.component.html',
  styleUrls: ['./edit-coverages-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCoveragesConsentComponent implements OnInit {
  dwellingReplacementCostDisclaimer: string;

  @Input() form: UntypedFormGroup;
  @Input() coverageChanges: CoverageChange[];
  @Input() isAgentExperience: boolean;

  @Output() consentToggled = new EventEmitter<any>();

  constructor() {}

  get dwellingReplacementCostCheckbox(): AbstractControl {
    return this.form.get('dwellingReplacementCostCheckbox');
  }

  ngOnInit(): void {
    this.dwellingReplacementCostDisclaimer = this.isAgentExperience
      ? DwellingReplacementCostDisclaimer['Agent']
      : DwellingReplacementCostDisclaimer['Customer'];
  }

  onConsentToggled(event: Event): void {
    this.consentToggled.emit(event);
  }

  showError(formControlName: string): boolean {
    if (!this.form) {
      return false;
    }
    const control = this.form.get(formControlName);
    return (
      !!control &&
      control.invalid &&
      (control.dirty || control.touched) &&
      control.errors.required
    );
  }
}
