import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromAdditionalInterest from '@core/store/reducers/additional-interest.reducer';
import * as fromProducts from '@core/store/selectors/products.selector';

import { AdditionalInterestEntity } from '@core/models/entities/additional-interest.entity';
import { AdditionalInterestRequest } from '@core/models/auto/additional-interest/additional-interest-request.model';

export const getAdditionalInterestState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.additionalInterest
);

export const {
  selectAll: selectAllAdditionalInterest,
  selectEntities: selectAdditionalInterestEntities,
} = fromAdditionalInterest.adapter.getSelectors(getAdditionalInterestState);

export const getAdditionalInterestEntity = (additionalInterestId: string) =>
  createSelector(
    selectAdditionalInterestEntities,
    entities => entities[additionalInterestId]
  );

export const getAdditionalInterestEntities = (ids: string[]) =>
  createSelector(selectAdditionalInterestEntities, entities => {
    ids = ids.map(id => id.toString()); // Could be numbers...
    return Object.keys(entities) // ...always strings
      .filter(entityId => ids.includes(entityId))
      .map(id => entities[id]);
  });

export const buildAdditionalInterestRequest = (
  additionalInterest: AdditionalInterestEntity
) =>
  createSelector(
    fromProducts.getProduct(additionalInterest.productId),
    product => {
      const request: AdditionalInterestRequest = {
        productId: product.id,
        quoteId: product.quoteId,
        additionalInterest,
      };

      return request;
    }
  );

export const selectAdditionalInterestsForProductsWithoutError = createSelector(
  selectAllAdditionalInterest,
  fromProducts.getSelectedProductsWithoutErrors,
  (interests, products) =>
    interests.filter(i => products.some(p => p.id === i.productId))
);
