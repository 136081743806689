import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { PolicyholderResponse } from '@core/models/response/policyholder-response.model';
import { PolicyholderRequest } from '@core/models/request/policyholder-request.model';
import { AddressRequest } from '@core/models/request/address-request.model';
import { DEFAULT_ID, PolicyholderTypes } from '@shared/constants/app-constants';

export class PolicyholderBuilder {
  static buildPolicyholderEntityFromResponse(
    response: PolicyholderResponse,
    productId: string,
    addressId: string,
    dateOfBirth?: string,
    policyHolderType?: string,
    prefillId?: string,
    emailAddress?: string,
    homeNumber?: string,
    infractionDesc?: string
  ): PolicyholderEntity {
    const policyHolderEntity: PolicyholderEntity = {
      person: {
        ...response.person,
        dateOfBirth: dateOfBirth || response.person.dateOfBirth,
        gender: response?.person?.gender ? response?.person?.gender : null,
      },
      policyHolderIds: {
        [`${productId}`]: response.policyHolderId,
      },
      policyHolderId: response.policyHolderId,
      policyHolderType: response.policyHolderType
        ? response.policyHolderType
        : policyHolderType,
      emailAddress: emailAddress || response.emailAddress,
      homeNumber: homeNumber || response.homeNumber,
      addressId,
      productId,
      prefillId,
      selected: true,
    };

    if (
      policyHolderEntity.policyHolderType ===
      PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
    ) {
      policyHolderEntity.policyHolderId = DEFAULT_ID;
      policyHolderEntity.policyHolderIds = {
        [productId]: response.policyHolderId,
      };
    }
    if (infractionDesc) {
      policyHolderEntity.person.infractionDesc = infractionDesc;
    }
    return policyHolderEntity;
  }

  static buildPolicyholderRequestFromEntity(
    ph: PolicyholderEntity,
    address: AddressRequest
  ): PolicyholderRequest {
    const entity: PolicyholderRequest = {
      address: {
        ...address,
        addressId: this.selectAddressId(ph, address),
      },
      person: {
        ...ph.person,
        suffix: (ph.person && ph.person.suffix) || null,
      },
      emailAddress: ph.emailAddress,
      homeNumber: ph.homeNumber,
      policyHolderId: ph.policyHolderId,
      policyHolderType: ph.policyHolderType,
      productId: ph.productId,
      prefillId: ph.prefillId,
    };
    if ((<any>entity.address).addressIds) {
      delete (<any>entity.address).addressIds;
    }

    if (entity.policyHolderId === DEFAULT_ID) {
      entity.policyHolderId = ph.policyHolderIds[ph.productId];
    }

    if (ph.policyHolderTempId) {
      entity.policyHolderTempId = ph.policyHolderTempId;
    }

    if (ph.prepopulatedInsuredId) {
      entity.prepopulatedInsuredId = ph.prepopulatedInsuredId;
    }

    if (ph.partialQuoteId) {
      entity.partialQuoteId = ph.partialQuoteId;
    }

    return entity;
  }

  static selectAddressId(
    ph: PolicyholderEntity,
    address: AddressRequest
  ): string {
    if ((<any>address)?.addressIds) {
      const id = (<any>address).addressIds[ph.productId];
      if (id) {
        return id;
      }
    }
    return address?.addressId;
  }
}
