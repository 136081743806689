import { HttpHeaders } from '@angular/common/http';
import { StringUtils } from './string.utils';
import {
  InitiatedByType,
  UserContext,
} from '@core/models/user-context/user-context.model';
import {
  APPLICATION_NAME,
  MOBILE_APPLICATION_NAME,
  PRIVATE_LABEL_APPLICATION_NAME,
} from '@shared/constants/app-constants';

export class HeadersUtils {
  static buildHeaderWithMessageId(
    apiKey: string,
    targetEnv?: string,
    userContext?: UserContext
  ): HttpHeaders {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('client_id', apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID());
    if (userContext) {
      headers = headers.set(
        'Application-Name',
        this.applicationNameForUserContext(userContext)
      );
    }
    if (targetEnv) {
      headers = headers.set('X-NW-Target-Env', targetEnv);
    }

    return headers;
  }

  static buildHeaderForDSMCalls(
    apiKey: string,
    accessToken: string,
    sessionId: string,
    targetEnv?: string,
    userContext?: UserContext,
    policyHolderId?: string,
    prePopulatedInsuredId?: string,
    ignoreRiskFilter?: string,
    ignoreAutoUWRules?: string,
    partialQuoteId?: string,
    byPassCookie?: string
  ): HttpHeaders {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Accept-Language', 'en-US')
      .set('client_id', apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID())
      .set('session-id', sessionId);
    if (accessToken) {
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    if (ignoreRiskFilter && ignoreRiskFilter === 'true') {
      headers = headers.set('Ignore-Risk-Filter', ignoreRiskFilter);
    }
    if (ignoreAutoUWRules && ignoreAutoUWRules === 'true') {
      headers = headers.set('Auto-Approve-Rules', ignoreAutoUWRules);
    }
    if (userContext) {
      headers = headers.set(
        'Application-Name',
        this.applicationNameForUserContext(userContext)
      );
    }

    if (targetEnv) {
      headers = headers.set('X-NW-Target-Env', targetEnv);
    }

    if (byPassCookie) {
      headers = headers.set('X-NW-Bypass-Cookie', byPassCookie);
    }
    if (partialQuoteId) {
      headers = headers.set('Partial-Quote-Id', `${partialQuoteId}`);
      headers = headers.set('Policy-Holder-Id', '1');
    }

    if (prePopulatedInsuredId) {
      headers = headers.set(
        'Prepopulated-Insured-Id',
        `${prePopulatedInsuredId}`
      );
      // Defaulting the policyHolderId as per DSM's request (only for prePopulated flows)
      headers = headers.set('Policy-Holder-Id', '1');
    }
    if (policyHolderId && !partialQuoteId && !prePopulatedInsuredId) {
      headers = headers.set('Policy-Holder-Id', `${policyHolderId}`);
    }

    return headers;
  }

  static applicationNameForUserContext(userContext?: UserContext): string {
    if (!userContext) {
      return APPLICATION_NAME;
    }
    if (userContext.privateLabelInformation) {
      return PRIVATE_LABEL_APPLICATION_NAME;
    }
    if (
      userContext.initiatedBy &&
      userContext.initiatedBy.toLocaleLowerCase() ===
        InitiatedByType.IOS_MOBILE.toLocaleLowerCase()
    ) {
      return MOBILE_APPLICATION_NAME;
    }
    if (
      userContext.initiatedBy &&
      userContext.initiatedBy.toLocaleLowerCase() ===
        InitiatedByType.ANDROID_MOBILE.toLocaleLowerCase()
    ) {
      return MOBILE_APPLICATION_NAME;
    }
    return APPLICATION_NAME;
  }

  static buildHeaderForVehicleDetails(accessToken: string): HttpHeaders {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + accessToken)
      .set('Accept', 'application/json')
      .set('X-Nw-Transaction-Id', StringUtils.generateUUID());

    return headers;
  }

  static buildHeaderForDriverVehiclePrefill(
    accessToken: string,
    clientId: string
  ): HttpHeaders {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${accessToken}`)
      .set('Content-Type', 'application/json')
      .set('X-NW-Message-ID', StringUtils.generateUUID())
      .set('client_id', clientId);

    return headers;
  }
}
