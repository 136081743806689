import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromVehicles from '@core/store/actions/vehicle.action';
import * as fromModifiers from '@core/store/actions/modifiers.action';
import * as fromAdditionalInterests from '@core/store/actions/additional-interest.action';

import { VehicleEntity } from '@core/models/entities/vehicle.entity';

export interface VehicleState extends EntityState<VehicleEntity> {
  loading: number;
}

export const adapter: EntityAdapter<VehicleEntity> =
  createEntityAdapter<VehicleEntity>({
    selectId: vehicle => vehicle.vehicleId,
  });

export function reducer(
  state = {
    ...adapter.getInitialState(),
    loading: 0,
  },
  action:
    | fromVehicles.DsmVehicleActions
    | fromModifiers.ModifierActions
    | fromAdditionalInterests.AdditionalInterestActions
): VehicleState {
  switch (action.type) {
    case fromVehicles.VehicleActionTypes.ADD_VEHICLE: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromVehicles.VehicleActionTypes.ADD_VEHICLE_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        loading: state.loading - 1,
      });
    }

    case fromVehicles.VehicleActionTypes.ADD_VEHICLE_TO_STORE: {
      return adapter.addOne(action.payload, {
        ...state,
      });
    }

    case fromVehicles.VehicleActionTypes.ADD_VEHICLE_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromVehicles.VehicleActionTypes.UPDATE_VEHICLE: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromVehicles.VehicleActionTypes.UPDATE_VEHICLE_SUCCESS: {
      return adapter.updateOne(action.payload, {
        ...state,
        loading: state.loading - 1,
      });
    }

    case fromVehicles.VehicleActionTypes.UPDATE_VEHICLE_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromVehicles.VehicleActionTypes.UPDATE_VEHICLE_DIRECTLY: {
      return adapter.updateOne(action.payload, state);
    }

    case fromVehicles.VehicleActionTypes.UPDATE_VEHICLE_DETAILS_SUCCESS:
    case fromVehicles.VehicleActionTypes.UPDATE_VEHICLE_SUCCESS: {
      return adapter.updateOne(action.payload, state);
    }
    case fromVehicles.VehicleActionTypes.UPDATE_ALL_VEHICLES: {
      return adapter.updateMany(action.payload, state);
    }

    case fromVehicles.VehicleActionTypes.UPDATE_ANNUAL_MILES_FOR_ALL_VEHICLES: {
      const newVehicles = action.payload;
      const newAnnualMilesVehicles = [];
      if (!newVehicles) {
        return state;
      }
      for (let newVehicle of newVehicles) {
        const existVehicle = state.entities[newVehicle.vehicleId];
        if (newVehicle.annualMiles) {
          const updatedAnnualMiles = newVehicle.annualMiles;
          newVehicle = { ...existVehicle };
          newVehicle.annualMiles = updatedAnnualMiles;
          newAnnualMilesVehicles.push(newVehicle);
        }
      }
      return adapter.updateMany(
        newAnnualMilesVehicles.map(vehicle =>
          Object.assign({}, { id: vehicle.vehicleId, changes: vehicle })
        ),
        { ...state }
      );
    }

    case fromVehicles.VehicleActionTypes.DELETE_VEHICLE: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromVehicles.VehicleActionTypes.DELETE_VEHICLE_SUCCESS: {
      return adapter.removeOne(action.payload, {
        ...state,
        loading: state.loading - 1,
      });
    }

    case fromVehicles.VehicleActionTypes.DELETE_VEHICLE_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromModifiers.ModifierActionTypes.UPDATE_VEHICLE_DISCOUNT_SUCCESS: {
      const updatedDiscount = action.payload;
      const newDiscounts = [];
      const vehicle = state.entities[action.vehicleId];
      if (!vehicle) {
        return state;
      }
      for (const existDiscount of vehicle.eligibleDiscounts) {
        existDiscount.eligibleDiscountId === updatedDiscount.eligibleDiscountId
          ? newDiscounts.push(updatedDiscount)
          : newDiscounts.push(existDiscount);
      }
      return adapter.updateOne(
        {
          id: action.vehicleId,
          changes: {
            eligibleDiscounts: newDiscounts,
          },
        },
        state
      );
    }

    case fromModifiers.ModifierActionTypes.UPDATE_VEHICLE_DISCOUNT_FAIL: {
      return adapter.updateOne(
        {
          id: action.vehicleId,
          changes: {
            vehicleDiscountsLoaded: false,
          },
        },
        state
      );
    }

    case fromVehicles.VehicleActionTypes.UNSET_VEHICLE_DISCOUNT_LOADED: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            vehicleDiscountsLoaded: false,
          },
        },
        state
      );
    }

    case fromVehicles.VehicleActionTypes.SET_VEHICLE_DISCOUNT_LOADED: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            vehicleDiscountsLoaded: true,
          },
        },
        state
      );
    }

    case fromVehicles.VehicleActionTypes.LOAD_VEHICLE_DISCOUNTS_SUCCESS: {
      return adapter.updateOne(
        {
          id: action.vehicleId,
          changes: {
            eligibleDiscounts: action.payload,
            vehicleDiscountsLoaded: true,
          },
        },
        state
      );
    }

    case fromVehicles.VehicleActionTypes.LOAD_VEHICLE_DISCOUNTS_FAIL: {
      return adapter.updateOne(
        {
          id: action.vehicleId,
          changes: {
            vehicleDiscountsLoaded: false,
          },
        },
        state
      );
    }

    case fromModifiers.ModifierActionTypes.DISABLE_VEHICLE_DISCOUNT: {
      const discountId = action.payload;
      const newDiscounts = [];
      const vehicle = state.entities[action.vehicleId];
      if (!vehicle) {
        return state;
      }
      for (const existDiscount of vehicle.eligibleDiscounts) {
        existDiscount.eligibleDiscountId === discountId
          ? newDiscounts.push({
              ...existDiscount,
              selectedOptionValue: 'false',
            })
          : newDiscounts.push(existDiscount);
      }
      return adapter.updateOne(
        {
          id: action.vehicleId,
          changes: {
            eligibleDiscounts: newDiscounts,
          },
        },
        state
      );
    }

    case fromAdditionalInterests.AdditionalInterestActionTypes
      .ADD_ADDITIONAL_INTEREST_SUCCESS: {
      const vehicle = { ...state.entities[action.payload.vehicleId] };

      if (vehicle.additionalInterestIds) {
        vehicle.additionalInterestIds = [
          ...vehicle.additionalInterestIds,
          action.payload.additionalInterestId,
        ];
      } else {
        vehicle.additionalInterestIds = [action.payload.additionalInterestId];
      }

      return adapter.updateOne(
        { id: vehicle.vehicleId, changes: vehicle },
        state
      );
    }

    case fromAdditionalInterests.AdditionalInterestActionTypes
      .DELETE_ADDITIONAL_INTEREST_SUCCESS: {
      const vehicle = { ...state.entities[action.payload.vehicleId] };

      const index = vehicle.additionalInterestIds.indexOf(
        action.payload.additionalInterestId
      );

      if (index > -1) {
        vehicle.additionalInterestIds = [];
      }

      return adapter.updateOne(
        { id: vehicle.vehicleId, changes: vehicle },
        state
      );
    }

    default: {
      return state;
    }
  }
}
