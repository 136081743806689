import { createSelector } from '@ngrx/store';
import * as fromProducts from '@core/store/selectors/products.selector';
import { PolicyNumberRequest } from '@core/models/policy-number/policy-number-request.model';
import { PolicyNumberStatus } from '@core/models/products/policynumber-status';

export const getPolicyNumberStatusOfAllProducts = createSelector(
  fromProducts.getSelectedProductsWithoutErrors,
  products => products.map(product => new PolicyNumberStatus(product))
);

export const getProductsWithoutPolicyNumber = createSelector(
  fromProducts.getSelectedProductsWithoutErrors,
  products => products.filter(product => !product.policyNumberLoaded)
);

export const isPolicyNumberLoaded = createSelector(
  fromProducts.getSelectedProductsWithoutErrors,
  products => {
    return products.every(product => product.policyNumberLoaded);
  }
);

export const buildPolicyNumberRequests = createSelector(
  getProductsWithoutPolicyNumber,
  products => {
    return products.map(product => {
      const request: PolicyNumberRequest = {
        quoteId: product.quoteId,
        productId: product.id,
      };

      return request;
    });
  }
);
