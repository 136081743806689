import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { TelematicsVehiclePremium } from '@core/models/telematics/telematics.model';

@Component({
  selector: 'mq-self-reported-enrollment',
  templateUrl: './self-reported-enrollment.component.html',
  styleUrls: ['../../../../../../assets/scss/telematics-panel.scss'],
})
export class SelfReportedEnrollmentComponent {
  @Input() vehicles: VehicleEntity[];
  @Input() vehiclePremiums: TelematicsVehiclePremium[];
  @Input() initialDiscount: string;
  @Input() maximumDiscount: string;

  @Output() selfReportedEnrollmentChanged = new EventEmitter<VehicleEntity>();

  headerIcon =
    '<img class="tm-icon" src="assets/images/telematics/SR-icon-white.svg" alt="Car-Image"/>';

  changeEnrollment(vehicle: VehicleEntity) {
    this.selfReportedEnrollmentChanged.emit(vehicle);
  }
}
