import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AddressService } from '@core/services';
import {
  take,
  map,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
} from 'rxjs/operators';
import { Observable, merge } from 'rxjs';

@Component({
  selector: 'nw-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressInputComponent implements OnInit {
  @Input()
  form: UntypedFormGroup;

  @Input()
  address: string;

  @Input()
  addressUnitNumber: string;

  searching = false;
  searchFailed = false;
  hideSearchingWhenUnsubscribed = new Observable(
    () => () => (this.searching = false)
  );

  constructor(private _addressService: AddressService) {}

  ngOnInit(): void {
    this.form.addControl(
      'street',
      new UntypedFormControl('', {
        validators: [Validators.required],
        asyncValidators: [this.validateAddress.bind(this)],
      })
    );

    this.form.addControl(
      'standardizedAddresses',
      new UntypedFormControl('', [])
    );
    this.form.addControl('unitNumber', new UntypedFormControl('', []));

    if (this.street) {
      this.form.patchValue({ street: this.address });
    }

    if (this.unitNumber) {
      this.form.patchValue({ unitNumber: this.addressUnitNumber });
    }
  }

  get street() {
    return this.form.get('street');
  }

  get unitNumber() {
    return this.form.get('unitNumber');
  }

  validateAddress(control: UntypedFormControl) {
    const street = control.value;
    let city = '';
    let state = '';
    let zipcode = '';
    if (street) {
      const streetAddress = street.split(',');
      if (streetAddress.length > 1) {
        city = streetAddress[1];
      }
      if (streetAddress.length > 2) {
        state = streetAddress[2];
      }
      const zipCodeLocation = 3;
      if (streetAddress.length > zipCodeLocation) {
        zipcode = streetAddress[zipCodeLocation];
      }
    }
    return this._addressService
      .loadStandardizedAddress(street, this.unitNumber.value)
      .pipe(
        take(1),
        map(addresses => {
          if (!addresses) {
            return null;
          }

          if (!addresses.standardizedAddresses) {
            return null;
          }

          if (addresses.standardizedAddresses.length < 1) {
            return { incomplete: true };
          }

          if (addresses.standardizedAddresses.length > 1) {
            return { multipleAddresses: true };
          }

          if (addresses.standardizedAddresses.length === 1) {
            const address = addresses.standardizedAddresses[0];

            if (!address.city) {
              return { incomplete: true };
            }

            if (
              city &&
              city.toUpperCase().trim() !== address.city.toUpperCase()
            ) {
              return { incomplete: true };
            }

            // TODO don't hardcode state
            if (!address.state) {
              return { incomplete: true };
            }

            if (
              state &&
              state.toUpperCase().trim() !== address.state.toUpperCase()
            ) {
              return { incomplete: true };
            }

            if (!address.zipCode) {
              return { incomplete: true };
            }

            if (zipcode && zipcode.trim() !== address.zipCode) {
              return { incomplete: true };
            }

            if (!address.timeZone) {
              return { incomplete: true };
            }

            if (city === '' || state === '' || zipcode === '') {
              return { incomplete: true };
            }

            this.form.patchValue({ standardizedAddresses: addresses });
            return null;
          }
        })
      );
  }

  // TODO refactor this, stolen from bootstrap docs
  search = (text$: Observable<string>) => {
    const loadInTime = 300;
    return merge(
      text$.pipe(
        debounceTime(loadInTime),
        distinctUntilChanged(),
        tap(() => (this.searching = true)),
        switchMap(term =>
          term.length < 2 ? [] : this._addressService.suggestAddress(term)
        ),
        map(term => (term ? term.map(t => t.text) : null)),
        tap(() => (this.searching = false))
      ),
      this.hideSearchingWhenUnsubscribed
    );
    // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
  };
}
