<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Self Reporting
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    Be rewarded for driving fewer miles. Join this free program and start saving. 
  </p>
  <ul>
  <li>Provide proof of mileage by sending us a photo or recent service receipt showing your current odometer reading</li>
  <li>Before each policy renewal, provide us with an updated odometer reading</li>
  <li>Earn <strong>up to 15% off</strong> based on the miles you drive annually</li>
  <li>Keep the discount for as long as you're insured with us and continue sending proof of your mileage</li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">
    Close
  </button>
</div>
