import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromHelpContent from '../actions/help-content.action';
import { HelpContentModel } from '@help-content/models/help-content.model';

export type HelpContentState = EntityState<HelpContentModel>;

export const adapter: EntityAdapter<HelpContentModel> =
  createEntityAdapter<HelpContentModel>({
    selectId: helpContent => helpContent.name,
  });

export function reducer(
  state = adapter.getInitialState(),
  action: fromHelpContent.Actions
): HelpContentState {
  switch (action.type) {
    case fromHelpContent.SET_HELP_CONTENT: {
      return adapter.upsertOne(action.payload, state);
    }
  }
  return state;
}
