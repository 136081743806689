import * as fromCoverages from '@core/store/actions/coverage.action';
import * as fromProducts from '@core/store/actions/products.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CoverageEntity } from '@core/models/entities/coverage.entity';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';

export interface CoverageState extends EntityState<CoverageEntity> {
  coverageChanges: CoverageChange[];
  extraCoverageId?: string;
  selectedProduct: string;
  coverageProducts: string[];
  dwellingCoverageConsent?: boolean;
  loaded: boolean;
  loading: boolean;
}

export interface CoverageUpdate {
  value: string;
  coverage: CoverageEntity;
}

export const adapter: EntityAdapter<CoverageEntity> = createEntityAdapter({
  selectId: coverage =>
    coverage.coverageId + (coverage.coverableId ? coverage.coverableId : ''),
});

export function reducer(
  state: CoverageState = adapter.getInitialState({
    coverageChanges: [],
    selectedProduct: null,
    coverageProducts: [],
    dwellingCoverageConsent: false,
    loaded: false,
    loading: false,
  }),
  action: fromCoverages.CoverageActions | fromProducts.ProductsAction
): CoverageState {
  switch (action.type) {
    case fromCoverages.CoverageActionTypes.REMOVE_ALL_COVERAGES: {
      return adapter.removeAll(state);
    }
    case fromCoverages.CoverageActionTypes.REMOVE_PRODUCT_COVERAGES: {
      const keys = (<string[]>state.ids)
        .map(id => {
          return { id, entity: state.entities[id] };
        })
        .filter(e => e.entity.productId === action.payload)
        .map(e => e.id);
      return adapter.removeMany(keys, state);
    }

    case fromCoverages.CoverageActionTypes.LOAD_ALL_COVERAGES_SUCCESS:
    case fromCoverages.CoverageActionTypes.ADD_MANY_COVERAGES: {
      return adapter.addMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }
    case fromCoverages.CoverageActionTypes.LOAD_ALL_COVERAGES: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromCoverages.CoverageActionTypes.LOAD_ALL_COVERAGES_FAIL: {
      return { ...state, loading: false, loaded: false };
    }
    case fromCoverages.CoverageActionTypes.UPDATE_COVERAGE: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromCoverages.CoverageActionTypes.UPDATE_COVERAGE_CHANGE: {
      return {
        ...state,
        coverageChanges: action.payload,
      };
    }
    case fromCoverages.CoverageActionTypes.UPDATE_COVERAGE_FAIL: {
      return { ...state, loading: false, loaded: true };
    }
    case fromCoverages.CoverageActionTypes.UPDATE_EXTRA_COVERAGE_BY_RULE: {
      return {
        ...state,
        extraCoverageId: action.payload,
      };
    }

    case fromCoverages.CoverageActionTypes.UPDATE_SELECTED_PRODUCT_COVERAGE: {
      const selectedProduct = action.payload;

      return { ...state, selectedProduct };
    }

    case fromCoverages.CoverageActionTypes.UPDATE_SELECTED_COVERAGE_PRODUCTS: {
      const coverageProducts = action.payload;

      return { ...state, coverageProducts };
    }

    case fromCoverages.CoverageActionTypes.ADD_SELECTED_COVERAGE_PRODUCT: {
      const coverageProduct = action.payload;
      const coverageProducts = [...state.coverageProducts];

      coverageProducts.push(coverageProduct);

      return { ...state, coverageProducts };
    }

    case fromCoverages.CoverageActionTypes.REMOVE_SELECTED_COVERAGE_PRODUCT: {
      return {
        ...state,
        coverageProducts: state.coverageProducts.filter(
          id => id !== action.payload
        ),
      };
    }

    case fromProducts.FAIL_PRODUCT: {
      const coverageProducts = [...state.coverageProducts];

      return {
        ...state,
        coverageProducts: coverageProducts.filter(
          id => id !== action.productId
        ),
      };
    }

    case fromCoverages.CoverageActionTypes.UPDATE_DWELLING_CONSENT: {
      return {
        ...state,
        dwellingCoverageConsent: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
