import * as fromPartialQuote from '@core/store/actions/partial-quote.action';
import { PartialQuote } from '@core/models/partial-quote/partial-quote.model';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { CoveredLocation } from './covered-location.reducer';
import {
  PartialQuoteProductTypes,
  ProductTypes,
} from '@shared/constants/app-constants';

export interface PartialQuoteState {
  partialQuote: PartialQuote;
  drivers: DriverEntity[];
  policyHolders: PolicyholderEntity[];
  vehicles: VehicleEntity[];
  coveredLocation: CoveredLocation;
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
}

export const initialState: PartialQuoteState = {
  partialQuote: null,
  drivers: [],
  policyHolders: [],
  vehicles: [],
  coveredLocation: null,
  loaded: false,
  loading: false,
  hasError: false,
};

export function reducer(
  state = initialState,
  action: fromPartialQuote.PartialQuoteActions
): PartialQuoteState {
  switch (action.type) {
    case fromPartialQuote.PartialQuoteActionTypes.LOAD_PARTIAL_QUOTE_DATA: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes
      .LOAD_PARTIAL_QUOTE_DATA_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        hasError: true,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes
      .LOAD_PARTIAL_QUOTE_DATA_SUCCESS: {
      return {
        ...state,
        partialQuote: action.payload,
        loaded: true,
        loading: false,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes
      .ADD_PARTIAL_QUOTE_POLICYHOLDERS: {
      return {
        ...state,
        policyHolders: action.payload,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes.ADD_PARTIAL_QUOTE_DRIVERS: {
      return {
        ...state,
        drivers: action.payload,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes.ADD_PARTIAL_QUOTE_VEHICLES: {
      return {
        ...state,
        vehicles: action.payload,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes
      .UPDATE_PARTIAL_QUOTE_VEHICLE_SELECTED: {
      const vehicle = action.payload;
      const partialQuoteVehicles = state.vehicles;
      const updatedVehicles = partialQuoteVehicles.map(partiaquoteVehicle => {
        if (partiaquoteVehicle.vehicleTempId === vehicle.vehicleTempId) {
          return { ...partiaquoteVehicle, selected: vehicle.selected };
        }
        return partiaquoteVehicle;
      });
      return {
        ...state,
        vehicles: updatedVehicles,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes
      .UPDATE_PARTIAL_QUOTE_DRIVER_SELECTED: {
      const driver = action.payload;
      const partialQuoteDrivers = state.drivers;
      const updatedDrivers = partialQuoteDrivers.map(partiaquoteDriver => {
        if (partiaquoteDriver.driverTempId === driver.driverTempId) {
          return { ...partiaquoteDriver, selected: driver.selected };
        }
        return partiaquoteDriver;
      });
      return {
        ...state,
        drivers: updatedDrivers,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes
      .UPDATE_PARTIAL_QUOTE_POLICY_HOLDER_SELECTED: {
      const policyHolder = action.payload;
      const partialQuotePolicyHolders = state.policyHolders;
      const updatedPolicyHolders = partialQuotePolicyHolders.map(
        partialQuotePolicyHolder => {
          if (
            partialQuotePolicyHolder.policyHolderTempId ===
            policyHolder.policyHolderTempId
          ) {
            return {
              ...partialQuotePolicyHolder,
              selected: policyHolder.selected,
            };
          }
          return partialQuotePolicyHolder;
        }
      );
      return {
        ...state,
        policyHolders: updatedPolicyHolders,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes
      .UPDATE_PARTIAL_QUOTE_VEHICLE_SUCCESS: {
      const vehicle = action.payload;
      const partialQuoteVehicles = state.vehicles;
      const updatedVehicles = partialQuoteVehicles.map(partiaquoteVehicle => {
        if (partiaquoteVehicle.vehicleTempId === vehicle.vehicleTempId) {
          return {
            ...vehicle,
          };
        }
        return partiaquoteVehicle;
      });
      return {
        ...state,
        vehicles: updatedVehicles,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes
      .REMOVE_PARTIAL_QUOTE_VEHICLE: {
      const vehicle = action.payload;
      const filteredVehicles = state.vehicles.filter(
        veh => veh.vehicleTempId !== vehicle.vehicleTempId
      );
      return {
        ...state,
        vehicles: filteredVehicles,
      };
    }

    case fromPartialQuote.PartialQuoteActionTypes
      .ADD_PARTIAL_QUOTE_COVERED_LOCATION: {
      return {
        ...state,
        coveredLocation: action.payload,
      };
    }
  }
  return state;
}
