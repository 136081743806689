<div [formGroup]="form">
  <div class="row">
    <div class="form-group col-9 col-md-5">
      <label for="address">Address</label>
      <div class="input-group">
        <input
          type="text"
          id="address"
          class="form-control"
          formControlName="address"
          [attr.readonly]="readOnly ? 'readonly' : null"
        />
          <div class="input-group-text"></div>
      </div>
      <p class="help-text">Street address, P.O. Box</p>
    </div>
    <div class="form-group col-3">
      <label for="unitNumber">Unit #</label>
      <div class="input-group">
        <input
          id="unitNumber"
          type="text"
          class="form-control"
          formControlName="unitNumber"
          [attr.readonly]="readOnly ? 'readonly' : null"
          aria-describedby="input-error"
        />
          <div class="input-group-text"></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-9 col-md-4">
      <label for="city">City</label>
      <div class="input-group">
        <input
          type="text"
          id="city"
          formControlName="city"
          class="form-control"
          [attr.readonly]="readOnly ? 'readonly' : null"
          aria-describedby="input-error"
        />
          <div class="input-group-text"></div>
      </div>
    </div>
    <div class="form-group col-3">
      <label for="state">State</label>
      <div class="input-group">
        <input
          type="text"
          id="state"
          formControlName="state"
          class="form-control"
          maxlength="2"
          minlength="2"
          [attr.readonly]="readOnly ? 'readonly' : null"
          aria-describedby="input-error"
        />
          <div class="input-group-text"></div>
      </div>
    </div>
    <div class="form-group col-4 col-md-3">
      <label for="zip">Zip</label>
      <div class="input-group">
        <input
          type="text"
          id="zip"
          formControlName="zip"
          class="form-control"
          min="5"
          max="10"
          [attr.readonly]="readOnly ? 'readonly' : null"
          aria-describedby="input-error"
        />
          <div class="input-group-text"></div>
      </div>
    </div>
  </div>
</div>
