import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as fromStore from '@core/store';

import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { switchMap, take, map, catchError, tap, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AgencyService, LoggingService } from '@core/services';
import { of } from 'rxjs';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable()
export class AgencyEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _agencyService: AgencyService,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  getProducerCodes$ = this._actions$.pipe(
    ofType(fromActions.PRODUCER_CODE_SEARCH),
    switchMap(() =>
      this._store.select(fromSelectors.getAgencyAccessToken).pipe(take(1))
    ),
    tap(token => this._loggingService.log('producerCodeSearch request', {})),
    switchMap(token =>
      this._agencyService.producerCodeSearch(token).pipe(
        tap(response =>
          this._loggingService.log('producerCodeSearch response', response)
        ),
        map(response => new fromActions.ProducerCodeSearchSuccess(response)),
        catchError(error =>
          of(
            new fromActions.ProducerCodeSearchFail(
              this._errorHelper.sanitizeError(error)
            )
          )
        )
      )
    )
  );

  @Effect()
  overwriteDirectAccessTokensWithAgents$ = this._actions$.pipe(
    ofType<fromActions.UpdateAgentJwt>(fromActions.UPDATE_AGENT_JWT),
    map(action => action.payload.accessToken),
    filter(token => !!token),
    map(token => new fromActions.InsertAccessToken(token))
  );
}
