import { Injectable } from '@angular/core';
import { AccountAdapter } from '@core/adapters/account.adapter';
import { AccountDao } from '@core/dao';
import { AccountRequest } from '@core/models/request/account-request.model';
import { AccountResponse } from '@core/models/response/account-response.model';
import { Observable } from 'rxjs';
import { filter, take, map, withLatestFrom } from 'rxjs/operators';
import { RetrieveService } from './retrieve.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private _accountAdapter: AccountAdapter,
    private _accountDao: AccountDao,
    private _retrieveService: RetrieveService
  ) {}

  createAccount(request: AccountRequest): Observable<AccountResponse> {
    return this._accountAdapter.createAccount(request);
  }

  getAccountLoaded(): Observable<boolean> {
    return this._accountDao.getAccountLoaded();
  }

  getAccountLoading(): Observable<boolean> {
    return this._accountDao.getAccountLoading();
  }

  getAccountId(): Observable<string | null> {
    return this._accountDao.getAccountId();
  }

  createAccountHolderIfAbsent(): Observable<boolean> {
    return this._accountDao.getAccountLoaded().pipe(
      withLatestFrom(this.getAccountLoading()),
      map(([loaded, loading]: [boolean, boolean]) => {
        if (!loaded && !loading) {
          this._accountDao.dispatchCreateAccount();
        }
        return loaded;
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
