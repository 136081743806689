import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { RenovationEntity } from '@core/store/reducers/renovation.reducer';
import {
  RENOVATION,
  HO_PROP_INFO_FORM_ENG,
} from '@shared/constants/app-constants';
import { PropertyHelper } from '@core/services/helpers/property.helper';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

@Component({
  selector: 'mq-renovations-form',
  templateUrl: './renovations-form.component.html',
  styleUrls: ['./renovations-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RenovationsFormComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() coveredLocation: CoveredLocation;
  @Input() renovationData: RenovationEntity[];
  @Output() formSubmit = new EventEmitter<string>();

  constructor(
    private _propertyHelper: PropertyHelper,
    private _fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this._buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.renovationData && this.form) {
      if (this.form.status === 'VALID') {
        this.renovations.clearValidators();
        this.renovations.updateValueAndValidity();
      }
    }
  }

  private _buildForm(): void {
    this._fb.group({
      renovations: this._fb.array([
        this._fb.control('year', [Validators.required]),
        this._fb.control('renovationId', []),
        this._fb.control('type', []),
      ]),
    });
    this.renovationData.forEach(renovation => {
      this.renovations.push(
        this._fb.group({
          year: this._fb.control(
            {
              value: renovation.year,
              disabled: renovation.year ? true : false,
            },
            [Validators.required]
          ),
          renovationId: this._fb.control(renovation.renovationId, []),
          type: this._fb.control(renovation.type, []),
        })
      );
    });
  }

  getRenovationErrorText(reno: RenovationEntity): string {
    const errors = this.form.controls[reno.type].errors;
    if (errors['beforeYearBuilt']) {
      return `Must be after year built (${errors['yearBuilt']})`;
    }
    if (errors['max']) {
      return HO_PROP_INFO_FORM_ENG.GET_RENO_ERROR.FUTURE_NOT_ALLOWED;
    }
    return HO_PROP_INFO_FORM_ENG.GET_RENO_ERROR.INCORRECT_FORMAT;
  }

  getRenovationText(): string {
    if (this.renovationData?.length === 1) {
      return RENOVATION.SINGLE.replace(
        '{renovationType}',
        this.renovationData[0].type.toLowerCase()
      );
    } else if (this.renovationData?.length > 1) {
      return RENOVATION.ALL;
    } else {
      return null;
    }
  }

  get renovations(): UntypedFormArray {
    return this.form.get('renovations') as UntypedFormArray;
  }
}
