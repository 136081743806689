import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { AddressEntity } from '@core/models/entities/address.entity';
import { CRUDService } from '@core/services/crud.service';
import { DEFAULT_ID } from '@shared/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class PolicyAddressDao implements CRUDService<AddressEntity> {
  constructor(private _store: Store<fromStore.AppState>) {}

  addOrUpdateAddress(address: AddressEntity): void {
    address.addressId !== DEFAULT_ID ? this.update(address) : this.add(address);
  }

  add(address: AddressEntity): void {
    this._store.dispatch(new fromActions.AddPolicyAddress(address));
  }

  update(address: AddressEntity): void {
    const updated: Update<AddressEntity> = {
      changes: address,
      id: address.addressId,
    };

    this._store.dispatch(new fromActions.UpdatePolicyAddress(updated));
  }

  delete(policyholderId: string): void {
    this._store.dispatch(new fromActions.DeletePolicyAddress(policyholderId));
  }

  getPolicyAddress(id: string): Observable<AddressEntity> {
    return this._store.select(fromSelectors.getPolicyAddress(id));
  }

  getAll(): Observable<AddressEntity[]> {
    return this._store.select(fromSelectors.getAllPolicyAddresses());
  }
}
