import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as fromDriverVehiclePrefill from '@core/store/actions/driver-vehicle-prefill.action';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { PersonViewModel } from '@core/models/entities/driver.entity';

type DriverPrefillState = EntityState<PersonViewModel>;
type VehiclePrefillState = EntityState<VehicleEntity>;

export interface DriverVehiclePrefillState {
  loaded: boolean;
  drivers: DriverPrefillState;
  vehicles: VehiclePrefillState;
  powersportsVehicles: VehiclePrefillState;
  powersportsDrivers: DriverPrefillState;
}

export const driverAdapter: EntityAdapter<PersonViewModel> =
  createEntityAdapter<PersonViewModel>({
    selectId: driver => driver.prefillId,
  });

export const vehicleAdapter: EntityAdapter<VehicleEntity> =
  createEntityAdapter<VehicleEntity>({
    selectId: vehicle => vehicle.prefillId,
  });

export const powersportsDriverAdapter: EntityAdapter<PersonViewModel> =
  createEntityAdapter<PersonViewModel>({
    selectId: driver => driver.prefillId,
  });

export const powersportsVehicleAdapter: EntityAdapter<VehicleEntity> =
  createEntityAdapter<VehicleEntity>({
    selectId: vehicle => vehicle.prefillId,
  });

const driverInitialState: DriverPrefillState = driverAdapter.getInitialState();
const vehicleInitialState: VehiclePrefillState =
  vehicleAdapter.getInitialState();
const powersportsDriverInitialState: DriverPrefillState =
  powersportsDriverAdapter.getInitialState();
const powersportsVehicleInitialState: VehiclePrefillState =
  powersportsVehicleAdapter.getInitialState();

const initialState = {
  loaded: false,
  drivers: driverInitialState,
  vehicles: vehicleInitialState,
  powersportsDrivers: powersportsDriverInitialState,
  powersportsVehicles: powersportsVehicleInitialState,
};

export function reducer(
  state = initialState,
  action: fromDriverVehiclePrefill.DriverVehiclePrefillActions
): DriverVehiclePrefillState {
  switch (action.type) {
    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .ADD_ALL_PREFILL_PEOPLE: {
      return {
        ...state,
        drivers: driverAdapter.setAll(action.payload, state.drivers),
      };
    }

    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .ADD_ALL_PREFILL_VEHICLES: {
      return {
        ...state,
        vehicles: vehicleAdapter.setAll(action.payload, state.vehicles),
      };
    }

    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .GET_CUSTOMER_DATA_FAIL:
    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes.NO_HIT:
    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .GET_CUSTOMER_DATA_SUCCESS: {
      return {
        ...state,
        loaded: true,
      };
    }

    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .UPDATE_PREFILL_DRIVER_SELECTED: {
      return {
        ...state,
        drivers: driverAdapter.updateOne(action.payload, state.drivers),
        loaded: true,
      };
    }

    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .UPDATE_PREFILL_VEHICLE_SELECTED: {
      return {
        ...state,
        vehicles: vehicleAdapter.updateOne(action.payload, state.vehicles),
        loaded: true,
      };
    }

    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .UPDATE_PREFILL_POWERSPORTS_VEHICLE_SELECTED: {
      return {
        ...state,
        powersportsVehicles: powersportsVehicleAdapter.updateOne(
          action.payload,
          state.powersportsVehicles
        ),
        loaded: true,
      };
    }

    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .ADD_ALL_PREFILL_POWERSPORTS_PEOPLE: {
      return {
        ...state,
        powersportsDrivers: powersportsDriverAdapter.setAll(
          action.payload,
          state.powersportsDrivers
        ),
      };
    }

    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .ADD_ALL_PREFILL_POWERSPORTS_VEHICLES: {
      return {
        ...state,
        powersportsVehicles: powersportsVehicleAdapter.setAll(
          action.payload,
          state.powersportsVehicles
        ),
      };
    }

    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .GET_CUSTOMER_POWERSPORTS_DATA_FAIL:
    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .NO_POWERSPORTS_PREFILL_HIT:
    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .GET_CUSTOMER_POWERSPORTS_DATA_SUCCESS: {
      return {
        ...state,
        loaded: true,
      };
    }

    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .UPDATE_PREFILL_POWERSPORTS_DRIVER_SELECTED: {
      return {
        ...state,
        powersportsDrivers: powersportsDriverAdapter.updateOne(
          action.payload,
          state.powersportsDrivers
        ),
        loaded: true,
      };
    }

    case fromDriverVehiclePrefill.DriverVehiclePrefillActionTypes
      .UPDATE_PREFILL_POWERSPORTS_VEHICLE_SELECTED: {
      return {
        ...state,
        powersportsVehicles: powersportsVehicleAdapter.updateOne(
          action.payload,
          state.powersportsVehicles
        ),
        loaded: true,
      };
    }
  }
  return state;
}
