import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import * as fromComponents from './components';
import * as fromContainers from './containers';
import * as fromProxies from './proxies';
import * as fromGuards from './guards';
import * as fromShared from '../shared';

import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './containers/search/search.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { NgbPaginationModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { SortableHeaderDirective } from '@shared/directives/sortable-header/sortable-header.directive';
import { RetrieveProductNamesPipe } from './pipes/retrieve-product-names.pipe';

@NgModule({
  imports: [
    CommonModule,
    SearchRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbPaginationModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    ...fromShared.modules,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    ...fromComponents.components,
    ...fromContainers.containers,
    SearchComponent,
    SearchFormComponent,
    SortableHeaderDirective,
    RetrieveProductNamesPipe,
  ],
  providers: [...fromProxies.proxies, ...fromGuards.guards],
})
export class SearchModule {}
