import { Action } from '@ngrx/store';

export const enum UnderlyingPolicyActionTypes {
  ADD_UNDERLYING_POLICY_FAIL = '[Underlying Policy] Add Underlying Policy Fail',
  ADD_UNDERLYING_POLICY_SUCCESS = '[Underlying Policy] Add Underlying Policy Success',
  ADD_ALL_UNDERLYING_POLICY_SUCCESS = '[Underlying Policy] Add All Underlying Policy Success',
}

export class AddUnderlyingPolicyFail implements Action {
  readonly type = UnderlyingPolicyActionTypes.ADD_UNDERLYING_POLICY_FAIL;
  constructor(public error: any) {}
}

export class AddUnderlyingPolicySuccess implements Action {
  readonly type = UnderlyingPolicyActionTypes.ADD_UNDERLYING_POLICY_SUCCESS;
  constructor(public payload: any) {}
}

export class AddAllUnderlyingPolicySuccess implements Action {
  readonly type = UnderlyingPolicyActionTypes.ADD_ALL_UNDERLYING_POLICY_SUCCESS;
  constructor(public payload: any[]) {}
}

export type UnderlyingPolicyActions =
  | AddUnderlyingPolicyFail
  | AddUnderlyingPolicySuccess
  | AddAllUnderlyingPolicySuccess;
