import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import {
  MobileEnrollment,
  TelematicsVehicle,
} from '@core/models/telematics/telematics.model';
import {
  TelematicsProgram,
  TelematicsEnrollmentStatus,
  CONNECTED_CAR_TERMS_AND_CONDITIONSS_URL,
  ANNUAL_MILES_MIN,
  ProductTypes,
  SmartMilesMethods,
} from '@shared/constants/app-constants';
import { ConnectedCarConsent } from '@core/models/private-label/private-label.model';
import { SessionService } from '@core/services';
import { take } from 'rxjs/operators';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { QuoteService } from '@core/services/quote.service';
import { TelematicsEnrollmentsService } from '@core/services/telematics-enrollments.service';
import { TelematicsEnrollmentRecommendationResponse } from '@core/models/telematics/telematics.model';
@Component({
  selector: 'mq-telematics-confirmation-modal',
  templateUrl: './telematics-confirmation-modal.component.html',
  styleUrls: ['./telematics-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelematicsConfirmationModalComponent implements OnInit {
  @Input() vehicles: VehicleEntity[];
  @Input() telematicsEnrollmentVehicles: TelematicsVehicle[];
  @Input() telematicsMobileEnrollment: MobileEnrollment;
  @Input() privateLabelIdentifier: string;
  @Input() isAgent: boolean;
  @Input() connectedCarConsent: ConnectedCarConsent;

  smartMilesVehicles: VehicleEntity[] = [];
  smartRideDeviceVehicles: VehicleEntity[] = [];
  smartRideInstantVehicles: VehicleEntity[] = [];
  connectedCarVehicles: VehicleEntity[] = [];
  selfReportedVehicles: VehicleEntity[] = [];
  preQualifiedVehicles: VehicleEntity[] = [];
  ineligibleVehicles: VehicleEntity[] = [];
  smartMilesConnectedCarVehicles: VehicleEntity[] = [];
  mobileAppEnrolled: boolean;
  isDeviceOnlyState: boolean;
  vehicleUnderSmartMilesLimit = false;
  termsAndConditionsURL = CONNECTED_CAR_TERMS_AND_CONDITIONSS_URL;
  isVehicleMiles = false;
  recommendation: TelematicsEnrollmentRecommendationResponse;

  form: UntypedFormGroup;
  @HostBinding('attr.role') role = 'main';
  constructor(
    public modal: NgbActiveModal,
    private _sessionService: SessionService,
    private _quoteService: QuoteService,
    private _fb: UntypedFormBuilder,
    private _telematicsEnrollmentsService: TelematicsEnrollmentsService
  ) {}

  ngOnInit(): void {
    this._buildForm();
    this.getRecommendation();
    if (this.telematicsEnrollmentVehicles?.length) {
      let vehicleUnderSmartMilesLimit = true;
      let smartmilesFound = false;
      let smartRideFound = false;
      this.vehicles.forEach(vehicle => {
        const enrollmentVehicle = this.telematicsEnrollmentVehicles.find(
          foundEnrollmentVehicle =>
            foundEnrollmentVehicle.vehicleId.toString() ===
            vehicle.vehicleId.toString()
        );
        if (enrollmentVehicle) {
          if (
            enrollmentVehicle.vehicleProgram === TelematicsProgram.SMART_MILES
          ) {
            smartmilesFound = true;
            if (!this.isAgent) {
              if (
                enrollmentVehicle.annualMileage < ANNUAL_MILES_MIN &&
                this.telematicsEnrollmentVehicles.length == 1
              ) {
                this.vehicleUnderSmartMilesLimit = true;
              } else if (
                enrollmentVehicle.annualMileage >= ANNUAL_MILES_MIN &&
                vehicleUnderSmartMilesLimit
              ) {
                vehicleUnderSmartMilesLimit = false;
              }
            } else {
              vehicleUnderSmartMilesLimit = false;
            }
            if (
              enrollmentVehicle?.dataCollectionMethod ===
              SmartMilesMethods.ConnectedCar
            )
              this.smartMilesConnectedCarVehicles.push(vehicle);
            else this.smartMilesVehicles.push(vehicle);
          } else if (
            enrollmentVehicle.vehicleProgram ===
            TelematicsProgram.SMARTRIDE_DEVICE
          ) {
            smartRideFound = true;
            this.smartRideDeviceVehicles.push(vehicle);
          } else if (
            enrollmentVehicle.vehicleProgram ===
            TelematicsProgram.SMARTRIDE_INSTANT
          ) {
            this.smartRideInstantVehicles.push(vehicle);
          } else if (
            enrollmentVehicle.vehicleProgram === TelematicsProgram.SELF_REPORTED
          ) {
            this.selfReportedVehicles.push(vehicle);
          } else if (
            enrollmentVehicle.vehicleProgram === TelematicsProgram.CONNECTED_CAR
          ) {
            this.connectedCarVehicles.push(vehicle);
          } else if (
            enrollmentVehicle.enrollmentStatus ===
            TelematicsEnrollmentStatus.PRE_QUALIFIED
          ) {
            this.preQualifiedVehicles.push(vehicle);
          } else if (
            enrollmentVehicle.enrollmentStatus ===
            TelematicsEnrollmentStatus.NOT_ENROLLED
          ) {
            this.ineligibleVehicles.push(vehicle);
          }
        } else {
          this.ineligibleVehicles.push(vehicle);
        }
      });
      if (smartRideFound) {
        this.vehicleUnderSmartMilesLimit = false;
      } else if (smartmilesFound) {
        this.vehicleUnderSmartMilesLimit = vehicleUnderSmartMilesLimit;
      } else {
        this.vehicleUnderSmartMilesLimit = false;
      }
    } else if (this.telematicsMobileEnrollment || !this.isDeviceOnlyState) {
      this.mobileAppEnrolled = true;
      this.smartRideDeviceVehicles.push(...this.vehicles);
    } else {
      this.ineligibleVehicles.push(...this.vehicles);
    }
    this.isVehicleMilesApplicable();
  }

  private _buildForm(): void {
    this.form = this._fb.group({
      isAgreeToSmartmiles: this._fb.control(null, []),
      isAgreeToSmartMilesConnectedCar: this._fb.control(null, []),
      isAgreeToSmartrideInstant: this._fb.control(null, []),
      isAgreeToSmartride: this._fb.control(null, []),
      isAgreeToSelfReported: this._fb.control(null, []),
      isAgreeToConnectedCar: this._fb.control(null, []),
      isAgreeToConnectedCarToyota: this._fb.control(null, []),
      isAgreeToConnectedCarFord: this._fb.control(null, []),
      isAgreeToPrequalified: this._fb.control(null, []),
    });
  }

  getEnrollmentVehicleById(vehicleId): TelematicsVehicle {
    return this.telematicsEnrollmentVehicles.find(
      ev => ev.vehicleId === vehicleId.toString()
    );
  }

  isAgreeToSmartmilesSelected(): boolean {
    if (
      this.smartMilesVehicles.length &&
      this.isAgreeToSmartmiles.value !== true
    )
      return false;
    else return true;
  }
  isAgreeToSmartMilesConnectedCarselected(): boolean {
    if (
      this.smartMilesConnectedCarVehicles.length &&
      this.isAgreeToSmartMilesConnectedCar.value !== true
    )
      return false;
    else return true;
  }
  isAgreeToSmartrideInstantSelected(): boolean {
    if (
      this.smartRideInstantVehicles.length &&
      this.isAgreeToSmartrideInstant.value !== true
    )
      return false;
    else return true;
  }
  isAgreeToSmartrideSelected(): boolean {
    if (
      this.smartRideDeviceVehicles.length &&
      this.isAgreeToSmartride.value !== true
    )
      return false;
    else return true;
  }
  isAgreeToSelfReportedSelected(): boolean {
    if (
      this.selfReportedVehicles.length &&
      this.isAgreeToSelfReported.value !== true
    )
      return false;
    else return true;
  }
  isAgreeToConnectedCarWithNonPrivateLabelsSelected(): boolean {
    if (
      this.getConnectedCarVehiclesWithNonPrivateLabels().length &&
      this.isAgreeToConnectedCar.value !== true
    )
      return false;
    else return true;
  }
  isAgreeToConnectedCarToyotaSelected(): boolean {
    if (
      this.getConnectedCarVehiclesByMake('TOYO').length &&
      this.isAgreeToConnectedCarToyota.value !== true
    )
      return false;
    else return true;
  }
  isAgreeToConnectedCarFordSelected(): boolean {
    if (
      this.getConnectedCarVehiclesByMake('FORD').length &&
      this.isAgreeToConnectedCarFord.value !== true
    )
      return false;
    else return true;
  }
  isAgreeToPrequalifiedSelected(): boolean {
    if (
      this.preQualifiedVehicles.length &&
      this.isAgreeToPrequalified.value !== true
    )
      return false;
    else return true;
  }

  get isAgreeToSmartmiles(): AbstractControl {
    return this.form.get('isAgreeToSmartmiles');
  }
  get isAgreeToSmartMilesConnectedCar(): AbstractControl {
    return this.form.get('isAgreeToSmartMilesConnectedCar');
  }
  get isAgreeToSmartrideInstant(): AbstractControl {
    return this.form.get('isAgreeToSmartrideInstant');
  }
  get isAgreeToSmartride(): AbstractControl {
    return this.form.get('isAgreeToSmartride');
  }
  get isAgreeToSelfReported(): AbstractControl {
    return this.form.get('isAgreeToSelfReported');
  }
  get isAgreeToConnectedCar(): AbstractControl {
    return this.form.get('isAgreeToConnectedCar');
  }
  get isAgreeToConnectedCarToyota(): AbstractControl {
    return this.form.get('isAgreeToConnectedCarToyota');
  }
  get isAgreeToConnectedCarFord(): AbstractControl {
    return this.form.get('isAgreeToConnectedCarFord');
  }
  get isAgreeToPrequalified(): AbstractControl {
    return this.form.get('isAgreeToPrequalified');
  }

  isVehicleMilesApplicable(): void {
    this._sessionService
      .getQuoteState()
      .pipe(take(1))
      .subscribe(state => (this.isVehicleMiles = state === 'CA'));
  }

  onModelSubmit(): string {
    if (
      !this.connectedCarConsent.hasConnectedCarConsent &&
      (this.connectedCarVehicles.length ||
        this.smartMilesConnectedCarVehicles.length)
    ) {
      this._quoteService.dispatchPatchQuote(ProductTypes.AUTO, {
        hasConnectedCarConsent:
          this.isAgreeToConnectedCar.value ||
          this.isAgreeToConnectedCarToyota.value ||
          this.isAgreeToConnectedCarFord.value ||
          this.isAgreeToSmartMilesConnectedCar.value,
      });
    }

    return this.vehicleUnderSmartMilesLimit === true ? 'Invalid' : 'Accept';
  }

  getConnectedCarVehiclesByMake(make: string): VehicleEntity[] {
    return this.connectedCarVehicles?.filter(vehicle => vehicle.make === make);
  }

  getConnectedCarVehiclesWithNonPrivateLabels(): VehicleEntity[] {
    return this.connectedCarVehicles?.filter(
      vehicle => vehicle.make != 'TOYO' && vehicle.make != 'FORD'
    );
  }

  forwardDisabled(): boolean {
    if (
      !this.isAgreeToSmartmilesSelected() ||
      !this.isAgreeToSmartMilesConnectedCarselected() ||
      !this.isAgreeToSmartrideInstantSelected() ||
      !this.isAgreeToSmartrideSelected() ||
      !this.isAgreeToSelfReportedSelected() ||
      !this.isAgreeToConnectedCarWithNonPrivateLabelsSelected() ||
      !this.isAgreeToConnectedCarToyotaSelected() ||
      !this.isAgreeToConnectedCarFordSelected() ||
      !this.isAgreeToPrequalifiedSelected()
    )
      return true;

    return false;
  }

  getRecommendation(): void {
    this._telematicsEnrollmentsService
      .getRecommendation()
      .pipe(take(1))
      .subscribe(recommendation => {
        this.recommendation = recommendation;
      });
  }

  shouldOfferSmartMiles_v2(): boolean {
    let smartmiles2_Eligible = false;
    if (
      this.recommendation?.recommendedProgram === 'Vehicle Program' &&
      this.recommendation?.qualifyingInformation.vehicles.find(
        vehicle => vehicle.availablePrograms.length > 0
      )
    ) {
      this.recommendation.qualifyingInformation.vehicles.forEach(vehicle => {
        vehicle.availablePrograms.forEach(availableProgram => {
          if (
            availableProgram.version === '2.0' &&
            availableProgram.name === TelematicsProgram.SMART_MILES
          ) {
            smartmiles2_Eligible = true;
          }
        });
      });
    }
    return smartmiles2_Eligible;
  }
}
