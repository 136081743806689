import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, filter, take } from 'rxjs/operators';
import { PrepopulatedInsuredService } from '@core/services/prepopulated-insured.service';

@Injectable()
export class PrepopulatedInsuredGuard implements CanActivate {
  constructor(
    private _prepopulatedInsuredService: PrepopulatedInsuredService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (route.queryParams['GUID'] && route.queryParams['OTP']) {
      return this.checkStore();
    }
    return of(true);
  }

  checkStore(): Observable<boolean> {
    return this._prepopulatedInsuredService.getPrepopulatedInsuredLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._prepopulatedInsuredService.loadPrepopulatedInsuredData();
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
