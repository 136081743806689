<ng-container
  *ngIf="
    selectedProduct?.hasError ||
    (selectedProduct?.errorCode === 'PUL001' &&
      selectedProduct?.id === autoProductId) ||
    extraUpdatedCoverage
  "
>
  <mq-coverage-error
    *ngIf="selectedProduct?.hasError"
    [product]="selectedProduct"
  ></mq-coverage-error>
  <div class="row" style="margin: 10px 0 15px 0" *ngIf="extraUpdatedCoverage">
    <div class="alert alert-info media col-sm-12" role="alert">
      <a class="icon info"></a>
      <span>
        One or more terms were updated automatically based on your selection.
        Please review the values below.</span
      >
    </div>
  </div>
  <div
    class="row"
    style="margin: 10px 0 15px 0"
    *ngIf="
      selectedProduct?.errorCode === 'PUL001' &&
      selectedProduct?.id === autoProductId
    "
  >
    <div class="alert alert-danger media col-sm-12" role="alert">
      <a class="icon error"></a>
      <span>
        Umbrella requires Bodily Injury limit of $250,000, Property Damage limit
        of $100,000, Uninsured Motorists Bodily Injury Limit of $250,000, and a
        Personal Liability Limit of $300,000.</span
      >
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!selectedProduct?.hasError">
  <div *ngIf="selectedProduct?.id === autoProductId">
    <mq-telematics-enrollments
      [vehicles]="vehicles"
      [telematicsEnrollments]="telematicsEnrollments"
      [preQualifiedVehicleInfo]="preQualifiedVehicleInfo"
      [connectedCarCriteria]="connectedCarCriteria"
      [isConnectedCarEnabled]="isConnectedCarEnabled"
      [stateSpecificFlags]="stateSpecificFlags"
      [coverages]="coverages"
      [termType]="selectedProduct?.termType"
      [coveragePremiums]="coveragePremiums"
      [quoteState]="quoteState"
      [loadProgramsOpen]="loadProgramsOpen"
      [privateLabelIdentifier]="privateLabelIdentifier"
      (resetRequested)="telematicsResetRequested.next()"
      (telematicsChangeRequested)="telematicsChangeRequested.emit()"
    ></mq-telematics-enrollments>
    <mq-edit-auto-coverages
      [form]="form"
      [policyCoverageGrouping]="autoCoverageGroupings.policy"
      [vehicleCoverageGroupings]="autoCoverageGroupings.vehicles"
      [driverCoverageGroupings]="autoCoverageGroupings.drivers"
      [additionalPolicyCoverageGrouping]="
        autoCoverageGroupings.additionalPolicy
      "
      [otherCoverageGrouping]="autoCoverageGroupings.other"
      [coverageChanges]="coverageChanges"
      [showCoveragePrices]="showCoveragePrices"
      [selectedProduct]="selectedProduct"
      [termMonths]="termMonths"
      [isMobile]="isMobile"
      [coverageErrors]="coverageErrors"
      [stateSpecificFlags]="stateSpecificFlags"
      (coverageUpdated)="coverageChanged($event)"
      [extraUpdatedCoverage]="extraUpdatedCoverage"
      [contactNumber]="contactNumber"
      [additionalPolicyNotification]="additionalPolicyNotification"
      [quoteState]="quoteState"
      [isAuto]="isAuto"
      [isPowersports]="isPowersports"
      [isAutoSelected]="isAutoSelected"
      [isHomeownersSelected]="isHomeownersSelected"
      [isUmbrellaSelected]="isUmbrellaSelected"
    ></mq-edit-auto-coverages>
  </div>

  <div *ngIf="selectedProduct?.id === homeownersProductId">
    <mq-edit-homeowners-coverages
      [form]="form"
      [propertyLiabilityCoverageGrouping]="
        homeownersCoverageGroupings.propertyLiability
      "
      [deductiblesCoverageGrouping]="homeownersCoverageGroupings.deductibles"
      [additionalProtectionCoverageGrouping]="
        homeownersCoverageGroupings.additionalProtection
      "
      [specialLimitsOfLiabilityCoverageGrouping]="
        homeownersCoverageGroupings.specialLimitsOfLiability
      "
      [coverageChanges]="coverageChanges"
      [showCoveragePrices]="showCoveragePrices"
      [selectedProduct]="selectedProduct"
      [termMonths]="termMonths"
      [coverageErrors]="coverageErrors"
      [isQuote]="isQuote"
      [stateSpecificFlags]="stateSpecificFlags"
      [contactNumber]="contactNumber"
      [isCompRater]="isCompRater"
      (coverageUpdated)="coverageChanged($event)"
      (consentToggled)="onConsentToggled($event)"
      (propertyInfoModalToggled)="propertyInfoModalToggled.emit($event)"
      [isMobile]="isMobile"
    ></mq-edit-homeowners-coverages>
  </div>

  <div *ngIf="selectedProduct?.id === condoProductId">
    <mq-edit-homeowners-coverages
      [form]="form"
      [propertyLiabilityCoverageGrouping]="
        condoCoverageGroupings.propertyLiability
      "
      [deductiblesCoverageGrouping]="condoCoverageGroupings.deductibles"
      [additionalProtectionCoverageGrouping]="
        condoCoverageGroupings.additionalProtection
      "
      [specialLimitsOfLiabilityCoverageGrouping]="
        condoCoverageGroupings.specialLimitsOfLiability
      "
      [coverageChanges]="coverageChanges"
      [showCoveragePrices]="showCoveragePrices"
      [selectedProduct]="selectedProduct"
      [termMonths]="termMonths"
      [coverageErrors]="coverageErrors"
      [isQuote]="isQuote"
      [stateSpecificFlags]="stateSpecificFlags"
      [contactNumber]="contactNumber"
      [isCompRater]="isCompRater"
      (coverageUpdated)="coverageChanged($event)"
      (consentToggled)="onConsentToggled($event)"
      (propertyInfoModalToggled)="propertyInfoModalToggled.emit($event)"
      [isMobile]="isMobile"
    ></mq-edit-homeowners-coverages>
  </div>

  <div *ngIf="selectedProduct?.id === rentersProductId">
    <mq-edit-homeowners-coverages
      [form]="form"
      [propertyLiabilityCoverageGrouping]="
        rentersCoverageGroupings.propertyLiability
      "
      [deductiblesCoverageGrouping]="rentersCoverageGroupings.deductibles"
      [additionalProtectionCoverageGrouping]="
        rentersCoverageGroupings.additionalProtection
      "
      [specialLimitsOfLiabilityCoverageGrouping]="
        rentersCoverageGroupings.specialLimitsOfLiability
      "
      [coverageChanges]="coverageChanges"
      [showCoveragePrices]="showCoveragePrices"
      [selectedProduct]="selectedProduct"
      [termMonths]="termMonths"
      [coverageErrors]="coverageErrors"
      [stateSpecificFlags]="stateSpecificFlags"
      [contactNumber]="contactNumber"
      (coverageUpdated)="coverageChanged($event)"
      (propertyInfoModalToggled)="propertyInfoModalToggled.emit($event)"
      [isMobile]="isMobile"
    ></mq-edit-homeowners-coverages>
    <div *ngIf="getStateSpecific('sinkhole')">
      <strong
        >Sinkhole coverage can be added to this policy by pivoting to Policy
        Center</strong
      >
    </div>
  </div>

  <div *ngIf="selectedProduct?.id === lifeProductId">
    <mq-edit-life-coverages
      [form]="form"
      [coverageChanges]="coverageChanges"
      [showCoveragePrices]="showCoveragePrices"
      [termMonths]="termMonths"
      [termLifePeople]="termLifePeople"
      [policyHolder]="policyHolder"
      [coverageErrors]="coverageErrors"
      [isQuote]="isQuote"
      [stateSpecificFlags]="stateSpecificFlags"
      [contactNumber]="contactNumber"
      [isCompRater]="isCompRater"
      (onTermPersonDeleted)="onTermPersonDeleted($event)"
    ></mq-edit-life-coverages>
  </div>

  <div *ngIf="selectedProduct?.id === umbrellaProductId">
    <mq-edit-homeowners-coverages
      [form]="form"
      [propertyLiabilityCoverageGrouping]="umbrellaCoverageGroupings.policy"
      [coverageChanges]="coverageChanges"
      [showCoveragePrices]="showCoveragePrices"
      [selectedProduct]="selectedProduct"
      [termMonths]="termMonths"
      [coverageErrors]="coverageErrors"
      [stateSpecificFlags]="stateSpecificFlags"
      [contactNumber]="contactNumber"
      (coverageUpdated)="coverageChanged($event)"
      [isMobile]="isMobile"
    ></mq-edit-homeowners-coverages>
  </div>

  <div *ngIf="selectedProduct?.id === powersportsProductId">
    <mq-edit-auto-coverages
      [form]="form"
      [policyCoverageGrouping]="powersportsCoverageGroupings.policy"
      [vehicleCoverageGroupings]="powersportsCoverageGroupings.vehicles"
      [driverCoverageGroupings]="powersportsCoverageGroupings.drivers"
      [additionalPolicyCoverageGrouping]="
        powersportsCoverageGroupings.additionalPolicy
      "
      [otherCoverageGrouping]="powersportsCoverageGroupings.other"
      [coverageChanges]="coverageChanges"
      [showCoveragePrices]="showCoveragePrices"
      [selectedProduct]="selectedProduct"
      [termMonths]="termMonths"
      [coverageErrors]="coverageErrors"
      [stateSpecificFlags]="stateSpecificFlags"
      (coverageUpdated)="coverageChanged($event)"
      [extraUpdatedCoverage]="extraUpdatedCoverage"
      [contactNumber]="contactNumber"
      [additionalPolicyNotification]="additionalPolicyNotification"
      [quoteState]="quoteState"
      [isMobile]="isMobile"
    ></mq-edit-auto-coverages>
  </div>
</ng-container>
