import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CoverageDao } from '@core/dao/coverage.dao';

import { CoverageEntity } from '@core/models/entities/coverage.entity';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import { Product } from '@core/models/products/product.model';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';

@Injectable({
  providedIn: 'root',
})
export class CoverageService {
  constructor(private _coverageDao: CoverageDao) {}

  getCoverages(): Observable<CoverageEntity[]> {
    return this._coverageDao.getCoverages();
  }

  getCoveragesFor(productId): Observable<CoverageEntity[]> {
    return this._coverageDao.getCoveragesFor(productId);
  }

  loadAllCoverages(productId: string): void {
    this._coverageDao.loadAllCoverages(productId);
  }

  updateCoverage(productId: string): void {
    this._coverageDao.updateCoverage(productId);
  }

  updateCoverageFail(productId: string, error: CoverageError[]): void {
    this._coverageDao.updateCoverageFail(productId, error);
  }

  updateCoverageChanges(coverageChanges: CoverageChange[]) {
    this._coverageDao.updateCoverageChanges(coverageChanges);
  }

  getCoverageChanges(): Observable<CoverageChange[]> {
    return this._coverageDao.getCoverageChanges();
  }

  isLifeCoverageProductSelected(): Observable<boolean> {
    return this._coverageDao.isLifeCoverageProductSelected();
  }

  isAutoCoverageProductSelected(): Observable<boolean> {
    return this._coverageDao.isAutoCoverageProductSelected();
  }

  getExtraUpdatedCoverage(): Observable<string> {
    return this._coverageDao.getExtraUpdatedCoverage();
  }

  updateSelectedProductCoverage(productId: string): void {
    return this._coverageDao.updateSelectedProductCoverage(productId);
  }

  updateSelectedCoverageProducts(productIds: string[]): void {
    this._coverageDao.updateSelectedCoverageProducts(productIds);
  }

  getSelectedProductCoverage(): Observable<Product> {
    return this._coverageDao.getSelectedProductCoverage();
  }

  setDwellingConsentChecked(value: boolean): void {
    return this._coverageDao.setDwellingConsentChecked(value);
  }

  getDwellingCoverageConsent(): Observable<boolean> {
    return this._coverageDao.getDwellingCoverageConsent();
  }
}
