import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UmbrellaKnockoutOccupations } from '@shared/constants/app-constants';

@Component({
  selector: 'mq-umbrella-occupations',
  templateUrl: './umbrella-occupations.component.html',
  styleUrls: ['./umbrella-occupations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UmbrellaOccupationsComponent implements OnInit {
  occupations: string[];

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {
    this.occupations = UmbrellaKnockoutOccupations;
  }
}
