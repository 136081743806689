import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  HostListener,
} from '@angular/core';
import { query, animate, style, AnimationBuilder } from '@angular/animations';

@Component({
  selector: 'nw-horizontal-slider',
  templateUrl: './horizontal-slider.component.html',
  styleUrls: ['./horizontal-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalSliderComponent {
  @Input() items: any[];
  @Input() distance: number;
  @Input() maxItems: number;

  scrollPos = 0;
  width: number;
  private readonly animationTime = 300;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.width = window.innerWidth;
  }

  constructor(private _builder: AnimationBuilder, private _elem: ElementRef) {
    this.onResize();
  }

  scrollRight() {
    if (
      this.scrollPos + this.distance * (this.items.length - this.maxItems) ===
      0
    ) {
      return;
    }
    this.scrollPos -= this.distance;
    const animation = this._builder.build([
      query(
        '.items-list',
        animate(this.animationTime, style({ left: `${this.scrollPos}px` }))
      ),
    ]);

    const player = animation.create(this._elem.nativeElement);
    player.play();
  }

  scrollLeft() {
    if (this.scrollPos === 0) {
      return;
    }

    this.scrollPos += this.distance;
    const animation = this._builder.build([
      query(
        '.items-list',
        animate(this.animationTime, style({ left: `${this.scrollPos}px` }))
      ),
    ]);

    const player = animation.create(this._elem.nativeElement);
    player.play();
  }

  show(): boolean {
    const desktopWidth = 650;
    return this.items.length - this.maxItems > 0 && this.width > desktopWidth;
  }
}
