<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Dog Breeds
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <ul>
    <li>Dogs commonly known as Pit Bulls - American Staffordshire Terriers, American Pit Bull Terriers, or Staffordshire Bull Terriers</li>
    <li>Doberman Pinschers</li>
    <li>Rottweilers</li>
    <li>Chow-Chows</li>
    <li>Presa Canarios</li>
    <li>Wolf hybrids</li>
  </ul>
</div>
