import { createSelector } from '@ngrx/store';

import * as fromDsmProducts from '@core/store/selectors/products.selector';
import * as fromHomwowners from '@core/store/selectors/homeowners.selector';
import * as fromRenters from '@core/store/selectors/renters.selector';
import * as fromAddress from '@core/store/selectors/policy-address.selector';
import * as fromCurrentCarrier from '@core/store/selectors/current-carrier.selector';
import * as fromVehicles from '@core/store/selectors/vehicle.selector';
import * as fromPeople from '@core/store/selectors/people.selector';
import * as fromPolicyholder from '@core/store/selectors/policyholder.selector';

import { ProductTypes, DEFAULT_ID } from '@shared/constants/app-constants';
import { LocationExposureEntity } from '@core/models/entities/location-exposure.entity';
import {
  LimitInformationEntity,
  UnderlyingPolicyEntity,
} from '@core/models/entities/underlying-policy.entity';
import { VehicleExposureEntity } from '@core/models/entities/vehicle-exposure.entity';
import { HouseholdMemberEntity } from '@core/models/entities/household-member.entity';
import { PersonEntity } from '@core/models/entities/person.entity';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { PersonViewModel } from '@core/models/entities/driver.entity';

export const buildLocationExposures = createSelector(
  fromDsmProducts.getSelectedProducts,
  fromHomwowners.getHomeowners,
  fromRenters.getRenters,
  fromAddress.getPolicyAddress(DEFAULT_ID),
  (products, homeowners, renters, address) => {
    const exposures: LocationExposureEntity[] = [];
    products.forEach(product => {
      switch (product.id) {
        case ProductTypes.HOMEOWNERS: {
          exposures.push({
            underlyingPolicyNumber: product.policyNumber || product.quoteId,
            policyType: 'HO3',
            locationUsage: 'prim',
            numberOfUnits: '1',
            locationAddress: address,
            dwellingDetails: {
              hasLiabilityLosses: false,
              hasNonDomesticAnimals: false,
              hasPool: false,
              hasTrampoline: false,
              haveDogs: false,
            },
          });
          break;
        }
        case ProductTypes.RENTERS: {
          exposures.push({
            underlyingPolicyNumber: product.policyNumber || product.quoteId,
            policyType: 'HO4',
            locationUsage: 'prim',
            numberOfUnits: renters.unitsInBuilding,
            locationAddress: address,
            dwellingDetails: {
              hasLiabilityLosses: false,
              hasNonDomesticAnimals: false,
              hasPool: false,
              hasTrampoline: false,
              haveDogs: false,
            },
          });
          break;
        }
        case ProductTypes.CONDO: {
          exposures.push({
            underlyingPolicyNumber: product.policyNumber || product.quoteId,
            policyType: 'HO6',
            locationUsage: 'prim',
            numberOfUnits: '1',
            locationAddress: address,
            dwellingDetails: {
              hasLiabilityLosses: false,
              hasNonDomesticAnimals: false,
              hasPool: false,
              hasTrampoline: false,
              haveDogs: false,
            },
          });
          break;
        }
      }
    });
    return exposures;
  }
);

function _buildAutoLimitInformation(state: string): LimitInformationEntity {
  switch (state) {
    case 'CA': {
      return {
        IsAutoCombinedSingleLimit: true,
        isBodilyInjurySufficient: true,
        isUninsuredUnderinsuredMotoristSufficient: true,
      } as LimitInformationEntity;
    }
    default: {
      return {
        isBodilyInjurySufficient: true,
        isPropertyDamageSufficient: true,
        isUninsuredUnderinsuredMotoristSufficient: true,
      } as LimitInformationEntity;
    }
  }
}

function _buildPropertyLimitInformation(state: string): LimitInformationEntity {
  switch (state) {
    // case 'CA': {
    //   return {
    //     isPersonalLiabilitySufficient: true,
    //     hasPersonalInjuryCoverageEndorsement: true
    //   } as LimitInformationEntity;
    // }
    default: {
      return {
        isPersonalLiabilitySufficient: true,
        hasPersonalInjuryCoverageEndorsement: true,
      } as LimitInformationEntity;
    }
  }
}

export const buildUnderlyingPolicies = createSelector(
  fromDsmProducts.getSelectedProducts,
  fromCurrentCarrier.getCurrentCarrierState,
  fromAddress.getPolicyAddress(DEFAULT_ID),
  (products, currentCarrier, address) => {
    return products
      .filter(
        product =>
          product.id !== ProductTypes.UMBRELLA &&
          product.id !== ProductTypes.TERMLIFE &&
          product.id !== ProductTypes.POWERSPORTS
      )
      .map(product => {
        const { state } = address;

        switch (product.id) {
          case ProductTypes.AUTO: {
            return {
              effectiveDate: product.effectiveDate,
              policyType: 'PA',
              underlyingPolicyNumber: product.policyNumber || product.quoteId,
              limitInformation: _buildAutoLimitInformation(state),
              isNationwidePolicy: true,
              currentCarrierName: 'Nationwide_Ext',
            } as UnderlyingPolicyEntity;
          }

          case ProductTypes.RENTERS:
          case ProductTypes.HOMEOWNERS:
          case ProductTypes.CONDO: {
            return {
              policyType: 'HO',
              isNationwidePolicy: true,
              effectiveDate: product.effectiveDate,
              underlyingPolicyNumber: product.policyNumber || product.quoteId,
              limitInformation: _buildPropertyLimitInformation(state),
              currentCarrierName: 'Nationwide_Ext',
            } as UnderlyingPolicyEntity;
          }
        }
      });
  }
);

export const buildVehicleExposures = createSelector(
  fromDsmProducts.getSelectedProducts,
  fromVehicles.getAllVehicles,
  (products, vehicles) => {
    const exposures: VehicleExposureEntity[] = [];
    products.forEach(product => {
      switch (product.id) {
        case ProductTypes.AUTO: {
          vehicles.forEach(vehicle => {
            if (vehicle.productId === product.id) {
              exposures.push({
                hasCompOnly: false,
                underlyingPolicyNumber: product.policyNumber || product.quoteId,
                vehicleType: 'PrivatePass_Ext',
              });
            }
          });
        }
      }
    });

    return exposures;
  }
);

export const buildHouseholdMembers = createSelector(
  fromPeople.getAllPeople,
  fromPolicyholder.getPrimaryNamedInsured,
  (people, pni): HouseholdMemberEntity[] => {
    return people
      .filter(p => !findPniInList(pni, p))
      .map(person => {
        return {
          person: person.person,
          driverStatus: 'PrincipalDriver',
          employerName: 'Something',
          occupation: 'Other',
          hasLiabilityLosses: false,
          infractions: person.infractions,
          prefillId: person.prefillId,
          productId: ProductTypes.UMBRELLA,
          licenseNumber: person.licenseNumber ? person.licenseNumber : '',
          licenseState: person.licenseState ? person.licenseState : '',
        };
      });
  }
);

function findPniInList(
  policyholder: PolicyholderEntity,
  people: PersonViewModel
): PersonViewModel {
  if (peopleMatch(people.person, policyholder.person)) {
    return people.person;
  }
  return null;
}

function hashPerson(person: PersonEntity): string {
  return [
    person.firstName,
    person.middleName,
    person.lastName,
    person.suffix,
    person.dateOfBirth,
  ]
    .map(s => {
      if (s && s.includes('-')) {
        return s.split('-')[0];
      }
      return s ? s.toLowerCase() : '';
    })
    .join(' ');
}

function peopleMatch(a: PersonEntity, b: PersonEntity): boolean {
  return hashPerson(a) === hashPerson(b);
}
