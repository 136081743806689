import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';

@Injectable({
  providedIn: 'root',
})
export class NonStandardBridgeDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  isNonStandardCode(errorCode: string): Observable<string> {
    return this._store.select(fromSelectors.isNonStandardCode(errorCode));
  }

  isNonStandard(): Observable<string> {
    return this._store.select(fromSelectors.isNonStandard);
  }
}
