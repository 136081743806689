import { Injectable } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { take, map } from 'rxjs/operators';

import * as _ from 'lodash';

import {
  NavigationPath,
  NavigationItem,
} from '@core/models/navigation/navigation.model';
import { Product } from '@core/models/products/product.model';

import { NavigationDao } from '@quote/dao/navigation.dao';
import { NavigationDirection } from '@quote/store/reducers/navigation.reducer';
import { ProductsService } from '@core/services';

@Injectable({ providedIn: 'root' })
export class QuoteNavigationService {
  constructor(
    private _navigationDao: NavigationDao,
    private _productsService: ProductsService
  ) {}

  getBaseNavigationPath(): Observable<NavigationPath> {
    return this._navigationDao.buildBaseNavigationPath();
  }

  buildSoftfallNavigationPath(): NavigationPath {
    const path = new NavigationPath();
    path.currentIndex = 0;
    path.items = [
      {
        url: '/softfall',
        enabled: true,
        pages: [],
        title: '',
      },
    ];
    return path;
  }

  loadNavigation(): void {
    this._navigationDao.loadNavigation();
  }

  updateNavigationPath(products: Product[]) {
    const basePath = this._navigationDao.getBasePath();
    const currentPath = this._navigationDao.getCurrentPath();
    const allFailed$ = this._productsService.areAllProductsFailed();

    combineLatest([basePath, currentPath, allFailed$])
      .pipe(take(1))
      .subscribe(([paths, current, allFailed]) => {
        if (allFailed) {
          this._navigationDao.updateNavigationPath(
            this.buildSoftfallNavigationPath()
          );
          return;
        }
        const newPath = { ...paths };
        newPath.items = paths.items
          .map(path => {
            const copy = { ...path };
            products.forEach(product => {
              if (path.pages.includes(product.name)) {
                copy.enabled = true;
              }
            });
            return copy;
          })
          .filter(item => item.enabled);
        newPath.currentIndex = this._selectIndexInRewrittenPath(
          newPath,
          current
        );
        this._navigationDao.updateNavigationPath({ ...newPath });
        this._issueActionIfUrlChanged(newPath, current);
      });
  }

  private _selectIndexInRewrittenPath(
    newPath: NavigationPath,
    previous: NavigationPath
  ): number {
    if (!previous) {
      return 0;
    }
    const previousItem = previous.items[previous.currentIndex];
    if (!previousItem) {
      return 0;
    }
    const indexOfSameUrl = newPath.items.findIndex(
      item => item.url === previousItem.url
    );
    if (indexOfSameUrl >= 0) {
      return indexOfSameUrl;
    }
    /* The page we're looking at is not in the new path!
     * This can happen after an initiate failure.
     * Count the identical members of each list, up to previous index, and return that.
     * Reasoning that we should put the user on the first difference between these two paths.
     */
    let nextIndex = 0;
    for (; nextIndex < previous.currentIndex; nextIndex++) {
      if (nextIndex >= newPath.items.length) {
        // panic
        return 0;
      }
      if (nextIndex >= previous.items.length) {
        break;
      }
      if (newPath.items[nextIndex].url !== previous.items[nextIndex].url) {
        break;
      }
    }
    return nextIndex;
  }

  private _issueActionIfUrlChanged(
    toPath: NavigationPath,
    fromPath: NavigationPath
  ) {
    const toItem = toPath.items[toPath.currentIndex];
    const fromItem = fromPath.items[fromPath.currentIndex];
    if (!toItem || !fromItem) {
      return;
    }
    const toUrl = toItem.url;
    const fromUrl = fromItem.url;
    if (toUrl === fromUrl) {
      return;
    }
    this.go({
      path: [toUrl],
    });
  }

  go(path: any) {
    this._navigationDao.go(path);
  }

  enablePage(pageName: any): Promise<boolean> {
    return new Promise(resolve => {
      const basePath$ = this._navigationDao.getBasePath();
      const currentPath$ = this.getCurrentPath();
      combineLatest([basePath$, currentPath$])
        .pipe(take(1))
        .subscribe(([basePathObj, currentPathObj]) => {
          const newPath = { ...basePathObj };
          newPath.items = basePathObj.items
            .map(path => {
              const copy = { ...path };
              if (
                _.findIndex(
                  currentPathObj.items,
                  cPath => cPath.url === path.url
                ) > -1 ||
                path.pages.includes(pageName)
              ) {
                copy.enabled = true;
              }
              return copy;
            })
            .filter(item => item.enabled);
          const currentPathObject = { ...currentPathObj };
          newPath.currentIndex = currentPathObject.currentIndex;
          this._navigationDao.updateNavigationPath({ ...newPath });
          resolve(true);
        });
    });
  }

  disablePage(pageName: any): Promise<boolean> {
    return new Promise(resolve => {
      const currentPath$ = this.getCurrentPath();
      currentPath$.pipe(take(1)).subscribe(currentPathObj => {
        const newPath = { ...currentPathObj };
        newPath.items = currentPathObj.items
          .map(path => {
            const copy = { ...path };
            if (path.pages.includes(pageName)) {
              copy.enabled = false;
            }
            return copy;
          })
          .filter(item => item.enabled);
        const currentPathObject = { ...currentPathObj };
        newPath.currentIndex = currentPathObject.currentIndex;
        this._navigationDao.updateNavigationPath({ ...newPath });
        resolve(true);
      });
    });
  }

  updateCurrentState(currentUrl: string): void {
    this.getCurrentPath()
      .pipe(take(1))
      .subscribe(path => {
        let currentIndex: number;

        path.items.forEach((item, index) => {
          if (item.url === this.getCurrentUrlWithoutQueryParams(currentUrl)) {
            currentIndex = index;
            return;
          }
        });

        this._navigationDao.updateNavigationPath({
          ...path,
          currentIndex,
        });
      });
  }

  getCurrentPath(): Observable<NavigationPath> {
    return this._navigationDao.getCurrentPath();
  }

  getCurrentPage(): Observable<NavigationItem> {
    return this.getCurrentPath().pipe(
      map(path => {
        return path.items[path.currentIndex];
      })
    );
  }

  navigateForward(): void {
    this.getCurrentPath()
      .pipe(take(1))
      .subscribe(path => {
        let { currentIndex } = path;

        if (currentIndex < path.items.length) {
          currentIndex++;
          this._navigationDao.navigateForward({
            ...path,
            currentIndex,
          });
        }
      });
  }

  navigateBack(): void {
    this.getCurrentPath()
      .pipe(take(1))
      .subscribe(path => {
        let { currentIndex } = path;

        if (currentIndex > 0) {
          currentIndex--;
          this._navigationDao.navigateBack({ ...path, currentIndex });
        }
      });
  }

  forceNavigationIndex(path: string) {
    this._navigationDao.forceNavigationIndex(path);
  }

  getNavigationLoaded(): Observable<boolean> {
    return this._navigationDao.getNavigationLoaded();
  }

  getNavigationLoading(): Observable<boolean> {
    return this._navigationDao.getNavigationLoading();
  }

  getNavigationDirection(): Observable<NavigationDirection> {
    return this._navigationDao.getNavigationDirection();
  }

  navigateInMostRecentDirection() {
    this._navigationDao
      .getNavigationDirection()
      .pipe(take(1))
      .subscribe(direction => {
        if (direction < 0) {
          this.navigateBack();
        } else {
          this.navigateForward();
        }
      });
  }

  private getCurrentUrlWithoutQueryParams(currentUrl: string): string {
    return currentUrl ? currentUrl.split('?')[0] : currentUrl;
  }

  skipCurrentPageNavigation(url: string): void {
    this._navigationDao.skipCurrentPageNavigation(url);
  }

  skipNextPageNavigation(url: string): void {
    this._navigationDao.skipNextPageNavigation(url);
  }
}
