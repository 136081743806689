import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@core/validators/custom-validators';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { DisplayPanelService } from '@shared/components/display-panel/display-panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  SQUARE_FOOTAGE_MIN,
  SQUARE_FOOTAGE_MAX,
} from '@shared/constants/app-constants';
import { MSBHelpText } from '@shared/constants/help-text-constants';

@Component({
  selector: 'mq-additional-property-info-form',
  templateUrl: './additional-property-info-form.component.html',
  styleUrls: ['./additional-property-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalPropertyInfoFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() form: UntypedFormGroup;
  @Input() needConstructionInfo: boolean;
  @Input() reviewConstructionInfo: boolean;
  @Input() coveredLocation: CoveredLocation;
  @Input() set _options(value) {
    if (value) {
      this.options = value;
      this.updateGarageTypePicklistOptions();
    }
  }
  pickList: any;
  formSubmitted: boolean;
  options: any;
  constructionTypeHelpText: string;
  constructionTypeFieldId: string;
  constructionTypelabelText: string;
  exteriorWallsHelpText: string;
  exteriorWallsFieldId: string;
  exteriorWallslabelText: string;
  roofMaterialHelpText: string;
  roofMaterialFieldId: string;
  roofMateriallabelText: string;
  foundationTypesHelpText: string;
  foundationTypesFieldId: string;
  foundationTypeslabelText: string;
  descriptionOfHalfBathsHelpText: string;
  descriptionOfHalfBathsFieldId: string;
  descriptionOfHalfBathslabelText: string;
  descriptionOfFullBathsHelpText: string;
  descriptionOfFullBathsFieldId: string;
  descriptionOfFullBathslabelText: string;
  descriptionOfKitchensHelpText: string;
  descriptionOfKitchensFieldId: string;
  descriptionOfKitchenslabelText: string;
  typeOfGarageHelpText: string;
  typeOfGarageFieldId: string;
  typeOfGaragelabelText: string;
  additionalStructuresHelpText: string;
  additionalStructuresFieldId: string;
  additionalStructureslabelText: string;
  additionalHeatingHelpText: string;
  additionalHeatingFieldId: string;
  additionalHeatinglabelText: string;
  garageTypeNotApplicable: boolean;

  private unsubscribe = new Subject();

  constructor(
    private _displayPanelService: DisplayPanelService,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setValidators();

    this.initMSBHelpText();

    this.form
      .get('garageType')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.onGarageTypeChange(value));

    this.form
      .get('numberOfHalfBathrooms')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.onHalfBathroomCountChange(value));

    this.form
      .get('numberOfFullBathrooms')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.onFullBathroomCountChange(value));

    this.form
      .get('numberOfKitchens')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.onNumberOfKitchensCountChange(value));

    this.form
      .get('numberOfStories')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(value => this.onNumberOfStoriesChange(value));

    this.form.statusChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(status => {
        this._changeDetector.detectChanges();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form || changes.coveredLocation) {
      if (this.form && this.coveredLocation) {
        this.prefillFormData(this.coveredLocation);
      }
    }
  }

  private setValidators() {
    const picklist = control => {
      control.setValidators([
        Validators.required,
        CustomValidators.PicklistRequired,
      ]);
    };

    this.yearBuilt.setValidators([
      Validators.required,
      CustomValidators.yearUpToNow,
    ]);
    this.squareFootage.setValidators([
      Validators.required,
      Validators.min(SQUARE_FOOTAGE_MIN),
      Validators.max(SQUARE_FOOTAGE_MAX),
    ]);

    if (this.showFlatRoof) {
      picklist(this.flatRoof);
    } else {
      this.flatRoof.setValidators([]);
    }

    if (this.showAdditionalHeating) {
      picklist(this.form.get('additionalHeating'));
    } else {
      this.form.get('additionalHeating').setValidators([]);
    }

    this.form.get('halfBathroomDescription').setValidators([]);

    picklist(this.form.get('numberOfStories'));
    picklist(this.form.get('constructionType'));
    picklist(this.form.get('exteriorWalls'));
    picklist(this.form.get('roofMaterial'));
    picklist(this.form.get('numberOfFullBathrooms'));
    picklist(this.form.get('numberOfKitchens'));
    picklist(this.form.get('fullBathroomDescription'));
    picklist(this.form.get('kitchenDescription'));
    picklist(this.form.get('numberOfCars'));
    picklist(this.form.get('garageType'));
  }

  onStructuresPillToggled(e) {
    const structureForm = this.form.get('otherStructures');
    structureForm.get(e.eventName).setValue(e.value);
  }

  onFoundationTypePillToggled(e) {
    const foundationTypeForm = this.form.get('foundationType');
    foundationTypeForm.get(e.eventName).setValue(e.value);
  }

  onSubmit(form: UntypedFormGroup) {
    if (form.status === 'VALID') {
      this.formSubmitted = true;
      this._displayPanelService.next(false);
    } else {
      this.yearBuilt.markAsTouched();
      this.squareFootage.markAsTouched();
      this.exteriorWalls.markAsTouched();
      this.fullBathroomDescription.markAsTouched();
      this.numberOfFullBathrooms.markAsTouched();
      this.kitchenDescription.markAsTouched();
      this.numberOfKitchens.markAsTouched();
      this.constructionType.markAsTouched();
      this.numberOfStories.markAsTouched();
      this.roofMaterial.markAsTouched();
      this.numberOfHalfBathrooms.markAsTouched();
      this.halfBathroomDescription.markAsTouched();
      this.garageType.markAsTouched();
      this.numberOfCars.markAsTouched();
      this.flatRoof.markAsTouched();
      this.additionalHeating.markAsTouched();
    }
  }

  prefillFormData(coveredLocationData: CoveredLocation) {
    const garages =
      (coveredLocationData &&
        coveredLocationData.constructionInfo &&
        coveredLocationData.constructionInfo.garages) ||
      [];

    const constructionInfo =
      this.needConstructionInfo || this.reviewConstructionInfo
        ? {
            ...coveredLocationData.constructionInfo,
            flatRoof: coveredLocationData.flatRoof,
            garageType: garages.length > 0 ? garages[0]['garageType'] : '',
            numberOfCars: garages.length > 0 ? garages[0]['numberOfCars'] : '',
          }
        : { ...coveredLocationData.constructionInfo };

    this.form.patchValue(constructionInfo);
  }
  //   'Please Select' added to form field of garageType in below method is a **hack**.
  //   This approach negates the code that was written to trigger a change in garageType value
  //   based on user selection for the form field asking about number of stories.
  //  Form field validation for garageType is not working if selected value is changed based on user selection for a value in number of stories.

  onGarageTypeChange(value) {
    this.garageTypeNotApplicable = false;
    if (value === '') {
      this.garageType.setValidators([
        Validators.required,
        CustomValidators.PicklistRequired,
      ]);
      this.numberOfCars.setValue('');
      this.numberOfCars.setValidators([
        Validators.required,
        CustomValidators.PicklistRequired,
      ]);
    } else if (value === 'Not Applicable') {
      this.numberOfCars.setValue(null);
      this.garageTypeNotApplicable = true;
      this.numberOfCars.setValidators([]);
    } else {
      this.numberOfCars.setValidators([
        Validators.required,
        CustomValidators.PicklistRequired,
      ]);
      this.garageTypeNotApplicable = false;
      this.numberOfCars.setValue('');
    }
    this.numberOfCars.updateValueAndValidity();
  }

  onHalfBathroomCountChange(value) {
    if (+value) {
      this.halfBathroomDescription.setValidators([Validators.required]);
    } else {
      this.halfBathroomDescription.setValidators([]);
    }
    this.halfBathroomDescription.updateValueAndValidity();
    this.descriptionOfHalfBathslabelText =
      +value === 1
        ? MSBHelpText.descriptionOfHalfBathsInnerHTMLOne
        : MSBHelpText.descriptionOfHalfBathsInnerHTMLOther;
  }

  onFullBathroomCountChange(value) {
    this.descriptionOfFullBathslabelText =
      +value === 1
        ? MSBHelpText.descriptionOfFullBathsInnerHTMLOne
        : MSBHelpText.descriptionOfFullBathsInnerHTMLOther;
  }

  onNumberOfKitchensCountChange(value) {
    this.descriptionOfKitchenslabelText =
      +value === 1
        ? MSBHelpText.descriptionOfKitchensInnerHTMLOne
        : MSBHelpText.descriptionOfKitchensInnerHTMLOther;
  }

  onNumberOfStoriesChange(value) {
    if (value === '1.0') {
      if (
        this.options &&
        this.options.additionalStructures &&
        this.options.additionalStructures.typeOfGarage
      ) {
        const index = this.options.additionalStructures.typeOfGarage.indexOf(
          'Built-in Garage',
          0
        );
        if (index > -1) {
          this.options.additionalStructures.typeOfGarage.splice(index, 1);
        }
      }
    } else {
      if (
        this.options &&
        this.options.additionalStructures &&
        this.options.additionalStructures.typeOfGarage
      ) {
        const index = this.options.additionalStructures.typeOfGarage.indexOf(
          'Built-in Garage',
          0
        );
        if (index === -1) {
          this.options.additionalStructures.typeOfGarage.splice(
            2,
            0,
            'Built-in Garage'
          );
        }
      }
    }
  }

  updateGarageTypePicklistOptions() {
    if (
      this.numberOfStories.value === '1.0' &&
      this.options &&
      this.options.additionalStructures &&
      this.options.additionalStructures.typeOfGarage &&
      this.options.additionalStructures.typeOfGarage.indexOf(
        'Built-in Garage',
        0
      ) > -1
    ) {
      const index = this.options.additionalStructures.typeOfGarage.indexOf(
        'Built-in Garage',
        0
      );
      if (index > -1) {
        this.options.additionalStructures.typeOfGarage.splice(index, 1);
      }
    }
  }

  onCancel() {
    this._displayPanelService.next(false);
  }
  get showFlatRoof() {
    return this.reviewConstructionInfo;
  }
  get showAdditionalHeating() {
    return this.reviewConstructionInfo;
  }
  get yearBuilt() {
    return this.form.get('yearBuilt');
  }
  get squareFootage() {
    return this.form.get('squareFootage');
  }
  get numberOfStories() {
    return this.form.get('numberOfStories');
  }
  get constructionType() {
    return this.form.get('constructionType');
  }
  get exteriorWalls() {
    return this.form.get('exteriorWalls');
  }
  get roofMaterial() {
    return this.form.get('roofMaterial');
  }
  get flatRoof() {
    return this.form.get('flatRoof');
  }
  get additionalHeating() {
    return this.form.get('additionalHeating');
  }
  get numberOfHalfBathrooms() {
    return this.form.get('numberOfHalfBathrooms');
  }
  get numberOfFullBathrooms() {
    return this.form.get('numberOfFullBathrooms');
  }
  get numberOfKitchens() {
    return this.form.get('numberOfKitchens');
  }
  get halfBathroomDescription() {
    return this.form.get('halfBathroomDescription');
  }
  get fullBathroomDescription() {
    return this.form.get('fullBathroomDescription');
  }
  get kitchenDescription() {
    return this.form.get('kitchenDescription');
  }
  get numberOfCars() {
    return this.form.get('numberOfCars');
  }
  get garageType() {
    return this.form.get('garageType');
  }
  get foundationType() {
    return this.form.get('foundationType');
  }
  get otherStructures() {
    return this.form.get('otherStructures');
  }
  initMSBHelpText() {
    this.constructionTypeHelpText = MSBHelpText.ConstructionType;
    this.constructionTypeFieldId = MSBHelpText.constructionTypeFieldId;
    this.constructionTypelabelText = MSBHelpText.constructionTypeInnerHTML;
    this.exteriorWallsHelpText = MSBHelpText.ExteriorWalls;
    this.exteriorWallsFieldId = MSBHelpText.exteriorWallsFieldId;
    this.exteriorWallslabelText = MSBHelpText.exteriorWallsInnerHTML;
    this.roofMaterialHelpText = MSBHelpText.RoofMaterial;
    this.roofMaterialFieldId = MSBHelpText.roofMaterialFieldId;
    this.roofMateriallabelText = MSBHelpText.roofMaterialInnerHTML;
    this.foundationTypesHelpText = MSBHelpText.FoundationTypes;
    this.foundationTypesFieldId = MSBHelpText.foundationTypesFieldId;
    this.foundationTypeslabelText = MSBHelpText.foundationTypesInnerHTML;
    this.descriptionOfHalfBathsHelpText = MSBHelpText.DescriptionOfHalfBaths;
    this.descriptionOfHalfBathsFieldId =
      MSBHelpText.descriptionOfHalfBathsFieldId;
    this.descriptionOfHalfBathslabelText =
      MSBHelpText.descriptionOfHalfBathsInnerHTMLOther;
    this.descriptionOfFullBathsHelpText = MSBHelpText.DescriptionOfFullBaths;
    this.descriptionOfFullBathsFieldId =
      MSBHelpText.descriptionOfFullBathsFieldId;
    this.descriptionOfFullBathslabelText =
      MSBHelpText.descriptionOfFullBathsInnerHTMLOther;
    this.descriptionOfKitchensHelpText = MSBHelpText.DescriptionOfKitchens;
    this.descriptionOfKitchensFieldId =
      MSBHelpText.descriptionOfKitchensFieldId;
    this.descriptionOfKitchenslabelText =
      MSBHelpText.descriptionOfKitchensInnerHTMLOther;
    this.typeOfGarageHelpText = MSBHelpText.TypeOfGarage;
    this.typeOfGarageFieldId = MSBHelpText.typeOfGarageFieldId;
    this.typeOfGaragelabelText = MSBHelpText.typeOfGarageInnerHTML;
    this.additionalStructuresHelpText = MSBHelpText.AdditionalStructures;
    this.additionalStructuresFieldId = MSBHelpText.additionalStructuresFieldId;
    this.additionalStructureslabelText =
      MSBHelpText.additionalStructuresInnerHTML;
    this.additionalHeatingHelpText = MSBHelpText.AdditionalHeating;
    this.additionalHeatingFieldId = MSBHelpText.additionalHeatingFieldId;
    this.additionalHeatinglabelText = MSBHelpText.additionalHeatingInnerHTML;
  }
}
