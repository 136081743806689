import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PolicyDao } from '@core/dao';
import { PolicyEffectiveDates } from '@core/models/policy/policy-effective-date.model';
import { map } from 'rxjs/operators';

@Injectable()
export class PolicyService {
  constructor(private _policyDao: PolicyDao) {}

  setPolicyEffectiveDate(policyEffectiveDates: PolicyEffectiveDates): void {
    return this._policyDao.setPolicyEffectiveDate(policyEffectiveDates);
  }

  getPolicyEffectiveDate(): Observable<PolicyEffectiveDates> {
    return this._policyDao.getPolicyEffectiveDate();
  }

  areEffectiveDatesInitialized(): Observable<boolean> {
    return this._policyDao.getPolicyEffectiveDate().pipe(
      map(res => {
        return res.initialized ? true : false;
      })
    );
  }
}
