import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DriverVehiclePrefillAdapter } from '@core/adapters/driver-vehicle-prefill.adapter';
import { DriverVehiclePrefillDao } from '@core/dao/driver-vehicle-prefill.dao';

import { DriverVehiclePrefillRequest } from '@core/models/driver-vehicle-prefill/driver-vehicle-prefill-request.model';
import { DriverVehiclePrefillResponse } from '@core/models/driver-vehicle-prefill/driver-vehicle-prefill-response.model';
import { PersonViewModel } from '@core/models/entities/driver.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';

@Injectable({
  providedIn: 'root',
})
export class DriverVehiclePrefillService {
  constructor(
    private _driverVehiclePrefillAdapter: DriverVehiclePrefillAdapter,
    private _driverVehiclePrefillDao: DriverVehiclePrefillDao
  ) {}

  customersData(
    request: DriverVehiclePrefillRequest
  ): Observable<DriverVehiclePrefillResponse> {
    return this._driverVehiclePrefillAdapter.customersData(request);
  }

  getDriverVehiclePrefillLoaded(): Observable<boolean> {
    return this._driverVehiclePrefillDao.getDriverVehiclePrefillLoaded();
  }

  getPowersportsDriverVehiclePrefillLoaded(): Observable<boolean> {
    return this._driverVehiclePrefillDao.getPowersportsDriverVehiclePrefillLoaded();
  }

  getCustomerData(): void {
    this._driverVehiclePrefillDao.getCustomerData();
  }

  getCustomerPowersportsData(): void {
    this._driverVehiclePrefillDao.getCustomerPowersportsData();
  }

  getAllPrefillPeople(): Observable<PersonViewModel[]> {
    return this._driverVehiclePrefillDao.getAllPrefillPeople();
  }

  getAllPrefillVehicles(): Observable<VehicleEntity[]> {
    return this._driverVehiclePrefillDao.getAllPrefillVehicles();
  }

  getAllPowersportsPrefillPeople(): Observable<PersonViewModel[]> {
    return this._driverVehiclePrefillDao.getAllPowersportsPrefillPeople();
  }

  getAllPowersportsPrefillVehicles(): Observable<VehicleEntity[]> {
    return this._driverVehiclePrefillDao.getAllPowersportsPrefillVehicles();
  }

  updatePrefillPersonSelected(prefillId: string, selected: boolean): void {
    this._driverVehiclePrefillDao.updatePrefillPersonSelected(
      prefillId,
      selected
    );
  }

  updatePrefillVehicleSelected(prefillId: string, selected: boolean): void {
    this._driverVehiclePrefillDao.updatePrefillVehicleSelected(
      prefillId,
      selected
    );
  }

  updatePowersportsPrefillPersonSelected(
    prefillId: string,
    selected: boolean
  ): void {
    this._driverVehiclePrefillDao.updatePowersportsPrefillPersonSelected(
      prefillId,
      selected
    );
  }

  updatePowersportsPrefillVehicleSelected(
    prefillId: string,
    selected: boolean
  ): void {
    this._driverVehiclePrefillDao.updatePowersportsPrefillVehicleSelected(
      prefillId,
      selected
    );
  }
}
