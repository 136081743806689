import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, switchMap, catchError, filter } from 'rxjs/operators';
import * as fromServices from '@core/services';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { ProductTypes } from '@shared/constants/app-constants';
import { AppState } from '..';
import { Store } from '@ngrx/store';
import { ProductsService } from '@core/services';

@Injectable()
export class ProductsEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _productsService: ProductsService,
    private _productsMetadataService: fromServices.ProductsMetadataService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  loadProducts$ = this._actions$.pipe(
    ofType(fromActions.LOAD_PRODUCTS),
    switchMap(() => this._productsMetadataService.getProducts()),
    map(products => new fromActions.LoadProductsSuccess(products['products'])),
    catchError(error =>
      of(
        new fromActions.LoadProductsFail(this._errorHelper.sanitizeError(error))
      )
    )
  );

  @Effect()
  initiateProductBeginAuto$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_AUTO),
    map(action => new fromActions.InitiateProductBegin(ProductTypes.AUTO))
  );

  @Effect()
  initiateProductBeginHomeowners$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_HOMEOWNERS),
    map(action => new fromActions.InitiateProductBegin(ProductTypes.HOMEOWNERS))
  );

  @Effect()
  initiateProductBeginRenters$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_RENTERS),
    map(action => new fromActions.InitiateProductBegin(ProductTypes.RENTERS))
  );

  @Effect()
  initiateProductBeginCondo$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_CONDO),
    map(action => new fromActions.InitiateProductBegin(ProductTypes.CONDO))
  );

  @Effect()
  initiateProductBeginUmbrella$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_UMBRELLA),
    map(action => new fromActions.InitiateProductBegin(ProductTypes.UMBRELLA))
  );

  @Effect()
  initiateProductEnd$ = this._actions$.pipe(
    ofType<{ type: string; productId: string }>(
      fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_SUCCESS,
      fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_FAIL
    ),
    map(action => new fromActions.InitiateProductEnd(action.productId))
  );

  @Effect()
  checkUmbrellaEligibility$ = this._actions$.pipe(
    ofType(fromActions.CHECK_UMBRELLA_ELIGIBILITY),
    switchMap(_ => this._store.select(fromSelectors.getSelectedProducts)),
    filter(
      products =>
        !!products.find(p => p.id === ProductTypes.UMBRELLA && !p.hasError)
    ),
    filter(products => !this._productsService.isEligibleForUmbrella(products)),
    switchMap(products => {
      const keepProducts = products.filter(p => p.id !== ProductTypes.UMBRELLA);
      return from([
        new fromActions.UpdateSelectedProducts(keepProducts),
        new fromActions.UpdateSelectedCoverageProducts(
          keepProducts.map(p => p.id)
        ),
      ]);
    })
  );

  @Effect()
  initiateRestrictProduct$ = this._actions$.pipe(
    ofType<{ type: string; productId: string }>(
      fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_SUCCESS,
      fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_FAIL
    ),
    map(action => new fromActions.InitiateProductEnd(action.productId))
  );
}
