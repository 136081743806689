import { Action } from '@ngrx/store';
import { PciV2Response } from '@core/models/pci-v2/pci-v2-response.model';
import { PersonEntity } from '@core/models/entities/person.entity';
import { DownPaymentExperienceResponse } from '@core/models/billing/down-payment/down-payment-expereience-response.model';
import { AccountVerifyResponse } from '@core/models/billing/billing-response.model';
import { BillingPaymentPlanModel } from '@core/models/billing/billing.model';

export const UPDATE_BILLING_NAME = '[Billing] Update Billing Name';
export class UpdateBillingName implements Action {
  readonly type = UPDATE_BILLING_NAME;
  constructor(public payload: PersonEntity) {}
}

export const UPDATE_BILLING_ADDRESS = '[Billing] Update Billing Address';
export class UpdateBillingAddress implements Action {
  readonly type = UPDATE_BILLING_ADDRESS;
  constructor(public payload: any) {}
}

export const GET_BILLING_INFO = '[Billing] Get Billing Info';
export class GetBillingInfo implements Action {
  readonly type = GET_BILLING_INFO;
  constructor(public payload: any) {}
}

// DSM
export const LOAD_DSM_DOWN_PAYMENT = '[Billing] Load DSM Down Payment';

export class LoadDsmDownPayment implements Action {
  readonly type = LOAD_DSM_DOWN_PAYMENT;
}

// DSM
export const LOAD_DSM_DOWN_PAYMENT_FAIL =
  '[Billing] Load DSM Down Payment Fail';

export class LoadDsmDownPaymentFail implements Action {
  readonly type = LOAD_DSM_DOWN_PAYMENT_FAIL;
  constructor(public error: any) {}
}

// DSM
export const DSM_DOWN_PAYMENT_SUCCESS = '[Billing] DSM Down Payment Success';

export class DsmDownPaymentSuccess implements Action {
  readonly type = DSM_DOWN_PAYMENT_SUCCESS;
  constructor(public payload: DownPaymentExperienceResponse) {}
}

export const DOWN_PAYMENT_NOT_NEEDED = '[Billing] Down Payment Not Needed';
export class DownPaymentNotNeeded implements Action {
  readonly type = DOWN_PAYMENT_NOT_NEEDED;
  constructor() {}
}

export const RESET_DSM_DOWN_PAYMENT_LOADED =
  '[Billing] Reset DSM Down Payment Loaded';
export class ResetDsmDownPaymentLoaded implements Action {
  readonly type = RESET_DSM_DOWN_PAYMENT_LOADED;
  constructor(public payload: boolean) {}
}

export const UPDATE_BILLING_UNIT_NUMBER =
  '[Billing] Update Billing Unit Number';
export class UpdateBillingUnitNumber implements Action {
  readonly type = UPDATE_BILLING_UNIT_NUMBER;
  constructor(public payload: string) {}
}

export const PREPARE_BANK_CARD_PAYMENT = '[Billing] Prepare Bank Card Payment';
export class PrepareBankCardPayment implements Action {
  readonly type = PREPARE_BANK_CARD_PAYMENT;
  constructor(public payload: { cardNumber: string; cardType: string }) {}
}

export const UPDATE_BILLING_BANKCARD_INFO =
  '[Billing] Update Billing Bank Card Info';
export class UpdateBillingBankCardInfo implements Action {
  readonly type = UPDATE_BILLING_BANKCARD_INFO;
  constructor(public payload: any) {}
}

export const UPDATE_BILLING_BANKACCOUNT_INFO =
  '[Billing] Update Billing Bank Account Info';
export class UpdateBillingBankAccountInfo implements Action {
  readonly type = UPDATE_BILLING_BANKACCOUNT_INFO;
  constructor(public payload: any) {}
}

export const UPDATE_PCI_BANKCARD_DATA_SUCCESS =
  '[Billing] Update Pci BankCard Data';
export class UpdatePciBankCardDataSuccess implements Action {
  readonly type = UPDATE_PCI_BANKCARD_DATA_SUCCESS;
  constructor(public payload: PciV2Response) {}
}

export const UPDATE_PCI_BANKCARD_DATA_FAIL =
  '[Billing] Update Pci BankCard Data Fail';
export class UpdatePciBankCardDataFail implements Action {
  readonly type = UPDATE_PCI_BANKCARD_DATA_FAIL;
  constructor(public payload: any) {}
}

export const RESET_PCI_V2_LOADED = '[Billing] Reset PciV2 Loaded';
export class ResetPciV2Loaded implements Action {
  readonly type = RESET_PCI_V2_LOADED;
  constructor(public payload: boolean) {}
}

// DSM
export const DSM_SETUP_ACCOUNT = '[Billing] DSM Setup Account';
export class DsmSetupAccount implements Action {
  readonly type = DSM_SETUP_ACCOUNT;
}

export const SETUP_ACCOUNT_SUCCESS = '[Billing] Setup Account Success';
export class SetupAccountSuccess implements Action {
  readonly type = SETUP_ACCOUNT_SUCCESS;
  constructor(public payload: any) {}
}

export const SETUP_ACCOUNT_FAIL = '[Billing] Setup Account Fail';
export class SetupAccountFail implements Action {
  readonly type = SETUP_ACCOUNT_FAIL;
  constructor(public payload: any) {}
}

export const UPDATE_ACCOUNTVERIFY_STATUS =
  '[Billing] Update AccountVerify Status';
export class UpdateAccountVerifyStatus implements Action {
  readonly type = UPDATE_ACCOUNTVERIFY_STATUS;
  constructor(public payload: AccountVerifyResponse) {}
}

// DSM
export const LOAD_DSM_DOWN_PAYMENT_FULL_PAY_MULTIPRODUCT =
  '[Billing] Load DSM Down Payment FullPayForMultiProduct';

export class LoadDsmDownPaymentFullPayForMultiProduct implements Action {
  readonly type = LOAD_DSM_DOWN_PAYMENT_FULL_PAY_MULTIPRODUCT;
}

// DSM
export const LOAD_DSM_DOWN_PAYMENT_FULL_PAY_MULTIPRODUCT_FAIL =
  '[Billing] Load DSM Down Payment FullPayForMultiProduct Fail';

export class LoadDsmDownPaymentFullPayForMultiProductFail implements Action {
  readonly type = LOAD_DSM_DOWN_PAYMENT_FULL_PAY_MULTIPRODUCT_FAIL;
  constructor(public error: any) {}
}

// DSM
export const DSM_DOWN_PAYMENT_FULL_PAY_MULTIPRODUCT_SUCCESS =
  '[Billing] DSM Down Payment FullPayForMultiProduct Success';

export class LoadDsmDownPaymentFullPayForMultiProductSuccess implements Action {
  readonly type = DSM_DOWN_PAYMENT_FULL_PAY_MULTIPRODUCT_SUCCESS;
  constructor(public payload: DownPaymentExperienceResponse) {}
}

export const DSM_SETUP_ACCOUNT_FULL_PAY_MULTIPRODUCT =
  '[Billing] DSM Setup Account FullPayForMultiProduct';
export class DsmSetupAccountFullPayForMultiProduct implements Action {
  readonly type = DSM_SETUP_ACCOUNT_FULL_PAY_MULTIPRODUCT;
}

export const SETUP_ACCOUNT_FULL_PAY_MULTIPRODUCT_SUCCESS =
  '[Billing] Setup Account Success FullPayForMultiProduct';
export class SetupAccountFullPayForMultiProductSuccess implements Action {
  readonly type = SETUP_ACCOUNT_FULL_PAY_MULTIPRODUCT_SUCCESS;
  constructor(public payload: any) {}
}

export const SETUP_ACCOUNT_FULL_PAY_MULTIPRODUCT_FAIL =
  '[Billing] Setup Account Fail FullPayForMultiProduct';
export class SetupAccountFullPayForMultiProductFail implements Action {
  readonly type = SETUP_ACCOUNT_FULL_PAY_MULTIPRODUCT_FAIL;
  constructor(public payload: any) {}
}

export const INITIALIZE_BILLING_PAYMENT_PLAN =
  '[Billing] Initialize Billing Payment Plan';
export const INITIALIZE_BILLING_PAYMENT_PLAN_SUCCESS =
  '[Billing] Initialize Billing Payment Plan Success';

export const SET_INITIALIZE_PAYMENT_PLAN_REQUIRED =
  '[Billing] Set Initialize Payment Plan Required';

export const SET_TRIGGER_EDIT_BILLING_POPUP =
  '[Billing] Set Trigger Edit Billing Popup';

export const TRIGGER_BIND_RATE_CALL = '[Billing] Trigger Bind Rate Call';
export const SET_REBIND_WHEN_INVALID_ON_BILLING_CHANGE =
  '[Billing] Set Rebind when invalid on Billing Change';

export class InitializeBillingPaymentPlan implements Action {
  readonly type = INITIALIZE_BILLING_PAYMENT_PLAN;
}
export class InitializeBillingPaymentPlanSuccess implements Action {
  readonly type = INITIALIZE_BILLING_PAYMENT_PLAN_SUCCESS;
  constructor(public payload: BillingPaymentPlanModel) {}
}

export class SetInitializePaymentPlanRequired implements Action {
  readonly type = SET_INITIALIZE_PAYMENT_PLAN_REQUIRED;
  constructor(public payload: boolean) {}
}

export class SetTriggerEditBillingPopup implements Action {
  readonly type = SET_TRIGGER_EDIT_BILLING_POPUP;
  constructor(public payload: boolean) {}
}
export class TriggerBindRateCall implements Action {
  readonly type = TRIGGER_BIND_RATE_CALL;
  constructor(public payload: boolean) {}
}

export class setRebindWhenInvalidOnBillingChange implements Action {
  readonly type = SET_REBIND_WHEN_INVALID_ON_BILLING_CHANGE;
  constructor(public payload: boolean) {}
}
export type BillingActions =
  | UpdateBillingName
  | UpdateBillingAddress
  | GetBillingInfo
  | LoadDsmDownPayment
  | LoadDsmDownPaymentFail
  | DsmDownPaymentSuccess
  | DownPaymentNotNeeded
  | ResetDsmDownPaymentLoaded
  | UpdateBillingUnitNumber
  | PrepareBankCardPayment
  | UpdatePciBankCardDataSuccess
  | UpdatePciBankCardDataFail
  | UpdateBillingBankCardInfo
  | ResetPciV2Loaded
  | UpdateBillingBankAccountInfo
  | DsmSetupAccount
  | SetupAccountSuccess
  | SetupAccountFail
  | UpdateAccountVerifyStatus
  | LoadDsmDownPaymentFullPayForMultiProduct
  | LoadDsmDownPaymentFullPayForMultiProductSuccess
  | LoadDsmDownPaymentFullPayForMultiProductFail
  | DsmSetupAccountFullPayForMultiProduct
  | SetupAccountFullPayForMultiProductSuccess
  | SetupAccountFullPayForMultiProductFail
  | InitializeBillingPaymentPlan
  | InitializeBillingPaymentPlanSuccess
  | SetInitializePaymentPlanRequired
  | setRebindWhenInvalidOnBillingChange
  | SetTriggerEditBillingPopup;
