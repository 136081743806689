import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountoModule } from 'angular2-counto';
import {
  NgbModalModule,
  NgbModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TogglePillModule } from '@shared/components/toggle-pill/toggle-pill.module';
import { CoveragesDiscountsComponent } from '@shared/components/coverages/coverages-discounts/coverages-discounts.component';
import { CoverageErrorComponent } from './coverage-error/coverage-error.component';
import { CoveragePremiumDisplayComponent } from './coverage-premium-display/coverage-premium-display.component';
import { EditCoveragesComponent } from './edit-coverages/edit-coverages.component';
import {
  EditCoverageTableComponent,
  InvalidOptionsPipe,
} from './edit-coverage-table/edit-coverage-table.component';
import { InlineHelpModule } from '../inline-help/inline-help.module';
import { InlineHelpPopupModule } from '../inline-help-popup/inline-help-popup.module';
import { DiscountsComponent } from './discounts/discounts.component';
import { BaseCoverageComponent } from './base-coverage/base-coverage.component';
import { AccordionHeaderComponent } from './edit-coverages/accordion-header/accordion-header.component';
import { EditAutoCoveragesComponent } from './edit-auto-coverages/edit-auto-coverages.component';
import { EditHomeownersCoveragesComponent } from './edit-homeowners-coverages/edit-homeowners-coverages.component';
import { NavigationButtonsModule } from '../navigation-buttons/navigation-buttons.module';
import { VehicleDiscountsComponent } from './vehicle-discounts/vehicle-discounts.component';
import { UnCamelCaseModule } from '@shared/pipes/un-camel-case/un-camel-case.module';
import { TelematicsEnrollmentsComponent } from './telematics-enrollments/telematics-enrollments.component';
import { DisplayPanelModule } from '../display-panel/display-panel.module';
import { TelematicsChangeModalComponent } from './telematics-enrollments/telematics-change-modal/telematics-change-modal.component';
import { TelematicsConfirmationModalComponent } from '@shared/components/coverages/telematics-enrollments/telematics-confirmation-modal/telematics-confirmation-modal.component';
import { TelematicsVideoComponent } from './telematics-enrollments/telematics-video/telematics-video.component';
import { SmartMilesEnrollmentComponent } from './telematics-enrollments/smartmiles-enrollment/smartmiles-enrollment.component';
import { SmartRideEnrollmentComponent } from './telematics-enrollments/smartride-enrollment/smartride-enrollment.component';
import { NoProgramEnrollmentComponent } from './telematics-enrollments/no-program-enrollment/no-program-enrollment.component';
import { TelematicsVehiclePremiumComponent } from './telematics-enrollments/telematics-vehicle-premium/telematics-vehicle-premium.component';
import { ConnectedCarEnrollmentComponent } from './telematics-enrollments/connected-car-enrollment/connected-car-enrollment.component';
import { SelfReportedEnrollmentComponent } from './telematics-enrollments/self-reported-enrollment/self-reported-enrollment.component';
import { PrequalifiedEnrollmentComponent } from './telematics-enrollments/prequalified-enrollment/prequalified-enrollment.component';
import { EditLifeCoveragesComponent } from './edit-life-coverages/edit-life-coverages.component';
import { IsAgentModule } from '@shared/directives/is-agent/is-agent.module';
import { IsCustomerModule } from '@shared/directives/is-customer/is-customer.module';
import { IsHomeOwnerModule } from '@shared/directives/is-homeowner/is-homeowner.module';
import { SafeUrlModule } from '@shared/pipes/safe-url/safe-url.module';
import { QuoteLetterComponent } from './quote-letter/quote-letter.component';
import { NgxMaskModule } from 'ngx-mask';
import {
  AgencyService,
  SessionService,
  PrivateLabelService,
} from '@core/services';
import { TermLifeMembersFormComponent } from './term-life-members-form/term-life-members-form.component';
import { SmartDeviceModalComponent } from './telematics-enrollments/smart-device-modal/smart-device-modal.component';
import { PaymentChartModalComponent } from './telematics-enrollments/payment-chart-modal/payment-chart-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CountoModule,
    NgbModalModule,
    NgbModule,
    TogglePillModule,
    FormsModule,
    ReactiveFormsModule,
    InlineHelpModule,
    InlineHelpPopupModule,
    NavigationButtonsModule,
    UnCamelCaseModule,
    DisplayPanelModule,
    IsAgentModule,
    IsCustomerModule,
    SafeUrlModule,
    IsHomeOwnerModule,
    NgxMaskModule.forRoot(),
    NgbPopoverModule,
  ],
  exports: [
    CoveragesDiscountsComponent,
    CoverageErrorComponent,
    CoveragePremiumDisplayComponent,
    EditCoveragesComponent,
    DiscountsComponent,
    VehicleDiscountsComponent,
    QuoteLetterComponent,
    TermLifeMembersFormComponent,
  ],
  declarations: [
    CoveragesDiscountsComponent,
    CoverageErrorComponent,
    CoveragePremiumDisplayComponent,
    EditCoveragesComponent,
    EditCoverageTableComponent,
    EditHomeownersCoveragesComponent,
    EditLifeCoveragesComponent,
    DiscountsComponent,
    VehicleDiscountsComponent,
    BaseCoverageComponent,
    AccordionHeaderComponent,
    EditAutoCoveragesComponent,
    InvalidOptionsPipe,
    TelematicsEnrollmentsComponent,
    TelematicsChangeModalComponent,
    TelematicsConfirmationModalComponent,
    SmartMilesEnrollmentComponent,
    SmartRideEnrollmentComponent,
    NoProgramEnrollmentComponent,
    TelematicsVehiclePremiumComponent,
    QuoteLetterComponent,
    TelematicsVideoComponent,
    ConnectedCarEnrollmentComponent,
    SelfReportedEnrollmentComponent,
    PrequalifiedEnrollmentComponent,
    TermLifeMembersFormComponent,
    SmartDeviceModalComponent,
    PaymentChartModalComponent,
  ],
  providers: [CurrencyPipe, AgencyService, SessionService, PrivateLabelService],
})
export class CoveragesModule {}
