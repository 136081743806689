import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { TermLifeResponse } from '@core/models/auto/quotes/quote-rating-response.model';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import {
  CoverageEntity,
  CoverageTerm,
  SelectedValue,
} from '@core/models/entities/coverage.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { Product } from '@core/models/products/product.model';
import {
  PremiumComparison,
  TermLifeEntity,
} from '@core/models/termlife/termlife.entity';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import {
  CONDO_RENTERS_DWELLING_MINIMUM,
  CoverageCodes,
  CoverageIds,
  CoveragesMetadata,
  DWELLING_MAX,
  KYPIPPowersportsTermTypes,
  PERSONAL_PROPERTY_MAX,
  ProductTypes,
  StateExceptionCoverageLimit,
  StateSpecificFlagKeys,
  StateStringReplacement,
  UmpdCoverageCashOption,
  VehicleTypes,
  SSL_COVERAGE_STATE,
} from '@shared/constants/app-constants';
import {
  CoverageHelpText,
  StateSpecificCoverageHelpText,
  TRANSPORTATION_EXPENSE_HELP_TEXT,
} from '@shared/constants/help-text-constants';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';

import { CoveredLocationService } from '../covered-location.service';
import { MetadataService } from '../metadata.service';
import { SessionLog } from '@core/models/session-log.model';

@Injectable({ providedIn: 'root' })
export class CoverageHelper {
  biToUmbiMultiplier = 1;
  isUmbiValidOptionState: boolean;

  constructor(
    private currencyPipe: CurrencyPipe,
    private coveredLocationService: CoveredLocationService,
    private metadataService: MetadataService
  ) {
    metadataService
      .getStateSpecificFlag('umbiLimitDoubleBi')
      .subscribe(flag => (this.biToUmbiMultiplier = flag ? 2 : 1));
    metadataService
      .getStateSpecificFlag('umbiValidOption')
      .subscribe(flag => (this.isUmbiValidOptionState = flag ? true : false));
  }

  private excludedCoverageCodes = [
    CoverageCodes.BasicCoverageDeductible,
    CoverageCodes.LossSettlementProvision,
  ];
  private termCodesOfDescriptionsToInclude = [
    CoverageCodes.AllPerilDeductible,
    CoverageCodes.WindHailPerilDeductible,
    CoverageCodes.HurricanePerilDeductible,
    CoverageCodes.PersonalInjuryLimit,
  ];
  private termCodesOfDescriptionsToExclude = [
    CoverageCodes.AllPerilDeductible,
    CoverageCodes.WindHailPerilDeductible,
    CoverageCodes.HurricanePerilDeductible,
  ];
  private termCodesOfTypesToExclude = [CoverageCodes.HurricanePerilDeductible];
  // public discountHelpText: HelpTextEntity = null as HelpTextEntity;

  getSelectedValue(coverage: CoverageEntity, code: string): string {
    if (!coverage || !coverage.selectedValue) {
      return null;
    }
    const selection = coverage.selectedValue.find(v => v.code === code);
    if (!selection) {
      return null;
    }
    return selection.value;
  }

  /**
   * returns a new object to be used as the payload for updating a
   * coverage
   * @param coverageId
   * @param code
   * @param value
   * @param coverableId
   */
  buildSessionLogForCoverageChange(data: CoverageChange): SessionLog {
    return {
      type: 'Recalculate Premium',
      code: data.coverageId,
      reason: 'Coverage Update ',
      value: data.selectedValue[0].value,
    };
  }
  createCoverageChange(data: {
    coverage: CoverageEntity;
    code: string;
    value: string;
    coverableId?: number | string;
    quoteState?: string;
  }): CoverageChange {
    const selectedValues: SelectedValue[] = [];
    if (data.coverage.coverageId === CoverageIds.ServiceLine) {
      selectedValues.push(
        { code: data.code, value: data.value },
        { code: CoverageCodes.ServiceLineDeductible, value: '500' }
      );
    } else if (
      data.quoteState === 'MI' &&
      data.coverage.coverageId === CoverageIds.PIP &&
      data.value === '50000'
    ) {
      selectedValues.push(
        { code: data.code, value: data.value },
        { code: CoverageCodes.PIPOption, value: '7' }
      );
    } else if (
      data.quoteState === 'KY' &&
      data.coverage.coverageId === CoverageIds.PIP &&
      data.code === 'PIPLimit'
    ) {
      if (data.value === 'false') {
        selectedValues.push({ code: 'PIPGuestOnly', value: 'true' });
      } else {
        selectedValues.push({ code: 'PIPGuestOnly', value: 'false' });
      }
    } else if (
      data.coverage.productId === ProductTypes.POWERSPORTS &&
      data.quoteState === 'CO' &&
      data.coverage.coverageId === CoverageIds.UMPD &&
      data.code === 'UMPDOption'
    ) {
      if (data.value === UmpdCoverageCashOption.Value) {
        selectedValues.push({
          code: CoverageCodes.UMPDOption,
          value: UmpdCoverageCashOption.Code,
        });
      }
    } else {
      selectedValues.push({ code: data.code, value: data.value });
      if (data.value !== 'false') {
        this.addSelectedValuesForSingleOptionTerms(
          selectedValues,
          data.coverage,
          data.code
        );
      }
    }
    return {
      productId: data.coverage.productId,
      selectedValue: selectedValues,
      coverageId: data.coverage.coverageId,
      coverageLevel: data.coverage.coverageLevel,
      coverableId: +data.coverableId,
    };
  }

  /**
   * returns a new object to be used as the payload for updating a
   * coverage
   * @param coverageId
   * @param code
   * @param value
   * @param coverableId
   */
  createCoverageChanges(
    coverages: CoverageEntity[],
    coverage: CoverageEntity,
    code: string,
    value: string,
    quoteState?: string
  ): CoverageChange[] {
    // spreadable coverages need to send a coverage change object for each coverable id
    if (this.isSpreadable(coverage)) {
      const coverableIds = coverages
        .filter(cov => cov.coverageId === coverage.coverageId)
        .filter(cov => cov.available)
        .map(cov => cov.coverableId);
      return coverableIds.map(coverableId =>
        this.createCoverageChange({
          coverage,
          code,
          value,
          coverableId,
          quoteState,
        })
      );
    } else {
      const { productId, coverageId, coverageLevel, coverableId } = coverage;
      code = this.modifySelectedValuesCodeForChange(coverage, code, value);
      value = this.modifySelectedValuesValueForChange(
        coverages,
        coverage,
        value
      );
      return [
        this.createCoverageChange({
          coverage,
          coverableId,
          code,
          value,
        }),
      ];
    }
  }

  private addSelectedValuesForSingleOptionTerms(
    selectedValues: SelectedValue[],
    coverage: CoverageEntity,
    termCode: string
  ) {
    if (!coverage.terms) {
      return;
    }
    if (coverage.terms) {
      for (const term of coverage.terms) {
        if (term.code === termCode) {
          continue;
        }
        if (!term.options || term.options.length !== 1) {
          continue;
        }
        selectedValues.push({
          code: term.code,
          value: term.options[0].value,
        });
      }
    }
  }
  updateSSLCoverageOptions(Coverages: CoverageEntity[], quoteState: string) {
    if (SSL_COVERAGE_STATE.NY == quoteState) {
      for (const coverage of Coverages) {
        if (coverage.terms) {
          for (const term of coverage.terms) {
            if (term.code === CoverageCodes.BISuppSpousalLiability) {
              term.options = [
                {
                  value: 'true_BISuppSpousalLiability',
                  priority: '1',
                  description: 'Accept',
                },
                {
                  value: 'false_BISuppSpousalLiability',
                  priority: '2',
                  description: 'Decline',
                },
              ];
            }
          }
        }
      }
      return Coverages;
    }
    return Coverages;
  }
  mergeSpreadableCoverages(coverages: CoverageEntity[]): CoverageEntity[] {
    const result: CoverageEntity[] = [];
    coverages.forEach(coverage => {
      const isSpreadable = this.isSpreadable(coverage);
      const existingSpreadableCoverage = result.find(
        cov => cov.coverageId === coverage.coverageId
      );
      if (coverage.coverageId === 'TREX') {
        coverage.helpText = TRANSPORTATION_EXPENSE_HELP_TEXT;
      }
      if (isSpreadable && existingSpreadableCoverage) {
        const existingCoverageCost =
          existingSpreadableCoverage.coverageCost || [];
        const currCoverageCost = coverage.coverageCost || [];
        const sum = costs =>
          costs
            .map(cost => cost?.actualTermAmount?.amount)
            .reduce((v, a) => v + a, 0);
        const newAmount = sum(existingCoverageCost) + sum(currCoverageCost);
        if (newAmount) {
          existingSpreadableCoverage.coverageCost = [
            {
              actualTermAmount: {
                amount: newAmount,
                currency: 'usd',
              },
            },
          ];
        }
        // Take the first "available" if there is one.
        // Unclear what would happen if term options are different across multiple "available" coverages!
        if (coverage.available && !existingSpreadableCoverage.available) {
          Object.assign(existingSpreadableCoverage, coverage, {
            coverageCost: [
              { actualTermAmount: { amount: newAmount, currency: 'usd' } },
            ],
          });
        }
      } else {
        result.push({ ...coverage });
      }
    });
    return result;
  }

  getCostEstimate(
    coverageTerm: string,
    coverageAmount: string,
    termLife: TermLifeEntity
  ): TermLifeEntity {
    let tenYearTermLife;
    let costEstimate;
    if (
      termLife &&
      termLife.termLifeResponse &&
      termLife.termLifeResponse.ProductVariations
    ) {
      tenYearTermLife = termLife.termLifeResponse.ProductVariations.find(
        termLife => termLife.productTerm === coverageTerm
      );
      if (tenYearTermLife && tenYearTermLife.premiumComparison) {
        const pcObject = tenYearTermLife.premiumComparison.find(
          premiumComparison => premiumComparison.faceAmount === +coverageAmount
        );
        if (pcObject && pcObject.premiumAmount) {
          costEstimate = pcObject.premiumAmount;
        }
      }
    }
    return {
      coverageTerm: coverageTerm,
      coverageAmount: coverageAmount,
      costEstimate: costEstimate,
    };
  }
  mergeSpreadableCoveragesLife(
    termLife: TermLifeEntity,
    productTerm: string
  ): PremiumComparison[] {
    const result: TermLifeResponse = null;
    let tenYearTermLife;
    if (
      termLife.termLifeResponse &&
      termLife.termLifeResponse.ProductVariations
    ) {
      tenYearTermLife = termLife.termLifeResponse.ProductVariations.find(
        termLife => termLife.productTerm === productTerm
      );
      if (tenYearTermLife && tenYearTermLife.premiumComparison) {
        tenYearTermLife = tenYearTermLife.premiumComparison;
      }
    }

    return tenYearTermLife;
  }

  runCoverageRules(
    coverages: CoverageEntity[],
    vehicles: VehicleEntity[],
    quoteState: string,
    isCompRater = false,
    stateSpecificFlags: StateSpecificFlagsObject
  ): CoverageEntity[] {
    let mappedCoverages: CoverageEntity[] = coverages;

    mappedCoverages = this.filterCoverageTermsByCode(mappedCoverages);
    mappedCoverages = this.includeDescriptionsForOnlySomeTerms(
      mappedCoverages,
      stateSpecificFlags
    );
    mappedCoverages = this.excludeTypeFromSomeTerms(
      mappedCoverages,
      stateSpecificFlags
    );
    mappedCoverages = this.removeTextAfterPipeForOptions(mappedCoverages);
    mappedCoverages = this.updateCoverages(mappedCoverages, stateSpecificFlags);
    mappedCoverages = this.updateTermOptions(mappedCoverages);
    mappedCoverages = this.updateSelectedValues(
      mappedCoverages,
      quoteState,
      stateSpecificFlags
    );
    mappedCoverages = this.updateCoverageTermDescriptions(mappedCoverages);
    mappedCoverages = this.addHelpText(mappedCoverages, quoteState);
    mappedCoverages = this.updateCoverageName(mappedCoverages, quoteState);
    mappedCoverages = this.markCompAndCollMandatoryIfLoanPresent(
      mappedCoverages,
      vehicles
    );

    mappedCoverages = this.swapUmpdOptionAndDeductible(
      mappedCoverages,
      stateSpecificFlags
    );

    if (!isCompRater) {
      mappedCoverages = this.markGapUnavailableIfNoLoanOrLease(
        mappedCoverages,
        vehicles
      );
    }

    mappedCoverages = this.markNcrpcollUnavailableIfLessor(
      mappedCoverages,
      vehicles
    );

    mappedCoverages = this.eliminateOptionsFromUneditableTerms(
      mappedCoverages,
      stateSpecificFlags
    );
    mappedCoverages = this.addLabelToDescriptionForUneditableTerms(
      mappedCoverages,
      stateSpecificFlags
    );

    mappedCoverages = this.makeLoiMandatoryForAuto(
      mappedCoverages,
      vehicles,
      stateSpecificFlags
    );
    mappedCoverages = this.swapTermsForKYPIPPowersports(
      mappedCoverages,
      stateSpecificFlags
    );
    mappedCoverages = this.makeDEPIPMandatoryForPowersports(
      mappedCoverages,
      stateSpecificFlags
    );
    mappedCoverages =
      this.isLimitedRoofLossOrBetterRoofRelpacementSelected(mappedCoverages);

    return mappedCoverages;
  }

  isOptionValid(
    value: string,
    coverage: CoverageEntity,
    coverageDisplays: CoverageEntity[],
    state: string
  ): boolean {
    if (coverage.coverageId === 'UMBI') {
      if (
        this.isUmbiValidOptionState &&
        coverage.productId === ProductTypes.UMBRELLA
      ) {
        return true;
      } else {
        const valueSplits = this.splitIntoNumbers(value);
        const selectedBIValueSplits = this.splitIntoNumbers(
          coverageDisplays.find(cvg => cvg.coverageId === 'BI').selectedValue[0]
            .value
        );
        if (
          valueSplits[0] > selectedBIValueSplits[0] * this.biToUmbiMultiplier ||
          (valueSplits[0] === selectedBIValueSplits[0] &&
            valueSplits[1] > selectedBIValueSplits[1] * this.biToUmbiMultiplier)
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else if (coverage.coverageId === 'WCD') {
      return value ===
        coverageDisplays.find(
          (cvg: { coverageId: string }) => cvg.coverageId === 'COLL'
        ).selectedValue[0].value
        ? true
        : false;
    } else if (
      coverage.coverageId === CoverageIds.SectionIDeductibles &&
      state === 'TX'
    ) {
      return value !== 'none' ? true : false;
    } else if (
      coverage.coverageId === 'UIMBI' &&
      coverage.productId === ProductTypes.POWERSPORTS &&
      state === 'WA'
    ) {
      const valueSplits = this.splitIntoNumbers(value);
      const selectedBIValueSplits = this.splitIntoNumbers(
        coverageDisplays.find(cvg => cvg.coverageId === 'BI').selectedValue[0]
          .value
      );
      if (
        valueSplits[0] > selectedBIValueSplits[0] * this.biToUmbiMultiplier ||
        (valueSplits[0] === selectedBIValueSplits[0] &&
          valueSplits[1] > selectedBIValueSplits[1] * this.biToUmbiMultiplier)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  splitIntoNumbers(value: string): number[] {
    return value.split('/').map(str => parseInt(str, 10));
  }

  buildCoverageFormControl(
    _fb: UntypedFormBuilder,
    term: CoverageTerm,
    formValue: string
  ): UntypedFormControl {
    // builds validator for Homeowners Dwelling amt
    const control = _fb.control(formValue);
    if (term.code === CoverageCodes.BasicCoverageLimit) {
      this.coveredLocationService
        .getReconstructionCost()
        .pipe(take(1))
        .subscribe(recCost => {
          if (recCost) {
            control.setValidators([Validators.min(+recCost)]);
          }
        });
    }
    return control;
  }

  private updateCoverageName(coverages: CoverageEntity[], quoteState: string) {
    return coverages.map(coverage => {
      if (
        StateStringReplacement[quoteState] &&
        StateStringReplacement[quoteState][coverage.name]
      ) {
        return {
          ...coverage,
          name: StateStringReplacement[quoteState][coverage.name].replacement,
        };
      }
      return coverage;
    });
  }

  private addHelpText(coverages: CoverageEntity[], quoteState: string) {
    return coverages.map(coverage => {
      let cvgWithHelpText = {
        ...coverage,
        helpText:
          StateSpecificCoverageHelpText[quoteState] &&
          StateSpecificCoverageHelpText[quoteState][coverage.coverageId]
            ? StateSpecificCoverageHelpText[quoteState][coverage.coverageId]
            : CoverageHelpText[coverage.coverageId],
      };
      if (coverage.productId === ProductTypes.POWERSPORTS) {
        const coverageIdSuffix = coverage.coverageId + 'Powersports';
        cvgWithHelpText = {
          ...coverage,
          helpText:
            StateSpecificCoverageHelpText[quoteState] &&
            StateSpecificCoverageHelpText[quoteState][coverageIdSuffix]
              ? StateSpecificCoverageHelpText[quoteState][coverageIdSuffix]
              : StateSpecificCoverageHelpText[quoteState] &&
                StateSpecificCoverageHelpText[quoteState][coverage.coverageId]
              ? StateSpecificCoverageHelpText[quoteState][coverage.coverageId]
              : CoverageHelpText[coverageIdSuffix]
              ? CoverageHelpText[coverageIdSuffix]
              : CoverageHelpText[coverage.coverageId],
        };
      }
      if (coverage.productId === ProductTypes.RENTERS) {
        const coverageIdSuffix = coverage.coverageId + 'Renters';
        cvgWithHelpText = {
          ...coverage,
          helpText:
            StateSpecificCoverageHelpText[quoteState] &&
            StateSpecificCoverageHelpText[quoteState][coverageIdSuffix]
              ? StateSpecificCoverageHelpText[quoteState][coverageIdSuffix]
              : StateSpecificCoverageHelpText[quoteState] &&
                StateSpecificCoverageHelpText[quoteState][coverage.coverageId]
              ? StateSpecificCoverageHelpText[quoteState][coverage.coverageId]
              : CoverageHelpText[coverageIdSuffix]
              ? CoverageHelpText[coverageIdSuffix]
              : CoverageHelpText[coverage.coverageId],
        };
      }
      // adding help text to addtl coverage terms
      if (coverage.terms && coverage.terms.length > 0) {
        const terms = coverage.terms.map((term, index) => {
          if (index > 0) {
            let _helptext =
              StateSpecificCoverageHelpText[quoteState] &&
              StateSpecificCoverageHelpText[quoteState][term.code]
                ? StateSpecificCoverageHelpText[quoteState][term.code]
                : CoverageHelpText[term.code];

            if (term.code === 'TREXOption') {
              if (coverage.selectedValue && coverage.selectedValue.length > 0) {
                const _teOption = coverage.selectedValue.find(
                  x => x.code === term.code
                );
                if (_teOption && _teOption.value === '800') {
                  _helptext = CoverageHelpText[term.code + '2'];
                }
              }
            }
            if (coverage.productId === ProductTypes.POWERSPORTS) {
              const termCodeWithSuffix = term.code + 'Powersports';
              _helptext =
                StateSpecificCoverageHelpText[quoteState] &&
                StateSpecificCoverageHelpText[quoteState][termCodeWithSuffix]
                  ? StateSpecificCoverageHelpText[quoteState][
                      termCodeWithSuffix
                    ]
                  : StateSpecificCoverageHelpText[quoteState] &&
                    StateSpecificCoverageHelpText[quoteState][term.code]
                  ? StateSpecificCoverageHelpText[quoteState][term.code]
                  : CoverageHelpText[term.code];
            }
            return {
              ...term,
              helpText: _helptext,
            };
          }
          return term;
        });
        return { ...cvgWithHelpText, terms };
      }
      return cvgWithHelpText;
    });
  }

  private markCompAndCollMandatoryIfLoanPresent(
    coverages: CoverageEntity[],
    vehicles: VehicleEntity[]
  ) {
    return coverages.map(coverage => {
      switch (coverage.coverageId) {
        case 'COMP':
        case 'COLL':
          {
            const vehicleId = coverage.coverableId;
            const vehicle = vehicles.find(v => v.vehicleId === vehicleId);
            if (vehicle) {
              if (
                vehicle.additionalInterests &&
                vehicle.additionalInterests.length > 0
              ) {
                return {
                  ...coverage,
                  mandatory: true,
                };
              }
            }
          }
          break;
      }
      return coverage;
    });
  }

  private markGapUnavailableIfNoLoanOrLease(
    coverages: CoverageEntity[],
    vehicles: VehicleEntity[]
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      if (coverage.coverageId !== 'GAPCOLL') {
        return coverage;
      }
      const vehicleId = coverage.coverableId;
      const vehicle = vehicles.find(v => v.vehicleId === vehicleId);
      if (!vehicle) {
        return coverage;
      }
      if (
        vehicle.additionalInterests &&
        vehicle.additionalInterests.length > 0
      ) {
        return coverage;
      }
      return {
        ...coverage,
        available: false,
      };
    });
  }

  private markNcrpcollUnavailableIfLessor(
    coverages: CoverageEntity[],
    vehicles: VehicleEntity[]
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      if (coverage.coverageId !== 'NCRPCOLL') {
        return coverage;
      }
      const vehicleId = coverage.coverableId;
      const vehicle = vehicles.find(v => v.vehicleId === vehicleId);
      if (!vehicle) {
        return coverage;
      }
      if (
        !vehicle.additionalInterests ||
        vehicle.additionalInterests.length < 1 ||
        vehicle.additionalInterests[0].additionalInterestType !== 'LESSOR'
      ) {
        return coverage;
      }
      return {
        ...coverage,
        available: false,
      };
    });
  }

  private eliminateOptionsFromUneditableTerms(
    coverages: CoverageEntity[],
    stateSpecificFlags: StateSpecificFlagsObject
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      return {
        ...coverage,
        terms: coverage.terms?.map(term => {
          if (term.editable || this.exception(term.code, stateSpecificFlags)) {
            return term;
          }
          return {
            ...term,
            options: null,
          };
        }),
      };
    });
  }

  private addLabelToDescriptionForUneditableTerms(
    coverages: CoverageEntity[],
    stateSpecificFlags: StateSpecificFlagsObject
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      return {
        ...coverage,
        selectedValue: coverage.selectedValue?.map(selectedValue => {
          const term = coverage.terms?.find(t => t.code === selectedValue.code);
          if (
            !term ||
            term.editable ||
            !term.description ||
            this.exception(term.code, stateSpecificFlags)
          ) {
            return selectedValue;
          }
          const newDescription = `${term.description} ${selectedValue.description}`;
          term.description = '';
          return {
            ...selectedValue,
            description: newDescription,
            value: newDescription,
          };
        }),
      };
    });
  }

  private makeLoiMandatoryForAuto(
    coverages: CoverageEntity[],
    vehicles: VehicleEntity[],
    stateSpecificFlags: StateSpecificFlagsObject
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      if (coverage.coverageId !== CoverageIds.LOI) {
        return coverage;
      }
      const vehicle = vehicles.find(v => v.vehicleId === coverage.coverableId);
      if (!vehicle) {
        return coverage;
      }
      if (
        vehicle.vehicleType === VehicleTypes.AUTO &&
        !stateSpecificFlags.loiNotMandatory
      ) {
        return {
          ...coverage,
          mandatory: true,
        };
      }
      return coverage;
    });
  }

  // Exception to eliminateOptionsFromUneditableTerms
  private exception(
    ex: string,
    stateSpecificFlags: StateSpecificFlagsObject
  ): boolean {
    return (
      ex === CoverageCodes.PIPLimit &&
      stateSpecificFlags.isDeclinePIPLimitCoverageApplicable
    );
  }

  addCoverageSelectionIfPresentAndUnselected(
    coverage: CoverageEntity,
    code: string,
    defaultValue: string
  ): CoverageEntity {
    const term = coverage.terms.find(term => term.code === code);
    if (!term) {
      return coverage;
    }
    const alreadySelected = coverage.selectedValue.find(
      value => value.code === code
    );
    if (alreadySelected) {
      return coverage;
    }
    return {
      ...coverage,
      selectedValue: [...coverage.selectedValue, { code, value: defaultValue }],
    };
  }

  private updateSelectedValues(
    coverages: CoverageEntity[],
    quoteState: string,
    stateSpecificFlags: StateSpecificFlagsObject
  ): CoverageEntity[] {
    const basicCoverageLimitValue = coverages.find(
      cvg => cvg.coverageId === CoverageIds.BasicCoverage
    );
    let personalPropertyBasicCoverageValue = '';
    if (basicCoverageLimitValue && basicCoverageLimitValue.selectedValue) {
      const basicCoverageLimitSelectedValue =
        basicCoverageLimitValue.selectedValue.find(
          selected => selected.code === CoverageCodes.BasicCoverageLimit
        );
      personalPropertyBasicCoverageValue =
        basicCoverageLimitSelectedValue &&
        basicCoverageLimitSelectedValue.value;
    }

    return coverages.map(coverage => {
      // Adding missing selected value for wind hail and hurricane deductible
      if (coverage.coverageId === CoverageIds.SectionIDeductibles) {
        coverage = this.addCoverageSelectionIfPresentAndUnselected(
          coverage,
          CoverageCodes.WindHailPerilDeductible,
          'none'
        );
        coverage = this.addCoverageSelectionIfPresentAndUnselected(
          coverage,
          CoverageCodes.HurricanePerilDeductible,
          'none'
        );
        return coverage;
      }
      // Added for updating NC Silverware coverage

      if (
        stateSpecificFlags.showAdditionalHelpTextForCoverages &&
        personalPropertyBasicCoverageValue &&
        coverage.coverageId === CoverageIds.UnscheduledSilverware &&
        coverage.selectedValue.length === 0
      ) {
        // eslint-disable-next-line no-magic-numbers
        const newSelectedValue = +personalPropertyBasicCoverageValue * 0.25;
        coverage = {
          ...coverage,
          selectedValue: [
            {
              code: CoverageCodes.UnscheduledSilverwareLimit,
              value: newSelectedValue.toString(),
            },
          ],
        };
        return coverage;
      }

      // Update value for Biological Deterioration Coverage for Condo NY
      if (
        coverage.productId === ProductTypes.CONDO &&
        StateExceptionCoverageLimit[quoteState] &&
        coverage.coverageId === CoverageIds.BioDeteriorationDamageCoverage
      ) {
        if (coverage.terms) {
          const nySpecificCoverage = coverage.terms
            .find(
              term =>
                term.code === CoverageCodes.BioDeteriorationDamageCoverageLimit
            )
            .options.find(option => option.value === '20000');
          if (
            nySpecificCoverage &&
            nySpecificCoverage.value ===
              StateExceptionCoverageLimit[quoteState][
                CoverageIds.BioDeteriorationDamageCoverage
              ][CoverageCodes.BioDeteriorationDamageCoverageLimit].value &&
            coverage.selectedValue.length === 0
          ) {
            return {
              ...coverage,
              selectedValue: [
                {
                  code: CoverageCodes.BioDeteriorationDamageCoverageLimit,
                  value: nySpecificCoverage.value,
                  description: nySpecificCoverage.description,
                },
              ],
            };
          }
        }
      }
      // Update the value for Personal property deductible
      if (coverage.coverageId === CoverageIds.UnscheduledPersonalProperty) {
        if (
          coverage.selectedValue &&
          coverage.selectedValue.find(
            selectedValue =>
              selectedValue.code ===
              CoverageCodes.UnscheduledPersonalPropertyDeductible
          )
        ) {
          const selectedValue = coverage.selectedValue.map(
            currSelectedValue => {
              if (
                currSelectedValue.code ===
                  CoverageCodes.UnscheduledPersonalPropertyDeductible &&
                !isNaN(+currSelectedValue.value)
              ) {
                return {
                  ...currSelectedValue,
                  value:
                    this.currencyPipe.transform(
                      +currSelectedValue.value,
                      '',
                      'symbol',
                      '1.0-0'
                    ) + ' deductible (per incident)',
                };
              }
              return currSelectedValue;
            }
          );
          return { ...coverage, selectedValue };
        }
      }

      return coverage;
    });
  }

  private includeDescriptionsForOnlySomeTerms(
    coverages: CoverageEntity[],
    stateSpecificFlags: StateSpecificFlagsObject
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      if (!coverage.terms) {
        return coverage;
      }
      const terms = coverage.terms.map(term => {
        if (
          !this.termCodesOfDescriptionsToInclude.includes(term.code) ||
          (this.termCodesOfDescriptionsToExclude.includes(term.code) &&
            stateSpecificFlags.seperateWindandHailorHurricanesandotherperils)
        ) {
          return { ...term, description: '' };
        }
        return term;
      });
      return { ...coverage, terms };
    });
  }

  private excludeTypeFromSomeTerms(
    coverages: CoverageEntity[],
    stateSpecificFlags: StateSpecificFlagsObject
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      if (!coverage.terms) {
        return coverage;
      }
      const terms = coverage.terms.map(term => {
        if (
          this.termCodesOfTypesToExclude.includes(term.code) &&
          !stateSpecificFlags.seperateWindandHailorHurricanesandotherperils
        ) {
          return { ...term, type: '' };
        }
        return term;
      });
      return { ...coverage, terms };
    });
  }

  /**
   * checks if coverage has a term that is spreadable or
   * if the coverage metadata has spreadable = true
   * @param coverage
   */
  private isSpreadable(coverage: CoverageEntity): boolean {
    const metadata = CoveragesMetadata[coverage.coverageId];
    return (
      (coverage.terms && coverage.terms.some(term => term.spreadable)) ||
      (metadata && metadata.spreadable)
    );
  }

  private filterCoverageTermsByCode(
    coverages: CoverageEntity[]
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      if (!coverage.terms) {
        return coverage;
      }
      const terms = coverage.terms.filter(
        term => !this.excludedCoverageCodes.includes(term.code)
      );
      return {
        ...coverage,
        terms,
      };
    });
  }

  /**
   * returns new array of coverages with the text after pipe character
   * of coverage.terms.options.description removed
   * @param coverages
   */
  private removeTextAfterPipeForOptions(
    coverages: CoverageEntity[]
  ): CoverageEntity[] {
    return coverages.map(coverages => {
      if (!coverages.terms) {
        return coverages;
      }
      const terms = coverages.terms.map(term => {
        if (!term.options) {
          return term;
        }
        const options = term.options.map(option => {
          return {
            ...option,
            description: option.description.split('|')[0].trim(),
          };
        });
        return {
          ...term,
          options,
        };
      });
      return {
        ...coverages,
        terms,
      };
    });
  }

  private updateCoverages(
    coverages: CoverageEntity[],
    stateSpecificFlags: StateSpecificFlagsObject
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      // replace name of section I deductibles
      if (coverage.coverageId === CoverageIds.SectionIDeductibles) {
        return stateSpecificFlags.seperateWindandHailorHurricanesandotherperils
          ? { ...coverage, name: 'All Other Perils' }
          : { ...coverage, name: 'Deductibles' };
      }
      if (coverage.coverageId === CoverageIds.BuildingAdditionsAlterations) {
        return {
          ...coverage,
          name: 'Dwelling (Building Additions & Alterations)',
        };
      }
      if (
        coverage.coverageId === CoverageIds.MDCL &&
        stateSpecificFlags.removeMedicalBenefitsDeclineOption
      ) {
        return { ...coverage, mandatory: true };
      }
      if (
        coverage.coverageId === CoverageIds.BodilyInjury ||
        (coverage.coverageId === CoverageIds.PropertyDamage &&
          stateSpecificFlags.powersportsProductApplicable)
      ) {
        return { ...coverage, mandatory: true };
      }
      return coverage;
    });
  }

  private updateTermOptions(coverages: CoverageEntity[]): CoverageEntity[] {
    return coverages.map(coverage => {
      if (!coverage.terms || !coverage.terms.some(term => !!term.options)) {
        return coverage;
      }

      // replace description for Loss of Use
      if (
        coverage.coverageId === CoverageIds.IncreasedLossOfUse ||
        coverage.coverageId === CoverageIds.UMPD ||
        coverage.coverageId === CoverageIds.ServiceLine
      ) {
        const terms = coverage.terms.map(term => {
          if (!term.options) {
            return term;
          }
          if (term.code === CoverageCodes.IncreasedLossOfUseALS) {
            const options = term.options.map(option => {
              // LOU coverage value recently changed to 1 vs ALS
              if (option.value === 'ALS' || option.value === '1') {
                return { ...option, description: 'Actual Loss Sustained' };
              }
            });
            return { ...term, options };
            // Replace Deductible Number with a string
          } else if (
            term.code === CoverageCodes.UMPDDeductible ||
            term.code === CoverageCodes.ServiceLineDeductible
          ) {
            const options = term.options.map(option => {
              return {
                ...option,
                description: '$' + option.value + ' Deductible',
              };
            });
            return { ...term, options };
          } else if (term.code === CoverageCodes.UMPDOption) {
            const options = term.options.map(option => {
              return {
                ...option,
                value: option.description,
              };
            });
            return { ...term, options };
          }
          return term;
        });
        return { ...coverage, terms };
      }
      return coverage;
    });
  }

  private updateCoverageTermDescriptions(
    coverages: CoverageEntity[]
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      if (!coverage.terms || coverage.terms.length === 1) {
        return coverage;
      }

      // Updating description with 'type' of secondary terms
      const terms = coverage.terms.map((term, index) => {
        if (index > 0 && term.options) {
          // this logic is to cover additional coverage term option values
          // that have true/false values, since for primary coverages true/false
          // are meant for accepting/declining a coverage
          const options = term.options.map(option => {
            const value =
              option.value === 'false' || option.value === 'true'
                ? option.value + '_' + term.code
                : option.value;

            return { ...option, value };
          });
          return { ...term, options };
        }
        return term;
      });
      return { ...coverage, terms };
    });
  }

  addFormValidators(
    productId: string,
    coverageCode: string,
    formControl: UntypedFormControl
  ): void {
    switch (productId) {
      case ProductTypes.RENTERS:
        {
          this._addRentersValidators(coverageCode, formControl);
        }
        break;

      case ProductTypes.CONDO:
        {
          this._addCondoValidators(coverageCode, formControl);
        }
        break;
    }
  }

  qualifiesForUmbrella(
    coverages: CoverageEntity[],
    products: Product[]
  ): boolean {
    let hasError = false;
    products.forEach(product => {
      switch (product.id) {
        case ProductTypes.AUTO: {
          const bodilyInjury = this.findCoverage(
            coverages,
            product,
            CoverageIds.BodilyInjury
          );

          const propertyDamage = this.findCoverage(
            coverages,
            product,
            CoverageIds.PropertyDamage
          );

          const umbi = this.findCoverage(
            coverages,
            product,
            CoverageIds.UninsuredMotoristsBodilyInjury
          );

          const uimbi = this.findCoverage(
            coverages,
            product,
            CoverageIds.UnderinsuredMotoristBodilyInjury
          );

          if (!bodilyInjury || !propertyDamage || (!umbi && !uimbi)) {
            break;
          }

          const biLimit = this.splitIntoNumbers(
            bodilyInjury.selectedValue[0].value
          );
          const pdLimit = this.splitIntoNumbers(
            propertyDamage.selectedValue[0].value
          );
          const umbiLimit = umbi
            ? this.splitIntoNumbers(umbi.selectedValue[0].value)
            : null;
          const uimbiLimit = uimbi
            ? this.splitIntoNumbers(uimbi.selectedValue[0].value)
            : null;

          const BI_MIN = 250000;
          const PD_MIN = 100000;
          const UMBI_MIN = 250000;
          const UIMBI_MIN = 250000;

          if (biLimit[0] < BI_MIN) {
            hasError = true;
          }

          if (pdLimit[0] < PD_MIN) {
            hasError = true;
          }

          if (umbiLimit && umbiLimit[0] < UMBI_MIN) {
            hasError = true;
          }

          if (uimbiLimit && uimbiLimit[0] < UIMBI_MIN) {
            hasError = true;
          }
          break;
        }

        case ProductTypes.CONDO:
        case ProductTypes.RENTERS:
        case ProductTypes.HOMEOWNERS: {
          const personalLiability = this.findCoverage(
            coverages,
            product,
            CoverageIds.PersonalLiability
          );

          if (!personalLiability) {
            break;
          }

          const limit = this.splitIntoNumbers(
            personalLiability.selectedValue[0].value
          );
          const PL_MIN = 300000;
          if (limit[0] < PL_MIN) {
            hasError = true;
          }
        }
      }
    });
    return hasError;
  }

  findCoverage(
    coverages: CoverageEntity[],
    product: Product,
    coverageId: string
  ): CoverageEntity {
    return coverages.find(
      coverage =>
        coverage.coverageId === coverageId && coverage.productId === product.id
    );
  }

  private _addRentersValidators(
    coverageCode: string,
    formControl: UntypedFormControl
  ): void {
    switch (coverageCode) {
      case CoverageCodes.BasicCoverageLimit: {
        this._addValidators(formControl, Validators.max(PERSONAL_PROPERTY_MAX));
      }
    }
  }

  private _addCondoValidators(
    coverageCode: string,
    formControl: UntypedFormControl
  ): void {
    switch (coverageCode) {
      case CoverageCodes.BasicCoverageLimit:
        {
          this._addValidators(
            formControl,
            Validators.max(PERSONAL_PROPERTY_MAX)
          );
        }
        break;
      case CoverageCodes.BuildingAdditionsAlterationsLimit:
        {
          this._addValidators(formControl, Validators.max(DWELLING_MAX));
        }
        break;
    }
  }

  private _addValidators(formControl: UntypedFormControl, ...validators): void {
    formControl.setValidators(validators);
  }

  private modifySelectedValuesCodeForChange(
    coverage: CoverageEntity,
    code: string,
    value: string
  ): string {
    return coverage.coverageId === CoverageIds.MineSubsidence &&
      value === 'true'
      ? CoverageCodes.MineSubsidenceLimit
      : code;
  }

  private modifySelectedValuesValueForChange(
    coverages: CoverageEntity[],
    coverage: CoverageEntity,
    value: string
  ): string {
    return coverage.coverageId === CoverageIds.MineSubsidence &&
      value === 'true'
      ? coverages
          .find(cvg => cvg.coverageId === CoverageIds.BasicCoverage)
          .selectedValue.find(
            selected => selected.code === CoverageCodes.BasicCoverageLimit
          ).value
      : value;
  }

  private swapUmpdOptionAndDeductible(
    coverages: CoverageEntity[],
    stateSpecificFlags: StateSpecificFlagsObject
  ) {
    return coverages.map(coverage => {
      if (coverage.coverageId !== 'UMPD') {
        return coverage;
      }
      if (!stateSpecificFlags[StateSpecificFlagKeys.umpdOption]) {
        return coverage;
      }
      if (!coverage.terms) {
        return coverage;
      }
      const newTerms = [];
      const term0 = coverage.terms.find(term => term.type === 'Limit');
      const term1 = coverage.terms.find(term => term.type === 'Deductible');
      const term2 = coverage.terms.find(term => term.type === 'Option');
      if (!term0 || !term1 || !term2) {
        return coverage;
      }
      newTerms.push(term0);
      newTerms.push(term2);
      newTerms.push(term1);
      return {
        ...coverage,
        terms: newTerms,
      };
    });
  }

  static replaceIncomingCoverages(
    coverages: CoverageEntity[],
    productId: string,
    state: string
  ): CoverageEntity[] {
    coverages.forEach(coverage => {
      if (
        state === 'CO' &&
        productId === ProductTypes.POWERSPORTS &&
        coverage.coverageId === CoverageIds.UMPD &&
        coverage.selectedValue[0].value === UmpdCoverageCashOption.Code
      ) {
        coverage.selectedValue[0].value = UmpdCoverageCashOption.Value;
      }
    });
    return coverages;
  }

  swapTermsForKYPIPPowersports(
    coverages: CoverageEntity[],
    stateSpecificFlags: StateSpecificFlagsObject
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      if (
        coverage.productId !== ProductTypes.POWERSPORTS &&
        coverage.productId !== ProductTypes.AUTO
      ) {
        return coverage;
      }
      if (coverage.coverageId !== CoverageIds.PIP) {
        return coverage;
      }
      if (!stateSpecificFlags.isDeclinePIPLimitCoverageApplicable) {
        return coverage;
      }
      if (!coverage.terms) {
        return coverage;
      }
      const newTerms = [];
      const term0 = coverage.terms.find(
        term => term.type === KYPIPPowersportsTermTypes.DEDUCTIBLE
      );
      const term1 = coverage.terms.find(
        term => term.type === KYPIPPowersportsTermTypes.LIMIT
      );
      const term2 = coverage.terms.find(
        term => term.type === KYPIPPowersportsTermTypes.GUEST
      );
      const pedAccept =
        coverages.find(cov => cov.coverageId === CoverageIds.PEDPIP)
          ?.selectedValue?.[0]?.value === '10000';
      const pipDecline =
        coverages.find(cov => cov.coverageId === CoverageIds.PIP)
          ?.selectedValue?.[0]?.value === 'false';
      const guestAccept = coverage.selectedValue?.[2]?.value === 'true';
      if (!term0 || !term1 || !term2) {
        return coverage;
      }
      if (term1.options[0]) {
        term1.options[0].description = guestAccept ? 'Decline' : '$10,000';
      }
      if (pedAccept && coverage.productId === ProductTypes.POWERSPORTS) {
        newTerms.push(term1);
      } else if (guestAccept) {
        newTerms.push(term1);
        newTerms.push(term2);
      } else {
        newTerms.push(term1);
        newTerms.push(term0);
        newTerms.push(term2);
      }
      return {
        ...coverage,
        terms: newTerms,
      };
    });
  }
  private makeDEPIPMandatoryForPowersports(
    coverages: CoverageEntity[],
    stateSpecificFlags: any
  ): CoverageEntity[] {
    return coverages.map(coverage => {
      if (coverage.coverageId !== CoverageIds.PIP) {
        return coverage;
      }
      if (!stateSpecificFlags.makeDEPIPMandatoryForPowersports) {
        return coverage;
      }
      if (coverage.productId === ProductTypes.POWERSPORTS) {
        return {
          ...coverage,
          mandatory: true,
        };
      }
      return coverage;
    });
  }

  excludeTDIcoverageIfUnavailable(
    coverages: CoverageEntity[]
  ): CoverageEntity[] {
    return coverages.filter(
      c => !(c.coverageId == CoverageIds.TDI && !c.available)
    );
  }

  isLimitedRoofLossOrBetterRoofRelpacementSelected(
    coverages: CoverageEntity[]
  ): CoverageEntity[] {
    //LimitedRoofLoss and BetterRoofReplacement are mutually exclusive
    if (
      coverages.find(
        c =>
          c.coverageId == CoverageCodes.LimitedRoofLoss &&
          c.selectedValue &&
          c.selectedValue[0]?.value == 'true'
      )
    ) {
      return coverages.filter(
        c => !(c.coverageId == CoverageCodes.BetterRoofReplacement)
      );
    }
    if (
      coverages.find(
        c =>
          c.coverageId == CoverageCodes.BetterRoofReplacement &&
          c.selectedValue &&
          c.selectedValue[0]?.value == 'true'
      )
    ) {
      return coverages.filter(
        c => !(c.coverageId == CoverageCodes.LimitedRoofLoss)
      );
    }
    return coverages;
  }

  isWYBIAuto(coverage: CoverageEntity, quoteState: string): boolean {
    return coverage.coverageId === CoverageIds.BodilyInjury &&
      coverage.productId === ProductTypes.AUTO &&
      quoteState === 'WY'
      ? true
      : false;
  }

  handleRulesForWYBIAuto(
    coverages: CoverageEntity[],
    value: string,
    quoteState: string,
    coverageDisplays: CoverageEntity[]
  ): CoverageChange[] {
    const covBI = this.createCoverageChanges(
      coverages,
      coverages.find(cov => cov.coverageId === CoverageIds.BodilyInjury),
      'BILimit',
      value,
      quoteState
    );
    const covUMBI = this.createCoverageChanges(
      coverages,
      coverages.find(cov => cov.coverageId === CoverageIds.UMBI),
      'UMBILimit',
      value,
      quoteState
    );
    const valueSplits = this.splitIntoNumbers(value);
    const selectedUMBIValueSplits = this.splitIntoNumbers(
      coverageDisplays.find(cvg => cvg.coverageId === 'UMBI').selectedValue[0]
        .value
    );
    if (
      selectedUMBIValueSplits[0] > valueSplits[0] * this.biToUmbiMultiplier ||
      (selectedUMBIValueSplits[0] === valueSplits[0] &&
        selectedUMBIValueSplits[1] > valueSplits[1] * this.biToUmbiMultiplier)
    ) {
      return covUMBI.concat(covBI);
    } else {
      return covBI;
    }
  }

  isWABIPowersports(coverage: CoverageEntity, quoteState: string): boolean {
    return coverage.coverageId === CoverageIds.BodilyInjury &&
      coverage.productId === ProductTypes.POWERSPORTS &&
      quoteState === 'WA'
      ? true
      : false;
  }

  handleRulesForWABIPowersports(
    coverages: CoverageEntity[],
    value: string,
    quoteState: string,
    coverageDisplays: CoverageEntity[]
  ): CoverageChange[] {
    const covBI = this.createCoverageChanges(
      coverages,
      coverages.find(cov => cov.coverageId === CoverageIds.BodilyInjury),
      'BILimit',
      value,
      quoteState
    );
    const covUIMBI = this.createCoverageChanges(
      coverages,
      coverages.find(
        cov => cov.coverageId === CoverageIds.UnderinsuredMotoristBodilyInjury
      ),
      'UIMBILimit',
      value,
      quoteState
    );
    const valueSplits = this.splitIntoNumbers(value);
    const selectedUIMBIValueSplits = this.splitIntoNumbers(
      coverageDisplays.find(cvg => cvg.coverageId === 'UIMBI').selectedValue[0]
        .value
    );
    if (
      selectedUIMBIValueSplits[0] > valueSplits[0] * this.biToUmbiMultiplier ||
      (selectedUIMBIValueSplits[0] === valueSplits[0] &&
        selectedUIMBIValueSplits[1] > valueSplits[1] * this.biToUmbiMultiplier)
    ) {
      return covUIMBI.concat(covBI);
    } else {
      return covBI;
    }
  }

  handlePropertyMinCoverageLimit(
    coverage: CoverageEntity,
    control: AbstractControl,
    state: string,
    coverageDisplays
  ) {
    if (
      coverage.coverageId === CoverageIds.BasicCoverage &&
      coverage.productId === ProductTypes.HOMEOWNERS
    ) {
      control.addValidators([Validators.required]);
    }
    if (
      coverage.coverageId === CoverageIds.BasicCoverage &&
      (coverage.productId === ProductTypes.RENTERS ||
        coverage.productId === ProductTypes.CONDO)
    ) {
      control.addValidators([
        Validators.required,
        Validators.min(CONDO_RENTERS_DWELLING_MINIMUM),
      ]);
    }
    if (
      (coverage.coverageId === CoverageIds.BuildingAdditionsAlterations ||
        coverage.coverageId === CoverageIds.IncreasedOtherStructures) &&
      (coverage.productId === ProductTypes.HOMEOWNERS ||
        coverage.productId === ProductTypes.RENTERS ||
        coverage.productId === ProductTypes.CONDO)
    ) {
      const basicCoverageValue = coverageDisplays.find(
        cvg => cvg.coverageId === CoverageIds.BasicCoverage
      ).selectedValue[0].value;
      let minimumBAA = Math.round(+basicCoverageValue * 0.1);
      if (state === 'NC' && coverage.productId === ProductTypes.CONDO) {
        minimumBAA = Math.round(+basicCoverageValue * 0.06666);
      }
      control.addValidators([Validators.required, Validators.min(minimumBAA)]);
    }
    if (
      coverage.coverageId === CoverageIds.UnscheduledPersonalProperty &&
      (coverage.productId === ProductTypes.HOMEOWNERS ||
        coverage.productId === ProductTypes.RENTERS ||
        coverage.productId === ProductTypes.CONDO)
    ) {
      const basicCoverageValue = coverageDisplays.find(
        cvg => cvg.coverageId === CoverageIds.BasicCoverage
      ).selectedValue[0].value;
      const minimumUPP = Math.round(+basicCoverageValue * 0.75);
      control.addValidators([Validators.required, Validators.min(minimumUPP)]);
    }
    if (
      coverage.coverageId === CoverageIds.IncreasedLossOfUse &&
      (coverage.productId === ProductTypes.HOMEOWNERS ||
        coverage.productId === ProductTypes.RENTERS ||
        coverage.productId === ProductTypes.CONDO)
    ) {
      const basicCoverageValue = coverageDisplays.find(
        cvg => cvg.coverageId === CoverageIds.BasicCoverage
      ).selectedValue[0].value;
      let minimumLOU = Math.round(+basicCoverageValue * 0.2);
      if (state === 'NC' && coverage.productId === ProductTypes.CONDO) {
        minimumLOU = Math.round(+basicCoverageValue * 0.4);
      }
      control.addValidators([Validators.required, Validators.min(minimumLOU)]);
    }
  }
}
