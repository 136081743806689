import { Injectable } from '@angular/core';
import { Product } from '@core/models/products/product.model';
import { Observable, of, forkJoin } from 'rxjs';
import { AgencyService } from '.';
import { map, switchMap, take } from 'rxjs/operators';
import { ErrorHelper } from './helpers/error.helper';
import { RecoverableErrorEntity } from '@core/models/recoverable-error/recoverable-error.entity';
import { Alert } from '@core/models/page-alerts/page-alert.model';
import { RecoverableErrorService } from './recoverable-error.service';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(
    private _agencyService: AgencyService,
    private _errorHelper: ErrorHelper,
    private _recoverableErrorService: RecoverableErrorService
  ) {}

  addErrorMessageToProducts(products: Product[]): Observable<Product[]> {
    return this._agencyService.isAgent().pipe(
      switchMap(isAgent => {
        const outputObservables: Observable<Product>[] = [];
        for (const product of products) {
          if (product.hasError && !product.errorMessage) {
            const newProduct = {
              ...product,
            };
            outputObservables.push(
              this._errorHelper
                .getErrorMessageForProduct(product, isAgent)
                .pipe(
                  map(message => {
                    newProduct.errorMessage = message;
                    return newProduct;
                  })
                )
            );
          } else {
            outputObservables.push(of(product));
          }
        }
        return forkJoin(outputObservables);
      })
    );
  }

  addMessagesToCoverageErrors(
    errors: CoverageError[]
  ): Observable<CoverageError[]> {
    return this._agencyService.isAgent().pipe(
      switchMap(isAgent => {
        const outputObservables: Observable<CoverageError>[] = [];
        for (const error of errors) {
          outputObservables.push(
            this._errorHelper
              .getErrorMessageForCode(error.ruleId, isAgent, false)
              .pipe(
                map(message => {
                  if (message) {
                    error.message = message;
                  }
                  return error;
                })
              )
          );
        }
        return forkJoin(outputObservables);
      })
    );
  }

  composeRecoverableError(
    productId: string,
    errorObject: any,
    pageId?: string
  ): Observable<RecoverableErrorEntity> {
    const errorCode =
      this._errorHelper.extractErrorCodeFromAnything(errorObject);
    return this._agencyService.isAgent().pipe(
      take(1),
      switchMap(isAgent => {
        return this._errorHelper.getRecoverableErrorForCode(
          errorCode,
          productId,
          isAgent,
          pageId
        );
      })
    );
  }

  getRecoverableErrorsAsAlert(page: string): Observable<Alert | null> {
    return this._recoverableErrorService.getRecoverableErrorsByPage(page).pipe(
      map(errors =>
        errors.length
          ? {
              id: '',
              type: 'danger',
              message: errors[0].message,
              icon: 'icon error',
              dismissible: false,
            }
          : null
      )
    );
  }

  getDefaultErrorMessage(): string {
    return this._errorHelper.defaultErrorMessage;
  }
}
