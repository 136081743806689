<form [formGroup]="form">
  <div class="row">
    <h1 class="heading-h3 col-12">
      {{ homeownerText.PROPERTY_INFO.TELL_US }}
    </h1>
  </div>
  <div class="row">
    <div class="form-group col-9 col-md-6 select-list-input">
      <legend>
        <label for="separateUnits"
          >How many separate units are contained in the building?</label
        >
      </legend>
      <div class="input-group">
        <select
          id="unitsInBuilding"
          aria-label="Units In Building"
          class="form-control form-select"
          attr.aria-describedby="sUnits-error"
          formControlName="unitsInBuilding"
        >
          <option value="" disabled="disabled" selected="selected">
            Please Select
          </option>
          <option
            *ngFor="let option of pickList?.unitsInBuilding"
            value="{{ option.value }}"
          >
            {{ option.label }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>

      <p class="help-text" id="personalPropertyHelpText1">
        A unit is part of a structure designed as a complete living quarters and
        does not share access with other units within the same structure.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-sm-auto">
      <div class="disclaimer-no-top-margin">
        *This quote assumes the property is primarily a wood-frame structure.
      </div>
    </div>
  </div>
</form>
