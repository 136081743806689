export const DiscountsAvailability = [
  {
    // BEGIN OF HOMEOWNERS
    discountName: 'Age Of Construction',
    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',
    helptext:
      '<p><strong>Age of Construction</strong> – Applied based on the age of the house.</p>',
  },
  {
    discountName: 'Age of Insured',
    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'FL',
      'DE',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',
    helptext:
      '<p><strong>Age of Insured</strong> – Applied based on a person’s age.</p>',
  },
  {
    discountName: 'Claim Free',
    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',
    helptext:
      '<p><strong>Claim Free</strong> – Applied if the insured hasn’t filed any claims.</p>',
  },
  {
    discountName: 'Farm Bureau',
    availability: ['CA', 'DE', 'MD', 'OH', 'WV'],
    product: 'Homeowners',
    helptext:
      '<p><strong>Farm Bureau</strong> – Applied if the insured is a voting member in the bureau (Only for states that offer Farm Bureau).</p>',
  },
  {
    discountName: 'Gated Community',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',
    helptext:
      '<p><strong>Gated Community</strong> – Applied if home is in a qualified gated community.</p>',
  },
  {
    discountName: 'Group Membership',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',
    helptext:
      '<p><strong>Group Membership</strong> – Applied based on affiliation with member (Affinity) groups.</p>',
  },
  {
    discountName: 'Home and Car (Multi-Policy Discount)',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',
    helptext:
      '<p><strong>Home and Car (Multi-Policy Discount)</strong> – Applied if a personal Auto policy is also provided.</p>',
  },
  {
    discountName: 'Home Purchase',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',
    helptext:
      '<p><strong>Home Purchase</strong> – Applied if the house was purchased no more than 13 months prior to policy effective date.</p>',
  },
  {
    discountName: 'Home Renovation',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',
    helptext:
      '<p><strong>Home Renovation</strong> – Applied based on the difference between the year renovated and the effective year of the policy.</p>',
  },
  {
    discountName: 'Marital Status (Personal Status)',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',

    helptext:
      '<p><strong>Marital Status (Personal Status)</strong> – Applied when the insured’s marital status is "married" or "widowed".</p>',
  },
  {
    discountName: 'Multi Line',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',

    helptext:
      '<p><strong>Multi Line</strong> – Applied when insured has a property policy along with an additional qualifying policy.</p>',
  },
  {
    discountName: 'Nationwide Associate',
    availability: [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'MA',
      'ME',
      'MD',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ],
    product: 'Homeowners',
    helptext:
      '<p><strong>Nationwide Associate</strong> – Applied if the insured is a current or retired Nationwide Associate.</p>',
  },
  {
    discountName: 'Prior Insurance',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',

    helptext:
      '<p><strong>Prior Insurance</strong> – Applied based on whether home/car discount applies to the policy, policy tenure and years with prior insurance input on the policy.</p>',
  },
  {
    discountName: 'Protective Devices',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'FL',
      'DE',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',

    helptext:
      '<p><strong>Protective Devices</strong> – Applied based on the type of protective device selected on the policy.</p>',
  },
  {
    discountName: 'Roof Rating Factor',
    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'MD',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Homeowners',
    helptext:
      '<p><strong>Roof Rating Factor</strong> – Applied based on the year renovated, roof, roof type, roof UL Class, and roof thickness.</p>',
  },
  // END OF HOMEOWNERS
  {
    // BEGIN OF CONDO
    discountName: 'Age Of Construction',
    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',
    helptext:
      '<p><strong>Age of Construction</strong> – Applied based on the age of the house.</p>',
  },
  {
    discountName: 'Age of Insured',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'FL',
      'DE',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',
    helptext:
      '<p><strong>Age of Insured</strong> – Applied based on a person’s age.</p>',
  },
  {
    discountName: 'Claim Free',
    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',
    helptext:
      '<p><strong>Claim Free</strong> – Applied if the insured hasn’t filed any claims.</p>',
  },
  {
    discountName: 'Farm Bureau',
    availability: ['CA', 'DE', 'MD', 'OH', 'WV'],
    product: 'Condo',
    helptext:
      '<p><strong>Farm Bureau</strong> – Applied if the insured is a voting member in the bureau (Only for states that offer Farm Bureau).</p>',
  },
  {
    discountName: 'Gated Community',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',
    helptext:
      '<p><strong>Gated Community</strong> – Applied if home is in a qualified gated community.</p>',
  },
  {
    discountName: 'Group Membership',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',
    helptext:
      '<p><strong>Group Membership</strong> – Applied based on affiliation with member (Affinity) groups.</p>',
  },
  {
    discountName: 'Home and Car (Multi-Policy Discount)',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',
    helptext:
      '<p><strong>Home and Car (Multi-Policy Discount)</strong> – Applied if a personal Auto policy is also provided.</p>',
  },
  {
    discountName: 'Home Purchase',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',
    helptext:
      '<p><strong>Home Purchase</strong> – Applied if the house was purchased no more than 13 months prior to policy effective date.</p>',
  },
  {
    discountName: 'Home Renovation',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',
    helptext:
      '<p><strong>Home Renovation</strong> – Applied based on the difference between the year renovated and the effective year of the policy.</p>',
  },
  {
    discountName: 'Marital Status (Personal Status)',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',

    helptext:
      '<p><strong>Marital Status (Personal Status)</strong> – Applied when the insured’s marital status is "married" or "widowed".</p>',
  },
  {
    discountName: 'Multi Line',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',

    helptext:
      '<p><strong>Multi Line</strong> – Applied when insured has a property policy along with an additional qualifying policy.</p>',
  },
  {
    discountName: 'Nationwide Associate',
    availability: [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'MA',
      'ME',
      'MD',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ],
    product: 'Condo',
    helptext:
      '<p><strong>Nationwide Associate</strong> – Applied if the insured is a current or retired Nationwide Associate.</p>',
  },
  {
    discountName: 'Prior Insurance',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',

    helptext:
      '<p><strong>Prior Insurance</strong> – Applied based on whether home/car discount applies to the policy, policy tenure and years with prior insurance input on the policy.</p>',
  },
  {
    discountName: 'Protective Devices',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'FL',
      'DE',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',

    helptext:
      '<p><strong>Protective Devices</strong> – Applied based on the type of protective device selected on the policy.</p>',
  },
  {
    discountName: 'Roof Rating Factor',
    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'MD',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Condo',
    helptext:
      '<p><strong>Roof Rating Factor</strong> – Applied based on the year renovated, roof, roof type, roof UL Class, and roof thickness.</p>',
  },
  // END OF CONDO
  // BEGIN OF AUTO
  {
    discountName: 'Accident Free',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',
    helptext:
      '<p><strong>Accident Free</strong> – Applied to drivers who have five years or more verifiable driving experience, driver is free from major violations in the most recent five years and free of any chargeable accidents in the most recent five years.</p>',
  },
  {
    discountName: 'Accident Prevention',

    availability: [
      'AL',
      'AR',
      'CA',
      'CO',
      'DC',
      'DE',
      'FL',
      'GA',
      'ID',
      'KS',
      'ME',
      'MI',
      'NJ',
      'NV',
      'NY',
      'OR',
      'SC',
      'TN',
      'UT',
      'VA',
      'WA',
      'WV',
    ],
    product: 'Auto',
    helptext:
      '<p><strong>Accident Prevention</strong> – Applied to liability and property damage premiums if a driver has completed a defensive driver course and meets the state criteria.</p>',
  },
  {
    discountName: 'Advance Quote',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',
    helptext:
      '<p><strong>Advance Quote</strong> – Applied to policies bound at least eight days prior to the current policy effective date.</p>',
  },
  {
    discountName: 'Advanced Accident Prevention',

    availability: ['DE'],
    product: 'Auto',
    helptext:
      '<p><strong>Advanced Accident Prevention</strong> - Provides a discount to drivers who have taken an accident prevention course or the advanced accident prevention course.</p>',
  },
  {
    discountName: 'AntiTheft',

    availability: ['FL', 'IL', 'MI', 'NJ', 'NY'],
    product: 'Auto',
    helptext:
      '<p><strong>AntiTheft</strong> - Applied when any passive or active anti theft system and passive or active anti theft device is permanently installed, either as original equipment from the manufacturer or as after market equipment on the vehicle, that is designed to inhibit the theft of the vehicle or its components.</p>',
  },
  {
    discountName: 'Associate',
    availability: [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'MA',
      'ME',
      'MD',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ],
    product: 'Auto',
    helptext:
      '<p><strong>Associate</strong> – Applied if the insured is a current or retired Nationwide Associate.</p>',
  },
  {
    discountName: 'Auto Financial Discount',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',

    helptext:
      '<p><strong>Auto Financial Discount</strong> - Applied for policyholders when we also provide coverage on a Life or Annuity policy.</p>',
  },
  {
    discountName: 'EasyPay',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',

    helptext:
      '<p><strong>Easy Pay</strong> - A $30.00 one time discount available to customers who sign up for recurring EFT for the first time.  The discount applies to the first eligible vehicle listed on the policy and will be removed after one policy period on the first subsequent renewal.</p>',
  },
  {
    discountName: 'Elite Driver',

    availability: ['CA'],
    product: 'Auto',

    helptext:
      '<p><strong>Elite Driver</strong> – Applied if a rated operator qualifies as an Elite Driver.</p>',
  },
  {
    discountName: 'Good Driver',

    availability: ['CA'],
    product: 'Auto',

    helptext:
      '<p><strong>Good Driver</strong> – Applied if a rated operator qualifies as a Good Driver.</p>',
  },
  {
    discountName: 'Good Student',

    availability: ['CA'],
    product: 'Auto',

    helptext:
      '<p><strong>Good Student</strong> – Applied if a driver is a full-time student with 0-8 years driving experience and the driver meets a certain GPA.</p>',
  },
  {
    discountName: 'Group Occupation',

    availability: ['CA'],
    product: 'Auto',

    helptext:
      '<p><strong>Group Occupation</strong> – Applied if the insured is a member of qualified professional group.</p>',
  },
  {
    discountName: 'Multi-policy',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',

    helptext:
      '<p><strong>*Multi-policy</strong> – Applied for policyholders when we also provide coverage on a personal homeowners policy.</p>',
  },
  {
    discountName: 'New Vehicle Discount',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'GA',
      'FL',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',

    helptext:
      '<p><strong>New Vehicle Discount</strong> - Applied only to Private Passenger Autos with model years within the most recent five years.</p>',
  },
  {
    discountName: 'Non Nationwide Homeowner',

    availability: ['AL', 'DE', 'FL', 'MD', 'ME', 'NC', 'SC', 'VA'],
    product: 'Auto',

    helptext:
      '<p><strong>Non Nationwide Homeowner</strong> - Applied when you live in a coastal area where a Nationwide Homeowners policy is not available and you have a homeowner policy with another carrier.</p>',
  },
  {
    discountName: 'Paperless Policy Discount',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',

    helptext:
      '<p><strong>Paperless Policy Discount</strong> - A recurring 5% discount for customers who choose to receive their policy via on-line account.</p>',
  },
  {
    discountName: 'Passive Occupant Restraint Discount',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'GA',
      'FL',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',

    helptext:
      '<p><strong>Passive Occupant Restraint Discount</strong> – Applied to medical payments premium for vehicles equipped with air bag(s) conforming to the federal crash protection requirements.</p>',
  },
  {
    discountName: 'Select Discount',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'GA',
      'FL',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',

    helptext:
      '<p><strong>Select Discount</strong> - Applied to premium, when customer previously had Nationwide Insurance, and has had consecutive terms with current carrier.</p>',
  },
  {
    discountName: 'Self Reported Verified Mileage Program',

    availability: [
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',

    helptext:
      '<p><strong>Self Reported Verified Mileage Program</strong> - Applied for vehicles that verify annual mileage by providing vehicle odometer photos when the vehicle is first enrolled in the program and at each subsequent renewal.</p>',
  },
  {
    discountName: 'SmartRide Connected Car',

    availability: [
      'AR',
      'AZ',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',

    helptext:
      '<p><strong>SmartRide Connected Car</strong> - The SmartRide Connected Car program enables vehicles to be rated with a discount on new business. The discount will apply as long as vehicle remains on the policy under the SmartRide Connected Car program.</p>',
  },
  {
    discountName: 'SmartRide Program',

    availability: ['AL', 'CA', 'TN'],
    product: 'Auto',

    helptext:
      '<p><strong>SmartRide Program</strong> - Applied for vehicles that verify annual mileage through the use of a telematics device.</p>',
  },
  {
    discountName: '*Student Away Discount',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Auto',

    helptext:
      '<p><strong>*Student Away Discount</strong> - Applied when college student is living away from home and does not have a vehicle on campus.</p>',
  },
  // END OF AUTO
  // BEGIN OF POWERSPORTS
  {
    discountName: 'Advance Quote',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Powersports',
    helptext:
      '<p><strong>Advance Quote</strong> – Applied to policies bound at least eight days prior to the current policy effective date.</p>',
  },
  {
    discountName: 'Accident Free',

    availability: ['MD'],
    product: 'Powersports',
    helptext:
      '<p><strong>Accident Free</strong> – Applied to drivers who have three years or more verifiable driving experience, driver is free from major violations in the most recent three years and free of any chargeable accidents in the most recent three years.</p>',
  },
  {
    discountName: 'Good Driver',

    availability: ['CA'],
    product: 'Powersports',

    helptext:
      '<p><strong>Good Driver</strong> – Applied if a rated operator qualifies as a Good Driver.</p>',
  },
  {
    discountName: 'Accident Prevention',

    availability: [
      'AL',
      'CA',
      'DE',
      'FL',
      'KS',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'TN',
      'WV',
      'WY',
    ],
    product: 'Powersports',
    helptext:
      '<p><strong>Accident Prevention</strong> – Applied to liability and property damage premiums if a driver has completed a defensive driver course and meets the state criteria.</p>',
  },
  // END OF POWERSPORTS
  // BEGIN OF RENTERS
  {
    discountName: 'Associate',
    availability: [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'MA',
      'ME',
      'MD',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ],
    product: 'Renters',
    helptext:
      '<p><strong>Associate</strong> – Applied if the insured is a current or retired Nationwide Associate.</p>',
  },
  {
    discountName: 'Claim Free',
    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Renters',
    helptext:
      '<p><strong>Claim Free</strong> – Applied if the insured hasn’t filed any claims.</p>',
  },
  {
    discountName: 'Home and Car (Multi-Policy Discount)',

    availability: [
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ],
    product: 'Renters',
    helptext:
      '<p><strong>Home and Car (Multi-Policy Discount)</strong> – Applied if a personal Auto policy is also provided.</p>',
  },
  // END OF RENTERS
];

export const DiscountsAvailabilityHelpText = {
  labelInnerHTML:
    '<font color=#575757>Information on all available discounts</font>',
  AGENT: [
    '<p>*Not all discounts can be obtained by using this tool. Please transfer to PolicyCenter for Multi-Policy and Student Away discounts.</p>',
  ],
  DIRECT: [
    '<p>*Not all discounts can be obtained by using this tool. Please call {phoneNumber} for more information</p>',
  ],
};
