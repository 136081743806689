import { Action } from '@ngrx/store';

export const enum rentersPropertyInfoActionsTypes {
  LOAD_PICKLIST = '[renters propertyInfo] Load renters pick list values',
}

export class LoadPickListRenters implements Action {
  readonly type = rentersPropertyInfoActionsTypes.LOAD_PICKLIST;
  constructor(public payload: any) {}
}

export type RentersPropertyInfoActions = LoadPickListRenters;
