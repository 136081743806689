import * as fromAgent from '@core/store/selectors/agency.selector';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState, getAppState } from '@core/store/reducers/index';
import { adapter } from '@core/store/reducers/products.reducer';
import { Product } from '@core/models/products/product.model';
import * as fromCoverage from '@core/store/selectors/coverage.selector';
import { ProductStatus } from '@core/models/products/product-status';
import {
  ProductTypes,
  ProductGroups,
  RoofConditionOptions,
  UtmMedium,
  UtmSource,
  RIVIAN_THIRDPARTY_ID,
} from '@shared/constants/app-constants';
import { getAgencyProducts } from './private-label.selector';
import { ProductUtils } from '@shared/utils/product.utils';
import {
  isNSSAgent,
  isAgent,
  isEAOrIAAgentQuoting,
  isthirdParty,
} from '@core/store/selectors/agency.selector';
import * as fromSession from '@core/store/selectors/session.selector';
import * as fromMetaData from '@core/store/selectors/metadata.selector';
import * as fromCondo from '@core/store/selectors/condo.selector';
import { getStateSpecificFlagsObject } from '@core/store/selectors/metadata.selector';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import { TFN_Lookup } from '@shared/constants/tfn-lookup.constants';
import { StringUtils } from '@shared/utils/string.utils';

export const getProductsState = createSelector(
  getAppState,
  (state: AppState) => state.products
);

export const { selectAll: getAllProducts, selectEntities: getProductsEntites } =
  adapter.getSelectors(getProductsState);

export const getSelectedProductsIds = createSelector(
  getProductsState,
  state => state.selectedProducts
);

export const getAvailableProducts = createSelector(
  getAllProducts,
  getAgencyProducts,
  getStateSpecificFlagsObject,
  isNSSAgent,
  isEAOrIAAgentQuoting,
  isAgent,
  isthirdParty,
  fromSession.getQuoteState,
  (
    products,
    agencyProducts,
    stateSpecificFlags,
    isNSSAgent,
    isEAOrIAAgentQuoting,
    isAgent,
    isthirdParty,
    quoteState
  ) => {
    return ProductUtils.filteredProducts(
      products,
      agencyProducts,
      stateSpecificFlags,
      isNSSAgent,
      isEAOrIAAgentQuoting,
      isAgent,
      isthirdParty,
      quoteState
    );
  }
);

export const getAddonProducts = createSelector(
  getAvailableProducts,
  getSelectedProductsIds,
  (products, ids) =>
    products.filter(
      product =>
        product.type === ProductGroups.ADDON && !ids.includes(product.id)
    )
);

export const getSelectedProductEntities = createSelector(
  getSelectedProductsIds,
  getProductsEntites,
  (ids, entities) => {
    const entity: Product = {};
    ids.forEach(id => {
      entity[id] = entities[id];
    });
    return entity;
  }
);

export const getSelectedProducts = createSelector(
  getSelectedProductsIds,
  getProductsEntites,
  (ids, entities) => ids.map(id => entities[id])
);

export const getSelectedProductsForHeader = createSelector(
  getSelectedProductsIds,
  getProductsEntites,
  (ids, entities) =>
    ids.filter(id => id !== ProductTypes.TERMLIFE).map(id => entities[id])
);

export const getSelectedProductsForDirectHeader = createSelector(
  getSelectedProductsForHeader,
  fromCoverage.getCoverageProducts,
  (products, coverageProductIds) => {
    if (!coverageProductIds || !coverageProductIds.length) {
      return products;
    }
    return products.filter(p => coverageProductIds.find(cp => cp === p.id));
  }
);

export const isProductSelectedWithoutError = (
  productType: string
): MemoizedSelector<unknown, boolean> =>
  createSelector(
    getSelectedProducts,
    products => !!products.find(p => p.id === productType && !p.hasError)
  );

export const isProductsSelectedWithoutError = (productTypes: string[]) =>
  createSelector(
    getSelectedProducts,
    products => !!products.filter(product => productTypes.includes(product.id))
  );

export const isProductSelected = (
  name: string // caution! (name), not (id)
) =>
  createSelector(
    getSelectedProducts,
    products => !!products.find(p => p.name === name)
  );

export const getNonRentalPropertyProductSelectedWithoutError = () =>
  createSelector(getSelectedProducts, products => {
    return !!products.find(p => p.id === ProductTypes.HOMEOWNERS && !p.hasError)
      ? ProductTypes.HOMEOWNERS
      : !!products.find(p => p.id === ProductTypes.CONDO && !p.hasError)
      ? ProductTypes.CONDO
      : null;
  });

export const getPropertyProductSelectedWithoutError = () =>
  createSelector(getSelectedProducts, products => {
    return !!products.find(p => p.id === ProductTypes.HOMEOWNERS && !p.hasError)
      ? ProductTypes.HOMEOWNERS
      : !!products.find(p => p.id === ProductTypes.CONDO && !p.hasError)
      ? ProductTypes.CONDO
      : !!products.find(p => p.id === ProductTypes.RENTERS && !p.hasError)
      ? ProductTypes.RENTERS
      : null;
  });

export const isAnyPropertyProductSelected = () =>
  createSelector(getSelectedProducts, products => {
    return !!products.find(
      p =>
        p.id === ProductTypes.HOMEOWNERS ||
        p.id === ProductTypes.CONDO ||
        p.id === ProductTypes.RENTERS
    )
      ? true
      : false;
  });

export const getSelectedPropertyProduct = createSelector(
  getSelectedProducts,
  products =>
    products.find(
      product =>
        product.id === ProductTypes.HOMEOWNERS ||
        product.id === ProductTypes.CONDO ||
        product.id === ProductTypes.RENTERS
    )
);

export const getVehicleProducts = createSelector(
  getSelectedProducts,
  products => {
    return products.filter(
      product => product.name === 'Auto' || product.name === 'Powersports'
    );
  }
);

export const getProductsLoaded = createSelector(
  getProductsState,
  (state): boolean => state.loaded
);

export const getProductsLoading = createSelector(
  getProductsState,
  (state): boolean => state.loading
);

export const getProduct = (productId: string) =>
  createSelector(
    getProductsEntites,
    (entities): Product => entities[productId]
  );

export const getLifeQuoteId = createSelector(getSelectedProducts, products => {
  return !!products.find(p => p.id === ProductTypes.TERMLIFE)
    ? products.find(p => p.id === ProductTypes.TERMLIFE).quoteId
    : null;
});

export const getDsmPropertyProduct = createSelector(
  getSelectedProducts,
  products =>
    products.find(p => p.id === (ProductTypes.HOMEOWNERS || ProductTypes.CONDO))
);

export const getQuoteLoaded = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteLoaded
  );

export const getAnyQuoteLoaded = createSelector(
  getAllProducts,
  products => !!products.find(p => p.quoteLoaded)
);

export const getQuoteLoading = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteLoading
  );

export const getNeedConstructionInfo = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.needConstructionInfo
  );

export const getQuoteCoverageLoaded = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteCoverageLoaded
  );

export const getAllQuoteCoverageLoaded = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.allQuoteCoverageLoaded
  );

export const getQuoteCoverageLoading = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteCoverageLoading
  );

export const getQuoteRated = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteRated
  );

export const getQuoteRating = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteRating
  );

export const getQuoteCompleted = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteCompleted
  );

export const getQuoteBound = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteBound
  );

export const getQuoteBinding = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteBinding
  );

export const getBindCompleted = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.bindCompleted
  );

export const getQuoteUpdateLoading = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteUpdateLoading
  );

export const getQuoteUpdateLoaded = (productId: string) =>
  createSelector(
    getProduct(productId),
    (product: Product): boolean => product.quoteUpdateLoaded
  );

export const getIssueCompleted = (productId: string) =>
  createSelector(getProduct(productId), product => product.issueCompleted);

export const isQuoteCompleted = createSelector(
  getSelectedProducts,
  products => {
    const pending = products.filter(p => !p.quoteCompleted && !p.hasError);
    if (pending.length) {
      return false;
    }
    const completed = products.filter(p => p.quoteCompleted);
    return completed.length > 0;
  }
);

export const getSelectedProductCoverage = createSelector(
  fromCoverage.getCoverageSelectedProduct,
  getSelectedProductEntities,
  (selectedProductId, products) => {
    return products[selectedProductId];
  }
);

export const getSelectedCoverageProducts = createSelector(
  fromCoverage.getCoverageProducts,
  getProductsEntites,
  (coverageProductIds, products) =>
    coverageProductIds.map(productId => products[productId])
);

export const getSelectedQuoteProductsWithoutErrors = createSelector(
  getSelectedProducts,
  selectedProducts =>
    selectedProducts.filter(p => !p.hasError && p.id !== ProductTypes.TERMLIFE)
);

export const getSelectedQuoteProducts = createSelector(
  getSelectedProducts,
  selectedProducts => selectedProducts
);

export const getSelectedProductsWithoutErrors = createSelector(
  getSelectedProducts,
  getSelectedCoverageProducts,
  (selectedProducts, bindProducts) => {
    return selectedProducts.filter(
      product =>
        bindProducts.map(bindProduct => bindProduct.id).includes(product.id) &&
        !product.hasError
    );
  }
);

// TODO: Life is not purchasable and needs to be filtered out here
export const getPurchasableSelectedBindProductsWithoutErrors = createSelector(
  getSelectedProductsWithoutErrors,
  selectedProducts => {
    return selectedProducts.filter(product => product);
  }
);

export const getIssuedProducts = createSelector(
  getSelectedProductsWithoutErrors,
  products =>
    products.filter(product => product.issueCompleted && product.policyNumber)
);

export const isIssueCompleted = createSelector(
  getSelectedProductsWithoutErrors,
  products => {
    const bool = !!products.find(product => !product.issueCompleted);
    return !bool;
  }
);

export const isIssueLoadedOrFailed = createSelector(
  getSelectedProductsWithoutErrors,
  products => {
    return products.every(
      product => product.issueCompleted || product.issueFailed
    );
  }
);

export const isDsmProductBindSelected = (id: string) =>
  createSelector(getSelectedProductsWithoutErrors, products => {
    const ids = products.map(product => product.id);
    return ids.includes(id);
  });

export const isBindCompleted = createSelector(
  getSelectedProductsWithoutErrors,
  products => {
    return products.every(product => product.bindCompleted);
  }
);

export const getStatusOfAllProducts = createSelector(
  getSelectedProducts,
  products => products.map(product => new ProductStatus(product))
);

export const getStatusOfAllProductsWithoutErrors = createSelector(
  getSelectedProductsWithoutErrors,
  products => products.map(product => new ProductStatus(product))
);

export const getDwellingCoverageConsent = createSelector(
  fromCoverage.getCoverageState,
  coverage => coverage.dwellingCoverageConsent
);

export const arePreBindDocumentsAcknowledged = createSelector(
  getSelectedProductsWithoutErrors,
  products => {
    const autoProduct = products.find(p => p.id === ProductTypes.AUTO);
    return autoProduct
      ? autoProduct.hasCustomerViewedDocuments &&
          autoProduct.hasReceivedRequiredDocuments
      : false;
  }
);

export const areAllProductsFailed = createSelector(
  getSelectedProducts,
  products =>
    products.length > 0 && !products.find(product => !product.hasError)
);

export const anyProductIsInitiating = createSelector(
  getAllProducts,
  products => !!products.find(p => p.initiating)
);

export const getIssuedProduct = (productId: string) =>
  createSelector(getIssuedProducts, products =>
    products.find(product => product.id === productId)
  );

export const getProductsWithPolicyNumber = createSelector(
  getSelectedProductsWithoutErrors,
  products => products.filter(product => product.policyNumber)
);

export const isMonoQuoteAllowed = createSelector(
  getSelectedProductsWithoutErrors,
  fromSession.getIgnoreProductDisablementState,
  fromAgent.isDirectUser,
  getStateSpecificFlagsObject,
  (
    selectedProducts,
    isIgnoreProductDisablement,
    isDirectUser,
    stateSpecificFlags
  ) => {
    if (
      isDirectUser &&
      !isIgnoreProductDisablement &&
      !stateSpecificFlags.isNoPlayStateAllProductsRestricted &&
      !stateSpecificFlags.isNoPlayStateTermLifeRestricted &&
      !stateSpecificFlags.isStatePausedRestriction
    )
      return false;
    else {
      return true;
    }
  }
);

export const isCAorNYStateQuoteAllowed = createSelector(
  getSelectedProductsWithoutErrors,
  fromSession.getIgnoreProductDisablementState,
  fromAgent.isDirectUser,
  fromSession.getQuoteState,
  getStateSpecificFlagsObject,
  (
    selectedProducts,
    isIgnoreProductDisablement,
    isDirectUser,
    quoteState,
    stateSpecificFlag
  ) => {
    if (
      isDirectUser &&
      !isIgnoreProductDisablement &&
      stateSpecificFlag.isStatePausedRestriction
    ) {
      return false;
    } else {
      return true;
    }
  }
);
export const isNonGenderSpecifiedAutoOnly = createSelector(
  getSelectedProductsWithoutErrors,
  fromMetaData.getStateSpecificFlag('nonSpecifiedGenderApplicableAutoOnly'),
  (selectedProducts, nonSpecifiedGenderApplicableAutoOnly) => {
    const isAutoSelected = selectedProducts.find(
      product => product.id === ProductTypes.AUTO
    );
    if (isAutoSelected && nonSpecifiedGenderApplicableAutoOnly) {
      return true;
    }
    return false;
  }
);

export const isNoPlayStateTermLifeRestricted = createSelector(
  getStateSpecificFlagsObject,
  fromAgent.isDirectUser,
  (stateSpecificFlags, isDirectUser) => {
    if (isDirectUser && stateSpecificFlags.isNoPlayStateTermLifeRestricted) {
      return true;
    }
    return false;
  }
);

export const isNoPlayStatePetRestricted = createSelector(
  getStateSpecificFlagsObject,
  fromAgent.isDirectUser,
  (stateSpecificFlags, isDirectUser) => {
    if (isDirectUser && stateSpecificFlags.isNoPlayStatePetRestricted) {
      return true;
    }
    return false;
  }
);

export const isPauseStatePetRestricted = createSelector(
  getStateSpecificFlagsObject,
  fromAgent.isDirectUser,
  (stateSpecificFlags, isDirectUser) => {
    if (isDirectUser && stateSpecificFlags.isPauseStatePetRestricted) {
      return true;
    }
    return false;
  }
);

export const isPetUnavailableState = createSelector(
  getStateSpecificFlagsObject,
  fromAgent.isDirectUser,
  (stateSpecificFlags, isDirectUser) => {
    if (isDirectUser && stateSpecificFlags.isPetUnavailableState) {
      return true;
    }
    return false;
  }
);

export const isNoPlayStateAllProductsRestricted = createSelector(
  getSelectedProductsWithoutErrors,
  fromSession.getIgnoreProductDisablementState,
  fromAgent.isDirectUser,
  getStateSpecificFlagsObject,
  (
    selectedProducts,
    isIgnoreProductDisablement,
    isDirectUser,
    stateSpecificFlags
  ) => {
    if (
      isDirectUser &&
      !isIgnoreProductDisablement &&
      stateSpecificFlags.isNoPlayStateAllProductsRestricted &&
      selectedProducts.length !== 0
    ) {
      return true;
    }
    return false;
  }
);
export const isNonGenderSpecifiedNonProperty = createSelector(
  getSelectedProductsWithoutErrors,
  fromMetaData.getStateSpecificFlag('nonSpecifiedGenderNonProperty'),
  (selectedProducts, nonSpecifiedGenderApplicableNonProperty) => {
    const isPropertyProductAvailable = selectedProducts.every(
      product =>
        product.id === ProductTypes.HOMEOWNERS ||
        product.id === ProductTypes.CONDO ||
        product.id === ProductTypes.RENTERS
    );
    if (
      (isPropertyProductAvailable && nonSpecifiedGenderApplicableNonProperty) ||
      nonSpecifiedGenderApplicableNonProperty === false
    ) {
      return false;
    }
    return true;
  }
);

export const isRoofConditionForCondoApplicable = createSelector(
  fromCondo.getCondo,
  condo => {
    return (
      condo &&
      condo.roofCondition &&
      condo.roofCondition === RoofConditionOptions.PoorOrNeedsRepair
    );
  }
);

export const get_Tfn = createSelector(
  getSelectedProductsWithoutErrors,
  isMonoQuoteAllowed,
  fromSession.getIgnoreProductDisablementState,
  fromAgent.isDirectUser,
  fromUserContext.getUserContext,
  (
    SelectedProducts,
    isMonoQuoteAllowed,
    isIgnoreProductDisablement,
    isDirectUser,
    userContext
  ) => {
    if (
      !isMonoQuoteAllowed &&
      isDirectUser &&
      !isIgnoreProductDisablement &&
      userContext?.telephoneNumber
    ) {
      return StringUtils.formatTelephoneNumber(userContext.telephoneNumber);
    } else if (
      !isIgnoreProductDisablement &&
      (userContext.spid === 'fordinsure' || userContext.spid === 'lmcinsure')
    ) {
      if (
        userContext?.utm_medium &&
        userContext?.utm_medium.toLowerCase() === UtmMedium.email &&
        userContext?.telephoneNumber
      ) {
        const TFN = TFN_Lookup.find(
          p =>
            p.utm_medium === userContext.utm_medium.toLowerCase() &&
            p.tfn.replace(/\D/g, '') ===
              userContext.telephoneNumber.replace(/\D/g, '')
        );
        return TFN?.tfn;
      }
    } else {
      return null;
    }
  }
);

export const getMonoline_Tfn = createSelector(
  getSelectedProductsWithoutErrors,
  isMonoQuoteAllowed,
  fromSession.getIgnoreProductDisablementState,
  fromAgent.isDirectUser,
  fromUserContext.getUserContext,
  (
    SelectedProducts,
    isMonoQuoteAllowed,
    isIgnoreProductDisablement,
    isDirectUser,
    userContext
  ) => {
    if (!isMonoQuoteAllowed && isDirectUser && !isIgnoreProductDisablement) {
      const ProductsSelected = SelectedProducts.map(p => {
        if (p.id) {
          return p.id;
        }
      });
      if (userContext?.telephoneNumber) {
        return StringUtils.formatTelephoneNumber(userContext.telephoneNumber);
      }
      if (
        userContext?.utm_medium &&
        (userContext?.utm_medium === UtmMedium.organic ||
          userContext?.utm_medium === UtmMedium.cpc)
      ) {
        const TFN = TFN_Lookup.find(
          p =>
            p.utm_medium === userContext.utm_medium &&
            p.productsId.length === ProductsSelected.length &&
            p.productsId.every((id, index) => id === ProductsSelected[index])
        );
        return TFN.tfn;
      } else {
        const TFN = TFN_Lookup.find(
          p =>
            p.utm_medium === 'else' &&
            p.productsId.length === ProductsSelected.length &&
            p.productsId.every((id, index) => id === ProductsSelected[index])
        );
        return TFN.tfn;
      }
    } else {
      return null;
    }
  }
);

export const arePreBindPowersportsDocumentsAcknowledged = createSelector(
  getSelectedProductsWithoutErrors,
  products => {
    const powersportsProduct = products.find(
      p => p.id === ProductTypes.POWERSPORTS
    );
    return powersportsProduct
      ? powersportsProduct.hasCustomerViewedDocuments &&
          powersportsProduct.hasReceivedRequiredDocuments
      : false;
  }
);
export const isPropertyOnlySelected = createSelector(
  getSelectedProductsWithoutErrors,
  fromUserContext.getUserContext,
  (products, usercontext) => {
    return (
      products &&
      products.length === 1 &&
      (products[0].id === ProductTypes.HOMEOWNERS ||
        products[0].id === ProductTypes.CONDO ||
        products[0].id === ProductTypes.RENTERS) &&
      usercontext.thirdPartyId !== RIVIAN_THIRDPARTY_ID &&
      usercontext.spid !== 'fordinsure'
    );
  }
);

export const isHomeOwnersOnlySelected = createSelector(
  getSelectedProducts,
  products => {
    return (
      products &&
      products.length === 1 &&
      products[0].id === ProductTypes.HOMEOWNERS
    );
  }
);

export const thirdPartyPropertyPause = createSelector(
  getSelectedPropertyProduct,
  fromSession.getIgnoreProductDisablementState,
  fromAgent.isthirdParty,
  fromAgent.isPrivateLabel,
  getStateSpecificFlagsObject,
  (
    isPropertySelected,
    isIgnoreProductDisablement,
    isthirdParty,
    isPrivateLabel,
    stateSpecificFlags
  ) => {
    if (
      (isPrivateLabel || isthirdParty) &&
      !isIgnoreProductDisablement &&
      stateSpecificFlags.thirdPartyPropertyPause &&
      isPropertySelected
    )
      return true;
    else {
      return false;
    }
  }
);
