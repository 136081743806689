import * as fromRenters from '@core/store/actions/renters.action';

import { RentersModel } from '@core/models/renters/renters.model';
import { SeparateUnit } from '@core/models/renters/separateunits.model';
import { StructuralMaterial } from '@core/models/renters/structuralmaterials.model';

export interface RentersState {
  separateUnits: {
    data: SeparateUnit[];
    loaded: boolean;
    loading: boolean;
  };

  structuralMaterials: {
    data: StructuralMaterial[];
    loaded: boolean;
    loading: boolean;
  };

  entities: { [id: string]: RentersModel };
  rentersloaded: boolean;
  rentersloading: boolean;
}

export const initialState: RentersState = {
  separateUnits: {
    data: [],
    loaded: false,
    loading: false,
  },

  structuralMaterials: {
    data: [],
    loaded: false,
    loading: false,
  },

  entities: {},
  rentersloaded: false,
  rentersloading: false,
};

export function reducer(
  state = initialState,
  action: fromRenters.RentersActions
): RentersState {
  switch (action.type) {
    case fromRenters.LOAD_RENTERS_SPE_UNITS: {
      const separateUnits = { ...state.separateUnits, loading: true };

      return {
        ...state,
        separateUnits,
      };
    }

    case fromRenters.LOAD_RENTERS_SPE_UNITS_FAIL: {
      const separateUnits = {
        ...state.separateUnits,
        loading: false,
        loaded: false,
      };

      return {
        ...state,
        separateUnits,
      };
    }

    case fromRenters.LOAD_RENTERS_SPE_UNITS_SUCCESS: {
      const data = action.payload;
      const separateUnits = {
        ...state.separateUnits,
        data,
        loading: false,
        loaded: true,
      };

      return {
        ...state,
        separateUnits,
      };
    }

    case fromRenters.LOAD_RENTERS_STR_MATERIALS: {
      const structuralMaterials = {
        ...state.structuralMaterials,
        loading: true,
      };

      return {
        ...state,
        structuralMaterials,
      };
    }

    case fromRenters.LOAD_RENTERS_STR_MATERIALS_FAIL: {
      const structuralMaterials = {
        ...state.structuralMaterials,
        loading: false,
        loaded: false,
      };

      return {
        ...state,
        structuralMaterials,
      };
    }

    case fromRenters.LOAD_RENTERS_STR_MATERIALS_SUCCESS: {
      const data = action.payload;
      const structuralMaterials = {
        ...state.structuralMaterials,
        data,
        loading: false,
        loaded: true,
      };

      return {
        ...state,
        structuralMaterials,
      };
    }

    case fromRenters.LOAD_RENTERS: {
      return {
        ...state,
        rentersloaded: false,
        rentersloading: true,
      };
    }

    case fromRenters.LOAD_RENTERS_FAIL: {
      return {
        ...state,
        rentersloaded: false,
        rentersloading: false,
      };
    }
    case fromRenters.LOAD_RENTERS_SUCCESS: {
      const renters = action.payload;

      const entities = { [renters.id]: renters };

      return {
        ...state,
        rentersloaded: true,
        rentersloading: false,
        entities,
      };
    }

    case fromRenters.UPDATE_RENTERS: {
      const renters = {
        ...state.entities[action.payload.id],
        ...action.payload,
      };

      const entities = {
        ...state.entities,
        [renters.id]: renters,
      };

      return {
        ...state,
        entities,
      };
    }
  }

  return state;
}
export const getSeparateUnits = (state: RentersState) => state.separateUnits;
export const getStructuralMaterials = (state: RentersState) =>
  state.structuralMaterials;

export const getRentersEntities = (state: RentersState) => state.entities;
export const getRentersLoading = (state: RentersState) => state.rentersloading;
export const getRentersLoaded = (state: RentersState) => state.rentersloaded;
