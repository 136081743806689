<div class="nw-container nw-inner-bun">
  <mq-search-form
    (formSubmit)="onSubmit($event)"
    [errorMessage]="error$ | async"
  ></mq-search-form>

  <div *ngIf="(searchQuotesResult$ | async)?.length">
    <hr />
    <div class="row">
      <h4 class="col-sm-auto">Search results</h4>
    </div>
    <div class="row">
      <span style="font-style: italic"
        >Select one or multiple product quotes for the same customer</span
      >
    </div>
    <div class="row">
      <div class="form-group form-inline">
        <div *ngIf="selectedQuotesError$ | async" class="row error-alert">
          <div class="header alert alert-danger col" role="alert">
            <a class="icon error"></a>
            <span>{{ selectedQuotesError$ | async }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <table class="table table-striped">
        <caption>
          List of Search Results
        </caption>
        <thead class="thead-light">
          <tr>
            <th scope="col">Select</th>
            <th scope="col" sortable="creationTime" (sort)="onSort($event)">
              Submission date
            </th>
            <th scope="col" sortable="quoteId" (sort)="onSort($event)">
              Submission ID
            </th>
            <th scope="col" sortable="lastName" (sort)="onSort($event)">
              Last name
            </th>
            <th scope="col" sortable="firstName" (sort)="onSort($event)">
              First name
            </th>
            <th
              scope="col"
              sortable="address.postalCode"
              (sort)="onSort($event)"
            >
              Zip code
            </th>
            <th scope="col" sortable="phoneNumber" (sort)="onSort($event)">
              Phone number
            </th>
            <th scope="col" sortable="lineOfBusiness" (sort)="onSort($event)">
              Product
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let quote of searchQuotes$ | async" scope="row">
            <td>
              <input
                type="checkbox"
                [id]="quote.quoteId"
                (click)="quoteSelected(quote)"
                [checked]="isChecked(quote)"
                [disabled]="isDisabled(quote)"
              />
            </td>
            <td>
              <ngb-highlight
                [result]="quote.creationTime | date: 'short'"
                [term]="service.searchTerm"
              ></ngb-highlight>
            </td>
            <td>
              <ngb-highlight
                [result]="quote.quoteId"
                [term]="service.searchTerm"
              ></ngb-highlight>
            </td>
            <td>
              <ngb-highlight
                [result]="quote.lastName"
                [term]="service.searchTerm"
              ></ngb-highlight>
            </td>
            <td>
              <ngb-highlight
                [result]="quote.firstName"
                [term]="service.searchTerm"
              ></ngb-highlight>
            </td>
            <td>
              <ngb-highlight
                [result]="quote.address.postalCode.slice(0, 5)"
                [term]="service.searchTerm"
              ></ngb-highlight>
            </td>
            <td>
              <ngb-highlight
                [result]="quote.phoneNumber"
                [term]="service.searchTerm"
              ></ngb-highlight>
            </td>
            <td>
              <ngb-highlight
                [result]="quote.lineOfBusiness | retrieveProductNames"
                [term]="service.searchTerm"
              ></ngb-highlight>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="form-group form-inline col justify-content-start">
        <a href="" (click)="!!deselectAll()">Deselect all</a>
      </div>
      <div class="form-group form-inline col justify-content-center">
        <ngb-pagination
          [collectionSize]="total$ | async"
          [(page)]="service.page"
          [pageSize]="service.pageSize"
          [rotate]="true"
          [maxSize]="5"
        >
        </ngb-pagination>
      </div>
      <div class="form-group form-inline col justify-content-end">
        Results per page&nbsp;
        <select
          class="form-select"
          style="width: auto"
          name="pageSize"
          [(ngModel)]="service.pageSize"
        >
          <option [ngValue]="6">6</option>
          <option [ngValue]="15">15</option>
          <option [ngValue]="25">25</option>
          <option [ngValue]="75">75</option>
        </select>
      </div>
    </div>
  </div>
</div>
<div *ngIf="(searchQuotes$ | async)?.length">
  <nw-navigation-buttons
    (forward)="onForward()"
    [forwardOnly]="true"
    [isAppLoading]="loading$ | async"
    [submitButtonText]="'Retrieve Quotes'"
  ></nw-navigation-buttons>
</div>
