import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '@core/services/app-config.service';
import { ISplunkLog } from '@core/services/logging.service';
import { KafkaService } from '@core/services/kafka.service';
import { StringUtils } from '@shared/utils/string.utils';
import { Observable } from 'rxjs';

@Injectable()
export class LoggingCloudAdapter {
  private readonly DELIVERY_TIMEOUT = 1000;
  private readonly OUTGOING_MESSAGE_LENGTH_LIMIT = 50000;

  // Splunk rejects requests longer than 200k.
  // Somewhere short of that, truncate messages even if it means breaking JSON format.
  private readonly PANIC_TRUNCATION_SIZE = 150000;

  private queuedMessages: string[] = [];
  private messagesInFlight: string[] = [];
  private timeoutId: any = null;

  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService,
    private _kafkaService: KafkaService
  ) {}

  private readonly stripNewRelicCruftFromErrorEvents = function (key, value) {
    switch (key) {
      case '__previousCurrentTarget':
      case '__composed':
      case '__composedPath':
      case '__nrNode':
      case '__target':
        return null;
    }
    return value;
  };

  logToSplunkCloud(log: ISplunkLog) {
    const eventText = JSON.stringify(
      log,
      this.stripNewRelicCruftFromErrorEvents
    );
    this._addEventTextToQueue(eventText);
  }

  // Send a request with everything we've got queued, even if another is in flight.
  // Do this when the user closes her browser.
  lastChanceSynchronize() {
    if (this.queuedMessages.length < 1) {
      return;
    }
    const lastChanceMessages = this.queuedMessages;
    this.queuedMessages = [];
    this._sendHttpRequest(lastChanceMessages).subscribe(
      () => {},
      error => {
        this.queuedMessages.splice(0, 0, ...lastChanceMessages);
      },
      () => {}
    );
  }

  private _addEventTextToQueue(eventText: string) {
    if (eventText.length > this.PANIC_TRUNCATION_SIZE) {
      eventText = `"CONTENT OMITTED DUE TO LENGTH ${eventText.length}"`;
    }
    this.queuedMessages.push(eventText);
    this._callApiLater();
  }

  private _callApiLater() {
    if (this.timeoutId) {
      return;
    }
    this.timeoutId = window.setTimeout(() => {
      this._callApiNow();
    }, this.DELIVERY_TIMEOUT);
  }

  private _callApiNow() {
    if (!this._moveMessagesFromQueueToInFlight()) {
      return;
    }
    this._sendHttpRequest(this.messagesInFlight).subscribe(
      () => {},
      error => {
        this._returnMessagesInFlightToQueue();
        this.timeoutId = null;
        this._callApiLater(); // if you don't at first succeed...
      },
      () => {
        this.messagesInFlight = [];
        this.timeoutId = null;
        if (this.queuedMessages.length) {
          // Lastly, retrigger later if we've collected any log entries since sending the request.
          this._callApiLater();
        }
      }
    );
  }

  private _moveMessagesFromQueueToInFlight(): boolean {
    if (this.queuedMessages.length < 1) {
      return false;
    }
    if (this.messagesInFlight.length > 0) {
      throw new Error(
        `Log synchronization triggered with a call still in flight`
      );
    }
    const countToSend = this._countQueuedMessagesToSend();
    this.messagesInFlight.splice(
      0,
      0,
      ...this.queuedMessages.splice(0, countToSend)
    );
    return true;
  }

  _countQueuedMessagesToSend(): number {
    let count = 0,
      size = 0;
    while (count < this.queuedMessages.length) {
      const nextLength = this.queuedMessages[count].length;
      if (!count || size + nextLength < this.OUTGOING_MESSAGE_LENGTH_LIMIT) {
        count++;
        size += nextLength;
      } else {
        break;
      }
    }
    return count;
  }

  private _returnMessagesInFlightToQueue() {
    this.queuedMessages.splice(
      this.queuedMessages.length,
      0,
      ...this.messagesInFlight
    );
    this.messagesInFlight = [];
  }

  private _sendHttpRequest(messages: string[]) {
    const sessionId = StringUtils.generateUUID();
    const url = this._appConfig.config.splunkCloudApiUrl;
    const headers = new HttpHeaders()
      .set('client_id', this._appConfig.config.apiKey)
      .set('Content-Type', 'application/json')
      .set('X-Nw-Transaction-Id', sessionId);

    const body = {
      splunkToken: this._appConfig.config.splunkCloudToken,
      logs: messages.map(text => ({
        event: text,
        fields: '{}',
        sourcetype: 'dgs_mpse_json',
      })),
    };

    this._kafkaService.publish(messages, sessionId);
    return this.log(url, body, headers);
  }

  private log(url: string, body: any, headers: HttpHeaders): Observable<any> {
    return this._http.post(url, body, { headers });
  }
}
