import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';

export const getLoadingState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.loading
);

export const getAppLoading = createSelector(
  getLoadingState,
  state => state.loading
);

export const getLoadingUpperMessage = createSelector(
  getLoadingState,
  state => state.upperMessage
);

export const getLoadingLowerMessage = createSelector(
  getLoadingState,
  state => state.lowerMessage
);
