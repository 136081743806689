import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, switchMap, catchError, filter } from 'rxjs/operators';

import { QuoteService } from '@core/services/quote.service';
import { ProductsService } from '@core/services/products.service';
import { ProductTypes } from '@shared/constants/app-constants';
import { PreBindDocumentsService } from '@core/services/pre-bind-documents.service';

@Injectable()
export class PreBindDocumentsAcknowledgementGuard implements CanActivate {
  constructor(
    private _preBindDocumentsService: PreBindDocumentsService,
    private _quoteService: QuoteService,
    private _productsService: ProductsService
  ) {}

  canActivate(): Observable<boolean> {
    return this._preBindDocumentsService
      .arePrebindDocumentsAcknowledgementRequired()
      .pipe(
        take(1),
        switchMap(required => {
          if (required) {
            return this.checkStore();
          }
          return of(true);
        }),
        catchError(() => of(true))
      );
  }

  checkStore(): Observable<boolean> {
    return this._productsService.arePreBindDocumentsAcknowledged().pipe(
      take(1),
      switchMap(acknowledged => {
        if (!acknowledged) {
          this._quoteService.dispatchPreBindDocumentsAcknowledgement(
            true,
            true
          );
          return this._quoteService
            .getQuoteUpdateLoaded(ProductTypes.AUTO)
            .pipe(
              filter(loaded => loaded),
              take(1)
            );
        }
        return of(true);
      })
    );
  }
}
