<div class="smartride" *ngIf="vehicles.length">
  <nw-display-panel
    heading="Enrolled in our {{ isVehicleMiles() ? 'low mileage' : 'safe-driving behavior' }} program, SmartRide&reg;"
    [alwaysOpen]="true"
    [openText]="headerIcon"
    class="program-panel"
  >
    <div class="tm-content-container">
      <p>
        Save {{ initialDiscount }} just for signing up. The safer the vehicle is
        driven the higher the discount - up to {{ maximumDiscount }}. How does
        it work?
      </p>
      <div class="row">
        <div class="col-1">
          <img
            *ngIf="!mobileAppEnrolled"
            class="tm-icon"
            src="assets/images/smartmiles-device-teal.svg"
            alt="Example of Smartride Device"
          />
          <img
            *ngIf="mobileAppEnrolled"
            class="tm-icon"
            src="assets/images/phone.svg"
            alt="SmartPhone with Mobile App"
          />
        </div>
        <div class="col-sm-3 col-xs-12 offset-sm-1 offset-md-0">
          <p class="font-weight-bold" *ngIf="!mobileAppEnrolled">
            Plug a device into the vehicle that measures {{ isVehicleMiles() ? 'mileage' : 'driving behavior' }}.
          </p>
          <p
            class="font-weight-bold"
            *ngIf="mobileAppEnrolled && !isSmartRideEarlyRewardApplicable()"
          >
            Download and activate a mobile app that measures driving behavior.
          </p>
          <p
            class="font-weight-bold"
            *ngIf="mobileAppEnrolled && isSmartRideEarlyRewardApplicable()"
          >
            Install a mobile app that measures driving behavior.
          </p>
        </div>
        <div class="col-1">
          <img
            class="tm-icon"
            src="assets/images/Calendar_Blue.svg"
            alt="Calendar"
          />
        </div>
        <div class="col-sm-3 col-xs-12 offset-sm-1 offset-md-0">
          <p
            class="font-weight-bold"
            *ngIf="!isSmartRideEarlyRewardApplicable()"
          >
            In about 6 months, we calculate the final discount.
          </p>
          <p
            class="font-weight-bold"
            *ngIf="mobileAppEnrolled && isSmartRideEarlyRewardApplicable()"
          >
            Most drivers can earn a discount in 80 days.
          </p>
          <p
            class="font-weight-bold"
            *ngIf="!mobileAppEnrolled && isSmartRideEarlyRewardApplicable()"
          >
            In about 6 months, we calculate the final discount.
          </p>
        </div>
        <mq-telematics-video
          [name]="'SmartRide'"
          [isDeviceOnlyState]="isDeviceOnlyState"
          [mobileAppEnrolled]="mobileAppEnrolled"
          [stateSpecificFlags]="stateSpecificFlags"
          [initialDiscount]="initialDiscount"
        ></mq-telematics-video>
      </div>
      <div
        style="padding-inline-start: 50px"
        *ngIf="mobileAppEnrolled"
        class="row"
      >
        <div class="col-2 col-sm-1" *ngIf="isSmartRideEarlyRewardApplicable()">
          <img
            class="tm-icon"
            src="assets/images/percent-up-icon.svg"
            alt="SmartPhone with Mobile App"
          />
        </div>
        <div class="col-md-6 col-sm-10 offset-sm-1 offset-md-0 col-xs-12">
          <p
            class="font-weight-bold"
            *ngIf="isSmartRideEarlyRewardApplicable()"
          >
            You have the opportunity to improve your discount at every renewal
            based on how safely you drive.
          </p>
        </div>
      </div>
    </div>
    <div class="disclaimer" *ngIf="!mobileAppEnrolled">
      If no vehicles are enrolled in SmartMiles, Smartride participation will be
      by mobile app rather than device.
    </div>
    <mq-telematics-vehicle-premium
      [vehicles]="vehicles"
      [vehiclePremiums]="vehiclePremiums"
      [stateSpecificFlags]="stateSpecificFlags"
      (enrollmentChanged)="changeEnrollment($event)"
    ></mq-telematics-vehicle-premium>
  </nw-display-panel>
</div>
