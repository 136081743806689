import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AddressEntity } from '@core/models/entities/address.entity';

export const enum PolicyAddressActionTypes {
  ADD_POLICY_ADDRESS = '[Policy Address] Add Policy Address',

  DELETE_POLICY_ADDRESS = '[Policy Address] Delete Policy Address',

  UPDATE_POLICY_ADDRESS = '[Policy Address] Update Policy Address',
}

export class AddPolicyAddress implements Action {
  readonly type = PolicyAddressActionTypes.ADD_POLICY_ADDRESS;
  constructor(public payload: AddressEntity) {}
}

export class DeletePolicyAddress implements Action {
  readonly type = PolicyAddressActionTypes.DELETE_POLICY_ADDRESS;
  constructor(public payload: string) {}
}

export class UpdatePolicyAddress implements Action {
  readonly type = PolicyAddressActionTypes.UPDATE_POLICY_ADDRESS;
  constructor(public payload: Update<AddressEntity>) {}
}

export type PolicyAddressActions =
  | AddPolicyAddress
  | DeletePolicyAddress
  | UpdatePolicyAddress;
