import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromPowersportsDriverVehiclePrefill from '@core/store/reducers/driver-vehicle-prefill.reducer';

import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromPolicyholder from '@core/store/selectors/policyholder.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromAgency from '@core/store/selectors/agency.selector';

import { DEFAULT_ID } from '@shared/constants/app-constants';
import { DriverVehiclePrefillRequest } from '@core/models/driver-vehicle-prefill/driver-vehicle-prefill-request.model';
import { UserType } from '@core/models/user-context/user-context.model';
import { AgencyModel } from '@core/models/agency/agency.model';

export const getPowersportsDriverVehiclePrefillState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.powersportsDriverVehiclePrefill
);

export const getPowersportsDriverVehiclePrefillLoaded = createSelector(
  getPowersportsDriverVehiclePrefillState,
  state => state.loaded
);

export const getPowersportsDriverPrefillState = createSelector(
  getPowersportsDriverVehiclePrefillState,
  state => state.powersportsDrivers
);

export const getPowersportsVehiclePrefillState = createSelector(
  getPowersportsDriverVehiclePrefillState,
  state => state.powersportsVehicles
);

export const { selectAll: selectAllPowersportsPrefillPeople } =
  fromPowersportsDriverVehiclePrefill.driverAdapter.getSelectors(
    getPowersportsDriverPrefillState
  );

export const { selectAll: selectAllPrefillPowersportsVehicles } =
  fromPowersportsDriverVehiclePrefill.vehicleAdapter.getSelectors(
    getPowersportsVehiclePrefillState
  );

export const getAllPowersportsPrefillPeople = createSelector(
  selectAllPowersportsPrefillPeople,
  fromPolicyholder.getPolicyholder(DEFAULT_ID),
  (people, policyholder) => {
    const first3Letters = 3;
    return people.filter(
      person =>
        person.person.firstName.substring(0, first3Letters).toUpperCase() !==
          policyholder.person.firstName
            .substring(0, first3Letters)
            .toUpperCase() ||
        person.person.lastName.toUpperCase() !==
          policyholder.person.lastName.toUpperCase()
    );
  }
);

export const getAllPowersportsPrefillVehicles = createSelector(
  selectAllPrefillPowersportsVehicles,
  vehicles => {
    return vehicles;
  }
);

export const buildPowersportsCustomerDataRequest = (productId: string) =>
  createSelector(
    fromPolicyholder.getPolicyholder(DEFAULT_ID),
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    fromTokens.selectAllTokens,
    fromProducts.getVehicleProducts,
    fromAgency.getAgency,
    (policyholder, policyAddress, tokens, products, agency) => {
      const businessChannel = getBusinessChannel(agency);
      const tokenForProduct = tokens.find(
        token => token.productId === productId
      );
      const product = products.find(product => product.id === productId);
      const request: DriverVehiclePrefillRequest = {
        accessToken: tokenForProduct.accessToken,
        body: {
          agreementID: product.quoteId,
          businessChannel: businessChannel,
          person: {
            firstName: policyholder.person.firstName,
            lastName: policyholder.person.lastName,
            dateOfBirth: policyholder.person.dateOfBirth,
            middleName: policyholder.person.middleName,
          },
          address: {
            city: policyAddress.city,
            state: policyAddress.state,
            streetName: policyAddress.addressLine1,
            zipCode: policyAddress.postalCode,
          },
        },
      };

      return request;
    }
  );

function getBusinessChannel(agency: AgencyModel): string {
  let businessChannel = 'Internet';
  if (agency !== null && agency.agencyChannel !== null) {
    const channel = agency.agencyChannel;
    if (channel === UserType.EA) {
      businessChannel = 'Exclusive';
    } else if (channel === UserType.IA) {
      businessChannel = 'Independent';
    } else if (channel === UserType.NSS) {
      businessChannel = channel;
    }
  }
  return businessChannel;
}
