import { Action } from '@ngrx/store';
import {
  PlaidCreateLinkTokenResponse,
  PlaidExchangeTokenRequest,
  PlaidExchangeTokenResponse,
} from '@core/models/billing/plaid/plaid.model';

export const CREATE_PLAID_LINK_TOKEN = '[Plaid] Create Plaid Link Token';
export const CREATE_PLAID_LINK_TOKEN_SUCCESS =
  '[Plaid] Create Plaid Link Token Success';
export const CREATE_PLAID_LINK_TOKEN_FAIL =
  '[Plaid] Create Plaid Link Token Fail';
export const GET_BANK_NAME = '[Plaid] Get Bank Name';
export const GET_BANK_NAME_SUCCESS = '[Plaid] Get Bank Name Success';
export const PLAID_EXCHANGE_TOKEN = '[Plaid] Plaid Exchange Token';
export const PLAID_EXCHANGE_TOKEN_SUCCESS =
  '[Plaid] Plaid Exchange Token Success';
export const PLAID_EXCHANGE_TOKEN_FAIL = '[Plaid] Plaid Exchange Token Fail';

export class CreatePlaidLinkToken implements Action {
  readonly type = CREATE_PLAID_LINK_TOKEN;
  constructor() {}
}

export class CreatePlaidLinkTokenSuccess implements Action {
  readonly type = CREATE_PLAID_LINK_TOKEN_SUCCESS;
  constructor(public payload: PlaidCreateLinkTokenResponse) {}
}

export class CreatePlaidLinkTokenFail implements Action {
  readonly type = CREATE_PLAID_LINK_TOKEN_FAIL;
  constructor(public payload: any) {}
}

export class GetBankName implements Action {
  readonly type = GET_BANK_NAME;
  constructor(public payload: PlaidExchangeTokenResponse) {}
}

export class GetBankNameSuccess implements Action {
  readonly type = GET_BANK_NAME_SUCCESS;
  constructor(public payload) {}
}

export class PlaidExchangeToken implements Action {
  readonly type = PLAID_EXCHANGE_TOKEN;
  constructor(public payload: PlaidExchangeTokenRequest) {}
}

export class PlaidExchangeTokenSuccess implements Action {
  readonly type = PLAID_EXCHANGE_TOKEN_SUCCESS;
  constructor(public payload: PlaidExchangeTokenResponse) {}
}

export class PlaidExchangeTokenFail implements Action {
  readonly type = PLAID_EXCHANGE_TOKEN_FAIL;
  constructor(public payload: PlaidExchangeTokenResponse) {}
}

export type PlaidActions =
  | CreatePlaidLinkToken
  | CreatePlaidLinkTokenSuccess
  | CreatePlaidLinkTokenFail
  | PlaidExchangeToken
  | PlaidExchangeTokenSuccess
  | PlaidExchangeTokenFail
  | GetBankName
  | GetBankNameSuccess;
