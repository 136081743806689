<div class="tm-vehicle-container" *ngFor="let vehicle of vehicles">
  <h1 class="heading-h6 row justify-content-center" style="text-align: center;">
    <strong> {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} </strong>
  </h1>
  <div class="tm-vehicle-details row align-items-center py-2">
    <ng-container *ngIf="isPrequalified; else default">
      <div class="col-sm-2 col-xs-6">Applied discount of</div>
      <div class="col-sm-2 col-xs-6">
        <h1>
          {{ getEnrollmentVehicleById(vehicle.vehicleId).discountPercentage }}%
        </h1>
      </div>
      <div class="col-sm-4 col-xs-12">
        Monthly Cost
        <h3
          class="prequalified-vehicle-premium"
          *ngIf="vehiclePremiums && vehiclePremiums.length"
        >
          {{ getVehiclePremium(vehicle.vehicleId) | currency }}*
        </h3>
      </div>
    </ng-container>
    <ng-template #default>
      <div class="col-sm-4 col-xs-12">
        <p *ngIf="!isSmartRideEarlyRewardApplicable()">
          Estimated average monthly cost for this vehicle
        </p>
        <p *ngIf="isSmartRideEarlyRewardApplicable()">
          Monthly cost for this vehicle
        </p>
      </div>
      <div class="col-sm-4 col-xs-12">
        <h1
          class="tm-vehicle-premium"
          *ngIf="vehiclePremiums && vehiclePremiums.length"
        >
          {{ getVehiclePremium(vehicle.vehicleId) | currency }}*
        </h1>
      </div>
    </ng-template>
    <div class="col-sm-4 col-xs-12" *ngIf="!isIneligible">
      <button
        type="button"
        class="btn tm-btn btn-outline-light"
        (click)="changeEnrollment(vehicle)"
      >
        Change / Remove Enrollment
      </button>
    </div>
  </div>
</div>
*Included in the total policy cost of
{{ getTotalPremium(premiums$ | async) | currency }}
