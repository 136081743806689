import { Action } from '@ngrx/store';
import { RenovationEntity } from '@core/store/reducers/renovation.reducer';
import { Product } from '@core/models/products/product.model';

export const enum RenovationActionTypes {
  // GET_RENOVATION = '[Renovation] Get Renovation',
  // GET_RENOVATION_SUCCESS = '[Renovation] Get Renovation Success',
  // GET_RENOVATION_FAIL = '[Renovation] Get Renovation Fail',

  GET_ALL_RENOVATION = '[Renovation] Get All Renovations',
  GET_ALL_RENOVATION_SUCCESS = '[Renovation] Get All Renovation Success',
  GET_ALL_RENOVATION_FAIL = '[Renovation] Get All Renovation Fail',

  ADD_RENOVATION = '[Renovation] Add Renovation',
  ADD_RENOVATION_SUCCESS = '[Renovation] Add Renovation Success',
  ADD_RENOVATION_FAIL = '[Renovation] Add Renovation Fail',

  UPDATE_RENOVATION = '[Renovation] Update Renovation',
  UPDATE_RENOVATION_SUCCESS = '[Renovation] Update Renovation Success',
  UPDATE_RENOVATION_FAIL = '[Renovation] Update Renovation Fail',

  DELETE_RENOVATION = '[Renovation] Delete Renovation',
  DELETE_RENOVATION_SUCCESS = '[Renovation] Delete Renovation Success',
  DELETE_RENOVATION_FAIL = '[Renovation] Delete Renovation Fail',

  // PATCH_RENOVATION = '[Renovation] Patch renovation on coveredlocation success',
}

// export class GetRenovation implements Action {
//   readonly type = RenovationHoActionTypes.GET_RENOVATION;
//   constructor(
//     public payload: renovationEntity: RenovationEntity[]
//   ) {}
// }

// export class GetRenovationSuccess implements Action {
//   readonly type = RenovationActionTypes.GET_RENOVATION_SUCCESS;
//   constructor(public payload: RenovationEntity[]) {}
// }

// export class GetRenovationFail implements Action {
//   readonly type = RenovationActionTypes.GET_RENOVATION_FAIL;
//   constructor(public payload: any) {}
// }

export class GetAllRenovation implements Action {
  readonly type = RenovationActionTypes.GET_ALL_RENOVATION;
}

export class GetAllRenovationSuccess implements Action {
  readonly type = RenovationActionTypes.GET_ALL_RENOVATION_SUCCESS;
  constructor(public payload: RenovationEntity[]) {}
}

export class GetAllRenovationFail implements Action {
  readonly type = RenovationActionTypes.GET_ALL_RENOVATION_FAIL;
  constructor(public payload: any) {}
}

export class AddRenovation implements Action {
  readonly type = RenovationActionTypes.ADD_RENOVATION;
  constructor(
    public payload: { product: Product; renovationEntity: RenovationEntity }
  ) {}
}

export class AddRenovationSuccess implements Action {
  readonly type = RenovationActionTypes.ADD_RENOVATION_SUCCESS;
  constructor(public payload: RenovationEntity[]) {}
}

export class AddRenovationFail implements Action {
  readonly type = RenovationActionTypes.ADD_RENOVATION_FAIL;
  constructor(public payload: any) {}
}

export class UpdateRenovation implements Action {
  readonly type = RenovationActionTypes.UPDATE_RENOVATION;
  constructor(public payload: RenovationEntity) {}
}

export class UpdateRenovationSuccess implements Action {
  readonly type = RenovationActionTypes.UPDATE_RENOVATION_SUCCESS;
  constructor(public payload: RenovationEntity[]) {}
}

export class UpdateRenovationFail implements Action {
  readonly type = RenovationActionTypes.UPDATE_RENOVATION_FAIL;
  constructor(public payload: any) {}
}

export class DeleteRenovation implements Action {
  readonly type = RenovationActionTypes.DELETE_RENOVATION;
  constructor(public payload: RenovationEntity[]) {}
}

export class DeleteRenovationSuccess implements Action {
  readonly type = RenovationActionTypes.DELETE_RENOVATION_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteRenovationFail implements Action {
  readonly type = RenovationActionTypes.DELETE_RENOVATION_FAIL;
  constructor(public payload: any) {}
}

// export class PatchRenovation implements Action {
//   readonly type = RenovationActionTypes.PATCH_RENOVATION;
//   constructor() {}
// }

export type RenovationAction =
  //  | GetRenovation
  // | GetRenovationSuccess
  // | GetRenovationFail
  | GetAllRenovation
  | GetAllRenovationSuccess
  | GetAllRenovationFail
  | AddRenovation
  | AddRenovationSuccess
  | AddRenovationFail
  | UpdateRenovation
  | UpdateRenovationSuccess
  | UpdateRenovationFail
  | DeleteRenovation
  | DeleteRenovationSuccess
  | DeleteRenovationFail;
// | PatchRenovation;
