import { CoverageEntity } from '@core/models/entities/coverage.entity';
import {
  StateExceptionCoverageMetadata,
  CoveragesMetadata,
  ProductExceptionCoverageMetadata,
} from '@shared/constants/app-constants';

export class CoverageUtils {
  static coverageSorter(quoteState: string, productId: string) {
    return (a: CoverageEntity, b: CoverageEntity) => {
      const coverageAMetadata = this.getMetadata(a, quoteState, productId);
      const sequenceA = coverageAMetadata
        ? coverageAMetadata.coverageSequence
        : 0;
      const coverageBMetadata = this.getMetadata(b, quoteState, productId);
      const sequenceB = coverageBMetadata
        ? coverageBMetadata.coverageSequence
        : 0;
      return sequenceA - sequenceB;
    };
  }

  static getMetadata(
    coverage: CoverageEntity,
    quoteState: string,
    productId: string
  ): any {
    return (
      StateExceptionCoverageMetadata[quoteState]?.[coverage.coverageId] ||
      ProductExceptionCoverageMetadata[productId]?.[coverage.coverageId] ||
      CoveragesMetadata[coverage.coverageId]
    );
  }

  static filterCoverageByGrouping(
    coverage: CoverageEntity,
    grouping: string,
    quoteState: string,
    productId: string
  ): boolean {
    const metadata = this.getMetadata(coverage, quoteState, productId);

    return metadata && metadata.displayGrouping === grouping;
  }
  static coverageValidationForIDFR(
    coverage: CoverageEntity,
    quoteState: string,
    productId: string
  ): boolean {
    const metadata = this.getMetadata(coverage, quoteState, productId);
    return metadata && metadata.displayGrouping === null;
  }
}
