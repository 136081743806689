import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Params,
} from '@angular/router';
import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import * as fromRouter from '@ngrx/router-store';

import * as fromSession from './sessions.reducer';
import * as fromLoading from './loading.reducer';
import * as fromProducts from './products.reducer';
import * as fromAddress from './address.reducer';
import * as fromMetadata from './metadata.reducer';
import * as fromRenters from './renters.reducer';
import * as fromLenders from './lenders.reducer';
import * as fromHomeowners from './homeowners.reducer';
import * as fromCondo from './condo.reducer';
import * as fromPolicy from './policy.reducer';
import * as fromAgency from './agency.reducer';
import * as fromBilling from './billing.reducer';
import * as fromPlaid from './plaid.reducer';
import * as fromPrivateLabel from './private-label.reducer';
import * as fromUserContext from './user-context.reducer';
import * as fromCma from './cma.reducer';
import * as fromQuoteLetter from './quote-letter.reducer';
import * as fromCompRaterNews from './comp-rater-news.reducer';
import * as fromPolicyholders from './policyholder.reducer';
import * as fromDrivers from './driver.reducer';
import * as fromPolicyaddress from './policy-address.reducer';
import * as fromTokens from './token.reducer';
import * as fromDsmVehicles from './vehicle.reducer';
import * as fromCurrentCarrier from './current-carrier.reducer';
import * as fromCoverages from './coverage.reducer';
import * as fromPremium from './premium.reducer';
import * as fromDiscounts from './discount.reducer';
import * as fromPowersportsDiscounts from './powersports-discount.reducer';
import * as fromCoverageErrors from './coverage-error.reducer';
import * as fromAdditionalInterest from './additional-interest.reducer';
import * as fromModifiers from './modifier.reducer';
import * as fromRetrieve from './retrieve.reducer';
import * as fromSearch from './search.reducer';
import * as fromCoveredLocation from './covered-location.reducer';
import * as fromDriverVehiclePrefill from './driver-vehicle-prefill.reducer';
import * as fromPowersportsDriverVehiclePrefill from './powersports-driver-vehicle-prefill.reducer';
import * as fromDocuments from './documents.reducer';
import * as fromEscrow from './escrow.reducer';
import * as fromHomeOwnerPropertyInfo from './homeowner-propertyInfo.reducer';
import * as fromCondoPropertyInfo from './condo-propertyInfo.reducer';
import * as fromRenovation from './renovation.reducer';
import * as fromDriverVehicleAssignment from './driver-vehicle-assignment.reducer';
import * as fromPowersportsDrivers from './powersports-driver.reducer';
import * as fromPowersportsVehicles from './powersports-vehicle.reducer';
import * as fromProtectiveDevices from './protective-devices.reducer';
import * as fromPageAlerts from './page-alert.reducer';
import * as fromRecoverableError from './recoverable-error.reducer';
import * as fromLocationExposures from './location-exposure.reducer';
import * as fromVehicleExposures from './vehicle-exposure.reducer';
import * as fromHouseholdMembers from './household-member.reducer';
import * as fromTelematicsEnrollments from './telematics-enrollments.reducer';
import * as fromPowersportsCurrentCarrier from './powersports-current-carrier.reducer';
import * as fromPreBindDocuments from './pre-bind-documents.reducer';
import * as fromConsent from './consent-values.reducer';
import * as fromPrepopulatedInsured from './prepopulated-insured.reducer';
import * as fromDigitalIdCardsText from './digital-id-cards-text.reducer';
import * as fromAccount from './account.reducer';
import * as fromTermLife from './term-life.reducer';
import * as fromMailingAddress from './mailing-address.reducer';
import * as fromPartialQuote from './partial-quote.reducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface RouterState {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const routerReducers: ActionReducerMap<RouterState> = {
  router: fromRouter.routerReducer,
};

export interface AppState {
  account: fromAccount.AccountState;
  additionalInterest: fromAdditionalInterest.AdditionalInterestState;
  address: fromAddress.AddressState;
  agency: fromAgency.AgencyState;
  billing: fromBilling.BillingState;
  cma: fromCma.CmaState;
  compRaterNews: fromCompRaterNews.CompRaterNewsState;
  discounts: fromDiscounts.DiscountState;
  drivers: fromDrivers.DriverState;
  condo: fromCondo.CondoState;
  condoPropertyInfo: fromCondoPropertyInfo.CondoPropertyInfoState;
  consentValues: fromConsent.ConsentValuesState;
  coverageErrors: fromCoverageErrors.CoverageErrorState;
  coverages: fromCoverages.CoverageState;
  coveredLocation: fromCoveredLocation.CoveredLocationState;
  currentCarrier: fromCurrentCarrier.CarrierState;
  digitalIdCardsText: fromDigitalIdCardsText.DigitalIdCardsTextState;
  documents: fromDocuments.DocumentsState;
  driverVehicleAssignment: fromDriverVehicleAssignment.DriverVehicleAssignmentState;
  driverVehiclePrefill: fromDriverVehiclePrefill.DriverVehiclePrefillState;
  dsmVehicles: fromDsmVehicles.VehicleState;
  escrow: fromEscrow.EscrowState;
  homeownerPropertyInfo: fromHomeOwnerPropertyInfo.HomeownerPropertyInfoState;
  homeowners: fromHomeowners.HomeownersState;
  householdMembers: fromHouseholdMembers.HouseholdMemberState;
  lenders: fromLenders.LendersState;
  loading: fromLoading.LoadingState;
  locationExposures: fromLocationExposures.LocationExposureState;
  metadata: fromMetadata.MetadataState;
  modifiers: fromModifiers.ModifierState;
  mailingAddress: fromMailingAddress.MailingAddressState;
  pageAlerts: fromPageAlerts.PageAlertState;
  partialQuote: fromPartialQuote.PartialQuoteState;
  plaid: fromPlaid.PlaidState;
  policy: fromPolicy.PolicyState;
  policyaddress: fromPolicyaddress.PolicyAddressState;
  policyholders: fromPolicyholders.PolicyholderState;
  powersportsCurrentCarrier: fromPowersportsCurrentCarrier.PowersportsCarrierState;
  powersportsDiscounts: fromPowersportsDiscounts.PowersportsDiscountState;
  powersportsDrivers: fromPowersportsDrivers.PowersportsDriverState;
  powersportsDriverVehiclePrefill: fromPowersportsDriverVehiclePrefill.PowersportsDriverVehiclePrefillState;
  powersportsVehicles: fromPowersportsVehicles.PowersportsVehicleState;
  preBindDocuments: fromPreBindDocuments.PreBindDocumentsState;
  premium: fromPremium.PremiumState;
  prepopulatedInsured: fromPrepopulatedInsured.PrepopulatedInsuredState;
  privateLabel: fromPrivateLabel.PrivateLabelState;
  products: fromProducts.ProductsState;
  protectiveDevices: fromProtectiveDevices.ProtectiveDevicesState;
  quoteLetter: fromQuoteLetter.QuoteLetterState;
  recoverableError: fromRecoverableError.RecoverableErrorState;
  renovation: fromRenovation.RenovationsState;
  renters: fromRenters.RentersState;
  retrieve: fromRetrieve.RetrieveState;
  search: fromSearch.SearchState;
  session: fromSession.SessionState;
  telematicsEnrollments: fromTelematicsEnrollments.TelematicsEnrollmentsState;
  termLife: fromTermLife.TermLifeState;
  tokens: fromTokens.TokenState;
  userContext: fromUserContext.UserContextState;
  vehicleExposures: fromVehicleExposures.VehicleExposureState;
}

export const appReducers: ActionReducerMap<AppState> = {
  account: fromAccount.reducer,
  additionalInterest: fromAdditionalInterest.reducer,
  address: fromAddress.reducer,
  agency: fromAgency.reducer,
  billing: fromBilling.reducer,
  cma: fromCma.reducer,
  compRaterNews: fromCompRaterNews.reducer,
  condo: fromCondo.reducer,
  condoPropertyInfo: fromCondoPropertyInfo.reducer,
  consentValues: fromConsent.reducer,
  coverageErrors: fromCoverageErrors.reducer,
  coverages: fromCoverages.reducer,
  coveredLocation: fromCoveredLocation.reducer,
  currentCarrier: fromCurrentCarrier.reducer,
  digitalIdCardsText: fromDigitalIdCardsText.reducer,
  discounts: fromDiscounts.reducer,
  documents: fromDocuments.reducer,
  drivers: fromDrivers.reducer,
  driverVehicleAssignment: fromDriverVehicleAssignment.reducer,
  driverVehiclePrefill: fromDriverVehiclePrefill.reducer,
  dsmVehicles: fromDsmVehicles.reducer,
  escrow: fromEscrow.reducer,
  homeownerPropertyInfo: fromHomeOwnerPropertyInfo.reducer,
  homeowners: fromHomeowners.reducer,
  householdMembers: fromHouseholdMembers.reducer,
  lenders: fromLenders.reducer,
  loading: fromLoading.reducer,
  locationExposures: fromLocationExposures.reducer,
  metadata: fromMetadata.reducer,
  modifiers: fromModifiers.reducer,
  pageAlerts: fromPageAlerts.reducer,
  plaid: fromPlaid.reducer,
  policy: fromPolicy.reducer,
  policyaddress: fromPolicyaddress.reducer,
  mailingAddress: fromMailingAddress.reducer,
  partialQuote: fromPartialQuote.reducer,
  policyholders: fromPolicyholders.reducer,
  powersportsCurrentCarrier: fromPowersportsCurrentCarrier.reducer,
  powersportsDiscounts: fromPowersportsDiscounts.reducer,
  powersportsDrivers: fromPowersportsDrivers.reducer,
  powersportsDriverVehiclePrefill: fromDriverVehiclePrefill.reducer,
  powersportsVehicles: fromPowersportsVehicles.reducer,
  preBindDocuments: fromPreBindDocuments.reducer,
  premium: fromPremium.reducer,
  prepopulatedInsured: fromPrepopulatedInsured.reducer,
  privateLabel: fromPrivateLabel.reducer,
  products: fromProducts.reducer,
  protectiveDevices: fromProtectiveDevices.reducer,
  quoteLetter: fromQuoteLetter.reducer,
  recoverableError: fromRecoverableError.reducer,
  renovation: fromRenovation.reducer,
  renters: fromRenters.reducer,
  retrieve: fromRetrieve.reducer,
  search: fromSearch.reducer,
  session: fromSession.reducer,
  telematicsEnrollments: fromTelematicsEnrollments.reducer,
  termLife: fromTermLife.reducer,
  tokens: fromTokens.reducer,
  userContext: fromUserContext.reducer,
  vehicleExposures: fromVehicleExposures.reducer,
};

export const getAppState = createFeatureSelector<AppState>('app');

export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl>
{
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;

    return { url, queryParams, params };
  }
}
