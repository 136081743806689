import * as fromPremium from '@core/store/actions/premium.action';
import * as fromCoverages from '@core/store/actions/coverage.action';
import * as fromQuote from '@core/store/actions/quote.action';
import { PremiumEntity } from '@core/models/entities/premium.entity';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

export interface PremiumState extends EntityState<PremiumEntity> {
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<PremiumEntity> =
  createEntityAdapter<PremiumEntity>({
    selectId: premium => premium.productId,
  });

export function reducer(
  state = adapter.getInitialState({ loaded: false, loading: false }),
  action:
    | fromPremium.PremiumActions
    | fromCoverages.CoverageActions
    | fromQuote.QuoteActions
): PremiumState {
  switch (action.type) {
    case fromPremium.PremiumActionTypes.UPDATE_PREMIUM: {
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }
    case fromCoverages.CoverageActionTypes.UPDATE_COVERAGE: {
      return { ...state, loading: true, loaded: false };
    }
    case fromQuote.QuoteActionTypes.INVALIDATE_QUOTE: {
      return { ...state, loaded: false, loading: false };
    }
    default: {
      return state;
    }
  }
}
