import { NavigationExtras } from '@angular/router';
import { Action } from '@ngrx/store';

export const GO = '[Router] Go';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';
export const SOFTFALL = '[Router] Softfall';
export const MAINTENANCE = '[Router] Maintenance';
export const NEW_QUOTE = '[Router] New Quote';
export const BRIDGE = '[Router] Bridge';
export const NAVIGATE_OUTSIDE_APP = '[Router] Navigate Outside App';

export class Go implements Action {
  readonly type = GO;
  constructor(
    public payload: {
      path: any[];
      query?: any;
      extras?: NavigationExtras;
    }
  ) {}
}

export class Back implements Action {
  readonly type = BACK;
}

export class Forward implements Action {
  readonly type = FORWARD;
}

export class Softfall implements Action {
  readonly type = SOFTFALL;
  constructor(public reason: string) {}
}

export class Maintenance implements Action {
  readonly type = MAINTENANCE;
  constructor(public reason: string) {}
}

export class NewQuote implements Action {
  readonly type = NEW_QUOTE;
}

export class Bridge implements Action {
  readonly type = BRIDGE;
  constructor(public reason: string, public redirectURL: string) {}
}

export class NavigateOutsideApp implements Action {
  readonly type = NAVIGATE_OUTSIDE_APP;
  constructor(public url: string) {}
}

export type Actions =
  | Go
  | Back
  | Forward
  | Softfall
  | Maintenance
  | Bridge
  | NewQuote
  | NavigateOutsideApp;
