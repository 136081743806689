import { Injectable } from '@angular/core';
import { PlaidAdapter } from '@core/adapters/plaid.adapter';
import { PlaidDao } from '@core/dao';
import {
  PlaidExchangeTokenRequest,
  PlaidExchangeTokenResponse,
} from '@core/models/billing/plaid/plaid.model';
import { Observable } from 'rxjs';

@Injectable()
export class PlaidService {
  constructor(
    private _plaidAdapter: PlaidAdapter,
    private _plaidDao: PlaidDao
  ) {}

  plaidCreateLinkToken(request): Observable<any> {
    return this._plaidAdapter.plaidCreateLinkToken(request);
  }

  plaidExchangeToken(request): Observable<any> {
    return this._plaidAdapter.plaidExchangeToken(request);
  }

  dispatchCreatePlaidLinkToken() {
    this._plaidDao.dispatchCreatePlaidLinkToken();
  }

  dispatchExchangePlaidToken(request: PlaidExchangeTokenRequest) {
    this._plaidDao.dispatchExchangePlaidToken(request);
  }

  getIsGetLinkTokenCalled(): Observable<boolean> {
    return this._plaidDao.getIsGetLinkTokenCalled();
  }

  getPlaidLinkToken(): Observable<string> {
    return this._plaidDao.getPlaidLinkToken();
  }

  getPlaidRetrieveCount(): Observable<number> {
    return this._plaidDao.getPlaidRetrieveCount();
  }

  getPlaidAccountInfo(): Observable<PlaidExchangeTokenResponse> {
    return this._plaidDao.getPlaidAccountInfo();
  }

  getPlaidExchangeTokenFail(): Observable<boolean> {
    return this._plaidDao.getPlaidExchangeTokenFail();
  }
}
