import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';

export const getConsentValuesState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.consentValues
);

export const getConsentValues = createSelector(
  getConsentValuesState,
  consentState => consentState.data
);

export const getPreBindDocumentsAcknowledgement = createSelector(
  getConsentValues,
  consentValues => consentValues.preBindDocumentsAcknowledgement
);

export const getConnectedCarConsent = createSelector(
  getConsentValues,
  consentValues => consentValues.connectedCarConsent
);

export const getSmartrideInstantConsent = createSelector(
  getConsentValues,
  consentValues => consentValues.smartrideInstantConsent
);

export const getConsentValuesLoaded = createSelector(
  getConsentValuesState,
  consentState => consentState.loaded
);

export const getAutoIdCardsTextConsent = createSelector(
  getConsentValues,
  consentValues => consentValues.autoIdCardsTextConsent
);

export const hasAutoIdCardsTextConsent = createSelector(
  getConsentValues,
  consentValues =>
    !!consentValues.autoIdCardsTextConsent &&
    consentValues.autoIdCardsTextConsent.agreementToText &&
    !!consentValues.autoIdCardsTextConsent.mobileNumber
);
