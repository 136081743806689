import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CRUDService } from '@core/services/crud.service';
import { AdditionalInterestAdapter } from '@core/adapters/additional-interest.adapter';
import { AdditionalInterestDao } from '@core/dao/additional-interest.dao';

import { AdditionalInterestEntity } from '@core/models/entities/additional-interest.entity';
import { AdditionalInterestRequest } from '@core/models/auto/additional-interest/additional-interest-request.model';
import { AdditionalInterestResponse } from '@core/models/auto/additional-interest/additional-interest-response.model';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';

@Injectable({
  providedIn: 'root',
})
export class AdditionalInterestService
  implements CRUDService<AdditionalInterestEntity>
{
  constructor(
    private _additionalInterestDao: AdditionalInterestDao,
    private _additionalInterestAdapter: AdditionalInterestAdapter
  ) {}

  add(entity: AdditionalInterestEntity): void {
    this._additionalInterestDao.add(entity);
  }

  addAdditionalInterest(
    request: AdditionalInterestRequest
  ): Observable<AdditionalInterestResponse> {
    return this._additionalInterestAdapter.addAdditionalInterest(request);
  }

  delete(entityId: string): void {
    this._additionalInterestDao.delete(entityId);
  }

  deleteAdditionalInterest(
    request: AdditionalInterestRequest
  ): Observable<AdditionalInterestResponse> {
    return this._additionalInterestAdapter.deleteAdditionalInterest(request);
  }

  update(entity: AdditionalInterestEntity): void {
    this._additionalInterestDao.update(entity);
  }

  updateAdditionalInterest(
    request: AdditionalInterestRequest
  ): Observable<AdditionalInterestResponse> {
    return this._additionalInterestAdapter.updateAdditionalInterest(request);
  }

  get?(entityId: string): Observable<AdditionalInterestEntity> {
    return this._additionalInterestDao.get(entityId);
  }

  getAll?(): Observable<AdditionalInterestEntity[]> {
    return this._additionalInterestDao.getAll();
  }

  getVehiclesWithAdditionalInterest(): Observable<VehicleEntity[]> {
    return this._additionalInterestDao.getVehiclesWithAdditionalInterest();
  }

  getPowersportsVehiclesWithAdditionalInterest(): Observable<VehicleEntity[]> {
    return this._additionalInterestDao.getPowersportsVehiclesWithAdditionalInterest();
  }

  getMultiple(entityIds: string[]): Observable<AdditionalInterestEntity[]> {
    return this._additionalInterestDao.getMultiple(entityIds);
  }
}
