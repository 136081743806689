import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromPolicyAddress from '@core/store/reducers/policy-address.reducer';
import { AddressEntity } from '@core/models/entities/address.entity';

export const getPolicyAddressState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.policyaddress
);

export const {
  selectAll: selectAllPolicyAddresses,
  selectEntities: selectPolicyAddressEntities,
} = fromPolicyAddress.adapter.getSelectors(getPolicyAddressState);

export const getPolicyAddress = (id?: string) =>
  createSelector(
    selectPolicyAddressEntities,
    (entities): AddressEntity => entities[id]
  );
export const getAllPolicyAddresses = () =>
  createSelector(
    selectAllPolicyAddresses,
    (addresses): AddressEntity[] => addresses
  );
