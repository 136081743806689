import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';
import * as fromModifiers from '@core/store/reducers/modifier.reducer';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromPolicyholder from '@core/store/selectors/policyholder.selector';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { EligibleDiscountRequest } from '@core/models/auto/modifiers/modifier-request.model';
import { ModifierNames } from '@shared/constants/app-constants';

export const getModifierState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.modifiers
);

export const {
  selectAll: selectAllModifiers,
  selectEntities: selectModifierEntities,
} = fromModifiers.adapter.getSelectors(getModifierState);

export const getPrimaryAssociateDiscountModifier = createSelector(
  selectAllModifiers,
  fromPolicyholder.getPrimaryNamedInsured,
  (modifiers, pni) => {
    const associateModifiers = modifiers.filter(
      modifier =>
        modifier.eligibleDiscountId === ModifierNames.ASSOCIATE_DISCOUNT &&
        modifier.selectedOptionValue === 'true'
    );
    if (associateModifiers.length) {
      return pni && pni.driverId
        ? associateModifiers.find(modifier => modifier.modelId === pni.driverId)
        : associateModifiers[0];
    }
    return null;
  }
);
export const getModifiersByDriverId = (driverId: string) =>
  createSelector(selectAllModifiers, modifiers => {
    return modifiers.filter(modifier => modifier.modelId === driverId);
  });

export const getModifiersByQuoteId = (quoteId: string) =>
  createSelector(selectAllModifiers, modifiers => {
    return modifiers.filter(modifier => modifier.modelId === quoteId);
  });

export const buildDriverDiscountRequest = (entity: EligibleDiscountEntity) =>
  createSelector(fromProducts.getProduct(entity.productId), product => {
    const request: EligibleDiscountRequest = {
      productId: product.id,
      quoteId: product.quoteId,
      modelId: entity.modelId,
      eligibleDiscount: {
        eligibleDiscountId: entity.eligibleDiscountId,
        selectedOptionValue: entity.selectedOptionValue,
      },
    };

    switch (entity.eligibleDiscountId) {
      case ModifierNames.ASSOCIATE_DISCOUNT:
      case ModifierNames.ADVANCED_DRIVER_TRAINING:
      case ModifierNames.DRIVER_TRAINING: {
        request.eligibleDiscount.qualifyingInformation =
          entity.qualifyingInformation;
        break;
      }
    }
    return request;
  });

export const buildPowersportsDriverDiscountRequest = (
  entity: EligibleDiscountEntity
) =>
  createSelector(fromProducts.getProduct(entity.productId), product => {
    const request: EligibleDiscountRequest = {
      productId: product.id,
      quoteId: product.quoteId,
      modelId: entity.modelId,
      eligibleDiscount: {
        eligibleDiscountId: entity.eligibleDiscountId,
        selectedOptionValue: entity.selectedOptionValue,
      },
    };

    switch (entity.eligibleDiscountId) {
      case ModifierNames.SAFETY_COURSE:
      case ModifierNames.ADVANCED_DRIVER_TRAINING:
      case ModifierNames.DRIVER_TRAINING: {
        request.eligibleDiscount.qualifyingInformation =
          entity.qualifyingInformation;
        break;
      }
    }
    return request;
  });

export const getDriverDiscountByDriverId = (
  eligibleDiscountId: string,
  driverId: string
) =>
  createSelector(getModifiersByDriverId(driverId), modifiers =>
    modifiers.find(
      modifier => modifier.eligibleDiscountId === eligibleDiscountId
    )
  );

export const getPowersportsDriverDiscountByDriverId = (
  eligibleDiscountId: string,
  driverId: string
) =>
  createSelector(getModifiersByDriverId(driverId), modifiers =>
    modifiers.find(
      modifier => modifier.eligibleDiscountId === eligibleDiscountId
    )
  );

export const buildUpdatePolicyLineRequest = (object: any) =>
  createSelector(
    fromProducts.getProduct(object.productId),
    fromPolicyholder.getPrimaryNamedInsured,
    (product, policyHolder) => {
      const request: EligibleDiscountRequest = {
        productId: product.id,
        quoteId: product.quoteId,
        name: object.eligibleDiscountId,
        eligibleDiscount: {
          eligibleDiscountId: object.eligibleDiscountId,
          selectedOptionValue: object.selectedOptionValue,
        },
      };

      if (
        object.eligibleDiscountId === ModifierNames.SMARTRIDE &&
        object.selectedOptionValue === 'true'
      ) {
        request.eligibleDiscount.qualifyingInformation = {};
        request.eligibleDiscount.qualifyingInformation.hasConsentedToSmartRideText =
          true;
        request.eligibleDiscount.qualifyingInformation.mobileNumber =
          policyHolder.homeNumber;
      } else if (object.qualifyingInformation) {
        request.eligibleDiscount.qualifyingInformation =
          object.qualifyingInformation;
      }

      return request;
    }
  );

export const buildGetModifiersRequest = (productId: string) =>
  createSelector(fromProducts.getProduct(productId), product => {
    const request: EligibleDiscountRequest = {
      quoteId: product.quoteId,
      productId: productId,
    };

    return request;
  });

export const buildUpdateVehicleDiscountRequest = (object: any) =>
  createSelector(fromProducts.getProduct(object.productId), product => {
    const request: EligibleDiscountRequest = {
      productId: product.id,
      quoteId: product.quoteId,
      name: object.eligibleDiscountId,
      vehicleId: object.vehicleId,
      eligibleDiscount: {
        eligibleDiscountId: object.eligibleDiscountId,
        selectedOptionValue: object.selectedOptionValue,
        qualifyingInformation: object.qualifyingInformation,
      },
    };

    return request;
  });

export const buildUpdatePowersportsVehicleDiscountRequest = (object: any) =>
  createSelector(fromProducts.getProduct(object.productId), product => {
    const request: EligibleDiscountRequest = {
      productId: product.id,
      quoteId: product.quoteId,
      name: object.eligibleDiscountId,
      vehicleId: object.vehicleId,
      eligibleDiscount: {
        eligibleDiscountId: object.eligibleDiscountId,
        selectedOptionValue: object.selectedOptionValue,
        qualifyingInformation: object.qualifyingInformation,
      },
    };

    return request;
  });

export const buildGetVehicleModifiersRequest = (
  productId: string,
  vehicleId: string
) =>
  createSelector(fromProducts.getProduct(productId), product => {
    const request: EligibleDiscountRequest = {
      quoteId: product.quoteId,
      productId,
      vehicleId,
    };

    return request;
  });

export const getPolicyLineModifier = (name: string, productId: string) =>
  createSelector(
    selectModifierEntities,
    fromProducts.getProduct(productId),
    (entities, product) => {
      return entities[`${name}_${product?.quoteId}`];
    }
  );

export const getSmartHomeDiscount = (name: string, productId: string) =>
  createSelector(
    selectModifierEntities,
    fromProducts.getProduct(productId),
    (entities, product) => {
      return entities[`${name}_${product.quoteId}`];
    }
  );

export const buildUpdateSmartHomeDiscountRequest = (value: string) =>
  createSelector(fromProducts.getSelectedPropertyProduct, product => {
    const request: EligibleDiscountRequest = {
      productId: product.id,
      quoteId: product.quoteId,
      name: 'SmartDevice',
      eligibleDiscount: {
        eligibleDiscountId: 'SmartDevice',
        selectedOptionValue: value,
      },
    };
    return request;
  });

export const buildAssociateDiscountRequest = (entity: EligibleDiscountEntity) =>
  createSelector(fromProducts.getProduct(entity.productId), product => {
    return {
      productId: product.id,
      quoteId: product.quoteId,
      name: entity.eligibleDiscountId,
      modelId: product.quoteId,
      eligibleDiscount: {
        eligibleDiscountId: entity.eligibleDiscountId,
        selectedOptionValue: entity.selectedOptionValue,
        qualifyingInformation: entity.qualifyingInformation,
      },
    } as EligibleDiscountRequest;
  });

export const getSmartRideLoaded = createSelector(
  getModifierState,
  state => state.smartrideLoaded
);

export const getModifierLoading = createSelector(
  getModifierState,
  state => state.loading > 0
);

export const buildMembershipDiscountRequest = (
  entity: EligibleDiscountEntity
) =>
  createSelector(fromProducts.getProduct(entity.productId), product => {
    return {
      productId: product.id,
      quoteId: product.quoteId,
      name: entity.eligibleDiscountId,
      modelId: product.quoteId,
      eligibleDiscount: {
        eligibleDiscountId: entity.eligibleDiscountId,
        selectedOptionValue: entity.selectedOptionValue,
      },
    } as EligibleDiscountRequest;
  });

export const buildBillingPaymentMethodRequest = (
  entity: EligibleDiscountEntity
) =>
  createSelector(fromProducts.getProduct(entity.productId), product => {
    return {
      productId: product.id,
      quoteId: product.quoteId,
      name: entity.eligibleDiscountId,
      modelId: product.quoteId,
      eligibleDiscount: {
        eligibleDiscountId: entity.eligibleDiscountId,
        selectedOptionValue: entity.selectedOptionValue,
      },
    } as EligibleDiscountRequest;
  });

export const getBillingPaymentMethodModifier = () =>
  createSelector(
    selectModifierEntities,
    fromProducts.getSelectedProductsWithoutErrors,
    (entities, products) => {
      let billingPaymentMethod;
      products.forEach(product => {
        if (!billingPaymentMethod)
          billingPaymentMethod =
            entities[`BillingPaymentMethod_${product.quoteId}`]
              ?.eligibleDiscountId === 'BillingPaymentMethod'
              ? entities[`BillingPaymentMethod_${product.quoteId}`]
              : null;
      });
      return billingPaymentMethod;
    }
  );
