import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentCarrierDao } from '@core/dao/current-carrier.dao';
import { QuoteAdapter } from '@core/adapters/quote.adapter';
import { CurrentCarrierHit } from '@shared/constants/app-constants';
import { CurrentCarrier } from '@core/store/reducers/current-carrier.reducer';

@Injectable({
  providedIn: 'root',
})
export class CurrentCarrierService {
  constructor(
    private _currentCarrierDao: CurrentCarrierDao,
    private _quoteAdapter: QuoteAdapter
  ) {}

  getCurrentCarrierLoaded(): Observable<boolean> {
    return this._currentCarrierDao.getCurrentCarrierLoaded();
  }

  getCurrentCarrierLoading(): Observable<boolean> {
    return this._currentCarrierDao.getCurrentCarrierLoading();
  }

  getPowersportsCurrentCarrierLoaded(): Observable<boolean> {
    return this._currentCarrierDao.getPowersportsCurrentCarrierLoaded();
  }

  getPowersportsCurrentCarrierLoading(): Observable<boolean> {
    return this._currentCarrierDao.getPowersportsCurrentCarrierLoading();
  }

  isCoverageLapsePresent(): Observable<boolean> {
    return this._currentCarrierDao.isCoverageLapsePresent();
  }

  loadCurrentCarrier(): void {
    this._currentCarrierDao.loadCurrentCarrier();
  }

  loadPowersportsCurrentCarrier(): void {
    this._currentCarrierDao.loadPowersportsCurrentCarrier();
  }

  dispatchUpdateCurrentCarrier(
    reasonForCoverageLapse?: string,
    currentCarrier?: CurrentCarrier
  ): void {
    this._currentCarrierDao.dispatchUpdateCurrentCarrier(
      reasonForCoverageLapse,
      currentCarrier
    );
  }

  getCurrentCarrierDecision(): Observable<string> {
    return this._currentCarrierDao.getCurrentCarrierDecision();
  }

  validateCurrentCarrierDecision(response: CurrentCarrier): string {
    if (
      response?.hasOwnProperty('coverageLapse') &&
      response?.hasOwnProperty('currentBodilyInjuryLimit') &&
      response?.hasOwnProperty('currentCarrierName') &&
      response?.hasOwnProperty('currentCarrierTerms')
    )
      return CurrentCarrierHit.FULL_HIT;

    return CurrentCarrierHit.NO_HIT;
  }
}
