import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  switchMap,
  take,
  tap,
  catchError,
  mergeMap,
  map,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services/logging.service';
import { PrepopulatedInsuredAccessTokenRequest } from '@core/models/auto/quotes/access-token-request.model';
import { EuaApiService } from '@core/services/eua-api.service';
import { EuaResponse } from '@core/services/oauth-iframe.service';
import { PrepopulatedInsuredService } from '@core/services/prepopulated-insured.service';
import { PrepopulatedInsuredRequest } from '@core/models/prepopulated-insured/prepopulated-insured.model';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { AutoService } from '@core/services';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { PrivateLabel } from '@core/models/private-label/private-label.model';
import { GetVinDetailsRequest } from '@core/models/auto/vehicle-vin.model';

@Injectable()
export class PrepopulatedInsuredEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _euaApiService: EuaApiService,
    private _prepopulatedInsuredService: PrepopulatedInsuredService,
    private _autoService: AutoService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  loadPrepopulatedInsuredData$ = this._actions$.pipe(
    ofType(
      fromActions.PrepopulatedInsuredActionTypes.LOAD_PREPOPULATED_INSURED_DATA
    ),
    switchMap(() =>
      this._store
        .select(fromSelectors.buildPrepopulatedInsuredEuaRequest)
        .pipe(take(1))
    ),
    switchMap((tokenRequest: PrepopulatedInsuredAccessTokenRequest) =>
      this._euaApiService.createAccessToken(tokenRequest).pipe(take(1))
    ),
    tap(response =>
      this._loggingService.log(
        'loadPrepopulatedInsuredData EUA Response',
        response
      )
    ),
    mergeMap((euaResponse: EuaResponse) => {
      if (!euaResponse?.access_token) {
        const safeError = this._errorHelper.sanitizeError(euaResponse?.error);
        this._loggingService.log(
          'loadPrepopulatedInsuredData EUA Error',
          safeError || 'Failed to aquire access token'
        );
        return of(
          new fromActions.LoadPrepopulatedInsuredDataFail(
            'loadPrepopulatedInsuredData Access Token Failure'
          )
        );
      }
      return this._store
        .select(
          fromSelectors.buildPrepopulatedInsuredRequest(
            euaResponse.access_token
          )
        )
        .pipe(take(1));
    }),
    tap(request => {
      this._loggingService.log('loadPrepopulatedInsuredData Request', request);
    }),
    switchMap(
      (data: { request: PrepopulatedInsuredRequest; config: PrivateLabel }) => {
        return this._prepopulatedInsuredService
          .getPrepopulatedInsured(data?.request)
          .pipe(
            take(1),
            tap(response =>
              this._loggingService.log(
                'loadPrepopulatedInsuredData Response',
                response
              )
            ),
            switchMap(response => {
              return this._prepopulatedInsuredService.getPrepopulatedInsuredActions(
                response,
                data.config
              );
            }),
            catchError(error => {
              const safeError = this._errorHelper.sanitizeError(error);
              this._loggingService.log(
                'loadPrepopulatedInsuredData Error',
                safeError
              );
              return of(
                new fromActions.LoadPrepopulatedInsuredDataFail(safeError)
              );
            })
          );
      }
    )
  );

  @Effect()
  updatePrepopulatedInsuredVehicle$ = this._actions$.pipe(
    ofType(
      fromActions.PrepopulatedInsuredActionTypes
        .UPDATE_PREPOPULATED_INSURED_VEHICLE
    ),
    map(
      (action: fromActions.UpdatePrepopulatedInsuredVehicle) => action.payload
    ),
    tap(payload => {
      this._loggingService.log(
        'updatePrepopulatedInsuredVehicle Request',
        payload
      );
    }),
    switchMap(payload =>
      this._autoService
        .getVinDetails({
          vehicleIdentificationNumber: payload.vin,
          prepopulatedInsuredId: payload.prepopulatedInsuredId,
          vehicleId: payload.vehicleTempId?.toString(),
        } as GetVinDetailsRequest)
        .pipe(
          take(1),
          tap(vinResponse => {
            this._loggingService.log(
              'updatePrepopulatedInsuredVehicle Response',
              vinResponse
            );
          }),
          switchMap(vinResponse => {
            return this._autoService
              .getMakesByYear(
                vinResponse?.retrieve17DigitVehicleIdentificationNumberOdbiResponse.vehicleModel.modelYear.toString()
              )
              .pipe(take(1))
              .pipe(
                switchMap(makeDetailsResponse => {
                  if (!vinResponse) {
                    return of(
                      new fromActions.RemovePrepopulatedInsuredVehicle(payload)
                    );
                  }
                  let makeDescription;
                  const vehicleResponse =
                    vinResponse
                      ?.retrieve17DigitVehicleIdentificationNumberOdbiResponse
                      .vehicleModel;

                  if (
                    makeDetailsResponse.retrieveVehicleModelMakesResponse.vehicleMake.find(
                      makeDetails => makeDetails.make === vehicleResponse.make
                    )
                  ) {
                    makeDescription =
                      makeDetailsResponse.retrieveVehicleModelMakesResponse.vehicleMake.find(
                        makeDetails => makeDetails.make === vehicleResponse.make
                      ).makeDescription;
                  }
                  const vehicle: VehicleEntity = {
                    ...payload,
                    year: vehicleResponse.modelYear.toString(),
                    make: vehicleResponse.make,
                    makeDescription: makeDescription,
                    model: vehicleResponse.model,
                    modelDescription: vehicleResponse.seriesDescription,
                  };
                  return of(
                    new fromActions.UpdatePrepopulatedInsuredVehicleSuccess(
                      vehicle
                    )
                  );
                })
              );
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log(
              'updatePrepopulatedInsuredVehicle Error',
              safeError
            );
            return of(
              new fromActions.RemovePrepopulatedInsuredVehicle(payload)
            );
          })
        )
    )
  );
}
