import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { combineLatest, EMPTY, from, of } from 'rxjs';
import {
  switchMap,
  catchError,
  tap,
  take,
  map,
  mergeMap,
  concatMap,
  debounceTime,
  withLatestFrom,
  filter,
} from 'rxjs/operators';

import * as billingActions from '@core/store/actions';

import { BillingService, LoggingService } from '@core/services';
import * as fromStore from '@core/store';
import { Action, Store } from '@ngrx/store';
import * as fromSelectors from '@core/store/selectors';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { DownPaymentExperienceRequest } from '@core/models/billing/down-payment/down-payment-experience-request.model';
import {
  AddRecoverableError,
  LoadDsmDownPaymentFail,
  LoadDsmDownPaymentFullPayForMultiProductFail,
} from '@core/store/actions';
import { ErrorMessageService } from '@core/services/error-message.service';
import {
  BillingPaymentPlan,
  ModifierNames,
  ModifierValues,
  ProductTypes,
} from '@shared/constants/app-constants';

@Injectable()
export class BillingEffects {
  constructor(
    private _actions$: Actions,
    private _billingService: BillingService,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper,
    private _errorMessageService: ErrorMessageService
  ) {}

  readonly DSM_DOWN_PAYMENT_DEBOUNCE = 500;
  @Effect()
  loadDsmDownPayment = this._actions$.pipe(
    ofType(billingActions.LOAD_DSM_DOWN_PAYMENT),
    switchMap(() =>
      this._store
        .select(fromSelectors.buildDownPaymentExperienceRequest)
        .pipe(take(1))
    ),
    map(request => {
      if (this.downPaymentRequestHasPolicies(request)) {
        return request;
      }
      this._loggingService.log('DownPaymentExperience not needed', request);
      return null;
    }),
    tap(request => {
      if (request) {
        this._loggingService.log('DownPaymentExperience request', request);
      }
    }),
    switchMap(request => {
      if (!request) {
        return of(new billingActions.DownPaymentNotNeeded());
      }
      return this._billingService.dsmDownPaymentExperience(request).pipe(
        tap(response =>
          this._loggingService.log('DownPaymentExperience response', response)
        ),
        map(response => new billingActions.DsmDownPaymentSuccess(response)),
        catchError(error => {
          const saneError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('DownPaymentExperience Error', saneError);
          return of(new LoadDsmDownPaymentFail(saneError));
        })
      );
    })
  );

  @Effect()
  showDownPaymentErrorsAtBindCoverages$ = this._actions$.pipe(
    ofType(billingActions.LOAD_DSM_DOWN_PAYMENT_FAIL),
    switchMap(() =>
      this._errorMessageService.composeRecoverableError(
        '',
        ErrorHelper.ERR_DOWNPAYMENT
      )
    ),
    map(error => new AddRecoverableError(error))
  );

  private downPaymentRequestHasPolicies(
    request: DownPaymentExperienceRequest
  ): boolean {
    return !!request?.body?.policiesAndQuotes?.length;
  }

  @Effect()
  loadDownPaymentFullPayForMultiProduct$ = this._actions$.pipe(
    ofType<billingActions.LoadDsmDownPaymentFullPayForMultiProduct>(
      billingActions.LOAD_DSM_DOWN_PAYMENT_FULL_PAY_MULTIPRODUCT
    ),
    debounceTime(this.DSM_DOWN_PAYMENT_DEBOUNCE),
    switchMap(() =>
      this._store
        .select(
          fromSelectors.buildDownPaymentExperienceFullPayMultiProductRequest
        )
        .pipe(take(1))
    ),

    mergeMap(requests => from(requests)),
    concatMap(request => {
      if (!this.downPaymentRequestHasPolicies(request)) {
        this._loggingService.log('DownPaymentExperience not needed', request);
        return EMPTY;
      }
      this._loggingService.log('DownPaymentExperience Requests:', request);
      return this._billingService.dsmDownPaymentExperience(request).pipe(
        tap(response =>
          this._loggingService.log('DownPaymentExperience response', response)
        ),
        switchMap(response => {
          return this.buildDownpaymentFullPayMultiProductResponseEntity(
            response
          );
        }),
        map(response => {
          return new billingActions.LoadDsmDownPaymentFullPayForMultiProductSuccess(
            response
          );
        }),
        catchError(error => {
          const saneError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('DownPaymentExperience Error', saneError);
          return of(
            new LoadDsmDownPaymentFullPayForMultiProductFail(saneError)
          );
        })
      );
    })
  );

  private buildDownpaymentFullPayMultiProductResponseEntity(response) {
    return this._store
      .select(
        fromSelectors.buildDownpaymentFullPayMultiProductResponseEntity(
          response
        )
      )
      .pipe(take(1));
  }

  @Effect()
  initializeBillingPaymentPlan$ = this._actions$.pipe(
    ofType(billingActions.INITIALIZE_BILLING_PAYMENT_PLAN),
    switchMap(() => {
      return combineLatest([
        this._store.select(fromSelectors.buildInitializeBillingPaymentPlan),
        this._store.select(fromSelectors.getProduct(ProductTypes.POWERSPORTS)),
        this._store.select(
          fromSelectors.getPolicyLineModifier(
            ModifierNames.PAIDINFULL,
            ProductTypes.POWERSPORTS
          )
        ),
        this._store.select(fromSelectors.getRecurringBankCardForSmartMiles),
      ]);
    }),
    mergeMap(
      ([
        billingPaymentPlan,
        powersportsProduct,
        powersportsPaidInFullModifier,
        recurringBankCardForSmartMiles,
      ]) =>
        this._store.select(fromSelectors.getInitializePaymentPlanRequired).pipe(
          take(1),
          switchMap(isInitializePaymentPlanRequired => {
            const action: Action[] = [];
            if (isInitializePaymentPlanRequired) {
              if (
                powersportsProduct?.id === ProductTypes.POWERSPORTS &&
                powersportsPaidInFullModifier?.selectedOptionValue &&
                billingPaymentPlan?.paymentPlan
              ) {
                const selectedOptionValue =
                  billingPaymentPlan.paymentPlan === BillingPaymentPlan.FULL_PAY
                    ? 'true'
                    : 'false';

                const powersportsModifier = {
                  eligibleDiscountId: ModifierNames.PAIDINFULL,
                  productId: ProductTypes.POWERSPORTS,
                  selectedOptionValue: selectedOptionValue,
                };
                action.push(
                  new billingActions.UpdatePolicyLine(powersportsModifier)
                );
                action.push(
                  new billingActions.RateQuote(ProductTypes.POWERSPORTS, 'bind')
                );
              }
              if (recurringBankCardForSmartMiles) {
                const billingPaymentModifier = {
                  eligibleDiscountId: ModifierNames.BILLINGPAYMENTMETHOD,
                  productId: ProductTypes.AUTO,
                  selectedOptionValue: ModifierValues.RECURRINGBANKCARD,
                };
                action.push(
                  new billingActions.UpdatePolicyLine(billingPaymentModifier)
                );
                action.push(
                  new billingActions.RateQuote(ProductTypes.AUTO, 'bind')
                );
              }
              action.push(
                new billingActions.InitializeBillingPaymentPlanSuccess(
                  billingPaymentPlan
                )
              );
              action.push(
                new billingActions.SetInitializePaymentPlanRequired(false)
              );
            }
            return action;
          })
        )
    )
  );

  @Effect()
  triggerBindRateCall$ = this._actions$.pipe(
    ofType<billingActions.TriggerBindRateCall>(
      billingActions.TRIGGER_BIND_RATE_CALL
    ),
    withLatestFrom(
      this._store.select(fromSelectors.getProductsWithPolicyNumber)
    ),
    filter(([action]) => action.payload),
    switchMap(([action, selectedProducts]) => {
      const actionlist = [];
      if (selectedProducts.find(product => product.id === ProductTypes.AUTO)) {
        actionlist.push(
          new billingActions.RateQuote(ProductTypes.AUTO, 'bind')
        );
      } else if (
        selectedProducts.find(
          product => product.id === ProductTypes.POWERSPORTS
        )
      ) {
        actionlist.push(
          new billingActions.RateQuote(ProductTypes.POWERSPORTS, 'bind')
        );
      }
      return actionlist;
    })
  );
}
