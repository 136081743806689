import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductUtilService {
  constructor() {}

  isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  loaded: Observable<boolean> = this.isLoaded$.asObservable();
  selectNJ$: Subject<string> = new Subject<string>();
}
