import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { AppConfigService } from '../../../core/services';

@Component({
  selector: 'mq-captcha-button',
  templateUrl: './captcha-button.component.html',
  styleUrls: ['./captcha-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptchaButtonComponent implements OnInit {
  captchaSiteToken: string;
  @Input() forwardOnly: boolean;
  @Input() isAppLoading: boolean;
  @Input() isReordered: boolean;
  @Input() forwardDisabled: boolean;
  @Input() gettingStarted: boolean;
  @Input() isDirectUser: boolean;

  @Output() forward = new EventEmitter();

  @ViewChild('captchaElem', { static: false })
  captchaElem: InvisibleReCaptchaComponent;

  form = new UntypedFormGroup({ cap: new UntypedFormControl() });

  constructor(private _appConfigService: AppConfigService) {}

  ngOnInit(): void {
    this.captchaSiteToken = this._appConfigService.config.captchaSiteToken;
  }

  handleLoad(): void {}

  handleSuccess(captchaResponse: string): void {
    this.forward.emit({ captchaResponse });
  }

  execute(): void {
    this.captchaElem.execute();
  }
}
