import { Action } from '@ngrx/store';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';

export const enum CoveredLocationActionTypes {
  GET_COVERED_LOCATION = '[Covered Location] Get covered location',
  GET_COVERED_LOCATION_SUCCESS = '[Covered Location] Get covered location Success',
  GET_COVERED_LOCATION_FAIL = '[Covered Location] Get covered location Fail',

  ADD_COVERED_LOCATION = '[Covered Location] Add covered location',
  UPDATE_COVERED_LOCATION = '[Covered Location] Update covered location',
  UPDATE_COVERED_LOCATION_SUCCESS = '[Covered Location] Update covered location Success',
  UPDATE_COVERED_LOCATION_FAIL = '[Covered Location] Update covered location Fail',
  RESET_COVERED_LOCATION_LOADED = '[Covered Location] Reset covered location loaded',
  REMOVE_COVERED_LOCATION = '[Covered Location] Remove covered location',
}

export class GetCoveredLocation implements Action {
  readonly type = CoveredLocationActionTypes.GET_COVERED_LOCATION;
  constructor(public payload: string) {}
}

export class GetCoveredLocationSuccess implements Action {
  readonly type = CoveredLocationActionTypes.GET_COVERED_LOCATION_SUCCESS;
  constructor(public payload: CoveredLocation) {}
}

export class GetCoveredLocationFail implements Action {
  readonly type = CoveredLocationActionTypes.GET_COVERED_LOCATION_FAIL;
  constructor(public payload: any) {}
}

export class AddCoveredLocation implements Action {
  readonly type = CoveredLocationActionTypes.ADD_COVERED_LOCATION;
  constructor(public payload: CoveredLocation) {}
}

export class UpdateCoveredLocation implements Action {
  readonly type = CoveredLocationActionTypes.UPDATE_COVERED_LOCATION;
  constructor(
    public productId: string,
    public coveredLocation: CoveredLocation
  ) {}
}

export class UpdateCoveredLocationSuccess implements Action {
  readonly type = CoveredLocationActionTypes.UPDATE_COVERED_LOCATION_SUCCESS;
  constructor(public payload: CoveredLocation) {}
}

export class UpdateCoveredLocationFail implements Action {
  readonly type = CoveredLocationActionTypes.UPDATE_COVERED_LOCATION_FAIL;
  constructor(public payload: any) {}
}

export class ResetCoveredLocationLoaded implements Action {
  readonly type = CoveredLocationActionTypes.RESET_COVERED_LOCATION_LOADED;
}

export class RemoveCoveredLocation implements Action {
  readonly type = CoveredLocationActionTypes.REMOVE_COVERED_LOCATION;
}

export type CoveredLocationActions =
  | GetCoveredLocation
  | GetCoveredLocationSuccess
  | GetCoveredLocationFail
  | AddCoveredLocation
  | UpdateCoveredLocation
  | UpdateCoveredLocationSuccess
  | UpdateCoveredLocationFail
  | ResetCoveredLocationLoaded
  | RemoveCoveredLocation;
