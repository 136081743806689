import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {
  catchError,
  map,
  tap,
  switchMap,
  take,
  mergeMap,
  filter,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services';
import { HouseholdMemberService } from '@core/services/household-member.service';
import { Update } from '@ngrx/entity';
import { HouseholdMemberEntity } from '@core/models/entities/household-member.entity';

@Injectable()
export class HouseholdMemberEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _householdMemberService: HouseholdMemberService
  ) {}

  @Effect()
  addHouseholdMember$ = this._actions$.pipe(
    ofType(fromActions.HouseholdMemberActionTypes.ADD_HOUSEHOLD_MEMBER),
    map((actions: fromActions.AddHouseholdMember) => actions.payload),
    // switchMap(location => {
    //   return this._store.select(fromSelectors.getSelectedProductsIds).pipe(
    //     take(1),
    //     switchMap(products => of({ location, products }))
    //   );
    // }),
    // filter(products =>
    //   this._productsService.isEligibleForUmbrella(products.products)
    // ),
    switchMap(householdMember => {
      return this._store
        .select(fromSelectors.buildHouseholdMemberRequest(householdMember))
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('addHouseholdMember Request', request)
    ),
    mergeMap(request =>
      this._householdMemberService.addHouseholdMember(request).pipe(
        tap(response =>
          this._loggingService.log('addHouseholdMember Response', response)
        ),
        map(response => {
          return new fromActions.AddHouseholdMemberSuccess(response);
        }),
        catchError(error => {
          this._loggingService.log('addHouseholdMember error', error);
          return of(new fromActions.AddHouseholdMemberFail(error));
        })
      )
    )
  );

  @Effect()
  addAllHouseHoldMembers = this._actions$.pipe(
    ofType(fromActions.HouseholdMemberActionTypes.ADD_ALL_HOUSEHOLD_MEMBERS),
    switchMap(() =>
      this._store.select(fromSelectors.buildHouseholdMembers).pipe(take(1))
    ),
    switchMap(householdMemberEntities =>
      this._store.select(fromSelectors.getUmbrellaAccessTokenLoading).pipe(
        filter(loading => !loading),
        take(1),
        mergeMap(() =>
          from(
            householdMemberEntities.map(
              member => new fromActions.AddHouseholdMember(member)
            )
          )
        )
      )
    )
  );

  @Effect()
  updateHouseholdMember$ = this._actions$.pipe(
    ofType(fromActions.HouseholdMemberActionTypes.UPDATE_HOUSEHOLD_MEMBER),
    map((actions: fromActions.UpdateHouseholdMember) => actions.payload),
    // switchMap(location => {
    //   return this._store.select(fromSelectors.getSelectedProductsIds).pipe(
    //     take(1),
    //     switchMap(products => of({ location, products }))
    //   );
    // }),
    // filter(products =>
    //   this._productsService.isEligibleForUmbrella(products.products)
    // ),
    switchMap(householdMember => {
      return this._store
        .select(fromSelectors.buildHouseholdMemberRequest(householdMember))
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('updateHouseholdMember Request', request)
    ),
    switchMap(request =>
      this._householdMemberService.updateHouseholdMember(request).pipe(
        tap(response =>
          this._loggingService.log('updateHouseholdMember Response', response)
        ),
        map(response => {
          const updated: Update<HouseholdMemberEntity> = {
            id: response.householdMemberId,
            changes: response,
          };
          return new fromActions.UpdateHouseholdMemberSuccess(updated);
        }),
        catchError(error => {
          this._loggingService.log('updateHouseholdMember error', error);
          return of(new fromActions.UpdateHouseholdMemberFail(error));
        })
      )
    )
  );
  @Effect()
  deleteHouseholdMember$ = this._actions$.pipe(
    ofType(fromActions.HouseholdMemberActionTypes.DELETE_HOUSEHOLD_MEMBER),
    map((actions: fromActions.DeleteHouseholdMember) => actions.payload),
    // switchMap(location => {
    //   return this._store.select(fromSelectors.getSelectedProductsIds).pipe(
    //     take(1),
    //     switchMap(products => of({ location, products }))
    //   );
    // }),
    // filter(products =>
    //   this._productsService.isEligibleForUmbrella(products.products)
    // ),
    switchMap(householdMemberId =>
      this._store
        .select(fromSelectors.getHouseholdMember(householdMemberId))
        .pipe(take(1))
    ),
    switchMap(householdMember => {
      return this._store
        .select(fromSelectors.buildHouseholdMemberRequest(householdMember))
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('deleteHouseholdMember Request', request)
    ),
    switchMap(request =>
      this._householdMemberService.deleteHouseholdMember(request).pipe(
        tap(response =>
          this._loggingService.log('deleteHouseholdMember Response', response)
        ),
        map(response => {
          return new fromActions.DeleteHouseholdMemberSuccess(
            request.householdMember.householdMemberId
          );
        }),
        catchError(error => {
          this._loggingService.log('deleteHouseholdMember error', error);
          return of(new fromActions.DeleteHouseholdMemberFail(error));
        })
      )
    )
  );
}
