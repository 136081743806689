import * as fromActions from '@core/store/actions/comp-rater-news.action';

export interface CompRaterNewsState {
  html: string;
  loaded: boolean;
  loading: boolean;
}

export const initialState: CompRaterNewsState = {
  html: '',
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromActions.CompRaterNewsActions
): CompRaterNewsState {
  switch (action.type) {
    case fromActions.LOAD_COMP_RATER_NEWS: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromActions.LOAD_COMP_RATER_NEWS_SUCCESS: {
      return {
        ...state,
        html: action.html,
        loading: false,
        loaded: true,
      };
    }

    case fromActions.LOAD_COMP_RATER_NEWS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: true, // only try once
      };
    }
  }
  return state;
}
