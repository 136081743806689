import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';

import { from, of } from 'rxjs';
import {
  switchMap,
  catchError,
  tap,
  take,
  map,
  filter,
  withLatestFrom,
  concatMap,
} from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AppState } from '@core/store/reducers';
import { LoggingService } from '@core/services/logging.service';

import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { ProtectiveDevicesService } from '@core/services/protective-devices.service';
import { ProtectiveDevice } from '@core/store/reducers/protective-devices.reducer';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { Product } from '@core/models/products/product.model';

@Injectable()
export class ProtectiveDevicesEffects {
  constructor(
    private _store: Store<AppState>,
    private _actions$: Actions,
    private _protectiveDevicesService: ProtectiveDevicesService,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  getProtectiveDevices$ = this._actions$.pipe(
    ofType(fromActions.ProtectiveDevicesActionTypes.GET_PROTECTIVE_DEVICES),
    switchMap(() =>
      this._store.select(fromSelectors.getSelectedPropertyProduct).pipe(
        filter(product => product && !!product.quoteId),
        take(1)
      )
    ),
    switchMap((product: Product) =>
      this._store
        .select(fromSelectors.buildGetProtectiveDevicesRequest(product))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('getProtectiveDevices request', request)
    ),
    switchMap(request => {
      return this._protectiveDevicesService.protectiveDevices(request).pipe(
        tap(response =>
          this._loggingService.log('getProtectiveDevices response', response)
        ),
        switchMap(response => {
          return from([new fromActions.GetProtectiveDevicesSuccess(response)]);
        }),
        catchError(error => {
          return from([
            new fromActions.GetProtectiveDevicesFail(
              this._errorHelper.sanitizeError(error)
            ),
          ]);
        })
      );
    })
  );

  @Effect()
  updateProtectiveDevices$ = this._actions$.pipe(
    ofType(fromActions.ProtectiveDevicesActionTypes.UPDATE_PROTECTIVE_DEVICES),
    map((action: any) => action.payload),
    concatMap(action => {
      return of(action).pipe(
        withLatestFrom(this._protectiveDevicesService.getProtectiveDevices()),
        switchMap(
          ([response, storeState]: [
            ProtectiveDevice[],
            ProtectiveDevice[]
          ]) => {
            const mappedResponse =
              this._protectiveDevicesService.mapProtectiveDevicesResponse(
                response,
                storeState
              );
            return from([
              new fromActions.UpdateProtectiveDevicesSuccess(mappedResponse),
            ]);
          }
        ),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updateProtectiveDevices error', safeError);
          return from([new fromActions.UpdateProtectiveDevicesFail(safeError)]);
        })
      );
    })
  );
}
