import { RetrieveService } from '@core/services/retrieve.service';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, of, NEVER, combineLatest } from 'rxjs';
import {
  AddressService,
  PersonsService,
  MetadataService,
  LoggingService,
  LoadingService,
  ProductsService,
  SessionService,
  BillingService,
  RentersService,
  HomeownersService,
  CondoService,
  PrivateLabelService,
  AgencyService,
  PowersportsService,
  UserContextService,
} from '@core/services';
import { PolicyholderService } from '@core/services/policyholder.service';
import { PolicyAddressService } from '@core/services/policy-address.service';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { AddressEntity } from '@core/models/entities/address.entity';
import { QuoteNavigationService } from '@quote/services';
import { PrivateLabel } from '@core/models/private-label/private-label.model';
import { McpContactPoint } from '@core/models/modify-contact-point/modify-contact-point-request.model';
import { QuoteService } from '@core/services/quote.service';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { DriverService } from '@core/services/driver.service';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { Product } from '@core/models/products/product.model';
import { HomeownersModel } from '@core/models/homeowners/homeowners.model';
import { CondoModel } from '@core/models/condo/condo.model';
import { CoveredLocationService } from '@core/services/covered-location.service';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { Update } from '@ngrx/entity';
import { switchMap, take } from 'rxjs/operators';
import { InitiateExtraParameters } from '@core/models/auto/quotes/initiate-auto-extra-parameters';
import { ModifiersService } from '@core/services/modifiers.service';
import { DriverHealthCarePlan } from '@core/models/auto/driver-heathCarePlan.model';
import { AgencyModel } from '@core/models/agency/agency.model';
import { RetrieveEntity } from '@core/models/entities/retrieve.entity';
import { MoreDetailsViewModel } from '@core/models/view/more-details.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { MailingAddressService } from '@core/services/mailing-address.service';
import { ProgressTrackerFlowNames } from '@shared/constants/app-constants';
import { PartialQuoteService } from '@core/services/partial-quote.service';

@Injectable()
export class MoreDetailsProxy {
  constructor(
    private _navigationService: QuoteNavigationService,
    private _addressService: AddressService,
    private _personsService: PersonsService,
    private _metadataService: MetadataService,
    private _title: Title,
    private _loggingService: LoggingService,
    private _loadingService: LoadingService,
    private _productsService: ProductsService,
    private _homeownersService: HomeownersService,
    private _condoService: CondoService,
    private _rentersService: RentersService,
    private _sessionService: SessionService,
    private _billingService: BillingService,
    private _privateLabelService: PrivateLabelService,
    private _policyholdersService: PolicyholderService,
    private _policyAddressService: PolicyAddressService,
    private _mailingAddressService: MailingAddressService,
    private _quoteService: QuoteService,
    private _driverService: DriverService,
    private _coveredLocationService: CoveredLocationService,
    private _modifiersService: ModifiersService,
    private _agencyService: AgencyService,
    private _retrieveService: RetrieveService,
    private _powersportsService: PowersportsService,
    private _userContextService: UserContextService,
    private _partialQuoteService: PartialQuoteService
  ) {}

  setTitle(title: string): void {
    this._title.setTitle(title);
  }

  addHomeownersIfAbsent(value: any): void {
    this._homeownersService.addHomeownersIfAbsent(value);
  }

  addCondoIfAbsent(value: any): void {
    this._condoService.addCondoIfAbsent(value);
  }

  updateRenters(renters: any): void {
    this._rentersService.updateRenters(renters);
  }

  loadStandardizedAddresses(street: string, unitNumber: string): void {
    this._addressService.loadStandardizedAddresses(street, unitNumber);
  }

  updateUnitNumber(unit: any): void {
    this._billingService.updateUnitNumber(unit);
    this._addressService.updateUnitNumber(unit);
  }

  getMaritalStatusData(): Observable<string[]> {
    return this._metadataService.getMaritalStatusData();
  }

  isAppLoading(): Observable<boolean> {
    return this._loadingService.getAppLoading();
  }

  logPageLoaded(): void {
    this._loggingService.logToSplunk({
      event: 'MORE_DETAILS_LOAD',
    });
  }

  logPageSubmit(): void {
    this._loggingService.logToSplunk({
      event: 'MORE_DETAILS_SUBMIT',
    });
  }

  logPetCrossSell(): void {
    const email$ = this._sessionService.getEmail();
    const userFirstName$ = this._sessionService.getFirstName();
    const userLastName$ = this._sessionService.getLastName();

    combineLatest([email$, userFirstName$, userLastName$])
      .pipe(take(1))
      .subscribe(([email, firstName, lastName]) => {
        this._loggingService.logToSplunk({
          event: 'PET_QUOTE_EMAIL_SENT',
          message: { email, firstName, lastName },
        });
      });
  }

  logPetApiCallStatus(message: string): void {
    this._loggingService.logToSplunk({
      event: 'PET_EMAIL_API_CALL',
      message,
    });
  }

  logAdobeTargetResponse(message, progressTrackerFlowName: string): void {
    let event: string;
    if (progressTrackerFlowName === ProgressTrackerFlowNames.AUTO_AND_HOME) {
      event = 'PROGRESS_TRACKER_PHASE_TWO_GROUP_ASSIGNMENT';
    } else if (progressTrackerFlowName === ProgressTrackerFlowNames.AUTO) {
      event = 'ADOBE_TARGET_PROGRESS_TRACKER_RESPONSE';
    }
    this._loggingService.logToSplunk({ event, message });
  }

  navigateForward(): void {
    this._navigationService.navigateForward();
  }

  navigateBack(): void {
    this._navigationService.navigateBack();
  }

  isProductSelectedWithoutError(productType: string): Observable<boolean> {
    return this._productsService.isProductSelectedWithoutError(productType);
  }

  isPauseStatePetRestricted(): Observable<boolean> {
    return this._productsService.isPauseStatePetRestricted();
  }

  getQuoteState(): Observable<string> {
    return this._sessionService.getQuoteState();
  }

  getSessionEmail(): Observable<string> {
    return this._sessionService.getEmail();
  }

  updateSessionEmail(email: string): void {
    this._sessionService.setEmail(email);
  }

  updateTempPolicyholder(model: PolicyholderEntity): void {
    this._policyholdersService.updateTempPolicyholder(model);
  }

  updatePolicyholder(model: PolicyholderEntity): void {
    this._policyholdersService.update(model);
  }

  dispatchInitiateNewBusiness(
    productId: string,
    extras?: InitiateExtraParameters
  ): void {
    this._quoteService.dispatchInitiateNewBusiness(productId, extras);
  }

  dispatchUpdateQuote(productId: string): void {
    this._quoteService.dispatchUpdateQuote(productId);
  }

  getPrimaryNamedInsured(): Observable<PolicyholderEntity> {
    return this._policyholdersService.getNamedInsured();
  }

  getPniDriver(): Observable<DriverEntity> {
    return this._driverService.getPniDriver();
  }

  getAutoPowersportsPniDriver(): Observable<DriverEntity> {
    return this._driverService.getAutoPowersportsPniDriver();
  }

  getPolicyAddress(id: string): Observable<AddressEntity> {
    return this._policyAddressService.getPolicyAddress(id);
  }

  addPolicyAddress(model: AddressEntity): void {
    this._policyAddressService.add(model);
  }

  updatePolicyAddress(address: AddressEntity): void {
    this._policyAddressService.update(address);
  }

  addOrUpdatePolicyAddress(address: AddressEntity): void {
    this._policyAddressService.addOrUpdate(address);
  }

  addOrUpdatePolicyMailingAddress(address: AddressEntity): void {
    this._loggingService.log('mailingAddress present', null);
    this._mailingAddressService.addOrUpdate(address);
  }

  removeMailingAddress(): void {
    this._mailingAddressService.delete();
  }

  updateDriver(driver: DriverEntity): void {
    return this._driverService.update(driver);
  }

  updatePowersportsDriver(driver: DriverEntity): void {
    this._driverService.dispatchUpdatePowersportsDriver(driver);
  }

  dispatchUpdateDriverDiscount(entity: EligibleDiscountEntity): void {
    this._modifiersService.dispatchUpdateDriverDiscount(entity);
  }

  dispatchUpdatePolicyLineDiscount(entity: EligibleDiscountEntity): void {
    this._modifiersService.dispatchUpdatePolicyLine(entity);
  }

  dispatchUpdatePowersportsDriverDiscount(
    entity: EligibleDiscountEntity
  ): void {
    this._modifiersService.dispatchUpdatePowersportsDriverDiscount(entity);
  }

  getDriverTrainingByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._driverService.getDriverTrainingByDriverId(driverId);
  }

  getAdvancedDriverTrainingByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._driverService.getAdvancedDriverTrainingByDriverId(driverId);
  }

  getUSArmedForcesByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._driverService.getUSArmedForcesByDriverId(driverId);
  }

  getDriverOccupationDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._driverService.getDriverOccupationByDriverId(driverId);
  }

  isProductInitiated(productId: string): Observable<boolean> {
    return this._productsService.getQuoteLoaded(productId);
  }

  getPniPolicyAddress(): Observable<AddressEntity> {
    return this._policyAddressService.getAll().pipe(
      switchMap(addresses => {
        if (addresses.length >= 1) {
          return of(addresses[0]);
        } else {
          return NEVER;
        }
      })
    );
  }

  getMailingAddress(): Observable<AddressEntity> {
    return this._mailingAddressService.getAll().pipe(
      switchMap(addresses => {
        if (addresses.length >= 1) {
          return of(addresses[0]);
        } else {
          return NEVER;
        }
      })
    );
  }

  updateContactPoint(event: McpContactPoint): void {
    this._policyholdersService.updateContactPoint(event);
  }

  getHomeowners(): Observable<HomeownersModel> {
    return this._homeownersService.getHomeowners();
  }

  getCondo(): Observable<CondoModel> {
    return this._condoService.getCondo();
  }

  getSelectedProducts(): Observable<Product[]> {
    return this._productsService.getSelectedProducts();
  }

  updateSelectedProducts(products: Product[]): void {
    this._productsService.updateSelectedProducts(products);
  }

  updateNavigationPath(products: Product[]): void {
    this._loggingService.logSelectedProducts('SELECTED_PRODUCTS', products);

    this._navigationService.updateNavigationPath(products);
  }

  updateDriverInStore(driver: Update<DriverEntity>): void {
    this._driverService.updateDriverInStore(driver);
  }

  addDriver(driver: DriverEntity): void {
    this._driverService.add(driver);
  }

  getCoveredLocation(): Observable<CoveredLocation> {
    return this._coveredLocationService.getCoveredLocation();
  }

  resetCoveredLocationLoaded(): void {
    this._coveredLocationService.resetCoveredLocationLoaded();
  }

  getPrivateLabelConfiguration(): Observable<PrivateLabel> {
    return this._privateLabelService.getPrivateLabelConfiguration();
  }

  getStateSpecificFlag(
    name: keyof StateSpecificFlagsObject
  ): Observable<boolean> {
    return this._metadataService.getStateSpecificFlag(name);
  }

  getStateSpecificFlags(): Observable<StateSpecificFlagsObject> {
    return this._metadataService.getStateSpecificFlagsObject();
  }

  getHealthCarePlanData(): Observable<DriverHealthCarePlan> {
    return this._driverService.getHealthCarePlans();
  }

  getContactInformation(): Observable<string> {
    return this._privateLabelService.getContactNumber();
  }

  getAgency(): Observable<AgencyModel> {
    return this._agencyService.getAgency();
  }

  getRetrieveSuccess(): Observable<boolean> {
    return this._retrieveService.getRetrieveSuccess();
  }

  getRetrievedQuote(productId: string): Observable<RetrieveEntity> {
    return this._retrieveService.getRetrievedQuote(productId);
  }

  getAllRetrievedQuotes(): Observable<RetrieveEntity[]> {
    return this._retrieveService.getAllRetrievedQuotes();
  }

  isEAOrIAAgentQuoting(): Observable<boolean> {
    return this._agencyService.isEAOrIAAgentQuoting();
  }

  isNewProductSelected(): Observable<boolean> {
    return this._retrieveService.isNewProductSelected();
  }

  dispatchUpdateAssociateDiscount(discount: EligibleDiscountEntity): void {
    this._modifiersService.updateAssociateDiscount(discount);
  }

  getPrimaryAssociateDiscount(): Observable<EligibleDiscountEntity> {
    return this._modifiersService.getPrimaryAssociateDiscount();
  }

  getOfferingType(): Observable<string> {
    return this._homeownersService.getOfferingType();
  }

  updateOfferingType(offeringType: string): void {
    this._homeownersService.updateOfferingType(offeringType);
  }

  submitSelectedProducts(moreDetailsViewModel: MoreDetailsViewModel): void {
    this._quoteService.dispatchInitiateAllNewBusiness(moreDetailsViewModel);
  }

  getSafetyCourseByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._driverService.getSafetyCourseByDriverId(driverId);
  }

  getPowersportsDriverTrainingFromDriverByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._driverService.getPowersportsDriverTrainingFromDriverByDriverId(
      driverId
    );
  }

  getPowersportsAdvancedDriverTrainingFromDriverByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._driverService.getPowersportsAdvancedDriverTrainingFromDriverByDriverId(
      driverId
    );
  }

  isQuoteRetrieve(): Observable<boolean> {
    return this._userContextService.isQuoteRetrieve();
  }

  getReferrer(): Observable<string> {
    return this._sessionService.getReferrer();
  }

  setHasSentPetEmail(hasSentPetEmail: boolean): void {
    this._sessionService.setHasSentPetEmail(hasSentPetEmail);
  }

  getHasSentPetEmail(): Observable<boolean> {
    return this._sessionService.getHasSentPetEmail();
  }

  setSessionProgressTrackerEligibility(
    isProgressTrackerEligible: boolean
  ): void {
    this._sessionService.setSessionProgressTrackerEligibility(
      isProgressTrackerEligible
    );
  }

  setProgressTrackerFlowName(progressTrackerFlowName: string): void {
    this._sessionService.setProgressTrackerFlowName(progressTrackerFlowName);
  }

  isNonGenderSpecifiedAutoOnly(): Observable<boolean> {
    return this._productsService.isNonGenderSpecifiedAutoOnly();
  }

  isNonGenderSpecifiedNonProperty(): Observable<boolean> {
    return this._productsService.isNonGenderSpecifiedNonProperty();
  }

  isMonoQuoteAllowed(): Observable<boolean> {
    return this._productsService.isMonoQuoteAllowed();
  }

  getPartialQuoteId(): Observable<string> {
    return this._partialQuoteService.getPartialQuoteId();
  }

  isRoofConditionForCondoApplicable(): Observable<boolean> {
    return this._productsService.isRoofConditionForCondoApplicable();
  }
  getMonolineTFN(): Observable<string> {
    return this._productsService.getMonolineTFN();
  }

  getTFN(): Observable<string> {
    return this._productsService.getTFN();
  }
  getPartialQuotePniDriver(): Observable<DriverEntity> {
    return this._partialQuoteService.getPartialQuotePniDriver();
  }

  thirdPartyPropertyPause(): Observable<boolean> {
    return this._productsService.thirdPartyPropertyPause();
  }
}
