import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { CRUDService } from '@core/services/crud.service';
import { TermLifeEntity } from '@core/models/termlife/termlife.entity';
import { Update } from '@ngrx/entity';
import { DsmResetEventService } from '@core/services/reset.service';
import { TermLifeResponse } from '@core/models/auto/quotes/quote-rating-response.model';

@Injectable({
  providedIn: 'root',
})
export class TermLifeDao
  implements CRUDService<TermLifeEntity>, DsmResetEventService
{
  // implements DsmResetEventService {
  constructor(private _store: Store<fromStore.AppState>) {}
  add(entity: TermLifeEntity): void {
    throw new Error('Method not implemented.');
  }

  // addAll?(entity: TermLifeEntity[]): void {
  //   throw new Error('Method not implemented.');
  // }

  delete(entityId: string): void {
    this._store.dispatch(new fromActions.DeleteDriver(entityId));
  }

  remove(entity: TermLifeEntity): void {
    const updated: Update<TermLifeResponse> = {
      id: entity.id,
      changes: entity,
    };

    this._store.dispatch(new fromActions.RemoveTermlifeCoverage(updated.id));
  }

  deleteIncident(driverId: string): void {
    this._store.dispatch(new fromActions.DeleteDriverIncident(driverId));
  }

  update(entity: TermLifeEntity): void {
    const updated: Update<TermLifeResponse> = {
      id: entity.id,
      changes: entity,
    };

    this._store.dispatch(new fromActions.UpdateTermlifeCoverage(updated));
  }

  getAll(): Observable<TermLifeEntity[]> {
    return this._store.select(fromSelectors.selectAllTermLifePeople);
  }

  getTermLifeEligiblePeople(): Observable<TermLifeEntity[]> {
    return this._store.select(fromSelectors.getTermLifeEligiblePeople);
  }

  emailQuoteEstimate(event: any): void {
    return this._store.dispatch(new fromActions.EmailQuoteEstimate(event));
  }

  getUpdatedTermLifePeople(): Observable<TermLifeEntity[]> {
    return this._store.select(fromSelectors.getUpdatedTermLifePeople);
  }
}
