import {
  TelematicsEnrollmentRecommendationResponse,
  TelematicsVehiclePremium,
  VehicleEnrollment,
  MobileEnrollment,
  TelematicsEnrollmentResponse,
  InformationalMessage,
  QualifyingInformationVehicle,
  TelematicsVehicle,
  EnrollmentConsents,
} from '@core/models/telematics/telematics.model';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { ConnectedCarCriteria } from '@core/models/private-label/private-label.model';
import { PrivateLabelContentHelper } from './private-label-content.helper';
import {
  TelematicsProgram,
  TelematicsEnrollmentStatus,
  ProductTypes,
  StateExceptionTelematicsDiscountMetadata,
} from '@shared/constants/app-constants';
import { PremiumEntity } from '@core/models/entities/premium.entity';
import { DriverEntity } from '@core/models/entities/driver.entity';

export class TelematicsEnrollmentUtils {
  static buildVehicleEnrollments(
    recommendationResponse: TelematicsEnrollmentRecommendationResponse,
    isDeviceOnlyState: boolean,
    isSmartMilesEligible: boolean,
    enrollment: TelematicsEnrollmentResponse,
    isSelfReportedProgramEligible: boolean
  ): VehicleEnrollment {
    let vehicleEnrollments: VehicleEnrollment = null;
    if (
      (TelematicsEnrollmentUtils.isValidVehicleRecommendationResponse(
        recommendationResponse
      ) &&
        isSmartMilesEligible &&
        TelematicsEnrollmentUtils.hasSomeSmartMilesRecommendations(
          recommendationResponse.qualifyingInformation.vehicles
        )) ||
      (isSelfReportedProgramEligible &&
        TelematicsEnrollmentUtils.isValidVehicleRecommendationResponse(
          recommendationResponse
        ) &&
        TelematicsEnrollmentUtils.hasSomeSelfReportedRecommendations(
          recommendationResponse.qualifyingInformation.vehicles
        )) ||
      (isDeviceOnlyState &&
        TelematicsEnrollmentUtils.isValidVehicleRecommendationResponse(
          recommendationResponse
        ) &&
        TelematicsEnrollmentUtils.hasSomeSmartRideRecommendations(
          recommendationResponse.qualifyingInformation.vehicles
        )) ||
      (TelematicsEnrollmentUtils.isValidVehicleRecommendationResponse(
        recommendationResponse
      ) &&
        TelematicsEnrollmentUtils.hasSomeSmartRideInstantRecommendations(
          recommendationResponse.qualifyingInformation.vehicles
        ))
    ) {
      vehicleEnrollments = {
        vehicles: TelematicsEnrollmentUtils.buildEnrollmentVehicles(
          recommendationResponse.qualifyingInformation.vehicles,
          enrollment
        ),
      };
    }
    return vehicleEnrollments;
  }

  static buildConnectedCarVehicleEnrollments(
    recommendationResponse: TelematicsEnrollmentRecommendationResponse,
    vehicles: VehicleEntity[],
    isDeviceOnlyState: boolean,
    isSmartMilesEligible: boolean,
    areAnyVehiclesConnectedCarEligible: boolean,
    enrollment: TelematicsEnrollmentResponse,
    isSelfReportedProgramEligible: boolean
  ): VehicleEnrollment {
    const availableProgramsVehicle =
      recommendationResponse.qualifyingInformation.vehicles.filter(qVeh => {
        return qVeh.availablePrograms.length;
      });

    if (
      areAnyVehiclesConnectedCarEligible &&
      TelematicsEnrollmentUtils.isValidVehicleRecommendationResponse(
        recommendationResponse
      )
    ) {
      return {
        vehicles: availableProgramsVehicle
          .filter(
            qVeh =>
              !!qVeh.availablePrograms &&
              vehicles.find(
                vehicle => vehicle.vehicleId + '' === qVeh.vehicleId + ''
              )
          )
          .map(qualVehInfo => {
            const dsmVehicle = vehicles.find(
              vehicle => vehicle.vehicleId + '' === qualVehInfo.vehicleId + ''
            );
            if (
              qualVehInfo.availablePrograms.some(
                telematicsAvailProg =>
                  telematicsAvailProg.name ===
                    TelematicsProgram.CONNECTED_CAR &&
                  telematicsAvailProg.recommended == true
              )
            ) {
              return {
                vehicleId: qualVehInfo.vehicleId + '',
                enrollmentStatus: TelematicsEnrollmentStatus.ENROLLED,
                vehicleProgram: TelematicsProgram.CONNECTED_CAR,
                annualMileage: qualVehInfo.estimatedAnnualMileage,
              } as TelematicsVehicle;
            } else {
              return {
                vehicleId: qualVehInfo.vehicleId + '',
                enrollmentStatus: TelematicsEnrollmentStatus.ENROLLED,
                vehicleProgram: qualVehInfo.availablePrograms.find(
                  program => program.recommended
                ).name,
                dataCollectionMethod: qualVehInfo.availablePrograms.find(
                  program => program.recommended
                )?.dataCollectionMethod,
                annualMileage: qualVehInfo.estimatedAnnualMileage,
              } as TelematicsVehicle;
            }
          }),
      } as VehicleEnrollment;
    } else {
      return TelematicsEnrollmentUtils.buildVehicleEnrollments(
        recommendationResponse,
        isDeviceOnlyState,
        isSmartMilesEligible,
        enrollment,
        isSelfReportedProgramEligible
      );
    }
  }

  static isValidVehicleRecommendationResponse(
    recommendationResponse: TelematicsEnrollmentRecommendationResponse
  ): boolean {
    return (
      recommendationResponse &&
      recommendationResponse.recommendedProgram === 'Vehicle Program' &&
      recommendationResponse.qualifyingInformation &&
      recommendationResponse.qualifyingInformation.vehicles &&
      recommendationResponse.qualifyingInformation.vehicles.length > 0
    );
  }

  static getPremiumById(
    vehiclePremiums: TelematicsVehiclePremium[],
    vehicleId: string
  ): number {
    const prem = vehiclePremiums.find(prem => prem.vehicleId === vehicleId);
    return prem ? prem.cost : 0;
  }

  static getAutoPolicyTotalPremium(premiums: PremiumEntity[]): number {
    if (premiums) {
      const autoPremium = premiums.filter(
        premium => premium.productId === ProductTypes.AUTO
      );
      if (autoPremium) {
        return autoPremium[0].total.amount;
      }
    }
    return 0;
  }

  static hasSomeSmartMilesRecommendations(
    enrollmentVehicles: QualifyingInformationVehicle[]
  ): boolean {
    return enrollmentVehicles.some(
      vehicle =>
        vehicle.availablePrograms &&
        vehicle.availablePrograms.length &&
        vehicle.availablePrograms.some(
          program =>
            program.name === TelematicsProgram.SMART_MILES &&
            program.recommended
        )
    );
  }

  static hasSomeSmartRideRecommendations(
    enrollmentVehicles: QualifyingInformationVehicle[]
  ): boolean {
    return enrollmentVehicles.some(
      vehicle =>
        vehicle.availablePrograms &&
        vehicle.availablePrograms.length &&
        vehicle.availablePrograms.some(
          program =>
            program.name === TelematicsProgram.SMARTRIDE_DEVICE &&
            program.recommended
        )
    );
  }

  static hasSomeSelfReportedRecommendations(
    enrollmentVehicles: QualifyingInformationVehicle[]
  ): boolean {
    return enrollmentVehicles.some(
      vehicle =>
        vehicle.availablePrograms &&
        vehicle.availablePrograms.length &&
        vehicle.availablePrograms.some(
          program =>
            program.name === TelematicsProgram.SELF_REPORTED &&
            program.recommended
        )
    );
  }

  static hasSomeSmartRideInstantRecommendations(
    enrollmentVehicles: QualifyingInformationVehicle[]
  ): boolean {
    return enrollmentVehicles.some(
      vehicle =>
        vehicle.availablePrograms &&
        vehicle.availablePrograms.length &&
        vehicle.availablePrograms.some(
          program =>
            program.name === TelematicsProgram.SMARTRIDE_INSTANT &&
            program.recommended
        )
    );
  }

  static ageLicensedToYearsLicensed(driver: DriverEntity): number {
    // calculates years licensed by subtracting age first licensed from current age
    const birthYear = driver.person
      ? new Date(driver.person.dateOfBirth).getFullYear()
      : null;
    const currYear = new Date().getFullYear();
    const ageFirstLicensed = parseInt(driver.ageFirstLicensed, 10);

    if (birthYear && !isNaN(ageFirstLicensed)) {
      return currYear - birthYear - ageFirstLicensed;
    } else {
      return 0;
    }
  }

  static getVehicleAnnualMiles(
    vehicles: VehicleEntity[],
    vehicleId: string
  ): string {
    const vehicle = vehicles.find(
      vehicle => vehicle.vehicleId.toString() === vehicleId
    );
    if (vehicle) {
      return vehicle.annualMiles;
    } else {
      return '0';
    }
  }

  static buildEnrollmentVehicles(
    vehicles: QualifyingInformationVehicle[],
    enrollment: TelematicsEnrollmentResponse
  ): TelematicsVehicle[] {
    const nonPrequalifiedVehicles = vehicles
      .filter(
        vehicle =>
          vehicle.availablePrograms &&
          vehicle.availablePrograms.length &&
          vehicle.availablePrograms.some(program => program.recommended)
      )
      .map(vehicle => ({
        vehicleId: vehicle.vehicleId,
        enrollmentStatus: TelematicsEnrollmentStatus.ENROLLED,
        vehicleProgram: vehicle.availablePrograms.find(
          program => program.recommended
        ).name,
        annualMileage: vehicle.estimatedAnnualMileage,
        dataCollectionMethod: vehicle?.availablePrograms.find(
          program => program.recommended
        )?.dataCollectionMethod,
      }));
    let prequalifiedVehicles = [];
    if (
      !nonPrequalifiedVehicles &&
      enrollment &&
      enrollment.informationalMessages
    ) {
      for (const message of enrollment.informationalMessages) {
        prequalifiedVehicles = prequalifiedVehicles.concat(
          message.warnings
            .filter(
              warning =>
                warning.ruleId === 'PAR613' &&
                !nonPrequalifiedVehicles.find(
                  vehicle =>
                    vehicle.vehicleId === warning.entity.fixedId.toString()
                )
            )
            .map(warning => {
              return {
                vehicleId: warning.entity.fixedId.toString(),
                enrollmentStatus: TelematicsEnrollmentStatus.PRE_QUALIFIED,
                vehicleProgram: TelematicsProgram.SMARTRIDE_INSTANT,
              };
            })
        );
      }
    }
    return nonPrequalifiedVehicles.concat(prequalifiedVehicles);
  }

  static buildMobileEnrollment(
    recommendationResponse: TelematicsEnrollmentRecommendationResponse,
    policyHolder: PolicyholderEntity,
    isDeviceOnlyState: boolean,
    areAnyConnectedCarEligible?: boolean
  ): MobileEnrollment {
    let mobileEnrollment: MobileEnrollment = null;
    if (isDeviceOnlyState || areAnyConnectedCarEligible) {
      mobileEnrollment = null;
    } else if (
      recommendationResponse.recommendedProgram === 'Mobile App Program' ||
      (!TelematicsEnrollmentUtils.hasSomeSmartMilesRecommendations(
        recommendationResponse.qualifyingInformation.vehicles
      ) &&
        !TelematicsEnrollmentUtils.hasSomeSmartRideInstantRecommendations(
          recommendationResponse.qualifyingInformation.vehicles
        ))
    ) {
      mobileEnrollment = {
        enrollmentStatus: 'Enrolled',
      };
    }
    return mobileEnrollment;
  }

  static buildEnrollmentConsents(
    recommendationResponse?: TelematicsEnrollmentRecommendationResponse,
    policyHolder?: PolicyholderEntity
  ): EnrollmentConsents {
    let enrollmentConsents: EnrollmentConsents = null;
    if (
      (TelematicsEnrollmentUtils.isValidVehicleRecommendationResponse(
        recommendationResponse
      ) &&
        !TelematicsEnrollmentUtils.hasSomeSmartRideInstantRecommendations(
          recommendationResponse.qualifyingInformation.vehicles
        )) ||
      recommendationResponse.recommendedProgram === 'Mobile App Program' ||
      (!TelematicsEnrollmentUtils.hasSomeSmartMilesRecommendations(
        recommendationResponse.qualifyingInformation.vehicles
      ) &&
        !TelematicsEnrollmentUtils.hasSomeSmartRideInstantRecommendations(
          recommendationResponse.qualifyingInformation.vehicles
        ))
    ) {
      enrollmentConsents = {
        phoneNumber: policyHolder.homeNumber,
        hasConsentToOneTimeTextActivation: true,
        hasConsentToOngoingTexts: false,
      };
    } else {
      enrollmentConsents = null;
    }

    return enrollmentConsents;
  }

  static getDiscountMetadata(discountType: string, quoteState: string): string {
    if (
      StateExceptionTelematicsDiscountMetadata[quoteState] &&
      StateExceptionTelematicsDiscountMetadata[quoteState][discountType]
    ) {
      return StateExceptionTelematicsDiscountMetadata[quoteState][discountType];
    }
  }

  static buildPrequalifiedVehicleEnrollments(
    informationalMessages: InformationalMessage[]
  ): VehicleEnrollment {
    const vehicles = [];
    for (const informationalMessage of informationalMessages) {
      for (const warning of informationalMessage.warnings) {
        if (warning.ruleId === 'PAR613') {
          vehicles.push({
            vehicleId: warning.entity.fixedId.toString(),
            enrollmentStatus: TelematicsEnrollmentStatus.PRE_QUALIFIED,
            vehicleProgram: TelematicsProgram.SMARTRIDE_INSTANT,
          });
        }
      }
    }

    const vehicleEnrollments = {
      vehicles,
    };
    return vehicleEnrollments;
  }
}
