import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { TermLifeEntity } from '@core/models/termlife/termlife.entity';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { TermLifeCvgAmtCodes } from '@shared/constants/app-constants';

@Component({
  selector: 'mq-edit-life-coverages',
  templateUrl: './edit-life-coverages.component.html',
  styleUrls: ['./edit-life-coverages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLifeCoveragesComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() showCoveragePrices: boolean;
  @Input() termLifePeople: TermLifeEntity[];
  @Input() policyHolder: PolicyholderEntity;
  @Input() termMonths = 1;
  @Input() coverageChanges: CoverageChange[];
  @Input() coverageErrors: CoverageError[];
  @Input() isQuote: boolean;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() contactNumber: string;
  @Input() isCompRater: boolean;

  @Output() termPersonDeleted = new EventEmitter<Event>();

  termLifeCvgAmtCodes = TermLifeCvgAmtCodes;
  termLifeCvgAmtKeys = Object.keys(TermLifeCvgAmtCodes);

  constructor() {}

  ngOnInit(): void {}
}
