import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { combineLatest, from, of, throwError } from 'rxjs';
import {
  catchError,
  map,
  tap,
  switchMap,
  take,
  mergeMap,
} from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services/logging.service';
import { VehicleService } from '@core/services/vehicle.service';
import { VehicleBuilder } from '@shared/utils/builders/vehicle.builder';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { AdditionalInterestEntity } from '@core/models/entities/additional-interest.entity';
import {
  ModifierNames,
  DsmVehicleTypesToVinVehicleTypes,
  PageAlertIDs,
  ProductTypes,
} from '@shared/constants/app-constants';
import { ModifiersBuilder } from '@shared/utils/builders/modifiers.builder';
import { ErrorMessageService } from '@core/services/error-message.service';
import { LoadingService } from '@core/services/loading.service';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { VehicleResponse } from '@core/models/response/vehicle-response.model';
import { StringUtils } from '@shared/utils/string.utils';

@Injectable()
export class VehicleEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _vehicleService: VehicleService,
    private _loggingService: LoggingService,
    private _errorMessageService: ErrorMessageService,
    private _loadingService: LoadingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  addVehicle$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.ADD_VEHICLE),
    map((action: fromActions.AddVehicle) => action.payload),
    switchMap(payload =>
      this._store
        .select(
          fromSelectors.buildVehicleRequest({
            ...payload,
            productId: ProductTypes.AUTO,
          })
        )
        .pipe(
          take(1),
          map(vehicleRequest => {
            return {
              request: vehicleRequest,
              antiTheftDevices: payload.antiTheftDevices,
              eligibleDiscounts: payload.eligibleDiscounts,
            };
          })
        )
    ),
    tap(req => this._loggingService.log('addVehicle Request', req.request)),
    switchMap(req =>
      this._vehicleService.addVehicle(req.request).pipe(
        tap(response =>
          this._loggingService.log('addVehicle response', response)
        ),
        switchMap(response => {
          const returnActions = [];
          const entity = VehicleBuilder.buildVehicleEntityFromResponse(
            response,
            req.request
          );
          entity.eventType = 'create';
          if (
            req.request.vehicle.prepopulatedInsuredId &&
            req.request.vehicle.vehicleTempId
          ) {
            entity.prepopulatedInsuredId =
              req.request.vehicle.prepopulatedInsuredId;
            entity.vehicleTempId = req.request.vehicle.vehicleTempId;
          }
          if (
            req.request.vehicle.partialQuoteId &&
            req.request.vehicle.vehicleTempId
          ) {
            entity.partialQuoteId = req.request.vehicle.partialQuoteId;
            entity.vehicleTempId = req.request.vehicle.vehicleTempId;
            if (req.request.vehicle.prequalifiedPartnerScoreId) {
              entity.prequalifiedPartnerScoreId =
                req.request.vehicle.prequalifiedPartnerScoreId;
            }
          }

          const additionalInterests = response.additionalInterests
            ? response.additionalInterests.map(interest => {
                interest.productId = req.request.productId;
                interest.vehicleId = entity.vehicleId;

                return interest;
              })
            : [];

          if (
            response.vin &&
            !response.vin.startsWith('*') &&
            !entity.prefillId
          ) {
            this._store.dispatch(new fromActions.UpdateVehicleDetails(entity));
          }
          const discountEntity =
            ModifiersBuilder.buildEntityForUpdateVehicleDiscount(
              req,
              response,
              entity
            );
          if (discountEntity) {
            this._store.dispatch(
              new fromActions.UpdateVehicleDiscount(discountEntity)
            );
          }
          if (req.request.vehicle.prefillId) {
            const updated: Update<VehicleEntity> = {
              changes: { selected: true },
              id: req.request.vehicle.prefillId,
            };
            returnActions.push(
              new fromActions.UpdatePrefillVehicleSelected(updated)
            );
          }
          returnActions.push(
            new fromActions.ClearDvaStore(),
            new fromActions.AddVehicleSuccess(entity),
            new fromActions.AppLoadingEnd(),
            new fromActions.AddAllAdditionalInterests(additionalInterests)
          );

          return from(returnActions);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('addVehicle error', safeError);
          return this._errorMessageService
            .composeRecoverableError(
              req.request.productId,
              error,
              PageAlertIDs['vehicles']
            )
            .pipe(
              switchMap(recoverableError => {
                return from([
                  new fromActions.AddRecoverableError(recoverableError),
                  new fromActions.AddVehicleFail(safeError),
                  new fromActions.AppLoadingEnd(),
                ]);
              })
            );
        })
      )
    )
  );

  @Effect()
  updateVehicle$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.UPDATE_VEHICLE),
    map((action: fromActions.UpdateVehicle) => action.payload),
    switchMap(payload => {
      return combineLatest([
        this._store.select(fromSelectors.buildVehicleRequest(payload)).pipe(
          take(1),
          map(vehicleRequest => {
            return {
              ...vehicleRequest,
              antiTheftDevices: payload.antiTheftDevices,
              eligibleDiscounts: payload.eligibleDiscounts,
            };
          })
        ),
        of(payload),
      ]).pipe(take(1));
    }),
    tap(request => this._loggingService.log('updateVehicle Request', request)),
    mergeMap(([request, payload]) =>
      this._vehicleService.updateVehicle(request).pipe(
        tap(response =>
          this._loggingService.log('updateVehicle response', response)
        ),
        switchMap(response => {
          const entity = VehicleBuilder.buildVehicleEntityFromResponse(
            response,
            request
          );

          entity.eventType = 'update';

          if (
            request.vehicle.prepopulatedInsuredId &&
            request.vehicle.vehicleTempId
          ) {
            entity.prepopulatedInsuredId =
              request.vehicle.prepopulatedInsuredId;
            entity.vehicleTempId = request.vehicle.vehicleTempId;
          }
          if (request.vehicle.partialQuoteId && request.vehicle.vehicleTempId) {
            entity.partialQuoteId = request.vehicle.partialQuoteId;
            entity.vehicleTempId = request.vehicle.vehicleTempId;
          }

          const additionalInterests: AdditionalInterestEntity[] =
            response.additionalInterests
              ? response.additionalInterests.map(interest => {
                  interest.productId = request.productId;
                  interest.vehicleId = request.vehicle.vehicleId;

                  return interest;
                })
              : [];

          const updated: Update<VehicleEntity> = {
            id: entity.vehicleId,
            changes: {
              ...entity,
            },
          };

          if (
            response.vin !== undefined &&
            response.vin.length > 0 &&
            !response.vin.startsWith('*')
          ) {
            this._store.dispatch(new fromActions.UpdateVehicleDetails(entity));
          }

          const dayTimeRunningLampdiscountEntity =
            ModifiersBuilder.buildEntityForUpdateVehicleDiscount(
              payload,
              response,
              entity,
              ModifierNames.VEHICLE_DAYTIMERUNNINGLAMPS
            );
          if (dayTimeRunningLampdiscountEntity) {
            this._store.dispatch(
              new fromActions.UpdateVehicleDiscount(
                dayTimeRunningLampdiscountEntity
              )
            );
          }
          const antiLockBrakesDiscountEntity =
            ModifiersBuilder.buildEntityForUpdateVehicleDiscount(
              payload,
              response,
              entity,
              ModifierNames.VEHICLE_ANTILOCKBRAKES
            );
          if (antiLockBrakesDiscountEntity) {
            this._store.dispatch(
              new fromActions.UpdateVehicleDiscount(
                antiLockBrakesDiscountEntity
              )
            );
          }
          const antiTheftDiscountEntity =
            ModifiersBuilder.buildEntityForUpdateVehicleDiscount(
              payload,
              response,
              entity,
              ModifierNames.VEHICLE_ANTITHEFT
            );
          if (antiTheftDiscountEntity) {
            this._store.dispatch(
              new fromActions.UpdateVehicleDiscount(antiTheftDiscountEntity)
            );
          }
          const passiveRestraintDiscountEntity =
            ModifiersBuilder.buildEntityForUpdateVehicleDiscount(
              payload,
              response,
              entity,
              ModifierNames.VEHICLE_PASSIVERESTRAINT
            );
          if (passiveRestraintDiscountEntity) {
            this._store.dispatch(
              new fromActions.UpdateVehicleDiscount(
                passiveRestraintDiscountEntity
              )
            );
          }
          return from([
            new fromActions.UpdateVehicleSuccess(updated),
            new fromActions.AppLoadingEnd(),
            // TODO delete current additionalInterests
            new fromActions.DeleteAdditionalInterestsByVehicleId(
              entity.vehicleId
            ),
            new fromActions.AddAllAdditionalInterests(additionalInterests),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updateVehicle error', safeError);
          return from([
            new fromActions.UpdateVehicleFail(safeError),
            new fromActions.AppLoadingEnd(),
          ]);
        })
      )
    )
  );

  @Effect()
  updateVehicleDetails$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.UPDATE_VEHICLE_DETAILS),
    map((actions: fromActions.UpdateVehicleDetails) => actions.payload),
    switchMap(entity =>
      this._store
        .select(fromSelectors.buildVehicleDetailsRequest(entity))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('updateVehicleDetails Request', request)
    ),
    switchMap(request =>
      this._vehicleService.vehicleDetails(request).pipe(
        tap(response =>
          this._loggingService.log('updateVehicleDetails Response', response)
        ),
        switchMap(response => {
          const entity: VehicleEntity = {
            ...request.entity,
            make: response.vehicledetailsResponse.vehicleMake[0].make,
            model: response.vehicledetailsResponse.vehicleModel.model,
            modelDescription:
              response.vehicledetailsResponse.vehicleModel.seriesDescription,
            makeDescription:
              response.vehicledetailsResponse.vehicleMake[0].makeDescription,
            seriesDescription:
              response.vehicledetailsResponse.vehicleModel.seriesDescription,
            productId: request.entity.productId,
          };

          const updated: Update<VehicleEntity> = {
            id: entity.vehicleId,
            changes: entity,
          };

          return from([new fromActions.UpdateVehicleDetailsSuccess(updated)]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updateVehicleDetails Error', safeError);
          return of(new fromActions.UpdateVehicleDetailsFail({}));
        })
      )
    )
  );

  @Effect()
  deleteVehicle$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.DELETE_VEHICLE),
    map((actions: fromActions.DeleteVehicle) => actions.payload),
    switchMap(vehicleId =>
      this._store
        .select(fromSelectors.getVehicleEntity(vehicleId))
        .pipe(take(1))
    ),
    switchMap(vehicle =>
      this._store
        .select(fromSelectors.buildVehicleRequest(vehicle))
        .pipe(take(1))
    ),
    tap(request => {
      this._loadingService.loadingBegin();
      this._loggingService.log('deleteVehicle Request', request);
    }),
    switchMap(request =>
      this._vehicleService.deleteVehicle(request).pipe(
        tap(response =>
          this._loggingService.log('deleteVehicle Response', response)
        ),
        switchMap(response => {
          this._loadingService.loadingEnd();
          return from([
            new fromActions.ClearDvaStore(),
            new fromActions.DeleteVehicleSuccess(request.vehicle.vehicleId),
            new fromActions.DeleteAllAdditionalInterests(
              request.vehicle.additionalInterests.map(
                interest => interest.additionalInterestId
              )
            ),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loadingService.loadingEnd();
          this._loggingService.log('deleteVehicle Error', safeError);
          return of(new fromActions.DeleteVehicleFail(safeError));
        })
      )
    )
  );

  @Effect()
  addPowersportsVehicle$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.ADD_POWERSPORTS_VEHICLE),
    map((action: fromActions.AddPowersportsVehicle) => action.payload),
    switchMap(payload =>
      this._store
        .select(fromSelectors.buildPowersportsVehicleRequest(payload))
        .pipe(
          take(1),
          map(powersportsVehicleRequest => {
            return {
              request: powersportsVehicleRequest,
              antiTheftDevices: payload.antiTheftDevices,
              eligibleDiscounts: payload.eligibleDiscounts,
            };
          })
        )
    ),
    tap(req =>
      this._loggingService.log('addPowersportsVehicle Request', req.request)
    ),
    switchMap(req =>
      this._vehicleService.addPowersportsVehicle(req.request).pipe(
        tap(response =>
          this._loggingService.log('addPowersportsVehicle response', response)
        ),
        switchMap((response: VehicleResponse) => {
          const entity =
            VehicleBuilder.buildPowersportsVehicleEntityFromResponse(
              response,
              req.request
            );

          entity.eventType = 'create';

          if (
            req.request.vehicle.prepopulatedInsuredId &&
            req.request.vehicle.vehicleTempId
          ) {
            entity.prepopulatedInsuredId =
              req.request.vehicle.prepopulatedInsuredId;
            entity.vehicleTempId = req.request.vehicle.vehicleTempId;
          }
          if (
            req.request.vehicle.partialQuoteId &&
            req.request.vehicle.vehicleTempId
          ) {
            entity.partialQuoteId = req.request.vehicle.partialQuoteId;
            entity.vehicleTempId = req.request.vehicle.vehicleTempId;
          }

          const additionalInterests = response.additionalInterests
            ? response.additionalInterests.map(interest => {
                interest.productId = req.request.productId;
                interest.vehicleId = entity.vehicleId;

                return interest;
              })
            : [];

          const nextActions: Action[] = [];

          const discountEntity =
            ModifiersBuilder.buildEntityForUpdatePowersportsVehicleDiscount(
              req,
              response,
              entity,
              ModifierNames.VEHICLE_ANTITHEFT
            );
          if (discountEntity) {
            nextActions.push(
              new fromActions.UpdatePowersportsVehicleDiscount(discountEntity)
            );
          }

          const antiLockBrakesDiscountEntity =
            ModifiersBuilder.buildEntityForUpdatePowersportsVehicleDiscount(
              req,
              response,
              entity,
              ModifierNames.VEHICLE_ANTILOCKBRAKES
            );
          if (antiLockBrakesDiscountEntity) {
            nextActions.push(
              new fromActions.UpdatePowersportsVehicleDiscount(
                antiLockBrakesDiscountEntity
              )
            );
          }

          return from([
            ...nextActions,
            new fromActions.ClearDvaStore(),
            new fromActions.AddPowersportsVehicleSuccess(entity),
            new fromActions.AppLoadingEnd(),
            new fromActions.AddAllAdditionalInterests(additionalInterests),
            new fromActions.ClearRecoverableErrors(),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('addPowersportsVehicle error', safeError);
          if (
            req.request.vehicle.additionalInformation &&
            req.request.vehicle.additionalInformation
              .hasVehicleBeenConvertedToTrike
          ) {
            return from([
              new fromActions.Softfall(
                'invalid input for [Vehicle.AdditionalInformation.HasVehicleBeenConvertedToTrike]'
              ),
              new fromActions.AddPowersportsVehicleFail(safeError),
              new fromActions.AppLoadingEnd(),
            ]);
          } else {
            return this._errorMessageService
              .composeRecoverableError(
                req.request.productId,
                error,
                PageAlertIDs['vehicles']
              )
              .pipe(
                switchMap(recoverableError => {
                  return from([
                    new fromActions.AddRecoverableError(recoverableError),
                    new fromActions.AddPowersportsVehicleFail(safeError),
                    new fromActions.AppLoadingEnd(),
                  ]);
                })
              );
          }
        })
      )
    )
  );

  @Effect()
  updatePowersportsVehicle$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.UPDATE_POWERSPORTS_VEHICLE),
    map((action: fromActions.UpdatePowersportsVehicle) => action.payload),
    mergeMap(payload =>
      this._store
        .select(fromSelectors.buildPowersportsVehicleRequest(payload))
        .pipe(
          take(1),
          map(vehicleRequest => {
            return {
              request: vehicleRequest,
              antiTheftDevices: payload.antiTheftDevices,
              eligibleDiscounts: payload.eligibleDiscounts,
            };
          })
        )
    ),
    tap(request =>
      this._loggingService.log(
        'updatePowersportsVehicle Request',
        request.request
      )
    ),
    mergeMap(request =>
      this._vehicleService.updatePowersportsVehicle(request.request).pipe(
        tap(response =>
          this._loggingService.log(
            'updatePowersportsVehicle response',
            response
          )
        ),
        switchMap(response => {
          const entity = VehicleBuilder.buildVehicleEntityFromResponse(
            response,
            request.request
          );

          entity.eventType = 'update';

          if (
            request.request.vehicle.prepopulatedInsuredId &&
            request.request.vehicle.vehicleTempId
          ) {
            entity.prepopulatedInsuredId =
              request.request.vehicle.prepopulatedInsuredId;
            entity.vehicleTempId = request.request.vehicle.vehicleTempId;
          }
          if (
            request.request.vehicle.partialQuoteId &&
            request.request.vehicle.vehicleTempId
          ) {
            entity.partialQuoteId = request.request.vehicle.partialQuoteId;
            entity.vehicleTempId = request.request.vehicle.vehicleTempId;
          }

          const additionalInterests: AdditionalInterestEntity[] =
            response.additionalInterests
              ? response.additionalInterests.map(interest => {
                  interest.productId = request.request.productId;
                  interest.vehicleId = request.request.vehicle.vehicleId;

                  return interest;
                })
              : [];

          const updated: Update<VehicleEntity> = {
            id: entity.vehicleId,
            changes: {
              ...entity,
            },
          };

          if (
            response.vin !== undefined &&
            response.vin.length > 0 &&
            !response.vin.startsWith('*')
          ) {
            this._store.dispatch(
              new fromActions.UpdatePowersportsVehicleDetails(entity)
            );
          }

          const discountEntity =
            ModifiersBuilder.buildEntityForUpdatePowersportsVehicleDiscount(
              request,
              response,
              entity,
              ModifierNames.VEHICLE_ANTITHEFT
            );
          if (discountEntity) {
            this._store.dispatch(
              new fromActions.UpdatePowersportsVehicleDiscount(discountEntity)
            );
          }

          const antiLockBrakesDiscountEntity =
            ModifiersBuilder.buildEntityForUpdatePowersportsVehicleDiscount(
              request,
              response,
              entity,
              ModifierNames.VEHICLE_ANTILOCKBRAKES
            );
          if (antiLockBrakesDiscountEntity) {
            this._store.dispatch(
              new fromActions.UpdatePowersportsVehicleDiscount(
                antiLockBrakesDiscountEntity
              )
            );
          }

          return from([
            new fromActions.UpdatePowersportsVehicleSuccess(updated),
            new fromActions.AppLoadingEnd(),
            // TODO delete current additionalInterests
            new fromActions.DeleteAdditionalInterestsByVehicleId(
              entity.vehicleId
            ),
            new fromActions.AddAllAdditionalInterests(additionalInterests),
            new fromActions.ClearRecoverableErrors(),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updatePowersportsVehicle error', safeError);
          if (
            request.request.vehicle.additionalInformation &&
            request.request.vehicle.additionalInformation
              .hasVehicleBeenConvertedToTrike
          ) {
            return from([
              new fromActions.Softfall(
                'invalid input for [Vehicle.AdditionalInformation.HasVehicleBeenConvertedToTrike]'
              ),
              new fromActions.UpdatePowersportsVehicleFail(safeError),
              new fromActions.AppLoadingEnd(),
            ]);
          } else {
            return from([
              new fromActions.UpdatePowersportsVehicleFail(safeError),
              new fromActions.AppLoadingEnd(),
            ]);
          }
        })
      )
    )
  );

  @Effect()
  updatePowersportsVehicleDetails$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.UPDATE_POWERSPORTS_VEHICLE_DETAILS),
    map(
      (actions: fromActions.UpdatePowersportsVehicleDetails) => actions.payload
    ),
    switchMap(entity =>
      this._store
        .select(fromSelectors.buildVehicleDetailsRequest(entity))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log(
        'updatePowersportsVehicleDetails Request',
        request
      )
    ),
    switchMap(request =>
      this._vehicleService.powersportsVehicleDetails(request).pipe(
        tap(response =>
          this._loggingService.log(
            'updatePowersportsVehicleDetails Response',
            response
          )
        ),
        switchMap(response => {
          if (response?.motorcycleModels?.length === 1) {
            const entity: VehicleEntity = {
              ...request.entity,
              make: response.motorcycleModels[0].make,
              model: response.motorcycleModels[0].model,
              productId: request.entity.productId,
            };
            const updated: Update<VehicleEntity> = {
              id: entity.vehicleId,
              changes: entity,
            };

            return from([
              new fromActions.UpdatePowersportsVehicleDetailsSuccess(updated),
            ]);
          } else if (response?.motorcycleModels?.length > 1) {
            const msaVehicles = [] as VehicleEntity[];
            response.motorcycleModels?.forEach(msa => {
              const entity: VehicleEntity = {
                ...request.entity,
                year: msa.year,
                make: msa.make,
                model: msa.model,
                vehicleType: this.convertVehicleTypeToDsm(msa.type),
                cubicCentimeters: msa.engineDisplacement,
                productId: request.entity.productId,
                vehicleId: `${request.entity.vehicleId}_${msa.controlNumber}`,
              };
              msaVehicles.push(entity);
            });
            return from([
              new fromActions.AddAllPowersportsMultipleVinMatchVehicles(
                msaVehicles
              ),
            ]);
          } else {
            return throwError(`empty powersports vin response`);
          }
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log(
            'updatePowersportsVehicleDetails Error',
            safeError
          );
          return of(new fromActions.UpdatePowersportsVehicleDetailsFail({}));
        })
      )
    )
  );

  @Effect()
  deletePowersportsVehicle$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.DELETE_POWERSPORTS_VEHICLE),
    map((actions: fromActions.DeletePowersportsVehicle) => actions.payload),
    switchMap(payload =>
      this._store
        .select(fromSelectors.getPowersportsVehicleEntity(payload.vehicleId))
        .pipe(take(1))
    ),
    switchMap(vehicle =>
      this._store
        .select(fromSelectors.buildPowersportsVehicleRequest(vehicle))
        .pipe(take(1))
    ),
    tap(request => {
      this._loadingService.loadingBegin();
      this._loggingService.log('deletePowersportsVehicle Request', request);
    }),
    switchMap(request =>
      this._vehicleService.deletePowersportsVehicle(request).pipe(
        tap(response =>
          this._loggingService.log(
            'deletePowersportsVehicle Response',
            response
          )
        ),
        switchMap(response => {
          this._loadingService.loadingEnd();
          return from([
            new fromActions.ClearDvaStore(),
            new fromActions.DeletePowersportsVehicleSuccess(
              request.vehicle.vehicleId
            ),
            new fromActions.DeleteAllAdditionalInterests(
              request.vehicle.additionalInterests.map(
                interest => interest.additionalInterestId
              )
            ),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loadingService.loadingEnd();
          this._loggingService.log('deletePowersportsVehicle Error', safeError);
          return of(new fromActions.DeletePowersportsVehicleFail(safeError));
        })
      )
    )
  );

  private convertVehicleTypeToDsm(vehicleType: string) {
    let newVehicleType;
    Object.entries(DsmVehicleTypesToVinVehicleTypes).forEach(([key, value]) => {
      if (StringUtils.areEqual(vehicleType, value)) {
        newVehicleType = key;
      }
    });
    return newVehicleType;
  }
}
