import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppConfigService } from '@core/services/app-config.service';

import { StringUtils } from '@shared/utils/string.utils';

import {
  CaptchaApiRequest,
  ReCaptchav2Request,
} from '@core/models/captcha/captcha-api-request.model';
import { RemoteIpResponse } from '@core/models/captcha/remote-ip-response.model';

@Injectable()
export class CaptchaAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  post(captchaToken: string, remoteIP: string): Observable<CaptchaApiRequest> {
    const url = this._appConfig.config.captchaApiUrl + '/client-token';
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID());
    const request = new CaptchaApiRequest();
    request.token = captchaToken;
    request.remoteIP = remoteIP;
    return this._http.post<CaptchaApiRequest>(url, request, {
      headers,
    });
  }

  newPost(request: ReCaptchav2Request): Observable<any> {
    const url = this._appConfig.config.captchaApiUrl;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID());
    return this._http.post(url, request, {
      headers,
    });
  }

  getIP(): Observable<RemoteIpResponse> {
    return this._http.get<RemoteIpResponse>(
      'https://api.ipify.org/?format=json'
    );
  }
}
