import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalSliderComponent } from './horizontal-slider.component';

@NgModule({
  imports: [CommonModule],
  declarations: [HorizontalSliderComponent],
  exports: [HorizontalSliderComponent],
})
export class HorizontalSliderModule {}
