import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mq-self-reported',
  templateUrl: './self-reported.component.html',
  styleUrls: ['./self-reported.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfReportedComponent implements OnInit {
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}
