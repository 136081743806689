import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PicklistSelectComponent } from './picklist-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [PicklistSelectComponent],
  exports: [PicklistSelectComponent],
})
export class PicklistSelectModule {}
