import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DriverDao } from '@core/dao/driver.dao';
import { DriverAdapter } from '@core/adapters/driver.adapter';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { CRUDService } from './crud.service';
import {
  DriverRequest,
  PowersportsDriverRequest,
} from '@core/models/auto/quotes/driver-request.model';
import { Update } from '@ngrx/entity';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { DsmResetEventService } from './reset.service';
import { DriverIncidentEntity } from '@core/models/entities/driver-incident.entity';
import { DriverIncidentRequest } from '@core/models/auto/quotes/driver-incident-request.model';
import { PersonEntity } from '@core/models/entities/person.entity';
import { DriverRelationToPni } from '@core/models/auto/driver-relationToPni.model';
import { DriverHealthCarePlan } from '@core/models/auto/driver-heathCarePlan.model';
import { take, withLatestFrom } from 'rxjs/operators';
import { DriverReasonForCoverageLapse } from '@core/models/auto/driver-reasonForCoverageLapse.model';

@Injectable({
  providedIn: 'root',
})
export class DriverService
  implements CRUDService<DriverEntity>, DsmResetEventService
{
  constructor(
    private _dsmDriverDao: DriverDao,
    private _driverAdapter: DriverAdapter
  ) {}

  add(entity: DriverEntity): void {
    this._dsmDriverDao.add(entity);
  }

  dispatchAddPowersportsDriver(entity: DriverEntity): void {
    this._dsmDriverDao.dispatchAddPowersportsDriver(entity);
  }

  addAll?(entity: DriverEntity[]): void {
    throw new Error('Method not implemented.');
  }

  delete(entityId: string): void {
    this._dsmDriverDao.delete(entityId);
  }

  dispatchDeletePowersportsDriver(entityId: string): void {
    this._dsmDriverDao.dispatchDeletePowersportsDriver(entityId);
  }

  update(entity: DriverEntity): void {
    this._dsmDriverDao.update(entity);
  }

  dispatchUpdatePowersportsDriver(entity: DriverEntity): void {
    this._dsmDriverDao.dispatchUpdatePowersportsDriver(entity);
  }

  updateDriverAndRespond(entity: DriverEntity): Observable<any> {
    return this._dsmDriverDao.updateDriverAndRespond(entity);
  }

  updatePowersportsDriverAndRespond(entity: DriverEntity): Observable<any> {
    return this._dsmDriverDao.updatePowersportsDriverAndRespond(entity);
  }

  updateAndRespond(entity: DriverEntity): Observable<any> {
    return this._dsmDriverDao.updateAndRespond(entity);
  }

  updateEvent(entity: DriverEntity): void {
    this._dsmDriverDao.updateEvent(entity);
  }

  get?(entityId: string): Observable<DriverEntity> {
    throw new Error('Method not implemented.');
  }

  getAll?(): Observable<DriverEntity[]> {
    return this._dsmDriverDao.getAll();
  }

  getAllPowersportsDrivers(): Observable<DriverEntity[]> {
    return this._dsmDriverDao.getAllPowersportsDrivers();
  }

  getAllPowersportsDriversIfSelected(): Observable<DriverEntity[]> {
    return this._dsmDriverDao.getAllPowersportsDriversIfSelected();
  }

  getAutoAndPowersportsDriversMappping(): Observable<
    Map<string, DriverEntity>
  > {
    return this._dsmDriverDao.getAutoAndPowersportsDriversMappping();
  }

  addDriver(driver: DriverRequest): Observable<DriverEntity> {
    return this._driverAdapter.addDriver(driver);
  }

  addPowersportsDriver(
    driver: PowersportsDriverRequest
  ): Observable<DriverEntity> {
    return this._driverAdapter.addPowersportsDriver(driver);
  }

  updateDriver(driver: DriverRequest): Observable<DriverEntity> {
    return this._driverAdapter.updateDriver(driver);
  }

  deleteDriver(request: DriverRequest): Observable<DriverEntity> {
    return this._driverAdapter.deleteDriver(request);
  }

  updatePowersportsDriver(
    driver: PowersportsDriverRequest
  ): Observable<DriverEntity> {
    return this._driverAdapter.updatePowersportsDriver(driver);
  }

  deletePowersportsDriver(
    request: PowersportsDriverRequest
  ): Observable<DriverEntity> {
    return this._driverAdapter.deletePowersportsDriver(request);
  }

  deleteIncident(driverId: string): void {
    this._dsmDriverDao.deleteIncident(driverId);
  }

  deleteDriverIncident(
    request: DriverIncidentRequest
  ): Observable<DriverIncidentEntity> {
    return this._driverAdapter.deleteDriverIncident(request);
  }

  updateDriverInStore(driver: Update<DriverEntity>): void {
    this._dsmDriverDao.updateDriverInStore(driver);
  }

  getPniDriver(): Observable<DriverEntity> {
    return this._dsmDriverDao.getPniDriver();
  }

  isMarriedDriverExists(): Observable<boolean> {
    return this._dsmDriverDao.isMarriedDriverExists();
  }

  isSpouseDriverExists(): Observable<boolean> {
    return this._dsmDriverDao.isSpouseDriverExists();
  }

  getDriverTrainingByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._dsmDriverDao.getDriverTrainingByDriverId(driverId);
  }

  getAdvancedDriverTrainingByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._dsmDriverDao.getAdvancedDriverTrainingByDriverId(driverId);
  }

  getUSArmedForcesByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._dsmDriverDao.getUSArmedForcesByDriverId(driverId);
  }

  getDriverInfractions(): Observable<string[]> {
    return this._dsmDriverDao.getDriverInfractions();
  }

  getDriverOccupationByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._dsmDriverDao.getDriverOccupationByDriverId(driverId);
  }

  getAllDriversWithVehicleAssignmentAsPersonModel(): Observable<
    PersonEntity[]
  > {
    return this._dsmDriverDao.getAllDriversWithVehicleAssignment();
  }

  getAllDriversIncludingPowersports(): Observable<DriverEntity[]> {
    return this._dsmDriverDao.getAllDriversIncludingPowersports();
  }

  isDriverMarriedWithDriverOccupation(driverId: string): Observable<boolean> {
    return this._dsmDriverDao.isDriverMarriedWithDriverOccupation(driverId);
  }

  getDriverRelationToPni(driverFormData: any): Observable<string> {
    return this._dsmDriverDao.getDriverRelationToPni(driverFormData);
  }

  getPowersportsDriverRelationToPni(driverFormData: any): Observable<string> {
    return this._dsmDriverDao.getPowersportsDriverRelationToPni(driverFormData);
  }

  getRelationToPni(): Observable<DriverRelationToPni> {
    return this._driverAdapter.getRelationToPni();
  }

  getHealthCarePlans(): Observable<DriverHealthCarePlan> {
    return this._driverAdapter.getHealthCarePlans();
  }

  getDriversLoading(): Observable<boolean> {
    return this._dsmDriverDao.getDriversLoading();
  }

  getAutoPowersportsPniDriver(): Observable<DriverEntity> {
    let result;
    this._dsmDriverDao
      .getPniDriver()

      .pipe(
        take(1),
        withLatestFrom(this._dsmDriverDao.getPowersportsPniDriver())
      )
      .subscribe(([pniDriver, powersportsPniDriver]) => {
        result =
          powersportsPniDriver && !pniDriver ? powersportsPniDriver : pniDriver;
      });
    return of(result);
  }

  //Powersports Eligible discounts

  getSafetyCourseByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._dsmDriverDao.getSafetyCourseByDriverId(driverId);
  }

  getPowersportsDriverTrainingFromDriverByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._dsmDriverDao.getPowersportsDriverTrainingFromDriverByDriverId(
      driverId
    );
  }

  getPowersportsAdvancedDriverTrainingFromDriverByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._dsmDriverDao.getPowersportsAdvancedDriverTrainingFromDriverByDriverId(
      driverId
    );
  }

  getPowersportsGoodStudentFromDriverByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._dsmDriverDao.getPowersportsGoodStudentFromDriverByDriverId(
      driverId
    );
  }

  getReasonForCoverageLapseOptions(): Observable<DriverReasonForCoverageLapse> {
    return this._driverAdapter.getReasonForCoverageLapseOptions();
  }
}
