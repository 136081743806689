import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'unCamelCase' })
export class UnCamelCasePipe implements PipeTransform {
  transform(value: string): string {
    const keywordsMarked = value
      .replace(/\bSmartRide\b/g, 'SMARTRIDE')
      .replace(/\bSmartMiles\b/g, 'SMARTMILES');

    const unCamelCased = keywordsMarked.replace(
      /([a-z])([A-Z])/g,
      (_, a, b) => `${a} ${b}`
    );

    const rebuilt = unCamelCased
      .replace('SMARTRIDE', 'SmartRide')
      .replace('SMARTMILES', 'SmartMiles');

    return rebuilt;
  }
}
