import { Injectable } from '@angular/core';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import {
  AgencyService,
  LoggingService,
  EuaHelper,
  UserContextService,
} from '@core/services';
import { RetrieveService } from '@core/services/retrieve.service';
import { RetrieveProxy } from 'app/retrieve/proxies';
import { TokenService } from '@core/services/token.service';
import { ComparativeQuotesRequest } from '@core/adapters/comparative-quotes.adapter';
import { ComparativeQuotesService } from '@core/services/comparative-quotes.service';
import { Observable, of } from 'rxjs';
import { RedirectDestinations } from '@shared/constants/app-constants';
import {
  InitiatedByType,
  UserType,
} from '@core/models/user-context/user-context.model';

interface nwJwtPayload extends JwtPayload {
  userId: string;
  racfId: string;
}

@Injectable()
export class CompRaterAuthenticationGuard implements CanActivate {
  constructor(
    private _agencyService: AgencyService,
    private _loggingService: LoggingService,
    private _euaHelper: EuaHelper,
    private _retrieveService: RetrieveService,
    private _retrieveProxy: RetrieveProxy,
    private _tokenService: TokenService,
    private _comparativeQuotesService: ComparativeQuotesService,
    private _userContextService: UserContextService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    if (route.fragment === null) {
      const deepLaunchId = route.queryParams.id;
      window.location.href =
        this._euaHelper.getEUAUrlForCompRater(deepLaunchId);
      return false;
    } else {
      const params = this._parseHashParams(route.fragment);

      if (!params) {
        this._retrieveProxy.setCompRaterError('Unable to login');
      }

      if (params['error']) {
        this._loggingService.logToSplunk({
          event: 'AUTHORIZATION_FAILURE',
          message: params['error_description'],
        });
        this._retrieveProxy.setCompRaterError(
          'Authorization failed. Please try again'
        );

        return false;
      }

      const accessToken = params['access_token'];
      const id_token = params['id_token'];
      const previousState = this._parseHashParams(params['state']);
      const deepLaunchId = previousState['DeepLaunchId'];
      const jwtContents = jwtDecode<nwJwtPayload>(id_token);
      const agencyChannel =
        jwtContents.userId === jwtContents.racfId ? 'IA' : 'EA';
      this._agencyService.updateAgentJwt(jwtContents, accessToken);
      this._retrieveService.updateCompRaterQuoteId(deepLaunchId);
      this._agencyService.dispatchProducerCodeSearch();
      this._tokenService.insertAccessToken(accessToken);
      this._agencyService.updateAgency({
        agencyChannel: agencyChannel,
      });
      this._userContextService.updateUserContext({
        initiatedBy: InitiatedByType.COMPRATER,
        userType: agencyChannel === 'IA' ? UserType.IA : UserType.EA,
        retrieve: true,
        retrievedBy: agencyChannel === 'IA' ? UserType.IA : UserType.EA,
      });
      this._userContextService.updateUserContext({
        initiatedBy: InitiatedByType.COMPRATER,
        userType: UserType.IA,
        retrieve: true,
        retrievedBy: UserType.IA,
      });

      const request: ComparativeQuotesRequest = {
        comparativeQuoteId: deepLaunchId,
        accessToken: accessToken,
      };

      this._retrieveProxy.loadingBegin();

      return this._comparativeQuotesService.comparativeQuotes(request).pipe(
        map(response => {
          this._retrieveProxy.loadingEnd();
          const redirect = response.some(
            comp => comp.destination === RedirectDestinations.PC
          );

          if (redirect) {
            this._loggingService.log('Redirecting to PolicyCenter', response);
            const url = this._agencyService.buildPCHydrationUrl(deepLaunchId);
            this._loggingService.log(
              `Redirection url for ${deepLaunchId}`,
              url
            );
            window.location.href = url;
            return false;
          }

          return true;
        }),
        catchError(err => {
          this._retrieveProxy.loadingEnd();
          this._loggingService.log('Redirecting to PolicyCenter', err);
          const url = this._agencyService.buildPCHydrationUrl(deepLaunchId);
          this._loggingService.log('Redirect url', url);
          window.location.href = url;
          return of(false);
        })
      );
    }
  }

  _parseHashParams(rawParams: string): { [key: string]: string } {
    const res = {};

    rawParams.split('&').forEach(keyValuePair => {
      const key = keyValuePair.substr(0, keyValuePair.indexOf('='));
      const value = keyValuePair.substr(keyValuePair.indexOf('=') + 1);
      res[key] = value;
    });

    return res;
  }
}
