import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromNavigation from './navigation.reducer';

export interface QuotingState {
  navigation: fromNavigation.NavigationState;
}

export const reducers: ActionReducerMap<QuotingState> = {
  navigation: fromNavigation.reducer,
};

export const getQuotingState = createFeatureSelector<QuotingState>('quote');
