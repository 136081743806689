import { SearchActions } from '@core/store/actions';
import * as fromAction from '@core/store/actions/search.action';
import { SearchModel } from '@core/models/search/search.model';
import { Quote } from '@core/models/auto/quotes/search-quotes-response.model';
import { SearchEntity } from '@core/models/entities/search.entity';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

// Entities are keyed by QuoteIds
export interface SearchState extends EntityState<SearchEntity> {
  searchCriteria: SearchModel;
  selectedQuoteId: Quote;
  error: string;
}

const adapter: EntityAdapter<SearchEntity> = createEntityAdapter({
  selectId: quote => quote.quoteId,
});

const initialState: SearchState = adapter.getInitialState({
  ids: [],
  entities: {},
  searchCriteria: null,
  selectedQuoteId: null,
  error: '',
});

export function reducer(
  state = initialState,
  action: SearchActions
): SearchState {
  switch (action.type) {
    case fromAction.SearchActionTypes.SEARCH_QUOTES: {
      return {
        ...state,
        searchCriteria: action.payload,
        error: '',
      };
    }

    case fromAction.SearchActionTypes.SEARCH_QUOTES_SUCCESS: {
      state = initialState;
      return adapter.upsertMany(action.payload, {
        ...state,
        error: '',
      });
    }

    case fromAction.SearchActionTypes.SEARCH_QUOTES_ERROR: {
      return adapter.removeAll({
        ...state,
        error: action.error || 'Failed to search for quotes',
      });
    }

    case fromAction.SearchActionTypes.SEARCH_QUOTES_CLEAR_ERROR: {
      return {
        ...state,
        error: '',
      };
    }

    default:
      return state;
  }
}
