import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, take, map } from 'rxjs/operators';
import { DSM_STAGE_TO_TEST_TARGET_ENV } from '@shared/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class QuoteStartDateFetcher {
  private output = new BehaviorSubject<string>('');
  private env = '';

  constructor(private _httpClient: HttpClient) {}

  /* Emits the date as "MM/DD/YYYY" any time our environment changes and call completes.
   */
  streamResults(): Observable<string> {
    return this.output;
  }

  setEnvironment(env: string): void {
    if (env !== this.env) {
      this.env = env;
      this.fetchDate(
        DSM_STAGE_TO_TEST_TARGET_ENV[this.env]
          ? DSM_STAGE_TO_TEST_TARGET_ENV[this.env]
          : this.env
      )
        .pipe(take(1))
        .subscribe();
    }
  }

  /* Returns date as whatver PC returns (eg "Fri Nov 08 14:25:06 EST 2019").
   */
  fetchDate(env: string): Observable<string> {
    return this._httpClient
      .get<{
        effectiveDate: string;
      }>(
        'https://api-int-test.nwie.net/customer-acquisition/clocks/v1/clocks',
        {
          headers: {
            client_id: 'yGCdTLB5jieIjKxqBPOWfRDCseaS7lyR',
            'X-NW-Message-ID': 'calling-from-nwx-test-page',
            'X-NW-Target-Env': env,
          },
        }
      )
      .pipe(
        map(result => result.effectiveDate),
        tap(result => this.addOutput(result))
      );
  }

  /* Reformat PC date to MM/DD/YYYY and emit it to our output stream.
   */
  addOutput(input: string): void {
    if (!input) {
      return;
    }
    const words = input.split(' ');
    const minValidLength = 4;
    if (words.length >= minValidLength) {
      // Realistically will always be 6.
      const month = this.monthFromString(words[1]);
      const day = (words[2].length < 2 ? '0' : '') + words[2];
      const year = words[words.length - 1];
      this.output.next(`${month}/${day}/${year}`);
    }
  }

  monthFromString(input: string): string {
    // eslint-disable-next-line no-magic-numbers
    switch (input.toLowerCase().substr(0, 3)) {
      case 'jan':
        return '01';
      case 'feb':
        return '02';
      case 'mar':
        return '03';
      case 'apr':
        return '04';
      case 'may':
        return '05';
      case 'jun':
        return '06';
      case 'jul':
        return '07';
      case 'aug':
        return '08';
      case 'sep':
        return '09';
      case 'oct':
        return '10';
      case 'nov':
        return '11';
      case 'dec':
        return '12';
    }
    return '01';
  }
}
