import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  Params,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { TokenService } from '@core/services/token.service';
import { AgencyService, EuaHelper } from '@core/services';
import { AgencyModel } from '@core/models/agency/agency.model';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { DateUtils } from '@shared/utils/date.utils';
import { ProductTypes } from '@shared/constants/app-constants';
import { SearchProxy } from '../proxies';
import { AccessToken } from '@core/store/reducers/token.reducer';

interface nwJwtPayload extends JwtPayload {
  firstName: string;
  lastName: string;
  racfId: string;
}

@Injectable({
  providedIn: 'root',
})
export class SearchGuard implements CanActivate {
  constructor(
    private _tokenService: TokenService,
    private _agentService: AgencyService,
    private _router: Router,
    private _euaHelper: EuaHelper,
    private _searchProxy: SearchProxy
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._tokenService.getAll().pipe(
      withLatestFrom(this._agentService.getAgency()),
      map(([tokens, agency]) => {
        if (tokens.length) {
          if (agency) {
            if (['NSS', 'EA', 'IA'].includes(agency.agencyChannel)) {
              return true;
            } else {
              this._router.navigate(['/multi-quote/getting-started']);
              return false;
            }
          } else if (route.queryParams && route.queryParams.id_token) {
            const idToken = route.queryParams.id_token;
            this.updateAgency(
              this.getAgencyDataFromToken(idToken, agency, route.queryParams)
            );
            return true;
          }
        } else if (
          route.queryParams &&
          route.queryParams.access_token &&
          route.queryParams.expires_in &&
          route.queryParams.id_token
        ) {
          this.setAccessTokens(this.getTokenParameters(route));
          this.updateAgency(
            this.getAgencyDataFromToken(
              route.queryParams.id_token,
              agency,
              route.queryParams
            )
          );
          const decodedJwt = JSON.stringify(
            jwt_decode(route.queryParams.id_token)
          );
          this._agentService.updateAgentJwt(
            JSON.parse(decodedJwt),
            route.queryParams.access_token
          );
          return true;
        } else if (
          route.queryParams &&
          route.queryParams.error &&
          route.queryParams.error_description
        ) {
          this._searchProxy.softfall(
            'Agent Login Error: ' +
              route.queryParams.error +
              ' - ' +
              route.queryParams.error_description
          );
          return false;
        } else {
          const agencyChannel = route.queryParams.agencyChannel
            ? route.queryParams.agencyChannel
            : 'IA';
          const agencyModel: AgencyModel = {
            state: route.queryParams.agencyState
              ? route.queryParams.agencyState
              : '',
            producerCode: route.queryParams.producerCode
              ? route.queryParams.producerCode
              : '',
            agencyCode: route.queryParams.agencyCode
              ? route.queryParams.agencyCode
              : '',
            agencyChannel: agencyChannel,
            firstName: route.queryParams.firstName
              ? route.queryParams.firstName
              : '',
            lastName: route.queryParams.lastName
              ? route.queryParams.lastName
              : '',
            email: route.queryParams.email ? route.queryParams.email : '',
            userId: route.queryParams.userId ? route.queryParams.userId : '',
            quoteState: route.queryParams.state ? route.queryParams.state : '',
          };
          window.location.href = this._euaHelper.getEUAUrl(
            agencyModel,
            '/search',
            null,
            null
          );
          return false;
        }
      })
    );
  }

  private getAgencyDataFromToken(
    idToken: string,
    agency: AgencyModel,
    params: Params
  ): AgencyModel {
    const jwt = jwt_decode<nwJwtPayload>(idToken);
    const isApplicationEntry = params.agencyChannel !== null;

    const agencyModel = {
      firstName: jwt.firstName,
      lastName: jwt.lastName,
      userId: jwt.racfId || (jwt as unknown as { userId: string }).userId,
      email: isApplicationEntry ? params.emails : agency.email,
      quoteState: isApplicationEntry ? params.state : agency.quoteState,
      state: isApplicationEntry ? params.agencyState : agency.state,
      producerCode: isApplicationEntry
        ? params.producerCode
        : agency.producerCode,
      agencyCode: isApplicationEntry ? params.agencyCode : agency.agencyCode,
      agencyChannel: isApplicationEntry
        ? params.agencyChannel
        : agency.agencyChannel,
    };
    return agencyModel;
  }

  private updateAgency(agency: AgencyModel): void {
    if (agency && agency.userId && agency.firstName && agency.lastName) {
      this._agentService.updateAgency(agency);
    }
  }

  private getTokenParameters(route: ActivatedRouteSnapshot): AccessToken {
    const tokenModel = {
      accessToken: route.queryParams.access_token,
      expiresIn: route.queryParams.expires_in,
      productId: '',
      expiryDate: null,
    };
    return tokenModel;
  }

  private setAccessTokens(token: AccessToken): void {
    if (token && token.accessToken.length > 0 && token.expiresIn.length > 0) {
      token.expiryDate = DateUtils.expiryDateFromInterval(token.expiresIn);
      const products = [
        { id: ProductTypes.AUTO },
        { id: ProductTypes.HOMEOWNERS },
        { id: ProductTypes.RENTERS },
      ];
      products.forEach(product => {
        const copyOfToken = Object.assign({}, token);
        copyOfToken.productId = product.id;
        this._tokenService.setAccessToken(copyOfToken);
      });
    }
  }
}
