import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { Observable } from 'rxjs';

import { CondoModel } from '@core/models/condo/condo.model';

@Injectable()
export class CondoDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  addCondo(value: CondoModel) {
    this._store.dispatch(new fromActions.AddCondo(value));
  }

  updateCondo(value: CondoModel) {
    this._store.dispatch(new fromActions.AddCondo(value));
  }

  getCondo(): Observable<CondoModel> {
    return this._store.select(fromSelectors.getCondo);
  }

  hasCondoMortgage(): Observable<boolean> {
    return this._store.select(fromSelectors.hasCondoMortgage);
  }
}
