import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryParameterPreservingRedirectComponent } from './query-parameter-preserving-redirect.component';

@NgModule({
  imports: [CommonModule],
  declarations: [QueryParameterPreservingRedirectComponent],
  exports: [QueryParameterPreservingRedirectComponent],
})
export class QueryParameterPreservingRedirectModule {}
