import { Action } from '@ngrx/store';
import { NavigationPath } from '@core/models/navigation/navigation.model';

export const LOAD_ROUTES = '[Quoting] Load Routes';
export const LOAD_ROUTES_FAIL = '[Quoting] Load Routes Fail';
export const LOAD_ROUTES_SUCCESS = '[Quoting] Load Routes Success';

export class LoadRoutes implements Action {
  readonly type = LOAD_ROUTES;
}

export class LoadRoutesFail implements Action {
  readonly type = LOAD_ROUTES_FAIL;
  constructor(public payload: any) {}
}

export class LoadRoutesSuccess implements Action {
  readonly type = LOAD_ROUTES_SUCCESS;
  constructor(public payload: NavigationPath) {}
}

export const UPDATE_ROUTE_PATH = '[Quoting] Update Route Path';

export class UpdateRoutePath implements Action {
  readonly type = UPDATE_ROUTE_PATH;
  constructor(public payload: NavigationPath) {}
}

export const NAVIGATE_FORWARD = '[Quoting] Navigate Forward';
export const NAVIGATE_BACK = '[Quoting] Navigate Previous';

export class NavigateForward implements Action {
  readonly type = NAVIGATE_FORWARD;
  constructor(public payload: NavigationPath) {}
}

export class NavigateBack implements Action {
  readonly type = NAVIGATE_BACK;
  constructor(public payload: NavigationPath) {}
}

export const SOFTFALL = '[Quote] Softfall';
export class Softfall implements Action {
  readonly type = SOFTFALL;
  constructor(public reason: string) {}
}

export const MAINTENANCE = '[Quote] Maintenance';
export class Maintenance implements Action {
  readonly type = MAINTENANCE;
  constructor(public reason: string) {}
}

export const FORCE_NAVIGATION_INDEX =
  '[Navigation] Quoting Force Navigation Index';
export class ForceNavigationIndex implements Action {
  readonly type = FORCE_NAVIGATION_INDEX;
  constructor(public url: string) {}
}

export const UPDATE_NAVIGATION_PATH = '[Navigation] Update Navigation Path';
export class UpdateNavigationPath implements Action {
  readonly type = UPDATE_NAVIGATION_PATH;
  constructor() {}
}

export const SKIP_CURRENT_PAGE_NAVIGATION =
  '[Quote] Skip Current Page Navigation';
export class SkipCurrentPageNavigation implements Action {
  readonly type = SKIP_CURRENT_PAGE_NAVIGATION;
  constructor(public url: string) {}
}

export const SKIP_NEXT_PAGE_NAVIGATION = '[Quote] Skip Next Page Navigation';
export class SkipNextPageNavigation implements Action {
  readonly type = SKIP_NEXT_PAGE_NAVIGATION;
  constructor(public url: string) {}
}

export type NavigationActions =
  | LoadRoutes
  | LoadRoutesFail
  | LoadRoutesSuccess
  | UpdateRoutePath
  | NavigateForward
  | NavigateBack
  | Softfall
  | Maintenance
  | ForceNavigationIndex
  | UpdateNavigationPath
  | SkipCurrentPageNavigation
  | SkipNextPageNavigation;
