import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { ModifierNames, ProductTypes } from '@shared/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class ModifiersDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  updateDriverDiscount(entity: EligibleDiscountEntity): void {
    this._store.dispatch(new fromActions.UpdateDriverDiscount(entity));
  }

  updatePowersportsDriverDiscount(entity: EligibleDiscountEntity): void {
    this._store.dispatch(
      new fromActions.UpdatePowersportsDriverDiscount(entity)
    );
  }

  getPowersportsDriverDiscountByDriverId(
    eligibleDiscountId: string,
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getPowersportsDriverDiscountByDriverId(
        eligibleDiscountId,
        driverId
      )
    );
  }

  getDriverDiscountByDriverId(
    eligibleDiscountId: string,
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getDriverDiscountByDriverId(eligibleDiscountId, driverId)
    );
  }

  getPolicyLine(productId: string): void {
    this._store.dispatch(new fromActions.GetPolicyLine(productId));
  }

  getPolicyLineModifier(
    name: string,
    productId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getPolicyLineModifier(name, productId)
    );
  }

  getSmartHomeDiscount(
    name: string,
    productId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getSmartHomeDiscount(name, productId)
    );
  }

  dispatchUpdateSmartHomeDiscount(selection: string): void {
    this._store.dispatch(new fromActions.UpdateSmartHomeDiscount(selection));
  }

  dispatchUpdatePolicyLine(request: EligibleDiscountEntity): void {
    this._store.dispatch(new fromActions.UpdatePolicyLine(request));
  }

  dispatchUnsetSmartrideLoaded(): void {
    this._store.dispatch(new fromActions.UnsetSmartrideLoaded());
  }

  getSmartRideLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getSmartRideLoaded);
  }

  dispatchUpdateVehicleDiscount(request: EligibleDiscountEntity): void {
    this._store.dispatch(new fromActions.UpdateVehicleDiscount(request));
  }

  areModifiersLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getModifierLoading);
  }

  updateAssociateDiscount(discount: EligibleDiscountEntity): void {
    this._store.dispatch(new fromActions.UpdateAssociateDiscount(discount));
  }

  getPrimaryAssociateDiscount(): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getPrimaryAssociateDiscountModifier
    );
  }

  beginCheckEasyPayDiscount(
    paymentPlan: string,
    paymentMethod: string,
    recurring: boolean
  ): void {
    this._store.dispatch(
      new fromActions.CheckEasyPayDiscount(
        paymentPlan,
        paymentMethod,
        recurring
      )
    );
  }
  updateMembershipDiscount(discount: EligibleDiscountEntity): void {
    this._store.dispatch(new fromActions.UpdateMembershipDiscount(discount));
  }
  updateBillingPaymentMethod(discount: EligibleDiscountEntity): void {
    this._store.dispatch(new fromActions.UpdateBillingPaymentMethod(discount));
  }

  getBillingPaymentMethodModifier(): Observable<EligibleDiscountEntity> {
    return this._store.select(fromSelectors.getBillingPaymentMethodModifier());
  }

  getEasyPayModifier(): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getPolicyLineModifier(
        ModifierNames.EASY_PAY,
        ProductTypes.AUTO
      )
    );
  }

  getPaidInFullPowersportsModifier(): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getPolicyLineModifier(
        ModifierNames.PAIDINFULL,
        ProductTypes.POWERSPORTS
      )
    );
  }
}
