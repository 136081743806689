import { Injectable } from '@angular/core';
import { of, from, combineLatest } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  concatMap,
  mergeMap,
  withLatestFrom,
  filter,
  delayWhen,
} from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services/logging.service';
import { ModifiersService } from '@core/services/modifiers.service';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { ModifiersBuilder } from '@shared/utils/builders/modifiers.builder';
import { ModifierNames, ProductTypes } from '@shared/constants/app-constants';
import { TokenService } from '@core/services/token.service';
import { ProductsService, UserContextService } from '@core/services';
import { Product } from '@core/models/products/product.model';
import { AccessToken } from '@core/store/reducers/token.reducer';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { NrErrorUtils } from '@shared/utils/nr-error.utils';
import { Update } from '@ngrx/entity';
import { RetrieveService } from '@core/services/retrieve.service';
@Injectable()
export class ModifiersEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _modifiersService: ModifiersService,
    private _loggingService: LoggingService,
    private _productsService: ProductsService,
    private _tokenService: TokenService,
    private _errorHelper: ErrorHelper,
    private _userContextService: UserContextService,
    private _retrieveService: RetrieveService
  ) {}

  @Effect()
  updateDriverDiscount$ = this._actions$.pipe(
    ofType(fromActions.ModifierActionTypes.UPDATE_DRIVER_DISCOUNT),
    map((action: fromActions.UpdateDriverDiscount) => action.payload),
    concatMap(payload => {
      return this._store
        .select(fromSelectors.buildDriverDiscountRequest(payload))
        .pipe(take(1));
    }),

    switchMap(request =>
      this._store
        .select(
          fromSelectors.getDriverDiscountByDriverId(
            request.eligibleDiscount.eligibleDiscountId,
            request.modelId
          )
        )
        .pipe(
          take(1),
          map(existing => ({ request, existing }))
        )
    ),
    map(({ request, existing }) => {
      if (this._modifiersService.discountNeedsUpdate(request, existing)) {
        return request;
      } else {
        return null; // signal to cancel request
      }
    }),

    tap(request => {
      if (request) {
        this._loggingService.log('updateDriverDiscount request', request);
      }
    }),
    concatMap(request => {
      if (!request || request.modelId === undefined)
        return of(new fromActions.ModifierActionCancelled());
      return this._modifiersService.updateDriverDiscount(request).pipe(
        tap(response => {
          this._loggingService.log('updateDriverDiscount response', response);
        }),
        map(response => {
          const entity: EligibleDiscountEntity = {
            ...response,
            modelId: request.modelId,
            productId: request.productId,
          };
          return new fromActions.UpdateDriverDiscountSuccess(entity);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updateDriverDiscount error', safeError);
          return of(new fromActions.UpdateDriverDiscountFail(safeError));
        })
      );
    })
  );

  @Effect()
  updatePowersportsDriverDiscount$ = this._actions$.pipe(
    ofType(fromActions.ModifierActionTypes.UPDATE_POWERSPORTS_DRIVER_DISCOUNT),
    map(
      (action: fromActions.UpdatePowersportsDriverDiscount) => action.payload
    ),
    concatMap(payload => {
      return this._store
        .select(fromSelectors.buildPowersportsDriverDiscountRequest(payload))
        .pipe(take(1));
    }),

    switchMap(request =>
      this._store
        .select(
          fromSelectors.getPowersportsDriverDiscountByDriverId(
            request.eligibleDiscount.eligibleDiscountId,
            request.modelId
          )
        )
        .pipe(
          take(1),
          map(existing => ({ request, existing }))
        )
    ),
    map(({ request, existing }) => {
      if (this._modifiersService.discountNeedsUpdate(request, existing)) {
        return request;
      } else {
        return null; // signal to cancel request
      }
    }),

    tap(request => {
      if (request) {
        this._loggingService.log(
          'updatePowersportsDriverDiscount request',
          request
        );
      }
    }),
    concatMap(request => {
      if (!request || request.modelId === undefined)
        return of(new fromActions.ModifierActionCancelled());
      return this._modifiersService
        .updatePowersportsDriverDiscount(request)
        .pipe(
          tap(response => {
            this._loggingService.log(
              'updatePowersportsDriverDiscount response',
              response
            );
          }),
          map(response => {
            const entity: EligibleDiscountEntity = {
              ...response,
              modelId: request.modelId,
              productId: request.productId,
            };
            return new fromActions.UpdatePowersportsDriverDiscountSuccess(
              entity
            );
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log(
              'updatePowersportsDriverDiscount error',
              safeError
            );
            return of(
              new fromActions.UpdatePowersportsDriverDiscountFail(safeError)
            );
          })
        );
    })
  );

  @Effect()
  updatePolicyLine$ = this._actions$.pipe(
    ofType(fromActions.ModifierActionTypes.UPDATE_POLICY_LINE),
    map((action: fromActions.UpdatePolicyLine) => action.payload),
    mergeMap(payload =>
      this._store
        .select(fromSelectors.buildUpdatePolicyLineRequest(payload))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('updatePolicyLine Request', request)
    ),
    mergeMap(request =>
      this._modifiersService.updatePolicyLine(request).pipe(
        tap(response =>
          this._loggingService.log('updatePolicyLine Response', response)
        ),
        withLatestFrom(
          this._userContextService.isQuoteRetrieve(),
          this._productsService.isAutoSelected(),
          this._productsService.isProductSelectedWithoutError(ProductTypes.AUTO)
        ),
        mergeMap(
          ([response, isQuoteRetrieve, isAutoselected, isAutoWithoutError]) => {
            const entity = ModifiersBuilder.buildEntityFromResponse(response);
            const actions: any = [];
            if (
              (!isQuoteRetrieve &&
                response.productId !== ProductTypes.POWERSPORTS &&
                !isAutoselected) ||
              (isAutoselected && isAutoWithoutError)
            ) {
              actions.push(new fromActions.InvalidateQuote(request.productId));
            }

            actions.push(new fromActions.UpdatePolicyLineSuccess(entity));

            if (request.name === ModifierNames.SMARTRIDE) {
              actions.push(
                new fromActions.SetSmartRideLoaded(
                  `${ModifierNames.SMARTRIDE}_${request.quoteId}`
                )
              );
            }
            return from(actions);
          }
        ),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updatePolicyLine Error', safeError);
          return of(new fromActions.UpdatePolicyLineFail(safeError));
        })
      )
    )
  );

  @Effect()
  getPolicyLine$ = this._actions$.pipe(
    ofType(fromActions.ModifierActionTypes.GET_POLICY_LINE),
    map((action: fromActions.GetPolicyLine) => action.payload),
    switchMap(productId =>
      this._store
        .select(fromSelectors.buildGetModifiersRequest(<string>productId))
        .pipe(
          take(1),
          map(request => request)
        )
    ),
    tap(request => this._loggingService.log('getPolicyLine Request', request)),
    mergeMap(request =>
      this._modifiersService.getPolicyLine(request).pipe(
        tap(response =>
          this._loggingService.log('getPolicyLine Response', response)
        ),
        mergeMap(response => {
          const entities =
            ModifiersBuilder.buildEntityFromArrayResponse(response);
          return of(new fromActions.GetPolicyLineSuccess(entities));
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('getPolicyLine Error', safeError);
          return of(new fromActions.GetPolicyLineFail(safeError));
        })
      )
    )
  );

  @Effect()
  updateSmartHomeDiscount$ = this._actions$.pipe(
    ofType(fromActions.ModifierActionTypes.UPDATE_SMARTHOME_DISCOUNT),
    map((action: fromActions.UpdateSmartHomeDiscount) => action.payload),
    mergeMap(payload =>
      this._store
        .select(fromSelectors.buildUpdateSmartHomeDiscountRequest(payload))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('updateSmartHomeDiscount Request', request)
    ),
    mergeMap(request =>
      this._modifiersService.updateSmartHomeDiscount(request).pipe(
        tap(response =>
          this._loggingService.log('updateSmartHomeDiscount Response', response)
        ),
        mergeMap(response => {
          const entity = ModifiersBuilder.buildEntityFromResponse(response);
          const updatedSmartHomeDiscountModifier: Update<EligibleDiscountEntity> =
            {
              id: `${ModifierNames.SMARTHOMEDEVICE}_${request.quoteId}`,
              changes: response,
            };

          const actions: any = [
            new fromActions.UpdateSmartHomeDiscountSuccess(
              updatedSmartHomeDiscountModifier
            ),
          ];
          return from(actions);
        }),
        catchError(error => {
          const safeError = NrErrorUtils.sanitizeError(error);
          this._loggingService.log('updateSmartHomeDiscount Error', safeError);
          return of(new fromActions.UpdateSmartHomeDiscountFail(safeError));
        })
      )
    )
  );

  @Effect()
  checkForMultiProductDiscount$ = combineLatest([
    this._productsService.getSelectedCoverageProducts(),
    this._tokenService.getAll(),
  ]).pipe(
    withLatestFrom(
      this._modifiersService.areModifiersLoading(),
      this._retrieveService.getRetrieveLoading()
    ),
    switchMap(([[products, tokens], loading, getRetrieveLoading]) => {
      // products cannot be updated during bind rating or after completed bind request
      const anyProductsRating = products.some(product => product.quoteRating);
      const anyQuoteIdsMissing = products.some(product => !product.quoteId);
      const nonLifeProducts = products.filter(
        product => product.id !== ProductTypes.TERMLIFE
      );
      if (
        products.length === 0 ||
        loading ||
        getRetrieveLoading ||
        anyProductsRating ||
        !this.allTokensPresent(nonLifeProducts, tokens) ||
        anyQuoteIdsMissing
      ) {
        return from([]);
      }
      return this._modifiersService.makeMultiproductActionsIfApplicable(
        products
      );
    })
  );

  @Effect()
  updateVehicleDiscount$ = this._actions$.pipe(
    ofType(fromActions.ModifierActionTypes.UPDATE_VEHICLE_DISCOUNT),
    map((action: fromActions.UpdateVehicleDiscount) => action.payload),
    mergeMap(payload =>
      this._store
        .select(fromSelectors.buildUpdateVehicleDiscountRequest(payload))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('updateVehicleDiscount Request', request)
    ),
    mergeMap(request =>
      this._modifiersService.updateVehicleDiscount(request).pipe(
        tap(response =>
          this._loggingService.log('updateVehicleDiscount Response', response)
        ),
        mergeMap(response => {
          const entity = ModifiersBuilder.buildEntityFromResponse(response);
          entity.vehicleId = request.vehicleId;

          const actions: any = [
            new fromActions.InvalidateQuote(request.productId),
            new fromActions.UpdateVehicleDiscountSuccess(
              request.vehicleId,
              entity
            ),
            // Removing logic to update store with all eligible discounts
            // becuase it is causing an issue with Anti-Theft

            // new fromActions.LoadVehicleDiscounts({
            //   productId: request.productId,
            //   vehicleId: request.vehicleId,
            // }),
          ];

          if (
            this.isDiscountSelected(
              entity.eligibleDiscountId,
              ModifierNames.VEHICLE_SELFREPORTED,
              entity.selectedOptionValue
            )
          ) {
            // When Self Reported Selected, Disable Veh Smartride in Store
            actions.push(
              new fromActions.DisableVehicleDiscount(
                request.vehicleId,
                ModifierNames.VEHICLE_SMARTRIDE
              )
            );
          }

          if (
            this.isDiscountSelected(
              entity.eligibleDiscountId,
              ModifierNames.VEHICLE_SMARTRIDE,
              entity.selectedOptionValue
            )
          ) {
            // When Veh Smartride Selected, Disable Self Reported in Store
            actions.push(
              new fromActions.DisableVehicleDiscount(
                request.vehicleId,
                ModifierNames.VEHICLE_SELFREPORTED
              )
            );
          }
          actions.push(
            new fromActions.SetVehicleDiscountsLoaded(request.vehicleId)
          );

          return from(actions);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updateVehicleDiscount Error', safeError);
          return from([
            new fromActions.UpdateVehicleDiscountFail(
              request.vehicleId,
              safeError
            ),
            new fromActions.Softfall(
              fromActions.ModifierActionTypes.UPDATE_VEHICLE_DISCOUNT_FAIL
            ),
          ]);
        })
      )
    )
  );

  @Effect()
  updatePowersportsVehicleDiscount$ = this._actions$.pipe(
    ofType(fromActions.ModifierActionTypes.UPDATE_POWERSPORTS_VEHICLE_DISCOUNT),
    map(
      (action: fromActions.UpdatePowersportsVehicleDiscount) => action.payload
    ),
    mergeMap(payload =>
      this._store
        .select(
          fromSelectors.buildUpdatePowersportsVehicleDiscountRequest(payload)
        )
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log(
        'updatePowersportsVehicleDiscount Request',
        request
      )
    ),
    mergeMap(request =>
      this._modifiersService.updatePowersportsVehicleDiscount(request).pipe(
        tap(response =>
          this._loggingService.log(
            'updatePowersportsVehicleDiscount Response',
            response
          )
        ),
        mergeMap(response => {
          const entity = ModifiersBuilder.buildEntityFromResponse(response);
          entity.vehicleId = request.vehicleId;

          const actions: any = [
            new fromActions.InvalidateQuote(request.productId),
            new fromActions.UpdatePowersportsVehicleDiscountSuccess(
              request.vehicleId,
              entity
            ),
          ];
          actions.push(
            new fromActions.SetPowersportsVehicleDiscountsLoaded(
              request.vehicleId
            )
          );

          return from(actions);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log(
            'updatePowersportsVehicleDiscount Error',
            safeError
          );
          return from([
            new fromActions.UpdatePowersportsVehicleDiscountFail(
              request.vehicleId,
              safeError
            ),
            new fromActions.Softfall(
              fromActions.ModifierActionTypes.UPDATE_POWERSPORTS_VEHICLE_DISCOUNT_FAIL
            ),
          ]);
        })
      )
    )
  );

  @Effect()
  loadVehicleDiscounts$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.LOAD_VEHICLE_DISCOUNTS),
    map((action: fromActions.LoadVehicleDiscounts) => action.payload),
    switchMap(payload =>
      this._store
        .select(
          fromSelectors.buildGetVehicleModifiersRequest(
            payload.productId,
            payload.vehicleId
          )
        )
        .pipe(take(1))
    ),
    switchMap(request =>
      this._modifiersService.getVehicleDiscounts(request).pipe(
        mergeMap(response => {
          const entities =
            ModifiersBuilder.buildEntityFromArrayResponse(response);

          const actions: any = [
            new fromActions.LoadVehicleDiscountsSuccess(
              request.vehicleId,
              entities
            ),
          ];
          return from(actions);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('loadVehicleDiscounts Error', safeError);
          return of(
            new fromActions.LoadVehicleDiscountsFail(
              request.vehicleId,
              safeError
            )
          );
        })
      )
    )
  );

  @Effect()
  updateAssociateDiscount$ = this._actions$.pipe(
    ofType(fromActions.ModifierActionTypes.UPDATE_ASSOCIATE_DISCOUNT),
    map((action: fromActions.UpdateAssociateDiscount) => action.payload),
    mergeMap(payload =>
      this._store
        .select(fromSelectors.buildAssociateDiscountRequest(payload))
        .pipe(take(1))
    ),

    switchMap(request => {
      return this.getPolicyLineModifier(request);
    }),
    map(({ request, existing }) => {
      return this.discountNeedsUpdate(request, existing);
    }),

    tap(request => {
      if (request) {
        this._loggingService.log('updateAssociateDiscount Request', request);
      }
    }),
    mergeMap(request => {
      if (!request) return of(new fromActions.ModifierActionCancelled());
      return this._modifiersService.updatePolicyLine(request).pipe(
        tap(response =>
          this._loggingService.log('updateAssociateDiscount Response', response)
        ),
        mergeMap(response => {
          const responseEntity =
            ModifiersBuilder.buildEntityFromResponse(response);

          // This is required because DSM truncates the associate number's leading zeros
          if (request.eligibleDiscount.selectedOptionValue === 'true') {
            responseEntity.qualifyingInformation = {
              associateNumber:
                request.eligibleDiscount.qualifyingInformation.associateNumber,
            };
          }

          return of(
            new fromActions.UpdateAssociateDiscountSuccess(responseEntity)
          );
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updateAssociateDiscount Error', safeError);
          return of(new fromActions.UpdateAssociateDiscountFail(safeError));
        })
      );
    })
  );

  @Effect()
  checkEasyPayDiscount$ = this._actions$.pipe(
    ofType<fromActions.CheckEasyPayDiscount>(
      fromActions.ModifierActionTypes.CHECK_EASY_PAY_DISCOUNT
    ),
    delayWhen(() =>
      this._store
        .select(fromSelectors.getModifierLoading)
        .pipe(filter(loading => !loading))
    ),
    withLatestFrom(
      this._store.select(fromSelectors.getProduct(ProductTypes.AUTO))
    ),
    filter(([action, product]) => product && !product.hasError),
    switchMap(([action, product]) => {
      return this._store
        .select(
          fromSelectors.getPolicyLineModifier(
            ModifierNames.EASY_PAY,
            ProductTypes.AUTO
          )
        )
        .pipe(
          take(1),
          map(modifier => ({
            action,
            modifier,
          }))
        );
    }),
    map(({ action, modifier }) => {
      if (modifier) {
        const response = this._modifiersService.checkEasyPayDiscount(
          action.paymentPlan,
          action.paymentMethod,
          action.recurring,
          modifier?.selectedOptionValue
        );
        switch (response) {
          case 'add':
            return new fromActions.AddEasyPayDiscount();
          case 'remove':
            return new fromActions.RemoveEasyPayDiscount();
        }
      }
      return null;
    }),
    filter(reaction => !!reaction)
  );

  @Effect()
  removeEasyPayDiscount$ = this._actions$.pipe(
    ofType<fromActions.RemoveEasyPayDiscount>(
      fromActions.ModifierActionTypes.REMOVE_EASY_PAY_DISCOUNT
    ),
    withLatestFrom(
      this._store.select(fromSelectors.getProduct(ProductTypes.AUTO))
    ),
    switchMap(([action, product]) => {
      const modifier = {
        eligibleDiscountId: ModifierNames.EASY_PAY,
        modelId: product.quoteId,
        productId: ProductTypes.AUTO,
        selectedOptionValue: 'false',
      };
      return of(new fromActions.UpdatePolicyLine(modifier));
    })
  );

  @Effect()
  addEasyPayDiscount$ = this._actions$.pipe(
    ofType<fromActions.AddEasyPayDiscount>(
      fromActions.ModifierActionTypes.ADD_EASY_PAY_DISCOUNT
    ),
    withLatestFrom(
      this._store.select(fromSelectors.getProduct(ProductTypes.AUTO))
    ),
    map(([action, product]) => {
      const modifier = {
        eligibleDiscountId: ModifierNames.EASY_PAY,
        modelId: product.quoteId,
        productId: ProductTypes.AUTO,
        selectedOptionValue: 'true',
      };
      return new fromActions.UpdatePolicyLine(modifier);
    })
  );

  allTokensPresent(products: Product[], tokens: AccessToken[]): boolean {
    if (tokens.length < products.length) {
      return false;
    }
    for (const product of products) {
      if (!tokens.find(token => token.productId === product.id)) {
        return false;
      }
    }
    return true;
  }

  isMultiProductDiscountBeingModified(modifierName: string): boolean {
    return (
      modifierName === ModifierNames.HOMEOWNER_PP ||
      modifierName === ModifierNames.TENANTS_PP ||
      modifierName === ModifierNames.HOME_CAR_INDICATOR_I
    );
  }

  isDiscountSelected(
    actualDiscountId: string,
    expectedDiscountId: string,
    selectedValue: string
  ): boolean {
    return actualDiscountId === expectedDiscountId && selectedValue === 'true';
  }

  @Effect()
  updateMembershipDiscount$ = this._actions$.pipe(
    ofType(fromActions.ModifierActionTypes.UPDATE_MEMBERSHIP_DISCOUNT),
    map((action: fromActions.UpdateMembershipDiscount) => action.payload),
    mergeMap(payload =>
      this._store
        .select(fromSelectors.buildMembershipDiscountRequest(payload))
        .pipe(take(1))
    ),

    switchMap(request => {
      return this.getPolicyLineModifier(request);
    }),
    map(({ request, existing }) => {
      return this.discountNeedsUpdate(request, existing);
    }),

    tap(request => {
      if (request) {
        this._loggingService.log('updateMembershipDiscount Request', request);
      }
    }),
    mergeMap(request => {
      if (!request) return of(new fromActions.ModifierActionCancelled());
      return this._modifiersService.updatePolicyLine(request).pipe(
        tap(response =>
          this._loggingService.log(
            'updateMembershipDiscount Response',
            response
          )
        ),
        mergeMap(response => {
          const responseEntity =
            ModifiersBuilder.buildEntityFromResponse(response);

          return of(
            new fromActions.UpdateMembershipDiscountSuccess(responseEntity)
          );
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updateMembershipDiscount Error', safeError);
          return of(new fromActions.UpdateMembershipDiscountFail(safeError));
        })
      );
    })
  );

  @Effect()
  updateBillingPaymentMethod$ = this._actions$.pipe(
    ofType(fromActions.ModifierActionTypes.UPDATE_BILLING_PAYMENT_METHOD),
    map((action: fromActions.UpdateBillingPaymentMethod) => action.payload),
    mergeMap(payload =>
      this._store
        .select(fromSelectors.buildBillingPaymentMethodRequest(payload))
        .pipe(take(1))
    ),
    switchMap(action => {
      const modifier = {
        eligibleDiscountId: ModifierNames.BILLINGPAYMENTMETHOD,
        modelId: action.quoteId,
        productId: action.productId,
        selectedOptionValue: action.eligibleDiscount.selectedOptionValue,
      };
      return of(new fromActions.UpdatePolicyLine(modifier));
    })
  );

  private getPolicyLineModifier(request) {
    return this._store
      .select(
        fromSelectors.getPolicyLineModifier(
          request.eligibleDiscount.eligibleDiscountId,
          request.productId
        )
      )
      .pipe(
        take(1),
        map(existing => ({ request, existing }))
      );
  }

  private discountNeedsUpdate(request, existing) {
    if (this._modifiersService.discountNeedsUpdate(request, existing)) {
      return request;
    } else {
      return null; // signal to cancel request
    }
  }
}
