import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromProducts from '@core/store/actions/products.action';
import * as fromQuote from '@core/store/actions/quote.action';
import * as fromCoverages from '@core/store/actions/coverage.action';
import * as fromPolicyIssue from '@core/store/actions/policy-issue.action';
import * as fromPolicyHolders from '@core/store/actions/policyholders.action';
import * as fromDrivers from '@core/store/actions/driver.action';
import * as fromVehicles from '@core/store/actions/vehicle.action';

import { Product } from '@core/models/products/product.model';
import { ProductTypes } from '@shared/constants/app-constants';
import * as fromPolicyNumber from '@core/store/actions/policy-number.action';
export interface ProductsState extends EntityState<Product> {
  loaded: boolean;
  loading: boolean;
  selectedProducts: string[];
}

export const adapter: EntityAdapter<Product> = createEntityAdapter({});

export function reducer(
  state = {
    ...adapter.getInitialState(),
    loaded: false,
    loading: false,
    selectedProducts: [],
  },
  action:
    | fromProducts.ProductsAction
    | fromQuote.QuoteActions
    // | fromCoverages.UpdateMultiQuoteCoveragesSelectedProducts
    | fromCoverages.CoverageActions
    | fromPolicyIssue.PolicyIssueActions
    | fromPolicyHolders.PolicyholderActions
    | fromDrivers.DriverActions
    | fromVehicles.DsmVehicleActions
    | fromPolicyNumber.PolicyNumberActions
): ProductsState {
  switch (action.type) {
    case fromProducts.LOAD_PRODUCTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromProducts.LOAD_PRODUCTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromProducts.LOAD_PRODUCTS_SUCCESS: {
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
    }

    case fromProducts.UPDATE_SELECTED_PRODUCTS: {
      const selectedProducts = action.payload.map(product => product.id).sort();

      return {
        ...state,
        selectedProducts,
      };
    }

    case fromProducts.UPDATE_ALL_PRODUCTS: {
      return adapter.updateMany(action.payload, state);
    }

    case fromProducts.ADD_TO_SELECTED_PRODUCTS: {
      if (state.selectedProducts.indexOf(action.payload.id) >= 0) {
        return state;
      } else {
        return {
          ...state,
          selectedProducts: [
            ...state.selectedProducts,
            action.payload.id,
          ].sort(),
        };
      }
    }

    case fromProducts.REMOVE_FROM_SELECTED_PRODUCTS: {
      return {
        ...state,
        selectedProducts: state.selectedProducts.filter(
          id => id !== action.payload.id
        ),
      };
    }

    case fromProducts.UPDATE_PRODUCT: {
      return adapter.updateOne(action.payload, state);
    }

    case fromProducts.SET_LEADS_SENT_TO_CMA: {
      const changes = (<string[]>state.ids).map(id => ({
        id: id,
        changes: {
          leadSentToCma: true,
        },
      }));
      return adapter.updateMany(changes, state);
    }

    case fromProducts.FAIL_PRODUCT: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            hasError: true,
            errorCode: action.errorCode,
          },
        },
        state
      );
    }

    case fromProducts.FAIL_ALL_PRODUCTS: {
      const updates = (<string[]>state.ids).map(id => ({
        id: id,
        changes: {
          hasError: true,
          errorCode: action.errorCode,
        },
      }));
      return adapter.updateMany(updates, state);
    }

    case fromProducts.DISQUALIFY_UMBRELLA: {
      return adapter.updateOne(
        {
          id: ProductTypes.UMBRELLA,
          changes: {
            isQualified: false,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.RATE_QUOTE: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            quoteRating: true,
            quoteRated: false,
            quoteCompleted: false,
            quoteBinding: false,
            quoteBound: false,
            bindCompleted: false,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.RATE_QUOTE_SUCCESS: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            quoteRating: false,
            quoteRated: true,
            quoteCompleted: true,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.RATE_QUOTE_FAIL: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            hasError: true,
            quoteRating: false,
            quoteRated: false,
            quoteCompleted: true,
            errorCode: action.errorCode,
          },
        },
        state
      );
    }
    case fromQuote.QuoteActionTypes.RATE_BIND_SUCCESS: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            quoteBinding: false,
            quoteRating: false,
            bindRated: true,
            bindCompleted: true,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.RATE_BIND_FAIL: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            hasError: true,
            quoteBinding: false,
            quoteRating: false,
            bindRated: false,
            bindCompleted: true,
            errorCode: action.errorCode,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.INITIATE_NEW_BUSINESS_FAIL: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            hasError: true,
            errorCode: action.errorCode,
          },
        },
        state
      );
    }

    case fromVehicles.VehicleActionTypes.ADD_VEHICLE:
    case fromVehicles.VehicleActionTypes.UPDATE_VEHICLE:
    case fromDrivers.DriverActionTypes.ADD_DRIVER:
    case fromDrivers.DriverActionTypes.UPDATE_DRIVER:
    case fromVehicles.VehicleActionTypes.ADD_POWERSPORTS_VEHICLE:
    case fromVehicles.VehicleActionTypes.UPDATE_POWERSPORTS_VEHICLE:
    case fromVehicles.VehicleActionTypes.DELETE_POWERSPORTS_VEHICLE:
    case fromDrivers.DriverActionTypes.ADD_POWERSPORTS_DRIVER:
    case fromDrivers.DriverActionTypes.UPDATE_POWERSPORTS_DRIVER:
    case fromPolicyHolders.PolicyholderActionTypes.ADD_POLICYHOLDER:
    case fromPolicyHolders.PolicyholderActionTypes.UPDATE_POLICYHOLDER: {
      return adapter.updateOne(
        {
          id: action.payload.productId,
          changes: {
            quoteRated: false,
            quoteCompleted: false,
            quoteBound: false,
            bindCompleted: false,
            allQuoteCoverageLoaded: false,
            bindRated: false,
            hasError: false,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.INVALIDATE_QUOTE: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            quoteRated: false,
            quoteCompleted: false,
            quoteBound: false,
            bindCompleted: false,
            allQuoteCoverageLoaded: false,
            bindRated: false,
            hasError: false,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.INVALIDATE_BIND: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            quoteBinding: false,
            quoteBound: false,
            bindCompleted: false,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.INVALIDATE_QUOTE_COMPLETION: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            quoteCompleted: false,
            quoteCoverageLoaded: false,
            allQuoteCoverageLoaded: false,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.VALIDATE_QUOTE_COMPLETION: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            quoteCompleted: true,
            quoteCoverageLoaded: true,
            allQuoteCoverageLoaded: true,
          },
        },
        state
      );
    }

    case fromPolicyIssue.PolicyIssueTypes.MARK_PRODUCT_ISSUING: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            issueLoading: true,
          },
        },
        state
      );
    }

    case fromPolicyIssue.PolicyIssueTypes.ISSUE_POLICY_SUCCESS: {
      const { payload } = action;
      return adapter.updateOne(
        {
          id: payload.productId,
          changes: {
            issueCompleted: true,
            issueLoading: false,
            issueFailed: false,
            tempUserId: payload.tempUserId,
            contextCacheGUID: payload.contextCacheGUID,
            contextCacheOTP: payload.contextCacheOTP,
          },
        },
        state
      );
    }

    case fromPolicyIssue.PolicyIssueTypes.ISSUE_POLICY_FAIL: {
      const { productId, error } = action;
      return adapter.updateOne(
        {
          id: productId,
          changes: {
            issueLoading: false,
            issueCompleted: false,
            issueFailed: true,
            errorMessage: error,
          },
        },
        state
      );
    }

    case fromCoverages.CoverageActionTypes.LOAD_ALL_COVERAGES: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            quoteCoverageLoading: true,
            quoteCoverageLoaded: false,
            allQuoteCoverageLoaded: false,
          },
        },
        state
      );
    }
    case fromCoverages.CoverageActionTypes.LOAD_ALL_COVERAGES_SUCCESS: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            allQuoteCoverageLoaded: true,
            quoteCoverageLoading: false,
          },
        },
        state
      );
    }
    case fromCoverages.CoverageActionTypes.LOAD_ALL_COVERAGES_FAIL: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            allQuoteCoverageLoaded: false,
          },
        },
        state
      );
    }

    case fromCoverages.CoverageActionTypes.UPDATE_COVERAGE: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            quoteCoverageLoading: true,
            quoteCoverageLoaded: false,
          },
        },
        state
      );
    }

    case fromCoverages.CoverageActionTypes.UPDATE_COVERAGE_SUCCESS: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            quoteCoverageLoading: false,
            quoteCoverageLoaded: true,
          },
        },
        state
      );
    }

    case fromCoverages.CoverageActionTypes.UPDATE_COVERAGE_FAIL: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            quoteCoverageLoading: false,
            quoteCoverageLoaded: false,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.PATCH_QUOTE:
    case fromQuote.QuoteActionTypes.UPDATE_QUOTE: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            quoteUpdateLoading: true,
            quoteUpdateLoaded: false,
            quoteBound: false,
            bindCompleted: false,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.UPDATE_QUOTE_SUCCESS: {
      return adapter.updateOne(
        {
          id: action.payload,
          changes: {
            hasError: false,
            quoteUpdateLoading: false,
            quoteUpdateLoaded: true,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.UPDATE_QUOTE_FAIL: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            hasError: true,
            quoteUpdateLoading: false,
            quoteUpdateLoaded: false,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.INITIATE_NEW_BUSINESS_SUCCESS: {
      return {
        ...state,
      };
    }

    case fromProducts.INITIATE_PRODUCT_BEGIN: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            initiating: true,
          },
        },
        state
      );
    }

    case fromProducts.INITIATE_PRODUCT_END: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            initiating: false,
          },
        },
        state
      );
    }

    case fromPolicyNumber.PolicyNumberTypes.MARK_POLICY_NUMBER_LOADING: {
      return adapter.updateOne(
        {
          id: action.productId,
          changes: {
            policyNumberLoading: true,
          },
        },
        state
      );
    }

    case fromPolicyNumber.PolicyNumberTypes.GENERATE_POLICY_NUMBER_SUCCESS: {
      const { payload } = action;
      return adapter.updateOne(
        {
          id: payload.productId,
          changes: {
            policyNumber: payload.policyNumber,
            policyNumberLoaded: true,
            policyNumberLoading: false,
          },
        },
        state
      );
    }

    case fromQuote.QuoteActionTypes.TERM_LIFE_RATE_QUOTE_FAIL: {
      return adapter.updateOne(
        {
          id: ProductTypes.TERMLIFE,
          changes: {
            hasError: true,
            quoteUpdateLoading: false,
            quoteUpdateLoaded: false,
          },
        },
        state
      );
    }

    default: {
      return state;
    }
  }
}
