import { Product } from './product.model';

export const enum PolicyNumberCallStatus {
  PENDING = 'PENDING',
  POLICY_NUMBER_LOADED = 'POLICY_NUMBER_LOADED',
  POLICY_NUMBER_NOT_LOADED = 'POLICY_NUMBER_NOT_LOADED',
}

export class PolicyNumberStatus {
  status: PolicyNumberCallStatus;

  constructor(product: Product) {
    this.status = PolicyNumberStatus.statusFromPolicy(product);
  }

  static statusFromPolicy(product: Product): PolicyNumberCallStatus {
    if (product.policyNumberLoading) {
      return PolicyNumberCallStatus.PENDING;
    }
    if (!product.policyNumberLoaded) {
      return PolicyNumberCallStatus.POLICY_NUMBER_NOT_LOADED;
    }
    if (product.policyNumberLoaded) {
      return PolicyNumberCallStatus.POLICY_NUMBER_LOADED;
    }
  }
}
