import * as fromFeature from '@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { CompRaterNewsState } from '../reducers/comp-rater-news.reducer';

export const getCompRaterNewsState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.compRaterNews
);

export const getCompRaterNewsHtml = createSelector(
  getCompRaterNewsState,
  (state: CompRaterNewsState) => state.html
);

export const getCompRaterNewsLoading = createSelector(
  getCompRaterNewsState,
  (state: CompRaterNewsState) => state.loading
);

export const getCompRaterNewsLoaded = createSelector(
  getCompRaterNewsState,
  (state: CompRaterNewsState) => state.loaded
);
