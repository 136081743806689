import { Injectable } from '@angular/core';
import {
  InitiateExtraParameters,
  InitiatePowersportsExtraParameters,
} from '@core/models/auto/quotes/initiate-auto-extra-parameters';
import { InitiateNewBusinessRequest } from '@core/models/auto/quotes/initiate-new-business-request.model';
import { TermLifeResponse } from '@core/models/auto/quotes/quote-rating-response.model';
import { UpdateCoverageRequestModel } from '@core/models/auto/quotes/update-coverages-request.model';
import { QuoteRequest } from '@core/models/auto/quotes/update-quote-request.model';
import { AddressEntity } from '@core/models/entities/address.entity';
import { TermAmount } from '@core/models/entities/coverage.entity';
import { Product } from '@core/models/products/product.model';
import { InitiateNewBusinessUmbrellaRequest } from '@core/models/umbrella/initiate-new-business-umbrella.request';
import { MoreDetailsViewModel } from '@core/models/view/more-details.model';
import {
  ProductsService,
  SessionService,
  UserContextService,
} from '@core/services';
import { CoveredLocationService } from '@core/services/covered-location.service';
import { ErrorMessageService } from '@core/services/error-message.service';
import { CoverageHelper } from '@core/services/helpers/coverage.helper';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { MoreDetailsHelper } from '@core/services/helpers/more-details.helper';
import { LoggingService } from '@core/services/logging.service';
import { NonStandardBridgeService } from '@core/services/non-standard-bridge.service';
import { PolicyAddressService } from '@core/services/policy-address.service';
import { PolicyholderService } from '@core/services/policyholder.service';
import { QuoteService } from '@core/services/quote.service';
import { RetrieveService } from '@core/services/retrieve.service';
import { TelematicsEnrollmentsService } from '@core/services/telematics-enrollments.service';
import { TermLifeService } from '@core/services/termlife.service';
import { VehicleService } from '@core/services/vehicle.service';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import {
  InitiateNewBusinessAuto,
  InitiateNewBusinessCondo,
  InitiateNewBusinessHomeowners,
  InitiateNewBusinessPowersports,
  InitiateNewBusinessRenters,
  InitiateNewBusinessUmbrella,
  InitiateProduct,
} from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import {
  CoverageCodes,
  CoverageIds,
  DEFAULT_FACEAMOUNT,
  DEFAULT_ID,
  DEFAULT_YEARS_WITH_PRIOR_CARRIER,
  ModifierNames,
  ProductTypes,
  RatingTypes,
  StateExceptionCoverageLimit,
  TERM_LIFE_DEFAULT_FACE,
  TERM_LIFE_DEFAULT_TEN_YEAR,
  TERMLIFE_TERM_MONTHS,
  ValidCoverageWarningCodes,
} from '@shared/constants/app-constants';
import { MaintenanceErrorCodes } from '@shared/constants/maintenance-constants';
import { PolicyholderBuilder } from '@shared/utils/builders/policyholder.builder';
import { NrErrorUtils } from '@shared/utils/nr-error.utils';
import { combineLatest, from, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delayWhen,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AccountService } from '../../services/account.service';

@Injectable()
export class QuoteEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _quoteService: QuoteService,
    private _loggingService: LoggingService,
    private _productsService: ProductsService,
    private _nonStandardBridgeService: NonStandardBridgeService,
    private _errorHelper: ErrorHelper,
    private _errorMessageService: ErrorMessageService,
    private _policyAddressService: PolicyAddressService,
    private _userContextService: UserContextService,
    private _coveredLocationService: CoveredLocationService,
    private _vehicleService: VehicleService,
    private _telematicsEnrollmentsService: TelematicsEnrollmentsService,
    private _retrieveService: RetrieveService,
    private _accountService: AccountService,
    private _termLifeService: TermLifeService,
    private _moreDetailsHelper: MoreDetailsHelper,
    private _policyholderService: PolicyholderService,
    private _sessionService: SessionService,
    private _coverageHelper: CoverageHelper
  ) {}

  @Effect()
  initiateNewBusinessAuto$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_AUTO),
    switchMap((action: InitiateNewBusinessAuto) =>
      this._store
        .select(
          fromSelectors.buildInitiateNewBusinessRequest(ProductTypes.AUTO)
        )
        .pipe(
          take(1),
          map(request => [request, action.payload])
        )
    ),
    tap(
      ([request, extra]: [
        InitiateNewBusinessRequest,
        InitiateExtraParameters
      ]) =>
        this._loggingService.log('initiateNewBusiness Request', {
          ...request,
          autoExtraParams: extra,
        })
    ),
    switchMap(
      ([request, extra]: [
        InitiateNewBusinessRequest,
        InitiateExtraParameters
      ]) => {
        return this._quoteService.initiateNewBusiness(request).pipe(
          tap(response =>
            this._loggingService.log('initiateNewBusiness Response', {
              ...response,
              productId: ProductTypes.AUTO,
            })
          ),
          switchMap(response => {
            const productChanges = this._quoteService.buildProductChanges(
              response,
              ProductTypes.AUTO
            );

            const productUpdate: Update<Product> = {
              id: ProductTypes.AUTO,
              changes: productChanges,
            };

            const policyHolderFromRequest = request?.policyHolders[0];
            // Required since the DOB is return as masked
            const dateOfBirth = policyHolderFromRequest?.person?.dateOfBirth;

            const policyHolder =
              PolicyholderBuilder.buildPolicyholderEntityFromResponse(
                response.policyHolders[0],
                productUpdate.id,
                response.policyAddress.addressId,
                dateOfBirth,
                undefined,
                undefined,
                policyHolderFromRequest?.emailAddress,
                policyHolderFromRequest?.homeNumber,
                policyHolderFromRequest?.person?.infractionDesc
              );

            const pniPolicyAddressChanges: AddressEntity =
              this._quoteService.buildPniPolicyAddressChanges(
                response,
                ProductTypes.AUTO,
                request.policyAddress
              );
            delete pniPolicyAddressChanges.addressLine1;

            const pniPolicyAddressUpdate: Update<AddressEntity> = {
              id: DEFAULT_ID,
              changes: pniPolicyAddressChanges,
            };

            const nextActions: Action[] = [
              new fromActions.UpdateProduct(productUpdate),
              new fromActions.UpdatePolicyholderSuccess(policyHolder),
              new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
              new fromActions.AddDriver(
                this._quoteService.buildDriverEntity(
                  ProductTypes.AUTO,
                  response.policyHolders[0].policyHolderId,
                  policyHolder.person,
                  policyHolderFromRequest?.person?.driverOccupation,
                  policyHolderFromRequest?.person?.infractionDesc,
                  pniPolicyAddressChanges.state,
                  extra
                ),
                true
              ),
              new fromActions.AddOtherExistingPeopleAsDrivers(
                ProductTypes.AUTO
              ),
              new fromActions.GenerateAccessToken(ProductTypes.AUTO),
              new fromActions.GetPolicyLine(ProductTypes.AUTO),
            ];

            if (response.accountId) {
              nextActions.push(
                new fromActions.SetAccountId(response.accountId)
              );
            }

            nextActions.push(
              new fromActions.InitiateNewBusinessSuccess(ProductTypes.AUTO)
            );

            return from(nextActions);
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log('initiateNewBusiness Error', safeError);
            const productChanges: Product = {
              hasError: true,
              id: request.productId,
            };
            const productUpdate: Update<Product> = {
              id: request.productId,
              changes: productChanges,
            };
            const pniPolicyAddressUpdate: Update<AddressEntity> = {
              id: DEFAULT_ID,
              changes: request.policyAddress,
            };
            const nextActions: Action[] = [
              new fromActions.UpdateProduct(productUpdate),
              new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
            ];
            if (request.policyHolders && request.policyHolders.length) {
              nextActions.push(
                new fromActions.UpdatePolicyholderSuccess(
                  request.policyHolders[0]
                )
              );
            }
            nextActions.push(
              new fromActions.InitiateNewBusinessFail(
                request.productId,
                this._errorHelper.extractErrorCodeFromAnything(safeError)
              )
            );
            return from(nextActions);
          })
        );
      }
    )
  );

  @Effect()
  initiateNewBusinessPowersports$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_POWERSPORTS),
    switchMap((action: InitiateNewBusinessPowersports) =>
      this._store
        .select(
          fromSelectors.buildInitiateNewBusinessRequest(
            ProductTypes.POWERSPORTS
          )
        )
        .pipe(
          take(1),
          map(request => [request, action.payload])
        )
    ),
    tap(
      ([request, extra]: [
        InitiateNewBusinessRequest,
        InitiatePowersportsExtraParameters
      ]) =>
        this._loggingService.log('initiateNewBusiness Request', {
          ...request,
          powersportsExtraParams: extra,
        })
    ),
    switchMap(
      ([request, extra]: [
        InitiateNewBusinessRequest,
        InitiatePowersportsExtraParameters
      ]) => {
        return this._quoteService.initiateNewBusiness(request).pipe(
          tap(response =>
            this._loggingService.log('initiateNewBusiness Response', {
              ...response,
              productId: ProductTypes.POWERSPORTS,
            })
          ),
          switchMap(response => {
            const productChanges = this._quoteService.buildProductChanges(
              response,
              ProductTypes.POWERSPORTS
            );

            const productUpdate: Update<Product> = {
              id: ProductTypes.POWERSPORTS,
              changes: productChanges,
            };

            const policyHolderFromRequest = request?.policyHolders[0];
            const dateOfBirth = policyHolderFromRequest?.person?.dateOfBirth;
            if (dateOfBirth) {
              response.policyHolders[0].person.dateOfBirth = dateOfBirth;
            }

            const policyHolder =
              PolicyholderBuilder.buildPolicyholderEntityFromResponse(
                response.policyHolders[0],
                productUpdate.id,
                response.policyAddress.addressId,
                dateOfBirth,
                undefined,
                undefined,
                policyHolderFromRequest?.emailAddress,
                policyHolderFromRequest?.homeNumber
              );

            policyHolder.homeNumber = policyHolderFromRequest?.homeNumber;
            policyHolder.emailAddress = policyHolderFromRequest?.emailAddress;

            const pniPolicyAddressChanges: AddressEntity =
              this._quoteService.buildPniPolicyAddressChanges(
                response,
                ProductTypes.POWERSPORTS,
                request.policyAddress
              );
            delete pniPolicyAddressChanges.addressLine1;

            const pniPolicyAddressUpdate: Update<AddressEntity> = {
              id: DEFAULT_ID,
              changes: pniPolicyAddressChanges,
            };

            const nextActions: Action[] = [
              new fromActions.UpdateProduct(productUpdate),
              new fromActions.UpdatePolicyholderSuccess(policyHolder),
              new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
              new fromActions.AddPowersportsDriver(
                this._quoteService.buildDriverEntity(
                  ProductTypes.POWERSPORTS,
                  response.policyHolders[0].policyHolderId,
                  policyHolder.person,
                  policyHolderFromRequest?.person?.driverOccupation,
                  null,
                  pniPolicyAddressChanges.state,
                  extra
                ),
                true
              ),
              new fromActions.GenerateAccessToken(ProductTypes.POWERSPORTS),
              new fromActions.GetPolicyLine(ProductTypes.POWERSPORTS),
            ];

            if (response.accountId) {
              nextActions.push(
                new fromActions.SetAccountId(response.accountId)
              );
            }

            nextActions.push(
              new fromActions.InitiateNewBusinessSuccess(
                ProductTypes.POWERSPORTS
              )
            );

            return from(nextActions);
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log('initiateNewBusiness Error', safeError);
            const productChanges: Product = {
              hasError: true,
              id: request.productId,
            };
            const productUpdate: Update<Product> = {
              id: request.productId,
              changes: productChanges,
            };
            const pniPolicyAddressUpdate: Update<AddressEntity> = {
              id: DEFAULT_ID,
              changes: request.policyAddress,
            };
            const nextActions: Action[] = [
              new fromActions.UpdateProduct(productUpdate),
              new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
            ];
            if (request.policyHolders && request.policyHolders.length) {
              nextActions.push(
                new fromActions.UpdatePolicyholderSuccess(
                  request.policyHolders[0]
                )
              );
            }
            nextActions.push(
              new fromActions.InitiateNewBusinessFail(
                request.productId,
                this._errorHelper.extractErrorCodeFromAnything(safeError)
              )
            );
            return from(nextActions);
          })
        );
      }
    )
  );

  @Effect()
  initiateNewBusinessHomeowners$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_HOMEOWNERS),
    switchMap((action: InitiateNewBusinessHomeowners) =>
      this._store
        .select(
          fromSelectors.buildInitiateNewBusinessHomeownersRequest(
            ProductTypes.HOMEOWNERS,
            DEFAULT_YEARS_WITH_PRIOR_CARRIER
          )
        )
        .pipe(
          take(1),
          map(request => [request, action.payload])
        )
    ),
    tap(
      ([request, extra]: [
        InitiateNewBusinessRequest,
        InitiateExtraParameters
      ]) => this._loggingService.log('initiateNewBusiness Request', request)
    ),
    switchMap(
      ([request, extra]: [
        InitiateNewBusinessRequest,
        InitiateExtraParameters
      ]) => {
        return this._quoteService.initiateNewBusiness(request).pipe(
          tap(response =>
            this._loggingService.log('initiateNewBusiness Response', {
              ...response,
              productId: ProductTypes.HOMEOWNERS,
            })
          ),
          switchMap(response => {
            const policyHolderFromRequest = request?.policyHolders[0];

            const productChanges: Product =
              this._quoteService.buildProductChanges(
                response,
                ProductTypes.HOMEOWNERS
              );

            const productUpdate: Update<Product> = {
              id: ProductTypes.HOMEOWNERS,
              changes: productChanges,
            };

            const policyholderActions = response.policyHolders.map(
              policyholder => {
                const dateOfBirth =
                  this._quoteService.findPolicyholderDobInRequest(
                    policyholder,
                    request
                  );

                const policyHolder =
                  PolicyholderBuilder.buildPolicyholderEntityFromResponse(
                    policyholder,
                    productUpdate.id,
                    response.policyAddress.addressId,
                    dateOfBirth,
                    undefined,
                    undefined,
                    policyHolderFromRequest?.emailAddress,
                    policyHolderFromRequest?.homeNumber,
                    undefined
                  );
                return new fromActions.UpdatePolicyholderSuccess(policyHolder);
              }
            );

            let policyModifierActions: any = [
              new fromActions.GetPolicyLine(ProductTypes.HOMEOWNERS),
            ];
            if (extra && extra.associateNumber) {
              const discountAction = new fromActions.UpdateAssociateDiscount({
                productId: request.productId,
                modelId: response.quoteId,
                eligibleDiscountId: ModifierNames.ASSOCIATE_DISCOUNT,
                selectedOptionValue: 'true',
                qualifyingInformation: {
                  associateNumber: extra.associateNumber,
                },
              });
              policyModifierActions = [
                discountAction,
                new fromActions.GetPolicyLine(ProductTypes.HOMEOWNERS),
              ];
            }

            const pniPolicyAddressChanges: AddressEntity =
              this._quoteService.buildPniPolicyAddressChanges(
                response,
                ProductTypes.HOMEOWNERS
              );
            delete pniPolicyAddressChanges.addressLine1;

            const pniPolicyAddressUpdate: Update<AddressEntity> = {
              id: DEFAULT_ID,
              changes: pniPolicyAddressChanges,
            };

            const nextActions: Action[] = [
              new fromActions.UpdateProduct(productUpdate),
              ...policyholderActions,
              new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
              new fromActions.GenerateAccessToken(ProductTypes.HOMEOWNERS),
              ...policyModifierActions,
            ];

            if (response.accountId) {
              nextActions.push(
                new fromActions.SetAccountId(response.accountId)
              );
            }

            nextActions.push(
              new fromActions.InitiateNewBusinessSuccess(
                ProductTypes.HOMEOWNERS
              )
            );

            response.policyHolders.forEach(homeownersPolicyholder => {
              const dateOfBirth =
                this._quoteService.findPolicyholderDobInRequest(
                  homeownersPolicyholder,
                  request
                );
              const policyHolder =
                PolicyholderBuilder.buildPolicyholderEntityFromResponse(
                  homeownersPolicyholder,
                  productUpdate.id,
                  response.policyAddress.addressId,
                  dateOfBirth,
                  undefined,
                  undefined,
                  policyHolderFromRequest?.emailAddress,
                  policyHolderFromRequest?.homeNumber,
                  undefined
                );
              nextActions.push(
                new fromActions.UpdatePropertyPolicyholder(policyHolder)
              );
            });

            return from(nextActions);
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log('initiateNewBusiness Error', safeError);
            const productChanges: Product = {
              hasError: true,
              id: request.productId,
            };
            const productUpdate: Update<Product> = {
              id: request.productId,
              changes: productChanges,
            };
            const pniPolicyAddressUpdate: Update<AddressEntity> = {
              id: DEFAULT_ID,
              changes: request.policyAddress,
            };
            const nextActions: Action[] = [
              new fromActions.UpdateProduct(productUpdate),
              new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
            ];
            if (request.policyHolders && request.policyHolders.length) {
              nextActions.push(
                new fromActions.UpdatePolicyholderSuccess(
                  request.policyHolders[0]
                )
              );
            }
            nextActions.push(
              new fromActions.InitiateNewBusinessFail(
                request.productId,
                this._errorHelper.extractErrorCodeFromAnything(safeError)
              )
            );
            return from(nextActions);
          })
        );
      }
    )
  );

  @Effect()
  initiateNewBusinessCondo$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_CONDO),
    switchMap((action: InitiateNewBusinessCondo) =>
      this._store
        .select(
          fromSelectors.buildInitiateNewBusinessCondoRequest(
            ProductTypes.CONDO,
            DEFAULT_YEARS_WITH_PRIOR_CARRIER
          )
        )
        .pipe(
          take(1),
          map(request => [request, action.payload])
        )
    ),
    tap(
      ([request, extra]: [
        InitiateNewBusinessRequest,
        InitiateExtraParameters
      ]) => this._loggingService.log('initiateNewBusiness Request', request)
    ),
    switchMap(
      ([request, extra]: [
        InitiateNewBusinessRequest,
        InitiateExtraParameters
      ]) => {
        const policyHolderFromRequest = request?.policyHolders[0];
        return this._quoteService.initiateNewBusiness(request).pipe(
          tap(response =>
            this._loggingService.log('initiateNewBusiness Response', {
              ...response,
              productId: ProductTypes.CONDO,
            })
          ),
          switchMap(response => {
            const productChanges: Product =
              this._quoteService.buildProductChanges(
                response,
                ProductTypes.CONDO
              );

            const productUpdate: Update<Product> = {
              id: ProductTypes.CONDO,
              changes: productChanges,
            };

            const policyholderActions = response.policyHolders.map(
              policyholder => {
                const dateOfBirth =
                  this._quoteService.findPolicyholderDobInRequest(
                    policyholder,
                    request
                  );

                const policyHolder =
                  PolicyholderBuilder.buildPolicyholderEntityFromResponse(
                    policyholder,
                    productUpdate.id,
                    response.policyAddress.addressId,
                    dateOfBirth,
                    undefined,
                    undefined,
                    policyHolderFromRequest?.emailAddress,
                    policyHolderFromRequest?.homeNumber,
                    undefined
                  );
                return new fromActions.UpdatePolicyholderSuccess(policyHolder);
              }
            );

            const pniPolicyAddressChanges: AddressEntity =
              this._quoteService.buildPniPolicyAddressChanges(
                response,
                ProductTypes.CONDO
              );
            delete pniPolicyAddressChanges.addressLine1;

            let policyModifierActions: any = [
              new fromActions.GetPolicyLine(ProductTypes.CONDO),
            ];
            if (extra && extra.associateNumber) {
              const discountAction = new fromActions.UpdateAssociateDiscount({
                productId: request.productId,
                modelId: response.quoteId,
                eligibleDiscountId: ModifierNames.ASSOCIATE_DISCOUNT,
                selectedOptionValue: 'true',
                qualifyingInformation: {
                  associateNumber: extra.associateNumber,
                },
              });
              policyModifierActions = [
                discountAction,
                new fromActions.GetPolicyLine(ProductTypes.CONDO),
              ];
            }

            const pniPolicyAddressUpdate: Update<AddressEntity> = {
              id: DEFAULT_ID,
              changes: pniPolicyAddressChanges,
            };

            const nextActions = [
              new fromActions.UpdateProduct(productUpdate),
              ...policyholderActions,
              new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
              new fromActions.GenerateAccessToken(ProductTypes.CONDO),
              ...policyModifierActions,
            ];

            if (response.accountId) {
              nextActions.push(
                new fromActions.SetAccountId(response.accountId)
              );
            }

            nextActions.push(
              new fromActions.InitiateNewBusinessSuccess(ProductTypes.CONDO)
            );

            response.policyHolders.forEach(condoPolicyholder => {
              const dateOfBirth =
                this._quoteService.findPolicyholderDobInRequest(
                  condoPolicyholder,
                  request
                );
              const policyHolder =
                PolicyholderBuilder.buildPolicyholderEntityFromResponse(
                  condoPolicyholder,
                  productUpdate.id,
                  response.policyAddress.addressId,
                  dateOfBirth,
                  undefined,
                  undefined,
                  policyHolderFromRequest?.emailAddress,
                  policyHolderFromRequest?.homeNumber,
                  undefined
                );
              nextActions.push(
                new fromActions.UpdatePropertyPolicyholder(policyHolder)
              );
            });

            return from(nextActions);
          }),
          catchError(error => {
            this._loggingService.log('initiateNewBusiness Error', error);
            const productChanges: Product = {
              hasError: true,
              id: request.productId,
            };
            const productUpdate: Update<Product> = {
              id: request.productId,
              changes: productChanges,
            };
            const pniPolicyAddressUpdate: Update<AddressEntity> = {
              id: DEFAULT_ID,
              changes: request.policyAddress,
            };
            const nextActions: Action[] = [
              new fromActions.UpdateProduct(productUpdate),
              new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
            ];
            if (request.policyHolders && request.policyHolders.length) {
              nextActions.push(
                new fromActions.UpdatePolicyholderSuccess(
                  request.policyHolders[0]
                )
              );
            }
            nextActions.push(
              new fromActions.InitiateNewBusinessFail(
                request.productId,
                this._errorHelper.extractErrorCodeFromAnything(error)
              )
            );
            return from(nextActions);
          })
        );
      }
    )
  );

  @Effect()
  initiateNewBusinessRenters$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_RENTERS),
    switchMap((action: InitiateNewBusinessRenters) =>
      this._store
        .select(
          fromSelectors.buildInitiateNewBusinessRentersRequest(
            ProductTypes.RENTERS,
            DEFAULT_YEARS_WITH_PRIOR_CARRIER
          )
        )
        .pipe(
          take(1),
          map(request => [request, action.payload])
        )
    ),
    tap(
      ([request, extra]: [
        InitiateNewBusinessRequest,
        InitiateExtraParameters
      ]) => this._loggingService.log('initiateNewBusiness Request', request)
    ),
    switchMap(
      ([request, extra]: [
        InitiateNewBusinessRequest,
        InitiateExtraParameters
      ]) => {
        const policyHolderFromRequest = request?.policyHolders[0];
        return this._quoteService.initiateNewBusiness(request).pipe(
          tap(response =>
            this._loggingService.log('initiateNewBusiness Response', {
              ...response,
              productId: ProductTypes.RENTERS,
            })
          ),
          switchMap(response => {
            const productChanges: Product =
              this._quoteService.buildProductChanges(
                response,
                ProductTypes.RENTERS
              );

            const productUpdate: Update<Product> = {
              id: request.productId,
              changes: productChanges,
            };

            const policyholderActions = response.policyHolders.map(
              policyholder => {
                const dateOfBirth =
                  this._quoteService.findPolicyholderDobInRequest(
                    policyholder,
                    request
                  );
                const policyHolder =
                  PolicyholderBuilder.buildPolicyholderEntityFromResponse(
                    policyholder,
                    productUpdate.id,
                    response.policyAddress.addressId,
                    dateOfBirth,
                    undefined,
                    undefined,
                    policyHolderFromRequest?.emailAddress,
                    policyHolderFromRequest?.homeNumber,
                    undefined
                  );
                return new fromActions.UpdatePolicyholderSuccess(policyHolder);
              }
            );

            const pniPolicyAddressChanges: AddressEntity =
              this._quoteService.buildPniPolicyAddressChanges(
                response,
                ProductTypes.RENTERS
              );
            delete pniPolicyAddressChanges.addressLine1;

            const pniPolicyAddressUpdate: Update<AddressEntity> = {
              id: DEFAULT_ID,
              changes: pniPolicyAddressChanges,
            };

            let policyModifierActions: any = [
              new fromActions.GetPolicyLine(request.productId),
            ];
            if (extra && extra.associateNumber) {
              const discountAction = new fromActions.UpdateAssociateDiscount({
                productId: request.productId,
                modelId: response.quoteId,
                eligibleDiscountId: ModifierNames.ASSOCIATE_DISCOUNT,
                selectedOptionValue: 'true',
                qualifyingInformation: {
                  associateNumber: extra.associateNumber,
                },
              });
              policyModifierActions = [
                discountAction,
                new fromActions.GetPolicyLine(request.productId),
              ];
            }

            const nextActions = [
              new fromActions.UpdateProduct(productUpdate),
              ...policyholderActions,
              new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
              new fromActions.GenerateAccessToken(request.productId),
              ...policyModifierActions,
            ];

            if (response.accountId) {
              nextActions.push(
                new fromActions.SetAccountId(response.accountId)
              );
            }

            nextActions.push(
              new fromActions.InitiateNewBusinessSuccess(request.productId)
            );

            response.policyHolders.forEach(rentersPolicyholder => {
              const dateOfBirth =
                this._quoteService.findPolicyholderDobInRequest(
                  rentersPolicyholder,
                  request
                );
              const policyHolder =
                PolicyholderBuilder.buildPolicyholderEntityFromResponse(
                  rentersPolicyholder,
                  productUpdate.id,
                  response.policyAddress.addressId,
                  dateOfBirth,
                  undefined,
                  undefined,
                  policyHolderFromRequest?.emailAddress,
                  policyHolderFromRequest?.homeNumber,
                  undefined
                );
              nextActions.push(
                new fromActions.UpdatePropertyPolicyholder(policyHolder)
              );
            });

            return from(nextActions);
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log('initiateNewBusiness Error', safeError);
            const productChanges: Product = {
              hasError: true,
              id: request.productId,
            };
            const productUpdate: Update<Product> = {
              id: request.productId,
              changes: productChanges,
            };
            const pniPolicyAddressUpdate: Update<AddressEntity> = {
              id: DEFAULT_ID,
              changes: request.policyAddress,
            };
            const nextActions: Action[] = [
              new fromActions.UpdateProduct(productUpdate),
              new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
            ];
            if (request.policyHolders && request.policyHolders.length) {
              nextActions.push(
                new fromActions.UpdatePolicyholderSuccess(
                  request.policyHolders[0]
                )
              );
            }
            nextActions.push(
              new fromActions.InitiateNewBusinessFail(
                request.productId,
                this._errorHelper.extractErrorCodeFromAnything(safeError)
              )
            );
            return from(nextActions);
          })
        );
      }
    )
  );

  @Effect()
  initiateNewBusinessUmbrella$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_UMBRELLA),
    switchMap((action: InitiateNewBusinessUmbrella) =>
      this._store
        .select(
          fromSelectors.buildCourseGrainInitiateNewBusinessUmbrellaRequest()
        )
        .pipe(
          take(1),
          map(request => [request])
        )
    ),
    tap(([request]: [InitiateNewBusinessUmbrellaRequest]) =>
      this._loggingService.log('initiateNewBusiness Request', request)
    ),
    switchMap(([request]: [InitiateNewBusinessUmbrellaRequest]) =>
      this._quoteService.ratedQuotes(request).pipe(
        tap(response =>
          this._loggingService.log('initiateNewBusiness Response', response)
        ),
        switchMap(response => {
          const policyHolderFromRequest = request?.policyHolders[0];
          const productChanges: Product =
            this._quoteService.buildUmbrellaProductChanges(response);
          const productUpdate: Update<Product> = {
            id: request.productId,
            changes: productChanges,
          };

          const dateOfBirth = policyHolderFromRequest?.person?.dateOfBirth;
          const policyHolder =
            PolicyholderBuilder.buildPolicyholderEntityFromResponse(
              response.policyHolders[0],
              productUpdate.id,
              response.policyAddress.addressId,
              dateOfBirth,
              undefined,
              undefined,
              policyHolderFromRequest?.emailAddress,
              policyHolderFromRequest?.homeNumber,
              undefined
            );

          const pniPolicyAddressUpdate: Update<AddressEntity> = {
            id: DEFAULT_ID,
            changes: this._quoteService.buildPniPolicyAddressChanges(
              response,
              request.productId
            ),
          };

          const householdMembers = response.householdMembers.find(member => {
            member.productId = request.productId;
            return member;
          });

          const addhouseholdMembers = response.householdMembers.map(member => {
            member.productId = request.productId;
            return member;
          });

          return from([
            new fromActions.UpdateProduct(productUpdate),
            new fromActions.AddToSelectedProducts(productUpdate),
            new fromActions.UpdatePolicyholderSuccess(policyHolder),
            new fromActions.AddAllLocationExposureSuccess(
              response.locationExposures
            ),
            new fromActions.AddAllHouseholdMembersSuccess(addhouseholdMembers),
            new fromActions.UpdateHouseholdMember(householdMembers),
            new fromActions.AddAllVehicleExposureSuccess(
              response.vehicleExposures
            ),
            new fromActions.AddUnderlyingPolicySuccess(
              response.underlyingPolicies
            ),
            new fromActions.UpdatePolicyAddress(pniPolicyAddressUpdate),
            new fromActions.GenerateAccessToken(request.productId),
            // new fromActions.GetPolicyLine(request.productId),
            new fromActions.AddSelectedCoverageProduct(productUpdate.id),
            new fromActions.AddAllHouseholdMembers(),
            new fromActions.RemoveProductCoverages(response.productId), // Required since unavailable coverages are removed
            new fromActions.AddManyCoverages(
              response.offeredQuotes[0].coverages.map(coverage => {
                coverage.productId = request.productId;
                return coverage;
              })
            ),
            new fromActions.UpdatePremium({
              productId: response.productId,
              ...response.offeredQuotes[0].premium,
            }),
            new fromActions.InitiateNewBusinessSuccess(request.productId),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('initiateNewBusiness Error', safeError);
          const productChanges: Product = {
            hasError: true,
            id: request.productId,
          };
          const productUpdate: Update<Product> = {
            id: request.productId,
            changes: productChanges,
          };
          return from([
            new fromActions.UpdateProduct(productUpdate),
            new fromActions.RemoveFromSelectedProducts(productChanges),
            new fromActions.RemoveSelectedCoverageProduct(request.productId),
            new fromActions.InitiateNewBusinessFail(
              request.productId,
              this._errorHelper.extractErrorCodeFromAnything(error)
            ),
          ]);
        })
      )
    )
  );

  @Effect()
  InitiateAllNewBusiness$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_ALL_NEW_BUSINESS),
    map((action: fromActions.InitiateAllNewBusiness) => action.payload),
    withLatestFrom(this._store.select(fromSelectors.getSelectedProducts)),
    map(([payload, products]: [MoreDetailsViewModel, Product[]]) => ({
      payload,
      products,
    })),
    map(({ payload, products }) => {
      const isPowersportsSelected = products.some(product => {
        return product.id === ProductTypes.POWERSPORTS ? true : false;
      });
      const isTermLifeSelected = products.some(product => {
        return product.id === ProductTypes.TERMLIFE ? true : false;
      });
      if (isPowersportsSelected && isTermLifeSelected) {
        const powersports = products.pop();
        products.unshift(powersports);
      }
      return {
        payload,
        products,
      };
    }),
    map(({ payload, products }) => ({
      payload,
      products: products.map((p, i) => ({
        ...p,
        createAccount: i === 0,
      })),
    })),
    mergeMap(({ payload, products }) =>
      from(products).pipe(
        map(product => ({
          payload,
          productId: product.id,
          createAccount: product.createAccount,
        })),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('initialize All Products Error', safeError);
          // should have an error action
          return from([]);
        })
      )
    ),
    switchMap(({ payload, productId, createAccount }) => {
      return [
        new fromActions.AddProductIdForNewBusinessCall(productId),
        new fromActions.InitiateProduct(payload, productId, createAccount),
      ];
    })
  );

  @Effect()
  InitiateProduct$ = this._actions$.pipe(
    ofType<InitiateProduct>(fromActions.QuoteActionTypes.INITIATE_PRODUCT),
    delayWhen(action =>
      this._store
        .select(
          fromSelectors.validateProductIdsForNewBusinessCall(action.productId)
        )
        .pipe(
          filter(delay => !delay),
          take(1)
        )
    ),
    concatMap(action =>
      this._store.select(fromSelectors.getPrimaryNamedInsured).pipe(
        take(1),
        map(primaryNamedInsured => ({
          ...action,
          primaryNamedInsured,
        }))
      )
    ),
    concatMap(action =>
      this._store.select(fromSelectors.getQuoteLoaded(action.productId)).pipe(
        take(1),
        map(isQuoteInitiated => ({
          ...action,
          isQuoteInitiated,
        })),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('initialize Product Error', safeError);
          if (error && error.productId) {
            return from([
              new fromActions.InitiateNewBusinessFail(
                error.productId,
                this._errorHelper.extractErrorCodeFromAnything(error)
              ),
            ]);
          } else {
            return from([]);
          }
        })
      )
    ),
    switchMap(action => {
      return this._moreDetailsHelper.buildInitiateNewBusinessActions(
        action.isQuoteInitiated,
        action.moreDetailsViewModel,
        action.productId,
        action.primaryNamedInsured
      );
    })
  );

  @Effect()
  updateQuote$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.UPDATE_QUOTE),
    map((action: fromActions.UpdateQuote) => action.payload),
    switchMap(productId =>
      this._store
        .select(fromSelectors.buildUpdateQuoteRequest(productId))
        .pipe(take(1))
    ),
    tap(request => this._loggingService.log('updateQuote Request', request)),
    withLatestFrom(this._policyAddressService.getPolicyAddress(DEFAULT_ID)),
    mergeMap(([request, previousAddress]) =>
      this._quoteService.updateQuote(request).pipe(
        tap(updateRentersQuoteResponse =>
          this._loggingService.log(
            'updateQuote Response',
            updateRentersQuoteResponse
          )
        ),
        switchMap(response => {
          const updatedAddress: Update<AddressEntity> = {
            id: response.policyAddress.addressId,
            changes: response.policyAddress,
          };

          const updatedProduct: Update<Product> = {
            id: response.productId,
            changes: {
              effectiveDate: response.effectiveDate,
            },
          };

          const nextActions: Action[] = [
            new fromActions.UpdatePolicyAddress(updatedAddress),
            new fromActions.UpdateProduct(updatedProduct),
            new fromActions.UpdateQuoteSuccess(response.productId),
            new fromActions.InvalidateQuote(response.productId),
          ];

          if (
            previousAddress.postalCode !== response.policyAddress.postalCode
          ) {
            nextActions.push(
              new fromActions.GenerateAccessToken(response.productId)
            );
          }

          return nextActions;
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updateQuote Error', safeError);
          return of(
            new fromActions.UpdateQuoteFail(request.productId, safeError)
          );
        })
      )
    )
  );

  @Effect()
  patchQuote$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.PATCH_QUOTE),
    map((action: fromActions.PatchQuote) => ({
      productId: action.payload,
      body: action.body,
    })),
    switchMap(args =>
      this._store
        .select(fromSelectors.buildPatchQuoteRequest(args.productId, args.body))
        .pipe(take(1))
    ),
    tap(request => this._loggingService.log('patchQuote Request', request)),
    mergeMap(request =>
      this._quoteService.updateQuote(request).pipe(
        tap(updateRentersQuoteResponse =>
          this._loggingService.log(
            'patchQuote Response',
            updateRentersQuoteResponse
          )
        ),
        switchMap(response => {
          const updatedProduct: Update<Product> = {
            id: response.productId,
            changes: {
              hasCustomerViewedDocuments: response.hasCustomerViewedDocuments,
              hasReceivedRequiredDocuments:
                response.hasReceivedRequiredDocuments,
            },
          };
          const actions = [];
          if (response.offeringType) {
            actions.push(
              new fromActions.UpdateOfferingType(response.offeringType)
            );
          }
          actions.push(
            new fromActions.UpdateProduct(updatedProduct),
            new fromActions.UpdateQuoteSuccess(response.productId)
          );
          if (
            response?.hasConnectedCarConsent === true ||
            response?.hasConnectedCarConsent === false
          ) {
            actions.push(
              new fromActions.UpdateConnectedCarConsent({
                hasConnectedCarConsent: response?.hasConnectedCarConsent,
              })
            );
          }
          return actions;
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('patchQuote Error', safeError);
          return of(
            new fromActions.UpdateQuoteFail(request.productId, safeError)
          );
        })
      )
    )
  );

  @Effect()
  initiateNewBusinessLife$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.GENERATE_TERMLIFE_QUOTEID),
    map((action: fromActions.GenerateTermLifeQuoteId) => action),
    tap(request =>
      this._loggingService.log('initiateNewBusinessTermLife Request', request)
    ),
    mergeMap(() =>
      this._quoteService.generateTermLifeQuoteId().pipe(
        tap(response =>
          this._loggingService.log(
            'initiateNewBusinessTermLife Response',
            response
          )
        ),
        switchMap(response => {
          const productChanges: Product = {
            quoteId: response,
            quoteLoaded: true,
            hasError: false,
            quoteCompleted: true,
          };
          const productUpdate: Update<Product> = {
            id: ProductTypes.TERMLIFE,
            changes: productChanges,
          };
          return from([
            new fromActions.UpdateProduct(productUpdate),
            new fromActions.InitiateNewBusinessSuccess(ProductTypes.TERMLIFE),
          ]);
        })
      )
    )
  );

  @Effect()
  rateQuote$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.RATE_QUOTE),
    delayWhen((action: fromActions.RateQuote) =>
      this._store
        .select(fromSelectors.ratingCallShouldDelay(action.productId))
        .pipe(
          filter(delay => !delay),
          take(1)
        )
    ),
    mergeMap(action =>
      this._coveredLocationService
        .updateNumberOfOccupants(action.productId)
        .pipe(map(() => action))
    ),
    mergeMap(actions =>
      this._store
        .select(
          fromSelectors.buildRateQuoteRequest(
            actions.productId,
            actions.ratingType
          )
        )
        .pipe(
          take(1),
          map(request => [request, actions])
        )
    ),
    tap(([request, action]) => {
      const logEventName =
        action.ratingType === RatingTypes.BIND
          ? 'rateBind Request'
          : 'rateQuote Request';
      this._loggingService.log(logEventName, request);
    }),
    mergeMap(([request, action]: [QuoteRequest, fromActions.RateQuote]) =>
      this._quoteService.quote(request).pipe(
        tap(response => {
          const logEventName =
            response.ratingType === RatingTypes.BIND
              ? 'rateBind Response'
              : 'rateQuote Response';
          this._loggingService.log(logEventName, response);
        }),
        mergeMap(response => {
          const quoteAction = [];
          if (response.ratingType === RatingTypes.BIND) {
            quoteAction.push(new fromActions.ResetDsmDownPaymentLoaded(false));
          } else {
            // Added to store Annual Miles from QPC Call in quote response
            if (response.productId === ProductTypes.AUTO) {
              quoteAction.push(
                new fromActions.UpdateAnnualMilesForAllVehicles(
                  response.vehicles
                )
              );
            }
          }

          const updatedProduct: Update<Product> = {
            id: response.productId,
            changes: {
              effectiveDate: response.effectiveDate,
              quoteStatus: response.quoteStatus,
              sessionId: response.sessionId,
              quoteId: response.quoteId,
              policyNumber: response.policyNumber,
              creditConsent: response.creditConsent,
              hasReceivedRequiredDocuments:
                response.hasReceivedRequiredDocuments,
              hasCustomerViewedDocuments: response.hasCustomerViewedDocuments,
            },
          };
          const termType =
            this._quoteService.readTermTypeFromResponse(response);
          if (termType) {
            updatedProduct.changes.termType = termType;
          }

          quoteAction.push(new fromActions.UpdateProduct(updatedProduct));

          if (
            response?.productId === ProductTypes.POWERSPORTS &&
            response?.discounts
          ) {
            const discounts = response.discounts.map(discount => {
              return { ...discount, productId: response.productId };
            });
            quoteAction.push(
              new fromActions.UpdateAllPowersportsDiscounts(discounts)
            );
          } else {
            if (response.discounts) {
              const isSmartRideDiscountAvailable =
                response.discounts.find(
                  val =>
                    val.category === 'PersonalVehicle' &&
                    val.description === 'SmartRide Discount'
                ) !== undefined
                  ? true
                  : false;

              const discounts = response.discounts.map(discount => {
                return { ...discount, productId: response.productId };
              });
              if (!isSmartRideDiscountAvailable) {
                discounts.push({
                  category: 'PersonalVehicle',
                  description: 'SmartRide Discount',
                  isDiscountApplied: false,
                  productId: response.productId,
                });
              }
              quoteAction.push(new fromActions.UpdateAllDiscounts(discounts));
            }
          }
          if (response) {
            response.offeredQuotes[0].coverages =
              CoverageHelper.replaceIncomingCoverages(
                response.offeredQuotes[0].coverages,
                response?.productId,
                response.policyAddress.state
              );
          }

          if (response.documents) {
            const documents = response.documents.map(doc => {
              return { ...doc, productId: response.productId };
            });
            quoteAction.push(new fromActions.AddAllPreBindDocuments(documents));
          }

          const actionsToCall = quoteAction.concat([
            new fromActions.RemoveProductCoverages(response.productId), // Required since unavailable coverages are removed
            new fromActions.AddManyCoverages(
              response.offeredQuotes[0].coverages.map(coverage => {
                coverage.productId = response.productId;
                return coverage;
              })
            ),
            new fromActions.UpdatePremium({
              productId: response.productId,
              ...response.offeredQuotes[0].premium,
            }),
            new fromActions.LoadQuoteLetter(),
          ]);

          if (response.ratingType === RatingTypes.BIND) {
            actionsToCall.push(
              new fromActions.RateBindSuccess(response.productId)
            );
            actionsToCall.push(new fromActions.InitializeBillingPaymentPlan());
          } else {
            actionsToCall.push(
              new fromActions.RateQuoteSuccess(response.productId)
            );
          }

          return from(actionsToCall);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          if (action.ratingType === RatingTypes.BIND) {
            this._loggingService.log('rateBind Error', safeError);
            return of(
              new fromActions.RateBindFail(
                action.productId,
                this._errorHelper.extractErrorCodeFromAnything(safeError)
              )
            );
          } else {
            this._loggingService.log('rateQuote Error', safeError);
            return of(
              new fromActions.RateQuoteFail(
                action.productId,
                this._errorHelper.extractErrorCodeFromAnything(safeError),
                action.suppressSoftfall
              )
            );
          }
        })
      )
    )
  );

  @Effect()
  termLifeRateQuote$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.TERM_LIFE_RATE_QUOTE),
    map((action: fromActions.TermLifeRateQuote) => action.termLifeEligible),
    switchMap(termLifeEligible =>
      this._store
        .select(fromSelectors.buildTermLifeRateQuoteRequest(termLifeEligible))
        .pipe(take(1))
    ),
    tap(request => {
      this._loggingService.log('termLifeRateQuote Request', request);
    }),
    mergeMap(request =>
      this._quoteService.rateTermLifeQuote(request.termLifeQuoteRequest).pipe(
        tap(response => {
          if (!request.termLifeEligible) {
            throw new Error('Ineligible based on information you provided');
          } else
            this._loggingService.log('termLifeRateQuote Response', response);
        }),
        mergeMap(response => {
          let termLife: TermLifeResponse = {
            id: request.id,
            firstName: request.firstName,
            lastName: request.lastName,
            termLifeEligible: request.termLifeEligible,
            termLifeResponse: response,
            hasTermLifeDataChanged: false,
          };
          const termLifeEntity = this._termLifeService.getCostEstimate(
            TERM_LIFE_DEFAULT_TEN_YEAR,
            DEFAULT_FACEAMOUNT,
            { termLifeResponse: response }
          );
          if (termLifeEntity) {
            termLife = {
              ...termLife,
              coverageTerm: termLifeEntity.coverageTerm,
              coverageAmount: termLifeEntity.coverageAmount,
              costEstimate: termLifeEntity.costEstimate,
            };
          }
          let termlifePremium;
          let total: TermAmount;
          if (response.ProductVariations) {
            termlifePremium = response.ProductVariations.find(
              termLife =>
                termLife.productTerm &&
                termLife.productTerm === TERM_LIFE_DEFAULT_TEN_YEAR
            ).premiumComparison.find(
              termLife =>
                termLife.faceAmount &&
                termLife.faceAmount === TERM_LIFE_DEFAULT_FACE
            );
            total = {
              amount: +termlifePremium.premiumAmount * TERMLIFE_TERM_MONTHS,
              currency: 'USD',
            };
          }
          return from([
            new fromActions.RateQuoteSuccess(ProductTypes.TERMLIFE),
            new fromActions.TermLifeRateQuoteSuccess(termLife),
            new fromActions.UpdatePremium({
              productId: ProductTypes.TERMLIFE,
              total: total,
              termMonths: TERMLIFE_TERM_MONTHS,
            }),
          ]);
        }),
        catchError(error => {
          const safeError = NrErrorUtils.sanitizeError(error);
          this._loggingService.log('TermLifeRateQuote Error', safeError);
          return from([
            new fromActions.TermLifeRateQuoteFail(
              ProductTypes.TERMLIFE,
              safeError
            ),
          ]);
        })
      )
    )
  );

  @Effect()
  emailQuoteEstimate$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.EMAIL_QUOTE_ESTIMATE),
    map((action: fromActions.EmailQuoteEstimate) => action.payload),
    switchMap(payload =>
      this._store
        .select(fromSelectors.buildEmailQuoteEstimateRequest(payload))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('emailQuoteEstimate Request', request)
    ),
    mergeMap(request =>
      this._termLifeService
        .emailQuoteEstimate(request.termLifeEmailRequest)
        .pipe(
          tap(response => {
            this._loggingService.log(
              'termLifeEmailQuoteEstimate Response',
              response
            );
          }),
          mergeMap(response => {
            if (response && response.status && request) {
              // let email = request.termLifeEmailRequest.toAddress.find(email => {
              //   if(email){return email}
              // })
              const termLifeChanges: TermLifeResponse = {
                id: request.id,
                firstName: request.termLifePerson.firstName,
                lastName: request.termLifePerson.lastName,
                emailAddress: request.termLifeEmailRequest.toAddress[0],
                hasTermLifeEmailSent: true,
              };
              const updatedTermLifeEntity: Update<TermLifeResponse> = {
                id: request.id,
                changes: termLifeChanges,
              };
              return from([
                new fromActions.EmailQuoteEstimateSuccess(
                  updatedTermLifeEntity
                ),
              ]);
            }
          }),
          catchError(error => {
            const safeError = NrErrorUtils.sanitizeError(error);
            this._loggingService.log('estimateQuoteEstimate Error', safeError);
            return of(new fromActions.EmailQuoteEstimateFail(safeError));
          })
        )
    )
  );

  @Effect()
  retrieveAllCoverages$ = this._actions$.pipe(
    ofType(fromActions.CoverageActionTypes.LOAD_ALL_COVERAGES),
    map((action: fromActions.LoadCoverages) => action.payload),
    mergeMap(productId =>
      this._store
        .select(fromSelectors.buildRetrieveCoveragesRequest(productId))
        .pipe(take(1))
    ),
    tap(request => this._loggingService.log('retrieveAllCoverages', request)),
    mergeMap(request =>
      this._quoteService.retrieveAllCoverages(request).pipe(
        tap(response =>
          this._loggingService.log('retrieveAllCoverages response', response)
        ),
        map(
          response =>
            new fromActions.LoadCoveragesSuccess(
              request.productId,
              response[0].coverages.map(coverage => {
                coverage.productId = request.productId;
                return coverage;
              })
            )
        ),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('retrieveAllCoverages error', safeError);
          return of(
            new fromActions.LoadCoveragesFail(
              request.productId,
              error.error.developerMessage
            )
          );
        })
      )
    )
  );

  @Effect()
  updateCoverage$ = this._actions$.pipe(
    ofType(fromActions.CoverageActionTypes.UPDATE_COVERAGE),
    map((action: fromActions.UpdateCoverage) => ({
      productId: action.payload,
      coverageChanges: action.coverageChanges,
    })),
    mergeMap(payload =>
      this._store
        .select(
          fromSelectors.buildUpdateCoverageRequest(
            payload.productId,
            payload.coverageChanges
          )
        )
        .pipe(take(1))
    ),
    tap(
      (request: {
        request: UpdateCoverageRequestModel;
        isBiologicalDeteriorationCoverageApplicable?: boolean;
        policyAddress?: AddressEntity;
      }) => this._loggingService.log('updateCoverage', request.request)
    ),
    mergeMap(
      (request: {
        request: UpdateCoverageRequestModel;
        isBiologicalDeteriorationCoverageApplicable?: boolean;
        policyAddress?: AddressEntity;
      }) =>
        this._quoteService.updateCoverage(request.request).pipe(
          tap(response =>
            this._loggingService.log('updateCoverage Response', response)
          ),
          switchMap(response => {
            const actions = [];
            const msg: any = response.messages?.find(
              (msg: any) => msg.warnings && msg.warnings.length
            );
            let extraCoverageCode;
            if (msg) {
              this._loggingService.log('updateCoverage warnings', msg.warnings);
              const warning = msg.warnings[0];
              if (ValidCoverageWarningCodes.includes(warning.ruleId)) {
                extraCoverageCode = warning.entity.coverageCode;
              }
            }

            const coverageChangeActions = [];

            if (
              request.isBiologicalDeteriorationCoverageApplicable &&
              request.policyAddress &&
              (request.request.productId === ProductTypes.CONDO ||
                request.request.productId === ProductTypes.HOMEOWNERS ||
                request.request.productId === ProductTypes.RENTERS) &&
              request.request.coverageChanges
            ) {
              const isBiologicalDeteriorationCoverageAvailable =
                !!request.request.coverageChanges.find(
                  coverage =>
                    coverage.coverageId ===
                    CoverageIds.BioDeteriorationDamageCoverage
                ) ||
                !!request.request.coverageChanges.find(
                  coverage => coverage.coverageId === CoverageIds.Mold
                );
              const policyAddress = request.policyAddress;
              if (isBiologicalDeteriorationCoverageAvailable && policyAddress) {
                const coverageChange =
                  this.updateBiologicalDeteriorationCoverage(
                    request.request,
                    response,
                    policyAddress
                  );
                if (coverageChange) {
                  coverageChangeActions.push(
                    new fromActions.UpdateCoverage(
                      request.request.productId,
                      coverageChange
                    )
                  );
                }
              }
            }
            if (response) {
              response.coverages = CoverageHelper.replaceIncomingCoverages(
                response.coverages,
                request.request.productId,
                request.policyAddress?.state
              );
            }
            actions.push(
              new fromActions.UpdateExtraCoverageByRule(extraCoverageCode)
            );
            actions.push(
              new fromActions.InvalidateQuote(request.request.productId)
            );
            actions.push(
              new fromActions.RemoveProductCoverages(request.request.productId)
            ); // Required since unavailable coverages are removed
            actions.push(
              new fromActions.AddManyCoverages(
                response.coverages.map(coverage => {
                  coverage.productId = request.request.productId;
                  return coverage;
                })
              )
            );
            if (this.isGapCollSelected(request)) {
              this.unenrollGapCoverageVehicle(request);
            }
            actions.push(
              new fromActions.UpdateCoverageSuccess(request.request.productId)
            );
            this._sessionService.updateSessionLogEntry(
              this._coverageHelper.buildSessionLogForCoverageChange(
                request.request.coverageChanges[0]
              )
            );
            const allActions = actions.concat(coverageChangeActions);
            return from(allActions);
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            this._loggingService.log('updateCoverage error', safeError);
            return this._errorMessageService
              .addMessagesToCoverageErrors(
                this._errorHelper.extractCoverageErrorFromAnything(safeError)
              )
              .pipe(
                map(
                  errors =>
                    new fromActions.UpdateCoverageFail(
                      request.request.productId,
                      errors
                    )
                )
              );
          })
        )
    )
  );

  @Effect()
  handleRatingFailure$ = this._actions$.pipe(
    ofType(
      fromActions.QuoteActionTypes.RATE_QUOTE_FAIL,
      fromActions.QuoteActionTypes.RATE_BIND_FAIL,
      fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_FAIL,
      fromActions.FAIL_PRODUCT,
      fromActions.FAIL_ALL_PRODUCTS
    ),
    switchMap((action: fromActions.RateQuoteFail) => {
      return combineLatest([
        this._productsService.getSelectedProducts(),
        this._nonStandardBridgeService.isNonStandardBridgeCode(
          action.errorCode
        ),
        of(
          MaintenanceErrorCodes.includes(action.errorCode)
            ? action.errorCode
            : null
        ),
        of(action.suppressSoftfall),
      ]).pipe(take(1));
    }),
    switchMap(
      ([selectedProducts, nonStandard, maintenance, suppressSoftfall]) => {
        if (selectedProducts.length > 0) {
          const nonLifeProducts = selectedProducts.filter(
            product => product.id !== ProductTypes.TERMLIFE
          );
          if (
            nonLifeProducts.length > 0 &&
            nonLifeProducts.some(product => product.showError === false)
          )
            nonLifeProducts.forEach((product, i) => {
              if (product.showError === false) {
                selectedProducts.splice(i, 1);
              }
            });
          if (
            selectedProducts.some(
              product => product.id === ProductTypes.TERMLIFE
            ) &&
            nonLifeProducts.every(product => product.hasError)
          ) {
            let lifeProductError = selectedProducts.find(
              product => product.id === ProductTypes.TERMLIFE
            );
            if (lifeProductError.showError === false) {
              selectedProducts.forEach((product, i) => {
                if (product.id === lifeProductError.id) {
                  selectedProducts.splice(i, 1);
                }
              });
            } else if (!lifeProductError.hasError) {
              lifeProductError = { ...lifeProductError, hasError: true };
              selectedProducts.forEach((product, i) => {
                if (product.id === lifeProductError.id) {
                  selectedProducts[i] = lifeProductError;
                }
              });
            }
          }
        }
        if (nonStandard !== null) {
          return from([
            new fromActions.NonStandardBridge(ProductTypes.AUTO, nonStandard),
            new fromActions.Bridge(nonStandard, null),
          ]);
        } else if (maintenance !== null) {
          return of(
            new fromActions.Maintenance(
              'Show maintenance page due to System Failure'
            )
          );
        } else {
          if (
            !suppressSoftfall &&
            selectedProducts.every(
              product => product.hasError || product.isConditional
            )
          ) {
            return of(new fromActions.Softfall('All products have errors'));
          }
          return from([new fromActions.CheckUmbrellaEligibility()]);
        }
      }
    )
  );

  @Effect()
  errorUmbrellaOnProductFailure$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.RATE_QUOTE_FAIL),
    switchMap(() => this._productsService.getSelectedProducts().pipe(take(1))),
    switchMap(products => {
      if (this._productsService.isEligibleForUmbrella(products)) {
        return of(new fromActions.FailProduct(ProductTypes.UMBRELLA));
      }

      return from([]);
    })
  );

  private isGapCollSelected(request): boolean {
    const change =
      request.coverageChanges && request.coverageChanges.length
        ? request.coverageChanges[0]
        : null;
    if (change) {
      const id = change.coverageId;
      const selected =
        change.selectedValue &&
        change.selectedValue.length &&
        change.selectedValue[0].value === 'true';
      return id === 'GAPCOLL' && selected;
    } else {
      return false;
    }
  }

  private unenrollGapCoverageVehicle(request) {
    const coverableId =
      request.coverageChanges && request.coverageChanges.length
        ? request.coverageChanges[0].coverableId
        : null;
    if (coverableId) {
      this._vehicleService
        .get(coverableId.toString())
        .pipe(
          withLatestFrom(this._telematicsEnrollmentsService.getEnrollments())
        )
        .subscribe(([vehicle, enrollments]) => {
          if (
            enrollments.vehicleEnrollment &&
            enrollments.vehicleEnrollment.vehicles &&
            enrollments.vehicleEnrollment.vehicles.length
          ) {
            const enrollmentVehicle =
              enrollments.vehicleEnrollment.vehicles.find(
                veh =>
                  veh.vehicleId ===
                  (vehicle.vehicleId ? vehicle.vehicleId.toString() : null)
              );
            this._telematicsEnrollmentsService.dispatchUpdateTelematicsEnrollment(
              {
                enrollmentVehicle: enrollmentVehicle,
                selectedProgram: 'NotEnrolled',
              }
            );
          } else if (enrollments.mobileEnrollment) {
            this._telematicsEnrollmentsService.dispatchRemoveTelematicsEnrollment();
          }
        });
    }
  }
  // Added for NY Biological Deterioration Coverage
  private updateBiologicalDeteriorationCoverage(
    request,
    response,
    policyAddress
  ) {
    if (request.coverageChanges) {
      const bioDetCoverage = request.coverageChanges.find(
        coverage =>
          coverage.coverageId === CoverageIds.BioDeteriorationDamageCoverage
      );
      const bioDetAddCoverage = request.coverageChanges.find(
        coverage => coverage.coverageId === CoverageIds.Mold
      );
      if (bioDetCoverage && bioDetCoverage.selectedValue) {
        const bioSelectedValue = bioDetCoverage.selectedValue.find(
          selectedValue =>
            selectedValue.code === 'selected' && selectedValue.value === 'false'
        );
        if (bioSelectedValue && response.coverages) {
          const bioAdditionalCoverage = response.coverages.find(
            coverage => coverage.coverageId === CoverageIds.Mold
          );
          if (bioAdditionalCoverage.terms) {
            const newTerms = bioAdditionalCoverage.terms.find(
              term =>
                !!term.options.find(
                  option =>
                    option.value ===
                    StateExceptionCoverageLimit[policyAddress.state][
                      CoverageIds.Mold
                    ][CoverageCodes.MoldLimit].value
                )
            );
            if (newTerms) {
              const bioAdditionalCoverage = response.coverages.find(
                cvg =>
                  cvg.coverageId === CoverageIds.Mold &&
                  (request.productId === ProductTypes.CONDO ||
                    request.productId === ProductTypes.HOMEOWNERS ||
                    request.productId === ProductTypes.RENTERS)
              );
              return [
                {
                  coverageId: bioAdditionalCoverage.coverageId,
                  coverableId: +bioAdditionalCoverage.coverableId,
                  coverageLevel: bioAdditionalCoverage.coverageLevel,
                  selectedValue: [
                    {
                      code: CoverageCodes.MoldLimit,
                      value:
                        StateExceptionCoverageLimit[policyAddress.state][
                          CoverageIds.Mold
                        ][CoverageCodes.MoldLimit].value,
                    },
                  ],
                },
              ];
            }
          }
        }
      } else if (bioDetAddCoverage && bioDetAddCoverage.selectedValue) {
        const bioDetAddSelectedValue = bioDetAddCoverage.selectedValue.find(
          selectedValue =>
            selectedValue.code === 'selected' && selectedValue.value === 'false'
        );
        if (bioDetAddSelectedValue && response.coverages) {
          const bioDetCoverage = response.coverages.find(
            coverage =>
              coverage.coverageId === CoverageIds.BioDeteriorationDamageCoverage
          );
          if (bioDetCoverage.terms) {
            const newTerms = bioDetCoverage.terms.find(
              term =>
                !!term.options.find(
                  option =>
                    option.value ===
                    StateExceptionCoverageLimit[policyAddress.state][
                      CoverageIds.BioDeteriorationDamageCoverage
                    ][CoverageCodes.BioDeteriorationDamageCoverageLimit].value
                )
            );
            if (newTerms) {
              const bioDetCoverage = response.coverages.find(
                cvg =>
                  cvg.coverageId ===
                    CoverageIds.BioDeteriorationDamageCoverage &&
                  (request.productId === ProductTypes.CONDO ||
                    request.productId === ProductTypes.HOMEOWNERS ||
                    request.productId === ProductTypes.RENTERS)
              );
              return [
                {
                  coverageId: bioDetCoverage.coverageId,
                  coverableId: +bioDetCoverage.coverableId,
                  coverageLevel: bioDetCoverage.coverageLevel,
                  selectedValue: [
                    {
                      code: CoverageCodes.BioDeteriorationDamageCoverageLimit,
                      value:
                        StateExceptionCoverageLimit[policyAddress.state][
                          CoverageIds.BioDeteriorationDamageCoverage
                        ][CoverageCodes.BioDeteriorationDamageCoverageLimit]
                          .value,
                    },
                  ],
                },
              ];
            }
          }
        }
      } else {
        return null;
      }
    }
  }

  @Effect()
  automaticallyCallBindRatingOnBillingChange$ = this._actions$.pipe(
    ofType<fromActions.UpdatePolicyLineSuccess>(
      fromActions.ModifierActionTypes.UPDATE_POLICY_LINE_SUCCESS
    ),
    filter(
      action =>
        action.payload.eligibleDiscountId === ModifierNames.EASY_PAY ||
        action.payload.eligibleDiscountId ===
          ModifierNames.BILLINGPAYMENTMETHOD ||
        action.payload.eligibleDiscountId === ModifierNames.PAIDINFULL
    ),
    withLatestFrom(
      this._store.select(fromSelectors.getRebindWhenInvalidOnBillingChange)
    ),

    switchMap(([action, rebindWhenInvalid]) => {
      const actions = [];

      if (rebindWhenInvalid) {
        actions.push(
          new fromActions.RateQuote(action.payload.productId, 'bind')
        );
      }
      return actions;
    })
  );

  @Effect()
  handleNewBusinessIteration$ = this._actions$.pipe(
    ofType(
      fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_SUCCESS,
      fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_FAIL
    ),
    map(action => <any>action),
    map(action => {
      return new fromActions.RemoveProductIdForNewBusinessCall(
        action.productId
      );
    })
  );
}
