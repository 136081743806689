import { Injectable } from '@angular/core';
import { VehicleExposureEntity } from '@core/models/entities/vehicle-exposure.entity';
import { CRUDService } from './crud.service';
import { Observable } from 'rxjs';
import { VehicleExposureDao } from '@core/dao/vehicle-exposure.dao';
import { VehicleExposureRequest } from '@core/models/umbrella/vehicle-exposure-request.model';
import { VehicleExposureAdapter } from '@core/adapters/vehicle-exposure.adapter';

@Injectable({
  providedIn: 'root',
})
export class VehicleExposureService
  implements CRUDService<VehicleExposureEntity>
{
  constructor(
    private _vehicleExposureDao: VehicleExposureDao,
    private _vehicleExposureAdapter: VehicleExposureAdapter
  ) {}
  add(entity: VehicleExposureEntity): void {
    this._vehicleExposureDao.add(entity);
  }
  addAll?(entity: VehicleExposureEntity[]): void {
    throw new Error('Method not implemented.');
  }
  delete(entityId: string): void {
    throw new Error('Method not implemented.');
  }
  update(entity: VehicleExposureEntity): void {
    throw new Error('Method not implemented.');
  }
  get?(entityId: string): Observable<VehicleExposureEntity> {
    throw new Error('Method not implemented.');
  }
  getAll?(): Observable<VehicleExposureEntity[]> {
    throw new Error('Method not implemented.');
  }

  vehicleExposures(
    request: VehicleExposureRequest
  ): Observable<VehicleExposureEntity> {
    return this._vehicleExposureAdapter.vehicleExposures(request);
  }

  deleteVehicleExposure(request: VehicleExposureRequest) {
    return this._vehicleExposureAdapter.deleteVehicleExposure(request);
  }
}
