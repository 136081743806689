<div
  *ngIf="!(gettingStarted && isDirectUser)"
  class="btn-bg"
  [class.reordered-top-spacing]="isReordered"
>
  <div class="nw-container">
    <div
      class="row g-2"
      id="toscaNav"
      [ngClass]="{
        'justify-content-between': !forwardOnly && !backOnly,
        'justify-content-end': forwardOnly,
        'justify-content-start': backOnly
      }"
    >
      <button
        type="Submit"
        *ngIf="!backOnly"
        class="toscaNav-cf p-2 btn btn-primary col-sm-2 order-1 order-sm-2 mb-2"
        (click)="forward.emit({})"
        [disabled]="isAppLoading || forwardDisabled"
        id="nav-continue"
      >
        {{ submitButtonText }}
      </button>
      <ng-content></ng-content>
      <button
        type="Button"
        *ngIf="!forwardOnly"
        class="toscaNav-gb p-2 btn btn-default col-sm-2 order-2 order-sm-1 mb-2"
        (click)="back.emit({})"
        [disabled]="isAppLoading"
        id="nav-back"
      >
        Back
      </button>
    </div>
  </div>
</div>
<div class="nw-container" *ngIf="gettingStarted && isDirectUser">
  <div
    class="row g-1 pb-3"
    id="toscaNav"
    [ngClass]="{
      'justify-content-start': forwardOnly
    }"
  >
    <button
      type="Submit"
      *ngIf="!backOnly"
      class="toscaNav-cf btn p-2 btn-primary col-sm-2 order-2 order-sm-1 mb-2"
      (click)="forward.emit({})"
      [disabled]="isAppLoading || forwardDisabled"
      id="nav-continue"
    >
      {{ submitButtonText }}
    </button>
  </div>
</div>
