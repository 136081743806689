import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { PersonViewModel } from '@core/models/entities/driver.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';

@Injectable({
  providedIn: 'root',
})
export class PrepopulatedInsuredDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  loadPrepopulatedInsuredData(): void {
    this._store.dispatch(new fromActions.LoadPrepopulatedInsuredData());
  }

  getPrepopulatedInsuredLoaded(): Observable<boolean> {
    return this._store.select(
      fromSelectors.getPrepopulatedInsuredLoadedOrErrored
    );
  }

  getPrepopulatedPolicyHolders(): Observable<PersonViewModel[]> {
    return this._store.select(fromSelectors.getPrepopulatedPolicyHolders);
  }

  getPrepopulatedDrivers(): Observable<PersonViewModel[]> {
    return this._store.select(fromSelectors.getPrepopulatedDrivers);
  }

  getPrepopulatedVehicles(): Observable<VehicleEntity[]> {
    return this._store.select(fromSelectors.getPrepopulatedVehicles);
  }

  updatePrepopulatedVehicleSelected(vehicle: VehicleEntity): void {
    this._store.dispatch(
      new fromActions.UpdatePrepopulatedVehicleSelected(vehicle)
    );
  }

  updatePrepopulatedDriverSelected(driver: PersonViewModel): void {
    this._store.dispatch(
      new fromActions.UpdatePrepopulatedDriverSelected(driver)
    );
  }
}
