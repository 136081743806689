import {
  style,
  animate,
  keyframes,
  trigger,
  transition,
  query,
  stagger,
  state,
  group,
  animateChild,
} from '@angular/animations';

export const Animations = {
  slideIn: trigger('slideIn', [
    transition(':enter', [
      query(':enter', style({ opacity: '0' }), { optional: true }),
      query(
        ':enter',
        [
          stagger('100ms', [
            style({
              opacity: '0',
              transform: 'translateX(-100%)',
            }),
            animate(
              '300ms',
              style({
                opacity: '1',
                transform: 'translateX(0)',
              })
            ),
          ]),
        ],
        { optional: true }
      ),
    ]),
  ]),
  create: trigger('create', [
    transition('void => create', [
      style({ opacity: '0' }),
      animate(
        '300ms 300ms',
        keyframes([
          style({ opacity: '0', offset: 0 }),
          style({ opacity: '1', offset: 1 }),
        ])
      ),
    ]),
    transition('* => update', [
      query('.product-button', style({ 'border-left': 'none' })),
      query('.product-button-text', style({ opacity: '0' })),
      query(
        '.saved',
        animate(
          '3000ms',
          keyframes([
            style({ opacity: '0', offset: 0 }),
            style({ opacity: '1', offset: 0.4 }),
            style({ opacity: '0', offset: 0.8 }),
          ])
        ),
        { optional: true }
      ),
    ]),
  ]),
  slideDown: trigger('slideDown', [
    state('0', style({ height: '0px', visibility: 'hidden' })),
    state('1', style({ height: '*', visibility: 'visible' })),
    transition('0 => 1', animate('300ms')),
  ]),
  slideInOut: trigger('slideInOut', [
    state(
      'in',
      style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible',
      })
    ),
    state(
      'out',
      style({
        'max-height': '0px',
        opacity: '0',
        visibility: 'hidden',
      })
    ),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '0px',
          })
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden',
          })
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '500px',
          })
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1',
          })
        ),
      ]),
    ]),
  ]),
  slideInOutParent: trigger('slideInOutParent', [
    transition(':enter, :leave', [
      query('@*', animateChild(), { optional: true }),
    ]),
  ]),
  slideInOutChild: trigger('slideInOut', [
    transition('void => new', [
      style({ height: '0px', visibility: 'hidden' }),
      animate('300ms 300ms', style({ height: '*', visibility: 'visible' })),
    ]),
    transition('* => left', [
      style({
        transform: 'translateX(100%)',
      }),
      animate(
        '300ms',
        style({
          transform: 'translateX(0)',
        })
      ),
    ]),
    transition('* => right', [
      style({
        transform: 'translateX(-100%)',
      }),
      animate(
        '300ms',
        style({
          transform: 'translateX(0)',
        })
      ),
    ]),
  ]),
  toggle: trigger('toggle', [
    state('collapsed', style({ height: '0px', visibility: 'hidden' })),
    state(
      'expanded',
      style({
        height: '*',
        visibility: 'visible',
      })
    ),
    transition('expanded <=> collapsed', animate('300ms')),
  ]),
  selected: trigger('selected', [
    state(
      'false',
      style({
        background: '#fff',
        'border-radius': '50%',
      })
    ),
    state(
      'true',
      style({
        background: 'var(--custom-bg-color-var)',
        'border-radius': '50%',
      })
    ),
    transition('false <=> true', animate('300ms')),
  ]),
  slideUpDownProductParent: trigger('slideUpDownProductParent', [
    transition(':enter, :leave', [
      query('@slideUpDownProductChild', animateChild(), { optional: true }),
    ]),
  ]),
  slideUpDownProductChild: trigger('slideUpDownProductChild', [
    transition(':enter', [
      style({ height: '0px' }),
      animate('300ms', style({ height: '*', visibility: 'visible' })),
    ]),
    transition(':leave', [
      style({ height: '*' }),
      animate('300ms', style({ height: '0px', visibility: 'hidden' })),
    ]),
  ]),
  slideUp: trigger('slideUp', [
    transition(':enter', [
      query(':enter', style({ opacity: '0' }), { optional: true }),
      query(
        ':enter',
        [
          stagger('100ms', [
            style({
              opacity: '0',
              transform: 'translateY(50%)',
            }),
            animate(
              '200ms',
              style({
                opacity: '1',
                transform: 'translateY(0)',
              })
            ),
          ]),
        ],
        { optional: true }
      ),
    ]),
  ]),
};
