import { ConsentValuesEntity } from '@core/models/entities/consent-values.entity';
import {
  UPDATE_PREBIND_DOCUMENTS_ACKNOWLEDGEMENT,
  ConsentValuesAction,
  UPDATE_CONNECTED_CAR_CONSENT,
  UPDATE_SMARTRIDE_INSTANT_CONSENT,
  UPDATE_AUTO_ID_CARDS_TEXT_CONSENT,
} from '../actions/consent-values.action';

export interface ConsentValuesState {
  data: ConsentValuesEntity;
  loaded: boolean;
}

const initialState: ConsentValuesState = {
  data: {
    preBindDocumentsAcknowledgement: {
      hasReceivedDocuments: false,
      hasViewedDocuments: false,
    },
    connectedCarConsent: {
      authorizationToShare: false,
      hasConnectedCarConsent: false,
    },
    smartrideInstantConsent: {
      hasViewedDrivingDataConsent: false,
      hasDrivingDataConsent: false,
    },
    autoIdCardsTextConsent: {
      agreementToText: false,
      mobileNumber: null,
    },
  },
  loaded: false,
};

export function reducer(
  state = initialState,
  action: ConsentValuesAction
): ConsentValuesState {
  switch (action.type) {
    case UPDATE_PREBIND_DOCUMENTS_ACKNOWLEDGEMENT: {
      const preBindDocumentsAcknowledgement = action.payload;
      const data = { ...state.data, preBindDocumentsAcknowledgement };

      return { ...state, data, loaded: true };
    }

    case UPDATE_CONNECTED_CAR_CONSENT: {
      const connectedCarConsent = action.payload;
      const data = { ...state.data, connectedCarConsent };

      return { ...state, data, loaded: true };
    }

    case UPDATE_SMARTRIDE_INSTANT_CONSENT: {
      const smartrideInstantConsent = action.payload;
      const data = { ...state.data, smartrideInstantConsent };

      return { ...state, data, loaded: true };
    }

    case UPDATE_AUTO_ID_CARDS_TEXT_CONSENT: {
      const autoIdCardsTextConsent = action.payload;
      const data = { ...state.data, autoIdCardsTextConsent };

      return { ...state, data, loaded: true };
    }

    default:
      return { ...state };
  }
}
