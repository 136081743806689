export class BankCardUtils {
  static cardType = {
    MAST: 'MasterCard',
    VISA: 'Visa',
    AMEX: 'American Express',
    DISC: 'Discover',
  };

  static mapCardToCardType(card: string) {
    return BankCardUtils.cardType[card];
  }
}
