import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, switchMap, catchError, filter } from 'rxjs/operators';

import { QuoteService } from '@core/services/quote.service';
import { ProductsService } from '@core/services/products.service';
import { ProductTypes } from '@shared/constants/app-constants';
import { PreBindDocumentsService } from '@core/services/pre-bind-documents.service';

@Injectable()
export class PreBindPowersportsDocumentsAcknowledgementGuard
  implements CanActivate
{
  constructor(
    private _preBindDocumentsService: PreBindDocumentsService,
    private _quoteService: QuoteService,
    private _productsService: ProductsService
  ) {}

  canActivate(): Observable<boolean> {
    return this._preBindDocumentsService
      .arePrebindDocumentsAcknowledgementRequiredPowersports()
      .pipe(
        take(1),
        switchMap(required => {
          if (required) {
            return this.checkStore();
          }
          return of(true);
        }),
        catchError(() => of(true))
      );
  }

  checkStore(): Observable<boolean> {
    return this._productsService
      .arePreBindPowersportsDocumentsAcknowledged()
      .pipe(
        take(1),
        switchMap(acknowledged => {
          if (!acknowledged) {
            this._quoteService.dispatchPreBindPowersportsDocumentsAcknowledgement(
              true,
              true
            );
            return this._quoteService
              .getQuoteUpdateLoaded(ProductTypes.POWERSPORTS)
              .pipe(
                filter(loaded => loaded),
                take(1)
              );
          }
          return of(true);
        })
      );
  }
}
