import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromHelpContent from './help-content.reducer';

export interface HelpContentState {
  helpContent: fromHelpContent.HelpContentState;
}

export const reducers: ActionReducerMap<HelpContentState> = {
  helpContent: fromHelpContent.reducer,
};

export const getHelpContentState =
  createFeatureSelector<HelpContentState>('helpContent');
