import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';

import { AccessToken } from '@core/store/reducers/token.reducer';
import { AccessTokenRequest } from '@core/models/auto/quotes/access-token-request.model';

@Injectable({
  providedIn: 'root',
})
export class TokenDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getAll(): Observable<AccessToken[]> {
    return this._store.select(fromSelectors.selectAllTokens);
  }

  getAccessTokenForProductId(productId: string): Observable<AccessToken> {
    return this._store.select(fromSelectors.getTokenByProduct(productId));
  }

  refresh(productId: string): Observable<AccessToken> {
    this._store.dispatch(new fromActions.DropAccessToken(productId));
    this._store.dispatch(new fromActions.GenerateAccessToken(productId));
    return this._store.select(fromSelectors.getTokenByProduct(productId));
  }

  getAccessTokenForRequest(
    request: AccessTokenRequest
  ): Observable<AccessToken> {
    if (!request.isCompRater) {
      this._store.dispatch(new fromActions.DropAccessToken(request.productId));
      this._store.dispatch(
        new fromActions.GenerateAccessTokenForRequest(request)
      );
    }
    return this._store.select(
      fromSelectors.getTokenByProduct(request.productId)
    );
  }

  getAccessTokenForNonQuoteIdRequest(
    request: AccessTokenRequest
  ): Observable<AccessToken> {
    if (!request.isCompRater) {
      this._store.dispatch(new fromActions.DropAccessToken(request.productId));
      this._store.dispatch(
        new fromActions.GenerateAccessTokenForRequest(request)
      );
    }
    return this._store.select(
      fromSelectors.getTokenByProduct(request.productId)
    );
  }

  setAccessToken(request: AccessToken): void {
    this._store.select(fromSelectors.getAgentJwt).subscribe(agentJwt => {
      if (!agentJwt.jwt) {
        this._store.dispatch(
          new fromActions.DropAccessToken(request.productId)
        );
        this._store.dispatch(new fromActions.SetAccessToken(request));
      }
    });
  }

  areAllTokensLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.areAllTokensLoaded);
  }

  insertAccessToken(accessToken: string): void {
    this._store.dispatch(new fromActions.InsertAccessToken(accessToken));
  }
}
