import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update,
} from '@ngrx/entity';

import * as fromPolicyholders from '@core/store/actions/policyholders.action';
import * as fromDrivers from '@core/store/actions/driver.action';

import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { DEFAULT_ID, PolicyholderTypes } from '@shared/constants/app-constants';

export interface PolicyholderState extends EntityState<PolicyholderEntity> {
  loaded: boolean;
  loading: number;
}
export const adapter: EntityAdapter<PolicyholderEntity> =
  createEntityAdapter<PolicyholderEntity>({
    selectId: policyholder => policyholder.policyHolderId,
  });

export function reducer(
  state = adapter.getInitialState({
    loaded: false,
    loading: 0,
  }),
  action: fromPolicyholders.PolicyholderActions | fromDrivers.DriverActions
): PolicyholderState {
  switch (action.type) {
    case fromPolicyholders.PolicyholderActionTypes.ADD_POLICYHOLDER: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromPolicyholders.PolicyholderActionTypes.UPDATE_POLICYHOLDER: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromPolicyholders.PolicyholderActionTypes.DELETE_POLICYHOLDER: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    // The SUCCESS actions are often fired artificially, so we explicitly mark call completions.
    case fromPolicyholders.PolicyholderActionTypes.POLICYHOLDER_CALL_COMPLETE: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromPolicyholders.PolicyholderActionTypes.ADD_POLICYHOLDER_SUCCESS: {
      const policyHolder = transformPolicyHolderForSave(action.payload, state);
      return adapter.upsertOne(policyHolder, state);
    }

    case fromPolicyholders.PolicyholderActionTypes.ADD_ALL_POLICYHOLDERS: {
      return adapter.setAll(action.payload, {
        ...state,
        loading: 0,
        loaded: true,
      });
    }

    case fromPolicyholders.PolicyholderActionTypes.UPDATE_ALL_POLICYHOLDERS: {
      const policyHolderUpdates = action.payload.map(policyHolder => {
        const changes = transformPolicyHolderForSave(policyHolder, state);
        const id = changes.policyHolderId;
        return { id, changes };
      });

      return adapter.updateMany(policyHolderUpdates, state);
    }

    case fromPolicyholders.PolicyholderActionTypes
      .DELETE_POLICYHOLDER_SUCCESS: {
      return adapter.removeOne(action.payload, state);
    }

    case fromPolicyholders.PolicyholderActionTypes
      .UPDATE_POLICYHOLDER_SUCCESS: {
      const policyHolder = transformPolicyHolderForSave(action.payload, state);
      return adapter.upsertOne(policyHolder, state);
    }

    case fromPolicyholders.PolicyholderActionTypes.UPDATE_POLICYHOLDER_FAIL: {
      return { ...state, loaded: false };
    }

    case fromPolicyholders.PolicyholderActionTypes.UPDATE_POLICYHOLDER_DOB: {
      const entity = state.entities[action.policyholderId];
      if (entity) {
        return adapter.updateOne(
          {
            id: action.policyholderId,
            changes: {
              person: {
                ...entity.person,
                dateOfBirth: action.dob,
              },
            },
          },
          state
        );
      }
      return state;
    }

    // TODO remove and call from effect
    case fromDrivers.DriverActionTypes.ADD_DRIVER_SUCCESS: {
      const changes: Update<PolicyholderEntity> = {
        changes: { driverId: action.payload.driverId },
        id: action.payload.policyHolderId,
      };
      return adapter.updateOne(changes, state);
      // } else {
      // return state;
      // }
    }

    case fromDrivers.DriverActionTypes.ADD_POWERSPORTS_DRIVER_SUCCESS: {
      const changes: Update<PolicyholderEntity> = {
        changes: { powersportsDriverId: action.payload.driverId },
        id: action.payload.policyHolderId,
      };
      return adapter.updateOne(changes, state);
    }

    // TODO remove and call from effect
    case fromDrivers.DriverActionTypes.UPDATE_DRIVER_SUCCESS:
    case fromDrivers.DriverActionTypes.UPDATE_DRIVER_DIRECTLY: {
      const policyholder =
        state.entities[action.payload.changes.policyHolderId];
      if (!policyholder) {
        return state;
      }
      const changedPerson = {
        ...action.payload.changes.person,
      };
      const changes: Update<PolicyholderEntity> = {
        changes: { person: changedPerson },
        id: action.payload.changes.policyHolderId,
      };
      return adapter.updateOne(changes, state);
      // } else {
      // return state;
      // }
    }

    case fromDrivers.DriverActionTypes.UPDATE_POWERSPORTS_DRIVER_SUCCESS: {
      const policyholder =
        state.entities[action.payload.changes.policyHolderId];
      if (!policyholder) {
        return state;
      }
      const changedPerson = {
        ...action.payload.changes.person,
      };
      const changes: Update<PolicyholderEntity> = {
        changes: { person: changedPerson },
        id: action.payload.changes.policyHolderId,
      };
      return adapter.updateOne(changes, state);
    }

    case fromPolicyholders.PolicyholderActionTypes
      .UPDATE_CONTACT_POINT_DSM_SUCCESS: {
      return adapter.updateOne(
        {
          id: DEFAULT_ID,
          changes: { tcpaConsent: action.payload.mcpContactPoint.consent },
        },
        state
      );
    }

    default: {
      return state;
    }
  }
}

/**
 * Appends the current state policyHolderIds to the input
 * policyHolder.policyHolderIds for pni policy holders
 */
function transformPolicyHolderForSave(
  policyHolder: PolicyholderEntity,
  state: PolicyholderState
): PolicyholderEntity {
  const currentStatePolicyHolder = state.entities[policyHolder.policyHolderId];
  const currentStatePerson = currentStatePolicyHolder
    ? currentStatePolicyHolder.person
    : {};
  const updatedPolicyHolder = {
    ...(currentStatePolicyHolder || {}),
    ...policyHolder,
  };
  const updatedPerson = { ...currentStatePerson, ...policyHolder.person };

  // If suffix is removed, it will be absent on the incoming entity.
  // !!! There are probably other fields like this... maybe the whole "person"?
  if (!policyHolder.person.suffix) {
    delete updatedPerson.suffix;
  }

  /**
   * need to update the policyHolderId and policyHolderIds
   * over the current state for pni policyHolder
   */
  const currentStatePolicyHolderIds = state.entities[DEFAULT_ID]
    ? state.entities[DEFAULT_ID].policyHolderIds
    : {};
  if (
    policyHolder.policyHolderId === DEFAULT_ID ||
    policyHolder.policyHolderType ===
      PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
  ) {
    updatedPolicyHolder.policyHolderId = DEFAULT_ID;
    updatedPolicyHolder.person = updatedPerson;
    updatedPolicyHolder.addressId = DEFAULT_ID;
    updatedPolicyHolder.policyHolderIds = {
      ...currentStatePolicyHolderIds,
      ...policyHolder.policyHolderIds,
    };

    if (policyHolder.policyHolderId !== DEFAULT_ID) {
      updatedPolicyHolder.policyHolderIds[policyHolder.productId] =
        policyHolder.policyHolderId;
    }

    /**
     * response returns masked dateOfBirth so need to use current
     * state if masked value is provided
     */
    if (
      !updatedPerson.dateOfBirth ||
      (updatedPerson.dateOfBirth.indexOf('*') >= 0 &&
        currentStatePolicyHolder &&
        currentStatePolicyHolder.person)
    ) {
      updatedPerson.dateOfBirth = currentStatePolicyHolder?.person?.dateOfBirth;
    }
    /* Similarly, keep the existing phoneNumber and emailAddress if incoming is missing or masked.
     */
    if (currentStatePolicyHolder && currentStatePolicyHolder.homeNumber) {
      if (
        !updatedPolicyHolder.homeNumber ||
        updatedPolicyHolder.homeNumber.indexOf('*') >= 0
      ) {
        updatedPolicyHolder.homeNumber = currentStatePolicyHolder.homeNumber;
      }
    }
    if (currentStatePolicyHolder && currentStatePolicyHolder.emailAddress) {
      // God help the poor fool with an asterisk in his email address...
      if (
        !updatedPolicyHolder.emailAddress ||
        updatedPolicyHolder.emailAddress.indexOf('*') >= 0
      ) {
        updatedPolicyHolder.emailAddress =
          currentStatePolicyHolder.emailAddress;
      }
    }

    /**
     * can remove these fields because we dont need to store them in state
     */
    delete updatedPolicyHolder.productId;
    delete updatedPolicyHolder.address;
  }

  return updatedPolicyHolder;
}
