import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import * as fromContainers from './containers';
import * as fromShared from '../shared';
import * as fromComponents from './components';
import * as fromProxies from './proxies';
import * as fromGuards from './guards';

import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

// TODO refactor services to be sharable across modules

import { effects } from './store';
import { EffectsModule } from '@ngrx/effects';
import { AgencyRoutingModule } from './agency-routing.module';
import { FieldValidationMessageModule } from '../shared';
import { SearchModule } from 'app/search/search.module';
import { QueryParameterPreservingRedirectModule } from '@shared/components/query-parameter-perserving-redirect/query-parameter-preserving-redirect.module';

@NgModule({
  imports: [
    CommonModule,
    QueryParameterPreservingRedirectModule,
    AgencyRoutingModule,
    SearchModule,
    FieldValidationMessageModule,
    fromShared.NavigationButtonsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    EffectsModule.forFeature(effects),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [...fromContainers.containers, ...fromComponents.components],
  providers: [...fromProxies.proxies, ...fromGuards.guards],
})
export class AgencyModule {}
