import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromVehicles from '@core/store/reducers/vehicle.reducer';
import * as fromProduct from '@core/store/selectors/products.selector';
import * as fromAdditionalInterest from '@core/store/selectors/additional-interest.selector';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import * as fromMetadata from '@core/store/selectors/metadata.selector';

import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { VehicleRequest } from '@core/models/request/vehicle-request.model';
import { VehicleDetailsRequest } from '@core/models/request/vehicle-details-request.model';
import {
  ANNUAL_MILES_DEFAULT_CA,
  DEFAULT_ID,
  ModifierNames,
  ProductTypes,
} from '@shared/constants/app-constants';

export const getPowersportsVehicleState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.powersportsVehicles
);

export const {
  selectAll: selectAllPowersportsVehicles,
  selectEntities: selectPowersportsVehicleEntities,
} = fromVehicles.adapter.getSelectors(getPowersportsVehicleState);

export const getPowersportsVehicleEntity = (powersportsVehicleId: string) =>
  createSelector(
    selectPowersportsVehicleEntities,
    fromAdditionalInterest.selectAdditionalInterestEntities,
    (powersportsVehicles, interests) => {
      const powersportsVehicle: VehicleEntity = {
        ...powersportsVehicles[powersportsVehicleId],
      };

      if (powersportsVehicle.additionalInterestIds) {
        powersportsVehicle.additionalInterests =
          powersportsVehicle.additionalInterestIds.map(id => interests[id]);
      } else {
        powersportsVehicle.additionalInterests = [];
      }

      return powersportsVehicle;
    }
  );

export const getAllPowersportsVehicles = createSelector(
  selectAllPowersportsVehicles,
  fromAdditionalInterest.selectAdditionalInterestEntities,
  (powersportsVehicles, interests) => {
    return powersportsVehicles.map(vehicle => {
      const returnVehicle = { ...vehicle };

      if (vehicle.additionalInterestIds) {
        returnVehicle.additionalInterests = vehicle.additionalInterestIds.map(
          id => interests[id]
        );
      } else if (vehicle.additionalInterests) {
        returnVehicle.additionalInterests = vehicle.additionalInterests.map(
          additionalInterest =>
            interests[additionalInterest.additionalInterestId]
        );
      } else {
        returnVehicle.additionalInterests = [];
      }
      return returnVehicle;
    });
  }
);

export const getAllPowersportsVehiclesIfSelected = createSelector(
  getAllPowersportsVehicles,
  fromProduct.getSelectedQuoteProductsWithoutErrors,
  (vehicles, products) => {
    const powersportsProduct = products.find(
      p => p.id === ProductTypes.POWERSPORTS
    );
    return powersportsProduct ? vehicles : [];
  }
);

export const getPowersportsVehiclesWithAdditionalInterest = createSelector(
  getAllPowersportsVehicles,
  fromProducts.getSelectedProductsWithoutErrors,
  (powersportsVehicles, products) => {
    const productIds = products.map(product => product.id);
    return powersportsVehicles.filter(
      vehicle =>
        vehicle.additionalInterests &&
        vehicle.additionalInterests.length > 0 &&
        productIds.includes(vehicle.productId)
    );
  }
);

export const getAllPowersportsVehiclesWithoutRegisteredOwners = createSelector(
  getAllPowersportsVehicles,
  powersportsVehicles =>
    powersportsVehicles.filter(
      veh => !veh.registeredOwners || !veh.registeredOwners.length
    )
);

export const getPowersportsVehicleByVin = (vin: string) =>
  createSelector(getAllPowersportsVehicles, powersportsVehicles => {
    for (const vehicle of powersportsVehicles) {
      if (vehicle.vin === vin) {
        return vehicle;
      }
    }
    return null;
  });

export const buildPowersportsVehicleRequest = (entity: VehicleEntity) =>
  createSelector(
    fromProducts.getProduct(entity.productId),
    getAllPowersportsVehicles,
    fromUserContext.getUserContext,
    fromMetadata.getStateSpecificFlag('defaultAnnualMiles'),
    fromMetadata.getStateSpecificFlag('primaryUseApplicable'),
    (
      product,
      allPowersportsVehicles,
      userContext,
      defaultAnnualMiles,
      primaryUseApplicable
    ) => {
      const existingEntity = allPowersportsVehicles.find(
        v => v.vehicleId === entity.vehicleId
      );
      const request: VehicleRequest = {
        quoteId: product.quoteId,
        productId: product.id,
        vehicle: {
          ...existingEntity,
          ...entity,
          primaryUse: null,
          vin: entity.vin || existingEntity?.vin || null,
          additionalInterests:
            entity.additionalInterests &&
            entity.additionalInterests.length > 0 &&
            entity.additionalInterests[0].additionalInterestType !== 'none'
              ? entity.additionalInterests
              : [],
          antiTheftDevices: null,
          eligibleDiscounts:
            entity.eligibleDiscounts?.length !== undefined
              ? entity.eligibleDiscounts.filter(obj =>
                  obj?.hasOwnProperty('selectedOptionValue')
                )
              : [],
          annualMiles: entity.annualMiles !== 'NaN' ? entity.annualMiles : '1',
          // PQCC Temp change. Once they start using PrePopulated, this will need changed.
          prequalifiedPartnerScoreId: userContext.prequalifiedPartnerScoreId
            ? allPowersportsVehicles.length === 0
              ? userContext.prequalifiedPartnerScoreId
              : undefined
            : undefined,
        },
      };
      if (defaultAnnualMiles) {
        request.vehicle.annualMiles = ANNUAL_MILES_DEFAULT_CA;
      }
      if (primaryUseApplicable) {
        request.vehicle.primaryUse = entity.primaryUse;
      } else {
        request.vehicle.primaryUse = null;
      }
      if (entity.additionalInformation) {
        request.vehicle.additionalInformation = entity.additionalInformation;
      }
      if (entity.value) {
        request.vehicle.value = entity.value;
      }
      if (entity.agreedValue) {
        request.vehicle.agreedValue = entity.agreedValue;
      }
      if (entity.marketValue) {
        request.vehicle.marketValue = entity.marketValue;
      }
      if (entity.vehicleTempId) {
        request.vehicle.vehicleTempId = entity.vehicleTempId;
      }
      if (entity.prepopulatedInsuredId) {
        request.vehicle.prepopulatedInsuredId = entity.prepopulatedInsuredId;
      }
      if (entity.cubicCentimeters) {
        request.vehicle.cubicCentimeters = entity.cubicCentimeters;
      }
      if (entity.subType) {
        request.vehicle.subType = entity.subType;
      }
      if (entity.garageLocation) {
        request.vehicle.garageLocation = entity.garageLocation;
      }
      return request;
    }
  );

export const buildPowersportsVehicleDetailsRequest = (entity: VehicleEntity) =>
  createSelector(
    fromTokens.getAccessToken(entity.productId),
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    (token, address) => {
      const request: VehicleDetailsRequest = {
        accessToken: token,
        state: address.state,
        vin: entity.vin,
        entity,
      };

      return request;
    }
  );

export const getPowersportsVehicleSmartRideLoaded = (
  powersportsVehicleId: string
) =>
  createSelector(selectPowersportsVehicleEntities, powersportsVehicles => {
    const vehicle: VehicleEntity = {
      ...powersportsVehicles[powersportsVehicleId],
    };

    if (vehicle && vehicle.eligibleDiscounts) {
      const smartride = vehicle.eligibleDiscounts.find(
        discount =>
          discount.eligibleDiscountId === ModifierNames.VEHICLE_SMARTRIDE
      );
      return smartride ? smartride.selectedOptionValue : false;
    }
    return false;
  });

export const getPowersportsSelfReportedLoaded = (
  powersportsVehicleId: string
) =>
  createSelector(selectPowersportsVehicleEntities, powersportsVehicles => {
    const vehicle: VehicleEntity = {
      ...powersportsVehicles[powersportsVehicleId],
    };

    if (vehicle && vehicle.eligibleDiscounts) {
      const selfreported = vehicle.eligibleDiscounts.find(
        discount =>
          discount.eligibleDiscountId === ModifierNames.VEHICLE_SELFREPORTED
      );
      return selfreported ? selfreported.selectedOptionValue : false;
    }
    return false;
  });

export const getPowersportsVehicleDiscount = (
  powersportsVehicleId: string,
  discountId: string
) =>
  createSelector(selectPowersportsVehicleEntities, powersportsVehicles => {
    const vehicle: VehicleEntity = {
      ...powersportsVehicles[powersportsVehicleId],
    };
    if (vehicle && vehicle.eligibleDiscounts) {
      const selectedDiscount = vehicle.eligibleDiscounts.find(
        discount => discount.eligibleDiscountId === discountId
      );
      return { ...selectedDiscount, productId: vehicle.productId };
    }
    return null;
  });

export const getPowersportsVehicleDiscountsLoaded = (
  powersportsVehicleId: string
) =>
  createSelector(selectPowersportsVehicleEntities, powersportsVehicles => {
    const vehicle: VehicleEntity = {
      ...powersportsVehicles[powersportsVehicleId],
    };
    return vehicle ? vehicle.vehicleDiscountsLoaded : false;
  });

export const getPowersportsVehiclesLoading = createSelector(
  getPowersportsVehicleState,
  state => state.loading > 0
);

export const getPowersportsVehicleAuxillaryLightingSystemDiscountValue = (
  powersportsVehicleId: string
) =>
  createSelector(selectPowersportsVehicleEntities, powersportsVehicles => {
    const vehicle: VehicleEntity = {
      ...powersportsVehicles[powersportsVehicleId],
    };
    if (vehicle && vehicle.eligibleDiscounts) {
      const auxillaryLightingSystem = vehicle.eligibleDiscounts.find(
        discount =>
          discount.eligibleDiscountId ===
          ModifierNames.AUXILLARY_LIGHTING_SYSTEM
      );
      return auxillaryLightingSystem
        ? auxillaryLightingSystem.selectedOptionValue === 'true'
        : false;
    }
    return false;
  });
