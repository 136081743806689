import { Injectable } from '@angular/core';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { HouseholdMemberRequest } from '@core/models/umbrella/household-member-request';
import { Observable } from 'rxjs';
import { HouseholdMemberEntity } from '@core/models/entities/household-member.entity';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HouseholdMemberAdapter extends BaseDsmAdapter {
  addHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<HouseholdMemberEntity> {
    const url = `${this.getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/household-members`;

    return this.post<HouseholdMemberEntity>(
      url,
      { householdMember: request.householdMember },
      { productId: request.productId }
    ).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }

  updateHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<HouseholdMemberEntity> {
    const url = `${this.getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/household-members/${request.householdMember.householdMemberId}`;

    return this.put<HouseholdMemberEntity>(
      url,
      { householdMember: request.householdMember },
      { productId: request.productId }
    ).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }

  deleteHouseholdMember(
    request: HouseholdMemberRequest
  ): Observable<HouseholdMemberEntity> {
    const url = `${this.getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/household-members/${request.householdMember.householdMemberId}`;

    return this.delete<HouseholdMemberEntity>(url, {
      productId: request.productId,
    });
  }
}
