import { Injectable } from '@angular/core';

import { Entry } from 'contentful';

import { ContentfulService } from '@core/services/contentful.service';
import { Observable, from } from 'rxjs';
import { PageAlert } from '@core/models/page-alerts/page-alert.model';

@Injectable()
export class PageAlertAdapter {
  private CONTENT_TYPE = 'applicationMessages';

  constructor(private _contentfulService: ContentfulService) {}

  getPageAlerts(): Observable<PageAlert[]> {
    return from(
      this._contentfulService
        .getEntries(this.CONTENT_TYPE, {
          include: 0,
        })
        .then((response: Entry<any>[]) => {
          return response.map(alert => {
            const priority = alert.fields.priority ? alert.fields.priority : 0;
            const appliedStates = alert.fields.appliedStates
              ? alert.fields.appliedStates
              : [];
            return {
              ...alert.fields,
              priority,
              appliedStates,
              id: alert.sys.id,
            } as PageAlert;
          }) as PageAlert[];
        })
    );
  }
}
