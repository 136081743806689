<div class="pill-wrapper">
  <div class="checkbox-wrapper" (click)="toggleDiscount()">
    <input
      type="checkbox"
      class="checkbox"
      [attr.aria-label]="eventName"
      [disabled]="disabled"
    />
    <div class="checkbox" [ngClass]="{ disabled: disabled }">
      <a class="icon checkmark" *ngIf="selected"></a>
    </div>
  </div>
  <div class="content" (click)="toggleDiscount()">{{ label }}</div>
  <div *ngIf="showHelp" class="help-content" (click)="onHelpContent()">
    <a class="icon questionmark"></a>
  </div>
</div>
