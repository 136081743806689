import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'mq-accordion-header',
  templateUrl: './accordion-header.component.html',
  styleUrls: ['./accordion-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionHeaderComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() cost: number;
  @Input() showCoveragePrices: boolean;

  constructor() {}

  ngOnInit(): void {}
}
