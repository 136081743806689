import * as fromFeature from '@core/store/reducers';
import { createSelector } from '@ngrx/store';

export const getPowersportsCurrentCarrierState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.powersportsCurrentCarrier
);

export const getPowersportsCurrentCarrierLoaded = createSelector(
  getPowersportsCurrentCarrierState,
  (state): boolean => state.powersportsCurrentCarrierLoaded
);

export const getPowersportsCurrentCarrierLoading = createSelector(
  getPowersportsCurrentCarrierState,
  (state): boolean => state.powersportsCurrentCarrierLoading
);

export const getPowersportsCurrentCarrier = createSelector(
  getPowersportsCurrentCarrierState,
  powersportsCurrentCarrier =>
    powersportsCurrentCarrier.powersportsCurrentCarrier
);

export const getPowersportsCurrentCarrierHit = createSelector(
  getPowersportsCurrentCarrierState,
  (state): boolean => state.isPowersportsCurrentCarrierHit
);
