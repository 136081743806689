import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SoftfallComponent } from './containers/softfall/softfall.component';
import { MaintenanceComponent } from './containers/maintenance/maintenance.component';

export const ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'multi-quote' },
  {
    path: 'multi-quote',
    loadChildren: () =>
      import('./quoting/quoting.module').then(m => m.QuotingModule),
  },
  {
    path: 'multi-bind',
    loadChildren: () => import('./bind/bind.module').then(m => m.BindModule),
  },
  {
    path: 'multi-payment',
    loadChildren: () =>
      import('./billing/billing.module').then(m => m.BillingModule),
  },
  {
    path: 'multi-policy',
    loadChildren: () =>
      import('./policy/policy.module').then(m => m.PolicyModule),
  },
  {
    path: 'agency',
    loadChildren: () =>
      import('./agency/agency.module').then(m => m.AgencyModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'retrieve',
    loadChildren: () =>
      import('./retrieve/retrieve.module').then(m => m.RetrieveModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then(m => m.SearchModule),
  },
  {
    path: 'softfall',
    component: SoftfallComponent,
  },
  {
    path: 'nonStandardBridge',
    component: SoftfallComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: '**',
    redirectTo: '/multi-quote',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      scrollPositionRestoration: 'enabled',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
