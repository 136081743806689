import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { Observable } from 'rxjs';
import {
  getLoadingUpperMessage,
  getLoadingLowerMessage,
} from '@core/store/selectors';

@Injectable()
export class LoadingDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  loadingBegin() {
    this._store.dispatch(new fromActions.AppLoadingBegin());
  }

  loadingEnd() {
    this._store.dispatch(new fromActions.AppLoadingEnd());
  }

  getAppLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getAppLoading);
  }

  setMessages(upperMessage: string, lowerMessage: string) {
    this._store.dispatch(
      new fromActions.AppLoadingMessages(upperMessage, lowerMessage)
    );
  }

  getUpperMessage(): Observable<string | null> {
    return this._store.select(getLoadingUpperMessage);
  }

  getLowerMessage(): Observable<string | null> {
    return this._store.select(getLoadingLowerMessage);
  }
}
