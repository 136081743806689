import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { ProducerInformation } from '@core/models/private-label/private-label.model';
import { StateUtils } from '@shared/utils/state.utils';

@Component({
  selector: 'nw-private-label-agency-card',
  templateUrl: './private-label-agency-card.component.html',
  styleUrls: ['./private-label-agency-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivateLabelAgencyCardComponent implements OnInit {
  @Input() producerInfo: ProducerInformation;

  licenseNumber: string;
  nameFiledWithCommissioner: string;
  stateAbbrev: string;
  fullResidentStateName: string;

  ngOnInit(): void {
    this.licenseNumber = this.producerInfo.agencyLicenseNumber;
    this.nameFiledWithCommissioner =
      this.producerInfo.nameFiledWithCommissioner;
    this.stateAbbrev = this.producerInfo.state;
    this.fullResidentStateName = StateUtils.mapAbbreviationToFullName(
      this.producerInfo.residentState
    );
  }
}
