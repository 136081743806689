import { createSelector } from '@ngrx/store';

import * as fromProducts from '@core/store/selectors/products.selector';
import { PolicyIssueRequest } from '@core/models/policy-issue/policy-issue-request.model';
import { isAgent, isNSSAgent } from '@core/store/selectors/agency.selector';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import {
  ProductTypes,
  RIVIAN_THIRDPARTY_ID,
} from '@shared/constants/app-constants';

export const buildPolicyIssueRequests = createSelector(
  fromProducts.getSelectedProductsWithoutErrors,
  isAgent,
  isNSSAgent,
  fromUserContext.getUserContext,
  (products, isAgent, isNSSAgent, userContext) => {
    products = products.filter(product => product.id !== ProductTypes.TERMLIFE);
    return products.map(product => {
      let request: PolicyIssueRequest = {
        quoteId: product.quoteId,
        productId: product.id,
      };
      if (
        !isNSSAgent &&
        !isAgent &&
        !(
          userContext &&
          userContext.thirdPartyId &&
          userContext.thirdPartyId === RIVIAN_THIRDPARTY_ID
        )
      ) {
        request = { ...request, hasEDDAConsent: true };
      }

      return request;
    });
  }
);

export const buildGetDocumentsRequests = createSelector(
  fromProducts.getIssuedProducts,
  products => {
    return products.map(product => {
      return {
        productId: product.id,
        policyNumber: product.policyNumber,
      };
    });
  }
);
