import { Injectable } from '@angular/core';
import { BillingDao } from '@core/dao/billing.dao';
import { BillingAdapter } from '@core/adapters/billing.adapter';
import { take, switchMap, tap, map, catchError } from 'rxjs/operators';
import { OneTimePasswordResponse } from '@core/models/one-time-password/one-time-password-response.model';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { PciV2Response } from '@core/models/pci-v2/pci-v2-response.model';
import { ProductDisplay } from '@core/models/display/quote-coverages/products/product-display.model';
import { LoggingService } from './logging.service';
import { ProductPayPlanSearchResponse } from '@core/models/billing/payment-plan/payment-plan-response.model';
import { BillingSummaryDisplay } from '@core/models/billing/billing-summary-display.model';
import { TokenService } from './token.service';
import { BankNameResponseModel } from '@core/models/billing/bank-name-response.model';
import * as _ from 'lodash';
import { PersonEntity } from '@core/models/entities/person.entity';
import { PayPlanFeesDisplay } from '@core/models/display/quote-coverages/fees/payplan-fees-display.model';
import { SetupAccountExperienceRequest } from '@core/models/billing/setup-account/setup-account-experience-request.model';
import { SetupAccountExperienceResponse } from '@core/models/billing/setup-account/setup-account-experience-response.model';
import { AccountVerifyResponse } from '@core/models/billing/billing-response.model';
import { AccountVerifyRequest } from '@core/models/billing/billing-request.model';
import { GIACTAPI_RESPONSE_CODES } from '@shared/constants/app-constants';
import { DownPaymentExperienceResponse } from '@core/models/billing/down-payment/down-payment-expereience-response.model';
import { DownPaymentExperienceRequest } from '@core/models/billing/down-payment/down-payment-experience-request.model';
import { BillingPaymentPlanModel } from '@core/models/billing/billing.model';
import { Product } from '@core/models/products/product.model';
import { ProductStatus } from '@core/models/products/product-status';
import { ProductsService } from './products.service';

@Injectable()
export class BillingService {
  constructor(
    private _billingDao: BillingDao,
    private _billingAdapter: BillingAdapter,
    private _loggingService: LoggingService,
    private _tokenService: TokenService,
    private _productsService: ProductsService
  ) {}

  dsmDownPaymentExperience(
    request: DownPaymentExperienceRequest
  ): Observable<DownPaymentExperienceResponse> {
    return this._billingAdapter.dsmDownPaymentExperience(request);
  }

  // DSM
  loadDsmDownPayment(): void {
    this._billingDao.loadDsmDownPayment();
  }

  getPciV2Loaded(): Observable<boolean> {
    return this._billingDao.getPciV2Loaded();
  }

  updateBillingName(name: PersonEntity): void {
    this._billingDao.updateBillingName(name);
  }

  updateBillingAddress(address: any): void {
    this._billingDao.updateBillingAddress(address);
  }

  updateUnitNumber(unit: string): void {
    this._billingDao.updateUnitNumber(unit);
  }

  // KEEP
  prepareBankCardPayment(bankCard: any): void {
    this._billingDao.prepareBankCardPayment(bankCard);
  }

  // KEEP
  updateBillingBankCardInfo(event: any): void {
    this._billingDao.updateBillingBankCardInfo(event);
  }

  // KEEP
  updateBillingBankAccountInfo(event: any): void {
    this._billingDao.updateBillingBankAccountInfo(event);
  }

  // DSM
  dsmGetBankName(routingNumber: string): Observable<BankNameResponseModel> {
    return this._tokenService.getAll().pipe(
      take(1),
      switchMap(token => {
        this._loggingService.logToSplunk({
          event: 'GET_BANK_NAME_REQUEST',
          message: token,
        });
        return this._billingAdapter
          .getBankName(routingNumber, token[0].accessToken)
          .pipe(
            tap(response => {
              this._loggingService.logToSplunk({
                event: 'GET_BANK_NAME_REQUEST_SUCCESS',
                message: response,
              });
            }),
            catchError((error: any) => {
              this._loggingService.logToSplunk({
                event: 'GET_BANK_NAME_REQUEST_ERROR',
                message: error,
              });
              return of(null);
            })
          );
      })
    );
  }

  // DSM
  dsmSetupAccount(): void {
    this._billingDao.dsmSetupAccount();
  }

  oneTimePassword(): Observable<OneTimePasswordResponse> {
    return this._tokenService.getAll().pipe(
      take(1),
      map(tokens => tokens[0].accessToken),
      switchMap(token => {
        this._loggingService.logToSplunk({
          event: 'ONE_TIME_PASSWORD_REQUEST',
          message: token,
        });
        return this._billingAdapter.oneTimePassword(token).pipe(
          tap(response => {
            this._loggingService.logToSplunk({
              event: 'ONE_TIME_PASSWORD_RESPONSE_SUCCESS',
              message: response,
            });
          }),
          catchError((error: any) => {
            this._loggingService.logToSplunk({
              event: 'ONE_TIME_PASSWORD_RESPONSE_ERROR',
              message: error,
            });
            return observableThrowError(error);
          })
        );
      })
    );
  }

  // KEEP
  pciV2(request: string): Observable<PciV2Response> {
    this._loggingService.logToSplunk({ event: 'PCI_V2_REQUEST' });

    return this._billingAdapter.pciV2(request).pipe(
      tap(response => {
        this._loggingService.logToSplunk({
          event: 'PCI_V2_RESPONSE_SUCCESS',
          message: response,
        });
      }),
      catchError((error: any) => {
        this._loggingService.logToSplunk({
          event: 'PCI_V2_RESPONSE_ERROR',
          message: error,
        });
        return observableThrowError(error);
      })
    );
  }

  // KEEP
  buildPciV2Request(bankCard: any, token: OneTimePasswordResponse): string {
    // eslint-disable-next-line max-len
    const request = `<?xml version="1.0" encoding="UTF-8"?><PCI><User><token>${token.token}</token></User><card><number>${bankCard.cardNumber}</number><type>${bankCard.cardType}</type></card></PCI>`;

    return request;
  }

  setupAccount(
    request: SetupAccountExperienceRequest
  ): Observable<SetupAccountExperienceResponse> {
    this._loggingService.logToConsole('setupAccount request', request);
    this._loggingService.logToSplunk({
      event: 'SETUP_ACCOUNT_REQUEST',
      message: this.maskSetupAccountBankData(_.cloneDeep(request)),
    });
    return this._billingAdapter.setupAccount(request).pipe(
      tap(response =>
        this._loggingService.logToConsole('setupAccount response', response)
      ),
      catchError(error => {
        this._loggingService.logToSplunk({
          event: 'SETUP_ACCOUNT_RESPONSE_ERROR',
          message: error,
        });
        return observableThrowError(error);
      }),
      take(1)
    );
  }

  resetPciV2Loaded(value: boolean): void {
    this._billingDao.resetPciV2Loaded(value);
  }

  getBillingInfo() {
    return this._billingDao.getBillingInfo();
  }

  getPaymentPlanStatus(): Observable<'NONE' | 'PENDING' | 'LOADED' | 'ERROR'> {
    return this._billingDao.getPaymentPlanStatus();
  }

  getPaymentPlanLoaded(): Observable<boolean> {
    return this._billingDao.getPaymentPlanLoaded();
  }

  getPaymentPlanLoading(): Observable<boolean> {
    return this._billingDao.getPaymentPlanLoading();
  }

  getPaymentPlan(): Observable<ProductPayPlanSearchResponse> {
    return this._billingDao.getPaymentPlan();
  }

  getDownPaymentLoaded(): Observable<boolean> {
    return this._billingDao.getDownPaymentLoaded();
  }

  getDownPaymentLoading(): Observable<boolean> {
    return this._billingDao.getDownPaymentLoading();
  }

  resetDsmDownPaymentLoaded(): void {
    this._billingDao.resetDsmDownPaymentLoaded();
  }

  getAccountSetupResponseData(): Observable<SetupAccountExperienceResponse> {
    return this._billingDao.getAccountSetupResponseData();
  }

  getAccountSetupResponseLoading(): Observable<boolean> {
    return this._billingDao.getAccountSetupResponseLoading();
  }

  getAccountSetupResponseLoaded(): Observable<boolean> {
    return this._billingDao.getAccountSetupResponseLoaded();
  }

  getAccountSetuptResponseError(): Observable<boolean> {
    return this._billingDao.getAccountSetupResponseError();
  }

  navigateForward(url: string) {
    this._billingDao.navigateForward(url);
  }

  navigateBack(url: string) {
    this._billingDao.navigateBack(url);
  }

  logResponse(results) {
    this._loggingService.logToSplunk({
      event: 'SEARCH_PAYMENT_PLAN_RESPONSE_SUCCESS',
      message: results,
    });
  }

  getBillingProducts(): Observable<ProductDisplay[]> {
    return this._billingDao.getBillingProducts();
  }

  getBillingPayPlanFees(): Observable<PayPlanFeesDisplay> {
    return this._billingDao.getBillingPayPlanFees();
  }

  // DSM
  dsmGetBillingSummaryInfo(): Observable<BillingSummaryDisplay[]> {
    return this._billingDao.dsmGetBillingSummaryInfo();
  }

  maskSetupAccountBankData(
    request: SetupAccountExperienceRequest
  ): SetupAccountExperienceRequest {
    if (request.body.payment.paymentTransaction.electronicFunds) {
      request.body.payment.paymentTransaction.electronicFunds.bankAccountNumber =
        '**********';
      request.body.payment.paymentTransaction.electronicFunds.bankRoutingNumber =
        '**********';
    } else if (request.body.payment.paymentTransaction.bankCard) {
      request.body.payment.paymentTransaction.bankCard.cardVerificationValue =
        '***';
    } else if (request.body.payment.paymentTransaction.bankCardWithClearPan) {
      request.body.payment.paymentTransaction.bankCardWithClearPan.cardNumber =
        '****************';
      request.body.payment.paymentTransaction.bankCardWithClearPan.cardVerificationValue =
        '***';
    }
    return request;
  }

  verifyAccount(bankInfo: any): Observable<any> {
    return this._tokenService.getAll().pipe(
      take(1),
      switchMap(token => {
        this._loggingService.logToSplunk({
          event: 'VERIFY_BANK_ACCOUNT_TOKEN_REQUEST',
          message: token,
        });
        this._loggingService.log(
          'verifyGiactAccountAPI request',
          this.maskVerifyAccountBankData(bankInfo)
        );

        return this._billingAdapter
          .verifyGiactAccount(
            this.buildVerifyAccountRequest(bankInfo),
            token[0].accessToken
          )
          .pipe(
            take(1),
            map(response => {
              this._loggingService.log(
                'verifyGiactAccountAPI response',
                response
              );
              return this.getResponseStatus(response);
            }),
            catchError(error => {
              this._loggingService.log('verifyGiactAccountAPI Error', error);
              return observableThrowError(error);
            })
          );
      })
    );
  }

  maskVerifyAccountBankData(bankInfo: any): any {
    const bankInfoCopy = JSON.parse(JSON.stringify(bankInfo));
    if (bankInfoCopy.accountNumber && bankInfoCopy.routingNumber) {
      bankInfoCopy.accountNumber = '**********';
      bankInfoCopy.routingNumber = '**********';
      return bankInfoCopy;
    }
  }

  buildVerifyAccountRequest(request: any): AccountVerifyRequest {
    const accountVerifyRequest = <AccountVerifyRequest>{};
    accountVerifyRequest.RoutingNumber = request.routingNumber;
    accountVerifyRequest.AccountNumber = request.accountNumber;
    return accountVerifyRequest;
  }

  getResponseStatus(accountVerifyResponse: AccountVerifyResponse): {
    ResponseStatus;
  } {
    let responseCode;
    if (accountVerifyResponse && accountVerifyResponse.Verification) {
      responseCode = accountVerifyResponse.Verification.Code;
    }
    if (responseCode) {
      const responseStatus = GIACTAPI_RESPONSE_CODES[responseCode];
      if (responseStatus) {
        this.updateAccountVerifyStatus(accountVerifyResponse);
        return {
          ResponseStatus: responseStatus,
        };
      }
    }
    return {
      ResponseStatus: 'ERROR',
    };
  }

  getAccountVerifyHitCount(): Observable<number> {
    return this._billingDao.getAccountVerifyHitCount();
  }

  getIsInvalidAccountNachaResponseCode(): Observable<boolean> {
    return this._billingDao.getIsInvalidAccountNachaResponseCode();
  }

  updateAccountVerifyStatus(request: any) {
    return this._billingDao.updateAccountVerifyStatus(request);
  }

  loadDsmDownPaymentFullPayForMultiProduct(): void {
    this._billingDao.loadDsmDownPaymentFullPayForMultiProduct();
  }
  dsmSetupAccountFullPayForMultiProduct(): void {
    this._billingDao.dsmSetupAccountFullPayForMultiProduct();
  }

  getAccountsSetupResponseData(): Observable<SetupAccountExperienceResponse[]> {
    return this._billingDao.getAccountsSetupResponseData();
  }

  initializeBillingPaymentPlan(): void {
    this._billingDao.initializeBillingPaymentPlan();
  }

  setInitializePaymentPlanRequired(
    isInitializePaymentPlanRequired: boolean
  ): void {
    this._billingDao.setInitializePaymentPlanRequired(
      isInitializePaymentPlanRequired
    );
  }

  dispatchUpdateBillingPaymentPlan(payload: BillingPaymentPlanModel): void {
    this._billingDao.dispatchUpdateBillingPaymentPlan(payload);
  }

  triggerBindRateCall(payload: boolean): void {
    this._billingDao.triggerBindRateCall(payload);
  }

  isMultiProductFullPayApplied(): Observable<boolean> {
    return this._billingDao.isMultiProductFullPayApplied();
  }

  isMonolinePropertyFullPaySelected(): Observable<boolean> {
    return this._billingDao.isMonolinePropertyFullPaySelected();
  }

  setRebindWhenInvalidOnBillingChange(rebindWhenInvalid: boolean): void {
    this._billingDao.setRebindWhenInvalidOnBillingChange(rebindWhenInvalid);
  }

  setTriggerEditBillingPopup(triggerEditBillingPopup: boolean): void {
    this._billingDao.setTriggerEditBillingPopup(triggerEditBillingPopup);
  }

  getTriggerEditBillingPopup(): Observable<boolean> {
    return this._billingDao.getTriggerEditBillingPopup();
  }

  isRemoveEftOptionRequired(): Observable<boolean> {
    return this._billingDao.isRemoveEftOptionRequired();
  }

  getProductsWithPolicyNumber(): Observable<Product[]> {
    return this._billingDao.getProductsWithPolicyNumber();
  }

  getStatusOfAllProductsWithoutErrors(): Observable<ProductStatus[]> {
    return this._productsService.getStatusOfAllProductsWithoutErrors();
  }

  _75PercentDownPayment(): Observable<boolean> {
    return this._billingDao._75PercentDownPayment();
  }
}
