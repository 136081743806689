import { EligibleDiscountResponse } from '@core/models/auto/modifiers/modifier-response.model';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import {
  AntiTheftDeviceEntity,
  VehicleEntity,
} from '@core/models/entities/vehicle.entity';
import { VehicleRequest } from '@core/models/request/vehicle-request.model';
import { VehicleResponse } from '@core/models/response/vehicle-response.model';
import { ModifierNames } from '@shared/constants/app-constants';

export class ModifiersBuilder {
  static buildEntityFromArrayResponse(
    response: EligibleDiscountResponse[]
  ): EligibleDiscountEntity[] {
    return response.map(modifier =>
      ModifiersBuilder.buildEntityFromResponse(modifier)
    );
  }

  static buildEntityFromResponse(
    response: EligibleDiscountResponse
  ): EligibleDiscountEntity {
    const entity: EligibleDiscountEntity = {
      name: response.name,
      description: response.description,
      selectedOptionValue: response.selectedOptionValue,
      productId: response.productId,
      eligibleDiscountId: response.eligibleDiscountId,
      modelId: response.modelId,
    };
    if (response.qualifyingInformation) {
      entity.qualifyingInformation = response.qualifyingInformation;
    }
    if (response.availableOptions) {
      entity.availableOptions = response.availableOptions;
    }
    return entity;
  }

  static buildEntityForUpdateVehicleDiscount(
    request,
    response,
    vehicleEntity: VehicleEntity,
    discountModifier?: string
  ): EligibleDiscountEntity {
    let discountEntity: EligibleDiscountEntity;
    if (request.eligibleDiscounts?.length > 0 && response.eligibleDiscounts) {
      if (discountModifier === ModifierNames.VEHICLE_DAYTIMERUNNINGLAMPS) {
        discountEntity = this.buildEligibleDiscountUpdateAutoRequest(
          request,
          response,
          vehicleEntity,
          ModifierNames.VEHICLE_DAYTIMERUNNINGLAMPS
        );
        return discountEntity;
      }
      if (discountModifier === ModifierNames.VEHICLE_ANTILOCKBRAKES) {
        discountEntity = this.buildEligibleDiscountUpdateAutoRequest(
          request,
          response,
          vehicleEntity,
          ModifierNames.VEHICLE_ANTILOCKBRAKES
        );
        return discountEntity;
      }
      if (request.antiTheftDevices && response.eligibleDiscounts) {
        const responseAntiTheftDiscount = response.eligibleDiscounts.find(
          respDiscount =>
            respDiscount.eligibleDiscountId === ModifierNames.VEHICLE_ANTITHEFT
        );
        if (
          responseAntiTheftDiscount &&
          request.antiTheftDevices &&
          request.antiTheftDevices.find(device => device !== null)
        ) {
          discountEntity = {
            ...responseAntiTheftDiscount,
            qualifyingInformation: {
              selectedAntiTheft: request.antiTheftDevices
                .filter(device => device.selected)
                .map(device => device.deviceType)
                .concat(
                  responseAntiTheftDiscount.availableOptions
                    .filter(option => option.isMandatory)
                    .map(option => option.optionValue)
                ),
            },
            productId: vehicleEntity.productId,
            vehicleId: vehicleEntity.vehicleId,
          };
          return discountEntity;
        }
      }
      request.eligibleDiscounts.forEach(discount => {
        const responseDiscount = response.eligibleDiscounts.find(
          respDiscount =>
            respDiscount.eligibleDiscountId === discount.eligibleDiscountId
        );
        if (responseDiscount) {
          if (
            responseDiscount.eligibleDiscountId ===
              ModifierNames.VEHICLE_ANTITHEFT &&
            request.antiTheftDevices &&
            request.antiTheftDevices.find(device => device !== null)
          ) {
            discountEntity = {
              ...responseDiscount,
              qualifyingInformation: {
                selectedAntiTheft: request.antiTheftDevices
                  .filter(device => device.selected)
                  .map(device => device.deviceType)
                  .concat(
                    responseDiscount.availableOptions
                      .filter(option => option.isMandatory)
                      .map(option => option.optionValue)
                  ),
              },
              productId: vehicleEntity.productId,
              vehicleId: vehicleEntity.vehicleId,
            };
          } else if (
            responseDiscount.selected &&
            responseDiscount.selected !== discount.selected
          ) {
            if (
              responseDiscount.eligibleDiscountId ===
              ModifierNames.VEHICLE_PASSIVERESTRAINT
            ) {
              if (!discount.selected && !responseDiscount.value) {
                discountEntity = {
                  ...responseDiscount,
                  value: null,
                  selected: discount.selected,
                  productId: vehicleEntity.productId,
                  vehicleId: vehicleEntity.vehicleId,
                };
              } else if (discount.selected && !responseDiscount.value) {
                discountEntity = {
                  ...responseDiscount,
                  value: 'AirBag_Ext',
                  selected: discount.selected,
                  productId: vehicleEntity.productId,
                  vehicleId: vehicleEntity.vehicleId,
                };
              }
            } else {
              discountEntity = {
                ...responseDiscount,
                selected: discount.selected,
                productId: vehicleEntity.productId,
                vehicleId: vehicleEntity.vehicleId,
              };
            }
          }
        }
      });
    } else if (request.antiTheftDevices && response.eligibleDiscounts) {
      const responseAntiTheftDiscount = response.eligibleDiscounts.find(
        respDiscount =>
          respDiscount.eligibleDiscountId === ModifierNames.VEHICLE_ANTITHEFT
      );
      if (
        responseAntiTheftDiscount &&
        request.antiTheftDevices &&
        request.antiTheftDevices.find(device => device !== null)
      ) {
        discountEntity = {
          ...responseAntiTheftDiscount,
          qualifyingInformation: {
            selectedAntiTheft: request.antiTheftDevices
              .filter(device => device.selected)
              .map(device => device.deviceType)
              .concat(
                responseAntiTheftDiscount.availableOptions
                  .filter(option => option.isMandatory)
                  .map(option => option.optionValue)
              ),
          },
          productId: vehicleEntity.productId,
          vehicleId: vehicleEntity.vehicleId,
        };
      }
    }
    return discountEntity;
  }

  static buildEntityForUpdatePowersportsVehicleDiscount(
    request: {
      request: VehicleRequest;
      antiTheftDevices: AntiTheftDeviceEntity[];
      eligibleDiscounts: EligibleDiscountEntity[];
    },
    response: VehicleResponse,
    vehicleEntity: VehicleEntity,
    discountModifier: string
  ): EligibleDiscountEntity {
    let discountEntity: EligibleDiscountEntity;
    if (request.eligibleDiscounts?.length && response.eligibleDiscounts) {
      if (discountModifier === ModifierNames.VEHICLE_ANTILOCKBRAKES) {
        const requestAntiLockBrakesDiscount = request.eligibleDiscounts?.find(
          discount =>
            discount.eligibleDiscountId === ModifierNames.VEHICLE_ANTILOCKBRAKES
        );
        const responseAntiLockBrakesDiscount = response.eligibleDiscounts?.find(
          discount =>
            discount.eligibleDiscountId === ModifierNames.VEHICLE_ANTILOCKBRAKES
        );
        if (
          requestAntiLockBrakesDiscount?.selected &&
          responseAntiLockBrakesDiscount
        ) {
          discountEntity = {
            ...requestAntiLockBrakesDiscount,
            selectedOptionValue: requestAntiLockBrakesDiscount.selected,
            productId: vehicleEntity.productId,
            vehicleId: vehicleEntity.vehicleId,
          };
          return discountEntity;
        }
      }
      request.eligibleDiscounts.forEach(discount => {
        const responseDiscount = response.eligibleDiscounts.find(
          respDiscount =>
            respDiscount.eligibleDiscountId === discount.eligibleDiscountId
        );
        if (responseDiscount) {
          if (
            responseDiscount.eligibleDiscountId ===
              ModifierNames.VEHICLE_ANTITHEFT &&
            request.antiTheftDevices &&
            request.antiTheftDevices.find(device => device !== null)
          ) {
            discountEntity = {
              ...responseDiscount,
              qualifyingInformation: {
                selectedAntiTheft: request.antiTheftDevices
                  .filter(device => device.selected)
                  .map(device => device.deviceType)
                  .concat(
                    responseDiscount.availableOptions
                      .filter(option => option.isMandatory)
                      .map(option => option.optionValue)
                  ),
              },
              productId: vehicleEntity.productId,
              vehicleId: vehicleEntity.vehicleId,
            };
          }
        }
      });
    } else if (
      request.antiTheftDevices &&
      response.eligibleDiscounts &&
      discountModifier === ModifierNames.VEHICLE_ANTITHEFT
    ) {
      const responseAntiTheftDiscount = response.eligibleDiscounts.find(
        respDiscount =>
          respDiscount.eligibleDiscountId === ModifierNames.VEHICLE_ANTITHEFT
      );
      if (
        responseAntiTheftDiscount &&
        request.antiTheftDevices &&
        request.antiTheftDevices.find(device => device !== null)
      ) {
        discountEntity = {
          ...responseAntiTheftDiscount,
          qualifyingInformation: {
            selectedAntiTheft: request.antiTheftDevices
              .filter(device => device.selected)
              .map(device => device.deviceType)
              .concat(
                responseAntiTheftDiscount.availableOptions
                  .filter(option => option.isMandatory)
                  .map(option => option.optionValue)
              ),
          },
          productId: vehicleEntity.productId,
          vehicleId: vehicleEntity.vehicleId,
        };
      }
    }
    return discountEntity;
  }
  static buildEligibleDiscountUpdateAutoRequest(
    request: {
      request: VehicleRequest;
      antiTheftDevices: AntiTheftDeviceEntity[];
      eligibleDiscounts: EligibleDiscountEntity[];
    },
    response: VehicleResponse,
    vehicleEntity: VehicleEntity,
    discountModifier: string
  ) {
    let discountEntity: EligibleDiscountEntity;
    const requestDiscount = request.eligibleDiscounts?.find(
      discount => discount.eligibleDiscountId === discountModifier
    );
    const responseDiscount = response.eligibleDiscounts?.find(
      discount => discount.eligibleDiscountId === discountModifier
    );
    if (
      requestDiscount?.selected &&
      responseDiscount?.selectedOptionValue === 'false'
    ) {
      discountEntity = {
        ...responseDiscount,
        selectedOptionValue: requestDiscount.selected,
        productId: vehicleEntity.productId,
        vehicleId: vehicleEntity.vehicleId,
      };
      return discountEntity;
    }
  }
}
