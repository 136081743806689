import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AddressEntity } from '@core/models/entities/address.entity';

export const enum MailingAddressActionTypes {
  ADD_MAILING_ADDRESS = '[Mailing Address] Add Mailing Address',

  DELETE_MAILING_ADDRESS = '[Mailing Address] Delete Mailing Address',

  UPDATE_MAILING_ADDRESS = '[Mailing Address] Update Mailing Address',
}

export class AddMailingAddress implements Action {
  readonly type = MailingAddressActionTypes.ADD_MAILING_ADDRESS;
  constructor(public payload: AddressEntity) {}
}

export class DeleteMailingAddress implements Action {
  readonly type = MailingAddressActionTypes.DELETE_MAILING_ADDRESS;
  constructor() {}
}

export class UpdateMailingAddress implements Action {
  readonly type = MailingAddressActionTypes.UPDATE_MAILING_ADDRESS;
  constructor(public payload: Update<AddressEntity>) {}
}

export type MailingAddressActions =
  | AddMailingAddress
  | DeleteMailingAddress
  | UpdateMailingAddress;
