import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import * as fromSession from '@core/store/selectors/session.selector';
import * as fromPrivateLabel from '@core/store/selectors/private-label.selector';

import { PartialQuoteRequest } from '@core/models/partial-quote/partial-quote.model';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { StringUtils } from '@shared/utils/string.utils';
import { DriverRelationToPNI } from '@shared/constants/app-constants';

export const getPartialQuoteState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.partialQuote
);

export const buildPartialQuoteRequest = (): any =>
  createSelector(
    fromSession.getSessionId,
    fromUserContext.getUserContext,
    fromPrivateLabel.getPrivateLabelConfiguration,
    (sessionId, userContext, config) => {
      const request = {
        partialQuoteId: userContext.partialQuoteId,
        otp: userContext.otp,
        userContext,
        sessionId,
      } as PartialQuoteRequest;

      return { request, config };
    }
  );

export const buildPartialQuoteEuaRequest = createSelector(
  fromUserContext.getUserContext,
  userContext => ({ guid: userContext.guid, otp: userContext.otp })
);

export const getPartialQuoteId = createSelector(
  getPartialQuoteState,
  state => state.partialQuote && state.partialQuote.partialQuoteId
);

export const getPartialQuoteLoadedOrErrored = createSelector(
  getPartialQuoteState,
  state => state.loaded || state.hasError
);

export const getPartialQuotePolicyHolders = createSelector(
  getPartialQuoteState,
  state => state.policyHolders
);

export const getPartialQuoteDrivers = createSelector(
  getPartialQuoteState,
  state => state.drivers
);

export const getPartialQuoteVehicles = createSelector(
  getPartialQuoteState,
  state => state.vehicles
);

export const getPartialQuoteDriverFrom = (driver: DriverEntity) =>
  createSelector(getPartialQuoteDrivers, partialQuoteDrivers => {
    if (!driver || !partialQuoteDrivers || !partialQuoteDrivers.length) {
      return null;
    }

    return partialQuoteDrivers.find(
      partialQuoteDrv =>
        partialQuoteDrv.person &&
        StringUtils.areEqual(
          partialQuoteDrv.person.firstName,
          driver.person.firstName
        ) &&
        StringUtils.areEqual(
          partialQuoteDrv.person.lastName,
          driver.person.lastName
        ) &&
        StringUtils.areEqual(
          partialQuoteDrv.person.suffix,
          driver.person.suffix
        )
    );
  });

export const getPartialQuotePniDriver = createSelector(
  getPartialQuoteDrivers,
  drivers =>
    drivers.find(
      driver => driver.relationToPrimaryNamedInsured === DriverRelationToPNI.PNI
    )
);

export const getPartialQuoteCoveredLocation = createSelector(
  getPartialQuoteState,
  state => state.coveredLocation
);
