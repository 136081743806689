import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '@core/services';
import { Observable } from 'rxjs';
import { DocumentsResponse } from '@core/models/distributed-documents/documents-response.model';

@Injectable()
export class DistributedDocumentsAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  documents(request: any): Observable<DocumentsResponse> {
    const url = this._appConfig.config.distributedDocumentsUrl;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('Authorization', 'Bearer ' + request.accessToken)
      .set(
        'TargetEnvironment',
        this._appConfig.config.distributedDocumentsTargetEnv
      );

    return this._http.post<DocumentsResponse>(url, request.body, { headers });
  }
}
