<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    SmartRide
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [innerHTML]="helpText"></div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Close click')"
  >
    Close
  </button>
</div>
