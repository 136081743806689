import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhoneNumberDirective } from './phone-number.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PhoneNumberDirective],
  exports: [PhoneNumberDirective],
})
export class PhoneNumberModule {}
