<form [formGroup]="form" class="container" [ngClass]="{ open: open }">
  <div>
    <input
      type="button"
      [value]="open ? 'X' : '\ud83d\udc1e'"
      (click)="onToggle()"
    />
    <span *ngIf="open">Debug</span>
  </div>

  <ng-container *ngIf="open">
    <table>
      <tr>
        <td>
          <label for="debug-billing-env">Billing Env:</label>
        </td>
        <td>
          <select
            class="form-control form-select"
            id="debug-billing-env"
            formControlName="billingEnv"
            [attr.disabled]="disableBillingEnv()"
          >
            <option *ngFor="let env of billingEnvs" [value]="env">
              {{ env }}
            </option>
          </select>
        </td>
      </tr>
      <tr>
        <td>
          <label for="debug-pc-env">PC Env: </label>
        </td>
        <td *ngIf="showPCEnv(); else DisableField">
          <select
            class="form-control form-select"
            id="debug-pc-env"
            formControlName="pcEnv"
            [attr.disabled]="disablePCEnv() ? true : null"
            (change)="onPCEnvChanged($event)"
          >
            <option *ngFor="let pcEnv of targetEnvs" [value]="pcEnv">
              {{ pcEnv }}
            </option>
            <option *ngIf="disablePCEnv()" [value]="plsEnv" [selected]="true">
              {{ plsEnv }}
            </option>
          </select>
        </td>
        <ng-template #DisableField>
          <td>
            <select
              class="form-control form-select"
              id="debug-pc-env"
              formControlName="pcEnv"
              [attr.disabled]="true"
              (change)="onPCEnvChanged($event)"
            >
              <option #DisableField [selected]="true" [value]="">
                disabled
              </option>
            </select>
          </td>
        </ng-template>
      </tr>
      <tr>
        <td>
          <select
            class="form-control form-select"
            id="debug-nwx-test-env"
            formControlName="nwxTestEnv"
            [attr.disabled]="true"
            [attr.hidden]="true"
          >
            <option *ngFor="let nwxTestEnv of nwxEnvs" [value]="nwxTestEnv">
              {{ nwxTestEnv }}
            </option>
          </select>
        </td>
      </tr>
      <tr>
        <td>
          <label for="debug-ignore-risk-filter">Ignore Risk Filter:</label>
        </td>
        <td>
          <input
            type="checkbox"
            id="debug-ignore-risk-filter"
            formControlName="ignoreRiskFilter"
            [checked]="ignoreRiskFilter?.value"
          />
        </td>
      </tr>
      <tr>
        <td>
          <label for="debug-product-disablement"
            >Ignore Product Disablement:</label
          >
        </td>
        <td>
          <input
            type="checkbox"
            id="debug-product-disablement"
            formControlName="ignoreProductDisablement"
            [checked]="ignoreProductDisablement?.value"
          />
        </td>
      </tr>
      <tr>
        <td>
          <label for="debug-ignore-current-carrier-validation"
            >Ignore Current Carrier Validation:</label
          >
        </td>
        <td>
          <input
            type="checkbox"
            id="debug-ignore-current-carrier-validation"
            formControlName="ignoreCurrentCarrierValidation"
            [checked]="ignoreCurrentCarrierValidation?.value"
          />
        </td>
      </tr>
      <tr>
        <td>
          <label for="debug-ignore-auto-uwrules">Ignore Auto UW Rules:</label>
        </td>
        <td>
          <input
            type="checkbox"
            id="debug-ignore-auto-uwrules"
            formControlName="ignoreAutoUWRules"
            [checked]="ignoreAutoUWRules?.value"
          />
        </td>
      </tr>
    </table>
  </ng-container>
</form>
