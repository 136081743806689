import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import * as fromFeature from '@core/store/reducers';
import * as fromAgent from '@core/store/selectors/agency.selector';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import * as fromMetadata from '@core/store/selectors/metadata.selector';
import {
  ProducerInformation,
  PrivateLabel,
  CoverageConfiguration,
} from '@core/models/private-label/private-label.model';
import { ProducerUtils } from '@shared/utils/producer.utils';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { AgencyModel } from '@core/models/agency/agency.model';
import { UserContext } from '@core/models/user-context/user-context.model';
import {
  NationwideContactNumber,
  StateSpecificFlagKeys,
} from '@shared/constants/app-constants';
import { StringUtils } from '@shared/utils/string.utils';

const lookupProducerInformation = (
  config: PrivateLabel,
  producerCode: string,
  state: string
): ProducerInformation => {
  const producerData = _.filter(config.producerInformation, {
    producerCode,
    state: state,
  }) as ProducerInformation[];

  const filterObject = producerData.length
    ? { state: state, producerCode: producerCode }
    : { state: state, isDefault: true };

  return _.find(
    config.producerInformation,
    filterObject
  ) as ProducerInformation;
};

export const getPrivateLabelState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.privateLabel
);

export const getPrivateLabelLoaded = createSelector(
  getPrivateLabelState,
  state => state.loaded
);

export const getPrivateLabelConfiguration = createSelector(
  getPrivateLabelState,
  state => state.privateLabel
);

export const getStates = createSelector(
  getPrivateLabelConfiguration,
  fromAgent.getAgency,
  (config: PrivateLabel, agency: AgencyModel) => {
    if (!config) {
      return null;
    }

    const producerData = _.filter(config.producerInformation, {
      producerCode: ProducerUtils.getProducerCode(agency, true),
    }) as ProducerInformation[];

    if (producerData.length) {
      return _.chain(producerData).map('state').union().value() as string[];
    } else {
      return _.chain(config.producerInformation)
        .filter({ isDefault: true })
        .map('state')
        .union()
        .value() as string[];
    }
  }
);

export const getProducerInformation = createSelector(
  getPrivateLabelConfiguration,
  fromAgent.getAgency,
  (config: PrivateLabel, agency: AgencyModel, props) => {
    if (!config) {
      return null;
    }

    return lookupProducerInformation(
      config,
      ProducerUtils.getProducerCode(agency, true),
      props.state
    );
  }
);

export const getProducerInformationForAgent = createSelector(
  getPrivateLabelConfiguration,
  fromAgent.getAgency,
  (config, agency): ProducerInformation => {
    if (!config || !agency) {
      return null;
    }

    return lookupProducerInformation(
      config,
      ProducerUtils.getProducerCode(agency, true),
      agency.quoteState
    );
  }
);

export const getCoverageConfiguration = createSelector(
  getProducerInformationForAgent,
  (producerInfo: ProducerInformation) => {
    if (!producerInfo) {
      return null;
    }

    return producerInfo.coverages && producerInfo.coverages.length > 0
      ? (producerInfo.coverages as CoverageConfiguration[])
      : null;
  }
);

export const getContactNumber = (page?: string) =>
  createSelector(
    getPrivateLabelConfiguration,
    getProducerInformationForAgent,
    fromUserContext.getUserContext,
    (
      config: PrivateLabel,
      producerInformation: ProducerInformation,
      userContext: UserContext
    ) => {
      if (!!config) {
        return PrivateLabelContentHelper.getContactInformationFor(
          page,
          config,
          producerInformation
        );
      } else if (userContext?.noplaystateAgentPhoneNo) {
        return userContext.noplaystateAgentPhoneNo;
      } else if (userContext?.monopauseAgentPhoneNo) {
        return userContext.monopauseAgentPhoneNo;
      } else if (userContext && userContext.telephoneNumber) {
        return StringUtils.formatTelephoneNumber(userContext.telephoneNumber);
      } else {
        return NationwideContactNumber;
      }
    }
  );

export const getProducerContactNumber = (page?: string) =>
  createSelector(
    getPrivateLabelConfiguration,
    getProducerInformationForAgent,
    (config: PrivateLabel, producerInformation: ProducerInformation) =>
      PrivateLabelContentHelper.getContactInformationFor(
        page,
        config,
        producerInformation
      )
  );

export const getAgencyProducts = createSelector(
  getPrivateLabelConfiguration,
  (config: PrivateLabel): string[] => {
    if (!config) {
      return null;
    }
    return _.chain(config.producerInformation)
      .map('products')
      .flatten()
      .uniqBy('product')
      .map('product')
      .value();
  }
);

export const isPrivateLabelExperience = createSelector(
  getPrivateLabelConfiguration,
  config => !!config && !!config.identifier
);

export const isConnectedCarEnabled = createSelector(
  getPrivateLabelConfiguration,
  fromMetadata.getStateSpecificFlagsObject,
  (config, stateSpecificFlags) =>
    PrivateLabelContentHelper.shouldEnableConnectedCar(config) &&
    !stateSpecificFlags[StateSpecificFlagKeys.srccIneligible]
);
