import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import {
  PreBindDocumentUploadResponse,
  PreBindDocumentEntity,
} from '@core/models/entities/pre-bind-document.entity';

@Injectable({
  providedIn: 'root',
})
export class PreBindDocumentsDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getAll(): Observable<PreBindDocumentEntity[]> {
    return this._store.select(fromSelectors.selectAllPreBindDocuments);
  }

  dispatchUploadPreBindDocuments(): void {
    this._store.dispatch(new fromActions.UploadPreBindDocuments());
  }

  dispatchUploadPowersportsPreBindDocuments(): void {
    this._store.dispatch(new fromActions.UploadPowersportsPreBindDocuments());
  }

  dispatchDocumentUrlEmail(): void {
    this._store.dispatch(new fromActions.ResendDocumentUrlEmail());
  }

  getPreBindDocumentsUploaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getPreBindDocumentsUploaded);
  }

  getPreBindDocumentUploadResponse(): Observable<PreBindDocumentUploadResponse> {
    return this._store.select(fromSelectors.getPreBindDocumentsUploadResponse);
  }

  arePrebindDocumentsAcknowledgementRequired(): Observable<boolean> {
    return this._store.select(
      fromSelectors.arePrebindDocumentsAcknowledgementRequired
    );
  }

  arePrebindDocumentsAcknowledgementRequiredPowersports(): Observable<boolean> {
    return this._store.select(
      fromSelectors.arePrebindDocumentsAcknowledgementRequiredPowersports
    );
  }

  shouldResetAcknowledgementFlags(): Observable<boolean> {
    return this._store.select(fromSelectors.shouldResetAcknowledgementFlags);
  }

  isPreBindDocumentEmailSent(): Observable<boolean> {
    return this._store.select(fromSelectors.isPreBindDocumentEmailSent);
  }

  dispatchGetPowersportsPreBindDocuments(): void {
    this._store.dispatch(new fromActions.GetPowersportsPreBindDocuments());
  }

  getPowersportsPreBindDocumentsUploadResponse(): Observable<PreBindDocumentUploadResponse> {
    return this._store.select(
      fromSelectors.getPowersportsPreBindDocumentsUploadResponse
    );
  }
}
