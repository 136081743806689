import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store';
import {
  CoveredLocation,
  CoveredLocationUpdateStatus,
} from '@core/store/reducers/covered-location.reducer';
import {
  UpdateCoveredLocation,
  AddCoveredLocation,
  ResetCoveredLocationLoaded,
  GetCoveredLocationSuccess,
} from '@core/store/actions/covered-location.action';
import {
  getCoveredLocationData,
  getCoveredLocationLoaded,
  getRequestIfNumberOfOccupantsChanged,
  getCoveredLocationUpdating,
  getCoveredLocationUpdateStatus,
  getCoveredLocationReconstructionCost,
} from '@core/store/selectors/covered-location.selector';
import * as fromActions from '@core/store/actions';

@Injectable({
  providedIn: 'root',
})
export class CoveredLocationDao {
  constructor(private _store: Store<AppState>) {}

  addCoveredLocation(coveredLocation: CoveredLocation): void {
    this._store.dispatch(new AddCoveredLocation(coveredLocation));
  }

  updateCoveredLocation(
    productId: string,
    coveredLocation: CoveredLocation
  ): void {
    this._store.dispatch(new UpdateCoveredLocation(productId, coveredLocation));
  }

  addCleanCoveredLocation(coveredLocation: CoveredLocation): void {
    this._store.dispatch(new GetCoveredLocationSuccess(coveredLocation));
  }

  getCoveredLocation(): Observable<CoveredLocation> {
    return this._store.select(getCoveredLocationData);
  }

  resetCoveredLocationLoaded(): void {
    this._store.dispatch(new ResetCoveredLocationLoaded());
  }

  getCoveredLocationLoaded(): Observable<boolean> {
    return this._store.select(getCoveredLocationLoaded);
  }

  getCoveredLocationUpdating(): Observable<boolean> {
    return this._store.select(getCoveredLocationUpdating);
  }

  getCoveredLocationUpdateStatus(): Observable<CoveredLocationUpdateStatus> {
    return this._store.select(getCoveredLocationUpdateStatus);
  }

  getMSBHitCoveredLocation(productId: string): void {
    this._store.dispatch(new fromActions.GetCoveredLocation(productId));
  }

  removeCoveredLocation(): void {
    this._store.dispatch(new fromActions.RemoveCoveredLocation());
  }

  getRequestIfNumberOfOccupantsChanged(): Observable<CoveredLocation | null> {
    return this._store.select(getRequestIfNumberOfOccupantsChanged);
  }

  getReconstructionCost(): Observable<number> {
    return this._store.select(getCoveredLocationReconstructionCost);
  }
}
