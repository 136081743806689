import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { tap, catchError, take, switchMap, map } from 'rxjs/operators';

import { AddressAdapter } from '@core/adapters/address.adapter';
import { AddressDao } from '@core/dao/address.dao';
import { LoggingService } from '@core/services/logging.service';

import { StandardizedAddresses } from '@core/models/address/standardized-address.model';
import { Suggestion } from '@core/models/address/suggestions.model';
import { SessionService } from './session.service';

@Injectable()
export class AddressService {
  constructor(
    private _addressDao: AddressDao,
    private _addressAdapter: AddressAdapter,
    private _loggingService: LoggingService,
    private _sessionService: SessionService
  ) {}

  loadStandardizedAddresses(street: string, unitNumber: string): void {
    this._addressDao.loadStandardizedAddresses(street, unitNumber);
  }

  getStandardizedAddresses(): Observable<StandardizedAddresses> {
    return this._addressDao.getStandardizedAddresses();
  }

  getStandardizedAddressesLoading(): Observable<boolean> {
    return this._addressDao.getStandardizedAddressesLoading();
  }

  getStandardizedAddressesLoaded(): Observable<boolean> {
    return this._addressDao.getStandardizedAddressesLoaded();
  }

  resetAddressLoaded() {
    this._addressDao.resetAddressLoaded();
  }

  updateUnitNumber(address) {
    this._addressDao.updateUnitNumber(address);
  }

  loadStandardizedAddress(
    street: string,
    unitNumber: string
  ): Observable<StandardizedAddresses> {
    if (unitNumber) {
      const unittedAddress = street.replace(/,/, ` ${unitNumber},`);
      street = unittedAddress;
    }

    return this._addressAdapter.loadStandardizedAddress(street).pipe(
      tap(response =>
        this._loggingService.logToSplunk({
          event: 'STANDARDIZED_ADDRESS_RESPONSE_SUCCESS',
          message: response,
        })
      ),
      map(response => this._adjustStandardizedLine2(response)),
      catchError(error => {
        this._loggingService.logToSplunk({
          event: 'STANDARDIZED_ADDRESS_RESPONSE_ERROR',
          message: error,
        });

        return observableThrowError(error);
      })
    );
  }

  _adjustStandardizedLine2(response) {
    if (
      response &&
      response.standardizedAddresses &&
      response.standardizedAddresses.length >= 1
    ) {
      const address = response.standardizedAddresses[0];
      const standardLine2 = `${address.unitType} ${address.unitNumber}`;
      if (address.street.endsWith(standardLine2)) {
        address.street = address.street
          .substr(0, address.street.length - standardLine2.length)
          .trim();
      }
    }
    return response;
  }

  suggestAddress(text: string): Observable<Suggestion[]> {
    return this._addressAdapter.suggestAddress(text);
  }

  suggestAddresswithState(text: string): Observable<Suggestion[]> {
    return this._sessionService.getQuoteState().pipe(
      take(1),
      switchMap(state =>
        this._addressAdapter.suggestAddresswithState(text, state)
      )
    );
  }
}
