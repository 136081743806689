import { Injectable } from '@angular/core';
import { PersonViewModel } from '@core/models/entities/driver.entity';
import { Observable } from 'rxjs';
import { PeopleDao } from '@core/dao/people.dao';
import { DsmResetEventService } from './reset.service';

@Injectable({
  providedIn: 'root',
})
export class PeopleService implements DsmResetEventService {
  constructor(private _peopleDao: PeopleDao) {}

  getAll(): Observable<PersonViewModel[]> {
    return this._peopleDao.getAll();
  }

  isMaritalStatusValid(): Observable<boolean> {
    return this._peopleDao.isMaritalStatusValid();
  }

  areMarriedAndSeparatedEvenlyDispersed(): Observable<boolean> {
    return this._peopleDao.areMarriedAndSeparatedEvenlyDispersed();
  }
}
