import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CurrentCarrierService } from '@core/services/current-carrier.service';
import { ProductsService } from '@core/services';
import { take, switchMap, tap, filter } from 'rxjs/operators';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class AutoCurrentCarrierGuard implements CanActivate {
  constructor(
    private _currentCarrierService: CurrentCarrierService,
    private _productsService: ProductsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._productsService
      .isProductSelectedWithoutError(ProductTypes.AUTO)
      .pipe(
        take(1),
        switchMap(isAutoSelected => {
          if (isAutoSelected) {
            return this.checkProductLoaded().pipe(
              switchMap(isloaded => this.checkStore())
            );
          }
          return of(true);
        })
      );
  }

  checkProductLoaded(): Observable<boolean> {
    return this._productsService.getQuoteLoaded(ProductTypes.AUTO).pipe(
      filter(loaded => loaded),
      take(1)
    );
  }

  checkStore(): Observable<boolean> {
    return this._currentCarrierService.getCurrentCarrierLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._currentCarrierService.loadCurrentCarrier();
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
