import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { from } from 'rxjs';
import {
  map,
  catchError,
  withLatestFrom,
  filter,
  exhaustMap,
  tap,
} from 'rxjs/operators';

import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';

import {
  PrivateLabelService,
  LoggingService,
  UserContextService,
} from '@core/services';
import { LoadPrivateLabelConfiguration } from '@core/store/actions';
import { Store } from '@ngrx/store';

@Injectable()
export class PrivateLabelEffect {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _privateLabelService: PrivateLabelService,
    private _loggingService: LoggingService,
    private _userContextService: UserContextService
  ) {}

  @Effect()
  loadPrivateLabelConfiguration$ = this._actions$.pipe(
    ofType<LoadPrivateLabelConfiguration>(
      fromActions.LOAD_PRIVATE_LABEL_CONFIGURATION
    ),
    map((action: LoadPrivateLabelConfiguration) => action.payload),
    withLatestFrom(
      this._privateLabelService.getPrivateLabelConfiguration(),
      this._userContextService.isPrivateLabelPreview()
    ),
    filter(([_, config, isPreview]) => !config),
    exhaustMap(([identifier, _, isPreview]) =>
      this._privateLabelService
        .establishPrivateLabelConfiguration(identifier, isPreview)
        .pipe(
          tap(response => {
            if (identifier && !response) {
              this._loggingService.log(
                'Private Label Invalid Path',
                identifier
              );
            }
          }),
          map(response => {
            if (response) {
              this._store.dispatch(
                new fromActions.LoadConnectedCarCriteria(response.identifier)
              );
            }

            return new fromActions.LoadPrivateLabelConfigurationSuccess(
              response
            );
          }),
          catchError(error => {
            this._loggingService.log('privateLabelContentful Error', error);
            return from([
              new fromActions.LoadPrivateLabelConfigurationFail(
                error ? error.message : ''
              ),
              new fromActions.Softfall(
                fromActions.LOAD_PRIVATE_LABEL_CONFIGURATION_FAIL
              ),
            ]);
          })
        )
    )
  );
}
