import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsInternetAgentDirective } from './is-internet-agent.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsInternetAgentDirective],
  exports: [IsInternetAgentDirective],
})
export class IsInternetAgentModule {}
