import {
  NatGenControl,
  NatGenCustomer,
  NatGenQuote,
  NatGenDriver,
  NatGenVehicle,
  NatGenAccidentOrViolation,
  NatGenVehicleCoverage,
} from '@core/models/non-standard/non-standard.model';
import { UUID } from 'angular2-uuid';
import {
  NatGenValues,
  NatGenGenderCodes,
  NatGenRelationshipToPNI,
  NatGenCoverageIds,
  NatGenAntiTheftDevices,
  NatGenAddtionalInterests,
  NonStandardErrorCodes,
} from '@shared/constants/non-standard-constants';
import {
  MaritalStatusCodes,
  GoodDriverDisplayByKey,
} from '@shared/constants/app-constants';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { CoverageEntity } from '@core/models/entities/coverage.entity';
import { DriverIncidentEntity } from '@core/models/entities/driver-incident.entity';
import { VehicleResponse } from '@core/models/response/vehicle-response.model';
import { AdditionalInterestEntity } from '@core/models/entities/additional-interest.entity';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

export class NonStandardUtils {
  static setControl(control: NatGenControl): NatGenControl {
    return {
      messageID: UUID.UUID(),
      transactionEffectiveDate: control.transactionEffectiveDate,
      stateID: control.stateID,
      agentID: NatGenValues.control.agentID,
      channelSource: NatGenValues.control.channelSource,
    };
  }

  static setCustomer(customer: NatGenCustomer): NatGenCustomer {
    return {
      name: customer.name,
      person: {
        dateOfBirth: customer.person.dateOfBirth,
        gender: NatGenGenderCodes[customer.person.gender],
        roleOnPolicy: [NatGenValues.customer.roleOnPolicy],
      },
      address: customer.address,
      contactPoints: customer.contactPoints,
    };
  }

  static setQuote(quote: NatGenQuote): NatGenQuote {
    return {
      quoteNumber: quote.quoteNumber,
      lineOfBusiness: NatGenValues.quote.lineOfBusiness,
      subLineOfBusiness: NatGenValues.quote.subLineOfBusiness,
      rateState: quote.rateState,
      effectiveDate: quote.effectiveDate,
      priorCarrier: quote.priorCarrier,
      term: NatGenValues.quote.term,
      maintainedContinousCoverage: this.setCoverageLapse(
        quote.maintainedContinousCoverage
      ),
    } as NatGenQuote;
  }

  static setDriver(drivers: DriverEntity[]): NatGenDriver[] {
    const returnDrivers = [];
    (drivers || []).forEach((driver, index) => {
      returnDrivers.push({
        driverID: (index + 1).toString(),
        id: driver.driverId,
        name: {
          firstName: driver.person.firstName,
          lastName: driver.person.lastName,
        },
        person: {
          gender: NatGenGenderCodes[driver.person.gender],
          dateOfBirth: driver.person.dateOfBirth,
          maritalStatus: MaritalStatusCodes[driver.person.maritalStatus],
          driverType: driver.driverType,
          defensiveDriver: driver.defensiveDrivingCourse,
          goodStudent: driver.goodStudent,
          relationshipToApplicant: this.setRelationshipToPNI(
            driver.relationToPrimaryNamedInsured
          ),
        },
        license: {
          driversLicenseNumber: driver.licenseNumber,
          driversLicenseState: driver.licenseState,
          driversLicenseStatus: NatGenValues.driver.driversLicenseStatus,
        },
        accidentsOrViolations: this.setAccidents(driver.infractions),
      });
    });
    return returnDrivers;
  }

  static setVehicle(
    vehicles: VehicleResponse[],
    coverages: CoverageEntity[],
    stateSpecificFlagKeys: StateSpecificFlagsObject
  ): NatGenVehicle[] {
    const returnVehicles = [];
    let vehicleCoverages = [];

    (vehicles || []).forEach((vehicle, index) => {
      if (!stateSpecificFlagKeys.nonStandardBridgeCoveragesNotAllowed) {
        vehicleCoverages = this.setVehicleCoverages(
          vehicle.vehicleId,
          coverages,
          stateSpecificFlagKeys
        );
      }

      returnVehicles.push({
        vehicleID: (index + 1).toString(),
        id: vehicle.vehicleId,
        make: vehicle.make,
        model: vehicle.model,
        year: vehicle.year as string,
        vin: vehicle.vin || vehicle.vehicleIdentificationPattern,
        type: NatGenValues.vehicle.type,
        usage: this.setUsage(vehicle.primaryUse) || NatGenValues.vehicle.usage,
        antiTheftDevice: this.setAntiTheftDevice(vehicle.antiTheftDevice),
        estimatedAnnualMiles: vehicle.annualMiles || 'null',
        additionalInterest: this.setAdditionalInterests(
          vehicle.additionalInterests[0]
        ),
        garagingAddress: vehicle.garageLocation,
        coverage: vehicleCoverages,
      });
    });
    return returnVehicles;
  }

  private static setRelationshipToPNI(relationship): string {
    return NatGenRelationshipToPNI[relationship];
  }

  private static setAdditionalInterests(
    additionalInterest: AdditionalInterestEntity
  ) {
    return additionalInterest === undefined
      ? []
      : [
          {
            additionalInterest:
              NatGenAddtionalInterests[
                additionalInterest.additionalInterestType
              ],
          },
        ];
  }

  private static setAccidents(
    infractions: DriverIncidentEntity[]
  ): NatGenAccidentOrViolation[] {
    if (
      infractions &&
      infractions.length === 1 &&
      (infractions[0].infractionDesc === GoodDriverDisplayByKey.Needswork ||
        infractions[0].infractionDesc === GoodDriverDisplayByKey.Prettygood)
    ) {
      return [];
    } else {
      return (infractions || []).map(infraction => {
        return {
          accidentViolationCode: infraction.infractionDesc,
          accidentViolationDate: infraction.occurrenceDate,
          nationwideApplication: NatGenValues.driver.nationwideApplication,
        };
      });
    }
  }

  static setUsage(primaryUse: string): string {
    return primaryUse;
  }

  static setAntiTheftDevice(antiTheftDevices: string): string {
    if (antiTheftDevices === null) {
      return 'None';
    }
    return NatGenAntiTheftDevices[antiTheftDevices];
  }

  static setVehicleCoverages(
    vehicleId: string,
    coverages: CoverageEntity[],
    stateSpecificFlagKeys: StateSpecificFlagsObject
  ): NatGenVehicleCoverage[] {
    const returnVehicleCoverages: NatGenVehicleCoverage[] = [];

    coverages.forEach(coverage => {
      if (
        NatGenCoverageIds[coverage.coverageId] &&
        coverage.selectedValue &&
        coverage.selectedValue.length >= 1 &&
        coverage.coverableId === vehicleId
      ) {
        if (
          coverage.selectedValue[0] &&
          coverage.selectedValue[0].value.includes('/')
        ) {
          returnVehicleCoverages.push({
            code: NatGenCoverageIds[coverage.coverageId],
            limit1: coverage.selectedValue[0].value.split('/')[0],
            limit1AppliesTo: NatGenValues.vehicle.coverage.limit1AppliesTo,
            limit2: coverage.selectedValue[0].value.split('/')[1],
            limit2AppliesTo: NatGenValues.vehicle.coverage.limit2AppliesTo,
          });
        } else if (
          coverage.selectedValue[0] &&
          coverage.selectedValue[0].code.search('Deductible') !== -1
        ) {
          returnVehicleCoverages.push({
            code: NatGenCoverageIds[coverage.coverageId],
            deductible: coverage.selectedValue[0].value,
          });
        } else if (
          coverage.selectedValue[0] &&
          coverage.selectedValue[0].code.search('Limit') !== -1
        ) {
          returnVehicleCoverages.push({
            code: NatGenCoverageIds[coverage.coverageId],
            limit1: coverage.selectedValue[0].value,
          });
        }
      }
    });

    return returnVehicleCoverages;
  }

  static setCoverageLapse(maintainedContinousCoverage: string): string {
    return maintainedContinousCoverage === '0Days' ? 'yes' : 'no';
  }

  static isNatGenError(errorCode: string): boolean {
    return NonStandardErrorCodes.includes(errorCode);
  }
}
