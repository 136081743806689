import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { SubmissionDetailsModel } from '@core/models/retrieve/retrieve.model';
import { DisplayPanelService } from '@shared/components/display-panel/display-panel.service';
import { ProductTypes } from '@shared/constants/app-constants';
import { Subject } from 'rxjs';

@Component({
  selector: 'mq-submission-details-form',
  templateUrl: './submission-details-form.component.html',
  styleUrls: ['./submission-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmissionDetailsFormComponent implements OnInit {
  @Input() id: number;
  @Input() submissionDetails: SubmissionDetailsModel[];
  @Input() quoteDetails: SubmissionDetailsModel;
  @Input() newSubmissionDetails: boolean;

  @Output()
  submissionDetailsSubmit = new EventEmitter<SubmissionDetailsModel>();
  @Output()
  multipleSubmissionDetailsMatch = new EventEmitter<boolean>();
  submissionIdMissing = new EventEmitter<boolean>();
  productTypeMissing = new EventEmitter<boolean>();

  form: UntypedFormGroup;
  fieldId: number;
  formWasSubmitted$ = new Subject<boolean>();
  catchedSubmissionDetails: SubmissionDetailsModel;
  products = [
    { description: 'Auto', value: ProductTypes.AUTO },
    { description: 'Homeowners', value: ProductTypes.HOMEOWNERS },
    { description: 'Renters', value: ProductTypes.RENTERS },
    { description: 'Condo', value: ProductTypes.CONDO },
    { description: 'Powersports', value: ProductTypes.POWERSPORTS },
  ];
  isSubmissionIdMissing: boolean;
  isProductTypeMissing: boolean;

  constructor(
    private _fb: UntypedFormBuilder,
    private _displayPanelService: DisplayPanelService
  ) {}

  ngOnInit(): void {
    this.fieldId = this.quoteDetails?.id || 0;

    if (this.quoteDetails) {
      this.catchedSubmissionDetails = this.quoteDetails;
    }

    this.form = this._fb.group({
      submissionId: this._fb.control('', [Validators.required]),
      productType: this._fb.control('', [Validators.required]),
    });
    this.updateRequiredFields();
    this._patchSubmissionDetails();
  }

  onCancel(): void {
    if (this.catchedSubmissionDetails) {
      this.form.patchValue({
        ...this.catchedSubmissionDetails,
      });
    } else {
      this.form.reset();
    }
    this.updateRequiredFields();
    this._displayPanelService.next(false);
    this.formWasSubmitted$.next(false);
  }

  onSubmit(form: UntypedFormGroup): void {
    this.updateRequiredFields();
    this.formWasSubmitted$.next(true);

    const { valid, value } = form;
    this._displayPanelService.fvNext(valid);
    if (
      this.form.valid ||
      (!this.isSubmissionIdMissing && !this.isProductTypeMissing)
    ) {
      if (!this.quoteDetails?.id) {
        value.eventType = 'create';
        value.id =
          this.submissionDetails.length > 0
            ? this.submissionDetails[this.submissionDetails.length - 1].id + 1
            : 1;
      } else {
        value.eventType = 'update';
        value.id = this.quoteDetails.id;
      }
      this.submissionDetailsSubmit.emit(value);
      if (this.newSubmissionDetails) {
        this.form.reset();
        this.form.patchValue({
          submissionId: '',
          productType: '',
        });
      }
      this._displayPanelService.next(false);
      this.formWasSubmitted$.next(false);
    } else {
      this.submissionId.markAsTouched();
      this.productType.markAsTouched();
    }
  }

  private _patchSubmissionDetails(): void {
    this.form.patchValue({
      submissionId: this.quoteDetails?.submissionId
        ? this.quoteDetails.submissionId
        : '',
      productType: this.quoteDetails?.productType
        ? this.quoteDetails.productType
        : '',
    });
  }

  updateRequiredFields(): void {
    let duplicateInfo: boolean;
    let missingSubmissionId: boolean;
    let missingProductType: boolean;
    if (this.submissionId.value == null || this.submissionId.value == '') {
      this.submissionId.setErrors({ required: true });
      this.isSubmissionIdMissing = true;
      missingSubmissionId = true;
    } else if (
      this.productType.value == undefined ||
      this.productType.value == ''
    ) {
      this.productType.setErrors({ required: true });
      this.isProductTypeMissing = true;
      missingProductType = true;
    } else if (
      this.submissionDetails?.find(
        details =>
          details.submissionId === this.form.get('submissionId').value &&
          details.id !== this.quoteDetails?.id
      )
    ) {
      this.submissionId.setErrors({ hasError: true });
      duplicateInfo = true;
    } else if (
      this.submissionDetails?.find(
        details =>
          (details.productType === this.form.get('productType').value &&
            details.id !== this.quoteDetails?.id) ||
          ((details.productType === ProductTypes.HOMEOWNERS ||
            details.productType === ProductTypes.RENTERS ||
            details.productType === ProductTypes.CONDO) &&
            (this.form.get('productType').value === ProductTypes.HOMEOWNERS ||
              this.form.get('productType').value === ProductTypes.RENTERS ||
              this.form.get('productType').value === ProductTypes.CONDO))
      )
    ) {
      this.productType.setErrors({ hasError: true });
      duplicateInfo = true;
    } else {
      this.submissionId.setErrors(null);
      this.productType.setErrors(null);
      duplicateInfo = false;
      missingSubmissionId = false;
      missingProductType = false;
      this.isSubmissionIdMissing = false;
    }
    this.multipleSubmissionDetailsMatch.emit(duplicateInfo);
    this.submissionIdMissing.emit(missingSubmissionId);
    this.productTypeMissing.emit(missingProductType);
  }

  get submissionId(): AbstractControl {
    return this.form.get('submissionId');
  }

  get productType(): AbstractControl {
    return this.form.get('productType');
  }
}
