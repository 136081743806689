import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[requiredIf]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequiredIfDirective,
      multi: true,
    },
  ],
})
export class RequiredIfDirective implements Validator {
  @Input()
  requiredIf: boolean;

  constructor() {}

  validate(control: AbstractControl) {
    const { value } = control;

    if (
      (value === null || value === undefined || value === '') &&
      this.requiredIf
    ) {
      return {
        required: { condition: this.requiredIf },
      };
    }

    return null;
  }
}
