<div class="modal-header umbrella-header">
  <div class="modal-title" id="modal-basic-title">Add umbrella insurance</div>
  <button type="button" class="close" aria-label="Close" (click)="modal.close()">
    <img src="assets/images/close-x-white.svg" alt="Close" width="18" height="20" />
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row bottom-spacer">
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-3 umbrella-img">
        <img src="assets/images/Umbrella-blue.svg" alt="Umbrella" class="device-img-fluid" />
      </div>
      <div class="col-lg col-md col-sm col-xs umbrella-body-text vertical-align umbrella-img-descr">
        <h3>About umbrella insurance</h3>
      </div>
    </div>
    <div class="row bottom-spacer">
      <p>
        A personal umbrella insurance policy offers an extra layer of liability
        protection for your wages, house, investments, cars and boats if you're
        in a serious auto accident or there's an accident on your property.
      </p>
    </div>
    <div class="row bottom-spacer">
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-3 umbrella-img">
        <img src="assets/images/home-auto.svg" alt="Umbrella" class="device-img-fluid" />
      </div>
      <div class="col-lg col-md col-sm col-xs umbrella-body-text vertical-align umbrella-img-descr">
        <h3>How it works</h3>
      </div>
    </div>

    <div class="row bottom-spacer">
      <p>
        Extends the bodily injury and personal damage liability limits of the
        home and auto policies.
        <br />
        <a [href]="url" target="_blank">Learn more.</a>
      </p>
    </div>
    <div class="row bottom-spacer">
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-3 umbrella-img">
        <img src="assets/images/dollar-ribbon.svg" alt="Umbrella" class="device-img-fluid" />
      </div>
      <div class="col-lg col-md col-sm col-xs umbrella-body-text vertical-align umbrella-img-descr">
        <h3>Adding it is simple</h3>
      </div>
    </div>
    <div class="row bottom-spacer">
      <p>
        Click add umbrella policy below and select your coverage extension
        amounts.
      </p>
      <p>
        By adding Umbrella, your Auto and Property policy coverages will be
        automatically adjusted to align with Umbrella qualifications. This may
        result in a premium increase.
      </p>
    </div>

    <form [formGroup]="form" class="row bottom-spacer dob-input" *ngIf="form.get('datesOfBirth')?.controls.length > 0">
      <div class="please-input">
        Please re-enter the date of birth for the following people:
      </div>
      <div class="row" *ngFor="let control of form.get('datesOfBirth').controls">
        <div class="key">{{ control.get('name').value }}</div>
        <div class="value input-group">
          <input type="text" class="form-control" mask="00/00/0000" [dropSpecialCharacters]="false"
            placeholder="MM/DD/YYYY" [formControl]="control.get('dateOfBirth')" />
          <div class="input-group-text"></div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-outline-primary" (click)="modal.close(false)">
    Maybe later
  </button>
  <button type="button" class="btn btn-primary" (click)="submit()">
    Add Umbrella policy
  </button>
</div>
