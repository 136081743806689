import { Action } from '@ngrx/store';
import { SearchModel } from '@core/models/search/search.model';
import { SearchEntity } from '@core/models/entities/search.entity';

export enum SearchActionTypes {
  SEARCH_QUOTES = '[Search] Search Quotes',
  SEARCH_QUOTES_SUCCESS = '[Search] Search Quotes Success',
  SEARCH_QUOTES_ERROR = '[Search] Search Quotes Error',
  SEARCH_QUOTES_CLEAR_ERROR = '[Search] Search Quotes Clear Error',
}

export class SearchQuotes implements Action {
  readonly type = SearchActionTypes.SEARCH_QUOTES;
  constructor(public payload: SearchModel) {}
}

export class SearchQuotesSuccess implements Action {
  readonly type = SearchActionTypes.SEARCH_QUOTES_SUCCESS;
  constructor(public payload: SearchEntity[]) {}
}

export class SearchQuotesError implements Action {
  readonly type = SearchActionTypes.SEARCH_QUOTES_ERROR;
  constructor(public error: string) {}
}

export class SearchQuotesClearError implements Action {
  readonly type = SearchActionTypes.SEARCH_QUOTES_CLEAR_ERROR;
  constructor() {}
}

export type SearchActions =
  | SearchQuotes
  | SearchQuotesSuccess
  | SearchQuotesError
  | SearchQuotesClearError;
