import { Action } from '@ngrx/store';
import { RentersModel } from '@core/models/renters/renters.model';

export const LOAD_RENTERS_SPE_UNITS = '[Core] Load Renters Seperate Units';
export const LOAD_RENTERS_SPE_UNITS_FAIL =
  '[Core] Load Renters Seperate Units Fail';
export const LOAD_RENTERS_SPE_UNITS_SUCCESS =
  '[Core]Load Renters Seperate Units Success';
export const LOAD_RENTERS_STR_MATERIALS =
  '[Core] Load Renters Structural Materials';
export const LOAD_RENTERS_STR_MATERIALS_FAIL =
  '[Core] Load Renters Structural Materials Fail';
export const LOAD_RENTERS_STR_MATERIALS_SUCCESS =
  '[Core]Load Renters Structural Materials Success';
export const LOAD_RENTERS = '[Core] Load Renters';
export const LOAD_RENTERS_FAIL = '[Core] Load Renters Fail';
export const LOAD_RENTERS_SUCCESS = '[Core] Load Renters Success';
export const UPDATE_RENTERS = '[Core] Update Renters';

export class LoadRentersSeparateUnits implements Action {
  readonly type = LOAD_RENTERS_SPE_UNITS;
}

export class LoadRentersSeparateUnitsFail implements Action {
  readonly type = LOAD_RENTERS_SPE_UNITS_FAIL;
  constructor(public payload: any) {}
}

export class LoadRentersSeparateUnitsSuccess implements Action {
  readonly type = LOAD_RENTERS_SPE_UNITS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadRentersStructuralMaterials implements Action {
  readonly type = LOAD_RENTERS_STR_MATERIALS;
}

export class LoadRentersStructuralMaterialsFail implements Action {
  readonly type = LOAD_RENTERS_STR_MATERIALS_FAIL;
  constructor(public payload: any) {}
}

export class LoadRentersStructuralMaterialsSuccess implements Action {
  readonly type = LOAD_RENTERS_STR_MATERIALS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadRenters implements Action {
  readonly type = LOAD_RENTERS;
}

export class LoadRentersFail implements Action {
  readonly type = LOAD_RENTERS_FAIL;
  constructor(public payload: any) {}
}

export class LoadRentersSuccess implements Action {
  readonly type = LOAD_RENTERS_SUCCESS;
  constructor(public payload: RentersModel) {}
}

export class UpdateRenters implements Action {
  readonly type = UPDATE_RENTERS;
  constructor(public payload: RentersModel) {}
}

export type RentersActions =
  | LoadRentersSeparateUnits
  | LoadRentersSeparateUnitsFail
  | LoadRentersSeparateUnitsSuccess
  | LoadRentersStructuralMaterials
  | LoadRentersStructuralMaterialsFail
  | LoadRentersStructuralMaterialsSuccess
  | LoadRenters
  | LoadRentersFail
  | LoadRentersSuccess
  | UpdateRenters;
