import { Action } from '@ngrx/store';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';

export const enum CoverageErrorActionTypes {
  ADD_COVERAGE_ERRORS = '[Coverage Error] add coverage errors',
  REMOVE_ALL_COVERAGE_ERRORS = '[Coverage Error] remove all coverage errors',
}

export class AddCoverageErrors implements Action {
  readonly type = CoverageErrorActionTypes.ADD_COVERAGE_ERRORS;
  constructor(public errors: CoverageError[]) {}
}

export class RemoveAllCoverageErrors implements Action {
  readonly type = CoverageErrorActionTypes.REMOVE_ALL_COVERAGE_ERRORS;
}

export type CoverageErrorActions = AddCoverageErrors | RemoveAllCoverageErrors;
