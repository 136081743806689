import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { DsmResetEventService } from '@core/services/reset.service';

@Injectable()
export class DsmResetEventGuard implements CanActivate {
  constructor(
    private _service: DsmResetEventService,
    private _service2: DsmResetEventService
  ) {}

  canActivate(): Observable<boolean> {
    this.dsmUpdateStore();

    return of(true);
  }

  dsmUpdateStore() {
    if (this._service !== undefined) {
      this._service
        .getAll()
        .pipe(take(1))
        .subscribe(item => {
          if (item && item.length > 0) {
            item.forEach(item => {
              this._service.updateEvent({ ...item, eventType: 'create' });
            });
          }
        });
    }

    if (this._service2 !== undefined) {
      this._service2
        .getAll()
        .pipe(take(1))
        .subscribe(item => {
          if (item && item.length > 0) {
            item.forEach(item => {
              this._service2.updateEvent({ ...item, eventType: 'create' });
            });
          }
        });
    }
  }
}
