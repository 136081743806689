import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Product } from '@core/models/products/product.model';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';
import { CoverageGrouping } from '../edit-coverage-table/coverage-grouping.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
@Component({
  selector: 'mq-edit-homeowners-coverages',
  templateUrl: './edit-homeowners-coverages.component.html',
  styleUrls: ['./edit-homeowners-coverages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditHomeownersCoveragesComponent {
  @Input() form: UntypedFormGroup;
  @Input() showCoveragePrices: boolean;
  @Input() selectedProduct: Product;
  @Input() termMonths = 1;
  @Input() coverageChanges: CoverageChange[];
  @Input() coverageErrors: CoverageError[];
  @Input() propertyLiabilityCoverageGrouping: CoverageGrouping;
  @Input() deductiblesCoverageGrouping: CoverageGrouping;
  @Input() additionalProtectionCoverageGrouping: CoverageGrouping;
  @Input() specialLimitsOfLiabilityCoverageGrouping: CoverageGrouping;
  @Input() isQuote: boolean;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() contactNumber: string;
  @Input() isCompRater: boolean;
  @Input() isMobile: boolean;

  @Output() consentToggled = new EventEmitter<Event>();

  @Output() coverageUpdated: EventEmitter<CoverageChange[]> = new EventEmitter<
    CoverageChange[]
  >();

  @Output() propertyInfoModalToggled = new EventEmitter<Event>();

  constructor() {}

  coverageChanged(coverageChange: CoverageChange[]): void {
    this.coverageUpdated.emit(coverageChange);
  }

  onConsentToggled(event: Event) {
    this.consentToggled.emit(event);
  }
}
