import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { PolicyNumberResponse } from '@core/models/policy-number/policy-number-response.model';
import { PolicyNumberRequest } from '@core/models/policy-number/policy-number-request.model';

@Injectable({
  providedIn: 'root',
})
export class PolicyNumberAdapter extends BaseDsmAdapter {
  generatePolicyNumber(
    request: PolicyNumberRequest
  ): Observable<PolicyNumberResponse> {
    const url = `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}?action=generatePolicyNumber`;
    return this.patch<PolicyNumberResponse>(
      url,
      {},
      { productId: request.productId }
    ).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }
}
