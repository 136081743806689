import * as fromFeature from '@core/store/reducers';

import { createSelector } from '@ngrx/store';
import { PremiumState } from '@core/store/reducers/premium.reducer';

import * as fromPremium from '@core/store/reducers/premium.reducer';

export const getPremiumState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.premium
);

export const {
  selectAll: selectAllPremiums,
  selectEntities: selectPremiumEntities,
} = fromPremium.adapter.getSelectors(getPremiumState);

export const getPremiumLoaded = createSelector(
  getPremiumState,
  (state: PremiumState): boolean => state.loaded
);

export const getPremiumLoading = createSelector(
  getPremiumState,
  (state: PremiumState): boolean => state.loading
);
