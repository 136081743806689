import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';
import * as fromPolicyholder from '@core/store/selectors/policyholder.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import * as fromPolicy from '@core/store/selectors/policy.selector';
import * as fromToken from '@core/store/selectors/token.selector';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromPremium from '@core/store/selectors/premium.selector';
import { EstablishEscrowAccountRequest } from '@core/models/billing/establish-escrow-account.request';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { AddressEntity } from '@core/models/entities/address.entity';
import { DEFAULT_ID, ProductTypes } from '@shared/constants/app-constants';
import { Product } from '@core/models/products/product.model';
import { DateUtils } from '@shared/utils/date.utils';
import { StateUtils } from '@shared/utils/state.utils';
import { PolicyState } from '@core/store/reducers/policy.reducer';

export const getEscrowState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.escrow
);

export const selectEscrowSelection = createSelector(
  fromFeature.getAppState,
  fromProducts.getSelectedProductsWithoutErrors,
  (state: fromFeature.AppState, products: Product[]) => {
    if (
      products.filter(
        product =>
          product.id === ProductTypes.HOMEOWNERS ||
          product.id === ProductTypes.CONDO
      ).length > 0
    ) {
      if (state.lenders && state.lenders.entities) {
        for (const id in state.lenders.entities) {
          if (state.lenders.entities.hasOwnProperty(id)) {
            const lender = state.lenders.entities[id];
            if (lender.mortgage && lender.mortgage.escrow) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }
);

function digitsOnly(input) {
  return (input.match(/\d+/g) || []).join('');
}

function nameFromPolicyholder(policyholder) {
  return `${policyholder.person.firstName} ${policyholder.person.lastName}`;
}

function formatCurrencyString(input) {
  return input.toFixed(2);
}

/* eslint-disable no-magic-numbers */
function formatTermLength(input) {
  if (input === 6) {
    return '006';
  }
  if (input === 12) {
    return '012';
  }
  return (input || 6).toString().padStart(3, '0');
}

export const buildEstablishEscrowAccountRequest = (productId: string) =>
  createSelector(
    fromFeature.getAppState,
    fromPolicyholder.getPrimaryNamedInsured,
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    fromPolicy.getPolicyState,
    fromToken.getAccessToken(productId),
    fromProducts.getProduct(productId),
    fromPremium.selectPremiumEntities,
    (
      app: fromFeature.AppState,
      policyholder: PolicyholderEntity,
      policyAddress: AddressEntity,
      policy: any,
      accessToken: string,
      product: Product,
      premiums: any
    ): EstablishEscrowAccountRequest => {
      const request: EstablishEscrowAccountRequest = {
        accessToken: accessToken,
        accountState: app.session.quoteState,
        paymentConfirmationPref: true,
        paymentReminderPref: true,
        paperlessPreference: 'EMAL',
        pbcConsentStatus: true, // ESDDA. Always true; enforced by UI.
        email: policyholder.emailAddress,
        address: policyAddress.addressLine1,
        city: policyAddress.city,
        postalCode: digitsOnly(policyAddress.postalCode),
        billingState: policyAddress.state,
        CASSBypassReasonDesc: 'Use customer preferred city',
        CASSSuccess: false,
        preferredName: nameFromPolicyholder(policyholder),
        firstName: policyholder.person.firstName,
        lastName: policyholder.person.lastName,
        termMonths: formatTermLength(premiums[productId].termMonths),
        displayPolicyNumber: product.policyNumber,
        downPaymentAmount: '0.00',
        policyEffectiveDate: getEffectiveDate(app.policy, productId),
        policyFinancialDivision: '01',
        policyIndicator: 'N',
        policyState: app.session.quoteState,
        policyTermPremium: formatCurrencyString(
          premiums[productId].total.amount
        ),
        productCode: '00067', // NAPS product code for Homeowners
        productGroupCode: 'FIRE',
        dayOfMonthDue: '01',
        feeAmount: '0.00',
        paymentPlan: '1P',
        producerNumber: StateUtils.defaultProducerCode(app.session.quoteState),
        producerState: app.session.quoteState,
        accountStatus: 'DB', // TODO what do these codes mean?
        accountTypeDescription: 'Flexible',
      };
      return request;
    }
  );

function getEffectiveDate(policy: PolicyState, productId: string): string {
  return productId === ProductTypes.HOMEOWNERS
    ? DateUtils.formatDateToDSM(policy.homeownersEffectiveDate)
    : DateUtils.formatDateToDSM(policy.condoEffectiveDate);
}
