import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mq-share-driving-data',
  templateUrl: './share-driving-data.component.html',
  styleUrls: ['./share-driving-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareDrivingDataComponent implements OnInit {
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}
