import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as _ from 'lodash';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import {
  map,
  catchError,
  switchMap,
  take,
  mergeMap,
  filter,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of, from, forkJoin } from 'rxjs';
import { RetrieveService } from '@core/services/retrieve.service';
import {
  RetrieveModel,
  HydrateModel,
} from '@core/models/retrieve/retrieve.model';
import { Action, Store } from '@ngrx/store';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { AddressEntity } from '@core/models/entities/address.entity';
import {
  ProductTypes,
  DEFAULT_ID,
  ProducerTypes,
  RetrieveProductTypes,
  PolicyholderTypes,
  QuoteStatus,
  DistributionPartnerSearchConstants,
  RIVIAN_THIRDPARTY_ID,
  MortgageeTypes,
  CurrentCarrierHit,
  DEFAULT_EXTERIOR_WALLS,
} from '@shared/constants/app-constants';
import { Update } from '@ngrx/entity';
import {
  RetrieveQuoteResponse,
  HydrateQuoteResponse,
} from '@core/models/auto/quotes/retrieve-quote-response.model';
import { HomeownersModel } from '@core/models/homeowners/homeowners.model';
import { CondoModel } from '@core/models/condo/condo.model';
import {
  HomeownersService,
  LoggingService,
  PolicyService,
  AppConfigService,
  AgencyService,
  DistributionPartnerManagementService,
  AutoService,
  UserContextService,
  SessionService,
  CondoService,
} from '@core/services';
import { RetrieveEntity } from '@core/models/entities/retrieve.entity';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { CoveredLocationService } from '@core/services/covered-location.service';
import { PolicyholderBuilder } from '@shared/utils/builders/policyholder.builder';
import { TokenService } from '@core/services/token.service';
import { AppState } from '@core/store/reducers';
import { DriverBuilder } from '@shared/utils/builders/driver.builder';
import { VehicleBuilder } from '@shared/utils/builders/vehicle.builder';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { HouseholdMemberEntity } from '@core/models/entities/household-member.entity';
import { UnderlyingPolicyEntity } from '@core/models/entities/underlying-policy.entity';
import { VehicleExposureEntity } from '@core/models/entities/vehicle-exposure.entity';
import { LocationExposureEntity } from '@core/models/entities/location-exposure.entity';
import { Product } from '@core/models/products/product.model';
import { AgencyModel } from '@core/models/agency/agency.model';
import { ProducerUtils } from '@shared/utils/producer.utils';
import { StateUtils } from '@shared/utils/state.utils';
import { ProducerSearch } from '@core/models/agency/producer-search.model';
import { PrequalificationAnswersUtil } from '@shared/utils/prequalification-answers.util';
import { DistributionPartnerRoleResponse } from '@core/models/distribution-partner-management/distribution-partner-role.model';
import { StringUtils } from '@shared/utils/string.utils';
import { StateSpecificFlagsObject } from '../reducers/metadata.reducer';
import { BypassFields } from '@core/models/bypass-retrieve/bypass-retrieve.model';
import { UserContext } from '@core/models/user-context/user-context.model';
import { CurrentCarrierService } from '@core/services/current-carrier.service';
import { CurrentCarrier } from '../reducers/current-carrier.reducer';

@Injectable()
export class RetrieveEffects {
  constructor(
    private _actions$: Actions,
    private _retrieveService: RetrieveService,
    private _tokenService: TokenService,
    private _store: Store<AppState>,
    private _homeownersService: HomeownersService,
    private _condoService: CondoService,
    private _coveredLocationService: CoveredLocationService,
    private _loggingService: LoggingService,
    private _policyService: PolicyService,
    private _appConfig: AppConfigService,
    private _errorHelper: ErrorHelper,
    private _agencyService: AgencyService,
    private _autoService: AutoService,
    private _distributionPartnerManagementService: DistributionPartnerManagementService,
    private _userContextService: UserContextService,
    private _currentCarrierService: CurrentCarrierService,
    private _sessionService: SessionService
  ) {}

  @Effect()
  hydrateQuoteEffect = this._actions$.pipe(
    ofType(fromActions.RetrieveActionTypes.HYDRATE_QUOTE),
    map((action: fromActions.HydrateQuote) => action.payload),
    switchMap((hydrateRequest: HydrateModel) => {
      this._loggingService.logToSplunk({
        event: 'HYDRATE_QUOTE_REQUEST',
        message: hydrateRequest,
      });
      return this._retrieveService.callHydrateQuote(hydrateRequest).pipe(
        switchMap(hydrateResponse => {
          this._loggingService.logToSplunk({
            event: 'HYDRATE_QUOTE_RESPONSE_SUCCESS',
            message: hydrateResponse,
          });
          const actions = [];

          if (!Array.isArray(hydrateResponse)) {
            hydrateResponse = [hydrateResponse];
          }
          actions.push(new fromActions.HydrateQuoteSuccess(hydrateResponse));

          const gotProducts = [];
          hydrateResponse.forEach(response => {
            const productType = this._mapProductTypeToProductId(response);

            if (gotProducts.includes(productType)) {
              return;
            }
            gotProducts.push(productType);

            const retrieveModel: RetrieveModel = {
              accessToken: hydrateRequest.accessToken,
              postalCode: response.postalCode,
              lastName: response.lastName,
              quoteId: response.quoteId,
              productType,
            };

            actions.push(new fromActions.RetrieveQuote(retrieveModel));
          });

          return from(actions);
        }),
        catchError(rawError => {
          const safeError = this._errorHelper.sanitizeError(rawError);
          this._loggingService.logToSplunk({
            event: 'HYDRATE_QUOTE_RESPONSE_ERROR',
            message: safeError,
          });

          const errorMessage = this._translateRawHydrateError(safeError);
          return from([
            new fromActions.HydrateQuoteFailure(errorMessage),
            new fromActions.AppLoadingEnd(),
          ]);
        })
      );
    })
  );

  @Effect()
  retrieveQuoteEffect$ = this._actions$.pipe(
    ofType(fromActions.RetrieveActionTypes.RETRIEVE_QUOTE),
    map((action: fromActions.RetrieveQuote) => action.payload),
    mergeMap(request => {
      this._loggingService.log('retrieveQuote request', request);
      return forkJoin([
        this._retrieveService.retrieve(request),
        this._store.select(fromSelectors.getAllProducts).pipe(take(1)),
        this._store.select(fromSelectors.getStateSpecificFlags).pipe(take(1)),
        this._store
          .select(fromSelectors.isPrivateLabelExperience)
          .pipe(take(1)),
      ]).pipe(
        tap(([response, products, stateFlags, isPrivateLabel]) =>
          this._loggingService.log('retrieveQuote response', response)
        ),
        switchMap(([response, products, stateFlags, isPrivateLabel]) => {
          if (
            response &&
            response.producer &&
            response.producer.type === ProducerTypes.IA &&
            response.producer.producerCode
          ) {
            const dpimRequest = {
              legacyAdministrativeSystem:
                DistributionPartnerSearchConstants.AMF_ADMIN_SYSTEM,
              legacyIdentifierType:
                DistributionPartnerSearchConstants.LEGACY_PRODUCER_ORGANIZATION_ROLE_NUMBER,
              legacyIdentifier: this.buildLegacyAMFIdentifier(response),
            };
            this._loggingService.log('DPIM Request', dpimRequest);
            return this._distributionPartnerManagementService
              .getDistributionPartnerRoles(dpimRequest)
              .pipe(
                tap(dpimResponse =>
                  this._loggingService.log('DPIM Response', dpimResponse)
                ),
                take(1),
                map(dpimResponse => [
                  response,
                  products,
                  stateFlags,
                  isPrivateLabel,
                  this.extractResidentState(dpimResponse),
                ])
              );
          }
          return of([response, products, stateFlags, isPrivateLabel, null]);
        }),
        switchMap(
          ([response, products, stateFlags, isPrivateLabel, dpimResponse]) => {
            return this._userContextService.getUserContext().pipe(
              take(1),
              map(userContext => [
                response,
                products,
                stateFlags,
                isPrivateLabel,
                dpimResponse,
                userContext,
              ])
            );
          }
        ),
        switchMap(
          ([
            response,
            products,
            stateFlags,
            isPrivateLabel,
            residentState,
            userContext,
          ]: [
            RetrieveQuoteResponse,
            Product[],
            any,
            boolean,
            string,
            UserContext
          ]) => {
            const actions = [];
            const entity = this.assembleActionsForRetrieveResponse(
              actions,
              response,
              products,
              stateFlags,
              isPrivateLabel,
              residentState,
              userContext
            );
            actions.push(new fromActions.RetrieveQuoteSuccess(entity));

            return from(actions);
          }
        ),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('retrieveQuote error', safeError);
          return of(
            new fromActions.RetrieveQuoteFailure({
              productType: request.productType,
              quoteId: request.quoteId,
              errorCode:
                this._errorHelper.extractErrorCodeFromAnything(safeError) ||
                ErrorHelper.ERR_GENERAL_RETRIEVE,
            })
          );
        })
      );
    })
  );

  @Effect()
  dropUmbrellaIfIneligible$ = this._actions$.pipe(
    ofType(
      fromActions.RetrieveActionTypes.RETRIEVE_QUOTE_SUCCESS,
      fromActions.RetrieveActionTypes.RETRIEVE_QUOTE_FAILURE
    ),
    withLatestFrom(this._store.select(fromSelectors.getRetrieveLoading())),
    filter(([action, loading]) => !loading),
    map(_ => new fromActions.CheckUmbrellaEligibility())
  );

  @Effect()
  updateVehicleFromRetrieve$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.UPDATE_VEHICLE_FROM_RETRIEVE),
    mergeMap((action: fromActions.UpdateVehicleFromRetrieve) =>
      this._autoService.getMakesByYear(action.payload.year).pipe(
        take(1),
        mergeMap(makes => {
          const matchedMake =
            makes.retrieveVehicleModelMakesResponse.vehicleMake.find(
              makeDetails => makeDetails.make.startsWith(action.payload.make)
            );
          if (matchedMake) {
            return of(
              new fromActions.AddVehicleToStore({
                ...action.payload,
                make: matchedMake.make,
                makeDescription: matchedMake.makeDescription,
              })
            );
          }
          return from([]);
        })
      )
    ),
    mergeMap((action: fromActions.UpdateVehicleFromRetrieve) =>
      this._autoService
        .getModelsByYearAndMake(action.payload.year, action.payload.make)
        .pipe(
          take(1),
          mergeMap(model => {
            const matchedModel =
              model.retrieveVehicleModelsWithDescriptionResponse.modelWithDescription.filter(
                vh => vh.model === action.payload.model
              );
            if (matchedModel) {
              return of(
                new fromActions.AddVehicleToStore({
                  ...action.payload,
                  model: matchedModel[0].model,
                  modelDescription:
                    matchedModel.length == 1
                      ? matchedModel[0].modelDescription
                      : '',
                })
              );
            }
            return from([]);
          })
        )
    )
  );

  @Effect()
  updatePowersportsVehicleFromRetrieve$ = this._actions$.pipe(
    ofType(fromActions.VehicleActionTypes.UPDATE_VEHICLE_FROM_RETRIEVE),
    mergeMap((action: fromActions.UpdatePowersportsVehicleFromRetrieve) =>
      this._autoService.getPowersportsMakesByYear(action.payload.year).pipe(
        take(1),
        mergeMap(makes => {
          const matchedMake = makes.makes.find(makeDetails =>
            makeDetails.make.startsWith(action.payload.make)
          );
          if (matchedMake) {
            return of(
              new fromActions.AddPowersportsVehicleToStore({
                ...action.payload,
                productId: ProductTypes.POWERSPORTS,
                make: matchedMake.make,
                makeDescription: matchedMake.makeDescription,
              })
            );
          }
          return from([]);
        })
      )
    )
  );

  @Effect()
  bypassRetrieveQuoteEffect$ = this._actions$.pipe(
    ofType(fromActions.RetrieveActionTypes.BYPASS_RETRIEVE_QUOTE),
    map((action: fromActions.BypassRetrieveQuote) => action.payload),
    switchMap((bypassFields: BypassFields) => {
      this._loggingService.logToSplunk({
        event: 'BYPASS_RETRIEVE_QUOTE_REQUEST',
        message: bypassFields,
      });
      return this._retrieveService.callBypassRetrieve(bypassFields).pipe(
        switchMap(bypassResponse => {
          this._loggingService.logToSplunk({
            event: 'BYPASS_RETRIEVE_QUOTE_SUCCESS',
            message: bypassResponse,
          });
          return of(new fromActions.BypassRetrieveQuoteSuccess(bypassResponse));
        }),
        catchError(rawError => {
          const safeError = this._errorHelper.sanitizeError(rawError);
          this._loggingService.logToSplunk({
            event: 'BYPASS_RETRIEVE_QUOTE_ERROR',
            message: safeError,
          });
          return of(new fromActions.BypassRetrieveQuoteFailure(safeError));
        })
      );
    })
  );

  assembleActionsForRetrieveResponse(
    actions,
    response: RetrieveQuoteResponse,
    products: Product[],
    stateFlags: any,
    isPrivateLabel: boolean,
    residentState: string,
    userContextDetails: UserContext
  ): RetrieveEntity {
    const entity = <RetrieveEntity>{};

    actions.push(
      new fromActions.AddToSelectedProducts(
        products.find(product => product.id === response.productType)
      )
    );

    // we can no longer set DPID from retrieved quote as
    // it uses legacy partner ID which is != partnerName (EBG ThirdPartyID)
    // if (response.distributionPartnerID) {}

    const userContext = {
      retrieve: true,
    };
    actions.push(new fromActions.UpdateUserContext(userContext));

    if (response.producer) {
      /* TODO Unclear what the correct behavior is regarding producer code. */
      // if the type is anything other than direct, it means we need to create the agency model
      if (
        (response.producer.type &&
          response.producer.type === ProducerTypes.EA) ||
        response.producer.type === ProducerTypes.IA
      ) {
        const quoteState = response.policyAddress
          ? response.policyAddress.state
            ? response.policyAddress.state
            : null
          : null;
        if (response.producer.type === ProducerTypes.EA) {
          const producerState = StateUtils.mapStateCodeToState(
            response.producer.producerCode.slice(0, 2)
          );
          actions.push(
            new fromActions.UpdateAgency(<AgencyModel>{
              producerCode: response.producer.producerCode,
              agencyChannel: ProducerUtils.getChannelByProducerType(
                response.producer.type
              ),
              state: producerState,
              quoteState: quoteState,
              isPrivateLabel: isPrivateLabel,
            })
          );
        } else if (response.producer.type === ProducerTypes.IA) {
          this._tokenService
            .getAll()
            .pipe(take(1))
            .subscribe(tokens => {
              return this._agencyService
                .producerCodeSearch(tokens[0].accessToken)
                .pipe(
                  take(1),
                  catchError(err => {
                    return of(<ProducerSearch>{
                      warning: null,
                      producers: [],
                    });
                  })
                )
                .subscribe((searchResponse: ProducerSearch) => {
                  let producerState = quoteState;
                  const producerCode = searchResponse.producers.find(
                    producerCode =>
                      producerCode.producerCode ===
                      response.producer.producerCode
                  );

                  if (producerCode) {
                    producerState = producerCode.agentState;
                  } else if (residentState) {
                    producerState = residentState;
                  }

                  this._store.dispatch(
                    new fromActions.UpdateAgency(<AgencyModel>{
                      producerCode: response.producer.producerCode,
                      agencyChannel: ProducerUtils.getChannelByProducerType(
                        response.producer.type
                      ),
                      state: producerState,
                      quoteState: quoteState,
                      isPrivateLabel: isPrivateLabel,
                    })
                  );
                });
            });
        }
      }
    }

    const productEntity: Product = {
      effectiveDate: this.adjustEffectiveDate(response.effectiveDate),
      creditConsent: response.creditConsent,
      sessionId: response.sessionId,
      quoteId: response.quoteId,
      quoteStatus: response.quoteStatus,
      termType: response.termType,
      tokenLoaded: true,
      tokenLoading: false,
      quoteLoaded: true,
      quoteCompleted: this.responseIsQuoted(response),
      needConstructionInfo: response?.needConstructionInfo,
      isQualified: true,
      show: true,
      hasReceivedRequiredDocuments: response.hasReceivedRequiredDocuments,
      hasCustomerViewedDocuments: response.hasCustomerViewedDocuments,
      policyNumber: response.policyNumber,
      bindRated: this.responseIsBound(response),
      bindCompleted: this.responseIsBound(response),
    };
    const productUpdate: Update<Product> = {
      id: response.productType,
      changes: productEntity,
    };
    actions.push(new fromActions.UpdateProduct(productUpdate));

    if (response.policyAddress) {
      const address: string[] =
        response.policyAddress.addressLine1.split('APT');
      const entity = <AddressEntity>{
        addressId: DEFAULT_ID,
        addressIds: {
          [response.productType]: response.policyAddress.addressId,
        },
        addressLine1:
          address.length === 2
            ? address[0]
            : response.policyAddress.addressLine1,
        addressLine2:
          address.length === 2
            ? 'APT' + address[1]
            : response.policyAddress.addressLine2,
        city: response.policyAddress.city,
        state: response.policyAddress.state,
        postalCode: response.policyAddress.postalCode,
        country: null,
        street: null,
      };
      this.synthesizeStreetAddress(entity);
      actions.push(new fromActions.AddPolicyAddress(entity));
      if (response.policyAddress.state) {
        actions.push(
          new fromActions.InitializeState(response.policyAddress.state)
        );
        // loading standardized address for payments page
        actions.push(
          new fromActions.LoadStandardizedAddress({
            street: entity.street,
            unitNumber: response.policyAddress.aptNumber,
          })
        );
      }
    }

    if (response.policyHolders) {
      if (response.policyHolders.length) {
        for (const policyHolder of response.policyHolders) {
          this.assembleActionsForPolicyHolder(actions, policyHolder, response);
        }

        const policyHolders: any = response.policyHolders;
        const pniPolicyHolder = policyHolders.find(policyHolder =>
          policyHolder.policyHolderType
            ? policyHolder.policyHolderType ===
              PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
            : policyHolder.type ===
              PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
        );
        // reduce the Mailing address if Present on the policyHolder object.
        if (pniPolicyHolder.address && response.policyAddress) {
          if (
            pniPolicyHolder.address.addressLine1 !==
              response.policyAddress.addressLine1 ||
            pniPolicyHolder.address.postalCode?.split('-')[0] !==
              response.policyAddress.postalCode?.split('-')[0]
          ) {
            const entity = <AddressEntity>{
              addressId: DEFAULT_ID,
              addressIds: {
                [response.productType]: pniPolicyHolder.address.addressId,
              },
              addressLine1: pniPolicyHolder.address.addressLine1,
              addressLine2: pniPolicyHolder.address.addressLine2,
              city: pniPolicyHolder.address.city,
              state: pniPolicyHolder.address.state,
              postalCode: pniPolicyHolder.address.postalCode,
              country: null,
              street: null,
            };
            this.synthesizeStreetAddress(entity);
            actions.push(new fromActions.AddMailingAddress(entity));
          }
        }
        // updating billing name for payments page
        this.assembleActionForUpdatingBillingName(actions, pniPolicyHolder);
      } else {
        this.assembleActionsForPolicyHolder(
          actions,
          response.policyHolders,
          response
        );
        this.assembleActionForUpdatingBillingName(
          actions,
          response.policyHolders
        );
      }
    }

    if (response.documents) {
      const documents = response.documents.map(doc => {
        return { ...doc, productId: response.productType };
      });
      actions.push(new fromActions.AddAllPreBindDocuments(documents));
    }

    actions.push(new fromActions.GenerateAccessToken(response.productType));

    /* If we changed the effective date, we must also update current carrier in PC.
     * Flag the change so our caller knows an update is required.
     */
    let updatingCurrentCarrier = false;
    const ccDecision =
      this._currentCarrierService.validateCurrentCarrierDecision(
        response?.currentCarrier
      );
    const isIgnoreCurrentCarrierValidation =
      this._sessionService.getIgnoreCurrentCarrierValidation();
    if (productEntity.effectiveDate !== response.effectiveDate) {
      response.updateRequired = true;
      if (response.productType === ProductTypes.AUTO) {
        const reasonForCoverageLapse =
          response.currentCarrier?.reasonForCoverageLapse;
        actions.push(new fromActions.AddCurrentCarrierDecision(ccDecision));
        if (ccDecision === CurrentCarrierHit.FULL_HIT) {
          actions.push(
            new fromActions.UpdateCurrentCarrier(
              reasonForCoverageLapse,
              response.currentCarrier
            )
          );
        } else if (
          isIgnoreCurrentCarrierValidation &&
          !(ccDecision === CurrentCarrierHit.FULL_HIT)
        ) {
          const currentCarrierRequest = <any>{};
          this.copyOrDefaultCurrentCarrier(
            currentCarrierRequest,
            response.currentCarrier
          );
          this.changeCurrentCarrierTermsArbitrarilyToForceUpdate(
            currentCarrierRequest
          );
          actions.push(
            new fromActions.UpdateCurrentCarrier(
              reasonForCoverageLapse,
              currentCarrierRequest
            )
          );
        }
        updatingCurrentCarrier = true;
      }
    }

    if (
      !updatingCurrentCarrier &&
      response.productType === ProductTypes.AUTO &&
      response.currentCarrier
    ) {
      actions.push(new fromActions.AddCurrentCarrierDecision(ccDecision));
      actions.push(
        new fromActions.GetCurrentCarrierSuccess(response.currentCarrier)
      );
    }

    if (response.prequalificationAnswers) {
      // TODO
    }

    if (response.properties) {
      // TODO TenantCoveredLocation[]
    }

    if (response.policyAddress && response.policyAddress.state) {
      let resultPrequalAnswers = [];
      resultPrequalAnswers =
        PrequalificationAnswersUtil.getPrequalificationAnswers(
          response.productType,
          response.policyAddress.state
        );

      if (
        response.prequalificationAnswers &&
        response.prequalificationAnswers.length
      ) {
        response.prequalificationAnswers =
          response.prequalificationAnswers.filter(
            value => value.answerValue != undefined
          );
        resultPrequalAnswers = _.unionBy(
          response.prequalificationAnswers,
          resultPrequalAnswers,
          'questionCode'
        );
      }
      if (resultPrequalAnswers.length) {
        response.rateRequired = true;
        actions.push(
          new fromActions.PatchQuote(response.productType, {
            prequalificationAnswers: resultPrequalAnswers,
          })
        );
      }
    }

    if (response.coveredLocation) {
      let coveredLocation: CoveredLocation = {};
      if (response.productType === ProductTypes.HOMEOWNERS) {
        const homeownersModel =
          this.composeHomeownersModelFromRetrieveResponse(response);
        this._homeownersService.addHomeowners(homeownersModel);
        coveredLocation =
          this.composeHomeownersCoveredLocationFromRetrieveResponse(
            response,
            homeownersModel
          );

        if (this.homeownersCoveredLocationIsIncomplete(response)) {
          response.updateRequired = true;
        }
      } else if (response.productType === ProductTypes.CONDO) {
        const condoModel = this.composeCondoModelFromRetrieveResponse(response);
        this._condoService.addCondo(condoModel);
        coveredLocation = this.composeCondoCoveredLocationFromRetrieveResponse(
          response,
          condoModel
        );

        if (this.condoCoveredLocationIsIncomplete(response)) {
          response.updateRequired = true;
        }
      } else if (response.productType === ProductTypes.RENTERS) {
        coveredLocation = {
          numberOfOccupants: response.coveredLocation.numberOfOccupants,
          constructionInfo: {
            constructionType: response.coveredLocation.constructionInfo
              ? response.coveredLocation.constructionInfo.constructionType
              : 'F',
          },
          unitsInBuilding: response.coveredLocation.unitsInBuilding,
        };

        if (this.rentersCoveredLocationIsIncomplete(response)) {
          response.updateRequired = true;
        }

        actions.push(
          new fromActions.UpdateRenters({
            id: '0',
            numberOfOccupants: (
              response.coveredLocation.numberOfOccupants || ''
            ).toString(),
            unitsInBuilding: (
              response.coveredLocation.unitsInBuilding || ''
            ).toString(),
            personalPropertyCoverage: '',
            structuralMaterial: '',
          })
        );
      }
      this._coveredLocationService.addCleanCoveredLocation(coveredLocation);
    }

    if (response.vehicles) {
      for (const vehicle of response.vehicles) {
        vehicle.quoteId = response.quoteId;
        const model = VehicleBuilder.entityFromVehicle(
          { ...vehicle, isRetrieve: true },
          response.productType
        );

        actions.push(new fromActions.UpdateVehicleFromRetrieve(model));
        if (vehicle.additionalInterests) {
          for (const interest of vehicle.additionalInterests) {
            actions.push(
              new fromActions.AddAdditionalInterestSuccess({
                ...interest,
                productId: response.productType,
                vehicleId: vehicle.vehicleId,
              })
            );
          }
        }
      }
    }

    if (response.drivers && response.drivers.length) {
      for (const driver of response.drivers) {
        if (response.productType === ProductTypes.AUTO) {
          let model = DriverBuilder.entityFromDriver(driver);
          if (response?.currentCarrier?.reasonForCoverageLapse) {
            model = {
              ...model,
              reasonForCoverageLapse:
                response.currentCarrier.reasonForCoverageLapse,
            };
          }
          actions.push(new fromActions.AddDriverDirectly(model));
        } else if (response.productType === ProductTypes.POWERSPORTS) {
          const model = DriverBuilder.entityFromPowersportsDriver(driver);
          actions.push(new fromActions.AddPowersportsDriverDirectly(model));
        }
      }
    }

    if (response.householdMembers && response.householdMembers.length) {
      for (const member of response.householdMembers) {
        const model = this._entityFromHouseholdMember(member);
        actions.push(new fromActions.AddHouseholdMemberSuccess(model));
      }
    }

    if (response.underlyingPolicies && response.underlyingPolicies.length) {
      for (const underlying of response.underlyingPolicies) {
        const model = this._entityFromUnderlyingPolicy(underlying);
        actions.push(new fromActions.AddUnderlyingPolicySuccess(model));
      }
    }

    if (response.vehicleExposures && response.vehicleExposures.length) {
      for (const vehicle of response.vehicleExposures) {
        const model = this._entityFromVehicleExposure(vehicle);
        actions.push(new fromActions.AddVehicleExposureSuccess(model));
      }
    }

    if (response.locationExposures && response.locationExposures.length) {
      for (const location of response.locationExposures) {
        const model = this._entityFromLocationExposure(location);
        actions.push(new fromActions.AddLocationExposureSuccess(model));
      }
    }

    if (response.telematicsEnrollments) {
      actions.push(
        new fromActions.GetTelematicsEnrollmentsSuccess(
          response.telematicsEnrollments[0]
        )
      );
    }

    if (this.responseIsQuoted(response)) {
      this.assembleActionsForRatedQuote(actions, response);
    }

    if (this.responseIsBound(response)) {
      this.assembleActionsForBoundQuote(actions, response);
    }

    actions.push(new fromActions.GetPolicyLine(response.productType));

    if (
      response.productType === ProductTypes.HOMEOWNERS ||
      response.productType === ProductTypes.CONDO
    ) {
      actions.push(new fromActions.RetrieveMortgages(response.quoteId));
    }

    if (
      response.productType === ProductTypes.HOMEOWNERS ||
      response.productType === ProductTypes.CONDO
    ) {
      if (response.currentBillTo === MortgageeTypes.MORTGAGEE) {
        actions.push(new fromActions.UpdateHasMortgageForRetrieve(true));
        actions.push(new fromActions.UpdateEscrowForRetrieve(true));
      } else if (response.mortgages.length > 0) {
        actions.push(new fromActions.UpdateHasMortgageForRetrieve(true));
        actions.push(new fromActions.UpdateEscrowForRetrieve(false));
      } else {
        actions.push(new fromActions.UpdateHasMortgageForRetrieve(false));
        actions.push(new fromActions.UpdateEscrowForRetrieve(false));
      }
    }

    if (
      response.productType === ProductTypes.HOMEOWNERS ||
      response.productType === ProductTypes.CONDO ||
      response.productType === ProductTypes.RENTERS
    ) {
      actions.push(new fromActions.GetProtectiveDevices());
    }
    actions.push(new fromActions.SetAccountId(response.accountId));

    if (
      userContextDetails &&
      userContextDetails.thirdPartyId &&
      userContextDetails.thirdPartyId === RIVIAN_THIRDPARTY_ID &&
      products.length > 2 &&
      response.productType === ProductTypes.AUTO
    ) {
      response.rateRequired = true;
      actions.push(new fromActions.SetTriggerEditBillingPopup(true));
    }
    entity.quoteId = response.quoteId;
    entity.productId = response.productType;
    entity.response = response;

    return entity;
  }

  assembleActionsForRatedQuote(
    actions: Action[],
    response: RetrieveQuoteResponse
  ) {
    actions.push(new fromActions.RateQuoteSuccess(response.productType));
    this.assembleActionsForCoveragesDiscountsPremiumAndQuoteLetter(
      actions,
      response
    );
  }

  assembleActionsForBoundQuote(
    actions: Action[],
    response: RetrieveQuoteResponse
  ) {
    actions.push(
      new fromActions.AddSelectedCoverageProduct(response.productType)
    );
    actions.push(new fromActions.RateBindSuccess(response.productType));
    actions.push(new fromActions.ResetDsmDownPaymentLoaded(false));

    this.assembleActionsForCoveragesDiscountsPremiumAndQuoteLetter(
      actions,
      response
    );
  }

  assembleActionsForCoveragesDiscountsPremiumAndQuoteLetter(
    actions: Action[],
    response: RetrieveQuoteResponse
  ) {
    actions.push(new fromActions.RemoveProductCoverages(response.productType));
    actions.push(
      new fromActions.AddManyCoverages(
        response.offeredQuotes[0].coverages.map(coverage => {
          coverage.productId = response.productType;
          return coverage;
        })
      )
    );
    actions.push(
      new fromActions.UpdatePremium({
        productId: response.productType,
        ...response.offeredQuotes[0].premium,
      })
    );

    if (response.discounts && response.discounts.length) {
      actions.push(
        new fromActions.UpdateAllDiscounts(
          response.discounts.map(discount => ({
            productId: response.productType,
            ...discount,
          }))
        )
      );
    }

    actions.push(new fromActions.LoadQuoteLetter());
  }

  getNeedConstructionInfo(response: RetrieveQuoteResponse): boolean {
    if (response.needConstructionInfo) {
      return true;
    }
    if (!response.coveredLocation) {
      // Not a property quote, or completely unavailable.
      // Expect the usual quote flow to manage it.
      return false;
    }
    if (!response.coveredLocation.constructionInfo) {
      // All constructionInfo missing? Yup we need it.
      return true;
    }
    const ci = response.coveredLocation.constructionInfo;
    // Various fields required by PC but not by Comp raters:
    if (
      !ci.numberOfFullBathrooms ||
      !ci.fullBathroomDescription ||
      !ci.numberOfKitchens ||
      !ci.kitchenDescription ||
      !ci.yearBuilt ||
      !ci.squareFootage ||
      !ci.numberOfStories
    ) {
      return true;
    }
    return false;
  }
  copyOrDefaultCurrentCarrier(output: CurrentCarrier, input: any) {
    if (input && input.currentCarrierTerms) {
      output.coverageLapse = input.coverageLapse;
      output.currentBodilyInjuryLimit = input.currentBodilyInjuryLimit;
      output.currentCarrierName = input.currentCarrierName;
      output.currentCarrierTerms = input.currentCarrierTerms;
      output.isNationwideWinBack = input.isNationwideWinBack;
    } else {
      output.currentCarrierName = 'AllOtherSelectRisk';
      output.coverageLapse = '0Days';
      output.currentBodilyInjuryLimit = 'GE100_300';
      output.currentCarrierTerms = '6';
      output.isNationwideWinBack = false;
    }
  }

  changeCurrentCarrierTermsArbitrarilyToForceUpdate(
    currentCarrier: CurrentCarrier
  ) {
    const termCount = Number(currentCarrier.currentCarrierTerms);
    currentCarrier.currentCarrierTerms = String(termCount + 1);
  }
  adjustEffectiveDate(input: string): string {
    let today = new Date();
    if (this._appConfig.isTest()) {
      this._policyService
        .getPolicyEffectiveDate()
        .subscribe(effDate => (today = new Date(effDate.baseEffectiveDate)))
        .unsubscribe();
    }
    try {
      const effective = new Date(input);
      if (effective < today) {
        throw new Error();
      }
      return effective.toISOString().split('T')[0];
    } catch (e) {
      return today.toISOString().split('T')[0];
    }
  }

  synthesizeStreetAddress(address: AddressEntity): void {
    address.street = `${address.addressLine1}, ${address.city},  ${address.state}, ${address.postalCode}`;
  }

  assembleActionsForPolicyHolder(actions, input, response) {
    const addressId = response.policyAddress
      ? response.policyAddress.addressId
      : undefined;

    const entity = PolicyholderBuilder.buildPolicyholderEntityFromResponse(
      input,
      response.productType,
      addressId,
      input.person.dateOfBirth,
      input.policyHolderType || input.type
    );

    this.addDriverIdToPolicyholderEntityIfPresent(entity, response);
    actions.push(new fromActions.AddPolicyholderSuccess(entity));
  }

  assembleActionForUpdatingBillingName(actions, pniPolicyHolder) {
    if (!pniPolicyHolder) {
      return;
    }
    const person = pniPolicyHolder.person
      ? pniPolicyHolder.person
      : pniPolicyHolder;

    actions.push(
      new fromActions.UpdateBillingName({
        firstName: person.firstName,
        lastName: person.lastName,
        middleName: person.middleName || person.middleInitial,
        suffix: person.suffix,
      })
    );
  }

  addDriverIdToPolicyholderEntityIfPresent(
    entity: PolicyholderEntity,
    response
  ) {
    if (response.drivers) {
      for (const driver of response.drivers) {
        if (DriverBuilder.driverMatchesPolicyholder(driver, entity)) {
          entity.driverId = driver.driverId;
          return;
        }
      }
    }
  }

  composeHomeownersModelFromRetrieveResponse(
    response: RetrieveQuoteResponse
  ): HomeownersModel {
    const model = <HomeownersModel>{};
    this.setPrequalificationAnswerIfPresent(
      model,
      'householdConvicted',
      response,
      'HOFelonyConviction_Ext'
    );
    this.setPrequalificationAnswerIfPresent(
      model,
      'foreclosure',
      response,
      'HO3_Foreclosure_Ext'
    );
    if (response.coveredLocation) {
      model.estimatedYearPurchased = this.convertHomePurchaseDateFromResponse(
        response.coveredLocation.datePurchased
      );
      if (response.coveredLocation.riskItems) {
      }
    }
    return model;
  }

  composeHomeownersCoveredLocationFromRetrieveResponse(
    response: RetrieveQuoteResponse,
    hoModel: HomeownersModel
  ): CoveredLocation {
    if (response.coveredLocation) {
      return {
        ...(<any>response.coveredLocation),
        datePurchased:
          this.convertHomePurchaseDateFromResponse(
            response.coveredLocation.datePurchased
          ) || hoModel.estimatedYearPurchased,
      };
    } else {
      const coveredLocation = <CoveredLocation>{
        datePurchased: hoModel.estimatedYearPurchased,
        roofCondition: 'Excellent',
        electricWiring: {
          circuitBreaker: true,
        },
        currentConstruction: false,
        currentRemodelingOrRehabiliation: false,
        deededOwner: true,
      };
      return coveredLocation;
    }
  }

  homeownersCoveredLocationIsIncomplete(
    response: RetrieveQuoteResponse
  ): boolean {
    if (!response.coveredLocation) {
      return true;
    }
    if (!response.coveredLocation.datePurchased) {
      return true;
    }
    if (response.coveredLocation.deededOwner === undefined) {
      return true;
    }
    if (!response.coveredLocation.roofCondition) {
      return true;
    }
    if (
      !response.coveredLocation.electricWiring ||
      response.coveredLocation.electricWiring.circuitBreaker === undefined
    ) {
      return true;
    }
    if (response.coveredLocation.currentConstruction === undefined) {
      return true;
    }
    if (
      response.coveredLocation.currentRemodelingOrRehabiliation === undefined
    ) {
      return true;
    }
    return false;
  }

  rentersCoveredLocationIsIncomplete(response: RetrieveQuoteResponse): boolean {
    if (!response.coveredLocation) {
      return true;
    }

    if (!response.coveredLocation.numberOfOccupants) {
      return true;
    }
    return false;
  }

  composeCondoModelFromRetrieveResponse(
    response: RetrieveQuoteResponse
  ): CondoModel {
    const model = <CondoModel>{};
    if (response.coveredLocation) {
      model.estimatedYearPurchased = this.convertHomePurchaseDateFromResponse(
        response.coveredLocation.datePurchased
      );
    }
    return model;
  }

  composeCondoCoveredLocationFromRetrieveResponse(
    response: RetrieveQuoteResponse,
    coModel: CondoModel
  ): CoveredLocation {
    if (response.coveredLocation) {
      return {
        ...(<any>response.coveredLocation),
        datePurchased: this.convertHomePurchaseDateFromResponse(
          response.coveredLocation.datePurchased
        ),
      };
    }
  }

  condoCoveredLocationIsIncomplete(response: RetrieveQuoteResponse): boolean {
    if (!response.coveredLocation.constructionInfo.yearBuilt) {
      return true;
    }
    return false;
  }

  // Input is 'YYYY-MM-DD', output is 'MM/YYYY'. Preserve invalid input.
  convertHomePurchaseDateFromResponse(input: string): string {
    if (!input) {
      return null;
    }
    const components = input.split('-');
    // eslint-disable-next-line no-magic-numbers
    if (components.length !== 3) {
      return input;
    }
    return `${components[1]}/${components[0]}`;
  }

  setPrequalificationAnswerIfPresent(
    model: HomeownersModel,
    modelKey: string,
    response: RetrieveQuoteResponse,
    questionCode: string
  ) {
    if (!response.prequalificationAnswers) {
      return;
    }
    for (const prequal of response.prequalificationAnswers) {
      if (prequal.questionCode !== questionCode) {
        continue;
      }
      model[modelKey] = prequal.answerValue;
      return;
    }
  }

  responseIsQuoted(response: RetrieveQuoteResponse): boolean {
    if (response.quoteStatus !== QuoteStatus.QUOTED) {
      return false;
    }
    if (!response.offeredQuotes) {
      return false;
    }
    if (response.offeredQuotes.length < 1) {
      return false;
    }
    if (response.offeredQuotes[0].hasBlockingUwIssues) {
      return false;
    }
    return true;
  }

  responseIsBound(response: RetrieveQuoteResponse): boolean {
    if (!StringUtils.areEqual(response.quoteStatus, QuoteStatus.BINDING)) {
      return false;
    }
    if (!response.offeredQuotes) {
      return false;
    }
    if (response.offeredQuotes.length < 1) {
      return false;
    }
    if (response.offeredQuotes[0].hasBlockingUwIssues) {
      return false;
    }
    return true;
  }

  private _mapProductTypeToProductId(response: HydrateQuoteResponse): string {
    switch (response.productType) {
      case RetrieveProductTypes.PERSONAL_AUTO: {
        return ProductTypes.AUTO;
      }

      case RetrieveProductTypes.POWERSPORTS: {
        return ProductTypes.POWERSPORTS;
      }

      case RetrieveProductTypes.HOMEOWNER:
      case RetrieveProductTypes.HOMEOWNERS: {
        return ProductTypes.HOMEOWNERS;
      }

      case RetrieveProductTypes.CONDOMINIUM:
      case RetrieveProductTypes.CONDO: {
        return ProductTypes.CONDO;
      }

      case RetrieveProductTypes.TENANT: {
        return ProductTypes.RENTERS;
      }

      case RetrieveProductTypes.UMBRELLA: {
        return ProductTypes.UMBRELLA;
      }
    }
  }

  private _translateRawHydrateError(rawError: any): string {
    if (rawError.error) {
      if (typeof rawError.error.developerMessage === 'string') {
        return rawError.error.developerMessage;
      } else if (rawError.error.userMessage) {
        return rawError.error.userMessage;
      }
    }
  }

  private isStateFlagApplicable(
    response: RetrieveQuoteResponse,
    flags: any,
    flagKey: keyof StateSpecificFlagsObject
  ): boolean {
    const state: string =
      response && response.policyAddress && response.policyAddress.state;
    return flags && flags[flagKey] && flags[flagKey].includes(state);
  }

  private _entityFromHouseholdMember(member: HouseholdMemberEntity) {
    return {
      ...member,
      productId: ProductTypes.UMBRELLA,
    } as HouseholdMemberEntity;
  }

  private _entityFromUnderlyingPolicy(underlying: UnderlyingPolicyEntity) {
    return {
      ...underlying,
      productId: ProductTypes.UMBRELLA,
    } as UnderlyingPolicyEntity;
  }

  private _entityFromVehicleExposure(exposure: VehicleExposureEntity) {
    return {
      ...exposure,
      productId: ProductTypes.UMBRELLA,
    } as VehicleExposureEntity;
  }

  private _entityFromLocationExposure(exposure: LocationExposureEntity) {
    return {
      ...exposure,
      productId: ProductTypes.UMBRELLA,
    } as LocationExposureEntity;
  }

  private buildLegacyAMFIdentifier(response: RetrieveQuoteResponse): string {
    const [agencyCode] = response.producer.producerCode.split(' - ');
    return `${agencyCode}`;
  }

  private extractResidentState(
    response: DistributionPartnerRoleResponse
  ): string {
    if (
      response &&
      response.licenseSummaries &&
      response.licenseSummaries.length
    ) {
      const residentLicense = response.licenseSummaries.find(
        license => license.residentStateIndicator === 'Y'
      );
      return residentLicense ? residentLicense.licenseState : null;
    }
    return null;
  }
}
