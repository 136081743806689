import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsCustomerDirective } from './is-customer.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsCustomerDirective],
  exports: [IsCustomerDirective],
})
export class IsCustomerModule {}
