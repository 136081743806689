import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { Observable } from 'rxjs';

@Injectable()
export class DigitalIdCardsTextDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  dispatchSendAutoIdCards(): void {
    this._store.dispatch(new fromActions.SendAutoIdCards());
  }

  getDigitalIDCardsTextLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getDigitalIDCardsTextLoaded);
  }
}
