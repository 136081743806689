import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchQuotesResponse } from '@core/models/auto/quotes/search-quotes-response.model';
import { map } from 'rxjs/operators';
import { SearchModel } from '@core/models/search/search.model';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class SearchAdapter extends BaseDsmAdapter {
  searchFromRemote(request: SearchModel): Observable<SearchQuotesResponse> {
    const url = this._appConfig.config.pcEdgeSearchQuotesUrl;
    return this.search(url, request);
  }

  search(url: string, request: SearchModel): Observable<SearchQuotesResponse> {
    return this.post<SearchQuotesResponse>(url, request, {
      productId: ProductTypes.AUTO,
    }).pipe(
      map(response => {
        return response;
      })
    );
  }
}
