import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService, LoggingService } from '@core/services';
import { catchError, map } from 'rxjs/operators';
import {
  PartialQuote,
  PartialQuoteRequest,
} from '@core/models/partial-quote/partial-quote.model';
import { Observable, of } from 'rxjs';
import { HeadersUtils } from '@shared/utils/headers.utils';
import { HttpParamsUtil } from '@shared/utils/http-params.util';

@Injectable({
  providedIn: 'root',
})
export class PartialQuoteAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfigService: AppConfigService,
    private _loggingService: LoggingService
  ) {}

  getPartialQuote(request: PartialQuoteRequest): Observable<PartialQuote> {
    const url = `${this._appConfigService.config.partnerBridgingUtilityUrl}/partial-quotes/${request.partialQuoteId}?otp=${request.otp}`;

    const headers = HeadersUtils.buildHeaderForDSMCalls(
      this._appConfigService.config.apiKey,
      null,
      request.sessionId,
      this._appConfigService.isTest()
        ? this._appConfigService.config.targetEnv
        : '',
      request.userContext,
      null,
      null,
      null,
      null,
      'true'
    );
    const params = HttpParamsUtil.buildHttpParamsWithPartnerName(
      request.userContext
    );
    return this._http
      .get<PartialQuote>(url, { headers, params })
      .pipe(map(response => response))
      .pipe(
        catchError((error: any) => {
          this._loggingService.log('getPartialQuote Error', error);
          return of(null);
        })
      );
  }
}
