import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { MetadataService, ProductsService } from '@core/services';
import { CoverageService } from '@core/services/coverage.service';
import { CoverageHelper } from '@core/services/helpers/coverage.helper';
import { CoverageIds, ProductTypes } from '@shared/constants/app-constants';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AFMVFCoveragesGuard implements CanActivate {
  constructor(
    private _productService: ProductsService,
    private _metadataService: MetadataService,
    private _productsService: ProductsService,
    private _coverageService: CoverageService,
    private _coverageHelper: CoverageHelper
  ) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this._productService.isProductSelectedWithoutError(ProductTypes.AUTO),
      this._metadataService.getStateSpecificFlag('smartrideAcceptAFMVF'),
    ]).pipe(
      take(1),
      mergeMap(([isAuto, isAFMVFRequired]) => {
        if (isAuto && isAFMVFRequired) {
          return this.checkStore();
        }
        return of(true);
      })
    );
  }

  private checkStore(): Observable<boolean> {
    this._coverageService.loadAllCoverages(ProductTypes.AUTO);
    return this._productsService
      .getAllQuoteCoverageLoaded(ProductTypes.AUTO)
      .pipe(
        filter(loaded => loaded === true),
        take(1),
        mergeMap(() => {
          return this._coverageService
            .getCoveragesFor(ProductTypes.AUTO)
            .pipe(take(1));
        }),
        switchMap(coverages => {
          const changes = [];
          const afCoverage = coverages.find(
            cvg => cvg.coverageId === CoverageIds.AF
          );
          const mvfCoverage = coverages.find(
            cvg => cvg.coverageId === CoverageIds.MVF
          );
          if (
            afCoverage &&
            this._coverageHelper.getSelectedValue(afCoverage, 'selected') !==
              'true'
          ) {
            changes.push(
              ...this._coverageHelper.createCoverageChanges(
                coverages,
                afCoverage,
                'selected',
                'true'
              )
            );
          }

          if (
            mvfCoverage &&
            this._coverageHelper.getSelectedValue(mvfCoverage, 'selected') !==
              'true'
          ) {
            changes.push(
              ...this._coverageHelper.createCoverageChanges(
                coverages,
                mvfCoverage,
                'selected',
                'true'
              )
            );
          }

          if (!changes || changes.length === 0) {
            return of(true);
          }

          this._coverageService.updateCoverageChanges(changes);
          this._coverageService.updateCoverage(ProductTypes.AUTO);
          return this._productsService
            .getQuoteCoverageLoaded(ProductTypes.AUTO)
            .pipe(
              filter(loaded => loaded === true),
              take(1),
              map(loaded => loaded)
            );
        })
      );
  }
}
