<form [formGroup]="form" (ngSubmit)="onSubmit(form)">
  <mq-field-validation-message
    [form]="form"
    [complainAboutRequiredFields]="formWasSubmitted$ | async"
  ></mq-field-validation-message>
  <div class="row">
    <div class="form-group col-sm-6">
      <label for="input-submissionId-{{ fieldId }}">Submission ID</label>
      <div class="input-group">
        <input
          type="text"
          id="input-submissionId-{{ fieldId }}"
          formControlName="submissionId"
          class="form-control"
        />
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-6">
      <label for="input-productType-{{ fieldId }}"> ProductType </label>
      <div class="input-group">
        <select
          id="input-productType-{{ fieldId }}"
          class="form-control form-select"
          formControlName="productType"
        >
          <option value="">Please Select</option>
          <option *ngFor="let product of products" value="{{ product.value }}">
            {{ product.description }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>
  </div>

  <div>
    <nw-display-panel-action-buttons
      (cancel)="onCancel()"
    ></nw-display-panel-action-buttons>
  </div>
</form>
