import { Action } from '@ngrx/store';
import { AgencyModel } from '@core/models/agency/agency.model';
import { ProducerSearch } from '@core/models/agency/producer-search.model';

export const LOAD_AGENCY = '[Agency] Load Agency';
export const LOAD_AGENCY_SUCCESS = '[Agency] Load Agency Success';
export const LOAD_AGENCY_FAIL = '[Agency] Load Agency Fail';
export const UPDATE_AGENCY = '[Agency] Update Agency';
export const UPDATE_AGENT_JWT = '[Agency] Update Agent JWT';

export const PRODUCER_CODE_SEARCH = '[Agency] Producer Code Search';
export const PRODUCER_CODE_SEARCH_FAIL = '[Agency] Producer Code Search Fail';
export const PRODUCER_CODE_SEARCH_SUCCESS =
  '[Agency] Producer Code Search Success';

export class LoadAgency implements Action {
  readonly type = LOAD_AGENCY;
}

export class LoadAgencySuccess implements Action {
  readonly type = LOAD_AGENCY_SUCCESS;
  constructor(public payload: AgencyModel) {}
}

export class LoadAgencyFail implements Action {
  readonly type = LOAD_AGENCY_FAIL;
  constructor(public payload: AgencyModel) {}
}

export class UpdateAgency implements Action {
  readonly type = UPDATE_AGENCY;
  constructor(public payload: AgencyModel) {}
}

export class UpdateAgentJwt implements Action {
  readonly type = UPDATE_AGENT_JWT;
  constructor(public payload: any) {}
}

export class ProducerCodeSearch implements Action {
  readonly type = PRODUCER_CODE_SEARCH;
}
export class ProducerCodeSearchFail implements Action {
  readonly type = PRODUCER_CODE_SEARCH_FAIL;
  constructor(public payload: any) {}
}
export class ProducerCodeSearchSuccess implements Action {
  readonly type = PRODUCER_CODE_SEARCH_SUCCESS;
  constructor(public payload: ProducerSearch) {}
}

export type AgencyActions =
  | LoadAgency
  | LoadAgencySuccess
  | LoadAgencyFail
  | UpdateAgency
  | UpdateAgentJwt
  | ProducerCodeSearch
  | ProducerCodeSearchFail
  | ProducerCodeSearchSuccess;
