import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as fromActions from '@core/store/actions';
import { map, catchError, switchMap, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SearchService } from '@core/services/search.service';
import { SearchModel } from '@core/models/search/search.model';
import { Quote } from '@core/models/auto/quotes/search-quotes-response.model';
import { LoggingService } from '@core/services';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { SearchEntity } from '@core/models/entities/search.entity';

@Injectable()
export class SearchEffects {
  constructor(
    private _actions$: Actions,
    private _searchService: SearchService,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  searchQuoteEffect$ = this._actions$.pipe(
    ofType(fromActions.SearchActionTypes.SEARCH_QUOTES),
    map((action: fromActions.SearchQuotes) => action.payload),
    tap((payload: SearchModel) =>
      this._loggingService.log('searchQuote Request', payload)
    ),
    mergeMap((payload: SearchModel) => {
      return this._searchService.searchFromRemote(payload).pipe(
        tap(response =>
          this._loggingService.log('searchQuote Response', response)
        ),
        switchMap(response => {
          if (response.quotes.length) {
            const filteredQuotes = this.filterQuotes(response.quotes);
            this._loggingService.log(
              'filteredSearchQuoteResults',
              filteredQuotes
            );
            return of(
              new fromActions.SearchQuotesSuccess(
                filteredQuotes as SearchEntity[]
              ),
              new fromActions.AppLoadingEnd()
            );
          } else {
            return of(
              new fromActions.SearchQuotesError(
                'No quotes were found for your search criteria'
              ),
              new fromActions.AppLoadingEnd()
            );
          }
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('searchQuote Error', safeError);
          return of(
            new fromActions.SearchQuotesError(
              this._errorHelper.extractErrorCodeFromAnything(safeError) ||
                ErrorHelper.ERR_GENERAL_SEARCH
            ),
            new fromActions.AppLoadingEnd()
          );
        })
      );
    })
  );

  filterQuotes(quotes: Quote[]): Quote[] {
    const filteredQuotes = [];
    if (quotes.length === 1) {
      // add quote to filtered quotes array
      filteredQuotes.push(quotes[0]);
    } else {
      while (quotes.length) {
        const matchingQuotes = quotes.filter(quote => {
          return (
            quote.firstName.toLowerCase() ===
              quotes[0].firstName.toLowerCase() &&
            quote.lastName.toLowerCase() === quotes[0].lastName.toLowerCase() &&
            quote.address.postalCode === quotes[0].address.postalCode &&
            quote.lineOfBusiness === quotes[0].lineOfBusiness
          );
        });
        if (matchingQuotes.length) {
          // remove duplicate quotes from quotes array
          for (const matchingQuote of matchingQuotes) {
            quotes.splice(quotes.indexOf(matchingQuote), 1);
          }
          if (matchingQuotes.length === 1) {
            // add quote to filtered quotes array
            filteredQuotes.push(matchingQuotes[0]);
          } else {
            // sort matching quotes array by date before adding newest quote to filtered quotes array
            matchingQuotes.sort((a, b) => {
              if (a.creationTime > b.creationTime) {
                return -1;
              }
              if (a.creationTime < b.creationTime) {
                return 1;
              }
              return 0;
            });
            filteredQuotes.push(matchingQuotes[0]);
          }
        }
      }
    }
    return filteredQuotes;
  }
}
