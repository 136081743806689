export const CustomUIElements = {
  REORDER_PRIVACY_STATEMENT: 'ReorderPrivacyStatement',
  PRODUCT_ICON_BG_COLOR: 'ProductIconBackground',
  COVERAGE_PAGE_DIVIDER_COLOR: 'CoveragesDivider',
  BANNER_BG_COLOR: 'Banner',
  BANNER_HEADER_TEXT: 'BannerHeader',
  TCPA_DISCLAIMER_TEXT: 'TCPADisclaimer',
  CONNECTED_CAR: 'ConnectedCar',
  SMART_MILES_CONTENT_DISABLED: 'SmartMilesContentDisabled',
  DISABLE_UNDERWRITING_DISCLAIMER_LINK: 'UnderwritingLinkHidden',
  AGREE_TO_SHARE_URL: 'ConnectedCarAgreeToShareUrl',
  STATE_SELECT_HELP_CONTENT: 'GettingStartedStateHelpContent',
  CONNECTED_CAR_ENABLED: 'ConnectedCarEnabled',
  ASSOCIATE_DISCOUNT_ENABLED: 'DisplayAssociateDiscountOption',
  REMOVE_VIEW_QUOTE_PDF_BTN: 'HideViewQuotePDF',
  DISABLE_PLAID: 'DisablePlaid',
  EXCLUDE_LOGO_QUOTE_PDF: 'ExcludeLogoQuoteLetter',
  ADDTL_FOOTER_DISCLAIMER: 'FooterDisclaimer',
};

export const PRODUCT_ICON_BG_COLOR_PROP = '--custom-bg-color-var';
