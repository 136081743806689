import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';

import { AppConfigService } from '@core/services';

@Injectable()
export class IsTestEnvironmentGuard implements CanActivateChild {
  constructor(private _appConfig: AppConfigService) {}

  canActivateChild(): boolean {
    if (this._appConfig.isTest()) {
      return true;
    } else {
      window.location.href = 'https://www.nationwide.com';
      return false;
    }
  }
}
