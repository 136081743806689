import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import {
  NavigationItem,
  NavigationPath,
} from '@core/models/navigation/navigation.model';

import { NavigationDao } from '@bind/dao';

@Injectable({ providedIn: 'root' })
export class BindNavigationService {
  constructor(private _navigationDao: NavigationDao) {}

  getBaseNavigationPath(): Observable<NavigationPath> {
    return this._navigationDao.buildBaseNavigationPath();
  }

  loadNavigation(): void {
    this._navigationDao.loadNavigation();
  }

  // DSM
  dsmUpdateNavigationPath(): void {
    this._navigationDao.dsmUpdateNavigationPath();
  }

  // KEEP
  updateCurrentState(currentUrl: string): void {
    this.getCurrentPath()
      .pipe(take(1))
      .subscribe(path => {
        let currentIndex: number;

        path.items.forEach((item, index) => {
          if (item.url === currentUrl) {
            currentIndex = index;
            return;
          }
        });

        this._navigationDao.updateNavigationPath({
          ...path,
          currentIndex,
        });
      });
  }

  getCurrentPath(): Observable<NavigationPath> {
    return this._navigationDao.getCurrentPath();
  }

  getCurrentPage(): Observable<NavigationItem> {
    const currentPath = this.getCurrentPath();

    return currentPath.pipe(
      map(path => {
        return path.items[path.currentIndex];
      })
    );
  }

  navigateForward(): void {
    const currentPath = this.getCurrentPath();

    currentPath.pipe(take(1)).subscribe(path => {
      let { currentIndex } = path;

      if (currentIndex < path.items.length) {
        currentIndex++;
        this._navigationDao.navigateForward({
          ...path,
          currentIndex,
        });
      }
    });
  }

  navigateBack(): void {
    const currentPath = this.getCurrentPath();

    currentPath.pipe(take(1)).subscribe(path => {
      let { currentIndex } = path;

      if (currentIndex > 0) {
        currentIndex--;
        this._navigationDao.navigateBack({ ...path, currentIndex });
      }
    });
  }

  getNavigationLoaded(): Observable<boolean> {
    return this._navigationDao.getNavigationLoaded();
  }

  getNavigationLoading(): Observable<boolean> {
    return this.getNavigationLoading();
  }

  forceNavigationIndex(url: string) {
    this._navigationDao.forceNavigationIndex(url);
  }

  removeCurrentPageNavigation(url: string): void {
    this._navigationDao.removeCurrentPageNavigation(url);
  }
}
