import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromDiscounts from '@core/store/actions/discount.action';

import { DiscountEntity } from '@core/models/entities/discount.entity';

export type PowersportsDiscountState = EntityState<DiscountEntity>;
export const adapter: EntityAdapter<DiscountEntity> =
  createEntityAdapter<DiscountEntity>({
    selectId: discount => discount.description,
  });

export function reducer(
  state = adapter.getInitialState(),
  action: fromDiscounts.DiscountActions
): PowersportsDiscountState {
  switch (action.type) {
    case fromDiscounts.DiscountActionTypes.UPDATE_ALL_POWERSPORTS_DISCOUNTS: {
      return adapter.upsertMany(action.payload, state);
    }

    default: {
      return state;
    }
  }
}
