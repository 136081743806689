import { Action } from '@ngrx/store';
import { AccountHolderRequest } from '@core/models/request/account-request.model';

export const enum AccountActionType {
  CREATE_ACCOUNT = '[Account] Create Account',
  CREATE_ACCOUNT_SUCCESS = '[Account] Create Account Success',
  CREATE_ACCOUNT_FAIL = '[Account] Create Account Fail',
  SET_ACCOUNT_ID = '[Account] Set Account ID',
  ADD_PRODUCT_ID_FOR_NEW_BUSINESS_CALL = '[Account] Add Product ID for New Business Call list',
  REMOVE_PRODUCT_ID_FOR_NEW_BUSINESS_CALL = '[Account] Remove Product ID for New Business Call list',
}

export class CreateAccount implements Action {
  readonly type = AccountActionType.CREATE_ACCOUNT;
  constructor() {}
}

export class CreateAccountSuccess implements Action {
  readonly type = AccountActionType.CREATE_ACCOUNT_SUCCESS;
  constructor(
    public accountId: string,
    public accountHolder: AccountHolderRequest
  ) {}
}

export class CreateAccountFail implements Action {
  readonly type = AccountActionType.CREATE_ACCOUNT_FAIL;
  constructor(public payload: any) {}
}

export class SetAccountId implements Action {
  readonly type = AccountActionType.SET_ACCOUNT_ID;
  constructor(public payload: string) {}
}
export class AddProductIdForNewBusinessCall implements Action {
  readonly type = AccountActionType.ADD_PRODUCT_ID_FOR_NEW_BUSINESS_CALL;
  constructor(public payload: string) {}
}

export class RemoveProductIdForNewBusinessCall implements Action {
  readonly type = AccountActionType.REMOVE_PRODUCT_ID_FOR_NEW_BUSINESS_CALL;
  constructor(public payload: string) {}
}

export type AccountAction =
  | CreateAccount
  | CreateAccountSuccess
  | CreateAccountFail
  | SetAccountId
  | AddProductIdForNewBusinessCall
  | RemoveProductIdForNewBusinessCall;
