import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  PrivateLabel,
  ProducerInformation,
} from '@core/models/private-label/private-label.model';
import { Product } from '@core/models/products/product.model';
import { SocialLink } from '@core/models/social-link/social-link.model';
import { TermLifeEntity } from '@core/models/termlife/termlife.entity';
import { PrivateLabelService, ProductsService } from '@core/services';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import {
  ProductTypes,
  SocialLinks,
  UrboNumber,
} from '@shared/constants/app-constants';
import {
  PageHelperText,
  UnderWritingDisclaimerReplacement,
} from '@shared/constants/help-text-constants';
import { CustomUIElements } from '@shared/constants/private-label-constants';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mq-nw-footer',
  templateUrl: './nw-footer.component.html',
  styleUrls: ['./nw-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NwFooterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() configLoaded: boolean;
  @Input() privateLabelConfig: PrivateLabel;
  @Input() phoneNumber: string;
  @Input() producerInfo: ProducerInformation;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() selectedProducts: Product[];
  @Input() quoteState: string;
  @Input() urboNumber: string;
  @Input() termLifePeople: TermLifeEntity[];
  @Input() isLifeCoverageProductSelected: boolean;
  @Input() isRivian: boolean;

  overrideContactNumber: string;

  websiteUrl = 'https://www.nationwide.com/';
  additionalPhone: string;
  label = 'Nationwide Insurance';

  links = SocialLinks as SocialLink[];
  disclaimer: string;
  disclaimerAddon: string;
  disclaimerAddonDoubleAsterik: string;

  private _ngUnsubscribe = new Subject();

  constructor(
    private _privateLabelService: PrivateLabelService,
    private _productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this._privateLabelService
      .getProducerContactNumber('HEADER_FOOTER')
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(contactNumber => {
        this.overrideContactNumber = contactNumber;
      });
    this.getUrboNumber();
    this.changeDisclaimerText();
    this.showLifeDisclaimerText();
    this.showDoubleAsterik();
  }

  ngOnChanges(): void {
    if (this.privateLabelConfig) {
      this.updateBranding();
    }
    this.changeDisclaimerText();
    this.showLifeDisclaimerText();
    this.showDoubleAsterik();
    this.getUrboNumber();
    this.updatePhoneNumber();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  changeDisclaimerText(): string {
    if (
      this.stateSpecificFlags.footerUnderwritingDisclaimer &&
      this.selectedProducts.length > 0
    ) {
      if (this.selectedProducts.length === 1) {
        return this.selectedProducts[0].id === ProductTypes.AUTO
          ? (this.disclaimer =
              UnderWritingDisclaimerReplacement[this.quoteState]['Auto'])
          : (this.disclaimer =
              UnderWritingDisclaimerReplacement[this.quoteState]['Property']);
      } else {
        return (this.disclaimer =
          UnderWritingDisclaimerReplacement[this.quoteState]['BothProducts']);
      }
    } else {
      return (this.disclaimer = PageHelperText.UNDERWRITING_DISCLAIMER);
    }
  }

  getCurrentYear(): number {
    return new Date().getFullYear();
  }

  private updateBranding(): void {
    this.phoneNumber = !!this.overrideContactNumber
      ? this.overrideContactNumber
      : this.privateLabelConfig.phoneNumber
      ? this.privateLabelConfig.phoneNumber.trim()
      : this.phoneNumber;
    this.additionalPhone = this.privateLabelConfig.additionalPhoneNumber
      ? this.privateLabelConfig.additionalPhoneNumber.trim()
      : undefined;
    this.websiteUrl = this.privateLabelConfig.websiteUrl
      ? this.privateLabelConfig.websiteUrl
      : this.websiteUrl;
    this.label = this.privateLabelConfig.agencyName;
  }

  getUrboNumber(): string {
    if (
      this.selectedProducts &&
      this.selectedProducts.some(
        product => product.id === ProductTypes.TERMLIFE
      )
    ) {
      return this.termLifePeople &&
        this.termLifePeople.length === 0 &&
        this.urboNumber &&
        (this.urboNumber.includes('/multi-quote/coverages') ||
          this.urboNumber.includes('/multi-bind/additional-information'))
        ? ''
        : UrboNumber[this.urboNumber];
      // non-termlife or landing page
    } else {
      if (this.urboNumber) {
        return this.urboNumber.includes('/multi-quote/getting-started')
          ? this.getUrboNumberForLoadingPage()
          : '';
      } else {
        return '';
      }
    }
  }

  private updatePhoneNumber(): void {
    this._productsService.getTFN().subscribe((tfn: string) => {
      this.phoneNumber = tfn ? tfn : this.phoneNumber;
    });
  }

  getUrboNumberForLoadingPage(): string {
    return UrboNumber['loading-page'];
  }

  showLifeDisclaimerText(): string {
    if (
      this.urboNumber &&
      this.urboNumber.includes('/multi-quote/getting-started')
    ) {
      return '';
    } else if (
      this.selectedProducts &&
      this.selectedProducts.some(
        product => product.id === ProductTypes.TERMLIFE
      )
    ) {
      return this.termLifePeople &&
        this.termLifePeople.length === 0 &&
        this.urboNumber &&
        // (this.urboNumber.includes('coverages') ||
        // (this.urboNumber.includes('-information') ||
        (this.urboNumber.includes('/multi-quote/coverages') ||
          this.urboNumber.includes('/multi-bind/policy-information') ||
          this.urboNumber.includes('/multi-bind/product-details') ||
          this.urboNumber.includes('/multi-bind/coverages') ||
          this.urboNumber.includes('/multi-bind/additional-information') ||
          this.urboNumber.includes('/multi-payment/billing') ||
          this.urboNumber.includes('/multi-payment/summary'))
        ? // (this.urboNumber.includes('/multi-quote/coverages') ||
          // this.urboNumber.includes('/multi-bind/additional-information'))
          ''
        : (this.disclaimerAddon = PageHelperText.UNDERWRITING_DISCLAIMER_ADDON);
    } else {
      return '';
    }
  }

  showDoubleAsterik(): string {
    if (this.showLifeDisclaimerText().includes('Guarantees')) {
      return (this.disclaimerAddonDoubleAsterik =
        PageHelperText.UNDERWRITING_DISCLAIMER__ADDON_DOUBLE_ASTERIK);
    } else {
      return '';
    }
  }

  getPrivateLabelAdditionalDisclaimerText(): string {
    return PrivateLabelContentHelper.getConfigValue(
      this.privateLabelConfig,
      CustomUIElements.ADDTL_FOOTER_DISCLAIMER
    );
  }
}
