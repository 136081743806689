import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';
import { CoverageGrouping } from '../edit-coverage-table/coverage-grouping.model';
import { Product } from '@core/models/products/product.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

@Component({
  selector: 'mq-edit-auto-coverages',
  templateUrl: './edit-auto-coverages.component.html',
  styleUrls: ['./edit-auto-coverages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAutoCoveragesComponent {
  @Input() form: UntypedFormGroup;
  @Input() showCoveragePrices: boolean;
  @Input() selectedProduct: Product;
  @Input() termMonths = 1;
  @Input() coverageChanges: CoverageChange[];
  @Input() coverageErrors: CoverageError[];
  @Input() policyCoverageGrouping: CoverageGrouping;
  @Input() vehicleCoverageGroupings: CoverageGrouping[] = [];
  @Input() driverCoverageGroupings: CoverageGrouping[] = [];
  @Input() additionalPolicyCoverageGrouping: CoverageGrouping;
  @Input() otherCoverageGrouping: CoverageGrouping;
  @Input() extraUpdatedCoverage: string;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() contactNumber: string;
  @Input() additionalPolicyNotification: string;
  @Input() quoteState: string;
  @Input() isMobile: boolean;
  @Input() isAutoSelected: boolean;
  @Input() isHomeownersSelected: boolean;
  @Input() isUmbrellaSelected: boolean;

  @Output() coverageUpdated: EventEmitter<CoverageChange[]> = new EventEmitter<
    CoverageChange[]
  >();

  constructor() {}

  coverageChanged(coverageChanges: CoverageChange[]): void {
    this.coverageUpdated.emit(coverageChanges);
  }
}
