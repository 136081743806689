import { Injectable } from '@angular/core';
import { DigitalIdCardsTextDao } from '@core/dao/digital-id-cards-text.dao';
import { DigitalIdCardsTextAdapter } from '@core/adapters/digital-id-cards-text.adapter';
import {
  DigitalIdCardsTextResponse,
  DigitalIdCardsTextRequest,
} from '@core/models/digital-id-cards/digital-id-cards-text.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DigitalIdCardsTextService {
  constructor(
    private _digitalIdCardsTextDao: DigitalIdCardsTextDao,
    private _digitalIdCardsTextAdapter: DigitalIdCardsTextAdapter
  ) {}

  sendText(request: {
    body: DigitalIdCardsTextRequest;
    accessToken: string;
    sessionId: string;
  }): Observable<DigitalIdCardsTextResponse> {
    return this._digitalIdCardsTextAdapter.sendText(request);
  }

  dispatchSendAutoIdCards(): void {
    this._digitalIdCardsTextDao.dispatchSendAutoIdCards();
  }

  getDigitalIDCardsTextLoaded(): Observable<boolean> {
    return this._digitalIdCardsTextDao.getDigitalIDCardsTextLoaded();
  }
}
