import { Injectable } from '@angular/core';
import { TokenDao } from '@core/dao/token.dao';
import { AccessToken } from '@core/store/reducers/token.reducer';
import { Observable } from 'rxjs';
import { AccessTokenRequest } from '@core/models/auto/quotes/access-token-request.model';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private _tokenDao: TokenDao) {}

  getAll(): Observable<AccessToken[]> {
    return this._tokenDao.getAll();
  }

  getAccessTokenForProductId(productId: string): Observable<AccessToken> {
    return this._tokenDao.getAccessTokenForProductId(productId);
  }

  refresh(productId: string): Observable<AccessToken> {
    return this._tokenDao.refresh(productId);
  }

  getAccessTokenForRequest(
    request: AccessTokenRequest
  ): Observable<AccessToken> {
    return this._tokenDao.getAccessTokenForRequest(request);
  }

  setAccessToken(token: AccessToken) {
    this._tokenDao.setAccessToken(token);
  }

  areAllTokensLoaded(): Observable<boolean> {
    return this._tokenDao.areAllTokensLoaded();
  }

  insertAccessToken(token: string) {
    this._tokenDao.insertAccessToken(token);
  }
}
