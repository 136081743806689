import { Injectable } from '@angular/core';
import { SearchService } from '@core/services/search.service';
import { SearchModel } from '@core/models/search/search.model';
import { Observable, of } from 'rxjs';
import {
  LoadingService,
  LoggingService,
  AgencyService,
  PageAlertService,
} from '@core/services';
import { SearchEntity } from '@core/models/entities/search.entity';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store';
import { Softfall } from '@quote/store';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { withLatestFrom, switchMap } from 'rxjs/operators';
import { PageAlertIDs } from '@shared/constants/app-constants';

@Injectable()
export class SearchProxy {
  constructor(
    private _searchService: SearchService,
    private _loadingService: LoadingService,
    private _loggingService: LoggingService,
    private _store: Store<AppState>,
    private _errorHelper: ErrorHelper,
    private _agencyService: AgencyService,
    private _pageAlertService: PageAlertService
  ) {}

  submitSearchRequest(model: SearchModel): void {
    this._searchService.searchQuotes(model);
  }

  getAllSearchQuotes(): Observable<SearchEntity[]> {
    return this._searchService.getAllSearchQuotes();
  }

  getAppLoading(): Observable<boolean> {
    return this._loadingService.getAppLoading();
  }

  loadingBegin(): void {
    this._loadingService.loadingBegin();
  }

  loadingEnd(): void {
    this._loadingService.loadingEnd();
  }

  logSearchSubmit(model: SearchModel): void {
    this._loggingService.logSearch(model);
  }

  logPageLoaded(): void {
    this._loggingService.logToSplunk({
      event: 'SEARCH_QUOTE_LOAD',
    });
  }

  clearSearchQuotesError(): void {
    this._searchService.clearError();
  }

  getSearchQuotesErrorMessage(): Observable<string> {
    return this._searchService.getError().pipe(
      withLatestFrom(this._agencyService.isAgent()),
      switchMap(([code, isAgent]) => {
        return code
          ? this._errorHelper.getErrorMessageForCode(code, isAgent)
          : of('');
      })
    );
  }

  softfall(reason: string): void {
    this._store.dispatch(new Softfall(reason));
  }

  dispatchActiveAlert(): void {
    this._pageAlertService.dispatchActiveAlert(PageAlertIDs.search);
  }
}
