import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {
  switchMap,
  take,
  tap,
  mergeMap,
  toArray,
  catchError,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services';
import { DocumentsService } from '@core/services/documents.service';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable()
export class DocumentEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _documentsService: DocumentsService,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  getDocuments$ = this._actions$.pipe(
    ofType(fromActions.DocumentActionTypes.GET_DOCUMENTS),
    switchMap(() =>
      this._store.select(fromSelectors.buildGetDocumentsRequests).pipe(take(1))
    ),
    tap(request => this._loggingService.log('getDocuments Requests:', request)),
    switchMap(requests =>
      from(requests).pipe(
        mergeMap(request => this._documentsService.documents(request)),
        toArray(),
        tap(response => {
          if (!response.length) {
            throw new Error('Empty documents response.');
          }
        }),
        tap(response =>
          this._loggingService.log('getDocuments Response', response)
        ),
        switchMap(response => {
          const actions = response.map(
            res => new fromActions.GetDocumentsSuccess(res)
          );
          return actions;
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('getDocuments Error', safeError);
          return of(new fromActions.GetDocumentsFail(safeError));
        })
      )
    )
  );
}
