import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';
import {
  ProtectiveDevicesState,
  ProtectiveDevice,
} from '@core/store/reducers/protective-devices.reducer';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import { Product } from '@core/models/products/product.model';
import { getStateSpecificFlagsObject } from './metadata.selector';

export const getProtectiveDevicesState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.protectiveDevices
);

export const getProtectiveDevicesData = createSelector(
  getProtectiveDevicesState,
  getStateSpecificFlagsObject,
  (state: ProtectiveDevicesState, stateSpecificFlags) => {
    let protectiveDevices: ProtectiveDevice[] = [...state.data];
    // Florida can only have OpeningProtection_Ext
    if (stateSpecificFlags.windMitigationDiscountApplicable) {
      protectiveDevices = protectiveDevices.filter(device => {
        if (device.category === 'Wind Mitigation') {
          return (
            device.type === 'OpeningProtection_Ext' ||
            device.type === 'WindowProtection_Ext'
          );
        } else {
          return true;
        }
      });
    }

    // Rhode Island Specifics
    if (stateSpecificFlags.combineHurricaneOptions) {
      const filteredProtectiveDevices = protectiveDevices.filter(
        device => !(device.category === 'Wind Protective Device')
      );
      let bothOptionsSelected = false;
      const returnSelectedValues: string[] = [];
      let returnValue: string;
      protectiveDevices.forEach(device => {
        if (
          device.category === 'Wind Protective Device' &&
          device.type !== 'Wind Protective Device'
        ) {
          returnSelectedValues.push(device.type);
          if (device.value === 'true') {
            if (
              device.type === 'Hurricane Resistant Permanent Storm Shutters' ||
              device.type === 'Hurricane Resistant Laminated Glass'
            ) {
              if (bothOptionsSelected) {
                returnValue = 'Both Hurricane Options';
              } else {
                bothOptionsSelected = true;
              }
            } else {
              returnValue = device.type;
            }
          }
        }
      });
      returnSelectedValues.splice(2, 0, 'Both Hurricane Options');
      filteredProtectiveDevices.push({
        category: 'Wind Protective Device',
        selectedValues: returnSelectedValues,
        type: 'Wind Protective Device',
        value: returnValue,
      });

      protectiveDevices = filteredProtectiveDevices;
    }

    if (!stateSpecificFlags.windMitigationDiscountApplicable) {
      protectiveDevices = protectiveDevices.filter(
        device =>
          device.category === 'Sprinkler System' ||
          device.category === 'Burglar Alarm' ||
          device.category === 'Fire/Smoke Alarm'
      );
    }

    return protectiveDevices.sort();
  }
);

export const getProtectiveDevicesForm = createSelector(
  getProtectiveDevicesState,
  (state: ProtectiveDevicesState) => state.form
);

export const getProtectiveDevicesLoaded = createSelector(
  getProtectiveDevicesState,
  (state: ProtectiveDevicesState) => state.loaded
);

export const buildGetProtectiveDevicesRequest = (product: Product) =>
  createSelector(fromTokens.getAccessToken(product.id), accessToken => {
    return {
      productId: product.id,
      quoteId: product.quoteId,
      accessToken,
    };
  });

export const buildUpdateProtectiveDevicesRequest = (
  devices: ProtectiveDevice[]
) =>
  createSelector(
    fromProducts.getSelectedPropertyProduct,
    getStateSpecificFlagsObject,
    (product: Product, stateSpecificFlags) => {
      // Rhode Island Specifics
      if (stateSpecificFlags.combineHurricaneOptions) {
        const bothOptionsIndex = devices.findIndex(
          device => device.value === 'Both Hurricane Options'
        );
        if (bothOptionsIndex > -1) {
          devices = devices.filter(
            device => device.value !== 'Both Hurricane Options'
          );
          devices.push({
            type: 'Wind Protective Device',
            value: 'Hurricane Resistant Permanent Storm Shutters',
          });
          devices.push({
            type: 'Wind Protective Device',
            value: 'Hurricane Resistant Laminated Glass',
          });
        }
      }

      return {
        product,
        devices,
      };
    }
  );
