import { HttpParams } from '@angular/common/http';
import { UserContext } from '@core/models/user-context/user-context.model';
import { DEFAULT_SPID } from '@shared/constants/app-constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OtherHttpParams = any;

export class HttpParamsUtil {
  static buildHttpParamsWithPartnerName(
    userContext: UserContext,
    otherParams?: OtherHttpParams
  ): HttpParams {
    let params = new HttpParams();

    if (otherParams) {
      for (const k of Object.keys(otherParams)) {
        params = params.append(k, otherParams[k]);
      }
    }

    if (
      !userContext ||
      !userContext.spid ||
      userContext.spid === DEFAULT_SPID
    ) {
      return params;
    }

    params = params.append('partnerName', userContext.spid);

    return params;
  }
}
