import { Action } from '@ngrx/store';

import { HouseholdMemberEntity } from '@core/models/entities/household-member.entity';
import { Update } from '@ngrx/entity';

export const enum HouseholdMemberActionTypes {
  ADD_HOUSEHOLD_MEMBER = '[Household Member] Add Household Member',
  ADD_HOUSEHOLD_MEMBER_FAIL = '[Household Member] Add Household Member Fail',
  ADD_HOUSEHOLD_MEMBER_SUCCESS = '[Household Member] Add Household Member Success',
  ADD_ALL_HOUSEHOLD_MEMBERS = '[Household Member] Add All  Household Members',

  ADD_ALL_HOUSEHOLD_MEMBERS_SUCCESS = '[Household Member] Add All Household Members Success',

  UPDATE_HOUSEHOLD_MEMBER = '[Household Member] Update Household Member',
  UPDATE_HOUSEHOLD_MEMBER_FAIL = '[Household Member] Update Household Member Fail',
  UPDATE_HOUSEHOLD_MEMBER_SUCCESS = '[Household Member] Update Household Member Success',

  DELETE_HOUSEHOLD_MEMBER = '[Household Member] Delete Household Member',
  DELETE_HOUSEHOLD_MEMBER_FAIL = '[Household Member] Delete Household Member Fail',
  DELETE_HOUSEHOLD_MEMBER_SUCCESS = '[Household Member] Delete Household Member Success',
}

export class AddHouseholdMember implements Action {
  readonly type = HouseholdMemberActionTypes.ADD_HOUSEHOLD_MEMBER;
  constructor(public payload: HouseholdMemberEntity) {}
}

export class AddHouseholdMemberFail implements Action {
  readonly type = HouseholdMemberActionTypes.ADD_HOUSEHOLD_MEMBER_FAIL;
  constructor(public error: any) {}
}

export class AddHouseholdMemberSuccess implements Action {
  readonly type = HouseholdMemberActionTypes.ADD_HOUSEHOLD_MEMBER_SUCCESS;
  constructor(public payload: HouseholdMemberEntity) {}
}

export class AddAllHouseholdMembers implements Action {
  readonly type = HouseholdMemberActionTypes.ADD_ALL_HOUSEHOLD_MEMBERS;
}

export class AddAllHouseholdMembersSuccess implements Action {
  readonly type = HouseholdMemberActionTypes.ADD_ALL_HOUSEHOLD_MEMBERS_SUCCESS;
  constructor(public paylaod: HouseholdMemberEntity[]) {}
}

export class UpdateHouseholdMember implements Action {
  readonly type = HouseholdMemberActionTypes.UPDATE_HOUSEHOLD_MEMBER;
  constructor(public payload: HouseholdMemberEntity) {}
}

export class UpdateHouseholdMemberFail implements Action {
  readonly type = HouseholdMemberActionTypes.UPDATE_HOUSEHOLD_MEMBER_FAIL;
  constructor(public error: any) {}
}

export class UpdateHouseholdMemberSuccess implements Action {
  readonly type = HouseholdMemberActionTypes.UPDATE_HOUSEHOLD_MEMBER_SUCCESS;
  constructor(public payload: Update<HouseholdMemberEntity>) {}
}

export class DeleteHouseholdMember implements Action {
  readonly type = HouseholdMemberActionTypes.DELETE_HOUSEHOLD_MEMBER;
  constructor(public payload: string) {}
}

export class DeleteHouseholdMemberFail implements Action {
  readonly type = HouseholdMemberActionTypes.DELETE_HOUSEHOLD_MEMBER_FAIL;
  constructor(public error: any) {}
}

export class DeleteHouseholdMemberSuccess implements Action {
  readonly type = HouseholdMemberActionTypes.DELETE_HOUSEHOLD_MEMBER_SUCCESS;
  constructor(public payload: string) {}
}

export type HouseholdMemberActions =
  | AddHouseholdMember
  | AddHouseholdMemberFail
  | AddHouseholdMemberSuccess
  | AddAllHouseholdMembers
  | AddAllHouseholdMembersSuccess
  | UpdateHouseholdMember
  | UpdateHouseholdMemberFail
  | UpdateHouseholdMemberSuccess
  | DeleteHouseholdMember
  | DeleteHouseholdMemberFail
  | DeleteHouseholdMemberSuccess;
