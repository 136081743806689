<ngb-accordion
  activeIds="{{ activePanelId }}"
  class="custom-accordion"
  #accordion="ngbAccordion"
  (panelChange)="logAccordionToggleToSplunk($event)"
>
  <ngb-panel id="programsToSave-{{ nonce }}">
    <ng-template ngbPanelTitle>
      <div class="accordion-title" header>
        <mq-accordion-header
          icon="assets/images/piggy.svg"
          title="Programs to save"
        ></mq-accordion-header>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <div>
        <div class="row">
          <div class="col-xs-8 col-sm-9">
            <h1 class="heading-h4">
              The Auto quote includes participation in these programs:
            </h1>
          </div>
          <div
            class="col-xs-4 col-sm-3"
            *ngIf="!allVehiclesIneligibleOrUnenrolled()"
          >
            <button
              type="button"
              class="btn btn-default btn-sm float-right"
              (click)="removeAllEnrollments()"
            >
              Remove All Programs
            </button>
          </div>
          <div
            class="col-xs-4 col-sm-3"
            *ngIf="allVehiclesIneligibleOrUnenrolled()"
          >
            <button
              type="button"
              class="btn btn-default btn-sm float-right"
              (click)="resetDefaultEnrollments()"
            >
              Reset Default Programs
            </button>
          </div>
        </div>
      </div>
      <mq-connected-car-enrollment
        *ngIf="connectedCarVehicles.length"
        [vehicles]="connectedCarVehicles"
        [vehiclePremiums]="vehiclePremiums"
        [initialDiscount]="initialDiscount"
        [maximumDiscount]="maximumDiscount"
        [isSRCCForCA]="isSRCCForCA"
        (connectedCarEnrollmentChanged)="changeEnrollment($event)"
      ></mq-connected-car-enrollment>
      <mq-smartmiles-enrollment
        *ngIf="smartMilesVehicles.length"
        [vehicles]="smartMilesVehicles"
        [vehiclePremiums]="vehiclePremiums"
        [isDeviceOnlyState]="isDeviceOnlyState"
        (smartMilesEnrollmentChanged)="changeEnrollment($event)"
      ></mq-smartmiles-enrollment>
      <mq-prequalified-enrollment
        *ngIf="prequalifiedVehicles.length"
        [vehicles]="prequalifiedVehicles"
        [vehiclePremiums]="vehiclePremiums"
        [enrollmentVehicles]="enrollmentVehicles"
        (prequalifiedEnrollmentChanged)="changeEnrollment($event)"
      >
      </mq-prequalified-enrollment>
      <mq-smartride-enrollment
        *ngIf="smartRideVehicles.length"
        [vehicles]="smartRideVehicles"
        [vehiclePremiums]="vehiclePremiums"
        [isDeviceOnlyState]="isDeviceOnlyState"
        [mobileAppEnrolled]="mobileAppEnrolled"
        [initialDiscount]="initialDiscount"
        [maximumDiscount]="maximumDiscount"
        [stateSpecificFlags]="stateSpecificFlags"
        (smartRideEnrollmentChanged)="changeEnrollment($event)"
      ></mq-smartride-enrollment>
      <mq-self-reported-enrollment
        *ngIf="selfReportedVehicles.length"
        [vehicles]="selfReportedVehicles"
        [vehiclePremiums]="vehiclePremiums"
        [initialDiscount]="initialDiscount"
        [maximumDiscount]="maximumDiscount"
        (selfReportedEnrollmentChanged)="changeEnrollment($event)"
      ></mq-self-reported-enrollment>
      <mq-no-program-enrollment
        *ngIf="ineligibleVehicles.length"
        [vehicles]="ineligibleVehicles"
        [vehiclePremiums]="vehiclePremiums"
        [isIneligible]="true"
        (noProgramEnrollmentChanged)="changeEnrollment($event)"
      ></mq-no-program-enrollment>
      <mq-no-program-enrollment
        *ngIf="unenrolledVehicles.length"
        [vehicles]="unenrolledVehicles"
        [vehiclePremiums]="vehiclePremiums"
        [isIneligible]="false"
        (noProgramEnrollmentChanged)="changeEnrollment($event)"
      ></mq-no-program-enrollment>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
