import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromAgency from '@core/store/reducers/agency.reducer';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import { Producer } from '@core/models/agency/producer-search.model';

export const getAgencyState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.agency
);

export const getAgentJwt = createSelector(getAgencyState, state => {
  return {
    jwt: state.agentJwt,
    accessToken: state.accessToken,
  };
});

export const getAgency = createSelector(getAgencyState, state => state.agency);

export const isAgent = createSelector(getAgencyState, state => {
  if (state.agentJwt && state.accessToken) {
    return true;
  }
  if (!state.agency) {
    return false;
  }
  if (state.agency.agencySource) {
    return false;
  }
  if (state.agency.isPrivateLabel) {
    return false;
  }
  if (state.agency.agencyChannel === 'EA') {
    return true;
  }
  return false;
});

export const isEAOrIAAgentQuoting = createSelector(getAgencyState, state => {
  return (
    state.agency &&
    (state.agency.agencyChannel === 'EA' ||
      state.agency.agencyChannel === 'IA') &&
    state.agentJwt &&
    state.accessToken &&
    !state.agency.isPrivateLabel
  );
});

export const getAgencyLoaded = createSelector(
  getAgencyState,
  fromAgency.getAgencyLoaded
);

export const getAgencyLoading = createSelector(
  getAgencyState,
  fromAgency.getAgencyLoading
);

export const isNSSAgent = createSelector(getAgencyState, state => {
  if (!state.agentJwt || !state.accessToken) {
    return false;
  }
  if (!state.agency) {
    return false;
  }
  if (state.agency.isPrivateLabel) {
    return false;
  }
  if (state.agency.agencyChannel === 'NSS') {
    return true;
  }
  return false;
});

export const isNSSOrDirect = createSelector(getAgency, agent => {
  if (!agent) {
    return true;
  }

  if (agent.agencyChannel === 'EA' || agent.agencyChannel === 'IA') {
    return false;
  }

  return true;
});

export const isPrivateLabel = createSelector(getAgency, agent =>
  agent ? agent.isPrivateLabel : false
);

export const isthirdParty = createSelector(
  fromUserContext.getUserContext,
  userContext => (userContext.thirdPartyId ? true : false)
);
export const isDirectUser = createSelector(
  getAgency,
  fromUserContext.getUserContext,
  (agent, userContext) =>
    !agent &&
    userContext.spid === 'Default' &&
    !userContext.thirdPartyId &&
    !userContext.initiatedBy
      ? true
      : false
);

export const getProducerCodes = createSelector(
  getAgencyState,
  agency => agency.producerCodes
);

export const getAgencyAccessToken = createSelector(
  getAgencyState,
  agency => agency.accessToken
);

export const getAgencyCodes = createSelector(getProducerCodes, codes => {
  if (!codes) {
    return [];
  }
  const producers = codes.producers;
  const agencyCodes = producers.map(producer => producer.agencyCode);

  return [...new Set(agencyCodes)];
});

export const getPDSCodes = createSelector(getProducerCodes, codes => {
  if (codes) {
    return codes.producers;
  } else {
    return [];
  }
});

function producerComparer(a: Producer, b: Producer) {
  const aId = a.producerCode;
  const bId = b.producerCode;

  if (aId > bId) {
    return 1;
  } else if (aId < bId) {
    return -1;
  } else {
    return 0;
  }
}

export const getProducerIdsByAgencyCode = (agencyCode: string) =>
  createSelector(getProducerCodes, codes => {
    if (!codes) {
      return [];
    }

    if (!agencyCode) {
      return [];
    }
    const producers = codes.producers;

    const agents = producers.filter(
      producer => producer.agencyCode === agencyCode
    );

    return agents
      .map(agent => {
        return {
          ...agent,
          producerCode: agent.producerCode.split('-')[1].trim(),
        };
      })
      .sort(producerComparer);
  });
