import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CmaResponse } from '@core/models/cma/cma-response.model';

import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';

@Injectable()
export class CmaDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  loadCustomerManagementAction(): void {
    this._store.dispatch(new fromActions.LoadCustomerManagementAction());
  }

  getCustomerManagementActionLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getCmaLoaded);
  }

  getCustomerManagementActionLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getCmaLoading);
  }

  getCustomerManagementAction(): Observable<CmaResponse> {
    return this._store.select(fromSelectors.getCma);
  }
}
