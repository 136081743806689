import { DisplayPanelModule } from './components/display-panel/display-panel.module';
import { LoadingSpinnerModule } from './components/loading-spinner/loading-spinner.module';
import { NavigationButtonsModule } from './components/navigation-buttons/navigation-buttons.module';
import { AddressInputModule } from './components/address-input/address-input.module';
import { CaptchaButtonModule } from './components/captcha-button/captcha-button.module';
import { ProductsModule } from './components/products/products.module';
import { CoveragesModule } from './components/coverages/coverages.module';
import { TogglePillModule } from './components/toggle-pill/toggle-pill.module';
import { AddressLongInputModule } from './components/address-long-input/address-long-input.module';
import { FieldValidationMessageModule } from './components/field-validation-message/field-validation-message.module';
import { PropertyInfoModule } from '../quoting/components/property-info/property-info.module';
import { QueryParameterPreservingRedirectModule } from './components/query-parameter-perserving-redirect/query-parameter-preserving-redirect.module';
import { PicklistSelectModule } from './components/picklist-select/picklist-select.module';
import { PhoneNumberModule } from './directives/phone-number/phone-number.module';
import { RequiredIfModule } from './directives/required-if/required-if.module';
import { IsAgentModule } from './directives/is-agent/is-agent.module';
import { IsInternetAgentModule } from './directives/is-internet-agent/is-internet-agent.module';
import { IsNssAgentModule } from './directives/is-nss-agent/is-nss-agent.module';
import { IsExclusiveAgentModule } from './directives/is-exclusive-agent/is-exclusive-agent.module';
import { IsIndependentAgentModule } from './directives/is-independent-agent/is-independent-agent.module';
import { IsCustomerModule } from './directives/is-customer/is-customer.module';
import { RequiredIfTrueModule } from './directives/required-if-true/required-if-true.module';
import { SvgIconModule } from './directives/svg-icon/svg-icon.module';
import { RemoveIfFeatureNotEffectiveModule } from './directives/remove-if-feature-not-effective/remove-if-feature-not-effective.module';
import { ValidationReceptacleModule } from './directives/validation-receptacle/validation-receptacle.module';
import { UnCamelCaseModule } from './pipes/un-camel-case/un-camel-case.module';
import { SafeUrlModule } from './pipes/safe-url/safe-url.module';
import { NumberOfGarageCarsModule } from './pipes/number-of-garage-cars/number-of-garage-cars.module';
import { IsDirectOrNssAgentModule } from './directives/is-direct-or-nss-agent/is-direct-or-nss-agent.module';
import { IsIndependentOrExclusiveAgentModule } from './directives/is-independent-or-exclusive-agent/is-independent-or-exclusive-agent.module';
import { AllProtectiveDevicesModule } from './pipes/all-protective-devices/all-protective-devices.module';
import { IsCondoModule } from './directives/is-condo/is-condo.module';
import { IsHomeOwnerModule } from './directives/is-homeowner/is-homeowner.module';
import { EditCoveragesConsentModule } from './components/coverages/edit-coverages/edit-coverages-consent.module';
import { BillingPaymentPlanModule } from './components/billing/billing-payment-plan.module';
import { ProgressTrackerModule } from './components/progress-tracker/progress-tracker.module';

export const modules: any[] = [
  DisplayPanelModule,
  NavigationButtonsModule,
  LoadingSpinnerModule,
  CaptchaButtonModule,
  PhoneNumberModule,
  AddressInputModule,
  AddressLongInputModule,
  FieldValidationMessageModule,
  PropertyInfoModule,
  RequiredIfModule,
  ProductsModule,
  CoveragesModule,
  IsAgentModule,
  IsInternetAgentModule,
  IsNssAgentModule,
  IsExclusiveAgentModule,
  IsIndependentAgentModule,
  IsIndependentOrExclusiveAgentModule,
  IsCustomerModule,
  IsDirectOrNssAgentModule,
  RequiredIfTrueModule,
  TogglePillModule,
  SvgIconModule,
  ValidationReceptacleModule,
  UnCamelCaseModule,
  RemoveIfFeatureNotEffectiveModule,
  SafeUrlModule,
  AllProtectiveDevicesModule,
  NumberOfGarageCarsModule,
  RemoveIfFeatureNotEffectiveModule,
  QueryParameterPreservingRedirectModule,
  PicklistSelectModule,
  IsCondoModule,
  IsHomeOwnerModule,
  EditCoveragesConsentModule,
  BillingPaymentPlanModule,
  ProgressTrackerModule,
];

export * from './components/billing/billing-payment-plan.module';
export * from './components/display-panel/display-panel.module';
export * from './components/loading-spinner/loading-spinner.module';
export * from './components/navigation-buttons/navigation-buttons.module';
export * from './components/horizontal-slider/horizontal-slider.module';
export * from './components/address-input/address-input.module';
export * from './components/products/products.module';
export * from './components/coverages/coverages.module';
export * from './components/toggle-pill/toggle-pill.module';
export * from './components/address-long-input/address-long-input.module';
export * from './components/field-validation-message/field-validation-message.module';
export * from '../quoting/components/property-info/property-info.module';
export * from './components/error-settable-component';
export * from './components/picklist-select/picklist-select.component';
export * from './utils/mocks/MockProductsService';
export * from './utils/mocks/MockCoverageService';

export * from './directives/phone-number/phone-number.module';
export * from './directives/required-if/required-if.module';
export * from './directives/is-agent/is-agent.module';
export * from './directives/is-customer/is-customer.module';
export * from './directives/required-if-true/required-if-true.module';
export * from './directives/is-exclusive-agent/is-exclusive-agent.module';
export * from './directives/is-independent-agent/is-independent-agent.module';
export * from './directives/is-independent-or-exclusive-agent/is-independent-or-exclusive-agent.module';
export * from './directives/is-nss-agent/is-nss-agent.module';
export * from './directives/is-internet-agent/is-internet-agent.module';
export * from './directives/is-direct-or-nss-agent/is-direct-or-nss-agent.module';
export * from './directives/svg-icon/svg-icon.module';
export * from './directives/remove-if-feature-not-effective/remove-if-feature-not-effective.module';
export * from './directives/validation-receptacle/validation-receptacle.module';
export * from './directives/is-condo/is-condo.module';

export * from './pipes/un-camel-case/un-camel-case.module';
export * from './pipes/safe-url/safe-url.module';
export * from './pipes/all-protective-devices/all-protective-devices.module';
export * from './pipes/number-of-garage-cars/number-of-garage-cars.module';
export * from './directives/is-homeowner/is-homeowner.module';
export * from './components/coverages/edit-coverages/edit-coverages-consent.module';
export * from './components/progress-tracker/progress-tracker.module';
