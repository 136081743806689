import { Action } from '@ngrx/store';

export const NAVIGATE_FORWARD = '[Billing] Navigate Forward';
export const NAVIGATE_BACK = '[Billing] Navigate Previous';

export class NavigateForward implements Action {
  readonly type = NAVIGATE_FORWARD;
  constructor(public payload: string) {}
}

export class NavigateBack implements Action {
  readonly type = NAVIGATE_BACK;
  constructor(public payload: string) {}
}

export type NavigationActions = NavigateForward | NavigateBack;
