import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import * as fromRouter from '../../../core/store/actions/router.action';

import * as fromActions from '../actions';

@Injectable()
export class NavigationEffects {
  constructor(private _actions$: Actions) {}

  @Effect()
  navigateForward$ = this._actions$.pipe(
    ofType(fromActions.NAVIGATE_FORWARD),
    map((action: fromActions.NavigateForward) => action.payload),
    map(url => {
      return new fromRouter.Go({ path: [url] });
    })
  );

  @Effect()
  navigateBackward$ = this._actions$.pipe(
    ofType(fromActions.NAVIGATE_BACK),
    map((action: fromActions.NavigateBack) => action.payload),
    map(url => new fromRouter.Go({ path: [url] }))
  );
}
