import { Action } from '@ngrx/store';
import { DiscountEntity } from '@core/models/entities/discount.entity';

export const enum DiscountActionTypes {
  UPDATE_ALL_DISCOUNTS = '[Discount] Update All Discounts',
  UPDATE_ALL_POWERSPORTS_DISCOUNTS = '[Discount] Update All Powersports Discounts',
  ADD_MANY_DISCOUNTS = '[Discount] Add Many Discounts',
}

export class UpdateAllDiscounts implements Action {
  readonly type = DiscountActionTypes.UPDATE_ALL_DISCOUNTS;
  constructor(public payload: DiscountEntity[]) {}
}
export class UpdateAllPowersportsDiscounts implements Action {
  readonly type = DiscountActionTypes.UPDATE_ALL_POWERSPORTS_DISCOUNTS;
  constructor(public payload: DiscountEntity[]) {}
}
export class AddManyDiscounts implements Action {
  readonly type = DiscountActionTypes.ADD_MANY_DISCOUNTS;
  constructor(public payload: DiscountEntity[]) {}
}
export type DiscountActions =
  | UpdateAllDiscounts
  | UpdateAllPowersportsDiscounts
  | AddManyDiscounts;
