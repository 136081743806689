<p *ngIf="alert">
  <ngb-alert
    [type]="alert.type"
    [dismissible]="alert.dismissible"
    (close)="close()"
    class="alert-display nw-container"
  >
    <div class="alert">
      <icon [class]="alert.icon"></icon>
      <span [innerHTML]="alert.message"></span>
    </div>
  </ngb-alert>
</p>
