<form [formGroup]="retrieveForm" (ngSubmit)="onSubmit(retrieveForm)">
  <mq-field-validation-message
    [form]="retrieveForm"
    [complainAboutRequiredFields]="formWasSubmitted$ | async"
  ></mq-field-validation-message>
  <div class="nw-container nw-inner-bun">
    <div
      *ngIf="isRetrieveByQuoteId && errorMessage"
      class="row error-alert"
    >
      <div class="header alert alert-danger col" role="alert">
        <a class="icon error"></a>
        <span>{{ errorMessage }}</span>
      </div>
    </div>
    <ng-container *ngIf="retrieveForm?.errors?.submissionDetailsRequired">
      <div class="header alert alert-danger col" role="alert">
        <a class="icon error"></a>
        <span>{{ retrieveFormText.ADD_SUBMISSION_DETAILS }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="retrieveForm?.errors?.multipleSubmissionDetailsMatch">
      <div class="header alert alert-danger col" role="alert">
        <a class="icon error"></a>
        <span>{{ retrieveFormText.MULTIPLE_SUBMISSION_DETAILS }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="retrieveForm?.errors?.missingSubmissionId">
      <div class="header alert alert-danger col" role="alert">
        <a class="icon error"></a>
        <span>{{ retrieveFormText.MISSING_SUBMISSION_ID }}</span>
      </div>
    </ng-container>
    <div class="row">
      <h4 class="page-sub-title col-8">Retrieve a Quote</h4>
      <a
        [routerLink]="'/multi-quote/getting-started'"
        (click)="clearErrors.emit()"
        class="col-4 text-right"
        >Start a new quote</a
      >
    </div>

    <div *ngIf="isRetrieveByQuoteId" id="retrieveByQuoteId">
      <div class="row mb-4 col-sm-12">
        <div id="retrieve-by-submissionid-text">
          Please input the submission ID's for quotes to retrieve, otherwise
          select Retrieve without Submission ID to see all products quoted.
        </div>
      </div>

      <div class="row mb-4">
        <div class="form-group col-sm-4">
          <label for="input-lastName"
            >Last Name <span class="required-asterisk">*</span></label
          >
          <div class="input-group">
            <input
              type="text"
              id="input-lastName"
              formControlName="lastName"
              class="form-control"
            />
              <div class="input-group-text"></div>
          </div>
        </div>

        <div class="form-group col-sm-4">
          <label for="input-postalCode"
            >Postal Code <span class="required-asterisk">*</span>
          </label>
          <div class="input-group">
            <input
              type="text"
              id="input-postalCode"
              formControlName="postalCode"
              class="form-control"
            />
              <div class="input-group-text"></div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <nw-display-item-list
            class="row"
            *ngFor="let quoteDetails of submissionDetails"
          >
            <nw-display-item class="dispcol">
              <nw-display-panel
                [@create]="quoteDetails?.eventType"
                [heading]="'SubmissionId: ' + quoteDetails.submissionId"
                [subheading]="getSubHeading(quoteDetails)"
                openText="Close"
                closedText="Edit"
                [item]="quoteDetails"
                [loadOpen]="false"
                (productDeleted)="onSubmissionDetailsDeleted($event)"
                [isDeletable]="true"
              >
                <mq-submission-details-form
                  [submissionDetails]="submissionDetails"
                  [quoteDetails]="quoteDetails"
                  (submissionDetailsSubmit)="onsubmissionDetailsSubmit($event)"
                  (multipleSubmissionDetailsMatch)="
                    onMultipleSubmissionDetailsMatch($event)
                  "
                  (submissionIdMissing)="validateSubmissionId($event)"
                >
                </mq-submission-details-form>
              </nw-display-panel>
            </nw-display-item>
          </nw-display-item-list>
        </div>
      </div>

      <div>
        <div *ngIf="hasValidSubmissionDetailscount()">
          <nw-display-item-list class="row">
            <nw-display-item class="dispcol">
              <nw-display-panel
                heading="Add Submission Details"
                openText="Close"
                closedText="Add"
              >
                <mq-submission-details-form
                  [newSubmissionDetails]="true"
                  [submissionDetails]="submissionDetails"
                  (submissionDetailsSubmit)="onsubmissionDetailsSubmit($event)"
                  (multipleSubmissionDetailsMatch)="
                    onMultipleSubmissionDetailsMatch($event)
                  "
                  (submissionIdMissing)="validateSubmissionId($event)"
                >
                </mq-submission-details-form>
              </nw-display-panel>
            </nw-display-item>
          </nw-display-item-list>
        </div>
      </div>

      <div class="row text-right-side">
        <p>
          Or
          <a
            [routerLink]="'/retrieve'"
            (click)="changeIsRetrieveByQuoteIdFlag()"
            >Retrieve without Submission ID</a
          >
        </p>
      </div>
    </div>
    <!-- Retrieve without quote Id Below Here-->
    <div *ngIf="!isRetrieveByQuoteId" id="retrieveWithoutQuoteId">
      <div class="row mb-4 g-2">
        <div class="form-group col-sm-12 col-md-6 col-lg-6">
          <label for="input-lastName"
            >Last Name <span class="required-asterisk">*</span>
          </label>
          <div class="input-group">
            <input
              type="text"
              id="input-lastName"
              formControlName="lastName"
              class="form-control"
            />
              <div class="input-group-text"></div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-md-6 col-lg-6">
          <label for="input-postalCode"
            >Postal Code <span class="required-asterisk">*</span>
          </label>
          <div class="input-group">
            <input
              type="text"
              id="input-postalCode"
              formControlName="postalCode"
              class="form-control"
            />
              <div class="input-group-text"></div>
          </div>
        </div>
      </div>
      <div class="row mb-4 g-2">
        <div class="form-group col-sm-12 col-md-6 col-lg-6">
          <label for="input-emailId"
            >Email ID <span class="required-asterisk">*</span>
          </label>
          <div class="input-group">
            <input
              type="text"
              id="input-emailId"
              formControlName="emailId"
              class="form-control"
            />
              <div class="input-group-text"></div>
          </div>
        </div>

        <div class="form-group col-sm-12 col-md-6 col-lg-6">
          <label for="input-dateOfBirth"
            >Date of Birth <span class="required-asterisk">*</span>
          </label>
          <div class="input-group">
            <input
              type="text"
              id="input-dateOfBirth"
              formControlName="dateOfBirth"
              mask="00/00/0000"
              [patterns]="customMaskPatterns"
              placeholder="MM/DD/YYYY"
              class="form-control"
              maxlength="10"
            />
              <div class="input-group-text"></div>
          </div>
        </div>
      </div>
      <div class="row" formGroupName="allChoices">
        <div class="form-group col-sm">
          <label id="productChoiceLabel"
            >Please select at least one product
            <span class="required-asterisk">*</span></label
          >
          <div class="pill-wrapper">
            <label
              class="checkbox-wrapper"
              *ngFor="let productChoice of productChecks; let i = index"
            >
              <input
                class="icon checkmark"
                type="checkbox"
                [value]="productChoice.value"
                (change)="onCheckChange($event)"
              />
              <div>{{ productChoice.description }}</div>
            </label>
            <div id="preventMultipleProperty-btn">
              <label
                class="checkbox-wrapper"
                for="preventMultiplePropertyHomeowners"
              >
                <input
                  class="icon checkmark"
                  id="preventMultiplePropertyHomeowners"
                  type="radio"
                  formControlName="preventMultipleProperty"
                  value="2"
                  (click)="togglePreventMultipleProperty($event)"
                />
                Homeowners
              </label>
              <label
                for="preventMultiplePropertyRenters"
                class="checkbox-wrapper"
              >
                <input
                  id="preventMultiplePropertyRenters"
                  type="radio"
                  class="icon checkmark"
                  formControlName="preventMultipleProperty"
                  value="3"
                  (click)="togglePreventMultipleProperty($event)"
                />
                Renters
              </label>
              <label
                for="preventMultiplePropertyCondo"
                class="checkbox-wrapper"
              >
                <input
                  id="preventMultiplePropertyCondo"
                  type="radio"
                  class="icon checkmark"
                  formControlName="preventMultipleProperty"
                  value="4"
                  (click)="togglePreventMultipleProperty($event)"
                />
                Condo
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-right-side">
        <p>
          Or
          <a
            [routerLink]="'/retrieve'"
            (click)="changeIsRetrieveByQuoteIdFlag()"
            >Retrieve with Submission ID</a
          >
        </p>
      </div>
    </div>
  </div>

  <nw-navigation-buttons
    *isAgent
    [forwardOnly]="true"
    [isAppLoading]="isAppLoading"
    [submitButtonText]="'Continue'"
  ></nw-navigation-buttons>

  <ng-container *ngIf="!showRecaptcha">
    <nw-navigation-buttons
      *isCustomer
      [forwardOnly]="true"
      [isAppLoading]="isAppLoading"
      [submitButtonText]="'Continue'"
    ></nw-navigation-buttons>
  </ng-container>

  <ng-container *ngIf="showRecaptcha">
    <mq-captcha-button
      *isCustomer
      [forwardOnly]="true"
      [isAppLoading]="isAppLoading"
      (forward)="onForward($event)"
    ></mq-captcha-button>
  </ng-container>
</form>
