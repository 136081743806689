<form [formGroup]="form">
  <div class="row">
    <h1 class="heading-h3 col-12">
      {{ homeownerText.PROPERTY_INFO.TELL_US }}
    </h1>
  </div>
  <div class="row">
    <div class="form-group col-md-5 col-sm-auto">
      <legend>Is there a mortgage on this condo?</legend>
      <div
        class="btn-group btn-group-toggle d-flex"
        id="hasMortgage-error"
        formControlName="hasMortgage"
        ngDefaultControl
      >
        <input
          class="btn-check"
          type="radio"
          aria-describedby="hasMortgage-error"
          [value]="true"
          name="hasMortgage"
          formControlName="hasMortgage"
          id="pi-has-mortgage-yes"
        />
        <label
          for="pi-has-mortgage-yes"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle: form.value.hasMortgage == true
          }"
        >
          Yes
        </label>
        <input
          class="btn-check"
          type="radio"
          aria-describedby="hasMortgage-error"
          [value]="false"
          name="hasMortgage"
          formControlName="hasMortgage"
          id="pi-has-mortgage-no"
        />
        <label
          for="pi-has-mortgage-no"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle: form.value.hasMortgage == false
          }"
        >
          No
        </label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-5 col-sm-12">
      <label for="pi-year-built"> When was your condo built? </label>
      <div class="d-flex">
        <div class="d-flex flex-column">
          <div class="input-group" name="yearBuilt" id="yearBuilt-error">
            <input
              id="pi-year-built"
              type="text"
              class="form-control"
              placeholder="YYYY"
              maxlength="4"
              mask="0000"
              [dropSpecialCharacters]="false"
              aria-describedby="yearBuilt-error"
              name="yearBuilt"
              formControlName="yearBuilt"
            />
            <div class="input-group-text"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group col-md-5 col-sm-12">
      <label for="pi-estimated-year-purchased"> Condo Purchase Date? </label>
      <div class="d-flex">
        <div class="d-flex flex-column" id="estimatedYearPurchased-error">
          <div class="input-group">
            <input
              id="pi-estimated-year-purchased"
              type="text"
              class="form-control"
              placeholder="MM/YYYY"
              maxlength="7"
              mask="00/0000"
              [dropSpecialCharacters]="false"
              aria-describedby="estimatedYearPurchased-error"
              formControlName="estimatedYearPurchased"
            />
            <div class="input-group-text"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-5 col-sm-auto">
      <legend>
        <label for="numberOfHalfBathrooms">Number of half baths</label>
      </legend>
      <div class="input-group">
        <select
          id="numberOfHalfBathrooms"
          class="form-control form-select"
          attr.aria-describedby="numberOfHalfBathrooms-error"
          formControlName="numberOfHalfBathrooms"
        >
          <option value="" disabled="disabled">Please Select</option>

          <option
            *ngFor="
              let halfbath of options?.homeFeatures?.numberOfHalfBathrooms
            "
            value="{{ halfbath }}"
          >
            {{ halfbath }}
          </option>
        </select>
        <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-md-5 col-sm-auto">
      <legend>
        <label for="numberOfFullBathrooms">Number of full baths</label>
      </legend>
      <div class="input-group">
        <select
          id="numberOfFullBathrooms"
          class="form-control form-select"
          attr.aria-describedby="numberOfFullBathrooms-error"
          formControlName="numberOfFullBathrooms"
        >
          <option value="" [selected]="true" disabled="disabled">
            Please Select
          </option>
          <option
            *ngFor="let fullbath of options?.homeFeatures?.numberOfFullbaths"
            value="{{ fullbath }}"
          >
            {{ fullbath }}
          </option>
        </select>
        <div class="input-group-text"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-5 col-sm-auto">
      <legend>
        <label for="roofCondition">How is the condition of your roof?</label>
      </legend>
      <div class="input-group">
        <select
          id="roofCondition"
          class="form-control form-select"
          attr.aria-describedby="roofCondition-error"
          formControlName="roofCondition"
        >
          <option value="" [selected]="true" disabled="disabled">
            Please Select
          </option>
          <option
            *ngFor="let option of options?.homeStructuralDetails?.roofCondition"
            value="{{ option.value }}"
          >
            {{ option.label }}
          </option>
        </select>
        <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-md-5 col-sm-auto">
      <legend>
        <label for="constructionType"
          >What was the type of construction used?</label
        >
      </legend>
      <div class="input-group">
        <select
          id="constructionType"
          class="form-control form-select"
          attr.aria-describedby="constructionType-error"
          formControlName="constructionType"
        >
          <option value="" [selected]="true" disabled="disabled">
            Please Select
          </option>
          <option
            *ngFor="
              let option of options?.homeStructuralDetails?.constructionTypes
            "
            value="{{ option.value }}"
          >
            {{ option.label }}
          </option>
        </select>
        <div class="input-group-text"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-12 no-gutters">
      <legend>
        <label for="unitsInBuilding"
          >How many separate units are contained in the building?</label
        >
      </legend>
      <div class="input-group col-md-6 col-sm-12">
        <select
          id="unitsInBuilding"
          class="form-control form-select"
          attr.aria-describedby="unitsInBuilding-error"
          formControlName="unitsInBuilding"
        >
          <option value="" [selected]="true" disabled="disabled">
            Please Select
          </option>
          <option
            *ngFor="let option of options?.unitsInBuilding"
            value="{{ option.value }}"
          >
            {{ option.label }}
          </option>
        </select>
        <div class="input-group-text"></div>
      </div>

      <p class="help-text" id="personalPropertyHelpText1">
        A unit is part of a structure designed as a complete living quarters and
        does not share access with other units within the same structure.
      </p>
    </div>
  </div>
</form>
