import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromReducer from '@core/store/reducers/driver-vehicle-assignment.reducer';
import * as fromProduct from '@core/store/selectors/products.selector';
import { ReassignDvaDriverRequest } from '@core/models/auto/driver-vehicle-assignment.model';
import { DriverVehicleAssignmentEntity } from '@core/models/entities/driver-vehicle-assignment.entity';
import { ProductTypes } from '@shared/constants/app-constants';

export const getDriverVehicleAssignmentState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.driverVehicleAssignment
);

export const {
  selectAll: selectAllDriverVehicleAssignments,
  selectEntities: selectDriverVehicleAssignmentEntities,
} = fromReducer.adapter.getSelectors(getDriverVehicleAssignmentState);

export const buildDriverVehicleAssignmentRequest = (productId: string) =>
  createSelector(fromProduct.getProduct(productId), product => {
    return {
      productId,
      quoteId: product.quoteId,
    };
  });

/* eslint-disable eqeqeq */
// We've had problems with these IDs being sometimes strings and sometimes numbers.
// Deliberately using == instead of === to accomodate that.

export const getDriverVehicleAssignment = (
  driverId: string,
  vehicleId: string
) =>
  createSelector(selectAllDriverVehicleAssignments, assignments =>
    assignments.find(a => a.driverId == driverId && a.vehicleId == vehicleId)
  );

export const reassignDriver = (request: ReassignDvaDriverRequest) =>
  createSelector(selectAllDriverVehicleAssignments, assignments => {
    return [
      ...assignments.filter(a => a.driverId != request.driverId),
      ..._applyPrimary(
        request.vehicleIds.map(vid => ({
          driverId: request.driverId,
          vehicleId: vid,
          isPrimaryDriver: false,
          isPrimaryVehicle: false,
        })),
        request.driverId,
        request.primaryVehicleId
      ),
    ];
  });

export const makeApiRequestForSendStore = createSelector(
  selectAllDriverVehicleAssignments,
  fromProduct.getProduct(ProductTypes.AUTO),
  (assignments, product) => ({
    quoteId: product.quoteId,
    entities: assignments,
  })
);

export const getDriverVehicleAssignmentsLoading = createSelector(
  getDriverVehicleAssignmentState,
  state => state.loading
);

export const getDriverVehicleAssignmentsErrorCode = createSelector(
  getDriverVehicleAssignmentState,
  state => state.errorCode
);

export enum DvaCallStatus {
  FAILED,
  PENDING,
  OK, // success or never sent
}

export const getDriverVehicleAssignmentCallStatus = createSelector(
  getDriverVehicleAssignmentState,
  state => {
    if (state.loading) {
      return DvaCallStatus.PENDING;
    }
    if (state.errorCode) {
      return DvaCallStatus.FAILED;
    }
    return DvaCallStatus.OK;
  }
);

function _applyPrimary(
  entities: DriverVehicleAssignmentEntity[],
  primaryDriverId: string,
  primaryVehicleId: string
): DriverVehicleAssignmentEntity[] {
  if (primaryDriverId && primaryVehicleId) {
    for (const entity of entities) {
      entity.isPrimaryDriver =
        entity.driverId == primaryDriverId &&
        entity.vehicleId == primaryVehicleId;
      entity.isPrimaryVehicle = entity.isPrimaryDriver;
    }
  }
  return entities;
}
