import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { StringUtils } from '@shared/utils/string.utils';

@Directive({
  selector: '[requiredIfTrue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RequiredIfTrueDirective),
      multi: true,
    },
  ],
})
export class RequiredIfTrueDirective implements Validator {
  @Input()
  condition: boolean;
  constructor(@Attribute('requiredIfTrue') public requiredIfTrue: string) {}

  validate(control: AbstractControl): ValidationErrors {
    const value = control.value;
    const comparator = control.root.get(this.requiredIfTrue);

    if (
      comparator &&
      (comparator.value === 'Y' || comparator.value === true) &&
      StringUtils.isEmpty(value)
    ) {
      return { required: true };
    }

    if (this.condition) {
      return { required: true };
    }

    return null;
  }
}
