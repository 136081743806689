import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import {
  switchMap,
  catchError,
  withLatestFrom,
  filter,
  take,
  tap,
  map,
  mergeMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store/reducers';
import { LoggingService, PolicyService } from '@core/services';
import {
  CoveredLocationActionTypes,
  UpdateCoveredLocationSuccess,
  UpdateCoveredLocation,
  UpdateCoveredLocationFail,
} from '@core/store/actions/covered-location.action';
import { CoveredLocationAdapter } from '@core/adapters/covered-location.adapter';
import { getProduct, getPolicyAddress } from '@core/store/selectors';
import {
  DEFAULT_ID,
  PageAlertIDs,
  ProductTypes,
} from '@shared/constants/app-constants';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { getCoveredLocationData } from '@core/store/selectors/covered-location.selector';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { Product } from '@core/models/products/product.model';
import {
  AddRecoverableError,
  GetProtectiveDevices,
  InvalidateQuote,
  PatchQuote,
} from '@core/store/actions';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { PropertyHelper } from '@core/services/helpers/property.helper';
import { ErrorMessageService } from '@core/services/error-message.service';
import { PolicyEffectiveDates } from '@core/models/policy/policy-effective-date.model';
import { formatDate } from '@angular/common';
import { DateUtils } from '@shared/utils/date.utils';

@Injectable()
export class CoveredLocationEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _loggingService: LoggingService,
    private _coveredLocationAdapter: CoveredLocationAdapter,
    private _propertyHelper: PropertyHelper,
    private _errorHelper: ErrorHelper,
    private _errorMessageService: ErrorMessageService,
    private _policyService: PolicyService
  ) {}

  @Effect()
  getCoveredLocation$ = this._actions$.pipe(
    ofType(CoveredLocationActionTypes.GET_COVERED_LOCATION),
    switchMap((action: fromActions.GetCoveredLocation) =>
      this._store.select(fromSelectors.getProduct(action.payload)).pipe(
        filter(product => product && !!product.quoteId),
        take(1)
      )
    ),
    switchMap((product: Product) => {
      return this._coveredLocationAdapter.getCoveredLocation(product).pipe(
        tap(response =>
          this._loggingService.log('getCoveredLocation Reponse', response)
        ),
        switchMap(response =>
          from([new fromActions.GetCoveredLocationSuccess(response)])
        ),
        catchError(error => {
          return from([
            new fromActions.GetCoveredLocationFail(
              this._errorHelper.sanitizeError(error)
            ),
          ]);
        })
      );
    })
  );

  @Effect()
  updateCoveredLocation$ = this._actions$.pipe(
    ofType(CoveredLocationActionTypes.UPDATE_COVERED_LOCATION),
    mergeMap((action: UpdateCoveredLocation) => {
      return this._store.select(getProduct(action.productId)).pipe(
        filter(product => !!product && !!product.quoteLoaded),
        withLatestFrom(
          this._store.select(getPolicyAddress(DEFAULT_ID)),
          this._store.select(getCoveredLocationData)
        ),
        take(1),
        map(([product, address, previousCoveredLocation]) => {
          return [
            product,
            this._propertyHelper.sanitizeCoveredLocationRequest({
              ...previousCoveredLocation,
              ...action.coveredLocation,
              location: {
                addressLine1: address.addressLine1,
                city: address.city,
                state: address.state,
                postalCode: address.postalCode,
              },
            }),
          ];
        }),
        tap(([product, coveredLocationRequest]) =>
          this._loggingService.log(
            'updateCoveredLocation Request',
            coveredLocationRequest
          )
        ),
        mergeMap(([product, coveredLocationRequest]) =>
          this._coveredLocationAdapter.updateCoveredLocation(
            product,
            coveredLocationRequest
          )
        ),
        tap(response =>
          this._loggingService.log('updateCoveredLocation Response', response)
        ),
        mergeMap((response: CoveredLocation) => {
          const actions = [];
          const needToPatch = this.shouldPatchPropertyEffectiveDate(
            response.datePurchased
          );
          const patchRequest = { effectiveDate: response.datePurchased };
          if (needToPatch) {
            actions.push(new PatchQuote(response.productId, patchRequest));
            this._policyService.setPolicyEffectiveDate(
              this.policyEffectiveDateForProperty(response.datePurchased)
            );
          }
          actions.push(new GetProtectiveDevices());
          actions.push(new InvalidateQuote(response.productId));
          actions.push(new UpdateCoveredLocationSuccess(response));

          return from(actions);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updateCoveredLocation Error', safeError);
          return this._errorMessageService
            .composeRecoverableError(
              '',
              safeError,
              PageAlertIDs['property-info']
            )
            .pipe(
              take(1),
              mergeMap(rerror =>
                from([
                  new UpdateCoveredLocationFail(safeError),
                  new AddRecoverableError(rerror),
                ])
              )
            );
        })
      );
    })
  );

  policyEffectiveDateForProperty(input: string): PolicyEffectiveDates {
    const format = 'MM/dd/yyyy';
    const myDate = input;
    const locale = 'en-US';
    const formattedDate = formatDate(myDate, format, locale);

    let initialized = false;
    let autoEffectiveDate = '';
    let homeownersEffectiveDate = '';
    let condoEffectiveDate = '';
    let rentersEffectiveDate = '';
    let baseEffectiveDate = '';
    this._policyService
      .getPolicyEffectiveDate()
      .subscribe(effDate => {
        initialized = effDate.initialized;
        autoEffectiveDate = effDate.autoEffectiveDate;
        homeownersEffectiveDate = effDate.homeownersEffectiveDate;
        condoEffectiveDate = effDate.condoEffectiveDate;
        rentersEffectiveDate = effDate.rentersEffectiveDate;
        baseEffectiveDate = effDate.baseEffectiveDate;
      })
      .unsubscribe();
    try {
      const updated = {
        initialized: initialized,
        autoEffectiveDate: autoEffectiveDate,
        homeownersEffectiveDate: formattedDate,
        condoEffectiveDate: formattedDate,
        rentersEffectiveDate: formattedDate,
        baseEffectiveDate: baseEffectiveDate,
      };
      return updated;
    } catch (e) {
      return null;
    }
  }

  shouldPatchPropertyEffectiveDate(datePurchased: string): boolean {
    // The effective date of the policy can only be on or after the purchase date of the property
    const efString = DateUtils.setInitialPolicyEffectiveDate();
    // From String to Date
    const policyEffectiveDate = new Date(efString);
    const purchaseDate = new Date(datePurchased);
    // Comparing Dates
    return purchaseDate > policyEffectiveDate ? true : false;
  }
}
