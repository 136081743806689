import { EntityState, createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { RecoverableErrorEntity } from '@core/models/recoverable-error/recoverable-error.entity';
import * as fromAction from '@core/store/actions/recoverable-error.action';

export interface RecoverableErrorState
  extends EntityState<RecoverableErrorEntity> {
  nextId: number;
}

export const adapter: EntityAdapter<RecoverableErrorEntity> =
  createEntityAdapter({});

export function reducer(
  state = {
    ...adapter.getInitialState(),
    nextId: 1,
  },
  action: fromAction.RecoverableErrorAction
): RecoverableErrorState {
  switch (action.type) {
    case fromAction.ADD_RECOVERABLE_ERROR: {
      return adapter.addOne(
        {
          ...action.error,
          id: state.nextId,
        },
        {
          ...state,
          nextId: state.nextId + 1,
        }
      );
    }

    case fromAction.REMOVE_RECOVERABLE_ERROR: {
      return adapter.removeOne(action.id, state);
    }

    case fromAction.CLEAR_RECOVERABLE_ERRORS: {
      return adapter.removeAll(state);
    }

    default:
      return state;
  }
}
