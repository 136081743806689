import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  NavigationExtras,
  Params,
} from '@angular/router';
import { AgencyModel } from '@core/models/agency/agency.model';
import { AgencyService, LoggingService } from '@core/services';
import { AgencyProxy } from '@agency/proxies/agency.proxy';
import { StateUtils } from '@shared/utils/state.utils';

@Injectable()
export class AgencyGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _agencyService: AgencyService,
    private _loggingService: LoggingService,
    private _proxy: AgencyProxy
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const agencyModel = this._buildAgencyModel(route.queryParams);

    if (
      ['NSS', 'EA', 'IA'].includes(agencyModel.agencyChannel) &&
      this._allFieldsPopulated(agencyModel)
    ) {
      if (agencyModel.agencyChannel === 'EA') {
        agencyModel.state = StateUtils.mapStateCodeToState(
          agencyModel.producerCode.substring(0, 2)
        );
      }
      this._agencyService.updateAgency(agencyModel);
      this._loggingService.logAgent(agencyModel);
      this._proxy.updateQuoteState(agencyModel.quoteState);

      // TODO review
      const extras: NavigationExtras = {
        queryParams: {
          state: route.queryParams['state'],
        },
      };
      if (route.data && Object.keys(route.data).length !== 0) {
        return true;
      }

      this._router.navigate(['/multi-quote/getting-started'], extras);

      return false;
    } else {
      this._agencyService.updateAgency(agencyModel);
      return true;
    }
  }

  private _allFieldsPopulated(agencyModel: AgencyModel): boolean {
    switch (agencyModel.agencyChannel) {
      case 'NSS':
        return (
          !!agencyModel.firstName &&
          !!agencyModel.lastName &&
          !!agencyModel.producerCode &&
          !!agencyModel.userId &&
          !!agencyModel.agencyChannel
        );
      case 'EA':
        return (
          !!agencyModel.firstName &&
          !!agencyModel.lastName &&
          !!agencyModel.email &&
          !!agencyModel.producerCode &&
          !!agencyModel.userId &&
          !!agencyModel.agencyChannel
        );
      default:
        return (
          !!agencyModel.firstName &&
          !!agencyModel.lastName &&
          !!agencyModel.email &&
          !!agencyModel.producerCode &&
          !!agencyModel.state &&
          !!agencyModel.userId &&
          !!agencyModel.agencyChannel
        );
    }
  }

  private _buildAgencyModel(params: Params): AgencyModel {
    const agencyModel = {} as AgencyModel;

    if (params['userId'] !== undefined) {
      agencyModel.userId = params['userId'];
    }
    if (params['firstName'] !== undefined) {
      agencyModel.firstName = params['firstName'];
    }

    if (params['lastName'] !== undefined) {
      agencyModel.lastName = params['lastName'];
    }

    if (params['producerCode'] !== undefined) {
      agencyModel.producerCode = params['producerCode'];
    }

    if (params['agencyCode'] !== undefined) {
      agencyModel.agencyCode = params['agencyCode'];
    }

    if (params['email'] !== undefined) {
      agencyModel.email = params['email'];
    }

    if (params['state'] !== undefined) {
      agencyModel.quoteState = params['state'];
    }

    if (params['agencyState'] !== undefined) {
      agencyModel.state = params['agencyState'];
    }

    if (params['agencyChannel'] !== undefined) {
      agencyModel.agencyChannel = params['agencyChannel'];
    }

    return agencyModel;
  }
}
