import { Injectable } from '@angular/core';
import {
  DEFAULT_ID,
  DriverRelationToPNI,
  ModifierNames,
  PolicyholderTypes,
  ProductTypes,
} from '@shared/constants/app-constants';
import * as fromActions from '@core/store/actions';
import { Update } from '@ngrx/entity';
import {
  DriverEntity,
  PersonViewModel,
} from '@core/models/entities/driver.entity';
import { DriverRequest } from '@core/models/auto/quotes/driver-request.model';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';

@Injectable({
  providedIn: 'root',
})
export class DriverHelper {
  getAddDriverNextActions(
    request: DriverRequest,
    response: DriverEntity,
    unmaskedDOB: string
  ): any[] {
    const nextActions = [];
    response.eventType = 'create';
    response.policyHolderId =
      request.driver.relationToPrimaryNamedInsured === DriverRelationToPNI.PNI
        ? DEFAULT_ID
        : request.driver.policyHolderId;

    response = this.setDriveryEntity(request, response, unmaskedDOB);

    const eligibleDiscounts = this.getEligibleDiscounts(
      response.eligibleDiscounts,
      response.driverId,
      request.productId
    );

    nextActions.push(new fromActions.AddAllModifiers(eligibleDiscounts));

    const driverTrainingModifier = eligibleDiscounts.find(
      modifier => modifier.eligibleDiscountId === ModifierNames.DRIVER_TRAINING
    );
    if (request.driver.driverTraining && driverTrainingModifier) {
      const driverTraining = {
        ...driverTrainingModifier,
      };
      driverTraining.eligibleDiscountId = ModifierNames.DRIVER_TRAINING;
      driverTraining.modelId = response.driverId;
      driverTraining.productId = request.productId;
      driverTraining.selectedOptionValue = request.driver.driverTraining + '';
      if (!driverTraining.qualifyingInformation) {
        driverTraining.qualifyingInformation = {
          trainingCourseCompletionDate:
            request.driver.trainingCourseCompletionDate,
        };
      } else {
        driverTraining.qualifyingInformation = {
          ...driverTraining.qualifyingInformation,
        };
      }
      nextActions.push(new fromActions.UpdateDriverDiscount(driverTraining));
    }

    const advancedDriverTrainingModifier = eligibleDiscounts.find(
      modifier =>
        modifier.eligibleDiscountId === ModifierNames.ADVANCED_DRIVER_TRAINING
    );
    if (
      request.driver.advancedDriverTraining &&
      advancedDriverTrainingModifier
    ) {
      const advancedDriverTraining = {
        ...advancedDriverTrainingModifier,
      };
      advancedDriverTraining.eligibleDiscountId =
        ModifierNames.ADVANCED_DRIVER_TRAINING;
      advancedDriverTraining.modelId = response.driverId;
      advancedDriverTraining.productId = request.productId;
      advancedDriverTraining.selectedOptionValue =
        request.driver.advancedDriverTraining + '';
      if (!advancedDriverTraining.qualifyingInformation) {
        advancedDriverTraining.qualifyingInformation = {
          advancedTrainingCourseCompletionDate:
            request.driver.advancedTrainingCourseCompletionDate,
        };
      } else {
        advancedDriverTraining.qualifyingInformation = {
          ...advancedDriverTraining.qualifyingInformation,
        };
      }
      nextActions.push(
        new fromActions.UpdateDriverDiscount(advancedDriverTraining)
      );
    }

    const usArmedForcesModifier = eligibleDiscounts.find(
      modifier => modifier.eligibleDiscountId === ModifierNames.US_ARMED_FORCES
    );
    // TODO: Need to check age....
    if (usArmedForcesModifier && request.driver.usArmedForces) {
      usArmedForcesModifier.eligibleDiscountId = ModifierNames.US_ARMED_FORCES;
      usArmedForcesModifier.modelId = response.driverId;
      usArmedForcesModifier.productId = request.productId;
      usArmedForcesModifier.selectedOptionValue =
        request.driver.usArmedForces + '';

      nextActions.push(
        new fromActions.UpdateDriverDiscount(usArmedForcesModifier)
      );
    }

    const associateModifier = eligibleDiscounts.find(
      modifier =>
        modifier.eligibleDiscountId === ModifierNames.ASSOCIATE_DISCOUNT
    );

    if (associateModifier && request.driver.associateNumber) {
      associateModifier.eligibleDiscountId = ModifierNames.ASSOCIATE_DISCOUNT;
      associateModifier.modelId = response.driverId;
      associateModifier.productId = request.productId;
      associateModifier.selectedOptionValue = 'true';
      associateModifier.qualifyingInformation = {
        associateNumber: request.driver.associateNumber,
      };

      nextActions.push(new fromActions.UpdateDriverDiscount(associateModifier));
    }

    const goodStudentModifier = eligibleDiscounts.find(
      modifier => modifier.eligibleDiscountId === ModifierNames.GOOD_STUDENT
    );

    if (typeof request.driver.goodStudent === 'boolean') {
      const modifier = {
        ...goodStudentModifier,
      };
      if (goodStudentModifier) {
        modifier.eligibleDiscountId = ModifierNames.GOOD_STUDENT;
        modifier.modelId = response.driverId;
        modifier.productId = request.productId;
        modifier.selectedOptionValue = request.driver.goodStudent + '';
        nextActions.push(new fromActions.UpdateDriverDiscount(modifier));
      }
      response.goodStudent = request.driver.goodStudent;
    }

    if (request.driver.person.driverOccupation) {
      nextActions.push(
        new fromActions.UpdateDriverDiscount({
          productId: request.productId,
          modelId: response.driverId,
          eligibleDiscountId: ModifierNames.GROUP_OCCUPATION,
          selectedOptionValue: request.driver.person.driverOccupation,
        })
      );
    }
    if (request.driver.person?.yearsOfMotorcycleAndOffRoadExp) {
      response.person.yearsOfMotorcycleAndOffRoadExp =
        request.driver.person?.yearsOfMotorcycleAndOffRoadExp;
    }
    if (request.driver.person?.hasMotorcycleEndorsement) {
      response.person.hasMotorcycleEndorsement =
        request.driver.person?.hasMotorcycleEndorsement;
    }
    if (request.driver.person?.endorsementDate) {
      response.person.endorsementDate = request.driver.person?.endorsementDate;
    }
    if (request.driver.person?.infractionDesc) {
      response.person.infractionDesc = request.driver.person?.infractionDesc;
    }
    const driverEntity = { ...response };

    if (request.driver.prefillId) {
      const updated: Update<PersonViewModel> = {
        changes: { selected: true },
        id: request.driver.prefillId,
      };
      nextActions.push(new fromActions.UpdatePrefillPersonSelected(updated));
    }

    if (request.driver.prepopulatedInsuredId && request.driver.driverTempId) {
      driverEntity.prepopulatedInsuredId = request.driver.prepopulatedInsuredId;
      driverEntity.driverTempId = request.driver.driverTempId;

      const updated: PersonViewModel = {
        ...request.driver,
        driverTempId: request.driver.driverTempId,
        selected: true,
      };
      nextActions.push(
        new fromActions.UpdatePrepopulatedDriverSelected(updated)
      );
    }

    if (request.driver.partialQuoteId && request.driver.driverTempId) {
      driverEntity.partialQuoteId = request.driver.partialQuoteId;
      driverEntity.driverTempId = request.driver.driverTempId;

      const updated: PersonViewModel = {
        ...request.driver,
        driverTempId: request.driver.driverTempId,
        selected: true,
      };
      nextActions.push(
        new fromActions.UpdatePartialQuoteDriverSelected(updated)
      );
    }
    nextActions.push(new fromActions.ClearDvaStore());
    nextActions.push(new fromActions.AddDriverSuccess(driverEntity));
    return nextActions;
  }

  getAddPowerSportsDriverNextActions(
    request: DriverRequest,
    response: DriverEntity,
    unmaskedDOB: string
  ): any[] {
    const nextActions = [];
    response.eventType = 'create';
    response.policyHolderId =
      request.driver.relationToPrimaryNamedInsured === DriverRelationToPNI.PNI
        ? DEFAULT_ID
        : request.driver.policyHolderId;

    response = this.setDriveryEntity(request, response, unmaskedDOB);

    const eligibleDiscounts = this.getEligibleDiscounts(
      response.eligibleDiscounts,
      response.driverId,
      request.productId
    );

    nextActions.push(new fromActions.AddAllModifiers(eligibleDiscounts));

    const driverTrainingModifier = eligibleDiscounts.find(
      modifier => modifier.eligibleDiscountId === ModifierNames.DRIVER_TRAINING
    );
    if (request.driver.driverTraining && driverTrainingModifier) {
      const driverTraining = {
        ...driverTrainingModifier,
      };
      driverTraining.eligibleDiscountId = ModifierNames.DRIVER_TRAINING;
      driverTraining.modelId = response.driverId;
      driverTraining.productId = request.productId;
      driverTraining.selectedOptionValue = request.driver.driverTraining + '';
      if (!driverTraining.qualifyingInformation) {
        driverTraining.qualifyingInformation = {
          trainingCourseCompletionDate:
            request.driver.trainingCourseCompletionDate,
        };
      } else {
        driverTraining.qualifyingInformation = {
          ...driverTraining.qualifyingInformation,
        };
      }
      nextActions.push(
        new fromActions.UpdatePowersportsDriverDiscount(driverTraining)
      );
    }

    const advancedDriverTrainingModifier = eligibleDiscounts.find(
      modifier =>
        modifier.eligibleDiscountId === ModifierNames.ADVANCED_DRIVER_TRAINING
    );
    if (
      request.driver.advancedDriverTraining &&
      advancedDriverTrainingModifier
    ) {
      const advancedDriverTraining = {
        ...advancedDriverTrainingModifier,
      };
      advancedDriverTraining.eligibleDiscountId =
        ModifierNames.ADVANCED_DRIVER_TRAINING;
      advancedDriverTraining.modelId = response.driverId;
      advancedDriverTraining.productId = request.productId;
      advancedDriverTraining.selectedOptionValue =
        request.driver.advancedDriverTraining + '';
      if (!advancedDriverTraining.qualifyingInformation) {
        advancedDriverTraining.qualifyingInformation = {
          advancedTrainingCourseCompletionDate:
            request.driver.advancedTrainingCourseCompletionDate,
        };
      } else {
        advancedDriverTraining.qualifyingInformation = {
          ...advancedDriverTraining.qualifyingInformation,
        };
      }
      nextActions.push(
        new fromActions.UpdatePowersportsDriverDiscount(advancedDriverTraining)
      );
    }

    const goodStudentModifier = eligibleDiscounts.find(
      modifier => modifier.eligibleDiscountId === ModifierNames.GOOD_STUDENT
    );

    if (typeof request.driver.goodStudent === 'boolean') {
      const modifier = {
        ...goodStudentModifier,
      };
      if (goodStudentModifier) {
        modifier.eligibleDiscountId = ModifierNames.GOOD_STUDENT;
        modifier.modelId = response.driverId;
        modifier.productId = request.productId;
        modifier.selectedOptionValue = request.driver.goodStudent + '';
        nextActions.push(
          new fromActions.UpdatePowersportsDriverDiscount(modifier)
        );
      }
      response.goodStudent = request.driver.goodStudent;
    }

    const safetyCourseModifier = eligibleDiscounts.find(
      modifier => modifier.eligibleDiscountId === ModifierNames.SAFETY_COURSE
    );
    if (request.driver.safetyCourse && safetyCourseModifier) {
      const safetyCourse = {
        ...safetyCourseModifier,
      };
      safetyCourse.eligibleDiscountId = ModifierNames.SAFETY_COURSE;
      safetyCourse.modelId = response.driverId;
      safetyCourse.productId = request.productId;
      safetyCourse.selectedOptionValue = request.driver.safetyCourse + '';
      if (!safetyCourse.qualifyingInformation) {
        safetyCourse.qualifyingInformation = {
          trainingCourseCompletionDate:
            request.driver.trainingCourseCompletionDate,
        };
      } else {
        safetyCourse.qualifyingInformation = {
          ...safetyCourse.qualifyingInformation,
        };
      }
      nextActions.push(
        new fromActions.UpdatePowersportsDriverDiscount(safetyCourse)
      );
    }

    const driverEntity = {
      ...response,
      powersportsDriverId: response.driverId,
      hasMotorcycleEndorsement: request.driver.person.hasMotorcycleEndorsement,
      endorsementDate: request.driver.person.endorsementDate,
    };

    if (request.driver.prefillId) {
      const updated: Update<PersonViewModel> = {
        changes: { selected: true },
        id: request.driver.prefillId,
      };
      nextActions.push(new fromActions.UpdatePrefillPersonSelected(updated));
    }

    if (request.driver.prepopulatedInsuredId && request.driver.driverTempId) {
      driverEntity.prepopulatedInsuredId = request.driver.prepopulatedInsuredId;
      driverEntity.driverTempId = request.driver.driverTempId;
    }

    nextActions.push(new fromActions.ClearDvaStore());
    nextActions.push(new fromActions.AddPowersportsDriverSuccess(driverEntity));
    return nextActions;
  }

  getUpdateDriverNextActions(
    request: DriverRequest,
    response: DriverEntity,
    unmaskedDOB: string
  ): any[] {
    const nextActions = [];

    response.eventType = 'update';
    response.policyHolderId = request.driver.policyHolderId;

    response = this.setDriveryEntity(request, response, unmaskedDOB);

    response.goodStudent = request.driver.goodStudent;
    response.reasonForCoverageLapse = request.driver.reasonForCoverageLapse;

    if (response.driverOccupation) {
      nextActions.push(
        new fromActions.UpdateDriverDiscount({
          productId: ProductTypes.AUTO,
          modelId: response.driverId,
          eligibleDiscountId: ModifierNames.GROUP_OCCUPATION,
          selectedOptionValue: response.driverOccupation,
        })
      );
    }

    if (request.driver.eligibleDiscounts) {
      const associateModifier = request.driver.eligibleDiscounts.find(
        discount =>
          discount.eligibleDiscountId === ModifierNames.ASSOCIATE_DISCOUNT
      );
      if (
        associateModifier &&
        associateModifier.selectedOptionValue === 'true'
      ) {
        // This is required because DSM removes the associate number when patching the driver
        nextActions.push(
          new fromActions.UpdateDriverDiscount({
            ...associateModifier,
          })
        );
      }
    }

    if (request.driver.person?.infractionDesc) {
      response.person.infractionDesc = request.driver.person?.infractionDesc;
    }
    if (request.driver.person?.yearsOfMotorcycleAndOffRoadExp) {
      response.person.yearsOfMotorcycleAndOffRoadExp =
        request.driver.person?.yearsOfMotorcycleAndOffRoadExp;
    }
    if (request.driver.person?.hasMotorcycleEndorsement) {
      response.person.hasMotorcycleEndorsement =
        request.driver.person?.hasMotorcycleEndorsement;
    }
    if (request.driver.person?.endorsementDate) {
      response.person.endorsementDate = request.driver.person?.endorsementDate;
    }
    const driverEntity = {
      ...response,
    };

    if (request.driver.prepopulatedInsuredId && request.driver.driverTempId) {
      driverEntity.prepopulatedInsuredId = request.driver.prepopulatedInsuredId;
      driverEntity.driverTempId = request.driver.driverTempId;
    }

    if (request.driver.partialQuoteId && request.driver.driverTempId) {
      driverEntity.partialQuoteId = request.driver.partialQuoteId;
      driverEntity.driverTempId = request.driver.driverTempId;
    }

    const updatedDriver: Update<DriverEntity> = {
      id: response.driverId,
      changes: driverEntity,
    };
    nextActions.push(new fromActions.UpdateDriverSuccess(updatedDriver));
    return nextActions;
  }

  getUpdatePowerSportsDriverNextActions(
    request: DriverRequest,
    response: DriverEntity,
    unmaskedDOB: string
  ): any[] {
    const nextActions = [];

    response.eventType = 'update';
    response.policyHolderId = request.driver.policyHolderId;

    response = this.setDriveryEntity(request, response, unmaskedDOB);

    response.goodStudent = request.driver.goodStudent;
    response.isContinuousInsuranceProvided =
      request.driver.isContinuousInsuranceProvided;
    response.isPersonalUmbrellaLiabilityProvided =
      request.driver.isPersonalUmbrellaLiabilityProvided;

    if (request.driver.safetyCourse) {
      const safetyCourseModifier = request.driver.eligibleDiscounts?.find(
        discount => discount.eligibleDiscountId === ModifierNames.SAFETY_COURSE
      );
      if (
        safetyCourseModifier &&
        safetyCourseModifier.selectedOptionValue === 'true'
      ) {
        nextActions.push(
          new fromActions.UpdatePowersportsDriverDiscount({
            ...safetyCourseModifier,
          })
        );
      }
    }
    if (response.safetyCourse) {
      nextActions.push(
        new fromActions.UpdatePowersportsDriverDiscount({
          productId: ProductTypes.POWERSPORTS,
          modelId: response.driverId,
          eligibleDiscountId: ModifierNames.SAFETY_COURSE,
          selectedOptionValue: response.safetyCourse + '',
        })
      );
    }

    const driverEntity = { ...response };

    if (request.driver.prepopulatedInsuredId && request.driver.driverTempId) {
      driverEntity.prepopulatedInsuredId = request.driver.prepopulatedInsuredId;
      driverEntity.driverTempId = request.driver.driverTempId;
    }

    const updatedDriver: Update<DriverEntity> = {
      id: response.driverId,
      changes: driverEntity,
    };
    nextActions.push(
      new fromActions.UpdatePowersportsDriverSuccess(updatedDriver)
    );
    return nextActions;
  }

  setDriveryEntity(
    request: DriverRequest,
    response: DriverEntity,
    unmaskedDOB: string
  ): DriverEntity {
    response.infractions = request.driver.infractions;
    response.driverOccupation = request.driver.person.driverOccupation;
    response.person.driverOccupation = request.driver.person.driverOccupation;
    response.employmentInformation = request.driver.employmentInformation;
    response.person.dateOfBirth = unmaskedDOB;
    response.productId = request.productId;
    response.prefillId = request.driver.prefillId;
    response.healthCarePlan = request.driver.healthCarePlan;
    response.isPersonalInjuryProtectionExcluded =
      request.driver.isPersonalInjuryProtectionExcluded;
    response.isLivingWithNWInsuredParent =
      request.driver.isLivingWithNWInsuredParent;
    response.hasGraduatedCollege = request.driver.hasGraduatedCollege;
    response.person.termLife = request.driver.person.termLife;
    response.person.termLifeHeightFeet =
      request.driver.person.termLifeHeightFeet;
    response.person.termLifeHeightInches =
      request.driver.person.termLifeHeightInches;
    response.person.termLifeWeight = request.driver.person.termLifeWeight;
    response.person.termLifeHealth = request.driver.person.termLifeHealth;
    response.person.termLifeNico = request.driver.person.termLifeNico;
    response.person.termLifeEligible = request.driver.person.termLifeEligible;
    response.reasonForCoverageLapse = request.driver.reasonForCoverageLapse;

    response.person.hasMotorcycleEndorsement =
      request.driver.hasMotorcycleEndorsement;
    response.person.endorsementDate = request.driver.endorsementDate;

    return response;
  }

  getEligibleDiscounts(
    discounts: EligibleDiscountEntity[],
    driverId: string,
    productId: string
  ): EligibleDiscountEntity[] {
    return discounts.map(discount => {
      discount.modelId = driverId;
      discount.productId = productId;
      return discount;
    });
  }

  addDriverRequestFromPolicyholder(
    policyholder: PolicyholderEntity,
    productId: string,
    quoteState: string
  ): DriverEntity {
    return {
      productId,
      person: { ...policyholder.person },
      relationToPrimaryNamedInsured: this.relationToPniFromPolicyholderType(
        policyholder.policyHolderType
      ),
      licenseState: quoteState,
      ageFirstLicensed: '16',
    };
  }

  relationToPniFromPolicyholderType(input: string): string {
    switch (input) {
      case PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED:
        return 'PrimaryNamedInsured';
      case PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED:
        return 'Spouse';
      case PolicyholderTypes.POLICY_ADDITIONAL_NAMED_INSURED:
        return 'Other';
    }
    return 'Other';
  }
}
