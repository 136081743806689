import { RetrieveActions } from '@core/store/actions';
import * as fromAction from '@core/store/actions/retrieve.action';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  RetrieveEntity,
  RetrieveFailure,
} from '@core/models/entities/retrieve.entity';
import { HydrateQuoteResponse } from '@core/models/auto/quotes/retrieve-quote-response.model';
import { BypassRetrieveResponse } from '@core/models/bypass-retrieve/bypass-retrieve.model';

// Entities are keyed by ProductTypes
export interface RetrieveState extends EntityState<RetrieveEntity> {
  loading: number; // Count of pending load requests.
  loaded: boolean; // True if at least one request succeeds and none failed.
  errorCode: string;
  failures: RetrieveFailure[];
  compRaterId?: string;
  compRaterFailure?: string;
  hydrateResponse: HydrateQuoteResponse[];
  targetPage: string;
  allRetrievesFailed: boolean;
  bypassRetrieveResponse?: BypassRetrieveResponse;
  hasMortgageForRetrieve?: boolean;
  escrowForRetrieve?: boolean;
}

const adapter: EntityAdapter<RetrieveEntity> = createEntityAdapter({
  selectId: entity => entity.productId,
});

export function reducer(
  state: RetrieveState = {
    ids: [],
    entities: {},
    loading: 0,
    loaded: false,
    errorCode: null,
    failures: [],
    allRetrievesFailed: false,
    hydrateResponse: [],
    targetPage: null,
  },
  action: RetrieveActions
): RetrieveState {
  switch (action.type) {
    case fromAction.RetrieveActionTypes.RETRIEVE_QUOTE: {
      return {
        ...state,
        loading: state.loading + 1,
        loaded: false,
        errorCode: null,
      };
    }

    case fromAction.RetrieveActionTypes.RETRIEVE_QUOTE_SUCCESS: {
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: state.loading - 1,
        loaded: true,
        errorCode: null,
      });
    }

    case fromAction.RetrieveActionTypes.RETRIEVE_QUOTE_FAILURE: {
      return {
        ...state,
        loading: state.loading - 1,
        loaded: false,
        errorCode: action.error.errorCode,
        failures: [
          ...state.failures.filter(
            p => p.productType !== action.error.productType
          ),
          action.error,
        ],
      };
    }

    case fromAction.RetrieveActionTypes.ALL_RETRIEVES_FAILED: {
      return {
        ...state,
        allRetrievesFailed: true,
      };
    }

    case fromAction.RetrieveActionTypes.RETRIEVE_QUOTE_CLEAR_FAILURES: {
      return {
        ...state,
        failures: [],
      };
    }

    case fromAction.RetrieveActionTypes.UPDATE_COMPRATER_ID: {
      const deepLaunchId = action.payload;
      return {
        ...state,
        compRaterId: deepLaunchId,
      };
    }
    case fromAction.RetrieveActionTypes.UPDATE_REDIRECT_TARGET: {
      const targetPage = action.payload;
      return {
        ...state,
        targetPage,
      };
    }

    case fromAction.RetrieveActionTypes.HYDRATE_QUOTE_FAILURE: {
      const error = action.payload;

      return {
        ...state,
        compRaterFailure: error,
      };
    }

    case fromAction.RetrieveActionTypes.HYDRATE_QUOTE_SUCCESS: {
      const hydrateResponse = action.payload;

      return {
        ...state,
        hydrateResponse,
      };
    }

    case fromAction.RetrieveActionTypes.BYPASS_RETRIEVE_QUOTE_SUCCESS: {
      const bypassRetrieveResponse = action.payload;

      return {
        ...state,
        bypassRetrieveResponse,
      };
    }

    case fromAction.RetrieveActionTypes.UPDATE_HAS_MORTGAGE_FOR_RETRIEVE: {
      const hasMortgageForRetrieve = action.payload;
      return {
        ...state,
        hasMortgageForRetrieve: hasMortgageForRetrieve,
      };
    }

    case fromAction.RetrieveActionTypes.UPDATE_ESCROW_FOR_RETRIEVE: {
      const escrowForRetrieve = action.payload;
      return {
        ...state,
        escrowForRetrieve: escrowForRetrieve,
      };
    }

    default:
      return state;
  }
}
