import { Injectable } from '@angular/core';
import { HelpContentItem } from '../help-content-item';
import {
  SmartRideComponent,
  VehicleSmartRideComponent,
  SelfReportedComponent,
  UnderwritingDisclaimerComponent,
  DogBreedsModalComponent,
  UmbrellaOccupationsComponent,
  CreditNoticeComponent,
  ShareDrivingDataComponent,
  SmartrideInstantConsentHelpContentComponent,
  SmartrideInstantConsentComponent,
} from '../components';
import { ConnectedCarComponent } from '@help-content/components/connected-car/connected-car.component';

@Injectable({
  providedIn: 'root',
})
export class HelpContentService {
  constructor() {}

  getHelpContent() {
    return {
      smartride: new HelpContentItem(SmartRideComponent, {}),
      vehicleSmartride: new HelpContentItem(VehicleSmartRideComponent, {}),
      selfreported: new HelpContentItem(SelfReportedComponent, {}),
      connectedCar: new HelpContentItem(ConnectedCarComponent, {}),
      dogBreeds: new HelpContentItem(DogBreedsModalComponent, {}),
      umbrellaOccupations: new HelpContentItem(
        UmbrellaOccupationsComponent,
        {}
      ),
    };
  }

  getUnderwritingHelpContent() {
    return {
      underwriting: new HelpContentItem(UnderwritingDisclaimerComponent, {}),
    };
  }

  getPrivacyHelpContent() {
    return {
      creditNotice: new HelpContentItem(CreditNoticeComponent, {}),
      shareDrivingData: new HelpContentItem(ShareDrivingDataComponent, {}),
    };
  }

  getSmartrideInstantConsentHelpContent() {
    return {
      smartrideInstantConsentHelpContent: new HelpContentItem(
        SmartrideInstantConsentHelpContentComponent,
        {}
      ),
    };
  }

  getSmartrideInstantConsent() {
    return {
      smartrideInstantConsent: new HelpContentItem(
        SmartrideInstantConsentComponent,
        {}
      ),
    };
  }
}
