import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { CustomValidators } from '@core/validators/custom-validators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from '@shared/utils/date.utils';

@Component({
  selector: 'mq-umbrella',
  templateUrl: './umbrella.component.html',
  styleUrls: ['./umbrella.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UmbrellaComponent implements OnInit, OnChanges {
  @Input() policyholders: PolicyholderEntity[];

  url =
    'https://www.nationwide.com/lc/resources/home/articles/do-i-need-an-umbrella-policy ';

  form = new UntypedFormGroup({
    datesOfBirth: new UntypedFormArray([]), // { policyholderId, name, dateOfBirth }
  });

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.policyholders) {
      this._buildForm();
    }
  }

  private _buildForm(): void {
    const formArray = <UntypedFormArray>this.form.get('datesOfBirth');
    while (formArray.length > 0) {
      formArray.removeAt(formArray.length - 1);
    }

    if (this.policyholders) {
      for (const policyholder of this.policyholders) {
        if (this._mustCollectDobForPolicyholder(policyholder)) {
          formArray.push(
            new UntypedFormGroup({
              policyholderId: new UntypedFormControl(
                policyholder.policyHolderId
              ),
              name: new UntypedFormControl(
                this._normalizePolicyholderName(policyholder)
              ),
              dateOfBirth: new UntypedFormControl(
                this._normalizeDob(policyholder.person.dateOfBirth),
                [Validators.required, CustomValidators.DateMmddyyyyPastOnly]
              ),
            })
          );
        }
      }
    }
  }

  private _mustCollectDobForPolicyholder(
    policyholder: PolicyholderEntity
  ): boolean {
    const dob = policyholder.person.dateOfBirth;
    return !dob || dob.indexOf('*') >= 0;
  }

  private _normalizePolicyholderName(policyholder: PolicyholderEntity): string {
    const p = policyholder.person;
    return `${p.firstName} ${p.middleName || ''} ${p.lastName} ${
      p.suffix || ''
    }`;
  }

  private _normalizeDob(input: string): string {
    const mmyydddd = DateUtils.formatDateToOld(input);
    if (!mmyydddd || !mmyydddd.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
      return '';
    }
    return mmyydddd;
  }

  submit(): void {
    if (this.form.valid) {
      this.modal.close(
        this.form.value.datesOfBirth.map(d => ({
          policyholderId: d.policyholderId,
          dateOfBirth: DateUtils.formatDateToDSM(d.dateOfBirth),
        }))
      );
    }
  }
}
