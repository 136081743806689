import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';
import * as fromPowersportsDrivers from '@core/store/reducers/powersports-driver.reducer';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromVehicles from '@core/store/selectors/vehicle.selector';
import * as fromDriverVehicleAssignment from '@core/store/selectors/driver-vehicle-assignment.selector';
import * as fromPolicyholder from '@core/store/selectors/policyholder.selector';
import * as fromMetadata from '@core/store/selectors/metadata.selector';
import * as fromActions from '@core/store/actions';
import * as fromSession from '@core/store/selectors/session.selector';
import * as fromPrepopulatedInsured from '@core/store/selectors/prepopulated-insured.selector';
import {
  DriverRelationToPNI,
  ModifierNames,
  ProductTypes,
  MaritalStatusToDsmCodes,
  Infractiontype,
  GoodDriverDisplayByKey,
  PolicyholderTypes,
} from '@shared/constants/app-constants';
import { DriverIncidentEntity } from '@core/models/entities/driver-incident.entity';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { PersonEntity } from '@core/models/entities/person.entity';
import { PowersportsDriverRequest } from '@core/models/auto/quotes/driver-request.model';
import { DriverIncidentRequest } from '@core/models/auto/quotes/driver-incident-request.model';

export const getPowersportsDriverState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.powersportsDrivers
);

export const {
  selectAll: selectAllPowersportsDrivers,
  selectEntities: selectPowersportsDriverEntities,
  selectTotal: selectPowersportsDriverTotal,
} = fromPowersportsDrivers.adapter.getSelectors(getPowersportsDriverState);

export const getPowersportsDriverEntity = (driverId: string) =>
  createSelector(
    selectPowersportsDriverEntities,
    entities => entities[driverId]
  );

export const getPowersportsPniDriver = createSelector(
  selectAllPowersportsDrivers,
  drivers =>
    drivers.find(
      driver => driver.relationToPrimaryNamedInsured === DriverRelationToPNI.PNI
    )
);

export const selectAllPowersportsDriversIfSelected = createSelector(
  selectAllPowersportsDrivers,
  fromProducts.getSelectedProductsWithoutErrors,
  (drivers, products) => {
    const powersportsProduct = products.find(
      p => p.id === ProductTypes.POWERSPORTS
    );
    return powersportsProduct ? drivers : [];
  }
);

export const selectAllPowersportsDriversWithVehicleAssignment = createSelector(
  selectAllPowersportsDrivers,
  fromVehicles.selectDsmVehicleEntities,
  fromDriverVehicleAssignment.selectAllDriverVehicleAssignments,
  (drivers, vehicles, assignments) => {
    const outputs = [];

    for (const inputDriver of drivers) {
      const copyDriver = { ...inputDriver };
      for (const assignment of assignments.filter(
        a => a.driverId === copyDriver.driverId
      )) {
        const vehicle = vehicles[assignment.vehicleId];
        if (!vehicle) {
          continue;
        }
        if (assignment.isPrimaryVehicle) {
          copyDriver.assignedVehicle = vehicle.vehicleId;
        }
        if (assignment.isPrimaryVehicle) {
          copyDriver.assignedVehicle = vehicle.vehicleId;
        }
        if (copyDriver.assignedVehiclesList) {
          copyDriver.assignedVehiclesList.push(vehicle.vehicleId);
        } else {
          copyDriver.assignedVehiclesList = [vehicle.vehicleId];
        }
      }
      outputs.push(copyDriver);
    }
    return outputs;
  }
);

function _maskPartiallyMaskedDob(input: string): string {
  if (!input || input.indexOf('*') < 0) {
    return input;
  }
  return '****-**-**';
}

export const buildPowersportsDriverRequest = (driver: DriverEntity) =>
  createSelector(
    fromProducts.getProduct(driver.productId),
    fromMetadata.getStateSpecificFlag('isEligibleDiscountDriverRequest'),
    fromSession.getQuoteState,
    fromPrepopulatedInsured.getPrepopulatedDriverFrom(driver),
    selectPowersportsDriverEntities,
    fromPolicyholder.getPolicyholder(driver.policyHolderId),
    fromMetadata.getStateSpecificFlag('nonSpecifiedGenderApplicable'),
    fromMetadata.getStateSpecificFlag('nonSpecifiedGenderNonProperty'),
    (
      product,
      isEligibleDiscountDriverRequest,
      quoteState,
      prePopulatedDriver,
      existingDriverEntities,
      policyholder,
      nonSpecifiedGenderApplicable,
      nonSpecifiedGenderNonProperty
    ) => {
      const existDriver = driver.driverId
        ? existingDriverEntities[driver.driverId]
        : null;
      const licenseNumber =
        existDriver && existDriver.licenseNumber && !driver.licenseNumber
          ? existDriver.licenseNumber
          : driver.licenseNumber;
      const licenseState =
        existDriver && existDriver.licenseState && !driver.licenseState
          ? existDriver.licenseState
          : driver.licenseState;
      const yearOfExp = !driver.person.yearsOfMotorcycleAndOffRoadExp
        ? driver.yearsOfMotorcycleAndOffRoadExp
        : driver.person.yearsOfMotorcycleAndOffRoadExp;
      const motoEnd =
        driver.person.hasMotorcycleEndorsement !== undefined
          ? driver.person.hasMotorcycleEndorsement
          : driver.hasMotorcycleEndorsement;
      const motoEndorseDate =
        driver.person.endorsementDate !== undefined
          ? driver.person.endorsementDate
          : driver.endorsementDate;
      let request: PowersportsDriverRequest = {
        quoteId: product.quoteId,
        productId: product.id,
        driver: {
          person: {
            ...driver.person,
            dateOfBirth: _maskPartiallyMaskedDob(driver.person.dateOfBirth),
            gender:
              driver &&
              driver.person &&
              driver.person.gender &&
              driver.person.gender !== 'n'
                ? driver.person.gender
                : null,
          },
          driverType: driver.driverType || 'Driver',
          relationToPrimaryNamedInsured:
            driver.relationToPrimaryNamedInsured || DriverRelationToPNI.PNI,
          isFinancialFilingRequired: false,
          infractions: driver.infractions,
          licenseState: licenseState || quoteState,
          licenseNumber: licenseNumber || null,
          ageFirstLicensed:
            driver.ageFirstLicensed || driver.person.ageFirstLicensed,
          yearsOfMotorcycleAndOffRoadExp: yearOfExp,
          policyHolderId: driver.policyHolderId,
          driverId: driver.powersportsDriverId || driver.driverId,
          prefillId: driver.prefillId,
          hasGraduatedCollege: driver.hasGraduatedCollege,
          isLivingWithNWInsuredParent: driver.isLivingWithNWInsuredParent,
          goodStudent: driver.goodStudent,
          employmentInformation: driver.employmentInformation,
          isContinuousInsuranceProvided: driver.isContinuousInsuranceProvided,
          isPersonalUmbrellaLiabilityProvided:
            driver.isPersonalUmbrellaLiabilityProvided,
          hasMotorcycleEndorsement: motoEnd,
          endorsementDate: motoEndorseDate,
          safetyCourse: driver.safetyCourse,
        },
      };

      if (policyholder && request.driver.person.dateOfBirth.indexOf('*') >= 0) {
        request.driver.person.dateOfBirth = policyholder.person.dateOfBirth;
      }

      if (
        (nonSpecifiedGenderApplicable || nonSpecifiedGenderNonProperty) &&
        (!driver?.person?.gender ||
          driver?.person?.gender === 'n' ||
          driver?.person?.gender === null)
      ) {
        request = {
          ...request,
          driver: { ...request.driver, nonSpecifiedGender: true },
        };
      }

      if (driver.driverTraining) {
        request.driver.driverTraining = driver.driverTraining;
        request.driver.trainingCourseCompletionDate =
          driver.trainingCourseCompletionDate;
      }

      if (driver.advancedDriverTraining) {
        request.driver.advancedDriverTraining = driver.advancedDriverTraining;
        request.driver.advancedTrainingCourseCompletionDate =
          driver.advancedTrainingCourseCompletionDate;
      }

      if (driver.usArmedForces) {
        request.driver.usArmedForces = driver.usArmedForces;
      }

      if (driver.safetyCourse) {
        request.driver.safetyCourse = driver.safetyCourse;
        request.driver.trainingCourseCompletionDate =
          driver.trainingCourseCompletionDate;
      }

      if (driver.eligibleDiscounts) {
        request.driver.eligibleDiscounts = [];
        for (const discount of driver.eligibleDiscounts) {
          request.driver.eligibleDiscounts.push(discount);
        }
        const dsmDriverTraining = driver.eligibleDiscounts.find(
          discount =>
            discount.eligibleDiscountId === ModifierNames.DRIVER_TRAINING
        );
        const dsmAdvancedDriverTraining = driver.eligibleDiscounts.find(
          discount =>
            discount.eligibleDiscountId ===
            ModifierNames.ADVANCED_DRIVER_TRAINING
        );
        const dsmUSArmedForces = driver.eligibleDiscounts.find(
          discount =>
            discount.eligibleDiscountId === ModifierNames.US_ARMED_FORCES
        );
        if (
          dsmDriverTraining &&
          dsmDriverTraining.selectedOptionValue === 'true'
        ) {
          request.driver.driverTraining =
            dsmDriverTraining.selectedOptionValue === 'true';
          request.driver.trainingCourseCompletionDate =
            dsmDriverTraining.qualifyingInformation.trainingCourseCompletionDate;
        }
        if (driver.groupOccupation !== undefined) {
          request.driver.groupOccupation = driver.groupOccupation;
        }
        if (
          dsmAdvancedDriverTraining &&
          dsmAdvancedDriverTraining.selectedOptionValue === 'true'
        ) {
          request.driver.advancedDriverTraining =
            dsmAdvancedDriverTraining.selectedOptionValue === 'true';
          request.driver.advancedTrainingCourseCompletionDate =
            dsmAdvancedDriverTraining.qualifyingInformation.advancedTrainingCourseCompletionDate;
        }
        if (
          dsmUSArmedForces &&
          dsmUSArmedForces.selectedOptionValue === 'true'
        ) {
          request.driver.usArmedForces =
            dsmUSArmedForces.selectedOptionValue === 'true';
        }
      } else {
        if (driver.driverTraining && isEligibleDiscountDriverRequest) {
          const discount = {
            name: 'Driver Training',
            selectedOptionValue: driver.driverTraining + '',
            eligibleDiscountId: ModifierNames.DRIVER_TRAINING,
            qualifyingInformation: {
              trainingCourseCompletionDate: driver.trainingCourseCompletionDate,
            },
          };
          if (request.driver.eligibleDiscounts) {
            request.driver.eligibleDiscounts.push(discount);
          } else {
            request.driver.eligibleDiscounts = [discount];
          }
        }
      }

      if (driver.relationToPrimaryNamedInsured === DriverRelationToPNI.PNI) {
        if (driver.person.driverOccupation !== undefined) {
          request.driver.driverOccupation = driver.person.driverOccupation;
        }
      }

      if (driver.employmentInformation !== undefined) {
        request.driver.employmentInformation = {
          currentEmployment: driver.employmentInformation.currentEmployment,
          fullTimeEmployment: driver.employmentInformation.fullTimeEmployment,
        };
      }

      if (driver.infractions !== undefined) {
        request.driver.infractionDesc = driver.infractionDesc;
      }

      if (driver.healthCarePlan !== undefined) {
        request.driver.healthCarePlan = driver.healthCarePlan;
      }

      if (driver.isPersonalInjuryProtectionExcluded !== undefined) {
        request.driver.isPersonalInjuryProtectionExcluded =
          driver.isPersonalInjuryProtectionExcluded;
      }

      if (driver.hasMotorcycleEndorsement !== undefined) {
        request.driver.hasMotorcycleEndorsement =
          driver.hasMotorcycleEndorsement;
        request.driver.endorsementDate = driver.endorsementDate;
      }

      if (prePopulatedDriver && prePopulatedDriver.driverTempId) {
        request.driver.driverTempId = prePopulatedDriver.driverTempId;
        request.driver.prepopulatedInsuredId =
          prePopulatedDriver.prepopulatedInsuredId;
        if (!request.driver.licenseNumber && prePopulatedDriver.licenseNumber) {
          request.driver.licenseNumber = prePopulatedDriver.licenseNumber;
        }
        if (!request.driver.licenseState && prePopulatedDriver.licenseState) {
          request.driver.licenseState = prePopulatedDriver.licenseState;
        }
      }
      return request;
    }
  );

export const buildPowersportsDriverIncidentRequest = (
  driverIncident: DriverIncidentEntity
) =>
  createSelector(
    fromProducts.getProduct(ProductTypes.POWERSPORTS),

    product => {
      const request: DriverIncidentRequest = {
        quoteId: product.quoteId,
        driverId: driverIncident.driverId,
        infractions: {
          infractionDesc: driverIncident.infractionDesc,
          infractionType: driverIncident.infractionType,
          occurrenceDate: driverIncident.occurrenceDate,
        },
      };
      return request;
    }
  );

export const getPowersportsDriverTrainingFromDriverByDriverId = (
  driverId: string
) =>
  createSelector(selectPowersportsDriverEntities, entities => {
    const entity = entities[driverId];
    if (entity && entity.eligibleDiscounts) {
      return entity.eligibleDiscounts.find(discount => {
        return discount.eligibleDiscountId === 'DriverTraining';
      });
    }
    return null;
  });

export const getPowersportsGoodStudentFromDriverByDriverId = (
  driverId: string
) =>
  createSelector(selectPowersportsDriverEntities, entities => {
    const entity = entities[driverId];
    if (entity && entity.eligibleDiscounts) {
      return entity.eligibleDiscounts.find(discount => {
        return discount.eligibleDiscountId === 'GoodStudent';
      });
    }
    return null;
  });

export const getPowersportsAdvancedDriverTrainingFromDriverByDriverId = (
  driverId: string
) =>
  createSelector(selectPowersportsDriverEntities, entities => {
    const entity = entities[driverId];
    if (entity && entity.eligibleDiscounts) {
      return entity.eligibleDiscounts.find(discount => {
        return discount.eligibleDiscountId === 'AdvancedDriverTraining';
      });
    }
    return null;
  });

export const getUSArmedForcesFromPowersportsDriverByDriverId = (
  driverId: string
) =>
  createSelector(selectPowersportsDriverEntities, entities => {
    const entity = entities[driverId];
    if (entity && entity.eligibleDiscounts) {
      return entity.eligibleDiscounts.find(discount => {
        return discount.eligibleDiscountId === 'USArmedForces';
      });
    }
    return null;
  });

export const getPowersportsDriverOccupationByDriverId = (driverId: string) =>
  createSelector(selectPowersportsDriverEntities, entities => {
    const entity = entities[driverId];
    if (entity && entity.eligibleDiscounts) {
      return entity.eligibleDiscounts.find(discount => {
        return discount.eligibleDiscountId === 'GroupOccupation';
      });
    }
    return null;
  });

export const getPowersportsDriverInfractions = () =>
  createSelector(selectAllPowersportsDrivers, drivers => {
    const returnInfractions: string[] = [];
    drivers.forEach(driver => {
      if (!driver.infractions) {
        returnInfractions.push(Infractiontype.Excellent);
        return;
      }
      driver.infractions.forEach(infraction => {
        if (infraction.infractionDesc === GoodDriverDisplayByKey.Prettygood) {
          returnInfractions.push(Infractiontype.Prettygood);
        }
      });
    });
    return returnInfractions;
  });

export const getSafetyCourseByDriverId = (driverId: string) =>
  createSelector(selectPowersportsDriverEntities, entities => {
    const entity = entities[driverId];
    if (entity && entity.eligibleDiscounts) {
      return entity.eligibleDiscounts.find(discount => {
        return discount.eligibleDiscountId === 'SafetyCourse';
      });
    }
    return null;
  });

export const getPowersportsDriverRelationToPni = (driverFormData: any) =>
  createSelector(
    getPowersportsPniDriver,
    selectAllPowersportsDrivers,
    (pniDriver, drivers) => {
      if (!pniDriver) {
        return DriverRelationToPNI.PNI;
      }

      const alreadyHasSpouse = drivers.some(
        driver =>
          driver.relationToPrimaryNamedInsured === DriverRelationToPNI.SPOUSE
      );
      const alreadyIsSpouse = drivers.some(driver => {
        return (
          driver.relationToPrimaryNamedInsured === DriverRelationToPNI.SPOUSE &&
          driver.driverId === driverFormData.driverId
        );
      });
      const pniDriverMarriedOrSeparated =
        pniDriver.person &&
        (pniDriver.person.maritalStatus === MaritalStatusToDsmCodes.Married ||
          pniDriver.person.maritalStatus === MaritalStatusToDsmCodes.Separated);
      const newDriverMarriedOrSeparated =
        MaritalStatusToDsmCodes[driverFormData.maritalStatus] ===
          MaritalStatusToDsmCodes.Married ||
        MaritalStatusToDsmCodes[driverFormData.maritalStatus] ===
          MaritalStatusToDsmCodes.Separated;
      if (driverFormData.driverId === pniDriver.driverId) {
        return DriverRelationToPNI.PNI;
      } else if (
        alreadyIsSpouse ||
        (!alreadyHasSpouse &&
          pniDriverMarriedOrSeparated &&
          newDriverMarriedOrSeparated)
      ) {
        if (
          (pniDriver.person.maritalStatus === MaritalStatusToDsmCodes.Married &&
            MaritalStatusToDsmCodes[driverFormData.maritalStatus] ===
              MaritalStatusToDsmCodes.Married) ||
          (pniDriver.person.maritalStatus ===
            MaritalStatusToDsmCodes.Separated &&
            MaritalStatusToDsmCodes[driverFormData.maritalStatus] ===
              MaritalStatusToDsmCodes.Separated)
        ) {
          return DriverRelationToPNI.SPOUSE;
        } else {
          return DriverRelationToPNI.OTHER;
        }
      } else {
        return DriverRelationToPNI.OTHER;
      }
    }
  );

function hashPerson(person: PersonEntity): string {
  return [
    person.firstName,
    person.middleName,
    person.lastName,
    person.suffix,
    person.dateOfBirth,
  ]
    .map(s => {
      if (s && s.includes('-')) {
        return s.split('-')[0];
      }
      return s ? s.toLowerCase() : '';
    })
    .join(' ');
}

function peopleMatch(a: PersonEntity, b: PersonEntity): boolean {
  return hashPerson(a) === hashPerson(b);
}

function findPolicyholderInPowersportsDrivers(
  policyholder: PolicyholderEntity,
  drivers: DriverEntity[]
): DriverEntity {
  for (const driver of drivers) {
    if (peopleMatch(driver.person, policyholder.person)) {
      return driver;
    }
  }
  return null;
}

// This selector never returns the PNI, we assume that PNI is added separately.
export const selectPolicyholdersWithNoPowersportsDriver = createSelector(
  fromPolicyholder.selectAllPolicyholders,
  selectAllPowersportsDrivers,
  (policyholders: PolicyholderEntity[], drivers: DriverEntity[]) => {
    policyholders = policyholders.filter(
      p =>
        p.policyHolderType !== PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED &&
        !findPolicyholderInPowersportsDrivers(p, drivers)
    );
    return policyholders;
  }
);

function addPowersportsDriverRequestFromPolicyholder(
  policyholder: PolicyholderEntity,
  productId: string,
  quoteState: string
): DriverEntity {
  return {
    productId,
    person: { ...policyholder.person },
    relationToPrimaryNamedInsured: relationToPniFromPolicyholderType(
      policyholder.policyHolderType
    ),
    licenseState: quoteState,
    ageFirstLicensed: '16',
  };
}

export const buildAddPowersportsDriverRequestsForPolicyholdersWithNoDriver = (
  productId: string
) =>
  createSelector(
    selectPolicyholdersWithNoPowersportsDriver,
    fromSession.getQuoteState,
    (
      policyholders: PolicyholderEntity[],
      quoteState: string
    ): fromActions.AddDriver[] => {
      return policyholders.map(policyholder => {
        return new fromActions.AddDriver(
          addPowersportsDriverRequestFromPolicyholder(
            policyholder,
            productId,
            quoteState
          )
        );
      });
    }
  );

function relationToPniFromPolicyholderType(input: string): string {
  switch (input) {
    case PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED:
      return 'PrimaryNamedInsured';
    case PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED:
      return 'Spouse';
    case PolicyholderTypes.POLICY_ADDITIONAL_NAMED_INSURED:
      return 'Other';
  }
  return 'Other';
}

function findPolicyholderInList(
  policyholder: PolicyholderEntity,
  list: PolicyholderEntity[]
): PolicyholderEntity {
  for (const existing of list) {
    if (peopleMatch(existing.person, policyholder.person)) {
      return existing;
    }
  }
  return null;
}

function hasValidDateOfBirth(person: PersonEntity): boolean {
  if (!person.dateOfBirth) {
    return false;
  }
  return person.dateOfBirth.includes('*') ? false : true;
}

function findDriverInPolicyholderList(
  driver: DriverEntity,
  list: PolicyholderEntity[]
): DriverEntity {
  for (const existing of list) {
    if (peopleMatch(existing.person, driver.person)) {
      return existing;
    }
  }
  return null;
}

function policyholderFromDriver(driver: DriverEntity): PolicyholderEntity {
  return {
    person: {
      ...driver.person,
    },
    prefillId: driver.prefillId,
    policyHolderType: PolicyholderTypes.POLICY_ADDITIONAL_NAMED_INSURED,
  };
}

// Never returns the PNI; you should already have that one.
export const getExistingAddablePowersportsPeopleAbsentFromProduct = (
  productId: string
) =>
  createSelector(
    fromPolicyholder.getAllPolicyholders,
    selectAllPowersportsDrivers,
    (policyholders, drivers) => {
      const policyholdersAlreadyPresent = policyholders.filter(
        p =>
          p.productId === productId ||
          p.policyHolderType === PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
      );
      const policyholdersToAdd = policyholders.filter(p => {
        return (
          p.policyHolderType !==
            PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED &&
          !findPolicyholderInList(p, policyholdersAlreadyPresent) &&
          hasValidDateOfBirth(p.person)
        );
      });
      for (const driver of drivers) {
        if (
          findDriverInPolicyholderList(driver, policyholdersAlreadyPresent) ||
          findDriverInPolicyholderList(driver, policyholdersToAdd)
        ) {
          continue;
        }
        if (hasValidDateOfBirth(driver.person)) {
          policyholdersToAdd.push(policyholderFromDriver(driver));
        }
      }
      return policyholdersToAdd;
    }
  );

export const getPowersportsDriversLoading = createSelector(
  getPowersportsDriverState,
  state => state.loading > 0
);
