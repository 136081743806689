import { Action } from '@ngrx/store';

import { PreBindDocumentsAcknowledgement } from '@core/models/entities/pre-bind-document.entity';
import { ConnectedCarConsent } from '@core/models/private-label/private-label.model';
import { SmartrideInstantConsent } from '@core/models/private-label/private-label.model';
import { AutoIdCardsTextConsent } from '@core/models/digital-id-cards/digital-id-cards-text.model';

export const UPDATE_PREBIND_DOCUMENTS_ACKNOWLEDGEMENT =
  '[Consent Values] Update PreBind Documents Acknowledgement';
export const UPDATE_CONNECTED_CAR_CONSENT =
  '[Consent Values] Update Connected Car Consent';

export const UPDATE_SMARTRIDE_INSTANT_CONSENT =
  '[Consent Values] Update Smartride Instant Consent';

export const UPDATE_AUTO_ID_CARDS_TEXT_CONSENT =
  '[Consent Values] Update Digital ID Cards Text Consent';

export class UpdatePreBindDocumentsAcknowledgement implements Action {
  readonly type = UPDATE_PREBIND_DOCUMENTS_ACKNOWLEDGEMENT;
  constructor(public payload: PreBindDocumentsAcknowledgement) {}
}

export class UpdateConnectedCarConsent implements Action {
  readonly type = UPDATE_CONNECTED_CAR_CONSENT;
  constructor(public payload: ConnectedCarConsent) {}
}

export class UpdateSmartrideInstantConsent implements Action {
  readonly type = UPDATE_SMARTRIDE_INSTANT_CONSENT;
  constructor(public payload: SmartrideInstantConsent) {}
}

export class UpdateAutoIDCardsTextConsent implements Action {
  readonly type = UPDATE_AUTO_ID_CARDS_TEXT_CONSENT;
  constructor(public payload: AutoIdCardsTextConsent) {}
}

export type ConsentValuesAction =
  | UpdatePreBindDocumentsAcknowledgement
  | UpdateConnectedCarConsent
  | UpdateSmartrideInstantConsent
  | UpdateAutoIDCardsTextConsent;
