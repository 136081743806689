<div class="maintenance__content">
  <ng-container *ngIf="isPrivateLabel$ | async; else default">
    <img src="assets/images/maintenance-private-label.png"
    alt="We are currently making improvements to our site.
    Please try again later. We Apologize for the inconvenience."
    />
  </ng-container>
  <ng-template #default>
    <img src="assets/images/maintenance.png"
    alt="We are currently making improvements to our site.
    Please try again later. We Apologize for the inconvenience."
    />
  </ng-template>
</div>
