import * as fromMetadata from '@core/store/actions/metadata.action';
import { SelectOption } from '@core/models/select-option.model';
import { ConnectedCarCriteria } from '@core/models/private-label/private-label.model';
import { VehiclePrimaryUseOptions } from '@core/models/auto/vehicle-primary-use.model';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { AntiTheftDevices } from '@core/models/auto/vehicle-antiTheftDevice-states.model';

type StateSpecificFlagsObjectAsType = keyof StateSpecificFlagsObject;
type Donkey = { abc: string; def: number };
type ItIsAGodDamnMappedObjectTypeAlready = string &
  keyof StateSpecificFlagsObject;

export interface MetadataState {
  maritalStatus: {
    data: string[];
    loaded: boolean;
    loading: boolean;
  };
  suffix: {
    data: SelectOption[];
    loaded: boolean;
    loading: boolean;
  };
  stateAbbreviations: {
    data: string[];
    loaded: boolean;
    loading: boolean;
  };
  activeStates: {
    data: string[];
    loaded: boolean;
    loading: boolean;
  };
  agencyChannel: {
    data: string[];
    loaded: boolean;
    loading: boolean;
  };
  infractionDesc: {
    data: string[];
    loaded: boolean;
    loading: boolean;
  };
  infractionType: {
    data: string[];
    loaded: boolean;
    loading: boolean;
  };
  stateSpecificFlags: {
    // Each field is an array of state abbreviations where that feature is relevant.
    data: Required<StateSpecificFlagsObject>;
    loaded: boolean;
    loading: boolean;
  };
  features: {
    data: any[];
    loaded: boolean;
    loading: boolean;
  };
  connectedCarCriteria: {
    data: ConnectedCarCriteria[];
    loaded: boolean;
    loading: boolean;
  };
  antiTheftDevices: {
    data: AntiTheftDevices;
    loaded: boolean;
    loading: boolean;
  };
  vehicleEligibleDiscounts: {
    data: EligibleDiscountEntity[];
    loaded: boolean;
    loading: boolean;
  };
  vehiclePrimaryUseOptions: {
    data: VehiclePrimaryUseOptions;
    loaded: boolean;
    loading: boolean;
  };
}

export const initialState: MetadataState = {
  maritalStatus: {
    data: [],
    loaded: false,
    loading: false,
  },
  suffix: {
    data: [],
    loaded: false,
    loading: false,
  },
  stateAbbreviations: {
    data: [],
    loaded: false,
    loading: false,
  },
  activeStates: {
    data: [],
    loaded: false,
    loading: false,
  },
  agencyChannel: {
    data: [],
    loaded: false,
    loading: false,
  },
  infractionDesc: {
    data: [],
    loaded: false,
    loading: false,
  },
  infractionType: {
    data: [],
    loaded: false,
    loading: false,
  },
  stateSpecificFlags: {
    data: {
      accidentPreventionCourseApplicable: false,
      additionalEligibleDiscount: false,
      isAdditionalPerilsDisclaimer: false,
      isThirdPartyDesigneeDisclaimer: false,
      ageFirstLicensed: false,
      animalBiteDisclaimer: false,
      annualMileageUpdate: false,
      antiTheftDevices: false,
      applicationLanguage: false,
      assignedRiskPlan: false,
      associateDiscount: false,
      auxillaryLightingSystemDiscountApplicable: false,
      combineHurricaneOptions: false,
      coveragesDisclaimer: false,
      defaultEstimatedAnnualMiles: false,
      defaultAnnualMiles: false,
      driverOccupation: false,
      driverRelationToPNI: false,
      driverTrainingDiscount: false,
      driverVehicleAssignment: false,
      dwellingReplacementCost: false,
      earthquakeCoverageEligible: false,
      earthquakeCoverageMessage: false,
      eligibleDiscountApplicable: false,
      footerUnderwritingDisclaimer: false,
      fullSafetyGlassSelectboxRemovableState: false,
      goodStudent: false,
      healthCarePlan: false,
      increasedOtherStructures: false,
      infractionDesc: false,
      isBiologicalDeteriorationCoverageApplicable: false,
      isStatePausedRestriction: false,
      isDeclinePIPLimitCoverageApplicable: false,
      isDeviceOnlyState: false,
      isEligibleDiscountDriverRequest: false,
      isExcludeTDIcoverageIfUnavailable: false,
      isMonoLineBrokerRestriction: false,
      isNoPlayStateTermLifeRestricted: false,
      isNoPlayStatePetRestricted: false,
      isPetUnavailableState: false,
      isNoPlayStateAllProductsRestricted: false,
      isPauseStatePetRestricted: false,
      isPipExcluded: false,
      isPreviouslyOwned: false,
      isUIMPDCoverageApplicable: false,
      loiNotMandatory: false,
      makeDEPIPMandatoryForPowersports: false,
      motoEndorseApplicable: false,
      msbDisclaimerMessage: false,
      nonSpecifiedGenderApplicable: false,
      nonStandardBridgeAllowed: false,
      nonStandardBridgeCoveragesNotAllowed: false,
      offeringTypeApplicable: false,
      passiveDisablingOnly: false,
      pipWorkLossWaiver: false,
      primaryUseApplicable: false,
      powersportsProductApplicable: false,
      preBindDocuments: false,
      removeContinuousInsuranceQuestion: false,
      removeEftPaymentOption: false,
      removeLowSpeedVehicleOption: false,
      removeManualEftPaymentOption: false,
      removeMedicalBenefitsDeclineOption: false,
      requireAdvancedAccidentPrevention: false,
      requiredOilTank: false,
      requiredUSArmedForces: false,
      requiredNationalGaurd: false,
      restrictAllProductsForDirect: false,
      restrictHOProduct: false,
      restrictRentersProductForNSS: false,
      restrictWindstormProtectiveDeviceToHomeowners: false,
      reviewConstructionInfo: false,
      ridingAssociationQuestion: false,
      selfReportedProgram: false,
      seperateWindandHailorHurricanesandotherperils: false,
      showAdditionalHelpTextForCoverages: false,
      showAdditionalProtectionDevices: false,
      showAdoptAccordLanguage: false,
      showDogsInPropertyDisqualifier: false,
      showDwellingReplacementCost: false,
      showPersonalUmbrellaLiabilityApplicable: false,
      sinkhole: false,
      smartHomeDiscountApplicable: false,
      smartMiles: false,
      smartrideAcceptAFMVF: false,
      smartRideEarlyReward: false,
      srccIneligible: false,
      srccForCA: false,
      sriIneligible: false,
      stateApprovedSafetyCourse: false,
      surchargeDisclosureStatement: false,
      umbiLimitDoubleBi: false,
      vehicleDailyUseQuestions: false,
      vehicleHistoricalQuestionApplicable: false,
      vehicleMiles: false,
      vehicleOdometerReading: false,
      vehicleRegisteredInState: false,
      vehicleRegisteredOwners: false,
      vehicleRegisteredOwnersForPowersports: false,
      windMitigationDiscountApplicable: false,
      workersCompensationHelpText: false,
      yearsOfMotorcycleAndOffRoadExp: false,
      membershipDiscount: false,
      umbiValidOption: false,
      thirdPartyPropertyPause: false,
    },
    loaded: false,
    loading: false,
  },
  features: {
    data: [],
    loaded: false,
    loading: false,
  },
  connectedCarCriteria: {
    data: [],
    loaded: false,
    loading: false,
  },
  antiTheftDevices: {
    data: {
      devices: [],
    },
    loaded: false,
    loading: false,
  },
  vehicleEligibleDiscounts: {
    data: [],
    loaded: false,
    loading: false,
  },
  vehiclePrimaryUseOptions: {
    data: {
      primaryUse: [],
    },
    loaded: false,
    loading: false,
  },
};

export interface StateSpecificFlagsObject {
  accidentPreventionCourseApplicable?: boolean;
  additionalEligibleDiscount?: boolean;
  isAdditionalPerilsDisclaimer?: boolean;
  isThirdPartyDesigneeDisclaimer?: boolean;
  ageFirstLicensed?: boolean;
  animalBiteDisclaimer?: boolean;
  annualMileageUpdate?: boolean;
  antiTheftDevices?: boolean;
  applicationLanguage?: boolean;
  assignedRiskPlan?: boolean;
  associateDiscount?: boolean;
  auxillaryLightingSystemDiscountApplicable?: boolean;
  combineHurricaneOptions?: boolean;
  coveragesDisclaimer?: boolean;
  defaultEstimatedAnnualMiles?: boolean;
  defaultAnnualMiles?: boolean;
  driverOccupation?: boolean;
  driverRelationToPNI?: boolean;
  driverTrainingDiscount?: boolean;
  driverVehicleAssignment?: boolean;
  dwellingReplacementCost?: boolean;
  earthquakeCoverageEligible?: boolean;
  earthquakeCoverageMessage?: boolean;
  eligibleDiscountApplicable?: boolean;
  footerUnderwritingDisclaimer?: boolean;
  fullSafetyGlassSelectboxRemovableState?: boolean;
  goodStudent?: boolean;
  healthCarePlan?: boolean;
  increasedOtherStructures?: boolean;
  infractionDesc?: boolean;
  isStatePausedRestriction?: boolean;
  isBiologicalDeteriorationCoverageApplicable?: boolean;
  isDeclinePIPLimitCoverageApplicable?: boolean;
  isDeviceOnlyState?: boolean;
  isEligibleDiscountDriverRequest?: boolean;
  isExcludeTDIcoverageIfUnavailable?: boolean;
  isMonoLineBrokerRestriction?: boolean;
  isNoPlayStateAllProductsRestricted?: boolean;
  isNoPlayStateTermLifeRestricted?: boolean;
  isNoPlayStatePetRestricted?: boolean;
  isPauseStatePetRestricted?: boolean;
  isPetUnavailableState?: boolean;
  isPipExcluded?: boolean;
  isPreviouslyOwned?: boolean;
  isUIMPDCoverageApplicable?: boolean;
  loiNotMandatory?: boolean;
  makeDEPIPMandatoryForPowersports?: boolean;
  motoEndorseApplicable?: boolean;
  msbDisclaimerMessage?: boolean;
  nonSpecifiedGenderApplicable?: boolean;
  nonStandardBridgeAllowed?: boolean;
  nonStandardBridgeCoveragesNotAllowed?: boolean;
  offeringTypeApplicable?: boolean;
  passiveDisablingOnly?: boolean;
  pipWorkLossWaiver?: boolean;
  primaryUseApplicable?: boolean;
  powersportsProductApplicable?: boolean;
  preBindDocuments?: boolean;
  removeContinuousInsuranceQuestion?: boolean;
  removeEftPaymentOption?: boolean;
  removeLowSpeedVehicleOption?: boolean;
  removeManualEftPaymentOption?: boolean;
  removeMedicalBenefitsDeclineOption?: boolean;
  requireAdvancedAccidentPrevention?: boolean;
  requiredOilTank?: boolean;
  requiredUSArmedForces?: boolean;
  requiredNationalGaurd?: boolean;
  restrictAllProductsForDirect?: boolean;
  restrictHOProduct?: boolean;
  restrictRentersProductForNSS?: boolean;
  restrictWindstormProtectiveDeviceToHomeowners?: boolean;
  reviewConstructionInfo?: boolean;
  ridingAssociationQuestion?: boolean;
  selfReportedProgram?: boolean;
  seperateWindandHailorHurricanesandotherperils?: boolean;
  showAdditionalHelpTextForCoverages?: boolean;
  showAdditionalProtectionDevices?: boolean;
  showAdoptAccordLanguage?: boolean;
  showDogsInPropertyDisqualifier?: boolean;
  showDwellingReplacementCost?: boolean;
  showPersonalUmbrellaLiabilityApplicable?: boolean;
  sinkhole?: boolean;
  smartHomeDiscountApplicable?: boolean;
  smartMiles?: boolean;
  smartrideAcceptAFMVF?: boolean;
  smartRideEarlyReward?: boolean;
  srccIneligible?: boolean;
  srccForCA?: boolean;
  sriIneligible?: boolean;
  stateApprovedSafetyCourse?: boolean;
  surchargeDisclosureStatement?: boolean;
  umbiLimitDoubleBi?: boolean;
  vehicleDailyUseQuestions?: boolean;
  vehicleHistoricalQuestionApplicable?: boolean;
  vehicleMiles?: boolean;
  vehicleOdometerReading?: boolean;
  vehicleRegisteredInState?: boolean;
  vehicleRegisteredOwners?: boolean;
  vehicleRegisteredOwnersForPowersports?: boolean;
  windMitigationDiscountApplicable?: boolean;
  workersCompensationHelpText?: boolean;
  yearsOfMotorcycleAndOffRoadExp?: boolean;
  membershipDiscount?: boolean;
  umbiValidOption?: boolean;
  thirdPartyPropertyPause?: boolean;
}

export function reducer(
  state = initialState,
  action: fromMetadata.MetadataActions
): MetadataState {
  switch (action.type) {
    case fromMetadata.LOAD_MARITAL_STATUS: {
      const maritalStatus = { ...state.maritalStatus, loading: true };

      return {
        ...state,
        maritalStatus,
      };
    }

    case fromMetadata.LOAD_MARITAL_STATUS_FAIL: {
      const maritalStatus = {
        ...state.maritalStatus,
        loading: false,
        loaded: false,
      };

      return {
        ...state,
        maritalStatus,
      };
    }

    case fromMetadata.LOAD_MARITAL_STATUS_SUCCESS: {
      const data = action.payload;
      const maritalStatus = {
        ...state.maritalStatus,
        data,
        loading: false,
        loaded: true,
      };

      return {
        ...state,
        maritalStatus,
      };
    }

    case fromMetadata.LOAD_SUFFIX: {
      const suffix = { ...state.suffix, loading: true };

      return {
        ...state,
        suffix,
      };
    }

    case fromMetadata.LOAD_SUFFIX_FAIL: {
      const suffix = {
        ...state.suffix,
        loading: false,
        loaded: false,
      };

      return {
        ...state,
        suffix,
      };
    }

    case fromMetadata.LOAD_SUFFIX_SUCCESS: {
      const data = action.payload;
      const suffix = {
        ...state.suffix,
        data,
        loading: false,
        loaded: true,
      };

      return {
        ...state,
        suffix,
      };
    }

    case fromMetadata.LOAD_STATE_ABBREVIATIONS: {
      const stateAbbreviations = {
        ...state.stateAbbreviations,
        loading: true,
      };

      return {
        ...state,
        stateAbbreviations,
      };
    }

    case fromMetadata.LOAD_STATE_ABBREVIATIONS_FAIL: {
      const stateAbbreviations = {
        ...state.stateAbbreviations,
        loading: false,
        loaded: false,
      };

      return {
        ...state,
        stateAbbreviations,
      };
    }

    case fromMetadata.LOAD_STATE_ABBREVIATIONS_SUCCESS: {
      const data = action.payload;
      const stateAbbreviations = {
        ...state.stateAbbreviations,
        data,
        loading: false,
        loaded: true,
      };

      return {
        ...state,
        stateAbbreviations,
      };
    }

    case fromMetadata.LOAD_ACTIVE_STATE_ABBREVIATIONS: {
      const activeStates = {
        ...state.activeStates,
        loading: true,
      };

      return {
        ...state,
        activeStates,
      };
    }

    case fromMetadata.LOAD_ACTIVE_STATE_ABBREVIATIONS_FAIL: {
      const activeStates = {
        ...state.activeStates,
        loading: false,
        loaded: false,
      };

      return {
        ...state,
        activeStates,
      };
    }

    case fromMetadata.LOAD_ACTIVE_STATE_ABBREVIATIONS_SUCCESS: {
      const data = action.payload;
      const activeStates = {
        ...state.activeStates,
        data,
        loading: false,
        loaded: true,
      };

      return {
        ...state,
        activeStates,
      };
    }

    case fromMetadata.LOAD_AGENCY_CHANNEL: {
      const agencyChannel = { ...state.agencyChannel, loading: true };

      return {
        ...state,
        agencyChannel,
      };
    }

    case fromMetadata.LOAD_AGENCY_CHANNEL_FAIL: {
      const agencyChannel = {
        ...state.agencyChannel,
        loading: false,
        loaded: false,
      };

      return {
        ...state,
        agencyChannel,
      };
    }

    case fromMetadata.LOAD_AGENCY_CHANNEL_SUCCESS: {
      const data = action.payload;
      const agencyChannel = {
        ...state.agencyChannel,
        data,
        loading: false,
        loaded: true,
      };

      return {
        ...state,
        agencyChannel,
      };
    }

    case fromMetadata.LOAD_STATE_SPECIFIC_FLAGS: {
      return {
        ...state,
        stateSpecificFlags: {
          ...state.stateSpecificFlags,
          loading: true,
        },
      };
    }

    case fromMetadata.LOAD_STATE_SPECIFIC_FLAGS_FAIL: {
      return {
        ...state,
        stateSpecificFlags: {
          ...state.stateSpecificFlags,
          loading: false,
          loaded: false,
        },
      };
    }

    case fromMetadata.LOAD_STATE_SPECIFIC_FLAGS_SUCCESS: {
      const input = <any>action.payload;
      return {
        ...state,
        stateSpecificFlags: {
          ...state.stateSpecificFlags,
          ...input,
          loading: false,
          loaded: true,
        },
      };
    }

    case fromMetadata.LOAD_FEATURES: {
      const features = { ...state.features, loading: true };

      return {
        ...state,
        features,
      };
    }

    case fromMetadata.LOAD_FEATURES_FAIL: {
      const features = {
        ...state.features,
        loading: false,
        loaded: false,
      };

      return {
        ...state,
        features,
      };
    }

    case fromMetadata.LOAD_FEATURES_SUCCESS: {
      const data = action.payload;
      const features = {
        ...state.features,
        data,
        loading: false,
        loaded: true,
      };

      return {
        ...state,
        features,
      };
    }

    case fromMetadata.LOAD_CONNECTED_CAR_CRITERIA: {
      const connectedCarCriteria = {
        ...state.connectedCarCriteria,
        loading: true,
        loaded: false,
      };

      return {
        ...state,
        connectedCarCriteria,
      };
    }

    case fromMetadata.LOAD_CONNECTED_CAR_CRITERIA_SUCCESS: {
      const data = action.payload;
      const connectedCarCriteria = {
        ...state.connectedCarCriteria,
        data,
        loading: false,
        loaded: true,
      };

      return {
        ...state,
        connectedCarCriteria,
      };
    }

    case fromMetadata.LOAD_CONNECTED_CAR_CRITERIA_FAIL: {
      const connectedCarCriteria = {
        ...state.connectedCarCriteria,
        loading: false,
        loaded: false,
      };

      return {
        ...state,
        connectedCarCriteria,
      };
    }

    case fromMetadata.LOAD_ANTI_THEFT_DEVICES: {
      return {
        ...state,
        antiTheftDevices: {
          ...state.antiTheftDevices,
          loading: true,
          loaded: false,
        },
      };
    }

    case fromMetadata.LOAD_ANTI_THEFT_DEVICES_SUCCESS: {
      return {
        ...state,
        antiTheftDevices: {
          data: action.payload,
          loading: false,
          loaded: true,
        },
      };
    }

    case fromMetadata.LOAD_ANTI_THEFT_DEVICES_FAIL: {
      return {
        ...state,
        antiTheftDevices: {
          data: { devices: [] },
          loaded: false,
          loading: false,
        },
      };
    }

    case fromMetadata.LOAD_VEHICLE_ELIGIBLE_DISCOUNTS: {
      return {
        ...state,
        vehicleEligibleDiscounts: {
          ...state.vehicleEligibleDiscounts,
          loading: true,
          loaded: false,
        },
      };
    }

    case fromMetadata.LOAD_VEHICLE_ELIGIBLE_DISCOUNTS_SUCCESS: {
      return {
        ...state,
        vehicleEligibleDiscounts: {
          data: action.payload,
          loading: false,
          loaded: true,
        },
      };
    }

    case fromMetadata.LOAD_VEHICLE_ELIGIBLE_DISCOUNTS_FAIL: {
      return {
        ...state,
        vehicleEligibleDiscounts: {
          data: [],
          loaded: false,
          loading: false,
        },
      };
    }

    case fromMetadata.LOAD_VEHICLE_PRIMARY_USE_OPTIONS: {
      return {
        ...state,
        vehiclePrimaryUseOptions: {
          ...state.vehiclePrimaryUseOptions,
          loading: true,
          loaded: false,
        },
      };
    }

    case fromMetadata.LOAD_VEHICLE_PRIMARY_USE_OPTIONS_SUCCESS: {
      return {
        ...state,
        vehiclePrimaryUseOptions: {
          data: action.payload,
          loading: false,
          loaded: true,
        },
      };
    }

    case fromMetadata.LOAD_VEHICLE_PRIMARY_USE_OPTIONS_FAIL: {
      return {
        ...state,
        vehiclePrimaryUseOptions: {
          data: { primaryUse: [] },
          loaded: false,
          loading: false,
        },
      };
    }
  }
  return state;
}

export const getMaritalStatus = (state: MetadataState) => state.maritalStatus;
export const getSuffix = (state: MetadataState) => state.suffix;
export const getStateAbbreviationsState = (state: MetadataState) =>
  state.stateAbbreviations;
export const getActiveStateAbbreviationsState = (state: MetadataState) =>
  state.activeStates;
export const getAgencyChannel = (state: MetadataState) => state.agencyChannel;
export const getFeatures = (state: MetadataState) => state.features;
