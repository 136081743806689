import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';

import { StandardizedAddresses } from '@core/models/address/standardized-address.model';

@Injectable()
export class AddressDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  loadStandardizedAddresses(street: string, unitNumber: string) {
    this._store.dispatch(
      new fromActions.LoadStandardizedAddress({ street, unitNumber })
    );
  }

  getStandardizedAddresses(): Observable<StandardizedAddresses> {
    return this._store.select(fromSelectors.getStandardizedAddresses);
  }

  getStandardizedAddressesLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getStandardizedAddressLoaded);
  }

  getStandardizedAddressesLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getStandardizedAddressLoading);
  }

  updateUnitNumber(unitNumber): void {
    return this._store.dispatch(new fromActions.UpdateUnitNumber(unitNumber));
  }

  resetAddressLoaded() {
    this._store.dispatch(new fromActions.ResetAddressLoaded());
  }
}
