import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  PremiumComparison,
  TermLifeEntity,
} from '@core/models/termlife/termlife.entity';
import { CoverageHelper } from '@core/services/helpers/coverage.helper';
import {
  DEFAULT_ID,
  TermLifeCvgAmtCodes,
} from '@shared/constants/app-constants';

@Component({
  selector: 'mq-term-life-members-form',
  templateUrl: './term-life-members-form.component.html',
  styleUrls: ['./term-life-members-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermLifeMembersFormComponent implements OnInit {
  @Input() termPerson: TermLifeEntity;
  @Input() displayType: string;

  @Output() termLifeFormSubmit = new EventEmitter<TermLifeEntity>();
  @Output() dsmFormSubmit = new EventEmitter();

  form: UntypedFormGroup;
  termLifeDisplays: PremiumComparison[];
  previousEstimate: number;
  fieldId = DEFAULT_ID;
  termLifeCvgAmtCodes = TermLifeCvgAmtCodes;
  termLifeCvgAmtKeys = Object.keys(TermLifeCvgAmtCodes);
  constructor(
    private _fb: UntypedFormBuilder,
    private coverageHelper: CoverageHelper
  ) {}

  ngOnInit(): void {
    this._buildForm();
    if (this.termPerson) {
      if (!this.termPerson.costEstimate) {
        this.termLifeDisplays =
          this.coverageHelper.mergeSpreadableCoveragesLife(
            this.termPerson,
            this.coverageTerm.value
          );
      } else {
        this.termLifeDisplays =
          this.coverageHelper.mergeSpreadableCoveragesLife(
            this.termPerson,
            this.termPerson.coverageTerm
          );
        this.form.patchValue({
          coverageTerm: this.termPerson.coverageTerm,
          coverageAmount: this.termPerson.coverageAmount,
        });
      }
      this.fieldId = this.termPerson.id;
    }
  }

  onCoverageAmountChange(event) {
    if (this.coverageTerm.value && event && this.termPerson) {
      let termLifeEntity = this.coverageHelper.getCostEstimate(
        this.coverageTerm.value,
        event,
        this.termPerson
      );
      termLifeEntity = { ...termLifeEntity, id: this.termPerson.id };
      this.termLifeFormSubmit.emit(termLifeEntity);
    }
  }

  onCoverageTermChange(event) {
    if (this.coverageAmount.value && event && this.termPerson) {
      let termLifeEntity = this.coverageHelper.getCostEstimate(
        event,
        this.coverageAmount.value,
        this.termPerson
      );
      termLifeEntity = { ...termLifeEntity, id: this.termPerson.id };
      this.termLifeFormSubmit.emit(termLifeEntity);
    }
  }

  onClick(event) {
    this.form.patchValue({
      coverageTerm: event,
    });
    this.termLifeDisplays = this.coverageHelper.mergeSpreadableCoveragesLife(
      this.termPerson,
      event
    );
  }

  onSubmit(form: UntypedFormGroup) {
    const { valid, value } = form;

    this.dsmFormSubmit.emit({
      dsmPerson: this.termPerson,
      id: this.termPerson.id,
      respsonse: this.termPerson.termLifeResponse,
      ...value,
      prev: this.previousEstimate,
    });
  }

  showTermLifePremium(
    faceAmount: string,
    premiumAmount: number,
    form: UntypedFormGroup
  ) {
    if (Number(faceAmount) === +this.coverageAmount.value) {
      this.form.patchValue({ costEstimate: premiumAmount });
      this.onSubmit(form);
      return true;
    }
    return this.coverageAmount.value === faceAmount;
  }

  private _buildForm(): void {
    this.form = this._fb.group({
      coverageTerm: this._fb.control('Nationwide 10-year Term GLT', []),
      coverageAmount: this._fb.control('100000', []),
      costEstimate: this._fb.control(null, []),
    });
  }

  get coverageTerm() {
    return this.form.get('coverageTerm');
  }

  get coverageAmount() {
    return this.form.get('coverageAmount');
  }

  get costEstimate() {
    return this.form.get('costEstimate');
  }
}
