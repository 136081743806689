import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as fromServices from '@core/services';
import * as addressActions from '@core/store/actions/address.action';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable()
export class AddressEffects {
  constructor(
    private _actions$: Actions,
    private _addressService: fromServices.AddressService,
    private _billingService: fromServices.BillingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  loadStandardizedAddress$ = this._actions$.pipe(
    ofType(addressActions.LOAD_STANDARDIZED_ADDRESS),
    map((action: addressActions.LoadStandardizedAddress) => action.payload),
    switchMap(addr => {
      return this._addressService
        .loadStandardizedAddress(addr['street'], addr['unitNumber'])
        .pipe(
          map(address => {
            this._billingService.updateBillingAddress({
              street: addr['street'],
              unitNumber: addr['unitNumber'],
              standardizedAddresses: address,
            });
            return new addressActions.LoadStandardizedAddressSuccess(address);
          }),
          catchError(error => {
            const safeError = this._errorHelper.sanitizeError(error);
            return of(
              new addressActions.LoadStandardizedAddressFail(safeError)
            );
          })
        );
    })
  );
}
