import { Action } from '@ngrx/store';

export const LOAD_COMP_RATER_NEWS = '[CompRater] Load News';
export const LOAD_COMP_RATER_NEWS_SUCCESS = '[CompRater] Load News Success';
export const LOAD_COMP_RATER_NEWS_FAIL = '[CompRater] Load News Fail';

export class LoadCompRaterNews implements Action {
  readonly type = LOAD_COMP_RATER_NEWS;
}

export class LoadCompRaterNewsSuccess implements Action {
  readonly type = LOAD_COMP_RATER_NEWS_SUCCESS;
  constructor(public html: string) {}
}

export class LoadCompRaterNewsFail implements Action {
  readonly type = LOAD_COMP_RATER_NEWS_FAIL;
}

export type CompRaterNewsActions =
  | LoadCompRaterNews
  | LoadCompRaterNewsSuccess
  | LoadCompRaterNewsFail;
