<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">SmartRide Device</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    Be rewarded for driving fewer miles. Join this free program and start
    saving.
  </p>
  <ul>
    <li>Get a guaranteed <strong>5% discount</strong> for participating</li>
    <li>
      Earn <strong>up to 15% off</strong> based on the miles you drive annually
    </li>
  </ul>
  <p>
    We’ll send you a small device that plugs into your vehicle’s dashboard to
    help determine your discount, based on your actual mileage.
  </p>
  <p>
    <a
      href="https://www.nationwide.com/personal/insurance/auto/discounts/smartride/"
      target="_blank"
      rel="noopener"
      >Click here</a
    >
    for program details.
  </p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Close click')"
  >
    Close
  </button>
</div>
