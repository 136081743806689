import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { DriverService } from '@core/services/driver.service';
import { VehicleService } from '@core/services/vehicle.service';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class DriversVehiclesLoadingGuard implements CanActivate {
  constructor(
    private _driverService: DriverService,
    private _vehicleService: VehicleService
  ) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this._driverService.getDriversLoading(),
      this._vehicleService.getVehiclesLoading(),
    ]).pipe(
      map(
        ([driversLoading, vehiclesLoading]) =>
          !(driversLoading || vehiclesLoading)
      ),
      filter(trueOnly => trueOnly)
    );
  }
}
