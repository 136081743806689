import { Action } from '@ngrx/store';
import { CoverageEntity } from '@core/models/entities/coverage.entity';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';

export const enum CoverageActionTypes {
  UPDATE_COVERAGE = '[Coverage] Update Coverage',
  UPDATE_COVERAGE_FAIL = '[Coverage] Update Coverage Fail',
  UPDATE_COVERAGE_SUCCESS = '[Coverage] Update Coverage Success',
  UPDATE_EXTRA_COVERAGE_BY_RULE = '[Coverage] Update Extra Coverage By Rule',

  ADD_MANY_COVERAGES = '[Coverage] Add Many Coverages',

  REMOVE_ALL_COVERAGES = '[Coverage] Remove All Coverages',
  REMOVE_PRODUCT_COVERAGES = '[Coverage] Remove Product Coverages',

  UPDATE_COVERAGE_CHANGE = '[Coverage] Update Coverage Change',
  UPDATE_SELECTED_PRODUCT_COVERAGE = '[Coverage] Update Selected Product Coverage',
  UPDATE_SELECTED_COVERAGE_PRODUCTS = '[Coverage] Update Selected Coverage Products',
  ADD_SELECTED_COVERAGE_PRODUCT = '[Coverage] Add Selected Coverage Product',
  REMOVE_SELECTED_COVERAGE_PRODUCT = '[Coverage] Remove Selected Coverage Product',

  LOAD_ALL_COVERAGES = '[Coverage] Load All Coverages',
  LOAD_ALL_COVERAGES_SUCCESS = '[Coverage] Load All Coverages Success',
  LOAD_ALL_COVERAGES_FAIL = '[Coverage] Load All Coverages Fail',

  UPDATE_DWELLING_CONSENT = '[Coverage] Dwelling Consent Updated',
}

export class AddManyCoverages implements Action {
  readonly type = CoverageActionTypes.ADD_MANY_COVERAGES;
  constructor(public payload: CoverageEntity[]) {}
}

export class RemoveAllCoverages implements Action {
  readonly type = CoverageActionTypes.REMOVE_ALL_COVERAGES;
}

export class RemoveProductCoverages implements Action {
  readonly type = CoverageActionTypes.REMOVE_PRODUCT_COVERAGES;
  constructor(public payload: string) {}
}

export class LoadCoverages implements Action {
  readonly type = CoverageActionTypes.LOAD_ALL_COVERAGES;
  constructor(public payload: string) {}
}

export class LoadCoveragesSuccess implements Action {
  readonly type = CoverageActionTypes.LOAD_ALL_COVERAGES_SUCCESS;
  constructor(public productId: string, public payload: CoverageEntity[]) {}
}

export class LoadCoveragesFail implements Action {
  readonly type = CoverageActionTypes.LOAD_ALL_COVERAGES_FAIL;
  constructor(public productId: string, public error: CoverageError[]) {}
}

export class UpdateCoverage implements Action {
  readonly type = CoverageActionTypes.UPDATE_COVERAGE;
  constructor(
    public payload: string,
    public coverageChanges?: CoverageChange[]
  ) {}
}

export class UpdateCoverageSuccess implements Action {
  readonly type = CoverageActionTypes.UPDATE_COVERAGE_SUCCESS;
  constructor(public payload: string) {}
}

export class UpdateCoverageFail implements Action {
  readonly type = CoverageActionTypes.UPDATE_COVERAGE_FAIL;
  constructor(public productId: string, public error: CoverageError[]) {}
}

export class UpdateCoverageChange implements Action {
  readonly type = CoverageActionTypes.UPDATE_COVERAGE_CHANGE;
  constructor(public payload: CoverageChange[]) {}
}

export class UpdateExtraCoverageByRule implements Action {
  readonly type = CoverageActionTypes.UPDATE_EXTRA_COVERAGE_BY_RULE;
  constructor(public payload: string) {}
}

export class UpdateSelectedProductCoverage implements Action {
  readonly type = CoverageActionTypes.UPDATE_SELECTED_PRODUCT_COVERAGE;
  constructor(public payload: string) {}
}

export class UpdateSelectedCoverageProducts implements Action {
  readonly type = CoverageActionTypes.UPDATE_SELECTED_COVERAGE_PRODUCTS;
  constructor(public payload: string[]) {}
}

export class AddSelectedCoverageProduct implements Action {
  readonly type = CoverageActionTypes.ADD_SELECTED_COVERAGE_PRODUCT;
  constructor(public payload: string) {}
}

export class RemoveSelectedCoverageProduct implements Action {
  readonly type = CoverageActionTypes.REMOVE_SELECTED_COVERAGE_PRODUCT;
  constructor(public payload: string) {}
}

export class UpdateDwellingConsent implements Action {
  readonly type = CoverageActionTypes.UPDATE_DWELLING_CONSENT;
  constructor(public payload: boolean) {}
}

export type CoverageActions =
  | AddManyCoverages
  | RemoveAllCoverages
  | RemoveProductCoverages
  | LoadCoverages
  | LoadCoveragesSuccess
  | LoadCoveragesFail
  | UpdateCoverage
  | UpdateCoverageFail
  | UpdateCoverageSuccess
  | UpdateCoverageChange
  | UpdateExtraCoverageByRule
  | UpdateSelectedProductCoverage
  | UpdateSelectedCoverageProducts
  | AddSelectedCoverageProduct
  | RemoveSelectedCoverageProduct
  | UpdateDwellingConsent;
