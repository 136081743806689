import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsIndependentOrExclusiveAgentDirective } from './is-independent-or-exclusive-agent.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsIndependentOrExclusiveAgentDirective],
  exports: [IsIndependentOrExclusiveAgentDirective],
})
export class IsIndependentOrExclusiveAgentModule {}
