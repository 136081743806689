import { createSelector } from '@ngrx/store';
import * as fromPolicyholder from '@core/store/selectors/policyholder.selector';
import * as fromDrivers from '@core/store/selectors/driver.selector';
import * as fromPowersportsDrivers from '@core/store/selectors/powersports-driver.selector';
import * as fromHouseholdMembers from '@core/store/selectors/household-member.selector';
import * as fromProducts from '@core/store/selectors/products.selector';

import { PersonViewModel } from '@core/models/entities/driver.entity';
import { PersonEntity } from '@core/models/entities/person.entity';
import {
  DEFAULT_ID,
  MaritalStatusCodes,
  PolicyholderTypes,
  DriverRelationToPNI,
  ProductTypes,
} from '@shared/constants/app-constants';
import { HouseholdMemberEntity } from '@core/models/entities/household-member.entity';
import { StringUtils } from '@shared/utils/string.utils';

export const getAllPeople = createSelector(
  fromPolicyholder.selectAllPolicyholders,
  fromDrivers.selectAllDrivers,
  fromPowersportsDrivers.selectAllPowersportsDrivers,
  fromHouseholdMembers.selectAllHouseholdMembers,
  fromProducts.getSelectedQuoteProductsWithoutErrors,
  fromDrivers.getAutoAndPowersportsDriversMapping,
  (
    policyholders,
    drivers,
    powersportsDrivers,
    householdMembers,
    validProducts,
    autoandPowersportsDriverMapping
  ) => {
    const models: PersonViewModel[] = [];

    if (policyholders && policyholders.length > 0) {
      policyholders.forEach(policyholder => {
        const policyHolderId = resolvePolicyHolderId(
          policyholder.policyHolderId,
          policyholder.policyHolderIds,
          policyholder.productId
        );

        if (models.length > 0) {
          let found: PersonViewModel, index: number;
          models.forEach((model, i) => {
            if (isSamePerson(model.person, policyholder.person)) {
              found = model;
              index = i;
            }
          });

          if (found) {
            const temp = {
              productIds: policyholder.productIds,
              emailAddress: policyholder.emailAddress,
              addressId: policyholder.addressId,
              policyHolderIds: policyholder.policyHolderIds,
              policyHolderType: policyholder.policyHolderType,
              relationToPrimaryNamedInsured: StringUtils.areEqual(
                policyholder.policyHolderType,
                PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
              )
                ? DriverRelationToPNI.PNI
                : null,
              prefillId: policyholder.prefillId,
              policyHolderId,
            };
            models[index] = { ...found, ...temp };
          } else {
            models.push({
              ...policyholder,
              policyHolderId,
            });
          }
        } else {
          models.push({
            ...policyholder,
            policyHolderId,
            relationToPrimaryNamedInsured: StringUtils.areEqual(
              policyholder.policyHolderType,
              PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
            )
              ? DriverRelationToPNI.PNI
              : null,
          });
        }
      });
    }

    const hasDriveableProduct = validProducts.find(
      p => p.id === ProductTypes.AUTO || p.id === ProductTypes.POWERSPORTS
    );

    if (drivers && drivers.length > 0 && hasDriveableProduct) {
      drivers.forEach(driver => {
        if (models.length > 0) {
          let found: PersonViewModel, index: number;
          models.forEach((model, i) => {
            if (isSamePerson(model.person, driver.person)) {
              found = model;
              index = i;
            }
          });

          if (found) {
            const temp = {
              driverId: driver.driverId,
              driverType: driver.driverType,
              relationToPrimaryNamedInsured:
                driver.relationToPrimaryNamedInsured,
              licenseNumber: driver.licenseNumber,
              licenseState: driver.licenseState,
              ageFirstLicensed: driver.ageFirstLicensed,
              prefillId: driver.prefillId,
              eligibleDiscounts: driver.eligibleDiscounts,
              healthCarePlan: driver.healthCarePlan,
              isPersonalInjuryProtectionExcluded:
                driver.isPersonalInjuryProtectionExcluded,
              person: {
                ...found.person,
                driverOccupation: driver.driverOccupation,
                infractionDesc: driver.person.infractionDesc,
                infractions: driver.infractions,
              },
              isLivingWithNWInsuredParent: driver.isLivingWithNWInsuredParent,
              termLife: driver.person.termLife,
              termLifeHeightFeet: driver.person.termLifeHeightFeet,
              termLifeHeightInches: driver.person.termLifeHeightInches,
              termLifeWeight: driver.person.termLifeWeight,
              termLifeHealth: driver.person.termLifeHealth,
              termLifeNico: driver.person.termLifeNico,
              hasGraduatedCollege: driver.hasGraduatedCollege,
              goodStudent: driver.goodStudent,
              reasonForCoverageLapse: driver.reasonForCoverageLapse,
            };
            if (powersportsDrivers?.length > 0) {
              const autoandPowersportsMap =
                autoandPowersportsDriverMapping?.get(driver.driverId);
              temp.eligibleDiscounts = temp.eligibleDiscounts?.concat(
                autoandPowersportsMap?.eligibleDiscounts?.filter(x =>
                  temp.eligibleDiscounts.every(
                    y => y?.eligibleDiscountId !== x?.eligibleDiscountId
                  )
                )
              );
            }
            models[index] = { ...found, ...temp };
          } else {
            models.push(driver);
          }
        } else {
          models.push(driver);
        }
      });
    }
    if (powersportsDrivers && powersportsDrivers.length > 0) {
      powersportsDrivers.forEach(powersportsDriver => {
        if (models.length > 0) {
          let found: PersonViewModel, index: number;
          models.forEach((model, i) => {
            if (isSamePerson(model.person, powersportsDriver.person)) {
              found = model;
              index = i;
            }
          });

          if (found) {
            const temp = {
              powersportsDriverId: powersportsDriver.driverId,
              driverType: powersportsDriver.driverType,
              relationToPrimaryNamedInsured:
                powersportsDriver.relationToPrimaryNamedInsured,
              licenseNumber: powersportsDriver.licenseNumber,
              licenseState: powersportsDriver.licenseState,
              ageFirstLicensed: powersportsDriver.ageFirstLicensed,
              yearsOfMotorcycleAndOffRoadExp:
                powersportsDriver.yearsOfMotorcycleAndOffRoadExp,
              prefillId: powersportsDriver.prefillId,
              eligibleDiscounts: powersportsDriver.eligibleDiscounts,
              healthCarePlan: powersportsDriver.healthCarePlan
                ? powersportsDriver.healthCarePlan
                : found.healthCarePlan,
              isPersonalInjuryProtectionExcluded:
                powersportsDriver.isPersonalInjuryProtectionExcluded,
              hasMotorcycleEndorsement:
                powersportsDriver.person.hasMotorcycleEndorsement,
              endorsementDate: powersportsDriver.person.endorsementDate,
              person: {
                ...found.person,
                infractions: powersportsDriver.infractions,
              },
              isLivingWithNWInsuredParent:
                powersportsDriver.isLivingWithNWInsuredParent,
              termLife: powersportsDriver.person.termLife,
              termLifeHeightFeet: powersportsDriver.person.termLifeHeightFeet,
              termLifeHeightInches:
                powersportsDriver.person.termLifeHeightInches,
              termLifeWeight: powersportsDriver.person.termLifeWeight,
              termLifeHealth: powersportsDriver.person.termLifeHealth,
              termLifeNico: powersportsDriver.person.termLifeNico,
              hasGraduatedCollege: powersportsDriver.hasGraduatedCollege,
              goodStudent: powersportsDriver.goodStudent,
              isContinuousInsuranceProvided:
                powersportsDriver.isContinuousInsuranceProvided,
              isPersonalUmbrellaLiabilityProvided:
                powersportsDriver.isPersonalUmbrellaLiabilityProvided,
            };
            models[index] = { ...found, ...temp };
          } else {
            models.push(powersportsDriver);
          }
        } else {
          models.push(powersportsDriver);
        }
      });
    }

    if (householdMembers && householdMembers.length > 0) {
      householdMembers.forEach(member => {
        if (models.length > 0) {
          let found: PersonViewModel, index: number;
          models.forEach((model, i) => {
            if (isSamePerson(model.person, member.person)) {
              found = model;
              index = i;
            }
          });

          if (found) {
            const temp = {
              householdMemberId: member.householdMemberId,
              driverStatus: member.driverStatus,
              employerName: member.employerName,
              hasLiabilityLosses: member.hasLiabilityLosses,
              occupation: member.occupation,
              person: {
                ...found.person,
              },
            } as HouseholdMemberEntity;
            models[index] = { ...found, ...temp };
          } else {
            models.push(member);
          }
        } else {
          models.push(member);
        }
      });
    }
    return models;
  }
);

export const isMaritalStatusValid = createSelector(getAllPeople, people => {
  const marriedPeople = people.filter(
    person =>
      MaritalStatusCodes[person.person.maritalStatus] === MaritalStatusCodes.M
  );

  const separatedPeople = people.filter(
    person =>
      MaritalStatusCodes[person.person.maritalStatus] === MaritalStatusCodes.P
  );

  return marriedPeople.length % 2 === 0 && separatedPeople.length % 2 === 0;
});

export const areMarriedAndSeparatedEvenlyDispersed = createSelector(
  getAllPeople,
  people => {
    if (!people || !people.length || people.length % 2 !== 0) {
      return true;
    }
    const marriedPeople = people.filter(
      person =>
        MaritalStatusCodes[person.person.maritalStatus] === MaritalStatusCodes.M
    );
    const separatedPeople = people.filter(
      person =>
        MaritalStatusCodes[person.person.maritalStatus] === MaritalStatusCodes.P
    );
    return marriedPeople.length % 2 === 0 && separatedPeople.length % 2 === 0;
  }
);

function isSamePerson(model: PersonEntity, product: PersonEntity): boolean {
  return (
    (model.firstName || '') === (product.firstName || '') &&
    (model.middleName || '') === (product.middleName || '') &&
    (model.lastName || '') === (product.lastName || '') &&
    (model.suffix || '') === (product.suffix || '') &&
    (model.dateOfBirth || '').substr(0, 4) ===
      (product.dateOfBirth || '').substr(0, 4)
  );
}

function resolvePolicyHolderId(
  id: string,
  ids: any,
  productId: string
): string {
  if (id !== DEFAULT_ID || !ids) {
    return id;
  }
  if (!productId) {
    // Return whatever is first in (ids)
    for (const key of Object.keys(ids)) {
      return ids[key];
    }
  }
  return ids[productId];
}
