import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HelpContentDao } from '@help-content/dao/help-content.dao';
import { HelpContentModel } from '@help-content/models/help-content.model';

@Injectable()
export class HelpContentModelService {
  constructor(private _helpContentDao: HelpContentDao) {}

  setHelpContent(content: HelpContentModel): void {
    this._helpContentDao.setHelpContent(content);
  }

  isHelpContentDismissed(name: string): Observable<boolean> {
    return this._helpContentDao.isHelpContentDismissed(name);
  }
}
