import * as fromStore from '@core/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { Injectable } from '@angular/core';
import {
  AccountHolderRequest,
  AccountRequest,
} from '@core/models/request/account-request.model';

@Injectable()
export class AccountDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getAccountLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getAccountLoaded);
  }

  getAccountLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getAccountLoading);
  }

  getAccountId(): Observable<string | null> {
    return this._store.select(fromSelectors.getAccountId);
  }

  getAccountHolder(): Observable<AccountHolderRequest> {
    return this._store.select(fromSelectors.getAccountHolder);
  }

  dispatchCreateAccount(): void {
    this._store.dispatch(new fromActions.CreateAccount());
  }

  buildAccountHolder(): Observable<AccountRequest> {
    return this._store.select(fromSelectors.buildCreateAccountRequest);
  }
}
