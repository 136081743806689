import { Action } from '@ngrx/store';

import { PersonEntity } from '@core/models/entities/person.entity';
import { SessionLog } from '@core/models/session-log.model';

export const enum SessionActionTypes {
  INITIALIZE_SESSION = '[Session] Initialize Session',
  INITIALIZE_STATE = '[Core] Initialize State',
  INITIALIZE_SESSION_NAME = '[Session] Initialize Name',
  INITIALIZE_SESSION_FIRSTNAME = '[Core] Initialize first name',
  INITIALIZE_SESSION_LASTNAME = '[Core] Initialize last name',
  INITIALIZE_SESSION_EMAIL = '[Core] Initialize email',
  INITIALIZE_REQUESTED_PRODUCTS = '[Core] Initialize Requested Products',
  INITIALIZE_SESSION_REFERRER = '[Session] Initialize referrer',
  INITIALIZE_HAS_SENT_PET_EMAIL = '[Session] Initialize has sent pet email',
  INITIALIZE_SESSION_PROGRESS_TRACKER_ELIGIBILITY = '[Session] Initilize progress tracker eligibility',
  INITIALIZE_SESSION_IGNORE_PRODUCT_DISABLEMENT = '[Session] Initialize ignore product disablement',
  INITIALIZE_SESSION_IGNORE_CURRENT_CARRIER_VALIADTION = '[Session] Initialize ignore current carrier validation',
  INITIALIZE_SESSION_IGNORE_RISK_FILTER = '[Session] Initialize ignore risk filter',
  INITIALIZE_SESSION_IGNORE_AUTO_UW_RULES = '[Session] Initialize ignore auto UW rules',
  INITIALIZE_SESSION_PC_ENV = '[Session] Initialize pc environment',
  INITIALIZE_SESSION_BILLING_ENV = '[Session] Initialize billing environment',
  INITIALIZE_SESSION_PROGRESS_TRACKER_FLOW_NAME = '[Session] Initialize progress tracker flow name',
  UPDATE_SESSION_LOG_ENTRY = '[Session] Update Session Log Entry',
  SEND_SESSION_LOG_ENTRY = '[Session] Send Session Log Entry',
  CLEAR_SESSION_LOG_ENTRY = '[Session] Clear Session Log Entry',
  INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_ELIGIBILITY = '[Session] Initialize claims reviews loading spinner eligibility',
  INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_ACTIVE = '[Session] Initialize claims reviews loading spinner activation on page name',
  INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_UPPER_MESSAGE = '[Session] Initialize claims reviews loading spinner upper message',
  INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_LOWER_MESSAGE = '[Session] Initialize claims reviews loading spinner lower message',
  INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_REVIEWER_NAME = '[Session] Initialize claims reviews loading spinner reviewer name',
}

export class InitializeSession implements Action {
  readonly type = SessionActionTypes.INITIALIZE_SESSION;
  constructor(public payload: string) {}
}

export class InitializeState implements Action {
  readonly type = SessionActionTypes.INITIALIZE_STATE;
  constructor(public payload: string) {}
}

export class InitializeSessionName implements Action {
  readonly type = SessionActionTypes.INITIALIZE_SESSION_NAME;
  constructor(public payload: PersonEntity) {}
}

export class InitializeSessionFirstName implements Action {
  readonly type = SessionActionTypes.INITIALIZE_SESSION_FIRSTNAME;
  constructor(public payload: string) {}
}

export class InitializeSessionLastName implements Action {
  readonly type = SessionActionTypes.INITIALIZE_SESSION_LASTNAME;
  constructor(public payload: string) {}
}

export class InitializeSessionEmail implements Action {
  readonly type = SessionActionTypes.INITIALIZE_SESSION_EMAIL;
  constructor(public payload: string) {}
}

export class InitializeRequestedProducts implements Action {
  readonly type = SessionActionTypes.INITIALIZE_REQUESTED_PRODUCTS;
  constructor(public payload: string[]) {}
}

export class InitializeSessionReferrer implements Action {
  readonly type = SessionActionTypes.INITIALIZE_SESSION_REFERRER;
  constructor(public payload: string) {}
}

export class InitializeHasSentPetEmail implements Action {
  readonly type = SessionActionTypes.INITIALIZE_HAS_SENT_PET_EMAIL;
  constructor(public payload: boolean) {}
}

export class InitializeSessionProgressTrackerEligibility implements Action {
  readonly type =
    SessionActionTypes.INITIALIZE_SESSION_PROGRESS_TRACKER_ELIGIBILITY;
  constructor(public payload: boolean) {}
}

export class InitializeSessionProgressTrackerFlowName implements Action {
  readonly type =
    SessionActionTypes.INITIALIZE_SESSION_PROGRESS_TRACKER_FLOW_NAME;
  constructor(public payload: string) {}
}

export class InitializeSessionIgnoreProductDisablement implements Action {
  readonly type =
    SessionActionTypes.INITIALIZE_SESSION_IGNORE_PRODUCT_DISABLEMENT;
  constructor(public payload: boolean) {}
}
export class InitializeSessionIgnoreCurrentCarrierValidation implements Action {
  readonly type =
    SessionActionTypes.INITIALIZE_SESSION_IGNORE_CURRENT_CARRIER_VALIADTION;
  constructor(public payload: boolean) {}
}
export class InitializeSessionIgnoreRiskFilter implements Action {
  readonly type = SessionActionTypes.INITIALIZE_SESSION_IGNORE_RISK_FILTER;
  constructor(public payload: boolean) {}
}

export class InitializeSessionIgnoreAutoUWRules implements Action {
  readonly type = SessionActionTypes.INITIALIZE_SESSION_IGNORE_AUTO_UW_RULES;
  constructor(public payload: boolean) {}
}
export class InitializeSessionPCEnv implements Action {
  readonly type = SessionActionTypes.INITIALIZE_SESSION_PC_ENV;
  constructor(public payload: string) {}
}
export class InitializeSessionBillingEnv implements Action {
  readonly type = SessionActionTypes.INITIALIZE_SESSION_BILLING_ENV;
  constructor(public payload: string) {}
}
export class UpdateSessionLogEntry implements Action {
  readonly type = SessionActionTypes.UPDATE_SESSION_LOG_ENTRY;
  constructor(public payload: SessionLog) {}
}
export class SendSessionLogEntry implements Action {
  readonly type = SessionActionTypes.SEND_SESSION_LOG_ENTRY;
  constructor(public payload: string) {}
}
export class ClearSessionLogEntry implements Action {
  readonly type = SessionActionTypes.CLEAR_SESSION_LOG_ENTRY;
  constructor() {}
}

export class InitializeSessionClaimsReviewsLoadingSpinnerEligibility
  implements Action
{
  readonly type =
    SessionActionTypes.INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_ELIGIBILITY;
  constructor(public payload: boolean) {}
}

export class InitializeSessionClaimsReviewsLoadingSpinnerActive
  implements Action
{
  readonly type =
    SessionActionTypes.INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_ACTIVE;
  constructor(public payload: boolean) {}
}

export class InitializeSessionClaimsReviewsLoadingSpinnerUpperMessage
  implements Action
{
  readonly type =
    SessionActionTypes.INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_UPPER_MESSAGE;
  constructor(public payload: string) {}
}

export class InitializeSessionClaimsReviewsLoadingSpinnerLowerMessage
  implements Action
{
  readonly type =
    SessionActionTypes.INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_LOWER_MESSAGE;
  constructor(public payload: string) {}
}

export class InitializeSessionClaimsReviewsLoadingSpinnerReviewerName
  implements Action
{
  readonly type =
    SessionActionTypes.INITIALIZE_SESSION_CLAIMS_REVIEWS_LOADING_SPINNER_REVIEWER_NAME;
  constructor(public payload: string) {}
}

export type SessionActions =
  | InitializeSession
  | InitializeState
  | InitializeSessionName
  | InitializeSessionFirstName
  | InitializeSessionLastName
  | InitializeSessionEmail
  | InitializeRequestedProducts
  | InitializeSessionReferrer
  | InitializeHasSentPetEmail
  | InitializeSessionProgressTrackerEligibility
  | InitializeSessionIgnoreProductDisablement
  | InitializeSessionIgnoreCurrentCarrierValidation
  | InitializeSessionIgnoreRiskFilter
  | InitializeSessionIgnoreAutoUWRules
  | InitializeSessionPCEnv
  | InitializeSessionBillingEnv
  | InitializeSessionProgressTrackerFlowName
  | UpdateSessionLogEntry
  | SendSessionLogEntry
  | ClearSessionLogEntry
  | InitializeSessionClaimsReviewsLoadingSpinnerEligibility
  | InitializeSessionClaimsReviewsLoadingSpinnerActive
  | InitializeSessionClaimsReviewsLoadingSpinnerUpperMessage
  | InitializeSessionClaimsReviewsLoadingSpinnerLowerMessage
  | InitializeSessionClaimsReviewsLoadingSpinnerReviewerName;
