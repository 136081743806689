import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { tap, take, filter, switchMap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { PlaidService } from '@core/services';

@Injectable()
export class PlaidCreateLinkTokenGuard implements CanActivate {
  constructor(private _plaidService: PlaidService) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this._plaidService.getIsGetLinkTokenCalled().pipe(
      tap(isGetLinkTokenCalled => {
        if (!isGetLinkTokenCalled) {
          this._plaidService.dispatchCreatePlaidLinkToken();
        }
      }),
      filter(isGetLinkTokenCalled => isGetLinkTokenCalled),
      take(1)
    );
  }
}
