import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { InlineHelpComponent } from './inline-help.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [InlineHelpComponent],
  exports: [InlineHelpComponent],
})
export class InlineHelpModule {}
