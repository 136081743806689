import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import {
  ProductsService,
  PrivateLabelService,
  PrivateLabelCoverageHelper,
  LoggingService,
} from '@core/services';
import { CoverageService } from '@core/services/coverage.service';
import { take, mergeMap, filter, map, catchError } from 'rxjs/operators';
import { CoverageConfiguration } from '@core/models/private-label/private-label.model';
import { Product } from '@core/models/products/product.model';

@Injectable()
export class PrivateLabelCoveragesGuard implements CanActivate {
  constructor(
    private _productsService: ProductsService,
    private _coverageService: CoverageService,
    private _privateLabelService: PrivateLabelService,
    private _privateLabelCoverageHelper: PrivateLabelCoverageHelper,
    private _loggingService: LoggingService
  ) {}

  canActivate(): Observable<boolean> {
    return this._privateLabelService.getCoverageConfiguration().pipe(
      take(1),
      mergeMap(coverageConfig => {
        if (!!coverageConfig) {
          return this.updateCoverages(coverageConfig);
        }
        return of(true);
      }),
      catchError(() => of(true))
    );
  }

  updateCoverages(
    configCoverages: CoverageConfiguration[]
  ): Observable<boolean> {
    return this._productsService.getSelectedProducts().pipe(
      take(1),
      mergeMap(products => {
        return this.forkJoinCoverageUpdateRequests(products, configCoverages);
      }),
      catchError(error => {
        this._loggingService.log(
          'privateLabelCustomCoverageUpdate error',
          error
        );
        return of(true);
      })
    );
  }

  private forkJoinCoverageUpdateRequests(
    products: Product[],
    configCoverages: CoverageConfiguration[]
  ): Observable<boolean> {
    const reqs$: Observable<boolean>[] = [];
    products.forEach(product => {
      reqs$.push(this.loadAndUpdateQuoteCoverages(product.id, configCoverages));
    });
    return forkJoin(reqs$).pipe(
      take(1),
      map(all => all.every(loaded => loaded))
    );
  }

  private loadAndUpdateQuoteCoverages(
    productId: string,
    configCoverages: CoverageConfiguration[]
  ): Observable<boolean> {
    this._coverageService.loadAllCoverages(productId);
    return this._productsService.getAllQuoteCoverageLoaded(productId).pipe(
      filter(loaded => loaded === true),
      take(1),
      mergeMap(() => {
        return this._coverageService.getCoveragesFor(productId).pipe(take(1));
      }),
      mergeMap(coverages => {
        const changes = this._privateLabelCoverageHelper.createCoverageChanges(
          coverages,
          configCoverages
        );
        if (changes && changes.length > 0) {
          this._coverageService.updateCoverageChanges(changes);
          this._coverageService.updateCoverage(productId);
          return this._productsService.getQuoteCoverageLoaded(productId).pipe(
            filter(loaded => loaded === true),
            take(1),
            map(loaded => loaded)
          );
        }
        return of(true);
      })
    );
  }
}
