import { throwError, of, Observable } from 'rxjs';
import { take, delay, retryWhen, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoggingService } from '@core/services/logging.service';
@Injectable({ providedIn: 'root' })
export class RetryOn503Operator {
  constructor(private _loggingService: LoggingService) {}

  retryOn503(delayMs: number, maxRetry?: number) {
    const DEFAULT_MAX_RETRIES = 5;
    let retries = maxRetry ? maxRetry : DEFAULT_MAX_RETRIES;
    const RETRY_STATUS_CODE = 503;
    return (src: Observable<any>) =>
      src.pipe(
        retryWhen(error => {
          return error.pipe(
            mergeMap((error: any) => {
              if (--retries <= 0) {
                this._loggingService.log('RETRY_ON_503_FAILURE', {
                  status: error.status,
                  userMessage: error.error.userMessage,
                  developerMessage: error.error.developerMessage,
                  remainingRetry: retries,
                });
              } else if (
                error.status === RETRY_STATUS_CODE ||
                (error.error.developerMessage &&
                  (error.error.developerMessage.includes(
                    'One or more entities involved in your transaction'
                  ) ||
                    error.error.developerMessage.includes('PolicyPeriod') ||
                    error.error.developerMessage.includes(
                      'Database bean version conflict'
                    ) ||
                    error.error.developerMessage.includes(
                      'PolicyCenter encountered a duplicate key error during createQuote process for quoteId'
                    ) ||
                    error.error.developerMessage.includes(
                      'Input Policy numer/Quote Id does not match'
                    )))
              ) {
                this._loggingService.log('RETRY_ON_503_FAILURE', {
                  status: error.status,
                  userMessage: error.error.userMessage,
                  developerMessage: error.error.developerMessage,
                  remainingRetry: retries,
                });
                return of(error.status).pipe(delay(delayMs));
              }
              return throwError(error);
            }),
            take(retries)
          );
        })
      );
  }
}
