import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import * as fromBillingActions from '@billing/store/actions';
import { Observable } from 'rxjs';
import { ProductPayPlanSearchResponse } from '@core/models/billing/payment-plan/payment-plan-response.model';
import { BillingSummaryDisplay } from '@core/models/billing/billing-summary-display.model';
import { ProductDisplay } from '@core/models/display/quote-coverages/products/product-display.model';
import { PersonEntity } from '@core/models/entities/person.entity';
import { PayPlanFeesDisplay } from '@core/models/display/quote-coverages/fees/payplan-fees-display.model';
import { SetupAccountExperienceResponse } from '@core/models/billing/setup-account/setup-account-experience-response.model';
import { ResetDsmDownPaymentLoaded } from '@core/store/actions';
import { BillingPaymentPlanModel } from '@core/models/billing/billing.model';
import { Product } from '@core/models/products/product.model';

@Injectable()
export class BillingDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  loadDsmDownPayment(): void {
    this._store.dispatch(new fromActions.LoadDsmDownPayment());
  }

  resetPciV2Loaded(value: boolean): void {
    this._store.dispatch(new fromActions.ResetPciV2Loaded(value));
  }

  getPciV2Loaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getBillingPciV2Loaded);
  }

  getPciV2Loading(): Observable<boolean> {
    return this._store.select(fromSelectors.getBillingPciV2Loading);
  }

  updateBillingName(name: PersonEntity) {
    this._store.dispatch(new fromActions.UpdateBillingName(name));
  }

  updateBillingAddress(address: any): void {
    this._store.dispatch(new fromActions.UpdateBillingAddress(address));
  }

  updateUnitNumber(unit: string): void {
    this._store.dispatch(new fromActions.UpdateBillingUnitNumber(unit));
  }

  getBillingInfo() {
    return this._store.select(fromSelectors.getBillingInfo);
  }

  getPaymentPlanStatus(): Observable<'NONE' | 'PENDING' | 'LOADED' | 'ERROR'> {
    return this._store.select(fromSelectors.getPaymentPlanStatus);
  }

  getPaymentPlanLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getPaymentPlanLoaded);
  }

  getPaymentPlanLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getPaymentPlanLoading);
  }

  getPaymentPlan(): Observable<ProductPayPlanSearchResponse> {
    return this._store.select(fromSelectors.getPaymentPlan);
  }

  getDownPaymentLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getDownPaymentLoaded);
  }

  getDownPaymentLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getDownPaymentLoading);
  }

  resetDsmDownPaymentLoaded(): void {
    this._store.dispatch(new ResetDsmDownPaymentLoaded(false));
  }

  // DSM
  dsmSetupAccount(): void {
    this._store.dispatch(new fromActions.DsmSetupAccount());
  }

  prepareBankCardPayment(bankCard: any) {
    this._store.dispatch(new fromActions.PrepareBankCardPayment(bankCard));
  }

  updateBillingBankCardInfo(bankCard: any) {
    this._store.dispatch(new fromActions.UpdateBillingBankCardInfo(bankCard));
  }

  updateBillingBankAccountInfo(bankAccount: any) {
    this._store.dispatch(
      new fromActions.UpdateBillingBankAccountInfo(bankAccount)
    );
  }

  getAccountSetupResponseData(): Observable<SetupAccountExperienceResponse> {
    return this._store.select(fromSelectors.getAccountSetupResponseData);
  }

  getAccountSetupResponseLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getAccountSetupResponseLoading);
  }

  getAccountSetupResponseLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getAccountSetupResponseLoaded);
  }

  getAccountSetupResponseError(): Observable<boolean> {
    return this._store.select(fromSelectors.getAccountSetupResponseError);
  }

  navigateForward(url: string) {
    this._store.dispatch(new fromBillingActions.NavigateForward(url));
  }

  navigateBack(url: string) {
    this._store.dispatch(new fromBillingActions.NavigateBack(url));
  }

  getBillingProducts(): Observable<ProductDisplay[]> {
    return this._store.select(fromSelectors.getBillingProducts);
  }

  getBillingPayPlanFees(): Observable<PayPlanFeesDisplay> {
    return this._store.select(fromSelectors.getBillingPayPlanFees);
  }

  dsmGetBillingSummaryInfo(): Observable<BillingSummaryDisplay[]> {
    return this._store.select(fromSelectors.dsmGetBillingSummaryInfo);
  }

  getAccountVerifyHitCount(): Observable<number> {
    return this._store.select(fromSelectors.getAccountVerifyHitCount);
  }

  getIsInvalidAccountNachaResponseCode(): Observable<boolean> {
    return this._store.select(
      fromSelectors.getIsInvalidAccountNachaResponseCode
    );
  }

  updateAccountVerifyStatus(request) {
    this._store.dispatch(new fromActions.UpdateAccountVerifyStatus(request));
  }

  loadDsmDownPaymentFullPayForMultiProduct(): void {
    this._store.dispatch(
      new fromActions.LoadDsmDownPaymentFullPayForMultiProduct()
    );
  }

  dsmSetupAccountFullPayForMultiProduct(): void {
    this._store.dispatch(
      new fromActions.DsmSetupAccountFullPayForMultiProduct()
    );
  }

  getAccountsSetupResponseData(): Observable<SetupAccountExperienceResponse[]> {
    return this._store.select(fromSelectors.getAccountsSetupResponseData);
  }

  initializeBillingPaymentPlan(): void {
    this._store.dispatch(new fromActions.InitializeBillingPaymentPlan());
  }

  setInitializePaymentPlanRequired(
    isInitializePaymentPlanRequired: boolean
  ): void {
    this._store.dispatch(
      new fromActions.SetInitializePaymentPlanRequired(
        isInitializePaymentPlanRequired
      )
    );
  }

  dispatchUpdateBillingPaymentPlan(payload: BillingPaymentPlanModel): void {
    this._store.dispatch(
      new fromActions.InitializeBillingPaymentPlanSuccess(payload)
    );
  }

  triggerBindRateCall(payload: boolean): void {
    this._store.dispatch(new fromActions.TriggerBindRateCall(payload));
  }

  isMultiProductFullPayApplied(): Observable<boolean> {
    return this._store.select(fromSelectors.isMultiProductFullPayApplied);
  }

  isMonolinePropertyFullPaySelected(): Observable<boolean> {
    return this._store.select(fromSelectors.isMonolinePropertyFullPaySelected);
  }

  setRebindWhenInvalidOnBillingChange(rebindWhenInvalid: boolean): void {
    this._store.dispatch(
      new fromActions.setRebindWhenInvalidOnBillingChange(rebindWhenInvalid)
    );
  }

  setTriggerEditBillingPopup(triggerEditBillingPopup: boolean): void {
    this._store.dispatch(
      new fromActions.SetTriggerEditBillingPopup(triggerEditBillingPopup)
    );
  }

  getTriggerEditBillingPopup(): Observable<boolean> {
    return this._store.select(fromSelectors.getTriggerEditBillingPopup);
  }

  isRemoveEftOptionRequired(): Observable<boolean> {
    return this._store.select(fromSelectors.isRemoveEftOptionRequired);
  }

  getProductsWithPolicyNumber(): Observable<Product[]> {
    return this._store.select(fromSelectors.getProductsWithPolicyNumber);
  }

  _75PercentDownPayment(): Observable<boolean> {
    return this._store.select(fromSelectors._75PercentDownPayment);
  }
}
