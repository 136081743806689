import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AgencyService, EuaHelper } from '@core/services';
import { TokenService } from '@core/services/token.service';
import { Observable } from 'rxjs';
import { ErrorSettableComponent } from '@shared/components/error-settable-component';

@Injectable()
export class AgentAuthenticationGuard implements CanActivate {
  constructor(
    private _agencyService: AgencyService,
    private _euaHelper: EuaHelper,
    private _tokenService: TokenService,
    private _injector: Injector
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    if (sessionStorage.jwt) {
      // They have been authenticated
      const jwtContents = JSON.parse(sessionStorage.jwt);
      const accessToken = sessionStorage.access_token;
      this._agencyService.updateAgentJwt(jwtContents, accessToken);
      this._tokenService.insertAccessToken(accessToken);

      return true;
    } else if (route.queryParams.error) {
      // Authentication failed

      const component: ErrorSettableComponent = <ErrorSettableComponent>(
        this._injector.get(route.component)
      );

      component.setErrorFromGuard(route.queryParams.error);
      return true;
    } else {
      // Needs authenticated
      const requestedRoute = route.pathFromRoot.reduce((prev, current) => {
        if (current.url.length > 0) {
          return prev + '/' + current.url[0];
        } else {
          return prev;
        }
      }, '');
      const params = route.queryParams;

      const euaUrl = this._euaHelper.buildEUAUrlForAgencyRoute(
        params,
        requestedRoute
      );

      window.location.href = euaUrl;
    }
  }

  _parseHashParams(rawParams: string): any {
    const res = {};

    rawParams.split('&').forEach(keyValuePair => {
      const key = keyValuePair.substr(0, keyValuePair.indexOf('='));
      const value = keyValuePair.substr(keyValuePair.indexOf('=') + 1);
      res[key] = value;
    });

    return res;
  }
}
