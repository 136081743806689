import { Injectable } from '@angular/core';

import { LendersDao } from '@core/dao/lenders.dao';
import { Observable } from 'rxjs';
import { LendersAndProduct } from '@core/models/lenders/lender.model';

@Injectable()
export class LendersService {
  constructor(private _lendersDao: LendersDao) {}

  addDsmLender(value: any) {
    this._lendersDao.addDsmLender(value);
  }

  updateDsmMortgageLender(value: any) {
    this._lendersDao.updateDsmMortgageLender(value);
  }

  getAllLenders(): Observable<LendersAndProduct[]> {
    return this._lendersDao.getAllLenders();
  }

  isPayingThroughEscrow(): Observable<boolean> {
    return this._lendersDao.isPayingThroughEscrow();
  }

  deleteMortgage(id: string) {
    this._lendersDao.deleteMortgage(id);
  }

  hasNonRentalPropertyMortgage(): Observable<boolean> {
    return this._lendersDao.hasNonRentalPropertyMortgage();
  }

  isPayingThroughSingleOrNoEscrow(): Observable<boolean> {
    return this._lendersDao.isPayingThroughSingleOrNoEscrow();
  }
}
