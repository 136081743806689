import * as fromDigitalIdCards from '@core/store/actions/digital-id-cards-text.action';

import { DigitalIdCardsTextResponse } from '@core/models/digital-id-cards/digital-id-cards-text.model';

export interface DigitalIdCardsTextState {
  response: DigitalIdCardsTextResponse;
  loaded: boolean;
  loading: boolean;
}

export const initialState: DigitalIdCardsTextState = {
  response: null,
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromDigitalIdCards.DigitalIdCardsActions
): DigitalIdCardsTextState {
  switch (action.type) {
    case fromDigitalIdCards.DigitalIdCardsTextActionTypes.SEND_AUTO_ID_CARDS: {
      return { ...state, loading: true };
    }

    case fromDigitalIdCards.DigitalIdCardsTextActionTypes
      .SEND_AUTO_ID_CARDS_FAIL: {
      return { ...state, loading: false, loaded: true };
    }

    case fromDigitalIdCards.DigitalIdCardsTextActionTypes
      .SEND_AUTO_ID_CARDS_SUCCESS: {
      return {
        ...state,
        response: action.payload,
        loaded: true,
        loading: false,
      };
    }
    default:
      return state;
  }
}
