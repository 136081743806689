<mq-progress-tracker
  *ngIf="isProgressTrackerEligible"
  [progressTrackerPageName]="progressTrackerPageName"
  [progressTrackerFlowName]="progressTrackerFlowName"
></mq-progress-tracker>
<div class="nw-container nw-inner-bun" [formGroup]="form">
  <mq-field-validation-message
    [form]="form"
    [complainAboutRequiredFields]="formSubmitted$ | async"
  ></mq-field-validation-message>

  <div class="row col-12">
    <h1 class="heading-h4 page-sub-title">Property Information</h1>
  </div>

  <ng-container *ngIf="isHome">
    <mq-ho-property-info-form
      [form]="homeownersForm"
      [homeowners]="homeowners$ | async"
      [coveredLocation]="coveredLocation$ | async"
      [stateSpecificFlags]="stateSpecificFlags$ | async"
      [policyEffectiveDates]="policyEffectiveDates$ | async"
      [partialQuoteCoveredLocation]="partialQuoteCoveredLocation$ | async"
    >
    </mq-ho-property-info-form>
  </ng-container>

  <ng-container *ngIf="isCondo">
    <mq-condo-property-info-form
      [form]="condoForm"
      [condo]="condo$ | async"
      [coveredLocation]="coveredLocation$ | async"
      [stateSpecificFlags]="stateSpecificFlags$ | async"
      [options]="options$ | async"
      [policyEffectiveDates]="policyEffectiveDates$ | async"
      [partialQuoteId]="partialQuoteId$ | async"
      [partialQuoteCoveredLocation]="partialQuoteCoveredLocation$ | async"
    >
    </mq-condo-property-info-form>
  </ng-container>

  <ng-container *ngIf="isRenters">
    <mq-renters-property-info-form
      [renters]="renters$ | async"
      [form]="rentersForm"
      [_pickList]="options$ | async"
      [stateSpecificFlags]="stateSpecificFlags$ | async"
    >
    </mq-renters-property-info-form>
  </ng-container>

  <ng-container>
    <mq-protective-devices-form
      [form]="protectiveDevicesForm"
      [protectiveDevices]="protectiveDevices$ | async"
      [stateSpecificFlags]="stateSpecificFlags$ | async"
      [options]="protectiveDeviceOptions$ | async"
    >
    </mq-protective-devices-form>
  </ng-container>

  <div *ngIf="homeownersProduct$ | async">
    <label for="yearsWithPriorCarrier_{{ fieldId }}" style="font-size: large"
      >How many years have you been continuously insured with your current home
      insurance carrier?</label
    >
    <div class="input-group">
      <select
        id="yearsWithPriorCarrier_{{ fieldId }}"
        class="form-control form-select col-sm-4 col-lg-4"
        [attr.aria-describedby]="
          yearsWithPriorCarrier.invalid &&
          (yearsWithPriorCarrier.dirty || yearsWithPriorCarrier.touched)
            ? 'vehicle-type-error-' + fieldId
            : null
        "
        formControlName="yearsWithPriorCarrier"
        autofocus
      >
        <option [selected]="true" value="" disabled="disabled">
          Please Select
        </option>
        <ng-container>
          <option
            *ngFor="let years of yearsWithPriorCarrierOptions"
            value="{{ years[0] }}"
          >
            {{ years }}
          </option>
        </ng-container>
      </select>
        <div class="input-group-text"></div>
    </div>
  </div>
  <br />

  <ng-container *ngIf="isHome">
    <mq-smarthome-price-points
      (smartHomeExperience)="setSmartHomeExperience($event)"
      [form]="smarthomeForm"
      [smartHomeDiscount]="smartHomeDiscountForHome$ | async"
      [isHome]="isHome"
    >
    </mq-smarthome-price-points>
  </ng-container>
  <ng-container *ngIf="isCondo">
    <mq-smarthome-price-points
      (smartHomeExperience)="setSmartHomeExperience($event)"
      [form]="smarthomeForm"
      [smartHomeDiscount]="smartHomeDiscountForCondo$ | async"
      [isCondo]="isCondo"
    >
    </mq-smarthome-price-points>
  </ng-container>
</div>

<nw-navigation-buttons
  (forward)="onSubmit()"
  (back)="onBack()"
  [isAppLoading]="isAppLoading$ | async"
  [submitButtonText]="'Continue'"
></nw-navigation-buttons>
