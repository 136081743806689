import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { EditCoveragesConsentComponent } from './edit-coverages-consent.component';
@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  declarations: [EditCoveragesConsentComponent],
  exports: [EditCoveragesConsentComponent],
})
export class EditCoveragesConsentModule {}
