import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InlineHelpPopupComponent } from './inline-help-popup.component';
import { CommonModule } from '@angular/common';
@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [InlineHelpPopupComponent],
  exports: [InlineHelpPopupComponent],
})
export class InlineHelpPopupModule {}
