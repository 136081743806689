import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import {
  TelematicsVehiclePremium,
  TelematicsVehicle,
} from '@core/models/telematics/telematics.model';

@Component({
  selector: 'mq-prequalified-enrollment',
  templateUrl: './prequalified-enrollment.component.html',
  styleUrls: ['../../../../../../assets/scss/telematics-panel.scss'],
})
export class PrequalifiedEnrollmentComponent {
  @Input() vehicles: VehicleEntity[];
  @Input() vehiclePremiums: TelematicsVehiclePremium[];
  @Input() enrollmentVehicles: TelematicsVehicle[];

  @Output() prequalifiedEnrollmentChanged = new EventEmitter<VehicleEntity>();

  headerIcon =
    '<img class="tm-icon" src="assets/images/connected-car-icon.svg" />';

  changeEnrollment(vehicle: VehicleEntity) {
    this.prequalifiedEnrollmentChanged.emit(vehicle);
  }
}
