import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DisplayPanelService } from './display-panel.service';

import { DisplayPanelComponent } from './display-panel/display-panel.component';
import { DisplayItemComponent } from './display-item/display-item.component';
import { DisplayItemListComponent } from './display-item-list/display-item-list.component';
import { DisplayPanelActionButtonsComponent } from './display-panel-action-buttons/display-panel-action-buttons.component';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';

@NgModule({
  imports: [CommonModule, NgbModalModule],
  declarations: [
    DisplayItemListComponent,
    DisplayItemComponent,
    DisplayPanelComponent,
    DisplayPanelActionButtonsComponent,
    ConfirmationModalComponent,
  ],
  exports: [
    DisplayItemListComponent,
    DisplayItemComponent,
    DisplayPanelComponent,
    DisplayPanelActionButtonsComponent,
  ],
  providers: [DisplayPanelService],
})
export class DisplayPanelModule {}
