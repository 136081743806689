import { PlaidExchangeTokenResponse } from '@core/models/billing/plaid/plaid.model';
import * as fromFeature from '@core/store/reducers';
import * as fromSession from '@core/store/selectors/session.selector';
import { createSelector } from '@ngrx/store';

export const getPlaidState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.plaid
);

export const getPlaidLinkToken = createSelector(getPlaidState, state => {
  const linkToken = state.linkToken;
  return state.linkToken;
});

export const getIsGetLinkTokenCalled = createSelector(
  getPlaidState,
  state => state.isGetLinkTokenCalled
);

export const buildPlaidCreateLinkTokenRequest = createSelector(
  fromSession.getSessionId,
  sessionId => {
    return { client_unique_id: sessionId };
  }
);

export const getPlaidAccountInfo = createSelector(getPlaidState, state => {
  return {
    balance: state.balance,
    accountNumber: state.accountNumber,
    routingNumber: state.routingNumber,
    accountType: state.accountType,
    bankName: state.bankName,
  } as PlaidExchangeTokenResponse;
});

export const getPlaidRetrieveCount = createSelector(getPlaidState, state => {
  return state.plaidRetrieveCount;
});

export const getPlaidExchangeTokenFail = createSelector(
  getPlaidState,
  state => {
    return state.isMostRecentPlaidExchangeTokenFail;
  }
);
