import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromDrivers from '@core/store/actions/driver.action';
import * as fromPolicyholders from '@core/store/actions/policyholders.action';
import * as fromModifiers from '@core/store/actions/modifiers.action';

import { DriverEntity } from '@core/models/entities/driver.entity';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';

export interface DriverState extends EntityState<DriverEntity> {
  loading: number; // Count of operations in flight; can be more than one.
}

export const adapter: EntityAdapter<DriverEntity> =
  createEntityAdapter<DriverEntity>({
    selectId: driver => driver.driverId,
  });

function copyDriversWithPreviousTrainingCourse(state, action) {
  const newDrivers = [];
  for (const driver of action.payload) {
    if (driver.changes.person) {
      const existing = state.entities[driver.changes.driverId];
      if (existing && existing.person) {
        newDrivers.push({
          ...driver,
          changes: {
            ...driver.changes,
            person: {
              ...driver.changes.person,
              driverTraining: existing.person.driverTraining,
              trainingCourseCompletionDate:
                existing.person.trainingCourseCompletionDate,
              goodStudent: existing.person.goodStudent,
            },
          },
        });
      }
    }
  }
  return newDrivers;
}

export function reducer(
  state = {
    ...adapter.getInitialState(),
    loading: 0,
  },
  action:
    | fromDrivers.DriverActions
    | fromPolicyholders.PolicyholderActions /* | fromModifiers.ModifierActionTypes */
): DriverState {
  switch (action.type) {
    case fromDrivers.DriverActionTypes.ADD_DRIVER: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromDrivers.DriverActionTypes.ADD_DRIVER_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        loading: state.loading - 1,
      });
    }

    case fromDrivers.DriverActionTypes.ADD_DRIVER_DIRECTLY: {
      return adapter.addOne(action.payload, state);
    }

    case fromDrivers.DriverActionTypes.ADD_DRIVER_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromDrivers.DriverActionTypes.UPDATE_DRIVER: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromDrivers.DriverActionTypes.UPDATE_DRIVER_SUCCESS: {
      return adapter.updateOne(action.payload, {
        ...state,
        loading: state.loading - 1,
      });
    }

    case fromDrivers.DriverActionTypes.UPDATE_DRIVER_DIRECTLY: {
      return adapter.updateOne(action.payload, state);
    }

    case fromDrivers.DriverActionTypes.UPDATE_DRIVER_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromDrivers.DriverActionTypes.UPDATE_ALL_DRIVERS: {
      const newDrivers = copyDriversWithPreviousTrainingCourse(state, action);
      return adapter.updateMany(newDrivers, {
        ...state,
      });
    }

    case fromDrivers.DriverActionTypes.DELETE_DRIVER: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromDrivers.DriverActionTypes.DELETE_DRIVER_SUCCESS: {
      return adapter.removeOne(action.payload, {
        ...state,
        loading: state.loading - 1,
      });
    }

    case fromDrivers.DriverActionTypes.DELETE_DRIVER_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    // TODO Are incidents not being persisted?
    case fromDrivers.DriverActionTypes.DELETE_DRIVERINCIDENT: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromDrivers.DriverActionTypes.DELETE_DRIVERINCIDENT_SUCCESS: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromDrivers.DriverActionTypes.DELETE_DRIVERINCIDENT_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromDrivers.DriverActionTypes.UPDATE_GOOD_STUDENT_FOR_DRIVER: {
      return adapter.updateOne(
        {
          id: action.driverId,
          changes: {
            goodStudent: action.selected,
          },
        },
        state
      );
    }

    case fromPolicyholders.PolicyholderActionTypes.ADD_POLICYHOLDER_SUCCESS: {
      return adapter.updateOne(
        {
          changes: { policyHolderId: action.payload.policyHolderId },
          id: action.payload.driverId,
        },
        state
      );
    }

    case <any>(
      fromModifiers.ModifierActionTypes.UPDATE_DRIVER_DISCOUNT_SUCCESS
    ): {
      const payload = <EligibleDiscountEntity>(<any>action).payload;
      const newDiscounts = [];
      const driver = state.entities[payload.modelId];
      if (!driver) {
        return state;
      }
      for (const oldDiscount of driver.eligibleDiscounts) {
        if (oldDiscount.eligibleDiscountId === payload.eligibleDiscountId) {
          newDiscounts.push(payload);
        } else {
          newDiscounts.push(oldDiscount);
        }
      }
      return adapter.updateOne(
        {
          id: payload.modelId,
          changes: {
            eligibleDiscounts: newDiscounts,
          },
        },
        state
      );
    }

    default: {
      return state;
    }
  }
}
