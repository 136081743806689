import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  map,
  catchError,
  withLatestFrom,
  filter,
  exhaustMap,
  take,
  switchMap,
} from 'rxjs/operators';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { PageAlertService } from '@core/services';
import { LoadAllPageAlerts, LoadActiveAlert } from '@core/store/actions';
import { Store } from '@ngrx/store';
import { PagesSupportingMultipleAlerts } from '@shared/constants/app-constants';

@Injectable()
export class PageAlertEffect {
  constructor(
    private _store: Store<fromStore.AppState>,
    private _actions$: Actions,
    private _pageAlertService: PageAlertService
  ) {}

  @Effect()
  loadPageAlerts$ = this._actions$.pipe(
    ofType<LoadAllPageAlerts>(fromActions.LOAD_ALL_PAGE_ALERTS),
    withLatestFrom(this._pageAlertService.getPageAlertLoaded()),
    filter(([_, loaded]) => !loaded),
    exhaustMap(() =>
      this._pageAlertService.establishPageAlerts().pipe(
        map(pageAlerts => {
          const isUnderMaintenance = pageAlerts.some(
            alert => alert.underMaintenance
          );
          if (isUnderMaintenance) {
            return new fromActions.Maintenance(
              'Site is down due to PG process'
            );
          }
          return new fromActions.LoadAllPageAlertSuccess(pageAlerts);
        }),
        catchError(error =>
          of(new fromActions.LoadAllPageAlertFail(error ? error.message : ''))
        )
      )
    )
  );

  @Effect()
  loadActivePageAlert$ = this._actions$.pipe(
    ofType<LoadActiveAlert>(fromActions.LOAD_ACTIVE_ALERT),
    map((action: fromActions.LoadActiveAlert) => action.payload),
    switchMap(pageId => {
      if (PagesSupportingMultipleAlerts.includes(pageId)) {
        return this._store.select(fromSelectors.getAlertsByPageId(pageId)).pipe(
          take(1),
          map(alerts =>
            alerts && alerts.length
              ? new fromActions.LoadActiveAlertsSuccess(alerts)
              : new fromActions.ClearActiveAlert()
          )
        );
      } else {
        return this._store.select(fromSelectors.getAlertByPageId(pageId)).pipe(
          take(1),
          map(alert =>
            alert
              ? new fromActions.LoadActiveAlertSuccess(alert)
              : new fromActions.ClearActiveAlert()
          )
        );
      }
    })
  );

  @Effect()
  reloadActiveAlertWhenContentArrives$ = this._actions$.pipe(
    ofType(fromActions.LOAD_ALL_PAGE_ALERTS_SUCCESS),
    withLatestFrom(this._store.select(fromSelectors.getCurrentPageId)),
    map(([_, pageId]) => new fromActions.LoadActiveAlert(pageId))
  );
}
