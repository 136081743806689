import { createSelector } from '@ngrx/store';
import { getAppState, AppState } from '@core/store/reducers';
import { CoveredLocationState } from '@core/store/reducers/covered-location.reducer';
import { selectAllPolicyholders } from '@core/store/selectors/policyholder.selector';

export const getCoveredLocationState = createSelector(
  getAppState,
  (state: AppState) => state.coveredLocation
);

export const getCoveredLocationData = createSelector(
  getCoveredLocationState,
  (state: CoveredLocationState) => state.data
);

export const getCoveredLocationLoaded = createSelector(
  getCoveredLocationState,
  (state: CoveredLocationState) => state.loaded
);

export const getCoveredLocationUpdating = createSelector(
  getCoveredLocationState,
  state => state.updating > 0
);

export const getCoveredLocationUpdateStatus = createSelector(
  getCoveredLocationState,
  state => state.updateStatus
);

export const getCoveredLocationReconstructionCost = createSelector(
  getCoveredLocationData,
  data => data.reconstructionCost
);

export const getRequestIfNumberOfOccupantsChanged = createSelector(
  getCoveredLocationData,
  selectAllPolicyholders,
  (coveredLocation, policyholders) => {
    if (!coveredLocation) {
      return null;
    }
    /*
     * I don't think that numberOfOccupants always need to equal number of policyHolders.
     * Number of occupants probably should be a separate input field altogether.
     */
    if (coveredLocation.numberOfOccupants >= policyholders.length) {
      /**
       *  Sort of an undefined scenario. There is the potential that you can add many policyholders,
       *  remove them, and still have a higher numberOfOccupants. Stated another way, we will never
       *  *decrease* the number of occupants
       */
      return null;
    }

    // squareFootage can be empty if we are coming in from comp rater. Then call will blow up. Return nothing here.
    if (
      coveredLocation.constructionInfo &&
      !coveredLocation.constructionInfo.squareFootage
    ) {
      return null;
    }

    return {
      ...coveredLocation,
      numberOfOccupants: policyholders.length,
    };
  }
);
