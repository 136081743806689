import { Action } from '@ngrx/store';
import { RecoverableErrorEntity } from '@core/models/recoverable-error/recoverable-error.entity';

export const ADD_RECOVERABLE_ERROR = '[Error] Add Recoverable Error';
export const REMOVE_RECOVERABLE_ERROR = '[Error] Clear Recoverable Error';
export const CLEAR_RECOVERABLE_ERRORS = '[Error] Clear Recoverable Errors';

export class AddRecoverableError implements Action {
  readonly type = ADD_RECOVERABLE_ERROR;
  constructor(public error: RecoverableErrorEntity) {}
}

export class RemoveRecoverableError implements Action {
  readonly type = REMOVE_RECOVERABLE_ERROR;
  constructor(public id: number) {}
}

export class ClearRecoverableErrors implements Action {
  readonly type = CLEAR_RECOVERABLE_ERRORS;
  constructor() {}
}

export type RecoverableErrorAction =
  | AddRecoverableError
  | RemoveRecoverableError
  | ClearRecoverableErrors;
