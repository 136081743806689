import { RetrieveEntity } from '@core/models/entities/retrieve.entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromPolicyholder from '@core/store/selectors/policyholder.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import * as fromAgency from '@core/store/selectors/agency.selector';
import * as fromPolicy from '@core/store/selectors/policy.selector';
import * as fromDsmProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromCoverages from '@core/store/selectors/coverage.selector';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import * as fromDriver from '@core/store/selectors/driver.selector';
import * as fromPowersportsDrivers from '@core/store/selectors/powersports-driver.selector';
import * as fromModifier from '@core/store/selectors/modifier.selector';
import * as fromVehicle from '@core/store/selectors/vehicle.selector';
import * as fromUmbrella from '@core/store/selectors/umbrella.selector';
import * as fromTelematics from '@core/store/selectors/telematics-enrollments.selector';
import * as fromAccount from '@core/store/selectors/account.selector';
import * as fromRetrieve from '@core/store/selectors/retrieve.selector';
import * as fromMetadata from '@core/store/selectors/metadata.selector';
import * as fromSession from '@core/store/selectors/session.selector';
import * as fromHomeowners from '@core/store/selectors/homeowners.selector';
import * as fromMailingAddress from '@core/store/selectors/mailing-address.selector';
import * as fromLenders from '@core/store/selectors/lenders.selector';
import {
  UpdateQuoteRequest,
  QuoteRequest,
  UpdateQuoteRequestBody,
  TermLifeQuoteRequest,
  TermLifeEligible,
} from '@core/models/auto/quotes/update-quote-request.model';
import { ProducerRequest } from '@core/models/request/producer-request.model';
import { InitiateNewBusinessRequest } from '@core/models/auto/quotes/initiate-new-business-request.model';
import { PolicyholderBuilder } from '@shared/utils/builders/policyholder.builder';
import { DateUtils } from '@shared/utils/date.utils';
import {
  DEFAULT_ID,
  DriverRelationToPNI,
  MortgageeTypes,
  PolicyholderTypes,
  ProductTypes,
  UIMPDPackageDeductible,
} from '@shared/constants/app-constants';
import { ProducerUtils } from '@shared/utils/producer.utils';
import { InitiateNewBusinessUmbrellaRequest } from '@core/models/umbrella/initiate-new-business-umbrella.request';
import { PrequalificationAnswersUtil } from '@shared/utils/prequalification-answers.util';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import { StringUtils } from '@shared/utils/string.utils';

export const buildInitiateNewBusinessRequest = (
  productId: string,
  yearsWithPriorCarrier?: string
): MemoizedSelector<unknown, InitiateNewBusinessRequest> =>
  createSelector(
    fromPolicyholder.getPrimaryNamedInsured,
    policyAndMailingAddresses,
    fromAgency.getAgencyState,
    fromPolicy.getPolicyEffectiveDates,
    fromUserContext.getUserContext,
    fromAccount.getAccountId,
    fromRetrieve.getRetrieveSuccess,
    fromRetrieve.getAllRetrievedQuotes,
    (
      policyholder,
      policyAndMailingaddresses,
      agencyState,
      effectiveDate,
      userContext,
      accountId,
      isQuoteRetrievedSuccess,
      retrievedQuotes
    ) => {
      if (!policyholder) {
        return null;
      }

      if (!accountId && isQuoteRetrievedSuccess && retrievedQuotes.length) {
        const rectrivedQuote: RetrieveEntity = retrievedQuotes.find(
          quote => quote && quote.response && quote.response.accountId
        );
        accountId = rectrivedQuote ? rectrivedQuote.response.accountId : null;
      }

      const policyAddress =
        policyAndMailingaddresses.policyAddress[policyholder.addressId];
      if (!policyAddress) {
        return null;
      }

      const producerRequest: ProducerRequest = {
        producerCode: ProducerUtils.getQuoteProducerCode(
          agencyState.agency,
          policyAddress.state
        ),
        type: ProducerUtils.getProducerType(agencyState.agency),
      };

      const policyholderRequest =
        PolicyholderBuilder.buildPolicyholderRequestFromEntity(
          policyholder,
          policyAndMailingaddresses.mailingAddress.length > 0
            ? policyAndMailingaddresses.mailingAddress[0]
            : policyAddress
        );

      const request: InitiateNewBusinessRequest = {
        policyHolders: accountId ? [] : [policyholderRequest],
        policyAddress: policyAddress,
        producer: producerRequest,
        yearsWithPriorCarrier: '0',
        effectiveDate: DateUtils.formatDateToDSM(
          DateUtils.todayOrLaterMMDDYYYY(effectiveDate.autoEffectiveDate)
        ),
        hasDrivingDataConsent: true,
        creditConsent: true,
        productId,
        accountId,
      };
      if (policyholderRequest.prepopulatedInsuredId) {
        request.prepopulatedInsuredId =
          policyholderRequest.prepopulatedInsuredId;
      }
      if (policyholderRequest.partialQuoteId) {
        request.partialQuoteId = policyholderRequest.partialQuoteId;
      }

      if (agencyState.accessToken) {
        request.accessToken = agencyState.accessToken;
      }

      return {
        ...request,
        userContext,
        agency: agencyState.agency,
      };
    }
  );

export const policyAndMailingAddresses = createSelector(
  fromPolicyAddress.selectPolicyAddressEntities,
  fromMailingAddress.selectAllMailingAddresses,
  (policyAddress, mailingAddress) => ({ policyAddress, mailingAddress })
);
export const buildInitiateNewBusinessHomeownersRequest = (
  productId: string,
  yearsWithPriorCarrier: string
) =>
  createSelector(
    buildInitiateNewBusinessRequest(productId, yearsWithPriorCarrier),
    fromDriver.getExistingAddablePeopleAbsentFromProduct(productId),
    fromAgency.isAgent,
    fromHomeowners.getOfferingType,
    fromMetadata.getStateSpecificFlag('offeringTypeApplicable'),
    fromMailingAddress.selectAllMailingAddresses,
    (
      request,
      existingPolicyholders,
      isAgent,
      offeringType,
      offeringTypeApplicable,
      mailingAddress
    ) => {
      request.policyHolders.push(
        ...existingPolicyholders.map(p =>
          PolicyholderBuilder.buildPolicyholderRequestFromEntity(
            p,
            mailingAddress[0]
          )
        )
      );
      request.prequalificationAnswers =
        PrequalificationAnswersUtil.getPrequalificationAnswers(
          productId,
          request.policyAddress.state
        );
      if (isAgent && offeringType && offeringTypeApplicable) {
        request = { ...request, offeringType: offeringType };
      }
      if (mailingAddress.length > 0 && request.policyHolders.length > 0) {
        request.policyHolders[0].address = mailingAddress[0];
      } else if (request.policyHolders.length > 0) {
        request.policyHolders[0].address = request.policyAddress;
      }
      return request;
    }
  );

// TODO add state specific logic
export const buildInitiateNewBusinessCondoRequest = (
  productId: string,
  yearsWithPriorCarrier: string
) =>
  createSelector(
    buildInitiateNewBusinessRequest(productId, yearsWithPriorCarrier),
    fromDriver.getExistingAddablePeopleAbsentFromProduct(productId),
    fromMailingAddress.selectAllMailingAddresses,
    (request, existingPolicyholders, mailingAddress) => {
      request.policyHolders.push(
        ...existingPolicyholders.map(p =>
          PolicyholderBuilder.buildPolicyholderRequestFromEntity(
            p,
            mailingAddress[0]
          )
        )
      );

      request.prequalificationAnswers =
        PrequalificationAnswersUtil.getPrequalificationAnswers(
          productId,
          request.policyAddress.state
        );
      if (mailingAddress.length > 0 && request.policyHolders.length > 0) {
        request.policyHolders[0].address = mailingAddress[0];
      } else if (request.policyHolders.length > 0) {
        request.policyHolders[0].address = request.policyAddress;
      }
      return request;
    }
  );

export const buildInitiateNewBusinessRentersRequest = (
  productId: string,
  yearsWithPriorCarrier: string
) =>
  createSelector(
    buildInitiateNewBusinessRequest(productId, yearsWithPriorCarrier),
    fromDriver.getExistingAddablePeopleAbsentFromProduct(productId),
    fromMailingAddress.selectAllMailingAddresses,
    (request, existingPolicyholders, mailingAddress) => {
      request.policyHolders.push(
        ...existingPolicyholders.map(p =>
          PolicyholderBuilder.buildPolicyholderRequestFromEntity(
            p,
            mailingAddress[0]
          )
        )
      );

      request.prequalificationAnswers =
        PrequalificationAnswersUtil.getPrequalificationAnswers(
          productId,
          request.policyAddress.state
        );
      if (mailingAddress.length > 0 && request.policyHolders.length > 0) {
        request.policyHolders[0].address = mailingAddress[0];
      } else if (request.policyHolders.length > 0) {
        request.policyHolders[0].address = request.policyAddress;
      }
      return request;
    }
  );

export const buildInitiateNewBusinessUmbrellaRequest = (productId: string) =>
  createSelector(buildInitiateNewBusinessRequest(productId), request => {
    request.prequalificationAnswers =
      PrequalificationAnswersUtil.getPrequalificationAnswers(
        productId,
        null // Can be null because there is only one prequal question for Umbrella
      );
    return request;
  });

export const buildUpdateQuoteRequest = (productId: string) =>
  createSelector(
    fromDsmProducts.getProduct(productId),
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    fromHomeowners.getOfferingType,
    fromAgency.isAgent,
    fromMetadata.getStateSpecificFlag('offeringTypeApplicable'),
    fromLenders.getLendersState,
    fromLenders.isPayingThroughEscrow,
    (
      product,
      policyAddress,
      offeringType,
      isAgent,
      offeringTypeApplicable,
      lenders,
      isPayingThroughEscrow
    ) => {
      const { addressIds, ...barePolicyAddress } = policyAddress;
      let request: UpdateQuoteRequest = {
        quoteId: product.quoteId,
        productId: productId,
        body: {
          offeringType: offeringType,
          effectiveDate: product.effectiveDate,
          policyAddress: {
            ...barePolicyAddress,
            addressId:
              policyAddress.addressId || policyAddress.addressIds[productId],
          },
          isAssignedRiskPlan: product.isAssignedRiskPlan,
          yearsWithPriorCarrier: product.yearsWithPriorCarrier,
        },
      };
      if (
        isAgent &&
        product.id === ProductTypes.HOMEOWNERS &&
        offeringTypeApplicable &&
        offeringType
      ) {
        const newBody = { ...request.body, offeringType: offeringType };
        request = { ...request, body: newBody };
      } else {
        const newBody = { ...request.body, offeringType: null };
        request = { ...request, body: newBody };
      }
      if (
        (product.id === ProductTypes.HOMEOWNERS ||
          product.id === ProductTypes.CONDO) &&
        JSON.stringify(lenders.entities).length > 2 &&
        isPayingThroughEscrow
      ) {
        const mortgageNewBody = {
          ...request.body,
          currentBillTo: MortgageeTypes.MORTGAGEE,
          renewalBillTo: MortgageeTypes.MORTGAGEE,
          renewalDocsTo: PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED,
        };
        request = { ...request, body: mortgageNewBody };
      }
      return request;
    }
  );

export const buildPatchQuoteRequest = (
  productId: string,
  body: UpdateQuoteRequestBody
) =>
  createSelector(
    fromDsmProducts.getProduct(productId),
    product =>
      ({
        quoteId: product.quoteId,
        productId: productId,
        body,
      } as UpdateQuoteRequest)
  );

export const buildRetrieveCoveragesRequest = (productId: string) =>
  createSelector(
    fromDsmProducts.getProduct(productId),
    fromTokens.getAccessToken(productId),
    (product, accessToken) => {
      return {
        accessToken,
        quoteId: product.quoteId,
        sessionId: product.sessionId,
        productId: product.id,
      };
    }
  );

export const buildUpdateCoverageRequest = (
  productId: string,
  coverageChanges: CoverageChange[]
) =>
  createSelector(
    fromDsmProducts.getProduct(productId),
    fromTokens.getAccessToken(productId),
    fromCoverages.getCoverageChangesFor(productId),
    fromMetadata.getStateSpecificFlag(
      'isBiologicalDeteriorationCoverageApplicable'
    ),
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    fromMetadata.getStateSpecificFlag('isUIMPDCoverageApplicable'),
    (
      product,
      accessToken,
      coverageChangeUpdates,
      isBiologicalDeteriorationCoverageApplicable,
      policyAddress,
      isUIMPDCoverageApplicable
    ) => {
      if (isUIMPDCoverageApplicable) {
        const inclDeductibleCoverages =
          coverageChanges && coverageChanges.length
            ? coverageChanges.map(coverageChange => {
                if (coverageChange.coverageId === 'UIMPD') {
                  return {
                    selectedValue: coverageChange.selectedValue.concat(
                      UIMPDPackageDeductible
                    ),
                    coverageId: coverageChange.coverageId,
                    coverageLevel: coverageChange.coverageLevel,
                    coverableId: coverageChange.coverableId,
                  };
                }
                return coverageChange;
              })
            : coverageChangeUpdates.map(coverageChange => {
                if (coverageChange.coverageId === 'UIMPD') {
                  return {
                    selectedValue: coverageChange.selectedValue.concat(
                      UIMPDPackageDeductible
                    ),
                    coverageId: coverageChange.coverageId,
                    coverageLevel: coverageChange.coverageLevel,
                    coverableId: coverageChange.coverableId,
                  };
                }
                return coverageChange;
              });
        return {
          request: {
            coverageChanges: inclDeductibleCoverages,
            accessToken,
            quoteId: product.quoteId,
            sessionId: product.sessionId,
            productId: product.id,
          },
        };
      } else {
        const updatedCoverageChanges =
          coverageChanges && coverageChanges.length
            ? coverageChanges.map(coverageChange => {
                return {
                  selectedValue: coverageChange.selectedValue,
                  coverageId: coverageChange.coverageId,
                  coverageLevel: coverageChange.coverageLevel,
                  coverableId: coverageChange.coverableId,
                };
              })
            : coverageChangeUpdates.map(coverageChange => {
                return {
                  selectedValue: coverageChange.selectedValue,
                  coverageId: coverageChange.coverageId,
                  coverageLevel: coverageChange.coverageLevel,
                  coverableId: coverageChange.coverableId,
                };
              });
        return {
          request: {
            coverageChanges: updatedCoverageChanges,
            accessToken,
            quoteId: product.quoteId,
            sessionId: product.sessionId,
            productId: product.id,
          },
          isBiologicalDeteriorationCoverageApplicable,
          policyAddress,
        };
      }
    }
  );

export const buildRateQuoteRequest = (productId: string, ratingType: string) =>
  createSelector(
    fromDsmProducts.getProduct(productId),
    fromTokens.getAccessToken(productId),
    (product, accessToken) => {
      return {
        sessionId: product.sessionId,
        quoteId: product.quoteId,
        accessToken,
        productId: product.id,
        ratingType,
      } as QuoteRequest;
    }
  );

export const buildTermLifeRateQuoteRequest = (
  termLifeEligible: TermLifeEligible
) =>
  createSelector(fromSession.getQuoteState, state => {
    const request: TermLifeQuoteRequest = {
      Illustration: {
        distribution: 'Broker Dealer',
        product: 'Nationwide 10-year Term GLT',
        insured: {
          issueState: state,
          gender:
            termLifeEligible.person.person.gender === 'M' ? 'Male' : 'Female',
          dateOfBirth: DateUtils.januaryFirstIfMasked(
            termLifeEligible.person.person.dateOfBirth
          ),
          riskClass: termLifeEligible.riskClass,
          rating: true,
          flatExtra: [],
        },
        proposal: {
          faceAmount1: '',
          scheduledPremium: '',
          premiumMode: 'Monthly',
        },
        riders: {
          premiumWaiver: false,
        },
      },
      Quote: {
        faceAmountRangeMinimum: 100000,
        faceAmountRangeMaximum: 1000000,
        faceAmountIncrement: 50000,
      },
    };
    const termLifeRequest = {
      id: termLifeEligible.id,
      firstName: termLifeEligible.firstName,
      lastName: termLifeEligible.lastName,
      termLifeEligible: termLifeEligible.termLifeEligible,
      termLifeQuoteRequest: request,
      relationToPrimaryNamedInsured:
        StringUtils.areEqual(
          termLifeEligible.person.relationToPrimaryNamedInsured,
          DriverRelationToPNI.PNI
        ) ||
        StringUtils.areEqual(
          termLifeEligible.person.policyHolderType,
          PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
        )
          ? DriverRelationToPNI.PNI
          : null,
      hasTermLifeDataChanged: termLifeEligible.hasTermLifeDataChanged,
    };
    return termLifeRequest;
  });

// For now, (productId) is ignored. We might want it in the future.
export const ratingCallShouldDelay = (productId: string) =>
  createSelector(
    fromDriver.getDriversLoading,
    fromPowersportsDrivers.getPowersportsDriversLoading,
    fromModifier.getModifierLoading,
    fromVehicle.getVehiclesLoading,
    fromTelematics.getEnrollmentsLoading,
    fromDsmProducts.anyProductIsInitiating,
    (
      driverLoading,
      powersportsDriversLoading,
      modifierLoading,
      vehiclesLoading,
      telematicsLoading,
      initiating
    ) =>
      driverLoading ||
      powersportsDriversLoading ||
      modifierLoading ||
      vehiclesLoading ||
      telematicsLoading ||
      initiating
  );

export const getPolicyHolder = createSelector(
  fromPolicyholder.getPrimaryNamedInsured,
  fromAccount.getAccountId,
  fromRetrieve.getRetrieveSuccess,
  fromRetrieve.getAllRetrievedQuotes,
  (pni, accountId, isQuoteRetrievedSuccess, retrievedQuotes) => {
    if (!accountId && isQuoteRetrievedSuccess && retrievedQuotes.length) {
      const rectrivedQuote: RetrieveEntity = retrievedQuotes.find(
        quote => quote && quote.response && quote.response.accountId
      );
      accountId = rectrivedQuote ? rectrivedQuote.response.accountId : null;
    }
    return { pni, accountId };
  }
);

export const buildCourseGrainInitiateNewBusinessUmbrellaRequest = () =>
  createSelector(
    getPolicyHolder,
    fromPolicyAddress.selectPolicyAddressEntities,
    fromAgency.getAgency,
    fromPolicy.getPolicyEffectiveDates,
    fromDsmProducts.getAllProducts,
    // fromUserContext.getUserContext,
    // fromAgency.getAgency,
    // fromDsmProducts.getSelectedProductsWithoutErrors,
    // fromCurrentCarrier.getCurrentCarrierState,
    // fromVehicles.selectAllDsmVehicles,
    fromUmbrella.buildUnderlyingPolicies,
    fromUmbrella.buildVehicleExposures,
    fromUmbrella.buildLocationExposures,
    // fromUmbrella.buildHouseholdMembers,
    (
      policyholder,
      policyAddress,
      agent,
      effectiveDate,
      products,
      // userContext,
      // agency,
      // currentCarrier,
      // vehicles,
      underlyingPolicies,
      vehicleExposures,
      locationExposures
      // householdMembers
    ) => {
      if (!policyholder.pni) {
        return null;
      }

      const address = policyAddress[policyholder.pni.addressId];

      if (!address) {
        return null;
      }

      const producerRequest: ProducerRequest = {
        // producerCode: ProducerUtils.getQuoteProducerCode(agent, address.state),
        // producerCode: '00012349 - 007',
        producerCode: ProducerUtils.getQuoteProducerCode(agent, address.state),
        // producerCode: '340030090',
        type: ProducerUtils.getProducerType(agent),
      };

      const policyholderRequest =
        PolicyholderBuilder.buildPolicyholderRequestFromEntity(
          policyholder.pni,
          address
        );

      const request: InitiateNewBusinessUmbrellaRequest = {
        policyHolders: policyholder.accountId ? [] : [policyholderRequest],
        policyAddress: address,
        producer: producerRequest,
        effectiveDate: DateUtils.getFurthestOutEffDateFromProducts(
          DateUtils.formatDateToDSM(effectiveDate.baseEffectiveDate),
          products
        ),
        underlyingPolicies,
        vehicleExposures,
        locationExposures,
        prequalificationAnswers:
          PrequalificationAnswersUtil.getPrequalificationAnswers(
            ProductTypes.UMBRELLA,
            null
          ),
        productId: ProductTypes.UMBRELLA,
        accountId: policyholder.accountId,
      };

      return request;
    }
  );

export const getTermLifeQuoteId = productId =>
  createSelector(fromDsmProducts.getProduct(productId), product => {
    return product?.quoteId;
  });
