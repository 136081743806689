import { Action } from '@ngrx/store';
import { PolicyEffectiveDates } from '@core/models/policy/policy-effective-date.model';

export const SET_POLICY_EFFECTIVE_DATE = '[Core] Set Policy Effective Date';

export class SetPolicyEffectiveDate implements Action {
  readonly type = SET_POLICY_EFFECTIVE_DATE;
  constructor(public payload: PolicyEffectiveDates) {}
}

export type PolicyActions = SetPolicyEffectiveDate;
