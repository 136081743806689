import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable, of } from 'rxjs';

import { PageAlertService } from '@core/services';

@Injectable()
export class PageAlertGuard implements CanActivate {
  constructor(private _pageAlertService: PageAlertService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this._pageAlertService.dispatchActiveAlert(
      this._pageAlertService.getPageAlertIdFromUrl(state.url)
    );
    return of(true);
  }
}
