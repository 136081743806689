import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import {
  TermType,
  TelematicsProgram,
  TelematicsEnrollmentStatus,
  ProductTypes,
  ModifierNames,
  SmartMilesMethods,
  SmartMiles_ConnectedCar,
} from '@shared/constants/app-constants';
import {
  NgbModal,
  NgbAccordion,
  NgbPanelChangeEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { TelematicsChangeModalComponent } from './telematics-change-modal/telematics-change-modal.component';
import { PremiumEntity } from '@core/models/entities/premium.entity';
import { TelematicsEnrollmentsService } from '@core/services/telematics-enrollments.service';
import { CoverageEntity } from '@core/models/entities/coverage.entity';
import {
  TelematicsEnrollmentResponse,
  TelematicsVehicle,
  TelematicsVehiclePremium,
  TelematicsEnrollmentRecommendationResponse,
  PreQualifiedVehicleInfo,
} from '@core/models/telematics/telematics.model';
import { ConnectedCarCriteria } from '@core/models/private-label/private-label.model';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { TelematicsEnrollmentUtils } from '@shared/utils/telematics-enrollment.utils';
import { take } from 'rxjs/operators';
import { ModifiersDao } from '@core/dao/modifiers.dao';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { Observable, Subject } from 'rxjs';
import { AgencyService } from '@core/services/agency.service';
import { LoggingService, SessionService } from '@core/services';
import { SessionLog } from '@core/models/session-log.model';
import { QuoteService } from '@core/services/quote.service';

@Component({
  selector: 'mq-telematics-enrollments',
  templateUrl: './telematics-enrollments.component.html',
  styleUrls: ['../../../../../assets/scss/telematics-panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelematicsEnrollmentsComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() vehicles: VehicleEntity[];
  @Input() telematicsEnrollments: TelematicsEnrollmentResponse;
  @Input() isConnectedCarEnabled: boolean;
  @Input() connectedCarCriteria: ConnectedCarCriteria[];
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() coverages: CoverageEntity[];
  @Input() termType: string;
  @Input() coveragePremiums: PremiumEntity[];
  @Input() quoteState: string;
  @Input() loadProgramsOpen: boolean;
  @Input() preQualifiedVehicleInfo: PreQualifiedVehicleInfo[];
  @Input() privateLabelIdentifier: string;

  @Output() resetRequested = new EventEmitter<void>();
  @Output() telematicsChangeRequested = new EventEmitter<void>();

  static nextNonce = 1;
  nonce = TelematicsEnrollmentsComponent.nextNonce++;

  mobileAppEnrolled: boolean;
  isDeviceOnlyState: boolean;
  isSmartMilesState: boolean;
  isSRCCForCA: boolean;
  recommendation: TelematicsEnrollmentRecommendationResponse;
  isSelfReportedState: boolean;

  enrollmentVehicles: TelematicsVehicle[] = [];
  vehiclePremiums: TelematicsVehiclePremium[] = [];
  premiumDisplays: any[] = [];

  smartMilesVehicles: VehicleEntity[] = [];
  smartRideVehicles: VehicleEntity[] = [];
  selfReportedVehicles: VehicleEntity[] = [];
  ineligibleVehicles: VehicleEntity[] = [];
  unenrolledVehicles: VehicleEntity[] = [];
  prequalifiedVehicles: VehicleEntity[] = [];
  connectedCarVehicles: VehicleEntity[] = [];

  initialDiscount: string;
  maximumDiscount: string;
  activePanelId: string;

  isNSSAgent$: Observable<boolean>;

  private _ngUnsubscribe = new Subject();

  @ViewChild('accordion') accordion: NgbAccordion;

  constructor(
    private _telematicsEnrollmentsService: TelematicsEnrollmentsService,
    private _modalService: NgbModal,
    private _modifiersDao: ModifiersDao,
    private _agencyService: AgencyService,
    private _sessionService: SessionService,
    private _quoteService: QuoteService,
    private _loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this.enrollmentVehicles =
      this.telematicsEnrollments &&
      this.telematicsEnrollments.vehicleEnrollment &&
      this.telematicsEnrollments.vehicleEnrollment.vehicles.length
        ? this.telematicsEnrollments.vehicleEnrollment.vehicles
        : [];
    this.isDeviceOnlyState = this.stateSpecificFlags
      ? this.stateSpecificFlags.isDeviceOnlyState
      : null;
    this.isSmartMilesState = this.stateSpecificFlags
      ? this.stateSpecificFlags.smartMiles
      : null;
    this.isSelfReportedState = this.stateSpecificFlags
      ? this.stateSpecificFlags.selfReportedProgram
      : null;
    this.isSRCCForCA = this.stateSpecificFlags
      ? this.stateSpecificFlags.srccForCA
      : null;
    this.initialDiscount = TelematicsEnrollmentUtils.getDiscountMetadata(
      'initialDiscount',
      this.quoteState
    )
      ? TelematicsEnrollmentUtils.getDiscountMetadata(
          'initialDiscount',
          this.quoteState
        )
      : '10%';
    this.maximumDiscount = TelematicsEnrollmentUtils.getDiscountMetadata(
      'maximumDiscount',
      this.quoteState
    )
      ? TelematicsEnrollmentUtils.getDiscountMetadata(
          'maximumDiscount',
          this.quoteState
        )
      : '40%';
    this.buildProgramLists();
    this.getVehiclePremiums();
    this.getRecommendation();
    if (this.accordion && this.accordion.activeIds.length) {
      if (this.loadProgramsOpen) {
        this.accordion.expandAll();
      } else {
        this.accordion.collapseAll();
      }
    }
    this._agencyService
      .isNSSAgent()
      .pipe(take(1))
      .subscribe(isNSSAgent => {
        if (isNSSAgent) {
          this.activePanelId = 'programsToSave-' + this.nonce;
        }
      });
  }

  ngOnChanges(changes) {
    this.enrollmentVehicles =
      this.telematicsEnrollments &&
      this.telematicsEnrollments.vehicleEnrollment &&
      this.telematicsEnrollments.vehicleEnrollment.vehicles.length
        ? this.telematicsEnrollments.vehicleEnrollment.vehicles
        : [];
    this.isDeviceOnlyState = this.stateSpecificFlags
      ? this.stateSpecificFlags.isDeviceOnlyState
      : null;
    this.isSmartMilesState = this.stateSpecificFlags
      ? this.stateSpecificFlags.smartMiles
      : null;
    this.isSRCCForCA = this.stateSpecificFlags
      ? this.stateSpecificFlags.srccForCA
      : null;
    this.buildProgramLists();
    this.getVehiclePremiums();
    this.getRecommendation();
    if (this.accordion && this.accordion.activeIds.length) {
      if (this.loadProgramsOpen) {
        this.accordion.expandAll();
      } else {
        this.accordion.collapseAll();
      }
    }
  }

  ngAfterViewInit(): void {
    this.accordion.expand('programsToSave-' + this.nonce);
  }

  buildProgramLists() {
    this.clearProgramLists();
    if (this.enrollmentVehicles.length) {
      // Vehicle Program recommended by API
      [
        this.prequalifiedVehicles,
        this.smartMilesVehicles,
        this.smartRideVehicles,
        this.selfReportedVehicles,
        this.unenrolledVehicles,
        this.ineligibleVehicles,
        this.connectedCarVehicles,
      ] = this.vehicles.reduce(
        (accum, vehicle) => {
          const preQualVehIdx = 0,
            smartMilesVehIdx = 1,
            smartRideVehIdx = 2,
            selfReportVehIdx = 3,
            unenrolledVehIdx = 4,
            ineligibleVehIdx = 5,
            connectedCarIdx = 6;
          const enrollmentVehicle = this.getEnrollmentVehicleById(
            vehicle.vehicleId
          );
          if (
            enrollmentVehicle &&
            (enrollmentVehicle.deviceCompatible || vehicle.isConnectable)
          ) {
            if (
              enrollmentVehicle.enrollmentStatus ===
              TelematicsEnrollmentStatus.PRE_QUALIFIED
            ) {
              this.pushUnique(accum[preQualVehIdx], vehicle);
            } else if (
              enrollmentVehicle.enrollmentStatus ===
              TelematicsEnrollmentStatus.ENROLLED
            ) {
              if (
                enrollmentVehicle.vehicleProgram ===
                TelematicsProgram.SMART_MILES
              ) {
                if (enrollmentVehicle?.dataCollectionMethod)
                  vehicle.dataCollectionMethod =
                    enrollmentVehicle?.dataCollectionMethod;
                this.pushUnique(accum[smartMilesVehIdx], vehicle);
              } else if (
                enrollmentVehicle.vehicleProgram ===
                TelematicsProgram.SMARTRIDE_DEVICE
              ) {
                this.pushUnique(accum[smartRideVehIdx], vehicle);
              } else if (
                enrollmentVehicle.vehicleProgram ===
                  TelematicsProgram.CONNECTED_CAR &&
                (this.isConnectedCar(vehicle) ||
                  this.isConnectedCarEnrolled(enrollmentVehicle, vehicle))
              ) {
                this.pushUnique(accum[connectedCarIdx], vehicle);
              } else if (
                enrollmentVehicle.vehicleProgram ===
                TelematicsProgram.SELF_REPORTED
              ) {
                this.pushUnique(accum[selfReportVehIdx], vehicle);
              } else {
                this.pushUnique(accum[unenrolledVehIdx], vehicle); // failsafe: push to unenrolled
              }
            } else {
              this.pushUnique(accum[unenrolledVehIdx], vehicle);
            }
          } else if (
            enrollmentVehicle &&
            enrollmentVehicle.vehicleProgram === TelematicsProgram.SELF_REPORTED
          ) {
            this.pushUnique(accum[selfReportVehIdx], vehicle);
          } else {
            this.pushUnique(accum[ineligibleVehIdx], vehicle);
          }
          return accum;
        },
        [[], [], [], [], [], [], []]
      );
    } else if (
      this.telematicsEnrollments &&
      this.telematicsEnrollments.mobileEnrollment
    ) {
      // Mobile App recommended by API
      this.mobileAppEnrolled = true;
      this.pushUnique(this.smartRideVehicles, this.vehicles);
    } else {
      this.pushUnique(this.unenrolledVehicles, this.vehicles);
    }
    // After assignments, see if we should convert all to Mobile App
    if (
      this.smartRideVehicles.length &&
      !this.prequalifiedVehicles.length &&
      !this.smartMilesVehicles.length &&
      !this.connectedCarVehicles.length &&
      !this.unenrolledVehicles.length &&
      !this.isDeviceOnlyState
    ) {
      this.mobileAppEnrolled = true;
      this.pushUnique(this.smartRideVehicles, this.vehicles);
      this.ineligibleVehicles = [];
    } else {
      this.mobileAppEnrolled = false;
    }
  }

  changeEnrollment(vehicle: VehicleEntity) {
    this.getRecommendation();
    const anyVehicleConnectedCarEligible = this.vehicles.some(
      vehicle => vehicle.isConnectable
    );
    const preQualVehInfo =
      this.preQualifiedVehicleInfo && this.preQualifiedVehicleInfo.length
        ? this.preQualifiedVehicleInfo.find(
            veh =>
              parseInt(veh.vehicleId, 10) === parseInt(vehicle.vehicleId, 10)
          )
        : null;
    const enrollmentVeh = this.getEnrollmentVehicleById(vehicle.vehicleId);
    const modal = this._modalService.open(TelematicsChangeModalComponent);
    modal.componentInstance.vehicle = vehicle;
    modal.componentInstance.recommendation = this.recommendation;
    modal.componentInstance.mobileAppEnrolled = this.mobileAppEnrolled;
    modal.componentInstance.enrollmentVehicle = enrollmentVeh;
    modal.componentInstance.isConnectedCar = this.isConnectedCar(vehicle);
    modal.componentInstance.isPreQualifiedVehicle =
      this.isPreQualifiedVehicle(vehicle);
    modal.componentInstance.discountPercentage =
      enrollmentVeh && enrollmentVeh.discountPercentage
        ? enrollmentVeh.discountPercentage
        : null;
    modal.componentInstance.preQualifiedVehicleInfo = preQualVehInfo;
    modal.componentInstance.isDeviceOnlyState = this.isDeviceOnlyState;

    modal.result.then(
      response => {
        const telematicsEnrollmentsChangeLog: SessionLog = {
          type: 'Recalculate Premium',
          code: 'Telematics Program',
          reason: 'Telematics Change Update',
          value: response.selectedProgram,
        };
        this._sessionService.updateSessionLogEntry(
          telematicsEnrollmentsChangeLog
        );
        let selectedProgram = response.selectedProgram || null;
        const enrollmentVehicle = this.getEnrollmentVehicleById(
          vehicle.vehicleId
        );
        if (
          !(
            this.connectedCarVehicles.length +
              this.smartMilesVehicles.filter(
                SMveh =>
                  SMveh?.dataCollectionMethod === SmartMilesMethods.ConnectedCar
              )?.length >
            1
          ) &&
          (enrollmentVehicle?.vehicleProgram ===
            TelematicsProgram.CONNECTED_CAR ||
            (enrollmentVehicle?.vehicleProgram ===
              TelematicsProgram.SMART_MILES &&
              enrollmentVehicle?.dataCollectionMethod ===
                SmartMilesMethods.ConnectedCar)) &&
          (selectedProgram !== TelematicsProgram.CONNECTED_CAR ||
            selectedProgram !== SmartMiles_ConnectedCar)
        ) {
          this._quoteService.dispatchPatchQuote(ProductTypes.AUTO, {
            hasConnectedCarConsent: false,
          });
        }
        if (
          selectedProgram === TelematicsProgram.SMARTRIDE_DEVICE &&
          !anyVehicleConnectedCarEligible &&
          !this.enrollmentVehicles.some(
            enrVeh =>
              enrVeh.enrollmentStatus ===
              TelematicsEnrollmentStatus.PRE_QUALIFIED
          ) &&
          !preQualVehInfo &&
          this.enrollmentVehicles.every(
            veh =>
              veh.vehicleProgram !== TelematicsProgram.SMART_MILES ||
              parseInt(veh.vehicleId, 10) ===
                parseInt(enrollmentVehicle.vehicleId, 10)
          )
        ) {
          selectedProgram = 'SmartRideMobile';
        }
        if (
          selectedProgram === TelematicsProgram.SMARTRIDE_DEVICE &&
          !this.stateSpecificFlags.isDeviceOnlyState
        ) {
          const allOtherVehiles = this?.enrollmentVehicles?.filter(veh => {
            return eval(veh?.vehicleId) !== vehicle?.vehicleId;
          });
          if (
            allOtherVehiles?.every(veh => {
              return (
                veh?.vehicleProgram === TelematicsProgram.SMARTRIDE_DEVICE ||
                veh?.vehicleProgram === TelematicsProgram.SMARTRIDE_MOBILE ||
                veh?.enrollmentStatus ===
                  TelematicsEnrollmentStatus.NOT_ENROLLED
              );
            })
          ) {
            selectedProgram = 'SmartRideMobile';
          }
        }
        if (
          selectedProgram === TelematicsEnrollmentStatus.NOT_ENROLLED &&
          this.mobileAppEnrolled
        ) {
          if (this.telematicsEnrollments) {
            this._telematicsEnrollmentsService.dispatchRemoveTelematicsEnrollment();
            const easyPayDiscount$ = this._modifiersDao.getPolicyLineModifier(
              ModifierNames.EASY_PAY,
              ProductTypes.AUTO
            );

            easyPayDiscount$.pipe(take(1)).subscribe(easyPayDiscount => {
              if (easyPayDiscount?.selectedOptionValue === 'true') {
                const easyPayModifier = {
                  eligibleDiscountId: ModifierNames.EASY_PAY,
                  productId: ProductTypes.AUTO,
                  selectedOptionValue: 'false',
                };
                this._modifiersDao.dispatchUpdatePolicyLine(easyPayModifier);
              }
            });
          }
        } else if (
          !enrollmentVehicle &&
          selectedProgram === 'SmartRideMobile' &&
          !this.telematicsEnrollments
        ) {
          this._telematicsEnrollmentsService.dispatchAddTelematicsEnrollments({
            recommendedProgram: 'Mobile App Program',
          });
          this.telematicsChangeRequested.emit();
          const easyPayDiscount$ = this._modifiersDao.getPolicyLineModifier(
            ModifierNames.EASY_PAY,
            ProductTypes.AUTO
          );

          easyPayDiscount$.pipe(take(1)).subscribe(easyPayDiscount => {
            if (easyPayDiscount?.selectedOptionValue === 'false') {
              const easyPayModifier = {
                eligibleDiscountId: ModifierNames.EASY_PAY,
                productId: ProductTypes.AUTO,
                selectedOptionValue: 'true',
              };
              this._modifiersDao.dispatchUpdatePolicyLine(easyPayModifier);
            }
          });
        } else if (enrollmentVehicle) {
          this._telematicsEnrollmentsService.dispatchUpdateTelematicsEnrollment(
            {
              enrollmentVehicle,
              selectedProgram,
            }
          );
          this.telematicsChangeRequested.emit();
          if (selectedProgram === TelematicsEnrollmentStatus.NOT_ENROLLED) {
            const easyPayDiscount$ = this._modifiersDao.getPolicyLineModifier(
              ModifierNames.EASY_PAY,
              ProductTypes.AUTO
            );

            easyPayDiscount$.pipe(take(1)).subscribe(easyPayDiscount => {
              if (easyPayDiscount?.selectedOptionValue === 'true') {
                const easyPayModifier = {
                  eligibleDiscountId: ModifierNames.EASY_PAY,
                  productId: ProductTypes.AUTO,
                  selectedOptionValue: 'false',
                };
                this._modifiersDao.dispatchUpdatePolicyLine(easyPayModifier);
              }
            });
          } else {
            const easyPayDiscount$ = this._modifiersDao.getPolicyLineModifier(
              ModifierNames.EASY_PAY,
              ProductTypes.AUTO
            );

            easyPayDiscount$.pipe(take(1)).subscribe(easyPayDiscount => {
              if (easyPayDiscount?.selectedOptionValue === 'false') {
                const easyPayModifier = {
                  eligibleDiscountId: ModifierNames.EASY_PAY,
                  productId: ProductTypes.AUTO,
                  selectedOptionValue: 'true',
                };
                this._modifiersDao.dispatchUpdatePolicyLine(easyPayModifier);
              }
            });
          }
          /* AddTelematicsEnrollments only accepts a recommendation response, so fake it if we have to. */
        } else if (
          selectedProgram === TelematicsProgram.SMARTRIDE_DEVICE ||
          selectedProgram === TelematicsProgram.SMART_MILES ||
          selectedProgram === TelematicsProgram.SELF_REPORTED ||
          selectedProgram === TelematicsProgram.CONNECTED_CAR ||
          selectedProgram === 'SmartMilesConnectedCar'
        ) {
          if (this.telematicsEnrollments?.enrollmentId) {
            this._telematicsEnrollmentsService.dispatchRemoveTelematicsEnrollment();
          }
          this._telematicsEnrollmentsService.dispatchAddTelematicsEnrollments({
            recommendedProgram: 'Vehicle Program',
            qualifyingInformation: {
              vehicles: [
                {
                  vehicleId: vehicle.vehicleId,
                  estimatedAnnualMileage: vehicle.annualMiles,
                  availablePrograms: [
                    {
                      name:
                        selectedProgram === 'SmartMilesConnectedCar'
                          ? 'SmartMiles'
                          : selectedProgram,
                      recommended: true,
                      dataCollectionMethod:
                        selectedProgram === 'SmartMilesConnectedCar'
                          ? SmartMilesMethods.ConnectedCar
                          : selectedProgram === TelematicsProgram.SMART_MILES
                          ? SmartMilesMethods.Device
                          : null,
                    },
                  ],
                },
              ],
            },
          });
          this.telematicsChangeRequested.emit();
          const easyPayDiscount$ = this._modifiersDao.getPolicyLineModifier(
            ModifierNames.EASY_PAY,
            ProductTypes.AUTO
          );

          easyPayDiscount$.pipe(take(1)).subscribe(easyPayDiscount => {
            if (easyPayDiscount?.selectedOptionValue === 'false') {
              const easyPayModifier = {
                eligibleDiscountId: ModifierNames.EASY_PAY,
                productId: ProductTypes.AUTO,
                selectedOptionValue: 'true',
              };
              this._modifiersDao.dispatchUpdatePolicyLine(easyPayModifier);
            }
          });
        }
      },
      () => {}
    );
  }

  clearProgramLists() {
    this.smartMilesVehicles = [];
    this.smartRideVehicles = [];
    this.unenrolledVehicles = [];
    this.ineligibleVehicles = [];
    this.selfReportedVehicles = [];
    this.connectedCarVehicles = [];
  }

  removeAllEnrollments() {
    if (!this.mobileAppEnrolled) {
      if (this.connectedCarVehicles?.length) {
        this._quoteService.dispatchPatchQuote(ProductTypes.AUTO, {
          hasConnectedCarConsent: false,
        });
      }
      for (const enrollmentVehicle of this.enrollmentVehicles) {
        this._telematicsEnrollmentsService.dispatchUpdateTelematicsEnrollment({
          enrollmentVehicle,
          selectedProgram: TelematicsEnrollmentStatus.NOT_ENROLLED,
        });
      }
    } else {
      if (this.telematicsEnrollments) {
        this._telematicsEnrollmentsService.dispatchRemoveTelematicsEnrollment();
      }
    }
    const easyPayDiscount$ = this._modifiersDao.getPolicyLineModifier(
      ModifierNames.EASY_PAY,
      ProductTypes.AUTO
    );

    easyPayDiscount$.pipe(take(1)).subscribe(easyPayDiscount => {
      if (easyPayDiscount?.selectedOptionValue === 'true') {
        const easyPayModifier = {
          eligibleDiscountId: ModifierNames.EASY_PAY,
          productId: ProductTypes.AUTO,
          selectedOptionValue: 'false',
        };
        this._modifiersDao.dispatchUpdatePolicyLine(easyPayModifier);
      }
    });
  }

  resetDefaultEnrollments(): void {
    this.resetRequested.next();
    if (this.telematicsEnrollments && this.telematicsEnrollments.enrollmentId) {
      this._telematicsEnrollmentsService.dispatchRemoveTelematicsEnrollment();
    }
    this._telematicsEnrollmentsService.dispatchTelematicsEnrollment();
    const easyPayDiscount$ = this._modifiersDao.getPolicyLineModifier(
      ModifierNames.EASY_PAY,
      ProductTypes.AUTO
    );

    easyPayDiscount$.pipe(take(1)).subscribe(easyPayDiscount => {
      if (easyPayDiscount?.selectedOptionValue === 'false') {
        const easyPayModifier = {
          eligibleDiscountId: ModifierNames.EASY_PAY,
          productId: ProductTypes.AUTO,
          selectedOptionValue: 'true',
        };
        this._modifiersDao.dispatchUpdatePolicyLine(easyPayModifier);
      }
    });
  }

  getRecommendation(): void {
    this._telematicsEnrollmentsService
      .getRecommendation()
      .pipe(take(1))
      .subscribe(recommendation => {
        this.recommendation = recommendation;
      });
  }

  getVehiclePremiums(): void {
    if (this.vehicles && this.vehicles.length) {
      this.vehiclePremiums = this.vehicles.map(vehicle => {
        const coverageCostSum = this.coverages.reduce(
          (sum, current) =>
            sum +
            (current.coverableId === vehicle.vehicleId &&
            current.coverageCost &&
            current.coverageCost.length
              ? current.coverageCost.reduce(
                  (sum, currentCost) =>
                    sum +
                    (currentCost.actualTermAmount
                      ? currentCost.actualTermAmount.amount
                      : 0.0),
                  0.0
                )
              : 0.0),
          0.0
        );
        const vehicleCost =
          this.termType === 'HalfYear'
            ? coverageCostSum / TermType.HalfYear
            : coverageCostSum / TermType.FullYear;
        return { cost: vehicleCost, vehicleId: vehicle.vehicleId };
      });
    }
  }

  getEnrollmentVehicleById(vehicleId): TelematicsVehicle {
    return this.enrollmentVehicles.find(
      ev => ev.vehicleId === vehicleId.toString()
    );
  }

  pushUnique(arr: any[], item: any | any[]) {
    if (item.length) {
      item.forEach(el => {
        if (arr.indexOf(el) === -1) {
          arr.push(el);
        }
      });
    } else {
      if (arr.indexOf(item) === -1) {
        arr.push(item);
      }
    }
  }

  allVehiclesIneligibleOrUnenrolled(): boolean {
    return (
      this.unenrolledVehicles.length + this.ineligibleVehicles.length ===
      this.vehicles.length
    );
  }

  logAccordionToggleToSplunk(event: NgbPanelChangeEvent): void {
    const accordionOpened = event.nextState;
    const experience = sessionStorage.getItem('adobeTargetExperience');
    const message = {
      accordionValue: `${accordionOpened ? 'opened' : 'closed'}`,
      accordionName: 'programToSave',
      testNumber: 'testLearn-digex760',
      groupAssignment: `${experience}`,
    };
    this._loggingService.logToSplunk({ event: 'engagementAccordion', message });
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  private isConnectedCar(vehicle: VehicleEntity): boolean {
    return (
      this.isConnectedCarEnabled &&
      PrivateLabelContentHelper.isConnectedCarEligible(
        vehicle,
        this.connectedCarCriteria,
        this.privateLabelIdentifier
      )
    );
  }

  private isConnectedCarEnrolled(
    enrollmentVehicle: TelematicsVehicle,
    vehicle: VehicleEntity
  ): boolean {
    return (
      vehicle.isConnectable &&
      enrollmentVehicle.enrollmentStatus ===
        TelematicsEnrollmentStatus.ENROLLED &&
      enrollmentVehicle.vehicleProgram === TelematicsProgram.CONNECTED_CAR
    );
  }

  private isPreQualifiedVehicle(vehicle: VehicleEntity): boolean {
    return this.prequalifiedVehicles.includes(vehicle);
  }
}
