import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

@Injectable({
  providedIn: 'root',
})
export class DocumentsDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  dispatchGetDocuments(): void {
    this._store.dispatch(new fromActions.GetDocuments());
  }

  getDocumentsLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getDocumentsLoaded);
  }

  getAll(): Observable<any[]> {
    return this._store.select(fromSelectors.selectAllDocumentss);
  }
}
