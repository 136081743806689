import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Effect, Actions, ofType } from '@ngrx/effects';
import * as RouterActions from '@core/store/actions/router.action';

import { AgencyService } from '@core/services';
import { tap, map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private agencyService: AgencyService,
    private _store: Store<fromStore.AppState>
  ) {}

  @Effect({ dispatch: false })
  navigate$ = this.actions$.pipe(
    ofType(RouterActions.GO),
    map((action: RouterActions.Go) => action.payload),
    tap(({ path, query: queryParams, extras }) => {
      this.router.navigate(path, {
        queryParams,
        ...extras,
      });
    })
  );

  @Effect({ dispatch: false })
  navigateOutsideApp$ = this.actions$.pipe(
    ofType(RouterActions.NAVIGATE_OUTSIDE_APP),
    map((action: RouterActions.NavigateOutsideApp) => action.url),
    tap(url => {
      window.location.href = url;
    })
  );

  @Effect({ dispatch: false })
  navigateBack$ = this.actions$.pipe(
    ofType(RouterActions.BACK),
    tap(() => this.location.back())
  );

  @Effect({ dispatch: false })
  navigateForward$ = this.actions$.pipe(
    ofType(RouterActions.FORWARD),
    tap(() => this.location.forward())
  );

  @Effect()
  softfall$ = this.actions$.pipe(
    ofType(RouterActions.SOFTFALL),
    map((action: RouterActions.Softfall) => action.reason),
    map(
      reason => new RouterActions.Go({ path: ['/softfall'], query: { reason } })
    )
  );

  @Effect()
  maintenance$ = this.actions$.pipe(
    ofType(RouterActions.MAINTENANCE),
    map((action: RouterActions.Maintenance) => action.reason),
    map(
      reason =>
        new RouterActions.Go({ path: ['/maintenance'], query: { reason } })
    )
  );

  @Effect()
  newQuote$ = this.actions$.pipe(
    ofType(RouterActions.NEW_QUOTE),
    switchMap(() =>
      this.agencyService.getAgency().pipe(
        take(1),
        map(agency => {
          const queryParams = {
            ...agency,
            agencyState: agency.quoteState,
          };
          return new RouterActions.Go({
            path: ['/agency/agency-route'],
            query: queryParams,
          });
        })
      )
    )
  );

  @Effect()
  nonStandardBridge$ = this.actions$.pipe(
    ofType(RouterActions.BRIDGE),
    map((action: RouterActions.Bridge) => [action.reason, action.redirectURL]),
    map(
      ([reason, redirectURL]) =>
        new RouterActions.Go({
          path: ['/nonStandardBridge'],
          query: { reason, redirectURL },
        })
    )
  );
}
