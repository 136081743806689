<div class="modal-body vw-50">
    <button
    type="button"
    class="close"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="modal.dismiss()"
     >
     <span aria-hidden="true">&times;</span>
    </button>
    <br>
    <div class="row">
        <div class="img-padding">
        <strong>How your monthly variable cost works.</strong> 
        <br>
        <br>
        Your monthly cost consists of two parts: a <strong> base rate</strong>, which is the same every month and a <strong> variable rate </strong>(number of miles driven multiplied by the cost per miles),
        which varies depending on <strong> mileage and driving trends. </strong>
        </div>
        
       <div class="row img-padding">
        <img class="tm-icon-img" src="assets/images/Variable-rate-chart.svg" alt="Variable-Chart-Image" /> 
       </div>
       <div class="row img-padding"> 
        <div>
            Your cost per mile may increase or decrease each month based on these four driving factors:
        </div>
        </div>
        <div class="row img-padding">
            <img class="tm-icon-img" src="assets/images/Driving-Factor.svg" alt="Device-Image" />         
        </div>
    </div>
</div>
