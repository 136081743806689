<div class="nw-container nw-inner-bun" [formGroup]="form">
  <mq-field-validation-message
    [form]="form"
    [complainAboutRequiredFields]="formSubmitted$ | async"
  ></mq-field-validation-message>

  <div class="row col-12">
    <h1 class="heading-h4 page-sub-title">Property Information</h1>
  </div>

  <ng-container *ngIf="isHome">
    <mq-ho-property-info-form
      [form]="homeownersForm"
      [homeowners]="homeowners$ | async"
      [coveredLocation]="coveredLocation$ | async"
      [stateSpecificFlags]="stateSpecificFlags$ | async"
      [partialQuoteCoveredLocation]="partialQuoteCoveredLocation$ | async"
    >
    </mq-ho-property-info-form>
  </ng-container>

  <ng-container *ngIf="isCondo">
    <mq-condo-property-info-form
      [form]="condoForm"
      [condo]="condo$ | async"
      [coveredLocation]="coveredLocation$ | async"
      [stateSpecificFlags]="stateSpecificFlags$ | async"
      [options]="options$ | async"
      [partialQuoteId]="partialQuoteId$ | async"
      [partialQuoteCoveredLocation]="partialQuoteCoveredLocation$ | async"
    >
    </mq-condo-property-info-form>
  </ng-container>

  <ng-container *ngIf="isRenters">
    <mq-renters-property-info-form
      [renters]="renters$ | async"
      [form]="rentersForm"
      [_pickList]="options$ | async"
      [stateSpecificFlags]="stateSpecificFlags$ | async"
    >
    </mq-renters-property-info-form>
  </ng-container>

  <ng-container>
    <mq-protective-devices-form
      [form]="protectiveDevicesForm"
      [protectiveDevices]="protectiveDevices$ | async"
      [stateSpecificFlags]="stateSpecificFlags$ | async"
      [options]="protectiveDeviceOptions$ | async"
    >
    </mq-protective-devices-form>
  </ng-container>

  <ng-container *ngIf="isHome">
    <mq-smarthome-price-points
      (smartHomeExperience)="setSmartHomeExperience($event)"
      [form]="smarthomeForm"
      [smartHomeDiscount]="smartHomeDiscountForHome$ | async"
      [isHome]="isHome"
    >
    </mq-smarthome-price-points>
  </ng-container>
  <ng-container *ngIf="isCondo">
    <mq-smarthome-price-points
      (smartHomeExperience)="setSmartHomeExperience($event)"
      [form]="smarthomeForm"
      [smartHomeDiscount]="smartHomeDiscountForCondo$ | async"
      [isCondo]="isCondo"
    >
    </mq-smarthome-price-points>
  </ng-container>
</div>

<nw-navigation-buttons
  (forward)="onSave()"
  [forwardOnly]="true"
  [isAppLoading]="isAppLoading$ | async"
  submitButtonText="Save"
></nw-navigation-buttons>
