<div class="nw-container nw-inner-bun">
  <div class="row">
    <div *ngIf="!rivianRedirect">
      <h1 class="heading-h3 col-sm-12 thank-you">
        Thank you for using Nationwide Express
      </h1>
      <h2 class="heading-h4 heading-unfortunately col-sm-12 sub-thank-you">
        Unfortunately, we ran into one or more issues and cannot directly offer
        you an Auto policy quote. However, our partner, National General
        insurance can provide you with a competitive Auto quote.
        <span id="product-specific-message" *ngIf="selectedProducts.length > 1">
          If you would like to obtain
          {{ additionalProductsText || 'a Nationwide Policy' }}, please contact
          a Nationwide representative at the phone number listed below.</span
        >
      </h2>
    </div>
    <div *ngIf="rivianRedirect">
      <h1 class="heading-h3 col-sm-12 thank-you">
        Thank you for using Nationwide Express
      </h1>
      <h2 class="heading-h4 heading-unfortunately col-sm-12 sub-thank-you">
        Unfortunately, we ran into one or more issues and cannot directly offer
        you an Auto policy quote. However, our partner, National General
        insurance can provide you with a competitive Auto quote.
      </h2>
    </div>
  </div>

  <div class="row" *ngFor="let error of errorMessages">
    <div class="col-12">
      <div class="custom-message alert alert-danger" role="alert">
        <a class="icon error"></a>
        <div>
          <div class="product">{{ error.product }}</div>
          <div class="message">{{ error.message }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 how-to-proceed">How to proceed</div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      You will be automatically redirected to the National General website. The
      information already entered will be shared with National General, in order
      to fulfill the request for an auto quote.
    </div>
  </div>

  <div class="row" *ngIf="showRedirectURL">
    <div class="col-sm-12">
      If you're not redirected to the National General website in 3 seconds,
      please click
      <a href="{{ redirectURL }}" target="_self">here</a>.
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="!rivianRedirect">
        <p>
          To contact a Nationwide representative call
          <a href="tel:+{{ unformattedContactNumber }}">{{ contactNumber }}</a
          >.
        </p>
        <ul>
          <li>Monday &ndash; Friday: 9 am &ndash; 8 pm ET</li>
          <li>Saturday: Closed</li>
          <li>Sunday: Closed</li>
        </ul>
      </div>
      <div *ngIf="rivianRedirect">
        <p>
          To contact a Rivian Insurance Advisor call
          <a href="tel:+{{ unformattedContactNumber }}">{{ contactNumber }}</a
          >.
        </p>
        <ul>
          <li>Monday &ndash; Friday: 9 am &ndash; 8 pm ET</li>
          <li>Saturday: Closed</li>
          <li>Sunday: Closed</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      You may also leave us feedback. To get started, click "Feedback" on the
      right side of your screen. Your feedback helps us continuously improve our
      platform &mdash; and your experience.
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="!rivianRedirect">
        Nationwide is separate from National General and its privacy practices
        and assumes no responsibility in connection with your use of their
        website. Any information that you provide directly to National General
        is subject to the privacy policy posted on National General's website.
        Unless otherwise stated, coverage is underwritten by companies
        affiliated with National General and is not underwritten by Nationwide.
      </div>
      <div *ngIf="rivianRedirect">
        Rivian is separate from National General and its privacy practices and
        assumes no responsibility in connection with your use of their website.
        Any information that you provide directly to National General is subject
        to the privacy policy posted on National General's website. Unless
        otherwise stated, coverage is underwritten by companies affiliated with
        National General and is not underwritten by Rivian.
      </div>
    </div>
  </div>
</div>
