import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'mq-inline-help-popup',
  templateUrl: './inline-help-popup.component.html',
  styleUrls: ['./inline-help-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineHelpPopupComponent {
  openHelpText = false;
  @Input() fieldId;
  @Input() labelInnerHTML;
  @Input() labelHTMLClass;
  @Input() helpTextInnerHTML;
  @Output() onHelp = new EventEmitter();

  onHelpCLick() {
    this.onHelp.emit(this.openHelpText);
  }
}
