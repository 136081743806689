import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'protectiveDevicesType',
})
export class AllProtectiveDevicesPipe implements PipeTransform {
  transform(val: string): string {
    const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    return val
      .replace(
        'Hurricane Resistant Permanent Storm Shutters and Hurricane Resistant Glass',
        'Both'
      )
      .replace('(with any glass type)', '')
      .replace('_Ext', '')
      .replace(regex, '$1$4 $2$3$5')
      .replace('Central Fire Alarm', 'Central/Direct Fire Alarm')
      .replace('Central Burglar Alarm', 'Central/Direct Burglar Alarm')
      .replace('Opening Protection applies', 'Qualifying Device');
  }
}
