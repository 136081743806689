import { Action } from '@ngrx/store';

export const enum PolicyNumberTypes {
  GENERATE_POLICY_NUMBER = '[Generate Policy Number] Generate Policy Number',
  GENERATE_POLICY_NUMBER_FAIL = '[Generate Policy Number] Generate Policy Number Fail',
  GENERATE_POLICY_NUMBER_SUCCESS = '[Generate Policy Number] Generate Policy Number Success',
  MARK_POLICY_NUMBER_LOADING = '[Mark Policy Number Loading] Mark Policy Number Loading',
}

export class GeneratePolicyNumber implements Action {
  readonly type = PolicyNumberTypes.GENERATE_POLICY_NUMBER;
}

export class GeneratePolicyNumberFail implements Action {
  readonly type = PolicyNumberTypes.GENERATE_POLICY_NUMBER_FAIL;
  constructor(public productId: string, public error: any) {}
}

export class GeneratePolicyNumberSuccess implements Action {
  readonly type = PolicyNumberTypes.GENERATE_POLICY_NUMBER_SUCCESS;
  constructor(public payload: any) {}
}

export class MarkPolicyNumberLoading implements Action {
  readonly type = PolicyNumberTypes.MARK_POLICY_NUMBER_LOADING;
  constructor(public productId: string) {}
}

export type PolicyNumberActions =
  | GeneratePolicyNumber
  | GeneratePolicyNumberFail
  | GeneratePolicyNumberSuccess
  | MarkPolicyNumberLoading;
