<ng-container *ngIf="isCompRater">
  <div class="row" style="margin: 10px 0 15px 0">
    <div class="alert alert-info col-sm-12" role="alert">
      <a class="icon info"></a>
      <span>
        Assumes the home has a roof in excellent condition, frame construction,
        and 100 amp circuit breaker panel.</span
      >
    </div>
  </div>
</ng-container>
<!-- home 2 -->
<mq-edit-coverage-table
  *ngIf="propertyLiabilityCoverageGrouping"
  [form]="form"
  icon="assets/images/Home2.svg"
  [title]="propertyLiabilityCoverageGrouping.title"
  [coverageChanges]="coverageChanges"
  [coverages]="propertyLiabilityCoverageGrouping.coverages"
  [coverageErrors]="coverageErrors"
  (coverageUpdated)="coverageChanged($event)"
  (consentToggled)="onConsentToggled($event)"
  [showCoveragePrices]="showCoveragePrices"
  [termMonths]="termMonths"
  [isQuote]="isQuote"
  [stateSpecificFlags]="stateSpecificFlags"
  [contactNumber]="contactNumber"
  (propertyInfoModalToggled)="propertyInfoModalToggled.emit($event)"
  [selectedProduct]="selectedProduct"
  [isMobile]="isMobile"
></mq-edit-coverage-table>

<!-- buidling wiht shield -->
<mq-edit-coverage-table
  *ngIf="deductiblesCoverageGrouping"
  [form]="form"
  icon="assets/images/BuildingAndShield_Blue.svg"
  [title]="deductiblesCoverageGrouping.title"
  [coverageChanges]="coverageChanges"
  [coverages]="deductiblesCoverageGrouping.coverages"
  [coverageErrors]="coverageErrors"
  (coverageUpdated)="coverageChanged($event)"
  [showCoveragePrices]="showCoveragePrices"
  [termMonths]="termMonths"
  [isQuote]="isQuote"
  [stateSpecificFlags]="stateSpecificFlags"
  [contactNumber]="contactNumber"
  [selectedProduct]="selectedProduct"
  [isMobile]="isMobile"
></mq-edit-coverage-table>

<mq-edit-coverage-table
  *ngIf="additionalProtectionCoverageGrouping"
  icon="assets/images/BuildingAndShield_Blue.svg"
  [form]="form"
  [title]="additionalProtectionCoverageGrouping.title"
  [coverageChanges]="coverageChanges"
  [coverages]="additionalProtectionCoverageGrouping.coverages"
  [coverageErrors]="coverageErrors"
  (coverageUpdated)="coverageChanged($event)"
  [showCoveragePrices]="showCoveragePrices"
  [termMonths]="termMonths"
  [isQuote]="isQuote"
  [stateSpecificFlags]="stateSpecificFlags"
  [contactNumber]="contactNumber"
  [selectedProduct]="selectedProduct"
  [isMobile]="isMobile"
></mq-edit-coverage-table>

<mq-edit-coverage-table
  *ngIf="specialLimitsOfLiabilityCoverageGrouping"
  [form]="form"
  icon="assets/images/Diamond_Blue.svg"
  [title]="specialLimitsOfLiabilityCoverageGrouping.title"
  [coverageChanges]="coverageChanges"
  [coverages]="specialLimitsOfLiabilityCoverageGrouping.coverages"
  [coverageErrors]="coverageErrors"
  (coverageUpdated)="coverageChanged($event)"
  [showCoveragePrices]="showCoveragePrices"
  [termMonths]="termMonths"
  [isQuote]="isQuote"
  [stateSpecificFlags]="stateSpecificFlags"
  [contactNumber]="contactNumber"
  [selectedProduct]="selectedProduct"
  [isMobile]="isMobile"
></mq-edit-coverage-table>
