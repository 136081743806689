<div class="nw-container">
  <div class="row">
    <div class="col-md-12">
      <h3>PLT Environment Status</h3>
      <p>Testing PolicyCenter Environments...</p>
    </div>
  </div>
</div>

<div class="nw-container">
  <div class="row">
    <div class="col-md-12">
      <table class="table">
        <caption>Environment Options</caption>
        <thead>
          <tr>
            <th scope="col">Environment</th>
            <th scope="col">Status</th>
            <th scope="col">Effective Date</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let response$ of responses$">
            <tr
              *ngIf="response$ | async as response"
              [ngClass]="{
                'table-success': response.successful === 'Up and running'
              }"
            >
              <td>{{ response.env }}</td>
              <td>{{ response.successful }}</td>
              <td>{{ response.effectiveDate | date: 'yyyy-MM-dd' }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
