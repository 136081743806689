import { Injectable } from '@angular/core';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { Observable } from 'rxjs';
import { ProductTypes } from '@shared/constants/app-constants';
import { TelematicsRecommendationRequest } from '@core/models/request/telematics-recommendation-request.model';
import {
  TelematicsEnrollmentRecommendationResponse,
  TelematicsEnrollmentResponse,
  TelematicsEnrollmentRequest,
} from '@core/models/telematics/telematics.model';

@Injectable({
  providedIn: 'root',
})
export class TelematicsEnrollmentsAdapter extends BaseDsmAdapter {
  getTelematicsRecommendation(
    request: TelematicsRecommendationRequest,
    quoteId: string
  ): Observable<TelematicsEnrollmentRecommendationResponse> {
    const url = `${this._appConfig.config.pcEdgeAutoTAndCUrl}/quotes/${quoteId}/telematics-recommended-programs`;
    return this.post<any>(url, request, {
      productId: ProductTypes.AUTO,
    });
  }

  getTelematicsEnrollments(request): Observable<TelematicsEnrollmentResponse> {
    const url = `${this._appConfig.config.pcEdgeAutoTAndCUrl}/quotes/${request.quoteId}/telematics-enrollments`;

    return this.get<any>(url, {
      productId: ProductTypes.AUTO,
    });
  }

  addTelematicsEnrollment(
    request: TelematicsEnrollmentRequest
  ): Observable<TelematicsEnrollmentResponse> {
    const url = `${this._appConfig.config.pcEdgeAutoTAndCUrl}/quotes/${request.quoteId}/telematics-enrollments`;
    const body = { enrollment: request.enrollment };

    return this.post<any>(url, body, {
      productId: ProductTypes.AUTO,
      policyHolderId: request.policyHolderId,
      prePopulatedInsuredId: request.prePopulatedInsuredId,
      partialQuoteId: request.partialQuoteId,
    });
  }

  updateTelematicsEnrollment(request): Observable<any> {
    const url = `${this._appConfig.config.pcEdgeAutoTAndCUrl}/quotes/${request.quoteId}/telematics-enrollments/${request.enrollmentId}`;
    const body = { enrollment: request.enrollment };

    return this.put<any>(url, body, {
      productId: ProductTypes.AUTO,
      policyHolderId: request.policyHolderId,
      prePopulatedInsuredId: request.prePopulatedInsuredId,
      partialQuoteId: request.partialQuoteId,
    });
  }

  removeTelematicsEnrollment(request): Observable<any> {
    const url = `${this._appConfig.config.pcEdgeAutoTAndCUrl}/quotes/${request.quoteId}/telematics-enrollments/${request.enrollmentId}`;

    return this.delete<any>(url, {
      productId: ProductTypes.AUTO,
    });
  }

  updateMobileInfoTelematicsEnrollment(request): Observable<any> {
    const url = `${this._appConfig.config.pcEdgeAutoTAndCUrl}/quotes/${request.quoteId}/telematics-enrollments/${request.enrollmentId}`;
    const body = { enrollment: request.enrollment };

    return this.put<any>(url, body, {
      productId: ProductTypes.AUTO,
      policyHolderId: request.policyHolderId,
      prePopulatedInsuredId: request.prePopulatedInsuredId,
      partialQuoteId: request.partialQuoteId,
    });
  }
}
