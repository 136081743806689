import { RecoverableErrorEntity } from '@core/models/recoverable-error/recoverable-error.entity';
import { Observable } from 'rxjs';
import { RecoverableErrorDao } from '@core/dao/recoverable-error.dao';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RecoverableErrorService {
  constructor(private _dao: RecoverableErrorDao) {}

  getAllRecoverableErrors(): Observable<RecoverableErrorEntity[]> {
    return this._dao.getAllRecoverableErrors();
  }

  getRecoverableErrorById(id: number): Observable<RecoverableErrorEntity> {
    return this._dao.getRecoverableErrorById(id);
  }

  getRecoverableErrorsByProduct(
    productId: string
  ): Observable<RecoverableErrorEntity[]> {
    return this._dao.getRecoverableErrorsByProduct(productId);
  }

  getRecoverableErrorsByPage(
    page: string
  ): Observable<RecoverableErrorEntity[]> {
    return this._dao.getRecoverableErrorsByPage(page);
  }

  addRecoverableError(error: RecoverableErrorEntity) {
    this._dao.addRecoverableError(error);
  }

  removeRecoverableError(id: number) {
    this._dao.removeRecoverableError(id);
  }

  clearRecoverableErrors() {
    this._dao.clearRecoverableErrors();
  }
}
