import { Injectable } from '@angular/core';
import {
  AccessTokenRequest,
  PrepopulatedInsuredAccessTokenRequest,
} from '@core/models/auto/quotes/access-token-request.model';
import { Observable } from 'rxjs';
import {
  EuaResponse,
  OauthIframeService,
} from '@core/services/oauth-iframe.service';

@Injectable({
  providedIn: 'root',
})
export class EuaApiAdapter {
  constructor(private _oauthIframeService: OauthIframeService) {}

  createAccessToken(
    request: AccessTokenRequest | PrepopulatedInsuredAccessTokenRequest
  ): Observable<EuaResponse> {
    return this._oauthIframeService.authenticate(request);
  }
}
