import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LendersAndProduct } from '@core/models/lenders/lender.model';

@Injectable()
export class LendersDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  addLender(value: any) {
    this._store.dispatch(new fromActions.AddLender(value));
  }

  addDsmLender(value: any) {
    this._store.dispatch(new fromActions.AddDsmLender(value));
  }

  updateDsmMortgageLender(value: any) {
    this._store.dispatch(new fromActions.UpdateDsmMortgageLender(value));
  }

  getAllLenders(): Observable<LendersAndProduct[]> {
    return this._store.select(fromSelectors.getAllLenders);
  }

  isPayingThroughEscrow(): Observable<boolean> {
    return this._store.select(fromSelectors.isPayingThroughEscrow);
  }

  deleteMortgage(id: string) {
    this._store.dispatch(new fromActions.DeleteLender(id));
  }

  hasNonRentalPropertyMortgage(): Observable<boolean> {
    return this._store.select(fromSelectors.hasNonRentalPropertyMortgage);
  }

  isPayingThroughSingleOrNoEscrow(): Observable<boolean> {
    return this._store.select(fromSelectors.isPayingThroughSingleOrNoEscrow);
  }
}
