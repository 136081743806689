import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { TelematicsVehiclePremium } from '@core/models/telematics/telematics.model';

@Component({
  selector: 'mq-connected-car-enrollment',
  templateUrl: './connected-car-enrollment.component.html',
  styleUrls: ['../../../../../../assets/scss/telematics-panel.scss'],
})
export class ConnectedCarEnrollmentComponent {
  @Input() vehicles: VehicleEntity[];
  @Input() vehiclePremiums: TelematicsVehiclePremium[];
  @Input() initialDiscount: string;
  @Input() maximumDiscount: string;
  @Input() isSRCCForCA: boolean;
  @Output() connectedCarEnrollmentChanged = new EventEmitter<VehicleEntity>();
  headerIcon =
    '<img class="tm-icon" src="assets/images/telematics/connected-car-icon-white.svg" />';

  isNotSRCCForCA(): boolean {
    return this.isSRCCForCA !== undefined ? false : true;
  }
  getDiscounthelpText(): string {
    return this.isSRCCForCA !== undefined
      ? '<strong>How does it work?</strong> Some new cars have built-in connected car technology that can capture mileage. Owners consent to sharing this data with insurers for the opportunity of earning a discount' //for CA
      : '<strong>How does it work?</strong> Some new cars have built-in connected car technology that can capture driving behavior. Owners consent to sharing this data with insurers for the opportunity of earning a discount';
  }
  getLeftSignupContent(): string {
    return this.isSRCCForCA !== undefined
      ? 'Share miles driven as measured by your car' //for CA
      : 'Share driving behavior as measured by your car'; //for others
  }
  changeEnrollment(vehicle: VehicleEntity) {
    this.connectedCarEnrollmentChanged.emit(vehicle);
  }
}
