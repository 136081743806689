import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';
import { SmartHomeHelpText } from '@shared/constants/help-text-constants';
import { UntypedFormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { MetadataService } from '@core/services';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'mq-smarthome',
  templateUrl: './smarthome-property-info.component.html',
  styleUrls: ['./smarthome-property-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SmartHomePropertyInfoComponent implements OnInit, OnChanges {
  smartHomeHelpText: string;
  smartHomelabelText: string;
  smartHomeFieldId: string;
  showSmartHomeDiscount$: Observable<boolean>;
  refreshVisibilityFlag$ = new ReplaySubject<number>(1);

  @Input() form: UntypedFormGroup;
  @Input() smartHomeDiscount: EligibleDiscountEntity;

  constructor(private _metadataService: MetadataService) {}

  ngOnInit(): void {
    this.smartHomeHelpText = SmartHomeHelpText.SmartHome;
    this.smartHomeFieldId = SmartHomeHelpText.assignedFieldId;
    this.smartHomelabelText = SmartHomeHelpText.labelInnerHTML;
    this.showSmartHomeDiscount$ = this.refreshVisibilityFlag$.pipe(
      map(value => !!this.smartHomeDiscount)
    );
  }

  ngOnChanges(): void {
    this.refreshVisibilityFlag$.next();
  }

  get optIn() {
    return this.form.get('optIn');
  }

  openSmartHomeContentWindow() {
    window.open(
      'https://www.nationwide.com/personal/insurance/homeowners/smart-home',
      '_blank'
    );
  }
}
