import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstablishEscrowAccountRequest } from '@core/models/billing/establish-escrow-account.request';
import { EstablishEscrowAccountResponse } from '@core/models/billing/establish-escrow-account.response';
import { Injectable } from '@angular/core';
import { StringUtils } from '@shared/utils/string.utils';
import { BaseDsmAdapter } from './base-dsm.adapter';

@Injectable({
  providedIn: 'root',
})
export class EscrowAdapter extends BaseDsmAdapter {
  establishEscrowAccount(
    request: EstablishEscrowAccountRequest
  ): Observable<EstablishEscrowAccountResponse> {
    const url = this._appConfig.config.escrowServiceUrl;

    const headers = new HttpHeaders()
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', StringUtils.generateUUID())
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${request.accessToken}`);

    const options = {
      headers,
    };

    const { accessToken, ...body } = request;

    return this._http.post(url, body, options)?.pipe(
      map(response => {
        if (this.serviceResponseIsError(response)) {
          throw response;
        }
        return this.composeEstablishEscrowAccountResponse(response);
      })
    );
  }

  serviceResponseIsError(response): boolean {
    if (
      response.setupNewAccountResponse.ReturnInfo.returnSeverity !==
      'Successful'
    ) {
      return true;
    }
    return false;
  }

  composeEstablishEscrowAccountResponse(
    input: any
  ): EstablishEscrowAccountResponse {
    return {
      ...input.setupNewAccountResponse,
    };
  }
}
