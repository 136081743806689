import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import * as fromDaos from './dao';
import * as fromServices from './services';
import * as fromComponents from './components';
import { reducers } from './store';
import { UmbrellaOccupationsComponent } from './components/umbrella-occupations/umbrella-occupations.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('helpContent', reducers),
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [CommonModule, ...fromComponents.components],
  declarations: [...fromComponents.components, UmbrellaOccupationsComponent],
  providers: [],
})
export class HelpContentModule {
  static forRoot(): ModuleWithProviders<HelpContentModule> {
    return {
      ngModule: HelpContentModule,
      providers: [...fromServices.services, ...fromDaos.daos],
    };
  }
}
