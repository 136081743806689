<div class="smartmiles">
  <div *ngIf="SMVehicles?.length">
    <nw-display-panel
      heading="Enrolled in our pay-per-mile program, SmartMiles&reg;"
      [alwaysOpen]="true"
      [openText]="headerIcon(false)"
      class="program-panel"
    >
      <div class="container tm-content-container">
        <p>
          Car insurance for low mileage vehicles where the monthly payment
          varies based on the number of miles driven. How does it work?
        </p>
        <div class="row">
          <div class="col-1">
            <img
              class="tm-icon"
              src="assets/images/smartmiles-device.svg"
              alt="Example of Smartmiles Device"
            />
          </div>
          <div class="col-sm">
            <p class="font-weight-bold">
              Plug a device into your vehicle.
              <mq-inline-help-popup
                [labelInnerHTML]="''"
                [fieldId]="'srcc-SMdevice-fieldId'"
                [helpTextInnerHTML]="' '"
                (onHelp)="getSMdeviceHelpText($event)"
                *ngIf = "shouldOfferSmartMiles_v2()"
              ></mq-inline-help-popup>
              
            </p>

          
          </div>
          <div class="col-1">
            <img
              class="tm-icon smartmiles-image-icon"
              src="assets/images/smartmiles-chart.svg"
              alt="Chart for Tracking Mileage"
            />
          </div>
          <div class="col-sm">
            <p class="font-weight-bold">
              Monthly cost varies based on mileage and driving trends.
              <mq-inline-help-popup
              [labelInnerHTML]="''"
              [fieldId]="'srcc-PaymentHelp-fieldId'"
              [helpTextInnerHTML]="' '"
              (onHelp)="getPaymentHelpText($event)"
              *ngIf = "shouldOfferSmartMiles_v2()"
            ></mq-inline-help-popup>
            </p>
           
          </div>
          <div class="col-sm">
            <mq-telematics-video
              [name]="'SmartMiles'"
              [isDeviceOnlyState]="isDeviceOnlyState"
            ></mq-telematics-video>
          </div>
        </div>
      </div>
      <div class="container row justify-content-center text-center">
        <div class="col-sm-auto col-xs-5">
          <span class="tm-graphic-container">Base rate</span><br />
          (Per month)
        </div>
        <div class="col-sm-auto col-xs-2">
          <span class="tm-graphic-container"><h1>+</h1></span>
        </div>
        <div class="col-sm-auto col-xs-5">
          <span class="tm-graphic-container">Mileage rate</span><br />
          (Cost per <strong>mile</strong> x mileage)
        </div>
        <div class="col-sm-auto col-xs-6">
          <span class="tm-graphic-container"><h1>=</h1></span>
        </div>
        <div class="col-sm-auto col-xs-6 tm-cost-graphic-container">
          Variable<br />
          monthly cost
        </div>
      </div>
      <mq-telematics-vehicle-premium
        [vehicles]="SMVehicles"
        [vehiclePremiums]="vehiclePremiums"
        (enrollmentChanged)="changeEnrollment($event)"
      ></mq-telematics-vehicle-premium>
    </nw-display-panel>
  </div>

  <div *ngIf="SMCCVehicles?.length">
    <nw-display-panel
      heading="Enrolled in SmartMiles&reg; with a connected car"
      [alwaysOpen]="true"
      [openText]="headerIcon(true)"
      class="program-panel"
    >
      <div class="container tm-content-container">
        <p>
          <strong>
            Car insurance for low mileage vehicles where the monthly payment
            varies based on the number of miles driven.
          </strong>
        </p>
        <p>How does it work? Your connected car can share your data</p>
        <div class="row">
          <div class="col-1">
            <img
              class="tm-icon"
              src="assets/images/telematics/no-device-dk-smartMiles.svg"
              alt="Example of Smartmiles connectedCar"
            />
          </div>
          <div class="col-sm">
              <p>
                <strong>Share mileage as measured by your connected car.</strong>
                <inline-help
                  [labelInnerHTML]="''"
                  [fieldId]="'srcc-SMCCdevice-fieldId'"
                  [helpTextInnerHTML]="getSMdeviceHelpText_CC($event)"
                  *ngIf = "shouldOfferSmartMiles_v2()"
                ></inline-help>
              </p> 
          </div>
          <div class="col-1">
            <img
              class="tm-icon smartmiles-image-icon"
              src="assets/images/smartmiles-chart.svg"
              alt="Chart for Tracking Mileage"
            />
          </div>
          <div class="col-sm">
            <p>
              <strong>The payment varies every month, based on miles driven.</strong>
              <inline-help
                [labelInnerHTML]="''"
                [fieldId]="'srcc-SMdevice-fieldId'"
                [helpTextInnerHTML]="getPaymentHelpText_CC($event)"
                *ngIf = "shouldOfferSmartMiles_v2()"
              ></inline-help>
            </p>
            
          </div>
          <div class="col-sm">
            <mq-telematics-video
              [name]="'SmartMiles'"
              [isDeviceOnlyState]="isDeviceOnlyState"
              [dataCollectionMethod]="'ConnectedCar'"
            ></mq-telematics-video>
          </div>
        </div>
      </div>
      <div class="container row justify-content-center text-center">
        <div class="col-sm-auto col-xs-5">
          <span class="tm-graphic-container">Base rate</span><br />
          (Per month)
        </div>
        <div class="col-sm-auto col-xs-2">
          <span class="tm-graphic-container"><h1>+</h1></span>
        </div>
        <div class="col-sm-auto col-xs-5">
          <span class="tm-graphic-container">Mileage rate</span><br />
          (Cost per <strong>mile</strong> x mileage)
        </div>
        <div class="col-sm-auto col-xs-6">
          <span class="tm-graphic-container"><h1>=</h1></span>
        </div>
        <div class="col-sm-auto col-xs-6 tm-cost-graphic-container">
          Variable<br />
          monthly cost
        </div>
      </div>
      <mq-telematics-vehicle-premium
        [vehicles]="SMCCVehicles"
        [vehiclePremiums]="vehiclePremiums"
        (enrollmentChanged)="changeEnrollment($event)"
      ></mq-telematics-vehicle-premium>
    </nw-display-panel>
  </div>
</div>
