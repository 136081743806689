import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';

import { PersonViewModel } from '@core/models/entities/driver.entity';

@Injectable({
  providedIn: 'root',
})
export class PeopleDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getAll(): Observable<PersonViewModel[]> {
    return this._store.select(fromSelectors.getAllPeople);
  }

  isMaritalStatusValid(): Observable<boolean> {
    return this._store.select(fromSelectors.isMaritalStatusValid);
  }

  areMarriedAndSeparatedEvenlyDispersed(): Observable<boolean> {
    return this._store.select(
      fromSelectors.areMarriedAndSeparatedEvenlyDispersed
    );
  }
}
