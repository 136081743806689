<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    Confirmation required
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    Are you sure you want to remove
    <strong>{{ item }}</strong> from your policy?
  </p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="modal.dismiss()"
  >
    Cancel
  </button>
  <button type="button" class="btn btn-danger" (click)="modal.close()">
    Ok
  </button>
</div>
