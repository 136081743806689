import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromActions from '@core/store/actions/driver-vehicle-assignment.action';
import { DriverVehicleAssignmentEntity } from '@core/models/entities/driver-vehicle-assignment.entity';

export interface DriverVehicleAssignmentState
  extends EntityState<DriverVehicleAssignmentEntity> {
  loading: boolean;
  errorCode: string;
}

export const adapter: EntityAdapter<DriverVehicleAssignmentEntity> =
  createEntityAdapter<DriverVehicleAssignmentEntity>({
    selectId: entity => `${entity.driverId}:${entity.vehicleId}`,
  });

export function reducer(
  state = {
    ...adapter.getInitialState(),
    loading: false,
    errorCode: null,
  },
  action: fromActions.DriverVehicleAssignmentActions
): DriverVehicleAssignmentState {
  switch (action.type) {
    case fromActions.DriverVehicleAssignmentActionTypes.SEND_DVA_STORE: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromActions.DriverVehicleAssignmentActionTypes.SEND_DVA_STORE_FAIL: {
      return {
        ...state,
        loading: false,
        errorCode: action.errorCode || 'error',
      };
    }

    case fromActions.DriverVehicleAssignmentActionTypes
      .SEND_DVA_STORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        errorCode: null,
      };
    }

    case fromActions.DriverVehicleAssignmentActionTypes.CLEAR_DVA_STORE: {
      return {
        ...adapter.getInitialState(),
        loading: state.loading,
        errorCode: null,
      };
    }

    case fromActions.DriverVehicleAssignmentActionTypes
      .REASSIGN_DRIVER_SUCCESS: {
      const response = (<fromActions.ReassignDvaDriverSuccess>action).payload;
      const newEntities = _guessPrimaryAssignment(response.entities);
      return adapter.setAll(newEntities, state);
    }

    case fromActions.DriverVehicleAssignmentActionTypes.DVA_REPLACE_STORE: {
      const newEntities = (<fromActions.DvaReplaceStore>action).payload;
      return adapter.setAll(newEntities, state);
    }

    default:
      return state;
  }
}

// Set (isPrimaryDriver) or (isPrimaryVehicle) on any entity which is the only valid choice.
function _guessPrimaryAssignment(
  incoming: DriverVehicleAssignmentEntity[]
): DriverVehicleAssignmentEntity[] {
  // Copy all entities immediately, since it's hard to tell which ones will change.
  incoming = incoming.map(entity => ({ ...entity }));

  const statsByDriverId = {};
  const statsByVehicleId = {};

  for (const entity of incoming) {
    let stats = statsByDriverId[entity.driverId];
    if (!stats) {
      stats = {
        count: 1,
        primaryCount: entity.isPrimaryVehicle ? 1 : 0,
        firstEntity: entity,
      };
      statsByDriverId[entity.driverId] = stats;
    } else {
      stats.count++;
      if (entity.isPrimaryVehicle) {
        stats.primaryCount++;
      }
    }

    stats = statsByVehicleId[entity.vehicleId];
    if (!stats) {
      stats = {
        count: 1,
        primaryCount: entity.isPrimaryDriver ? 1 : 0,
        firstEntity: entity,
      };
      statsByVehicleId[entity.vehicleId] = stats;
    } else {
      stats.count++;
      if (entity.isPrimaryDriver) {
        stats.primaryCount++;
      }
    }
  }

  // eslint-disable-next-line guard-for-in
  for (const driverId in statsByDriverId) {
    const stats = statsByDriverId[driverId];
    if (stats.count === 1) {
      if (!stats.primaryCount) {
        stats.firstEntity.isPrimaryVehicle = true;
      }
    } else if (stats.primaryCount > 1) {
      for (const entity of incoming) {
        // eslint-disable-next-line eqeqeq
        if (entity.driverId == driverId) {
          entity.isPrimaryVehicle = false;
        }
      }
    }
  }
  // eslint-disable-next-line guard-for-in
  for (const vehicleId in statsByVehicleId) {
    const stats = statsByVehicleId[vehicleId];
    if (stats.count === 1) {
      if (!stats.primaryCount) {
        stats.firstEntity.isPrimaryDriver = true;
      }
    } else if (stats.primaryCount > 1) {
      for (const entity of incoming) {
        // eslint-disable-next-line eqeqeq
        if (entity.vehicleId == vehicleId) {
          entity.isPrimaryDriver = false;
        }
      }
    }
  }

  return incoming;
}
