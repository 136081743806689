import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductTypes } from '@shared/constants/app-constants';
import { ProtectiveDevicesRequest } from '@core/store/reducers/protective-devices.reducer';
import { BaseDsmAdapter } from './base-dsm.adapter';
@Injectable({
  providedIn: 'root',
})
export class ProtectiveDevicesAdapter extends BaseDsmAdapter {
  getProtectiveDevices(request: any): Observable<any> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/covered-locations/primary/protective-devices`;
    return this.get(url, { productId: request.productId });
  }

  updateProtectiveDevices(request: ProtectiveDevicesRequest): Observable<any> {
    const url = `${this._getUrlPrefixForProduct(request.product.id)}/quotes/${
      request.product.quoteId
    }/covered-locations/primary/protective-devices`;

    const payload = {
      protectiveDevices: request.devices,
    };

    return this.patch(url, payload, { productId: request.product.id });
  }

  private _getUrlPrefixForProduct(productId) {
    switch (productId) {
      case ProductTypes.HOMEOWNERS: {
        return this._appConfig.config.pcEdgeHomeownersUrl;
      }

      case ProductTypes.CONDO: {
        return this._appConfig.config.pcEdgeCondoUrl;
      }

      case ProductTypes.RENTERS: {
        return this._appConfig.config.pcEdgeRentersUrl;
      }
    }
  }
}
