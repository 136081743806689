import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PolicyholderDao } from '@core/dao/policyholder.dao';

import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { CRUDService } from './crud.service';
import { DsmResetEventService } from './reset.service';
import { McpContactPoint } from '@core/models/modify-contact-point/modify-contact-point-request.model';
import { AddressEntity } from '@core/models/entities/address.entity';

@Injectable({
  providedIn: 'root',
})
export class PolicyholderService
  implements CRUDService<PolicyholderEntity>, DsmResetEventService
{
  constructor(private _dsmPolicyholdersDao: PolicyholderDao) {}

  get(): Observable<PolicyholderEntity> {
    throw new Error('Method not implemented.');
  }

  add(policyholder: PolicyholderEntity): void {
    this._dsmPolicyholdersDao.add(policyholder);
  }

  addTempPolicyholder(policyholder: PolicyholderEntity): void {
    this._dsmPolicyholdersDao.addTempPolicyholder(policyholder);
  }

  addAll(policyholders: PolicyholderEntity[]): void {
    this._dsmPolicyholdersDao.addAll(policyholders);
  }

  update(policyholder: PolicyholderEntity): void {
    this._dsmPolicyholdersDao.update(policyholder);
  }

  updateTempPolicyholder(policyholder: PolicyholderEntity): void {
    this._dsmPolicyholdersDao.updateTempPolicyholder(policyholder);
  }

  updateEvent(entity: PolicyholderEntity): void {
    if (entity.policyHolderId) {
      this._dsmPolicyholdersDao.updateTempPolicyholder(entity);
    }
  }

  delete(policyholderId: string): void {
    this._dsmPolicyholdersDao.delete(policyholderId);
  }

  getAll(): Observable<PolicyholderEntity[]> {
    return this._dsmPolicyholdersDao.getAll();
  }

  getNamedInsured(): Observable<PolicyholderEntity> {
    return this._dsmPolicyholdersDao.getNamedInsured();
  }

  getCurrentNamedInsured(): Observable<PolicyholderEntity> {
    return this._dsmPolicyholdersDao.getCurrentNamedInsured();
  }

  isNamedInsuredMarriedOrSeparated(): Observable<boolean> {
    return this.getNamedInsured().pipe(
      map(
        policyholder =>
          policyholder.person.maritalStatus === 'M' ||
          policyholder.person.maritalStatus === 'P'
      )
    );
  }

  updateContactPoint(contactPoint: McpContactPoint): void {
    this._dsmPolicyholdersDao.updateContactPoint(contactPoint);
  }

  getPolicyHolderType(policyHolder): Observable<string> {
    return this._dsmPolicyholdersDao.getPolicyHolderType(policyHolder);
  }

  countCallsInFlight(): Observable<number> {
    return this._dsmPolicyholdersDao.countCallsInFlight();
  }

  updatePolicyholderDatesOfBirth(
    updates: { policyholderId: string; dateOfBirth: string }[]
  ) {
    for (const update of updates) {
      this._dsmPolicyholdersDao.updatePolicyholderDob(
        update.policyholderId,
        update.dateOfBirth
      );
    }
  }

  getPolicyHolderAddress(): Observable<AddressEntity> {
    return this._dsmPolicyholdersDao.getPolicyHolderAddress();
  }
}
