import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  AbstractControl,
} from '@angular/forms';
import { CustomValidators } from '@core/validators/custom-validators';
import { SearchModel } from '@core/models/search/search.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mq-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormComponent implements OnInit {
  searchForm: UntypedFormGroup;
  @Input() errorMessage = '';

  @Output()
  formSubmit = new EventEmitter<SearchModel>();

  submitButtonText = 'Search';

  constructor(
    private _fb: UntypedFormBuilder,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.buildForm();
    if (this.shouldAutoSubmitOnLoad()) {
      this.onSubmit(this.searchForm);
    }
  }

  private shouldAutoSubmitOnLoad(): boolean {
    if (this._route.snapshot.queryParams['agencyChannel']) {
      if (this._route.snapshot.queryParams['agencyChannel'] === 'NSS') {
        return false;
      }
      return true;
    }
    return false;
  }

  buildForm(): void {
    this.searchForm = this._fb.group({
      lastName: this._fb.control('', []),
      postalCode: this._fb.control('', []),
      phone: new UntypedFormControl('', {}),
      submissionId: this._fb.control('', []),
      timeRange: this._fb.control('', []),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  onSubmit(form: any): void {
    if (form.valid) {
      const searchModel = {} as SearchModel;
      if (form.value.lastName) {
        searchModel.lastName = form.value.lastName;
      } else {
        searchModel.lastName = null;
      }
      if (form.value.postalCode) {
        searchModel.postalCode = form.value.postalCode;
      } else {
        searchModel.postalCode = null;
      }
      if (form.value.phone) {
        searchModel.phoneNumber = form.value.phone;
      } else {
        searchModel.phoneNumber = null;
      }
      if (form.value.timeRange) {
        searchModel.quoteAge = form.value.timeRange;
      } else {
        const quoteAge = 30;
        searchModel.quoteAge = quoteAge;
      }
      searchModel.linesOfBusiness = [
        {
          lineOfBusiness: 'PersonalAuto',
        },
        {
          lineOfBusiness: 'Homeowner',
        },
        {
          lineOfBusiness: 'Tenant',
        },
        {
          lineOfBusiness: 'Condominium',
        },
      ];
      if (this.searchModelOkToSubmit(searchModel)) {
        this.formSubmit.emit(searchModel);
      }
    } else {
      CustomValidators.MarkFormGroupTouched(this.searchForm);
    }
  }

  private searchModelOkToSubmit(model: SearchModel): boolean {
    // Phone number is always ok.
    if (model.phoneNumber) {
      return true;
    }

    // If one of (lastName,postalCode) is present, they must both be.
    if (model.lastName || model.postalCode) {
      if (!model.lastName || !model.postalCode) {
        return false;
      }
      return true;
    }

    // Otherwise it's a timeframe search: Not allowed for NSS.
    if (this._route.snapshot.queryParams['agencyChannel'] === 'NSS') {
      return false;
    }
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  beforeTabChange(event: any): void {
    const lastNameControl = this.searchForm.get('lastName');
    const postalCodeControl = this.searchForm.get('postalCode');
    const phoneControl = this.searchForm.get('phone');
    const timeRangeControl = this.searchForm.get('timeRange');
    const submissionIdControl = this.searchForm.get('submissionId');
    let phoneValidators = [];
    let lastNameValidators = [];
    let postalCodeValidators = [];
    let submissionIdValidators = [];
    let timeRangeValidators = [];

    const logicalId = this.logicalTabIdFromElementId(event.nextId);

    switch (logicalId) {
      case 'phoneNumber':
        phoneValidators = [
          Validators.required,
          CustomValidators.PhoneNumberFormat,
        ];
        lastNameValidators = [];
        postalCodeValidators = [];
        submissionIdValidators = [];
        timeRangeValidators = [];
        this.submitButtonText = 'Search';
        break;
      case 'lastNameZip':
        phoneValidators = [];
        lastNameValidators = [Validators.required, CustomValidators.StringOnly];
        postalCodeValidators = [Validators.required, CustomValidators.ZipCode];
        submissionIdValidators = [];
        timeRangeValidators = [];
        this.submitButtonText = 'Search';
        break;
      case 'timeRange':
        phoneValidators = [];
        lastNameValidators = [];
        postalCodeValidators = [];
        submissionIdValidators = [];
        timeRangeValidators = [Validators.required];
        this.submitButtonText = 'Search';
        break;
    }

    phoneControl.reset('');
    phoneControl.setValidators(phoneValidators);
    phoneControl.updateValueAndValidity();

    lastNameControl.reset('');
    lastNameControl.setValidators(lastNameValidators);
    lastNameControl.updateValueAndValidity();

    postalCodeControl.reset('');
    postalCodeControl.setValidators(postalCodeValidators);
    postalCodeControl.updateValueAndValidity();

    submissionIdControl.reset('');
    submissionIdControl.setValidators(submissionIdValidators);
    submissionIdControl.updateValueAndValidity();

    timeRangeControl.reset(null);
    timeRangeControl.setValidators(timeRangeValidators);
    timeRangeControl.updateValueAndValidity();

    phoneControl.markAsUntouched();
    lastNameControl.markAsUntouched();
    postalCodeControl.markAsUntouched();
    submissionIdControl.markAsUntouched();
    timeRangeControl.markAsUntouched();
  }

  private logicalTabIdFromElementId(elementId: string): string {
    switch (elementId) {
      case 'ngb-nav-0':
        return 'lastNameZip';
      case 'ngb-nav-1':
        return 'phoneNumber';
      case 'ngb-nav-2':
        return 'timeRange';
    }
    return elementId;
  }

  get lastName(): AbstractControl {
    return this.searchForm.get('lastName');
  }

  get postalCode(): AbstractControl {
    return this.searchForm.get('postalCode');
  }

  get phone(): AbstractControl {
    return this.searchForm.get('phone');
  }

  get submissionId(): AbstractControl {
    return this.searchForm.get('submissionId');
  }

  get timeRange(): AbstractControl {
    return this.searchForm.get('timeRange');
  }
}
