import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  ElementRef,
  OnDestroy,
  SimpleChanges,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import {
  requiredFieldValidationKeys,
  fieldValidationMessageMap,
} from '@shared/constants/app-constants';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mq-field-validation-message',
  templateUrl: './field-validation-message.component.html',
  styleUrls: ['./field-validation-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FieldValidationMessageComponent
  implements OnInit, OnChanges, OnDestroy
{
  public carrotDirection: 'none' | 'left' | 'right' | 'top' | 'bottom' = 'none';

  @Input() public form: UntypedFormGroup;
  @Input() public complainAboutRequiredFields = true;
  @Input() public getToastableElementForFormControlName: (string) => any = null;
  @Input() public idNonce = '';

  private formSubscription: Subscription = null;
  public message = '';
  private resizeObserver = null;
  private resize$ = new Subject<void>();
  private focussedControl: AbstractControl = null;
  private unsubscribe = new Subject();
  isHighlightingPanelHeader = false;

  constructor(
    private element: ElementRef,
    private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const RESIZE_EVENT_DEBOUNCE_TIME = 50;
    this.resize$
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(RESIZE_EVENT_DEBOUNCE_TIME)
      )
      .subscribe(_ => this.onResize());
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
      this.formSubscription = null;
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
    if (this.unsubscribe) {
      this.unsubscribe.next();
      this.unsubscribe.complete();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.formSubscription) {
      this.formSubscription.unsubscribe();
      this.formSubscription = null;
    }
    if (this.form && !this.formSubscription) {
      this.formStatusChanged(this.form.status);
      this.formSubscription = this.form.statusChanges.subscribe(status =>
        this.formStatusChanged(status)
      );
    } else if (this.form && changes.complainAboutRequiredFields) {
      this.formStatusChanged(this.form.status);
    }
  }

  private formStatusChanged(status: string, secondTry?: boolean) {
    if (status === 'VALID' || status === 'PENDING') {
      this.hideSelf();
      return;
    }
    const focus = this.findFocus();
    if (!focus) {
      this.hideSelf();
      return;
    }
    // We must show element before moving; otherwise offsetParent is unset.
    this.showSelf();
    if (!this.moveToControl(focus.control)) {
      this.hideSelf();
      // TODO For DVA page at least, form may be updated before the corresponding UI.
      // This timeout works to check again later but it seems dangerous. Is there a smarter way to get notified when we're ready?
      if (!secondTry) {
        const revalidateTimeout = 500;
        window.setTimeout(
          () => this.formStatusChanged(status, true),
          revalidateTimeout
        );
      }
      return;
    }
    this.focussedControl = focus.control;
    this.message = focus.message;
    this.requireResizeObserver();
    this._changeDetector.detectChanges();
  }

  private findFocus(
    parent?: AbstractControl
  ): { control: AbstractControl; message: string } | null {
    if (!parent) {
      parent = this.form;
    }
    const controls = (<any>parent).controls;
    if (!controls) {
      return null;
    }
    for (const controlName of Object.keys(controls)) {
      const control = controls[controlName];
      if (control.status !== 'INVALID') {
        continue;
      }
      if (control.errors) {
        for (const errorKey of Object.keys(control.errors)) {
          const message = this.getMessageForFormError(
            errorKey,
            control.errors[errorKey],
            control
          );
          if (message) {
            return { control, message };
          }
        }
      }
      if (control.controls) {
        const focus = this.findFocus(control);
        if (focus) {
          return focus;
        }
      }
    }
    return null;
  }

  private getMessageForFormError(
    key: string,
    value: any,
    control: AbstractControl
  ): string {
    // Unwrap ngbDate errors.
    if (key === 'ngbDate') {
      for (const subkey of Object.keys(value)) {
        if (value[subkey]) {
          key = subkey;
          value = value[subkey];
          break;
        }
      }
    }

    if (!this.complainAboutRequiredFields) {
      if (requiredFieldValidationKeys.includes(key)) {
        return null;
      }
    }

    const template = fieldValidationMessageMap[key];
    if (!template) {
      return null;
    }

    let message = template;
    const re = /{([^}]*)}/g;
    for (let match; (match = re.exec(template)); ) {
      if (match[1]) {
        message = message.replace(match[0], value[match[1]]);
      } else {
        message = message.replace(match[0], value.toString());
      }
    }

    return message;
  }

  private hideSelf() {
    this.element.nativeElement.classList.remove('visible');
    this.focussedControl = null;
  }

  private showSelf() {
    this.element.nativeElement.classList.add('visible');
  }

  private moveToControl(control: AbstractControl): boolean {
    if (!this.element.nativeElement.offsetParent) {
      return false;
    }
    const element = this.getDomElementForFormControl(control);
    if (!element) {
      return false;
    }
    const [x, y] = this.getFocusPositionForElement(element);
    this.moveToPosition(x, y, element);
    return true;
  }

  private getFocusPositionForElement(element) {
    let bounds = element.getBoundingClientRect();

    this.isHighlightingPanelHeader = false;
    if (!bounds.width && !bounds.height) {
      const accordionHeader = this.findAccordionHeader(element);
      if (accordionHeader) {
        accordionHeader.querySelector('.product-header label').click();
        this.isHighlightingPanelHeader = true;
        element = accordionHeader;
        bounds = element.getBoundingClientRect();
      }
    }

    const [scrollX, scrollY] = this.getWindowScroll();
    const x = scrollX + bounds.left;
    const y = scrollY + bounds.top;
    const halfWidth = Math.floor(bounds.width / 2);
    const halfHeight = Math.floor(bounds.height / 2);
    return [x + halfWidth, y + halfHeight];
  }

  private getWindowScroll() {
    // ah if only: return [window.scrollX, window.scrollY];
    if (window.pageXOffset === undefined) {
      const reference: any =
        document.documentElement || document.body.parentNode || document.body;
      return [reference.scrollLeft, reference.scrollTop];
    } else {
      return [window.pageXOffset, window.pageYOffset];
    }
  }

  private moveToPosition(focusX, focusY, focusElement) {
    const [scrollX, scrollY] = this.getWindowScroll();
    const bounds = this.element.nativeElement.getBoundingClientRect();
    const parentBounds =
      this.element.nativeElement.offsetParent.getBoundingClientRect();
    const parentX = scrollX + parentBounds.left;
    const parentY = scrollY + parentBounds.top;
    const focusElementBounds = focusElement.getBoundingClientRect();
    const focusElementX = window.scrollX + focusElementBounds.left;
    const focusElementHalfHeight = Math.floor(focusElementBounds.height / 2);

    let x = focusX - Math.floor(bounds.width / 2);
    if (x < 0) {
      x = 0;
    }
    x -= parentX;
    this.element.nativeElement.style.left = `${x}px`;

    const verticalSpacing = 5;
    let y = focusY + focusElementHalfHeight + verticalSpacing - parentY;
    if (focusElement.getAttribute('toastposition') === 'above') {
      y =
        focusY -
        focusElementHalfHeight -
        verticalSpacing -
        bounds.height -
        parentY;
      this.carrotDirection = 'bottom';
      this.element.nativeElement.style.top = `${y}px`;
    } else {
      this.carrotDirection = 'top';
      this.element.nativeElement.style.top = `${y}px`;
    }

    this.recookCarrot(
      x + parentX,
      bounds.width,
      focusElementX,
      focusElementBounds.width
    );
  }

  private findAccordionHeader(
    element: any /* HTMLElement */
  ): any /* HTMLElement */ {
    let nwDisplayPanel = element;
    for (; nwDisplayPanel; nwDisplayPanel = nwDisplayPanel.parentElement) {
      if (nwDisplayPanel.tagName === 'NW-DISPLAY-PANEL') {
        return nwDisplayPanel.querySelector('.product-container');
      }
    }
    return null;
  }

  // Set the carrot's horizontal position to align with the field's center.
  private recookCarrot(myX, myW, fieldX, fieldW) {
    const element = this.element.nativeElement.querySelector('.carrot');
    if (!element) {
      return;
    }
    const fieldMidX = fieldX + fieldW / 2;
    const MAX_PERCENT = 100;
    let midPercent = ((fieldMidX - myX) * MAX_PERCENT) / myW;
    if (midPercent < 0) {
      midPercent = 0;
    } else if (midPercent > MAX_PERCENT) {
      midPercent = MAX_PERCENT;
    }
    element.style.left = `calc(${midPercent}% - 0.5em)`;
  }

  private getDomElementForFormControl(
    control: AbstractControl
  ): any /* HTMLElement */ {
    const formControlName = this.getNameForFormControl(control);
    if (!formControlName) {
      return null;
    }

    if (this.getToastableElementForFormControlName) {
      const substituteElement =
        this.getToastableElementForFormControlName(formControlName);
      if (substituteElement) {
        return substituteElement;
      }
    }

    const rootElement = this.getRootElementForDomSearch();
    if (!rootElement) {
      return null;
    }
    let candidate = null;
    for (const element of rootElement.querySelectorAll(
      `*[formcontrolname='${formControlName}'],*[formarrayname='${formControlName}']`
    )) {
      // We have an element that matches formControlName, but that's still not necessarily unique.
      // Check that the values match, and I think that's as close as we can get.
      // TODO This could still return the wrong element, eg "lastName" in a form containing all of the named insureds.
      candidate = element;
      if (element.value === undefined) {
        return element;
      }
      if (
        (element.value || '').toString() === (control.value || '').toString()
      ) {
        return element;
      }
    }
    return candidate;
  }

  private getNameForFormControl(
    control: AbstractControl,
    parent?: UntypedFormGroup
  ): string {
    if (!parent) {
      parent = this.form;
    }
    if (!parent.controls) {
      return null;
    }
    for (const controlName of Object.keys(parent.controls)) {
      if (parent.controls[controlName] === control) {
        return controlName;
      }
    }
    for (const child of Object.values(parent.controls)) {
      const name = this.getNameForFormControl(control, <UntypedFormGroup>child);
      if (name) {
        return name;
      }
    }
    return null;
  }

  private getRootElementForDomSearch(): any /* HTMLElement */ {
    let formElement = this.element.nativeElement;
    while (formElement && formElement.tagName !== 'FORM') {
      formElement = formElement.parentElement;
    }
    if (formElement) {
      return formElement;
    }
    return this.element.nativeElement.ownerDocument;
  }

  private requireResizeObserver() {
    if (this.resizeObserver) {
      return;
    }
    const ResizeObserver = (<any>window).ResizeObserver;
    if (!ResizeObserver) {
      return;
    }
    this.resizeObserver = new ResizeObserver(() => this.resize$.next());
    const body = this.element.nativeElement.ownerDocument.querySelector('body');
    this.resizeObserver.observe(body);
  }

  private onResize() {
    if (this.focussedControl) {
      this.moveToControl(this.focussedControl);
      this._changeDetector.detectChanges();
    }
  }
}
