<ngb-accordion activeIds="moreWaysToSavePanel" class="custom-accordion">
  <ngb-panel id="moreWaysToSavePanel">
    <ng-template ngbPanelTitle>
      <div class="accordion-title" header>
        <mq-accordion-header
          icon="assets/images/CashAward_Blue.svg"
          title="More ways to save"
        ></mq-accordion-header>
      </div>
    </ng-template>

    <ng-template ngbPanelContent>
      <!-- vehicle smartride / self reported miles -->
      <ng-container *ngIf="vehicleDiscounts">
        <div *ngFor="let discount of vehicleDiscounts">
          <div class="d-flex flex-wrap">
            <div class="vehicle">{{ discount.vehicleDescr }}: &nbsp;</div>
            <div class="d-flex" *ngIf="discount.smartride">
              <nw-toggle-pill
                class="discounts-toggle-pill"
                [id]="discount.smartride.vehicleId"
                [label]="
                  getDiscountLabel(discount.smartride.eligibleDiscountId)
                "
                eventName="smartride"
                [showHelp]="discount.smartride.showHelp"
                [defaultValue]="
                  discount.smartride.selectedOptionValue === 'true'
                "
                [trueValue]="true"
                [falseValue]="false"
                [helpContent]="discount.smartride.helpContent"
                (pillToggled)="onPillToggled(discount.smartride, $event)"
              >
              </nw-toggle-pill>
            </div>
            <div class="d-flex" *ngIf="discount.selfreported">
              <nw-toggle-pill
                class="discounts-toggle-pill"
                [id]="discount.selfreported.vehicleId"
                [label]="
                  getDiscountLabel(discount.selfreported.eligibleDiscountId)
                "
                eventName="selfreported"
                [showHelp]="discount.selfreported.showHelp"
                [defaultValue]="
                  discount.selfreported.selectedOptionValue === 'true'
                "
                [trueValue]="true"
                [falseValue]="false"
                [helpContent]="discount.selfreported.helpContent"
                (pillToggled)="onPillToggled(discount.selfreported, $event)"
              >
              </nw-toggle-pill>
            </div>
          </div>
        </div>
        <div class="discount-disclaimer">
          <p>
            <strong *ngIf="isVehicleDiscountsAvailable()"
              >SmartRide and the Self-Reporting Mileage program are optional
              discounts.</strong
            >
            <strong *ngIf="isSmartrideVehicleOnlyDiscountAvailable()"
              >SmartRide is an optional discount.</strong
            >
            <strong>
              By selecting this discount, you agree to install the device and
              keep it installed during the participation period.
            </strong>
          </p>
        </div>
      </ng-container>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
