import { Injectable, Injector } from '@angular/core';
import {
  LoggingService,
  LoadingService,
  ProductsService,
  HomeownersService,
  CondoService,
  RentersService,
  MetadataService,
  AgencyService,
  PrivateLabelService,
  LendersService,
  PolicyService,
  SessionService,
} from '@core/services';
import { QuoteNavigationService } from '@quote/services';
import { Observable } from 'rxjs';
import { HomeownersModel } from '@core/models/homeowners/homeowners.model';
import { CondoModel } from '@core/models/condo/condo.model';
import { CoveredLocationService } from '@core/services/covered-location.service';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { SeparateUnit } from '@core/models/renters/separateunits.model';
import { HomeownerPropertyInfoMetadataService } from '@core/services/homeowner-propertyInfo-metadata.service';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { PolicyholderService } from '@core/services/policyholder.service';
import { RentersModel } from '@core/models/renters/renters.model';
import { RenovationService } from '@core/services/renovations.services';
import { ProtectiveDevicesService } from '@core/services/protective-devices.service';
import { Product } from '@core/models/products/product.model';
import { ProtectiveDevice } from '@core/store/reducers/protective-devices.reducer';
import { LocationExposureService } from '@core/services/location-exposure.service';
import { LocationExposureEntity } from '@core/models/entities/location-exposure.entity';
import { Router, NavigationEnd } from '@angular/router';
import { BindNavigationService } from '@bind/services';
import { QuoteService } from '@core/services/quote.service';
import { ModifiersService } from '@core/services/modifiers.service';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { PropertyInfoMetadataAdapter } from '@core/adapters';
import { RenovationEntity } from '@core/store/reducers/renovation.reducer';
import { PolicyEffectiveDates } from '@core/models/policy/policy-effective-date.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { PartialQuoteService } from '@core/services/partial-quote.service';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PropertyInfoProxy {
  private _navigationService;
  constructor(
    private _injector: Injector,
    private _productsService: ProductsService,
    private _policyHolderService: PolicyholderService,
    private _loggingService: LoggingService,
    private _loadingService: LoadingService,
    private _homeownersService: HomeownersService,
    private _condoService: CondoService,
    private _rentersService: RentersService,
    private _coveredLocationService: CoveredLocationService,
    private _homeownerPicklistService: HomeownerPropertyInfoMetadataService,
    private _renovationService: RenovationService,
    private _protectiveDevicesService: ProtectiveDevicesService,
    private _metadataService: MetadataService,
    private _agencyService: AgencyService,
    private _privateLabelService: PrivateLabelService,
    private _locationExposureService: LocationExposureService,
    private _quoteService: QuoteService,
    private _modifiersService: ModifiersService,
    private _lendersService: LendersService,
    private _propertyMetadataAdapter: PropertyInfoMetadataAdapter,
    private _route: Router,
    private _policyService: PolicyService,
    private _sessionService: SessionService,
    private _partialQuoteService: PartialQuoteService
  ) {
    // HACK.  DO NOT COPY
    _route.events?.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('multi-quote')) {
          this._navigationService = _injector.get<QuoteNavigationService>(
            QuoteNavigationService
          );
        } else if (event.url.includes('multi-bind')) {
          this._navigationService = _injector.get<BindNavigationService>(
            BindNavigationService
          );
        }
      }
    });
  }

  isAppLoading(): Observable<boolean> {
    return this._loadingService.getAppLoading();
  }

  isProductSelectedWithoutError(productType: string): Observable<boolean> {
    return this._productsService.isProductSelectedWithoutError(productType);
  }

  isHomeownersSelected(): Observable<boolean> {
    return this._productsService.isHomeownersSelected();
  }

  updateLocationExposure(location: LocationExposureEntity): void {
    this._locationExposureService.update(location);
  }

  getPrimaryNamedInsured(): Observable<PolicyholderEntity> {
    return this._policyHolderService.getNamedInsured();
  }

  getRenters(): Observable<RentersModel> {
    return this._rentersService.getRenters();
  }

  logPageLoaded(): void {
    this._loggingService.logToSplunk({
      event: 'PROPERTY_INFO_LOAD',
    });
  }

  logPageSubmit(): void {
    this._loggingService.logToSplunk({
      event: 'PROPERTY_INFO_SUBMIT',
    });
  }

  navigateForward(): void {
    if (this._navigationService instanceof BindNavigationService) {
      this._navigationService.dsmUpdateNavigationPath();
    }
    this._navigationService.navigateForward();
  }

  navigateBack(): void {
    this._navigationService.navigateBack();
  }

  getHomeowners(): Observable<HomeownersModel> {
    return this._homeownersService.getHomeowners();
  }

  getCoveredLocation(): Observable<CoveredLocation> {
    return this._coveredLocationService.getCoveredLocation();
  }

  updateCoveredLocation(
    productId: string,
    coveredLocation: CoveredLocation
  ): void {
    this._coveredLocationService.updateCoveredLocation(
      productId,
      coveredLocation
    );
  }

  addHomeowners(value: any): void {
    // TODO: A user could say they have a mortgage, add a mortgage, go back and say they don't have one,
    // and if they said they were paying through escrow they wouldn't have to complete billing
    // not likely but still a bug. Not sure how to handle it.
    // if(value.hasMortgage){
    //   this._lendersService.deleteMortgage();
    // }
    this._homeownersService.addHomeowners(value);
  }

  addCondo(value: any): void {
    this._condoService.addCondo(value);
  }

  getCondo(): Observable<CondoModel> {
    return this._condoService.getCondo();
  }

  updateRenters(renters: RentersModel): void {
    this._rentersService.updateRenters(renters);
  }

  getSeparateUnitsData(): Observable<SeparateUnit[]> {
    return this._rentersService.getSeparateUnitsData();
  }

  getProduct(id: string): Observable<Product> {
    return this._productsService.getProduct(id);
  }

  getNeedConstructionInfo(productId: string): Observable<boolean> {
    return this._productsService.getNeedConstructionInfo(productId);
  }

  getPropertyInfoHomeownerPicklistData(): Observable<any> {
    return this._homeownerPicklistService.getPropertyInfoPicklistData();
  }

  getPropertyInfoCondoPicklistData(): Observable<any> {
    return this._homeownerPicklistService.getPropertyInfoCondoPicklistData();
  }

  getPropertyInfoRentersPicklistData(): Observable<any> {
    return this._homeownerPicklistService.getPropertyInfoRentersPicklistData();
  }

  getRenovationStoreData(): Observable<any> {
    return this._renovationService.getRenovationStateData();
  }

  getStateSpecificFlag(
    name: keyof StateSpecificFlagsObject
  ): Observable<boolean> {
    return this._metadataService.getStateSpecificFlag(name);
  }

  getStateSpecificFlags(): Observable<any> {
    return this._metadataService.getStateSpecificFlagsObject();
  }

  getAllRenovation(): void {
    this._renovationService.dispatchGetAllRenovations();
  }

  addRenovation(request): void {
    this._renovationService.dispatchAddRenovation(request);
  }

  updateRenovations(request): void {
    this._renovationService.dispatchUpdateRenovations(request);
  }

  deleteRenovation(request): void {
    this._renovationService.dispatchDeleteRenovation(request);
  }

  getProtectiveDevices(): Observable<ProtectiveDevice[]> {
    return this._protectiveDevicesService.getProtectiveDevices();
  }

  updateProtectiveDevices(protectiveDevices: ProtectiveDevice[]): void {
    this._protectiveDevicesService.dispatchUpdateProtectiveDevices(
      protectiveDevices
    );
  }

  getSmartHomeDiscount(name, productId): Observable<EligibleDiscountEntity> {
    return this._modifiersService.getSmartHomeDiscount(name, productId);
  }

  dispatchUpdateSmartHomeDiscount(selection: string): void {
    this._modifiersService.dispatchUpdateSmartHomeDiscount(selection);
  }

  updatePolicyLine(request: any): void {
    this._modifiersService.updatePolicyLine(request);
  }

  getMSBHitCoveredLocation(productId: string): void {
    this._coveredLocationService.getMSBHitCoveredLocation(productId);
  }

  isAgent(): Observable<boolean> {
    return this._agencyService.isAgent();
  }

  getContactInformation(): Observable<string> {
    return this._privateLabelService.getContactNumber();
  }

  getLocationExposures(): Observable<LocationExposureEntity[]> {
    return this._locationExposureService.getAll();
  }

  addPickList(response: any): void {
    return this._homeownerPicklistService.addPickList(response);
  }

  addCondoPickList(response: any): void {
    return this._homeownerPicklistService.addCondoPickList(response);
  }

  addRentersPickList(response: any): void {
    return this._homeownerPicklistService.addRentersPickList(response);
  }

  getAllProtectiveDevices(): Observable<ProtectiveDevice[]> {
    return this._protectiveDevicesService.getProtectiveDevices();
  }

  dispatchGetProtectiveDevices(): void {
    this._protectiveDevicesService.dispatchGetProtectiveDevices();
  }

  getSelectedProperty(): Observable<string> {
    return this._productsService.getPropertyProductSelectedWithoutError();
  }

  getProtectiveDevicesOptions(): Observable<any> {
    return this._propertyMetadataAdapter.getProtectiveDeviceOptions();
  }

  getPolicyEffectiveDate(): Observable<PolicyEffectiveDates> {
    return this._policyService.getPolicyEffectiveDate();
  }

  getQuoteState(): Observable<string> {
    return this._sessionService.getQuoteState();
  }

  updateProduct(entity: Product): void {
    this._productsService.updateProduct(entity);
  }

  dispatchPatchQuote(productId: string, request): void {
    this._quoteService.dispatchPatchQuote(productId, request);
  }

  getSessionProgressTrackerEligibility(): Observable<boolean> {
    return this._sessionService.getSessionProgressTrackerEligibility();
  }

  getProgressTrackerFlowName(): Observable<string> {
    return this._sessionService.getProgressTrackerFlowName();
  }

  getPartialQuoteId(): Observable<string> {
    return this._partialQuoteService.getPartialQuoteId();
  }

  removeCurrentPageNavigation(url): void {
    if (this._navigationService instanceof BindNavigationService) {
      this._navigationService.removeCurrentPageNavigation(url);
    }
  }

  skipNextPageNavigation(url): void {
    if (this._navigationService instanceof QuoteNavigationService) {
      this._navigationService.skipNextPageNavigation(url);
    }
  }

  failProduct(productId: string) {
    this._productsService.failProduct(
      productId,
      ErrorHelper.ERR_GENERAL_KNOCKOUT
    );
  }

  navigateIfAnyProductValid() {
    this._productsService
      .getSelectedProductsWithoutErrors()
      .pipe(
        tap(products => {
          if (products.length) {
            this._navigationService.navigateForward();
          }
        }),
        take(1)
      )
      .subscribe();
  }

  isRoofConditionForCondoApplicable(): Observable<boolean> {
    return this._productsService.isRoofConditionForCondoApplicable();
  }

  getPartialQuoteCoveredLocation(): Observable<CoveredLocation> {
    return this._partialQuoteService.getPartialQuoteCoveredLocation();
  }

  isPropertyOnlySelected(): Observable<boolean> {
    return this._productsService.isPropertyOnlySelected();
  }

  failProductWith9996(productId: string): void {
    this._productsService.failProduct(productId, ErrorHelper.ERR_QUOTE_BLOCK);
  }

  softfall(productId: string, errorCode?: string): void {
    return this._productsService.softfall(productId, errorCode);
  }
}
