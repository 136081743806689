import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';

import { from, of, forkJoin } from 'rxjs';
import { switchMap, take, tap, mergeMap, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import {
  LoggingService,
  PreBindDocumentsService,
  LoadingService,
} from '@core/services';

import { ErrorHelper } from '@core/services/helpers/error.helper';
import { ProductTypes } from '@shared/constants/app-constants';
import { PreBindDocumentActionTypes } from '@core/store/actions';

@Injectable()
export class PreBindDocumentEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _preBindDocumentService: PreBindDocumentsService,
    private _loggingService: LoggingService,
    private _loadingService: LoadingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  uploadPreBindDocuments$ = this._actions$.pipe(
    ofType(fromActions.PreBindDocumentActionTypes.UPLOAD_PREBIND_DOCUMENTS),
    switchMap(() =>
      this._store
        .select(
          fromSelectors.buildPreBindDocumentDetailRequest(ProductTypes.AUTO)
        )
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('getPreBindDocumentDetail Request', request)
    ),
    switchMap(request => {
      const individualDocRequests = request.map(reqObj => {
        return this._preBindDocumentService.getPreBindDocument(reqObj);
      });
      return forkJoin(individualDocRequests).pipe(take(1));
    }),
    mergeMap(allPreBindDocuments =>
      this._store
        .select(
          fromSelectors.buildPreBindDocumentUploadRequest(
            ProductTypes.AUTO,
            allPreBindDocuments
          )
        )
        .pipe(take(1))
    ),
    mergeMap(reqObj =>
      this._preBindDocumentService
        .uploadDocuments(reqObj.request, reqObj.accessToken, reqObj.sessionId)
        .pipe(
          take(1),
          tap(response =>
            this._loggingService.log(
              'getPreBindDocuments Upload Response',
              response
            )
          ),
          mergeMap(response =>
            of(
              new fromActions.UploadPreBindDocumentsSuccess(
                reqObj.documents,
                response
              )
            )
          )
        )
    ),
    catchError(error => {
      const safeError = this._errorHelper.sanitizeError(error);
      this._loggingService.log('getPreBindDocuments Error', safeError);
      return from([
        new fromActions.UploadPreBindDocumentsFail(safeError),
        new fromActions.FailProduct(
          ProductTypes.AUTO,
          ErrorHelper.ERR_PREBIND_DOCS
        ),
      ]);
    })
  );

  @Effect()
  resendDocumentUrlEmail$ = this._actions$.pipe(
    ofType(fromActions.PreBindDocumentActionTypes.RESEND_DOCUMENT_URL_EMAIL),
    switchMap(() =>
      this._store
        .select(
          fromSelectors.buildPreBindDocumentEmailRequest(ProductTypes.AUTO)
        )
        .pipe(take(1))
    ),
    tap(request => {
      this._loadingService.loadingBegin();
      this._loggingService.log('resendDocumentUrlEmail', request);
    }),
    switchMap(reqObj =>
      this._preBindDocumentService
        .sendEmail(reqObj.request, reqObj.accessToken, reqObj.sessionId)
        .pipe(
          take(1),
          mergeMap(response => {
            this._loadingService.loadingEnd();
            return of(new fromActions.ResendDocumentUrlEmailSuccess(response));
          })
        )
    ),
    catchError(error => {
      const safeError = this._errorHelper.sanitizeError(error);
      this._loadingService.loadingEnd();
      this._loggingService.log('resendDocumentUrlEmail Error', safeError);
      return of(new fromActions.ResendDocumentUrlEmailFail(safeError));
    })
  );

  @Effect()
  getPowersportsPreBindDocuments$ = this._actions$.pipe(
    ofType(
      fromActions.PreBindDocumentActionTypes.GET_POWERSPORTS_PRE_BIND_DOCUMENTS
    ),
    switchMap(() =>
      this._store
        .select(
          fromSelectors.buildPreBindDocumentDetailRequestPowersports(
            ProductTypes.POWERSPORTS
          )
        )
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log(
        'getPowersportsPreBindDocumentDetail Request',
        request
      )
    ),
    switchMap(request => {
      return this._preBindDocumentService.getPreBindDocuments(request);
    }),
    tap(response =>
      this._loggingService.log(
        'getPowersportsPreBindDocumentDetail Response',
        response
      )
    ),
    mergeMap(response => {
      const preBindDocuments = [];
      preBindDocuments.push(response[0]);
      preBindDocuments.push(response[1]);
      preBindDocuments.push(response[2]);
      return from([
        new fromActions.AddAllPreBindDocuments(preBindDocuments),
        new fromActions.UploadPowersportsPreBindDocuments(),
      ]);
    }),
    catchError(error => {
      const safeError = this._errorHelper.sanitizeError(error);
      this._loggingService.log(
        'getPowersportsPreBindDocuments Error',
        safeError
      );
      return from([
        new fromActions.UploadPreBindDocumentsFail(safeError),
        new fromActions.FailProduct(
          ProductTypes.POWERSPORTS,
          ErrorHelper.ERR_PREBIND_DOCS
        ),
      ]);
    })
  );

  @Effect()
  uploadPowersportsPreBindDocuments$ = this._actions$.pipe(
    ofType(
      fromActions.PreBindDocumentActionTypes
        .UPLOAD_POWERSPORTS_PREBIND_DOCUMENTS
    ),
    switchMap(() =>
      this._store
        .select(
          fromSelectors.buildPreBindDocumentDetailRequest(
            ProductTypes.POWERSPORTS
          )
        )
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log(
        'uploadPowersportsPreBindDocumentDetail Request',
        request
      )
    ),
    switchMap(request => {
      const individualDocRequests = request.map(reqObj => {
        return this._preBindDocumentService.getPreBindDocument(reqObj);
      });
      return forkJoin(individualDocRequests).pipe(take(1));
    }),
    mergeMap(allPreBindDocuments =>
      this._store
        .select(
          fromSelectors.buildPreBindDocumentUploadRequest(
            ProductTypes.POWERSPORTS,
            allPreBindDocuments
          )
        )
        .pipe(take(1))
    ),
    mergeMap(reqObj =>
      this._preBindDocumentService
        .uploadDocuments(reqObj.request, reqObj.accessToken, reqObj.sessionId)
        .pipe(
          take(1),
          tap(response =>
            this._loggingService.log(
              'getPowersportsPreBindDocuments Upload Response',
              response
            )
          ),
          mergeMap(response =>
            of(
              new fromActions.UploadPowersportsPreBindDocumentsSuccess(
                reqObj.documents,
                response
              )
            )
          )
        )
    ),
    catchError(error => {
      const safeError = this._errorHelper.sanitizeError(error);
      this._loggingService.log(
        'upload Powersports PreBindDocuments Error',
        safeError
      );
      return from([
        new fromActions.UploadPowersportsPreBindDocumentsFail(safeError),
        new fromActions.FailProduct(
          ProductTypes.POWERSPORTS,
          ErrorHelper.ERR_PREBIND_DOCS
        ),
      ]);
    })
  );
}
