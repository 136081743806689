import { Action } from '@ngrx/store';
import {
  RetrieveModel,
  HydrateModel,
} from '@core/models/retrieve/retrieve.model';
import { RetrieveFailure } from '@core/models/entities/retrieve.entity';
import {
  BypassFields,
  BypassRetrieveResponse,
} from '@core/models/bypass-retrieve/bypass-retrieve.model';

export enum RetrieveActionTypes {
  ALL_RETRIEVES_FAILED = '[Retrieve] All Retrieves Failed',
  RETRIEVE_QUOTE = '[Retrieve] Retrieve Quote',
  RETRIEVE_QUOTE_SUCCESS = '[Retrieve] Retrieve Quote Success',
  RETRIEVE_QUOTE_FAILURE = '[Retrieve] Retrieve Quote Failure',
  RETRIEVE_QUOTE_CLEAR_FAILURES = '[Retrieve] Retrieve Quote Clear Failures',
  HYDRATE_QUOTE = '[Retrieve] Hydrate Quote',
  HYDRATE_QUOTE_SUCCESS = '[Retrieve] Hydrate Quote Success',
  HYDRATE_QUOTE_FAILURE = '[Retrieve] Hydrate Quote Failure',
  UPDATE_COMPRATER_ID = '[Retrieve] Update CompRater Id',
  UPDATE_REDIRECT_TARGET = '[Retrieve] Update Redirect Target',
  BYPASS_RETRIEVE_QUOTE = '[Retrieve] Bypass Retrieve Quote',
  BYPASS_RETRIEVE_QUOTE_SUCCESS = '[Retrieve] Bypass Retrieve Quote Success',
  BYPASS_RETRIEVE_QUOTE_FAILURE = '[Retrieve] Bypass Retrieve Quote Failure',
  UPDATE_HAS_MORTGAGE_FOR_RETRIEVE = '[Retrieve] Update HasMortgageForRetrieve',
  UPDATE_ESCROW_FOR_RETRIEVE = '[Retrieve] Update EscrowForRetrieve',
}

export class RetrieveQuote implements Action {
  readonly type = RetrieveActionTypes.RETRIEVE_QUOTE;
  constructor(public payload: RetrieveModel) {}
}

export class RetrieveQuoteSuccess implements Action {
  readonly type = RetrieveActionTypes.RETRIEVE_QUOTE_SUCCESS;
  constructor(public payload: any) {}
}

export class RetrieveQuoteFailure implements Action {
  readonly type = RetrieveActionTypes.RETRIEVE_QUOTE_FAILURE;
  constructor(public error: RetrieveFailure) {}
}

export class AllRetrievesFailed implements Action {
  readonly type = RetrieveActionTypes.ALL_RETRIEVES_FAILED;
  constructor() {}
}

export class RetrieveQuoteClearFailures implements Action {
  readonly type = RetrieveActionTypes.RETRIEVE_QUOTE_CLEAR_FAILURES;
  constructor() {}
}

export class HydrateQuote implements Action {
  readonly type = RetrieveActionTypes.HYDRATE_QUOTE;
  constructor(public payload: HydrateModel) {}
}

export class HydrateQuoteSuccess implements Action {
  readonly type = RetrieveActionTypes.HYDRATE_QUOTE_SUCCESS;
  constructor(public payload: any) {}
}

export class HydrateQuoteFailure implements Action {
  readonly type = RetrieveActionTypes.HYDRATE_QUOTE_FAILURE;
  constructor(public payload: any) {}
}

export class UpdateCompRaterId implements Action {
  readonly type = RetrieveActionTypes.UPDATE_COMPRATER_ID;
  constructor(public payload: string) {}
}

export class UpdateRedirectTarget implements Action {
  readonly type = RetrieveActionTypes.UPDATE_REDIRECT_TARGET;
  constructor(public payload: string) {}
}

export class BypassRetrieveQuote implements Action {
  readonly type = RetrieveActionTypes.BYPASS_RETRIEVE_QUOTE;
  constructor(public payload: BypassFields) {}
}

export class BypassRetrieveQuoteSuccess implements Action {
  readonly type = RetrieveActionTypes.BYPASS_RETRIEVE_QUOTE_SUCCESS;
  constructor(public payload: BypassRetrieveResponse) {}
}

export class BypassRetrieveQuoteFailure implements Action {
  readonly type = RetrieveActionTypes.BYPASS_RETRIEVE_QUOTE_FAILURE;
  constructor(public error: any) {}
}

export class UpdateHasMortgageForRetrieve implements Action {
  readonly type = RetrieveActionTypes.UPDATE_HAS_MORTGAGE_FOR_RETRIEVE;
  constructor(public payload: boolean) {}
}

export class UpdateEscrowForRetrieve implements Action {
  readonly type = RetrieveActionTypes.UPDATE_ESCROW_FOR_RETRIEVE;
  constructor(public payload: boolean) {}
}

export type RetrieveActions =
  | RetrieveQuote
  | RetrieveQuoteSuccess
  | RetrieveQuoteFailure
  | RetrieveQuoteClearFailures
  | AllRetrievesFailed
  | HydrateQuote
  | HydrateQuoteSuccess
  | HydrateQuoteFailure
  | UpdateCompRaterId
  | UpdateRedirectTarget
  | BypassRetrieveQuote
  | BypassRetrieveQuoteSuccess
  | BypassRetrieveQuoteFailure
  | UpdateHasMortgageForRetrieve
  | UpdateEscrowForRetrieve;
