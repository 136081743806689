export const TermLifeRiskClass = [
  {
    id: 'Excellent',
    Never: 'Preferred Plus Nontobacco',
    Five: 'Preferred Nontobacco',
    Year: 'Preferred Tobacco',
    Fivepast: 'Preferred Plus Nontobacco',
  },
  {
    id: 'Good',
    Never: 'Preferred Nontobacco',
    Five: 'Preferred Nontobacco',
    Year: 'Preferred Tobacco',
    Fivepast: 'Preferred Nontobacco',
  },
  {
    id: 'Average',
    Never: 'Standard Nontobacco',
    Five: 'Standard Nontobacco',
    Year: 'Standard Tobacco',
    Fivepast: 'Standard Nontobacco',
  },
  {
    id: 'Fair',
    Never: 'Standard Nontobacco',
    Five: 'Standard Nontobacco',
    Year: 'Standard Tobacco',
    Fivepast: 'Standard Nontobacco',
  },
  {
    id: 'Poor',
    Never: 'Ineligible',
    Five: 'Ineligible',
    Year: 'Ineligible',
    Fivepast: 'Ineligible',
  },
];

export const BMIRanges = [
  { height: 60, weightRange: { weightMin: 95, weightMax: 192 } },
  { height: 61, weightRange: { weightMin: 98, weightMax: 198 } },
  { height: 62, weightRange: { weightMin: 102, weightMax: 205 } },
  { height: 63, weightRange: { weightMin: 105, weightMax: 211 } },
  { height: 64, weightRange: { weightMin: 108, weightMax: 218 } },
  { height: 65, weightRange: { weightMin: 112, weightMax: 225 } },
  { height: 66, weightRange: { weightMin: 115, weightMax: 232 } },
  { height: 67, weightRange: { weightMin: 119, weightMax: 239 } },
  { height: 68, weightRange: { weightMin: 122, weightMax: 246 } },
  { height: 69, weightRange: { weightMin: 126, weightMax: 253 } },
  { height: 70, weightRange: { weightMin: 129, weightMax: 261 } },
  { height: 71, weightRange: { weightMin: 133, weightMax: 268 } },
  { height: 72, weightRange: { weightMin: 137, weightMax: 276 } },
  { height: 73, weightRange: { weightMin: 141, weightMax: 284 } },
  { height: 74, weightRange: { weightMin: 145, weightMax: 292 } },
  { height: 75, weightRange: { weightMin: 149, weightMax: 300 } },
  { height: 76, weightRange: { weightMin: 152, weightMax: 308 } },
  { height: 77, weightRange: { weightMin: 157, weightMax: 316 } },
  { height: 78, weightRange: { weightMin: 161, weightMax: 324 } },
  { height: 79, weightRange: { weightMin: 165, weightMax: 332 } },
  { height: 80, weightRange: { weightMin: 169, weightMax: 341 } },
  { height: 81, weightRange: { weightMin: 173, weightMax: 349 } },
  { height: 82, weightRange: { weightMin: 177, weightMax: 358 } },
  { height: 83, weightRange: { weightMin: 182, weightMax: 367 } },
];

export const DEFAULT_RISKCLASS = 'Standard Nontobacco';
export const INCHES_PER_FOOT = 12;
export const BMI_MIN_HEIGHT = 60;
export const BMI_MAX_HEIGHT = 83;

export const PEOPLE_PAGE_TERMLIFE_SUBMIT = 'PEOPLE_PAGE_TERMLIFE_SUBMIT';

export const QUOTE_COVERAGES_TERMLIFE_SUBMIT =
  'QUOTE_COVERAGES_TERMLIFE_SUBMIT';

export const SCHEDULE_CALL_BUTTON_CLICK = 'SCHEDULE_CALL_BUTTON_CLICK';

export const EMAIL_QUOTE_ESTIMATE_CLICK = 'EMAIL_QUOTE_ESTIMATE_CLICK';

/* TO DO Code Refactor move below termlife constants in this file from app-constants.ts

export const TERM_LIFE_HEIGHT_FEET_LENGTH = 1;
export const TERM_LIFE_HEIGHT_FEET_MAX = 9;
export const TERM_LIFE_HEIGHT_FEET_MIN = 1;
export const TERM_LIFE_HEIGHT_INCHES_LENGTH = 2;
export const TERM_LIFE_HEIGHT_INCHES_MAX = 11;
export const TERM_LIFE_HEIGHT_INCHES_MIN = 0;
export const TERM_LIFE_WEIGHT_LENGTH = 3;
export const TERM_LIFE_WEIGHT_MAX = 999;
export const TERM_LIFE_WEIGHT_MIN = 10;
export const TERM_LIFE_DEFAULT_FACE = 100000;

export const TermLifeHealthCodes = {
  Excellent: 'Excellent',
  Good: 'Good',
  Average: 'Average',
  Fair: 'Fair',
  Poor: 'Poor',
};

export const TermLifeNicotineCodes = {
  Never: 'Never used',
  Year: 'Used within the past year',
  Five: 'Used over one year ago but less than 5 years ago',
  Fivepast: 'Used over 5 years ago',
};

export const TermLifeCvgAmtCodes = {
  100000: '$100,000',
  150000: '$150,000',
  200000: '$200,000',
  250000: '$250,000',
  300000: '$300,000',
  350000: '$350,000',
  400000: '$400,000',
  450000: '$450,000',
  500000: '$500,000',
  550000: '$550,000',
  600000: '$650,000',
  650000: '$600,000',
  700000: '$700,000',
  750000: '$750,000',
  800000: '$800,000',
  850000: '$850,000',
  900000: '$900,000',
  950000: '$950,000',
  1000000: '$1,000,000',
};

export const TermLifeYearTerms = {
  'Nationwide 10-year Term GLT': '10 years',
  'Nationwide 15-year Term GLT': '15 years',
  'Nationwide 20-year Term GLT': '20 years',
  'Nationwide 30-year Term GLT': '30 years',
};

export const UrboNumber = {
  'loading-page': 'NPR-1295AO.1 (11/19), NFW-9599AO',
  '/admin/quote-start': 'NPR-1295AO.1 (11/19), NFW-9599AO',
  '/multi-quote/getting-started': 'NPR-1295AO.1 (11/19), NFW-9599AO',
  '/multi-quote/more-details': 'NPR-1295AO.1 (11/19)',
  '/multi-quote/property-info': 'NPR-1295AO.1 (11/19)',
  '/multi-quote/vehicles': 'NPR-1295AO.1 (11/19)',
  '/multi-quote/people': 'NPR-1295AO.1 (11/19), NFW-9600AO',
  '/multi-quote/coverages': 'NPR-1295AO.1 (11/19), NFW-9258AO',
  '/multi-bind/additional-information': 'NPR-1295AO.1 (11/19), NFW-9687AO',
  '/multi-bind/product-details': 'NPR-1295AO.1 (11/19), NFW-9687AO',
  '/multi-bind/coverages': '',
  '/multi-bind/policy-information': '',
  '/multi-payment/billing': '',
  '/multi-payment/summary': '',
};

export const TERM_LIFE_DEFAULT_TEN_YEAR = 'Nationwide 10-year Term GLT';

export const TermLifeConstants = {
  maxHeightFeet: 9,
  maxHeightInches: 11,
  maxWeightChars: 3,
  maxWeightTotal: 999,
  // UMBRELLA
};
export const TERMLIFE_TERM_MONTHS = 12;
*/
