import { ROUTES } from '../../app-routing.module';
import {
  PRIVATE_LABEL_PATH_URL_MAP,
  URL_MAP,
} from '@shared/constants/app-constants';

export class AppRouteUtils {
  static getBaseHref() {
    const paths: string[] = location.pathname.split('/').splice(1, 1);
    const basePath: string = (paths && paths[0]) || ''; // Default: EMPTY
    const host = window.location.host;
    if (PRIVATE_LABEL_PATH_URL_MAP[host] || URL_MAP[host] !== 'prod') {
      return ROUTES.some(route => route.path.includes(basePath))
        ? ''
        : '/' + basePath;
    }
    return '';
  }
}
