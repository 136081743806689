<hr
  *ngIf="package.discounts"
  style="border-top: dashed 1px lightgray; margin-top: 3rem"
/>

<h4 *ngIf="package.discounts && package.discounts.length > 0">
  The {{ name | lowercase }} quote includes these discounts:
</h4>

<div class="row">
  <div class="discount" *ngFor="let discount of package.discounts">
    <a class="icon checkmark"></a>{{ discount.type }}
  </div>
</div>

<h4 class="optional-discounts" *ngIf="name === 'Auto'">
  The {{ name | lowercase }} quote includes this optional discount:
</h4>
<div class="row">
  <div class="col-sm-12">
    <ng-content></ng-content>
  </div>
</div>
<div class="row" *ngIf="name === 'Auto'">
  <div class="discount-disclaimer col-sm-12">
    <p>
      <strong *ngIf="discountRemoved"
        >SmartRide is an optional discount. Removing this discount will result
        in a quote increase.</strong
      >
      <strong *ngIf="!discountRemoved">
        By enrolling, the insured agrees to download and activate the SmartRide
        Mobile app, and receive SmartRide email communication.
      </strong>
    </p>
  </div>
</div>
