import * as fromPolicy from '@core/store/actions/policy.action';

export interface PolicyState {
  initialized: boolean;
  autoEffectiveDate: string;
  homeownersEffectiveDate: string;
  condoEffectiveDate: string;
  rentersEffectiveDate: string;
  baseEffectiveDate: string;
}

export const initialState: PolicyState = {
  initialized: false,
  autoEffectiveDate: null,
  homeownersEffectiveDate: null,
  condoEffectiveDate: null,
  rentersEffectiveDate: null,
  baseEffectiveDate: null,
};

export function reducer(
  state = initialState,
  action: fromPolicy.PolicyActions
): PolicyState {
  switch (action.type) {
    case fromPolicy.SET_POLICY_EFFECTIVE_DATE: {
      return {
        ...state,
        initialized: action.payload.initialized,
        autoEffectiveDate: action.payload.autoEffectiveDate,
        homeownersEffectiveDate: action.payload.homeownersEffectiveDate,
        condoEffectiveDate: action.payload.condoEffectiveDate,
        rentersEffectiveDate: action.payload.rentersEffectiveDate,
        baseEffectiveDate: action.payload.baseEffectiveDate
          ? action.payload.baseEffectiveDate
          : state.baseEffectiveDate,
      };
    }
  }
  return state;
}
