import { Monoline_TFN_Lookup } from '@core/models/TFN_lookup.model';
import { MonolineTFN, UtmMedium } from './app-constants';

export const TFN_Lookup: Monoline_TFN_Lookup[] = [
  {
    utm_medium: UtmMedium.organic,
    productsId: ['1'],
    tfn: MonolineTFN.TFN1,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['1', '7'],
    tfn: MonolineTFN.TFN1,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['1', '6'],
    tfn: MonolineTFN.TFN1,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['1', '6', '7'],
    tfn: MonolineTFN.TFN1,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['2'],
    tfn: MonolineTFN.TFN2,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['2', '7'],
    tfn: MonolineTFN.TFN2,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['2', '6'],
    tfn: MonolineTFN.TFN2,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['2', '6', '7'],
    tfn: MonolineTFN.TFN2,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['3'],
    tfn: MonolineTFN.TFN3,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['3', '7'],
    tfn: MonolineTFN.TFN3,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['3', '6'],
    tfn: MonolineTFN.TFN3,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['3', '6', '7'],
    tfn: MonolineTFN.TFN3,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['4'],
    tfn: MonolineTFN.TFN4,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['4', '7'],
    tfn: MonolineTFN.TFN4,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['4', '6'],
    tfn: MonolineTFN.TFN4,
  },
  {
    utm_medium: UtmMedium.organic,
    productsId: ['4', '6', '7'],
    tfn: MonolineTFN.TFN4,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['1'],
    tfn: MonolineTFN.TFN5,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['1', '7'],
    tfn: MonolineTFN.TFN5,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['1', '6'],
    tfn: MonolineTFN.TFN5,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['1', '6', '7'],
    tfn: MonolineTFN.TFN5,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['2'],
    tfn: MonolineTFN.TFN6,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['2', '7'],
    tfn: MonolineTFN.TFN6,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['2', '6'],
    tfn: MonolineTFN.TFN6,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['2', '6', '7'],
    tfn: MonolineTFN.TFN6,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['3'],
    tfn: MonolineTFN.TFN7,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['3', '7'],
    tfn: MonolineTFN.TFN7,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['3', '6'],
    tfn: MonolineTFN.TFN7,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['3', '6', '7'],
    tfn: MonolineTFN.TFN7,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['4'],
    tfn: MonolineTFN.TFN8,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['4', '7'],
    tfn: MonolineTFN.TFN8,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['4', '6'],
    tfn: MonolineTFN.TFN8,
  },
  {
    utm_medium: UtmMedium.cpc,
    productsId: ['4', '6', '7'],
    tfn: MonolineTFN.TFN8,
  },
  {
    utm_medium: 'else',
    productsId: ['1'],
    tfn: MonolineTFN.TFN9,
  },
  {
    utm_medium: 'else',
    productsId: ['1', '7'],
    tfn: MonolineTFN.TFN9,
  },
  {
    utm_medium: 'else',
    productsId: ['1', '6'],
    tfn: MonolineTFN.TFN9,
  },
  {
    utm_medium: 'else',
    productsId: ['1', '6', '7'],
    tfn: MonolineTFN.TFN9,
  },
  {
    utm_medium: 'else',
    productsId: ['2'],
    tfn: MonolineTFN.TFN10,
  },
  {
    utm_medium: 'else',
    productsId: ['2', '7'],
    tfn: MonolineTFN.TFN10,
  },
  {
    utm_medium: 'else',
    productsId: ['2', '6'],
    tfn: MonolineTFN.TFN10,
  },
  {
    utm_medium: 'else',
    productsId: ['2', '6', '7'],
    tfn: MonolineTFN.TFN10,
  },
  {
    utm_medium: 'else',
    productsId: ['3'],
    tfn: MonolineTFN.TFN11,
  },
  {
    utm_medium: 'else',
    productsId: ['3', '7'],
    tfn: MonolineTFN.TFN11,
  },
  {
    utm_medium: 'else',
    productsId: ['3', '6'],
    tfn: MonolineTFN.TFN11,
  },
  {
    utm_medium: 'else',
    productsId: ['3', '6', '7'],
    tfn: MonolineTFN.TFN11,
  },
  {
    utm_medium: 'else',
    productsId: ['4'],
    tfn: MonolineTFN.TFN12,
  },
  {
    utm_medium: 'else',
    productsId: ['4', '7'],
    tfn: MonolineTFN.TFN12,
  },
  {
    utm_medium: 'else',
    productsId: ['4', '6'],
    tfn: MonolineTFN.TFN12,
  },
  {
    utm_medium: 'else',
    productsId: ['4', '6', '7'],
    tfn: MonolineTFN.TFN12,
  },
  {
    utm_medium: 'email',
    utm_source: 'exacttarget',
    utm_content: 'AAQ00123',
    tfn: MonolineTFN.TFN13,
  },
  {
    utm_medium: 'email',
    utm_source: 'exacttarget',
    utm_content: 'AAQ00223',
    tfn: MonolineTFN.TFN14,
  },
  {
    utm_medium: 'email',
    utm_source: 'exacttarget',
    utm_content: 'AAQ00323',
    tfn: MonolineTFN.TFN15,
  },
  {
    utm_medium: 'email',
    utm_source: 'exacttarget',
    utm_content: 'AAQ00423',
    tfn: MonolineTFN.TFN16,
  },
  {
    utm_medium: 'email',
    utm_source: 'exacttarget',
    utm_content: 'AAQ00523',
    tfn: MonolineTFN.TFN17,
  },
  {
    utm_medium: 'email',
    utm_source: 'exacttarget',
    utm_content: 'AAQ00623',
    tfn: MonolineTFN.TFN18,
  },
  {
    utm_medium: 'email',
    utm_source: 'exacttarget',
    utm_content: 'AAQ00723',
    tfn: MonolineTFN.TFN19,
  },
  {
    utm_medium: 'email',
    utm_source: 'exacttarget',
    utm_content: 'AAQ00823',
    tfn: MonolineTFN.TFN20,
  },
];
