import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromLenders from '@core/store/reducers/lenders.reducer';
import * as fromNonRentalProperty from '@core/store/selectors/non-rental-property.selector';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromRetrieve from '@core/store/selectors/retrieve.selector';

import {
  LendersAndProduct,
  LendersModel,
} from '@core/models/lenders/lender.model';
import { ProductTypes } from '@shared/constants/app-constants';
import { Product } from '@core/models/products/product.model';

export const getLendersState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.lenders
);

export const getLendersEntities = createSelector(
  getLendersState,
  fromLenders.getLendersState,
  lenders => lenders.entities
);

export const getMortgageEntityById = (id: string) =>
  createSelector(getLendersEntities, entities => {
    let mortgage;
    Object.keys(entities).forEach(entity => {
      if (entities[entity].mortgage.mortgageId === id) {
        mortgage = entities[entity];
      }
    });

    return mortgage;
  });

export const getAllLenders = createSelector(
  getLendersEntities,
  fromNonRentalProperty.getNonRentalProperty,
  fromProducts.getSelectedProductsWithoutErrors,
  (lenders, nonRentalProperty, selectedProducts) => {
    if (!selectedProducts) {
      return null;
    }
    const nonRentalPropertySelected = selectedProducts.find(
      product =>
        product.id === ProductTypes.HOMEOWNERS ||
        product.id === ProductTypes.CONDO
    );

    const lendersAndProducts: LendersAndProduct[] = [];

    if (nonRentalPropertySelected) {
      Object.keys(lenders).forEach(index => {
        if (lenders[index].mortgage) {
          lendersAndProducts.push({
            lender: lenders[index],
            product: nonRentalProperty,
          });
        }
      });
    }

    return lendersAndProducts.filter(lender => lender !== undefined);
  }
);

export const isPayingThroughEscrow = createSelector(
  getAllLenders,
  fromProducts.getSelectedProductsWithoutErrors,
  (lenders: LendersAndProduct[], products: Product[]) => {
    if (!lenders) {
      return false;
    }
    if (lenders != null && lenders.length < 1) {
      return false;
    }
    if (!products) {
      return null;
    }
    return products.find(
      p => p.id === ProductTypes.HOMEOWNERS || p.id === ProductTypes.CONDO
    )
      ? lenders.filter(
          lender => lender.lender.mortgage && lender.lender.mortgage.escrow
        ).length > 0
      : false;
  }
);

export const isPayingThroughEscrowFromOverall = createSelector(
  isPayingThroughEscrow,
  fromRetrieve.getEscrowForRetrieve(),
  (isPayingThroughEscrow, isPayingThroughEscrowFromRetrieve) => {
    if (isPayingThroughEscrow || isPayingThroughEscrowFromRetrieve) {
      return true;
    } else return false;
  }
);

export const isEscrowOnlyPaymentMethod = createSelector(
  isPayingThroughEscrow,
  fromProducts.getPurchasableSelectedBindProductsWithoutErrors,
  (isPayingThroughEscrow: boolean, products: Product[]) => {
    return isPayingThroughEscrow && products.length === 1;
  }
);

// TODO make producttype dynamic for condo
export const buildMortgageRequest = (lender: LendersModel, productId: string) =>
  createSelector(
    fromProducts.getProduct(productId),
    fromTokens.getAccessToken(productId),
    (product, accessToken) => {
      return {
        productId: product.id,
        quoteId: product.quoteId,
        accessToken,
        lender,
      };
    }
  );

export const hasNonRentalPropertyMortgage = createSelector(
  fromNonRentalProperty.getNonRentalProperty,
  fromProducts.getSelectedProductsWithoutErrors,
  (nonRentalProperty, selectedProducts) => {
    return (
      selectedProducts.filter(
        product =>
          (product.id === ProductTypes.HOMEOWNERS ||
            product.id === ProductTypes.CONDO) &&
          nonRentalProperty.hasMortgage
      ).length > 0
    );
  }
);

export const isPayingThroughSingleOrNoEscrow = createSelector(
  getAllLenders,
  lenders => {
    let escrowTotalCount = 0;
    lenders.forEach(lender => {
      const escrowCount = lender.lender.mortgage.escrow ? 1 : 0;
      escrowTotalCount += escrowCount;
    });
    return escrowTotalCount < 2;
  }
);
