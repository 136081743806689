import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EligibleDiscountRequest } from '@core/models/auto/modifiers/modifier-request.model';
import { EligibleDiscountResponse } from '@core/models/auto/modifiers/modifier-response.model';
import { ProductTypes } from '@shared/constants/app-constants';
import { BaseDsmAdapter } from './base-dsm.adapter';

@Injectable({
  providedIn: 'root',
})
export class ModifiersAdapter extends BaseDsmAdapter {
  updateDriverDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/drivers/${request.modelId}/eligible-discounts/${
      request.eligibleDiscount.eligibleDiscountId
    }`;

    return this.put(
      url,
      {
        eligibleDiscount: request.eligibleDiscount,
      },
      {
        productId: request.productId,
      }
    );
  }

  updatePowersportsDriverDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/drivers/${request.modelId}/eligible-discounts/${
      request.eligibleDiscount.eligibleDiscountId
    }`;

    return this.put(
      url,
      {
        eligibleDiscount: request.eligibleDiscount,
      },
      {
        productId: request.productId,
      }
    );
  }

  updateSmartHomeDiscount(request: EligibleDiscountRequest): Observable<any> {
    const url = `${this._appConfig.config.pcEdgeHomeownersUrl}/quotes/${request.quoteId}/eligible-discounts/SmartDevice`;
    const payload = {
      eligibleDiscount: {
        eligibleDiscountId: request.eligibleDiscount.eligibleDiscountId,
        selectedOptionValue: request.eligibleDiscount.selectedOptionValue,
      },
    };
    return this.put(url, payload, { productId: request.productId });
  }

  updatePolicyLine(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/eligible-discounts/${request.name}`;

    return this.put<EligibleDiscountResponse>(
      url,
      {
        eligibleDiscount: request.eligibleDiscount,
      },
      {
        productId: request.productId,
      }
    ).pipe(
      map(response => {
        return {
          ...response,
          productId: request.productId,
          modelId: request.quoteId,
        };
      })
    );
  }

  getPolicyLine(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse[]> {
    if (!this.productSupportsPolicyLineDiscounts(request.productId)) {
      return of([]);
    }
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/eligible-discounts`;

    return this.get<EligibleDiscountResponse[]>(url, {
      productId: request.productId,
    }).pipe(
      map(response =>
        response.map(res => {
          res.productId = request.productId;
          res.modelId = request.quoteId;
          return res;
        })
      )
    );
  }

  productSupportsPolicyLineDiscounts(productId: string): boolean {
    switch (productId) {
      case ProductTypes.UMBRELLA:
        return false;
    }
    return true;
  }

  getVehicleDiscounts(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse[]> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/vehicles/${request.vehicleId}/eligible-discounts`;

    return this.get<EligibleDiscountResponse[]>(url, {
      productId: request.productId,
    }).pipe(
      map(response =>
        response.map(res => {
          res.productId = request.productId;
          res.modelId = request.quoteId;
          res.vehicleId = request.vehicleId;
          return res;
        })
      )
    );
  }

  updateVehicleDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/vehicles/${request.vehicleId}/eligible-discounts/${request.name}`;

    return this.put<EligibleDiscountResponse>(
      url,
      {
        eligibleDiscount: request.eligibleDiscount,
      },
      {
        productId: request.productId,
      }
    ).pipe(
      map(response => {
        return {
          ...response,
          productId: request.productId,
          modelId: request.quoteId,
          vehicleId: request.vehicleId,
        };
      })
    );
  }

  updatePowersportsVehicleDiscount(
    request: EligibleDiscountRequest
  ): Observable<EligibleDiscountResponse> {
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/vehicles/${request.vehicleId}/eligible-discounts/${request.name}`;

    return this.put<EligibleDiscountResponse>(
      url,
      {
        eligibleDiscount: request.eligibleDiscount,
      },
      {
        productId: request.productId,
      }
    ).pipe(
      map(response => {
        return {
          ...response,
          productId: request.productId,
          modelId: request.quoteId,
          vehicleId: request.vehicleId,
        };
      })
    );
  }

  private _getUrlPrefixForProduct(productId) {
    switch (productId) {
      case ProductTypes.AUTO: {
        return this._appConfig.config.pcEdgeAutoUrl;
      }

      case ProductTypes.HOMEOWNERS: {
        return this._appConfig.config.pcEdgeHomeownersUrl;
      }

      case ProductTypes.CONDO: {
        return this._appConfig.config.pcEdgeCondoUrl;
      }

      case ProductTypes.RENTERS: {
        return this._appConfig.config.pcEdgeRentersUrl;
      }

      case ProductTypes.UMBRELLA: {
        return this._appConfig.config.pcEdgeUmbrellaUrl;
      }

      case ProductTypes.POWERSPORTS: {
        return this._appConfig.config.pcEdgePowersportsUrl;
      }
    }
  }
}
