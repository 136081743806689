import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DisplayPanelService {
  // private _opened = new BehaviorSubject<boolean>(false);
  // opened = this._opened.asObservable();

  // private _formValid = new BehaviorSubject<boolean>(false);
  // formValid = this._formValid.asObservable();

  private _opened: BehaviorSubject<boolean>;
  opened: Observable<boolean>;

  private _formValid: BehaviorSubject<boolean>;
  formValid: Observable<boolean>;

  constructor() {
    this._opened = new BehaviorSubject<boolean>(false);
    this.opened = this._opened.asObservable();

    this._formValid = new BehaviorSubject<boolean>(false);
    this.formValid = this._formValid.asObservable();
  }

  next(value: boolean) {
    this._opened.next(value);
  }

  toggle() {
    const state = this._opened.getValue();
    this._opened.next(!state);
  }

  fvNext(value: boolean) {
    this._formValid.next(value);
  }
}
