import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserContext } from '@core/models/user-context/user-context.model';
import { UserContextDao } from '@core/dao/user-context.dao';

@Injectable({ providedIn: 'root' })
export class UserContextService {
  constructor(private _userContextDao: UserContextDao) {}

  updateUserContext(userContext: UserContext): void {
    this._userContextDao.updateUserContext(userContext);
  }

  getUserContext(): Observable<UserContext> {
    return this._userContextDao.getUserContext();
  }

  getUserContextLoaded(): Observable<boolean> {
    return this._userContextDao.getUserContextLoaded();
  }

  isQuoteRetrieve(): Observable<boolean> {
    return this._userContextDao.isQuoteRetrieve();
  }

  isCompRater(): Observable<boolean> {
    return this._userContextDao.isCompRater();
  }

  isPrivateLabelPreview(): Observable<boolean> {
    return this._userContextDao.isPrivateLabelPreview();
  }
}
