import { Observable, throwError, of } from 'rxjs';
import { retryWhen, delay, mergeMap, take } from 'rxjs/operators';

const DEFAULT_MAX_RETRIES = 3;
const RETRY_STATUS = 0;

export function retryOnStatus0(
  delayMs: number,
  maxRetry = DEFAULT_MAX_RETRIES
) {
  let retries = maxRetry;

  return (src: Observable<any>) =>
    src.pipe(
      retryWhen(error => {
        return error.pipe(
          mergeMap((error: any) => {
            if (error.status === RETRY_STATUS && !(--retries <= 0)) {
              return of(error.status).pipe(delay(delayMs));
            }
            return throwError(error);
          }),
          take(retries)
        );
      })
    );
}
