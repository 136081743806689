import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { tap, take, filter, switchMap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { PreBindDocumentsService } from '@core/services/pre-bind-documents.service';

@Injectable()
export class PreBindDocumentsGuard implements CanActivate {
  constructor(private _preBindDocumentsService: PreBindDocumentsService) {}

  canActivate(): Observable<boolean> {
    return this._preBindDocumentsService
      .arePrebindDocumentsAcknowledgementRequired()
      .pipe(
        take(1),
        switchMap(required => {
          if (required) {
            return this.checkStore();
          }
          return of(true);
        }),
        catchError(() => of(false))
      );
  }

  checkStore(): Observable<boolean> {
    return this._preBindDocumentsService.getPreBindDocumentsUploaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._preBindDocumentsService.dispatchUploadPreBindDocuments();
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
