<div class="modal-body">
  <p>
    If we receive driving data or personal information with your consent, we will retain this
    information as part of your quote or policy records pursuant to our
    <a
      href="http://www.nationwide.com/privacy-security.jsp"
      target="_blank"
      rel="noopener"
      >Privacy Policy</a
    >. Participating partners include: Acura, Genesis, GMC, Buick, Cadillac,
    Chevrolet, Honda, Hyundai, and Toyota's affiliate, Connected Analytic
    Services, LLC ("CAS").
  </p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Close click')"
  >
    Close
  </button>
</div>
