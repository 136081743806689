import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ProtectiveDevicesService } from '@core/services/protective-devices.service';

import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class ProtectiveDevicesGuard implements CanActivate {
  constructor(private _protectiveDevicesService: ProtectiveDevicesService) {}
  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this._protectiveDevicesService.getProtectiveDevicesLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._protectiveDevicesService.dispatchGetProtectiveDevices();
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
