<ng-container *ngIf="!selectedProduct.hasError">
  <div>
    <p
      class="quote-letter-link"
      *ngIf="selectedProducts.length && isFormValid && isQuoteValid"
    >
      <button
        id="quoteLetter"
        class="btn btn-primary"
        (click)="getQuoteLetterPdf()"
      >
        View Quote (PDF)
      </button>
    </p>
    <div class="term-life-text" *ngIf="lifeProductIsSelected">
      (Term Life premium not included)
    </div>
  </div>
</ng-container>
