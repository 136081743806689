import { Action } from '@ngrx/store';
import { PrivateLabel } from '@core/models/private-label/private-label.model';

export const LOAD_PRIVATE_LABEL_CONFIGURATION =
  '[PRIVATE LABEL] Load Private Label Configuration';
export const LOAD_PRIVATE_LABEL_CONFIGURATION_FAIL =
  '[PRIVATE LABEL] Load Private Label Configuration Fail';
export const LOAD_PRIVATE_LABEL_CONFIGURATION_SUCCESS =
  '[PRIVATE LABEL] Load Private Label Configuration Success';
export const SET_CONSENT_CHECKED_VALUES =
  '[PRIVATE LABEL] Set Consent Checked Values';

export class LoadPrivateLabelConfiguration implements Action {
  readonly type = LOAD_PRIVATE_LABEL_CONFIGURATION;
  constructor(public payload?: string) {}
}

export class LoadPrivateLabelConfigurationFail implements Action {
  readonly type = LOAD_PRIVATE_LABEL_CONFIGURATION_FAIL;
  constructor(public payload: any) {}
}

export class LoadPrivateLabelConfigurationSuccess implements Action {
  readonly type = LOAD_PRIVATE_LABEL_CONFIGURATION_SUCCESS;
  constructor(public payload: PrivateLabel) {}
}

export type PrivateLabelActions =
  | LoadPrivateLabelConfiguration
  | LoadPrivateLabelConfigurationFail
  | LoadPrivateLabelConfigurationSuccess;
