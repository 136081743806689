import { Injectable } from '@angular/core';
import { KafkaAdapter } from '@core/adapters/kafka.adapter';

@Injectable()
export class KafkaService {
  constructor(private _kafkaAdapter: KafkaAdapter) {}

  publish(messages: string[], sessionId: string): void {
    this._kafkaAdapter.publish(messages, sessionId);
  }
}
