import { Injectable } from '@angular/core';
import { DriverVehicleAssignmentDao } from '@core/dao/driver-vehicle-assignment.dao';
import { take, tap } from 'rxjs/operators';
import { VehicleService } from './vehicle.service';
import { ReassignDvaDriverRequest } from '@core/models/auto/driver-vehicle-assignment.model';
import { Observable, combineLatest } from 'rxjs';
import { DriverDao } from '@core/dao/driver.dao';
import { VehicleDao } from '@core/dao/vehicle.dao';
import { DvaCallStatus } from '@core/store';
import { DriverVehicleAssignmentEntity } from '@core/models/entities/driver-vehicle-assignment.entity';

@Injectable({
  providedIn: 'root',
})
export class DriverVehicleAssignmentService {
  constructor(
    private _dao: DriverVehicleAssignmentDao,
    private _vehicleService: VehicleService,
    private _driverDao: DriverDao,
    private _vehicleDao: VehicleDao
  ) {}

  getAll(): Observable<DriverVehicleAssignmentEntity[]> {
    return this._dao.getEntireStore();
  }

  replaceEntireStore(entities: DriverVehicleAssignmentEntity[]): void {
    this._dao.replaceEntireStore(entities);
  }

  reassignDriverInStore(
    driverId: string,
    vehicleId: string,
    primary: boolean
  ): void {
    const request: ReassignDvaDriverRequest = {
      driverId: driverId,
      vehicleIds: [vehicleId],
      primaryVehicleId: primary ? vehicleId : null,
    };
    this._dao.reassignDriverInStore(request);
  }

  sendStore(): void {
    this._dao.sendStore();
  }

  /* This unpleasant business will become unnecessary if DSM accepts Update Driver before DVA.
   * At present they can not, due to a QPC call in Update Driver.
   */
  defaultStoreToBackendIfUnset() {
    combineLatest([
      this._driverDao.getAll(),
      this._vehicleDao.getAll(),
      this._dao.getEntireStore(),
    ])
      .pipe(
        take(1),
        tap(([drivers, vehicles, store]) => {
          // If the store's shape matches the existing objects, do nothing.
          const driversInStore = new Set(store.map(a => a.driverId));
          const vehiclesInStore = new Set(store.map(a => a.vehicleId));
          if (
            driversInStore.size === drivers.length &&
            vehiclesInStore.size === vehicles.length
          ) {
            return;
          }

          // Walk drivers and vehicles together, looping the shorter one, until each object has been visited.
          const assignments = [];
          let driverIndex = 0;
          let vehicleIndex = 0;
          for (
            ;
            driverIndex < drivers.length || vehicleIndex < vehicles.length;
            driverIndex++, vehicleIndex++
          ) {
            const driver = drivers[driverIndex % drivers.length];
            const vehicle = vehicles[vehicleIndex % vehicles.length];
            const assignment = {
              driverId: driver.driverId,
              vehicleId: vehicle.vehicleId,
              isPrimaryDriver: vehicleIndex < vehicles.length,
              isPrimaryVehicle: driverIndex < drivers.length,
              autoGeneratedAssignment: true,
            };
            assignments.push(assignment);
          }

          this._dao.replaceEntireStore(assignments);
          this._dao.sendStore();
        })
      )
      .subscribe();
  }

  removeAutoGeneratedAssignments(): void {
    this._dao.removeAutoGeneratedAssignments();
  }

  getLoading(): Observable<boolean> {
    return this._dao.getLoading();
  }

  getErrorCode(): Observable<string> {
    return this._dao.getErrorCode();
  }

  getCallStatus(): Observable<DvaCallStatus> {
    return this._dao.getCallStatus();
  }

  clearStore(): void {
    this._dao.clearStore();
  }
}
