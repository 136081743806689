import { Action } from '@ngrx/store';
import { UserContext } from '@core/models/user-context/user-context.model';

export const LOAD_USER_CONTEXT = '[USER CONTEXT] Load User Context';
export const LOAD_USER_CONTEXT_FAIL = '[USER CONTEXT] Load User Context Fail';
export const LOAD_USER_CONTEXT_SUCCESS =
  '[USER CONTEXT] Load User Context Success';
export const UPDATE_USER_CONTEXT = '[USER CONTEXT] Update User Context';

export class LoadUserContext implements Action {
  readonly type = LOAD_USER_CONTEXT;
}

export class LoadUserContextSuccess implements Action {
  readonly type = LOAD_USER_CONTEXT_SUCCESS;
  constructor(public payload: UserContext) {}
}

export class LoadUserContextFail implements Action {
  readonly type = LOAD_USER_CONTEXT_FAIL;
  constructor(public payload: UserContext) {}
}

export class UpdateUserContext implements Action {
  readonly type = UPDATE_USER_CONTEXT;
  constructor(public payload: UserContext) {}
}

export type UserContextActions =
  | LoadUserContext
  | LoadUserContextFail
  | LoadUserContextSuccess
  | UpdateUserContext;
