import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';

import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromServices from '@core/services';
import * as fromActions from '@core/store/actions';
import { MetadataAdapter } from '@core/adapters';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable()
export class MetadataEffects {
  constructor(
    private _actions$: Actions,
    private _metadataService: fromServices.MetadataService,
    private _metadataAdapter: MetadataAdapter,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  loadSuffix$ = this._actions$.pipe(
    ofType(fromActions.LOAD_SUFFIX),
    switchMap(() => {
      return this._metadataService.getSuffix().pipe(
        map(suffix => new fromActions.LoadSuffixSuccess(suffix['suffix'])),
        catchError(error =>
          of(
            new fromActions.LoadSuffixFail(
              this._errorHelper.sanitizeError(error)
            )
          )
        )
      );
    })
  );

  @Effect()
  loadMaritalStatus$ = this._actions$.pipe(
    ofType(fromActions.LOAD_MARITAL_STATUS),
    switchMap(() => {
      return this._metadataService.getMaritalStatus().pipe(
        map(
          suffix =>
            new fromActions.LoadMaritalStatusSuccess(suffix['maritalStatus'])
        ),
        catchError(error =>
          of(
            new fromActions.LoadMaritalStatusFail(
              this._errorHelper.sanitizeError(error)
            )
          )
        )
      );
    })
  );

  @Effect()
  loadStateSpecificFlags$ = this._actions$.pipe(
    ofType(fromActions.LOAD_STATE_SPECIFIC_FLAGS),
    switchMap(() => {
      return this._metadataAdapter.getStateSpecificFlags();
    }),
    map(response => {
      return new fromActions.LoadStateSpecificFlagsSuccess(response);
    }),
    catchError(error => {
      return of(
        new fromActions.LoadStateSpecificFlagsFail(
          this._errorHelper.sanitizeError(error)
        )
      );
    })
  );

  @Effect()
  loadStateAbbreviations$ = this._actions$.pipe(
    ofType(fromActions.LOAD_STATE_ABBREVIATIONS),
    switchMap(() => {
      return this._metadataService.getStateAbbreviations().pipe(
        map(
          suffix =>
            new fromActions.LoadStateAbbreviationsSuccess(suffix['states'])
        ),
        catchError(error =>
          of(
            new fromActions.LoadStateAbbreviationsFail(
              this._errorHelper.sanitizeError(error)
            )
          )
        )
      );
    })
  );

  @Effect()
  loadActiveStateAbbreviations$ = this._actions$.pipe(
    ofType(fromActions.LOAD_ACTIVE_STATE_ABBREVIATIONS),
    switchMap(() => {
      return this._metadataService.getStateAbbreviations().pipe(
        map(
          suffix =>
            new fromActions.LoadActiveStateAbbreviationsSuccess(
              suffix['active']
            )
        ),
        catchError(error =>
          of(
            new fromActions.LoadActiveStateAbbreviationsFail(
              this._errorHelper.sanitizeError(error)
            )
          )
        )
      );
    })
  );

  @Effect()
  loadAgencyChannel$ = this._actions$.pipe(
    ofType(fromActions.LOAD_AGENCY_CHANNEL),
    switchMap(() => {
      return this._metadataService.getAgencyChannel().pipe(
        map(
          suffix =>
            new fromActions.LoadAgencyChannelSuccess(suffix['agencyChannel'])
        ),
        catchError(error =>
          of(
            new fromActions.LoadAgencyChannelFail(
              this._errorHelper.sanitizeError(error)
            )
          )
        )
      );
    })
  );

  @Effect()
  loadFeatures$ = this._actions$.pipe(
    ofType(fromActions.LOAD_FEATURES),
    switchMap(() => {
      return this._metadataService.getFeatures().pipe(
        map(features => new fromActions.LoadFeaturesSuccess(features)),
        catchError(error =>
          of(
            new fromActions.LoadFeaturesFail(
              this._errorHelper.sanitizeError(error)
            )
          )
        )
      );
    })
  );

  @Effect()
  loadConnectedCarCriteria$ = this._actions$.pipe(
    ofType(fromActions.LOAD_CONNECTED_CAR_CRITERIA),
    map((action: fromActions.LoadConnectedCarCriteria) => action.payload),
    switchMap(identifier => {
      return this._metadataService.getConnectedCarCriteria().pipe(
        map(
          criteria =>
            new fromActions.LoadConnectedCarCriteriaSuccess(
              criteria['data'].filter(data =>
                data['identifiers'].includes(identifier)
              )
            )
        ),
        catchError(error =>
          of(
            new fromActions.LoadConnectedCarCriteriaFail(
              this._errorHelper.sanitizeError(error)
            )
          )
        )
      );
    })
  );

  @Effect()
  loadAntiTheftDevices$ = this._actions$.pipe(
    ofType(fromActions.LOAD_ANTI_THEFT_DEVICES),
    switchMap(_ => this._metadataService.loadAntiTheftDevices()),
    map(response => new fromActions.LoadAntiTheftDevicesSuccess(response)),
    catchError(error =>
      of(
        new fromActions.LoadAntiTheftDevicesFail(
          this._errorHelper.sanitizeError(error)
        )
      )
    )
  );

  @Effect()
  loadVehicleEligibleDiscounts$ = this._actions$.pipe(
    ofType(fromActions.LOAD_VEHICLE_ELIGIBLE_DISCOUNTS),
    switchMap(_ => this._metadataService.loadVehicleEligibleDiscounts()),
    map(
      response => new fromActions.LoadVehicleEligibleDiscountsSuccess(response)
    ),
    catchError(error =>
      of(
        new fromActions.LoadVehicleEligibleDiscountsFail(
          this._errorHelper.sanitizeError(error)
        )
      )
    )
  );

  @Effect()
  loadVehiclePrimaryUseOptions$ = this._actions$.pipe(
    ofType(fromActions.LOAD_VEHICLE_PRIMARY_USE_OPTIONS),
    switchMap(_ => this._metadataService.loadVehiclePrimaryUseOptions()),
    map(
      response => new fromActions.LoadVehiclePrimaryUseOptionsSuccess(response)
    ),
    catchError(error =>
      of(
        new fromActions.LoadVehiclePrimaryUseOptionsFail(
          this._errorHelper.sanitizeError(error)
        )
      )
    )
  );
}
