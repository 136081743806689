import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import * as fromSession from '@core/store/selectors/session.selector';
import * as fromPrivateLabel from '@core/store/selectors/private-label.selector';

import { PrepopulatedInsuredRequest } from '@core/models/prepopulated-insured/prepopulated-insured.model';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { StringUtils } from '@shared/utils/string.utils';

export const getPrePrepopulatedInsuredState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.prepopulatedInsured
);

export const buildPrepopulatedInsuredRequest = (accessToken: string): any =>
  createSelector(
    fromSession.getSessionId,
    fromUserContext.getUserContext,
    fromPrivateLabel.getPrivateLabelConfiguration,
    (sessionId, userContext, config) => {
      const request = {
        guid: userContext.guid,
        otp: userContext.otp,
        accessToken,
        userContext,
        sessionId,
      } as PrepopulatedInsuredRequest;

      return { request, config };
    }
  );

export const buildPrepopulatedInsuredEuaRequest = createSelector(
  fromUserContext.getUserContext,
  userContext => ({ guid: userContext.guid, otp: userContext.otp })
);

export const getPrepopulatedInsuredId = createSelector(
  getPrePrepopulatedInsuredState,
  state =>
    state.prepopulatedInsured && state.prepopulatedInsured.prepopulatedInsuredId
);

export const getPrepopulatedInsuredLoadedOrErrored = createSelector(
  getPrePrepopulatedInsuredState,
  state => state.loaded || state.hasError
);

export const getPrepopulatedPolicyHolders = createSelector(
  getPrePrepopulatedInsuredState,
  state => state.policyHolders
);

export const getPrepopulatedDrivers = createSelector(
  getPrePrepopulatedInsuredState,
  state => state.drivers
);

export const getPrepopulatedVehicles = createSelector(
  getPrePrepopulatedInsuredState,
  state => state.vehicles
);

export const getPrepopulatedDriverFrom = (driver: DriverEntity) =>
  createSelector(getPrepopulatedDrivers, prepopulatedDrivers => {
    if (!driver || !prepopulatedDrivers || !prepopulatedDrivers.length) {
      return null;
    }

    return prepopulatedDrivers.find(
      prepopDrv =>
        prepopDrv.person &&
        StringUtils.areEqual(
          prepopDrv.person.firstName,
          driver.person.firstName
        ) &&
        StringUtils.areEqual(
          prepopDrv.person.lastName,
          driver.person.lastName
        ) &&
        StringUtils.areEqual(prepopDrv.person.suffix, driver.person.suffix) &&
        StringUtils.areEqual(prepopDrv.person.gender, driver.person.gender)
    );
  });
