<div *ngIf="gettingStarted; else coverageDisplay">
  <div
    role="application"
    [@slideUp]="state"
    class="products-list getting-started-product-list"
    aria-labelledby="input-error"
  >
    <div
      class="product-wrapper flex-container nowrap"
      *ngIf="products.length > 0"
    >
      <div class="row">
        <div class="col-sm-12 product-list">
          <div class="mobile-container" *ngIf="isMobile">
            <ng-container *ngFor="let product of products; let i = index">
              <span *ngIf="product.id === '6'">
                <mq-product
                  class="col-sm-auto available-product"
                  [index]="i"
                  [product]="product"
                  (selectedProduct)="selectProduct($event)"
                  [class.selected]="isProductSelected(product)"
                  [gettingStartedProduct]="gettingStarted"
                  [showDisabled]="showProductDisabled(product)"
                  [privateLabelConfig]="privateLabelConfig"
                  [isNoPlayStateTermLifeRestricted]="
                    isNoPlayStateTermLifeRestricted
                  "
                  [isNoPlayStateAnyProductSelectedWithOutTermLife]="
                    isNoPlayStateAnyProductSelectedWithOutTermLife
                  "
                >
                </mq-product>
              </span>
              <span *ngIf="product.id === '8' && !hasSentPetEmail">
                <mq-product
                  class="col-sm-auto available-product"
                  [index]="i"
                  [product]="product"
                  (selectedProduct)="selectProduct($event)"
                  [class.selected]="isProductSelected(product)"
                  [gettingStartedProduct]="gettingStarted"
                  [showDisabled]="showProductDisabled(product)"
                  [privateLabelConfig]="privateLabelConfig"
                  [isNoPlayStatePetRestricted]="isNoPlayStatePetRestricted"
                  [isNoPlayStateAnyProductSelectedWithOutPet]="
                    isNoPlayStateAnyProductSelectedWithOutPet
                  "
                  [isPetUnavailableState]="isPetUnavailableState"
                >
                </mq-product>
              </span>
              <span *ngIf="product.id !== '6' && product.id !== '8'">
                <mq-product
                  class="product-icon-mobile available-product"
                  [index]="i"
                  [product]="product"
                  (selectedProduct)="selectProduct($event)"
                  [class.selected]="isProductSelected(product)"
                  [gettingStartedProduct]="gettingStarted"
                  [showDisabled]="showProductDisabled(product)"
                  [privateLabelConfig]="privateLabelConfig"
                  [hide]="disableForFirstTime(product)"
                >
                </mq-product>
              </span>
            </ng-container>
          </div>
          <!-- Auto -->
          <div
            *ngIf="!isMobile && !(showProductsAreRestrictedError$ | async)"
            class="card card-single card-auto"
          >
            <div *ngIf="showAutoProduct()" class="card-header header-single">
              <span class="header-auto">Auto Insurance</span>
            </div>

            <div *ngIf="showAutoProduct()" class="card-body body-single">
              <ng-container *ngFor="let product of products; let i = index">
                <span *ngIf="product.type === 'auto'">
                  <mq-product
                    class="col-sm-auto available-product"
                    [index]="i"
                    [product]="product"
                    (selectedProduct)="selectProduct($event)"
                    [class.selected]="isProductSelected(product)"
                    [gettingStartedProduct]="gettingStarted"
                    [showDisabled]="showProductDisabled(product)"
                    [privateLabelConfig]="privateLabelConfig"
                  >
                  </mq-product>
                </span>
              </ng-container>
            </div>
            <ng-container *isCustomer>
              <div
                *ngIf="
                  shouldShowWhatYouNeedLink() &&
                  !isNoPlayStateAnyProductSelectedWithOutTermLife &&
                  !isNoPlayStateAnyProductSelectedWithOutPet &&
                  isStatePaused &&
                  isFLStatePaused
                "
                class="card-footer footer-single"
              >
                <a
                  href="{{ url }}"
                  class="footer-auto"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>
                    {{ hyperlinkedText }}
                  </p>
                </a>
              </div>
            </ng-container>
          </div>
          <!-- Divider After Auto & Before Property -->
          <div
            *ngIf="!isMobile"
            class="card card-vertical-line auto-after-line"
          >
            <div
              *ngIf="
                (showAutoProduct() && showPropertyProduct()) ||
                (showAutoProduct() && showLifeProduct())
              "
              class="card-body body-single"
            >
              <ng-container *ngFor="let product of products; let i = index">
                <div
                  [ngClass]="{ verticalLine: gettingStartedProduct }"
                  *ngIf="product.id === '1'"
                >
                  <svg height="130" width="70">
                    <line
                      x1="35%"
                      y1="0"
                      x2="35%"
                      y2="75%"
                      stroke="#808080"
                      stroke-width="4%"
                      stroke-linecap="round"
                      stroke-dasharray="1, 7"
                    />
                  </svg>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- Property -->
          <div
            *ngIf="!isMobile && !(showProductsAreRestrictedError$ | async)"
            class="card card-property card-outside"
          >
            <div
              *ngIf="showPropertyProduct()"
              class="card-header header-outside"
            >
              <span class="header-property">Property Insurance</span>
            </div>

            <div class="card-body body-outside">
              <div class="products-list property-products-list">
                <ng-container *ngFor="let product of products; let i = index">
                  <div class="card card-inside">
                    <div
                      *ngIf="product.type === 'property'"
                      class="card-body body-inside"
                      [ngClass]="{ homeowner: product.id == 2 }"
                    >
                      <mq-product
                        class="col-sm-auto available-product"
                        [index]="i"
                        [product]="product"
                        (selectedProduct)="selectProduct($event)"
                        [class.selected]="isProductSelected(product)"
                        [gettingStartedProduct]="gettingStarted"
                        [showDisabled]="showProductDisabled(product)"
                        [privateLabelConfig]="privateLabelConfig"
                      >
                      </mq-product>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <!-- Divider After Property & Before Powersports -->

          <div
            *ngIf="
              !isMobile &&
              !hideForFirstTime &&
              !isNoPlayStatePowersportsOnlySelected
            "
            class="card card-vertical-line"
          >
            <div *ngIf="showPowersportsProduct() && showPropertyProduct()">
              <ng-container *ngFor="let product of products; let i = index">
                <div
                  [ngClass]="{ verticalLine: gettingStartedProduct }"
                  *ngIf="product.id === '7'"
                >
                  <svg height="130" width="70">
                    <line
                      x1="50%"
                      y1="0"
                      x2="50%"
                      y2="75%"
                      stroke="#808080"
                      stroke-width="4%"
                      stroke-linecap="round"
                      stroke-dasharray="1, 7"
                    />
                  </svg>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- Powersports -->
          <div
            *ngIf="
              !isMobile &&
              !(showProductsAreRestrictedError$ | async) &&
              !hideForFirstTime &&
              !isNoPlayStatePowersportsOnlySelected
            "
            class="card card-powersports card-single"
          >
            <div
              *ngIf="showPowersportsProduct()"
              class="card-header header-single"
            >
              <span class="header-powersports">Powersports</span>
            </div>

            <div class="card-body body-single">
              <ng-container *ngFor="let product of products; let i = index">
                <div *ngIf="product.type === 'powersports'">
                  <mq-product
                    class="col-sm-auto available-product"
                    [index]="i"
                    [product]="product"
                    (selectedProduct)="selectProduct($event)"
                    [class.selected]="isProductSelected(product)"
                    [gettingStartedProduct]="gettingStarted"
                    [hide]="disableForFirstTime(product)"
                    [showDisabled]="showProductDisabled(product)"
                    [privateLabelConfig]="privateLabelConfig"
                  >
                  </mq-product>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- Divider After Powersports & Before TermLife -->
          <div *ngIf="!isMobile" class="card card-vertical-line">
            <div
              *ngIf="
                (showLifeProduct() && showAutoProduct()) ||
                (showLifeProduct() && showPropertyProduct()) ||
                (showLifeProduct() && showPowersportsProduct())
              "
            >
              <ng-container *ngFor="let product of products; let i = index">
                <div
                  [ngClass]="{ verticalLine: gettingStartedProduct }"
                  *ngIf="product.id === '6'"
                >
                  <svg height="130" width="70">
                    <line
                      x1="50%"
                      y1="0"
                      x2="50%"
                      y2="75%"
                      stroke="#808080"
                      stroke-width="4%"
                      stroke-linecap="round"
                      stroke-dasharray="1, 7"
                    />
                  </svg>
                </div>
              </ng-container>
            </div>
          </div>
          <!--TermLife-->
          <div
            *ngIf="!isMobile && !(showProductsAreRestrictedError$ | async)"
            class="card card-single card-life"
          >
            <div
              *ngIf="
                (showLifeProduct() && showAutoProduct()) ||
                (showLifeProduct() && showPropertyProduct()) ||
                (showLifeProduct() && showPowersportsProduct())
              "
              class="card-header header-single"
            >
              <span class="header-life">Term Life</span>
            </div>

            <div
              *ngIf="
                (showLifeProduct() && showAutoProduct()) ||
                (showLifeProduct() && showPropertyProduct()) ||
                (showLifeProduct() && showPowersportsProduct())
              "
              class="card-body body-single"
            >
              <ng-container *ngFor="let product of products; let i = index">
                <span *ngIf="product.id === '6'">
                  <mq-product
                    class="col-sm-auto available-product"
                    [index]="i"
                    [product]="product"
                    (selectedProduct)="selectProduct($event)"
                    [class.selected]="isProductSelected(product)"
                    [gettingStartedProduct]="gettingStarted"
                    [showDisabled]="showProductDisabled(product)"
                    [privateLabelConfig]="privateLabelConfig"
                    [isNoPlayStateTermLifeRestricted]="
                      isNoPlayStateTermLifeRestricted
                    "
                    [isNoPlayStateAnyProductSelectedWithOutTermLife]="
                      isNoPlayStateAnyProductSelectedWithOutTermLife
                    "
                  >
                  </mq-product>
                </span>
              </ng-container>
            </div>
          </div>
          <!-- Divider After TermLife & Before Pet -->
          <div *ngIf="!isMobile" class="card card-vertical-line">
            <div
              *ngIf="
                (showPetProduct() && showAutoProduct()) ||
                (showPetProduct() && showPropertyProduct()) ||
                (showPetProduct() && showPowersportsProduct())
              "
            >
              <ng-container *ngFor="let product of products; let i = index">
                <div
                  [ngClass]="{ verticalLine: gettingStartedProduct }"
                  *ngIf="product.id === '8'"
                >
                  <svg height="130" width="70">
                    <line
                      x1="50%"
                      y1="0"
                      x2="50%"
                      y2="75%"
                      stroke="#808080"
                      stroke-width="4%"
                      stroke-linecap="round"
                      stroke-dasharray="1, 7"
                    />
                  </svg>
                </div>
              </ng-container>
            </div>
          </div>
          <!--Pet-->
          <div
            *ngIf="!isMobile && !(showProductsAreRestrictedError$ | async)"
            class="card card-single card-life"
          >
            <div
              *ngIf="
                (showPetProduct() && showAutoProduct()) ||
                (showPetProduct() && showPropertyProduct()) ||
                (showPetProduct() && showPowersportsProduct())
              "
              class="card-header header-single"
            >
              <span class="header-life">Pet</span>
            </div>

            <div
              *ngIf="
                (showPetProduct() && showAutoProduct()) ||
                (showPetProduct() && showPropertyProduct()) ||
                (showPetProduct() && showPowersportsProduct())
              "
              class="card-body body-single"
            >
              <ng-container *ngFor="let product of products; let i = index">
                <span *ngIf="product.id === '8'">
                  <mq-product
                    class="col-sm-auto available-product"
                    [index]="i"
                    [product]="product"
                    (selectedProduct)="selectProduct($event)"
                    [class.selected]="isProductSelected(product)"
                    [gettingStartedProduct]="gettingStarted"
                    [showDisabled]="showProductDisabled(product)"
                    [privateLabelConfig]="privateLabelConfig"
                    [isNoPlayStatePetRestricted]="isNoPlayStatePetRestricted"
                    [isNoPlayStateAnyProductSelectedWithOutPet]="
                      isNoPlayStateAnyProductSelectedWithOutPet
                    "
                    [isPetUnavailableState]="isPetUnavailableState"
                  >
                  </mq-product>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #coverageDisplay>
  <div
    role="application"
    class="products-list"
    [@slideUp]="state"
    [@.disabled]="isIEOrEdge"
    #productContainer
    aria-labelledby="coverage-display"
    id="coverage-display"
  >
    <ng-container
      *ngFor="let product of products; let i = index; trackBy: trackByFn"
      id="coverage-display"
    >
      <div class="product-wrapper" id="coverage-display-{{ product.type }}">
        <div class="product-title" *ngIf="showLifeText(product)">
          Apply Today
        </div>
        <div class="product-title" *ngIf="!showLifeText(product)">
          <br />
        </div>
        <div class="product-list">
          {{ costEstimate }}
          <mq-product
            [ngClass]="{ termLife: product.name === 'Term Life' }"
            [index]="i"
            [coverageProduct]="coverageProduct"
            [product]="product"
            (selectedProduct)="selectProduct($event)"
            (selectedProductCoverages)="selectProductCoverages($event)"
            [class.selected]="isProductSelected(product)"
            [privateLabelConfig]="privateLabelConfig"
            [totalCostEstimate]="totalCostEstimate"
            [disabled]="isReadOnlyWithOneProduct(product)"
            id="termLife-{{ i }}"
          >
            <ng-content></ng-content>
          </mq-product>
        </div>
      </div>
    </ng-container>
    <div
      class="arrow-up"
      *ngIf="coverageProduct"
      [ngStyle]="{
        left: bigPos + 'px',
        'border-bottom': '15px solid ' + defaultArrowColor
      }"
      #arrowUp
      (window:resize)="onResize(posCalc)"
    ></div>
  </div>
  <br />
</ng-template>
