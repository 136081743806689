import { Injectable } from '@angular/core';

import { Observable, from, of } from 'rxjs';

import { AppConfigService } from '@core/services/app-config.service';

import { CmaRequest } from '@core/models/cma/cma-request.model';
import { CmaResponse } from '@core/models/cma/cma-response.model';

import { StringUtils } from '@shared/utils/string.utils';

@Injectable()
export class CmaAdapter {
  constructor(private _appConfig: AppConfigService) {}

  customerManagementAction(request: CmaRequest): Observable<CmaResponse> {
    const url = `${this._appConfig.config.multiproductApi}/leads`;

    if (window.fetch) {
      const urlString =
        url +
        '?client_id=' +
        this._appConfig.config.apiKey +
        '&message_id=' +
        StringUtils.generateUUID();

      return from(
        fetch(<any>urlString, {
          method: 'POST',
          keepalive: true,
          body: JSON.stringify(request),
        })
      );
    } else {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url, false);
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('client_id', this._appConfig.config.apiKey);
      xhr.setRequestHeader('X-NW-Message-ID', StringUtils.generateUUID());
      xhr.send(JSON.stringify(request));
      return of(xhr.response);
    }
  }
}
