import { Injectable } from '@angular/core';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { UnderlyingPolicyRequest } from '@core/models/umbrella/underlying-policy-request.model';
import { Observable } from 'rxjs';
import { UnderlyingPolicyEntity } from '@core/models/entities/underlying-policy.entity';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UnderlyingPolicyAdapter extends BaseDsmAdapter {
  underlyingPolicies(request: UnderlyingPolicyRequest): Observable<any> {
    const url = `${this.getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/underlying-policies`;

    return this.post<UnderlyingPolicyEntity>(
      url,
      { underlyingPolicy: request.underlyingPolicy },
      { productId: request.productId }
    ).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }
}
