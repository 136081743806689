import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProtectiveDevicesAdapter } from '@core/adapters/protective-devices.adapter';
import {
  ProtectiveDevicesRequest,
  ProtectiveDevice,
} from '@core/store/reducers/protective-devices.reducer';
import { ProtectiveDevicesDao } from '../dao/protective-devices.dao';

@Injectable({
  providedIn: 'root',
})
export class ProtectiveDevicesService {
  constructor(
    private _protectiveDevicesAdapter: ProtectiveDevicesAdapter,
    private _protectiveDevicesDao: ProtectiveDevicesDao
  ) {}

  getProtectiveDevices(): Observable<ProtectiveDevice[]> {
    return this._protectiveDevicesDao.getProtectiveDevices();
  }

  getProtectiveDevicesLoaded(): Observable<boolean> {
    return this._protectiveDevicesDao.getProtectiveDevicesLoaded();
  }

  // getAllProtectiveDevices(
  //   product: string
  // ): Observable<ProtectiveDevicesResponse[]> {
  //   return this._protectiveDevicesAdapter.getAllProtectiveDevices(product);
  // }

  protectiveDevices(request: any): Observable<ProtectiveDevice[]> {
    return this._protectiveDevicesAdapter.getProtectiveDevices(request);
  }

  updateProtectiveDevices(request: ProtectiveDevicesRequest) {
    return this._protectiveDevicesAdapter.updateProtectiveDevices(request);
  }

  dispatchGetProtectiveDevices() {
    this._protectiveDevicesDao.dispatchGetProtectiveDevices();
  }

  dispatchUpdateProtectiveDevices(devices: ProtectiveDevice[]) {
    this._protectiveDevicesDao.updateProtectiveDevices(devices);
  }

  mapProtectiveDevicesResponse(
    response: ProtectiveDevice[],
    storeState: ProtectiveDevice[]
  ): ProtectiveDevice[] {
    const updatedProtectiveDevices = [];
    storeState.forEach(protectiveDevice => {
      const updatedProtectiveDevice = response.find(
        protectiveDeviceUpdate =>
          protectiveDeviceUpdate.type === protectiveDevice.type
      );
      if (
        updatedProtectiveDevice &&
        updatedProtectiveDevice.value &&
        updatedProtectiveDevice.value !== 'false'
      ) {
        updatedProtectiveDevices.push({
          ...protectiveDevice,
          value: updatedProtectiveDevice.value,
        });
      } else {
        updatedProtectiveDevices.push(protectiveDevice);
      }
    });

    return updatedProtectiveDevices;
  }
}
