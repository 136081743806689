import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsIndependentAgentDirective } from './is-independent-agent.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsIndependentAgentDirective],
  exports: [IsIndependentAgentDirective],
})
export class IsIndependentAgentModule {}
