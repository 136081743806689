import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import { HomeownersModel } from '@core/models/homeowners/homeowners.model';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { Subject, Observable } from 'rxjs';
import { PropertyHelper } from '@core/services/helpers/property.helper';
import {
  HO_PROP_INFO_FORM_ENG,
  ProductTypes,
} from '@shared/constants/app-constants';
import { PropertyInfoProxy } from '../../proxies/property-info.proxy';
import { map } from 'rxjs/operators';
import { MSBDisclaimerMessage } from '@shared/constants/help-text-constants';
import { RenovationEntity } from '@core/store/reducers/renovation.reducer';
import { PolicyEffectiveDates } from '@core/models/policy/policy-effective-date.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { DateUtils } from '@shared/utils/date.utils';

@Component({
  selector: 'mq-ho-property-info-form',
  templateUrl: './ho-property-info-form.component.html',
  styleUrls: ['./ho-property-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HoPropertyInfoFormComponent implements OnInit, OnDestroy {
  @Input() homeowners: HomeownersModel;
  @Input() coveredLocation: CoveredLocation;
  @Input() form: UntypedFormGroup;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() policyEffectiveDates: PolicyEffectiveDates;
  @Input() partialQuoteCoveredLocation: CoveredLocation;

  renovationValue$: Observable<RenovationEntity[]>;
  needConstructionInfo$: Observable<boolean>;
  MSBDisclaimerCustomerMessage$: Observable<string>;

  MSBDisclaimerAgentMessage = MSBDisclaimerMessage['Agent'];
  showmsbDisclaimerMessage: boolean;
  homeownerText = HO_PROP_INFO_FORM_ENG;
  reviewConstructionInfo: boolean;
  showOilTankForm: boolean;
  currentYear: number = new Date().getFullYear();
  unsubscribe = new Subject();

  options$: Observable<any>;

  constructor(
    private _propertyHelper: PropertyHelper,
    private _proxy: PropertyInfoProxy,
    private _fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.needConstructionInfo$ = this._proxy.getNeedConstructionInfo(
      ProductTypes.HOMEOWNERS
    );

    this.options$ = this._proxy.getPropertyInfoHomeownerPicklistData();

    this.renovationValue$ = this._proxy.getRenovationStoreData();
    this.form.patchValue(this.homeowners);

    if (this.coveredLocation?.datePurchased) {
      this.form.controls.estimatedYearPurchased.patchValue(
        this._propertyHelper.convertHomePurchaseDateFromResponse(
          this.coveredLocation.datePurchased
        )
      );
    }

    if (this.coveredLocation?.constructionInfo) {
      this.form.controls.constructionInfoForm.patchValue(
        this.coveredLocation.constructionInfo
      );
    }

    if (
      this.partialQuoteCoveredLocation &&
      (!this.coveredLocation?.constructionInfo?.yearBuilt ||
        !this.coveredLocation?.constructionInfo?.squareFootage ||
        !this.coveredLocation?.constructionInfo?.numberOfStories ||
        !this.coveredLocation?.datePurchased)
    ) {
      this.form.controls.constructionInfoForm
        .get('yearBuilt')
        .patchValue(
          this.partialQuoteCoveredLocation.constructionInfo.yearBuilt
        );
      this.form.controls.constructionInfoForm
        .get('squareFootage')
        .patchValue(
          this.partialQuoteCoveredLocation.constructionInfo.squareFootage
        );

      if (
        this.partialQuoteCoveredLocation.constructionInfo.numberOfStories &&
        this.partialQuoteCoveredLocation.constructionInfo.numberOfStories >=
          '1.0' &&
        this.partialQuoteCoveredLocation.constructionInfo.numberOfStories <=
          '3.0'
      ) {
        this.form.controls.constructionInfoForm
          .get('numberOfStories')
          .patchValue(
            this.partialQuoteCoveredLocation.constructionInfo.numberOfStories
          );
      }
      this.form.patchValue({
        estimatedYearPurchased:
          DateUtils.transformYearMonthDayDashToMonthYearSlash(
            this.partialQuoteCoveredLocation.datePurchased
          ),
      });
    }

    this.reviewConstructionInfo =
      this.stateSpecificFlags.reviewConstructionInfo;
    this.showmsbDisclaimerMessage =
      this.stateSpecificFlags.msbDisclaimerMessage;
    this.showOilTankForm = this.stateSpecificFlags.requiredOilTank;

    this.MSBDisclaimerCustomerMessage$ = this._proxy
      .getContactInformation()
      .pipe(
        map(number =>
          MSBDisclaimerMessage['Customer'].replace('{phoneNumber}', number)
        )
      );
    this.estimatedYearPurchased.setValidators([
      Validators.required,
      this.validateMaxEstimatedYearPurchased.bind(this),
    ]);
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.next();
    }
  }

  private validateMaxEstimatedYearPurchased(field: AbstractControl) {
    return this._propertyHelper.validateMaxEstimatedYearPurchased(
      field,
      this.policyEffectiveDates.baseEffectiveDate
    );
  }

  /** Form Getters */
  get hasMortgage(): AbstractControl {
    return this.form.get('hasMortgage');
  }

  get estimatedYearPurchased(): AbstractControl {
    return this.form.get('estimatedYearPurchased');
  }

  get constructionInfoForm(): AbstractControl {
    return this.form.get('constructionInfoForm');
  }

  get renovationsForm(): AbstractControl {
    return this.form.get('renovationsForm');
  }

  get oilTankForm(): AbstractControl {
    return this.form.get('oilTankForm');
  }
}
