import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { UserContext } from '@core/models/user-context/user-context.model';
import { Observable } from 'rxjs';

@Injectable()
export class UserContextDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  updateUserContext(userContext: UserContext): void {
    this._store.dispatch(new fromActions.UpdateUserContext(userContext));
  }

  getUserContext(): Observable<UserContext> {
    return this._store.select(fromSelectors.getUserContext);
  }

  getUserContextLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getUserContextLoaded);
  }

  isQuoteRetrieve(): Observable<boolean> {
    return this._store.select(fromSelectors.isQuoteRetrieve);
  }

  isCompRater(): Observable<boolean> {
    return this._store.select(fromSelectors.isCompRaterInitiated);
  }

  isPrivateLabelPreview(): Observable<boolean> {
    return this._store.select(fromSelectors.isPrivateLabelPreview);
  }
}
