import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions/agency.action';
import * as fromSelectors from '@core/store/selectors/agency.selector';

import { AgencyModel } from '@core/models/agency/agency.model';
import { Producer } from '@core/models/agency/producer-search.model';

@Injectable()
export class AgencyDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getAgency(): Observable<AgencyModel> {
    return this._store.select(fromSelectors.getAgency);
  }

  updateAgency(agency: AgencyModel): void {
    this._store.dispatch(new fromActions.UpdateAgency(agency));
  }

  isAgent(): Observable<boolean> {
    return this._store.select(fromSelectors.isAgent);
  }

  isNSSAgent(): Observable<boolean> {
    return this._store.select(fromSelectors.isNSSAgent);
  }

  isDirectUser(): Observable<boolean> {
    return this._store.select(fromSelectors.isDirectUser);
  }

  isPrivateLabel(): Observable<boolean> {
    return this._store.select(fromSelectors.isPrivateLabel);
  }

  updateAgentJwt(jwtContents: any, accessToken: string = null): void {
    this._store.dispatch(
      new fromActions.UpdateAgentJwt({
        jwtContents,
        accessToken,
      })
    );
  }

  getAgentJwt(): Observable<any> {
    return this._store.select(fromSelectors.getAgentJwt);
  }

  dispatchProducerCodeSearch(): void {
    this._store.dispatch(new fromActions.ProducerCodeSearch());
  }

  getAgencyCodes(): Observable<string[]> {
    return this._store.select(fromSelectors.getAgencyCodes);
  }

  getPDSCodes(): Observable<Producer[]> {
    return this._store.select(fromSelectors.getPDSCodes);
  }

  getProducerIdsByAgencyCode(agencyCode: string): Observable<Producer[]> {
    return this._store.select(
      fromSelectors.getProducerIdsByAgencyCode(agencyCode)
    );
  }

  isEAOrIAAgentQuoting(): Observable<boolean> {
    return this._store.select(fromSelectors.isEAOrIAAgentQuoting);
  }
}
