import { OnInit, Component, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mq-dog-breeds-modal',
  templateUrl: './dog-breeds-modal.component.html',
  styleUrls: ['./dog-breeds-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DogBreedsModalComponent implements OnInit {
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}
