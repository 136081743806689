export interface PrivateLabel {
  identifier: string;
  residentState: string;
  agencyName: string;
  phoneNumber: string;
  additionalPhoneNumber: string;
  websiteUrl: string;
  logoUrl: string;
  logoWidth?: string;
  favicon: string;
  emailAddress: string;
  channel: string;
  producerInformation: ProducerInformation[];
  customUIConfig?: CustomUIConfiguration[];
  isThirdPartyPartner?: boolean;
}

export interface ProducerInformation {
  producerIdentifier: string;
  producerCode: string;
  agencyIdentifier: string;
  state: string;
  nameFiledWithCommissioner?: string;
  agencyLicenseNumber?: string;
  residentState?: string;
  products: AgencyProduct[];
  coverages: CoverageConfiguration[];
  isDefault: boolean;
  phoneNumber: string;
  email: string;
}

export interface CoverageConfiguration {
  id: string;
  name: string;
  value: string;
  coverageComparison?: MinComparison | MaxComparison | DefaultComparison;
  coveragePercentValue?: MinPercentValue;
  productId: string;
  type: CoverageComparisonType;
  mandatory: boolean;
}

export type MinComparison = (configVal: number, actualVal: number) => boolean;
export type MaxComparison = (configVal: number, actualVal: number) => boolean;
export type DefaultComparison = (
  configVal: number,
  actualVal: number
) => boolean;
export type MinPercentValue = (
  configDecimal: number,
  currentVal: number
) => number;

export interface AgencyProduct {
  product: string;
}

export enum CoverageComparisonType {
  Minimum,
  Maximum,
  Default,
  PercentageMinimum,
}

export interface CustomUIConfiguration {
  element: string;
  identifier: string;
  value: string;
}

export interface ConnectedCarCriteria {
  year: number;
  make: string;
  models: string[];
  identifiers: string[];
}

export interface ConnectedCarConsent {
  authorizationToShare?: boolean;
  hasConnectedCarConsent?: boolean;
}

export interface SmartrideInstantConsent {
  hasViewedDrivingDataConsent?: boolean;
  hasDrivingDataConsent?: boolean;
}
