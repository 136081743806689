import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { AppConfigService } from '@core/services/app-config.service';
import {
  DistributionPartnerRoleResponse,
  DistributionPartnerRoleRequest,
} from '@core/models/distribution-partner-management/distribution-partner-role.model';
import { StringUtils } from '@shared/utils/string.utils';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DistributionPartnerManagementAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfigService: AppConfigService
  ) {}

  getDistributionPartnerRoles(
    request: DistributionPartnerRoleRequest
  ): Observable<DistributionPartnerRoleResponse> {
    const url = `${this._appConfigService.config.distributionPartnerManagementUrl}/distributionpartnerroles/${request.legacyIdentifier}`;
    let params = new HttpParams();
    params = params.set('legacyIdentifierType', request.legacyIdentifierType);
    params = params.set(
      'legacyAdministrativeSystem',
      request.legacyAdministrativeSystem
    );
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfigService.config.apiKey)
      .set(
        'X-NWD-Scope',
        this._appConfigService.config.distributionPartnerManagementScope
      )
      .set('X-NW-Message-ID', StringUtils.generateUUID());

    return this._http
      .get<DistributionPartnerRoleResponse>(url, {
        params,
        headers,
      })
      .pipe(catchError((error: any) => of(null)));
  }
}
