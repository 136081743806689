import { Action } from '@ngrx/store';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { McpContactPoint } from '@core/models/modify-contact-point/modify-contact-point-request.model';

export const enum PolicyholderActionTypes {
  ADD_POLICYHOLDER = '[Policyholder] Add Policyholder',
  ADD_POLICYHOLDER_FAIL = '[Policyholder] Add Policyholder Fail',
  ADD_POLICYHOLDER_SUCCESS = '[Policyholder] Add Policyholder Success',

  ADD_ALL_POLICYHOLDERS = '[Policyholder] Add All Policyholders',
  UPDATE_ALL_POLICYHOLDERS = '[Policyholder] Update All Policyholders',
  UPDATE_POLICYHOLDER = '[Policyholder] Update Policyholder',
  UPDATE_POLICYHOLDER_FAIL = '[Policyholder] Update Policyholder Fail',
  UPDATE_POLICYHOLDER_SUCCESS = '[Policyholder] Update Policyholder Success',
  UPDATE_POLICYHOLDER_DOB = '[Policyholder] Update Policyholder DOB',
  UPDATE_CONTACT_POINT_DSM = '[Policyholder] Update Contact Point',
  UPDATE_CONTACT_POINT_DSM_SUCCESS = '[Policyholder] Update Contact Point Success',
  UPDATE_CONTACT_POINT_DSM_FAIL = '[Policyholder] Update Contact Point Fail',

  DELETE_POLICYHOLDER = '[Policyholder] Delete Policyholder',
  DELETE_POLICYHOLDER_FAIL = '[Policyholder] Delete Policyholder Fail',
  DELETE_POLICYHOLDER_SUCCESS = '[Policyholder] Delete Policyholder Success',

  POLICYHOLDER_CALL_COMPLETE = '[Policyholder] Policyholder Call Complete',

  UPDATE_PROPERTY_POLICYHOLDER = '[Policyholder] Update Property Policyholder',
  UPDATE_PROPERTY_POLICYHOLDER_FAIL = '[Policyholder] Update Property Policyholder Fail',
  UPDATE_PROPERTY_POLICYHOLDER_SUCCESS = '[Policyholder] Update Property Policyholder Success',
}

export class AddPolicyholder implements Action {
  readonly type = PolicyholderActionTypes.ADD_POLICYHOLDER;
  constructor(public payload: PolicyholderEntity) {}
}
export class AddPolicyholderFail implements Action {
  readonly type = PolicyholderActionTypes.ADD_POLICYHOLDER_FAIL;
  constructor(public payload: PolicyholderEntity) {}
}
export class AddPolicyholderSuccess implements Action {
  readonly type = PolicyholderActionTypes.ADD_POLICYHOLDER_SUCCESS;
  constructor(public payload: PolicyholderEntity) {}
}

export class AddAllPolicyholders implements Action {
  readonly type = PolicyholderActionTypes.ADD_ALL_POLICYHOLDERS;
  constructor(public payload: PolicyholderEntity[]) {}
}

export class UpdateAllPolicyholders implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_ALL_POLICYHOLDERS;
  constructor(public payload: PolicyholderEntity[]) {}
}

export class DeletePolicyholder implements Action {
  readonly type = PolicyholderActionTypes.DELETE_POLICYHOLDER;
  constructor(public payload: string) {}
}
export class DeletePolicyholderFail implements Action {
  readonly type = PolicyholderActionTypes.DELETE_POLICYHOLDER_FAIL;
  constructor(public payload: string) {}
}
export class DeletePolicyholderSuccess implements Action {
  readonly type = PolicyholderActionTypes.DELETE_POLICYHOLDER_SUCCESS;
  constructor(public payload: string) {}
}

export class UpdatePolicyholder implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_POLICYHOLDER;
  constructor(public payload: PolicyholderEntity) {}
}

export class UpdatePolicyholderFail implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_POLICYHOLDER_FAIL;
  constructor(public payload: any) {}
}

export class UpdatePolicyholderSuccess implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_POLICYHOLDER_SUCCESS;
  constructor(public payload: PolicyholderEntity) {}
}

export class UpdatePolicyholderDob implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_POLICYHOLDER_DOB;
  constructor(public policyholderId: string, public dob: string) {}
}

export class UpdateContactPointDsm implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_CONTACT_POINT_DSM;
  constructor(public payload: McpContactPoint) {}
}

export class UpdateContactPointDsmSuccess implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_CONTACT_POINT_DSM_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateContactPointDsmFail implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_CONTACT_POINT_DSM_FAIL;
  constructor(public payload: any) {}
}

export class PolicyholderCallComplete implements Action {
  readonly type = PolicyholderActionTypes.POLICYHOLDER_CALL_COMPLETE;
  constructor(public payload?: any) {}
}

export class UpdatePropertyPolicyholder implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_PROPERTY_POLICYHOLDER;
  constructor(public payload: PolicyholderEntity) {}
}

export class UpdatePropertyPolicyholderFail implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_PROPERTY_POLICYHOLDER_FAIL;
  constructor(public payload: any) {}
}

export class UpdatePropertyPolicyholderSuccess implements Action {
  readonly type = PolicyholderActionTypes.UPDATE_PROPERTY_POLICYHOLDER_SUCCESS;
  constructor(public payload: PolicyholderEntity) {}
}

export type PolicyholderActions =
  | AddPolicyholder
  | AddPolicyholderFail
  | AddPolicyholderSuccess
  | AddAllPolicyholders
  | UpdateAllPolicyholders
  | DeletePolicyholder
  | DeletePolicyholderFail
  | DeletePolicyholderSuccess
  | UpdatePolicyholder
  | UpdatePolicyholderFail
  | UpdatePolicyholderSuccess
  | UpdatePolicyholderDob
  | UpdateContactPointDsm
  | UpdateContactPointDsmSuccess
  | UpdateContactPointDsmFail
  | PolicyholderCallComplete
  | UpdatePropertyPolicyholder
  | UpdatePropertyPolicyholderSuccess
  | UpdatePropertyPolicyholderFail;
