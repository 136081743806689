import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, fromEvent } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import {
  AccessTokenRequest,
  PrepopulatedInsuredAccessTokenRequest,
} from '@core/models/auto/quotes/access-token-request.model';

export class EuaResponse {
  /* These fields come directly off the EUA response, and have the same names.
   */
  public access_token: string;
  public token_type: string;
  public expires_in: string;
  public scope: string;
  public id_token: string;
  public state: string;

  /* Decoded and evaluated objects from 'id_token', or null if anything went wrong.
   */
  public idTokenFirst: any;
  public idTokenSecond: any;

  /* These two are only present in failure cases.
   */
  public error: string;
  public error_description: string;

  public isValid(): boolean {
    if (this.error) {
      return false;
    }
    if (!this.access_token) {
      return false;
    }
    return true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OauthIframeService {
  constructor(
    private _appConfigService: AppConfigService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  authenticate(
    request: AccessTokenRequest | PrepopulatedInsuredAccessTokenRequest
  ): Observable<EuaResponse> {
    const element: HTMLIFrameElement = <HTMLIFrameElement>(
      this._document.createElement('IFRAME')
    );

    const observable = fromEvent(element, 'load').pipe(
      filter(
        event =>
          element.contentWindow &&
          element.contentWindow.location.href !== 'about:blank'
      ),
      map(event => {
        const euaResponse = this.euaResponseFromUrlFragment(
          element.contentWindow.location.hash
        );
        element.parentNode.removeChild(element);
        return euaResponse;
      }),
      take(1)
    );

    this.makeElementInvisible(element);
    this._document.body.appendChild(element);

    element.src = this.composeUrl(request);

    return observable;
  }

  private composeUrl(
    request: AccessTokenRequest | PrepopulatedInsuredAccessTokenRequest
  ): string {
    let redirectHost = window.location.host;
    let additionalState = '';
    if (window.location.host.indexOf('aws.e1.nwie.net') >= 0) {
      redirectHost = 'nwx-oauth2-trampoline.apps-dev.aws.e1.nwie.net';
      additionalState = encodeURIComponent(
        '&kubernetesEndpoint=//' + window.location.host + '/'
      );
    }

    let url =
      this._appConfigService.config.euaUrl +
      '?client_id=' +
      this._appConfigService.config.apiKey +
      '&nonce=myNonce&redirect_uri=' +
      window.location.protocol +
      '//' +
      redirectHost +
      '/&response_type=id_token token' +
      '&scope=test pci' +
      '&state=eua-redirect' +
      additionalState +
      '&realm=unidentified';

    if ('guid' in request && 'otp' in request) {
      url += this.buildPrepopulatedInsuredEuaURL(request);
    } else if ('quoteId' in request) {
      url += this.buildPersonalLinesSalesEuaURL(request);
    } else if ('emailId' in request) {
      url += this.buildPersonalLinesSalesEuaURLNonQuoteId(request);
    }

    if (
      this._appConfigService.config.targetEnv &&
      this._appConfigService.isTest()
    ) {
      url += '&targetEnv=' + this._appConfigService.config.targetEnv;
    }
    return url;
  }

  private buildPrepopulatedInsuredEuaURL(
    request: PrepopulatedInsuredAccessTokenRequest
  ): string {
    return (
      '&auth_method=pl-prepop-insured' +
      '&auth_id_guid=' +
      request.guid +
      '&auth_id_code=' +
      request.otp
    );
  }

  private buildPersonalLinesSalesEuaURLNonQuoteId(
    request: AccessTokenRequest
  ): string {
    const [postalCode] = request.postalCode.split('-');
    return (
      '&auth_method=personal-lines-sales' +
      '&auth_id_emailId=' +
      request.emailId +
      '&auth_id_dateOfBirth=' +
      request.dateOfBirth +
      '&auth_id_postalCode=' +
      request.postalCode +
      '&auth_id_lastName=' +
      request.lastName
    );
  }

  private buildPersonalLinesSalesEuaURL(request: AccessTokenRequest): string {
    const [postalCode] = request.postalCode.split('-');
    return (
      '&auth_method=personal-lines-sales' +
      '&auth_id_quoteId=' +
      request.quoteId +
      '&auth_id_postalCode=' +
      postalCode +
      '&auth_id_lastName=' +
      request.lastName
    );
  }

  private euaResponseFromUrlFragment(input): EuaResponse {
    const output: EuaResponse = new EuaResponse();

    if (input) {
      if (input.startsWith('#')) {
        input = input.substr(1);
      }

      for (const field of input.split('&')) {
        const separatorPosition = field.indexOf('=');
        let key, value;
        if (separatorPosition < 0) {
          key = field;
          value = '';
        } else {
          key = field.substr(0, separatorPosition);
          value = field.substr(separatorPosition + 1);
        }
        key = decodeURIComponent(key);
        value = decodeURIComponent(value);
        output[key] = value;
      }
    }

    return output;
  }

  private makeElementInvisible(element) {
    element.style.display = 'none';
  }
}
