import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { switchMap, catchError, tap, map, filter, take } from 'rxjs/operators';
import { LoggingService } from '@core/services/logging.service';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { RenovationService } from '@core/services/renovations.services';
import { RenovationEntity } from '@core/store/reducers/renovation.reducer';
import { Product } from '@core/models/products/product.model';
import { ProductsService } from '@core/services';

@Injectable()
export class RenovationEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _renovationService: RenovationService,
    private _productsService: ProductsService,
    private _loggingService: LoggingService
  ) {}

  @Effect()
  getAllRenovations$ = this._actions$.pipe(
    ofType(fromActions.RenovationActionTypes.GET_ALL_RENOVATION),
    switchMap(() =>
      this._store.select(fromSelectors.getSelectedPropertyProduct).pipe(
        filter(product => product && !!product.quoteId),
        take(1)
      )
    ),
    switchMap(product => {
      return this._renovationService.getAllRenovations(product).pipe(
        tap(response =>
          this._loggingService.log('Get All Renovation', response)
        ),
        switchMap(response =>
          from([new fromActions.GetAllRenovationSuccess(response)])
        ),
        catchError(error => {
          return from([new fromActions.GetAllRenovationFail(error)]);
        })
      );
    })
  );

  @Effect()
  addRenovation$ = this._actions$.pipe(
    ofType(fromActions.RenovationActionTypes.ADD_RENOVATION),
    switchMap((action: fromActions.AddRenovation) => {
      return this._renovationService
        .addRenovation(action.payload.product, action.payload.renovationEntity)
        .pipe(
          tap(response =>
            this._loggingService.log('addRenovation response', response)
          ),
          switchMap(response => {
            return from([
              new fromActions.AddRenovationSuccess([
                {
                  renovationId: response.renovationId,
                  type: response.type,
                  year: response.year,
                },
              ]),
            ]);
          }),
          catchError(error => {
            this._loggingService.log('addRenovation error', error);
            return from([new fromActions.AddRenovationFail(error)]);
          })
        );
    })
  );

  @Effect()
  updateRenovation$ = this._actions$.pipe(
    ofType(fromActions.RenovationActionTypes.UPDATE_RENOVATION),
    map((action: any) => action.payload),
    switchMap(payload =>
      this._productsService.getSelectedPropertyProduct().pipe(
        take(1),
        map(product => [payload, product])
      )
    ),
    switchMap(([payload, product]: [RenovationEntity, Product]) => {
      return this._renovationService.updateRenovation(product, payload).pipe(
        tap(response =>
          this._loggingService.log('updateRenovation response', response)
        ),
        switchMap((response: any) => {
          const data = [];
          data.push(response);
          return from([new fromActions.UpdateRenovationSuccess(data)]);
        }),
        catchError(error => {
          this._loggingService.log('updateRenovation error', error);
          return from([new fromActions.UpdateRenovationFail(error)]);
        })
      );
    })
  );

  @Effect()
  deleteRenovations$ = this._actions$.pipe(
    ofType(fromActions.RenovationActionTypes.DELETE_RENOVATION),
    map((action: any) => action.payload),
    switchMap(payload =>
      this._productsService.getSelectedPropertyProduct().pipe(
        take(1),
        map(product => [payload, product])
      )
    ),
    switchMap(([payload, product]: [any, Product]) => {
      return this._renovationService
        .deleteRenovation(product, payload.renovationEntity)
        .pipe(
          tap(response =>
            this._loggingService.log('Delete Renovation', response)
          ),
          switchMap(response =>
            from([new fromActions.DeleteRenovationSuccess(response)])
          ),

          catchError(error => {
            this._loggingService.log('deleteRenovation error', error);
            return from([new fromActions.DeleteRenovationFail(error)]);
          })
        );
    })
  );
}
