import { Action } from '@ngrx/store';
import { CmaResponse } from '@core/models/cma/cma-response.model';

export const LOAD_CUSTOMER_MANAGEMENT_ACTION =
  '[CMA] Load Customer Management Action';
export const LOAD_CUSTOMER_MANAGEMENT_ACTION_FAIL =
  '[CMA] Load Customer Management Action Fail';
export const LOAD_CUSTOMER_MANAGEMENT_ACTION_SUCCESS =
  '[CMA] Load Customer Management Action Success';

export class LoadCustomerManagementAction implements Action {
  readonly type = LOAD_CUSTOMER_MANAGEMENT_ACTION;
}

export class LoadCustomerManagementActionFail implements Action {
  readonly type = LOAD_CUSTOMER_MANAGEMENT_ACTION_FAIL;
  constructor(public payload: any) {}
}

export class LoadCustomerManagementActionSuccess implements Action {
  readonly type = LOAD_CUSTOMER_MANAGEMENT_ACTION_SUCCESS;
  constructor(public payload: CmaResponse) {}
}

export type CmaActions =
  | LoadCustomerManagementAction
  | LoadCustomerManagementActionFail
  | LoadCustomerManagementActionSuccess;
