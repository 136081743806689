import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { MetadataAdapter } from '@core/adapters/metadata.adapter';
import { MetadataDao } from '@core/dao';
import { SelectOption } from '@core/models/select-option.model';
import { ConnectedCarCriteria } from '@core/models/private-label/private-label.model';
import { VehiclePrimaryUseOptions } from '@core/models/auto/vehicle-primary-use.model';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { AntiTheftDevices } from '@core/models/auto/vehicle-antiTheftDevice-states.model';
import { filter, switchMap, tap } from 'rxjs/operators';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

@Injectable()
export class MetadataService {
  constructor(
    private _metadataAdapter: MetadataAdapter,
    private _metadataDao: MetadataDao
  ) {}

  getSuffix(): Observable<{ suffix: SelectOption[] }> {
    return this._metadataAdapter.getSuffix();
  }

  getMaritalStatus(): Observable<string[]> {
    return this._metadataAdapter.getMaritalStatus();
  }

  loadMaritalStatus(): void {
    this._metadataDao.loadMaritalStatus();
  }

  getMaritalStatusData(): Observable<string[]> {
    return this._metadataDao.getMaritalStatusData();
  }

  getMaritalStatusLoaded(): Observable<boolean> {
    return this._metadataDao.getMaritalStatusLoaded();
  }

  getMaritalStatusLoading(): Observable<boolean> {
    return this._metadataDao.getMaritalStatusLoading();
  }

  loadStateSpecificFlags(): void {
    this._metadataDao.loadStateSpecificFlags();
  }

  getStateSpecificFlags(): Observable<StateSpecificFlagsObject> {
    return this._metadataDao.getStateSpecificFlags();
  }

  getStateSpecificFlagsLoaded(): Observable<boolean> {
    return this._metadataDao.getStateSpecificFlagsLoaded();
  }

  getStateSpecificFlagsLoading(): Observable<boolean> {
    return this._metadataDao.getStateSpecificFlagsLoading();
  }

  getStateSpecificFlag(
    name: keyof StateSpecificFlagsObject
  ): Observable<boolean> {
    return this._metadataDao.getStateSpecificFlag(name);
  }

  getStateSpecificFlagsObject(): Observable<StateSpecificFlagsObject> {
    return this._metadataDao.getStateSpecificFlagsObject();
  }

  loadSuffix(): void {
    this._metadataDao.loadSuffix();
  }

  getSuffixData(): Observable<SelectOption[]> {
    return this._metadataDao.getSuffixData();
  }

  getSuffixLoaded(): Observable<boolean> {
    return this._metadataDao.getSuffixLoaded();
  }

  getSuffixLoading(): Observable<boolean> {
    return this._metadataDao.getSuffixLoading();
  }

  getThirdParties(): Observable<string[]> {
    return this._metadataAdapter.getThirdParties();
  }

  getStateAbbreviations(): Observable<string[]> {
    return this._metadataAdapter.getStateAbbreviations();
  }

  loadStateAbbreviations(): void {
    this._metadataDao.loadStateAbbreviations();
  }

  loadActiveStateAbbreviations(): void {
    this._metadataDao.loadActiveStateAbbreviations();
  }

  getStateAbbreviationsData(): Observable<string[]> {
    return this._metadataDao.getStateAbbreviationsData();
  }

  getActiveStateAbbreviationsData(): Observable<string[]> {
    return this._metadataDao.getActiveStateAbbreviationsData();
  }

  getActiveStateAbbreviationsLoaded(): Observable<boolean> {
    return this._metadataDao.getActiveStateAbbreviationsLoaded();
  }

  getActiveStateAbbreviationsLoading(): Observable<boolean> {
    return this._metadataDao.getActiveStateAbbreviationsLoading();
  }

  getStateAbbreviationsLoaded(): Observable<boolean> {
    return this._metadataDao.getStateAbbreviationsLoaded();
  }

  getStateAbbreviationsLoading(): Observable<boolean> {
    return this._metadataDao.getStateAbbreviationsLoading();
  }

  getAgencyChannel(): Observable<string[]> {
    return this._metadataAdapter.getAgencyChannel();
  }

  loadAgencyChannel(): void {
    this._metadataDao.loadAgencyChannel();
  }

  getAgencyChannelsData(): Observable<string[]> {
    return this._metadataDao.getAgencyChannelData();
  }

  getAgencyChannelLoaded(): Observable<boolean> {
    return this._metadataDao.getAgencyChannelLoaded();
  }

  getAgencyChannelLoading(): Observable<boolean> {
    return this._metadataDao.getAgencyChannelLoading();
  }

  getFeaturesLoaded(): Observable<boolean> {
    return this._metadataDao.getFeaturesLoaded();
  }

  loadFeatures(): void {
    this._metadataDao.loadFeatures();
  }

  getFeaturesData(): Observable<any> {
    return this._metadataDao.getFeaturesData();
  }

  getFeatures(): Observable<any> {
    return this._metadataAdapter.getFeatures();
  }

  getConnectedCarCriteriaLoaded(): Observable<boolean> {
    return this._metadataDao.getConnectedCarCriteriaLoaded();
  }

  loadConnectedCarCriteria(identifier: string): void {
    this._metadataDao.loadConnectedCarCriteria(identifier);
  }

  getConnectedCarCriteriaData(): Observable<ConnectedCarCriteria[]> {
    return this._metadataDao.getConnectedCarCriteriaData();
  }

  getConnectedCarCriteria(): Observable<any> {
    return this._metadataAdapter.getConnectedCarCriteria();
  }

  getAntiTheftDevices(): Observable<AntiTheftDevices> {
    return this._metadataDao.getAntiTheftDevicesLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._metadataDao.loadAntiTheftDevices();
        }
      }),
      filter(loaded => loaded),
      switchMap(_ => this._metadataDao.getAntiTheftDevices())
    );
  }

  getVehicleEligibleDiscounts(): Observable<EligibleDiscountEntity[]> {
    return combineLatest([
      this._metadataDao.getVehicleEligibleDiscountsLoaded(),
      this._metadataDao.getVehicleEligibleDiscountsLoading(),
    ]).pipe(
      tap(([loaded, loading]) => {
        if (!loaded && !loading) {
          this._metadataDao.loadVehicleEligibleDiscounts();
        }
      }),
      filter(([loaded, _]) => loaded),
      switchMap(([_, _2]) => this._metadataDao.getVehicleEligibleDiscounts())
    );
  }

  getVehiclePrimaryUseOptions(): Observable<VehiclePrimaryUseOptions> {
    return this._metadataDao.getVehiclePrimaryUseOptionsLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._metadataDao.loadVehiclePrimaryUseOptions();
        }
      }),
      filter(loaded => loaded),
      switchMap(_ => this._metadataDao.getVehiclePrimaryUseOptions())
    );
  }

  loadAntiTheftDevices(): Observable<AntiTheftDevices> {
    return this._metadataAdapter.getAntiTheftDevices();
  }

  loadVehicleEligibleDiscounts(): Observable<EligibleDiscountEntity[]> {
    return this._metadataAdapter.getVehicleEligibleDiscounts();
  }

  loadVehiclePrimaryUseOptions(): Observable<VehiclePrimaryUseOptions> {
    return this._metadataAdapter.getVehiclePrimaryUseOptions();
  }
}
