import { Injectable } from '@angular/core';
import { NonStandardAdapter } from '@core/adapters/non-standard.adapter';
import { NonStandardBridgeDao } from '@core/dao/non-standard-bridge.dao';
import { Observable } from 'rxjs';
import { NatGenResponse } from '@core/models/non-standard/non-standard.model';

@Injectable({
  providedIn: 'root',
})
export class NonStandardBridgeService {
  constructor(
    private _nonStandardAdapter: NonStandardAdapter,
    private _nonStandardBridgeDao: NonStandardBridgeDao
  ) {}

  isNonStandardBridgeCode(errorCode): Observable<string> {
    return this._nonStandardBridgeDao.isNonStandardCode(errorCode);
  }

  isNonStandardBridge(): Observable<string> {
    return this._nonStandardBridgeDao.isNonStandard();
  }

  nonStandardBridge(
    natGenRequest,
    accessToken,
    sessionId
  ): Observable<NatGenResponse> {
    return this._nonStandardAdapter.getNonStandardBridgeLink(
      natGenRequest,
      accessToken,
      sessionId
    );
  }
}
