import { AddressAdapter } from './address.adapter';
import { AutoAdapter } from './auto.adapter';
import { CaptchaAdapter } from './captcha.adapter';
import { LoggingAdapter } from './logging.adapter';
import { LoggingCloudAdapter } from './logging-cloud.adapter';
import { MetadataAdapter } from './metadata.adapter';
import { RentersAdapter } from './renters.adapter';
import { BillingAdapter } from './billing.adapter';
import { PlaidAdapter } from './plaid.adapter';
import { PrivateLabelAdapter } from './private-label.adapter';
import { HomeownerPropertyInfoMetadataAdapter } from './ho-propertyInfo-metadata.adapter';
import { CmaAdapter } from './cma.adapter';
import { PageAlertAdapter } from './page-alert.adapter';
import { PowersportsAdapter } from './powersports.adapter';
import { NonStandardAdapter } from './non-standard.adapter';
import { DistributedDocumentsAdapter } from './distributed-documents.adapter';
import { DistributionPartnerManagementAdapter } from './distribution-partner-management.adapter';
import { KafkaAdapter } from './kafka.adapter';
import { PropertyInfoMetadataAdapter } from './property-metadata.adapter';

export const adapters: any = [
  AddressAdapter,
  AutoAdapter,
  CaptchaAdapter,
  LoggingAdapter,
  LoggingCloudAdapter,
  MetadataAdapter,
  RentersAdapter,
  BillingAdapter,
  PlaidAdapter,
  PrivateLabelAdapter,
  HomeownerPropertyInfoMetadataAdapter,
  PropertyInfoMetadataAdapter,
  CmaAdapter,
  PageAlertAdapter,
  PowersportsAdapter,
  NonStandardAdapter,
  DistributedDocumentsAdapter,
  DistributionPartnerManagementAdapter,
  KafkaAdapter,
];

export * from './address.adapter';
export * from './auto.adapter';
export * from './captcha.adapter';
export * from './logging.adapter';
export * from './logging-cloud.adapter';
export * from './metadata.adapter';
export * from './renters.adapter';
export * from './billing.adapter';
export * from './plaid.adapter';
export * from './private-label.adapter';
export * from './ho-propertyInfo-metadata.adapter';
export * from './property-metadata.adapter';
export * from './cma.adapter';
export * from './page-alert.adapter';
export * from './powersports.adapter';
export * from './non-standard.adapter';
export * from './distribution-partner-management.adapter';
export * from './kafka.adapter';
