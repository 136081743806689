import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {
  switchMap,
  take,
  tap,
  catchError,
  map,
  concatMap,
  mergeMap,
  debounceTime,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services';
import { PolicyIssueService } from '@core/services/policy-issue.service';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable()
export class PolicyIssueEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _policyIssueService: PolicyIssueService,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper
  ) {}

  readonly ISSUE_POLICY_DEBOUNCE = 500;

  @Effect()
  issuePolicy$ = this._actions$.pipe(
    ofType(fromActions.PolicyIssueTypes.ISSUE_POLICY),
    debounceTime(this.ISSUE_POLICY_DEBOUNCE),
    switchMap(() =>
      this._store.select(fromSelectors.buildPolicyIssueRequests).pipe(take(1))
    ),
    tap(request => this._loggingService.log('issuePolicy Requests:', request)),
    mergeMap(requests => from(requests)),
    concatMap(request => {
      return this._policyIssueService.policies(request).pipe(
        map(response => {
          this._loggingService.log('issuePolicy Response', response);
          return new fromActions.IssuePolicySuccess(response);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          safeError['quoteId'] = request.quoteId;
          this._loggingService.log('issuePolicy Error', safeError);
          return of(
            new fromActions.IssuePolicyFail(request.productId, safeError)
          );
        })
      );
    })
  );

  @Effect()
  markProductIssuing$ = this._actions$.pipe(
    ofType(fromActions.PolicyIssueTypes.ISSUE_POLICY),
    switchMap(() => this._store.select(fromSelectors.buildPolicyIssueRequests)),
    take(1),
    mergeMap(requests =>
      from(
        requests.map(request => {
          return new fromActions.MarkProductIssuing(request.productId);
        })
      )
    )
  );
}
