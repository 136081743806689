import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CaptchaAdapter } from '@core/adapters/captcha.adapter';

import { RemoteIpResponse } from '@core/models/captcha/remote-ip-response.model';
import { ReCaptchav2Request } from '@core/models/captcha/captcha-api-request.model';
import { AppConfigService } from './app-config.service';

@Injectable()
export class CaptchaService {
  request: ReCaptchav2Request;
  constructor(
    private _captchaAdapter: CaptchaAdapter,
    private _appConfigService: AppConfigService
  ) {}

  post(captchaToken: string, remoteIP: string): Observable<any> {
    this.request = this.buildReCaptchaRequest(captchaToken, remoteIP);
    return this._captchaAdapter.newPost(this.request);
  }

  getIP(): Observable<RemoteIpResponse> {
    return this._captchaAdapter.getIP();
  }

  buildReCaptchaRequest(
    captchaToken: string,
    remoteIP: string
  ): ReCaptchav2Request {
    return {
      event: {
        token: captchaToken,
        siteKey: this._appConfigService.config.captchaSiteToken,
        userIpAddress: remoteIP,
      },
    };
  }
}
