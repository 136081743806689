import { AddressDao } from './address.dao';
import { AgencyDao } from './agency.dao';
import { HomeownersDao } from './homeowners.dao';
import { CondoDao } from './condo.dao';
import { LendersDao } from './lenders.dao';
import { LoadingDao } from './loading.dao';
import { MetadataDao } from './metadata.dao';
import { PolicyDao } from './policy.dao';
import { ProductsDao } from './products.dao';
import { RentersDao } from './renters.dao';
import { SessionDao } from './session.dao';
import { BillingDao } from './billing.dao';
import { PlaidDao } from './plaid.dao';
import { PrivateLabelDao } from './private-label.dao';
import { UserContextDao } from './user-context.dao';
import { CmaDao } from './cma.dao';
import { PageAlertDao } from './page-alert.dao';
import { ConsentValuesDao } from './consent-values.dao';
import { PreBindDocumentsDao } from './pre-bind-documents.dao';
import { TelematicsEnrollmentsDao } from './telematics-enrollments.dao';
import { QuoteLetterDao } from './quote-letter.dao';
import { PrepopulatedInsuredDao } from './prepopulated-insured.dao';
import { DigitalIdCardsTextDao } from './digital-id-cards-text.dao';
import { AccountDao } from './account.dao';
import { TermLifeDao } from './termlife.dao';
import { PartialQuoteDao } from './partial-quote.dao';

export const daos: any[] = [
  AddressDao,
  AgencyDao,
  HomeownersDao,
  CondoDao,
  LendersDao,
  LoadingDao,
  MetadataDao,
  ProductsDao,
  RentersDao,
  SessionDao,
  PolicyDao,
  BillingDao,
  PlaidDao,
  PrivateLabelDao,
  UserContextDao,
  CmaDao,
  PageAlertDao,
  ConsentValuesDao,
  PreBindDocumentsDao,
  TelematicsEnrollmentsDao,
  QuoteLetterDao,
  PrepopulatedInsuredDao,
  DigitalIdCardsTextDao,
  AccountDao,
  TermLifeDao,
  PartialQuoteDao,
];

export * from './address.dao';
export * from './agency.dao';
export * from './homeowners.dao';
export * from './condo.dao';
export * from './lenders.dao';
export * from './loading.dao';
export * from './metadata.dao';
export * from './policy.dao';
export * from './products.dao';
export * from './renters.dao';
export * from './session.dao';
export * from './billing.dao';
export * from './plaid.dao';
export * from './private-label.dao';
export * from './user-context.dao';
export * from './cma.dao';
export * from './page-alert.dao';
export * from './consent-values.dao';
export * from './pre-bind-documents.dao';
export * from './telematics-enrollments.dao';
export * from './quote-letter.dao';
export * from './prepopulated-insured.dao';
export * from './digital-id-cards-text.dao';
export * from './account.dao';
export * from './termlife.dao';
export * from './partial-quote.dao';
