import { Injectable } from '@angular/core';
import { PolicyIssueRequest } from '@core/models/policy-issue/policy-issue-request.model';
import { Observable } from 'rxjs';
import { PolicyIssueAdapter } from '@core/adapters/policy-issue.adapter';
import { PolicyIssueDao } from '@core/dao/policy-issue.dao';
import { PolicyIssueResponse } from '@core/models/policy-issue/policy-issue-response.model';

@Injectable({
  providedIn: 'root',
})
export class PolicyIssueService {
  constructor(
    private _policyIssueAdapter: PolicyIssueAdapter,
    private _policyIssueDao: PolicyIssueDao
  ) {}

  policies(request: PolicyIssueRequest): Observable<PolicyIssueResponse> {
    return this._policyIssueAdapter.policies(request);
  }

  dispatchIssuePolicies(): void {
    this._policyIssueDao.dispatchIssuePolicies();
  }
}
