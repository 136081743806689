import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';

import { AppConfigService } from '@core/services/app-config.service';

export interface LicenseValidaion {
  successStatus: boolean;
  form: null;
  modelObjects: null;
}

@Injectable()
export class PowersportsAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  validateLicense(licenseNumber: string, licenseState: string) {
    const url = `${this._appConfig.config.powersportsApiUrl}/drivers/validatelicense `;

    const headers = new HttpHeaders()
      .set('client_id', this._appConfig.config.apiKey)
      .set('x-nw-transaction-id', Date.now().toString());

    return this._http
      .post<LicenseValidaion>(url, { licenseNumber, licenseState }, { headers })
      .pipe(catchError((error: any) => observableThrowError(error)));
  }
}
