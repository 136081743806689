import { Directive, OnInit, Input, ElementRef } from '@angular/core';
import { FeatureService } from '@core/services/feature.service';

@Directive({
  selector: '[removeIfFeatureNotEffective]',
})
export class RemoveIfFeatureNotEffectiveDirective implements OnInit {
  @Input('removeIfFeatureNotEffective') featureName: string;

  constructor(
    private el: ElementRef,
    private _featureService: FeatureService
  ) {}

  ngOnInit(): void {
    if (this._featureService.featureNotEffective(this.featureName)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }
}
