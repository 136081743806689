import { Effect, ofType, Actions } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { of } from 'rxjs';
import { map, take, tap, catchError, filter, mergeMap } from 'rxjs/operators';

import { ProductTypes } from '@shared/constants/app-constants';

import { UnderlyingPolicyService } from '@core/services/underlying-policy.service';
import { LoggingService, ProductsService } from '@core/services';
import { Injectable } from '@angular/core';
import { TokenService } from '@core/services/token.service';

@Injectable()
export class UnderlyingPolicyEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _underlyingPolicyService: UnderlyingPolicyService,
    private _loggingService: LoggingService,
    private _tokenService: TokenService,
    private _productsService: ProductsService
  ) {}

  @Effect()
  addUnderlyingPolicy$ = this._actions$.pipe(
    ofType(fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_SUCCESS),
    map((actions: fromActions.InitiateNewBusinessSuccess) => actions.productId),
    filter(product => product !== ProductTypes.UMBRELLA),
    mergeMap(product => {
      return this._store.select(fromSelectors.getSelectedProducts).pipe(
        take(1),
        mergeMap(products => of({ product, products }))
      );
    }),
    filter(products => {
      // this._productsService.isEligibleForUmbrella(products.products)
      const umbrella = products.products.find(
        product => product.id === ProductTypes.UMBRELLA
      );
      return !!umbrella;
    }),
    mergeMap(products =>
      this._tokenService.getAccessTokenForProductId(ProductTypes.UMBRELLA).pipe(
        filter(token => !!token),
        take(1),
        mergeMap(() => of(products.product))
      )
    ),
    mergeMap(product => {
      return this._store
        .select(fromSelectors.buildUnderlyingPolicyRequest(product))
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('addUnderlyingPolicy request', request)
    ),
    mergeMap(request =>
      this._underlyingPolicyService.underlyingPolicies(request).pipe(
        tap(response =>
          this._loggingService.log('addUnderlyingPolicy response', response)
        ),
        map(response => new fromActions.AddUnderlyingPolicySuccess(response)),
        catchError(error => {
          this._loggingService.log('addUnderlyingPolicy error', error);
          return of(new fromActions.AddUnderlyingPolicyFail(error));
        })
      )
    )
  );
}
