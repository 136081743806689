<form [formGroup]="form" (ngSubmit)="onSubmit(form)">
  <div class="row">
    <div class="form-group col-sm-4" id="yearBuilt-error">
      <label for="pi-year-build"> Year built </label>
      <div class="input-group">
        <input
          id="pi-year-build"
          type="text"
          class="form-control"
          placeholder="YYYY"
          mask="0000"
          aria-describedby="yearBuilt-error"
          formControlName="yearBuilt"
        />
          <div class="input-group-text"></div>
      </div>
    </div>
    <div class="form-group col-sm-4" id="squareFootage-error">
      <label for="pi-square-footage"> Square footage </label>
      <div class="input-group">
        <input
          id="pi-square-footage"
          type="text"
          pattern="\d{1,4}"
          class="form-control"
          aria-describedby="squareFootage-error"
          formControlName="squareFootage"
          placeholder="Sq. ft."
        />
          <div class="input-group-text"></div>
      </div>
    </div>
    <div class="form-group col-sm-4">
      <label for="pi-numberOfStories"> Number of stories </label>
      <div class="input-group">
        <select
          id="pi-numberOfStories"
          class="form-control form-select"
          attr.aria-describedby="numberOfStories-error"
          formControlName="numberOfStories"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let stories of options?.numberOfStories"
            value="{{ stories.value }}"
          >
            {{ stories.label }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="col-12">
      <h1 class="heading-h4">The home's structural details</h1>
    </div>

    <div class="form-group col-sm-4">
      <div
        id="inline-help-{{ constructionTypeFieldId }}"
        [style.color]="'black'"
      >
        <inline-help
          [labelInnerHTML]="constructionTypelabelText"
          [helpTextInnerHTML]="constructionTypeHelpText"
          [fieldId]="constructionTypeFieldId"
        ></inline-help>
      </div>
      <div class="input-group">
        <select
          id="pi-constructionType"
          class="form-control form-select"
          attr.aria-describedby="constructionType-error"
          formControlName="constructionType"
          aria-label="Construction Type"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="
              let type of options?.homeStructuralDetails?.constructionTypes
            "
            value="{{ type.value }}"
          >
            {{ type.label }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-4">
      <div id="inline-help-{{ exteriorWallsFieldId }}" [style.color]="'black'">
        <inline-help
          [labelInnerHTML]="exteriorWallslabelText"
          [helpTextInnerHTML]="exteriorWallsHelpText"
          [fieldId]="exteriorWallsFieldId"
        ></inline-help>
      </div>
      <div class="input-group">
        <select
          id="pi-exteriorWalls"
          class="form-control form-select"
          attr.aria-describedby="exteriorWalls-error"
          formControlName="exteriorWalls"
          aria-label="Exterior Walls"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let wall of options?.homeStructuralDetails?.exteriorWalls"
            value="{{ wall.value }}"
          >
            {{ wall.label }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-4">
      <div id="inline-help-{{ roofMaterialFieldId }}" [style.color]="'black'">
        <inline-help
          [labelInnerHTML]="roofMateriallabelText"
          [helpTextInnerHTML]="roofMaterialHelpText"
          [fieldId]="roofMaterialFieldId"
        ></inline-help>
      </div>
      <div class="input-group">
        <select
          id="pi-roofMaterial"
          class="form-control form-select"
          attr.aria-describedby="roofMaterial-error"
          formControlName="roofMaterial"
          aria-label="Roof Material"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let roof of options?.homeStructuralDetails?.roofMaterial"
            value="{{ roof.value }}"
          >
            {{ roof.label }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-4" *ngIf="showFlatRoof">
      <label for="pi-flatRoof"> Flat Roof </label>
      <div class="input-group">
        <select
          id="pi-flatRoof"
          class="form-control form-select"
          attr.aria-describedby="flatRoof-error"
          formControlName="flatRoof"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let roof of options?.homeStructuralDetails?.flatRoof"
            value="{{ roof.value }}"
          >
            {{ roof.label }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-12">
      <div
        id="inline-help-{{ foundationTypesFieldId }}"
        [style.color]="'black'"
      >
        <inline-help
          [labelInnerHTML]="foundationTypeslabelText"
          [helpTextInnerHTML]="foundationTypesHelpText"
          [fieldId]="foundationTypesFieldId"
        ></inline-help>
      </div>
      <div class="d-flex flex-wrap">
        <ng-container
          *ngFor="
            let foundation of options?.homeStructuralDetails?.foundationTypes
          "
        >
          <nw-toggle-pill
            id="pi-foundationTypes-{{ foundation?.label }}"
            label="{{ foundation?.label }}"
            eventName="{{ foundation?.value }}"
            [trueValue]="true"
            [falseValue]="false"
            [defaultValue]="(foundationType?.value)[foundation?.value]"
            (pillToggled)="onFoundationTypePillToggled($event)"
            [showHelp]="false"
            [disabled]="!needConstructionInfo && !reviewConstructionInfo"
          >
          </nw-toggle-pill>
        </ng-container>
      </div>
    </div>

    <div class="col-12">
      <h1 class="heading-h4">The home's features</h1>
    </div>

    <div class="form-group col-sm-6">
      <label for="pi-numberOfHalfBathrooms"> Number of half baths </label>
      <div class="input-group">
        <select
          id="pi-numberOfHalfBathrooms"
          class="form-control form-select"
          attr.aria-describedby="numberOfHalfBathrooms-error"
          formControlName="numberOfHalfBathrooms"
        >
        <option value="" disabled="disabled">Please Select</option>

          <option
            *ngFor="
              let halfbath of options?.homeFeatures?.numberOfHalfBathrooms
            "
            value="{{ halfbath }}"
          >
            {{ halfbath }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-6">
      <div
        id="inline-help-{{ descriptionOfHalfBathsFieldId }}"
        [style.color]="'black'"
      >
        <inline-help
          [labelInnerHTML]="descriptionOfHalfBathslabelText"
          [helpTextInnerHTML]="descriptionOfHalfBathsHelpText"
          [fieldId]="descriptionOfHalfBathsFieldId"
        ></inline-help>
      </div>
      <div class="input-group">
        <select
          id="pi-halfBathroomDescription"
          class="form-control form-select"
          attr.aria-describedby="halfBathroomDescription-error"
          formControlName="halfBathroomDescription"
          aria-label="Half Bathroom Description"
        >
          <ng-container *ngIf="numberOfHalfBathrooms?.value === '0' || numberOfHalfBathrooms?.value === 0">
            <option value="" [selected]="true" disabled="disabled">Not Applicable</option>
          </ng-container>
          <ng-container *ngIf="numberOfHalfBathrooms?.value === '' || numberOfHalfBathrooms.value > 0">
            <option value="" disabled="disabled">Please Select</option>
            <option *ngFor="let hbDescription of options?.homeFeatures?.description" value="{{ hbDescription }}">
              {{ hbDescription }}
            </option>
          </ng-container>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-6">
      <label for="pi-numberOfFullBathrooms"> Number of full baths </label>
      <div class="input-group">
        <select
          id="pi-numberOfFullBathrooms"
          class="form-control form-select"
          attr.aria-describedby="numberOfFullBathrooms-error"
          formControlName="numberOfFullBathrooms"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let fullbath of options?.homeFeatures?.numberOfFullbaths"
            value="{{ fullbath }}"
          >
            {{ fullbath }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-6">
      <div
        id="inline-help-{{ descriptionOfFullBathsFieldId }}"
        [style.color]="'black'"
      >
        <inline-help
          [labelInnerHTML]="descriptionOfFullBathslabelText"
          [helpTextInnerHTML]="descriptionOfFullBathsHelpText"
          [fieldId]="descriptionOfFullBathsFieldId"
        ></inline-help>
      </div>
      <div class="input-group">
        <select
          id="pi-fullBathroomDescription"
          class="form-control form-select"
          attr.aria-describedby="fullBathroomDescription-error"
          formControlName="fullBathroomDescription"
          aria-label="Full Bathroom Description"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let fbDescription of options?.homeFeatures?.description"
            value="{{ fbDescription }}"
          >
            {{ fbDescription }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-6">
      <label for="pi-numberOfKitchens"> Number of kitchens </label>
      <div class="input-group">
        <select
          id="pi-numberOfKitchens"
          class="form-control form-select"
          attr.aria-describedby="numberOfKitchens-error"
          formControlName="numberOfKitchens"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let Kitchen of options?.homeFeatures?.numberOfKitchens"
            value="{{ Kitchen }}"
          >
            {{ Kitchen }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-6">
      <div
        id="inline-help-{{ descriptionOfKitchensFieldId }}"
        [style.color]="'black'"
      >
        <inline-help
          [labelInnerHTML]="descriptionOfKitchenslabelText"
          [helpTextInnerHTML]="descriptionOfKitchensHelpText"
          [fieldId]="descriptionOfKitchensFieldId"
        ></inline-help>
      </div>
      <div class="input-group">
        <select
          id="pi-kitchenDescription"
          class="form-control form-select"
          attr.aria-describedby="kitchenDescription-error"
          formControlName="kitchenDescription"
          aria-label="Kitchen Description"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let kDescription of options?.homeFeatures?.description"
            value="{{ kDescription }}"
          >
            {{ kDescription }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-6" *ngIf="showAdditionalHeating">
      <div
        id="inline-help-{{ additionalHeatingFieldId }}"
        [style.color]="'black'"
      >
        <inline-help
          [labelInnerHTML]="additionalHeatinglabelText"
          [helpTextInnerHTML]="additionalHeatingHelpText"
          [fieldId]="additionalHeatingFieldId"
        ></inline-help>
      </div>
      <div class="input-group">
        <select
          id="pi-additionalHeating"
          class="form-control form-select"
          attr.aria-describedby="additionalHeating-error"
          formControlName="additionalHeating"
          aria-label="Additional Heating"
        >
          <option value="" disabled="disabled">Please Select</option>
          <option
            *ngFor="let heating of options?.homeFeatures?.additionalHeating"
            value="{{ heating.value }}"
          >
            {{ heating.label }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="col-12">
      <h1 class="heading-h4">Additional structures</h1>
    </div>

    <div class="form-group col-sm-6">
      <div id="inline-help-{{ typeOfGarageFieldId }}" [style.color]="'black'">
        <inline-help
          [labelInnerHTML]="typeOfGaragelabelText"
          [helpTextInnerHTML]="typeOfGarageHelpText"
          [fieldId]="typeOfGarageFieldId"
        ></inline-help>
      </div>
      <div class="input-group">
        <select
          id="pi-garageType"
          class="form-control form-select"
          attr.aria-describedby="garageType-error"
          formControlName="garageType"
          aria-label="Garage Type"
          (change)="onGarageTypeChange(garageType.value)"
        >
          <option value="">Please Select</option>
          <option
            *ngFor="let type of options?.additionalStructures?.typeOfGarage"
            value="{{ type }}"
          >
            {{ type }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-6">
      <label for="pi-numberOfCars"> Number of cars parked in the garage </label>
      <div class="input-group">
        <select
          id="pi-numberOfCars"
          class="form-control form-select"
          attr.aria-describedby="numberOfCars-error"
          formControlName="numberOfCars"
        >
        <!--OPTION 1 Default -->
        <option *ngIf="!garageTypeNotApplicable" value="">
          Please Select
        </option>
        <!--OPTION 2 N/A Selected-->
        <option *ngIf="garageTypeNotApplicable" value="">
          Not Applicable
        </option>
        <!--OPTION 3 # of Cars-->   
        <option
            *ngFor="
              let num of options?.additionalStructures?.numberOfCars
                | filterNumberOfGarageCars: garageType.value
            "
            value="{{ num }}"
          >
            {{ num }}
          </option>
        </select>
          <div class="input-group-text"></div>
      </div>
    </div>

    <div class="form-group col-sm-12">
      <div
        id="inline-help-{{ additionalStructuresFieldId }}"
        [style.color]="'black'"
      >
        <inline-help
          [labelInnerHTML]="additionalStructureslabelText"
          [helpTextInnerHTML]="additionalStructuresHelpText"
          [fieldId]="additionalStructuresFieldId"
        ></inline-help>
      </div>
      <div class="d-flex flex-wrap">
        <ng-container
          *ngFor="let structure of options?.additionalStructures?.structures"
        >
          <nw-toggle-pill
            id="pi-structures-{{ structure?.label }}"
            label="{{ structure?.label }}"
            eventName="{{ structure?.value }}"
            [trueValue]="true"
            [falseValue]="false"
            [defaultValue]="(otherStructures?.value)[structure?.value]"
            (pillToggled)="onStructuresPillToggled($event)"
            [showHelp]="false"
            [disabled]="!needConstructionInfo && !reviewConstructionInfo"
          >
          </nw-toggle-pill>
        </ng-container>
      </div>
    </div>
  </div>

  <div>
    <nw-display-panel-action-buttons
      (cancel)="onCancel()"
    ></nw-display-panel-action-buttons>
  </div>
</form>
