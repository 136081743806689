import * as fromActions from '@core/store/actions/escrow.action';

import { EscrowAccount } from '@core/models/entities/escrow-account.entity';

export type EscrowState = EscrowAccount;

export function reducer(
  state = <EscrowAccount>{
    status: 'none',
    response: null,
  },
  action: fromActions.EscrowActions
): EscrowAccount {
  switch (action.type) {
    case fromActions.EscrowActionTypes.ADD_ESCROW_ACCOUNT: {
      return {
        ...state,
        response: action.payload.response,
      };
    }

    case fromActions.EscrowActionTypes.ESTABLISH_ESCROW_ACCOUNT: {
      return {
        ...state,
        status: 'pending',
      };
    }

    case fromActions.EscrowActionTypes.ESTABLISH_ESCROW_ACCOUNT_SUCCESS: {
      return {
        ...state,
        status: 'success',
        response: action.payload,
      };
    }

    case fromActions.EscrowActionTypes.ESTABLISH_ESCROW_ACCOUNT_FAILURE: {
      return {
        ...state,
        status: 'error',
        response: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
