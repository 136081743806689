import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import { ProductDisplay } from '@core/models/display/quote-coverages/products/product-display.model';
import {
  CoverageEntity,
  TermAmount,
} from '@core/models/entities/coverage.entity';
import { DiscountEntity } from '@core/models/entities/discount.entity';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { PremiumEntity } from '@core/models/entities/premium.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import {
  CoverageConfiguration,
  PrivateLabel,
} from '@core/models/private-label/private-label.model';
import { Product } from '@core/models/products/product.model';
import {
  PreQualifiedVehicleInfo,
  TelematicsEnrollmentResponse,
} from '@core/models/telematics/telematics.model';
import { TermLifeEntity } from '@core/models/termlife/termlife.entity';
import { LoadingService, SessionService } from '@core/services';
import { CoverageService } from '@core/services/coverage.service';
import { ErrorMessageService } from '@core/services/error-message.service';
import { CoverageHelper } from '@core/services/helpers/coverage.helper';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { PrivateLabelCoverageHelper } from '@core/services/helpers/private-label-coverage.helper';
import { ModifiersService } from '@core/services/modifiers.service';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';
import { CustomValidators } from '@core/validators/custom-validators';
import { UmbrellaComponent } from '@help-content/components';
import { HelpContentService } from '@help-content/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animations } from '@shared/animations/animations';
import { ProductsListComponent } from '@shared/components/products/products-list/products-list.component';
import {
  AFMVF_SMARTRIDE_NOTICE_VERBIAGE,
  CoverageCodes,
  CoverageIds,
  DEFAULT_FACEAMOUNT,
  ProductTypes,
  TelematicsEnrollmentStatus,
  TERM_LIFE_DEFAULT_TEN_YEAR,
  TERMLIFE_TERM_MONTHS,
  RatingTypes,
} from '@shared/constants/app-constants';
import { CustomUIElements } from '@shared/constants/private-label-constants';
import { BaseCoveragesProxy } from '@shared/proxies/base-coverages.proxy';
import { DiscountNameHelper } from '@shared/utils/discount-name.helper';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';
import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import {
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs/operators';

import { TelematicsConfirmationModalComponent } from '../telematics-enrollments/telematics-confirmation-modal/telematics-confirmation-modal.component';

@Component({
  selector: 'mq-base-coverage',
  templateUrl: './base-coverage.component.html',
  styleUrls: ['./base-coverage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseCoverageComponent implements OnInit, OnDestroy {
  public static animations = [
    Animations.slideInOut,
    Animations.slideInOutParent,
    Animations.slideUpDownProductChild,
    Animations.slideInOutChild,
  ];

  protected _ngUnsubscribe = new Subject();

  policyHolder$: Observable<PolicyholderEntity>;
  policyHolders$: Observable<PolicyholderEntity[]>;
  coverages$: Observable<CoverageEntity[]>;
  coverageErrors$: Observable<CoverageError[]>;
  selectedProduct$: Observable<Product>;
  allProducts$: Observable<Product[]>;
  productDisplays$: Observable<Product[]>;
  totalPremiumValue$: Observable<number>;
  selectedProductDiscounts$: Observable<DiscountEntity[]>;
  stateSpecificFlags$: Observable<any>;
  telematicsEnrollments$: Observable<TelematicsEnrollmentResponse>;
  isConnectedCarEnabled$: Observable<boolean>;
  privateLabelConfig$: Observable<PrivateLabel>;
  preQualifiedVehicleInfo$: Observable<PreQualifiedVehicleInfo[]>;
  quoteLetterHidden$: Observable<boolean>;
  isQuoteRetrieve$: Observable<boolean>;
  privateLabelIdentifier$: Observable<string>;
  fullPayPremiumValue$: Observable<number>;

  protected _baseProxy: BaseCoveragesProxy;
  protected coveragePremiums$: Observable<PremiumEntity[]>;
  protected vehicles$: Observable<VehicleEntity[]>;
  protected powersportsVehicles$: Observable<VehicleEntity[]>;
  protected drivers$: Observable<DriverEntity[]>;
  protected discounts$: Observable<DiscountEntity[]>;
  protected powersportsDiscounts$: Observable<DiscountEntity[]>;
  protected termLife$: Observable<TermLifeEntity[]>;
  protected products: ProductDisplay[];
  public selectedProductsWithoutErrors$: Observable<Product[]>;
  protected showCoveragePrices$: Observable<boolean>;
  protected selectedProductPremium$: Observable<PremiumEntity>;
  protected isAFMVFApplicable$: Observable<boolean>;
  protected smartride$: Observable<boolean>;
  protected quoteState$: Observable<string>;
  protected smartride: boolean;
  protected direction = 'new';
  protected form = this._fb.group({
    products: [[], [CustomValidators.IsUmbrellaValid]],
  });
  coveragesForm;
  protected coverageChanges$: Observable<CoverageChange[]>;
  protected extraUpdatedCoverage$: Observable<string>;
  protected helpContent: {
    smartride?: any;
    vehicleSmartride?: any;
    selfreported?: any;
    connectedCar?: any;
  } = {};
  protected isConnectedCarAndHasEnrollment$: Observable<boolean>;
  public quoteLetter$: Observable<string>;
  public isQuoteValid$: Observable<boolean>;
  public isBindValid$: Observable<boolean>;
  public defaultDividerColor = '#336093';
  public ACCIDENT_FORGIVENESS = 'AF';
  public MINOR_VIOLATION_FORGIVENESS = 'MVF';
  public isAFMVFApplicable: boolean;
  public showSmartRideAfmvfNotice = false;
  public additionalPolicyNotification = '';
  public loadProgramsOpen = false;
  public totalCostEstimate = 0;
  umbrellaInitiateError$: Observable<string>;

  @ViewChild(ProductsListComponent)
  productsListComponent: ProductsListComponent;

  constructor(
    protected _coverageHelper: CoverageHelper,
    protected _privateLabelCoverageHelper: PrivateLabelCoverageHelper,
    protected _coverageService: CoverageService,
    protected _loadingService: LoadingService,
    protected _fb: UntypedFormBuilder,
    protected _helpContentService: HelpContentService,
    protected _modifiersService: ModifiersService,
    protected _sessionService: SessionService,
    protected _discountNameHelper: DiscountNameHelper,
    protected _errorHelper: ErrorHelper,
    protected _errorMessageService: ErrorMessageService,
    protected _modalService: NgbModal
  ) {}

  ngOnInit(): void {}

  // DSM
  protected handleDsm(
    proxy: BaseCoveragesProxy,
    configCoverages?: CoverageConfiguration[]
  ): void {
    this._baseProxy = proxy;
    this.quoteState$ = this._baseProxy.getQuoteState();
    this.stateSpecificFlags$ = this._baseProxy.getStateSpecificFlags();
    this.helpContent = this._helpContentService.getHelpContent();
    this.policyHolder$ = this._baseProxy.getPolicyHolder();
    this.policyHolders$ = this._baseProxy.getAllPolicyholders();
    this.coveragePremiums$ = this._baseProxy.getAllPremiums();
    this.vehicles$ = this._baseProxy.getAllVehicles();
    this.powersportsVehicles$ = this._baseProxy.getAllPowersportsVehicles();
    this.drivers$ = this._baseProxy.getAllDrivers();
    this.termLife$ = this._baseProxy.getAllTermLifePeople().pipe(
      take(1),
      map(termLifePeople => {
        this.totalCostEstimate = 0;
        if (termLifePeople) {
          termLifePeople.forEach((termLifePerson, i) => {
            if (!termLifePerson.costEstimate) {
              const termLifeEntity = this._coverageHelper.getCostEstimate(
                TERM_LIFE_DEFAULT_TEN_YEAR,
                DEFAULT_FACEAMOUNT,
                termLifePerson
              );
              termLifePerson = {
                ...termLifePerson,
                costEstimate: termLifeEntity.costEstimate,
                coverageTerm: TERM_LIFE_DEFAULT_TEN_YEAR,
                coverageAmount: DEFAULT_FACEAMOUNT,
              };
              termLifePeople[i] = termLifePerson;
            } else {
              termLifePerson = {
                ...termLifePerson,
                costEstimate: termLifePerson.costEstimate,
                coverageTerm: termLifePerson.coverageTerm,
                coverageAmount: termLifePerson.coverageAmount,
              };
              termLifePeople[i] = termLifePerson;
            }
            if (termLifePerson.termLifeEligible) {
              this.totalCostEstimate += termLifePerson.costEstimate;
            }
          });
          const total: TermAmount = {
            amount: +this.totalCostEstimate * TERMLIFE_TERM_MONTHS,
            currency: 'USD',
          };
          this._baseProxy.updatePremium(ProductTypes.TERMLIFE, total);
        }
        return termLifePeople;
      })
    );
    this.coverages$ = this._baseProxy.getCoverages().pipe(
      withLatestFrom(
        this.vehicles$,
        this.quoteState$,
        this._baseProxy.isCompRater(),
        this.stateSpecificFlags$
      ),
      map(
        ([
          coverages,
          vehicles,
          quoteState,
          isCompRater,
          stateSpecificFlags,
        ]) => {
          const filteredCoverages =
            this._privateLabelCoverageHelper.filterCoverageTermOptionsByConfiguredCoverages(
              coverages,
              configCoverages
            );
          return this._coverageHelper.runCoverageRules(
            filteredCoverages,
            vehicles,
            quoteState,
            isCompRater,
            stateSpecificFlags
          );
        }
      ),
      tap(coverages => this.buildCoveragesForm(coverages, configCoverages))
    );
    this.allProducts$ = this._baseProxy.selectAllProducts();
    this.coverageErrors$ = this._baseProxy.getCoverageErrors();
    this.discounts$ = this._baseProxy.getAppliedDiscounts();
    this.powersportsDiscounts$ =
      this._baseProxy.getAppliedPowersportsDiscounts();
    this.selectedProductsWithoutErrors$ =
      this._baseProxy.getSelectedProductsWithoutErrors();

    this.selectedProduct$ = this._baseProxy.getSelectedProductCoverage();
    this.isQuoteValid$ = this._baseProxy
      .getSelectedProductsWithoutErrors()
      .pipe(map(products => products.every(product => product.quoteRated)));

    this.isBindValid$ = this._baseProxy
      .getSelectedProductsWithoutErrors()
      .pipe(map(products => products.every(product => product.bindRated)));

    this.coverageChanges$ = this._coverageService.getCoverageChanges();
    this.extraUpdatedCoverage$ =
      this._coverageService.getExtraUpdatedCoverage();
    this.isAFMVFApplicable$ = this._baseProxy.getStateSpecificFlag(
      'smartrideAcceptAFMVF'
    );
    this.isAFMVFApplicable$
      .pipe(take(1))
      .subscribe(value => (this.isAFMVFApplicable = value));

    this.checkConditionalProducts();
    this.telematicsEnrollments$ = this._baseProxy.getTelematicsEnrollments();
    this.isConnectedCarEnabled$ = this._baseProxy.isConnectedCarEnabled();
    this.quoteLetter$ = this._baseProxy.getQuoteLetter();
    this.privateLabelConfig$ = this._baseProxy.getPrivateLabelConfig();
    this.privateLabelIdentifier$ = this.privateLabelConfig$.pipe(
      map(config => (config ? config.identifier : null))
    );
    this.isConnectedCarAndHasEnrollment$ =
      this._baseProxy.isConnectedCarEligibleAndHasAnyEnrollment();
    this.preQualifiedVehicleInfo$ =
      this._baseProxy.getPreQualifiedVehicleInfo();
    this.quoteLetterHidden$ = this.shouldHideQuoteLetter();
    this.isQuoteRetrieve$ = this._baseProxy.isQuoteRetrieve();
    this.umbrellaInitiateError$ = this._baseProxy.getUmbrellaInitiateError();
    this.initProductDisplays();
    this.initTotalPremiumValue();
    this.initSelectedProductPremium();
    this.initShowCoveragePricesForProduct();
    this.initSelectedProductDiscounts();
    this.initFullPayPremiumValue();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  public selectProductCoverages(event: ProductDisplay): void {
    this._baseProxy.updateSelectedProductCoverage(event.id);
  }

  protected productSelected(event: {
    product: Product;
    products: ProductDisplay[];
  }): void {
    const selectedProductIds = event.products
      .filter(product => !product.hasError)
      .map(product => product.id);
    this._baseProxy.updateSelectedCoverageProducts(selectedProductIds);
  }

  public coverageIsSelected(
    coverageChanges: CoverageChange[],
    coverageCode: string
  ): boolean {
    const coverages = coverageChanges.filter(
      coverage => coverage.coverageId === coverageCode
    );
    return coverages.every(
      coverage =>
        !!coverage.selectedValue.find(
          selected => selected.code === 'selected' && selected.value === 'true'
        )
    );
  }

  public animationDirection(event: string): void {
    this.direction = event;
  }

  /**
   * create form group for all coverages
   * @param coverages
   */
  public buildCoveragesForm(
    coverages,
    configCoverages: CoverageConfiguration[]
  ): void {
    if (!coverages) {
      return;
    }

    const group = coverages.reduce((map, coverage: CoverageEntity) => {
      if (!coverage.mandatory) {
        const selectedValue = coverage.selectedValue
          ? coverage.selectedValue.find(value => value.code === 'selected')
          : null;
        const value = selectedValue ? selectedValue.value : '';
        map[coverage.coverageId + (+coverage.coverableId || '')] =
          this._fb.control(value);
      }

      if (coverage.terms) {
        coverage.terms.forEach((term, index) => {
          const selectedValue = coverage.selectedValue
            ? coverage.selectedValue.find(value => value.code === term.code)
            : null;
          const value = selectedValue ? selectedValue.value : 'false';
          // this logic is to cover additional coverage term option values
          // that have true/false values, since for primary coverages true/false
          // are meant for accepting/declining a coverage
          const tempTermValue =
            index > 0 && (value === 'false' || value === 'true')
              ? value + '_' + term.code
              : value;

          let formControl: UntypedFormControl =
            this._coverageHelper.buildCoverageFormControl(
              this._fb,
              term,
              tempTermValue
            );

          this._coverageHelper.addFormValidators(
            coverage.productId,
            term.code,
            formControl
          );

          const updatedFormControl =
            this._privateLabelCoverageHelper.buildFormControl(
              this._fb,
              term,
              configCoverages,
              coverage,
              value
            );

          if (updatedFormControl !== null) {
            formControl = updatedFormControl;
          }

          map[coverage.coverageId + (+coverage.coverableId || '') + term.code] =
            formControl;
        });
      }

      return map;
    }, {});

    this.coveragesForm = this._fb.group(group);
  }

  public connectedCarAuthToShareUrl(): Observable<string> {
    return combineLatest([
      this.privateLabelConfig$,
      this.isConnectedCarAndHasEnrollment$,
    ]).pipe(
      map(([config, ccEnrolled]) =>
        ccEnrolled
          ? PrivateLabelContentHelper.getConfigValue(
              config,
              CustomUIElements.AGREE_TO_SHARE_URL
            )
          : config?.identifier === 'toyotacc'
          ? 'true'
          : null
      )
    );
  }

  public deselectTelematicsDueToAFMVF(coverageChanges: CoverageChange[]): void {
    if (!this.hasAfAndMvfCoverages(coverageChanges)) {
      this.additionalPolicyNotification = AFMVF_SMARTRIDE_NOTICE_VERBIAGE;
      this.showSmartRideAfmvfNotice = false;
      this._baseProxy
        .hasAnyTelematicsEnrollment()
        .pipe(take(1))
        .subscribe(enrolled => {
          if (enrolled) {
            this._baseProxy.dispatchRemoveTelematicsEnrollment();
          }
        });
    }
  }

  private hasAfAndMvfCoverages(coverageChanges: CoverageChange[]) {
    return (
      this.coverageIsSelected(coverageChanges, this.ACCIDENT_FORGIVENESS) &&
      this.coverageIsSelected(coverageChanges, this.MINOR_VIOLATION_FORGIVENESS)
    );
  }

  protected updateDividerColor(configVal: string): void {
    if (configVal) {
      this.defaultDividerColor = configVal;
    }
  }

  protected checkConditionalProducts(): void {
    combineLatest([this.allProducts$, this.coverages$])
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(([products, coverages]) => {
        const isUmbrellaEligible =
          this._baseProxy.isEligibleForUmbrella(products);

        if (isUmbrellaEligible) {
          const umbrella = products.find(
            product => product.id === ProductTypes.UMBRELLA
          );

          if (!umbrella) {
            return;
          }

          const hasError = this._coverageHelper.qualifiesForUmbrella(
            coverages,
            products
          );

          if (umbrella.hasError && hasError) {
            // do nothing
          } else if (umbrella.hasError && !hasError) {
            // Unset error if we set it due to insufficient coverages (PUL001).
            // Other real errors should stick
            if (umbrella.errorCode === 'PUL001') {
              this._baseProxy.updateProduct({
                errorCode: null,
                id: ProductTypes.AUTO,
              });
              this._baseProxy.updateProduct({
                ...umbrella,
                hasError: false,
                errorCode: null,
                isQualified: true,
                quoteCompleted: false,
                quoteRated: false,
                quoteStatus: '',
              });
            }
          } else if (!umbrella.hasError && hasError) {
            this._baseProxy.updateProduct({
              errorCode: 'PUL001',
              id: ProductTypes.AUTO,
            });
            this._baseProxy.updateProduct({
              ...umbrella,
              hasError: true,
              errorCode: 'PUL001',
              isQualified: false,
            });
          } else if (!umbrella.hasError && !hasError) {
            this.productDisplays$ = this._baseProxy.getAllProductDisplay().pipe(
              take(1),
              switchMap(products =>
                this._errorMessageService.addErrorMessageToProducts(
                  <Product[]>products
                )
              ),
              tap((productDisplays: any) => (this.products = productDisplays))
            );

            this.selectedProductsWithoutErrors$
              .pipe(take(1))
              .subscribe(products => {
                this._baseProxy.updateSelectedCoverageProducts(
                  products.map(product => product.id)
                );
              });
            this.productDisplays$
              .pipe(take(1))
              .subscribe((productDisplays: ProductDisplay[]) => {
                this.form.patchValue({ products: productDisplays });
              });
          }
        }
      });
  }

  protected initProductDisplays(newest?: boolean): void {
    this.productDisplays$ = this._baseProxy.getAllProductDisplay().pipe(
      takeUntil(this._ngUnsubscribe),
      switchMap(products =>
        this._errorMessageService.addErrorMessageToProducts(<Product[]>products)
      ),
      tap((productDisplays: any) => (this.products = productDisplays))
    );

    this.productDisplays$
      .pipe(take(1))
      .subscribe((productDisplays: ProductDisplay[]) => {
        const isPowersportsSelected = productDisplays.some(product => {
          return product.id === ProductTypes.POWERSPORTS ? true : false;
        });
        const isTermLifeSelected = productDisplays.some(product => {
          return product.id === ProductTypes.TERMLIFE ? true : false;
        });
        if (isPowersportsSelected && isTermLifeSelected) {
          const powersports = productDisplays.pop();
          productDisplays.unshift(powersports);
        }
        this.selectProductCoverages(
          productDisplays[newest ? productDisplays.length - 1 : 0]
        );
      });

    this.selectedProductsWithoutErrors$
      .pipe(take(1))
      .subscribe((products: Product[]) => {
        this.form.patchValue({ products });
      });
  }

  protected confirmTelematicsEnrollments(
    isValid: boolean,
    isAuto: boolean
  ): Observable<any> {
    if (isValid && isAuto) {
      return combineLatest([
        this.telematicsEnrollments$,
        this.vehicles$,
        this.isConnectedCarEnabled$,
        this.privateLabelIdentifier$,
        this._baseProxy.isAgent(),
        this._baseProxy.getConnectedCarConsent(),
      ]).pipe(
        take(1),
        switchMap(
          ([
            enrollments,
            vehicles,
            connectedCarEnabled,
            privateLabelIdentifier,
            isAgent,
            connectedCarConsent,
          ]) => {
            if (this.checkEnrollmentStatus(enrollments)) {
              const modal = this._modalService.open(
                TelematicsConfirmationModalComponent,
                { size: 'lg', ariaLabelledBy: 'modal-title' }
              );
              modal.componentInstance.telematicsEnrollmentVehicles =
                enrollments?.vehicleEnrollment?.vehicles || [];
              modal.componentInstance.telematicsMobileEnrollment =
                enrollments.mobileEnrollment;
              modal.componentInstance.vehicles = vehicles;
              modal.componentInstance.isConnectedCarEnabled =
                connectedCarEnabled;
              modal.componentInstance.privateLabelIdentifier =
                privateLabelIdentifier;
              modal.componentInstance.isAgent = isAgent;
              modal.componentInstance.connectedCarConsent = connectedCarConsent;
              return from(modal.result);
            } else {
              return of(isValid);
            }
          }
        )
      );
    } else {
      return of(isValid);
    }
  }

  protected checkEnrollmentStatus(
    enrollments: TelematicsEnrollmentResponse
  ): boolean {
    let enrolled = false;
    if (
      enrollments &&
      enrollments.vehicleEnrollment &&
      enrollments.vehicleEnrollment.vehicles &&
      enrollments.vehicleEnrollment.vehicles.length
    ) {
      enrollments.vehicleEnrollment.vehicles.forEach(vehicle => {
        if (
          (vehicle.enrollmentStatus === TelematicsEnrollmentStatus.ENROLLED ||
            vehicle.enrollmentStatus ===
              TelematicsEnrollmentStatus.PRE_QUALIFIED) &&
          vehicle.hasOwnProperty('vehicleProgram')
        ) {
          enrolled = true;
        }
      });
    } else if (enrollments && enrollments.mobileEnrollment) {
      enrolled = true;
    }
    return enrolled;
  }

  private initSelectedProductPremium() {
    this.selectedProductPremium$ = combineLatest([
      this.selectedProduct$,
      this.coveragePremiums$,
    ]).pipe(
      map(([selectedProduct, coveragePremiums]) => {
        return (
          selectedProduct &&
          coveragePremiums.find(
            e => e.productId === selectedProduct.id && selectedProduct.show
          )
        );
      })
    );
  }

  /**
   * sums the total premium of all selected coverages
   */
  private initTotalPremiumValue() {
    this.totalPremiumValue$ = combineLatest([
      this.allProducts$,
      this.selectedProductsWithoutErrors$,
      this.coveragePremiums$,
      this._baseProxy.isMonoQuoteAllowed(),
    ]).pipe(
      map(
        ([
          products,
          selectedProductsWithoutErrors,
          coveragePremiums,
          isMonoQuoteAllowed,
        ]) => {
          const coveragePremiumProducts = !isMonoQuoteAllowed
            ? products
            : selectedProductsWithoutErrors;
          const selectedCoveragePremiums = coveragePremiums.filter(premium =>
            coveragePremiumProducts
              .filter(product => product.show)
              .map(product => product.id)
              .includes(premium.productId)
          );
          const totalPremium = selectedCoveragePremiums
            .map(premium => premium?.total?.amount / premium?.termMonths)
            .reduce((total, e) => total + e, 0);
          return totalPremium;
        }
      )
    );
  }

  private initFullPayPremiumValue() {
    this.fullPayPremiumValue$ = combineLatest([
      this.allProducts$,
      this.selectedProductsWithoutErrors$,
      this.coveragePremiums$,
      this._baseProxy.isMonoQuoteAllowed(),
    ]).pipe(
      map(
        ([
          products,
          selectedProductsWithoutErrors,
          coveragePremiums,
          isMonoQuoteAllowed,
        ]) => {
          const coveragePremiumProducts = !isMonoQuoteAllowed
            ? products
            : selectedProductsWithoutErrors;
          const selectedCoveragePremiums = coveragePremiums.filter(
            totalPremium =>
              coveragePremiumProducts
                .filter(product => product.show)
                .map(product => product.id)
                .includes(totalPremium.productId)
          );
          return selectedCoveragePremiums
            .map(fullPayPremium => fullPayPremium?.total?.amount)
            .reduce((total, e) => total + e, 0);
        }
      )
    );
  }

  private initShowCoveragePricesForProduct() {
    const displayPricesForProducts = [
      ProductTypes.AUTO,
      ProductTypes.POWERSPORTS,
    ];
    this.showCoveragePrices$ = this.selectedProduct$.pipe(
      map(
        selectedProduct =>
          selectedProduct &&
          displayPricesForProducts.includes(selectedProduct.id)
      )
    );
  }

  private initSelectedProductDiscounts() {
    this.selectedProductDiscounts$ = combineLatest([
      this.discounts$,
      this.powersportsDiscounts$,
      this.selectedProduct$,
    ]).pipe(
      map(([discounts, powersportsDiscounts, selectedProduct]) => {
        if (selectedProduct?.id === ProductTypes.POWERSPORTS) {
          return powersportsDiscounts
            .filter(
              discount =>
                selectedProduct &&
                !selectedProduct.hasError &&
                discount.productId === selectedProduct.id
            )
            .map(discount =>
              this._discountNameHelper.helpDiscountName(discount)
            );
        } else {
          return discounts
            .filter(
              discount =>
                selectedProduct &&
                !selectedProduct.hasError &&
                discount.productId === selectedProduct.id
            )
            .map(discount =>
              this._discountNameHelper.helpDiscountName(discount)
            );
        }
      })
    );
  }

  protected getAfmvfCoverageChangesAfterSmartrideChange(): Observable<
    CoverageChange[]
  > {
    this.showSmartRideAfmvfNotice = false;
    if (this.isAFMVFApplicable) {
      return this.coverages$.pipe(
        take(1),
        map(coverages => {
          const coverageChanges = [];

          if (
            this._addCoverageChangeIfPresentAndUnselected(
              coverageChanges,
              coverages,
              this.ACCIDENT_FORGIVENESS
            ) ||
            this._addCoverageChangeIfPresentAndUnselected(
              coverageChanges,
              coverages,
              this.MINOR_VIOLATION_FORGIVENESS
            )
          ) {
            this.showSmartRideAfmvfNotice = true;
          }

          return coverageChanges;
        })
      );
    }
    return from([]);
  }

  private _addCoverageChangeIfPresentAndUnselected(
    coverageChanges: CoverageChange[],
    coverages: CoverageEntity[],
    coverageId: string
  ): boolean {
    const coverage = coverages.find(
      coverage => coverage.coverageId === coverageId
    );
    if (coverage) {
      if (
        this._coverageHelper.getSelectedValue(coverage, 'selected') !== 'true'
      ) {
        coverageChanges.push(
          ...this._coverageHelper.createCoverageChanges(
            coverages,
            coverage,
            'selected',
            'true'
          )
        );
        return true;
      }
    }
    return false;
  }

  protected updateCoverageFailMichiganPIPLimit50k(
    coverageChanges: CoverageChange[]
  ): boolean {
    let returnValue = false;
    combineLatest([this.quoteState$, this.drivers$])
      .pipe(take(1))
      .subscribe(([quoteState, drivers]: [string, DriverEntity[]]) => {
        const pipFiftyThousandLimitCoverageChanges = coverageChanges.filter(
          change =>
            change.coverageId === CoverageIds.PIP &&
            change.selectedValue.filter(
              value =>
                value.code === CoverageCodes.PIPLimit && value.value === '50000'
            ).length === 1
        );

        if (
          quoteState === 'MI' &&
          pipFiftyThousandLimitCoverageChanges.length
        ) {
          if (
            drivers.filter(
              driver =>
                driver.driverType === 'Driver' &&
                driver.relationToPrimaryNamedInsured ===
                  'PrimaryNamedInsured' &&
                driver.healthCarePlan !== 'Medicaid'
            ).length
          ) {
            returnValue = true;
          } else if (drivers.length > 1) {
            const driversWhoAreRelatives = drivers.filter(
              driver =>
                driver.driverType === 'Driver' &&
                ['SPOUSE', 'CHILD', 'OTHER_RELATIVE'].includes(
                  driver.relationToPrimaryNamedInsured
                )
            );
            const driversWhoAreRelativesWithHealthCarePlans = drivers.filter(
              driver =>
                driver.driverType === 'Driver' &&
                ['SPOUSE', 'CHILD', 'OTHER_RELATIVE'].includes(
                  driver.relationToPrimaryNamedInsured
                ) &&
                [
                  'QualifyingHealthCoverage',
                  'Medicare',
                  'Medicaid',
                  'OtherPIPHealthBenefitPolicy',
                ].includes(driver.healthCarePlan)
            );
            if (
              driversWhoAreRelatives.length !==
              driversWhoAreRelativesWithHealthCarePlans.length
            ) {
              returnValue = true;
            }
          }
        }
      });
    return returnValue;
  }

  protected addMichiganPipFormValidators(): void {
    this._coverageService.updateCoverageFail(ProductTypes.AUTO, [
      {
        ruleId: '',
        message:
          'This limit is not valid with the indicated health plan type.  Please choose another limit',
        level: '',
      },
    ]);
  }

  private shouldHideQuoteLetter(): Observable<boolean> {
    return this.privateLabelConfig$.pipe(
      map(
        config =>
          PrivateLabelContentHelper.getConfigValue(
            config,
            CustomUIElements.REMOVE_VIEW_QUOTE_PDF_BTN
          ) === 'true'
      )
    );
  }

  protected updateCoverages(
    productId: string,
    ratingType: string
  ): Observable<boolean> {
    return of(false);
  }

  private _initiateOrReenableUmbrella(products: Product[], modalResult: any) {
    const umbrella = products.find(
      product => product.id === ProductTypes.UMBRELLA
    );
    this._updateUmbrellaCoverages();
    if (umbrella && umbrella.quoteId) {
      this._baseProxy.updatePolicyholderDatesOfBirth(modalResult);
    } else {
      products.push({ id: ProductTypes.UMBRELLA, hasError: false });
      this._baseProxy.updatePolicyholderDatesOfBirth(modalResult);
      this._baseProxy.dispatchInitiateNewBusiness(ProductTypes.UMBRELLA);
    }
    this._baseProxy.updateSelectedCoverageProducts(
      products.filter(product => !product.hasError).map(product => product.id)
    );
    this.initProductDisplays(true);
  }

  private _unselectUmbrellaUi() {
    if (this.productsListComponent) {
      this.productsListComponent.unselectProductUi(ProductTypes.UMBRELLA);
    }
  }

  isUmbrellaEligible(products: Product[], isQuoteRetrieve?: boolean): boolean {
    return this._baseProxy.isEligibleForUmbrella(products, isQuoteRetrieve);
  }

  umbrellaIsSelectedWithoutError(): boolean {
    return this.products
      ? !!this.products.find(
          product => product.id === ProductTypes.UMBRELLA && !product.hasError
        )
      : false;
  }

  private _updateUmbrellaCoverages() {
    combineLatest([this.allProducts$, this.coverages$])
      .pipe(take(1))
      .subscribe(([products, coverages]) => {
        const coverageChanges = {};
        products.forEach(product => {
          switch (product.id) {
            case ProductTypes.AUTO: {
              [
                { coverageId: CoverageIds.BodilyInjury, limit: 250000 },
                { coverageId: CoverageIds.PropertyDamage, limit: 100000 },
                {
                  coverageId: CoverageIds.UnderinsuredMotoristBodilyInjury,
                  limit: 250000,
                },
                {
                  coverageId: CoverageIds.UninsuredMotoristsBodilyInjury,
                  limit: 250000,
                },
              ].forEach(cov => {
                const coverage = this._coverageHelper.findCoverage(
                  coverages,
                  product,
                  cov.coverageId
                );

                if (!coverage) {
                  return;
                }

                const currentValue =
                  +coverage.selectedValue[0].value.split('/')[0];

                if (currentValue >= cov.limit) {
                  return;
                }

                const termCode = coverage.selectedValue[0].code;
                const options = coverage.terms.find(
                  term => term.code === termCode
                );
                const newOption = options.options.find(option => {
                  const value = option.value.split('/')[0];

                  return +value >= cov.limit;
                });

                if (newOption) {
                  if (coverageChanges[product.id]) {
                    coverageChanges[product.id].push(
                      this._coverageHelper.createCoverageChange({
                        coverage,
                        code: termCode,
                        value: newOption.value,
                        coverableId: coverage.coverableId,
                      })
                    );
                  } else {
                    coverageChanges[product.id] = [
                      this._coverageHelper.createCoverageChange({
                        coverage,
                        code: termCode,
                        value: newOption.value,
                        coverableId: coverage.coverableId,
                      }),
                    ];
                  }
                }
              });

              break;
            }

            case ProductTypes.HOMEOWNERS:
            case ProductTypes.CONDO:
            case ProductTypes.RENTERS: {
              [
                { coverageId: CoverageIds.PersonalLiability, limit: 300000 },
              ].forEach(cov => {
                const coverage = this._coverageHelper.findCoverage(
                  coverages,
                  product,
                  cov.coverageId
                );

                if (
                  !coverage ||
                  +coverage.selectedValue[0].value >= cov.limit
                ) {
                  return;
                }

                const termCode = coverage.selectedValue[0].code;
                const options = coverage.terms.find(
                  term => term.code === termCode
                );
                const newOption = options.options.find(option => {
                  const value = option.value.split('/')[0];

                  return +value >= cov.limit;
                });

                if (newOption) {
                  if (coverageChanges[product.id]) {
                    coverageChanges[product.id].push(
                      this._coverageHelper.createCoverageChange({
                        coverage,
                        code: termCode,
                        value: newOption.value,
                        coverableId: coverage.coverableId,
                      })
                    );
                  } else {
                    coverageChanges[product.id] = [
                      this._coverageHelper.createCoverageChange({
                        coverage,
                        code: termCode,
                        value: newOption.value,
                        coverableId: coverage.coverableId,
                      }),
                    ];
                  }
                }
              });
              break;
            }
          }
        });
        if (Object.keys(coverageChanges)) {
          from(Object.keys(coverageChanges).map(key => coverageChanges[key]))
            .pipe(
              mergeMap(changes => {
                this._coverageService.updateCoverageChanges(changes);
                return this.updateCoverages(
                  changes[0].productId,
                  RatingTypes.QUOTE
                );
              }),
              toArray()
            )
            .subscribe(() => {
              this.coverages$.pipe(take(1)).subscribe();
              this._loadingService.loadingEnd();
            });
        }
      });
  }

  openUmbrellaModal() {
    const modal = this._modalService.open(UmbrellaComponent);

    this._baseProxy.logModalEvent('UMBRELLA_MODAL_OPENED');

    combineLatest([this._baseProxy.getAllPolicyholders(), this.allProducts$])
      .pipe(take(1))
      .subscribe(([policyholders, products]) => {
        modal.componentInstance.policyholders = policyholders;
        modal.componentInstance.ngOnChanges({ policyholders });
        modal.result.then(result => {
          return new Promise((resolve, reject) => {
            if (result) {
              this._initiateOrReenableUmbrella(products, result);
              this._baseProxy.logModalEvent('UMBRELLA_ADDED');
              resolve(result);
              this._baseProxy.logModalEvent('UMBRELLA_MODAL_CLOSED');
            } else {
              this._baseProxy.logModalEvent('UMBRELLA_NOT_ADDED');
              reject(result);
              this._baseProxy.logModalEvent('UMBRELLA_MODAL_CLOSED');
            }
          }).catch(err => {
            this._unselectUmbrellaUi();
          });
        });
      });
  }
}
