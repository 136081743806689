import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsExclusiveAgentDirective } from './is-exclusive-agent.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsExclusiveAgentDirective],
  exports: [IsExclusiveAgentDirective],
})
export class IsExclusiveAgentModule {}
