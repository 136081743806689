import * as fromCondo from '@core/store/actions/condo.action';

import { CondoModel } from '@core/models/condo/condo.model';

export interface CondoState {
  condo: CondoModel;
}

export const initialState: CondoState = {
  condo: null,
};

export function reducer(
  state = initialState,
  action: fromCondo.CondoActions
): CondoState {
  switch (action.type) {
    case fromCondo.ADD_CONDO: {
      const condo = action.payload;

      return {
        ...state,
        condo,
      };
    }

    case fromCondo.SET_CONDO_MORTGAGE_FLAG: {
      return {
        ...state,
        condo: {
          ...state.condo,
          hasMortgage: action.hasMortgage,
        },
      };
    }
  }

  return state;
}

export const getCondoState = (state: CondoState) => state.condo;
