<form [formGroup]="form">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">
      Review and accept program enrollment
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      aria-describedby="modal-title"
      (click)="modal.close('Close')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">Accept all to continue</div>

  <div class="modal-body" *ngIf="!mobileAppEnrolled">
    <div
      class="telematics-program"
      *ngIf="smartMilesVehicles.length && !shouldOfferSmartMiles_v2()"
    >
      <!-- SmartMiles -->
      <div class="row tm-center-align program-header">
        <span class="smartmiles col-10">
          <strong>Enrolled in our pay-per-mile program, SmartMiles&reg;</strong>
        </span>
        <img
          class="tm-icon col-1"
          src="assets/images/telematics/SM-icon-purple.svg"
          alt="SmartMiles Icon"
          role="img"
        />
      </div>
      <div class="container" *ngFor="let vehicle of smartMilesVehicles">
        <h1 class="heading-h6">
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
        </h1>
      </div>
      <div class="container">
        <ul>
          <li>Premium will vary monthly based on actual miles driven</li>
          <li>Requires device plugged into the car to measure mileage</li>
        </ul>
      </div>
      <div class="container telematics-check">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isAgreeToSmartmiles"
          id="member-acknowledgement-smartmiles"
        />
        <label
          *isCustomer
          for="member-acknowledgement-smartmiles"
          class="form-check-label"
          ><strong>Yes, I agree</strong></label
        >
        <label
          *isAgent
          for="member-acknowledgement-smartmiles"
          class="form-check-label"
          ><strong>Customer agrees to these terms</strong></label
        >
      </div>
    </div>
    <div
      class="telematics-program"
      *ngIf="smartMilesVehicles.length && shouldOfferSmartMiles_v2()"
    >
      <!-- SmartMiles -->
      <div class="row tm-center-align program-header">
        <span class="smartmiles col-10">
          <strong>SmartMiles pay-per-mile program,with a device</strong>
        </span>
        <img
          class="tm-icon col-1"
          src="assets/images/telematics/SM-icon-purple.svg"
          alt="SmartMiles Icon"
          role="img"
        />
      </div>
      <div class="container" *ngFor="let vehicle of smartMilesVehicles">
        <h1 class="heading-h6">
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
        </h1>
      </div>
      <div class="container">
        <ul>
          <li>
            I agree to plug in a device into the vehicle to measure mileage and
            driving trends
          </li>
          <li>
            I understand the premium will vary monthly based on mileage and
            driving trends
          </li>
          <li>I agree to receive a one-time text and ongoing program emails</li>
        </ul>
      </div>
      <div class="container telematics-check">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isAgreeToSmartmiles"
          id="member-acknowledgement-smartmiles"
        />
        <label
          *isCustomer
          for="member-acknowledgement-smartmiles"
          class="form-check-label"
          ><strong>Yes, I agree</strong></label
        >
        <label
          *isAgent
          for="member-acknowledgement-smartmiles"
          class="form-check-label"
          ><strong>Customer agrees to these terms</strong></label
        >
      </div>
    </div>
    <div
      class="telematics-program"
      *ngIf="
        smartMilesConnectedCarVehicles.length && !shouldOfferSmartMiles_v2()
      "
    >
      <!-- SmartMiles ConnectedCar-->
      <div class="row tm-center-align program-header">
        <span class="smartmiles col-10">
          <strong>Enrolled in SmartMiles&reg; with a connected car</strong>
        </span>
        <img
          class="tm-icon col-1"
          src="assets/images/telematics/connected-car-dk-smartMiles.svg"
          alt="SmartMiles Icon"
          role="img"
        />
      </div>
      <div
        class="container"
        *ngFor="let vehicle of smartMilesConnectedCarVehicles"
      >
        <h1 class="heading-h6">
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
        </h1>
      </div>

      <div class="container">
        <ul>
          <li>Premium will vary monthly based upon actual miles driven</li>
          <li>I agree to receive a one-time text and ongoing program emails</li>
          <li>
            I agree to allow Nationwide to share my
            <span
              class="content"
              tabindex="0"
              [ngbPopover]="fordContent"
              #p1="ngbPopover"
              triggers="mouseenter:mouseleave"
              placement="bottom"
              >information</span
            >
            with my vehicle manufacturer to complete my enrollment.
          </li>
        </ul>
        <ng-template #fordContent
          >Personally identifiable information such as Name, Vin and Policy
          Number
        </ng-template>
      </div>
      <div class="container telematics-check">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isAgreeToSmartMilesConnectedCar"
          id="member-acknowledgement-smartmiles-connectedcar"
        />
        <label
          *isCustomer
          for="member-acknowledgement-smartmiles-connectedcar"
          class="form-check-label"
          ><strong>Yes, I agree</strong></label
        >
        <label
          *isAgent
          for="member-acknowledgement-smartmiles-connectedcar"
          class="form-check-label"
          ><strong>Customer agrees to these terms</strong></label
        >
      </div>
    </div>
    <div
      class="telematics-program"
      *ngIf="
        smartMilesConnectedCarVehicles.length && shouldOfferSmartMiles_v2()
      "
    >
      <!-- SmartMiles ConnectedCar-->
      <div class="row tm-center-align program-header">
        <span class="smartmiles col-10">
          <strong>SmartMiles pay-per-mile program, with a connected car</strong>
        </span>
        <img
          class="tm-icon col-1"
          src="assets/images/telematics/connected-car-dk-smartMiles.svg"
          alt="SmartMiles Icon"
          role="img"
        />
      </div>
      <div
        class="container"
        *ngFor="let vehicle of smartMilesConnectedCarVehicles"
      >
        <h1 class="heading-h6">
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
        </h1>
      </div>

      <div class="container">
        <ul>
          <li>
            I agree to share driving data with my vehicle manufacturer and
            Nationwide
          </li>
          <li>
            I understand the premium will vary monthly based on mileage and
            driving trends
          </li>
          <li>I agree to receive a one-time text and ongoing program emails</li>
        </ul>
        <ng-template #fordContent
          >Personally identifiable information such as Name, Vin and Policy
          Number
        </ng-template>
      </div>
      <div class="container telematics-check">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isAgreeToSmartMilesConnectedCar"
          id="member-acknowledgement-smartmiles-connectedcar"
        />
        <label
          *isCustomer
          for="member-acknowledgement-smartmiles-connectedcar"
          class="form-check-label"
          ><strong>Yes, I agree</strong></label
        >
        <label
          *isAgent
          for="member-acknowledgement-smartmiles-connectedcar"
          class="form-check-label"
          ><strong>Customer agrees to these terms</strong></label
        >
      </div>
    </div>

    <div class="telematics-program" *ngIf="smartRideInstantVehicles.length">
      <!-- smartRide Instant-->
      <div class="row tm-center-align program-header">
        <span class="smartride col-10">
          <strong>SmartRide&reg; Discount Instantly Applied</strong>
        </span>
        <img
          class="tm-icon col-1"
          src="assets/images/telematics/SR-icon-teal.svg"
          alt="SmartRide Icon"
        />
      </div>
      <div class="container" *ngFor="let vehicle of smartRideInstantVehicles">
        <h1 class="heading-h6">
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
          <span class="discount-text"
            >({{
              getEnrollmentVehicleById(vehicle.vehicleId).discountPercentage
            }}% Discount Applied)</span
          >
        </h1>
      </div>
      <div class="container">
        <ul>
          <li>Requires agreement to use data already collected by this car</li>
          <li>No device or future monitoring required to keep the discount</li>
        </ul>
      </div>
      <div class="container telematics-check">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isAgreeToSmartrideInstant"
          id="member-acknowledgement-smartride-instant"
        />
        <label
          *isCustomer
          for="member-acknowledgement-smartride-instant"
          class="form-check-label"
          ><strong>Yes, I agree</strong></label
        >
        <label
          *isAgent
          for="member-acknowledgement-smartride-instant"
          class="form-check-label"
          ><strong>Customer agrees to these terms</strong></label
        >
      </div>
    </div>

    <div class="telematics-program" *ngIf="smartRideDeviceVehicles.length">
      <!-- smartRide Device -->
      <div class="row tm-center-align program-header">
        <span class="smartride col-10" [ngSwitch]="isVehicleMiles">
          <span *ngSwitchCase="true"
            ><strong
              >Enrolled in our low mileage program, SmartRide&reg;</strong
            ></span
          >
          <span *ngSwitchDefault
            ><strong
              >Enrolled in our safe-driving behavior program,
              SmartRide&reg;</strong
            ></span
          >
        </span>
        <img
          class="tm-icon col-1"
          src="assets/images/telematics/SR-icon-teal.svg"
          alt="SmartRide Icon"
        />
      </div>
      <div class="container" *ngFor="let vehicle of smartRideDeviceVehicles">
        <h1 class="heading-h6">
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
        </h1>
      </div>
      <div class="container">
        <ul>
          <li>
            Requires a device plugged into the car that measures
            {{ isVehicleMiles ? 'mileage' : 'driving behavior' }}
          </li>
          <li>Agreement to receive program emails</li>
        </ul>
      </div>
      <div class="container telematics-check">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isAgreeToSmartride"
          id="member-acknowledgement-smartride-device"
        />
        <label
          *isCustomer
          for="member-acknowledgement-smartride-device"
          class="form-check-label"
          ><strong>Yes, I agree</strong></label
        >
        <label
          *isAgent
          for="member-acknowledgement-smartride-device"
          class="form-check-label"
          ><strong>Customer agrees to these terms</strong></label
        >
      </div>
    </div>

    <div class="telematics-program" *ngIf="selfReportedVehicles.length">
      <div class="row tm-center-align program-header">
        <span class="smartride col-10">
          <strong
            >Enrolled in our self-reported, low mileage program,
            SmartRide&reg;</strong
          >
        </span>
        <img
          class="tm-icon col-1"
          src="assets/images/telematics/SR-icon-teal.svg"
          alt="SmartRide Icon"
        />
      </div>
      <div class="container" *ngFor="let vehicle of selfReportedVehicles">
        <h1 class="heading-h6">
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
        </h1>
      </div>
      <div class="container">
        <ul>
          <li>
            Requires proof of mileage by sending us a photo or recent service
            receipt showing the current odometer reading
          </li>
          <li>
            Before each policy renewal, provide us with an updated odometer
            reading
          </li>
          <li>
            Keep the discount for as long as you're insured with us and continue
            sending proof of your mileage
          </li>
        </ul>
      </div>
      <div class="container telematics-check">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isAgreeToSelfReported"
          id="member-acknowledgement-self-reported"
        />
        <label
          *isCustomer
          for="member-acknowledgement-self-reported"
          class="form-check-label"
          ><strong>Yes, I agree</strong></label
        >
        <label
          *isAgent
          for="member-acknowledgement-self-reported"
          class="form-check-label"
          ><strong>Customer agrees to these terms</strong></label
        >
      </div>
    </div>

    <div class="telematics-program" *ngIf="connectedCarVehicles?.length">
      <div *ngIf="getConnectedCarVehiclesByMake('TOYO')?.length">
        <div class="row tm-center-align program-header">
          <span class="connected-car col-10">
            <strong
              >Enrolled in our connected car, safe-driving behavior program,
              SmartRide&reg;</strong
            ></span
          >
          <img
            class="tm-connected-car-icon col-1"
            src="assets/images/telematics/connected-car-dk-blue.svg"
            alt="Connected Car Icon"
          />
        </div>
        <div
          class="container"
          *ngFor="let vehicle of getConnectedCarVehiclesByMake('TOYO')"
        >
          <h1 class="heading-h6">
            {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
          </h1>
        </div>
        <div class="container">
          <ul *ngIf="privateLabelIdentifier !== 'toyotaconnectedcar'">
            <li>
              I agree to receive a one-time text and ongoing program emails.
            </li>
            <li>
              I agree to share
              <span
                class="content"
                tabindex="0"
                [ngbPopover]="toyotaContent"
                #p1="ngbPopover"
                placement="bottom"
              >
                driving data
              </span>
              with my vehicle manufacturer and Nationwide.
            </li>
          </ul>
          <ng-template #toyotaContent>
            Nationwide Insurance partners with Toyota's affiliate, Connected
            Analytic Services, LLC ('CAS') to offer personalized discounts to
            customers with connected vehicles. When you authorize us to obtain
            Driving Data from CAS, CAS may provide information such as the date,
            time, and distance traveled for every distinct trip taken, as well
            as braking and acceleration events. We may also receive vehicle
            speed and steering data. If we receive Driving Data from CAS, we
            will retain this information as part of your quote or policy records
            pursuant to our
            <a
              href="https://www.nationwide.com/personal/privacy-security/pages/privacy"
              target="_blank"
              >privacy policy</a
            >.</ng-template
          >
          <ul *ngIf="privateLabelIdentifier === 'toyotaconnectedcar'">
            <li>
              Requires Agreement to these
              <a [href]="termsAndConditionsURL" target="_blank"
                >Terms &amp; Conditions</a
              >
            </li>
          </ul>
        </div>
        <div class="container telematics-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="isAgreeToConnectedCarToyota"
            id="member-acknowledgement-connected-car"
          />
          <label
            *isCustomer
            for="member-acknowledgement-connected-car"
            class="form-check-label"
            ><strong>Yes, I agree</strong></label
          >
          <label
            *isAgent
            for="member-acknowledgement-connected-car"
            class="form-check-label"
            ><strong>Customer agrees to these terms</strong></label
          >
        </div>
      </div>

      <div *ngIf="getConnectedCarVehiclesByMake('FORD')?.length">
        <div class="row tm-center-align program-header">
          <span class="connected-car col-10">
            <strong
              >Enrolled in our connected car, safe-driving behavior program,
              SmartRide&reg;</strong
            ></span
          >
          <img
            class="tm-connected-car-icon col-1"
            src="assets/images/telematics/connected-car-dk-blue.svg"
            alt="Connected Car Icon"
          />
        </div>
        <div
          class="container"
          *ngFor="let vehicle of getConnectedCarVehiclesByMake('FORD')"
        >
          <h1 class="heading-h6">
            {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
          </h1>
        </div>
        <div class="container">
          <ul *ngIf="privateLabelIdentifier !== 'fordinsure'">
            <li>
              I agree to receive a one-time text and ongoing program emails.
            </li>
            <li>
              I agree to allow Nationwide to share my
              <span
                class="content"
                tabindex="0"
                [ngbPopover]="fordContent"
                #p1="ngbPopover"
                triggers="mouseenter:mouseleave"
                placement="bottom"
                >information</span
              >
              with my vehicle manufacturer to complete my enrollment.
            </li>
          </ul>
          <ng-template #fordContent
            >Personally identifiable information such as Name, Vin and Policy
            Number
          </ng-template>
          <ul *ngIf="privateLabelIdentifier === 'fordinsure'">
            <li>
              Requires Agreement to these
              <a [href]="termsAndConditionsURL" target="_blank"
                >Terms &amp; Conditions</a
              >
            </li>
          </ul>
        </div>
        <div class="container telematics-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="isAgreeToConnectedCarFord"
            id="member-acknowledgement-connected-car"
          />
          <label
            *isCustomer
            for="member-acknowledgement-connected-car"
            class="form-check-label"
            ><strong>Yes, I agree</strong></label
          >
          <label
            *isAgent
            for="member-acknowledgement-connected-car"
            class="form-check-label"
            ><strong>Customer agrees to these terms</strong></label
          >
        </div>
      </div>

      <div *ngIf="getConnectedCarVehiclesWithNonPrivateLabels()?.length">
        <div class="row tm-center-align program-header">
          <span class="connected-car col-10">
            <strong
              >Enrolled in our connected car, safe-driving behavior program,
              SmartRide&reg;</strong
            ></span
          >
          <img
            class="tm-connected-car-icon col-1"
            src="assets/images/telematics/connected-car-dk-blue.svg"
            alt="Connected Car Icon"
          />
        </div>
        <div
          class="container"
          *ngFor="let vehicle of getConnectedCarVehiclesWithNonPrivateLabels()"
        >
          <h1 class="heading-h6">
            {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
          </h1>
        </div>
        <div class="container">
          <ul>
            <li>
              Requires Agreement to these
              <a [href]="termsAndConditionsURL" target="_blank"
                >Terms &amp; Conditions</a
              >
            </li>
          </ul>
        </div>
        <div class="container telematics-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="isAgreeToConnectedCar"
            id="member-acknowledgement-connected-car"
          />
          <label
            *isCustomer
            for="member-acknowledgement-connected-car"
            class="form-check-label"
            ><strong>Yes, I agree</strong></label
          >
          <label
            *isAgent
            for="member-acknowledgement-connected-car"
            class="form-check-label"
            ><strong>Customer agrees to these terms</strong></label
          >
        </div>
      </div>
    </div>

    <div class="telematics-program" *ngIf="preQualifiedVehicles.length">
      <div class="row tm-center-align">
        <span class="connected-car col-10 program-header">
          <strong
            >Pre-approved for our safe-driving behavior discount</strong
          ></span
        >
        <img
          class="tm-connected-car-icon col-1"
          src="assets/images/telematics/connected-car-dk-blue.svg"
          alt="Connected Car Icon"
        />
      </div>
      <div class="container" *ngFor="let vehicle of preQualifiedVehicles">
        <h1 class="heading-h6">
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
        </h1>
      </div>
      <div class="container telematics-check">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isAgreeToPrequalified"
          id="member-acknowledgement-prequalified"
        />
        <label
          *isCustomer
          for="member-acknowledgement-prequalified"
          class="form-check-label"
          ><strong>Yes, I agree</strong></label
        >
        <label
          *isAgent
          for="member-acknowledgement-prequalified"
          class="form-check-label"
          ><strong>Customer agrees to these terms</strong></label
        >
      </div>
    </div>

    <div *ngIf="ineligibleVehicles.length">
      <!-- No Program Selected -->
      <span class="ineligibleVehicles no-program program-header">
        <strong>Not enrolled in a Program to save</strong></span
      >
      <div class="container" *ngFor="let vehicle of ineligibleVehicles">
        <h1 class="heading-h6">
          {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
        </h1>
      </div>
    </div>
  </div>

  <div class="modal-body" *ngIf="mobileAppEnrolled">
    <div class="row tm-center-align">
      <span class="smartride col-10">
        <strong
          >Enrolled in our safe-driving behavior program, SmartRide&reg;</strong
        >
      </span>
      <img
        class="tm-icon col-1"
        src="assets/images/telematics/SR-icon-teal.svg"
        alt="SmartRide Icon"
      />
    </div>
    <div class="container" *ngFor="let vehicle of vehicles">
      <h1 class="heading-h6">
        {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
      </h1>
    </div>
    <div class="container">
      <strong>Requires:</strong>
      <ul>
        <li>Download and activation of the SmartRide&reg; Mobile App</li>
        <li>Agreement to receive program emails & one-time welcome text</li>
        <li>In about 6 months we calculate the final discount</li>
      </ul>
      <div class="container telematics-check">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isAgreeToSmartride"
          id="member-acknowledgement-smartride-mobile"
        />
        <label
          *isCustomer
          for="member-acknowledgement-smartride-mobile"
          class="form-check-label"
          ><strong>Yes, I agree</strong></label
        >
        <label
          *isAgent
          for="member-acknowledgement-smartride-mobile"
          class="form-check-label"
          ><strong>Customer agrees to these terms</strong></label
        >
      </div>
      <span class="smartride">
        <strong
          >By enrolling, the insured agrees to download and activate the
          SmartRide Mobile App and receive program emails and a one-time welcome
          text.</strong
        >
      </span>
    </div>
  </div>

  <div class="modal-footer tm-modal-footer-bg">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.close('Change')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.close(onModelSubmit())"
      [disabled]="forwardDisabled()"
    >
      Continue
    </button>
  </div>
</form>
