import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsLifeDirective } from './is-life.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsLifeDirective],
  exports: [IsLifeDirective],
})
export class IsLifeModule {}
