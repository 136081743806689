import { Injectable } from '@angular/core';
import {
  ComparativeQuotesRequest,
  ComparativeQuotesResponse,
  ComparativeQuotesAdapter,
} from '@core/adapters/comparative-quotes.adapter';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ComparativeQuotesService {
  constructor(private _comparativeQuotesAdapter: ComparativeQuotesAdapter) {}

  comparativeQuotes(
    request: ComparativeQuotesRequest
  ): Observable<ComparativeQuotesResponse[]> {
    return this._comparativeQuotesAdapter.comparativeQuotes(request);
  }
}
