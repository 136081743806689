import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  LoggingService,
  PageAlertService,
  UserContextService,
} from '@core/services';
import { Subject } from 'rxjs';
import {
  PageAlertIDs,
  ProductTypes,
  RIVIAN_THIRDPARTY_ID,
} from '@shared/constants/app-constants';
import { ProductRatingError } from '@core/models/error/error.model';
import {
  BridgeWaitTime,
  AdditionalProductsText,
} from '@shared/constants/non-standard-constants';
import { Product } from '@core/models/products/product.model';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'non-standard-bridge',
  templateUrl: './non-standard-bridge.component.html',
  styleUrls: ['../softfall/softfall.component.scss'],
})
export class NonStandardBridgeComponent implements OnInit, OnChanges {
  @Input() errorMessages: ProductRatingError[];
  @Input() contactNumber: string;
  @Input() selectedProducts: Product[];
  @Input() unformattedContactNumber: string;

  autoErrorMessage: string;
  homeownersErrorMessage: string;
  rentersErrorMessage: string;
  _unsubscribe = new Subject<any>();
  redirectURL = null;
  showRedirectURL = false;
  additionalProductsText: string = AdditionalProductsText.default;
  bridgeCompleted = false;
  rivianRedirect: string;

  constructor(
    private _loggingService: LoggingService,
    private _route: ActivatedRoute,
    private _changeDetection: ChangeDetectorRef,
    private _pageAlertService: PageAlertService,
    private _userContextService: UserContextService
  ) {}

  ngOnInit(): void {
    this._pageAlertService.dispatchActiveAlert(PageAlertIDs.nonStandardBridge);
    // If user had more products selected, show some error messaging
    this.additionalProductsText =
      this.selectedProducts.length === 2
        ? AdditionalProductsText[
            this.selectedProducts
              .filter(
                selectedProduct => selectedProduct.id !== ProductTypes.AUTO
              )[0]
              .name.replace(/\s/g, '') // getting rid of space in Term Life
          ]
        : AdditionalProductsText.multiple;
    this._userContextService
      .getUserContext()
      .pipe(take(1))
      .subscribe(userContext => {
        if (
          userContext &&
          userContext.thirdPartyId &&
          userContext.thirdPartyId === RIVIAN_THIRDPARTY_ID
        ) {
          this.rivianRedirect = userContext.redirectUrl;
        }
      });
  }

  ngOnChanges() {
    this._route.queryParamMap
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(params => {
        if (params.get('redirectURL')) {
          this.redirectURL = params.get('redirectURL');
          this.setRedirectURL(this.redirectURL);
          this._loggingService.logToSplunk({
            event: 'NON-STANDARD BRIDGE',
            message: {
              reason: params.get('reason'),
              redirectUrl: this.redirectURL,
            },
          });

          if (this.redirectURL !== '' && !this.bridgeCompleted) {
            this.bridgeCompleted = true;
            setTimeout(
              () => window.open(this.redirectURL, '_self'),
              BridgeWaitTime
            );
          }
        }
      });
  }

  setRedirectURL(redirectURL: string) {
    this.showRedirectURL = true;
    this.redirectURL = redirectURL;
    this._changeDetection.detectChanges();
  }
}
