import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { SessionLog } from '@core/models/session-log.model';

@Injectable()
export class SessionDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  setSessionId(sessionId: string): void {
    this._store.dispatch(new fromActions.InitializeSession(sessionId));
  }

  getSessionId(): Observable<string> {
    return this._store.select(fromSelectors.getSessionId);
  }

  setQuoteState(quoteState: string): void {
    this._store.dispatch(new fromActions.InitializeState(quoteState));
  }

  setIgnoreProductDisablement(ignoreProductDisablement: boolean): void {
    this._store.dispatch(
      new fromActions.InitializeSessionIgnoreProductDisablement(
        ignoreProductDisablement
      )
    );
  }

  getIgnoreProductDisablement(): Observable<boolean> {
    return this._store.select(fromSelectors.getIgnoreProductDisablementState);
  }

  setIgnoreCurrentCarrierValidation(
    setIgnoreCurrentCarrierValidation: boolean
  ): void {
    this._store.dispatch(
      new fromActions.InitializeSessionIgnoreCurrentCarrierValidation(
        setIgnoreCurrentCarrierValidation
      )
    );
  }
  getIgnoreCurrentCarrierValidation(): Observable<boolean> {
    return this._store.select(fromSelectors.getIgnoreCurrentCarrierValidation);
  }

  setIgnoreRiskFilter(ignoreRiskFilter: boolean): void {
    this._store.dispatch(
      new fromActions.InitializeSessionIgnoreRiskFilter(ignoreRiskFilter)
    );
  }

  setIgnoreAutoUWRules(ignoreAutoUWRules: boolean): void {
    this._store.dispatch(
      new fromActions.InitializeSessionIgnoreAutoUWRules(ignoreAutoUWRules)
    );
  }

  getIgnoreRiskFilter(): Observable<boolean> {
    return this._store.select(fromSelectors.getIgnoreRiskFilter);
  }

  getIgnoreAutoUWRules(): Observable<boolean> {
    return this._store.select(fromSelectors.getIgnoreAutoUWRules);
  }

  setPCEnv(pcEnv: string): void {
    this._store.dispatch(new fromActions.InitializeSessionPCEnv(pcEnv));
  }

  getPCEnv(): Observable<string> {
    return this._store.select(fromSelectors.getPCEnv);
  }

  setBillingEnv(billingEnv: string): void {
    this._store.dispatch(
      new fromActions.InitializeSessionBillingEnv(billingEnv)
    );
  }

  getBillingEnv(): Observable<string> {
    return this._store.select(fromSelectors.getBillingEnv);
  }

  getQuoteState(): Observable<string> {
    return this._store.select(fromSelectors.getQuoteState);
  }

  setFirstName(firstName: string): void {
    this._store.dispatch(new fromActions.InitializeSessionFirstName(firstName));
  }

  getFirstName(): Observable<string> {
    return this._store.select(fromSelectors.getSessionFirstName);
  }

  setLastName(lastName: string): void {
    this._store.dispatch(new fromActions.InitializeSessionLastName(lastName));
  }

  getLastName(): Observable<string> {
    return this._store.select(fromSelectors.getSessionLastName);
  }

  setEmail(email: string): void {
    this._store.dispatch(new fromActions.InitializeSessionEmail(email));
  }

  getEmail(): Observable<string> {
    return this._store.select(fromSelectors.getSessionEmail);
  }

  setRequestedProducts(requestedProducts: string[]): void {
    this._store.dispatch(
      new fromActions.InitializeRequestedProducts(requestedProducts)
    );
  }

  getRequestedProducts(): Observable<string[]> {
    return this._store.select(fromSelectors.getRequestedProducts);
  }

  setReferrer(referrer: string): void {
    this._store.dispatch(new fromActions.InitializeSessionReferrer(referrer));
  }

  getReferrer(): Observable<string> {
    return this._store.select(fromSelectors.getReferrer);
  }

  setHasSentPetEmail(hasSentPetEmail: boolean): void {
    this._store.dispatch(
      new fromActions.InitializeHasSentPetEmail(hasSentPetEmail)
    );
  }

  getHasSentPetEmail(): Observable<boolean> {
    return this._store.select(fromSelectors.getHasSentPetEmail);
  }

  setSessionProgressTrackerEligibility(
    isProgressTrackerEligible: boolean
  ): void {
    this._store.dispatch(
      new fromActions.InitializeSessionProgressTrackerEligibility(
        isProgressTrackerEligible
      )
    );
  }
  getSessionProgressTrackerEligibility(): Observable<boolean> {
    return this._store.select(
      fromSelectors.getSessionProgressTrackerEligibility
    );
  }
  setProgressTrackerFlowName(progressTrackerFlowName: string): void {
    this._store.dispatch(
      new fromActions.InitializeSessionProgressTrackerFlowName(
        progressTrackerFlowName
      )
    );
  }
  getProgressTrackerFlowName(): Observable<string> {
    return this._store.select(fromSelectors.getProgressTrackerFlowName);
  }
  updateSessionLogEntry(entity: SessionLog): void {
    this._store.dispatch(new fromActions.UpdateSessionLogEntry(entity));
  }
  sendSessionLogEntry(sessionEventName: string): void {
    this._store.dispatch(new fromActions.SendSessionLogEntry(sessionEventName));
  }

  setSessionClaimsReviewsLoadingSpinnerEligibility(eligible: boolean): void {
    this._store.dispatch(
      new fromActions.InitializeSessionClaimsReviewsLoadingSpinnerEligibility(
        eligible
      )
    );
  }

  getSessionClaimsReviewsLoadingSpinnerEligibility(): Observable<boolean> {
    return this._store.select(
      fromSelectors.getSessionClaimsReviewsLoadingSpinnerEligibility
    );
  }

  setSessionClaimsReviewsLoadingSpinnerActive(active: boolean): void {
    this._store.dispatch(
      new fromActions.InitializeSessionClaimsReviewsLoadingSpinnerActive(active)
    );
  }

  getSessionClaimsReviewsLoadingSpinnerActive(): Observable<boolean> {
    return this._store.select(
      fromSelectors.getSessionClaimsReviewsLoadingSpinnerActive
    );
  }

  setSessionClaimsReviewsLoadingSpinnerUpperMessage(message: string): void {
    this._store.dispatch(
      new fromActions.InitializeSessionClaimsReviewsLoadingSpinnerUpperMessage(
        message
      )
    );
  }

  getSessionClaimsReviewsLoadingSpinnerUpperMessage(): Observable<string> {
    return this._store.select(
      fromSelectors.getSessionClaimsReviewsLoadingSpinnerUpperMessage
    );
  }

  setSessionClaimsReviewsLoadingSpinnerLowerMessage(message: string): void {
    this._store.dispatch(
      new fromActions.InitializeSessionClaimsReviewsLoadingSpinnerLowerMessage(
        message
      )
    );
  }
  getSessionClaimsReviewsLoadingSpinnerLowerMessage(): Observable<string> {
    return this._store.select(
      fromSelectors.getSessionClaimsReviewsLoadingSpinnerLowerMessage
    );
  }

  setSessionClaimsReviewsLoadingSpinnerReviewerName(name: string): void {
    this._store.dispatch(
      new fromActions.InitializeSessionClaimsReviewsLoadingSpinnerReviewerName(
        name
      )
    );
  }

  getSessionClaimsReviewsLoadingSpinnerReviewerName(): Observable<string> {
    return this._store.select(
      fromSelectors.getSessionClaimsReviewsLoadingSpinnerReviewerName
    );
  }
}
