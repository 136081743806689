import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { Product } from '@core/models/products/product.model';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { ProductTypes } from '@shared/constants/app-constants';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CoveredLocationAdapter extends BaseDsmAdapter {
  getCoveredLocation(product: Product): Observable<CoveredLocation> {
    const url = `${this._getUrlPrefixForProduct(product.id)}/quotes/${
      product.quoteId
    }/covered-locations/primary`;
    return this.get(url, { productId: product.id });
  }

  updateCoveredLocation(
    product: Product,
    request: CoveredLocation
  ): Observable<CoveredLocation> {
    const url = `${this._getUrlPrefixForProduct(product.id)}/quotes/${
      product.quoteId
    }/covered-locations/primary`;

    return this.patch<CoveredLocation>(
      url,
      { coveredLocation: request },
      { productId: product.id }
    ).pipe(
      map(response => {
        response.productId = product.id;
        return response;
      })
    );
  }

  private _getUrlPrefixForProduct(productId) {
    switch (productId) {
      case ProductTypes.AUTO: {
        return this._appConfig.config.pcEdgeAutoUrl;
      }
      case ProductTypes.HOMEOWNERS: {
        return this._appConfig.config.pcEdgeHomeownersUrl;
      }
      case ProductTypes.CONDO: {
        return this._appConfig.config.pcEdgeCondoUrl;
      }
      case ProductTypes.RENTERS: {
        return this._appConfig.config.pcEdgeRentersUrl;
      }
    }
  }
}
