import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { EscrowAdapter } from '@core/adapters/escrow.adapter';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions/escrow.action';
import { concatMap, catchError, tap } from 'rxjs/operators';
import { from } from 'rxjs';
import { LoggingService } from '@core/services';
import { EstablishEscrowAccount } from '@core/store/actions/escrow.action';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable({
  providedIn: 'root',
})
export class EscrowEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _escrowAdapter: EscrowAdapter,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  establishAccount$ = this._actions$.pipe(
    ofType<EstablishEscrowAccount>(
      fromActions.EscrowActionTypes.ESTABLISH_ESCROW_ACCOUNT
    ),
    tap(action =>
      this._loggingService.log('establishEscrowAccount request', action.payload)
    ),
    concatMap((action: fromActions.EstablishEscrowAccount) => {
      return this._escrowAdapter.establishEscrowAccount(action.payload);
    }),
    tap(response =>
      this._loggingService.log('establishEscrowAccount response', response)
    ),
    concatMap(response => {
      return from([
        new fromActions.AddEscrowAccount({ response }),
        new fromActions.EstablishEscrowAccountSuccess(response),
      ]);
    }),
    catchError(error => {
      const safeError = this._errorHelper.sanitizeError(error);
      this._loggingService.log('establishEscrowAccount error', safeError);
      return from([new fromActions.EstablishEscrowAccountFailure(safeError)]);
    })
  );
}
