import {
  CoveredLocationActions,
  CoveredLocationActionTypes,
} from '@core/store/actions/covered-location.action';
import { OilTankFormComponent } from '@quote/components/property-info/components/oil-tank-form/oil-tank-form.component';
import {
  ProtectiveDevice,
  ProtectiveDevicesInfo,
} from './protective-devices.reducer';
import { RenovationEntity } from './renovation.reducer';

export interface CoveredLocation {
  businessOnPremises?: boolean;
  constructionInfo?: ConstructionInfo;
  currentConstruction?: boolean;
  currentRemodelingOrRehabiliation?: boolean;
  datePurchased?: string;
  deededOwner?: boolean;
  electricWiring?: ElectricWiring;
  flatRoof?: boolean;
  gatedCommunity?: boolean;
  hasHydrantIn1000Ft?: boolean;
  hasOilTank?: boolean;
  historicDistrict?: boolean;
  isInCurrLocForThreeYrs?: boolean;
  isShortSaleAuctForeclose?: boolean;
  location?: Location;
  modularHome?: boolean;
  nonResidentialToResidential?: boolean;
  numberOfAmps?: string;
  numberOfFamilies?: number;
  numberOfOccupants?: number;
  occupancy?: string;
  oilTank?: OilTank;
  productId?: string;
  registeredHistoric?: boolean;
  riskItems?: RiskItem;
  roofCondition?: string;
  roofUlType?: string;
  seasonal?: boolean;
  secondaryHome?: boolean;
  thermostat?: boolean;
  townhouse?: boolean;
  unitsInBuilding?: number;
  weeksRented?: string;
  reconstructionCost?: number;
  protectiveDevices?: ProtectiveDevicesInfo;
}

export interface UnsanitizedCoveredLocation {
  businessOnPremises?: boolean;
  constructionInfo?: UnsanitizedConstructionInfo;
  constructionInfoForm?: UnsanitizedConstructionInfo;
  currentConstruction?: boolean;
  currentRemodelingOrRehabiliation?: boolean;
  datePurchased?: string;
  deededOwner?: boolean;
  electricWiring?: ElectricWiring;
  flatRoof?: boolean;
  gatedCommunity?: boolean;
  hasHydrantIn1000Ft?: boolean;
  hasOilTank?: boolean;
  historicDistrict?: boolean;
  isInCurrLocForThreeYrs?: boolean;
  isShortSaleAuctForeclose?: boolean;
  location?: Location;
  modularHome?: boolean;
  nonResidentialToResidential?: boolean;
  numberOfAmps?: string;
  numberOfFamilies?: number;
  numberOfOccupants?: number;
  occupancy?: string;
  oilTank?: OilTank;
  oilTankForm?: OilTankFormComponent;
  productId?: string;
  protectiveDevices?: ProtectiveDevice[];
  registeredHistoric?: boolean;
  renovations?: RenovationEntity[];
  renovationsForm?: RenovationEntity[];
  riskItems?: RiskItem;
  roofCondition?: string;
  roofUlType?: string;
  seasonal?: boolean;
  secondaryHome?: boolean;
  thermostat?: boolean;
  townhouse?: boolean;
  unitsInBuilding?: number;
  warningMessages?: string[];
  weeksRented?: string;
}

export interface OtherStructure {
  openPorch?: boolean;
  screenPorch?: boolean;
  sunSolarRoom?: boolean;
  woodDeck?: boolean;
  breezeway?: boolean;
}

export interface FoundationType {
  slab?: boolean;
  crawlSpace?: boolean;
  basement?: boolean;
  daylightWalkoutBasement?: boolean;
  piersRaised?: boolean;
  suspendedOverHillside?: boolean;
}

export interface AdditionalFoundationType {
  concreteMasonry?: boolean;
  dirt?: boolean;
  landfill?: boolean;
  open?: boolean;
  rock?: boolean;
  sand?: boolean;
}

export interface ConstructionInfo {
  additionalFoundationType?: AdditionalFoundationType;
  additionalHeating?: string;
  centralCoolingSystem?: string;
  constructionType?: string;
  exteriorWalls?: string;
  foundationType?: FoundationType;
  fullBathroomDescription?: string;
  garages?: Garages[];
  halfBathroomDescription?: string;
  kitchenDescription?: string;
  numberOfFullBathrooms?: number;
  numberOfHalfBathrooms?: number;
  numberOfKitchens?: number;
  numberOfStories?: string;
  otherStructures?: OtherStructure;
  roofCondition?: string;
  roofMaterial?: string;
  squareFootage?: number;
  yearBuilt?: number;
}

export interface UnsanitizedConstructionInfo {
  additionalFoundationType?: AdditionalFoundationType;
  additionalHeating?: string;
  centralCoolingSystem?: string;
  constructionType?: string;
  exteriorWalls?: string;
  foundationType?: FoundationType;
  fullBathroomDescription?: string;
  garages?: Garages[];
  halfBathroomDescription?: string;
  kitchenDescription?: string;
  numberOfFullBathrooms?: number;
  numberOfHalfBathrooms?: number;
  numberOfKitchens?: number;
  numberOfStories?: string;
  otherStructures?: OtherStructure;
  roofCondition?: string;
  roofMaterial?: string;
  squareFootage?: number;
  wallHeights?: string;
  yearBuilt?: number;
}

export interface Location {
  addressId?: number;
  addressLine1?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export interface ElectricWiring {
  fuseBox?: boolean;
  circuitBreaker?: boolean;
  aluminumWire?: boolean;
  knobTube?: boolean;
}

export interface RiskItem {
  swimmingPool?: boolean;
  divingBoard?: boolean;
  poolFence?: boolean;
  trampoline?: boolean;
  trampolineFence?: boolean;
  trampolineTieDowns?: boolean;
  dangerousOrExoticAnimal?: boolean;
  animalThatCausedInjury?: boolean;
  haveDogs?: boolean;
  incidentalFarm?: boolean;
}

export interface Warning {
  message?: string;
}

export interface WarningMessage {
  errors?: any[];
  warnings?: Warning[];
}

export interface Garages {
  garageType: string;
  numberOfCars: number;
}

export interface OilTank {
  tankMaterial: string;
  yearTankInstalled: number;
  tankLocation: string;
  isClosureFillCertified: boolean;
}

export type CoveredLocationUpdateStatus =
  | 'clean'
  | 'dirty'
  | 'pending'
  | 'error';

export interface CoveredLocationState {
  data: CoveredLocation;
  loaded: boolean;
  updating: number;
  updateStatus: CoveredLocationUpdateStatus;
}

const initialState: CoveredLocationState = {
  data: null,
  loaded: false,
  updating: 0,
  updateStatus: 'clean',
};

export function reducer(
  state = initialState,
  action: CoveredLocationActions
): CoveredLocationState {
  switch (action.type) {
    case CoveredLocationActionTypes.GET_COVERED_LOCATION_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loaded: true,
        updateStatus: 'clean',
      };
    }

    case CoveredLocationActionTypes.GET_COVERED_LOCATION_FAIL: {
      return {
        ...state,
        loaded: true,
        updateStatus: 'dirty',
      };
    }

    case CoveredLocationActionTypes.ADD_COVERED_LOCATION: {
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        updateStatus: 'dirty',
      };
    }

    case CoveredLocationActionTypes.UPDATE_COVERED_LOCATION: {
      return {
        ...state,
        updating: state.updating + 1,
        updateStatus: 'pending',
      };
    }

    case CoveredLocationActionTypes.UPDATE_COVERED_LOCATION_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loaded: true,
        updating: state.updating - 1,
        updateStatus: 'clean',
      };
    }

    case CoveredLocationActionTypes.UPDATE_COVERED_LOCATION_FAIL: {
      return {
        ...state,
        updating: state.updating - 1,
        updateStatus: 'error',
      };
    }

    case CoveredLocationActionTypes.RESET_COVERED_LOCATION_LOADED: {
      return {
        ...state,
        loaded: false,
        updateStatus: 'clean',
      };
    }

    case CoveredLocationActionTypes.REMOVE_COVERED_LOCATION: {
      return {
        ...state,
        data: null,
        loaded: false,
        updateStatus: 'clean',
      };
    }

    default: {
      return state;
    }
  }
}
