import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mq-picklist-select',
  templateUrl: './picklist-select.component.html',
  styleUrls: ['./picklist-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PicklistSelectComponent implements OnInit {
  @Input() options: any[];
  @Input() legendText: string;
  @Input() componentId: string;
  @Input() formInnerControlName: string;
  @Input() form: UntypedFormGroup;
  @Input() selectedValue: string;

  constructor() {}

  ngOnInit(): void {}
}
