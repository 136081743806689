import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromDocuments from '@core/store/reducers/pre-bind-documents.reducer';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import * as fromAgency from '@core/store/selectors/agency.selector';
import * as fromPolicyHolder from '@core/store/selectors/policyholder.selector';
import * as fromMetadata from '@core/store/selectors/metadata.selector';
import * as fromConsentValues from '@core/store/selectors/consent-values.selector';
import * as fromPrivateLabel from '@core/store/selectors/private-label.selector';
import * as fromSession from '@core/store/selectors/session.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';

import {
  PreBindDocumentEntity,
  PreBindDocumentRequest,
  PreBindDocumentUploadRequest,
  PreBindDocumentUploadRequestModel,
  PreBindDocumentEmailRequest,
} from '@core/models/entities/pre-bind-document.entity';
import { ChannelNames, ProductTypes } from '@shared/constants/app-constants';

export const getPreBindDocumentsState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.preBindDocuments
);

export const {
  selectAll: selectAllPreBindDocuments,
  selectEntities: selectPreBindDocumentsEntities,
} = fromDocuments.adapter.getSelectors(getPreBindDocumentsState);

export const buildPreBindDocumentDetailRequest = (productId: string) =>
  createSelector(
    fromProducts.getProduct(productId),
    fromTokens.getTokenByProduct(productId),
    fromUserContext.getUserContext,
    selectAllPreBindDocuments,
    (product, token, userContext, preBindDocuments) => {
      return preBindDocuments.map(doc => {
        const preBindDoc = {
          productId: product.id,
          quoteId: product.quoteId,
          documentId: doc.documentId,
          accessToken: token.accessToken,
          sessionId: product.sessionId,
          userContext,
        } as PreBindDocumentRequest;
        return preBindDoc;
      });
    }
  );

export const buildPreBindDocumentDetailRequestPowersports = (
  productId: string
) =>
  createSelector(
    fromProducts.getProduct(productId),
    fromTokens.getTokenByProduct(productId),
    fromUserContext.getUserContext,

    (product, token, userContext) => {
      const preBindDoc = {
        productId: productId,
        quoteId: product.quoteId,
        accessToken: token.accessToken,
        sessionId: product.sessionId,
        userContext,
      } as PreBindDocumentRequest;

      return preBindDoc;
    }
  );

export const getPreBindDocumentsUploadResponse = createSelector(
  getPreBindDocumentsState,
  state => state.uploadResponse
);

export const getPowersportsPreBindDocumentsUploadResponse = createSelector(
  getPreBindDocumentsState,
  state => state.uploadPowersportsResponse
);

export const buildPreBindDocumentEmailRequest = (productId: string) =>
  createSelector(
    fromProducts.getProduct(productId),
    fromTokens.getTokenByProduct(productId),
    getPreBindDocumentsUploadResponse,
    fromAgency.getAgency,
    fromPolicyHolder.getPrimaryNamedInsured,
    fromPrivateLabel.getContactNumber(),
    fromSession.getSessionId,
    (
      product,
      token,
      uploadResponse,
      agency,
      policyholder,
      contactInformation,
      sessionId
    ) => {
      const request = {
        channel: agency
          ? !agency.isPrivateLabel
            ? agency.agencyChannel
            : ChannelNames.PRIVATE_LABEL
          : ChannelNames.INTERNET,
        email: policyholder.emailAddress,
        submissionNumber: product.quoteId,
        contactInformation,
        url: uploadResponse.url,
        policyHolderId: policyholder.policyHolderIds[product.id],
      } as PreBindDocumentEmailRequest;
      return { request, accessToken: token.accessToken, sessionId };
    }
  );

export const buildPreBindDocumentUploadRequest = (
  productId: string,
  documents: PreBindDocumentEntity[]
) =>
  createSelector(
    fromProducts.getProduct(productId),
    fromTokens.getTokenByProduct(productId),
    getPreBindDocumentsUploadResponse,
    fromAgency.getAgency,
    fromPolicyHolder.getPrimaryNamedInsured,
    fromPrivateLabel.getContactNumber(),
    fromSession.getSessionId,
    fromPolicyAddress.selectAllPolicyAddresses,
    (
      product,
      token,
      uploadResponse,
      agency,
      policyholder,
      contactInformation,
      sessionId,
      policyAddressEntities
    ) => {
      const policyAddress = policyAddressEntities[policyholder.addressId];
      const docs = documents.map(doc => {
        return {
          documentId: doc.documentId,
          documentName: doc.name,
          documentByteStream: doc.documentByteStream,
          extension: 'pdf',
        } as PreBindDocumentUploadRequestModel;
      });

      const request = {
        channel: agency
          ? !agency.isPrivateLabel
            ? agency.agencyChannel
            : ChannelNames.PRIVATE_LABEL
          : ChannelNames.INTERNET,
        email: policyholder.emailAddress,
        submissionNumber: product.quoteId,
        contactInformation,
        zipCode: policyAddress.postalCode.split('-')[0],
        documents: docs,
        policyHolderId: policyholder.policyHolderIds[product.id],
        productType: product.name === 'Powersports' ? 'MSA' : null,
      } as PreBindDocumentUploadRequest;

      if (uploadResponse) {
        request.folderId = uploadResponse.folderId;
      }
      return {
        request,
        documents,
        accessToken: token.accessToken,
        sessionId,
      };
    }
  );

export const arePrebindDocumentsAcknowledgementRequired = createSelector(
  fromMetadata.getStateSpecificFlag('preBindDocuments'),
  fromProducts.isDsmProductBindSelected(ProductTypes.AUTO),
  (isPreBind, isAutoSelected) => isPreBind && isAutoSelected
);

export const arePrebindDocumentsAcknowledgementRequiredPowersports =
  createSelector(
    fromMetadata.getStateSpecificFlag('preBindDocuments'),
    fromProducts.isDsmProductBindSelected(ProductTypes.POWERSPORTS),
    (isPreBind, isPowersportsSelected) => isPreBind && isPowersportsSelected
  );

export const shouldResetAcknowledgementFlags = createSelector(
  arePrebindDocumentsAcknowledgementRequired,
  fromProducts.getSelectedProductsWithoutErrors,
  fromConsentValues.getPreBindDocumentsAcknowledgement,
  (ackRequired, products, acknowledgement) => {
    if (ackRequired) {
      const autoProduct = products.find(p => p.id === ProductTypes.AUTO);
      return (
        ackRequired &&
        !autoProduct.issueCompleted &&
        autoProduct.hasReceivedRequiredDocuments &&
        autoProduct.hasCustomerViewedDocuments &&
        (!acknowledgement.hasReceivedDocuments ||
          !acknowledgement.hasViewedDocuments)
      );
    }
    return false;
  }
);

export const getPreBindDocumentsUploaded = createSelector(
  getPreBindDocumentsState,
  state =>
    (state.loaded && !!state.uploadResponse) ||
    (state.loaded && !!state.uploadPowersportsResponse)
);

export const isPreBindDocumentEmailSent = createSelector(
  getPreBindDocumentsState,
  state => state.emailResent
);
