<form [formGroup]="form">
  <div class="row">
    <h1 class="heading-h3 col-12">
      {{ homeownerText.PROPERTY_INFO.TELL_US }}
    </h1>
  </div>
  <ng-container
    *ngIf="(needConstructionInfo$ | async) || reviewConstructionInfo"
  >
    <div class="row">
      <div
        *ngIf="additionalPropertyInfoComponent?.form?.valid === false"
        class="alert col-sm-12 alert-info"
        role="alert"
      >
        <a class="icon info"></a>
        <span>
          {{
            reviewPropertyInfo
              ? homeownerText.PROPERTY_INFO.CONSTRUCTION_INFO_MSB
              : homeownerText.PROPERTY_INFO.CONSTRUCTION_INFO
          }}
        </span>
      </div>
    </div>
    <div class="row" *ngIf="showmsbDisclaimerMessage$ | async">
      <div class="col-12" *isCustomer>
        <p>{{ MSBDisclaimerCustomerMessage }}</p>
      </div>
      <div class="col-12" *isAgent>
        <p>{{ MSBDisclaimerAgentMessage }}</p>
      </div>
    </div>
    <div class="row foot-room">
      <div class="col-12">
        <nw-display-panel
          class="additionalDetailsPanel"
          [heading]="'Additional Property Details'"
          openText="Close"
          [closedText]="reviewPropertyInfo ? 'Review' : 'Edit'"
          [loadOpen]="true"
        >
          <mq-additional-property-info-form
            #contentPlaceholder
            [form]="constructionInfoForm"
            [coveredLocation]="coveredLocation"
            [needConstructionInfo]="needConstructionInfo$ | async"
            [reviewConstructionInfo]="reviewConstructionInfo"
            [_options]="options$ | async"
          >
          </mq-additional-property-info-form>
        </nw-display-panel>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="form-group col-sm-auto">
      <legend>Is there a mortgage on this house?</legend>
      <div
        class="btn-group btn-group-toggle d-flex"
        formControlName="hasMortgage"
        id="hasMortgage-error"
        ngDefaultControl
      >
        <input
          class="btn-check"
          type="radio"
          aria-describedby="hasMortgage-error"
          [value]="true"
          name="hasMortgage"
          formControlName="hasMortgage"
          id="pi-has-mortgage-yes"
        />
        <label
          for="pi-has-mortgage-yes"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle: form.value.hasMortgage == true
          }"
        >
          Yes
        </label>
        <input
          class="btn-check"
          type="radio"
          aria-describedby="hasMortgage-error"
          [value]="false"
          name="hasMortgage"
          formControlName="hasMortgage"
          id="pi-has-mortgage-no"
        />
        <label
          for="pi-has-mortgage-no"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle: form.value.hasMortgage == false
          }"
        >
          No
        </label>
      </div>
    </div>

    <div class="form-group col-sm-12">
      <label for="pi-estimated-year-purchased">
        Estimated month and year of home purchase?
      </label>
      <div class="d-flex">
        <div class="d-flex flex-column" id="estimatedYearPurchased-error">
          <div class="input-group">
            <input
              id="pi-estimated-year-purchased"
              type="text"
              class="form-control"
              placeholder="MM/YYYY"
              maxlength="7"
              mask="00/0000"
              [dropSpecialCharacters]="false"
              aria-describedby="estimatedYearPurchased-error"
              formControlName="estimatedYearPurchased"
            />
            <div class="input-group-text"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <ng-container>
      <mq-renovations-form
        [form]="renovationsForm"
        [stateSpecificFlags]="stateSpecificFlags"
        [coveredLocation]="coveredLocation"
        [renovationData]="renovationValue$ | async"
      ></mq-renovations-form>
    </ng-container>
  </div>
  <div class="row">
    <ng-container *ngIf="showOilTankForm">
      <mq-oil-tank-form
        [form]="oilTankForm"
        [stateSpecificFlags]="stateSpecificFlags"
        [coveredLocation]="coveredLocation"
        [needConstructionInfo]="needConstructionInfo$ | async"
        [_options]="options$ | async"
      >
      </mq-oil-tank-form>
    </ng-container>
  </div>
</form>
