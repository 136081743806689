import * as fromFeature from '@core/store/reducers';

import { createSelector } from '@ngrx/store';
import * as fromDiscounts from '@core/store/reducers/discount.reducer';
import * as fromMetaData from '@core/store/selectors/metadata.selector';
import * as fromPowersportsVehicle from '@core/store/selectors/powersports-vehicle.selector';

import {
  DiscountDescriptions,
  ModifierNames,
  ProductTypes,
  VehicleTypes,
} from '@shared/constants/app-constants';
import { DiscountEntity } from '@core/models/entities/discount.entity';

export const getDiscountState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.discounts
);

export const getPowersportsDiscountState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.powersportsDiscounts
);

export const {
  selectAll: selectAllDiscounts,
  selectEntities: selectDiscountEntities,
  selectTotal: selectTotalDiscounts,
} = fromDiscounts.adapter.getSelectors(getDiscountState);

export const {
  selectAll: selectAllPowersportsDiscounts,
  selectEntities: selectPowersportsDiscountEntities,
  selectTotal: selectTotalPowersportsDiscounts,
} = fromDiscounts.adapter.getSelectors(getPowersportsDiscountState);

export const getDiscountEntity = (description: string) =>
  createSelector(selectDiscountEntities, entities => entities[description]);

export const getSelectedDiscounts = createSelector(
  selectAllDiscounts,
  discounts => discounts.filter(discount => discount.isDiscountApplied)
);

export const getSelectedPowersportsDiscounts = createSelector(
  selectAllPowersportsDiscounts,
  fromMetaData.getStateSpecificFlagsObject,
  fromPowersportsVehicle.getAllPowersportsVehicles,
  (discounts, stateSpecificFlags, powersportsVehicles) => {
    if (stateSpecificFlags.auxillaryLightingSystemDiscountApplicable) {
      const updatedDiscounts = discounts.filter(
        discount =>
          discount.description !==
            DiscountDescriptions.AuxillaryLightingSystem &&
          discount.isDiscountApplied
      );
      const selectedOptionValuesAndvehicleIds = [];
      powersportsVehicles.forEach(psVehicle => {
        if (psVehicle.vehicleType === VehicleTypes.SNOWMOBILE) {
          const alsTrueValue = psVehicle.eligibleDiscounts
            ? psVehicle.eligibleDiscounts.find(
                d =>
                  d.eligibleDiscountId ===
                    ModifierNames.AUXILLARY_LIGHTING_SYSTEM &&
                  d.selectedOptionValue === 'true'
              )?.selectedOptionValue
            : 'false';
          selectedOptionValuesAndvehicleIds.push(
            alsTrueValue === 'true' ? alsTrueValue : 'false',
            psVehicle.vehicleId
          );
        }
      });
      const index = selectedOptionValuesAndvehicleIds.indexOf('true');
      if (index > -1) {
        const alsVehicleId = selectedOptionValuesAndvehicleIds[index + 1];
        const auxillaryLightingSystemDiscountEntity: DiscountEntity = {
          coverableId: alsVehicleId,
          isDiscountApplied: true,
          category: 'MCOffRoadVehicle',
          description: DiscountDescriptions.AuxillaryLightingSystem,
          productId: ProductTypes.POWERSPORTS,
        };
        updatedDiscounts.push(auxillaryLightingSystemDiscountEntity);
      }
      return updatedDiscounts;
    }
    return discounts.filter(discount => discount.isDiscountApplied);
  }
);

export const getAllSelectedDiscounts = createSelector(
  selectAllDiscounts,
  selectAllPowersportsDiscounts,
  (discounts, powersportsDiscounts) =>
    [...discounts, ...powersportsDiscounts].filter(
      discount => discount.isDiscountApplied
    )
);
