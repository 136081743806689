import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import * as fromActions from '@core/store/actions';
import { PartialQuoteAdapter } from '@core/adapters/partial-quote.adapter';
import { MetadataDao, PartialQuoteDao } from '@core/dao';
import {
  PartialQuoteRequest,
  PartialQuote,
} from '@core/models/partial-quote/partial-quote.model';
import { DateUtils } from '@shared/utils/date.utils';
import { DriverBuilder } from '@shared/utils/builders/driver.builder';
import {
  DriverRelationToPNI,
  MaritalStatusToDsmCodes,
  DEFAULT_ID,
  ProductTypes,
  PolicyholderTypes,
  VehiclePrimaryUseCodeStrings,
  VehicleTypes,
  PartialQuoteProductTypes,
} from '@shared/constants/app-constants';
import { AddressEntity } from '@core/models/entities/address.entity';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { VehicleBuilder } from '@shared/utils/builders/vehicle.builder';
import {
  DriverEntity,
  PersonViewModel,
} from '@core/models/entities/driver.entity';
import { PersonEntity } from '@core/models/entities/person.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { PrivateLabel } from '@core/models/private-label/private-label.model';
import { take } from 'rxjs/operators';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';

@Injectable({
  providedIn: 'root',
})
export class PartialQuoteService {
  constructor(
    private _partialQuoteAdapter: PartialQuoteAdapter,
    private _partialQuoteDao: PartialQuoteDao,
    private _metadataDao: MetadataDao
  ) {}

  getPartialQuote(request: PartialQuoteRequest): Observable<PartialQuote> {
    return this._partialQuoteAdapter.getPartialQuote(request);
  }

  loadPartialQuoteData(): void {
    this._partialQuoteDao.loadPartialQuoteData();
  }

  getPartialQuoteLoaded(): Observable<boolean> {
    return this._partialQuoteDao.getPartialQuoteLoaded();
  }

  getPartialQuotePolicyHolders(): Observable<PersonViewModel[]> {
    return this._partialQuoteDao.getPartialQuotePolicyHolders();
  }

  getPartialQuoteDrivers(): Observable<PersonViewModel[]> {
    return this._partialQuoteDao.getPartialQuoteDrivers();
  }

  getPartialQuoteVehicles(): Observable<VehicleEntity[]> {
    return this._partialQuoteDao.getPartialQuoteVehicles();
  }

  updatePartialQuoteVehicleSelected(vehicle: VehicleEntity): void {
    this._partialQuoteDao.updatePartialQuoteVehicleSelected(vehicle);
  }

  updatePartialQuoteDriverSelected(driver: PersonViewModel): void {
    this._partialQuoteDao.updatePartialQuoteDriverSelected(driver);
  }

  updatePartialQuotePolicyHolderSelected(policyHolder: PersonViewModel): void {
    this._partialQuoteDao.updatePartialQuotePolicyHolderSelected(policyHolder);
  }
  getPartialQuoteId(): Observable<string> {
    return this._partialQuoteDao.getPartialQuoteId();
  }

  getActiveStateAbbreviationsData(): Observable<string[]> {
    return this._metadataDao.getActiveStateAbbreviationsData();
  }

  getPartialQuotePniDriver(): Observable<DriverEntity> {
    return this._partialQuoteDao.getPartialQuotePniDriver();
  }

  getPartialQuoteCoveredLocation(): Observable<CoveredLocation> {
    return this._partialQuoteDao.getPartialQuoteCoveredLocation();
  }

  getPartialQuoteActions(
    data: PartialQuote,
    config: PrivateLabel
  ): Observable<Action> {
    const actions = [];
    if (!data) {
      actions.push(new fromActions.LoadPartialQuoteDataFail(null));
      return from(actions);
    }

    if (data?.partialQuoteDetails?.coveredLocation) {
      actions.push(
        new fromActions.AddPartialQuoteCoveredLocation(
          data?.partialQuoteDetails?.coveredLocation
        )
      );
      actions.push(
        new fromActions.AddCoveredLocation(
          data?.partialQuoteDetails?.coveredLocation
        )
      );
    }

    this.appendActionsForPolicyHoldersAndDrivers(actions, data);
    const vehicleVINActions = [];
    if (data.partialQuoteDetails.vehicles) {
      const partialQuoteVehicles = [];
      for (const vehicle of data.partialQuoteDetails.vehicles.autoVehicles) {
        const vehicleEntity = VehicleBuilder.entityFromVehicle(
          vehicle,
          ProductTypes.AUTO
        );
        vehicleEntity.vehicleTempId = vehicle.vehicleTempId;
        vehicleEntity.garageLocation = { ...vehicle.garageLocation };
        vehicleEntity.selected = false;
        vehicleEntity.partialQuoteId = data.partialQuoteId;
        vehicleEntity.vehicleType = VehicleTypes.AUTO;
        vehicleEntity.productId = ProductTypes.AUTO;
        vehicleEntity.primaryUse = vehicle.primaryUse;
        vehicleEntity.isPurchasedNew = vehicle.isPurchasedNew;
        vehicleEntity.purchasedNewDate = vehicle.purchasedNewDate;
        vehicleEntity.annualMiles = vehicle.annualMiles;
        if (vehicle.prequalifiedPartnerScoreId) {
          vehicleEntity.prequalifiedPartnerScoreId =
            vehicle.prequalifiedPartnerScoreId;
        }
        if (vehicleEntity.vin) {
          vehicleVINActions.push(
            new fromActions.UpdatePartialQuoteVehicle(vehicleEntity)
          );
        }
        if (vehicleEntity.year || vehicleEntity.make || vehicleEntity.model) {
          partialQuoteVehicles.push(vehicleEntity);
        }
      }
      actions.push(
        new fromActions.AddPartialQuoteVehicles(partialQuoteVehicles)
      );
    }

    if (data.partialQuoteDetails.policyAddress) {
      if (
        this.isValidState(config, data.partialQuoteDetails.policyAddress.state)
      ) {
        const entity = this.buildPolicyAddressFrom(
          data.partialQuoteDetails.policyAddress
        );
        actions.push(new fromActions.AddPolicyAddress(entity));
        if (data.partialQuoteDetails.policyAddress.state) {
          actions.push(
            new fromActions.InitializeState(
              data.partialQuoteDetails.policyAddress.state
            )
          );
        }
        if (data.partialQuoteDetails.policyHolders) {
          const pniAddress = this.extractAddressFromPolicyHolder(data);
          const mailingAddress = this.buildPolicyAddressFrom(pniAddress);
          if (
            data.applicableProducts.applyToProperty &&
            data.partialQuoteDetails.policyAddress.addressLine1 !==
              mailingAddress?.addressLine1
          ) {
            actions.push(new fromActions.AddMailingAddress(mailingAddress));
          }
        }
      }
    } else {
      const phAddress = this.extractAddressFromPolicyHolder(data);
      if (phAddress) {
        const entity = this.buildPolicyAddressFrom(phAddress);
        if (this.isValidState(config, entity?.state)) {
          actions.push(new fromActions.AddPolicyAddress(entity));
          if (entity.state) {
            actions.push(new fromActions.InitializeState(entity.state));
          }
        }
      }
    }

    if (data?.applicableProducts) {
      if (data?.applicableProducts?.applyToAuto) {
        actions.push(
          new fromActions.UpdateSelectedProducts([
            {
              id: ProductTypes.AUTO,
              hasDrivingDataConsent: data.partialQuoteDetails
                .hasDrivingDataConsent
                ? true
                : false,
            },
          ])
        );
      }
      if (data?.applicableProducts?.applyToProperty) {
        if (
          data?.applicableProducts?.propertyType ===
          PartialQuoteProductTypes.HOMEOWNER
        ) {
          actions.push(
            new fromActions.AddToSelectedProducts({
              id: ProductTypes.HOMEOWNERS,
            })
          );
        } else if (
          data?.applicableProducts?.propertyType ===
          PartialQuoteProductTypes.CONDO
        ) {
          actions.push(
            new fromActions.AddToSelectedProducts({
              id: ProductTypes.CONDO,
            })
          );
        } else {
          actions.push(
            new fromActions.AddToSelectedProducts({
              id: ProductTypes.RENTERS,
            })
          );
        }
      }
    }

    let allActions = actions;
    if (vehicleVINActions.length) {
      allActions = allActions.concat(vehicleVINActions);
    }

    allActions.push(new fromActions.LoadPartialQuoteDataSuccess(data));

    return from(allActions);
  }

  private appendActionsForPolicyHoldersAndDrivers(
    actions,
    data: PartialQuote
  ): void {
    if (
      !data ||
      ((!data.partialQuoteDetails.drivers ||
        !data.partialQuoteDetails.drivers.length) &&
        (!data.partialQuoteDetails.policyHolders ||
          !data.partialQuoteDetails.policyHolders.length))
    ) {
      return;
    }
    // when there are no policy holders but there are drivers
    // add the drivers as policy holders/drivers
    // but exclude masked fields
    if (
      (!data.partialQuoteDetails.policyHolders ||
        !data.partialQuoteDetails.policyHolders.length) &&
      data.partialQuoteDetails.drivers &&
      data.partialQuoteDetails.drivers.length
    ) {
      const partialQuoteDrivers = [];
      const partialQuotePolicyHolders = [];
      // When there are no policy holders we must prefill first driver as policy Holder based on tempID or relation to PNI
      // load the rest of the drivers into fake store to be shown later
      // cannot prefill masked data in this scenario as policyholder add requests will fail
      const pniDriver = data.partialQuoteDetails.drivers.find(
        drv =>
          (drv.relationToPrimaryNamedInsured === DriverRelationToPNI.PNI &&
            !this.hasExistingDriverRelation(
              data.partialQuoteDetails.drivers,
              drv,
              DriverRelationToPNI.PNI
            )) ||
          drv.driverTempId === 1
      );
      const pniDriverEntity = DriverBuilder.entityFromDriver(pniDriver);
      pniDriverEntity.driverType = 'Driver';
      pniDriverEntity.relationToPrimaryNamedInsured =
        this.getDriverRelationToPNI(data, pniDriverEntity);
      pniDriverEntity.partialQuoteId = data.partialQuoteId;
      pniDriverEntity.selected = true;

      pniDriverEntity.person.gender = this.isValidGender(
        pniDriverEntity.person.gender
      );
      if (pniDriverEntity.person.gender === 'X') {
        pniDriverEntity.nonSpecifiedGender = true;
      }

      if (this.entityHasEnoughInsuredInfo(pniDriverEntity)) {
        partialQuoteDrivers.push(pniDriverEntity);
      }

      const pniPolicyHolderEntity: PolicyholderEntity = {
        person: this.buildAdhocPerson(pniDriverEntity),
        address: !!data.partialQuoteDetails.policyAddress
          ? { ...data.partialQuoteDetails.policyAddress }
          : null,
        policyHolderType: PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED,
        policyHolderTempId: 1,
        partialQuoteId: data.partialQuoteId,
      };
      if (this.entityHasEnoughInsuredInfo(pniPolicyHolderEntity)) {
        actions.push(
          new fromActions.AddPolicyholderSuccess(pniPolicyHolderEntity)
        );
      }

      for (const driver of data.partialQuoteDetails.drivers) {
        if (driver.driverTempId === pniDriverEntity.driverTempId) {
          continue;
        }
        const entity = DriverBuilder.entityFromDriver(driver);
        entity.driverType = 'Driver';
        entity.partialQuoteId = data.partialQuoteId;
        if (this.entityHasEnoughInsuredInfo(entity)) {
          partialQuoteDrivers.push(entity);
        }

        const policyHolderEntity: PolicyholderEntity = {
          person: {
            ...this.buildAdhocPerson(pniDriverEntity),
          },
          address: !!data.partialQuoteDetails.policyAddress
            ? { ...data.partialQuoteDetails.policyAddress }
            : null,
        };
        policyHolderEntity.policyHolderType = this.getPolicyHolderTypeFrom(
          data,
          policyHolderEntity
        );
        if (this.entityHasEnoughInsuredInfo(policyHolderEntity)) {
          partialQuotePolicyHolders.push(policyHolderEntity);
        }
      }
      if (data.applicableProducts.applyToAuto) {
        actions.push(
          new fromActions.AddPartialQuoteDrivers(partialQuoteDrivers)
        );
      }
      actions.push(
        new fromActions.AddPartialQuotePolicyHolders(partialQuotePolicyHolders)
      );
    }

    // when there are no drivers but there are policy holders
    if (
      (!data.partialQuoteDetails.drivers ||
        !data.partialQuoteDetails.drivers.length) &&
      data.partialQuoteDetails.policyHolders &&
      data.partialQuoteDetails.policyHolders.length
    ) {
      const partialQuoteDrivers = [];
      const partialQuotePolicyHolders = [];

      // When there are no drivers we must prefill first policy holder based on tempID or policy holder type
      // load the rest of the policyholders into fake store to be shown later when adding drivers
      // cannot prefill masked data in this scenario as driver add requests will fail
      const pniPolicyHolder = data.partialQuoteDetails.policyHolders.find(
        ph =>
          (ph.policyHolderType ===
            PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED &&
            !this.hasExistingPolicyHolderType(
              data.partialQuoteDetails.policyHolders,
              ph,
              PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
            )) ||
          ph.policyHolderTempId === 1
      );
      const policyHolderEntity: PolicyholderEntity = {
        ...pniPolicyHolder,
        person: {
          ...this.buildAdhocPerson(pniPolicyHolder),
          dateOfBirth: pniPolicyHolder.person
            ? pniPolicyHolder.person.dateOfBirth
            : null,
        },
        address: !!pniPolicyHolder.address
          ? {
              ...pniPolicyHolder.address,
              addressId: DEFAULT_ID,
            }
          : data.partialQuoteDetails.policyAddress
          ? { ...data.partialQuoteDetails.policyAddress }
          : null,
        partialQuoteId: data.partialQuoteId,
        policyHolderType: PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED,
      };
      if (this.entityHasEnoughInsuredInfo(policyHolderEntity)) {
        actions.push(
          new fromActions.AddPolicyholderSuccess(policyHolderEntity)
        );
      }

      for (const policyHolder of data.partialQuoteDetails.policyHolders) {
        if (
          policyHolder.policyHolderTempId ===
          policyHolderEntity.policyHolderTempId
        ) {
          continue;
        }
        const phEntity: PolicyholderEntity = {
          ...policyHolder,
          person: {
            ...this.buildAdhocPerson(policyHolder),
            dateOfBirth: policyHolder.person
              ? policyHolder.person.dateOfBirth
              : null,
          },
          address: !!policyHolder.address
            ? {
                ...policyHolder.address,
                addressId: DEFAULT_ID,
              }
            : data.partialQuoteDetails.policyAddress
            ? { ...data.partialQuoteDetails.policyAddress }
            : null,
          partialQuoteId: data.partialQuoteId,
        };
        phEntity.policyHolderType = this.getPolicyHolderTypeFrom(
          data,
          phEntity
        );
        if (this.entityHasEnoughInsuredInfo(phEntity)) {
          partialQuotePolicyHolders.push(phEntity);
        }

        const driverEntity: DriverEntity = {
          person: this.buildAdhocPerson(phEntity),
          driverType: 'Driver',
        };
        driverEntity.relationToPrimaryNamedInsured =
          this.getDriverRelationFromPolicyHolder(data, phEntity, driverEntity);
        if (this.entityHasEnoughInsuredInfo(driverEntity)) {
          partialQuoteDrivers.push(driverEntity);
        }
      }
      actions.push(
        new fromActions.AddPartialQuotePolicyHolders(partialQuotePolicyHolders)
      );
      if (data.applicableProducts.applyToAuto) {
        actions.push(
          new fromActions.AddPartialQuoteDrivers(partialQuoteDrivers)
        );
      }
    }

    // when there are both drivers and policy holders
    if (
      data.partialQuoteDetails.drivers &&
      data.partialQuoteDetails.drivers.length &&
      data.partialQuoteDetails.policyHolders &&
      data.partialQuoteDetails.policyHolders.length
    ) {
      const partialQuoteDrivers: DriverEntity[] = [];
      const partialQuotePolicyHolders: PolicyholderEntity[] = [];
      // when both drivers and policy holders exists, iterate through policy holders to find the
      // matching driver, add first policy holder based on policy type or tempID
      // load the remaining into the fake store

      const pniPolicyHolder = data.partialQuoteDetails.policyHolders.find(
        ph => {
          return (
            (ph.policyHolderType ===
              PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED &&
              !this.hasExistingPolicyHolderType(
                data.partialQuoteDetails.policyHolders,
                ph,
                PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
              )) ||
            ph.policyHolderTempId === 1
          );
        }
      );
      const phEntity: PolicyholderEntity = {
        ...pniPolicyHolder,
        person: {
          ...this.buildAdhocPerson(pniPolicyHolder),
          dateOfBirth: pniPolicyHolder.person
            ? pniPolicyHolder.person.dateOfBirth
            : null,
        },
        address: !!pniPolicyHolder.address
          ? {
              ...pniPolicyHolder.address,
              addressId: DEFAULT_ID,
            }
          : data.partialQuoteDetails.policyAddress
          ? { ...data.partialQuoteDetails.policyAddress }
          : null,
        partialQuoteId: data.partialQuoteId,
        policyHolderType: PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED,
      };
      if (this.entityHasEnoughInsuredInfo(phEntity)) {
        actions.push(new fromActions.AddPolicyholderSuccess(phEntity));
      }

      for (const policyHolder of data.partialQuoteDetails.policyHolders) {
        if (policyHolder.policyHolderTempId === phEntity.policyHolderTempId) {
          continue;
        }
        const policyHolderEntity: PolicyholderEntity = {
          ...policyHolder,
          person: {
            ...this.buildAdhocPerson(policyHolder),
            dateOfBirth: policyHolder.person
              ? policyHolder.person.dateOfBirth
              : null,
          },
          address: !!policyHolder.address
            ? {
                ...policyHolder.address,
                addressId: DEFAULT_ID,
              }
            : data.partialQuoteDetails.policyAddress
            ? { ...data.partialQuoteDetails.policyAddress }
            : null,
          partialQuoteId: data.partialQuoteId,
        };
        policyHolderEntity.policyHolderType = this.getPolicyHolderTypeFrom(
          data,
          policyHolderEntity
        );
        if (this.entityHasEnoughInsuredInfo(policyHolderEntity)) {
          partialQuotePolicyHolders.push(policyHolderEntity);
        }

        const foundDriver = this.findMatchDriverForPolicyHolder(
          data,
          policyHolderEntity
        );
        if (!foundDriver) {
          const driverEntity: DriverEntity = {
            person: this.buildAdhocPerson(policyHolderEntity),
            driverType: 'Driver',
          };
          driverEntity.relationToPrimaryNamedInsured =
            this.getDriverRelationFromPolicyHolder(
              data,
              policyHolderEntity,
              driverEntity
            );
          if (this.entityHasEnoughInsuredInfo(driverEntity)) {
            partialQuoteDrivers.push(driverEntity);
          }
          continue;
        }
        const driverEntity: DriverEntity = {
          ...foundDriver,
          person: {
            ...this.buildAdhocPerson(foundDriver),
            dateOfBirth: foundDriver.person
              ? foundDriver.person.dateOfBirth
              : null,
          },
          driverType: 'Driver',
          partialQuoteId: data.partialQuoteId,
        };
        driverEntity.relationToPrimaryNamedInsured =
          this.getDriverRelationFromPolicyHolder(
            data,
            policyHolderEntity,
            driverEntity
          );

        driverEntity.person.gender = this.isValidGender(
          driverEntity.person.gender
        );
        if (!this.isValidState(null, driverEntity.licenseState)) {
          driverEntity.licenseState = null;
          driverEntity.licenseNumber = null;
        }
        if (driverEntity.person.gender === 'X') {
          driverEntity.nonSpecifiedGender = true;
        }

        if (this.entityHasEnoughInsuredInfo(driverEntity)) {
          partialQuoteDrivers.push(driverEntity);
        }
      }

      // add any missing drivers that didnt have a policy holder match
      for (const driver of data.partialQuoteDetails.drivers) {
        const alreadyAdded = partialQuoteDrivers.find(
          drv => drv.driverTempId === driver.driverTempId
        );
        if (alreadyAdded) {
          continue;
        }
        const entity = DriverBuilder.entityFromDriver(driver);
        entity.selected = false;
        entity.driverType = 'Driver';
        entity.person.gender = this.isValidGender(driver.person.gender);
        if (entity.person.gender === 'X') {
          entity.nonSpecifiedGender = true;
        }
        if (!this.isValidState(null, entity.licenseState)) {
          entity.licenseState = null;
          entity.licenseNumber = null;
        }
        entity.partialQuoteId = data.partialQuoteId;
        if (DriverBuilder.driverMatchesPolicyholder(entity, pniPolicyHolder)) {
          entity.selected = true;
        }
        if (this.entityHasEnoughInsuredInfo(entity)) {
          partialQuoteDrivers.push(entity);
        }
      }

      actions.push(
        new fromActions.AddPartialQuotePolicyHolders(partialQuotePolicyHolders)
      );
      if (data.applicableProducts.applyToAuto) {
        actions.push(
          new fromActions.AddPartialQuoteDrivers(partialQuoteDrivers)
        );
      }
    }
  }

  private getDriverRelationToPNI(
    data: PartialQuote,
    driver: DriverEntity
  ): string {
    if (
      !data.partialQuoteDetails.policyHolders ||
      !data.partialQuoteDetails.policyHolders.length
    ) {
      return (driver.relationToPrimaryNamedInsured ===
        DriverRelationToPNI.PNI &&
        !this.hasExistingDriverRelation(
          data.partialQuoteDetails.drivers,
          driver,
          DriverRelationToPNI.PNI
        )) ||
        driver.driverTempId === 1
        ? DriverRelationToPNI.PNI
        : this.findDriverRelationToPNI(data, driver);
    }
    const policyHolderDriverMatches =
      data.partialQuoteDetails.policyHolders.filter(ph =>
        DriverBuilder.driverMatchesPolicyholder(driver, ph)
      );
    if (!policyHolderDriverMatches || !policyHolderDriverMatches.length) {
      return this.findDriverRelationToPNI(data, driver);
    }

    const policyHolder = policyHolderDriverMatches.reduce((res, obj) =>
      obj.policyHolderTempId < res.policyHolderTempId ? obj : res
    );
    return policyHolder.policyHolderType ===
      PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
      ? DriverRelationToPNI.PNI
      : this.getDriverRelationFromPolicyHolder(data, policyHolder, driver); // TODO test this
  }

  private findDriverRelationToPNI(
    data: PartialQuote,
    driver: DriverEntity
  ): string {
    let relationToPni = DriverRelationToPNI.OTHER;
    if (!driver.person) {
      return relationToPni;
    }
    if (
      driver.person.maritalStatus === MaritalStatusToDsmCodes.Married ||
      driver.person.maritalStatus === MaritalStatusToDsmCodes.Separated
    ) {
      if (
        !data.partialQuoteDetails.policyHolders ||
        !data.partialQuoteDetails.policyHolders.length
      ) {
        relationToPni = this.partialQuoteDriversHaveSpousePNI(
          data.partialQuoteDetails.drivers,
          driver.driverTempId
        )
          ? DriverRelationToPNI.SPOUSE
          : DriverRelationToPNI.OTHER;
      } else {
        relationToPni = this.partialQuotePolicyHoldersHaveSpousePNI(
          data.partialQuoteDetails.policyHolders,
          driver
        )
          ? DriverRelationToPNI.SPOUSE
          : DriverRelationToPNI.OTHER;
      }
    }
    return relationToPni;
  }

  private getDriverRelationFromPolicyHolder(
    data: PartialQuote,
    policyHolder: PolicyholderEntity,
    driver: DriverEntity
  ): string {
    if (
      (policyHolder.policyHolderType &&
        policyHolder.policyHolderType ===
          PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED) ||
      policyHolder.policyHolderTempId === 1
    ) {
      return DriverRelationToPNI.PNI;
    }
    if (
      policyHolder.person &&
      (policyHolder.person.maritalStatus === MaritalStatusToDsmCodes.Married ||
        policyHolder.person.maritalStatus ===
          MaritalStatusToDsmCodes.Separated) &&
      this.partialQuotePolicyHoldersHaveSpousePNI(
        data.partialQuoteDetails.policyHolders,
        driver
      ) &&
      this.findIdOfFirstNonPNISpouse(data.partialQuoteDetails.policyHolders) ===
        policyHolder.policyHolderTempId
    ) {
      return DriverRelationToPNI.SPOUSE;
    }
    return DriverRelationToPNI.OTHER;
  }

  private getPolicyHolderTypeFrom(
    data: PartialQuote,
    policyHolder: PolicyholderEntity
  ): string {
    let type = PolicyholderTypes.POLICY_ADDITIONAL_NAMED_INSURED;
    if (
      (policyHolder.policyHolderType ===
        PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED &&
        this.hasExistingPolicyHolderType(
          data.partialQuoteDetails.policyHolders,
          policyHolder,
          PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
        )) ||
      (policyHolder.policyHolderType ===
        PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED &&
        this.hasExistingPolicyHolderType(
          data.partialQuoteDetails.policyHolders,
          policyHolder,
          PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED
        ))
    ) {
      if (policyHolder.policyHolderTempId === 1) {
        type = PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED;
      }
      if (
        this.findIdOfFirstNonPNISpouse(
          data.partialQuoteDetails.policyHolders
        ) === policyHolder.policyHolderTempId
      ) {
        type = PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED;
      }
      if (policyHolder.policyHolderTempId > 2) {
        type = PolicyholderTypes.POLICY_ADDITIONAL_NAMED_INSURED;
      }
    }
    if (
      !policyHolder.policyHolderType &&
      !this.hasExistingPolicyHolderType(
        data.partialQuoteDetails.policyHolders,
        policyHolder,
        PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
      ) &&
      policyHolder.policyHolderTempId === 1
    ) {
      type = PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED;
    }
    if (
      !policyHolder.policyHolderType &&
      !this.hasExistingPolicyHolderType(
        data.partialQuoteDetails.policyHolders,
        policyHolder,
        PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED
      ) &&
      this.findIdOfFirstNonPNISpouse(data.partialQuoteDetails.policyHolders) ===
        policyHolder.policyHolderTempId
    ) {
      type = PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED;
    }
    if (
      !policyHolder.policyHolderType &&
      this.hasExistingPolicyHolderType(
        data.partialQuoteDetails.policyHolders,
        policyHolder,
        PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED
      ) &&
      policyHolder.policyHolderTempId > 2
    ) {
      type = PolicyholderTypes.POLICY_ADDITIONAL_NAMED_INSURED;
    }

    if (
      (policyHolder.policyHolderType ===
        PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED &&
        !this.hasExistingPolicyHolderType(
          data.partialQuoteDetails.policyHolders,
          policyHolder,
          PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
        )) ||
      (policyHolder.policyHolderType ===
        PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED &&
        !this.hasExistingPolicyHolderType(
          data.partialQuoteDetails.policyHolders,
          policyHolder,
          PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED
        ))
    ) {
      type = policyHolder.policyHolderType;
    }

    if (
      policyHolder.policyHolderType ===
        PolicyholderTypes.POLICY_ADDITIONAL_NAMED_INSURED &&
      this.hasExistingPolicyHolderType(
        data.partialQuoteDetails.policyHolders,
        policyHolder,
        PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
      ) &&
      this.hasExistingPolicyHolderType(
        data.partialQuoteDetails.policyHolders,
        policyHolder,
        PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED
      )
    ) {
      type = policyHolder.policyHolderType;
    }

    if (
      policyHolder.policyHolderType ===
        PolicyholderTypes.POLICY_ADDITIONAL_NAMED_INSURED &&
      (!this.hasExistingPolicyHolderType(
        data.partialQuoteDetails.policyHolders,
        policyHolder,
        PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
      ) ||
        !this.hasExistingPolicyHolderType(
          data.partialQuoteDetails.policyHolders,
          policyHolder,
          PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED
        ))
    ) {
      if (policyHolder.policyHolderTempId === 1) {
        type = PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED;
      }
      if (
        this.findIdOfFirstNonPNISpouse(
          data.partialQuoteDetails.policyHolders
        ) === policyHolder.policyHolderTempId
      ) {
        type = PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED;
      }
      if (policyHolder.policyHolderTempId > 2) {
        type = PolicyholderTypes.POLICY_ADDITIONAL_NAMED_INSURED;
      }
    }

    return type;
  }

  private hasExistingPolicyHolderType(
    policyHolders: PolicyholderEntity[],
    policyHolder: PolicyholderEntity,
    type: string
  ): boolean {
    return (
      policyHolders &&
      !!policyHolders.find(
        ph =>
          ph.policyHolderType === type &&
          ph.policyHolderTempId !== policyHolder.policyHolderTempId
      )
    );
  }

  private hasExistingDriverRelation(
    drivers: DriverEntity[],
    driver: DriverEntity,
    relation: string
  ) {
    return (
      drivers &&
      !!drivers.find(
        drv =>
          drv.relationToPrimaryNamedInsured === relation &&
          drv.driverTempId !== driver.driverTempId
      )
    );
  }

  private partialQuoteDriversHaveSpousePNI(
    drivers: DriverEntity[],
    tempId: number
  ): boolean {
    return !!drivers.find(
      drv =>
        (drv.driverTempId !== tempId &&
          drv.relationToPrimaryNamedInsured === DriverRelationToPNI.PNI &&
          drv.person &&
          (drv.person.maritalStatus === MaritalStatusToDsmCodes.Married ||
            drv.person.maritalStatus === MaritalStatusToDsmCodes.Separated)) ||
        (drv.driverTempId < tempId &&
          !drv.relationToPrimaryNamedInsured &&
          drv.person &&
          (drv.person.maritalStatus === MaritalStatusToDsmCodes.Married ||
            drv.person.maritalStatus === MaritalStatusToDsmCodes.Separated))
    );
  }

  private partialQuotePolicyHoldersHaveSpousePNI(
    policyHolders: PolicyholderEntity[],
    driver: DriverEntity
  ): boolean {
    return !!policyHolders.find(
      ph =>
        (ph.policyHolderType ===
          PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED &&
          !DriverBuilder.driverMatchesPolicyholder(driver, ph) &&
          ph.person &&
          (ph.person.maritalStatus === MaritalStatusToDsmCodes.Married ||
            ph.person.maritalStatus === MaritalStatusToDsmCodes.Separated)) ||
        (!ph.policyHolderType &&
          ph.policyHolderTempId === 1 &&
          !DriverBuilder.driverMatchesPolicyholder(driver, ph) &&
          ph.person &&
          (ph.person.maritalStatus === MaritalStatusToDsmCodes.Married ||
            ph.person.maritalStatus === MaritalStatusToDsmCodes.Separated))
    );
  }

  private findIdOfFirstNonPNISpouse(
    policyHolders: PolicyholderEntity[]
  ): number {
    if (!policyHolders) {
      return 0;
    }
    const nonPniSpouse = policyHolders.find(
      ph =>
        ((ph.policyHolderType &&
          ph.policyHolderType !==
            PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED) ||
          ph.policyHolderTempId !== 1) &&
        ph.person &&
        (ph.person.maritalStatus === MaritalStatusToDsmCodes.Married ||
          ph.person.maritalStatus === MaritalStatusToDsmCodes.Separated)
    );
    return nonPniSpouse ? nonPniSpouse.policyHolderTempId : null;
  }

  private entityHasEnoughInsuredInfo(
    entity: DriverEntity | PolicyholderEntity
  ): boolean {
    return (
      !!entity.person && !!entity.person.firstName && !!entity.person.lastName
    );
  }

  private buildAdhocPerson(
    entity: DriverEntity | PolicyholderEntity
  ): PersonEntity {
    return {
      prefix: !!entity.person ? entity.person.prefix : null,
      firstName: !!entity.person ? entity.person.firstName : null,
      middleName: !!entity.person ? entity.person.middleName : null,
      lastName: !!entity.person ? entity.person.lastName : null,
      suffix: !!entity.person ? entity.person.suffix : null,
      gender: !!entity.person ? entity.person.gender : null,
      maritalStatus: !!entity.person ? entity.person.maritalStatus : null,
      dateOfBirth: !!entity.person ? entity.person.dateOfBirth : null, // cannot use masked fields when data is not provided from API
    };
  }

  private findMatchDriverForPolicyHolder(
    data: PartialQuote,
    policyHolder: PolicyholderEntity
  ): DriverEntity {
    if (
      !data.partialQuoteDetails.drivers ||
      !data.partialQuoteDetails.drivers.length
    ) {
      return null;
    }
    return data.partialQuoteDetails.drivers.find(drv =>
      DriverBuilder.driverMatchesPolicyholder(drv, policyHolder)
    );
  }

  private buildPolicyAddressFrom(policyAddress: AddressEntity): AddressEntity {
    return <AddressEntity>{
      addressId: DEFAULT_ID,
      addressIds: {
        [ProductTypes.AUTO]: DEFAULT_ID,
      },
      addressLine1: policyAddress.addressLine1,
      addressLine2: policyAddress.addressLine2,
      city: policyAddress.city,
      state: policyAddress.state,
      postalCode: policyAddress.postalCode,
      country: null,
      street: `${policyAddress.addressLine1 || ''}, ${
        policyAddress.city || ''
      },  ${policyAddress.state || ''}, ${policyAddress.postalCode || ''}`,
    };
  }

  private extractAddressFromPolicyHolder(data: PartialQuote): AddressEntity {
    if (
      data.partialQuoteDetails.policyHolders &&
      data.partialQuoteDetails.policyHolders.length
    ) {
      const primaryPolicyHolder = data.partialQuoteDetails.policyHolders.find(
        ph =>
          (ph.policyHolderType &&
            ph.policyHolderType ===
              PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED) ||
          ph.policyHolderTempId === 1
      );
      return primaryPolicyHolder &&
        primaryPolicyHolder.address &&
        primaryPolicyHolder.address.addressLine1
        ? primaryPolicyHolder.address
        : null;
    }
    return null;
  }

  private isValidState(config: PrivateLabel, state: string): boolean {
    if (config) {
      return config
        ? config?.producerInformation.some(producer => producer.state === state)
        : true;
    } else {
      let activeStates = [];
      this.getActiveStateAbbreviationsData()
        .pipe(take(1))
        .subscribe(states => (activeStates = states));
      return activeStates
        ? activeStates.some(activeState => activeState === state)
        : true;
    }
  }

  private isValidGender(gender: string): string {
    if (gender !== 'M' && gender !== 'F') {
      return null;
    } else return gender;
  }
}
