import { Injectable } from '@angular/core';
import { MetadataService } from './metadata.service';
import { environment } from 'environments/environment';
import { PolicyService } from './policy.service';

@Injectable()
export class FeatureService {
  constructor(
    private _metadataService: MetadataService,
    private _policyService: PolicyService
  ) {}

  featureEffective(featureName: string): boolean {
    let features;
    this._metadataService
      .getFeaturesData()
      .subscribe(returnedFeatures => (features = returnedFeatures))
      .unsubscribe();

    if (features.features.hasOwnProperty(featureName)) {
      const featureEffectiveDate = new Date(features.features[featureName]);

      let systemDate;
      if (!environment.production) {
        this._policyService
          .getPolicyEffectiveDate()
          .subscribe(
            effectiveDate =>
              (systemDate = new Date(effectiveDate.baseEffectiveDate))
          )
          .unsubscribe();
      } else {
        systemDate = new Date();
      }

      if (systemDate.valueOf() >= featureEffectiveDate.valueOf()) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  featureNotEffective(featureName: string): boolean {
    return !this.featureEffective(featureName);
  }
}
