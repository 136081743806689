import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { TelematicsVehiclePremium } from '@core/models/telematics/telematics.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { StateSpecificFlagKeys } from '@shared/constants/app-constants';

@Component({
  selector: 'mq-smartride-enrollment',
  templateUrl: './smartride-enrollment.component.html',
  styleUrls: ['../../../../../../assets/scss/telematics-panel.scss'],
})
export class SmartRideEnrollmentComponent {
  @Input() vehicles: VehicleEntity[];
  @Input() vehiclePremiums: TelematicsVehiclePremium[];
  @Input() mobileAppEnrolled: boolean;
  @Input() isDeviceOnlyState: boolean;
  @Input() initialDiscount: string;
  @Input() maximumDiscount: string;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;

  @Output() smartRideEnrollmentChanged = new EventEmitter<VehicleEntity>();

  headerIcon =
    '<img class="tm-icon" src="assets/images/telematics/SR-icon-white.svg" alt="Car-Image" />';

  changeEnrollment(vehicle: VehicleEntity) {
    this.smartRideEnrollmentChanged.emit(vehicle);
  }

  isSmartRideEarlyRewardApplicable(): boolean {
    if (
      this.stateSpecificFlags &&
      this.stateSpecificFlags[StateSpecificFlagKeys.smartRideEarlyReward]
    ) {
      return true;
    } else return false;
  }

  isVehicleMiles(): boolean {
    if (
      this.stateSpecificFlags &&
      this.stateSpecificFlags[StateSpecificFlagKeys.vehicleMiles]
    ) {
      return true;
    } else return false;
  }
}
