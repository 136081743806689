import * as fromReducer from '@core/store/reducers/recoverable-error.reducer';
import * as fromFeature from '@core/store/reducers';
import { createSelector } from '@ngrx/store';

export const getRecoverableErrorState = createSelector(
  fromFeature.getAppState,
  state => state.recoverableError
);

export const {
  selectAll: getAllRecoverableErrors,
  selectEntities: getRecoverableErrorEntities,
} = fromReducer.adapter.getSelectors(getRecoverableErrorState);

export const getRecoverableErrorById = (id: number) =>
  createSelector(getRecoverableErrorEntities, entities => entities[id]);

export const getRecoverableErrorsByProduct = (productId: string) =>
  createSelector(getAllRecoverableErrors, errors =>
    errors.filter(e => e.productId === productId)
  );

export const getRecoverableErrorsByPage = (page: string) =>
  createSelector(getAllRecoverableErrors, errors =>
    errors.filter(e => e.page === page || !e.page)
  );
