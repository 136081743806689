import { Injectable } from '@angular/core';
import { LocationExposureRequest } from '@core/models/umbrella/location-exposure-request.model';
import { Observable } from 'rxjs';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { LocationExposureEntity } from '@core/models/entities/location-exposure.entity';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationExposureAdapter extends BaseDsmAdapter {
  addLocationExposure(request: LocationExposureRequest): Observable<any> {
    const url = `${this.getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/location-exposures`;

    return this.post<LocationExposureEntity>(
      url,
      { locationExposure: request.locationExposure },
      { productId: request.productId }
    ).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }

  updateLocationExposure(request: LocationExposureRequest): Observable<any> {
    const url = `${this.getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/location-exposures/${request.locationExposure.locationExposureId}`;

    return this.put<LocationExposureEntity>(
      url,
      { locationExposure: request.locationExposure },
      { productId: request.productId }
    ).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }
}
