import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { CRUDService } from '@core/services/crud.service';
import { LocationExposureEntity } from '@core/models/entities/location-exposure.entity';

@Injectable({
  providedIn: 'root',
})
export class LocationExposureDao
  implements CRUDService<LocationExposureEntity>
{
  constructor(private _store: Store<fromStore.AppState>) {}

  add(entity: LocationExposureEntity): void {
    this._store.dispatch(new fromActions.AddLocationExposure(entity));
  }

  addAll?(entity: LocationExposureEntity[]): void {
    throw new Error('Method not implemented.');
  }

  delete(entityId: string): void {
    throw new Error('Method not implemented.');
  }

  update(entity: LocationExposureEntity): void {
    this._store.dispatch(new fromActions.UpdateLocationExposure(entity));
  }

  get?(entityId: string): Observable<LocationExposureEntity> {
    throw new Error('Method not implemented.');
  }

  getAll?(): Observable<LocationExposureEntity[]> {
    return this._store.select(fromSelectors.selectAllLocationExposures);
  }

  isLocationExposuresLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getLocationExposureLoaded);
  }
}
