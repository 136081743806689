import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import {
  TelematicsVehiclePremium,
  TelematicsEnrollmentRecommendationResponse,
} from '@core/models/telematics/telematics.model';
import {
  SmartMilesMethods,
  TelematicsProgram,
} from '@shared/constants/app-constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SmartDeviceModalComponent } from '../smart-device-modal/smart-device-modal.component';
import { PaymentChartModalComponent } from '../payment-chart-modal/payment-chart-modal.component';
import { TelematicsEnrollmentsService } from '@core/services/telematics-enrollments.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'mq-smartmiles-enrollment',
  templateUrl: './smartmiles-enrollment.component.html',
  styleUrls: ['../../../../../../assets/scss/telematics-panel.scss'],
})
export class SmartMilesEnrollmentComponent {
  @Input() vehicles: VehicleEntity[];
  @Input() vehiclePremiums: TelematicsVehiclePremium[];
  @Input() isDeviceOnlyState: boolean;
  recommendation: TelematicsEnrollmentRecommendationResponse;
  SMVehicles: VehicleEntity[];
  SMCCVehicles: VehicleEntity[];

  @Output() smartMilesEnrollmentChanged = new EventEmitter<VehicleEntity>();
  constructor(
    private _modalService: NgbModal,
    private _telematicsEnrollmentsService: TelematicsEnrollmentsService
  ) {}

  ngOnInit() {
    this.SMCCVehicles = this.vehicles.filter(
      vehicle =>
        vehicle?.dataCollectionMethod === SmartMilesMethods.ConnectedCar
    );

    this.SMVehicles = this.vehicles.filter(
      vehicle =>
        vehicle?.dataCollectionMethod !== SmartMilesMethods.ConnectedCar
    );
    this.getRecommendation();
  }
  headerIcon(isSMConnectedCar: boolean): string {
    return isSMConnectedCar
      ? '<img class="tm-icon" src="assets/images/telematics/connected-car-icon-white.svg" alt="Car-Image" />'
      : '<img class="tm-icon" src="assets/images/telematics/SM-icon-white.svg" alt="Car-Image" />';
  }
  getSMdeviceHelpText(): any {
    const modal = this._modalService.open(SmartDeviceModalComponent);
  }
  getPaymentHelpText(): any {
    const modal = this._modalService.open(PaymentChartModalComponent);
  }

  getSMdeviceHelpText_CC(): string {
    return `<strong>How does this work?</strong> Your car has built-in connected car technology that can capture driving trends.
     You can enable sharing data with <strong> Nationwide.</strong>`;
  }
  getPaymentHelpText_CC(): string {
    return ` <strong>How does this work?.</strong> Your monthly cost consists of two parts: a <strong> base rate</strong>, which is the same every month and a <strong> variable rate </strong>(number of miles driven multiplied by the cost per miles),
   which varies depending on <strong> mileage and driving trends</strong>.
   Your cost per mile may increase or decrease each month based on these four driving factors:
   Route Consistency, Smooth Speed Changes, Stop-and-Go Traffic, Hard Braking.`;
  }

  getRecommendation(): void {
    this._telematicsEnrollmentsService
      .getRecommendation()
      .pipe(take(1))
      .subscribe(recommendation => {
        this.recommendation = recommendation;
      });
  }

  shouldOfferSmartMiles_v2(): boolean {
    let smartmiles2_Eligible = false;
    if (
      this.recommendation?.recommendedProgram === 'Vehicle Program' &&
      this.recommendation?.qualifyingInformation.vehicles.find(
        vehicle => vehicle.availablePrograms.length > 0
      )
    ) {
      this.recommendation.qualifyingInformation.vehicles.forEach(vehicle => {
        vehicle.availablePrograms.forEach(availableProgram => {
          if (
            availableProgram.version === '2.0' &&
            availableProgram.name === TelematicsProgram.SMART_MILES
          ) {
            smartmiles2_Eligible = true;
          }
        });
      });
    }
    return smartmiles2_Eligible;
  }

  changeEnrollment(vehicle: VehicleEntity) {
    this.smartMilesEnrollmentChanged.emit(vehicle);
  }
}
