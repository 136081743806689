import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { DigitalIdCardsTextService } from '@core/services/digital-id-cards-text.service';
import { LoggingService } from '@core/services/logging.service';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { take, switchMap, tap, mergeMap, catchError } from 'rxjs/operators';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable()
export class DigitalIdCardsTextEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _digitalIdCardsTextService: DigitalIdCardsTextService,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  sendDigitalAutoIdCards$ = this._actions$.pipe(
    ofType(fromActions.DigitalIdCardsTextActionTypes.SEND_AUTO_ID_CARDS),
    switchMap(() =>
      this._store
        .select(fromSelectors.buildDigitalIdCardsTextRequest(ProductTypes.AUTO))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('sendDigitalAutoIdCards Request', request)
    ),
    switchMap(request =>
      this._digitalIdCardsTextService.sendText(request).pipe(
        tap(response =>
          this._loggingService.log('sendDigitalAutoIdCards Response', response)
        ),
        mergeMap(response => {
          return of(new fromActions.SendAutoIdCardsSuccess(response));
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('sendDigitalAutoIdCards Error', safeError);
          return of(new fromActions.SendAutoIdCardsFail(safeError));
        })
      )
    )
  );
}
