import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { PolicyEffectiveDates } from '@core/models/policy/policy-effective-date.model';

@Injectable()
export class PolicyDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  setPolicyEffectiveDate(policyEffectiveDates: PolicyEffectiveDates): void {
    this._store.dispatch(
      new fromActions.SetPolicyEffectiveDate(policyEffectiveDates)
    );
  }

  getPolicyEffectiveDate(): Observable<PolicyEffectiveDates> {
    return this._store.select(fromSelectors.getPolicyEffectiveDates);
  }
}
