import { Injectable } from '@angular/core';
import { LocationExposureEntity } from '@core/models/entities/location-exposure.entity';
import { LocationExposureDao } from '@core/dao/location-exposure.dao';
import { CRUDService } from '@core/services/crud.service';
import { LocationExposureRequest } from '@core/models/umbrella/location-exposure-request.model';
import { Observable } from 'rxjs';
import { LocationExposureAdapter } from '@core/adapters/location-exposure.adapter';

@Injectable({
  providedIn: 'root',
})
export class LocationExposureService
  implements CRUDService<LocationExposureEntity>
{
  constructor(
    private _locationExposureDao: LocationExposureDao,
    private _locationExposureAdapter: LocationExposureAdapter
  ) {}
  add(entity: LocationExposureEntity): void {
    this._locationExposureDao.add(entity);
  }
  addAll?(entity: LocationExposureEntity[]): void {
    throw new Error('Method not implemented.');
  }
  delete(entityId: string): void {
    throw new Error('Method not implemented.');
  }
  update(entity: LocationExposureEntity): void {
    this._locationExposureDao.update(entity);
  }
  get?(entityId: string): Observable<LocationExposureEntity> {
    throw new Error('Method not implemented.');
  }
  getAll?(): Observable<LocationExposureEntity[]> {
    return this._locationExposureDao.getAll();
  }

  isLocationExposuresLoaded(): Observable<boolean> {
    return this._locationExposureDao.isLocationExposuresLoaded();
  }

  addLocationExposure(
    request: LocationExposureRequest
  ): Observable<LocationExposureEntity> {
    return this._locationExposureAdapter.addLocationExposure(request);
  }

  updateLocationExposure(
    request: LocationExposureRequest
  ): Observable<LocationExposureEntity> {
    return this._locationExposureAdapter.updateLocationExposure(request);
  }
}
