import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromHouseholdMembers from '@core/store/reducers/household-member.reducer';

import { HouseholdMemberEntity } from '@core/models/entities/household-member.entity';
import { HouseholdMemberRequest } from '@core/models/umbrella/household-member-request';
import * as fromDriver from '@core/store/selectors/driver.selector';
import * as fromPowersportsDriver from '@core/store/selectors/powersports-driver.selector';

export const getHouseholdMemberstate = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.householdMembers
);

export const {
  selectEntities: selectHouseholdMemberEntities,
  selectAll: selectAllHouseholdMembers,
} = fromHouseholdMembers.adapter.getSelectors(getHouseholdMemberstate);

export const getHouseholdMember = (householdMemberId: string) =>
  createSelector(
    selectHouseholdMemberEntities,
    entities => entities[householdMemberId]
  );

export const buildHouseholdMemberRequest = (member: HouseholdMemberEntity) =>
  createSelector(
    fromProducts.getProduct(member.productId),
    fromTokens.getAccessToken(member.productId),
    fromDriver.selectAllDrivers,
    fromPowersportsDriver.selectAllPowersportsDrivers,
    (product, accessToken, drivers, powersportsDrivers) => {
      const autoDriver = drivers.find(driver => {
        return (
          driver?.nonSpecifiedGender &&
          driver?.person?.firstName === member?.person?.firstName
        );
      });
      const powersportsDriver = powersportsDrivers.find(driver => {
        return (
          driver?.nonSpecifiedGender &&
          driver?.person?.firstName === member?.person?.firstName
        );
      });
      let nonspecifiedGender = false;
      if (
        autoDriver?.nonSpecifiedGender ||
        powersportsDriver?.nonSpecifiedGender
      ) {
        nonspecifiedGender = true;
      } else nonspecifiedGender = false;
      return {
        accessToken,
        productId: product.id,
        quoteId: product.quoteId,
        sessionId: product.sessionId,
        householdMember: { ...member, nonSpecifiedGender: nonspecifiedGender },
      } as HouseholdMemberRequest;
    }
  );
