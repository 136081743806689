import { RetrieveService } from '@core/services/retrieve.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router/';
import { DriverVehiclePrefillService } from '@core/services/driver-vehicle-prefill.service';
import { ProductsService } from '@core/services';
import { take, filter, tap, mergeMap } from 'rxjs/operators';
import { of, Observable, combineLatest } from 'rxjs';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class DriverVehiclePrefillGuard implements CanActivate {
  constructor(
    private _driverVehiclePrefillService: DriverVehiclePrefillService,
    private _productsService: ProductsService,
    private _retrieveService: RetrieveService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const isAutoSelected$ = this._productsService.isProductSelectedWithoutError(
      ProductTypes.AUTO
    );
    const isQuoteRetrieved$ = this._retrieveService.getRetrieveSuccess();
    return combineLatest([isAutoSelected$, isQuoteRetrieved$]).pipe(
      take(1),
      mergeMap(([isAutoSelected, isQuoteRetrieved]) => {
        if (isAutoSelected && !isQuoteRetrieved) {
          return this.checkStore();
        } else {
          return of(true);
        }
      })
    );
  }

  checkStore(): Observable<boolean> {
    return this._driverVehiclePrefillService
      .getDriverVehiclePrefillLoaded()
      .pipe(
        tap(loaded => {
          if (!loaded) {
            this._driverVehiclePrefillService.getCustomerData();
          }
        }),
        filter(loaded => loaded),
        take(1)
      );
  }
}
