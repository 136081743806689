<div class="smartride" *ngIf="vehicles.length">
  <nw-display-panel
    heading="Earn a discount by enrolling in our low mileage program, SmartRide"
    [alwaysOpen]="true"
    [openText]="headerIcon"
    class="program-panel"
  >
    <div class="tm-content-container">
      <p>
        Save {{ initialDiscount }} just for signing up. The safer the vehicle is
        driven the higher the discount - up to {{ maximumDiscount }}. How does
        it work?
      </p>
      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <p class="font-weight-bold">
            Send us a photo or recent service receipt showing the current
            odometer reading, and provide updates before each policy renewal.
          </p>
        </div>
        <div class="col-1">
          <img class="tm-icon" src="assets/images/Calendar_Blue.svg"
          alt="Calendar"
          />
        </div>
        <div class="col-sm-3 col-xs-12">
          <p class="font-weight-bold">
            Keep the discount for as long as the vehicles are insured with us,
            and proof is provided.
          </p>
        </div>
        <mq-telematics-video
          [name]="'SmartRide'"
          [isDeviceOnlyState]="isDeviceOnlyState"
          [mobileAppEnrolled]="mobileAppEnrolled"
        ></mq-telematics-video>
      </div>
    </div>
    <div class="disclaimer" *ngIf="!mobileAppEnrolled">
      If no vehicles are enrolled in SmartMiles, Smartride participation will be
      by mobile app rather than device.
    </div>
    <mq-telematics-vehicle-premium
      [vehicles]="vehicles"
      [vehiclePremiums]="vehiclePremiums"
      (enrollmentChanged)="changeEnrollment($event)"
    ></mq-telematics-vehicle-premium>
  </nw-display-panel>
</div>
