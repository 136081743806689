import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  filter,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { DriverService } from '@core/services/driver.service';
import { ProductsService, LoadingService } from '@core/services';
import { LoggingService } from '@core/services/logging.service';
import { DriverEntity } from '@core/models/entities/driver.entity';
import {
  ProductTypes,
  PageAlertIDs,
  DriverRelationToPNI,
} from '@shared/constants/app-constants';
import { ErrorMessageService } from '@core/services/error-message.service';
import { AddOtherExistingPeopleAsDrivers } from '@core/store/actions';
import {
  DriverRequest,
  PowersportsDriverRequest,
} from '@core/models/auto/quotes/driver-request.model';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { DriverHelper } from '@core/services/helpers/driver.helper';

@Injectable()
export class DriverEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _driverService: DriverService,
    private _productService: ProductsService,
    private _loggingService: LoggingService,
    private _errorMessageService: ErrorMessageService,
    private _loadingService: LoadingService,
    private _errorHelper: ErrorHelper,
    private driverHelper: DriverHelper
  ) {}

  @Effect()
  addDriver$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.ADD_DRIVER),
    map((action: fromActions.AddDriver) => [
      action.payload,
      action.isPrimaryDriver,
    ]),
    mergeMap(([driverEntity, isPrimaryDriver]: [DriverEntity, boolean]) =>
      this._productService.getAutoTokenLoaded().pipe(
        filter(loaded => loaded),
        switchMap(loaded => {
          if (loaded) {
            return this._store
              .select(fromSelectors.buildDriverRequest(driverEntity))
              .pipe(take(1));
          }
        }),
        take(1),
        tap(request => {
          if (
            request.driver.relationToPrimaryNamedInsured !==
            DriverRelationToPNI.PNI
          ) {
            this._loadingService.loadingBegin();
          }
          this._loggingService.log('addDriver Request', request);
        }),
        switchMap(request =>
          this._driverService.addDriver(request).pipe(
            tap(response =>
              this._loggingService.log('addDriver Response', response)
            ),
            switchMap(response => {
              if (
                request.driver.relationToPrimaryNamedInsured !==
                DriverRelationToPNI.PNI
              ) {
                this._loadingService.loadingEnd();
              }
              return this.driverHelper.getAddDriverNextActions(
                request,
                response,
                driverEntity.person.dateOfBirth
              );
            }),
            catchError(error => {
              if (
                request.driver.relationToPrimaryNamedInsured !==
                DriverRelationToPNI.PNI
              ) {
                this._loadingService.loadingEnd();
              }
              const errorActions = [];
              const safeError = this._errorHelper.sanitizeError(error);
              this._loggingService.logToSplunk({
                event: 'API_ERROR_ADD_DRIVER_FAIL',
                message: safeError,
              });
              errorActions.push(new fromActions.AddDriverFail(safeError));
              if (isPrimaryDriver) {
                errorActions.push(
                  new fromActions.FailProduct(ProductTypes.AUTO)
                );
              }
              return from(errorActions);
            })
          )
        )
      )
    )
  );

  @Effect()
  addDriverFailed$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.ADD_DRIVER_FAIL),
    switchMap((action: fromActions.AddDriverFail) => {
      this._loggingService.log('addDriver Error', action.payload);
      return this._errorMessageService
        .composeRecoverableError(
          ProductTypes.AUTO,
          action.payload,
          PageAlertIDs['people']
        )
        .pipe(map(error => new fromActions.AddRecoverableError(error)));
    })
  );

  @Effect()
  addPowersportsDriver$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.ADD_POWERSPORTS_DRIVER),
    map((action: fromActions.AddDriver) => [
      action.payload,
      action.isPrimaryDriver,
    ]),
    mergeMap(([driverEntity, isPrimaryDriver]: [DriverEntity, boolean]) =>
      this._productService.getPowersportsTokenLoaded().pipe(
        filter(loaded => loaded),
        switchMap(loaded => {
          if (loaded) {
            return this._store
              .select(fromSelectors.buildPowersportsDriverRequest(driverEntity))
              .pipe(take(1));
          }
        }),
        take(1),
        tap(request =>
          this._loggingService.log('addPowersportsDriver Request', request)
        ),
        switchMap(request =>
          this._driverService.addPowersportsDriver(request).pipe(
            tap(response =>
              this._loggingService.log(
                'addPowersportsDriver Response',
                response
              )
            ),
            switchMap(response => {
              return this.driverHelper.getAddPowerSportsDriverNextActions(
                request,
                response,
                driverEntity.person.dateOfBirth
              );
            }),
            catchError(error => {
              const errorActions = [];
              const safeError = this._errorHelper.sanitizeError(error);
              this._loggingService.logToSplunk({
                event: 'API_ERROR_ADD_POWERSPORTS_DRIVER_FAIL',
                message: safeError,
              });
              errorActions.push(
                new fromActions.AddPowersportsDriverFail(safeError)
              );
              if (isPrimaryDriver) {
                errorActions.push(
                  new fromActions.Softfall('Initial Driver Add Failed')
                );
              }
              return from(errorActions);
            })
          )
        )
      )
    )
  );

  @Effect()
  addPowersportsDriverFailed$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.ADD_POWERSPORTS_DRIVER_FAIL),
    switchMap((action: fromActions.AddPowersportsDriverFail) => {
      this._loggingService.log('addPowersportsDriver Error', action.payload);
      return this._errorMessageService
        .composeRecoverableError(
          ProductTypes.POWERSPORTS,
          action.payload,
          PageAlertIDs['people']
        )
        .pipe(map(error => new fromActions.AddRecoverableError(error)));
    })
  );

  @Effect()
  updateDriverFailed$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.UPDATE_DRIVER_FAIL),
    switchMap((action: fromActions.UpdateDriverFail) => {
      this._loggingService.log('updateDriver Error', action.payload);
      return this._errorMessageService
        .composeRecoverableError(
          action.request.productId,
          action.payload,
          PageAlertIDs['people']
        )
        .pipe(map(error => new fromActions.AddRecoverableError(error)));
    })
  );

  @Effect()
  updatePowersportsDriverFailed$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.UPDATE_POWERSPORTS_DRIVER_FAIL),
    switchMap((action: fromActions.UpdatePowersportsDriverFail) => {
      this._loggingService.log('updatePowersportsDriver Error', action.payload);
      return this._errorMessageService
        .composeRecoverableError(
          action.request.productId,
          action.payload,
          PageAlertIDs['people']
        )
        .pipe(map(error => new fromActions.AddRecoverableError(error)));
    })
  );

  @Effect()
  updateDriver$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.UPDATE_DRIVER),
    map((actions: fromActions.UpdateDriver) => actions.payload),
    switchMap(payload => {
      return this._store.select(fromSelectors.buildDriverRequest(payload)).pipe(
        take(1),
        map(request => [request, payload])
      );
    }),
    tap(([request, payload]) =>
      this._loggingService.log('updateDriver Request', request)
    ),
    mergeMap(([request, payload]: [DriverRequest, DriverEntity]) => {
      if (request?.driver?.driverId === undefined) {
        return of(new fromActions.UpdateDriverFail(payload, request));
      } else
        return this._driverService.updateDriver(request).pipe(
          tap(response =>
            this._loggingService.log('updateDriver Response', response)
          ),
          switchMap(response => {
            return this.driverHelper.getUpdateDriverNextActions(
              request,
              response,
              payload.person.dateOfBirth
            );
          }),
          catchError(error =>
            of(
              new fromActions.UpdateDriverFail(
                this._errorHelper.sanitizeError(error),
                request
              )
            )
          )
        );
    })
  );

  @Effect()
  updatePowersportsDriver$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.UPDATE_POWERSPORTS_DRIVER),
    map((actions: fromActions.UpdatePowersportsDriver) => actions.payload),
    switchMap(payload => {
      return this._store
        .select(fromSelectors.buildPowersportsDriverRequest(payload))
        .pipe(
          take(1),
          map(request => [request, payload])
        );
    }),
    tap(([request, payload]) =>
      this._loggingService.log('updatePowersportsDriver Request', request)
    ),
    mergeMap(([request, payload]: [PowersportsDriverRequest, DriverEntity]) => {
      if (request?.driver?.driverId === undefined) {
        return of(
          new fromActions.UpdatePowersportsDriverFail(payload, request)
        );
      } else
        return this._driverService.updatePowersportsDriver(request).pipe(
          tap(response =>
            this._loggingService.log(
              'updatePowersportsDriver Response',
              response
            )
          ),
          switchMap(response => {
            return this.driverHelper.getUpdatePowerSportsDriverNextActions(
              request,
              response,
              payload.person.dateOfBirth
            );
          }),
          catchError(error =>
            of(
              new fromActions.UpdatePowersportsDriverFail(
                this._errorHelper.sanitizeError(error),
                request
              )
            )
          )
        );
    })
  );

  @Effect()
  deleteDriver$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.DELETE_DRIVER),
    map((actions: fromActions.DeleteDriver) => actions.payload),
    switchMap(payload =>
      this._store.select(fromSelectors.getDriverEntity(payload)).pipe(take(1))
    ),
    switchMap(entity =>
      this._store.select(fromSelectors.buildDriverRequest(entity)).pipe(take(1))
    ),
    tap(request => {
      this._loadingService.loadingBegin();
      this._loggingService.log('deleteDriver Request', request);
    }),
    switchMap(request =>
      this._driverService.deleteDriver(request).pipe(
        tap(response =>
          this._loggingService.log('deleteDriver Response', response)
        ),
        switchMap(() => {
          this._loadingService.loadingEnd();
          return from([
            new fromActions.InvalidateQuote(request.productId),
            new fromActions.ClearDvaStore(),
            new fromActions.DeleteDriverSuccess(request.driver.driverId),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loadingService.loadingEnd();
          this._loggingService.log('deleteDriver Error', safeError);
          return of(new fromActions.DeleteDriverFail(safeError));
        })
      )
    )
  );

  @Effect()
  deletePowersportsDriver$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.DELETE_POWERSPORTS_DRIVER),
    map((actions: fromActions.DeletePowersportsDriver) => actions.payload),
    switchMap(payload =>
      this._store
        .select(fromSelectors.getPowersportsDriverEntity(payload))
        .pipe(take(1))
    ),
    switchMap(entity =>
      this._store
        .select(fromSelectors.buildPowersportsDriverRequest(entity))
        .pipe(take(1))
    ),
    tap(request => {
      this._loadingService.loadingBegin();
      this._loggingService.log('deletePowersportsDriver Request', request);
    }),
    switchMap(request =>
      this._driverService.deletePowersportsDriver(request).pipe(
        tap(() =>
          this._loggingService.log('deletePowersportsDriver Response', request)
        ),
        switchMap(() => {
          this._loadingService.loadingEnd();
          return from([
            new fromActions.InvalidateQuote(request.productId),
            new fromActions.ClearDvaStore(),
            new fromActions.DeletePowersportsDriverSuccess(
              request.driver.driverId
            ),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loadingService.loadingEnd();
          this._loggingService.log('deletePowersportsDriver Error', safeError);
          return of(new fromActions.DeletePowersportsDriverFail(safeError));
        })
      )
    )
  );

  @Effect()
  deleteDriverIncident$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.DELETE_DRIVERINCIDENT),
    map((actions: fromActions.DeleteDriverIncident) => actions.payload),
    switchMap(payload =>
      this._store.select(fromSelectors.getDriverEntity(payload)).pipe(take(1))
    ),
    switchMap(entity =>
      this._store
        .select(fromSelectors.buildDriverIncidentRequest(entity))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('deleteDriverIncident Request', request)
    ),
    mergeMap(request =>
      this._driverService.deleteDriverIncident(request).pipe(
        tap(() =>
          this._loggingService.log('deleteDriverIncident Response', request)
        ),
        switchMap(() => {
          return from([
            new fromActions.DeleteDriverSuccessIncident(request.driverId),
            new fromActions.InvalidateQuote(request.productId),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('deleteDriverIncident Error', safeError);
          return of(new fromActions.DeleteDriverFailIncident(safeError));
        })
      )
    )
  );

  @Effect()
  addOtherExistingPeopleAsDrivers$ = this._actions$.pipe(
    ofType(fromActions.DriverActionTypes.ADD_OTHER_EXISTING_PEOPLE_AS_DRIVERS),
    map((action: AddOtherExistingPeopleAsDrivers) => action.productId),
    switchMap(productId =>
      this._store.select(fromSelectors.selectPolicyholdersWithNoDriver).pipe(
        take(1),
        map(policyholders => [policyholders, productId])
      )
    ),
    filter(([policyholders, productId]) => !!policyholders.length),
    withLatestFrom(this._store.select(fromSelectors.getQuoteState)),
    switchMap(
      ([[policyholders, productId], quoteState]: [
        [PolicyholderEntity[], string],
        string
      ]) => {
        return from(
          policyholders.map(
            policyholder =>
              new fromActions.AddDriver(
                this.driverHelper.addDriverRequestFromPolicyholder(
                  policyholder,
                  productId,
                  quoteState
                ),
                false
              )
          )
        );
      }
    )
  );
}
