import { Action } from '@ngrx/store';

export const APP_LOADING_BEGIN = '[Quoting] App Loading True';

export class AppLoadingBegin implements Action {
  readonly type = APP_LOADING_BEGIN;
}

export const APP_LOADING_END = '[Quoting] App Loading End';

export class AppLoadingEnd implements Action {
  readonly type = APP_LOADING_END;
}

export const APP_LOADING_MESSAGES = '[Quoting] App Loading Messages';

export class AppLoadingMessages implements Action {
  readonly type = APP_LOADING_MESSAGES;
  constructor(public upperMessage: string, public lowerMessage: string) {}
}

export type LoadingActions =
  | AppLoadingBegin
  | AppLoadingEnd
  | AppLoadingMessages;
