import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { from } from 'rxjs';
import { filter, take, switchMap, tap, mergeMap } from 'rxjs/operators';

import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { LoggingService, FullStoryService } from '@core/services';
import { LOAD_QUOTE_LETTER } from '@core/store/actions';

@Injectable()
export class BusinessLoggingEffect {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _fullStoryService: FullStoryService
  ) {}

  @Effect()
  checkForQuoteInitiation$ = this._actions$.pipe(
    ofType(
      fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_SUCCESS,
      fromActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_FAIL
    ),
    switchMap(() =>
      this._store.select(fromSelectors.areAllProductsInitiated).pipe(
        take(1),
        filter(initiated => initiated),
        mergeMap(() => {
          return this._store.select(fromSelectors.getLoggingData).pipe(
            take(1),
            tap(log => this._loggingService.log('NEW_BUSINESS_INITIATED', log)),
            mergeMap(() => from([]))
          );
        })
      )
    )
  );

  @Effect()
  checkForQuoteCompletion$ = this._actions$.pipe(
    ofType(
      LOAD_QUOTE_LETTER,
      fromActions.QuoteActionTypes.RATE_QUOTE_FAIL,
      fromActions.QuoteActionTypes.RATE_BIND_FAIL
    ),
    switchMap(() =>
      this._store.select(fromSelectors.areAllProductsQuoted).pipe(
        take(1),
        filter(quoted => quoted),
        mergeMap(() => {
          return this._store.select(fromSelectors.getLoggingData).pipe(
            take(1),
            tap(log => this._loggingService.log('QUOTE_COMPLETED', log)),
            mergeMap(() => from([]))
          );
        })
      )
    )
  );

  @Effect()
  checkForPolicyIssue$ = this._actions$.pipe(
    ofType(
      fromActions.PolicyIssueTypes.ISSUE_POLICY_SUCCESS,
      fromActions.PolicyIssueTypes.ISSUE_POLICY_FAIL
    ),
    switchMap(() =>
      this._store.select(fromSelectors.areAllProductsIssuedOrErrored).pipe(
        take(1),
        filter(issued => issued),
        mergeMap(() => {
          return this._store.select(fromSelectors.getLoggingDataForIssue).pipe(
            take(1),
            tap(log => {
              const erroredProducts = log.products.filter(
                product => !!product.errorMessage
              );
              if (erroredProducts && erroredProducts.length) {
                this._loggingService.log('POLICY_ISSUE_ERROR', {
                  ...log,
                  products: erroredProducts,
                });
              }

              const issuedProducts = log.products.filter(
                product => !product.errorMessage
              );
              if (issuedProducts && issuedProducts.length) {
                this._loggingService.log('POLICY_ISSUED', {
                  ...log,
                  products: issuedProducts,
                });
              }
            }),
            mergeMap(() => from([]))
          );
        })
      )
    )
  );

  @Effect({ dispatch: false })
  checkForPrimaryNamedInsuredModification$ = this._store
    .select(fromSelectors.getPrimaryInsuredName)
    .pipe(
      filter(name => name !== null),
      tap(() => this._fullStoryService.setPrimaryNamedInsured())
    );

  @Effect({ dispatch: false })
  checkForSessionIdModification$ = this._store
    .select(fromSelectors.getSessionId)
    .pipe(
      filter(id => id !== null),
      tap(id => this._fullStoryService.setSessionId(id))
    );

  @Effect({ dispatch: false })
  checkForLoadedQuotes$ = this._store
    .select(fromSelectors.areAllProductsInitiated)
    .pipe(
      filter(loaded => loaded === true),
      tap(() => this._fullStoryService.setProductSubmissionInfo())
    );
}
