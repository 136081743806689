import { Action } from '@ngrx/store';
import { ProtectiveDevice } from '@core/store/reducers/protective-devices.reducer';

export const enum ProtectiveDevicesActionTypes {
  UPDATE_PROTECTIVE_DEVICES = '[Protective Devices] Update Protective Devices',
  UPDATE_PROTECTIVE_DEVICES_SUCCESS = '[Protective Devices] Update Protective Devices Success',
  UPDATE_PROTECTIVE_DEVICES_FAIL = '[Protective Devices] Update Protective Devices Fail',

  GET_PROTECTIVE_DEVICES = '[Protective Devices] Get Protective Devices',
  GET_PROTECTIVE_DEVICES_SUCCESS = '[Protective Devices] Get Protective Devices Success',
  GET_PROTECTIVE_DEVICES_FAIL = '[Protective Devices] Get Protective Devices Fail',
}

export class UpdateProtectiveDevices implements Action {
  readonly type = ProtectiveDevicesActionTypes.UPDATE_PROTECTIVE_DEVICES;
  constructor(public payload: ProtectiveDevice[]) {}
}

export class UpdateProtectiveDevicesSuccess implements Action {
  readonly type =
    ProtectiveDevicesActionTypes.UPDATE_PROTECTIVE_DEVICES_SUCCESS;
  constructor(public payload: ProtectiveDevice[]) {}
}

export class UpdateProtectiveDevicesFail implements Action {
  readonly type = ProtectiveDevicesActionTypes.UPDATE_PROTECTIVE_DEVICES_FAIL;
  constructor(public payload: any) {}
}

export class GetProtectiveDevices implements Action {
  readonly type = ProtectiveDevicesActionTypes.GET_PROTECTIVE_DEVICES;
}

export class GetProtectiveDevicesSuccess implements Action {
  readonly type = ProtectiveDevicesActionTypes.GET_PROTECTIVE_DEVICES_SUCCESS;
  constructor(public payload: ProtectiveDevice[]) {}
}

export class GetProtectiveDevicesFail implements Action {
  readonly type = ProtectiveDevicesActionTypes.GET_PROTECTIVE_DEVICES_FAIL;
  constructor(public payload: any) {}
}

export type ProtectiveDevicesAction =
  | UpdateProtectiveDevices
  | UpdateProtectiveDevicesSuccess
  | UpdateProtectiveDevicesFail
  | GetProtectiveDevices
  | GetProtectiveDevicesSuccess
  | GetProtectiveDevicesFail;
