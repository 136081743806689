import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromMailingAddress from '@core/store/reducers/mailing-address.reducer';
import { AddressEntity } from '@core/models/entities/address.entity';

export const getMailingAddressState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.mailingAddress
);

export const {
  selectAll: selectAllMailingAddresses,
  selectEntities: selectMailingAddressEntities,
} = fromMailingAddress.adapter.getSelectors(getMailingAddressState);

export const getMailingAddress = (id?: string) =>
  createSelector(
    selectMailingAddressEntities,
    (entities): AddressEntity => entities[id]
  );
export const getAllMailingAddresses = () =>
  createSelector(
    selectAllMailingAddresses,
    (addresses): AddressEntity[] => addresses
  );
