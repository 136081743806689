import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import * as fromGuards from './guards';
import * as fromCoreGuards from '@core/guards';

import * as fromContainers from './containers';
import { QueryParameterPreservingRedirectComponent } from '@shared/components/query-parameter-perserving-redirect/query-parameter-preserving-redirect.component';

export const ROUTES: Routes = [
  {
    path: '',
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'agency-route' },
      {
        path: 'agency-route',
        component: fromContainers.AgencyComponent,
        canActivate: [
          fromCoreGuards.ActiveStatesGuard,
          fromCoreGuards.StateAbbreviationsGuard,
          fromGuards.AgencyGuard,
          fromCoreGuards.AgencyChannelGuard,
          fromCoreGuards.PageAlertGuard,
          fromGuards.AgentAuthenticationGuard,
          fromCoreGuards.ProductsGuard,
        ],
      },
      {
        path: 'search',
        component: QueryParameterPreservingRedirectComponent,
        data: { destination: '/search' },
      },
      {
        path: 'retrieve',
        component: QueryParameterPreservingRedirectComponent,
        canActivate: [
          fromGuards.AgentAuthenticationGuard,
          fromGuards.AgencyGuard,
        ],
        data: {
          destination: '/retrieve',
          swap: { input: 'pniLastName', output: 'lastName' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class AgencyRoutingModule {}
