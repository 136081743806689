import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromModifiers from '@core/store/actions/modifiers.action';

import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';

export interface ModifierState extends EntityState<EligibleDiscountEntity> {
  smartrideLoaded: boolean;
  loading: number;
}

export const adapter: EntityAdapter<EligibleDiscountEntity> =
  createEntityAdapter<EligibleDiscountEntity>({
    selectId: discount => `${discount.eligibleDiscountId}_${discount.modelId}`,
  });

export function reducer(
  state = adapter.getInitialState({
    smartrideLoaded: false,
    loading: 0,
  }),
  action: fromModifiers.ModifierActions
): ModifierState {
  switch (action.type) {
    case fromModifiers.ModifierActionTypes.GET_POLICY_LINE_SUCCESS:
    case fromModifiers.ModifierActionTypes.ADD_ALL_MODIFIERS: {
      return adapter.addMany(action.payload, state);
    }

    case fromModifiers.ModifierActionTypes.UPDATE_MEMBERSHIP_DISCOUNT:
    case fromModifiers.ModifierActionTypes.UPDATE_ASSOCIATE_DISCOUNT:
    case fromModifiers.ModifierActionTypes.UPDATE_POLICY_LINE: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromModifiers.ModifierActionTypes.UPDATE_MEMBERSHIP_DISCOUNT_SUCCESS:
    case fromModifiers.ModifierActionTypes.UPDATE_ASSOCIATE_DISCOUNT_SUCCESS:
    case fromModifiers.ModifierActionTypes.UPDATE_POLICY_LINE_SUCCESS: {
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: state.loading - 1,
      });
    }

    case fromModifiers.ModifierActionTypes.UPDATE_MEMBERSHIP_DISCOUNT_FAIL:
    case fromModifiers.ModifierActionTypes.UPDATE_ASSOCIATE_DISCOUNT_FAIL:
    case fromModifiers.ModifierActionTypes.UPDATE_POLICY_LINE_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromModifiers.ModifierActionTypes.UPDATE_DRIVER_DISCOUNT: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromModifiers.ModifierActionTypes.UPDATE_DRIVER_DISCOUNT_SUCCESS: {
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: state.loading - 1,
      });
    }

    case fromModifiers.ModifierActionTypes.UPDATE_DRIVER_DISCOUNT_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromModifiers.ModifierActionTypes.UPDATE_POWERSPORTS_DRIVER_DISCOUNT: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromModifiers.ModifierActionTypes
      .UPDATE_POWERSPORTS_DRIVER_DISCOUNT_SUCCESS: {
      return adapter.upsertOne(action.payload, {
        ...state,
        loading: state.loading - 1,
      });
    }

    case fromModifiers.ModifierActionTypes
      .UPDATE_POWERSPORTS_DRIVER_DISCOUNT_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromModifiers.ModifierActionTypes.UPDATE_VEHICLE_DISCOUNT: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case fromModifiers.ModifierActionTypes.UPDATE_VEHICLE_DISCOUNT_SUCCESS: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromModifiers.ModifierActionTypes.UPDATE_VEHICLE_DISCOUNT_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromModifiers.ModifierActionTypes.SET_SMARTRIDE_LOADED: {
      return { ...state, smartrideLoaded: true };
    }

    case fromModifiers.ModifierActionTypes.UNSET_SMARTRIDE_LOADED: {
      return { ...state, smartrideLoaded: false };
    }

    case fromModifiers.ModifierActionTypes.UPDATE_SMARTHOME_DISCOUNT: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case fromModifiers.ModifierActionTypes.UPDATE_SMARTHOME_DISCOUNT_SUCCESS: {
      return adapter.updateOne(action.payload, {
        ...state,
        loading: state.loading - 1,
      });
    }

    case fromModifiers.ModifierActionTypes.UPDATE_SMARTHOME_DISCOUNT_FAIL: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case fromModifiers.ModifierActionTypes.MODIFIER_ACTION_CANCELLED: {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }
  }
  return state;
}
