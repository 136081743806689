import { Action } from '@ngrx/store';
import { PartialQuote } from '@core/models/partial-quote/partial-quote.model';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import {
  DriverEntity,
  PersonViewModel,
} from '@core/models/entities/driver.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { CoveredLocation } from '../reducers/covered-location.reducer';

export const enum PartialQuoteActionTypes {
  LOAD_PARTIAL_QUOTE_DATA = '[Partial Quote] Load Partial Quote',
  LOAD_PARTIAL_QUOTE_DATA_FAIL = '[Partial Quote] Load Partial Quote Fail',
  LOAD_PARTIAL_QUOTE_DATA_SUCCESS = '[Partial Quote] Load Partial Quote Success',

  ADD_PARTIAL_QUOTE_POLICYHOLDERS = '[Partial Quote] Add Partial Quote Policy Holders',
  ADD_PARTIAL_QUOTE_DRIVERS = '[Partial Quote] Add Partial Quote Drivers',
  ADD_PARTIAL_QUOTE_VEHICLES = '[Partial Quote] Add Partial Quote Vehicles',

  UPDATE_PARTIAL_QUOTE_VEHICLE = '[Partial Quote] Update Partial Quote Vehicle',
  UPDATE_PARTIAL_QUOTE_VEHICLE_SUCCESS = '[Partial Quote] Update Partial Quote Vehicle Success',
  REMOVE_PARTIAL_QUOTE_VEHICLE = '[Partial Quote] Remove Partial Quote Vehicle',

  UPDATE_PARTIAL_QUOTE_VEHICLE_SELECTED = '[Partial Quote] Update Partial Quote Vehicle Selected',
  UPDATE_PARTIAL_QUOTE_DRIVER_SELECTED = '[Partial Quote] Update Partial Quote Driver Selected',
  UPDATE_PARTIAL_QUOTE_POLICY_HOLDER_SELECTED = '[Partial Quote] Update Partial Quote PolicyHolder Selected',

  ADD_PARTIAL_QUOTE_COVERED_LOCATION = '[Partial Quote] Add Partial Quote Covered Location',
}

export class LoadPartialQuoteData implements Action {
  readonly type = PartialQuoteActionTypes.LOAD_PARTIAL_QUOTE_DATA;
}

export class LoadPartialQuoteDataFail implements Action {
  readonly type = PartialQuoteActionTypes.LOAD_PARTIAL_QUOTE_DATA_FAIL;
  constructor(public error: any) {}
}

export class LoadPartialQuoteDataSuccess implements Action {
  readonly type = PartialQuoteActionTypes.LOAD_PARTIAL_QUOTE_DATA_SUCCESS;
  constructor(public payload: PartialQuote) {}
}

export class AddPartialQuotePolicyHolders implements Action {
  readonly type = PartialQuoteActionTypes.ADD_PARTIAL_QUOTE_POLICYHOLDERS;
  constructor(public payload: PolicyholderEntity[]) {}
}

export class AddPartialQuoteDrivers implements Action {
  readonly type = PartialQuoteActionTypes.ADD_PARTIAL_QUOTE_DRIVERS;
  constructor(public payload: DriverEntity[]) {}
}

export class AddPartialQuoteVehicles implements Action {
  readonly type = PartialQuoteActionTypes.ADD_PARTIAL_QUOTE_VEHICLES;
  constructor(public payload: VehicleEntity[]) {}
}

export class UpdatePartialQuoteVehicle implements Action {
  readonly type = PartialQuoteActionTypes.UPDATE_PARTIAL_QUOTE_VEHICLE;
  constructor(public payload: VehicleEntity) {}
}

export class UpdatePartialQuoteVehicleSuccess implements Action {
  readonly type = PartialQuoteActionTypes.UPDATE_PARTIAL_QUOTE_VEHICLE_SUCCESS;
  constructor(public payload: VehicleEntity) {}
}

export class RemovePartialQuoteVehicle implements Action {
  readonly type = PartialQuoteActionTypes.REMOVE_PARTIAL_QUOTE_VEHICLE;
  constructor(public payload: VehicleEntity) {}
}

export class UpdatePartialQuoteVehicleSelected implements Action {
  readonly type = PartialQuoteActionTypes.UPDATE_PARTIAL_QUOTE_VEHICLE_SELECTED;
  constructor(public payload: VehicleEntity) {}
}

export class UpdatePartialQuoteDriverSelected implements Action {
  readonly type = PartialQuoteActionTypes.UPDATE_PARTIAL_QUOTE_DRIVER_SELECTED;
  constructor(public payload: PersonViewModel) {}
}

export class AddPartialQuoteCoveredLocation implements Action {
  readonly type = PartialQuoteActionTypes.ADD_PARTIAL_QUOTE_COVERED_LOCATION;
  constructor(public payload: CoveredLocation) {}
}

export class UpdatePartialQuotePolicyHolderSelected implements Action {
  readonly type =
    PartialQuoteActionTypes.UPDATE_PARTIAL_QUOTE_POLICY_HOLDER_SELECTED;
  constructor(public payload: PersonViewModel) {}
}

export type PartialQuoteActions =
  | LoadPartialQuoteData
  | LoadPartialQuoteDataFail
  | LoadPartialQuoteDataSuccess
  | AddPartialQuotePolicyHolders
  | AddPartialQuoteDrivers
  | AddPartialQuoteVehicles
  | UpdatePartialQuoteVehicleSelected
  | UpdatePartialQuoteDriverSelected
  | UpdatePartialQuoteVehicle
  | UpdatePartialQuoteVehicleSuccess
  | RemovePartialQuoteVehicle
  | AddPartialQuoteCoveredLocation
  | UpdatePartialQuotePolicyHolderSelected;
