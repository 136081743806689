import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavigationButtonsComponent } from './navigation-buttons.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NavigationButtonsComponent],
  exports: [NavigationButtonsComponent],
})
export class NavigationButtonsModule {}
