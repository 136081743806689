import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromCma from '@core/store/reducers/cma.reducer';

export const getCmaState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.cma
);

export const getCmaLoaded = createSelector(getCmaState, fromCma.getCmaLoaded);

export const getCmaLoading = createSelector(getCmaState, fromCma.getCmaLoading);

export const getCma = createSelector(getCmaState, fromCma.getCma);
