import { PrequalificationAnswer } from '@core/models/auto/quotes/update-quote-response.model';
import { ProductTypes } from '@shared/constants/app-constants';

export class PrequalificationAnswersUtil {
  static getPrequalificationAnswers(
    productId: string,
    state: string
  ): PrequalificationAnswer[] {
    switch (productId) {
      case ProductTypes.HOMEOWNERS:
        return PrequalificationAnswersUtil.buildHomeownersPrequalificationAnswers(
          state
        );
      case ProductTypes.RENTERS:
        return PrequalificationAnswersUtil.buildRentersPrequalificationAnswers(
          state
        );
      case ProductTypes.UMBRELLA:
        return PrequalificationAnswersUtil.buildUmbrellaPrequalificationAnswers(
          state
        );
      case ProductTypes.CONDO:
        return PrequalificationAnswersUtil.buildCondoPrequalificationAnswers(
          state
        );
    }
    return [];
  }

  static buildHomeownersPrequalificationAnswers(
    state: string
  ): PrequalificationAnswer[] {
    let prequalAnswers = [
      {
        questionCode: 'HOOwnerOccupied_Ext',
        answerValue: true,
      },
      {
        questionCode: 'HOInsuranceFraud_Ext',
        answerValue: false,
      },
      {
        questionCode: 'HOFelonyConviction_Ext',
        answerValue: false,
      },
      {
        questionCode: 'HOFireLoss_Ext',
        answerValue: false,
      },
      {
        questionCode: 'HOFireAccess_Ext',
        answerValue: true,
      },
      {
        questionCode: 'HOVacaRental_Ext',
        answerValue: false,
      },
    ];

    switch (state) {
      case 'ME': {
        prequalAnswers.push({
          questionCode: 'HO3_Foreclosure2_Ext',
          answerValue: false,
        });
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        break;
      }
      case 'MD': {
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOInsuranceFraud_Ext'
        );
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFelonyConviction_Ext'
        );
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFireLoss_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HOArson_ExtCopy',
          answerValue: false,
        });
        prequalAnswers.push({
          questionCode: 'HOFireLoss3yrs_ExtCopy',
          answerValue: false,
        });
        prequalAnswers.push({
          questionCode: 'HO3_Foreclosure_Ext',
          answerValue: false,
        });
        break;
      }
      case 'MI': {
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFelonyConviction_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HO3_Foreclosure_Ext',
          answerValue: false,
        });
        break;
      }
      case 'WI': {
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFelonyConviction_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HO3_Foreclosure_Ext',
          answerValue: false,
        });
        break;
      }
      case 'CA': {
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFireLoss_Ext'
        );
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HOFireLossExCat_Ext',
          answerValue: false,
        });
        prequalAnswers.push({
          questionCode: 'HO3_Foreclosure_Ext',
          answerValue: false,
        });
        break;
      }
      case 'RI': {
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HOArsonConviction_Ext',
          answerValue: false,
        });
        prequalAnswers.push({
          questionCode: 'HO3_Foreclosure_Ext',
          answerValue: false,
        });
        break;
      }
      default: {
        prequalAnswers.push({
          questionCode: 'HO3_Foreclosure_Ext',
          answerValue: false,
        });
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        break;
      }
    }
    return prequalAnswers;
  }

  static buildCondoPrequalificationAnswers(
    state: string
  ): PrequalificationAnswer[] {
    let prequalAnswers = [
      {
        questionCode: 'HOCondoOwnerOneProd_Ext',
        answerValue: true,
      },
      {
        questionCode: 'HO6_Foreclosure1_Ext',
        answerValue: false,
      },
      {
        questionCode: 'HOInsuranceFraud_Ext',
        answerValue: false,
      },
      {
        questionCode: 'HOFelonyConviction_Ext',
        answerValue: false,
      },
      {
        questionCode: 'HOFireLoss_Ext',
        answerValue: false,
      },
      {
        questionCode: 'HOFireAccess_Ext',
        answerValue: true,
      },
      {
        questionCode: 'HOVacaRental_Ext',
        answerValue: false,
      },
    ];

    switch (state) {
      case 'ME': {
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HO6_Foreclosure1_Ext'
        );
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        break;
      }
      case 'MD': {
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOInsuranceFraud_Ext'
        );
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFelonyConviction_Ext'
        );
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFireLoss_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HOArson_ExtCopy',
          answerValue: false,
        });
        prequalAnswers.push({
          questionCode: 'HOFireLoss3yrs_ExtCopy',
          answerValue: false,
        });
        break;
      }
      case 'CA': {
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFireLoss_Ext'
        );
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HOFireLossExCat_Ext',
          answerValue: false,
        });
        break;
      }
      case 'RI': {
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HOArsonConviction_Ext',
          answerValue: false,
        });
        break;
      }
      default: {
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        break;
      }
    }

    return prequalAnswers;
  }

  static buildRentersPrequalificationAnswers(
    state: string
  ): PrequalificationAnswer[] {
    let prequalAnswers = [
      {
        questionCode: 'HOCurrentlyLiving_Ext',
        answerValue: true,
      },
      {
        questionCode: 'HOInsuranceFraud_Ext',
        answerValue: false,
      },
      {
        questionCode: 'HOFelonyConviction_Ext',
        answerValue: false,
      },
      {
        questionCode: 'HOFireLoss_Ext',
        answerValue: false,
      },
      {
        questionCode: 'HOFireAccess_Ext',
        answerValue: true,
      },
      {
        questionCode: 'HOVacaRental_Ext',
        answerValue: false,
      },
    ];

    switch (state) {
      case 'MD': {
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOInsuranceFraud_Ext'
        );
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFelonyConviction_Ext'
        );
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFireLoss_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HOArson_ExtCopy',
          answerValue: false,
        });
        prequalAnswers.push({
          questionCode: 'HOFireLoss3yrs_ExtCopy',
          answerValue: false,
        });
        break;
      }
      case 'CA': {
        prequalAnswers = prequalAnswers.filter(
          ans => ans.questionCode !== 'HOFireLoss_Ext'
        );
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HOFireLossExCat_Ext',
          answerValue: false,
        });
        break;
      }
      case 'RI': {
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        prequalAnswers.push({
          questionCode: 'HOArsonConviction_Ext',
          answerValue: false,
        });
        break;
      }
      default: {
        prequalAnswers = this.manipulatePrequalificationAnswerByCode(
          prequalAnswers,
          'HOFelonyConviction_Ext'
        );
        break;
      }
    }

    return prequalAnswers;
  }

  static buildUmbrellaPrequalificationAnswers(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: string
  ): PrequalificationAnswer[] {
    return [
      {
        questionCode: 'PUPBusinessActivities_PUE',
        answerValue: false,
      },
    ];
  }
  static manipulatePrequalificationAnswerByCode(
    preAns: PrequalificationAnswer[],
    code: string
  ): PrequalificationAnswer[] {
    return preAns.filter(ans => ans.questionCode !== code);
  }
}
