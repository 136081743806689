import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { DriverEntity } from '@core/models/entities/driver.entity';
import {
  DriverRequest,
  PowersportsDriverRequest,
} from '@core/models/auto/quotes/driver-request.model';

export const enum DriverActionTypes {
  ADD_DRIVER = '[Driver] Add Driver',
  ADD_DRIVER_FAIL = '[Driver] Add Driver Fail',
  ADD_DRIVER_SUCCESS = '[Driver] Add Driver Success',
  ADD_DRIVER_DIRECTLY = '[Driver] Add Driver Directly',

  ADD_POWERSPORTS_DRIVER = '[Driver] Add Powersports Driver',
  ADD_POWERSPORTS_DRIVER_FAIL = '[Driver] Add Powersports Driver Fail',
  ADD_POWERSPORTS_DRIVER_SUCCESS = '[Driver] Add Powersports Driver Success',
  ADD_POWERSPORTS_DRIVER_DIRECTLY = '[Driver] Add Powersports Driver Directly',

  UPDATE_DRIVER = '[Driver] Update Driver',
  UPDATE_DRIVER_FAIL = '[Driver] Update Driver Fail',
  UPDATE_DRIVER_SUCCESS = '[Driver] Update Driver Success',
  UPDATE_DRIVER_DIRECTLY = '[Driver] Update Driver Directly',

  UPDATE_POWERSPORTS_DRIVER = '[Driver] Update Powersports Driver',
  UPDATE_POWERSPORTS_DRIVER_FAIL = '[Driver] Update Powersports Driver Fail',
  UPDATE_POWERSPORTS_DRIVER_SUCCESS = '[Driver] Update Powersports Driver Success',
  UPDATE_POWERSPORTS_DRIVER_DIRECTLY = '[Driver] Update Powersports Driver Directly',

  UPDATE_ALL_DRIVERS = '[Driver] Update All Drivers',
  UPDATE_ALL_POWERSPORTS_DRIVERS = '[Driver] Update All Powersports Drivers',
  ADD_OTHER_EXISTING_PEOPLE_AS_DRIVERS = '[Driver] Add Other Existing People As Drivers',

  DELETE_DRIVER = '[Driver] Delete Driver',
  DELETE_DRIVER_FAIL = '[Driver] Delete Driver Fail',
  DELETE_DRIVER_SUCCESS = '[Driver] Delete Driver Success',

  DELETE_POWERSPORTS_DRIVER = '[Driver] Delete Powersports Driver',
  DELETE_POWERSPORTS_DRIVER_FAIL = '[Driver] Delete Powersports Driver Fail',
  DELETE_POWERSPORTS_DRIVER_SUCCESS = '[Driver] Delete Powersports Driver Success',

  DELETE_DRIVERINCIDENT = '[Driver] Delete DriverIncident',
  DELETE_DRIVERINCIDENT_FAIL = '[Driver] Delete DriverIncident Fail',
  DELETE_DRIVERINCIDENT_SUCCESS = '[Driver] Delete DriverIncident Success',

  UPDATE_GOOD_STUDENT_FOR_DRIVER = '[Driver] Update Good Student For Driver',
}

export class UpdateAllDrivers implements Action {
  readonly type = DriverActionTypes.UPDATE_ALL_DRIVERS;
  constructor(public payload: Update<DriverEntity>[]) {}
}

export class UpdateAllPowersportsDrivers implements Action {
  readonly type = DriverActionTypes.UPDATE_ALL_POWERSPORTS_DRIVERS;
  constructor(public payload: Update<DriverEntity>[]) {}
}

export class AddDriver implements Action {
  readonly type = DriverActionTypes.ADD_DRIVER;
  constructor(public payload: DriverEntity, public isPrimaryDriver?: boolean) {}
}

export class AddDriverFail implements Action {
  readonly type = DriverActionTypes.ADD_DRIVER_FAIL;
  constructor(public payload: any) {}
}

export class AddDriverSuccess implements Action {
  readonly type = DriverActionTypes.ADD_DRIVER_SUCCESS;
  constructor(public payload: DriverEntity) {}
}

export class AddPowersportsDriver implements Action {
  readonly type = DriverActionTypes.ADD_POWERSPORTS_DRIVER;
  constructor(public payload: DriverEntity, public isPrimaryDriver?: boolean) {}
}

export class AddPowersportsDriverFail implements Action {
  readonly type = DriverActionTypes.ADD_POWERSPORTS_DRIVER_FAIL;
  constructor(public payload: any) {}
}

export class AddPowersportsDriverSuccess implements Action {
  readonly type = DriverActionTypes.ADD_POWERSPORTS_DRIVER_SUCCESS;
  constructor(public payload: DriverEntity) {}
}
export class AddDriverDirectly implements Action {
  readonly type = DriverActionTypes.ADD_DRIVER_DIRECTLY;
  constructor(public payload: DriverEntity) {}
}

export class AddPowersportsDriverDirectly implements Action {
  readonly type = DriverActionTypes.ADD_POWERSPORTS_DRIVER_DIRECTLY;
  constructor(public payload: DriverEntity) {}
}

export class UpdateDriver implements Action {
  readonly type = DriverActionTypes.UPDATE_DRIVER;
  constructor(public payload: DriverEntity) {}
}

export class UpdateDriverFail implements Action {
  readonly type = DriverActionTypes.UPDATE_DRIVER_FAIL;
  constructor(public payload: any, public request: DriverRequest) {}
}

export class UpdateDriverSuccess implements Action {
  readonly type = DriverActionTypes.UPDATE_DRIVER_SUCCESS;
  constructor(public payload: Update<DriverEntity>) {}
}

export class UpdatePowersportsDriver implements Action {
  readonly type = DriverActionTypes.UPDATE_POWERSPORTS_DRIVER;
  constructor(public payload: DriverEntity) {}
}

export class UpdatePowersportsDriverFail implements Action {
  readonly type = DriverActionTypes.UPDATE_POWERSPORTS_DRIVER_FAIL;
  constructor(public payload: any, public request: PowersportsDriverRequest) {}
}

export class UpdatePowersportsDriverSuccess implements Action {
  readonly type = DriverActionTypes.UPDATE_POWERSPORTS_DRIVER_SUCCESS;
  constructor(public payload: Update<DriverEntity>) {}
}

export class UpdateDriverDirectly implements Action {
  readonly type = DriverActionTypes.UPDATE_DRIVER_DIRECTLY;
  constructor(public payload: Update<DriverEntity>) {}
}

export class UpdatePowersportsDriverDirectly implements Action {
  readonly type = DriverActionTypes.UPDATE_POWERSPORTS_DRIVER_DIRECTLY;
  constructor(public payload: Update<DriverEntity>) {}
}
export class AddOtherExistingPeopleAsDrivers implements Action {
  readonly type = DriverActionTypes.ADD_OTHER_EXISTING_PEOPLE_AS_DRIVERS;
  constructor(public productId: string) {}
}

export class DeleteDriver implements Action {
  readonly type = DriverActionTypes.DELETE_DRIVER;
  constructor(public payload: string) {}
}

export class DeleteDriverFail implements Action {
  readonly type = DriverActionTypes.DELETE_DRIVER_FAIL;
  constructor(public payload: any) {}
}

export class DeleteDriverSuccess implements Action {
  readonly type = DriverActionTypes.DELETE_DRIVER_SUCCESS;
  constructor(public payload: string) {}
}

export class DeletePowersportsDriver implements Action {
  readonly type = DriverActionTypes.DELETE_POWERSPORTS_DRIVER;
  constructor(public payload: string) {}
}

export class DeletePowersportsDriverFail implements Action {
  readonly type = DriverActionTypes.DELETE_POWERSPORTS_DRIVER_FAIL;
  constructor(public payload: any) {}
}

export class DeletePowersportsDriverSuccess implements Action {
  readonly type = DriverActionTypes.DELETE_POWERSPORTS_DRIVER_SUCCESS;
  constructor(public payload: string) {}
}

export class UpdateGoodStudentForDriver implements Action {
  readonly type = DriverActionTypes.UPDATE_GOOD_STUDENT_FOR_DRIVER;
  constructor(
    public driverId: string,
    public selected: boolean,
    public payload?: any
  ) {}
}

export class DeleteDriverIncident implements Action {
  readonly type = DriverActionTypes.DELETE_DRIVERINCIDENT;
  constructor(public payload: string) {}
}

export class DeleteDriverFailIncident implements Action {
  readonly type = DriverActionTypes.DELETE_DRIVERINCIDENT_FAIL;
  constructor(public payload: any) {}
}

export class DeleteDriverSuccessIncident implements Action {
  readonly type = DriverActionTypes.DELETE_DRIVERINCIDENT_SUCCESS;
  constructor(public payload: string) {}
}

export type DriverActions =
  | AddDriver
  | AddDriverSuccess
  | AddDriverFail
  | AddPowersportsDriver
  | AddPowersportsDriverSuccess
  | AddPowersportsDriverFail
  | AddDriverDirectly
  | AddPowersportsDriverDirectly
  | UpdateDriver
  | UpdateDriverFail
  | UpdateDriverSuccess
  | UpdatePowersportsDriver
  | UpdatePowersportsDriverFail
  | UpdatePowersportsDriverSuccess
  | UpdateDriverDirectly
  | UpdatePowersportsDriverDirectly
  | UpdateAllDrivers
  | UpdateAllPowersportsDrivers
  | DeleteDriver
  | DeleteDriverFail
  | DeleteDriverSuccess
  | DeletePowersportsDriver
  | DeletePowersportsDriverFail
  | DeletePowersportsDriverSuccess
  | UpdateGoodStudentForDriver
  | DeleteDriverIncident
  | DeleteDriverFailIncident
  | DeleteDriverSuccessIncident;
