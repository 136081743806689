import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileSystemUtils {
  readFile(blob: Blob): Observable<string> {
    if (!(blob instanceof Blob)) {
      return throwError(
        new Error('`blob` must be an instance of File or Blob.')
      );
    }

    return new Observable(obs => {
      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result.toString());
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }
}
