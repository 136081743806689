import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchModel } from '@core/models/search/search.model';
import { SearchQuotesResponse } from '@core/models/auto/quotes/search-quotes-response.model';
import { SearchAdapter } from '@core/adapters/search.adapter';
import { SearchDao } from '@core/dao/search.dao';
import { SearchEntity } from '@core/models/entities/search.entity';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private _searchDao: SearchDao,
    private _searchAdapter: SearchAdapter
  ) {}

  searchQuotes(model: SearchModel): void {
    this._searchDao.searchQuotes(model);
  }

  getAllSearchQuotes(): Observable<SearchEntity[]> {
    return this._searchDao.getAllSearchQuotes();
  }

  searchFromRemote(request: SearchModel): Observable<SearchQuotesResponse> {
    return this._searchAdapter.searchFromRemote(request);
  }

  declareSearchQuoteError(message: string): void {
    this._searchDao.declareSearchQuoteError(message);
  }

  clearError(): void {
    this._searchDao.clearError();
  }

  getError(): Observable<string> {
    return this._searchDao.getError();
  }
}
