<form [formGroup]="form">
  <h1 class="heading-h4 heading-termlife form-group">
    Term Life for {{ termPerson.firstName }} {{ termPerson.lastName }}
  </h1>
  <div *ngIf="!termPerson.termLifeEligible">
    <h1 class="heading-notcovered heading-h4">Not covered</h1>
    <div class="alert header alert-danger col" role="alert">
      <a class="icon error"></a>
      <span>Ineligible based on information you provided</span>
    </div>
  </div>
  <div *ngIf="termPerson.termLifeEligible">
    <div class="form-group">
      <legend>Coverage term</legend>
      <div
        class="btn-group btn-group-toggle d-flex"
        name="coverageTerm"
        formControlName="coverageTerm"
        (change)="onCoverageTermChange($event.target.value)"
      >
        <input
          class="form-control btn-check"
          type="radio"
          value="Nationwide 10-year Term GLT"
          (click)="onClick($event.target.value)"
          name="10-year"
          id="10-year-{{ fieldId }}-{{ displayType }}"
        />
        <label
          for="10-year-{{ fieldId }}-{{ displayType }}"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle:
              form.value.coverageTerm == 'Nationwide 10-year Term GLT'
          }"
        >
          10-year
        </label>
        <input
          class="form-control btn-check"
          type="radio"
          value="Nationwide 15-year Term GLT"
          (click)="onClick($event.target.value)"
          name="15-year"
          id="15-year-{{ fieldId }}-{{ displayType }}"
        />
        <label
          for="15-year-{{ fieldId }}-{{ displayType }}"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle:
              form.value.coverageTerm == 'Nationwide 15-year Term GLT'
          }"
        >
          15-year
        </label>
        <input
          class="form-control btn-check"
          type="radio"
          value="Nationwide 20-year Term GLT"
          name="20-year"
          id="20-year-{{ fieldId }}-{{ displayType }}"
          (click)="onClick($event.target.value)"
        />
        <label
          for="20-year-{{ fieldId }}-{{ displayType }}"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle:
              form.value.coverageTerm == 'Nationwide 20-year Term GLT'
          }"
        >
          20-year
        </label>
        <input
          class="form-control btn-check"
          type="radio"
          value="Nationwide 30-year Term GLT"
          name="30-year"
          id="30-year-{{ fieldId }}-{{ displayType }}"
          (click)="onClick($event.target.value)"
        />
        <label
          for="30-year-{{ fieldId }}-{{ displayType }}"
          class="btn btn-default col-6 col-sm-3"
          [ngClass]="{
            activetoggle:
              form.value.coverageTerm == 'Nationwide 30-year Term GLT'
          }"
        >
          30-year
        </label>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-auto">
        <label for="coverage-amount-{{ fieldId }}-{{ displayType }}"
          >Coverage amount</label
        >
        <div class="input-group">
          <select
            id="coverage-amount-{{ fieldId }}-{{ displayType }}"
            aria-describedby="coverage-amount-error"
            formControlName="coverageAmount"
            class="form-control form-select"
            (change)="onCoverageAmountChange($event.target.value)"
          >
            <option *ngFor="let t of termLifeCvgAmtKeys" value="{{ t }}">
              {{ termLifeCvgAmtCodes[t] }}
            </option>
          </select>
          <div class="input-group-text"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-auto">
        <label for="cost-estimate-{{ fieldId }}">Cost estimate</label>
        <div *ngFor="let l of termLifeDisplays">
          <div *ngIf="showTermLifePremium(l.faceAmount, l.premiumAmount, form)">
            <strong
              >{{ l.premiumAmount | currency : 'USD' : 'symbol' }}/mo</strong
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
