import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromPageAlerts from '@core/store/reducers/page-alert.reducer';
import * as fromAgent from '@core/store/selectors/agency.selector';
import * as fromSession from '@core/store/selectors/session.selector';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import { ChannelNames } from '@shared/constants/app-constants';

export const getPageAlertState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.pageAlerts
);

export const {
  selectAll: selectAllPageAlerts,
  selectEntities: selectPageAlertEntities,
  selectTotal: selectTotalPageAlerts,
} = fromPageAlerts.adapter.getSelectors(getPageAlertState);

export const getFilteredAndSortedAlertsByPageId = (pageId: string) =>
  createSelector(
    selectAllPageAlerts,
    fromAgent.isAgent,
    fromAgent.getAgency,
    fromSession.getQuoteState,
    fromUserContext.isCompRaterInitiated,
    fromUserContext.isMobileInitiated,
    (pageAlerts, isAgent, agent, quoteState, isCompRater, isMobile) => {
      if (!pageAlerts || !pageId) {
        return null;
      }

      let appliedChannel;
      if (isCompRater) {
        appliedChannel = 'Comp Rater';
      } else if (isMobile) {
        appliedChannel = 'Mobile';
      } else if (agent && agent.isPrivateLabel) {
        appliedChannel = ChannelNames.PRIVATE_LABEL;
      } else if (isAgent && agent) {
        appliedChannel = agent.agencyChannel;
      } else {
        appliedChannel = 'Direct';
      }

      const filteredAlerts = pageAlerts.filter(alert => {
        if (quoteState) {
          return alert.appliedStates.length
            ? alert.appliedStates.includes(quoteState) &&
                alert.appliedChannels.includes(appliedChannel) &&
                alert.pageId === pageId
            : alert.appliedChannels.includes(appliedChannel) &&
                alert.pageId === pageId;
        }
        return (
          alert.appliedStates.length === 0 &&
          alert.appliedChannels.includes(appliedChannel) &&
          alert.pageId === pageId
        );
      });
      return filteredAlerts.sort((a, b) => a.priority - b.priority);
    }
  );

export const getAlertByPageId = (pageId: string) =>
  createSelector(
    getFilteredAndSortedAlertsByPageId(pageId),
    alerts => (alerts && alerts[0]) || null
  );

export const getAlertsByPageId = getFilteredAndSortedAlertsByPageId;

export const getActiveAlert = createSelector(
  getPageAlertState,
  (state: fromPageAlerts.PageAlertState) => state.activeAlert
);

export const getActiveAlerts = createSelector(
  getPageAlertState,
  (state: fromPageAlerts.PageAlertState) => state.activeAlerts
);

export const getPageAlertsLoaded = createSelector(
  getPageAlertState,
  fromPageAlerts.getPageAlertsLoaded
);

export const getCurrentPageId = createSelector(
  getPageAlertState,
  state => state.currentPageId
);
