import { Action } from '@ngrx/store';
import {
  InitiateAutoExtraParameters,
  InitiateExtraParameters,
} from '@core/models/auto/quotes/initiate-auto-extra-parameters';
import {
  TermLifeEligible,
  UpdateQuoteRequestBody,
} from '@core/models/auto/quotes/update-quote-request.model';
import { TermLifeResponse } from '@core/models/auto/quotes/quote-rating-response.model';
import { Update } from '@ngrx/entity';
import { MoreDetailsViewModel } from '@core/models/view/more-details.model';

export const enum QuoteActionTypes {
  INITIATE_ALL_NEW_BUSINESS = '[Quote] Initiate All New Business',

  INITIATE_PRODUCT = '[Quote] Initiate Product',

  INITIATE_NEW_BUSINESS_AUTO = '[Quote] Initiate New Business Auto',
  INITIATE_NEW_BUSINESS_HOMEOWNERS = '[Quote] Initiate New Business Homeowners',
  INITIATE_NEW_BUSINESS_CONDO = '[Quote] Initiate New Business Condo',

  INITIATE_NEW_BUSINESS_RENTERS = '[Quote] Initiate New Business Renters',
  INITIATE_NEW_BUSINESS_UMBRELLA = '[Quote] Initiate New Business Umbrella',
  INITIATE_NEW_BUSINESS_POWERSPORTS = '[Quote] Initiate New Business Powersports',

  INITIATE_NEW_BUSINESS_TERMLIFE = '[Quote] Initiate New Business TermLife',
  GENERATE_TERMLIFE_QUOTEID = '[Quote] Generate TermLife Quote Id',

  INITIATE_NEW_BUSINESS_FAIL = '[Quote] Initiate New Business Fail',
  INITIATE_NEW_BUSINESS_SUCCESS = '[Quote] Initiate New Business Success',

  UPDATE_QUOTE = '[Quote] Update Quote',
  UPDATE_QUOTE_FAIL = '[Quote] Update Quote Fail',
  UPDATE_QUOTE_SUCCESS = '[Quote] Update Quote Success',

  PATCH_QUOTE = '[Quote] Patch Quote',

  RATE_QUOTE = '[Quote] Rate Quote',
  RATE_QUOTE_FAIL = '[Quote] Rate Quote Fail',
  RATE_QUOTE_SUCCESS = '[Quote] Rate Quote Success',

  TERM_LIFE_RATE_QUOTE = '[Quote] Rate Term Life Quote',
  TERM_LIFE_RATE_QUOTE_FAIL = '[Quote] Rate Term Life Quote Fail',
  TERM_LIFE_RATE_QUOTE_SUCCESS = '[Quote] Rate Term Life Quote Success',
  UPDATE_TERMLIFE_COVERAGE = '[Termlife] Update Termlife Coverage',
  REMOVE_TERMLIFE_COVERAGE = '[Termlife] Remove Termlife Coverage',
  EMAIL_QUOTE_ESTIMATE = '[TermLife] Email Quote Estimate',
  EMAIL_QUOTE_ESTIMATE_SUCCESS = '[TermLife] Email Quote Estimate Success',
  EMAIL_QUOTE_ESTIMATE_FAIL = '[TermLife] Email Quote Estimate Fail',

  RATE_BIND_FAIL = '[Quote] Rate Bind Fail',
  RATE_BIND_SUCCESS = '[Quote] Rate Bind Success',

  INVALIDATE_QUOTE = '[Quote] Invalidate Quote',
  INVALIDATE_BIND = '[Bind] Invalidate Bind',

  INVALIDATE_QUOTE_COMPLETION = '[Quote] Invalidate Quote Completion',
  VALIDATE_QUOTE_COMPLETION = '[Quote] Validate Quote Completion',
}

export class InitiateAllNewBusiness implements Action {
  readonly type = QuoteActionTypes.INITIATE_ALL_NEW_BUSINESS;
  constructor(public payload?: MoreDetailsViewModel) {}
}

export class InitiateProduct implements Action {
  readonly type = QuoteActionTypes.INITIATE_PRODUCT;
  constructor(
    public moreDetailsViewModel?: MoreDetailsViewModel,
    public productId?: string,
    public createAccount?: boolean
  ) {}
}

export class InitiateNewBusinessAuto implements Action {
  readonly type = QuoteActionTypes.INITIATE_NEW_BUSINESS_AUTO;
  constructor(public payload?: InitiateAutoExtraParameters) {}
}

export class InitiateNewBusinessHomeowners implements Action {
  readonly type = QuoteActionTypes.INITIATE_NEW_BUSINESS_HOMEOWNERS;
  constructor(public payload?: InitiateExtraParameters) {}
}

export class InitiateNewBusinessCondo implements Action {
  readonly type = QuoteActionTypes.INITIATE_NEW_BUSINESS_CONDO;
  constructor(public payload?: InitiateExtraParameters) {}
}

export class InitiateNewBusinessRenters implements Action {
  readonly type = QuoteActionTypes.INITIATE_NEW_BUSINESS_RENTERS;
  constructor(public payload?: InitiateExtraParameters) {}
}

export class InitiateNewBusinessUmbrella implements Action {
  readonly type = QuoteActionTypes.INITIATE_NEW_BUSINESS_UMBRELLA;
  constructor() {}
}

export class InitiateNewBusinessPowersports implements Action {
  readonly type = QuoteActionTypes.INITIATE_NEW_BUSINESS_POWERSPORTS;
  constructor(public payload?: InitiateAutoExtraParameters) {}
}

export class InitiateNewBusinessTermLife implements Action {
  readonly type = QuoteActionTypes.INITIATE_NEW_BUSINESS_TERMLIFE;
  constructor(public payload: string) {}
}

export class GenerateTermLifeQuoteId implements Action {
  readonly type = QuoteActionTypes.GENERATE_TERMLIFE_QUOTEID;
  constructor() {}
}

export class InitiateNewBusinessFail implements Action {
  readonly type = QuoteActionTypes.INITIATE_NEW_BUSINESS_FAIL;
  constructor(public productId: string, public errorCode: string) {}
}

export class InitiateNewBusinessSuccess implements Action {
  readonly type = QuoteActionTypes.INITIATE_NEW_BUSINESS_SUCCESS;
  constructor(public productId: string) {}
}

export class UpdateQuote implements Action {
  readonly type = QuoteActionTypes.UPDATE_QUOTE;
  constructor(public payload: string) {}
}

export class UpdateQuoteFail implements Action {
  readonly type = QuoteActionTypes.UPDATE_QUOTE_FAIL;
  constructor(public productId: string, public error: any) {}
}

export class UpdateQuoteSuccess implements Action {
  readonly type = QuoteActionTypes.UPDATE_QUOTE_SUCCESS;
  constructor(public payload: string) {}
}

export class PatchQuote implements Action {
  readonly type = QuoteActionTypes.PATCH_QUOTE;
  constructor(public payload: string, public body: UpdateQuoteRequestBody) {}
}

export class RateQuote implements Action {
  readonly type = QuoteActionTypes.RATE_QUOTE;
  constructor(
    public productId: string,
    public ratingType: string,
    public suppressSoftfall?: boolean
  ) {}
}

export class RateQuoteFail implements Action {
  readonly type = QuoteActionTypes.RATE_QUOTE_FAIL;
  constructor(
    public productId: string,
    public errorCode: string,
    public suppressSoftfall?: boolean
  ) {}
}

export class RateQuoteSuccess implements Action {
  readonly type = QuoteActionTypes.RATE_QUOTE_SUCCESS;
  constructor(public productId: any) {}
}

export class TermLifeRateQuote implements Action {
  readonly type = QuoteActionTypes.TERM_LIFE_RATE_QUOTE;
  constructor(public termLifeEligible: TermLifeEligible) {}
}

export class TermLifeRateQuoteFail implements Action {
  readonly type = QuoteActionTypes.TERM_LIFE_RATE_QUOTE_FAIL;
  constructor(public productId: string, public errorCode: string) {}
}

export class TermLifeRateQuoteSuccess implements Action {
  readonly type = QuoteActionTypes.TERM_LIFE_RATE_QUOTE_SUCCESS;
  constructor(public termLife: TermLifeResponse) {}
}

export class UpdateTermlifeCoverage implements Action {
  readonly type = QuoteActionTypes.UPDATE_TERMLIFE_COVERAGE;
  constructor(public termLife: Update<TermLifeResponse>) {}
}

export class RemoveTermlifeCoverage implements Action {
  readonly type = QuoteActionTypes.REMOVE_TERMLIFE_COVERAGE;
  constructor(public id: string) {}
}

export class EmailQuoteEstimate implements Action {
  readonly type = QuoteActionTypes.EMAIL_QUOTE_ESTIMATE;
  constructor(public payload: any) {}
}
export class EmailQuoteEstimateSuccess implements Action {
  readonly type = QuoteActionTypes.EMAIL_QUOTE_ESTIMATE_SUCCESS;
  constructor(public payload: Update<TermLifeResponse>) {}
}
export class EmailQuoteEstimateFail implements Action {
  readonly type = QuoteActionTypes.EMAIL_QUOTE_ESTIMATE_FAIL;
  constructor(public error: any) {}
}

export class RateBindFail implements Action {
  readonly type = QuoteActionTypes.RATE_BIND_FAIL;
  constructor(public productId: string, public errorCode: string) {}
}

export class RateBindSuccess implements Action {
  readonly type = QuoteActionTypes.RATE_BIND_SUCCESS;
  constructor(public payload: string) {}
}

export class InvalidateQuote implements Action {
  readonly type = QuoteActionTypes.INVALIDATE_QUOTE;
  constructor(public payload: string) {}
}

export class InvalidateBind implements Action {
  readonly type = QuoteActionTypes.INVALIDATE_BIND;
  constructor(public payload: string) {}
}

export class InValidateQuoteCompletion implements Action {
  readonly type = QuoteActionTypes.INVALIDATE_QUOTE_COMPLETION;
  constructor(public payload: string) {}
}

export class ValidateQuoteCompletion implements Action {
  readonly type = QuoteActionTypes.VALIDATE_QUOTE_COMPLETION;
  constructor(public payload: string) {}
}

export type QuoteActions =
  | InitiateNewBusinessAuto
  | InitiateNewBusinessPowersports
  | InitiateNewBusinessHomeowners
  | InitiateNewBusinessCondo
  | InitiateNewBusinessRenters
  | InitiateNewBusinessUmbrella
  | InitiateNewBusinessTermLife
  | GenerateTermLifeQuoteId
  | InitiateNewBusinessFail
  | InitiateNewBusinessSuccess
  | UpdateQuote
  | UpdateQuoteFail
  | UpdateQuoteSuccess
  | PatchQuote
  | RateQuote
  | RateQuoteFail
  | RateQuoteSuccess
  | TermLifeRateQuote
  | TermLifeRateQuoteFail
  | TermLifeRateQuoteSuccess
  | UpdateTermlifeCoverage
  | RemoveTermlifeCoverage
  | RateBindFail
  | RateBindSuccess
  | InvalidateQuote
  | InvalidateBind
  | InValidateQuoteCompletion
  | ValidateQuoteCompletion
  | EmailQuoteEstimate
  | EmailQuoteEstimateSuccess
  | EmailQuoteEstimateFail;
