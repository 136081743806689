import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { CRUDService } from '@core/services/crud.service';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { filter } from 'rxjs/operators';
import { McpContactPoint } from '@core/models/modify-contact-point/modify-contact-point-request.model';
import { AddressEntity } from '@core/models/entities/address.entity';

@Injectable({
  providedIn: 'root',
})
export class PolicyholderDao implements CRUDService<PolicyholderEntity> {
  constructor(private _store: Store<fromStore.AppState>) {}

  get(): Observable<PolicyholderEntity> {
    throw new Error('Method not implemented.');
  }

  add(policyholder: PolicyholderEntity): void {
    this._store.dispatch(new fromActions.AddPolicyholder(policyholder));
  }

  addTempPolicyholder(policyholder: PolicyholderEntity): void {
    this._store.dispatch(new fromActions.AddPolicyholderSuccess(policyholder));
  }

  addAll(policyholders: PolicyholderEntity[]): void {
    this._store.dispatch(new fromActions.AddAllPolicyholders(policyholders));
  }

  update(policyholder: PolicyholderEntity): void {
    this._store.dispatch(new fromActions.UpdatePolicyholder(policyholder));
  }

  updateTempPolicyholder(policyholder: PolicyholderEntity): void {
    this._store.dispatch(
      new fromActions.UpdatePolicyholderSuccess(policyholder)
    );
  }

  delete(policyholderId: string): void {
    this._store.dispatch(new fromActions.DeletePolicyholder(policyholderId));
  }

  getNamedInsured(): Observable<PolicyholderEntity> {
    return this.getCurrentNamedInsured().pipe(filter(valid => !!valid));
  }

  getCurrentNamedInsured(): Observable<PolicyholderEntity> {
    return this._store.select(fromSelectors.getPrimaryNamedInsured);
  }

  getAll(): Observable<PolicyholderEntity[]> {
    return this._store.select(fromSelectors.getAllPolicyholders);
  }

  updateContactPoint(contactPoint: McpContactPoint): void {
    this._store.dispatch(new fromActions.UpdateContactPointDsm(contactPoint));
  }

  getPolicyHolderType(policyHolder): Observable<string> {
    return this._store.select(fromSelectors.getPolicyHolderType(policyHolder));
  }

  countCallsInFlight(): Observable<number> {
    return this._store.select(fromSelectors.getLoading);
  }

  updatePolicyholderDob(policyholderId: string, dob: string) {
    this._store.dispatch(
      new fromActions.UpdatePolicyholderDob(policyholderId, dob)
    );
  }

  getPolicyHolderAddress(): Observable<AddressEntity> {
    return this._store.select(fromSelectors.getPolicyHolderAddress);
  }
}
