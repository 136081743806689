import { Injectable } from '@angular/core';

import {
  createClient,
  Entry,
  EntryCollection,
  ContentfulClientApi,
} from 'contentful';

import { AppConfigService } from '@core/services/app-config.service';

@Injectable()
export class ContentfulService {
  constructor(private _appConfig: AppConfigService) {}

  getEntries(
    contentType: string,
    query?: Record<string, unknown>,
    isPreview?: boolean
  ): Promise<Entry<any>[]> {
    return this.getClient(isPreview)
      .getEntries(
        Object.assign(
          {
            content_type: contentType,
          },
          query
        )
      )
      .then((response: EntryCollection<any>) => response.items);
  }

  private getClient(isPreview: boolean): ContentfulClientApi {
    const options: any = {
      space: this._appConfig.config.contentfulSpaceId,
      environment: this._appConfig.config.contentfulEnvironment,
    };
    if (isPreview) {
      options['accessToken'] =
        this._appConfig.config.contentfulPreviewAccessToken;
      options['host'] = 'preview.contentful.com';
    } else {
      options['accessToken'] = this._appConfig.config.contentfulAccessToken;
    }
    return createClient(options);
  }
}
