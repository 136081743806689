import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {
  switchMap,
  take,
  catchError,
  map,
  concatMap,
  mergeMap,
  debounceTime,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { PolicyNumberService } from '@core/services/policy-number.service';
import { PolicyNumberResponse } from '@core/models/policy-number/policy-number-response.model';

@Injectable()
export class PolicyNumberEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _policyNumberService: PolicyNumberService,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper
  ) {}

  readonly GENERATE_POLICY_DEBOUNCE = 500;

  @Effect()
  generatePolicy$ = this._actions$.pipe(
    ofType(fromActions.PolicyNumberTypes.GENERATE_POLICY_NUMBER),
    debounceTime(this.GENERATE_POLICY_DEBOUNCE),
    switchMap(() =>
      this._store.select(fromSelectors.buildPolicyNumberRequests).pipe(take(1))
    ),
    mergeMap(requests => from(requests)),
    concatMap(request => {
      this._loggingService.log('GeneratePolicy Requests:', request);
      return this._policyNumberService.generatePolicyNumber(request).pipe(
        map(response => {
          this._loggingService.log('GeneratePolicy Response', response);
          const policyNumberResponse: PolicyNumberResponse = {
            productId: request.productId,
            policyNumber: response.policyNumber,
            quoteId: response.quoteId,
          };
          return new fromActions.GeneratePolicyNumberSuccess(
            policyNumberResponse
          );
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          safeError['quoteId'] = request.quoteId;
          this._loggingService.log('GeneratePolicy Error', safeError);
          return of(new fromActions.Softfall('Generate PolicyNumber Error'));
        })
      );
    })
  );

  @Effect()
  markPolicyNumberLoading$ = this._actions$.pipe(
    ofType(fromActions.PolicyNumberTypes.GENERATE_POLICY_NUMBER),
    switchMap(() =>
      this._store.select(fromSelectors.buildPolicyNumberRequests)
    ),
    take(1),
    mergeMap(requests =>
      from(
        requests.map(request => {
          return new fromActions.MarkPolicyNumberLoading(request.productId);
        })
      )
    )
  );
}
