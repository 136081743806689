import {
  ReassignDvaApiResponse,
  ReassignDvaApiRequest,
  GetDvaApiRequest,
} from '@core/models/auto/driver-vehicle-assignment.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { ProductTypes } from '@shared/constants/app-constants';
import { map } from 'rxjs/operators';
import { DriverVehicleAssignmentEntity } from '@core/models/entities/driver-vehicle-assignment.entity';

@Injectable({
  providedIn: 'root',
})
export class DriverVehicleAssignmentAdapter extends BaseDsmAdapter {
  putAssignments(
    request: ReassignDvaApiRequest
  ): Observable<ReassignDvaApiResponse> {
    const url = `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}/driver-vehicle-assignments`;

    return this.put(
      url,
      {
        vehicleDrivers: request.entities,
      },
      {
        productId: ProductTypes.AUTO,
      }
    ).pipe(
      map((apiResponse: DriverVehicleAssignmentEntity[]) => ({
        entities: apiResponse,
      }))
    );
  }

  getAssignments(
    request: GetDvaApiRequest
  ): Observable<DriverVehicleAssignmentEntity[]> {
    const url = `${this._appConfig.config.pcEdgeAutoUrl}/quotes/${request.quoteId}/driver-vehicle-assignments`;

    return this.get(url, {
      productId: ProductTypes.AUTO,
    });
  }
}
