import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PolicyAddressDao } from '@core/dao/policy-address.dao';

import { AddressEntity } from '@core/models/entities/address.entity';
import { CRUDService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyAddressService implements CRUDService<AddressEntity> {
  constructor(private _dsmPolicyAddressDao: PolicyAddressDao) {}

  add(address: AddressEntity): void {
    this._dsmPolicyAddressDao.add(address);
  }

  update(address: AddressEntity): void {
    this._dsmPolicyAddressDao.update(address);
  }

  addOrUpdate(address: AddressEntity): void {
    this._dsmPolicyAddressDao.addOrUpdateAddress(address);
  }

  delete(addressId: string): void {
    this._dsmPolicyAddressDao.delete(addressId);
  }

  getPolicyAddress(id: string): Observable<AddressEntity> {
    return this._dsmPolicyAddressDao.getPolicyAddress(id);
  }

  getAll(): Observable<AddressEntity[]> {
    return this._dsmPolicyAddressDao.getAll();
  }
}
