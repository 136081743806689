import {
  Directive,
  OnChanges,
  OnDestroy,
  Input,
  ElementRef,
} from '@angular/core';
import { FormControlStatus, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[mqValidationReceptacle]',
})
export class ValidationReceptacleDirective implements OnChanges, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() controlName: string; // not "formControlName"; that's a directive on its own
  private unsubscribe = new Subject();

  constructor(private element: ElementRef) {}

  ngOnDestroy() {
    this.dropSubscription();
  }

  ngOnChanges() {
    if (!this.form) {
      return;
    }
    const control = this.controlName
      ? this.form.get(this.controlName)
      : this.form;
    if (control) {
      this.commitStatus(<FormControlStatus>control.status);
      this.dropSubscription();
      control.statusChanges
        .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
        .subscribe(statusChange => {
          this.commitStatus(statusChange);
        });
    }
  }

  private dropSubscription() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
    this.unsubscribe = new Subject();
  }

  private commitStatus(status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED') {
    switch (status) {
      case 'VALID':
        {
          this.element.nativeElement.classList.add('ng-valid');
          this.element.nativeElement.classList.remove(
            'ng-invalid',
            'ng-pending'
          );
        }
        break;
      case 'INVALID':
        {
          this.element.nativeElement.classList.add('ng-invalid');
          this.element.nativeElement.classList.remove('ng-valid', 'ng-pending');
        }
        break;
      case 'PENDING':
        {
          this.element.nativeElement.classList.add('ng-pending');
          this.element.nativeElement.classList.remove('ng-valid', 'ng-invalid');
        }
        break;
    }
  }
}
