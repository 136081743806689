<div id="agencyStateLicenseCard">
  <span id="nameFiledWithCommissionerAndState"
    >{{ nameFiledWithCommissioner }}, {{ fullResidentStateName }}</span
  >
</div>
<div>
  <span id="agencyStateLicenseNumnber"
    >{{ stateAbbrev }} license number: {{ licenseNumber }}</span
  >
</div>
