import { Product } from './product.model';

export const enum ProductSimpleStatus {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  UNRATED = 'UNRATED',
  RATED = 'RATED',
  BOUND = 'BOUND',
  ISSUED = 'ISSUED',
  ISSUE_FAILED = 'ISSUE_FAILED',
}

export class ProductStatus {
  productId: string;
  status: ProductSimpleStatus;

  constructor(product: Product) {
    this.productId = product.id;
    this.status = ProductStatus.simpleStatusFromProduct(product);
  }

  static simpleStatusFromProduct(product: Product): ProductSimpleStatus {
    if (product.hasError) {
      return ProductSimpleStatus.ERROR;
    }
    if (
      product.quoteLoading ||
      product.quoteRating ||
      product.quoteBinding ||
      product.quoteCoverageLoading ||
      product.quoteUpdateLoading ||
      product.issueLoading
    ) {
      return ProductSimpleStatus.PENDING;
    }
    if (product.issueCompleted) {
      return ProductSimpleStatus.ISSUED;
    }
    if (product.issueFailed) {
      return ProductSimpleStatus.ISSUE_FAILED;
    }
    if (product.bindRated) {
      return ProductSimpleStatus.BOUND;
    }
    if (product.quoteRated) {
      return ProductSimpleStatus.RATED;
    }
    return ProductSimpleStatus.UNRATED;
  }
}
