export class NrErrorUtils {
  static sanitizeError<T>(input: T, depthLimit = 8): T {
    if (!input || typeof input !== 'object' || input instanceof Array) {
      return input;
    }

    if (depthLimit <= 0) {
      return input;
    }
    depthLimit--;

    const output = {};

    for (const specialKey of ['message', 'stack']) {
      if (input[specialKey]) {
        output[specialKey] = NrErrorUtils.sanitizeError(
          input[specialKey],
          depthLimit
        );
      }
    }

    for (const key of Object.keys(input)) {
      if (key.startsWith('_')) {
        continue;
      }
      output[key] = NrErrorUtils.sanitizeError(input[key], depthLimit);
    }
    return <T>output;
  }
}
