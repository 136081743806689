<div class="prequalified" *ngIf="vehicles.length">
  <nw-display-panel
    heading="Pre-approved for our safe-driving behavior discount"
    [alwaysOpen]="true"
    [openText]="headerIcon"
    class="program-panel"
  >
    <div class="container tm-content-container">
      <p>
        <strong>Congratulations!</strong> You earned a safe-driving behavior
        discount, based on
        <strong>driving data already collected by your vehicle.</strong> No
        further action is necessary to apply this discount to your policy today.
      </p>
      <inline-help
        labelInnerHTML="SmartRide Instant Discount"
        helpTextInnerHTML="<strong>How does this work?</strong> Many new cars have built-in
      technology that can capture driving behavior. Owners consent to sharing
      that information with insurers for the opportunity of getting a discount."
      ></inline-help>
      <div class="row">
        <div class="col-1">
          <img class="tm-icon" src="assets/images/no-device-dk-blue.svg"
          alt="Nothing Extra in the Form of Software or Special Equipment"
          />
        </div>
        <div class="col-sm-3 col-xs-12 offset-sm-1 offset-md-0">
          <p class="font-weight-bold">No device or app required.</p>
        </div>
        <div class="col-1">
          <img class="tm-icon" src="assets/images/dollar-lock-dk-blue.svg"
          alt="Dollar with Lock - Receive an Immediate Discount When you Sign-Up Now."
          />
        </div>
        <div class="col-sm-3 col-xs-12 offset-sm-1 offset-md-0">
          <p class="font-weight-bold">
            Keep the discount for as long as the vehicles are insured with us.
          </p>
        </div>
        <mq-telematics-video [name]="'PreQualified'"></mq-telematics-video>
      </div>
    </div>

    <mq-telematics-vehicle-premium
      [vehicles]="vehicles"
      [vehiclePremiums]="vehiclePremiums"
      [enrollmentVehicles]="enrollmentVehicles"
      [isPrequalified]="true"
      (enrollmentChanged)="changeEnrollment($event)"
    ></mq-telematics-vehicle-premium>
  </nw-display-panel>
</div>
