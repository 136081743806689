import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CoverageChange } from '@core/models/auto/quotes/update-coverages-request.model';
import { CoverageEntity } from '@core/models/entities/coverage.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { Product } from '@core/models/products/product.model';
import { SessionLog } from '@core/models/session-log.model';
import {
  AgencyService,
  HomeownersService,
  LoadingService,
  LoggingService,
  SessionService,
} from '@core/services';
import { CoverageErrorService } from '@core/services/coverage-error.service';
import { CoverageHelper } from '@core/services/helpers/coverage.helper';
import { TelematicsEnrollmentsService } from '@core/services/telematics-enrollments.service';
import { VehicleService } from '@core/services/vehicle.service';
import { CoverageError } from '@core/store/reducers/coverage-error.reducer';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import {
  CHAR_CODE_SPECIAL,
  CHAR_CODE_SYMBOL,
  CoverageCodes,
  CoverageDisplayGrouping,
  CoverageIds,
  DEFAULT_OFFERING_TYPE,
  ProductTypes,
} from '@shared/constants/app-constants';
import {
  AdditionalCoveragesDisplayHelpText,
  ANNUAL_MILEAGE_HELP_TEXT,
  AnnualMilesHelpText,
  PIPEntireHouseholdExcluded,
  PIPWorkLossWaiver,
  WorkersCompensationHelpText,
} from '@shared/constants/help-text-constants';
import { StringUtils } from '@shared/utils/string.utils';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Pipe({ name: 'filterInvalidOptions' })
export class InvalidOptionsPipe implements PipeTransform {
  state: string;
  constructor(
    private coverageHelper: CoverageHelper,
    private sessionService: SessionService
  ) {
    this.sessionService.getQuoteState().subscribe(qs => (this.state = qs));
  }
  transform(
    options,
    coverage: CoverageEntity,
    coverageDisplays: CoverageEntity[]
  ): CoverageEntity[] {
    return options.filter(option =>
      this.coverageHelper.isOptionValid(
        option.value,
        coverage,
        coverageDisplays,
        this.state
      )
    );
  }
}

@Component({
  selector: 'mq-edit-coverage-table',
  templateUrl: './edit-coverage-table.component.html',
  styleUrls: ['./edit-coverage-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCoverageTableComponent implements OnChanges, OnInit {
  coverageDisplays: CoverageEntity[];
  appLoading = false;
  activePanelIds: string[] = ['discountsPanel'];

  // PIP Work Loss Waiver
  pipWorkLossWaiverCustomerMessage: string;
  pipWorkLossWaiverAgentMessage = PIPWorkLossWaiver['Agent'];
  isAgentExperience$: Observable<boolean>;
  // PIP Entire Household Excluded
  pipEntireHouseholdExcludedCustomerMessage: string;
  pipEntireHouseholdExcludedAgentMessage = PIPEntireHouseholdExcluded['Agent'];
  showPIPEntireHouseholdExcluded: boolean;
  //
  showDwellingReplacementCost: boolean;
  WorkersCompensationCustomerHelpText: string;
  MoneyCoverageAdditionalDisplayText: string;
  SecuritiesCoverageAdditionalDisplayText: string;
  JewelryWatchesFursCoverageAdditionalDisplayText: string;
  SilverwareCoverageAdditionalDisplayText: string;
  WorkersCompensationAgentHelpText = WorkersCompensationHelpText['Agent'];
  showWorkersCompensationHelpText: boolean;
  showAdditionalCoveragesDisplayHelpText: boolean;
  showPIPWorkLossWaiver: boolean;
  annualMileageHelpText: string;
  isSmartMilesState: boolean;
  isSRCCForCA: boolean;
  defaultOfferingType = DEFAULT_OFFERING_TYPE;
  helpText = AnnualMilesHelpText.CA;
  helpTextALL = AnnualMilesHelpText.ALL;
  isVehicleMilesState: boolean;
  isFullSafetyGlassSelectboxRemovableState: boolean;
  isDeclinePIPLimitCoverageApplicable: boolean;
  isExcludeTDIcoverageIfUnavailable: boolean;
  offeringType$: Observable<string>;
  otherStructure: number;
  state$: Observable<string>;
  state: string;

  vehicle: VehicleEntity;

  @Input() form: UntypedFormGroup;
  @Input() coverageChanges: CoverageChange[];
  @Input() coverages: CoverageEntity[];
  @Input() coverageErrors: CoverageError[];
  @Input() showCoveragePrices: boolean;
  @Input() checkboxSelected: boolean;
  @Input() termMonths = 1;
  @Input() title: string;
  @Input() icon: string;
  @Input() isQuote: boolean;
  @Input() extraUpdatedCoverage: string;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() contactNumber: string;
  @Input() notification: string;
  @Input() vehicleId: string;
  @Input() vehicleMiles: string;
  @Input() quoteState: string;
  @Input() showPropertyInfo = false;
  @Input() isAuto: boolean;
  @Input() isPowersports: boolean;
  @Input() selectedProduct: Product;
  @Input() isMobile: boolean;
  @Input() isAutoSelected: boolean;
  @Input() isHomeownersSelected: boolean;
  @Input() isUmbrellaSelected: boolean;
  @Input() openProgramsToSaveData: { shouldLogToSplunk: boolean; name: string };

  @Output() consentToggled = new EventEmitter<Event>();
  @Output() coverageUpdated: EventEmitter<CoverageChange[]> = new EventEmitter<
    CoverageChange[]
  >();

  @Output() propertyInfoModalToggled = new EventEmitter<Event>();

  constructor(
    private coverageHelper: CoverageHelper,
    private coverageErrorService: CoverageErrorService,
    private loadingService: LoadingService,
    private agencyService: AgencyService,
    private vehicleService: VehicleService,
    private telematicsEnrollmentsService: TelematicsEnrollmentsService,
    private _homeownersService: HomeownersService,
    private _sessionService: SessionService,
    private _loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this.isAgentExperience$ = this.agencyService.isAgent();
    this.agencyService.isCompRater().subscribe(isComprater => {
      if (isComprater) {
        this.showPropertyInfo = true;
      } else {
        this.showPropertyInfo = false;
      }
    });
    this.title = this.vehicle
      ? this.vehicle?.year +
        ' ' +
        this.vehicle?.make +
        ' ' +
        this.vehicle?.model
      : this.title;
    if (this.stateSpecificFlags) {
      this.showDwellingReplacementCost =
        this.stateSpecificFlags.dwellingReplacementCost;
      this.showWorkersCompensationHelpText =
        this.stateSpecificFlags.workersCompensationHelpText;
      this.showAdditionalCoveragesDisplayHelpText =
        this.stateSpecificFlags.showAdditionalHelpTextForCoverages;
      this.showPIPWorkLossWaiver = this.stateSpecificFlags.pipWorkLossWaiver;
      this.isSmartMilesState = this.stateSpecificFlags.smartMiles;
      this.isSRCCForCA = this.stateSpecificFlags.srccForCA;
      this.isVehicleMilesState = this.stateSpecificFlags.vehicleMiles;
      this.isFullSafetyGlassSelectboxRemovableState = this.stateSpecificFlags
        .fullSafetyGlassSelectboxRemovableState
        ? true
        : false;
      this.showPIPEntireHouseholdExcluded =
        this.stateSpecificFlags.isPipExcluded;
      this.isExcludeTDIcoverageIfUnavailable =
        this.stateSpecificFlags.isExcludeTDIcoverageIfUnavailable;
    }
    this.pipWorkLossWaiverCustomerMessage = this.contactNumber
      ? PIPWorkLossWaiver['Customer'].replace(
          '{phoneNumber}',
          this.contactNumber
        )
      : '';
    this.WorkersCompensationCustomerHelpText = this.contactNumber
      ? WorkersCompensationHelpText['Customer'].replace(
          '{phoneNumber}',
          this.contactNumber
        )
      : '';
    this.SecuritiesCoverageAdditionalDisplayText =
      AdditionalCoveragesDisplayHelpText['UnscheduledSecurities'];
    this.MoneyCoverageAdditionalDisplayText =
      AdditionalCoveragesDisplayHelpText['UnscheduledMoney'];
    this.JewelryWatchesFursCoverageAdditionalDisplayText =
      AdditionalCoveragesDisplayHelpText['UnscheduledJewelryWatchesFurs'];
    this.SilverwareCoverageAdditionalDisplayText =
      AdditionalCoveragesDisplayHelpText['UnscheduledSilverware'];
    this.pipEntireHouseholdExcludedCustomerMessage = this.contactNumber
      ? PIPEntireHouseholdExcluded['Customer'].replace(
          '{phoneNumber}',
          this.contactNumber
        )
      : '';
    if (
      this.stateSpecificFlags &&
      this.stateSpecificFlags.dwellingReplacementCost &&
      this.title === CoverageDisplayGrouping.PropertyLiability &&
      this.isQuote
    ) {
      this.activePanelIds.push(this.title);
    }

    if (
      (this.isVehicleMilesState || this.isSmartMilesState) &&
      this.vehicleId &&
      this.isAutoProduct()
    ) {
      const DSM_ANNUAL_MILES_LIMIT = 60000;
      this.form.addControl(
        `annualMiles_${this.vehicleId}`,
        new UntypedFormControl(this.vehicleMiles, [
          Validators.required,
          Validators.min(1),
          Validators.max(DSM_ANNUAL_MILES_LIMIT),
        ])
      );
      this.vehicleService
        .get(this.vehicleId)
        .subscribe(vehicle => (this.vehicle = vehicle));
      this.annualMileageHelpText = ANNUAL_MILEAGE_HELP_TEXT;
    }
    this.isDeclinePIPLimitCoverageApplicable =
      this.stateSpecificFlags.isDeclinePIPLimitCoverageApplicable;
    this.offeringType$ = this._homeownersService.getOfferingType();
    if (this.isExcludeTDIcoverageIfUnavailable) {
      this.coverages = this.coverageHelper.excludeTDIcoverageIfUnavailable(
        this.coverages
      );
    }
    this.coverages = this.coverageHelper.updateSSLCoverageOptions(
      this.coverages,
      this.quoteState
    );
    this.state$ = this._sessionService.getQuoteState();
    this.state$.pipe(take(1)).subscribe(state => (this.state = state));
  }

  ngOnChanges(): void {
    this.coverages = this.coverageHelper.updateSSLCoverageOptions(
      this.coverages,
      this.quoteState
    );
    this.loadingService
      .getAppLoading()
      .subscribe(appLoading => (this.appLoading = appLoading));
    this.coverageDisplays = this.coverageHelper.mergeSpreadableCoverages(
      this.coverages
    );
    if (this.isExcludeTDIcoverageIfUnavailable) {
      this.coverageDisplays = this.coverageDisplays.filter(
        c => !(c.coverageId == CoverageIds.TDI && !c.available)
      );
    }
    const changeIds = this.coverageChanges.map(
      coverageChange => coverageChange.coverageId
    );
    const updatedCoverages = this.coverages.filter(cvg => {
      return (
        changeIds.includes(cvg.coverageId) ||
        cvg.coverageId === this.extraUpdatedCoverage
      );
    });
    if (updatedCoverages.length) {
      this.activePanelIds.push(this.title);
    }

    if (this.vehicleId && this.isAutoProduct()) {
      const DSM_ANNUAL_MILES_LIMIT = 60000;
      const DSM_ANNUAL_MIN_MILES_LIMIT = 365;
      const _vehicleMile = parseInt(this.vehicleMiles, 10);
      if (_vehicleMile < DSM_ANNUAL_MIN_MILES_LIMIT) {
        this.vehicleMiles = DSM_ANNUAL_MIN_MILES_LIMIT.toString();
      }

      this.form.addControl(
        `annualMiles_${this.vehicleId}`,
        new UntypedFormControl(this.vehicleMiles, [
          Validators.required,
          Validators.min(DSM_ANNUAL_MIN_MILES_LIMIT),
          Validators.max(DSM_ANNUAL_MILES_LIMIT),
        ])
      );
      this.vehicleService
        .get(this.vehicleId)
        .subscribe(vehicle => (this.vehicle = vehicle));
      this.annualMileageHelpText = ANNUAL_MILEAGE_HELP_TEXT;
      if (this.annualMiles?.invalid) {
        this.activePanelIds.push(this.formatTitle(this.title));
        this.annualMiles.markAsTouched();
        this.annualMiles.markAsDirty();
      }
    }
  }

  updateAnnualMiles(vehicleId: string): void {
    if (
      vehicleId &&
      this.vehicle &&
      this.vehicle.vehicleId === vehicleId &&
      this.annualMiles.valid
    ) {
      this.vehicle.annualMiles = this.annualMiles.value;
      window.scrollTo(0, 0);
      this.loadingService.loadingBegin();
      this.vehicleService.update(this.vehicle);
      const updateAnnualMileLog: SessionLog = {
        type: 'Recalculate Premium',
        code: 'Annual Miles',
        reason: 'Annual Miles Update',
        value: this.vehicle.annualMiles,
      };
      this._sessionService.updateSessionLogEntry(updateAnnualMileLog);
      this.resetTelematicsEnrollments();
    }
  }

  resetTelematicsEnrollments(): void {
    this.loadingService
      .getAppLoading()
      .pipe(
        filter(loading => !loading), // update vehicle ends loading on its own, pick up on that here
        take(1)
      )
      .subscribe(() => {
        this.loadingService.loadingBegin();
        this.telematicsEnrollmentsService.dispatchRemoveTelematicsEnrollment(); // remove current enrollment as miles have changed
        this.telematicsEnrollmentsService
          .getEnrollments()
          .pipe(
            filter(enrollments => !enrollments), // must wait until enrollment has been removed before next step
            take(1)
          )
          .subscribe(() => {
            this.telematicsEnrollmentsService.dispatchTelematicsEnrollment(); // re-enroll for new mileage
            this.telematicsEnrollmentsService
              .getEnrollments()
              .pipe(
                filter(enrollments => !!enrollments), // must wait for new enrollment to finish before ending loading spinner
                take(1)
              )
              .subscribe(() => {
                this.loadingService.loadingEnd();
              });
          });
      });
  }

  asNumber(value: string): number {
    return +value;
  }

  isNumber(value: number): boolean {
    return !isNaN(value);
  }

  getSelectedValue(code: string, selectedValues: any): string {
    if (!selectedValues) {
      return;
    }
    const selectedValue = selectedValues.find(
      selectedValue => selectedValue.code === code
    );
    {
      return selectedValue ? selectedValue.description.split('|')[0] : null;
    }
  }

  isDeclined(coverage: CoverageEntity): boolean {
    const selectedCode = coverage.selectedValue.find(
      selectedValue => selectedValue.code === 'selected'
    );
    return selectedCode && selectedCode.value === 'false';
  }

  removeErrors(): void {
    this.coverageErrorService.removeCoverageErrors();
  }

  handleCoverageChange(
    coverage: CoverageEntity,
    code: string,
    value: string
  ): void {
    if (this.isKYPIPPowersports(coverage)) {
      this.handleRulesForKYPIPPowersports(coverage, value, code);
      return;
    }
    if (this.coverageHelper.isWYBIAuto(coverage, this.quoteState)) {
      this.coverageUpdated.emit(
        this.coverageHelper.handleRulesForWYBIAuto(
          this.coverages,
          value,
          this.quoteState,
          this.coverageDisplays
        )
      );
      return;
    }
    if (this.coverageHelper.isWABIPowersports(coverage, this.quoteState)) {
      this.coverageUpdated.emit(
        this.coverageHelper.handleRulesForWABIPowersports(
          this.coverages,
          value,
          this.quoteState,
          this.coverageDisplays
        )
      );
      return;
    }
    if (this.shouldNotAllowQuoteRecalculation(coverage, code)) {
      return;
    }
    this.removeErrors();
    let termCode = value === 'false' || value === 'true' ? 'selected' : code;
    // this logic is to cover additional coverage term option values
    // that have true/false values, since for primary coverages true/false
    // are meant for accepting/declining a coverage
    if (this.isDeclinePIPLimitCoverageApplicable && code === 'PIPLimit') {
      termCode = code;
    }
    const termValue = value.includes('_' + termCode)
      ? value.split('_')[0]
      : value;
    const coverageChanges = this.coverageHelper.createCoverageChanges(
      this.coverages,
      coverage,
      termCode,
      termValue,
      this.quoteState
    );
    this.coverageUpdated.emit(coverageChanges);
  }

  getFormControlName(coverage: CoverageEntity, code?: string): string {
    let controlName =
      coverage.coverageId + (this.asNumber(coverage.coverableId) || '');
    controlName = code ? controlName + code : controlName;

    return controlName;
  }

  shouldShowInputError(
    coverage: CoverageEntity,
    code: string,
    error: string
  ): boolean {
    const control = this.form.get(this.getFormControlName(coverage, code));
    this.coverageHelper.handlePropertyMinCoverageLimit(
      coverage,
      control,
      this.state,
      this.coverageDisplays
    );
    return (
      control.invalid &&
      (control.dirty || control.touched) &&
      control.errors[error]
    );
  }

  shouldNotAllowQuoteRecalculation(
    coverage: CoverageEntity,
    code: string
  ): boolean {
    const control =
      code === 'selected'
        ? this.form.get(this.getFormControlName(coverage))
        : this.form.get(this.getFormControlName(coverage, code));
    return control.invalid && (control.dirty || control.touched);
  }

  onConsentToggled(event: any): void {
    this.consentToggled.emit(event);
  }

  getMinimumFieldAmount(coverage: CoverageEntity, code: string): string {
    const control = this.form.get(this.getFormControlName(coverage, code));
    return control.errors ? control.errors.min.min : '';
  }

  getMaximumFieldAmount(coverage: CoverageEntity, code: string): string {
    const control = this.form.get(this.getFormControlName(coverage, code));
    return control.errors ? control.errors.max.max : '';
  }

  shouldShowCoverageError(coverage: any): boolean {
    return (
      this.coverageErrors &&
      this.coverageErrors.length > 0 &&
      this.coverageChanges &&
      this.coverageChanges.some(change => {
        return (
          change.coverageId === coverage.coverageId &&
          (coverage.coverableId && change.coverableId
            ? coverage.coverableId === change.coverableId
            : true)
        );
      })
    );
  }

  shouldShowMonthlyCoverageCost(coverage: CoverageEntity): boolean {
    return (
      coverage.coverageCost &&
      !!coverage.coverageCost.find(cost => !!cost.actualTermAmount)
    );
  }

  calculateMonthlyCoverageCost(coverage: CoverageEntity): number {
    if (!coverage || !coverage.coverageCost) {
      return 0;
    }
    return (
      coverage.coverageCost
        .filter(cost => !!cost.actualTermAmount)
        .map(cost => cost.actualTermAmount.amount)
        .reduce((a, v) => a + v, 0) / this.termMonths
    );
  }

  get annualMiles(): AbstractControl {
    return this.vehicleId
      ? this.form.get(`annualMiles_${this.vehicleId}`)
      : null;
  }

  get CoverageIds(): any {
    return CoverageIds;
  }

  get homeownersProductId(): string {
    return ProductTypes.HOMEOWNERS;
  }

  isAutoProduct(): boolean {
    if (this.selectedProduct?.id === ProductTypes.AUTO) return true;
    return false;
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > CHAR_CODE_SPECIAL && charCode < CHAR_CODE_SYMBOL) {
      return true;
    }
    return false;
  }

  formatTitle(title: string): string {
    if (this.isMobile)
      return 'mq' + StringUtils.forceValidCssIdentifier(title) + '-mobile';
    else return 'mq' + StringUtils.forceValidCssIdentifier(title) + '-desktop';
  }

  isKYPIPPowersports(coverage: CoverageEntity): boolean {
    return (coverage.coverageId === CoverageIds.PEDPIP ||
      coverage.coverageId === CoverageIds.PIP) &&
      coverage.productId === ProductTypes.POWERSPORTS &&
      this.isDeclinePIPLimitCoverageApplicable
      ? true
      : false;
  }

  handleRulesForKYPIPPowersports(
    coverage: CoverageEntity,
    value: string,
    code: string
  ): void {
    const covPIPDefault = this.coverageHelper.createCoverageChanges(
      this.coverages,
      this.coverages.find(cov => cov.coverageId === CoverageIds.PIP),
      'PIPLimit',
      '10000',
      this.quoteState
    );
    const covPIPDecline = this.coverageHelper.createCoverageChanges(
      this.coverages,
      this.coverages.find(cov => cov.coverageId === CoverageIds.PIP),
      'selected',
      'false',
      this.quoteState
    );
    const covPEDAccept = this.coverageHelper.createCoverageChanges(
      this.coverages,
      this.coverages.find(cov => cov.coverageId === CoverageIds.PEDPIP),
      'PEDPIPLimit',
      '10000',
      this.quoteState
    );
    const covPEDDecline = this.coverageHelper.createCoverageChanges(
      this.coverages,
      this.coverages.find(cov => cov.coverageId === CoverageIds.PEDPIP),
      'selected',
      'false',
      this.quoteState
    );
    const covGuestAccept = this.coverageHelper.createCoverageChanges(
      this.coverages,
      this.coverages.find(cov => cov.coverageId === CoverageIds.PIP),
      'PIPGuestOnly',
      'true',
      this.quoteState
    );
    const covGuestDecline = this.coverageHelper.createCoverageChanges(
      this.coverages,
      this.coverages.find(cov => cov.coverageId === CoverageIds.PIP),
      'PIPGuestOnly',
      'false',
      this.quoteState
    );
    const covDeductible = this.coverageHelper.createCoverageChanges(
      this.coverages,
      this.coverages.find(cov => cov.coverageId === CoverageIds.PIP),
      'PIPDeductible',
      value,
      this.quoteState
    );
    const pedDecline =
      this.coverageDisplays.find(cov => cov.coverageId === 'PEDPIP')
        ?.selectedValue?.[0]?.value === 'false';

    if (coverage.coverageId === CoverageIds.PEDPIP) {
      switch (value) {
        case '10000': {
          this.coverageUpdated.emit(covPIPDecline.concat(covPEDAccept));
          break;
        }
        default: {
          this.coverageUpdated.emit(covPIPDefault.concat(covPEDDecline));
          break;
        }
      }
    } else if (coverage.coverageId === CoverageIds.PIP && !pedDecline) {
      switch (value) {
        case '10000': {
          this.coverageUpdated.emit(covPIPDefault.concat(covPEDDecline));
          break;
        }
        default: {
          break;
        }
      }
    } else if (
      coverage.coverageId === CoverageIds.PIP &&
      pedDecline &&
      code === 'PIPLimit'
    ) {
      switch (value) {
        case 'false': {
          this.coverageUpdated.emit(covGuestAccept);
          break;
        }
        default: {
          this.coverageUpdated.emit(covGuestDecline);
          break;
        }
      }
    } else if (
      coverage.coverageId === CoverageIds.PIP &&
      pedDecline &&
      code === 'PIPGuestOnly'
    ) {
      switch (value) {
        case 'false_PIPGuestOnly': {
          this.coverageUpdated.emit(covPIPDecline.concat(covPEDAccept));
          break;
        }
        default: {
          this.coverageUpdated.emit(covGuestAccept);
          break;
        }
      }
    } else if (
      coverage.coverageId === CoverageIds.PIP &&
      pedDecline &&
      code === 'PIPDeductible'
    ) {
      switch (value) {
        default: {
          this.coverageUpdated.emit(covDeductible);
          break;
        }
      }
    } else {
      return;
    }
  }

  isUMBIApplicable(coverage: CoverageEntity): boolean {
    return this.isAutoSelected &&
      this.isHomeownersSelected &&
      this.isUmbrellaSelected &&
      coverage.coverageId === CoverageIds.UMBI
      ? false
      : true;
  }

  isIncreasedOtherStructuresApplicable(coverage: CoverageEntity): boolean {
    let result = false;
    if (
      coverage.coverageId === CoverageIds.BasicCoverage &&
      this.stateSpecificFlags.increasedOtherStructures
    ) {
      coverage.selectedValue.forEach(v => {
        if (v.code === CoverageCodes.BasicCoverageLimit) {
          this.otherStructure = Number(v.value) * 0.3;
        }
      });
    }
    if (
      coverage.coverageId === CoverageIds.IncreasedOtherStructures &&
      this.stateSpecificFlags.increasedOtherStructures
    ) {
      coverage.selectedValue.forEach(v => {
        result = Number(v.value) > this.otherStructure ? true : false;
      });
    }
    return result;
  }

  logToSplunkIfEligible(event: NgbPanelChangeEvent): void {
    if (this.openProgramsToSaveData?.shouldLogToSplunk) {
      const accordionOpened = event.nextState;
      const experience = sessionStorage.getItem('adobeTargetExperience');
      const message = {
        accordionValue: `${accordionOpened ? 'opened' : 'closed'}`,
        accordionName: this.openProgramsToSaveData.name,
        testNumber: 'testLearn-digex760',
        groupAssignment: `${experience}`,
      };
      this._loggingService.logToSplunk({
        event: 'engagementAccordion',
        message,
      });
    }
  }
}
