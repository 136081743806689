import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Injectable } from '@angular/core';
import {
  ProtectiveDevice,
  ProtectiveDevicesInfo,
} from '@core/store/reducers/protective-devices.reducer';
import {
  ConstructionInfo,
  CoveredLocation,
  OilTank,
} from '@core/store/reducers/covered-location.reducer';
import {
  ProtectiveDeviceSelectedValueReplace,
  ProtectiveDeviceTypes,
} from '@shared/constants/app-constants';
import { DateUtils } from '@shared/utils/date.utils';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { CustomValidators } from '@core/validators/custom-validators';

@Injectable({
  providedIn: 'root',
})
export class PropertyHelper {
  buildHomeownerForm(_fb: UntypedFormBuilder): UntypedFormGroup {
    return _fb.group(
      {
        hasMortgage: _fb.control('', [Validators.required]),
        estimatedYearPurchased: _fb.control('', [Validators.required]),
        renovationsForm: _fb.group({
          renovations: _fb.array([]),
        }),
        constructionInfoForm: this.buildConstructionInfoForm(_fb),
        oilTankForm: this.buildOilTankForm(_fb),
      },
      {
        validators: [
          form =>
            this.validateYearPurchasedAgainstYearBuilt(<UntypedFormGroup>form),
          form =>
            this.validateRenovationsAgainstYearBuilt(<UntypedFormGroup>form),
        ],
      }
    );
  }

  buildRenterForm(_fb: UntypedFormBuilder): UntypedFormGroup {
    return _fb.group({
      personalPropertyCoverage: _fb.control('', []),
      structuralMaterial: _fb.control('', []),
      unitsInBuilding: _fb.control('', [Validators.required]),
    });
  }

  buildCondoForm(_fb: UntypedFormBuilder): UntypedFormGroup {
    return _fb.group({
      hasMortgage: _fb.control('', [Validators.required]),
      yearBuilt: _fb.control('', [Validators.required]),
      estimatedYearPurchased: _fb.control('', [Validators.required]),
      // constructionInfoForm: this.buildConstructionInfoForm(_fb),
      numberOfHalfBathrooms: _fb.control('', [
        Validators.required,
        CustomValidators.PicklistRequired,
      ]),
      numberOfFullBathrooms: _fb.control('', [
        Validators.required,
        CustomValidators.PicklistRequired,
      ]),
      roofCondition: _fb.control('', [Validators.required]),
      constructionType: _fb.control('', [Validators.required]),
      unitsInBuilding: _fb.control('', [Validators.required]),
    });
  }

  buildConstructionInfoForm(_fb: UntypedFormBuilder): UntypedFormGroup {
    return _fb.group({
      yearBuilt: _fb.control('', []),
      squareFootage: _fb.control('', []),
      numberOfStories: _fb.control('', []),
      constructionType: _fb.control('', []),
      exteriorWalls: _fb.control('', []),
      roofMaterial: _fb.control('', []),
      flatRoof: _fb.control('', []),
      // roofCondition: _fb.control('', []),
      foundationType: _fb.group({
        slab: _fb.control(false, []),
        crawlSpace: _fb.control(false, []),
        basement: _fb.control(false, []),
        daylightWalkoutBasement: _fb.control(false, []),
        suspendedOverHillside: _fb.control(false, []),
      }),
      numberOfHalfBathrooms: _fb.control('', []),
      halfBathroomDescription: _fb.control('', []),
      numberOfFullBathrooms: _fb.control('', []),
      fullBathroomDescription: _fb.control('', []),
      numberOfKitchens: _fb.control('', []),
      kitchenDescription: _fb.control('', []),
      additionalHeating: _fb.control('', []),
      garageType: _fb.control('', []),
      numberOfCars: _fb.control('', []),
      otherStructures: _fb.group({
        openPorch: _fb.control(false, []),
        screenPorch: _fb.control(false, []),
        sunSolarRoom: _fb.control(false, []),
        woodDeck: _fb.control(false, []),
        breezeway: _fb.control(false, []),
      }),
    });
  }

  buildOilTankForm(_fb: UntypedFormBuilder): UntypedFormGroup {
    return _fb.group({
      hasOilTank: _fb.control('', []),
      yearTankInstalled: _fb.control('', []),
      tankMaterial: _fb.control('', []),
      tankLocation: _fb.control('', []),
      isClosureFillCertified: _fb.control('', []),
    });
  }

  getNumberOfOccupants(policyholder: PolicyholderEntity): number {
    if (policyholder && policyholder.person) {
      switch (policyholder.person.maritalStatus) {
        case 'M':
          return 2;
      }
    }
    return 1;
  }

  serializeProtectiveDevices(devices: {
    [key: string]: string;
  }): ProtectiveDevice[] {
    return Object.keys(devices).map(device => ({
      type: device,
      value: this.replaceSelectedValues(device, devices[device]),
    }));
  }

  replaceSelectedTypes(selection: string): string {
    return selection
      .replace('burglarAlarm', ProtectiveDeviceTypes.BURGLARALARM)
      .replace('sprinklerSystem', ProtectiveDeviceTypes.SPRINKLERSYSTEM)
      .replace('fireOrSmokeAlarm', ProtectiveDeviceTypes.FIREORSMOKEALARM);
  }

  replaceSelectedValues(device: string, selection: string): any {
    // For cases where you know what you show and what
    // you return are different, use the app-constants replace
    if (typeof selection === 'boolean') {
      return selection;
    }
    if (
      ProtectiveDeviceSelectedValueReplace[device] &&
      ProtectiveDeviceSelectedValueReplace[device][selection]
    ) {
      return ProtectiveDeviceSelectedValueReplace[device][selection]
        .replacement;
    }
    // else use this replace to get the correct thing
    const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    // Considerations for NC (This is a DSM Defect they refuse to admit to)

    if (
      selection === 'CentralAlarm' ||
      selection === 'DirectAlarm' ||
      selection === 'CentralAlarm_Ext' ||
      selection === 'DirectAlarm_Ext'
    ) {
      return this.nullForNone(
        selection
          .replace('CentralAlarm_Ext', 'Central Burglar Alarm')
          .replace('DirectAlarm_Ext', 'Direct Burglar Alarm')
          .replace('CentralAlarm', 'Central Fire Alarm')
          .replace('DirectAlarm', 'Direct Fire Alarm')
      );
    } else {
      return this.nullForNone(
        selection
          .replace('_Ext', '')
          .replace(regex, '$1$4 $2$3$5')
          .replace('Central Fire Alarm', 'Central/Direct Fire Alarm')
          .replace('Central Burglar Alarm', 'Central/Direct Burglar Alarm')
      );
    }
  }

  nullForNone(input: string): string {
    if (!input || input === 'None') {
      return null;
    }
    return input;
  }

  formatCoveredLocation(
    form: any,
    protectiveDevicesForm: any
  ): CoveredLocation {
    return {
      ...form,
      constructionInfo: this.formatConstructionInfo(form.constructionInfoForm),
      flatRoof: form.constructionInfoForm.flatRoof,
      datePurchased: DateUtils.transformMonthYearSlashToYearMonthDaySlash(
        form.estimatedYearPurchased
      ),
      protectiveDevices: this.formatProtectiveDevicesRequest(
        protectiveDevicesForm
      ),
    };
  }

  formatProtectiveDevicesRequest(
    protectiveDevicesForm: any[]
  ): ProtectiveDevicesInfo {
    const protectiveDevice: ProtectiveDevicesInfo = {};

    if (protectiveDevicesForm) {
      protectiveDevicesForm.forEach(element => {
        if (this.nullForNone(element.value)) {
          switch (element.type) {
            case ProtectiveDeviceTypes.BURGLARALARM:
              protectiveDevice.burglarAlarm = element.value
                .replace('Local Alarm', 'LocalAlarm_Ext')
                .replace(
                  'Central/Direct Burglar Alarm',
                  'CentralBurglarAlarm_Ext'
                )
                .replace('Central Burglar Alarm', 'CentralAlarm_Ext')
                .replace('Direct Burglar Alarm', 'DirectAlarm_Ext');
              break;
            case ProtectiveDeviceTypes.SPRINKLERSYSTEM:
              protectiveDevice.sprinklerSystem = element.value
                .replace('Partial', 'Partial_Ext')
                .replace('Fully Automatic', 'FullyAutomatic_Ext');
              break;
            case ProtectiveDeviceTypes.FIREORSMOKEALARM:
              protectiveDevice.fireOrSmokeAlarm = element.value
                .replace('Local Alarm', 'LocalAlarm')
                .replace('Central/Direct Fire Alarm', 'CentralFireAlarm_Ext')
                .replace('Direct Fire Alarm', 'DirectAlarm')
                .replace('Central Fire Alarm', 'CentralAlarm');
              break;

            default:
              break;
          }
        }
      });
    }

    return protectiveDevice;
  }

  formatProtectiveDevicesResponseValues(
    device: string,
    selection: string
  ): string {
    // For cases where you know what you show and what
    // you return are different, use the app-constants replace
    if (
      ProtectiveDeviceSelectedValueReplace[device] &&
      ProtectiveDeviceSelectedValueReplace[device][selection]
    ) {
      return ProtectiveDeviceSelectedValueReplace[device][selection]
        .replacement;
    }
    // else use this replace to get the correct thing
    const regex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
    // Considerations for NC (This is a DSM Defect they refuse to admit to)
    if (
      selection === 'CentralAlarm' ||
      selection === 'DirectAlarm' ||
      selection === 'CentralAlarm_Ext' ||
      selection === 'DirectAlarm_Ext'
    ) {
      return this.nullForNone(
        selection
          .replace('CentralAlarm_Ext', 'Central Burglar Alarm')
          .replace('DirectAlarm_Ext', 'Direct Burglar Alarm')
          .replace('CentralAlarm', 'Central Fire Alarm')
          .replace('DirectAlarm', 'Direct Fire Alarm')
      );
    } else {
      return this.nullForNone(
        selection
          .replace('_Ext', '')
          .replace(regex, '$1$4 $2$3$5')
          .replace('Central Fire Alarm', 'Central/Direct Fire Alarm')
          .replace('Central Burglar Alarm', 'Central/Direct Burglar Alarm')
      );
    }
  }

  formatCondoCoveredLocation(
    form: any,
    protectiveDevicesForm?: any
  ): CoveredLocation {
    return {
      ...form,
      constructionInfo: form,
      // roofCondition: form.constructionInfoForm.roofCondition,
      datePurchased: DateUtils.transformMonthYearSlashToYearMonthDaySlash(
        form.estimatedYearPurchased
      ),
      protectiveDevices: this.formatProtectiveDevicesRequest(
        protectiveDevicesForm
      ),
    };
  }

  formatConstructionInfo(
    constructionInfoForm: AbstractControl
  ): ConstructionInfo {
    let constructionInfo = {};
    if (constructionInfoForm['constructionType'] === '') {
      return {};
    }
    if (constructionInfoForm['garageType'] === '') {
      constructionInfo = { ...constructionInfoForm };
      delete constructionInfo['garageType'];
      delete constructionInfo['numberOfCars'];
    } else {
      constructionInfo = {
        ...constructionInfoForm,
        garages: [
          {
            garageType: constructionInfoForm['garageType'],
            numberOfCars: constructionInfoForm['numberOfCars'],
          },
        ],
      };
    }
    return constructionInfo;
  }

  getOilTank(homeownersForm: AbstractControl): OilTank {
    const form = homeownersForm.value;
    if (form && form.hasOilTank) {
      return {
        yearTankInstalled: parseInt(form.yearTankInstalled, 10),
        tankMaterial: form.tankMaterial,
        tankLocation: form.tankLocation,
        isClosureFillCertified: form.isClosureFillCertified
          ? form.isClosureFillCertified
          : false,
      };
    } else {
      return null;
    }
  }

  validateYearPurchasedAgainstYearBuilt(form: UntypedFormGroup): void {
    const builtControl = form.get('constructionInfoForm.yearBuilt');
    const purchasedControl = form.get('estimatedYearPurchased');
    const builtDate = DateUtils.ngbDateFromYear(builtControl.value);
    const purchasedDate = DateUtils.ngbDateFromMmYyyy(purchasedControl.value);
    if (!builtDate || !purchasedDate) {
      return null;
    }
    if (DateUtils.compareNgbDates(builtDate, purchasedDate) > 0) {
      builtControl.setErrors({
        requiredAfter: purchasedDate,
      });
      purchasedControl.setErrors({
        beforeYearBuilt: builtDate.year,
      });
      return null;
    }
    builtControl.setErrors(
      this.removeError(builtControl.errors, 'requiredAfter')
    );
    purchasedControl.setErrors(
      this.removeError(purchasedControl.errors, 'beforeYearBuilt')
    );
    return null;
  }

  validateRenovationsAgainstYearBuilt(form: UntypedFormGroup): void {
    const builtControl = form.get('constructionInfoForm.yearBuilt');
    const builtDate = DateUtils.ngbDateFromYear(builtControl.value);
    if (!builtDate) {
      return null;
    }
    for (const renovationControl of (<UntypedFormArray>(
      form.get('renovationsForm.renovations')
    )).controls) {
      const renovationDate = DateUtils.ngbDateFromYear(
        renovationControl.value.year
      );
      if (!renovationDate) {
        continue;
      }
      const yearControl = renovationControl.get('year');
      if (DateUtils.compareNgbDates(builtDate, renovationDate) > 0) {
        yearControl.setErrors({
          beforeYearBuilt: builtDate.year,
        });
      } else {
        yearControl.setErrors(
          this.removeError(yearControl.errors, 'beforeYearBuilt')
        );
      }
    }
  }

  removeError(errors: any, name: string): any {
    if (!errors) {
      return null;
    }
    const newErrors = { ...errors };
    delete newErrors[name];
    if (Object.keys(newErrors).length < 1) {
      return null;
    }
    return newErrors;
  }

  validateYearPurchasedFormat(control: AbstractControl): any {
    const value = control.value;
    const TWO_PLUS_SEPARATOR_PLUS_FOUR = 7;
    if (!value || value.length < TWO_PLUS_SEPARATOR_PLUS_FOUR) {
      return { required: true };
    }
    const date = DateUtils.ngbDateFromMmYyyy(value);
    if (!date) {
      return { dateFormatMmYyyy: true };
    }
    const MONTHS_PER_YEAR = 12;
    if (date.month < 1 || date.month > MONTHS_PER_YEAR) {
      return { dateFormatMmYyyy: true };
    }
    return null;
  }

  validateEstimatedYearPurchased(control: AbstractControl) {
    if (!control.value || control.value === '') {
      return null;
    }
    const purchaseDateComponents = control.value.toString().split(/\//);
    if (purchaseDateComponents.length !== 2) {
      return { required: true };
    }
    const purchaseMonth = parseInt(purchaseDateComponents[0], 10);
    const purchaseYear = parseInt(purchaseDateComponents[1], 10);
    const LOWEST_FOUR_DIGIT_NUMBER = 1000;
    if (isNaN(purchaseYear) || purchaseYear < LOWEST_FOUR_DIGIT_NUMBER) {
      return { required: true };
    }
    const numFutureDays = 0;
    const futureDate = this.addDays(numFutureDays);
    if (this.checkFutureDate(futureDate, purchaseYear, purchaseMonth)) {
      return { afterFutureDate: true };
    }
    return null;
  }

  checkFutureDate(
    futureDate: Date,
    purchaseYear: number,
    purchaseMonth: number
  ): boolean {
    const addedmonths = 3;
    const futureMonth = futureDate.getMonth() + addedmonths;
    const newDate = new Date(futureDate.setMonth(futureMonth));
    if (
      purchaseYear > newDate.getFullYear() ||
      (purchaseYear === newDate.getFullYear() &&
        purchaseMonth > newDate.getMonth())
    ) {
      return true;
    } else {
      return false;
    }
  }

  addDays(days): Date {
    const result = new Date();
    result.setDate(result.getDate() + days);
    return result;
  }

  validateAfterYearBuilt(field: AbstractControl, yearBuilt: number): any {
    if (!field.value) {
      return null;
    }
    const inputYear = parseInt(field.value, 10);
    if (isNaN(inputYear)) {
      return { invalid: true };
    }
    if (inputYear < yearBuilt) {
      return { beforeYearBuilt: yearBuilt };
    }
    return null;
  }

  sanitizeCoveredLocationRequest(coveredLocation: any): any {
    if (coveredLocation.datePurchased) {
      const mmyyyy = coveredLocation.datePurchased.split('/');
      if (mmyyyy.length === 2) {
        coveredLocation.datePurchased = `${mmyyyy[1]}-${mmyyyy[0]}-01`;
      }
    }
    if (coveredLocation.constructionInfo) {
      const ci = {
        ...coveredLocation.constructionInfo,
      };
      coveredLocation.constructionInfo = ci;
      if (!ci.additionalHeating) {
        delete ci.additionalHeating;
      }
      // Half-bathroom count can not be zero, but it can be null.
      if (!+ci.numberOfHalfBathrooms) {
        ci.halfBathroomDescription = null;
        ci.numberOfHalfBathrooms = null;
      }
      // exteriorWalls can be null but not empty.
      if (!ci.exteriorWalls) {
        delete ci.exteriorWalls;
      }
      // More constructionInfo fields that we must not send...
      delete ci.wallHeights;
    }

    if (coveredLocation && !coveredLocation.hasOwnProperty('deededOwner')) {
      coveredLocation.deededOwner = true;
    }

    if (coveredLocation.oilTankForm) {
      coveredLocation.hasOilTank = coveredLocation.oilTankForm.hasOilTank.value;
      if (coveredLocation.hasOilTank) {
        coveredLocation.oilTank = {
          yearTankInstalled:
            coveredLocation.oilTankForm.yearTankInstalled.value,
          tankMaterial: coveredLocation.oilTankForm.tankMaterial.value,
          tankLocation: coveredLocation.oilTankForm.tankLocation.value,
          isClosureFillCertified:
            coveredLocation.oilTankForm.isClosureFillCertified.value,
        };
      } else {
        delete coveredLocation.oilTank;
      }
    } else {
      coveredLocation.hasOilTank = false;
      delete coveredLocation.oilTank;
    }
    if (
      coveredLocation.constructionInfo &&
      coveredLocation.constructionInfo.yearBuilt
    ) {
      const yearBuiltValue = +coveredLocation.constructionInfo.yearBuilt;
      const today = new Date();
      const currentYear = today.getFullYear();
      const yearDifference = currentYear - yearBuiltValue;
      if (yearDifference < 70) {
        coveredLocation.historicDistrict = false;
      } else {
        coveredLocation.historicDistrict = true; // Property is 70 years old or older
      }
    }
    delete coveredLocation.riskItems;
    delete coveredLocation.seasonal;
    delete coveredLocation.renovations;
    delete coveredLocation.weeksRented;
    delete coveredLocation.constructionInfoForm;
    delete coveredLocation.oilTankForm;
    delete coveredLocation.renovationsForm;
    delete coveredLocation.warningMessages;
    return coveredLocation;
  }

  // TODO: If Dsm fixes this issue, remove this and all traces of it.
  convertProtectiveDeviceSelectedValueBackToCorrectFormat(
    device: ProtectiveDevice,
    badFormatSelectedValue: string
  ): string {
    if (badFormatSelectedValue === 'None') {
      return 'None';
    }
    let returnSelectedValue = '';
    device.selectedValues.forEach(selectedValue => {
      if (
        this.replaceSelectedValues(device.type, selectedValue) ===
        badFormatSelectedValue
      ) {
        returnSelectedValue = selectedValue;
      }
    });

    return returnSelectedValue;
  }

  estimatePurchaseDate(value: string): Date {
    const purchaseNgbDate = DateUtils.ngbDateFromMmYyyy(value);
    return new Date(
      purchaseNgbDate.year,
      purchaseNgbDate.month - 1,
      purchaseNgbDate.day
    );
  }

  estimateFutureDate(value: string): Date {
    const futureDays = 60;
    return DateUtils.getFutureDate(value, futureDays);
  }

  validateDateFormat(value: string): boolean {
    const dateRegex = /^(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
    return !dateRegex.test(value);
  }

  validateMaxEstimatedYearPurchased(field, effectiveDate): any {
    if (!field.value || field.value === '') {
      return null;
    }
    if (this.validateDateFormat(field.value)) {
      return { dateFormatMmYyyy: true };
    }
    const futureDate = this.estimateFutureDate(effectiveDate);
    const purchaseDate = this.estimatePurchaseDate(field.value);
    if (purchaseDate > futureDate) {
      return { afterFutureDate: true };
    }
    return null;
  }
  convertHomePurchaseDateFromResponse(input: string): string {
    if (!input) {
      return null;
    }
    const components = input.split('-');
    // eslint-disable-next-line no-magic-numbers
    if (components.length !== 3) {
      return input;
    }
    return `${components[1]}/${components[0]}`;
  }
}
