import {
  UntypedFormControl,
  UntypedFormGroup,
  AbstractControl,
  Validators,
} from '@angular/forms';
import {
  StandardizedAddress,
  StandardizedAddresses,
} from '@core/models/address/standardized-address.model';
import { Product } from '@core/models/products/product.model';
import { ProductTypes } from '@shared/constants/app-constants';
import { DateUtils } from '@shared/utils/date.utils';

const DAYS_PER_WEEK = 7;

export class CustomValidators {
  static HomeownersRequired(isHomeowners: boolean) {
    return isHomeowners ? { required: true } : null;
  }

  static PicklistRequired(control: UntypedFormControl) {
    if (!CustomValidators.hasRequiredField(control)) {
      return null;
    }

    return control.value !== null &&
      control.value !== undefined &&
      control.value !== '' &&
      control.value !== 'Please Select' &&
      control.value !== 'Month' &&
      control.value !== 'Year' &&
      control.value !== 'Day'
      ? null
      : { picklistRequired: true };
  }

  static ValidNumOfDaysPerWeek(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const daysPerWeek = control.value;
    if (daysPerWeek < 1 || daysPerWeek > DAYS_PER_WEEK) {
      return { invalidDays: true };
    } else {
      return null;
    }
  }

  static specialRequired(key: string): (control: UntypedFormControl) => any {
    return (control: UntypedFormControl) => {
      if (!control.value) {
        return { [key]: true };
      }
      if (typeof control.value === 'object' && !(<any>control.value).length) {
        return { [key]: true };
      }
      return null;
    };
  }

  static ValidNumOfMilesPerWeek(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const milesOneWay = control.value;
    if (milesOneWay < 1) {
      return { invalidMiles: true };
    } else {
      return null;
    }
  }

  static ValidAnnualMiles(control: UntypedFormControl) {
    const annualMiiles = control.value;
    if (annualMiiles < 1) {
      return { invalidAnnualMiles: true };
    } else {
      return null;
    }
  }

  static hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (abstractControl['controls'][controlName]) {
          if (
            CustomValidators.hasRequiredField(
              abstractControl['controls'][controlName]
            )
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  static yearUpToNow(control: UntypedFormControl) {
    if (control.value) {
      const now = new Date();
      const currentYear = now.getFullYear();
      const input = +control.value;
      const LOWEST_FOUR_DIGIT_NUMBER = 1000;
      if (input < LOWEST_FOUR_DIGIT_NUMBER) {
        return { required: true };
      }
      if (input > currentYear) {
        return { thisYearOrEarlier: true };
      }
    }
    return null;
  }

  static DateFormat(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp(/^\d{2}\/\d{2}\/\d{4}$/);
    return dateRegEx.test(control.value) ? null : { dateFormat: true };
  }

  static ngbDateFormat(control: UntypedFormControl) {
    if (!control) {
      return null;
    }
    const ngbDate = control.value;
    const LOG10_OF_CREDIBLE_YEAR = 4;
    if (
      ngbDate &&
      ngbDate.month &&
      ngbDate.day &&
      ngbDate.year &&
      ngbDate.year.toString().length === LOG10_OF_CREDIBLE_YEAR
    ) {
      const month = DateUtils.padZero(ngbDate.month.toString());
      const day = DateUtils.padZero(ngbDate.day.toString());
      const year = ngbDate.year;
      const date = `${month}/${day}/${year}`;
      if (
        !CustomValidators._splitDateMakesSense(
          year,
          parseInt(month, 10),
          parseInt(day, 10)
        )
      ) {
        return { invalid: true };
      }

      if (
        CustomValidators.isFutureYearMonthDay(
          year,
          parseInt(month, 10),
          parseInt(day, 10)
        )
      ) {
        return { futureDate: true };
      }

      const dateRegEx = new RegExp(/^\d{2}\/\d{2}\/\d{4}$/);
      return dateRegEx.test(date) ? null : { dateFormat: true };
    } else {
      return { dateFormat: true };
    }
  }

  static DateFormatOrMasked(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp(
      /^(\d{2}\/?\d{2}\/?\d{4}|\*\*\/?\*\*\/?\*\*\*\*)$/
    );
    return dateRegEx.test(control.value) ? null : { dateFormat: true };
  }

  static PhoneNumberFormat(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const digits = control.value.replace(/[^0-9]/g, '');
    if (digits.length < 10) {
      return { required: true };
    }
    const dateRegEx = new RegExp(/^\d{3}\-\d{3}\-\d{4}$/);
    return dateRegEx.test(control.value) ? null : { phoneNumberFormat: true };
  }

  static MaskablePhoneNumberFormat(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const digits = control.value.replace(/[^0-9*]/g, '');
    if (digits.length < 10) {
      return { phoneNumberRequired: true };
    }
    const dateRegEx = new RegExp(/^[\d*]{3}\-[\d*]{3}\-[\d*]{4}$/);
    return dateRegEx.test(control.value) ? null : { phoneNumberFormat: true };
  }

  /* Use Angular's email validator, but return "required" if it looks short.
   */
  static email(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    if (!control.value.match(/^.+@.+\..{2,}$/)) {
      return { emailIncomplete: true };
    }
    control = <UntypedFormControl>{
      value: control.value.replace(/\*/g, 'a').trim(),
    };

    // Enforce the more-restrictive regex that DSM uses:
    if (
      !control.value.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)
    ) {
      return { email: true };
    }

    return Validators.email(control);
  }

  static DateOfBirthValid(control: UntypedFormControl) {
    const yearTooOld = 1900;
    const dob = control.value;
    if (
      !dob ||
      dob === '**/**/****' ||
      (dob.indexOf('*') > -1 && dob.length === 10)
    ) {
      return;
    }

    /* eslint-disable no-magic-numbers */
    const components = dob.match(/(\d{2})\/?(\d{2})\/?(\d{4})/);
    if (!components || components.length !== 4) {
      return { dateOfBirthInvalid: true };
    }
    const month = +components[1];
    const day = +components[2];
    const year = +components[3];
    /* eslint-enable no-magic-numbers */

    const sixteenYearsInMilliseconds = 504910816000;
    const currentDateInMilliseconds = new Date().getTime();
    const dobInMilliseconds = new Date(year, month - 1, day).getTime();
    const difference = currentDateInMilliseconds - dobInMilliseconds;

    if (!CustomValidators._splitDateMakesSense(year, month, day)) {
      return { dateOfBirthInvalid: true };
    }

    if (year < yearTooOld) {
      return { dateOfBirthTooOld: true };
    }

    if (difference < 0) {
      return { dateOfBirthFuture: true };
    }

    if (difference < sixteenYearsInMilliseconds) {
      return { dateOfBirthTooYoung: true };
    }
    return null;
  }
  static AgeFirstLicensedIsTooYoung(control: UntypedFormControl) {
    const ageFirstLicensed = control.value;
    const ageLimit = 16;
    if (ageFirstLicensed < ageLimit) {
      return { TooYoung: true };
    } else {
      return null;
    }
  }

  static PetDateOfBirthValid(control: UntypedFormControl) {
    const yearTooOld = 1900;
    const dob = control.value;
    if (!dob) {
      return;
    }

    const split = dob.split('/');
    const month = +split[0];
    const day = +split[1];
    const year = +split[2];
    const dobInMilliseconds = new Date(dob).getTime();
    const currentDateInMilliseconds = new Date().getTime();

    if (!CustomValidators._splitDateMakesSense(year, month, day)) {
      return { dateOfBirthInvalid: true };
    }

    if (currentDateInMilliseconds - dobInMilliseconds < 0) {
      return { dateOfBirthTooYoung: true };
    }

    if (year < yearTooOld) {
      return { dateOfBirthTooOld: true };
    }

    return null;
  }

  static DateMmddyyyyPastOnly(control: UntypedFormControl): any {
    const value = control.value;
    if (!value) {
      return { required: true };
    }

    const COUNT_OF_THINGS_IN_A_THREE_THING_THING = 3;
    const LOG10_10000 = 4;
    const split = value.split('/');
    if (split.length < COUNT_OF_THINGS_IN_A_THREE_THING_THING) {
      return { required: true };
    }
    const month = +split[0];
    const day = +split[1];
    const year = +split[2];
    if (year < LOG10_10000) {
      return { required: true };
    }

    const now = new Date();
    const currentYear = now.getFullYear();
    if (year > currentYear) {
      return { dateOfBirthTooYoung: true };
    } else if (year === currentYear) {
      const currentMonth = now.getMonth() + 1;
      if (month > currentMonth) {
        return { dateOfBirthTooYoung: true };
      } else if (month === currentMonth) {
        const currentDay = now.getDate();
        if (day > currentDay) {
          return { dateOfBirthTooYoung: true };
        }
      }
    }

    if (!CustomValidators._splitDateMakesSense(year, month, day)) {
      return { dateOfBirthInvalid: true };
    }

    return null;
  }

  static StringOnly(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp('^[a-zA-Z-\\s]+$');
    return dateRegEx.test(control.value) ? null : { stringFormat: true };
  }

  static SpecialCharacterStringOnly(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const illegalMatch = control.value.match(/[^a-zA-Z'\s-]/);
    if (!illegalMatch) {
      return null;
    }
    return { illegalCharacter: illegalMatch[0] };
  }

  static NumericCommaOnly(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp('^[1-9]+[0-9,]*$');
    return dateRegEx.test(control.value) ? null : { stringFormat: true };
  }

  static ZipCode(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const dateRegEx = new RegExp('^[0-9-]+$');
    return dateRegEx.test(control.value) ? null : { zipFormat: true };
  }

  static dsmCompanyName(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    if (!control.value.match(/^[a-zA-Z0-9][a-zA-Z0-9 '\-&/.,]*$/)) {
      const illegalCharacter = control.value.match(/[^a-zA-Z0-9 '\-&/.,]/);
      if (illegalCharacter) {
        return {
          illegalCharacter: illegalCharacter[0],
        };
      }
      return { invalid: true };
    }
    return null;
  }

  /* eslint-disable no-magic-numbers */
  private static _splitDateMakesSense(
    year: number,
    month: number,
    day: number
  ): boolean {
    if (day < 1 || month < 1 || year < 1) {
      return false;
    }
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return false;
    }

    if (day > 31) {
      return false;
    }

    if (month > 12) {
      return false;
    }

    if (CustomValidators._is30DayMonth(month)) {
      if (day > 30) {
        return false;
      }
    }

    if (CustomValidators._isFebruary(month)) {
      const monthLength = CustomValidators._isLeapYear(year) ? 29 : 28;
      if (day > monthLength) {
        return false;
      }
    }

    return true;
  }
  /* eslint-disable no-magic-numbers */

  /* eslint-disable no-magic-numbers */
  private static _is30DayMonth(month): boolean {
    const months = [4, 6, 9, 11];
    return months.includes(month);
  }
  /* eslint-enable no-magic-numbers */

  private static _isFebruary(month): boolean {
    return month === 2;
  }

  private static _isLeapYear(year): boolean {
    const every4Years = 4;
    const every100Years = 100;
    const every400Years = 400;
    if (
      +year % every4Years === 0 &&
      (+year % every100Years !== 0 || +year % every400Years === 0)
    ) {
      return true;
    }

    return false;
  }

  static normalizeVin(input: string): string {
    if (!input) {
      return '';
    }
    return input.toUpperCase().trim().replace(/I/g, '1').replace(/O/g, '0');
  }

  /* eslint-disable no-magic-numbers */
  static ValidateVin(control: UntypedFormControl) {
    // https://www.federalregister.gov/documents/2008/04/30/08-1197/vehicle-identification-number-requirements

    const vin = CustomValidators.normalizeVin(control.value);
    if (!vin) {
      return null;
    }
    if (vin.indexOf('*') >= 0) {
      return null;
    }
    if (vin.length < 17) {
      return { required: true };
    }
    if (vin.length > 17) {
      return { invalid: true };
    }

    const numericVin = Array.from(vin).map((ch: string) =>
      CustomValidators.vinCharacterValue(ch)
    );
    if (numericVin.indexOf(undefined) >= 0) {
      return { invalid: true };
    }

    const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
    for (let i = 0; i < numericVin.length; i++) {
      numericVin[i] *= weights[i];
    }

    const sum = numericVin.reduce((a, v) => a + v, 0);
    const remainder = sum % 11;
    const checkDigit = remainder < 10 ? remainder.toString() : 'X';

    if (vin[8] !== checkDigit) {
      return { invalid: true };
    }
    return null;
  }
  /* eslint-enable no-magic-numbers */

  static vinCharacterValue(character: string): number {
    if (character >= '0' && character <= '9') {
      return +character;
    }
    return {
      A: 1,
      B: 2,
      C: 3,
      D: 4,
      E: 5,
      F: 6,
      G: 7,
      H: 8,
      J: 1,
      K: 2,
      L: 3,
      M: 4,
      N: 5,
      P: 7,
      R: 9,
      S: 2,
      T: 3,
      U: 4,
      V: 5,
      W: 6,
      X: 7,
      Y: 8,
      Z: 9,
    }[character];
  }

  static ValidateLuhn(control: UntypedFormControl) {
    const cardNumber = control.value;
    let sum = 0;
    if (!cardNumber) {
      return null;
    }
    const numdigits = cardNumber.length;
    const minimumLength = 15;
    if (numdigits < minimumLength) {
      return { required: true };
    }
    const parity = numdigits % 2;
    for (let i = 0; i < numdigits; i++) {
      let digit = parseInt(cardNumber.charAt(i), 10);
      if (i % 2 === parity) {
        digit *= 2;
      }
      const luhnDigit9 = 9;
      if (digit > luhnDigit9) {
        digit -= luhnDigit9;
      }
      sum += digit;
    }
    return sum % 10 === 0 ? null : { invalid: true };
  }

  static ValidateCreditCardType(value: string) {
    let cardType = null;
    if (value !== null && value !== '') {
      if (value.substring(0, 1) === '4') {
        cardType = 'VISA';
      } else if (
        value.substring(0, 1) === '5' ||
        value.substring(0, 1) === '2'
      ) {
        cardType = 'MAST';
      } else if (value.substring(0, 1) === '6') {
        cardType = 'DISC';
      } else if (value.substring(0, 1) === '3') {
        cardType = 'AMEX';
      }
    }

    return cardType;
  }

  static ValidateCreditCardNumber(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const cardNumber = control.value.toString();
    // validating the card if it is valid payment type present in admin table picklist
    const ctype = CustomValidators.ValidateCreditCardType(cardNumber);
    if (ctype == null) {
      return { invalid: true };
    }
    const cardMaxLength = 16,
      cardMinLength = 15;
    if (cardNumber.length < cardMinLength) {
      return { required: true };
    }
    if (
      cardNumber.length !== cardMaxLength &&
      (cardNumber.substring(0, 1) === '4' ||
        cardNumber.substring(0, 1) === '5' ||
        cardNumber.substring(0, 1) === '6')
    ) {
      return { invalid: true };
    }

    if (
      cardNumber.length !== cardMinLength &&
      cardNumber.substring(0, 1) === '3'
    ) {
      return { invalid: true };
    }
  }

  static validateCVV(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const cvvLength = 3;
    if (control.value.length < cvvLength) {
      return { required: true };
    }
    return control.value.length === cvvLength ? null : { invalid: true };
  }

  static validateCID(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const cvvLength = 4;
    return control.value.length === cvvLength ? null : { invalid: true };
  }

  static validateAssociateNumber(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const isNumericOnly = control.value.match(/^[0-9]*$/);
    const associateNumberLength = 6;
    return control.value.length === associateNumberLength && isNumericOnly
      ? null
      : { invalidAssociateNumber: true };
  }

  static VerifyBankAccountInputMatch(formControl: UntypedFormControl) {
    if (!formControl.parent) {
      return null;
    }
    const a = formControl.parent.get('accountNumber');
    const b = formControl.parent.get('verifyAccountNumber');
    if (!a || !b) {
      return null;
    }
    if (!a.value || !b.value) {
      a.setErrors(null);
      b.setErrors(null);
      return null;
    }
    if (a.value === b.value) {
      a.setErrors(null);
      b.setErrors(null);
      return null;
    }
    // If one is a prefix of the other, call it "required" rather than "mustMatch".
    if (a.value.startsWith(b.value) || b.value.startsWith(a.value)) {
      return { required: true };
    }
    return { mustMatch: true };
  }

  static ValidateRoutingNumberLength(control: UntypedFormControl) {
    const nineDigitRegEx = new RegExp('^[0-9]{9}$');
    return nineDigitRegEx.test(control.value) ? null : { invalid: true };
  }

  static ValidateZipCode(control: UntypedFormControl) {
    const SHORT_ZIP_LENGTH = 5;
    if (!control.value || control.value.length < SHORT_ZIP_LENGTH) {
      return { required: true };
    }
    if (control.value.match(/^\d{5}(-\d{4})?$/)) {
      return null;
    } else if (control.value.match(/^\d{5}-\d{0,3}$/)) {
      return { required: true };
    } else if (control.value.match(/^\d{0,5}$/)) {
      return { required: true };
    } else {
      return { invalid: true };
    }
  }

  static MarkFormGroupTouched(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = <any>formGroup.controls[key];
      control.markAsTouched();
      if (control.controls) {
        CustomValidators.MarkFormGroupTouched(control);
      }
    });
  }

  static IsUmbrellaValid(control: UntypedFormControl) {
    const products: Product[] = control.value;

    const umbrella = products.find(
      product => product.id === ProductTypes.UMBRELLA
    );

    if (umbrella) {
      const ids = products.map(product => product.id);
      const vehiclePolicy = ids.includes(ProductTypes.AUTO);
      const propertyPolicy =
        ids.includes(ProductTypes.CONDO) ||
        ids.includes(ProductTypes.RENTERS) ||
        ids.includes(ProductTypes.HOMEOWNERS);
      if (!vehiclePolicy || !propertyPolicy) {
        return { umbrella: true };
      }
    }
  }

  static isValidYearMonth(year: number, month: number) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    if (year < currentYear) {
      return false;
    }
    if (year === currentYear && month < currentMonth) {
      return false;
    }
    return true;
  }

  static isFutureYearMonthDay(year: number, month: number, day: number) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const currentDay = new Date().getDate();
    if (year < currentYear) {
      return false;
    }
    if (year === currentYear && month < currentMonth) {
      return false;
    }
    if (year === currentYear && month === currentMonth && day <= currentDay) {
      return false;
    }
    return true;
  }

  static ValidateAddress(addresses: StandardizedAddresses): boolean {
    if (!addresses) {
      return true;
    }

    if (!addresses.standardizedAddresses) {
      return true;
    }

    if (addresses.standardizedAddresses.length < 1) {
      return true;
    }

    if (addresses.standardizedAddresses.length > 1) {
      return true;
    }

    return this.ValidateSingleAddress(addresses.standardizedAddresses[0]);
  }

  static ValidateSingleAddress(address: StandardizedAddress): boolean {
    if (!address.city) {
      return true;
    }

    if (!address.state) {
      return true;
    }

    if (!address.zipCode) {
      return true;
    }

    return false;
  }

  static validateMultiplePropertyTypesNotChecked(control: UntypedFormControl) {
    const products = control.value;
    const homeOwnerProduct = products.find(
      product => product.name === 'Homeowners'
    );
    const rentersProduct = products.find(product => product.name === 'Renters');
    if (homeOwnerProduct && rentersProduct) {
      return false;
    } else {
      return true;
    }
  }

  static validateAgeLicensedAgainstDateOfBirth(control: AbstractControl): any {
    const controls = CustomValidators._findAgeLicensedAndDateOfBirth(control);
    if (!controls) {
      return null;
    }
    const ageLicensed = CustomValidators._parseAge(
      controls.ageFirstLicensed.value
    );
    const age = CustomValidators._calculateAgeFromDateOfBirth(
      controls.dateOfBirth.value
    );
    if (isNaN(ageLicensed) || isNaN(age)) {
      return null;
    }
    if (ageLicensed > age) {
      return { greaterThanAge: age.toString() };
    }
    return null;
  }

  static validateYearsOfExperienceAgainstAgeLicensed(
    control: AbstractControl
  ): any {
    const controls =
      CustomValidators._findDateOfBirthAgeLicensedAndYearsOfExperience(control);
    if (!controls) {
      return null;
    }
    const ageLicensed = CustomValidators._parseAge(
      controls.ageFirstLicensed.value
    );
    const age = CustomValidators._calculateAgeFromDateOfBirth(
      controls.dateOfBirth.value
    );
    const yearsExperience = CustomValidators._parseYearsExp(
      controls.yearsOfMotorcycleAndOffRoadExp.value
    );

    if (isNaN(ageLicensed) || isNaN(age) || isNaN(yearsExperience)) {
      return null;
    }
    if (yearsExperience > age - ageLicensed) {
      return { greaterThanAgeFirstLicense: age.toString() };
    }
    return null;
  }

  private static _findAgeLicensedAndDateOfBirth(control: AbstractControl): any {
    while (control) {
      const ageFirstLicensed = control.get('ageFirstLicensed');
      const dateOfBirth = control.get('dateOfBirth');
      if (ageFirstLicensed && dateOfBirth) {
        return { ageFirstLicensed, dateOfBirth };
      }
      control = control.parent;
    }
    return null;
  }

  private static _findDateOfBirthAgeLicensedAndYearsOfExperience(
    control: AbstractControl
  ): any {
    while (control) {
      const ageFirstLicensed = control.get('ageFirstLicensed');
      const yearsOfMotorcycleAndOffRoadExp = control.get(
        'yearsOfMotorcycleAndOffRoadExp'
      );
      const dateOfBirth = control.get('dateOfBirth');
      if (ageFirstLicensed && yearsOfMotorcycleAndOffRoadExp && dateOfBirth) {
        return {
          ageFirstLicensed,
          yearsOfMotorcycleAndOffRoadExp,
          dateOfBirth,
        };
      }
      control = control.parent;
    }
    return null;
  }

  private static _parseAge(input: string): number {
    return parseInt(input, 10);
  }

  private static _parseYearsExp(input: string): number {
    return parseInt(input, 10);
  }

  // Input can be 'MM/DD/YYYY' or '**/**/YYYY', we must handle both.
  private static _calculateAgeFromDateOfBirth(input: string): number {
    if (!input) {
      return NaN;
    }
    const components = input.split('/');
    const countOfComponentsInDate = 3;
    if (components.length !== countOfComponentsInDate) {
      return NaN;
    }
    const year = parseInt(components[2], 10);
    if (isNaN(year)) {
      return NaN;
    }
    const month = parseInt(components[0], 10) || 1;
    const day = parseInt(components[1], 10) || 1;
    const dob = new Date(year, month - 1, day);
    const now = CustomValidators.getCurrentDate();
    const ageInMilliseconds = now.getTime() - dob.getTime();
    const millisecondsPerYear = 31556736000; // based on 365.24-day year
    return Math.floor(ageInMilliseconds / millisecondsPerYear);
  }

  static getCurrentDate() {
    return new Date();
  }
}
