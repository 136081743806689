<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Occupations</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <ul>
    <li *ngFor="let occupation of occupations">
      {{ occupation }}
    </li>
  </ul>
</div>
