import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';

import { CRUDService } from '@core/services/crud.service';

import { HouseholdMemberEntity } from '@core/models/entities/household-member.entity';

@Injectable({
  providedIn: 'root',
})
export class HouseholdMemberDao implements CRUDService<HouseholdMemberEntity> {
  constructor(private _store: Store<fromStore.AppState>) {}

  add(entity: HouseholdMemberEntity): void {
    this._store.dispatch(new fromActions.AddHouseholdMember(entity));
  }

  addAll?(entity: HouseholdMemberEntity[]): void {
    throw new Error('Method not implemented.');
  }

  delete(entityId: string): void {
    this._store.dispatch(new fromActions.DeleteHouseholdMember(entityId));
  }

  update(entity: HouseholdMemberEntity): void {
    this._store.dispatch(new fromActions.UpdateHouseholdMember(entity));
  }

  get?(entityId: string): Observable<HouseholdMemberEntity> {
    return this._store.select(fromSelectors.getHouseholdMember(entityId));
  }

  getAll?(): Observable<HouseholdMemberEntity[]> {
    return this._store.select(fromSelectors.selectAllHouseholdMembers);
  }
}
