import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, tap, switchMap, take, filter } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { LoggingService, ProductsService } from '@core/services';
import { LocationExposureService } from '@core/services/location-exposure.service';
import { Update } from '@ngrx/entity';
import { LocationExposureEntity } from '@core/models/entities/location-exposure.entity';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable()
export class LocationExposureEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _locationExposureService: LocationExposureService,
    private _productsService: ProductsService
  ) {}

  @Effect()
  addLocationExposure$ = this._actions$.pipe(
    ofType(fromActions.LocationExposureActionTypes.ADD_LOCATION_EXPOSURE),
    map((actions: fromActions.AddLocationExposure) => actions.payload),
    switchMap(location => {
      return this._store.select(fromSelectors.getSelectedProducts).pipe(
        take(1),
        switchMap(products => of({ location, products }))
      );
    }),
    filter(products => {
      // this._productsService.isEligibleForUmbrella(products.products)
      const umbrella = products.products.find(
        product => product.id === ProductTypes.UMBRELLA
      );
      return !!umbrella;
    }),
    switchMap(location => {
      return this._store
        .select(fromSelectors.buildLocationExposureRequest(location.location))
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('addLocationExposure Request', request)
    ),
    switchMap(request =>
      this._locationExposureService.addLocationExposure(request).pipe(
        tap(response =>
          this._loggingService.log('addLocationExposure Response', response)
        ),
        map(response => {
          return new fromActions.AddLocationExposureSuccess(response);
        }),
        catchError(error => {
          this._loggingService.log('addLocationExposure error', error);
          return of(new fromActions.AddLocationExposureFail(error));
        })
      )
    )
  );

  @Effect()
  updateLocationExposure$ = this._actions$.pipe(
    ofType(fromActions.LocationExposureActionTypes.UPDATE_LOCATION_EXPOSURE),
    map((actions: fromActions.UpdateLocationExposure) => actions.payload),
    switchMap(location => {
      return this._store.select(fromSelectors.getSelectedProducts).pipe(
        take(1),
        switchMap(products => of({ location, products }))
      );
    }),
    filter(products =>
      this._productsService.isEligibleForUmbrella(products.products)
    ),
    switchMap(location => {
      return this._store
        .select(fromSelectors.buildLocationExposureRequest(location.location))
        .pipe(take(1));
    }),
    tap(request =>
      this._loggingService.log('updateLocationExposure Request', request)
    ),
    switchMap(request =>
      this._locationExposureService.updateLocationExposure(request).pipe(
        tap(response =>
          this._loggingService.log('updateLocationExposure Response', response)
        ),
        map(response => {
          const updated: Update<LocationExposureEntity> = {
            id: response.locationExposureId,
            changes: response,
          };
          return new fromActions.UpdateLocationExposureSuccess(updated);
        }),
        catchError(error => {
          this._loggingService.log('updateLocationExposure error', error);
          return of(new fromActions.UpdateLocationExposureFail(error));
        })
      )
    )
  );
}
