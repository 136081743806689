import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { Product } from '@core/models/products/product.model';
import { Update } from '@ngrx/entity';
import { ProductStatus } from '@core/models/products/product-status';

@Injectable()
export class ProductsDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  updateSelectedProducts(products: Product[]): void {
    this._store.dispatch(new fromActions.UpdateSelectedProducts(products));
  }

  loadProducts(): void {
    this._store.dispatch(new fromActions.LoadProducts());
  }

  getProducts(): Observable<Product[]> {
    return this._store.select(fromSelectors.getAvailableProducts);
  }

  getProductsLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getProductsLoaded);
  }

  getLifeQuoteId(): Observable<string> {
    return this._store.select(fromSelectors.getLifeQuoteId);
  }

  getProductsLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getProductsLoading);
  }

  getAddonProducts(): Observable<Product[]> {
    return this._store.select(fromSelectors.getAddonProducts);
  }

  getSelectedProducts(): Observable<Product[]> {
    return this._store.select(fromSelectors.getSelectedProducts);
  }

  getSelectedProductsForHeader(): Observable<Product[]> {
    return this._store.select(fromSelectors.getSelectedProductsForHeader);
  }

  getSelectedProductsForDirectHeader(): Observable<Product[]> {
    return this._store.select(fromSelectors.getSelectedProductsForDirectHeader);
  }

  getSelectedCoverageProducts(): Observable<Product[]> {
    return this._store.select(fromSelectors.getSelectedCoverageProducts);
  }

  isProductSelectedWithoutError(productType: string): Observable<boolean> {
    return this._store.select(
      fromSelectors.isProductSelectedWithoutError(productType)
    );
  }

  isProductsSelectedWithoutError(productTypes: string[]): Observable<boolean> {
    return this._store.select(
      fromSelectors.isProductsSelectedWithoutError(productTypes)
    );
  }

  isProductSelected(name: string): Observable<boolean> {
    return this._store.select(fromSelectors.isProductSelected(name));
  }

  getNonRentalPropertyProductSelectedWithoutError(): Observable<string> {
    return this._store.select(
      fromSelectors.getNonRentalPropertyProductSelectedWithoutError()
    );
  }

  getPropertyProductSelectedWithoutError(): Observable<string> {
    return this._store.select(
      fromSelectors.getPropertyProductSelectedWithoutError()
    );
  }
  isAnyPropertyProductSelected(): Observable<boolean> {
    return this._store.select(fromSelectors.isAnyPropertyProductSelected());
  }

  getVehicleProducts(): Observable<Product[]> {
    return this._store.select(fromSelectors.getVehicleProducts);
  }

  getProduct(id: string): Observable<Product> {
    return this._store.select(fromSelectors.getProduct(id));
  }

  updateProduct(entity: Product): void {
    const updated: Update<Product> = {
      changes: entity,
      id: entity.id,
    };
    this._store.dispatch(new fromActions.UpdateProduct(updated));
  }

  getAutoTokenLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getAutoAccessTokenLoading);
  }

  getPowersportsTokenLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getPowersportsAccessTokenLoading);
  }

  getAutoTokenLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getAutoAccessTokenLoaded);
  }

  getVehicleAccessTokenLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getVehicleAccessTokenLoaded);
  }

  getPowersportsTokenLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getPowersportsAccessTokenLoaded);
  }

  generateAccessToken(productId: string): void {
    this._store.dispatch(new fromActions.GenerateAccessToken(productId));
  }

  getQuoteLoading(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteLoading(productId));
  }

  getQuoteLoaded(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteLoaded(productId));
  }

  getAnyQuoteLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getAnyQuoteLoaded);
  }

  getSelectedProductsIds(): Observable<string[]> {
    return this._store.select(fromSelectors.getSelectedProductsIds);
  }

  getQuoteRated(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteRated(productId));
  }

  getQuoteCompleted(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteCompleted(productId));
  }

  getQuoteCoverageLoading(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteCoverageLoading(productId));
  }

  getQuoteRating(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteRating(productId));
  }

  getQuoteUpdateLoading(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteUpdateLoading(productId));
  }

  isQuoteLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.isQuoteCompleted);
  }

  isBindLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.isBindCompleted);
  }

  isIssueLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.isIssueCompleted);
  }

  isIssueLoadedOrFailed(): Observable<boolean> {
    return this._store.select(fromSelectors.isIssueLoadedOrFailed);
  }

  getQuoteCoverageLoaded(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteCoverageLoaded(productId));
  }

  getAllQuoteCoverageLoaded(productId: string): Observable<boolean> {
    return this._store.select(
      fromSelectors.getAllQuoteCoverageLoaded(productId)
    );
  }

  getQuoteBound(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteBound(productId));
  }

  getBindCompleted(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getBindCompleted(productId));
  }

  getQuoteBinding(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getQuoteBinding(productId));
  }

  getSelectedQuoteProducts(): Observable<Product[]> {
    return this._store.select(fromSelectors.getSelectedQuoteProducts);
  }

  getSelectedQuoteProductsWithoutErrors(): Observable<Product[]> {
    return this._store.select(
      fromSelectors.getSelectedQuoteProductsWithoutErrors
    );
  }

  getSelectedProductsWithoutErrors(): Observable<Product[]> {
    return this._store.select(fromSelectors.getSelectedProductsWithoutErrors);
  }

  // DSM
  isDsmProductBindSelected(id: string): Observable<boolean> {
    return this._store.select(fromSelectors.isDsmProductBindSelected(id));
  }

  getNeedConstructionInfo(productId: string): Observable<boolean> {
    return this._store.select(fromSelectors.getNeedConstructionInfo(productId));
  }

  setLeadsSentToCma(): void {
    this._store.dispatch(new fromActions.SetLeadsSentToCma());
  }

  getStatusOfAllProducts(): Observable<ProductStatus[]> {
    return this._store.select(fromSelectors.getStatusOfAllProducts);
  }

  getStatusOfAllProductsWithoutErrors(): Observable<ProductStatus[]> {
    return this._store.select(
      fromSelectors.getStatusOfAllProductsWithoutErrors
    );
  }

  failProduct(productId: string, errorCode?: string): void {
    this._store.dispatch(new fromActions.FailProduct(productId, errorCode));
  }

  failAllProducts(errorCode?: string): void {
    this._store.dispatch(new fromActions.FailAllProducts(errorCode));
  }

  softfall(productId: string, errorCode?: string): void {
    this._store.dispatch(new fromActions.Softfall(errorCode));
  }

  arePreBindDocumentsAcknowledged(): Observable<boolean> {
    return this._store.select(fromSelectors.arePreBindDocumentsAcknowledged);
  }

  areAllProductsFailed(): Observable<boolean> {
    return this._store.select(fromSelectors.areAllProductsFailed);
  }

  getSelectedPropertyProduct(): Observable<Product> {
    return this._store.select(fromSelectors.getSelectedPropertyProduct);
  }

  anyProductIsInitiating(): Observable<boolean> {
    return this._store.select(fromSelectors.anyProductIsInitiating);
  }

  disqualifyUmbrella(): void {
    this._store.dispatch(new fromActions.DisqualifyUmbrella());
  }

  isMonoQuoteAllowed(): Observable<boolean> {
    return this._store.select(fromSelectors.isMonoQuoteAllowed);
  }

  isCAorNYStateQuoteAllowed(): Observable<boolean> {
    return this._store.select(fromSelectors.isCAorNYStateQuoteAllowed);
  }

  isNonGenderSpecifiedAutoOnly(): Observable<boolean> {
    return this._store.select(fromSelectors.isNonGenderSpecifiedAutoOnly);
  }

  isNonGenderSpecifiedNonProperty(): Observable<boolean> {
    return this._store.select(fromSelectors.isNonGenderSpecifiedNonProperty);
  }

  isNoPlayStateTermLifeRestricted(): Observable<boolean> {
    return this._store.select(fromSelectors.isNoPlayStateTermLifeRestricted);
  }

  isNoPlayStatePetRestricted(): Observable<boolean> {
    return this._store.select(fromSelectors.isNoPlayStatePetRestricted);
  }

  isNoPlayStateAllProductsRestricted(): Observable<boolean> {
    return this._store.select(fromSelectors.isNoPlayStateAllProductsRestricted);
  }

  isPauseStatePetRestricted(): Observable<boolean> {
    return this._store.select(fromSelectors.isPauseStatePetRestricted);
  }

  isPetUnavailableState(): Observable<boolean> {
    return this._store.select(fromSelectors.isPetUnavailableState);
  }

  isRoofConditionForCondoApplicable(): Observable<boolean> {
    return this._store.select(fromSelectors.isRoofConditionForCondoApplicable);
  }
  getTFN(): Observable<string> {
    return this._store.select(fromSelectors.get_Tfn);
  }
  getMonolineTFN(): Observable<string> {
    return this._store.select(fromSelectors.getMonoline_Tfn);
  }

  arePreBindPowersportsDocumentsAcknowledged(): Observable<boolean> {
    return this._store.select(
      fromSelectors.arePreBindPowersportsDocumentsAcknowledged
    );
  }
  isPropertyOnlySelected(): Observable<boolean> {
    return this._store.select(fromSelectors.isPropertyOnlySelected);
  }
  isHomeOwnersOnlySelected(): Observable<boolean> {
    return this._store.select(fromSelectors.isHomeOwnersOnlySelected);
  }

  thirdPartyPropertyPause(): Observable<boolean> {
    return this._store.select(fromSelectors.thirdPartyPropertyPause);
  }
}
