import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { SelectOption } from '@core/models/select-option.model';
import { ConnectedCarCriteria } from '@core/models/private-label/private-label.model';
import { AntiTheftDevices } from '@core/models/auto/vehicle-antiTheftDevice-states.model';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { VehiclePrimaryUseOptions } from '@core/models/auto/vehicle-primary-use.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

@Injectable()
export class MetadataDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  loadMaritalStatus(): void {
    this._store.dispatch(new fromActions.LoadMaritalStatus());
  }

  getMaritalStatusData(): Observable<string[]> {
    return this._store.select(fromSelectors.getMaritalStatusData);
  }

  getMaritalStatusLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getMaritalStatusLoaded);
  }

  getMaritalStatusLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getMaritalStatusLoading);
  }

  loadStateSpecificFlags() {
    return this._store.dispatch(new fromActions.LoadStateSpecificFlags());
  }

  getStateSpecificFlags(): Observable<any> {
    return this._store.select(fromSelectors.getStateSpecificFlags);
  }

  getStateSpecificFlagsLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getStateSpecificFlagsLoaded);
  }

  getStateSpecificFlagsLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getStateSpecificFlagsLoading);
  }

  getStateSpecificFlag(name: string): Observable<boolean> {
    return this._store.select(fromSelectors.getStateSpecificFlag(name));
  }

  getStateSpecificFlagsObject(): Observable<StateSpecificFlagsObject> {
    return this._store.select(fromSelectors.getStateSpecificFlagsObject);
  }

  loadSuffix(): void {
    this._store.dispatch(new fromActions.LoadSuffix());
  }

  getSuffixData(): Observable<SelectOption[]> {
    return this._store.select(fromSelectors.getSuffixData);
  }

  getSuffixLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getSuffixLoaded);
  }

  getSuffixLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getSuffixLoading);
  }

  loadStateAbbreviations() {
    return this._store.dispatch(new fromActions.LoadStateAbbreviations());
  }

  loadActiveStateAbbreviations() {
    return this._store.dispatch(new fromActions.LoadActiveStateAbbreviations());
  }

  getStateAbbreviationsData(): Observable<string[]> {
    return this._store.select(fromSelectors.getStateAbbreviations);
  }

  getActiveStateAbbreviationsData(): Observable<string[]> {
    return this._store.select(fromSelectors.getActiveStateAbbreviations);
  }

  getActiveStateAbbreviationsLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getActiveStateAbbreviationsLoading);
  }

  getActiveStateAbbreviationsLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getActiveStateAbbreviationsLoaded);
  }

  getStateAbbreviationsLoading() {
    return this._store.select(fromSelectors.getStateAbbreviationsLoading);
  }

  getStateAbbreviationsLoaded() {
    return this._store.select(fromSelectors.getStateAbbreviationsLoaded);
  }

  loadAgencyChannel(): void {
    this._store.dispatch(new fromActions.LoadAgencyChannel());
  }

  getAgencyChannelData(): Observable<string[]> {
    return this._store.select(fromSelectors.getAgencyChannelData);
  }

  getAgencyChannelLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getAgencyChannelLoaded);
  }

  getAgencyChannelLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getAgencyChannelLoading);
  }

  getFeaturesLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getFeaturesLoaded);
  }

  loadFeatures(): void {
    this._store.dispatch(new fromActions.LoadFeatures());
  }

  getFeaturesData(): Observable<any> {
    return this._store.select(fromSelectors.getFeaturesData);
  }

  getConnectedCarCriteriaLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getConnectedCarCriteriaLoaded);
  }

  loadConnectedCarCriteria(identifier: string): void {
    this._store.dispatch(new fromActions.LoadConnectedCarCriteria(identifier));
  }

  getConnectedCarCriteriaData(): Observable<ConnectedCarCriteria[]> {
    return this._store.select(fromSelectors.getConnectedCarCriteriaData);
  }

  getAntiTheftDevicesLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getAntiTheftDevicesLoaded);
  }

  loadAntiTheftDevices(): void {
    this._store.dispatch(new fromActions.LoadAntiTheftDevices());
  }

  getAntiTheftDevices(): Observable<AntiTheftDevices> {
    return this._store.select(fromSelectors.getAntiTheftDevices);
  }

  getVehicleEligibleDiscountsLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getVehicleEligibleDiscountsLoaded);
  }

  getVehicleEligibleDiscountsLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getVehicleEligibleDiscountsLoading);
  }

  loadVehicleEligibleDiscounts(): void {
    this._store.dispatch(new fromActions.LoadVehicleEligibleDiscounts());
  }

  getVehicleEligibleDiscounts(): Observable<EligibleDiscountEntity[]> {
    return this._store.select(fromSelectors.getVehicleEligibleDiscounts);
  }

  getVehiclePrimaryUseOptionsLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getVehiclePrimaryUseOptionsLoaded);
  }

  loadVehiclePrimaryUseOptions(): void {
    this._store.dispatch(new fromActions.LoadVehiclePrimaryUseOptions());
  }

  getVehiclePrimaryUseOptions(): Observable<VehiclePrimaryUseOptions> {
    return this._store.select(fromSelectors.getVehiclePrimaryUseOptions);
  }
}
