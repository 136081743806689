<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Underwriting Information</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="privateLabelDisclaimer$ | async; let disclaimer">
    {{ disclaimer }}
  </p>
  <p>
    Products underwritten by Nationwide Mutual Insurance Company and affiliates.
    Subject to underwriting guidelines, review, and approval. Products and
    discounts not available to all persons in all states.
  </p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="modal.close('Close click')"
  >
    Close
  </button>
</div>
