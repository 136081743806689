import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SeparateUnit } from '@core/models/renters/separateunits.model';
import { StructuralMaterial } from '@core/models/renters/structuralmaterials.model';

@Injectable()
export class RentersAdapter {
  constructor(private _http: HttpClient) {}

  getNumOfSeparateUnits(): Observable<SeparateUnit[]> {
    return this._http
      .get<SeparateUnit[]>('assets/api/metadata/renters/separateunits.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }

  getStructuralMaterials(): Observable<StructuralMaterial[]> {
    return this._http
      .get<StructuralMaterial[]>(
        'assets/api/metadata/renters/structuralmaterials.json'
      )
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }
}
