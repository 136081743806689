import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuoteAdapter } from '@core/adapters/quote.adapter';
import { QuoteDao } from '@core/dao/quote.dao';
import { InitiateNewBusinessRequest } from '@core/models/auto/quotes/initiate-new-business-request.model';
import { InitiateNewBusinessResponse } from '@core/models/auto/quotes/initiate-new-business-response.model';
import {
  UpdateQuoteRequest,
  QuoteRequest,
  UpdateQuoteRequestBody,
  TermLifeQuoteRequest,
  TermLifeRequest,
  TermLifeEligible,
} from '@core/models/auto/quotes/update-quote-request.model';
import { UpdatePolicyholderRequest } from '@core/models/auto/quotes/update-policyholder-request.model';
import { PolicyholderResponse } from '@core/models/response/policyholder-response.model';
import { UpdateQuoteResponse } from '@core/models/auto/quotes/update-quote-response.model';
import {
  UpdateVehiclesRequest,
  GetVehiclesRequest,
} from '@core/models/auto/quotes/update-vehicles-request.model';
import { CurrentCarrier } from '@core/store/reducers/current-carrier.reducer';
import {
  QuoteRatingResponseModel,
  TermLifeQuoteRatingResponseModel,
} from '@core/models/auto/quotes/quote-rating-response.model';
import {
  UpdateCoverageRequestModel,
  CoverageChangeResponseModel,
} from '@core/models/auto/quotes/update-coverages-request.model';
import {
  InitiateAutoExtraParameters,
  InitiateExtraParameters,
} from '@core/models/auto/quotes/initiate-auto-extra-parameters';
import { RetrieveCoverageRequestModel } from '@core/models/auto/quotes/retrieve-coverage-request.model';
import { RetrieveCoverageResponseModel } from '@core/models/auto/quotes/rertrieve-coverage-response.model';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { ModifyContactPointRequest } from '@core/models/modify-contact-point/modify-contact-point-request.model';
import { PowersportsCurrentCarrier } from '@core/store/reducers/powersports-current-carrier.reducer';
import {
  GetCurrentCarrierRequest,
  GetPowersportsCurrentCarrierRequest,
  UpdateCurrentCarrierRequest,
  UpdatePowersportsCurrentCarrierRequest,
} from '@core/models/auto/quotes/update-current-carrier-request.model';
import {
  DEFAULT_ID,
  DriverRelationToPNI,
  ProductTypes,
} from '@shared/constants/app-constants';
import { InitiateNewBusinessUmbrellaRequest } from '@core/models/umbrella/initiate-new-business-umbrella.request';
import { MoreDetailsViewModel } from '@core/models/view/more-details.model';
import { Product } from '@core/models/products/product.model';
import { AddressEntity } from '@core/models/entities/address.entity';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { PersonEntity } from '@core/models/entities/person.entity';
import { AddressRequest } from '@core/models/request/address-request.model';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { PolicyholderRequest } from '@core/models/request/policyholder-request.model';
import { DateUtils } from '@shared/utils/date.utils';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  constructor(
    private _quoteAdapter: QuoteAdapter,
    private _quoteDao: QuoteDao
  ) {}

  initiateNewBusiness(
    request: InitiateNewBusinessRequest
  ): Observable<InitiateNewBusinessResponse> {
    return this._quoteAdapter.initiateNewBusiness(request);
  }

  ratedQuotes(
    request: InitiateNewBusinessUmbrellaRequest
  ): Observable<QuoteRatingResponseModel> {
    return this._quoteAdapter.ratedQuotes(request);
  }

  rateTermLifeQuote(
    request: TermLifeQuoteRequest
  ): Observable<TermLifeQuoteRatingResponseModel> {
    return this._quoteAdapter.termLifeQuote(request);
  }

  dispatchInitiateNewBusiness(
    productId: string,
    extras?: InitiateAutoExtraParameters
  ): void {
    this._quoteDao.dispatchInitiateNewBusiness(productId, extras);
  }

  dispatchInitiateAllNewBusiness(
    moreDetailsViewModel: MoreDetailsViewModel
  ): void {
    this._quoteDao.dispatchInitiateAllNewBusiness(moreDetailsViewModel);
  }

  dispatchUpdateQuote(productId: string): void {
    this._quoteDao.dispatchUpdateQuote(productId);
  }

  dispatchPatchQuote(productId: string, request: UpdateQuoteRequestBody): void {
    this._quoteDao.dispatchPatchQuote(productId, request);
  }

  updateQuote(request?: UpdateQuoteRequest): Observable<UpdateQuoteResponse> {
    return this._quoteAdapter.updateQuote(request);
  }

  addPolicyholder(
    request: UpdatePolicyholderRequest
  ): Observable<PolicyholderResponse> {
    return this._quoteAdapter.addPolicyholder(request);
  }

  updatePolicyholder(
    request: UpdatePolicyholderRequest
  ): Observable<PolicyholderResponse> {
    return this._quoteAdapter.updatePolicyholder(request);
  }

  deletePolicyholder(
    request: UpdatePolicyholderRequest
  ): Observable<PolicyholderResponse> {
    return this._quoteAdapter.deletePolicyholder(request);
  }

  updateCurrentCarrier(
    request: UpdateCurrentCarrierRequest
  ): Observable<CurrentCarrier> {
    return this._quoteAdapter.updateCurrentCarrier(request);
  }

  updatePowersportsCurrentCarrier(
    request: UpdatePowersportsCurrentCarrierRequest
  ): Observable<PowersportsCurrentCarrier> {
    return this._quoteAdapter.updatePowersportsCurrentCarrier(request);
  }

  updateVehicles(request: UpdateVehiclesRequest): Observable<VehicleEntity[]> {
    return this._quoteAdapter.updateVehicles(request);
  }

  dispatchTermLifeRateQuote(termLifeEligible: TermLifeEligible): void {
    this._quoteDao.dispatchTermLifeRateQuote(termLifeEligible);
  }

  dispatchRateQuote(
    productId: string,
    ratingType: string,
    suppressSoftfall?: boolean
  ): void {
    if (productId !== ProductTypes.TERMLIFE) {
      this._quoteDao.dispatchRateQuote(productId, ratingType, suppressSoftfall);
    }
  }

  getQuoteRating(productId: string): Observable<boolean> {
    return this._quoteDao.getQuoteRating(productId);
  }

  getQuoteRated(productId: string): Observable<boolean> {
    return this._quoteDao.getQuoteRated(productId);
  }

  getQuoteCompleted(productId: string): Observable<boolean> {
    return this._quoteDao.getQuoteCompleted(productId);
  }

  getQuoteBinding(productId: string): Observable<boolean> {
    return this._quoteDao.getQuoteBinding(productId);
  }

  getQuoteBound(productId: string): Observable<boolean> {
    return this._quoteDao.getQuoteBound(productId);
  }

  getBindCompleted(productId: string): Observable<boolean> {
    return this._quoteDao.getBindCompleted(productId);
  }

  quote(request: QuoteRequest): Observable<QuoteRatingResponseModel> {
    return this._quoteAdapter.quote(request);
  }

  retrieveCurrentCarrier(
    request: GetCurrentCarrierRequest
  ): Observable<CurrentCarrier> {
    return this._quoteAdapter.retrieveCurrentCarrier(request);
  }

  retrievePowersportsCurrentCarrier(
    request: GetPowersportsCurrentCarrierRequest
  ): Observable<PowersportsCurrentCarrier> {
    return this._quoteAdapter.retrievePowersportsCurrentCarrier(request);
  }

  retrieveVehicles(request: GetVehiclesRequest): Observable<VehicleEntity[]> {
    return this._quoteAdapter.retrieveVehicles(request);
  }

  retrieveAllCoverages(
    request: RetrieveCoverageRequestModel
  ): Observable<RetrieveCoverageResponseModel[]> {
    return this._quoteAdapter.retrieveAllCoverages(request);
  }

  updateCoverage(
    request: UpdateCoverageRequestModel
  ): Observable<CoverageChangeResponseModel> {
    return this._quoteAdapter.updateCoverages(request);
  }

  invalidateQuote(productId: string): void {
    this._quoteDao.invalidateQuote(productId);
  }

  invalidateBind(productId: string): void {
    this._quoteDao.invalidateBind(productId);
  }

  invalidateQuoteCompletion(productId: string): void {
    this._quoteDao.invalidateQuoteCompletion(productId);
  }

  validateQuoteCompletion(productId: string): void {
    this._quoteDao.validateQuoteCompletion(productId);
  }

  loadModifyContactPointResponse(
    request: ModifyContactPointRequest
  ): Observable<any> {
    return this._quoteAdapter.loadModifyContactPoint(request);
  }

  sendPetPromoEmail(recipientEmailAddress: string): Observable<any> {
    return this._quoteAdapter.sendPetPromoEmail(recipientEmailAddress);
  }

  getQuoteUpdateLoaded(productId: string): Observable<boolean> {
    return this._quoteDao.getQuoteUpdateLoaded(productId);
  }

  dispatchPreBindDocumentsAcknowledgement(
    received: boolean,
    viewed: boolean
  ): void {
    this.dispatchPatchQuote(ProductTypes.AUTO, {
      hasReceivedRequiredDocuments: received,
      hasCustomerViewedDocuments: viewed,
    });
  }

  getTermlifeQuoteId(productId: string): Observable<string> {
    return this._quoteDao.getTermLifeQuoteId(productId);
  }

  generateTermLifeQuoteId(): Observable<string> {
    return this._quoteAdapter.generateTermLifeQuoteId();
  }

  buildRateQuoteRequest(
    productId: string,
    ratingType: string
  ): Observable<QuoteRequest> {
    return this._quoteDao.buildRateQuoteRequest(productId, ratingType);
  }

  buildTermLifeRateQuoteRequest(
    termLifeEligible: TermLifeEligible
  ): Observable<TermLifeRequest> {
    return this._quoteDao.buildQuoteTermLifeRequest(termLifeEligible);
  }

  buildProductChanges(
    response: InitiateNewBusinessResponse,
    productType: string
  ): Product {
    let returnProduct = {
      effectiveDate: response.effectiveDate,
      quoteId: response.quoteId,
      creditConsent: response.creditConsent,
      termType: this.readTermTypeFromResponse(response),
      sessionId: response.sessionId,
      quoteStatus: response.quoteStatus,
      tokenLoaded: false,
      tokenLoading: false,
      quoteLoading: false,
      quoteLoaded: true,
    } as Product;

    if (productType === (ProductTypes.HOMEOWNERS || ProductTypes.CONDO)) {
      returnProduct = {
        ...returnProduct,
        needConstructionInfo: response.needConstructionInfo,
        prequalificationAnswers: response.prequalificationAnswers,
      };
    }

    if (productType === ProductTypes.RENTERS) {
      returnProduct = {
        ...returnProduct,
        prequalificationAnswers: response.prequalificationAnswers,
      };
    }

    if (productType === ProductTypes.UMBRELLA) {
      returnProduct = {
        ...returnProduct,
        prequalificationAnswers: response.prequalificationAnswers,
      };
    }

    return returnProduct;
  }

  buildUmbrellaProductChanges(response: QuoteRatingResponseModel): Product {
    return {
      effectiveDate: response.effectiveDate,
      quoteId: response.quoteId,
      creditConsent: response.creditConsent,
      termType: response.termType,
      sessionId: response.sessionId,
      quoteStatus: response.quoteStatus,
      tokenLoaded: false,
      tokenLoading: false,
      quoteLoading: false,
      quoteLoaded: true,
      quoteRated: response.quoteStatus === 'Quoted',
      quoteCompleted: response.quoteStatus === 'Quoted',
      show: true,
      hasError: false,
      isQualified: true,
    } as Product;
  }

  buildPniPolicyAddressChanges(
    response: InitiateNewBusinessResponse | QuoteRatingResponseModel,
    productId: string,
    request?: AddressRequest
  ): AddressEntity {
    let street =
      response.policyAddress.addressLine1 +
      ', ' +
      response.policyAddress.city +
      ', ' +
      response.policyAddress.state +
      ', ' +
      response.policyAddress.postalCode;
    if (response.policyAddress.country) {
      street += ', ' + response.policyAddress.country;
    }

    const output = {
      ...response.policyAddress,
      addressId: DEFAULT_ID,
      addressIds: {
        [productId]: response.policyAddress.addressId,
      },
      street,
    } as AddressEntity;

    if (
      request &&
      !output.addressLine2 &&
      output.addressLine1?.endsWith(request.addressLine2)
    ) {
      output.addressLine2 = request.addressLine2;
      output.addressLine1 = output.addressLine1
        .substr(0, output.addressLine1.length - request.addressLine2.length)
        .trim();
    }

    return output;
  }

  buildDriverEntity(
    productId: string,
    policyHolderId: string,
    person: PersonEntity,
    driverOccupation: string,
    infractionDesc: string,
    licenseState: string,
    extra: InitiateExtraParameters
  ): DriverEntity {
    let returnDriver = {
      person: {
        ...person,
        driverOccupation,
        // hasMotorcycleEndorsement: person.hasMotorcycleEndorsement
      },
      driverType: 'Driver',
      relationToPrimaryNamedInsured: DriverRelationToPNI.PNI,
      isFinancialFilingRequired: false,
      policyHolderId,
      licenseState,
      productId: productId,
    } as DriverEntity;

    if (extra) {
      returnDriver = { ...returnDriver, ...extra };
      returnDriver.person.gender = extra.gender;
      delete (returnDriver as any).gender;
      if (extra.dateOfBirth) {
        returnDriver.person.dateOfBirth = DateUtils.formatDateToDSM(
          extra.dateOfBirth
        );
        delete (returnDriver as any).dateOfBirth;
      }
    }
    if (!returnDriver.ageFirstLicensed) {
      returnDriver.ageFirstLicensed = '16';
    }
    if (infractionDesc) {
      returnDriver.person.infractionDesc = infractionDesc;
    }

    return returnDriver;
  }

  readTermTypeFromResponse(
    response: InitiateNewBusinessResponse | QuoteRatingResponseModel
  ): string {
    const MONTHS_PER_YEAR = 12;
    const MONTHS_PER_HALF_YEAR = 6;
    if (response?.termType) {
      return response.termType;
    }
    const ratingResponse = <QuoteRatingResponseModel>response;
    if (
      ratingResponse?.offeredQuotes &&
      ratingResponse?.offeredQuotes.length > 0
    ) {
      if (ratingResponse.offeredQuotes[0].premium) {
        switch (ratingResponse.offeredQuotes[0].premium.termMonths) {
          case MONTHS_PER_HALF_YEAR:
            return 'HalfYear';
          case MONTHS_PER_YEAR:
            return 'FullYear';
        }
      }
    }
    return null;
  }

  private policyholderMatchesRequest(
    policyholder: PersonEntity,
    request: PersonEntity
  ): boolean {
    return (
      (policyholder.firstName || '') === (request.firstName || '') &&
      (policyholder.lastName || '') === (request.lastName || '') &&
      (policyholder?.middleName || '') === (request.middleName || '') &&
      (policyholder?.suffix || '') === (request.suffix || '')
    );
  }

  findPolicyholderDobInRequest(
    policyholder: PolicyholderEntity,
    request: InitiateNewBusinessRequest
  ): string {
    return (
      request.policyHolders.filter(
        (requestPolicyholder: PolicyholderRequest) =>
          requestPolicyholder.person.dateOfBirth &&
          this.policyholderMatchesRequest(
            policyholder.person,
            requestPolicyholder.person
          )
      )[0]?.person?.dateOfBirth || undefined
    );
  }

  dispatchPreBindPowersportsDocumentsAcknowledgement(
    received: boolean,
    viewed: boolean
  ): void {
    this.dispatchPatchQuote(ProductTypes.POWERSPORTS, {
      hasReceivedRequiredDocuments: received,
      hasCustomerViewedDocuments: viewed,
    });
  }
}
