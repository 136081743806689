import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';

import { Observable } from 'rxjs';
import { Alert, PageAlert } from '@core/models/page-alerts/page-alert.model';

@Injectable()
export class PageAlertDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  loadPageAlerts(): void {
    this._store.dispatch(new fromActions.LoadAllPageAlerts());
  }

  dispatchActiveAlert(pageId: string): void {
    this._store.dispatch(new fromActions.LoadActiveAlert(pageId));
  }

  getActiveAlert(): Observable<Alert> {
    return this._store.select(fromSelectors.getActiveAlert);
  }

  getActiveAlerts(): Observable<Alert[]> {
    return this._store.select(fromSelectors.getActiveAlerts);
  }

  clear(): void {
    this._store.dispatch(new fromActions.ClearActiveAlert());
  }

  getPageAlertLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getPageAlertsLoaded);
  }

  addAlert(alert: PageAlert): void {
    this._store.dispatch(new fromActions.AddPageAlert(alert));
  }
}
