import * as fromCma from '@core/store/actions/cma.action';

import { CmaResponse } from '@core/models/cma/cma-response.model';

export interface CmaState {
  cma: CmaResponse;
  loaded: boolean;
  loading: boolean;
}

export const initialState: CmaState = {
  cma: null,
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromCma.CmaActions
): CmaState {
  switch (action.type) {
    case fromCma.LOAD_CUSTOMER_MANAGEMENT_ACTION: {
      return { ...state, loading: true };
    }

    case fromCma.LOAD_CUSTOMER_MANAGEMENT_ACTION_FAIL: {
      return { ...state, loading: false, loaded: true };
    }

    case fromCma.LOAD_CUSTOMER_MANAGEMENT_ACTION_SUCCESS: {
      const cma = action.payload;

      return { ...state, cma, loaded: true, loading: false };
    }
  }
  return state;
}

export const getCma = (state: CmaState) => state.cma;
export const getCmaLoading = (state: CmaState) => state.loading;
export const getCmaLoaded = (state: CmaState) => state.loaded;
