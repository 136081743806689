import { PropertyInfoModalComponent } from '@quote/containers/property-info-modal/property-info-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdditionalPropertyInfoFormComponent } from './components/additional-property-info-form/additional-property-info-form.component';
import { PropertyInfoComponent } from '../../containers/property-info/property-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldValidationMessageModule } from '../../../shared/components/field-validation-message/field-validation-message.module';
import { DisplayPanelModule } from '../../../shared/components/display-panel/display-panel.module';
import { HoPropertyInfoFormComponent } from './components/ho-property-info-form/ho-property-info-form.component';
import { RentersPropertyInfoFormComponent } from './components/renters-property-info-form/renters-property-info-form.component';
import { SmartHomePropertyInfoComponent } from './components/smarthome-property-info-form/smarthome-property-info.component';
import { CondoPropertyInfoFormComponent } from './components/condo-property-info-form/condo-property-info-form.component';
import { NavigationButtonsModule } from '../../../shared/components/navigation-buttons/navigation-buttons.module';
import { PicklistSelectModule } from '../../../shared/components/picklist-select/picklist-select.module';
import { TogglePillModule } from '../../../shared/components/toggle-pill/toggle-pill.module';
import { NumberOfGarageCarsModule } from '@shared/pipes/number-of-garage-cars/number-of-garage-cars.module';
import { InlineHelpModule } from '../../../shared/components/inline-help/inline-help.module';
import { NgxMaskModule } from 'ngx-mask';
import { AllProtectiveDevicesModule } from '@shared/pipes/all-protective-devices/all-protective-devices.module';
import { ProtectiveDevicesFormComponent } from './components/protective-devices-form/protective-devices-form.component';
import { OilTankFormComponent } from './components/oil-tank-form/oil-tank-form.component';
import { RenovationsFormComponent } from './components/renovations-form/renovations-form.component';
import { RenovationUpdateFormComponent } from './components/renovation-update-form/renovation-update-form.component';
import { SmarthomePropertyInfoPricePointsFormComponent } from './components/smarthome-property-info-price-points-form/smarthome-property-info-price-points-form.component';
import { ProgressTrackerModule } from '@shared/components/progress-tracker/progress-tracker.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FieldValidationMessageModule,
    DisplayPanelModule,
    NavigationButtonsModule,
    PicklistSelectModule,
    TogglePillModule,
    NumberOfGarageCarsModule,
    InlineHelpModule,
    NgxMaskModule,
    AllProtectiveDevicesModule,
    ProgressTrackerModule,
  ],
  exports: [
    AdditionalPropertyInfoFormComponent,
    HoPropertyInfoFormComponent,
    RentersPropertyInfoFormComponent,
    SmartHomePropertyInfoComponent,
    ProtectiveDevicesFormComponent,
    CondoPropertyInfoFormComponent,
    OilTankFormComponent,
    RenovationsFormComponent,
    RenovationUpdateFormComponent,
  ],
  declarations: [
    AdditionalPropertyInfoFormComponent,
    HoPropertyInfoFormComponent,
    RentersPropertyInfoFormComponent,
    SmartHomePropertyInfoComponent,
    CondoPropertyInfoFormComponent,
    ProtectiveDevicesFormComponent,
    OilTankFormComponent,
    RenovationsFormComponent,
    RenovationUpdateFormComponent,
    SmarthomePropertyInfoPricePointsFormComponent,
    PropertyInfoComponent,
    PropertyInfoModalComponent,
  ],
  providers: [],
})
export class PropertyInfoModule {}
