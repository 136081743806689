<div class="agent nw-text-sm">
  <span> <strong>Agent</strong> </span>
  <div class="agent-name">
    <span>{{ agent?.firstName }} {{ agent?.lastName }}</span>
  </div>
  <div *ngIf="agent?.producerCode">
    <span>Producer Code: {{ agent?.producerCode }}</span>
  </div>
  <div>
    <span *ngIf="agent?.agencyCode">Agency Code: {{ agent?.agencyCode }}</span>
  </div>
  <div *showSearchQuote>
    <span *ngIf="routeUrl?.includes('/getting-started')"
      ><a routerLink="/search">Search quotes</a></span
    >
  </div>
  <div>
    <span
      *ngIf="
        !routeUrl?.includes('/getting-started') &&
        !routeUrl?.includes('/compRater')
      "
      ><a (click)="startNewQuote()">Start a new quote</a></span
    >
  </div>

  <div class="quoteids" *ngFor="let product of dsmProducts">
    <span *ngIf="product.quoteId">
      <strong>{{ product.name }} Quote ID: &nbsp;</strong>
      <a
        [href]="deepLaunchToPC(product.quoteId)"
        (click)="logDeepLaunchClickEvent(product.quoteId, product.name)"
        target="_blank"
        rel="noopener"
        >{{ product.quoteId }}</a
      >
    </span>
  </div>
</div>
