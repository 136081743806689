import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, switchMap, catchError, concatMap, tap } from 'rxjs/operators';

import * as cmaActions from '@core/store/actions';

import { CmaService } from '@core/services';
import { LoggingService } from '@core/services/logging.service';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable()
export class CmaEffects {
  constructor(
    private _actions$: Actions,
    private _cmaService: CmaService,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  loadCustomerManagementAction = this._actions$.pipe(
    ofType(cmaActions.LOAD_CUSTOMER_MANAGEMENT_ACTION),
    switchMap((action: cmaActions.LoadCustomerManagementAction) =>
      this._cmaService.buildCustomerManagementActionRequest()
    ),
    tap(request =>
      this._loggingService.log('Customer Management Action request', request)
    ),
    concatMap(request =>
      this._cmaService.customerManagementActionResponse(request).pipe(
        map(
          response =>
            new cmaActions.LoadCustomerManagementActionSuccess(response)
        ),
        tap(response =>
          this._loggingService.log(
            'Customer Management Action response',
            response
          )
        ),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log(
            'Customer Management Action Error',
            safeError
          );
          return of(new cmaActions.LoadCustomerManagementActionFail(safeError));
        })
      )
    )
  );
}
