import { Action } from '@ngrx/store';

import { VehicleExposureEntity } from '@core/models/entities/vehicle-exposure.entity';

export const enum VehicleExposureActionTypes {
  ADD_VEHICLE_EXPOSURE = '[Vehicle Exposure] Add Vehicle Exposure',
  ADD_VEHICLE_EXPOSURE_FAIL = '[Vehicle Exposure] Add Vehicle Exposure Fail',
  ADD_VEHICLE_EXPOSURE_SUCCESS = '[Vehicle Exposure] Add Vehicle Exposure Success',
  ADD_ALL_VEHICLE_EXPOSURE_SUCCESS = '[Vehicle Exposure] Add All Vehicle Exposure Success',

  DELETE_VEHICLE_EXPOSURE = '[Vehicle Exposure] Delete Vehicle Exposure',
  DELETE_VEHICLE_EXPOSURE_FAIL = '[Vehicle Exposure] Delete Vehicle Exposure Fail',
  DELETE_VEHICLE_EXPOSURE_SUCCESS = '[Vehicle Exposure] Delete Vehicle Exposure Success',
}

export class AddVehicleExposure implements Action {
  readonly type = VehicleExposureActionTypes.ADD_VEHICLE_EXPOSURE;
  constructor(public payload: VehicleExposureEntity) {}
}

export class AddVehicleExposureFail implements Action {
  readonly type = VehicleExposureActionTypes.ADD_VEHICLE_EXPOSURE_FAIL;
  constructor(public error: any) {}
}

export class AddVehicleExposureSuccess implements Action {
  readonly type = VehicleExposureActionTypes.ADD_VEHICLE_EXPOSURE_SUCCESS;
  constructor(public payload: VehicleExposureEntity) {}
}
export class AddAllVehicleExposureSuccess implements Action {
  readonly type = VehicleExposureActionTypes.ADD_ALL_VEHICLE_EXPOSURE_SUCCESS;
  constructor(public payload: VehicleExposureEntity[]) {}
}
export class DeleteVehicleExposure implements Action {
  readonly type = VehicleExposureActionTypes.DELETE_VEHICLE_EXPOSURE;
  constructor(public payload: VehicleExposureEntity) {}
}

export class DeleteVehicleExposureFail implements Action {
  readonly type = VehicleExposureActionTypes.DELETE_VEHICLE_EXPOSURE_FAIL;
  constructor(public error: any) {}
}

export class DeleteVehicleExposureSuccess implements Action {
  readonly type = VehicleExposureActionTypes.DELETE_VEHICLE_EXPOSURE_SUCCESS;
  constructor(public payload: string) {}
}

export type VehicleExposureActions =
  | AddVehicleExposure
  | AddVehicleExposureFail
  | AddVehicleExposureSuccess
  | AddAllVehicleExposureSuccess
  | DeleteVehicleExposure
  | DeleteVehicleExposureFail
  | DeleteVehicleExposureSuccess;
