import * as fromQuoteLetter from '@core/store/actions/quote-letter.action';

export interface QuoteLetterState {
  quoteLetter: string;
  errorMessage: string;
  loaded: boolean;
  loading: boolean;
}

export const initialState: QuoteLetterState = {
  quoteLetter: null,
  errorMessage: null,
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromQuoteLetter.QuoteLetterActions
): QuoteLetterState {
  switch (action.type) {
    case fromQuoteLetter.LOAD_QUOTE_LETTER: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromQuoteLetter.LOAD_QUOTE_LETTER_SUCCESS: {
      return {
        ...state,
        quoteLetter: action.payload,
        loading: false,
        loaded: true,
      };
    }

    case fromQuoteLetter.LOAD_QUOTE_LETTER_FAIL: {
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    }
  }
  return state;
}

export const getQuoteLetter = (state: QuoteLetterState) => state.quoteLetter;
export const getQuoteLetterLoaded = (state: QuoteLetterState) => state.loaded;
export const getQuoteLetterLoading = (state: QuoteLetterState) => state.loading;
