import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

import { Product } from '@core/models/products/product.model';

@Injectable()
export class ProductsMetadataService {
  constructor(private _http: HttpClient) {}

  getProducts(): Observable<Product[]> {
    return this._http
      .get<Product[]>('assets/api/metadata/products.json')
      .pipe(catchError((error: any) => observableThrowError(error.json())));
  }
}
