import { Action } from '@ngrx/store';
import { PowersportsCurrentCarrier } from '@core/store/reducers/powersports-current-carrier.reducer';
import { CurrentCarrier } from '@core/store/reducers/current-carrier.reducer';

export const enum CurrentCarrierActionTypes {
  GET_CURRENT_CARRIER = '[Carrier] Get Current Carrier',
  GET_CURRENT_CARRIER_SUCCESS = '[Carrier] Get Current Carrier Success',
  GET_CURRENT_CARRIER_FAIL = '[Carrier] Get Current Carrier Fail',
  ADD_CURRENT_CARRIER_DECISION = '[Carrier] Add Current Carrier Decision',
  UPDATE_CURRENT_CARRIER = '[Carrier] Update Current Carrier',
  UPDATE_CURRENT_CARRIER_SUCCESS = '[Carrier] Update Current Carrier Success',
  UPDATE_CURRENT_CARRIER_FAIL = '[Carrier] Update Current Carrier Fail',
  GET_POWERSPORTS_CURRENT_CARRIER = '[Carrier] Get Powersports Current Carrier',
  GET_POWERSPORTS_CURRENT_CARRIER_SUCCESS = '[Carrier] Get Powersports Current Carrier Success',
  GET_POWERSPORTS_CURRENT_CARRIER_FAIL = '[Carrier] Get Powersports Current Carrier Fail',
  UPDATE_POWERSPORTS_CURRENT_CARRIER = '[Carrier] Update Powersports Current Carrier',
  UPDATE_POWERSPORTS_CURRENT_CARRIER_SUCCESS = '[Carrier] Update Powersports Current Carrier Success',
  UPDATE_POWERSPORTS_CURRENT_CARRIER_FAIL = '[Carrier] Update Powersports Current Carrier Fail',
  UPDATE_CURRENT_CARRIER_FROM_QUOTE = '[Carrier] Update Current Carrier From Quote',
  UPDATE_POWERSPORTS_CURRENT_CARRIER_FROM_QUOTE = '[Carrier] Update Powersports Current Carrier From Quote',
}

export class UpdateCurrentCarrier implements Action {
  readonly type = CurrentCarrierActionTypes.UPDATE_CURRENT_CARRIER;
  constructor(
    public reasonForCoverageLapse?: string,
    public payload?: CurrentCarrier
  ) {}
}

export class UpdateCurrentCarrierSuccess implements Action {
  readonly type = CurrentCarrierActionTypes.UPDATE_CURRENT_CARRIER_SUCCESS;
  constructor(public payload: CurrentCarrier) {}
}

export class UpdateCurrentCarrierFail implements Action {
  readonly type = CurrentCarrierActionTypes.UPDATE_CURRENT_CARRIER_FAIL;
  constructor() {}
}

export class GetCurrentCarrier implements Action {
  readonly type = CurrentCarrierActionTypes.GET_CURRENT_CARRIER;
  constructor() {}
}

export class GetCurrentCarrierSuccess implements Action {
  readonly type = CurrentCarrierActionTypes.GET_CURRENT_CARRIER_SUCCESS;
  constructor(public payload: CurrentCarrier) {}
}

export class AddCurrentCarrierDecision implements Action {
  readonly type = CurrentCarrierActionTypes.ADD_CURRENT_CARRIER_DECISION;
  constructor(public payload: string) {}
}

export class GetCurrentCarrierFail implements Action {
  readonly type = CurrentCarrierActionTypes.GET_CURRENT_CARRIER_FAIL;
  constructor(public payload: any) {}
}

export class UpdatePowersportsCurrentCarrier implements Action {
  readonly type = CurrentCarrierActionTypes.UPDATE_POWERSPORTS_CURRENT_CARRIER;
  constructor(
    public isContinuousInsuranceProvided?: boolean,
    public payload?: PowersportsCurrentCarrier
  ) {}
}

export class UpdatePowersportsCurrentCarrierSuccess implements Action {
  readonly type =
    CurrentCarrierActionTypes.UPDATE_POWERSPORTS_CURRENT_CARRIER_SUCCESS;
  constructor(public payload: PowersportsCurrentCarrier) {}
}

export class UpdatePowersportsCurrentCarrierFail implements Action {
  readonly type =
    CurrentCarrierActionTypes.UPDATE_POWERSPORTS_CURRENT_CARRIER_FAIL;
  constructor() {}
}

export class GetPowersportsCurrentCarrier implements Action {
  readonly type = CurrentCarrierActionTypes.GET_POWERSPORTS_CURRENT_CARRIER;
  constructor() {}
}

export class GetPowersportsCurrentCarrierSuccess implements Action {
  readonly type =
    CurrentCarrierActionTypes.GET_POWERSPORTS_CURRENT_CARRIER_SUCCESS;
  constructor(public payload: PowersportsCurrentCarrier) {}
}
export class GetPowersportsCurrentCarrierFail implements Action {
  readonly type =
    CurrentCarrierActionTypes.GET_POWERSPORTS_CURRENT_CARRIER_FAIL;
  constructor(public payload: any) {}
}

export class UpdateCurrentCarrierFromQuote implements Action {
  readonly type = CurrentCarrierActionTypes.UPDATE_CURRENT_CARRIER_FROM_QUOTE;
  constructor(public payload: CurrentCarrier) {}
}

export class UpdatePowersportsCurrentCarrierFromQuote implements Action {
  readonly type =
    CurrentCarrierActionTypes.UPDATE_POWERSPORTS_CURRENT_CARRIER_FROM_QUOTE;
  constructor(public payload: PowersportsCurrentCarrier) {}
}

export type CurrentCarrierActions =
  | UpdateCurrentCarrier
  | UpdateCurrentCarrierSuccess
  | UpdateCurrentCarrierFail
  | GetCurrentCarrier
  | GetCurrentCarrierSuccess
  | AddCurrentCarrierDecision
  | GetCurrentCarrierFail
  | UpdatePowersportsCurrentCarrier
  | UpdatePowersportsCurrentCarrierSuccess
  | UpdatePowersportsCurrentCarrierFail
  | GetPowersportsCurrentCarrier
  | GetPowersportsCurrentCarrierSuccess
  | GetPowersportsCurrentCarrierFail
  | UpdatePowersportsCurrentCarrierFromQuote
  | UpdateCurrentCarrierFromQuote;
