import { FieldValidationMessageComponent } from './field-validation-message.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule],
  declarations: [FieldValidationMessageComponent],
  exports: [FieldValidationMessageComponent],
})
export class FieldValidationMessageModule {}
