import { createSelector } from '@ngrx/store';

import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';

import { UnderlyingPolicyRequest } from '@core/models/umbrella/underlying-policy-request.model';
import { ProductTypes, DEFAULT_ID } from '@shared/constants/app-constants';
import { LimitInformationEntity } from '@core/models/entities/underlying-policy.entity';

const getUnderlyingPolicyType = (productId: string) => {
  switch (productId) {
    case ProductTypes.AUTO: {
      return 'PA';
    }

    case ProductTypes.HOMEOWNERS:
    case ProductTypes.RENTERS:
    case ProductTypes.CONDO: {
      return 'HO';
    }
  }
};

const getLimitInformation = (productId: string, quoteState: string) => {
  switch (productId) {
    case ProductTypes.AUTO: {
      switch (quoteState) {
        case 'CA': {
          return {
            IsAutoCombinedSingleLimit: true,
            isBodilyInjurySufficient: true,
          } as LimitInformationEntity;
        }

        default: {
          return {
            isBodilyInjurySufficient: true,
            isPropertyDamageSufficient: true,
            isUninsuredUnderinsuredMotoristSufficient: true,
          } as LimitInformationEntity;
        }
      }
    }

    case ProductTypes.CONDO:
    case ProductTypes.RENTERS:
    case ProductTypes.HOMEOWNERS: {
      return {
        isPersonalLiabilitySufficient: true,
        hasPersonalInjuryCoverageEndorsement: false,
      };
    }
  }
};

export const buildUnderlyingPolicyRequest = (productId: string) =>
  createSelector(
    fromProducts.getProduct(productId),
    fromProducts.getProduct(ProductTypes.UMBRELLA),
    fromTokens.getAccessToken(ProductTypes.UMBRELLA),
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    (product, umbrella, accessToken, address) => {
      return {
        accessToken,
        productId: umbrella.id,
        quoteId: umbrella.quoteId,
        sessionId: product.sessionId,
        underlyingPolicy: {
          currentCarrierName: 'Nationwide_Ext',
          effectiveDate: product.effectiveDate,
          isNationwidePolicy: true,
          policyType: getUnderlyingPolicyType(productId),
          underlyingPolicyNumber: product.policyNumber || product.quoteId,
          limitInformation: getLimitInformation(productId, address.state),
        },
      } as UnderlyingPolicyRequest;
    }
  );
