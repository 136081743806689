<div
  class="product-container"
  [ngClass]="{ selected: item?.selected || alwaysOpen }"
>
  <div class="product-header" [ngClass]="{ 'border-bottom': isOpen | async }">
    <div class="product-label">
      <div class="heading-wrapper">
        <div
          *ngIf="heading"
          (click)="toggle()"
          class="heading"
          [ngClass]="{ 'link-style': !item && !alwaysOpen }"
          [ngStyle]="{ 'margin-left': alwaysOpen ? '20px' : '0' }"
          [attr.aria-expanded]="(isOpen | async) ? true : false"
          role="button"
        >
          <label for="{{ heading }}">{{ heading }}</label>
        </div>
        <div
          *ngIf="heading && subheading"
          (click)="toggle()"
          class="subheading"
          [attr.aria-expanded]="(isOpen | async) ? true : false"
          role="button"
        >
          {{ subheading }}
        </div>
      </div>
    </div>
    <div *ngIf="headerImage != ''">
      <img class="display-image" [src]="headerImage" alt="Header Image" />
    </div>
    <div
      *ngIf="!isPrimaryNamedInsured && isDeletable"
      class="product-button"
      [ngStyle]="{
        'border-left': alwaysOpen ? '' : '1px dashed #aaa'
      }"
      (click)="deleteProduct()"
      (keydown)="deleteProductIfSpaceOrEnter($event)"
    >
      <div
        class="product-button-text link-style"
        style="color: #d60000"
        tabindex="0"
      >
        Remove
      </div>
    </div>
    <div
      class="product-button"
      [ngStyle]="{
        'border-left': alwaysOpen ? '' : '1px dashed #aaa'
      }"
      (click)="toggle()"
      (keydown)="toggleIfSpaceOrEnter($event)"
    >
      <div
        class="product-button-text link-style"
        [innerHtml]="((isOpen | async) ? openText : closedText) || ''"
        [attr.aria-expanded]="(isOpen | async) ? true : false"
        role="button"
        tabindex="0"
        autofocus="autofocus"
      ></div>
    </div>
    <div
      *ngIf="icon"
      class="product-icon"
      [ngStyle]="{
        'border-left': alwaysOpen ? '' : '1px dashed #aaa'
      }"
    >
      <img
        *ngIf="icon"
        [src]="icon"
        aria-label="iconLabel"
        alt="Product Icon"
      />
    </div>
  </div>
  <div
    role="presentation"
    class="product-body"
    [@toggle]="openState"
    (@toggle.start)="animateBody($event)"
    (@toggle.done)="animateBody($event)"
  >
    <div class="product-body-content"><ng-content></ng-content></div>
  </div>
</div>
