import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromDocuments from '@core/store/reducers/documents.reducer';

export const getDocumentsState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.documents
);

export const {
  selectAll: selectAllDocumentss,
  selectEntities: selectDocumentsEntities,
} = fromDocuments.adapter.getSelectors(getDocumentsState);

export const getDocumentsLoaded = createSelector(
  getDocumentsState,
  state => state.loaded
);
