<div class="nw-footer nw-text-sm" *ngIf="configLoaded">
  <div class="nw-container nw-inner-bun">
    <ng-container
      class="nw-footer__urbo-number"
      *ngIf="selectedProducts.length > 0; else default"
    >
      <p>{{ getUrboNumber() }}</p>
    </ng-container>
    <ng-template class="nw-footer__urbo-number" #default>
      <p *ngIf="!privateLabelConfig">{{ getUrboNumberForLoadingPage() }}</p>
    </ng-template>
    <div class="nw-footer__top">
      <a
        *ngIf="!privateLabelConfig"
        [href]="websiteUrl"
        class="nw-footer__logo"
        [attr.aria-label]="label"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="41 0 144 54">
          <path
            d="M112.3 22.8c.7 0 1.2.5 1.2 1.2 0 .6-.6 1.2-1.2 1.2-.7 0-1.2-.5-1.2-1.2-.1-.7.5-1.2 1.2-1.2M144.1 22.8c.7 0 1.2.5 1.2 1.2 0 .6-.6 1.2-1.2 1.2-.7 0-1.2-.5-1.2-1.2-.1-.7.5-1.2 1.2-1.2M103.7 28.9v4.4h-1.9v-.7c-.5.5-1.1.9-2.1.9-1.5 0-2.7-.9-2.7-2.3 0-2.1 2.3-2.6 4.5-2.6h.2c0-.6-.3-1.1-1.4-1.1-.8 0-1.5.3-2 .7L97.2 27c.8-.8 1.9-1.2 3.2-1.2 1.2 0 1.9.2 2.6.8.6.6.7 1.7.7 2.3m-2 1.2h-.2c-.3 0-1.1 0-1.7.2-.4.1-.7.4-.7.8 0 .5.5.8 1 .8 1 0 1.6-.6 1.6-1.5v-.3M108 26v-2.3h-2.1V26h-1.4v1.7h1.4v3.1c0 1 .2 1.7.6 2.1.4.4 1 .6 1.9.6.6 0 1.1-.1 1.6-.3v-1.6c-.3.1-.6.1-1.1.1-.6 0-.8-.4-.8-.9v-3h1.9V26h-2M162.9 29.8v.6h-5.4c0 .7.9 1.4 1.9 1.4.9 0 1.4-.3 1.9-.8l1.3 1c-.8 1-1.8 1.6-3.2 1.6-2.2 0-4-1.4-4-3.8 0-2 1.2-3.8 3.8-3.8 2.6-.2 3.7 1.9 3.7 3.8m-2.2-1c-.1-.8-.5-1.4-1.6-1.4-.9 0-1.5.6-1.6 1.4h3.2M93.5 22.7v7.2L89 22.7h-2.8v10.6h2.2v-7.4l4.7 7.4h2.6V22.7h-2.2M140.1 26l-1.2 4.5-1.2-4.5h-2.1l-1.2 4.5-1.1-4.5H131l2.3 7.3h2.1l1.2-4.6 1.4 4.6h2l2.3-7.3h-2.2M111.2 26h2.1v7.3h-2.1zM143 26h2.1v7.3H143zM129.7 26.5c-.4-.5-1-.7-1.8-.7s-1.7.4-2.2 1V26h-2v7.3h2.1v-4c0-.5.1-.9.4-1.2.2-.2.5-.4.9-.4.5 0 .7.2.9.4.2.3.3.8.3 1.4v3.7h2.1v-4.6c0-.8-.1-1.5-.7-2.1M154.2 22.7v10.6h-2v-.8c-.5.5-1.2 1-2.3 1-1 0-1.9-.3-2.5-1-.7-.7-1.1-1.7-1.1-2.8 0-2.2 1.5-3.8 3.5-3.8.9 0 1.6.3 2.2.8v-3.9h2.2v-.1zm-2 6.9c0-1-.6-2-1.9-2-1.2 0-1.9 1-1.9 2s.6 2 1.9 2c1.2 0 1.9-1 1.9-2M122.5 29.6c0 2.2-1.7 3.8-4 3.8s-4-1.6-4-3.8c0-2.2 1.7-3.8 4-3.8s4 1.6 4 3.8m-4-1.9c-1.2 0-1.9 1-1.9 2s.6 2 1.9 2c1.2 0 1.9-1 1.9-2s-.7-2-1.9-2M163.8 25.3h.3v-.8h.3l.5.8h.4l-.5-.8c.3 0 .5-.2.5-.5 0-.4-.2-.6-.7-.6h-.7v1.9h-.1zm.4-1.6h.3c.2 0 .5 0 .5.3s-.2.3-.5.3h-.3v-.6zm-1.4.7c0 .9.8 1.7 1.7 1.7.9 0 1.7-.8 1.7-1.7 0-.9-.8-1.7-1.7-1.7-.9 0-1.7.7-1.7 1.7m.3 0c0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4-.8-.1-1.4-.7-1.4-1.4M68.6 12.4v18.3l-2.3-2.2v7c0 1.6-.1 2.7-.9 3.7l-.1.1 2.6 2.4h8.9V12.4h-8.2M49.2 24s3.6 3.4 3.9 3.7c-.3-1.3-.6-2.9.1-4l.4-.6c-.4-2-.3-4.1 1-5.5l.3-.4-5-4.8h-8.8v29.3h8.1V24"
          />
          <path
            d="M67.7 43.9c1.9.1 3.3.3 4.7.4.5.1.7.7.8.9-.7-.2-1.7-.2-1.7-.2l-.1.6s.9-.3 1.9-.3c0 0 2 0 1.9 1.3-.5-.4-1.7-.2-2-.2-1 .2-2 .6-3.1 1.2-1 .5-2.1 1.1-3.4 1.5.6-.5 2-2.2 2-2.2l-2-.7 1.6-1.4-2.5-1.3c.7.3 1.3.4 1.9.4m-2.9-5.2c-.9 1-2.1 2.5-1.8 3.6.2.9 1.5 1.5 1.5 1.5l2.7 1.5-1.8 1.5 2.1.7s-1.9 2-2.2 2.2c-.6.1-1.1.1-1.8.1-4.5 0-10.8-2.7-12.3-2.7-1.9 0-4.2 1.1-4.2 1.1l1.2-1.3s-1.6.3-3.2.2l2.2-1.5c-1.9-.1-3.6-.6-4.4-1.2 0 0 3.6-.3 8.4-.2 1.9 0 5.3.2 5.3.2-1.6-.7-3.2-1.6-3.8-3.2l3.1.5c-1.3-.8-3.2-2.8-3.6-4.4l3.5 1.2c-.6-.7-2.8-2.7-3.1-5l2.7 1.7c-.7-.7-2.7-4.6-2.3-5.7l2.6 1.7c-1.2-1.5-2.7-5.5-1.7-7.1.8 3 2.5 4.1 2.5 4.1-1.1-1.7-3.6-7.5-1.2-10.1.6 5.8 3.1 7.8 3.1 7.8-2-3.3-4.4-13.3 0-15.3-.9 6.4 1.2 9.9 1.2 9.9-1.3-5.9-1.3-14.4 3.2-15.1-2.2 6.3-.6 11.2-.6 11.2-.5-14.8 4.3-14.5 4.3-14.5s-.8 4.2-.8 11.7v21.7c0 1.5-.1 2.4-.8 3.2z"
          />
        </svg>
      </a>
      <nw-private-label-agency-card
        *ngIf="
          producerInfo &&
          producerInfo.agencyLicenseNumber &&
          producerInfo.nameFiledWithCommissioner &&
          producerInfo.residentState
        "
        [producerInfo]="producerInfo"
      ></nw-private-label-agency-card>
      <div class="nw-footer__phone">
        <ng-container *isCustomer>
          <div *ngIf="!isRivian">
            {{ phoneNumber }}
            <span
              *ngIf="privateLabelConfig?.additionalPhoneNumber"
              class="addtl-phone"
              >{{ additionalPhone }}</span
            >
          </div>
          <div *ngIf="isRivian">Rivian: {{ phoneNumber }}</div>
        </ng-container>
      </div>

      <!-- Social Icons/Links -->
      <ul class="nw-footer__social" *ngIf="!privateLabelConfig">
        <li *ngFor="let link of links">
          <a
            [href]="link.url"
            [attr.aria-label]="link.name + '- opens in a new window'"
            target="_blank"
            rel="noopener"
          >
            <div *svgIcon="link.icon"></div>
          </a>
        </li>
      </ul>
    </div>

    <div class="nw-outer-bottom" *ngIf="!privateLabelConfig">
      <a href="https://www.nationwide.com/personal/about-us/">About Us</a
      >&nbsp;&nbsp;|&nbsp;&nbsp;
      <a href="https://www.nationwide.com/personal/about-us/careers/">Careers</a
      >&nbsp;&nbsp;|&nbsp;&nbsp;
      <a href=" https://www.nationwide.com/personal/contact/">Contact Us</a
      >&nbsp;&nbsp;|&nbsp;&nbsp;
      <span id="teconsent" class="nw-footer-link"></span>
    </div>

    <p
      class="nw-text-xs"
      *ngIf="getPrivateLabelAdditionalDisclaimerText(); let addtlDisclaimer"
    >
      {{ addtlDisclaimer }}
    </p>
    <p class="nw-text-xs">
      {{ showDoubleAsterik() }}
      {{ disclaimer }}
      &copy; {{ getCurrentYear() }} Nationwide Mutual Insurance Company.
    </p>
    <p>
      {{ showLifeDisclaimerText() }}
    </p>

    <div class="row collapse">
      <div>
        <p class="nw-text-xs">
          <a
            href="https://www.nationwide.com/personal/privacy-security/index.html"
            >Privacy and Security</a
          >&nbsp;&nbsp;|&nbsp;&nbsp;
          <a
            href="https://www.nationwide.com/personal/about-us/terms-conditions.html"
            >Terms and Conditions</a
          >&nbsp;&nbsp;|&nbsp;&nbsp;
          <a href="https://www.nationwide.com/personal/sitemap/index.html"
            >Sitemap</a
          >
        </p>
      </div>
    </div>
  </div>
</div>
