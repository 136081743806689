import { createSelector } from '@ngrx/store';
import * as fromHomeowners from '@core/store/selectors/homeowners.selector';
import * as fromCondo from '@core/store/selectors/condo.selector';

export const getNonRentalProperty = createSelector(
  fromHomeowners.getHomeownersState,
  fromCondo.getCondoState,
  (HomeownerState, CondoState) =>
    HomeownerState.homeowners !== null
      ? HomeownerState.homeowners
      : CondoState.condo
);
