import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { map, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import * as fromRouter from '@core/store/actions/router.action';

import * as fromServices from '@quote/services/navigation/navigation.service';
import * as fromActions from '@quote/store/actions';
import * as fromCoreActions from '@core/store/actions';
import { NavigationExtras } from '@angular/router';
import { ProductsService } from '@core/services';
import { ErrorHelper } from '@core/services/helpers/error.helper';

@Injectable()
export class NavigationEffects {
  constructor(
    private _actions$: Actions,
    private _navigationService: fromServices.QuoteNavigationService,
    private _productsService: ProductsService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  loadRoutes$ = this._actions$.pipe(
    ofType(fromActions.LOAD_ROUTES),
    switchMap(() => this._navigationService.getBaseNavigationPath()),
    map(navigationPath => new fromActions.LoadRoutesSuccess(navigationPath)),
    catchError(error =>
      of(new fromActions.LoadRoutesFail(this._errorHelper.sanitizeError(error)))
    )
  );

  @Effect()
  navigateForward$ = this._actions$.pipe(
    ofType(fromActions.NAVIGATE_FORWARD),
    map((action: fromActions.NavigateForward) => action.payload),
    map(navigationPath => {
      const url = navigationPath.items[navigationPath.currentIndex].url;
      const extras = { queryParamsHandling: 'merge' } as NavigationExtras;

      return new fromRouter.Go({
        path: [url],
        extras: extras,
      });
    })
  );

  @Effect()
  navigatePrevious$ = this._actions$.pipe(
    ofType(fromActions.NAVIGATE_BACK),
    map((action: fromActions.NavigateBack) => action.payload),
    map(navigationPath => {
      const url = navigationPath.items[navigationPath.currentIndex].url;
      const extras = { queryParamsHandling: 'merge' } as NavigationExtras;
      return new fromRouter.Go({
        path: [url],
        extras: extras,
      });
    })
  );

  @Effect()
  softfall$ = this._actions$.pipe(
    ofType(fromActions.SOFTFALL),
    map((action: fromActions.Softfall) => action.reason),
    map(reason => new fromRouter.Go({ path: ['/softfall'], query: { reason } }))
  );

  @Effect()
  maintenance$ = this._actions$.pipe(
    ofType(fromActions.MAINTENANCE),
    map((action: fromActions.Maintenance) => action.reason),
    map(
      reason => new fromRouter.Go({ path: ['/maintenance'], query: { reason } })
    )
  );

  @Effect()
  updateNavigationPath$ = this._actions$.pipe(
    ofType(fromActions.UPDATE_NAVIGATION_PATH),
    withLatestFrom(
      this._productsService.getSelectedQuoteProductsWithoutErrors()
    ),
    switchMap(([_, products]) => {
      this._navigationService.updateNavigationPath(products);
      return from([]);
    })
  );

  @Effect()
  reactToInitiateFailure$ = this._actions$.pipe(
    ofType(fromCoreActions.QuoteActionTypes.INITIATE_NEW_BUSINESS_FAIL),
    map(() => new fromActions.UpdateNavigationPath())
  );
}
