import { AgencyModel } from '@core/models/agency/agency.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AgencyHelper {
  static adjustAgencyAndProducerCodes(agent: AgencyModel): AgencyModel {
    if (agent.agencyChannel === 'IA' && agent.producerCode) {
      /* IA numbers are of the format 000<5 digit agencyCode> - <3 digit producer code prefixed by any number of 0's>
       * They could be entered as producerCode: 00029900 - 013, or they could
       * be entered as agencyCode: 29900, producerCode: 013, or they could be entered
       * as agencyCode: 00029900, producerCode: 0000000013. Need to account for everything and format things right.
       */
      agent = { ...agent };
      if (agent.producerCode.indexOf('-') !== -1) {
        const [agencyCode, producerCode] = agent.producerCode.split('-');
        agent.producerCode = producerCode.trim();
        agent.agencyCode = agencyCode.trim();
      }

      const length = agent.agencyCode.length;
      const expectedAgencyLength = 8;
      if (length < expectedAgencyLength) {
        agent.agencyCode =
          '0'.repeat(expectedAgencyLength - length) + agent.agencyCode;
      } else if (length > expectedAgencyLength) {
        agent.agencyCode = agent.agencyCode.slice(
          length - expectedAgencyLength
        );
      }

      const expectedProducerLength = 3;
      if (agent.producerCode.length > expectedProducerLength) {
        agent.producerCode = agent.producerCode.slice(
          agent.producerCode.length - expectedProducerLength
        );
      } else {
        agent.producerCode =
          '0'.repeat(expectedProducerLength - agent.producerCode.length) +
          agent.producerCode;
      }
    }
    return agent;
  }
}
