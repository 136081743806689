import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpContentItem } from '@help-content/help-content-item';
import {
  SmartrideInstantConsentText,
  ProductTypes,
} from '@shared/constants/app-constants';
import { ConsentValuesService } from '@core/services';
import { QuoteService } from '@core/services/quote.service';

@Component({
  selector: 'mq-smartride-instant-consent',
  templateUrl: './smartride-instant-consent.component.html',
  styleUrls: ['./smartride-instant-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SmartrideInstantConsentComponent implements OnInit {
  @Input() helpContent: {
    smartrideInstantConsentHelpContent: HelpContentItem;
  };
  smartrideInstantConsentChecked: boolean;
  smartrideInstantConsentText = SmartrideInstantConsentText;
  hasDrivingDataConsent = false;
  modalHeader: string;

  constructor(
    private _consentValuesService: ConsentValuesService,
    private _quoteService: QuoteService,
    private _modalService: NgbModal,
    public modal: NgbActiveModal
  ) {}
  ngOnInit() {
    this.smartrideInstantConsentChecked = true;
  }

  onLearnMoreClicked() {
    this._modalService.open(
      this.helpContent.smartrideInstantConsentHelpContent.component
    );
    return false;
  }

  toggleSmartrideInstantConsent(event: any): void {
    this.smartrideInstantConsentChecked = !this.smartrideInstantConsentChecked;
  }

  updateSmartrideInstantConsent(smartrideInstantConsentChecked) {
    this._consentValuesService.updateSmartrideInstantConsent({
      hasDrivingDataConsent: smartrideInstantConsentChecked,
      hasViewedDrivingDataConsent: true,
    });
    this._quoteService.dispatchPatchQuote(ProductTypes.AUTO, {
      hasDrivingDataConsent: smartrideInstantConsentChecked,
    });
  }
}
