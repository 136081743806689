<!-- table headers -->
<ngb-accordion
  [activeIds]="activePanelIds"
  class="custom-accordion"
  (panelChange)="logToSplunkIfEligible($event)"
>
  <ngb-panel [id]="formatTitle(title)">
    <ng-template ngbPanelTitle>
      <div class="accordion-title" header>
        <mq-accordion-header
          [icon]="icon"
          [title]="title"
        ></mq-accordion-header>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <div [formGroup]="form">
        <ngb-alert
          *ngIf="notification"
          type="info"
          [dismissible]="false"
          class="alert-display nw-container"
        >
          <a class="icon info"></a>
          <span>{{ notification }}</span>
        </ngb-alert>
        <div
          class="row coverage-container"
          *ngFor="let coverage of coverageDisplays"
          [ngClass]="{
            'auto-updated':
              coverage.coverageId === extraUpdatedCoverage && !appLoading
          }"
        >
          <div class="col">
            <div class="d-flex">
              <div
                *ngIf="shouldShowCoverageError(coverage)"
                class="alert alert-danger custom-alert-dismissable"
                role="alert"
              >
                <a class="icon error"></a>
                <div class="custom-alert-dismissable-messages">
                  <ng-container *ngFor="let coverageError of coverageErrors">
                    <span>{{
                      (coverageError.message?.split('['))[0] | unCamelCase
                    }}</span>
                    <br />
                  </ng-container>
                </div>
                <div
                  class="custom-alert-dismissable-close"
                  (click)="removeErrors()"
                >
                  <a class="icon close"></a>
                </div>
              </div>
              <div
                *ngIf="isIncreasedOtherStructuresApplicable(coverage)"
                class="alert alert-danger"
                role="alert"
              >
                <a class="icon error"></a>

                <div class="custom-alert-dismissable-messages">
                  <span
                    >Other Structures cannot be increased more than 30% of
                    Dwelling amount. If more than 30% is needed, please contact
                    an agent 1-855-550-0768.
                  </span>
                </div>
              </div>
            </div>

            <!-- START coverage row -->
            <div class="col coverage-row">
              <div
                class="coverage-item d-flex justify-content-end"
                *ngIf="
                  isVehicleMilesState &&
                  isAutoProduct() &&
                  vehicleId &&
                  vehicleMiles &&
                  coverage.coverageId === 'COMP'
                "
              >
                <div class="coverage-item-description">
                  <inline-help
                    [labelInnerHTML]="'Annual Mileage'"
                    fieldId="annualMiles-{{ vehicleId }}"
                    [helpTextInnerHTML]="helpText"
                  ></inline-help
                  >{{ helpTextALL }}
                </div>
                <div class="coverage-item-terms">
                  <div class="term-row flex-row-reverse">
                    <div class="term-value">
                      <ng-container class="mr-3">
                        <div class="input-group">
                          <input
                            type="text"
                            id="annualMiles-{{ vehicleId }}"
                            class="form-control"
                            placeholder="Enter miles driven"
                            [formControl]="annualMiles"
                            mask="comma_separator.0"
                            [dropSpecialCharacters]="true"
                            maxlength="7"
                            (blur)="updateAnnualMiles(vehicleId)"
                          />
                            <div class="input-group-text"></div>
                          <span *ngIf="annualMiles?.invalid" style="color: red">
                            Enter the estimated annual miles to continue.
                          </span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="coverage-cost-col">&nbsp;</div>
              </div>
              <div class="coverage-item d-flex">
                <div class="coverage-item-description">
                  <inline-help
                    [labelInnerHTML]="coverage.name"
                    [fieldId]="coverage.coverageId"
                    [helpTextInnerHTML]="coverage.helpText"
                  ></inline-help>
                  <ng-container
                    *ngIf="
                      coverage.available &&
                      coverage.terms &&
                      coverage.terms.length > 1 &&
                      !isDeclined(coverage) &&
                      (!showWorkersCompensationHelpText ||
                        coverage.coverageId !== 'WorkersCompensation')
                    "
                  >
                    <ng-container
                      *ngFor="
                        let cvgTerm of coverage.terms;
                        let counter = index
                      "
                    >
                      <ng-container *ngIf="counter > 0">
                        <!-- Secondary coverage description -->
                        <div class="secondary-term-description">
                          <inline-help
                            [labelInnerHTML]="cvgTerm.type"
                            [fieldId]="cvgTerm.code"
                            [helpTextInnerHTML]="cvgTerm.helpText"
                          ></inline-help>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      coverage.coverageId === 'BasicCoverage' &&
                      showPropertyInfo &&
                      !isQuote
                    "
                  >
                    <p>
                      <span
                        class="modal-link"
                        (click)="propertyInfoModalToggled.emit($event)"
                        >Edit Dwelling Info</span
                      >
                    </p>
                  </ng-container>
                  <!-- Dwelling Replacement Cost Options -->
                  <ng-container
                  *ngIf="
                  isQuote &&
                  coverage.coverageId === 'DwellingReplacementCost200' &&
                  showDwellingReplacementCost
                  "
                  >
                    <p>
                    </p>
                  </ng-container>
                  <!--   -->
                  <!-- PIP Work Loss Waiver -->
                  <div
                    class="col-sm-12"
                    *ngIf="
                      coverage.coverageId == CoverageIds.PIP &&
                      showPIPWorkLossWaiver
                    "
                  >
                    <div class="row" *ngIf="!(isAgentExperience$ | async)">
                      <div class="alert alert-info mb-4">
                        <a class="icon info"></a>
                        <span>{{ pipWorkLossWaiverCustomerMessage }}</span>
                      </div>
                    </div>
                    <div class="row" *ngIf="isAgentExperience$ | async">
                      <div class="alert alert-info mb-4">
                        <a class="icon info"></a>
                        <span>{{ pipWorkLossWaiverAgentMessage }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- Entire Household PIP Excluded -->
                  <div
                    class="col-sm-12"
                    *ngIf="
                      coverage.coverageId == CoverageIds.PIP &&
                      showPIPEntireHouseholdExcluded
                    "
                  >
                    <div class="row" *ngIf="!(isAgentExperience$ | async)">
                      <div class="alert alert-info mb-4">
                        <a class="icon info"></a>
                        <span>{{
                          pipEntireHouseholdExcludedCustomerMessage
                        }}</span>
                      </div>
                    </div>
                    <div class="row" *ngIf="isAgentExperience$ | async">
                      <div class="alert alert-info mb-4">
                        <a class="icon info"></a>
                        <span>{{
                          pipEntireHouseholdExcludedAgentMessage
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="coverage-item-terms">
                  <!-- START unavailable -->
                  <ng-container *ngIf="!coverage.available">
                    <div class="term-row">
                      <div class="term-description"></div>
                      <div class="term-value" id="{{ coverage.coverageId }}">
                        Not Available
                      </div>
                    </div>
                  </ng-container>
                  <!-- END unavailable -->

                  <!-- START no terms -->
                  <ng-container *ngIf="coverage.available && !coverage.terms">
                    <div class="term-row">
                      <div class="term-description"></div>
                      <div class="term-value">
                        <ng-container
                          *ngIf="coverage.editable && !coverage.mandatory"
                        >
                          <select
                            id="{{ coverage.coverageId }}"
                            *ngIf="!coverage.terms"
                            [formControlName]="getFormControlName(coverage)"
                            class="form-control form-select"
                            [attr.aria-label]="coverage.description"
                            (change)="
                              handleCoverageChange(
                                coverage,
                                'selected',
                                $event.target.value
                              )
                            "
                          >
                            <option [value]="false">Decline</option>
                            <option [value]="true">Accept</option>
                          </select>
                        </ng-container>
                        <ng-container *ngIf="!!coverage.mandatory">
                          Included
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>

                  <!-- END no terms -->

                  <!-- START terms -->
                  <ng-container *ngIf="coverage.available && coverage.terms">
                    <ng-container
                      *ngFor="let term of coverage.terms; let index = index"
                    >
                      <div class="term-row">
                        <div class="term-description">
                          {{ term.description }}
                        </div>
                        <div class="term-value">
                          <!-- term options select -->
                          <ng-container
                            *ngIf="term.options && term.options.length > 1"
                          >
                            <select
                              id="{{ coverage.coverageId }}{{ term.code }}"
                              [formControlName]="
                                getFormControlName(coverage, term.code)
                              "
                              class="form-control form-select"
                              [attr.aria-label]="coverage.name"
                              (change)="
                                handleCoverageChange(
                                  coverage,
                                  term.code,
                                  $event.target.value
                                )
                              "
                            >
                              <option
                                *ngFor="
                                  let option of term.options
                                    | filterInvalidOptions
                                      : coverage
                                      : coverageDisplays
                                "
                                [value]="option.value"
                              >
                                {{ option.description }}
                              </option>
                              <option
                                *ngIf="
                                  coverage.editable &&
                                  !coverage.mandatory &&
                                  index == 0 &&
                                  isUMBIApplicable(coverage)
                                "
                                [value]="false"
                              >
                                Decline
                              </option>
                            </select>
                          </ng-container>

                          <!-- single term option, no select -->
                          <ng-container
                            *ngIf="term.options && term.options.length === 1"
                          >
                            <!-- show select for editable non-mandatory coverages i.e. ID Theft-->
                            <ng-container
                              *ngIf="
                                coverage.editable &&
                                  !coverage.mandatory &&
                                  index == 0;
                                else defaultOption
                              "
                            >
                              <select
                                id="{{ coverage.coverageId }}"
                                [formControlName]="
                                  getFormControlName(coverage, term.code)
                                "
                                class="form-control form-select"
                                [attr.aria-label]="coverage.description"
                                (change)="
                                  handleCoverageChange(
                                    coverage,
                                    term.code,
                                    $event.target.value
                                  )
                                "
                              >
                                <option [value]="term.options[0].value">
                                  {{ term.options[0].description }}
                                </option>
                                <option [value]="false">Decline</option>
                              </select>
                            </ng-container>
                            <!-- show description and no select for mandatory coverages -->
                            <ng-template #defaultOption>
                              {{ term.options[0].description }}
                            </ng-template>
                          </ng-container>

                          <!-- term input -->
                          <ng-container
                          *ngIf="
                          !term.options &&
                          term.editable &&
                          (!showWorkersCompensationHelpText ||
                          coverage.coverageId !== 'WorkersCompensation')
                          "
                          >
                            <label>
                          <div class="money-input-wrapper">
                                <input
                                  class="form-control custom-input"
                                  (keypress)="numberOnly($event)"
                                  type="text"
                                  maxlength="6"
                                  [attr.aria-label]="coverage.description"
                                  [formControlName]="
                                    getFormControlName(coverage, term.code)
                                  "
                                  (change)="
                                    handleCoverageChange(
                                      coverage,
                                      term.code,
                                      $event.target.value
                                    )
                                  "
                                />
                              </div>
                              <div
                                *ngIf="
                                  shouldShowInputError(
                                    coverage,
                                    term.code,
                                    'min'
                                  )
                                "
                                class="alert alert-danger"
                                role="alert"
                              >
                                <a class="icon error"></a>
                                <div class="custom-alert-dismissable-messages">
                                  <span
                                    >Minimum required amount is
                                    {{
                                      getMinimumFieldAmount(coverage, term.code)
                                    }}</span
                                  >
                                </div>
                              </div>
                              <div
                                *ngIf="
                                  shouldShowInputError(
                                    coverage,
                                    term.code,
                                    'max'
                                  )
                                "
                                class="alert alert-danger"
                                role="alert"
                              >
                                <a class="icon error"></a>
                                <div class="custom-alert-dismissable-messages">
                                  <span
                                    >Maximum amount allowed is
                                    {{
                                      getMaximumFieldAmount(coverage, term.code)
                                    }}</span
                                  >
                                </div>
                              </div>
                              <div
                                *ngIf="
                                  shouldShowInputError(
                                    coverage,
                                    term.code,
                                    'required'
                                  )
                                "
                                class="alert alert-danger"
                                role="alert"
                              >
                                <a class="icon error"></a>
                                <div class="custom-alert-dismissable-messages">
                                  <span>Input required</span>
                                </div>
                              </div>
                            </label>
                          </ng-container>

                          <!-- display non-select value -->
                          <ng-container *ngIf="!term.options && !term.editable">
                            <!-- coverage is editable but not mandatory and the term is not editable i.e. MineSubsidence -->
                            <ng-container
                              *ngIf="
                                coverage.editable &&
                                !coverage.mandatory &&
                                term.type !== 'Deductible' &&
                                !(
                                  term.type === 'Full Coverage Safety Glass' &&
                                  isFullSafetyGlassSelectboxRemovableState
                                )
                              "
                            >
                              <select
                                id="{{ coverage.coverageId }}"
                                [formControlName]="getFormControlName(coverage)"
                                class="form-control form-select"
                                [attr.aria-label]="coverage.description"
                                (change)="
                                  handleCoverageChange(
                                    coverage,
                                    term.code,
                                    $event.target.value
                                  )
                                "
                              >
                                <option
                                  [value]="false"
                                  [selected]="
                                    !getSelectedValue(
                                      term.code,
                                      coverage.selectedValue
                                    )
                                  "
                                >
                                  Decline
                                </option>
                                <option
                                  [value]="true"
                                  [selected]="
                                    !!getSelectedValue(
                                      term.code,
                                      coverage.selectedValue
                                    )
                                  "
                                >
                                  Accept
                                </option>
                              </select>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                !isNumber(
                                  getSelectedValue(
                                    term.code,
                                    coverage.selectedValue
                                  )
                                )
                              "
                            >
                              {{
                                getSelectedValue(
                                  term.code,
                                  coverage.selectedValue
                                )
                              }}
                            </ng-container>
                            <ng-container
                              *ngIf="
                                isNumber(
                                  getSelectedValue(
                                    term.code,
                                    coverage.selectedValue
                                  )
                                )
                              "
                            >
                              {{
                                getSelectedValue(
                                  term.code,
                                  coverage.selectedValue
                                ) | currency: 'USD':'symbol':'1.0-0'
                              }}
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <!-- END terms -->
                </div>

                <div class="coverage-cost-col" *ngIf="showCoveragePrices">
                  &nbsp;
                  <div
                    class="coverage-cost"
                    *ngIf="shouldShowMonthlyCoverageCost(coverage)"
                  >
                    {{
                      calculateMonthlyCoverageCost(coverage)
                        | currency: 'USD':'symbol':'1.2-2'
                    }}
                  </div>
                </div>
              </div>
            </div>
            <!-- END coverage row -->

            <!-- protection boost content -->
            <div
              *ngIf="coverage.coverageId === CoverageIds.ProtectionBoost"
              class="col"
            >
              <span class="protection-boost-content"
                >Selecting Protection Boost increases specified special limits
                of liability.</span
              >
            </div>
            <div *isAgent>
              <div
                *ngIf="
                  (offeringType$ | async) === defaultOfferingType &&
                  selectedProduct?.id === homeownersProductId
                "
              >
                <div
                  *ngIf="
                    showAdditionalCoveragesDisplayHelpText &&
                    coverage.coverageId === CoverageIds.UnscheduledMoney
                  "
                  class="col additional-coverages-display-helptext-holder"
                >
                  <span class="additional-coverages-display-helptext">{{
                    MoneyCoverageAdditionalDisplayText
                  }}</span>
                </div>
                <div
                  *ngIf="
                    showAdditionalCoveragesDisplayHelpText &&
                    coverage.coverageId === CoverageIds.UnscheduledSecurities
                  "
                  class="col additional-coverages-display-helptext-holder"
                >
                  <span class="additional-coverages-display-helptext">{{
                    SecuritiesCoverageAdditionalDisplayText
                  }}</span>
                </div>
                <div
                  *ngIf="
                    showAdditionalCoveragesDisplayHelpText &&
                    coverage.coverageId ===
                      CoverageIds.UnscheduledJewelryWatchesFurs
                  "
                  class="col additional-coverages-display-helptext-holder"
                >
                  <span class="additional-coverages-display-helptext">{{
                    JewelryWatchesFursCoverageAdditionalDisplayText
                  }}</span>
                </div>
                <div
                  *ngIf="
                    showAdditionalCoveragesDisplayHelpText &&
                    coverage.coverageId === CoverageIds.UnscheduledSilverware
                  "
                  class="col additional-coverages-display-helptext-holder"
                >
                  <span class="additional-coverages-display-helptext">{{
                    SilverwareCoverageAdditionalDisplayText
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="col"
              *ngIf="
                showWorkersCompensationHelpText &&
                coverage.coverageId === 'WorkersCompensation'
              "
            >
              <div class="row" *ngIf="!(isAgentExperience$ | async)">
                <span class="worker-compensation-helptext">{{
                  WorkersCompensationCustomerHelpText
                }}</span>
              </div>
              <div class="row" *ngIf="isAgentExperience$ | async">
                <span class="worker-compensation-helptext">{{
                  WorkersCompensationAgentHelpText
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row coverage-container"
          *ngIf="vehicleId && isAutoProduct() && !isVehicleMilesState"
        >
          <div class="col">
            <div class="col coverage-row">
              <div class="coverage-item d-flex justify-content-end">
                <div class="coverage-item-description">
                  <inline-help
                    labelInnerHTML="Estimated Annual Mileage"
                    fieldId="annualMiles-{{ vehicleId }}"
                    [helpTextInnerHTML]="annualMileageHelpText"
                  ></inline-help>
                </div>
                <div class="coverage-item-terms">
                  <div class="term-row flex-row-reverse">
                    <div class="term-value">
                      <ng-container class="mr-3">
                        <div class="input-group">
                          <input
                            type="text"
                            id="annualMiles-{{ vehicleId }}"
                            class="form-control"
                            placeholder="Enter miles driven"
                            [formControl]="annualMiles"
                            mask="comma_separator.0"
                            [dropSpecialCharacters]="true"
                            maxlength="7"
                            (blur)="updateAnnualMiles(vehicleId)"
                          />
                            <div class="input-group-text"></div>
                          <span *ngIf="annualMiles.invalid" style="color: red"
                            >Enter the estimated annual miles to continue.</span
                          >
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="coverage-cost-col">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
