import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CookieService } from 'ngx-cookie-service';

// bootstrap
import { AppComponent } from './containers/app/app.component';

// routes
import { AppRoutingModule } from './app-routing.module';

// components
import { NonStandardBridgeComponent } from './containers/non-standard-bridge/non-standard-bridge.component';
import { NwHeaderComponent } from './containers/nw-header/nw-header.component';
import { NwFooterComponent } from './containers/nw-footer/nw-footer.component';
import { NeedHelpComponent } from './containers/need-help/need-help.component';
import { AppBannerComponent } from './containers/app-banner/app-banner.component';
import { AgencyCardComponent } from './containers/agency-card/agency-card.component';
import { PrivateLabelAgencyCardComponent } from './containers/private-label-agency-card/private-label-agency-card.component';

// modules
import { CoreModule } from './core/core.module';
import { NgxMaskModule } from 'ngx-mask';
import { RetrieveModule } from './retrieve/retrieve.module';

import {
  IsAgentModule,
  IsHomeOwnerModule,
  IsCustomerModule,
  IsIndependentAgentModule,
  SvgIconModule,
  RemoveIfFeatureNotEffectiveModule,
  LoadingSpinnerModule,
} from './shared';
import { SoftfallComponent } from './containers/softfall/softfall.component';
import { SearchModule } from './search/search.module';
import { AlertComponent } from './containers/page-alerts/alert.component';
import { APP_BASE_HREF } from '@angular/common';
import { AppRouteUtils } from '@shared/utils/app-route.utils';
import { MaintenanceComponent } from './containers/maintenance/maintenance.component';
import { TextInputComponent } from './shared/components/text-input/text-input.component';
import { IsLifeModule } from '@shared/directives/is-life/is-life.module';
import { AdminOverlayComponent } from '@admin/admin-overlay/admin-overlay.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    NwHeaderComponent,
    NwFooterComponent,
    NeedHelpComponent,
    AppBannerComponent,
    AgencyCardComponent,
    PrivateLabelAgencyCardComponent,
    SoftfallComponent,
    NonStandardBridgeComponent,
    AlertComponent,
    MaintenanceComponent,
    TextInputComponent,
    AdminOverlayComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    CoreModule.forRoot(),
    IsAgentModule,
    IsCustomerModule,
    IsHomeOwnerModule,
    IsLifeModule,
    IsIndependentAgentModule,
    SvgIconModule,
    NgxMaskModule.forRoot(),
    RetrieveModule,
    SearchModule,
    NgbAlertModule,
    RemoveIfFeatureNotEffectiveModule,
    LoadingSpinnerModule,
    ReactiveFormsModule,
  ],
  providers: [
    CookieService,
    {
      provide: APP_BASE_HREF,
      useFactory: AppRouteUtils.getBaseHref,
    },
  ],
  exports: [AppBannerComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
