import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromLocationExposure from '@core/store/actions/location-exposure.action';
import { LocationExposureEntity } from '@core/models/entities/location-exposure.entity';

export interface LocationExposureState
  extends EntityState<LocationExposureEntity> {
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<LocationExposureEntity> =
  createEntityAdapter<LocationExposureEntity>({
    selectId: location => location.locationExposureId,
  });

export function reducer(
  state = adapter.getInitialState({
    loaded: false,
    loading: false,
  }),
  action: fromLocationExposure.LocationExposureActions
): LocationExposureState {
  switch (action.type) {
    case fromLocationExposure.LocationExposureActionTypes
      .ADD_LOCATION_EXPOSURE: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromLocationExposure.LocationExposureActionTypes
      .ADD_LOCATION_EXPOSURE_FAIL: {
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    }

    case fromLocationExposure.LocationExposureActionTypes
      .ADD_LOCATION_EXPOSURE_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
      });
    }
    case fromLocationExposure.LocationExposureActionTypes
      .ADD_ALL_LOCATION_EXPOSURE_SUCCESS: {
      return adapter.setAll(action.payload, {
        ...state,
        loaded: true,
        loading: false,
      });
    }

    case fromLocationExposure.LocationExposureActionTypes
      .UPDATE_LOCATION_EXPOSURE_SUCCESS: {
      return adapter.updateOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
      });
    }

    default: {
      return state;
    }
  }
}
