import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductComponent } from './product/product.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { IsCustomerModule } from 'app/shared/directives/is-customer/is-customer.module';

@NgModule({
  imports: [CommonModule, IsCustomerModule],
  exports: [ProductComponent, ProductsListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ProductComponent, ProductsListComponent],
  providers: [],
})
export class ProductsModule {}
