<div class="items-list">
  <ng-content></ng-content>
</div>

<button class="icon-button right" *ngIf="show()" (click)="scrollRight()">
  <a class="icon right-arrow"></a>
</button>
<button class="icon-button left" *ngIf="show()" (click)="scrollLeft()">
  <a class="icon left-arrow"></a>
</button>
