import { Injectable } from '@angular/core';
import {
  InitiateAutoExtraParameters,
  InitiateExtraParameters,
  InitiatePowersportsExtraParameters,
} from '@core/models/auto/quotes/initiate-auto-extra-parameters';
import * as fromActions from '@core/store/actions';
import { DriverIncidentEntity } from '@core/models/entities/driver-incident.entity';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { MoreDetailsViewModel } from '@core/models/view/more-details.model';
import { McpContactPoint } from '@core/models/modify-contact-point/modify-contact-point-request.model';
import { DatePipe } from '@angular/common';
import {
  DEFAULT_ID,
  DriverRelationToPNI,
  GoodDriverDisplayByKey,
  Infractiontype,
  MaritalStatusToDsmCodes,
  DEFAULT_OFFERING_TYPE,
  PolicyholderTypes,
  ProductTypes,
  ModifierNames,
} from '@shared/constants/app-constants';
import { DateUtils } from '@shared/utils/date.utils';
import { Action } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class MoreDetailsHelper {
  monthLength = 30;
  // DSM
  buildPolicyholderEntity(model: MoreDetailsViewModel): PolicyholderEntity {
    return {
      ...model.policyholder,
      policyHolderId: DEFAULT_ID,
      policyHolderType: PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED,
      addressId: model.address.addressId,
      person: {
        ...model.policyholder.person,
        gender:
          model.sex.substring(0, 1) === 'n' ? null : model.sex.substring(0, 1),
        dateOfBirth: DateUtils.formatDateToDSM(model.dateOfBirth),
        maritalStatus: MaritalStatusToDsmCodes[model.maritalStatus],
        driverOccupation: model.driverOccupation,
        infractionDesc: model.infractionDesc,
        hasMotorcycleEndorsement: model.hasMotorcycleEndorsement,
        endorsementDate: model.endorsementDate,
        employmentInformation: {
          currentEmployment: model.employmentInformation.currentEmployment,
          fullTimeEmployment: model.employmentInformation.fullTimeEmployment,
        },
        ageFirstLicensed: !model.ageFirstLicensed
          ? '16'
          : model.ageFirstLicensed,
        currentEmployment: model.employmentInformation.currentEmployment,
        fullTimeEmployment: model.employmentInformation.fullTimeEmployment,
      },
      hasMotorcycleEndorsement: model.hasMotorcycleEndorsement,
      endorsementDate: model.endorsementDate,
      yearsOfMotorcycleAndOffRoadExp: model.yearsOfMotorcycleAndOffRoadExp,
      emailAddress: model.email,
      homeNumber: model.phone,
      tcpaConsent: model.consent,
    } as PolicyholderEntity;
  }

  buildDriverEntity(
    policyHolder: PolicyholderEntity,
    model: MoreDetailsViewModel,
    productId: string
  ): DriverEntity {
    return {
      person: {
        ...policyHolder.person,
        gender: model.sex?.[0],
        dateOfBirth: DateUtils.formatDateToDSM(model.dateOfBirth),
      },
      driverId: policyHolder.driverId,
      powersportsDriverId: policyHolder.powersportsDriverId,
      driverType: 'Driver',
      relationToPrimaryNamedInsured: DriverRelationToPNI.PNI,
      isFinancialFilingRequired: false,
      policyHolderId:
        productId === ProductTypes.AUTO
          ? policyHolder.policyHolderIds[ProductTypes.AUTO]
          : policyHolder.policyHolderIds[ProductTypes.POWERSPORTS],
      licenseState:
        model.pniDriver && model.pniDriver.licenseState
          ? model.pniDriver.licenseState
          : model.address.state,
      productId: productId,
      ageFirstLicensed: !model.ageFirstLicensed ? '16' : model.ageFirstLicensed,
      yearsOfMotorcycleAndOffRoadExp: model.yearsOfMotorcycleAndOffRoadExp,
      hasGraduatedCollege: model.hasGraduatedCollege,
      isLivingWithNWInsuredParent: model.isLivingWithNWInsuredParent,
      infractions: this.getInfractions(model),
      goodStudent: model.goodStudent,
      employmentInformation: {
        currentEmployment: model.employmentInformation.currentEmployment,
        fullTimeEmployment: model.employmentInformation.fullTimeEmployment,
      },
      driverOccupation: model.driverOccupation,
      driverTraining: model.defensiveDrivingCourse === 'Y' ? true : false,
      advancedDriverTraining:
        model.defensiveDrivingCourse === 'Y' &&
        model.advancedDefensiveDrivingCourse === 'Y'
          ? true
          : false,
      trainingCourseCompletionDate: DateUtils.formatDateToDSM(
        model.defensiveDrivingCourseDate
      ),
      advancedTrainingCourseCompletionDate:
        model.defensiveDrivingCourse === 'Y'
          ? DateUtils.formatDateToDSM(model.advancedDefensiveDrivingCourseDate)
          : null,
      usArmedForces:
        model.defensiveDrivingCourse === 'Y' &&
        model.usArmedForcesDrivingCourse === 'Y'
          ? true
          : false,
      hasMotorcycleEndorsement: model.hasMotorcycleEndorsement,
      endorsementDate: model.endorsementDate,
      healthCarePlan: model.healthCarePlan,
      isPersonalInjuryProtectionExcluded: model.isPipExcluded,
      safetyCourse: model.stateApprovedSafetyCourse === 'Y' ? true : false,
      safetyCourseCompletionDate: DateUtils.formatDateToDSM(
        model.defensiveDrivingCourseDate
      ),
    } as DriverEntity;
  }

  buildExtraParameters(model: MoreDetailsViewModel): InitiateExtraParameters {
    return {
      associateNumber:
        model.isAssociate === 'Y' && model.associateNumber
          ? model.associateNumber
          : null,
      offeringType: !model.offeringType
        ? DEFAULT_OFFERING_TYPE
        : model.offeringType,
    };
  }

  buildAutoExtras(model: MoreDetailsViewModel): InitiateAutoExtraParameters {
    return {
      hasGraduatedCollege: model.hasGraduatedCollege,
      isLivingWithNWInsuredParent: model.isLivingWithNWInsuredParent,
      infractions: this.getInfractions(model),
      driverTraining: model.defensiveDrivingCourse === 'Y' ? true : false,
      advancedDriverTraining:
        model.defensiveDrivingCourse === 'Y' &&
        model.advancedDefensiveDrivingCourse === 'Y'
          ? true
          : false,
      trainingCourseCompletionDate: DateUtils.formatDateToDSM(
        model.defensiveDrivingCourseDate
      ),
      advancedTrainingCourseCompletionDate:
        model.defensiveDrivingCourse === 'Y'
          ? DateUtils.formatDateToDSM(model.advancedDefensiveDrivingCourseDate)
          : null,
      usArmedForces:
        model.defensiveDrivingCourse === 'Y' &&
        model.usArmedForcesDrivingCourse === 'Y'
          ? true
          : false,
      goodStudent: model.goodStudent,
      ageFirstLicensed: !model.ageFirstLicensed ? '16' : model.ageFirstLicensed,
      employmentInformation: {
        currentEmployment: model.employmentInformation.currentEmployment,
        fullTimeEmployment: model.employmentInformation.fullTimeEmployment,
      },
      driverOccupation: model.driverOccupation,
      currentEmployment: model.employmentInformation.currentEmployment,
      fullTimeEmployment: model.employmentInformation.fullTimeEmployment,
      groupOccupation: model.groupOccupation,
      healthCarePlan: model.healthCarePlan,
      isPersonalInjuryProtectionExcluded: model.isPipExcluded,
      associateNumber: model.associateNumber ? model.associateNumber : null,
      gender: model.sex?.[0],
      dateOfBirth: model.dateOfBirth,
    } as InitiateAutoExtraParameters;
  }

  buildPowersportsExtraParameters(
    model: MoreDetailsViewModel
  ): InitiatePowersportsExtraParameters {
    return {
      safetyCourse: model.stateApprovedSafetyCourse === 'Y' ? true : false,
      safetyCourseCompletionDate: DateUtils.formatDateToDSM(
        model.defensiveDrivingCourseDate
      ),
      driverTraining: model.defensiveDrivingCourse === 'Y' ? true : false,
      advancedDriverTraining:
        model.defensiveDrivingCourse === 'Y' &&
        model.advancedDefensiveDrivingCourse === 'Y'
          ? true
          : false,
      trainingCourseCompletionDate: DateUtils.formatDateToDSM(
        model.defensiveDrivingCourseDate
      ),
      advancedTrainingCourseCompletionDate:
        model.defensiveDrivingCourse === 'Y'
          ? DateUtils.formatDateToDSM(model.advancedDefensiveDrivingCourseDate)
          : null,
      goodStudent: model.goodStudent,
      ageFirstLicensed: !model.ageFirstLicensed ? '16' : model.ageFirstLicensed,
      yearsOfMotorcycleAndOffRoadExp: model.yearsOfMotorcycleAndOffRoadExp,
      hasGraduatedCollege: model.hasGraduatedCollege,
      isLivingWithNWInsuredParent: model.isLivingWithNWInsuredParent,
      hasMotorcycleEndorsement: model.hasMotorcycleEndorsement,
      endorsementDate: model.endorsementDate,
      gender: model.sex?.[0],
      dateOfBirth: model.dateOfBirth,
    } as InitiatePowersportsExtraParameters;
  }

  private booleanFromYnNull(input: string): boolean {
    switch (input) {
      case 'Y':
        return true;
      case 'N':
        return false;
      default:
        return null;
    }
  }

  getContactPoint(formFields: any): McpContactPoint {
    return {
      consent: !!formFields.consent,
      phoneNumber: formFields.phone,
    } as McpContactPoint;
  }

  buildDriverTrainingDiscountEntity(
    eligibleDiscountId: string,
    driver: DriverEntity
  ): EligibleDiscountEntity {
    const entity = this.buildDriverEligibleDiscountEntity(
      eligibleDiscountId,
      driver
    );
    entity.qualifyingInformation.trainingCourseCompletionDate =
      driver.trainingCourseCompletionDate;

    return entity;
  }

  buildAdvancedDriverTrainingDiscountEntity(
    eligibleDiscountId: string,
    driver: DriverEntity
  ): EligibleDiscountEntity {
    const entity = this.buildAdvancedDriverEligibleDiscountEntity(
      eligibleDiscountId,
      driver
    );
    entity.qualifyingInformation.advancedTrainingCourseCompletionDate =
      driver.advancedTrainingCourseCompletionDate;

    return entity;
  }

  buildAdvancedDriverEligibleDiscountEntity(
    eligibleDiscountId: string,
    driver: DriverEntity
  ): EligibleDiscountEntity {
    return {
      eligibleDiscountId,
      modelId:
        driver.productId === ProductTypes.AUTO
          ? driver.driverId
          : driver.powersportsDriverId,
      productId: driver.productId,
      selectedOptionValue: driver.advancedDriverTraining + '',
      qualifyingInformation: {},
    };
  }

  buildUSArmedForcesDiscountEntity(
    eligibleDiscountId: string,
    driver: DriverEntity
  ): EligibleDiscountEntity {
    const entity = this._buildUSArmedForcesEligibleDiscountEntity(
      eligibleDiscountId,
      driver
    );
    return entity;
  }

  buildSafetyCourseDiscountEntity(
    eligibleDiscountId: string,
    driver: DriverEntity
  ): EligibleDiscountEntity {
    const entity = this.buildSafetyCourseEligibleDiscountEntity(
      eligibleDiscountId,
      driver
    );
    entity.qualifyingInformation.trainingCourseCompletionDate =
      driver.trainingCourseCompletionDate;

    return entity;
  }

  private _buildUSArmedForcesEligibleDiscountEntity(
    eligibleDiscountId: string,
    driver: DriverEntity
  ): EligibleDiscountEntity {
    return {
      eligibleDiscountId,
      modelId: driver.driverId,
      productId: driver.productId,
      selectedOptionValue: driver.usArmedForces + '',
    };
  }

  buildGoodStudentDiscountEntity(
    eligibleDiscountId: string,
    driver: DriverEntity
  ): EligibleDiscountEntity {
    const entity = this.buildGoodStudentEligibleDiscountEntity(
      eligibleDiscountId,
      driver
    );
    return entity;
  }

  buildGoodStudentEligibleDiscountEntity(
    eligibleDiscountId: string,
    driver: DriverEntity
  ): EligibleDiscountEntity {
    return {
      eligibleDiscountId,
      modelId:
        driver.productId === ProductTypes.AUTO
          ? driver.driverId
          : driver.powersportsDriverId,
      productId: driver.productId,
      selectedOptionValue: driver.goodStudent + '',
    };
  }

  buildDriverEligibleDiscountEntity(
    eligibleDiscountId: string,
    driver: DriverEntity
  ): EligibleDiscountEntity {
    return {
      eligibleDiscountId,
      modelId:
        driver.productId === ProductTypes.AUTO
          ? driver.driverId
          : driver.powersportsDriverId,
      productId: driver.productId,
      selectedOptionValue: driver.driverTraining + '',
      qualifyingInformation: {},
    };
  }

  buildSafetyCourseEligibleDiscountEntity(
    eligibleDiscountId: string,
    driver: DriverEntity
  ): EligibleDiscountEntity {
    return {
      eligibleDiscountId,
      modelId:
        driver.productId === ProductTypes.AUTO
          ? driver.driverId
          : driver.powersportsDriverId,
      productId: driver.productId,
      selectedOptionValue: driver.safetyCourse + '',
      qualifyingInformation: {},
    };
  }

  // TODO - Move this to a service
  private getInfractions(model): DriverIncidentEntity[] {
    if (
      model.infractionDesc &&
      model.infractionDesc !== Infractiontype.Excellent
    ) {
      return [
        {
          infractionDesc: GoodDriverDisplayByKey[model.infractionDesc],
          infractionType: Infractiontype[model.infractionType],
          occurrenceDate: this.getOccurrenceDate(),
        },
      ];
    } else {
      return null;
    }
  }

  private getOccurrenceDate() {
    const initialEffectiveDate = new Date(
      DateUtils.setInitialPolicyEffectiveDate()
    );
    const occurrenceDate = new DatePipe('en-US').transform(
      new Date(
        initialEffectiveDate.setDate(
          initialEffectiveDate.getDate() - this.monthLength
        )
      ),
      'yyyy-MM-dd'
    );
    return occurrenceDate;
  }

  buildAssociateEligibleDiscountEntity(
    eligibleDiscountId: string,
    modelId: string,
    associateNumber: string,
    productId: string,
    selected: boolean
  ): EligibleDiscountEntity {
    return {
      eligibleDiscountId,
      modelId,
      productId,
      selectedOptionValue: selected ? 'true' : 'false',
      qualifyingInformation: { associateNumber },
    };
  }

  getAssociateDiscountAction(
    isAssociate: 'Y' | 'N' | undefined,
    associateNumber: string,
    productId: string,
    driverId: string
  ): Action {
    if (isAssociate === 'N' || (isAssociate === 'Y' && associateNumber)) {
      if (driverId) {
        return new fromActions.UpdateDriverDiscount(
          this.buildAssociateEligibleDiscountEntity(
            ModifierNames.ASSOCIATE_DISCOUNT,
            driverId,
            associateNumber,
            productId,
            isAssociate === 'Y'
          )
        );
      } else {
        return new fromActions.UpdatePolicyLine(
          this.buildAssociateEligibleDiscountEntity(
            ModifierNames.ASSOCIATE_DISCOUNT,
            null,
            associateNumber,
            productId,
            isAssociate === 'Y'
          )
        );
      }
    }
    return null;
  }

  private getPropertyDiscountActions(
    productId: string,
    model: MoreDetailsViewModel
  ): Action[] {
    const actions = [];
    const action = this.getAssociateDiscountAction(
      model.isAssociate,
      model.associateNumber,
      productId,
      null
    );
    if (action) {
      actions.push(action);
    }
    return actions;
  }

  getDriverDiscountActions(
    moreDetailsViewModel: MoreDetailsViewModel,
    driverEntity: DriverEntity
  ): Action[] {
    const nextActions: Action[] = [];
    if (moreDetailsViewModel.defensiveDrivingCourse) {
      nextActions.push(
        new fromActions.UpdateDriverDiscount(
          this.buildDriverTrainingDiscountEntity(
            ModifierNames.DRIVER_TRAINING,
            driverEntity
          )
        )
      );
    }
    if (moreDetailsViewModel.advancedDefensiveDrivingCourse) {
      nextActions.push(
        new fromActions.UpdateDriverDiscount(
          this.buildAdvancedDriverTrainingDiscountEntity(
            ModifierNames.ADVANCED_DRIVER_TRAINING,
            driverEntity
          )
        )
      );
    }
    if (moreDetailsViewModel.usArmedForcesDrivingCourse) {
      nextActions.push(
        new fromActions.UpdateDriverDiscount(
          this.buildUSArmedForcesDiscountEntity(
            ModifierNames.US_ARMED_FORCES,
            driverEntity
          )
        )
      );
    }
    if (moreDetailsViewModel.goodStudent) {
      nextActions.push(
        new fromActions.UpdateDriverDiscount(
          this.buildDriverEligibleDiscountEntity(
            ModifierNames.GOOD_STUDENT,
            driverEntity
          )
        )
      );
    }
    const associateDiscountAction = this.getAssociateDiscountAction(
      moreDetailsViewModel.isAssociate,
      moreDetailsViewModel.associateNumber,
      driverEntity.productId,
      driverEntity.driverId
    );
    if (associateDiscountAction) {
      nextActions.push(associateDiscountAction);
    }
    return nextActions;
  }

  getPowersportsDriverDiscountActions(
    moreDetailsViewModel: MoreDetailsViewModel,
    driverEntity: DriverEntity
  ): Action[] {
    const nextActions: Action[] = [];
    if (moreDetailsViewModel.defensiveDrivingCourse) {
      nextActions.push(
        new fromActions.UpdatePowersportsDriverDiscount(
          this.buildDriverTrainingDiscountEntity(
            ModifierNames.DRIVER_TRAINING,
            driverEntity
          )
        )
      );
    }

    if (moreDetailsViewModel.stateApprovedSafetyCourse) {
      nextActions.push(
        new fromActions.UpdatePowersportsDriverDiscount(
          this.buildSafetyCourseDiscountEntity(
            ModifierNames.SAFETY_COURSE,
            driverEntity
          )
        )
      );
    }

    if (moreDetailsViewModel.goodStudent) {
      nextActions.push(
        new fromActions.UpdatePowersportsDriverDiscount(
          this.buildGoodStudentDiscountEntity(
            ModifierNames.GOOD_STUDENT,
            driverEntity
          )
        )
      );
    }

    const associateDiscountAction = this.getAssociateDiscountAction(
      moreDetailsViewModel.isAssociate,
      moreDetailsViewModel.associateNumber,
      driverEntity.productId,
      driverEntity.driverId
    );
    if (associateDiscountAction) {
      nextActions.push(associateDiscountAction);
    }
    return nextActions;
  }

  buildInitiateNewBusinessActions(
    isProductInitiated: boolean,
    model: MoreDetailsViewModel,
    productId: string,
    primaryNamedInsured: PolicyholderEntity
  ): Action[] {
    const driverEntity = this.buildDriverEntity(
      primaryNamedInsured,
      model,
      productId
    );

    let nextActions: Action[] = [];
    switch (productId) {
      case ProductTypes.AUTO: {
        if (!isProductInitiated) {
          nextActions.push(
            new fromActions.InitiateNewBusinessAuto(this.buildAutoExtras(model))
          );
        } else {
          nextActions.push(new fromActions.UpdateQuote(productId));
          nextActions.push(
            new fromActions.UpdatePolicyholder({
              ...primaryNamedInsured,
              productId: productId,
            })
          );
          if (!model.showAgentInfo) {
            if (driverEntity.driverId) {
              nextActions.push(new fromActions.UpdateDriver(driverEntity));
            } else {
              nextActions.push(new fromActions.AddDriver(driverEntity, true));
            }
          }

          nextActions = nextActions.concat(
            this.getDriverDiscountActions(model, driverEntity)
          );
        }
        break;
      }

      case ProductTypes.POWERSPORTS: {
        if (!isProductInitiated) {
          nextActions.push(
            new fromActions.InitiateNewBusinessPowersports(
              this.buildPowersportsExtraParameters(model)
            )
          );
        } else {
          nextActions.push(new fromActions.UpdateQuote(productId));
          nextActions.push(
            new fromActions.UpdatePolicyholder({
              ...primaryNamedInsured,
              productId: productId,
            })
          );
          nextActions.push(
            new fromActions.UpdatePowersportsDriver(driverEntity)
          );
          nextActions = nextActions.concat(
            this.getPowersportsDriverDiscountActions(model, driverEntity)
          );
        }
        break;
      }

      case ProductTypes.HOMEOWNERS: {
        nextActions.push(
          new fromActions.AddHomeowners({
            id: DEFAULT_ID,
            primary: true,
          })
        );

        if (!isProductInitiated) {
          nextActions.push(
            new fromActions.InitiateNewBusinessHomeowners(
              this.buildExtraParameters(model)
            )
          );
        } else {
          nextActions.push(
            new fromActions.UpdatePolicyholder({
              ...primaryNamedInsured,
              productId: productId,
            })
          );
          nextActions.push(
            new fromActions.ResetCoveredLocationLoaded(),
            new fromActions.UpdateQuote(productId),
            ...this.getPropertyDiscountActions(ProductTypes.HOMEOWNERS, model)
          );
        }
        break;
      }

      case ProductTypes.CONDO: {
        nextActions.push(
          new fromActions.AddCondo({
            id: DEFAULT_ID,
            primary: true,
          })
        );

        if (!isProductInitiated) {
          nextActions.push(
            new fromActions.InitiateNewBusinessCondo(
              this.buildExtraParameters(model)
            )
          );
        } else {
          nextActions.push(
            new fromActions.UpdatePolicyholder({
              ...primaryNamedInsured,
              productId: productId,
            })
          );
          nextActions.push(
            new fromActions.ResetCoveredLocationLoaded(),
            new fromActions.UpdateQuote(productId),
            ...this.getPropertyDiscountActions(ProductTypes.CONDO, model)
          );
        }
        break;
      }

      case ProductTypes.RENTERS: {
        if (!isProductInitiated) {
          nextActions.push(
            new fromActions.UpdateRenters({
              id: DEFAULT_ID,
              personalPropertyCoverage: '15,000',
            })
          );

          nextActions.push(
            new fromActions.InitiateNewBusinessRenters(
              this.buildExtraParameters(model)
            )
          );
        } else {
          nextActions.push(
            new fromActions.UpdatePolicyholder({
              ...primaryNamedInsured,
              productId: productId,
            })
          );
          nextActions.push(
            new fromActions.ResetCoveredLocationLoaded(),
            new fromActions.UpdateQuote(productId),
            ...this.getPropertyDiscountActions(ProductTypes.RENTERS, model)
          );
        }
        break;
      }

      case ProductTypes.UMBRELLA: {
        if (!isProductInitiated) {
          nextActions.push(new fromActions.InitiateNewBusinessUmbrella());
        } else {
          nextActions.push(
            new fromActions.UpdateQuote(productId),
            new fromActions.UpdatePolicyholder({
              ...primaryNamedInsured,
              productId: productId,
            })
          );
        }
        break;
      }

      case ProductTypes.TERMLIFE: {
        if (!isProductInitiated) {
          nextActions.push(new fromActions.GenerateTermLifeQuoteId());
        }
        break;
      }
    }
    return nextActions;
  }
}
