import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { PolicyNumberStatus } from '@core/models/products/policynumber-status';

@Injectable({
  providedIn: 'root',
})
export class PolicyNumberDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  // DSM
  dispatchGeneratePolicy(): void {
    this._store.dispatch(new fromActions.GeneratePolicyNumber());
  }

  isPolicyNumberLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.isPolicyNumberLoaded);
  }

  getPolicyNumberStatusOfAllProducts(): Observable<PolicyNumberStatus[]> {
    return this._store.select(fromSelectors.getPolicyNumberStatusOfAllProducts);
  }
}
