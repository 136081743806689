import { Injectable } from '@angular/core';

@Injectable()
export class PersonsService {
  checkAgeUnder25(dateOfBirth: string, ageOfDriver?: number): boolean {
    let ageUnder25 = false;
    let age: number;
    if (dateOfBirth !== null) {
      age = this.calculateAge(dateOfBirth);
    }
    const youthfulAge = 25;

    if (
      (!isNaN(ageOfDriver) && ageOfDriver < youthfulAge) ||
      (!isNaN(age) && age < youthfulAge)
    ) {
      ageUnder25 = true;
    }
    return ageUnder25;
  }

  /* eslint-disable no-magic-numbers */
  calculateAge(dob: any): number {
    let age: number;
    if (dob !== null && dob !== undefined) {
      const components = dob.split('/');
      if (components.length === 3) {
        const month = (+components[0] || 1) - 1;
        const day = +components[1] || 1;
        const year = +components[2];
        const today = new Date();
        const birthDate = new Date(year, month, day);
        age = today.getFullYear() - birthDate.getFullYear();
        const diffmonth = today.getMonth() - birthDate.getMonth();
        if (
          diffmonth < 0 ||
          (diffmonth === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
      }
    }
    return age;
  }

  isRecentlyLicensed(dateOfBirth: string, ageFirstLicensed: string): boolean {
    const recentYears = 3;
    const ageFirstLicensedAsNumber = +ageFirstLicensed;
    if (!ageFirstLicensedAsNumber) {
      return false;
    }
    const age = this.calculateAge(dateOfBirth);
    const licenseDuration = age - ageFirstLicensedAsNumber;
    return licenseDuration < recentYears;
  }

  yearFromMaskedDate(date: string): number {
    const match = date.match(/^\D*(\d{4})\D*$/);
    if (!match) {
      return NaN;
    }
    return +match[1];
  }

  termLifeAgeCheck(dateOfBirth: string): boolean {
    let age: number;
    if (dateOfBirth !== null) {
      age = this.calculateAge(dateOfBirth);
    }
    const minAge = 18;
    const maxAge = 65;

    return !isNaN(age) && age >= minAge && age <= maxAge;
  }
}
