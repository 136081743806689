<header>
  <mq-nw-header
    [configLoaded]="configLoaded$ | async"
    [privateLabelConfig]="privateLabelConfig$ | async"
    [phoneNumber]="contactNumber$ | async"
  >
  </mq-nw-header>
  <mq-app-banner
    *ngIf="
      (configLoaded$ | async) && showBanner && !isRivian;
      else bannerSubstitute
    "
    [privateLabelConfig]="privateLabelConfig$ | async"
  ></mq-app-banner>
  <ng-template #bannerSubstitute><hr /></ng-template>
  <div *ngIf="showDebugOverlay">
    <nwx-admin-overlay
      [selectedProducts]="selectedProducts$ | async"
    ></nwx-admin-overlay>
  </div>
</header>
<mq-alert *ngIf="alert$ | async as alert" [alert]="alert"></mq-alert>
<nw-loading-spinner [show]="loading$ | async"></nw-loading-spinner>
<router-outlet></router-outlet>

<footer>
  <nw-need-help
    *isCustomer
    class="d-sm-none"
    [phoneNumber]="contactNumber$ | async"
    [additionalPhone]="(privateLabelConfig$ | async)?.additionalPhoneNumber"
    [dsmProducts]="null"
  ></nw-need-help>

  <mq-nw-footer
    [configLoaded]="configLoaded$ | async"
    [privateLabelConfig]="privateLabelConfig$ | async"
    [phoneNumber]="contactNumber$ | async"
    [producerInfo]="producerInformation$ | async"
    [stateSpecificFlags]="stateSpecificFlags$ | async"
    [selectedProducts]="selectedProducts$ | async"
    [quoteState]="quoteState$ | async"
    [urboNumber]="urboNumber$ | async"
    [termLifePeople]="termLifePeople$ | async"
    [isRivian]="isRivian"
  ></mq-nw-footer>
</footer>
<div id="consent_blackbar"></div>
