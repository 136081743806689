import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { Observable } from 'rxjs';
import {
  PlaidExchangeTokenRequest,
  PlaidExchangeTokenResponse,
} from '@core/models/billing/plaid/plaid.model';

@Injectable()
export class PlaidDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getIsGetLinkTokenCalled(): Observable<boolean> {
    return this._store.select(fromSelectors.getIsGetLinkTokenCalled);
  }

  dispatchCreatePlaidLinkToken() {
    this._store.dispatch(new fromActions.CreatePlaidLinkToken());
  }

  dispatchExchangePlaidToken(request: PlaidExchangeTokenRequest) {
    this._store.dispatch(new fromActions.PlaidExchangeToken(request));
  }

  getPlaidLinkToken(): Observable<string> {
    return this._store.select(fromSelectors.getPlaidLinkToken);
  }

  getPlaidRetrieveCount(): Observable<number> {
    return this._store.select(fromSelectors.getPlaidRetrieveCount);
  }

  getPlaidAccountInfo(): Observable<PlaidExchangeTokenResponse> {
    return this._store.select(fromSelectors.getPlaidAccountInfo);
  }

  getPlaidExchangeTokenFail(): Observable<boolean> {
    return this._store.select(fromSelectors.getPlaidExchangeTokenFail);
  }
}
