import { ContentfulService } from './contentful.service';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  getCompRaterNewsHtml,
  getCompRaterNewsLoaded,
  getCompRaterNewsLoading,
} from '@core/store/selectors/comp-rater-news.selector';
import { AppState } from '@core/store/reducers';
import { LoadCompRaterNews } from '@core/store/actions';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Block, Inline, BLOCKS } from '@contentful/rich-text-types';

const CONTENT_TYPE = 'compRaterNews';

@Injectable()
export class CompRaterNewsService {
  constructor(
    private _contentfulService: ContentfulService,
    private _store: Store<AppState>
  ) {}

  getNewsHtml(): Observable<string> {
    return this._store.select(getCompRaterNewsHtml);
  }

  getCompRaterNewsLoading(): Observable<boolean> {
    return this._store.select(getCompRaterNewsLoading);
  }

  getCompRaterNewsLoaded(): Observable<boolean> {
    return this._store.select(getCompRaterNewsLoaded);
  }

  loadCompRaterNews() {
    this._store.dispatch(new LoadCompRaterNews());
  }

  fetch(): Observable<string> {
    return from(this._contentfulService.getEntries(CONTENT_TYPE)).pipe(
      map(entries => {
        if (!entries || !entries.length) {
          return '';
        }
        return this._htmlFromContentfulDom(entries[0].fields.news);
      })
    );
  }

  private _htmlFromContentfulDom(input: any): string {
    const options = {
      renderNode: {
        [BLOCKS.LIST_ITEM]: (node: Block | Inline, next) => {
          if (
            node.content.length === 1 &&
            node.content[0].nodeType === 'paragraph'
          ) {
            node = <Block | Inline>node.content[0];
          }
          return `<li>${next(node.content)}</li>`;
        },
        [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline, next) => {
          const { title, description, file } = node.data.target.fields;
          const mimeType = file.contentType;
          const mimeGroup = mimeType.split('/')[0];

          switch (mimeGroup) {
            case 'image':
              return `<img title="${title || ''}" alt="${
                description || ''
              }" src="${file.url}" width="100%" style="cursor: default"/>`;
            default:
              return '';
          }
        },
      },
    };
    return documentToHtmlString(input, options);
  }
}
