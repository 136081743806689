import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import { RentersModel } from '@core/models/renters/renters.model';
import { SeparateUnit } from '@core/models/renters/separateunits.model';
import { StructuralMaterial } from '@core/models/renters/structuralmaterials.model';

@Injectable()
export class RentersDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getRenters(): Observable<RentersModel> {
    return this._store.select(fromSelectors.getRenters);
  }

  getRentersLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getRentersLoading);
  }

  getRentersLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getRentersLoaded);
  }

  updateRenters(value: RentersModel): void {
    this._store.dispatch(new fromActions.UpdateRenters(value));
  }

  loadRentersSeparateUnits(): void {
    this._store.dispatch(new fromActions.LoadRentersSeparateUnits());
  }

  getSeparateUnitsData(): Observable<SeparateUnit[]> {
    return this._store.select(fromSelectors.getSeparateUnitsData);
  }

  getSeparateUnitsLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getSeparateUnitsLoaded);
  }

  getSeparateUnitsLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getSeparateUnitsLoading);
  }

  loadRentersStructuralMaterials(): void {
    this._store.dispatch(new fromActions.LoadRentersStructuralMaterials());
  }

  getStructuralMaterialsData(): Observable<StructuralMaterial[]> {
    return this._store.select(fromSelectors.getStructuralMaterialsData);
  }

  getStructuralMaterialsLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getStructuralMaterialsLoaded);
  }

  getStructuralMaterialsLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getStructuralMaterialsLoading);
  }
}
