import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';
import { RenovationService } from '@core/services/renovations.services';
import { ProductsService } from '@core/services';

@Injectable()
export class RenovationsGuard implements CanActivate {
  constructor(
    private _renovationsService: RenovationService,
    private _productsService: ProductsService
  ) {}
  canActivate(): Observable<boolean> {
    return this._productsService.isHomeownersSelected().pipe(
      take(1),
      switchMap(isHomeownersSelected => {
        if (isHomeownersSelected) {
          return this.checkStore().pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
          );
        }
        return of(true);
      })
    );
  }

  checkStore(): Observable<boolean> {
    return this._renovationsService.getAllRenovationsLoaded().pipe(
      tap(loaded => {
        if (!loaded) {
          this._renovationsService.dispatchGetAllRenovations();
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
