import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  NatGenResponse,
  NatGenBridgeRequest,
} from '@core/models/non-standard/non-standard.model';
import { AppConfigService } from '@core/services/app-config.service';

@Injectable()
export class NonStandardAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService
  ) {}

  getNonStandardBridgeLink(
    request: NatGenBridgeRequest,
    accessToken: string,
    sessionId: string
  ): Observable<NatGenResponse> {
    const url = `${this._appConfig.config.natGenApiUrl}`;
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfig.config.apiKey)
      .set('X-NW-Message-ID', request.Control.messageID) // get this through the selector
      .set('Authorization', 'Bearer ' + accessToken)
      .set('session-Id', sessionId);
    if (this._appConfig.config.targetEnv) {
      headers = headers.set(
        'X-NW-Target-Env',
        this._appConfig.config.targetEnv
      );
    }
    return this._http.post<NatGenResponse>(url, request, { headers });
  }
}
