import { createSelector } from '@ngrx/store';

import { AppState, getAppState } from '@core/store/reducers/index';
import { adapter } from '@core/store/reducers/coverage.reducer';
import { ProductTypes } from '@shared/constants/app-constants';

export const getCoverageState = createSelector(
  getAppState,
  (state: AppState) => state.coverages
);

export const {
  selectAll: selectAllCoverages,
  selectEntities: selectCoverageEntities,
  selectTotal: selectTotalCoverages,
} = adapter.getSelectors(getCoverageState);

export const getCoverageEntity = (coverageId: string) =>
  createSelector(selectCoverageEntities, entities => entities[coverageId]);

export const getCoverageChanges = createSelector(
  getCoverageState,
  state => state.coverageChanges
);

export const getExtraUpdatedCoverage = createSelector(
  getCoverageState,
  state => state.extraCoverageId
);

export const getCoverageSelectedProduct = createSelector(
  getCoverageState,
  state => state.selectedProduct
);

export const getCoverageProducts = createSelector(
  getCoverageState,
  state => state.coverageProducts
);

export const isLifeCoverageProductSelected = createSelector(
  getCoverageProducts,
  coverageProducts =>
    coverageProducts.some(productId => productId === ProductTypes.TERMLIFE)
);

export const isAutoCoverageProductSelected = createSelector(
  getCoverageProducts,
  coverageProducts =>
    coverageProducts.some(productId => productId === ProductTypes.AUTO)
);

export const getCoverageChangesFor = (productId: string) =>
  createSelector(getCoverageChanges, coverageChanges =>
    coverageChanges.filter(
      coverageChange => coverageChange.productId === productId
    )
  );

export const getCoveragesFor = (productId: string) =>
  createSelector(selectAllCoverages, coverages =>
    coverages.filter(coverage => coverage.productId === productId)
  );
