import { Action } from '@ngrx/store';
import { AdditionalInterestEntity } from '@core/models/entities/additional-interest.entity';
import { Update } from '@ngrx/entity';

export const enum AdditionalInterestActionTypes {
  ADD_ADDITIONAL_INTEREST = '[Additional Interest] Add Additional Interest',
  ADD_ADDITIONAL_INTEREST_FAIL = '[Additional Interest] Add Additional Interest Fail',
  ADD_ADDITIONAL_INTEREST_SUCCESS = '[Additional Interest] Add Additional Interest Success',

  ADD_ALL_ADDITIONAL_INTERESTS = '[Additional Interest] Add All Additional Interests',

  UPDATE_ADDITIONAL_INTEREST = '[Additional Interest] Update Additional Interest',
  UPDATE_ADDITIONAL_INTEREST_FAIL = '[Additional Interest] Update Additional Interest Fail',
  UPDATE_ADDITIONAL_INTEREST_SUCCESS = '[Additional Interest] Update Additional Interest Success',

  DELETE_ADDITIONAL_INTEREST = '[Additional Interest] Delete Additional Interest',
  DELETE_ADDITIONAL_INTEREST_FAIL = '[Additional Interest] Delete Additional Interest Fail',
  DELETE_ADDITIONAL_INTEREST_SUCCESS = '[Additional Interest] Delete Additional Interest Success',

  DELETE_ALL_ADDITIONAL_INTERESTS = '[Additional Interest] Delete All Aditional Interests',

  DELETE_ADDITIONAL_INTERESTS_BY_VEHCILE_ID = '[Additional Interest] Delete Additional Interests By VehicleId',
}

export class AddAdditionalInterest implements Action {
  readonly type = AdditionalInterestActionTypes.ADD_ADDITIONAL_INTEREST;
  constructor(public payload: AdditionalInterestEntity) {}
}

export class AddAdditionalInterestFail implements Action {
  readonly type = AdditionalInterestActionTypes.ADD_ADDITIONAL_INTEREST_FAIL;
  constructor(public payload: any) {}
}

export class AddAdditionalInterestSuccess implements Action {
  readonly type = AdditionalInterestActionTypes.ADD_ADDITIONAL_INTEREST_SUCCESS;
  constructor(public payload: AdditionalInterestEntity) {}
}

export class AddAllAdditionalInterests implements Action {
  readonly type = AdditionalInterestActionTypes.ADD_ALL_ADDITIONAL_INTERESTS;
  constructor(public payload: AdditionalInterestEntity[]) {}
}

export class UpdateAdditionalInterest implements Action {
  readonly type = AdditionalInterestActionTypes.UPDATE_ADDITIONAL_INTEREST;
  constructor(public payload: AdditionalInterestEntity) {}
}

export class UpdateAdditionalInterestFail implements Action {
  readonly type = AdditionalInterestActionTypes.UPDATE_ADDITIONAL_INTEREST_FAIL;
  constructor(public payload: any) {}
}

export class UpdateAdditionalInterestSuccess implements Action {
  readonly type =
    AdditionalInterestActionTypes.UPDATE_ADDITIONAL_INTEREST_SUCCESS;
  constructor(public payload: Update<AdditionalInterestEntity>) {}
}

export class DeleteAdditionalInterest implements Action {
  readonly type = AdditionalInterestActionTypes.DELETE_ADDITIONAL_INTEREST;
  constructor(public payload: string) {}
}

export class DeleteAdditionalInterestFail implements Action {
  readonly type = AdditionalInterestActionTypes.DELETE_ADDITIONAL_INTEREST_FAIL;
  constructor(public payload: any) {}
}

export class DeleteAdditionalInterestSuccess implements Action {
  readonly type =
    AdditionalInterestActionTypes.DELETE_ADDITIONAL_INTEREST_SUCCESS;
  constructor(public payload: AdditionalInterestEntity) {}
}

export class DeleteAllAdditionalInterests implements Action {
  readonly type = AdditionalInterestActionTypes.DELETE_ALL_ADDITIONAL_INTERESTS;
  constructor(public payload: string[]) {}
}

export class DeleteAdditionalInterestsByVehicleId implements Action {
  readonly type =
    AdditionalInterestActionTypes.DELETE_ADDITIONAL_INTERESTS_BY_VEHCILE_ID;
  constructor(public payload: string) {}
}

export type AdditionalInterestActions =
  | AddAdditionalInterest
  | AddAdditionalInterestFail
  | AddAdditionalInterestSuccess
  | AddAllAdditionalInterests
  | UpdateAdditionalInterest
  | UpdateAdditionalInterestFail
  | UpdateAdditionalInterestSuccess
  | DeleteAdditionalInterest
  | DeleteAdditionalInterestFail
  | DeleteAdditionalInterestSuccess
  | DeleteAllAdditionalInterests
  | DeleteAdditionalInterestsByVehicleId;
