import * as fromQuote from '@core/store/actions/quote.action';
import { TermLifeResponse } from '@core/models/auto/quotes/quote-rating-response.model';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
export interface TermLifeState extends EntityState<TermLifeResponse> {
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<TermLifeResponse> =
  createEntityAdapter<TermLifeResponse>({
    selectId: termLifeResponse => termLifeResponse.id,
  });

export function reducer(
  state = {
    ...adapter.getInitialState(),
    loaded: false,
    loading: false,
  },
  action: fromQuote.QuoteActions
): TermLifeState {
  switch (action.type) {
    case fromQuote.QuoteActionTypes.TERM_LIFE_RATE_QUOTE: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromQuote.QuoteActionTypes.TERM_LIFE_RATE_QUOTE_SUCCESS: {
      return adapter.upsertOne(action.termLife, {
        ...state,
        loaded: true,
        loading: false,
      });
    }
    case fromQuote.QuoteActionTypes.TERM_LIFE_RATE_QUOTE_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case fromQuote.QuoteActionTypes.UPDATE_TERMLIFE_COVERAGE: {
      return adapter.updateOne(action.termLife, {
        ...state,
        loaded: true,
        loading: false,
      });
    }

    case fromQuote.QuoteActionTypes.REMOVE_TERMLIFE_COVERAGE: {
      return adapter.removeOne(action.id, {
        ...state,
        loaded: true,
        loading: false,
      });
    }

    case fromQuote.QuoteActionTypes.EMAIL_QUOTE_ESTIMATE: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromQuote.QuoteActionTypes.EMAIL_QUOTE_ESTIMATE_SUCCESS: {
      return adapter.updateOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
      });
    }

    case fromQuote.QuoteActionTypes.EMAIL_QUOTE_ESTIMATE_FAIL: {
      return adapter.removeOne(action.error, {
        ...state,
        loading: false,
        loaded: true,
      });
    }
  }
  return state;
}
