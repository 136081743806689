import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';

export const getPolicyState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.policy
);

export const getPolicyEffectiveDates = createSelector(
  getPolicyState,
  state => state
);
