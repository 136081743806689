<div
  *ngIf="show && (isClaimsReviewsSpinnerEligible$ | async) && (isClaimsReviewsSpinnerActive$ | async) && !checkIfOnCoveragesPage(); else normalSpinner"
  class="nw-loading-bg">
  <div class="nw-loading-container bolt-background-blue-dark">
    <div id="upper-message-claims-review">
      {{ (upperMessageReviews$ | async) }}
    </div>
    <div class="nw-spinner"></div>
    <span id="lower-message-claims-review">
      <div class="apostrophe-images">
        <img src={{nWXClaimsReviewsApostrophe}} alt="apostrophe-image">
        <img src={{nWXClaimsReviewsApostrophe}} alt="apostrophe-image">
      </div>
      {{ (lowerMessageReviews$ | async) }}
    </span>
    <div id="reviewer-name-claims-review">
      {{ (reviewerName$ | async) }}
    </div>
  </div>
</div>
<ng-template #normalSpinner>
  <div *ngIf="show" class="nw-loading-bg">
    <div class="nw-loading-container bolt-background-blue-dark">
      <h3>{{ (upperMessage$ | async) || 'Submitting information' }}</h3>
      <div class="nw-spinner"></div>
      <p class="mt-3" style="text-align: center;">
        {{ (lowerMessage$ | async) || 'This may take a few seconds...' }}
      </p>
    </div>
  </div>
</ng-template>