import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  HostBinding,
} from '@angular/core';
import { RetrieveProxy } from '../../proxies/retrieve.proxy';
import { RetrieveModel } from '@core/models/retrieve/retrieve.model';
import { take, filter, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product } from '@core/models/products/product.model';
import { RetrieveEntity } from '@core/models/entities/retrieve.entity';
import {
  ProductTypes,
  MobilePartnerIDs,
  QuoteStatus,
  RatingTypes,
  RIVIAN_THIRDPARTY_ID,
} from '@shared/constants/app-constants';
import { UserContext } from '@core/models/user-context/user-context.model';
import { Router } from '@angular/router';
import { StringUtils } from '@shared/utils/string.utils';
import {
  BypassFields,
  BypassRetrieveResponse,
} from '@core/models/bypass-retrieve/bypass-retrieve.model';

@Component({
  selector: 'mq-retrieve',
  templateUrl: './retrieve.component.html',
  styleUrls: ['./retrieve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrieveComponent implements OnInit, OnDestroy {
  loading$: Observable<boolean> = null;
  error$: Observable<string> = null;
  products$: Observable<Product[]> = null;
  isAgentExperience$: Observable<boolean>;
  bypassRetrieveResponse$: Observable<BypassRetrieveResponse> = null;

  private retrieveCompleteSubscription = null;

  @HostBinding('attr.role') role = 'main';
  constructor(private _proxy: RetrieveProxy, private _router: Router) {}

  ngOnInit(): void {
    this.error$ = this._proxy.getRetrieveErrorMessage();
    this._proxy.logPageLoaded();
    this.loading$ = this._proxy.getAppLoading();
    this.isAgentExperience$ = this._proxy.isAgent();
    this.bypassRetrieveResponse$ = this._proxy.getBypassRetrieve();
  }

  ngOnDestroy() {
    if (this.retrieveCompleteSubscription) {
      this.retrieveCompleteSubscription.add(this._proxy.loadingEnd());
      this.retrieveCompleteSubscription.unsubscribe();
    }
  }

  onSubmit(model: RetrieveModel) {
    this._proxy.clearRetrieveFailures();
    this._proxy.loadingBegin();
    this._proxy.submitRetrievalRequest(model);
    this._proxy.logPageSubmit('RETRIEVE_SUBMIT', model);

    if (this.retrieveCompleteSubscription) {
      this.retrieveCompleteSubscription.unsubscribe();
    }

    this.retrieveCompleteSubscription = this._proxy
      .getRetrieveLoading()
      .pipe(
        filter(loaded => !loaded),
        take(1),
        withLatestFrom(
          this._proxy.getAllRetrievedQuotes(),
          this._proxy.getUserContext()
        )
      )
      .subscribe(([loaded, quotes, userContext]) => {
        this.navigateToSalesFlow(quotes, userContext)
          .then(navigated => {
            this._proxy.loadingEnd();
          })
          .catch(() => {
            // TODO Display informative Error to user.
          });
      });
  }

  navigateToSalesFlow(
    quotes: RetrieveEntity[],
    userContext: UserContext
  ): Promise<boolean> {
    if (quotes.length < 1) {
      this._proxy.setAllRetrieveQuotesFailedFlag();
      this._proxy.loadingEnd();
      return this._router.navigate(['/multi-quote/getting-started']);
    }
    if (
      this.informationReviewRequired(userContext) &&
      userContext.thirdPartyId !== RIVIAN_THIRDPARTY_ID
    ) {
      return this._proxy.navigateToGettingStarted();
    }

    if (
      this.allQuotesHasStatus(quotes, QuoteStatus.BINDING) &&
      !this.anyQuoteRequiresUpdate(quotes)
    ) {
      return this.updateQuotesThatNeedIt(quotes, RatingTypes.BIND)
        .then(() => {
          if (
            userContext &&
            userContext.thirdPartyId &&
            userContext.thirdPartyId === RIVIAN_THIRDPARTY_ID
          ) {
            return this._proxy.navigateToBilling();
          } else {
            return this._proxy.navigateToBindCoverages();
          }
        })
        .catch(() => {
          return this._proxy.navigateToGettingStarted();
        });
    }

    if (
      this.allQuotesHasStatus(quotes, QuoteStatus.QUOTED) &&
      this.canNavigateToQuoteCoverages(quotes) &&
      userContext.thirdPartyId !== RIVIAN_THIRDPARTY_ID
    ) {
      return this.updateQuotesThatNeedIt(quotes, RatingTypes.QUOTE).then(() => {
        return this._proxy.navigateToQuoteCoverages();
      });
    }

    return this._proxy.navigateToGettingStarted();
  }

  allQuotesHasStatus(entities: RetrieveEntity[], status: string): boolean {
    return entities.every(entity =>
      !entity.response
        ? false
        : StringUtils.areEqual(entity.response.quoteStatus, status)
    );
  }

  anyQuoteRequiresUpdate(entities: RetrieveEntity[]): boolean {
    return entities.some(
      entity => entity && entity.response && entity.response.updateRequired
    );
  }

  canNavigateToQuoteCoverages(entities: RetrieveEntity[]): boolean {
    if (entities.length < 1) {
      return false;
    }
    for (const entity of entities) {
      if (!entity.response) {
        return false;
      }
      if (entity.response.quoteStatus !== QuoteStatus.QUOTED) {
        return false;
      }
      if (entity.productId === ProductTypes.AUTO) {
        if (!entity.response.drivers || entity.response.drivers.length < 1) {
          return false;
        }
      }
    }
    return true;
  }

  informationReviewRequired(userContext: UserContext): boolean {
    if (
      userContext &&
      userContext.initiatedBy &&
      Object.keys(MobilePartnerIDs).some(
        key =>
          MobilePartnerIDs[key].toLocaleLowerCase() ===
          userContext.initiatedBy.toLocaleLowerCase()
      )
    ) {
      return true;
    }

    return false;
  }

  updateQuotesThatNeedIt(
    entities: RetrieveEntity[],
    ratingType: string
  ): Promise<any> {
    const updates = [];
    for (const entity of entities) {
      if (entity.response.rateRequired && ratingType === RatingTypes.BIND) {
        updates.push(this._proxy.bindQuoteAndWaitForConfirmation(entity));
      }
      if (entity.response.updateRequired && ratingType === RatingTypes.QUOTE) {
        updates.push(this._proxy.updateQuoteAndWaitForConfirmation(entity));
      }
    }
    if (updates.length < 1) {
      return Promise.resolve();
    }
    if (updates.length === 1) {
      return updates[0];
    }
    return Promise.all(updates);
  }

  clearErrors() {
    this._proxy.clearRetrieveFailures();
  }

  triggerBypassRetrieve(bypassFields: BypassFields): void {
    this._proxy.triggerBypassRetrieve(bypassFields);
  }
}
