import { Injectable } from '@angular/core';
import { PolicyholderService } from './policyholder.service';
import { ProductsService } from './products.service';
import { take } from 'rxjs/operators';

@Injectable()
export class FullStoryService {
  constructor(
    private _policyHolderService: PolicyholderService,
    private _productsService: ProductsService
  ) {}

  DELAY_TIME_MS = 2000;
  MAX_ATTEMPTS = 4;

  setPrimaryNamedInsured(): void {
    this._policyHolderService
      .getNamedInsured()
      .pipe(take(1))
      .subscribe(namedInsured => {
        const fullStory: any = (<any>window).FS;
        if (!fullStory) {
          return;
        }
        const firstName = namedInsured.person.firstName;
        const lastName = namedInsured.person.lastName;
        fullStory.event('user name', { name_str: `${firstName} ${lastName}` });
      });
  }

  setProductSubmissionInfo(): void {
    this._productsService
      .getSelectedProducts()
      .pipe(take(1))
      .subscribe(selectedProducts => {
        const fullStory: any = (<any>window).FS;
        if (!fullStory || !selectedProducts || selectedProducts.length === 0) {
          return;
        }
        const productInfo = selectedProducts.reduce((acc, selectedProduct) => {
          const { name, quoteId } = selectedProduct;
          return {
            ...acc,
            [name + '_str']: quoteId,
            products_strs: [...(acc['products_strs'] || []), name],
            quoteIds_strs: [...(acc['quoteIds_strs'] || []), quoteId],
          };
        }, {});
        fullStory.event('products selected', productInfo);
      });
  }

  setSessionId(id: string, attempts = 0): void {
    const fullStory: any = (<any>window).FS;
    if (!fullStory || id === null) {
      if (attempts < this.MAX_ATTEMPTS) {
        setTimeout(() => {
          this.setSessionId(id, attempts + 1);
        }, this.DELAY_TIME_MS);
      }
      return;
    }
    fullStory.event('session start', { sessionId_str: id });
  }
}
