import { Injectable } from '@angular/core';
import { AdditionalInterestRequest } from '@core/models/auto/additional-interest/additional-interest-request.model';
import { AdditionalInterestResponse } from '@core/models/auto/additional-interest/additional-interest-response.model';
import { Observable } from 'rxjs';
import { ProductTypes } from '@shared/constants/app-constants';
import { BaseDsmAdapter } from './base-dsm.adapter';

@Injectable({
  providedIn: 'root',
})
export class AdditionalInterestAdapter extends BaseDsmAdapter {
  addAdditionalInterest(
    request: AdditionalInterestRequest
  ): Observable<AdditionalInterestResponse> {
    // eslint-disable-next-line max-len
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/vehicles/${
      request.additionalInterest.vehicleId
    }/third-party-lien-holders`;

    const outboundRequestBody = {
      additionalInterest: { ...request.additionalInterest },
    };

    return this.post(url, outboundRequestBody, {
      productId: request.productId,
    });
  }

  updateAdditionalInterest(
    request: AdditionalInterestRequest
  ): Observable<AdditionalInterestResponse> {
    // eslint-disable-next-line max-len
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/vehicles/${
      request.additionalInterest.vehicleId
    }/third-party-lien-holders/${
      request.additionalInterest.additionalInterestId
    }`;

    const outboundRequestBody = {
      additionalInterest: { ...request.additionalInterest },
    };

    return this.put(url, outboundRequestBody, {
      productId: request.productId,
    });
  }

  deleteAdditionalInterest(
    request: AdditionalInterestRequest
  ): Observable<AdditionalInterestResponse> {
    // eslint-disable-next-line max-len
    const url = `${this._getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/vehicles/${
      request.additionalInterest.vehicleId
    }/third-party-lien-holders/${
      request.additionalInterest.additionalInterestId
    }`;

    return this.delete(url, {
      productId: request.productId,
    });
  }

  private _getUrlPrefixForProduct(productId) {
    switch (productId) {
      case ProductTypes.AUTO: {
        return this._appConfig.config.pcEdgeAutoUrl;
      }
      case ProductTypes.POWERSPORTS: {
        return this._appConfig.config.pcEdgePowersportsUrl;
      }
    }
  }
}
