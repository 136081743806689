import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';

@Injectable({
  providedIn: 'root',
})
export class PolicyIssueDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  dispatchIssuePolicies(): void {
    this._store.dispatch(new fromActions.IssuePolicy());
  }
}
