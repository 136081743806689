<form [formGroup]="form">
  <div formArrayName="renovations">
    <div class="col-lg">
      <label for="reno-input-0">
        {{ getRenovationText() }}
      </label>
    </div>
    <div class="col-lg year-width">
      <div class="row">
        <div
          class="form-group col-sm"
          *ngFor="let reno of renovations.controls; index as i"
        >
          <form [formGroup]="reno">
            <label
              for="reno-input-{{ i }}{{ i ? '' : '-dont-label' }}"
              *ngIf="renovationData.length > 1"
              >{{ reno.value.type }}</label
            >
            <div class="input-group">
              <input
                id="reno-input-{{ i }}"
                type="text"
                class="form-control"
                placeholder="YYYY"
                mask="0000"
                [value]="reno.value.year"
                formControlName="year"
              />
              <div class="input-group-text"></div>
            </div>
          </form>
          <div *ngIf="reno.value.type == 'Roof'">
            Please note that roof year <br />will not be editable <br />after
            leaving this screen
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
