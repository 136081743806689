<div [formGroup]="form">
  <div
    *ngIf="!(gettingStarted && isDirectUser)"
    class="btn-bg"
    [class.btn-reorder]="isReordered"
  >
    <div class="nw-container">
      <div
        class="row justify-content-end"
        [class.btn-reorder-spacing]="isReordered"
        id="toscaNav"
      >
        <button
          class="toscaNav-reCaptchaAgree btn btn-primary col-sm-2 mb-2"
          id="nav-continue"
          type="button"
          [disabled]="forwardDisabled"
          (click)="execute()"
        >
          Agree and Continue
          <ngx-invisible-recaptcha
            class="toscaNav-reCaptchaHidden"
            #captchaElem
            [type]="'image'"
            [badge]="'bottomright'"
            (load)="handleLoad()"
            [siteKey]="captchaSiteToken"
            (success)="handleSuccess($event)"
            formControlName="cap"
          >
          </ngx-invisible-recaptcha>
        </button>
      </div>
    </div>
  </div>
  <div class="nw-container" *ngIf="gettingStarted && isDirectUser">
    <div class="row pb-3 justify-content-start" id="toscaNav">
      <button
        class="toscaNav-reCaptchaAgree btn btn-primary col-sm-2 mb-2"
        id="nav-continue"
        type="button"
        [disabled]="forwardDisabled"
        (click)="execute()"
      >
        Agree and Continue
        <ngx-invisible-recaptcha
          class="toscaNav-reCaptchaHidden"
          #captchaElem
          [type]="'image'"
          [badge]="'bottomright'"
          (load)="handleLoad()"
          [siteKey]="captchaSiteToken"
          (success)="handleSuccess($event)"
          formControlName="cap"
        >
        </ngx-invisible-recaptcha>
      </button>
    </div>
  </div>
</div>
