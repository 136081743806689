import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  animate,
  AnimationEvent,
  transition,
  state,
  trigger,
  style,
} from '@angular/animations';
import { Observable, Subject, of } from 'rxjs';
import { DisplayPanelService } from '../display-panel.service';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { StringUtils } from '@shared/utils/string.utils';
import {
  DriverRelationToPNI,
  PolicyholderTypes,
} from '@shared/constants/app-constants';

@Component({
  selector: 'nw-display-panel',
  templateUrl: './display-panel.component.html',
  styleUrls: ['./display-panel.component.scss'],
  providers: [DisplayPanelService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggle', [
      state('collapsed', style({ height: '0px', visibility: 'hidden' })),
      state(
        'expanded',
        style({
          height: '*',
          visibility: 'visible',
        })
      ),
      transition('expanded <=> collapsed', animate('300ms')),
    ]),
  ],
})
export class DisplayPanelComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() heading: string;
  @Input() subheading: string;
  @Input() openText: string;
  @Input() closedText: string;
  @Input() alwaysOpen: boolean;
  @Input() loadOpen: boolean;
  @Input() icon: string;
  @Input() isDeletable: boolean;
  @Input() headerImage = '';

  @Output() productToggled = new EventEmitter();
  @Output() visibilityChanged = new EventEmitter();
  @Output() productDeleted = new EventEmitter<any>();

  private _ngUnsubscribe = new Subject();

  isOpen: Observable<boolean>;
  openState: string;
  public isValid: boolean;
  isPrimaryNamedInsured: boolean;

  constructor(
    private _displayPanelService: DisplayPanelService,
    private _modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this._displayPanelService.next(this.loadOpen);
    this.isOpen = this.alwaysOpen ? of(true) : this._displayPanelService.opened;
    this._displayPanelService.formValid.subscribe(valid => {
      this.isValid = valid;
    });
    this.isOpen.pipe(takeUntil(this._ngUnsubscribe)).subscribe(isOpen => {
      if (isOpen) {
        this.openState = 'expanded';
      } else {
        this.openState = 'collapsed';
      }
      this.visibilityChanged.next(isOpen);
    });

    this.isPrimaryNamedInsured =
      (this.item &&
        this.item.relationToPrimaryNamedInsured &&
        StringUtils.areEqual(
          this.item.relationToPrimaryNamedInsured,
          DriverRelationToPNI.PNI
        )) ||
      StringUtils.areEqual(
        this.item && this.item.policyHolderType,
        PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED
      )
        ? true
        : false;
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  toggle(): void {
    this._displayPanelService.toggle();
  }

  toggleIfSpaceOrEnter(event): void {
    if (event && (event.key === 'Enter' || event.key === ' ')) {
      this.toggle();
      event.preventDefault();
    }
  }

  toggleProduct(): void {
    this._displayPanelService.formValid
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe(valid => {
        if (valid) {
          this.productToggled.emit({ ...this.item });
        } else {
          this.toggle();
        }
      });
  }

  deleteProduct(): void {
    const modal = this._modalService.open(ConfirmationModalComponent);
    modal.componentInstance.item = this.heading;

    modal.result.then(
      response => {
        this.productDeleted.emit(this.item);
      },
      () => {}
    );
  }

  deleteProductIfSpaceOrEnter(event): void {
    if (event && (event.key === 'Enter' || event.key === ' ')) {
      this.deleteProduct();
      event.preventDefault();
    }
  }

  animateBody(event: AnimationEvent): void {
    const classList = event.element?.classList;
    const cssClass = 'expanded';
    const { phaseName, toState } = event;
    // Toggle the body's `overflow: hidden` class when closing starts or when expansion ends in
    // order to prevent the cases where switching too early would cause the animation to jump.
    // Note that we do it directly on the DOM element to avoid the slight delay that comes
    // with doing it via change detection.
    if (phaseName === 'done' && toState === 'expanded') {
      classList.add(cssClass);
    } else if (phaseName === 'start' && toState === 'collapsed') {
      classList.remove(cssClass);
    }
  }

  onKeyDown(event: any): void {
    const enterKey = 13,
      spaceBar = 32;
    if (event.keyCode === enterKey || event.keyCode === spaceBar) {
      this.toggleProduct();
      event.preventDefault();
    }
  }
}
