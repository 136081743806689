import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import {
  PrivateLabel,
  ProducerInformation,
  CoverageConfiguration,
} from '@core/models/private-label/private-label.model';
import { Observable } from 'rxjs';

@Injectable()
export class PrivateLabelDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  loadPrivateLabelConfiguration(identifier?: string): void {
    this._store.dispatch(
      new fromActions.LoadPrivateLabelConfiguration(identifier)
    );
  }

  getPrivateLabelConfiguration(): Observable<PrivateLabel> {
    return this._store.select(fromSelectors.getPrivateLabelConfiguration);
  }

  getStates(): Observable<string[]> {
    return this._store.select(fromSelectors.getStates);
  }

  getProducerInformationBy(state: string): Observable<ProducerInformation> {
    return this._store.select(fromSelectors.getProducerInformation, {
      state,
    });
  }

  getProducerInformation(): Observable<ProducerInformation> {
    return this._store.select(fromSelectors.getProducerInformationForAgent);
  }

  getCoverageConfiguration(): Observable<CoverageConfiguration[]> {
    return this._store.select(fromSelectors.getCoverageConfiguration);
  }

  getPrivateLabelConfigurationLoaded(): Observable<boolean> {
    return this._store.select(fromSelectors.getPrivateLabelLoaded);
  }

  getContactNumber(page?: string): Observable<string> {
    return this._store.select(fromSelectors.getContactNumber(page));
  }

  isPrivateLabel(): Observable<boolean> {
    return this._store.select(fromSelectors.isPrivateLabelExperience);
  }

  getProducerContactNumber(page?: string): Observable<string> {
    return this._store.select(fromSelectors.getProducerContactNumber(page));
  }

  getAgencyProducts(): Observable<string[]> {
    return this._store.select(fromSelectors.getAgencyProducts);
  }

  isConnectedCarEnabled(): Observable<boolean> {
    return this._store.select(fromSelectors.isConnectedCarEnabled);
  }
}
