import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { AddressEntity } from '@core/models/entities/address.entity';
import { CRUDService } from '@core/services/crud.service';

@Injectable({
  providedIn: 'root',
})
export class MailingAddressDao implements CRUDService<AddressEntity> {
  constructor(private _store: Store<fromStore.AppState>) {}

  addOrUpdateAddress(address: AddressEntity): void {
    this.add(address);
  }

  add(address: AddressEntity): void {
    this._store.dispatch(new fromActions.AddMailingAddress(address));
  }

  update(address: AddressEntity): void {
    const updated: Update<AddressEntity> = {
      changes: address,
      id: address.addressId,
    };

    this._store.dispatch(new fromActions.UpdateMailingAddress(updated));
  }

  delete(): void {
    this._store.dispatch(new fromActions.DeleteMailingAddress());
  }

  getMailingAddress(id: string): Observable<AddressEntity> {
    return this._store.select(fromSelectors.getMailingAddress(id));
  }

  getAll(): Observable<AddressEntity[]> {
    return this._store.select(fromSelectors.getAllMailingAddresses());
  }
}
