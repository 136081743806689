import { Action } from '@ngrx/store';
import { PageAlert } from '@core/models/page-alerts/page-alert.model';

export const LOAD_ALL_PAGE_ALERTS = '[Page Alerts] Load All Page Alerts';
export const LOAD_ALL_PAGE_ALERTS_FAIL =
  '[Page Alerts] Load All Page Alerts Fail';
export const LOAD_ALL_PAGE_ALERTS_SUCCESS =
  '[Page Alerts] Load All Page Alerts Success';
export const LOAD_ACTIVE_ALERT = '[Page Alerts] Load Active Alert';
export const LOAD_ACTIVE_ALERT_SUCCESS =
  '[Page Alerts] Load Active Alert Success';
export const LOAD_ACTIVE_ALERTS_SUCCESS =
  '[Page Alerts] Load Active Alerts Success';
export const CLEAR_ACTIVE_ALERT = '[Page Alerts] Clear Active Alert';
export const ADD_PAGE_ALERT = '[Page Alerts] Add Page Alert';

export class LoadAllPageAlerts implements Action {
  readonly type = LOAD_ALL_PAGE_ALERTS;
}

export class LoadAllPageAlertFail implements Action {
  readonly type = LOAD_ALL_PAGE_ALERTS_FAIL;
  constructor(public payload: any) {}
}

export class LoadAllPageAlertSuccess implements Action {
  readonly type = LOAD_ALL_PAGE_ALERTS_SUCCESS;
  constructor(public payload: PageAlert[]) {}
}

export class LoadActiveAlert implements Action {
  readonly type = LOAD_ACTIVE_ALERT;
  constructor(public payload: string) {}
}

export class LoadActiveAlertSuccess implements Action {
  readonly type = LOAD_ACTIVE_ALERT_SUCCESS;
  constructor(public payload: PageAlert) {}
}

export class LoadActiveAlertsSuccess implements Action {
  readonly type = LOAD_ACTIVE_ALERTS_SUCCESS;
  constructor(public payload: PageAlert[]) {}
}

export class ClearActiveAlert implements Action {
  readonly type = CLEAR_ACTIVE_ALERT;
}

export class AddPageAlert implements Action {
  readonly type = ADD_PAGE_ALERT;
  constructor(public payload: PageAlert) {}
}

export type PageAlertActions =
  | LoadAllPageAlerts
  | LoadAllPageAlertFail
  | LoadAllPageAlertSuccess
  | LoadActiveAlert
  | LoadActiveAlertSuccess
  | LoadActiveAlertsSuccess
  | ClearActiveAlert
  | AddPageAlert;
