import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TogglePillComponent } from './toggle-pill.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { HelpContentModule } from '@help-content/help-content.module';

@NgModule({
  imports: [CommonModule, NgbModalModule, HelpContentModule],
  declarations: [TogglePillComponent],
  exports: [TogglePillComponent],
})
export class TogglePillModule {}
