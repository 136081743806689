import {
  TelematicsEnrollmentsAction,
  TelematicsEnrollmentsActionTypes,
} from '@core/store/actions/telematics-enrollments.action';
import {
  TelematicsEnrollmentRecommendationResponse,
  TelematicsEnrollmentResponse,
  PreQualifiedVehicleInfo,
} from '@core/models/telematics/telematics.model';

import * as _ from 'lodash';

export interface TelematicsEnrollmentsState {
  recommendation: TelematicsEnrollmentRecommendationResponse;
  enrollment: TelematicsEnrollmentResponse;
  preQualifiedInfo: PreQualifiedVehicleInfo[];
  loaded: boolean;
  loading: boolean;
}

const initialState: TelematicsEnrollmentsState = {
  recommendation: null,
  enrollment: null,
  preQualifiedInfo: [],
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: TelematicsEnrollmentsAction
): TelematicsEnrollmentsState {
  switch (action.type) {
    case TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION_SUCCESS: {
      return { ...state, recommendation: action.payload };
    }

    case TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION_WITHLOADED_SUCCESS: {
      return {
        ...state,
        recommendation: action.payload,
        loading: false,
        loaded: true,
      };
    }

    case TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION_FAIL: {
      return { ...state, loading: false, loaded: true };
    }

    case TelematicsEnrollmentsActionTypes.GET_TELEMATICS_ENROLLMENTS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case TelematicsEnrollmentsActionTypes.GET_TELEMATICS_RECOMMENDATION: {
      return {
        ...state,
        enrollment: action.payload,
      };
    }

    case TelematicsEnrollmentsActionTypes.GET_TELEMATICS_ENROLLMENTS_SUCCESS: {
      return {
        ...state,
        enrollment: action.payload,
        loading: false,
        loaded: true,
      };
    }

    case TelematicsEnrollmentsActionTypes.GET_TELEMATICS_ENROLLMENTS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT_SUCCESS: {
      return {
        ...state,
        enrollment: action.payload,
        loading: false,
        loaded: true,
      };
    }

    case TelematicsEnrollmentsActionTypes.ADD_TELEMATICS_ENROLLMENT_FAIL: {
      return { ...state, loading: false, loaded: true };
    }

    case TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_ENROLLMENT: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_ENROLLMENT_SUCCESS: {
      return {
        ...state,
        enrollment: action.payload,
        loading: false,
        loaded: true,
      };
    }

    case TelematicsEnrollmentsActionTypes.UPDATE_TELEMATICS_ENROLLMENT_FAIL: {
      return { ...state, loading: false, loaded: true };
    }

    case TelematicsEnrollmentsActionTypes.REMOVE_TELEMATICS_ENROLLMENT: {
      return { ...state, loading: true };
    }

    case TelematicsEnrollmentsActionTypes.REMOVE_TELEMATICS_ENROLLMENT_SUCCESS: {
      return { ...state, loaded: false, loading: false, enrollment: null };
    }

    case TelematicsEnrollmentsActionTypes.REMOVE_TELEMATICS_ENROLLMENT_FAIL: {
      return { ...state, loading: false, loaded: true };
    }

    case TelematicsEnrollmentsActionTypes.UNSET_TELEMATICS_ENROLLMENT_LOADED: {
      return { ...state, loaded: false, loading: false };
    }

    case TelematicsEnrollmentsActionTypes.SET_TELEMATICS_ENROLLMENT: {
      return { ...state, enrollment: action.payload };
    }

    case TelematicsEnrollmentsActionTypes.UPDATE_PREQUALIFIED_VEHICLE_INFO: {
      const updatedPreQualVehicleInfo = action.payload;
      const existPreQualVehInfo = state.preQualifiedInfo;

      return {
        ...state,
        preQualifiedInfo: existPreQualVehInfo
          ? _.unionBy(
              updatedPreQualVehicleInfo,
              existPreQualVehInfo,
              'vehicleId'
            )
          : updatedPreQualVehicleInfo,
      };
    }

    case TelematicsEnrollmentsActionTypes.UPDATE_MOBILE_INFO_TELEMATICS_ENROLLEMENT_SUCCESS: {
      return {
        ...state,
        enrollment: action.payload,
        loading: false,
        loaded: true,
      };
    }

    case TelematicsEnrollmentsActionTypes.UPDATE_MOBILE_INFO_TELEMATICS_ENROLLEMENT_FAIL: {
      return { ...state, loading: false, loaded: true };
    }

    default:
      return { ...state };
  }
}
