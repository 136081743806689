import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';
import {
  RenovationsState,
  RenovationEntity,
} from '@core/store/reducers/renovation.reducer';

export const getRenovationState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.renovation
);

export const getAllRenovationsLoaded = createSelector(
  getRenovationState,
  (state: RenovationsState) => state.loaded
);

export const selectAllRenovations = createSelector(
  getRenovationState,
  (state: RenovationsState) => {
    let renovations: RenovationEntity[];
    if (state.entities.length) {
      renovations = [...state.entities];
      return renovations;
    }
  }
);

export const getRenovationEntity = (renovationId: string) =>
  createSelector(getRenovationState, (state: RenovationsState) => {
    const renovation: RenovationEntity = {
      ...[...state.entities][renovationId],
    };
    return renovation;
  });
