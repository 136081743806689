import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  DigitalIdCardsTextRequest,
  DigitalIdCardsTextResponse,
} from '@core/models/digital-id-cards/digital-id-cards-text.model';
import { Observable } from 'rxjs';
import { AppConfigService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class DigitalIdCardsTextAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfigService: AppConfigService
  ) {}

  sendText(request: {
    body: DigitalIdCardsTextRequest;
    accessToken: string;
    sessionId: string;
  }): Observable<DigitalIdCardsTextResponse> {
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this._appConfigService.config.apiKey)
      .set('Authorization', 'Bearer ' + request.accessToken)
      .set('X-NW-Message-ID', request.sessionId);
    const requestBody = { ...request.body, targetEnv: null };
    if (this._appConfigService.isTest()) {
      requestBody.targetEnv = this._appConfigService.config.targetEnv || '';
    }

    const url = `${this._appConfigService.config.multiproductApi}/digital-id-cards`;

    return this._http.post<DigitalIdCardsTextResponse>(url, requestBody, {
      headers,
    });
  }
}
