import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CRUDService } from '@core/services/crud.service';
import { VehicleDao } from '@core/dao/vehicle.dao';
import { VehicleAdapter } from '@core/adapters/vehicle.adapter';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { VehicleRequest } from '@core/models/request/vehicle-request.model';
import { VehicleResponse } from '@core/models/response/vehicle-response.model';
import { VehicleDetailsResponse } from '@core/models/response/vehicle-details-response.model';
import { VehicleDetailsRequest } from '@core/models/request/vehicle-details-request.model';
import { DsmResetEventService } from './reset.service';
import { PowersportsVehicleVinDetailsResponse } from '@core/models/auto/vehicle-vin.model';
import {
  ProductTypes,
  VehicleTypesToDsm,
} from '@shared/constants/app-constants';
import { StringUtils } from '@shared/utils/string.utils';
import { ProductsService } from './products.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VehicleService
  implements CRUDService<VehicleEntity>, DsmResetEventService
{
  constructor(
    private _vehicleAdapter: VehicleAdapter,
    private _vehicleDao: VehicleDao,
    private _productsService: ProductsService
  ) {}

  add(entity: VehicleEntity): void {
    this._vehicleDao.add(entity);
  }

  dispatchAddPowersportsVehicle(entity: VehicleEntity): void {
    this._vehicleDao.dispatchAddPowersportsVehicle(entity);
  }

  addVehicle(request: VehicleRequest): Observable<VehicleResponse> {
    return this._vehicleAdapter.addVehicle(request);
  }

  addPowersportsVehicle(request: VehicleRequest): Observable<VehicleResponse> {
    return this._vehicleAdapter.addPowersportsVehicle(request);
  }

  addAll?(entity: VehicleEntity[]): void {
    throw new Error('Method not implemented.');
  }

  delete(vehicleId: string): void {
    this._vehicleDao.delete(vehicleId);
  }

  diapatchDeletePowersportsVehicle(vehicle: VehicleEntity): void {
    this._vehicleDao.diapatchDeletePowersportsVehicle(vehicle);
  }

  deleteVehicle(request: VehicleRequest): Observable<VehicleResponse> {
    return this._vehicleAdapter.deleteVehicle(request);
  }

  deletePowersportsVehicle(
    request: VehicleRequest
  ): Observable<VehicleResponse> {
    return this._vehicleAdapter.deletePowerportsVehicle(request);
  }

  update(entity: VehicleEntity): void {
    this._vehicleDao.update(entity);
  }

  dispatchUpdatePowersportsVehicle(entity: VehicleEntity): void {
    this._vehicleDao.dispatchUpdatePowersportsVehicle(entity);
  }

  updateVehicle(request: VehicleRequest): Observable<VehicleResponse> {
    return this._vehicleAdapter.updateVehicle(request);
  }

  updatePowersportsVehicle(
    request: VehicleRequest
  ): Observable<VehicleResponse> {
    return this._vehicleAdapter.updatePowersportsVehicle(request);
  }

  updateEvent(entity: VehicleEntity): void {
    this._vehicleDao.updateEvent(entity);
  }

  get(entityId: string): Observable<VehicleEntity> {
    return this._vehicleDao.get(entityId);
  }

  getPowersportsVehicleEntity(entityId: string): Observable<VehicleEntity> {
    return this._vehicleDao.getPowersportsVehicleEntity(entityId);
  }

  getAll(): Observable<VehicleEntity[]> {
    return this._vehicleDao.getAll();
  }

  getAutoVehiclesIfSelected(): Observable<VehicleEntity[]> {
    return this._productsService
      .isProductSelectedWithoutError(ProductTypes.AUTO)
      .pipe(
        switchMap(selected => {
          if (selected) {
            return this.getAll();
          }
          return of([]);
        })
      );
  }

  getAllPowersportsVehicles(): Observable<VehicleEntity[]> {
    return this._vehicleDao.getAllPowersportsVehicles();
  }

  getAllPowersportsVehiclesIfSelected(): Observable<VehicleEntity[]> {
    return this._vehicleDao.getAllPowersportsVehiclesIfSelected();
  }

  getAllVehicles(): Observable<VehicleEntity[]> {
    return this._vehicleDao.getAll();
  }

  getVehicleByVin(vin: string): Observable<VehicleEntity> {
    return this._vehicleDao.getVehicleByVin(vin);
  }

  dispatchVehicleDetails(entity: VehicleEntity): void {
    this._vehicleDao.vehicleDetails(entity);
  }

  dispatchPowersportsVehicleDetails(entity: VehicleEntity): void {
    this._vehicleDao.powersportsVehicleDetails(entity);
  }

  vehicleDetails(
    request: VehicleDetailsRequest
  ): Observable<VehicleDetailsResponse> {
    return this._vehicleAdapter.vehicleDetails(request);
  }

  powersportsVehicleDetails(
    request: VehicleDetailsRequest
  ): Observable<PowersportsVehicleVinDetailsResponse> {
    return this._vehicleAdapter.powersportsVehicleDetails(request);
  }

  getVehicleDiscountsLoaded(vehicleId: string): Observable<boolean> {
    return this._vehicleDao.getVehicleDiscountsLoaded(vehicleId);
  }

  unsetVehicleDiscountsLoaded(vehicleId: string): void {
    this._vehicleDao.unsetVehicleDiscountsLoaded(vehicleId);
  }

  getVehiclesLoading(): Observable<boolean> {
    return this._vehicleDao.getVehiclesLoading();
  }

  getVehiclesLoadingIncludingPowersports(): Observable<boolean> {
    return this._vehicleDao.getVehiclesLoadingIncludingPowersports();
  }

  getAllVehiclesWithoutRegisteredOwners(): Observable<VehicleEntity[]> {
    return this._vehicleDao.getAllVehiclesWithoutRegisteredOwners();
  }

  getAllVehiclesWithoutRegisteredOwnersIncludingPowersports(): Observable<
    VehicleEntity[]
  > {
    return this._vehicleDao.getAllVehiclesWithoutRegisteredOwnersIncludingPowersports();
  }

  isPowersportsVehicleType(vehicleType: string): boolean {
    if (vehicleType) {
      if (StringUtils.areEqual(vehicleType, VehicleTypesToDsm.auto)) {
        return false;
      } else if (
        !!Object.keys(VehicleTypesToDsm).find(
          key =>
            StringUtils.areEqual(vehicleType, key) &&
            vehicleType !== VehicleTypesToDsm.auto
        )
      ) {
        return true;
      } else if (
        !!Object.values(VehicleTypesToDsm).find(
          value =>
            StringUtils.areEqual(vehicleType, value) &&
            vehicleType !== VehicleTypesToDsm.auto
        )
      ) {
        return true;
      }
    }
    return false;
  }

  isAutoVehicleType(vehicleType: string): boolean {
    return (
      vehicleType && StringUtils.areEqual(vehicleType, VehicleTypesToDsm.auto)
    );
  }

  getPowersportsVehicleAuxillaryLightingSystemDiscountValue(
    powersportsVehicleId
  ): Observable<boolean> {
    return this._vehicleDao.getPowersportsVehicleAuxillaryLightingSystemDiscountValue(
      powersportsVehicleId
    );
  }

  isThereAnySmartrideInstantConsentQualifiedVehicle(): Observable<boolean> {
    return this._vehicleDao.isThereAnySmartrideInstantConsentQualifiedVehicle();
  }
}
