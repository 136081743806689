import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ProductsService } from '@core/services';
import { take, switchMap, tap, filter } from 'rxjs/operators';
import { CurrentCarrierService } from '@core/services/current-carrier.service';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class PowersportsCurrentCarrierGuard implements CanActivate {
  constructor(
    private _currentCarrierService: CurrentCarrierService,
    private _productsService: ProductsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._productsService
      .isProductSelectedWithoutError(ProductTypes.POWERSPORTS)
      .pipe(
        take(1),
        switchMap(isPowersportsSelected => {
          if (isPowersportsSelected) {
            return this.checkProductLoaded().pipe(
              switchMap(isloaded => this.checkStore())
            );
          }
          return of(true);
        })
      );
  }

  checkProductLoaded(): Observable<boolean> {
    return this._productsService.getQuoteLoaded(ProductTypes.POWERSPORTS).pipe(
      filter(loaded => loaded),
      take(1)
    );
  }

  checkStore(): Observable<boolean> {
    return this._currentCarrierService
      .getPowersportsCurrentCarrierLoaded()
      .pipe(
        tap(loaded => {
          if (!loaded) {
            this._currentCarrierService.loadPowersportsCurrentCarrier();
          }
        }),
        filter(loaded => loaded),
        take(1)
      );
  }
}
