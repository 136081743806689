import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';

import { PolicyholderService } from '@core/services/policyholder.service';

@Injectable()
export class RefreshGuard implements CanActivate {
  constructor(
    private _policyholderService: PolicyholderService,
    private _router: Router
  ) {}
  canActivate(): Observable<boolean> {
    return this.checkStoreDsm().pipe(
      switchMap(pniExists => {
        if (!pniExists) {
          this._router.navigateByUrl('/multi-quote/getting-started');
          return of(false);
        }
        return of(true);
      })
    );
  }

  checkStoreDsm() {
    return this._policyholderService.getCurrentNamedInsured().pipe(
      map(person => person !== undefined),
      take(1)
    );
  }
}
