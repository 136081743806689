import { Injectable } from '@angular/core';
import { BaseDsmAdapter } from './base-dsm.adapter';
import { VehicleExposureRequest } from '@core/models/umbrella/vehicle-exposure-request.model';
import { Observable } from 'rxjs';
import { VehicleExposureEntity } from '@core/models/entities/vehicle-exposure.entity';

@Injectable({
  providedIn: 'root',
})
export class VehicleExposureAdapter extends BaseDsmAdapter {
  vehicleExposures(
    request: VehicleExposureRequest
  ): Observable<VehicleExposureEntity> {
    const url = `${this.getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/vehicle-exposures`;

    return this.post<VehicleExposureEntity>(
      url,
      { vehicleExposure: request.vehicleExposure },
      { productId: request.productId }
    );
  }

  deleteVehicleExposure(request: VehicleExposureRequest) {
    const url = `${this.getUrlPrefixForProduct(request.productId)}/quotes/${
      request.quoteId
    }/vehicle-exposures/${request.vehicleExposure.vehicleExposureId}`;

    return this.delete(url, { productId: request.productId });
  }
}
