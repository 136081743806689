import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { Observable } from 'rxjs';
import { RecoverableErrorEntity } from '@core/models/recoverable-error/recoverable-error.entity';

@Injectable({
  providedIn: 'root',
})
export class RecoverableErrorDao {
  constructor(private _store: Store<fromStore.AppState>) {}

  getAllRecoverableErrors(): Observable<RecoverableErrorEntity[]> {
    return this._store.select(fromSelectors.getAllRecoverableErrors);
  }

  getRecoverableErrorById(id: number): Observable<RecoverableErrorEntity> {
    return this._store.select(fromSelectors.getRecoverableErrorById(id));
  }

  getRecoverableErrorsByProduct(
    productId: string
  ): Observable<RecoverableErrorEntity[]> {
    return this._store.select(
      fromSelectors.getRecoverableErrorsByProduct(productId)
    );
  }

  getRecoverableErrorsByPage(
    page: string
  ): Observable<RecoverableErrorEntity[]> {
    return this._store.select(fromSelectors.getRecoverableErrorsByPage(page));
  }

  addRecoverableError(error: RecoverableErrorEntity) {
    this._store.dispatch(new fromActions.AddRecoverableError(error));
  }

  removeRecoverableError(id: number) {
    this._store.dispatch(new fromActions.RemoveRecoverableError(id));
  }

  clearRecoverableErrors() {
    this._store.dispatch(new fromActions.ClearRecoverableErrors());
  }
}
