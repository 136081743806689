import { Injectable } from '@angular/core';
import { HomeownersDao } from '@core/dao/homeowners.dao';
import { Observable } from 'rxjs';
import { HomeownersModel } from '@core/models/homeowners/homeowners.model';
import { StringUtils } from '@shared/utils/string.utils';
import { LendersService } from './lenders.service';
import { take } from 'rxjs/operators';
@Injectable()
export class HomeownersService {
  constructor(
    private _homeownersDao: HomeownersDao,
    private _lendersService: LendersService
  ) {}

  addHomeowners(value: HomeownersModel): void {
    if (!value.id) {
      value.id = StringUtils.generateUUID();
    }
    this._homeownersDao.addHomeowners(value);
  }

  addHomeownersIfAbsent(value: HomeownersModel): void {
    this._homeownersDao
      .getHomeowners()
      .pipe(take(1))
      .subscribe(homeowners => {
        if (!homeowners) {
          this.addHomeowners(value);
        }
      });
  }

  getHomeowners(): Observable<HomeownersModel> {
    return this._homeownersDao.getHomeowners();
  }

  updateHomeowners(value: HomeownersModel): void {
    this._homeownersDao.updateHomeowners(value);
  }

  hasHomeMortgage(): Observable<boolean> {
    return this._homeownersDao.hasHomeMortgage();
  }

  getOfferingType(): Observable<string> {
    return this._homeownersDao.getOfferingType();
  }

  updateOfferingType(offeringType: string): void {
    this._homeownersDao.updateOfferingType(offeringType);
  }
}
