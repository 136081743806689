import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromVehicles from '@core/store/reducers/vehicle.reducer';

import * as fromAdditionalInterest from '@core/store/selectors/additional-interest.selector';
import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import * as fromUserContext from '@core/store/selectors/user-context.selector';

import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { VehicleRequest } from '@core/models/request/vehicle-request.model';
import { VehicleDetailsRequest } from '@core/models/request/vehicle-details-request.model';
import {
  DEFAULT_ID,
  ModifierNames,
  VehicleTypes,
} from '@shared/constants/app-constants';
import * as fromPowersportsVehicles from '@core/store/selectors/powersports-vehicle.selector';

export const getVehicleState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.dsmVehicles
);

export const {
  selectAll: selectAllDsmVehicles,
  selectEntities: selectDsmVehicleEntities,
} = fromVehicles.adapter.getSelectors(getVehicleState);

export const getVehicleEntity = (vehicleId: string) =>
  createSelector(
    selectDsmVehicleEntities,
    fromAdditionalInterest.selectAdditionalInterestEntities,
    (vehicles, interests) => {
      const vehicle: VehicleEntity = { ...vehicles[vehicleId] };

      if (vehicle.additionalInterestIds) {
        vehicle.additionalInterests = vehicle.additionalInterestIds.map(
          id => interests[id]
        );
      } else if (vehicle.additionalInterests?.length === 0) {
        vehicle.additionalInterests = [];
      }

      return vehicle;
    }
  );

export const getAllVehicles = createSelector(
  selectAllDsmVehicles,
  fromAdditionalInterest.selectAdditionalInterestEntities,
  (vehicles, interests) => {
    return vehicles.map(vehicle => {
      const returnVehicle = { ...vehicle };

      if (vehicle.additionalInterestIds) {
        returnVehicle.additionalInterests = vehicle.additionalInterestIds.map(
          id => interests[id]
        );
      } else if (vehicle.additionalInterests) {
        returnVehicle.additionalInterests = vehicle.additionalInterests.map(
          additionalInterest =>
            interests[additionalInterest.additionalInterestId]
        );
      } else {
        returnVehicle.additionalInterests = [];
      }

      return returnVehicle;
    });
  }
);

export const getVehiclesWithAdditionalInterest = createSelector(
  getAllVehicles,
  fromProducts.getSelectedProductsWithoutErrors,
  (vehicles, products) => {
    const productIds = products.map(product => product.id);
    return vehicles.filter(
      vehicle =>
        vehicle.additionalInterests &&
        vehicle.additionalInterests.length > 0 &&
        productIds.includes(vehicle.productId)
    );
  }
);

export const getAllVehiclesWithoutRegisteredOwners = createSelector(
  getAllVehicles,
  vehicles =>
    vehicles.filter(
      veh => !veh.registeredOwners || !veh.registeredOwners.length
    )
);

export const getAllVehiclesWithoutRegisteredOwnersIncludingPowersports =
  createSelector(
    getAllVehiclesWithoutRegisteredOwners,
    fromPowersportsVehicles.getAllPowersportsVehiclesWithoutRegisteredOwners,
    (vehicles, powersportsVehicles) => {
      const vehiclesFiltered = vehicles.filter(
        veh => !veh.registeredOwners || !veh.registeredOwners.length
      );
      const powersportsVehiclesFiltered = powersportsVehicles.filter(
        veh => !veh.registeredOwners || !veh.registeredOwners.length
      );
      return vehiclesFiltered.concat(powersportsVehiclesFiltered);
    }
  );

export const getVehicleByVin = (vin: string) =>
  createSelector(getAllVehicles, vehicles => {
    for (const vehicle of vehicles) {
      if (vehicle.vin === vin) {
        return vehicle;
      }
    }
    return null;
  });

export const buildVehicleRequest = (entity: VehicleEntity) =>
  createSelector(
    fromProducts.getProduct(entity.productId),
    getAllVehicles,
    fromUserContext.getUserContext,
    (product, allDsmVehicles, userContext) => {
      const request: VehicleRequest = {
        quoteId: product.quoteId,
        productId: product.id,
        vehicle: {
          vehicleId: entity.vehicleId,
          year: entity.year,
          make: entity.make,
          makeDescription: entity.makeDescription,
          model: entity.model,
          modelDescription: entity.modelDescription,
          vin: entity.vin?.toUpperCase() || null,
          vehicleType: entity.vehicleType,
          vehicleViewType: entity.vehicleViewType,
          primaryUse: entity.primaryUse,
          additionalInterests:
            entity.additionalInterests &&
            entity.additionalInterests.length > 0 &&
            entity.additionalInterests[0].additionalInterestType !== 'none'
              ? entity.additionalInterests
              : [],
          bodyStyle: entity.bodyStyle,
          bodyType: entity.bodyType,
          prefillId: entity.prefillId,
          antiTheftDevices: null,
          eligibleDiscounts: null,
          previouslyOwned: entity.previouslyOwned,
          isPurchasedNew: entity.isPurchasedNew,
          purchasedNewDate: entity.purchasedNewDate,
          daysCommutingPerWeek: entity.daysCommutingPerWeek,
          milesOneWay: entity.milesOneWay,
          odometerReading: entity.odometerReading,
          isRegisteredInState: entity.isRegisteredInState,
          annualMiles: entity.annualMiles !== 'NaN' ? entity.annualMiles : '1',
          vehicleIdentificationPattern: entity.vehicleIdentificationPattern,
          // PQCC Temp change. Once they start using PrePopulated, this will need changed.
          prequalifiedPartnerScoreId: userContext.prequalifiedPartnerScoreId
            ? allDsmVehicles.length === 0
              ? userContext.prequalifiedPartnerScoreId
              : undefined
            : undefined,
        },
      };
      if (entity.registeredOwners) {
        request.vehicle.registeredOwners = entity.registeredOwners;
      }
      if (entity.isRegisteredOwnerNotListed) {
        request.vehicle.isRegisteredOwnerNotListed =
          entity.isRegisteredOwnerNotListed;
      }
      if (entity.vehicleTempId) {
        request.vehicle.vehicleTempId = entity.vehicleTempId;
      }
      if (entity.prepopulatedInsuredId) {
        request.vehicle.prepopulatedInsuredId = entity.prepopulatedInsuredId;
      }
      if (entity.partialQuoteId) {
        request.vehicle.partialQuoteId = entity.partialQuoteId;
        if (entity.prequalifiedPartnerScoreId) {
          request.vehicle.prequalifiedPartnerScoreId =
            entity.prequalifiedPartnerScoreId;
        }
      }
      // There shouldn't be a purchasedNewDate if not isPurchasedNew, but it's a hard error if so, so hey just be sure:
      if (!entity.isPurchasedNew) {
        delete request.vehicle.purchasedNewDate;
      }
      if (entity.garageLocation) {
        request.vehicle.garageLocation = entity.garageLocation;
      }
      return request;
    }
  );

export const buildVehicleDetailsRequest = (entity: VehicleEntity) =>
  createSelector(
    fromTokens.getAccessToken(entity.productId),
    fromProducts.getProduct(entity.productId),
    fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
    (token, product, address) => {
      const request: VehicleDetailsRequest = {
        quoteId: product.quoteId,
        accessToken: token,
        state: address.state,
        vin: (entity.vin || '').trim(),
        entity,
      };

      return request;
    }
  );

export const getVehicleSmartRideLoaded = (vehicleId: string) =>
  createSelector(selectDsmVehicleEntities, vehicles => {
    const vehicle: VehicleEntity = { ...vehicles[vehicleId] };

    if (vehicle && vehicle.eligibleDiscounts) {
      const smartride = vehicle.eligibleDiscounts.find(
        discount =>
          discount.eligibleDiscountId === ModifierNames.VEHICLE_SMARTRIDE
      );
      return smartride ? smartride.selectedOptionValue : false;
    }
    return false;
  });

export const getSelfReportedLoaded = (vehicleId: string) =>
  createSelector(selectDsmVehicleEntities, vehicles => {
    const vehicle: VehicleEntity = { ...vehicles[vehicleId] };

    if (vehicle && vehicle.eligibleDiscounts) {
      const selfreported = vehicle.eligibleDiscounts.find(
        discount =>
          discount.eligibleDiscountId === ModifierNames.VEHICLE_SELFREPORTED
      );
      return selfreported ? selfreported.selectedOptionValue : false;
    }
    return false;
  });

export const getVehicleDiscount = (vehicleId: string, discountId: string) =>
  createSelector(selectDsmVehicleEntities, vehicles => {
    const vehicle: VehicleEntity = { ...vehicles[vehicleId] };
    if (vehicle && vehicle.eligibleDiscounts) {
      const discount = vehicle.eligibleDiscounts.find(
        discount => discount.eligibleDiscountId === discountId
      );
      return { ...discount, productId: vehicle.productId };
    }
    return null;
  });

export const getVehicleDiscountsLoaded = (vehicleId: string) =>
  createSelector(selectDsmVehicleEntities, vehicles => {
    const vehicle: VehicleEntity = { ...vehicles[vehicleId] };

    return vehicle ? vehicle.vehicleDiscountsLoaded : false;
  });

export const getVehiclesLoading = createSelector(
  getVehicleState,
  state => state.loading > 0
);

export const getVehiclesLoadingIncludingPowersports = createSelector(
  getVehicleState,
  fromPowersportsVehicles.getPowersportsVehicleState,
  (state, powersportsState) => state.loading > 0 || powersportsState.loading > 0
);

export const isThereAnySmartrideInstantConsentQualifiedVehicle = createSelector(
  selectAllDsmVehicles,
  vehicles => {
    let foundAny = false;
    vehicles.forEach(vehicle => {
      if (
        (vehicle.vehicleType === VehicleTypes.AUTO &&
          vehicle.make === 'TOYO' &&
          +vehicle.year >= 2018) ||
        (vehicle.vehicleType === VehicleTypes.AUTO &&
          vehicle.make !== 'TOYO' &&
          +vehicle.year >= 2015)
      ) {
        foundAny = true;
      }
    });
    return foundAny;
  }
);
