import { Injectable } from '@angular/core';
import { CondoDao } from '@core/dao/condo.dao';
import { Observable } from 'rxjs';
import { CondoModel } from '@core/models/condo/condo.model';
import { StringUtils } from '@shared/utils/string.utils';
import { take } from 'rxjs/operators';

@Injectable()
export class CondoService {
  constructor(private _condoDao: CondoDao) {}

  addCondo(value: CondoModel): void {
    if (!value.id) {
      value.id = StringUtils.generateUUID();
    }
    this._condoDao.addCondo(value);
  }

  addCondoIfAbsent(value: CondoModel): void {
    this._condoDao
      .getCondo()
      .pipe(take(1))
      .subscribe(condo => {
        if (!condo) {
          this.addCondo(value);
        }
      });
  }

  getCondo(): Observable<CondoModel> {
    return this._condoDao.getCondo();
  }

  updateCondo(value: CondoModel): void {
    this._condoDao.updateCondo(value);
  }

  hasCondoMortgage(): Observable<boolean> {
    return this._condoDao.hasCondoMortgage();
  }
}
