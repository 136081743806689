import { StandardizedAddresses } from '@core/models/address/standardized-address.model';

import * as fromLenders from '@core/store/actions/lenders.action';

export interface MortgageEntity {
  escrow: boolean;
  name: string;
  loanNumber: string;
  standardizedAddresses: StandardizedAddresses;
  mortgageId: string;
  mortgageType: string;
  type: string;
  companyId: string;
}

export interface LoanEntity {
  name: string;
  expMonth: string;
  expYear: string;
  standardizedAddresses: StandardizedAddresses;
}

export interface LeaseEntity {
  name: string;
  standardizedAddresses: StandardizedAddresses;
}

export interface LendersState {
  entities: {
    [id: string]: {
      id: string;
      selected: boolean;
      mortgage: MortgageEntity;
      loan: LoanEntity;
      lease: LeaseEntity;
    };
  };
}

export const initialState: LendersState = {
  entities: {},
};

export function reducer(
  state = initialState,
  action: fromLenders.LendersActions
): LendersState {
  switch (action.type) {
    case fromLenders.ADD_DSM_LENDER:
    case fromLenders.ADD_LENDER: {
      const { id } = action.payload;

      const entities = {
        ...state.entities,
        [id]: action.payload,
      };

      return {
        ...state,
        entities,
      };
    }

    case fromLenders.ADD_LENDER_SUCCESS: {
      const addedEntities = {};
      if (
        action.payload &&
        action.payload.mortgageId &&
        action.payload.companyId
      ) {
        for (const id of Object.keys(state.entities)) {
          const entity = state.entities[id];
          if (
            entity.mortgage &&
            entity.mortgage.companyId === action.payload.companyId &&
            entity.mortgage.loanNumber === action.payload.loanNumber
          ) {
            addedEntities[id] = {
              ...entity,
              mortgage: {
                ...entity.mortgage,
                mortgageId: action.payload.mortgageId,
                mortgageType: action.payload.mortgageType,
                type: action.payload.type,
              },
            };
            break;
          }
        }
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          ...addedEntities,
        },
      };
    }

    case fromLenders.UPDATE_DSM_LENDER_SUCCESS: {
      const modifiedEntities = {};
      if (
        action.payload &&
        action.payload.mortgageId &&
        action.payload.companyId &&
        action.payload.mortgageId !== 'null'
      ) {
        for (const id of Object.keys(state.entities)) {
          const entity = state.entities[id];
          if (
            entity &&
            entity.mortgage &&
            entity.mortgage.mortgageId === action.payload.mortgageId &&
            entity.mortgage.mortgageType === action.payload.mortgageType
          ) {
            modifiedEntities[id] = {
              ...entity,
              mortgage: {
                ...action.payload,
              },
            };
            break;
          }
        }
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          ...modifiedEntities,
        },
      };
    }

    case fromLenders.ADD_LENDER_FAIL:
    case fromLenders.UPDATE_DSM_LENDER_FAIL: {
      if (action.payload && action.payload.requestPayload) {
        const { id } = action.payload.requestPayload;
        const newState = { entities: {} };
        for (const key in state.entities) {
          if (key !== id) {
            newState.entities[key] = state.entities[key];
          }
        }
        state = newState;
      }
      return state;
    }

    case fromLenders.DELETE_LENDER_SUCCESS: {
      const lender = action.payload;
      const { [lender.id]: removed, ...entities } = state.entities;
      //--------------------------------------------
      console.log('State. Entites : ', state.entities);
      console.log('Entities : ', entities);

      const modifiedEntities = {};
      let newPrimaryMortgage = true;
      for (const id of Object.keys(entities)) {
        const entity = entities[id];
        if (newPrimaryMortgage) {
          modifiedEntities[id] = {
            ...entity,
            mortgage: {
              ...entity.mortgage,
              type: 'Mortgagee1',
            },
          };
          newPrimaryMortgage = false;
          break;
        }
      }

      return {
        ...state,
        entities: {
          ...entities,
          ...modifiedEntities,
        },
      };
    }
  }
  return state;
}

export const getLendersState = (state: LendersState) => state;
