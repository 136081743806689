<div class="display-panel-buttons btn-padding-top">
  <button
    type="button"
    (click)="cancel.emit({})"
    class="btn btn-default cancel"
    id="dp-cancel-{{ id || '0' }}"
  >
    Cancel
  </button>
  <button type="submit" class="btn btn-primary" id="dp-save-{{ id || '0' }}">
    Save
  </button>
</div>
