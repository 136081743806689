import { Action } from '@ngrx/store';

export const enum DocumentActionTypes {
  GET_DOCUMENTS = '[Documents] Get Documents',
  GET_DOCUMENTS_FAIL = '[Documents] Get Documents Fail',
  GET_DOCUMENTS_SUCCESS = '[Documents] Get Documents Success',
}

export class GetDocuments implements Action {
  readonly type = DocumentActionTypes.GET_DOCUMENTS;
}

export class GetDocumentsFail implements Action {
  readonly type = DocumentActionTypes.GET_DOCUMENTS_FAIL;
  constructor(public error: any) {}
}

export class GetDocumentsSuccess implements Action {
  readonly type = DocumentActionTypes.GET_DOCUMENTS_SUCCESS;
  constructor(public payload: any) {}
}

export type DocumentActions =
  | GetDocuments
  | GetDocumentsFail
  | GetDocumentsSuccess;
