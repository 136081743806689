import { createSelector } from '@ngrx/store';

import { isPrivateLabel, isAgent } from './agency.selector';
import { getPrimaryNamedInsured } from './policyholder.selector';
import { selectPolicyAddressEntities } from './policy-address.selector';
import { getAllVehicles } from './vehicle.selector';
import { selectAllDrivers } from './driver.selector';
import { getPolicyEffectiveDates } from './policy.selector';
import { getQuoteState } from './session.selector';
import { getCurrentCarrier } from './current-carrier.selector';
import { ProductTypes } from '@shared/constants/app-constants';
import { getProduct, getSelectedProducts } from './products.selector';
import { getCoveragesFor } from './coverage.selector';
import { NonStandardErrorCodes } from '@shared/constants/non-standard-constants';
import { NonStandardUtils } from '@shared/utils/non-standard.utils';
import { VehicleResponse } from '@core/models/response/vehicle-response.model';
import { Product } from '@core/models/products/product.model';
import { getStateSpecificFlagsObject } from './metadata.selector';

export const isNonStandardCode = (errorCode: string) =>
  createSelector(
    isAgent,
    isPrivateLabel,
    getStateSpecificFlagsObject,
    (isAgent, isPrivateLabel, stateSpecificFlags) => {
      return NonStandardErrorCodes.includes(errorCode) &&
        stateSpecificFlags.nonStandardBridgeAllowed &&
        !isAgent &&
        !isPrivateLabel
        ? errorCode
        : null;
    }
  );

export const isNonStandard = createSelector(
  isAgent,
  isPrivateLabel,
  getSelectedProducts,
  getStateSpecificFlagsObject,
  (isAgent, isPrivateLabel, selectedProducts, stateSpecificFlags) => {
    const autoProduct: Product[] = selectedProducts.filter(product => {
      return product.id === ProductTypes.AUTO;
    });

    return autoProduct.length > 0 &&
      NonStandardUtils.isNatGenError(autoProduct[0].errorCode) &&
      stateSpecificFlags.nonStandardBridgeAllowed &&
      !isAgent &&
      !isPrivateLabel
      ? autoProduct[0].errorCode
      : null;
  }
);

export const getBridgeDataControl = createSelector(
  getPolicyEffectiveDates,
  getQuoteState,
  (effectiveDates, quoteState) => {
    return NonStandardUtils.setControl({
      transactionEffectiveDate: effectiveDates.autoEffectiveDate,
      stateID: quoteState,
    });
  }
);

export const getBridgeDataCustomer = createSelector(
  getPrimaryNamedInsured,
  selectPolicyAddressEntities,
  (policyHolder, policyAddressEntities) => {
    return NonStandardUtils.setCustomer({
      name: {
        firstName: policyHolder.person.firstName,
        lastName: policyHolder.person.lastName,
      },
      person: {
        dateOfBirth: policyHolder.person.dateOfBirth,
        gender: policyHolder.person.gender,
      },
      address: [
        {
          addressLine1:
            policyAddressEntities[policyHolder.addressId].addressLine1,
          addressLine2:
            policyAddressEntities[policyHolder.addressId].addressLine2,
          city: policyAddressEntities[policyHolder.addressId].city,
          state: policyAddressEntities[policyHolder.addressId].state,
          postalCode: policyAddressEntities[policyHolder.addressId].postalCode,
          county: '',
          type: 'home',
        },
      ],
      contactPoints: [
        {
          contactPoint: policyHolder.emailAddress,
          type: 'email-home',
        },
        {
          contactPoint: policyHolder.homeNumber.replace(/\-/g, ''),
          type: 'phone-cell',
        },
      ],
    });
  }
);

export const getBridgeDataQuote = createSelector(
  getCurrentCarrier,
  getPolicyEffectiveDates,
  getQuoteState,
  getProduct(ProductTypes.AUTO),
  (currentCarrier, effectiveDates, quoteState, product) => {
    return NonStandardUtils.setQuote({
      quoteNumber: product.quoteId,
      rateState: quoteState,
      effectiveDate: effectiveDates.autoEffectiveDate,
      priorCarrier: {
        priorInsurerName: currentCarrier.currentCarrierName,
        // do not have date of last prior/current carrier
      },
      maintainedContinousCoverage: currentCarrier.coverageLapse,
    });
  }
);

export const getBridgeDataDrivers = createSelector(
  selectAllDrivers,
  drivers => {
    return NonStandardUtils.setDriver(drivers);
  }
);

export const getBridgeDataVehicles = createSelector(
  getAllVehicles,
  getCoveragesFor(ProductTypes.AUTO),
  getStateSpecificFlagsObject,
  (vehicles, coverages, stateSpecificFlags) => {
    return NonStandardUtils.setVehicle(
      vehicles as VehicleResponse[],
      coverages,
      stateSpecificFlags
    );
  }
);

export const getNonStandardBridgeRequest = createSelector(
  getBridgeDataControl,
  getBridgeDataCustomer,
  getBridgeDataQuote,
  getBridgeDataDrivers,
  getBridgeDataVehicles,
  (control, customer, quote, driver, vehicle) => {
    return {
      // For NatGen, Control needs to be Capitalized
      Control: control,
      customer,
      quote,
      driver,
      vehicle,
    };
  }
);
