import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  EventEmitter,
} from '@angular/core';
import { PropertyInfoProxy } from '@quote/components/property-info/proxies/property-info.proxy';
import { Observable, Subject } from 'rxjs';
import { HomeownersModel } from '@core/models/homeowners/homeowners.model';
import { ProductTypes } from '@shared/constants/app-constants';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { CondoModel } from '@core/models/condo/condo.model';
import { RentersModel } from '@core/models/renters/renters.model';
import { ProtectiveDevice } from '@core/store/reducers/protective-devices.reducer';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Animations } from '@shared/animations/animations';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { PropertyHelper } from '@core/services/helpers/property.helper';
import { LoggingService } from '@core/services';

declare let Bootstrapper: any;

@Component({
  selector: 'mq-property-info-modal',
  templateUrl: './property-info-modal.component.html',
  styleUrls: ['./property-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.slideIn, Animations.create],
})
export class PropertyInfoModalComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  isAppLoading$: Observable<boolean>;
  policyholder$: Observable<PolicyholderEntity>;
  homeowners$: Observable<HomeownersModel>;
  condo$: Observable<CondoModel>;
  renters$: Observable<RentersModel>;
  options$: Observable<any>;

  isAgent$: Observable<boolean>;

  coveredLocation$: Observable<CoveredLocation>;
  productId: string;

  formSubmitted$ = new Subject<boolean>();
  protectiveDeviceOptions$: Observable<any>;
  protectiveDevices$: Observable<ProtectiveDevice[]>;
  yearBuilt$: Observable<number>;
  requiredOilTank: Observable<boolean>;
  stateSpecificFlags$: Observable<any>;
  smartHomeDiscountForHome$: Observable<EligibleDiscountEntity>;
  smartHomeDiscountForCondo$: Observable<EligibleDiscountEntity>;
  partialQuoteId$: Observable<string>;
  partialQuoteCoveredLocation$: Observable<CoveredLocation>;

  propertyInfoSaved = new EventEmitter<Event>();

  isHome: boolean;
  isCondo: boolean;
  isRenters: boolean;

  smartHomeExperience: string;

  private _ngUnsubscribe = new Subject();

  constructor(
    private _proxy: PropertyInfoProxy,
    private _propertyHelper: PropertyHelper,
    private _fb: UntypedFormBuilder,
    private _loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this._proxy.logPageLoaded();
    this._proxy
      .getSelectedProperty()
      .subscribe(productId => (this.productId = productId));

    // TODO Maybe get to this
    // this.productId$ = this._proxy
    // .getSelectedProperty();

    this.isAppLoading$ = this._proxy.isAppLoading();
    this.isAgent$ = this._proxy.isAgent();

    this.protectiveDevices$ = this._proxy.getAllProtectiveDevices();
    this.protectiveDeviceOptions$ = this._proxy.getProtectiveDevicesOptions();

    this.stateSpecificFlags$ = this._proxy.getStateSpecificFlags();

    this.policyholder$ = this._proxy.getPrimaryNamedInsured();

    if (this.productId === ProductTypes.HOMEOWNERS) {
      this.isHome = true;
      this.form = this._fb.group({
        homeownersForm: this._propertyHelper.buildHomeownerForm(this._fb),
        protectiveDevicesForm: this._fb.group({}),
        smarthomeForm: this._fb.group({
          optIn: this._fb.control(false, Validators.required),
        }),
      });
      this.homeowners$ = this._proxy.getHomeowners();
      this.coveredLocation$ = this._proxy.getCoveredLocation();
      this._proxy.getAllRenovation();
      this.options$ = this._proxy.getPropertyInfoHomeownerPicklistData();

      this.smartHomeDiscountForHome$ = this._proxy.getSmartHomeDiscount(
        'SmartDevice',
        ProductTypes.HOMEOWNERS
      );
    }
    this.partialQuoteId$ = this._proxy.getPartialQuoteId();
    this.partialQuoteCoveredLocation$ =
      this._proxy.getPartialQuoteCoveredLocation();

    if (this.productId === ProductTypes.CONDO) {
      this.isCondo = true;
      this.condo$ = this._proxy.getCondo();
      this.coveredLocation$ = this._proxy.getCoveredLocation();

      this.form = this.form = this._fb.group({
        condoForm: this._propertyHelper.buildCondoForm(this._fb),
        protectiveDevicesForm: this._fb.group({}),
        smarthomeForm: this._fb.group({
          optIn: this._fb.control(false, []),
        }),
        // oilTankForm: this._propertyHelper.buildOilTankForm(this._fb),
      });
      this.options$ = this._proxy.getPropertyInfoCondoPicklistData();

      this.options$.subscribe(response =>
        this._proxy.addCondoPickList(response)
      );

      this.smartHomeDiscountForCondo$ = this._proxy.getSmartHomeDiscount(
        'SmartDevice',
        ProductTypes.CONDO
      );
    }

    if (this.productId === ProductTypes.RENTERS) {
      this.isRenters = true;
      this.renters$ = this._proxy.getRenters();
      this.form = this.form = this._fb.group({
        rentersForm: this._propertyHelper.buildRenterForm(this._fb),
        protectiveDevicesForm: this._fb.group({}),
      });
      this.options$ = this._proxy.getPropertyInfoRentersPicklistData();

      this.options$.subscribe(response =>
        this._proxy.addRentersPickList(response)
      );
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  onBack() {
    this._proxy.navigateBack();
  }

  onSave() {
    if (!this.form.valid) {
      return;
    }

    this.propertyInfoSaved.emit();

    if (this.isHome) {
      this._proxy.addHomeowners({
        hasMortgage: this.homeownersForm.value.hasMortgage,
        primary: true,
        renovations: this.homeownersForm.value.renovationsForm,
      });
      this._proxy.updateCoveredLocation(
        ProductTypes.HOMEOWNERS,
        this._propertyHelper.formatCoveredLocation(
          this.homeownersForm.value,
          this._propertyHelper.serializeProtectiveDevices(
            this.protectiveDevicesForm.value
          )
        )
      );
    }
    if (this.isCondo) {
      this._proxy.addCondo({
        hasMortgage: this.condoForm.value.hasMortgage,
        primary: true,
        roofCondition: this.condoForm.value.roofCondition,
      });
      this._proxy.updateCoveredLocation(
        ProductTypes.CONDO,
        this._propertyHelper.formatCondoCoveredLocation(
          this.condoForm.value,
          this._propertyHelper.serializeProtectiveDevices(
            this.protectiveDevicesForm.value
          )
        )
      );
    }
    if (this.isRenters) {
      const form = this.rentersForm.value;
      this._proxy.updateRenters({
        id: '0',
        // prettier-ignore
        personalPropertyCoverage: this.rentersForm.value.personalPropertyCoverage,
        numberOfOccupants: this.rentersForm.value.numberOfOccupants,
        unitsInBuilding: this.rentersForm.value.unitsInBuilding,
        structuralMaterial: this.rentersForm.value.structuralMaterial,
      });
      this._proxy.updateCoveredLocation(ProductTypes.RENTERS, {
        numberOfOccupants: this.rentersForm.value.numberOfOccupants,
        unitsInBuilding: +this.rentersForm.value.unitsInBuilding,
        constructionInfo: {
          constructionType: 'F',
        },
        protectiveDevices: this._propertyHelper.formatProtectiveDevicesRequest(
          this._propertyHelper.serializeProtectiveDevices(
            this.protectiveDevicesForm.value
          )
        ),
      });
    }
    if (this.smarthomeForm && this.smarthomeForm.dirty) {
      this.submitSmartHome();
    }
    this._proxy.logPageSubmit();
  }

  setSmartHomeExperience(event: string) {
    this.smartHomeExperience = event;
  }

  private submitSmartHome() {
    const optInChoice = this.smarthomeForm.value.optIn
      ? 'Participating'
      : 'Declined';
    this._loggingService.logToSplunk({
      event: 'SMARTHOME_PO_FORM_SUBMITTED_PROPERTYINFOMODAL',
      message: JSON.stringify({
        optInChoice: optInChoice,
      }),
    });
    this._proxy.dispatchUpdateSmartHomeDiscount(optInChoice);
    this.sendSmartHomeOptInChoiceToCelebrus();
  }

  private sendSmartHomeOptInChoiceToCelebrus(): void {
    if (this.productId === ProductTypes.HOMEOWNERS) {
      if (
        Bootstrapper?.Celebrus &&
        Bootstrapper?.Celebrus?.sendCelebrusCustomAttribute
      ) {
        const key = 'Digex_534_smarthome';
        const smartHomeCelebrusAttributeValue =
          (this.smarthomeForm.value.optIn ? 'yes' : 'no') +
          '|' +
          this.smartHomeExperience;

        Bootstrapper.Celebrus.sendCelebrusCustomAttribute(
          key,
          smartHomeCelebrusAttributeValue
        );

        this._loggingService.logToSplunk({
          event: 'SMARTHOME_PO_PARTICIPATIONCHOICE_LOGGED',
          message: JSON.stringify({
            key: key,
            value: smartHomeCelebrusAttributeValue,
          }),
        });
      } else {
        this._loggingService.logToSplunk({
          event: 'SMARTHOME_PO_BOOTSTRAPPERCELEBRUS_UNDEFINED_PROPERTYINFO',
        });
      }
    }
  }

  get homeownersForm() {
    return this.form.get('homeownersForm');
  }

  get condoForm() {
    return this.form.get('condoForm');
  }

  get rentersForm() {
    return this.form.get('rentersForm');
  }

  get smarthomeForm() {
    return this.form.get('smarthomeForm');
  }

  get protectiveDevicesForm() {
    return this.form.get('protectiveDevicesForm');
  }
}
