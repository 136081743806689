import * as fromCurrentCarrier from '@core/store/actions/current-carrier.action';

export interface PowersportsCurrentCarrier {
  currentCarrierName: string;
  coverageLapse: string;
  currentBodilyInjuryLimit?: string;
  currentCarrierTerms?: string;
  isNationwideWinBack?: boolean;
  reasonForCoverageLapse?: string;
  informationalMessages?: string[];
}
export interface PowersportsCarrierState {
  powersportsCurrentCarrierLoading: boolean;
  powersportsCurrentCarrierLoaded: boolean;
  powersportsCurrentCarrier: PowersportsCurrentCarrier;
  isPowersportsCurrentCarrierHit: boolean;
}

export function reducer(
  state = {
    powersportsCurrentCarrierLoading: false,
    powersportsCurrentCarrierLoaded: false,
    powersportsCurrentCarrier: null,
    isPowersportsCurrentCarrierHit: false,
  },
  action: fromCurrentCarrier.CurrentCarrierActions
): PowersportsCarrierState {
  switch (action.type) {
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .UPDATE_POWERSPORTS_CURRENT_CARRIER: {
      return {
        ...state,
        powersportsCurrentCarrierLoading: true,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .UPDATE_POWERSPORTS_CURRENT_CARRIER_SUCCESS: {
      return {
        ...state,
        powersportsCurrentCarrier: action.payload,
        powersportsCurrentCarrierLoading: false,
        powersportsCurrentCarrierLoaded: true,
        isPowersportsCurrentCarrierHit: false,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .UPDATE_POWERSPORTS_CURRENT_CARRIER_FAIL: {
      return {
        ...state,
        powersportsCurrentCarrierLoading: false,
        powersportsCurrentCarrierLoaded: false,
        isPowersportsCurrentCarrierHit: false,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .GET_POWERSPORTS_CURRENT_CARRIER_SUCCESS: {
      return {
        ...state,
        powersportsCurrentCarrier: action.payload,
        powersportsCurrentCarrierLoading: false,
        powersportsCurrentCarrierLoaded: true,
        isPowersportsCurrentCarrierHit: true,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .GET_POWERSPORTS_CURRENT_CARRIER_FAIL: {
      return {
        ...state,
        powersportsCurrentCarrierLoading: false,
        powersportsCurrentCarrierLoaded: true,
        isPowersportsCurrentCarrierHit: false,
      };
    }
    case fromCurrentCarrier.CurrentCarrierActionTypes
      .UPDATE_POWERSPORTS_CURRENT_CARRIER_FROM_QUOTE: {
      return {
        ...state,
        powersportsCurrentCarrier: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
