import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PolicyIssueRequest } from '@core/models/policy-issue/policy-issue-request.model';
import { PolicyIssueResponse } from '@core/models/policy-issue/policy-issue-response.model';

import { BaseDsmAdapter } from './base-dsm.adapter';

@Injectable({
  providedIn: 'root',
})
export class PolicyIssueAdapter extends BaseDsmAdapter {
  policies(request: PolicyIssueRequest): Observable<PolicyIssueResponse> {
    const url = `${this._appConfig.config.pcEdgePolicyIssueUrl}/policies`;

    const body = {
      quoteId: request.quoteId,
      hasEDDAConsent: request.hasEDDAConsent,
    };

    return this.post<PolicyIssueResponse>(url, body, {
      productId: request.productId,
    }).pipe(
      map(response => {
        response.productId = request.productId;
        return response;
      })
    );
  }
}
