import { Injectable } from '@angular/core';
import { CRUDService } from './crud.service';
import { UnderlyingPolicyEntity } from '@core/models/entities/underlying-policy.entity';
import { Observable } from 'rxjs';
import { UnderlyingPolicyRequest } from '@core/models/umbrella/underlying-policy-request.model';
import { UnderlyingPolicyAdapter } from '@core/adapters/underlying-policy.adapter';

@Injectable({
  providedIn: 'root',
})
export class UnderlyingPolicyService
  implements CRUDService<UnderlyingPolicyEntity>
{
  constructor(private _underlyingPolicyAdapter: UnderlyingPolicyAdapter) {}
  add(entity: UnderlyingPolicyEntity): void {
    throw new Error('Method not implemented.');
  }
  addAll?(entity: UnderlyingPolicyEntity[]): void {
    throw new Error('Method not implemented.');
  }
  delete(entityId: string): void {
    throw new Error('Method not implemented.');
  }
  update(entity: UnderlyingPolicyEntity): void {
    throw new Error('Method not implemented.');
  }
  get?(entityId: string): Observable<UnderlyingPolicyEntity> {
    throw new Error('Method not implemented.');
  }
  getAll?(): Observable<UnderlyingPolicyEntity[]> {
    throw new Error('Method not implemented.');
  }

  underlyingPolicies(request: UnderlyingPolicyRequest): Observable<any> {
    return this._underlyingPolicyAdapter.underlyingPolicies(request);
  }
}
