import { Action } from '@ngrx/store';

export const LOAD_SUFFIX = '[Core] Load Suffix';
export const LOAD_SUFFIX_FAIL = '[Core] Load Suffix Fail';
export const LOAD_SUFFIX_SUCCESS = '[Core] Load Suffix Success';

export class LoadSuffix implements Action {
  readonly type = LOAD_SUFFIX;
}

export class LoadSuffixFail implements Action {
  readonly type = LOAD_SUFFIX_FAIL;
  constructor(public payload: any) {}
}

export class LoadSuffixSuccess implements Action {
  readonly type = LOAD_SUFFIX_SUCCESS;
  constructor(public payload: any) {}
}

export const LOAD_MARITAL_STATUS = '[Core] Load Marital Status';
export const LOAD_MARITAL_STATUS_FAIL = '[Core] Load Marital Status Fail';
export const LOAD_MARITAL_STATUS_SUCCESS = '[Core] Load Marital Status Success';

export const LOAD_GOOD_DRIVER = '[Core] Load good Driver';
export const LOAD_GOOD_DRIVER_FAIL = '[Core] Load good Driver Fail';
export const LOAD_GOOD_DRIVER_SUCCESS = '[Core] Load good Driver Success';

export class LoadMaritalStatus implements Action {
  readonly type = LOAD_MARITAL_STATUS;
}

export class LoadMaritalStatusFail implements Action {
  readonly type = LOAD_MARITAL_STATUS_FAIL;
  constructor(public payload: any) {}
}

export class LoadMaritalStatusSuccess implements Action {
  readonly type = LOAD_MARITAL_STATUS_SUCCESS;
  constructor(public payload: any) {}
}

export const LOAD_STATE_ABBREVIATIONS = '[Core] Load State Abbreviations';
export const LOAD_STATE_ABBREVIATIONS_FAIL =
  '[Core] Load State Abbreviations Fail';
export const LOAD_STATE_ABBREVIATIONS_SUCCESS =
  '[Core] Load State Abbreviations Success';

export class LoadStateAbbreviations implements Action {
  readonly type = LOAD_STATE_ABBREVIATIONS;
}

export class LoadStateAbbreviationsFail implements Action {
  readonly type = LOAD_STATE_ABBREVIATIONS_FAIL;
  constructor(public payload: any) {}
}

export class LoadStateAbbreviationsSuccess implements Action {
  readonly type = LOAD_STATE_ABBREVIATIONS_SUCCESS;
  constructor(public payload: string[]) {}
}

export const LOAD_ACTIVE_STATE_ABBREVIATIONS =
  '[Core] Load Active State Abbreviations';
export const LOAD_ACTIVE_STATE_ABBREVIATIONS_FAIL =
  '[Core] Load Active State Abbreviations Fail';
export const LOAD_ACTIVE_STATE_ABBREVIATIONS_SUCCESS =
  '[Core] Load Active State Abbreviations Success';

export class LoadActiveStateAbbreviations implements Action {
  readonly type = LOAD_ACTIVE_STATE_ABBREVIATIONS;
}

export class LoadActiveStateAbbreviationsFail implements Action {
  readonly type = LOAD_ACTIVE_STATE_ABBREVIATIONS_FAIL;
  constructor(public payload: any) {}
}

export class LoadActiveStateAbbreviationsSuccess implements Action {
  readonly type = LOAD_ACTIVE_STATE_ABBREVIATIONS_SUCCESS;
  constructor(public payload: string[]) {}
}

export const LOAD_DSM_STATE_ABBREVIATIONS =
  '[Core] Load Dsm State Abbreviations';
export const LOAD_DSM_STATE_ABBREVIATIONS_FAIL =
  '[Core] Load Dsm State Abbreviations Fail';
export const LOAD_DSM_STATE_ABBREVIATIONS_SUCCESS =
  '[Core] Load Dsm State Abbreviations Success';

export const LOAD_AGENCY_CHANNEL = '[Core] Load Agency Channel';
export const LOAD_AGENCY_CHANNEL_FAIL = '[Core] Load Agency Channel Fail';
export const LOAD_AGENCY_CHANNEL_SUCCESS = '[Core] Load Agency Channel Success';

export class LoadAgencyChannel implements Action {
  readonly type = LOAD_AGENCY_CHANNEL;
}

export class LoadAgencyChannelFail implements Action {
  readonly type = LOAD_AGENCY_CHANNEL_FAIL;
  constructor(public payload: any) {}
}

export class LoadAgencyChannelSuccess implements Action {
  readonly type = LOAD_AGENCY_CHANNEL_SUCCESS;
  constructor(public payload: any) {}
}

export const LOAD_STATE_SPECIFIC_FLAGS = '[Core] Load State Specific Flags';
export const LOAD_STATE_SPECIFIC_FLAGS_FAIL =
  '[Core] Load State Specific Flags Fail';
export const LOAD_STATE_SPECIFIC_FLAGS_SUCCESS =
  '[Core] Load State Specific Flags Success';

export class LoadStateSpecificFlags implements Action {
  readonly type = LOAD_STATE_SPECIFIC_FLAGS;
  constructor(public payload?: any) {}
}

export class LoadStateSpecificFlagsFail implements Action {
  readonly type = LOAD_STATE_SPECIFIC_FLAGS_FAIL;
  constructor(public payload?: any) {}
}

export class LoadStateSpecificFlagsSuccess implements Action {
  readonly type = LOAD_STATE_SPECIFIC_FLAGS_SUCCESS;
  constructor(public payload: any) {}
}

export const LOAD_FEATURES = '[Core] Load Features';
export const LOAD_FEATURES_FAIL = '[Core] Load Features Fail';
export const LOAD_FEATURES_SUCCESS = '[Core] Load Features Success';

export class LoadFeatures implements Action {
  readonly type = LOAD_FEATURES;
}

export class LoadFeaturesFail implements Action {
  readonly type = LOAD_FEATURES_FAIL;
  constructor(public payload: any) {}
}

export class LoadFeaturesSuccess implements Action {
  readonly type = LOAD_FEATURES_SUCCESS;
  constructor(public payload: any) {}
}

export const LOAD_CONNECTED_CAR_CRITERIA = '[Core] Load Connected Car Criteria';
export const LOAD_CONNECTED_CAR_CRITERIA_SUCCESS =
  '[Core] Load Connected Car Criteria Success';
export const LOAD_CONNECTED_CAR_CRITERIA_FAIL =
  '[Core] Load Connected Car Criteria Fail';

export class LoadConnectedCarCriteria implements Action {
  readonly type = LOAD_CONNECTED_CAR_CRITERIA;
  constructor(public payload: string) {}
}

export class LoadConnectedCarCriteriaSuccess implements Action {
  readonly type = LOAD_CONNECTED_CAR_CRITERIA_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadConnectedCarCriteriaFail implements Action {
  readonly type = LOAD_CONNECTED_CAR_CRITERIA_FAIL;
  constructor(public payload: any) {}
}

export const LOAD_ANTI_THEFT_DEVICES = '[Core] Load Anti Theft Devices';
export const LOAD_ANTI_THEFT_DEVICES_SUCCESS =
  '[Core] Load Anti Theft Devices Success';
export const LOAD_ANTI_THEFT_DEVICES_FAIL =
  '[Core] Load Anti Theft Devices Fail';
export const LOAD_VEHICLE_ELIGIBLE_DISCOUNTS =
  '[Core] Load Vehicle Eligible Discounts';
export const LOAD_VEHICLE_ELIGIBLE_DISCOUNTS_SUCCESS =
  '[Core] Load Vehicle Eligible Discounts Success';
export const LOAD_VEHICLE_ELIGIBLE_DISCOUNTS_FAIL =
  '[Core] Load Vehicle Eligible Discounts Fail';
export const LOAD_VEHICLE_PRIMARY_USE_OPTIONS =
  '[Core] Load Vehicle Primary Use Options';
export const LOAD_VEHICLE_PRIMARY_USE_OPTIONS_SUCCESS =
  '[Core] Load Vehicle Primary Use Options Success';
export const LOAD_VEHICLE_PRIMARY_USE_OPTIONS_FAIL =
  '[Core] Load Vehicle Primary Use Options Fail';

export class LoadAntiTheftDevices implements Action {
  readonly type = LOAD_ANTI_THEFT_DEVICES;
  constructor() {}
}

export class LoadAntiTheftDevicesSuccess implements Action {
  readonly type = LOAD_ANTI_THEFT_DEVICES_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadAntiTheftDevicesFail implements Action {
  readonly type = LOAD_ANTI_THEFT_DEVICES_FAIL;
  constructor(public payload: any) {}
}

export class LoadVehicleEligibleDiscounts implements Action {
  readonly type = LOAD_VEHICLE_ELIGIBLE_DISCOUNTS;
  constructor() {}
}

export class LoadVehicleEligibleDiscountsSuccess implements Action {
  readonly type = LOAD_VEHICLE_ELIGIBLE_DISCOUNTS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadVehicleEligibleDiscountsFail implements Action {
  readonly type = LOAD_VEHICLE_ELIGIBLE_DISCOUNTS_FAIL;
  constructor(public payload: any) {}
}

export class LoadVehiclePrimaryUseOptions implements Action {
  readonly type = LOAD_VEHICLE_PRIMARY_USE_OPTIONS;
  constructor() {}
}

export class LoadVehiclePrimaryUseOptionsSuccess implements Action {
  readonly type = LOAD_VEHICLE_PRIMARY_USE_OPTIONS_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadVehiclePrimaryUseOptionsFail implements Action {
  readonly type = LOAD_VEHICLE_PRIMARY_USE_OPTIONS_FAIL;
  constructor(public payload: any) {}
}

export type MetadataActions =
  | LoadSuffix
  | LoadSuffixFail
  | LoadSuffixSuccess
  | LoadMaritalStatus
  | LoadMaritalStatusFail
  | LoadMaritalStatusSuccess
  | LoadStateAbbreviations
  | LoadStateAbbreviationsFail
  | LoadStateAbbreviationsSuccess
  | LoadActiveStateAbbreviations
  | LoadActiveStateAbbreviationsFail
  | LoadActiveStateAbbreviationsSuccess
  | LoadAgencyChannel
  | LoadAgencyChannelSuccess
  | LoadAgencyChannelFail
  | LoadStateSpecificFlags
  | LoadStateSpecificFlagsFail
  | LoadStateSpecificFlagsSuccess
  | LoadFeatures
  | LoadFeaturesFail
  | LoadFeaturesSuccess
  | LoadConnectedCarCriteria
  | LoadConnectedCarCriteriaSuccess
  | LoadConnectedCarCriteriaFail
  | LoadAntiTheftDevices
  | LoadAntiTheftDevicesSuccess
  | LoadAntiTheftDevicesFail
  | LoadVehicleEligibleDiscounts
  | LoadVehicleEligibleDiscountsSuccess
  | LoadVehicleEligibleDiscountsFail
  | LoadVehiclePrimaryUseOptions
  | LoadVehiclePrimaryUseOptionsSuccess
  | LoadVehiclePrimaryUseOptionsFail;
