import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { Alert } from '@core/models/page-alerts/page-alert.model';
import { AlertService } from '@core/services/alert.service';
import { PAGE_ALERT_DISMISS_EXPIRY } from '@shared/constants/app-constants';

@Component({
  selector: 'mq-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  private _alert: Alert;

  @Input() set alert(alert: Alert) {
    if (alert && !this._alert) {
      window.scrollTo(0, 0);
    }
    this._alert =
      alert && alert.dismissible && this._cookieService.get(alert.id) === 'true'
        ? null
        : alert;
  }

  get alert(): Alert {
    return this._alert;
  }

  constructor(
    private alertService: AlertService,
    private _cookieService: CookieService
  ) {}

  close() {
    this._cookieService.set(this.alert.id, 'true', PAGE_ALERT_DISMISS_EXPIRY);
    this.alertService.clear();
  }
}
