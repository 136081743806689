<div
  [class]="isIneligible ? 'ineligible' : 'unenrolled'"
  *ngIf="vehicles.length"
>
  <nw-display-panel
    [heading]="panelHeading"
    [alwaysOpen]="true"
    class="program-panel"
  >
    <div class="tm-content-container">
      <p>
        {{ panelSubHeading }}
      </p>
    </div>
    <mq-telematics-vehicle-premium
      [vehicles]="vehicles"
      [vehiclePremiums]="vehiclePremiums"
      [isIneligible]="isIneligible"
      (enrollmentChanged)="changeEnrollment($event)"
    ></mq-telematics-vehicle-premium>
  </nw-display-panel>
</div>
