import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import * as fromStore from '@core/store';
import * as plaidActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';

import {
  switchMap,
  catchError,
  tap,
  take,
  map,
  mergeMap,
} from 'rxjs/operators';

import {
  BillingService,
  LoadingService,
  LoggingService,
  PlaidService,
} from '@core/services';
import { from } from 'rxjs';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { PlaidExchangeTokenResponse } from '@core/models/billing/plaid/plaid.model';

@Injectable()
export class PlaidEffects {
  readonly NON_MASKED_DIGITS_LENGTH = 4;

  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _loadingService: LoadingService,
    private _plaidService: PlaidService,
    private _billingService: BillingService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  createPlaidLinkToken = this._actions$.pipe(
    ofType(plaidActions.CREATE_PLAID_LINK_TOKEN),
    mergeMap(() =>
      this._store
        .select(fromSelectors.buildPlaidCreateLinkTokenRequest)
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('CreatePlaidLinkToken Request', request)
    ),
    switchMap(request => {
      return this._plaidService.plaidCreateLinkToken(request).pipe(
        tap(response =>
          this._loggingService.log('CreatePlaidLinkToken Response', response)
        ),
        switchMap(response => {
          return from([new plaidActions.CreatePlaidLinkTokenSuccess(response)]);
        }),
        catchError(error => {
          return from([new plaidActions.CreatePlaidLinkTokenFail(error)]);
        })
      );
    })
  );

  @Effect()
  getBankName = this._actions$.pipe(
    ofType(plaidActions.GET_BANK_NAME),
    map((action: any) => action.payload),
    tap((request: PlaidExchangeTokenResponse) =>
      this._loggingService.log('getBankName Request', request.routingNumber)
    ),
    switchMap((request: PlaidExchangeTokenResponse) => {
      return this._billingService.dsmGetBankName(request.routingNumber).pipe(
        switchMap(response => {
          return from([new plaidActions.GetBankNameSuccess(response)]);
        })
      );
    })
  );

  @Effect()
  plaidExchangeToken = this._actions$.pipe(
    ofType(plaidActions.PLAID_EXCHANGE_TOKEN),
    map((action: plaidActions.PlaidExchangeToken) => action.payload),
    tap(request => {
      this._loadingService.loadingBegin();
      this._loggingService.log('PlaidExchangeToken Request', request);
    }),
    switchMap(request => {
      return this._plaidService.plaidExchangeToken(request).pipe(
        tap(response => {
          this._loggingService.log(
            'PlaidExchangeToken Response',
            this.maskPrivateInfoAndLogToSplunk(response)
          );
          this._loadingService.loadingEnd();
        }),
        switchMap(response => {
          const exchangeResponse = {
            balance: response.accounts[0].balances.available,
            accountType: response.accounts[0].subtype,
            accountNumber: response.numbers.ach[0].account,
            routingNumber: response.numbers.ach[0].routing,
          } as PlaidExchangeTokenResponse;

          return from([
            new plaidActions.PlaidExchangeTokenSuccess(exchangeResponse),
            new plaidActions.GetBankName(exchangeResponse),
          ]);
        }),
        catchError(error => {
          this._loadingService.loadingEnd();
          this._loggingService.log('PlaidExchangeToken Error', error);
          return from([new plaidActions.PlaidExchangeTokenFail(error)]);
        })
      );
    })
  );

  maskPrivateInfoAndLogToSplunk(response: any) {
    const responseToLog = JSON.parse(JSON.stringify(response));
    if (response.numbers) {
      const accNum = responseToLog.numbers.ach[0].account;
      responseToLog.numbers.ach[0].account =
        '****' + accNum.slice(accNum.length - this.NON_MASKED_DIGITS_LENGTH);
    }
    if (responseToLog.accounts && responseToLog.accounts[0].balances) {
      responseToLog.accounts[0].balances.available = '***';
      responseToLog.accounts[0].balances.current = '***';
    }
    return responseToLog;
  }
}
