import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from '../store';
import * as fromSelectors from '../store/selectors';
import * as fromActions from '../store/actions';

import { Observable } from 'rxjs';
import { HelpContentModel } from '@help-content/models/help-content.model';

@Injectable()
export class HelpContentDao {
  constructor(private _store: Store<fromStore.HelpContentState>) {}

  setHelpContent(content: HelpContentModel): void {
    this._store.dispatch(new fromActions.SetHelpContent(content));
  }

  isHelpContentDismissed(name: string): Observable<boolean> {
    return this._store.select(fromSelectors.isHelpContentDismissed(name));
  }
}
