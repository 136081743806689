import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  RetrieveModel,
  HydrateModel,
} from '@core/models/retrieve/retrieve.model';
import {
  RetrieveQuoteResponse,
  HydrateQuoteResponse,
} from '@core/models/auto/quotes/retrieve-quote-response.model';
import { RetrieveDao } from '@core/dao/retrieve.dao';
import { RetrieveAdapter } from '@core/adapters/retrieve.adapter';
import {
  RetrieveEntity,
  RetrieveFailure,
} from '@core/models/entities/retrieve.entity';
import {
  BypassFields,
  BypassRetrieveResponse,
} from '@core/models/bypass-retrieve/bypass-retrieve.model';

@Injectable({
  providedIn: 'root',
})
export class RetrieveService {
  constructor(
    private _retrieveDao: RetrieveDao,
    private _retrieveAdapter: RetrieveAdapter
  ) {}

  retrieveQuote(model: RetrieveModel) {
    this._retrieveDao.retrieveQuote(model);
  }

  getRetrievedQuote(productId: string): Observable<RetrieveEntity> {
    return this._retrieveDao.getRetrievedQuote(productId);
  }

  getAllRetrievedQuotes(): Observable<RetrieveEntity[]> {
    return this._retrieveDao.getAllRetrievedQuotes();
  }

  retrieve(request: RetrieveModel): Observable<RetrieveQuoteResponse> {
    if (request.quoteId) {
      return this._retrieveAdapter.retrieve(request);
    } else {
      return this._retrieveAdapter.nonQuoteIdRetrieve(request);
    }
  }

  declareRetrieveQuoteFailure(message: RetrieveFailure) {
    this._retrieveDao.declareRetrieveQuoteFailure(message);
  }

  declareAllRetrieveQuotesFailed() {
    this._retrieveDao.declareAllRetrieveQuotesFailed();
  }

  getAllFailedRetrieves(): Observable<RetrieveFailure[]> {
    return this._retrieveDao.getAllFailedRetrieves();
  }

  getAllRetrievesFailedFlag(): Observable<boolean> {
    return this._retrieveDao.getAllRetrievesFailedFlag();
  }

  clearFailures() {
    this._retrieveDao.clearFailures();
  }

  hydrateQuote(request) {
    this._retrieveDao.hydrateQuote(request);
  }

  updateCompRaterQuoteId(newDeepLaunchId) {
    this._retrieveDao.updateCompRaterQuoteId(newDeepLaunchId);
  }

  getCompRaterQuoteId() {
    return this._retrieveDao.getCompRaterQuoteId();
  }

  callHydrateQuote(
    request: HydrateModel
  ): Observable<HydrateQuoteResponse[] | HydrateQuoteResponse> {
    return this._retrieveAdapter.hydrateQuote(request);
  }

  setCompRaterError(errorMsg: string) {
    this._retrieveDao.setCompRaterError(errorMsg);
  }

  getCompRaterError() {
    return this._retrieveDao.getCompRaterError();
  }

  isCompRater(): Observable<boolean> {
    return this._retrieveDao.isCompRater();
  }

  getGettingStartedInformationalMessages(): Observable<string[]> {
    return this._retrieveDao.getGettingStartedInformationalMessages();
  }

  getCoveragesInformationalMessages(): Observable<string[]> {
    return this._retrieveDao.getCoveragesInformationalMessages();
  }

  hasBeenPreviouslyHydrated(): Observable<boolean> {
    return this._retrieveDao.hasBeenPreviouslyHydrated();
  }

  getRetrieveSuccess(): Observable<boolean> {
    return this._retrieveDao.getRetrieveSuccess();
  }

  getRetrieveLoading(): Observable<number> {
    return this._retrieveDao.getRetrieveLoading();
  }

  getTargetPage(): Observable<string> {
    return this._retrieveDao.getTargetPage();
  }

  updateRedirectTarget(target: string): void {
    this._retrieveDao.updateRedirectTarget(target);
  }

  isNewProductSelected(): Observable<boolean> {
    return this._retrieveDao.isNewProductSelected();
  }

  triggerBypassRetrieve(bypassFields: BypassFields): void {
    this._retrieveDao.bypassRetrieve(bypassFields);
  }

  callBypassRetrieve(
    bypassFields: BypassFields
  ): Observable<BypassRetrieveResponse> {
    return this._retrieveAdapter.bypassRetrieve(bypassFields);
  }

  getBypassRetrieve(): Observable<BypassRetrieveResponse> {
    return this._retrieveDao.getBypassRetrieve();
  }

  getRetrievedEntities(productId: string): Observable<any> {
    return this._retrieveDao.getRetrievedEntities(productId);
  }

  getHasMortgageForRetrieve(): Observable<boolean> {
    return this._retrieveDao.getHasMortgageForRetrieve();
  }

  getEscrowForRetrieve(): Observable<boolean> {
    return this._retrieveDao.getEscrowForRetrieve();
  }
}
