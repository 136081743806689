import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { CRUDService } from '@core/services/crud.service';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { Update } from '@ngrx/entity';
import { DsmResetEventService } from '@core/services/reset.service';

@Injectable({
  providedIn: 'root',
})
export class VehicleDao
  implements CRUDService<VehicleEntity>, DsmResetEventService
{
  constructor(private _store: Store<fromStore.AppState>) {}

  add(entity: VehicleEntity): void {
    this._store.dispatch(new fromActions.AddVehicle(entity));
  }

  dispatchAddPowersportsVehicle(entity: VehicleEntity): void {
    this._store.dispatch(new fromActions.AddPowersportsVehicle(entity));
  }

  addAll?(entity: VehicleEntity[]): void {
    throw new Error('Method not implemented.');
  }

  delete(entityId: string): void {
    this._store.dispatch(new fromActions.DeleteVehicle(entityId));
  }

  diapatchDeletePowersportsVehicle(vehicle: VehicleEntity): void {
    this._store.dispatch(new fromActions.DeletePowersportsVehicle(vehicle));
  }

  update(entity: VehicleEntity): void {
    this._store.dispatch(new fromActions.UpdateVehicle(entity));
  }

  dispatchUpdatePowersportsVehicle(entity: VehicleEntity): void {
    this._store.dispatch(new fromActions.UpdatePowersportsVehicle(entity));
  }

  updateEvent(entity: VehicleEntity): void {
    const updated: Update<VehicleEntity> = {
      id: entity.vehicleId,
      changes: entity,
    };

    this._store.dispatch(new fromActions.UpdateVehicleDirectly(updated));
  }

  get(entityId: string): Observable<VehicleEntity> {
    return this._store.select(fromSelectors.getVehicleEntity(entityId));
  }

  getPowersportsVehicleEntity(entityId: string): Observable<VehicleEntity> {
    return this._store.select(
      fromSelectors.getPowersportsVehicleEntity(entityId)
    );
  }

  getAll(): Observable<VehicleEntity[]> {
    return this._store.select(fromSelectors.getAllVehicles);
  }

  getAllPowersportsVehicles(): Observable<VehicleEntity[]> {
    return this._store.select(fromSelectors.getAllPowersportsVehicles);
  }

  getAllPowersportsVehiclesIfSelected(): Observable<VehicleEntity[]> {
    return this._store.select(
      fromSelectors.getAllPowersportsVehiclesIfSelected
    );
  }

  getVehicleByVin(vin: string): Observable<VehicleEntity> {
    return this._store.select(fromSelectors.getVehicleByVin(vin));
  }

  vehicleDetails(entity: VehicleEntity): void {
    this._store.dispatch(new fromActions.UpdateVehicleDetails(entity));
  }

  powersportsVehicleDetails(entity: VehicleEntity): void {
    this._store.dispatch(
      new fromActions.UpdatePowersportsVehicleDetails(entity)
    );
  }

  getVehicleDiscountsLoaded(vehicleId: string): Observable<boolean> {
    return this._store.select(
      fromSelectors.getVehicleDiscountsLoaded(vehicleId)
    );
  }

  unsetVehicleDiscountsLoaded(vehicleId: string): void {
    this._store.dispatch(
      new fromActions.UnSetVehicleDiscountsLoaded(vehicleId)
    );
  }

  getVehiclesLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getVehiclesLoading);
  }

  getVehiclesLoadingIncludingPowersports(): Observable<boolean> {
    return this._store.select(
      fromSelectors.getVehiclesLoadingIncludingPowersports
    );
  }

  getAllVehiclesWithoutRegisteredOwners(): Observable<VehicleEntity[]> {
    return this._store.select(
      fromSelectors.getAllVehiclesWithoutRegisteredOwners
    );
  }

  getAllVehiclesWithoutRegisteredOwnersIncludingPowersports(): Observable<
    VehicleEntity[]
  > {
    return this._store.select(
      fromSelectors.getAllVehiclesWithoutRegisteredOwnersIncludingPowersports
    );
  }

  getPowersportsVehicleAuxillaryLightingSystemDiscountValue(
    powersportsVehicleId
  ): Observable<boolean> {
    return this._store.select(
      fromSelectors.getPowersportsVehicleAuxillaryLightingSystemDiscountValue(
        powersportsVehicleId
      )
    );
  }

  isThereAnySmartrideInstantConsentQualifiedVehicle(): Observable<boolean> {
    return this._store.select(
      fromSelectors.isThereAnySmartrideInstantConsentQualifiedVehicle
    );
  }
}
