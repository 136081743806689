import * as fromFeature from '@core/store/reducers';

import { createSelector } from '@ngrx/store';

export const getCurrentCarrierState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.currentCarrier
);

export const getCurrentCarrierLoaded = createSelector(
  getCurrentCarrierState,
  (state): boolean => state.currentCarrierLoaded
);

export const getCurrentCarrierLoading = createSelector(
  getCurrentCarrierState,
  (state): boolean => state.currentCarrierLoading
);

export const getCurrentCarrier = createSelector(
  getCurrentCarrierState,
  currentCarrier => currentCarrier.currentCarrier
);
export const isCoverageLapsePresent = createSelector(
  getCurrentCarrierState,
  currentCarrier => {
    const currentCarrierInfo = currentCarrier?.currentCarrier;
    if (currentCarrierInfo?.coverageLapse) {
      if (currentCarrierInfo?.coverageLapse !== '0Days') {
        return true;
      } else return false;
    }
    return false;
  }
);

export const getCurrentCarrierDecision = createSelector(
  getCurrentCarrierState,
  (state): string => state.currentCarrierDecision
);
