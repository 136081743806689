import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import {
  StateSpecificFlagKeys,
  TelematicsProgram,
} from '@shared/constants/app-constants';
import { MetadataService } from '@core/services';
import { Observable } from 'rxjs';
import { TelematicsEnrollmentRecommendationResponse } from '@core/models/telematics/telematics.model';
import { TelematicsEnrollmentsService } from '@core/services/telematics-enrollments.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'mq-telematics-video',
  templateUrl: './telematics-video.component.html',
  styleUrls: ['./telematics-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelematicsVideoComponent implements OnInit {
  showSmartMilesCA$: Observable<boolean>;

  @Input() name: string;
  @Input() isDeviceOnlyState: boolean;
  @Input() mobileAppEnrolled: boolean;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() initialDiscount: string;
  @Input() dataCollectionMethod: string;
  recommendation: TelematicsEnrollmentRecommendationResponse;
  @HostBinding('class.col-sm-3')
  iscol = true;

  constructor(
    private _metadataService: MetadataService,
    private _telematicsEnrollmentsService: TelematicsEnrollmentsService
  ) {}

  ngOnInit(): void {
    this.showSmartMilesCA$ = this._metadataService.getStateSpecificFlag(
      StateSpecificFlagKeys.selfReportedProgram
    );
    this.getRecommendation();
  }
  isSmartRideEarlyRewardApplicable(): boolean {
    if (
      this.stateSpecificFlags &&
      this.stateSpecificFlags[StateSpecificFlagKeys.smartRideEarlyReward]
    ) {
      return true;
    } else return false;
  }

  getRecommendation(): void {
    this._telematicsEnrollmentsService
      .getRecommendation()
      .pipe(take(1))
      .subscribe(recommendation => {
        this.recommendation = recommendation;
      });
  }

  shouldOfferSmartMiles(): boolean {
    let smartmiles2_Eligible = false;
    if (
      this.recommendation?.recommendedProgram === 'Vehicle Program' &&
      this.recommendation?.qualifyingInformation.vehicles.find(
        vehicle => vehicle.availablePrograms.length > 0
      )
    ) {
      this.recommendation.qualifyingInformation.vehicles.forEach(vehicle => {
        vehicle.availablePrograms.forEach(availableProgram => {
          if (
            availableProgram.version === '2.0' &&
            availableProgram.name === TelematicsProgram.SMART_MILES
          ) {
            smartmiles2_Eligible = true;
          }
        });
      });
    }
    return smartmiles2_Eligible;
  }
}
