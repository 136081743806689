import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';

/**
 * Angular doesn't support redirects in ROUTES config with query parameters. So this is a pass through component to
 * redirect to the route you want while still preserving query parameters.
 */
@Component({
  selector: 'nw-query-parameter-preserving-redirect',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryParameterPreservingRedirectComponent implements OnInit {
  constructor(private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    combineLatest([this._route.data, this._route.queryParams]).subscribe(
      ([data, queryParams]) => {
        const newQueryParams = Object.assign({}, queryParams);
        if (data.swap) {
          newQueryParams[data.swap['output']] = queryParams[data.swap.input];
        }

        const extras: NavigationExtras = { queryParams: newQueryParams };

        this._router.navigate([data.destination], extras);
      }
    );
  }
}
