import { Product } from '@core/models/products/product.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { ProductTypes } from '@shared/constants/app-constants';
import { IsDirectOrNssAgentDirective } from '@shared/directives/is-direct-or-nss-agent/is-direct-or-nss-agent.directive';

export class ProductUtils {
  static products = {
    Auto: 'AUTO',
    Homeowners: 'PROPERTY',
    Condo: 'PROPERTY',
    Renters: 'PROPERTY',
    Umbrella: 'AUTO',
    Powersports: 'POWERSPORTS',
  };

  static mapProductToProductName(product: string): string {
    return ProductUtils.products[product];
  }

  static filteredProducts(
    products: Product[],
    agencyProducts: string[],
    stateSpecificFlags: StateSpecificFlagsObject,
    isNSSAgent: boolean,
    isEAOrIAAgentQuoting: boolean,
    isAgent: boolean,
    isthirdParty: boolean,
    quoteState: string
  ): Product[] {
    let filteredProducts = [...products];
    if (stateSpecificFlags.restrictHOProduct && (isAgent || isthirdParty)) {
      filteredProducts = filteredProducts.filter(
        product => product.id !== ProductTypes.HOMEOWNERS
      );
      filteredProducts = filteredProducts.filter(
        product => product.id !== ProductTypes.CONDO
      );
    }
    if (quoteState && !stateSpecificFlags.powersportsProductApplicable) {
      filteredProducts = filteredProducts.filter(
        product => product.id !== ProductTypes.POWERSPORTS
      );
    }
    if (isNSSAgent) {
      filteredProducts = filteredProducts.filter(
        product => product.id !== ProductTypes.POWERSPORTS
      );

      if (stateSpecificFlags.restrictRentersProductForNSS) {
        filteredProducts = filteredProducts.filter(
          product => product.id !== ProductTypes.RENTERS
        );
      }
    }
    if (isEAOrIAAgentQuoting) {
      filteredProducts = filteredProducts.filter(
        product => product.id !== ProductTypes.TERMLIFE
      );
      filteredProducts = filteredProducts.filter(
        product => product.id !== ProductTypes.POWERSPORTS
      );
    }
    if (!isAgent) {
      if (stateSpecificFlags.restrictAllProductsForDirect) {
        filteredProducts = filteredProducts.filter(
          product => product.id !== ProductTypes.AUTO
        );
        filteredProducts = filteredProducts.filter(
          product => product.id !== ProductTypes.POWERSPORTS
        );
        filteredProducts = filteredProducts.filter(
          product => product.id !== ProductTypes.HOMEOWNERS
        );
        filteredProducts = filteredProducts.filter(
          product => product.id !== ProductTypes.CONDO
        );
        filteredProducts = filteredProducts.filter(
          product => product.id !== ProductTypes.RENTERS
        );
        filteredProducts = filteredProducts.filter(
          product => product.id !== ProductTypes.TERMLIFE
        );
      }
    }
    if (!agencyProducts) {
      return filteredProducts;
    }

    return filteredProducts.filter(product =>
      agencyProducts.includes(product.name)
    );
  }
}
