import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';

export const getHomeownerPropertyInfo = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.homeownerPropertyInfo
);

export const getPickListValue = createSelector(
  getHomeownerPropertyInfo,
  state => state.pickListValue
);
