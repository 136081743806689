import { AccountHolderRequest } from '../../models/request/account-request.model';
import {
  AccountActionType,
  AccountAction,
} from '@core/store/actions/account.action';

export interface AccountState {
  accountHolder?: AccountHolderRequest;
  accountId: string;
  loaded: boolean;
  loading: boolean;
  productIdsForNewBusinessCall: string[];
}

const initialState: AccountState = {
  accountHolder: null,
  accountId: null,
  loaded: false,
  loading: false,
  productIdsForNewBusinessCall: [],
};

export function reducer(
  state = initialState,
  action: AccountAction
): AccountState {
  switch (action.type) {
    case AccountActionType.CREATE_ACCOUNT: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case AccountActionType.CREATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        accountHolder: action.accountHolder,
        accountId: action.accountId,
        loading: false,
        loaded: true,
      };
    }
    case AccountActionType.CREATE_ACCOUNT_FAIL: {
      return { ...state, loading: false, loaded: true };
    }
    case AccountActionType.SET_ACCOUNT_ID: {
      return { ...state, accountId: action.payload };
    }
    case AccountActionType.ADD_PRODUCT_ID_FOR_NEW_BUSINESS_CALL: {
      const productIdsForNewBusinessCall = [
        ...state.productIdsForNewBusinessCall,
      ];
      productIdsForNewBusinessCall?.unshift(action.payload);
      productIdsForNewBusinessCall.sort((a, b) => (a < b ? -1 : 1));

      return {
        ...state,
        productIdsForNewBusinessCall: productIdsForNewBusinessCall,
      };
    }

    case AccountActionType.REMOVE_PRODUCT_ID_FOR_NEW_BUSINESS_CALL: {
      const productIdsForNewBusinessCall = [
        ...state.productIdsForNewBusinessCall,
      ];
      const filteredProductIdsForNewBusinessCall =
        productIdsForNewBusinessCall?.filter(val => val != action.payload);
      return {
        ...state,
        productIdsForNewBusinessCall: filteredProductIdsForNewBusinessCall
          ? filteredProductIdsForNewBusinessCall
          : [],
      };
    }
    default:
      return { ...state };
  }
}
