import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {
  catchError,
  map,
  tap,
  switchMap,
  take,
  mergeMap,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services/logging.service';
import { QuoteService } from '@core/services/quote.service';
import { PolicyholderBuilder } from '@shared/utils/builders/policyholder.builder';
import { LoadingService } from '@core/services/loading.service';
import { PolicyholderService } from '@core/services/policyholder.service';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import {
  MaritalStatusToDsmCodes,
  PolicyholderTypes,
  ProductTypes,
} from '@shared/constants/app-constants';

@Injectable()
export class PolicyholderEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _quoteService: QuoteService,
    private _loggingService: LoggingService,
    private _loadingService: LoadingService,
    private _policyholderService: PolicyholderService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  addPolicyholder$ = this._actions$.pipe(
    ofType(fromActions.PolicyholderActionTypes.ADD_POLICYHOLDER),
    map((action: fromActions.AddPolicyholder) => action.payload),
    switchMap(payload =>
      this._store
        .select(fromSelectors.buildAddPolicyholderRequest(payload))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('addPolicyholder Request', request)
    ),
    mergeMap(request =>
      this._quoteService.addPolicyholder(request).pipe(
        tap(response =>
          this._loggingService.log('addPolicyholder Response', response)
        ),
        switchMap(response => {
          // TODO refactor
          const policyHolderEntity =
            PolicyholderBuilder.buildPolicyholderEntityFromResponse(
              response,
              request.productId,
              request.body.policyHolder.address.addressId,
              request.body.policyHolder.person.dateOfBirth,
              request.body.policyHolder.policyHolderType,
              request.body.prefillId,
              request.body.policyHolder.emailAddress,
              request.body.policyHolder.homeNumber
            );

          policyHolderEntity.eventType = 'create';

          if (
            request.body.policyHolder.prepopulatedInsuredId &&
            request.body.policyHolder.policyHolderTempId
          ) {
            policyHolderEntity.prepopulatedInsuredId =
              request.body.policyHolder.prepopulatedInsuredId;
            policyHolderEntity.policyHolderTempId =
              request.body.policyHolder.policyHolderTempId;
          }

          if (
            request.body.policyHolder.partialQuoteId &&
            request.body.policyHolder.policyHolderTempId
          ) {
            policyHolderEntity.partialQuoteId =
              request.body.policyHolder.partialQuoteId;
            policyHolderEntity.policyHolderTempId =
              request.body.policyHolder.policyHolderTempId;
          }

          if (request.body.policyHolder.person) {
            policyHolderEntity.person.termLife =
              request.body.policyHolder.person.termLife;
            policyHolderEntity.person.termLifeHeightFeet =
              request.body.policyHolder.person.termLifeHeightFeet;
            policyHolderEntity.person.termLifeHeightInches =
              request.body.policyHolder.person.termLifeHeightInches;
            policyHolderEntity.person.termLifeWeight =
              request.body.policyHolder.person.termLifeWeight;
            policyHolderEntity.person.termLifeHealth =
              request.body.policyHolder.person.termLifeHealth;
            policyHolderEntity.person.termLifeNico =
              request.body.policyHolder.person.termLifeNico;
            policyHolderEntity.person.termLifeEligible =
              request.body.policyHolder.person.termLifeEligible;
          }

          return from([
            new fromActions.AddPolicyholderSuccess(policyHolderEntity),
            new fromActions.PolicyholderCallComplete(),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('addPolicyholder Error', safeError);
          return from([
            new fromActions.AddPolicyholderFail(safeError),
            new fromActions.PolicyholderCallComplete(),
          ]);
        })
      )
    )
  );

  @Effect()
  updatePolicyholder$ = this._actions$.pipe(
    ofType(fromActions.PolicyholderActionTypes.UPDATE_POLICYHOLDER),
    map((action: fromActions.UpdatePolicyholder) => action.payload),
    switchMap(payload =>
      this._store
        .select(fromSelectors.buildUpdatePolicyholderRequest(payload))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('updatePolicyholder Request', request)
    ),
    withLatestFrom(
      this._policyholderService.getNamedInsured(),
      this._policyholderService.getAll()
    ),
    mergeMap(([request, pni, allPolicyHolders]) => {
      if (request.body.policyHolderId === undefined) {
        return of(new fromActions.UpdatePolicyholderFail(request));
      }
      return this._quoteService.updatePolicyholder(request).pipe(
        tap(response =>
          this._loggingService.log('updatePolicyholder Response', response)
        ),
        switchMap(response => {
          const policyHolderEntity =
            PolicyholderBuilder.buildPolicyholderEntityFromResponse(
              response,
              request.productId,
              request.body.policyHolder.address.addressId,
              request.body.policyHolder.person.dateOfBirth,
              request.body.policyHolder.policyHolderType,
              request.body.prefillId,
              request.body.policyHolder.emailAddress,
              request.body.policyHolder.homeNumber
            );

          policyHolderEntity.eventType = 'update';

          if (
            request.body.policyHolder.prepopulatedInsuredId &&
            request.body.policyHolder.policyHolderTempId
          ) {
            policyHolderEntity.prepopulatedInsuredId =
              request.body.policyHolder.prepopulatedInsuredId;
            policyHolderEntity.policyHolderTempId =
              request.body.policyHolder.policyHolderTempId;
          }

          if (
            request.body.policyHolder.partialQuoteId &&
            request.body.policyHolder.policyHolderTempId
          ) {
            policyHolderEntity.partialQuoteId =
              request.body.policyHolder.partialQuoteId;
            policyHolderEntity.policyHolderTempId =
              request.body.policyHolder.policyHolderTempId;
          }

          if (request.body.policyHolder.person) {
            policyHolderEntity.person.termLife =
              request.body.policyHolder.person.termLife;
            policyHolderEntity.person.termLifeHeightFeet =
              request.body.policyHolder.person.termLifeHeightFeet;
            policyHolderEntity.person.termLifeHeightInches =
              request.body.policyHolder.person.termLifeHeightInches;
            policyHolderEntity.person.termLifeWeight =
              request.body.policyHolder.person.termLifeWeight;
            policyHolderEntity.person.termLifeHealth =
              request.body.policyHolder.person.termLifeHealth;
            policyHolderEntity.person.termLifeNico =
              request.body.policyHolder.person.termLifeNico;
            policyHolderEntity.person.termLifeEligible =
              request.body.policyHolder.person.termLifeEligible;
          }

          const nextActions: Action[] = [
            new fromActions.UpdatePolicyholderSuccess(policyHolderEntity),
            new fromActions.PolicyholderCallComplete(),
          ];

          if (pni.policyHolderId === policyHolderEntity.policyHolderId) {
            if (
              pni.person &&
              policyHolderEntity.person &&
              pni.person.lastName !== policyHolderEntity.person.lastName
            ) {
              nextActions.push(
                new fromActions.GenerateAccessToken(request.productId)
              );
              nextActions.push(
                new fromActions.UpdateBillingName(policyHolderEntity.person)
              );
            }
            const secondaryNamedInsuredExists = allPolicyHolders.some(
              policyHolder =>
                policyHolder.policyHolderType ===
                PolicyholderTypes.POLICY_SECONDARY_NAMED_INSURED
            );
            if (
              (policyHolderEntity.productId === ProductTypes.CONDO ||
                policyHolderEntity.productId === ProductTypes.RENTERS ||
                policyHolderEntity.productId === ProductTypes.HOMEOWNERS) &&
              !secondaryNamedInsuredExists &&
              (policyHolderEntity.person.maritalStatus ===
                MaritalStatusToDsmCodes.Married ||
                policyHolderEntity.person.maritalStatus ===
                  MaritalStatusToDsmCodes.Separated)
            ) {
              const marriedPolicyHolder = allPolicyHolders.find(
                ph =>
                  ph.policyHolderType !==
                    PolicyholderTypes.POLICY_PRIMARY_NAMED_INSURED &&
                  (ph.person.maritalStatus ===
                    MaritalStatusToDsmCodes.Married ||
                    ph.person.maritalStatus ===
                      MaritalStatusToDsmCodes.Separated)
              );
              if (marriedPolicyHolder) {
                nextActions.push(
                  new fromActions.DeletePolicyholder(
                    marriedPolicyHolder.policyHolderId
                  )
                );
                nextActions.push(
                  new fromActions.AddPolicyholder(marriedPolicyHolder)
                );
              }
            }
          }

          return nextActions;
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('updatePolicyholder Error', safeError);
          return from([
            new fromActions.UpdatePolicyholderFail(safeError),
            new fromActions.PolicyholderCallComplete(),
          ]);
        })
      );
    })
  );

  @Effect()
  deletePolicyholder$ = this._actions$.pipe(
    ofType(fromActions.PolicyholderActionTypes.DELETE_POLICYHOLDER),
    map((actions: fromActions.DeletePolicyholder) => actions.payload),
    switchMap(policyholderId =>
      this._store
        .select(fromSelectors.getPolicyholder(policyholderId))
        .pipe(take(1))
    ),
    filter(policyholder => !!policyholder),
    switchMap(policyholder =>
      this._store
        .select(fromSelectors.buildUpdatePolicyholderRequest(policyholder))
        .pipe(take(1))
    ),
    tap(request => {
      this._loadingService.loadingBegin();
      this._loggingService.log('deletePolicyHolder Request', request);
    }),
    switchMap(request =>
      this._quoteService.deletePolicyholder(request).pipe(
        tap(response =>
          this._loggingService.log('deletePolicyholder Response', response)
        ),
        switchMap(response => {
          this._loadingService.loadingEnd();
          return from([
            new fromActions.DeletePolicyholderSuccess(
              request.body.policyHolderId
            ),
            new fromActions.PolicyholderCallComplete(),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loadingService.loadingEnd();
          this._loggingService.log('deletePolicyholder Error', safeError);
          return from([
            new fromActions.DeleteVehicleFail(safeError),
            new fromActions.PolicyholderCallComplete(),
          ]);
        })
      )
    )
  );

  @Effect()
  updateContactPointDsm$ = this._actions$.pipe(
    ofType(fromActions.PolicyholderActionTypes.UPDATE_CONTACT_POINT_DSM),
    map((action: fromActions.UpdateContactPointDsm) => action.payload),
    switchMap(contactPoint =>
      this._store
        .select(fromSelectors.buildUpdateContactPointRequest(contactPoint))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.logToSplunk({
        event: 'MULTIPRODUCT_MODIFY_CONTACT_POINT_REQUEST',
        message: request,
      })
    ),
    switchMap(request => {
      return this._quoteService.loadModifyContactPointResponse(request).pipe(
        tap(response =>
          this._loggingService.logToSplunk({
            event: 'MULTIPRODUCT_MODIFY_CONTACT_POINT_RESPONSE_SUCCESS',
            message: response,
          })
        ),
        map(response => {
          return new fromActions.UpdateContactPointDsmSuccess(response);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.logToSplunk({
            event: 'MULTIPRODUCT_MODIFY_CONTACT_POINT_RESPONSE_ERROR',
            message: safeError,
          });
          return of(new fromActions.UpdateContactPointDsmFail(safeError));
        })
      );
    })
  );

  @Effect()
  updatePropertyPolicyholder$ = this._actions$.pipe(
    ofType(fromActions.PolicyholderActionTypes.UPDATE_PROPERTY_POLICYHOLDER),
    map((action: fromActions.UpdatePropertyPolicyholder) => action.payload),
    switchMap(payload =>
      this._store
        .select(fromSelectors.buildUpdatePolicyholderRequest(payload))
        .pipe(take(1))
    ),
    tap(request =>
      this._loggingService.log('UpdatePropertyPolicyHolder Request', request)
    ),
    switchMap(request => {
      return this._quoteService.updatePolicyholder(request).pipe(
        tap(response =>
          this._loggingService.log(
            'UpdatePropertyPolicyHolder Response',
            response
          )
        ),
        switchMap(response => {
          return from([
            new fromActions.UpdatePropertyPolicyholderSuccess(response),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log(
            'UpdatePropertyPolicyHolder Error',
            safeError
          );
          return of(new fromActions.UpdatePropertyPolicyholderFail(safeError));
        })
      );
    })
  );
}
