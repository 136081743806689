import { Component, Input, EventEmitter, Output } from '@angular/core';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { DiscountLabels, ModifierNames } from '@shared/constants/app-constants';

@Component({
  selector: 'mq-vehicle-discounts',
  templateUrl: './vehicle-discounts.component.html',
  styleUrls: ['./vehicle-discounts.component.scss'],
})
export class VehicleDiscountsComponent {
  @Input() vehicleDiscounts: any[];

  @Output() discountToggled = new EventEmitter<EligibleDiscountEntity>();

  constructor() {}

  onPillToggled(discount: EligibleDiscountEntity, event) {
    this.discountToggled.emit({
      ...discount,
      selectedOptionValue: event.value + '',
    });
  }

  getDiscountLabel(discountId: string): string {
    switch (discountId) {
      case ModifierNames.VEHICLE_SMARTRIDE:
        return DiscountLabels.VEHICLE_SMARTRIDE;
      case ModifierNames.VEHICLE_SELFREPORTED:
        return DiscountLabels.VEHICLE_SELFREPORTED;
      default:
        return discountId;
    }
  }

  isVehicleDiscountsAvailable() {
    return this.vehicleDiscounts.some(
      discount => !!discount.smartride && !!discount.selfreported
    );
  }

  isSmartrideVehicleOnlyDiscountAvailable() {
    return this.vehicleDiscounts.some(
      discount => !!discount.smartride && !discount.selfreported
    );
  }
}
