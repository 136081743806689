import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import {
  catchError,
  withLatestFrom,
  filter,
  tap,
  mergeMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import {
  ProductsService,
  LoggingService,
  AppConfigService,
} from '@core/services';
import { ProductTypes } from '@shared/constants/app-constants';
import { QuoteService } from '@core/services/quote.service';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { CurrentCarrierService } from '@core/services/current-carrier.service';

@Injectable()
export class CurrentCarrierEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _productsService: ProductsService,
    private _quoteService: QuoteService,
    private _appConfig: AppConfigService,
    private _loggingService: LoggingService,
    private _errorHelper: ErrorHelper,
    private _currentCarrierService: CurrentCarrierService
  ) {}

  @Effect()
  getCurrentCarrier$ = this._actions$.pipe(
    ofType(fromActions.CurrentCarrierActionTypes.GET_CURRENT_CARRIER),
    mergeMap(action =>
      this._productsService.getAutoTokenLoaded().pipe(
        filter(autoTokenLoaded => autoTokenLoaded),
        withLatestFrom(
          this._store.select(fromSelectors.getProduct(ProductTypes.AUTO))
        ),
        mergeMap(([tokenLoaded, product]) =>
          this._quoteService
            .retrieveCurrentCarrier({
              quoteId: product.quoteId,
              productId: product.id,
            })
            .pipe(
              tap(response =>
                this._loggingService.log('getCurrentCarrier Response', response)
              ),
              mergeMap(response => {
                return from([
                  new fromActions.AddCurrentCarrierDecision(
                    this._currentCarrierService.validateCurrentCarrierDecision(
                      response
                    )
                  ),
                  new fromActions.GetCurrentCarrierSuccess(response),
                ]);
              }),
              catchError(error => {
                const safeError = this._errorHelper.sanitizeError(error);
                this._loggingService.log('getCurrentCarrier Error', safeError);
                return from([new fromActions.GetCurrentCarrierFail(safeError)]);
              })
            )
        )
      )
    )
  );

  @Effect()
  saveCurrentCarrier$ = this._actions$.pipe(
    ofType(fromActions.CurrentCarrierActionTypes.UPDATE_CURRENT_CARRIER),
    mergeMap((action: fromActions.UpdateCurrentCarrier) =>
      this._productsService.getAutoTokenLoaded().pipe(
        filter(autoTokenLoaded => autoTokenLoaded),
        withLatestFrom(
          this._store.select(fromSelectors.getProduct(ProductTypes.AUTO)),
          this._store.select(fromSelectors.getCurrentCarrier)
        ),
        mergeMap(([response, product, currentCarrierInfo]) => {
          let currentCarrier = action.payload
            ? action.payload
            : currentCarrierInfo;
          const reasonForCoverageLapse = action.reasonForCoverageLapse;
          if (reasonForCoverageLapse) {
            currentCarrier = {
              ...currentCarrier,
              reasonForCoverageLapse: reasonForCoverageLapse,
            };
          }
          return this._quoteService
            .updateCurrentCarrier({
              quoteId: product.quoteId,
              productId: product.id,
              body: {
                currentCarrier: currentCarrier,
              },
            })
            .pipe(
              tap(response =>
                this._loggingService.log(
                  'updateCurrentCarrier Response',
                  response
                )
              ),
              mergeMap(response => {
                return from([
                  new fromActions.UpdateCurrentCarrierSuccess(response),
                ]);
              }),
              catchError(error => {
                const safeError = this._errorHelper.sanitizeError(error);
                this._loggingService.log(
                  'updateCurrentCarrier Error',
                  safeError
                );
                return of(new fromActions.UpdateCurrentCarrierFail());
              })
            );
        })
      )
    )
  );

  @Effect()
  getPowersportsCurrentCarrier$ = this._actions$.pipe(
    ofType(
      fromActions.CurrentCarrierActionTypes.GET_POWERSPORTS_CURRENT_CARRIER
    ),
    mergeMap(action =>
      this._productsService.getPowersportsTokenLoaded().pipe(
        filter(powersportsTokenLoaded => powersportsTokenLoaded),
        withLatestFrom(
          this._store.select(fromSelectors.getProduct(ProductTypes.POWERSPORTS))
        ),
        mergeMap(([tokenLoaded, product]) =>
          this._quoteService
            .retrievePowersportsCurrentCarrier({
              quoteId: product.quoteId,
              productId: product.id,
            })
            .pipe(
              tap(response =>
                this._loggingService.log(
                  'getPowersportsCurrentCarrier Response',
                  response
                )
              ),
              mergeMap(response => {
                if (response.currentCarrierTerms) {
                  return from([
                    new fromActions.GetPowersportsCurrentCarrierSuccess(
                      response
                    ),
                  ]);
                } else {
                  return from([
                    new fromActions.GetPowersportsCurrentCarrierFail(
                      'Powersports Current carrier not set'
                    ),
                    new fromActions.UpdatePowersportsCurrentCarrier(),
                  ]);
                }
              }),
              catchError(error => {
                const safeError = this._errorHelper.sanitizeError(error);
                this._loggingService.log(
                  'getPowersportsCurrentCarrier Error',
                  safeError
                );
                return from([
                  new fromActions.GetPowersportsCurrentCarrierFail(safeError),
                  new fromActions.UpdatePowersportsCurrentCarrier(),
                ]);
              })
            )
        )
      )
    )
  );

  @Effect()
  savePowersportsCurrentCarrier$ = this._actions$.pipe(
    ofType(
      fromActions.CurrentCarrierActionTypes.UPDATE_POWERSPORTS_CURRENT_CARRIER
    ),
    mergeMap((action: fromActions.UpdatePowersportsCurrentCarrier) =>
      this._productsService.getPowersportsTokenLoaded().pipe(
        filter(powersportsTokenLoaded => powersportsTokenLoaded),
        withLatestFrom(
          this._store.select(fromSelectors.getProduct(ProductTypes.POWERSPORTS))
        ),
        mergeMap(([response, product]) => {
          let powersportsCurrentCarrier = action.payload;
          if (!powersportsCurrentCarrier) {
            powersportsCurrentCarrier = {
              currentCarrierName: 'AllOtherSelectRisk',
              coverageLapse: '0DaysLapseInPrior6Months',
            };
          }
          return this._quoteService
            .updatePowersportsCurrentCarrier({
              quoteId: product.quoteId,
              productId: product.id,
              body: {
                currentCarrier: powersportsCurrentCarrier,
              },
            })
            .pipe(
              tap(response =>
                this._loggingService.log(
                  'updatePowersportsCurrentCarrier Response',
                  response
                )
              ),
              mergeMap(response => {
                return from([
                  new fromActions.UpdatePowersportsCurrentCarrierSuccess(
                    response
                  ),
                ]);
              }),
              catchError(error => {
                const safeError = this._errorHelper.sanitizeError(error);
                this._loggingService.log(
                  'updatePowersportsCurrentCarrier Error',
                  safeError
                );
                return of(
                  new fromActions.UpdatePowersportsCurrentCarrierFail()
                );
              })
            );
        })
      )
    )
  );

  getBodilyInjuryLimitBasedOnState(quoteState: string): string {
    let limit;
    switch (quoteState) {
      case 'MD':
      case 'MI':
        limit = 'GE100_300';
        break;
      case 'MN':
      case 'NC':
      case 'VA':
      case 'TX':
        limit = 'LT100_300GT30_60';
        break;
      case 'UT':
        limit = 'LT100_300GT25_65';
        break;
      default:
        limit = 'LT100_300GT25_50';
        break;
    }
    if (!this._appConfig.isProd() && quoteState === 'ME') {
      limit = 'GE250_500';
    }
    return limit;
  }
}
