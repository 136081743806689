import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';

import { CRUDService } from '@core/services/crud.service';

import { AdditionalInterestEntity } from '@core/models/entities/additional-interest.entity';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';

@Injectable({
  providedIn: 'root',
})
export class AdditionalInterestDao
  implements CRUDService<AdditionalInterestEntity>
{
  constructor(private _store: Store<fromStore.AppState>) {}

  add(entity: AdditionalInterestEntity): void {
    this._store.dispatch(new fromActions.AddAdditionalInterest(entity));
  }

  delete(entity: string): void {
    this._store.dispatch(new fromActions.DeleteAdditionalInterest(entity));
  }

  update(entity: AdditionalInterestEntity): void {
    this._store.dispatch(new fromActions.UpdateAdditionalInterest(entity));
  }

  get?(entityId: string): Observable<AdditionalInterestEntity> {
    return this._store.select(
      fromSelectors.getAdditionalInterestEntity(entityId)
    );
  }

  getAll?(): Observable<AdditionalInterestEntity[]> {
    return this._store.select(fromSelectors.selectAllAdditionalInterest);
  }

  getVehiclesWithAdditionalInterest(): Observable<VehicleEntity[]> {
    return this._store.select(fromSelectors.getVehiclesWithAdditionalInterest);
  }

  getPowersportsVehiclesWithAdditionalInterest(): Observable<VehicleEntity[]> {
    return this._store.select(
      fromSelectors.getPowersportsVehiclesWithAdditionalInterest
    );
  }

  getMultiple(entityIds: string[]): Observable<AdditionalInterestEntity[]> {
    return this._store.select(
      fromSelectors.getAdditionalInterestEntities(entityIds)
    );
  }
}
