import { AgencyGuard } from './agency.guard';
import { CompRaterAuthenticationGuard } from './comp-rater-authentication.guard';
import { AgentAuthenticationGuard } from './agent-authentication.guard';

export const guards: any[] = [
  AgencyGuard,
  CompRaterAuthenticationGuard,
  AgentAuthenticationGuard,
];

export * from './agency.guard';
export * from './comp-rater-authentication.guard';
export * from './agent-authentication.guard';
