import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Inject,
  OnDestroy,
  HostListener,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import {
  SessionService,
  PrivateLabelService,
  ProductsService,
  AgencyService,
  UserContextService,
  AlertService,
  PageAlertService,
  PreBindDocumentsService,
  LoadingService,
  MetadataService,
  LoggingService,
  AppConfigService,
} from '../../core/services';
import { StringUtils } from '../../shared/utils/string.utils';
import {
  PrivateLabel,
  ProducerInformation,
} from '@core/models/private-label/private-label.model';
import {
  take,
  takeUntil,
  switchMap,
  filter,
  map,
  distinctUntilChanged,
} from 'rxjs/operators';
import { Observable, Subject, combineLatest } from 'rxjs';
import {
  AgencyIdentityMethod,
  ProductTypes,
  RIVIAN_THIRDPARTY_ID,
} from '@shared/constants/app-constants';
import { Product } from '@core/models/products/product.model';
import { AgencyModel } from '@core/models/agency/agency.model';
import { Alert } from '@core/models/page-alerts/page-alert.model';
import { ErrorMessageService } from '@core/services/error-message.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { LoggingAdapter, LoggingCloudAdapter } from '@core/adapters';
import { QuoteService } from '@core/services/quote.service';
import { TermLifeEntity } from '@core/models/termlife/termlife.entity';
import { TermLifeService } from '@core/services/termlife.service';
import {
  UserContext,
  InitiatedByType,
} from '@core/models/user-context/user-context.model';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';
import { PolicyholderService } from '@core/services/policyholder.service';

@Component({
  selector: 'mq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  selectedProducts$: Observable<Product[]>;
  configLoaded$: Observable<boolean>;
  privateLabelConfig$: Observable<PrivateLabel>;
  producerInformation$: Observable<ProducerInformation>;
  agency$: Observable<AgencyModel>;
  alert$: Observable<Alert>;
  contactNumber$: Observable<string>;
  loading$: Observable<boolean>;
  showBanner$ = new Subject<boolean>();
  dsmAutoQuoteId: string = null;
  dsmHomeownersQuoteId: string = null;
  dsmCondoQuoteId: string = null;
  dsmRentersQuoteId: string = null;
  dsmUmbrellaQuoteId: string = null;
  dsmPowersportsQuoteId: string = null;
  autoTag: string = null;
  homeTag: string = null;
  condoTag: string = null;
  renterTag: string = null;
  umbrellaTag: string = null;
  powersportsTag: string = null;
  isRivian = false;
  showDebugOverlay = false;

  private _ngUnsubscribe$ = new Subject();
  stateSpecificFlags$: Observable<StateSpecificFlagsObject>;
  quoteState$: Observable<string>;
  urboNumber$: Observable<[string]>;
  termLifePeople$: Observable<TermLifeEntity[]>;

  showBanner = true;

  private registeredOpinionLabListener = false;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _titleService: Title,
    private _sessionService: SessionService,
    private _privateLabelService: PrivateLabelService,
    private _productsService: ProductsService,
    private _agentService: AgencyService,
    private _userContextService: UserContextService,
    private _alertService: AlertService,
    private _pageAlertService: PageAlertService,
    private _meta: Meta,
    private _errorMessageService: ErrorMessageService,
    private _router: Router,
    private _loggingAdapter: LoggingAdapter,
    private _loggingService: LoggingService,
    private _loggingCloudAdapter: LoggingCloudAdapter,
    private _quoteService: QuoteService,
    private _preBindDocumentsService: PreBindDocumentsService,
    private _loadingService: LoadingService,
    private _metadataService: MetadataService,
    private _termLifeService: TermLifeService,
    private _renderer2: Renderer2,
    private _policyholdersService: PolicyholderService,
    private appConfigService: AppConfigService
  ) {}

  @HostListener('window:beforeunload')
  onBeforeUnload(): void {
    this._loggingAdapter.lastChanceSynchronize();
    this._loggingCloudAdapter.lastChanceSynchronize();
    this._preBindDocumentsService
      .shouldResetAcknowledgementFlags()
      .pipe(take(1))
      .subscribe(shouldReset => {
        // If fake ack given originally, reset acks
        if (shouldReset) {
          this._quoteService.dispatchPreBindDocumentsAcknowledgement(
            false,
            false
          );
        }
      });
  }

  @HostListener('click')
  click(event): void {
    if (this.registeredOpinionLabListener) {
      return;
    }
    const button = this._document.querySelector('a#oo_tab');
    if (!button) {
      return;
    }
    button.addEventListener('click', () => {
      this._loggingService.log('OPINION_LAB_CLICK', null);
    });
    this.registeredOpinionLabListener = true;
  }

  ngOnInit(): void {
    this.selectedProducts$ = this._productsService.getSelectedProducts();
    this.agency$ = this._agentService.getAgency();
    this.loading$ = this._loadingService.getAppLoading();
    this.showBanner$.next(true);
    this.quoteState$ = this._sessionService.getQuoteState();

    this.stateSpecificFlags$ =
      this._metadataService.getStateSpecificFlagsObject();
    this.quoteState$ = this._sessionService.getQuoteState();

    this.alert$ = combineLatest([
      this._alertService.getAlert(),
      this._router.events.pipe(
        filter((event: any) => !!event.url),
        map(event => this._pageAlertService.getPageAlertIdFromUrl(event.url)),
        distinctUntilChanged(),
        switchMap(pageId => {
          return this._errorMessageService.getRecoverableErrorsAsAlert(pageId);
        })
      ),
    ]).pipe(map(([fromAlert, fromError]) => fromAlert || fromError));

    this._router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        const newrelic = (window as any).newrelic;
        if (!newrelic) {
          return;
        }

        const i = newrelic.interaction();
        i.setName(event.url);
        i.setAttribute('source', 'customBrowserImplementation');

        i.save();
      });

    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.isBannerlessUrl(event.url)) {
          this.showBanner = false;
        } else {
          this.showBanner = true;
        }
      });

    this.setTitleAndFavicon();
    this.configLoaded$ =
      this._privateLabelService.getPrivateLabelConfigurationLoaded();
    this.privateLabelConfig$ =
      this._privateLabelService.getPrivateLabelConfiguration();
    this.producerInformation$ =
      this._privateLabelService.getProducerInformation();
    this._sessionService.setSessionId(StringUtils.generateUUID());
    this.contactNumber$ =
      this._privateLabelService.getContactNumber('HEADER_FOOTER');

    this._setMetaTags();

    this._loadGoogleTagManager();

    this.urboNumber$ = combineLatest([
      this._router.events.pipe(
        filter((event: any) => !!event.url),
        distinctUntilChanged(),
        map(event => event.url)
      ),
    ]);
    this.termLifePeople$ = this._termLifeService.getTermLifeEligiblePeople();
    if (this.appConfigService.isTest()) {
      this.showDebugOverlay = true;
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

  setTitle(title: string): void {
    this._titleService.setTitle(title);
  }

  private isBannerlessUrl(url: string): boolean {
    return url.startsWith('/retrieve/compRater');
  }

  private isInitiatedByCompraterOrMobile(userContext: UserContext): boolean {
    if (userContext == null || !userContext.initiatedBy) {
      return false;
    }

    return [
      InitiatedByType.COMPRATER.toLocaleLowerCase(),
      InitiatedByType.IOS_MOBILE.toLocaleLowerCase(),
    ].includes(userContext.initiatedBy.toLocaleLowerCase());
  }

  private _loadGoogleTagManager(): void {
    this._userContextService
      .getUserContext()
      .pipe(take(1))
      .subscribe(userContext => {
        if (
          userContext &&
          userContext.thirdPartyId &&
          userContext.thirdPartyId === RIVIAN_THIRDPARTY_ID
        ) {
          this.isRivian = true;
        }
        if (userContext && userContext.gtm) {
          const gaScript = document.createElement('script');
          gaScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':new Date().getTime(),event:\'gtm.js\'});`;
          gaScript.innerText += `var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;`;
          gaScript.innerText += `j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})`;
          gaScript.innerText += `(window,document,\'script\',\'dataLayer\',\'${userContext.gtm}\');`;

          document.documentElement.firstChild.appendChild(gaScript);
        }
      });
  }

  private setTitleAndFavicon(): void {
    this._privateLabelService
      .getPrivateLabelConfiguration()
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe((data: PrivateLabel) => {
        if (data) {
          this.setTitle(data.agencyName + ' Bundled Quote');
          this.setCustomFavIcon(data.favicon);
        } else {
          this.setTitle('Nationwide Bundled Quote');
        }
      });
  }

  private setCustomFavIcon(faviconUrl: string): void {
    if (faviconUrl) {
      this._document.getElementById('favIcon').setAttribute('href', faviconUrl);
    }
  }

  private _setMetaTags(): void {
    const AUTO = 'auto';
    const HOME = 'home';
    const CONDO = 'condo';
    const RENTERS = 'renters';
    const UMBRELLA = 'umbrella';
    const POWERSPORTS = 'powersports';

    this._meta.addTag(
      {
        name: 'applicationName',
        content: 'nwexpress',
      },
      false
    );
    this._sessionService
      .getQuoteState()
      .pipe(
        filter(quoteState => !!quoteState),
        take(1)
      )
      .subscribe(quoteState => {
        this._meta.addTag(
          {
            name: 'state',
            content: quoteState,
          },
          false
        );
      });

    this._sessionService
      .getQuoteState()
      .pipe(
        filter(quoteState => !!quoteState),
        take(1)
      )
      .subscribe(quoteState => {
        this._meta.addTag(
          {
            name: 'state',
            content: quoteState,
          },
          false
        );
      });

    this._agentService
      .getAgency()
      .pipe(
        filter(agency => !!agency),
        take(1)
      )
      .subscribe(agency => {
        let producer_id;
        if (agency.agencyChannel === 'IA') {
          producer_id = `${agency.agencyCode}00000${agency.producerCode}`;
        } else {
          producer_id = agency.producerCode;
        }
        this._meta.addTag(
          {
            name: 'producer_id',
            content: producer_id,
          },
          false
        );
      });

    this._agentService
      .getAgentJwt()
      .pipe(
        filter(agencyJwt => !!agencyJwt?.jwt),
        take(1)
      )
      .subscribe(agencyJwt => {
        if (agencyJwt.jwt?.racfId)
          this._meta.addTag(
            {
              name: 'racf_id',
              content: agencyJwt.jwt.racfId,
            },
            false
          );
        if (
          agencyJwt.jwt?.identity_method ===
          AgencyIdentityMethod.DEFAULT_IDENTITY_METHOD
        )
          this._meta.addTag(
            {
              name: 'nwie_id',
              content: agencyJwt.jwt.userId,
            },
            false
          );
      });

    this.selectedProducts$
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe(dsmProducts => {
        this.dsmAutoQuoteId = null;
        this.dsmHomeownersQuoteId = null;
        this.dsmCondoQuoteId = null;
        this.dsmRentersQuoteId = null;
        this.dsmPowersportsQuoteId = null;
        this.dsmUmbrellaQuoteId = null;
        this.autoTag = null;
        this.homeTag = null;
        this.renterTag = null;
        this.umbrellaTag = null;
        this.powersportsTag = null;
        this.condoTag = null;

        for (const product of dsmProducts) {
          if (product.id === ProductTypes.AUTO) {
            this.dsmAutoQuoteId = product.quoteId;
            this.autoTag = AUTO;
          } else if (product.id === ProductTypes.HOMEOWNERS) {
            this.dsmHomeownersQuoteId = product.quoteId;
            this.homeTag = HOME;
          } else if (product.id === ProductTypes.CONDO) {
            this.dsmCondoQuoteId = product.quoteId;
            this.condoTag = CONDO;
          } else if (product.id === ProductTypes.RENTERS) {
            this.dsmRentersQuoteId = product.quoteId;
            this.renterTag = RENTERS;
          } else if (product.id === ProductTypes.UMBRELLA) {
            this.dsmUmbrellaQuoteId = product.quoteId;
            this.umbrellaTag = UMBRELLA;
          } else if (product.id === ProductTypes.POWERSPORTS) {
            this.dsmPowersportsQuoteId = product.quoteId;
            this.powersportsTag = POWERSPORTS;
          }
        }
        if (this.dsmAutoQuoteId !== null && this.dsmAutoQuoteId !== undefined) {
          const tag = this._meta.getTag('name="auto_id"');
          tag
            ? this._meta.updateTag(
                {
                  name: 'auto_id',
                  content: this.dsmAutoQuoteId,
                },
                'name="auto_id"'
              )
            : this._meta.addTag(
                {
                  name: 'auto_id',
                  content: this.dsmAutoQuoteId,
                },
                false
              );
        } else if (this._meta.getTag('name="auto_id"')) {
          this._meta.removeTag('name="auto_id"');
        }
        if (
          this.dsmHomeownersQuoteId !== null &&
          this.dsmHomeownersQuoteId !== undefined
        ) {
          const tag = this._meta.getTag('name="home_id"');
          tag
            ? this._meta.updateTag(
                {
                  name: 'home_id',
                  content: this.dsmHomeownersQuoteId,
                },
                'name="home_id"'
              )
            : this._meta.addTag(
                {
                  name: 'home_id',
                  content: this.dsmHomeownersQuoteId,
                },
                false
              );
        } else if (this._meta.getTag('name="home_id"')) {
          this._meta.removeTag('name="home_id"');
        }
        if (
          this.dsmCondoQuoteId !== null &&
          this.dsmCondoQuoteId !== undefined
        ) {
          const tag = this._meta.getTag('name="condo_id"');
          tag
            ? this._meta.updateTag(
                {
                  name: 'condo_id',
                  content: this.dsmCondoQuoteId,
                },
                'name="condo_id"'
              )
            : this._meta.addTag(
                {
                  name: 'condo_id',
                  content: this.dsmCondoQuoteId,
                },
                false
              );
        } else if (this._meta.getTag('name="condo_id"')) {
          this._meta.removeTag('name="condo_id"');
        }
        if (
          this.dsmRentersQuoteId !== null &&
          this.dsmRentersQuoteId !== undefined
        ) {
          const tag = this._meta.getTag('name="renters_id');
          tag
            ? this._meta.updateTag(
                {
                  name: 'renters_id',
                  content: this.dsmRentersQuoteId,
                },
                'name="renters_id"'
              )
            : this._meta.addTag(
                {
                  name: 'renters_id',
                  content: this.dsmRentersQuoteId,
                },
                false
              );
        } else if (this._meta.getTag('name="renters_id"')) {
          this._meta.removeTag('name="renters_id"');
        }
        if (
          this.dsmUmbrellaQuoteId !== null &&
          this.dsmUmbrellaQuoteId !== undefined
        ) {
          const tag = this._meta.getTag('name="umbrella_id');
          tag
            ? this._meta.updateTag(
                {
                  name: 'umbrella_id',
                  content: this.dsmUmbrellaQuoteId,
                },
                'name="umbrella_id"'
              )
            : this._meta.addTag(
                {
                  name: 'umbrella_id',
                  content: this.dsmUmbrellaQuoteId,
                },
                false
              );
        } else if (this._meta.getTag('name="umbrella_id"')) {
          this._meta.removeTag('name="umbrella_id"');
        }
        if (
          this.dsmPowersportsQuoteId !== null &&
          this.dsmPowersportsQuoteId !== undefined
        ) {
          const tag = this._meta.getTag('name="powersports_id');
          tag
            ? this._meta.updateTag(
                {
                  name: 'powersports_id',
                  content: this.dsmPowersportsQuoteId,
                },
                'name="powersports_id"'
              )
            : this._meta.addTag(
                {
                  name: 'powersports_id',
                  content: this.dsmPowersportsQuoteId,
                },
                false
              );
        } else if (this._meta.getTag('name="powersports_id"')) {
          this._meta.removeTag('name="powersports_id"');
        }
        if (
          this.autoTag !== null ||
          this.homeTag !== null ||
          this.condoTag !== null ||
          this.renterTag !== null ||
          this.umbrellaTag !== null ||
          this.powersportsTag !== null
        ) {
          const tag = this._meta.getTag('name="products"');
          tag
            ? this._meta.updateTag(
                {
                  name: 'products',
                  content: [
                    this.autoTag,
                    this.homeTag,
                    this.condoTag,
                    this.renterTag,
                    this.umbrellaTag,
                    this.powersportsTag,
                  ]
                    .filter(Boolean)
                    .join(','),
                },
                'name="products"'
              )
            : this._meta.addTag(
                {
                  name: 'products',
                  content: [
                    this.autoTag,
                    this.homeTag,
                    this.condoTag,
                    this.renterTag,
                    this.umbrellaTag,
                    this.powersportsTag,
                  ]
                    .filter(Boolean)
                    .join(','),
                },
                false
              );
        }
      });

    this._policyholdersService
      .getNamedInsured()
      .pipe(
        filter(policyholder => !!policyholder?.emailAddress),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(policyholder => {
        const tag = this._meta.getTag('name="email"');
        tag
          ? this._meta.updateTag(
              {
                name: 'email',
                content: policyholder.emailAddress,
              },
              'name="email"'
            )
          : this._meta.addTag(
              {
                name: 'email',
                content: policyholder.emailAddress,
              },
              false
            );
      });
  }

  ngAfterViewInit() {
    const trustarc_banner = this._renderer2.createElement('script');
    trustarc_banner.type = 'text/javascript';
    trustarc_banner.src =
      '//consent.trustarc.com/notice?domain=cm-nationwide.com&c=teconsent&js=nj&noticeType=bb&text=true&pcookie&gtm=1';
    trustarc_banner.async = true;
    this._renderer2.appendChild(document.body, trustarc_banner);
  }
}
