import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { Product } from '@core/models/products/product.model';
import { ProductTypes } from '@shared/constants/app-constants';

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string) => void;
  }
}
@Component({
  selector: 'mq-quote-letter',
  templateUrl: './quote-letter.component.html',
  styleUrls: ['./quote-letter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteLetterComponent implements OnInit {
  @Input() selectedProduct: Product;
  @Input() selectedProducts: Product[];
  @Input() quoteLetter: string;
  @Input() isQuoteValid: boolean;
  @Input() isFormValid: boolean;

  lifeProductIsSelected: boolean;

  constructor() {}

  ngOnInit(): void {
    if (this.selectedProducts && this.selectedProducts.length) {
      this.lifeProductIsSelected = this.selectedProducts.some(
        product => product.id === ProductTypes.TERMLIFE
      );
    }
  }

  getQuoteLetterPdf() {
    const byteArray = new Uint8Array(
      atob(this.quoteLetter)
        .split('')
        .map(char => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    if (window.navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob);
    } else {
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    }
    return false;
  }

  get autoProductId() {
    return ProductTypes.AUTO;
  }

  get homeownersProductId() {
    return ProductTypes.HOMEOWNERS;
  }

  get condoProductId() {
    return ProductTypes.CONDO;
  }

  get rentersProductId() {
    return ProductTypes.RENTERS;
  }
}
