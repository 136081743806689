import { RetrieveComponent } from './retrieve/retrieve.component';
import { CompRaterRetrieveComponent } from './comp-rater-retrieve/comp-rater-retrieve.component';

export const containers: any[] = [
  RetrieveComponent,
  CompRaterRetrieveComponent,
];

export * from './retrieve/retrieve.component';
export * from './comp-rater-retrieve/comp-rater-retrieve.component';
