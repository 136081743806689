import {
  OnInit,
  Input,
  TemplateRef,
  ViewContainerRef,
  Directive,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { SvgIconUtils } from '@shared/utils/svg-icon.utils';
import { SvgIcon } from '@core/models/svg-icon/svg-icon.model';

@Directive({
  selector: '[svgIcon]',
})
export class SvgIconDirective implements OnInit, OnDestroy {
  private _ngUnsubscribe = new Subject();

  @Input()
  svgIcon: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const icon = SvgIconUtils.icons.find(i => i.name === this.svgIcon);
    this.updateView(icon);
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  private updateView(icon: SvgIcon): void {
    if (icon) {
      const decodedSvg = atob(icon.data);
      this.templateRef.elementRef.nativeElement.innerHTML = decodedSvg;
      const t = this.viewContainer.createEmbeddedView(this.templateRef);
      t.rootNodes[0].innerHTML = decodedSvg;
    } else {
      this.viewContainer.clear();
    }
  }
}
