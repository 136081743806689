import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { catchError, switchMap, take, tap, filter } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import { LoggingService } from '@core/services/logging.service';
import { DriverVehiclePrefillService } from '@core/services/driver-vehicle-prefill.service';
import { VehicleBuilder } from '@shared/utils/builders/vehicle.builder';
import { DriverBuilder } from '@shared/utils/builders/driver.builder';
import { ProductsService } from '@core/services';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { ProductTypes } from '@shared/constants/app-constants';

@Injectable()
export class DriverVehiclePrefillEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromStore.AppState>,
    private _loggingService: LoggingService,
    private _driverVehiclePrefillService: DriverVehiclePrefillService,
    private _productsService: ProductsService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  getCustomerData$ = this._actions$.pipe(
    ofType(fromActions.DriverVehiclePrefillActionTypes.GET_CUSTOMER_DATA),
    switchMap(() =>
      this._productsService.getVehicleProducts().pipe(
        filter(products => !!products[0].quoteId),
        take(1)
      )
    ),
    switchMap(() =>
      this._productsService.getAutoTokenLoaded().pipe(
        filter(loaded => loaded),
        switchMap(loaded => {
          if (loaded) {
            return this._store
              .select(fromSelectors.buildCustomerDataRequest(ProductTypes.AUTO))
              .pipe(take(1));
          }
        })
      )
    ),
    tap(request => this._loggingService.log('customersData Request', request)),
    switchMap(request =>
      this._driverVehiclePrefillService.customersData(request).pipe(
        tap(response =>
          this._loggingService.log('customersData Response', response)
        ),
        switchMap(response => {
          if (!response.drivers && !response.vehicles) {
            return from([new fromActions.DriverVehiclePrefillNoHit()]);
          }

          const actionsArray: Action[] = [];

          const vehicleEntities =
            VehicleBuilder.buildVehicleEntitiesFromPrefillResponse(
              response.vehicles
            );

          const driverEntities =
            DriverBuilder.buildDriverEntitiesFromPrefillResponse(
              response.drivers
            );

          if (vehicleEntities) {
            actionsArray.push(
              new fromActions.AddAllPrefillVehicles(vehicleEntities)
            );
          }

          if (driverEntities) {
            actionsArray.push(
              new fromActions.AddAllPrefillPeople(driverEntities)
            );
          }

          actionsArray.push(new fromActions.GetCustomerDataSuccess());

          return from(actionsArray);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('getCustomerData Error', safeError);
          return of(new fromActions.GetCustomerDataFail(safeError));
        })
      )
    )
  );

  @Effect()
  getCustomerPowersportsData$ = this._actions$.pipe(
    ofType(
      fromActions.DriverVehiclePrefillActionTypes.GET_CUSTOMER_POWERSPORTS_DATA
    ),
    switchMap(() =>
      this._productsService.getVehicleProducts().pipe(
        filter(products => {
          return !!products.find(
            product => product.id === ProductTypes.POWERSPORTS
          ).quoteId;
        }),
        take(1)
      )
    ),
    switchMap(() =>
      this._productsService.getPowersportsTokenLoaded().pipe(
        filter(loaded => loaded),
        switchMap(loaded => {
          if (loaded) {
            return this._store
              .select(
                fromSelectors.buildPowersportsCustomerDataRequest(
                  ProductTypes.POWERSPORTS
                )
              )
              .pipe(take(1));
          }
        })
      )
    ),
    tap(request =>
      this._loggingService.log('customersPowersportsData Request', request)
    ),
    switchMap(request =>
      this._driverVehiclePrefillService.customersData(request).pipe(
        tap(response =>
          this._loggingService.log(
            'customersPowersportsData Response',
            response
          )
        ),
        switchMap(response => {
          if (!response.drivers && !response.vehicles) {
            return from([
              new fromActions.DriverPowersportsVehiclePrefillNoHit(),
            ]);
          }

          const actionsArray: Action[] = [];

          const vehicleEntities =
            VehicleBuilder.buildPowerpsortsVehicleEntitiesFromPrefillResponse(
              response.vehicles
            );

          const driverEntities =
            DriverBuilder.buildDriverEntitiesFromPrefillResponse(
              response.drivers
            );

          if (vehicleEntities) {
            actionsArray.push(
              new fromActions.AddAllPrefillPowersportsVehicles(vehicleEntities)
            );
          }

          if (driverEntities) {
            actionsArray.push(
              new fromActions.AddAllPrefillPowersportsPeople(driverEntities)
            );
          }

          actionsArray.push(
            new fromActions.GetCustomerPowersportsDataSuccess()
          );

          return from(actionsArray);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log(
            'getCustomerPowersportsData Error',
            safeError
          );
          return of(new fromActions.GetCustomerPowersportsDataFail(safeError));
        })
      )
    )
  );
}
