<!-- Auto coverage tables -->
<mq-edit-coverage-table
  *ngIf="policyCoverageGrouping"
  icon="assets/images/DocumentWithCheck.svg"
  [form]="form"
  [title]="policyCoverageGrouping.title"
  [coverageChanges]="coverageChanges"
  [coverages]="policyCoverageGrouping.coverages"
  [coverageErrors]="coverageErrors"
  (coverageUpdated)="coverageChanged($event)"
  [showCoveragePrices]="showCoveragePrices"
  [termMonths]="termMonths"
  [extraUpdatedCoverage]="extraUpdatedCoverage"
  [stateSpecificFlags]="stateSpecificFlags"
  [contactNumber]="contactNumber"
  [quoteState]="quoteState"
  [isAuto]="isAuto"
  [isPowersports]="isPowersports"
  [isMobile]="isMobile"
  [isAutoSelected]="isAutoSelected"
  [isHomeownersSelected]="isHomeownersSelected"
  [isUmbrellaSelected]="isUmbrellaSelected"
  [openProgramsToSaveData]="{shouldLogToSplunk: true, name: 'policyCoverages'}"
></mq-edit-coverage-table>

<mq-edit-coverage-table
  *ngFor="let vehicle of vehicleCoverageGroupings"
  [form]="form"
  [title]="vehicle.title"
  [icon]="vehicle.icon"
  [coverageChanges]="coverageChanges"
  [coverages]="vehicle.coverages"
  [coverageErrors]="coverageErrors"
  (coverageUpdated)="coverageChanged($event)"
  [showCoveragePrices]="showCoveragePrices"
  [termMonths]="termMonths"
  [extraUpdatedCoverage]="extraUpdatedCoverage"
  [stateSpecificFlags]="stateSpecificFlags"
  [contactNumber]="contactNumber"
  [vehicleId]="vehicle.vehicleId"
  [vehicleMiles]="vehicle.annualMiles"
  [selectedProduct]="selectedProduct"
  [isMobile]="isMobile"
  [openProgramsToSaveData]="{shouldLogToSplunk: true, name: 'vehicle'}"
></mq-edit-coverage-table>

<mq-edit-coverage-table
  *ngFor="let driver of driverCoverageGroupings"
  [form]="form"
  [title]="driver.title"
  icon="assets/images/id-card.svg"
  [coverageChanges]="coverageChanges"
  [coverages]="driver.coverages"
  [coverageErrors]="coverageErrors"
  (coverageUpdated)="coverageChanged($event)"
  [showCoveragePrices]="showCoveragePrices"
  [termMonths]="termMonths"
  [extraUpdatedCoverage]="extraUpdatedCoverage"
  [stateSpecificFlags]="stateSpecificFlags"
  [contactNumber]="contactNumber"
  [isMobile]="isMobile"
></mq-edit-coverage-table>

<mq-edit-coverage-table
  *ngIf="additionalPolicyCoverageGrouping"
  [form]="form"
  [title]="additionalPolicyCoverageGrouping.title"
  icon="assets/images/Form1_Blue.svg"
  [coverageChanges]="coverageChanges"
  [coverages]="additionalPolicyCoverageGrouping.coverages"
  [coverageErrors]="coverageErrors"
  (coverageUpdated)="coverageChanged($event)"
  [showCoveragePrices]="showCoveragePrices"
  [stateSpecificFlags]="stateSpecificFlags"
  [termMonths]="termMonths"
  [contactNumber]="contactNumber"
  [notification]="additionalPolicyNotification"
  [isMobile]="isMobile"
  [openProgramsToSaveData]="{shouldLogToSplunk: true, name: 'additionalPolicyOptions'}"
></mq-edit-coverage-table>

<mq-edit-coverage-table
  *ngIf="otherCoverageGrouping?.coverages?.length > 0"
  [form]="form"
  [title]="otherCoverageGrouping.title"
  icon="assets/images/Forms2_Charts_Blue.svg"
  [coverageChanges]="coverageChanges"
  [coverages]="otherCoverageGrouping.coverages"
  [coverageErrors]="coverageErrors"
  (coverageUpdated)="coverageChanged($event)"
  [showCoveragePrices]="showCoveragePrices"
  [stateSpecificFlags]="stateSpecificFlags"
  [termMonths]="termMonths"
  [contactNumber]="contactNumber"
  [isMobile]="isMobile"
  [openProgramsToSaveData]="{shouldLogToSplunk: true, name: 'otherCoverages'}"
></mq-edit-coverage-table>
