<div class="price-wrapper">
  <div>
    <div
      class="price-large"
      [ngClass]="{
        'mono-quote': !isMonoQuoteAllowed,
        'not-rated': !isQuoteValid
      }"
      counto
      [step]="30"
      [countTo]="premium"
      [countFrom]="0"
      [duration]="0.5"
      (countoChange)="monthly = $event"
    >
      <span *ngIf="premium === 0" class="blur premium-amnt">
        {{ monthly | currency : 'USD' : 'symbol' : '1.2-2' }}
      </span>
      <span *ngIf="showFullTermPremiumAmount()" class="visible premium-amnt">
        {{ fullPayPremium | currency : 'USD' : 'symbol' : '1.2-2' }}
      </span>
      <span *ngIf="!showFullTermPremiumAmount()" class="visible premium-amnt">
        {{ monthly | currency : 'USD' : 'symbol' : '1.2-2' }}
      </span>
    </div>

    <div
      class="price-month"
      [ngClass]="{
        'mono-quote': !isMonoQuoteAllowed,
        'not-rated': !isQuoteValid
      }"
    >
      <span *ngIf="premium === 0" class="blur payment-frequency">
        monthly payment
      </span>
      <span
        *ngIf="!showFullTermPremiumAmount() && !isSmartMilesEnrollment()"
        class="visible payment-frequency"
      >
        monthly payment
      </span>
      <span
        *ngIf="!showFullTermPremiumAmount() && isSmartMilesEnrollment()"
        class="visible payment-frequency"
      >
        estimated <br />
        monthly payment
      </span>
      <span
        *ngIf="showFullTermPremiumAmount()"
        class="visible payment-frequency"
      >
        full term payment
      </span>
    </div>
    <div class="price-equate" *ngIf="showFullTermPremiumAmount()">
      Equates to {{ monthly | currency : 'USD' : 'symbol' : '1.2-2' }} per month
    </div>
    <div
      class="price-equate"
      *ngIf="!isSmartMilesEnrollment() && !showFullTermPremiumAmount()"
    >
      Equates to
      {{ fullPayPremium | currency : 'USD' : 'symbol' : '1.2-2' }} for the full
      term policy
    </div>
    <div class="price-equate" *ngIf="isSmartMilesEnrollment()">
      Based on SmartMiles&#174; enrollment
    </div>
    <button
      *ngIf="!isQuoteValid"
      [disabled]="selectedProducts.length < 1 || isFormValid"
      type="Submit"
      class="btn btn-primary col-md-7 col-sm-12"
      (click)="forward.emit({})"
      id="coverage-premium-continue"
    >
      Recalculate Quote
    </button>
    <div
      *ngIf="isFordMultiLineRedirectExperiment && selectedProducts?.length >= 2"
    >
      <a
        href="tel:1-855-241-7624"
        class="btn btn-outline-primary my-3"
        id="call-an-agent"
      >
        Call an agent to review
      </a>
    </div>

    <mq-quote-letter
      *ngIf="isQuoteValid && !quoteLetterHidden && selectedProduct"
      [selectedProduct]="selectedProduct"
      [quoteLetter]="quoteLetter"
      [selectedProducts]="selectedProducts"
      [isQuoteValid]="isQuoteValid"
      [isFormValid]="quoteLetterValid"
    >
    </mq-quote-letter>
    <div
      class="visible payment-frequency"
      *ngIf="isPaidInFullApplicableForAutoPS()"
    >
      {{ discountName }} applied <br />to your Auto and Powersports policy
    </div>
    <div
      class="visible payment-frequency"
      *ngIf="!isPaidInFullApplicableForAutoPS() && isDiscountAppliedForAuto()"
    >
      {{ discountName }} applied <br />to your Auto policy
    </div>
    <div
      class="visible payment-frequency"
      *ngIf="
        !isPaidInFullApplicableForAutoPS() && isDiscountAppliedForPowersports()
      "
    >
      {{ discountName }} applied <br />to your Powersports policy
    </div>
    <div class="price-equate">See more discounts below</div>
  </div>
</div>
