import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromLenderActions from '@core/store/actions';
import * as fromSelectors from '@core/store/selectors';
import {
  mergeMap,
  map,
  filter,
  catchError,
  tap,
  switchMap,
  take,
} from 'rxjs/operators';
import { from, of, combineLatest } from 'rxjs';
import {
  MortgageAdapter,
  AddMortgageRequest,
  AddMortgageResponse,
} from '@core/adapters/mortgage.adapter';
import { ProductTypes, MortgageeTypes } from '@shared/constants/app-constants';
import {
  getTokenByProduct,
  getProduct,
  getAllLenders,
} from '@core/store/selectors';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';
import { Product } from '@core/models/products/product.model';
import { LoggingService, ProductsService } from '@core/services';
import { LendersAndProduct } from '@core/models/lenders/lender.model';
import { ErrorHelper } from '@core/services/helpers/error.helper';
import { AccessToken } from '@core/store/reducers/token.reducer';

@Injectable()
export class MortgageEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<AppState>,
    private _mortgageAdapter: MortgageAdapter,
    private _loggingService: LoggingService,
    private _productsService: ProductsService,
    private _errorHelper: ErrorHelper
  ) {}

  @Effect()
  addMortgage$ = this._actions$.pipe(
    ofType(fromLenderActions.ADD_DSM_LENDER),
    map((action: any) => action.payload),
    filter((action: any) => !!action.mortgage),
    switchMap(payload =>
      this._productsService
        .getNonRentalPropertyProductSelectedWithoutError()
        .pipe(
          take(1),
          map(productId => [payload, productId])
        )
    ),
    switchMap(([payload, productId]: [any, string]) =>
      combineLatest([
        this._store.select(getProduct(productId)),
        this._store.select(getTokenByProduct(productId)),
        this._store.select(getAllLenders),
      ]).pipe(
        take(1),
        map(([product, token, lenders]) => [payload, product, token, lenders])
      )
    ),
    map(
      ([payload, product, token, lenders]: [
        any,
        Product,
        AccessToken,
        LendersAndProduct[]
      ]) => [
        payload,
        this.composeAddMortgageRequest(
          payload,
          product,
          token.accessToken,
          lenders
        ),
        product.id,
      ]
    ),
    tap(([payload, request, productId]: [any, AddMortgageRequest, string]) =>
      this._loggingService.log('add mortgage request', request)
    ),
    switchMap(
      ([payload, request, productId]: [any, AddMortgageRequest, string]) => {
        return this._mortgageAdapter
          .addOrUpdateMortgage(request, productId)
          .pipe(
            take(1),
            tap(response =>
              this._loggingService.log('add mortgage response', response)
            ),
            switchMap(response => {
              return from([
                new fromLenderActions.AddLenderSuccess(response),
                // new fromLenderActions.InvalidateQuote(productId),
              ]);
            }),
            catchError(error => {
              const safeError = this._errorHelper.sanitizeError(error);
              this._loggingService.log('add mortage error', safeError);
              return of(
                new fromLenderActions.AddLenderFail({
                  ...safeError,
                  requestPayload: payload,
                })
              );
            })
          );
      }
    )
  );

  @Effect()
  updateMortgage$ = this._actions$.pipe(
    ofType(fromLenderActions.UPDATE_DSM_LENDER),
    map((action: any) => action.payload),
    filter((action: any) => !!action.mortgage),
    switchMap(payload =>
      this._productsService
        .getNonRentalPropertyProductSelectedWithoutError()
        .pipe(
          take(1),
          map(productId => [payload, productId])
        )
    ),
    switchMap(([payload, productId]: [any, string]) =>
      combineLatest([
        this._store.select(getProduct(productId)),
        this._store.select(getTokenByProduct(productId)),
        this._store.select(getAllLenders),
      ]).pipe(
        take(1),
        map(([product, token, lenders]) => [payload, product, token, lenders])
      )
    ),
    map(
      ([payload, product, token, lenders]: [
        any,
        Product,
        AccessToken,
        LendersAndProduct[]
      ]) => [
        payload,
        this.composeAddMortgageRequest(
          payload,
          product,
          token.accessToken,
          lenders
        ),
        product.id,
      ]
    ),
    tap(([payload, request, productId]: [any, AddMortgageRequest, string]) =>
      this._loggingService.log('update mortgage request', request)
    ),
    switchMap(
      ([payload, request, productId]: [any, AddMortgageRequest, string]) => {
        return this._mortgageAdapter
          .addOrUpdateMortgage(request, productId)
          .pipe(
            take(1),
            tap(response =>
              this._loggingService.log('update mortgage response', response)
            ),
            switchMap(response => {
              return from([
                new fromLenderActions.UpdateDsmMortgageLenderSuccess(response),
                new fromLenderActions.InvalidateQuote(productId),
              ]);
            }),
            catchError(error => {
              const safeError = this._errorHelper.sanitizeError(error);
              this._loggingService.log('update mortage error', safeError);
              return of(
                new fromLenderActions.UpdateDsmMortgageLenderFail({
                  ...safeError,
                  requestPayload: payload,
                })
              );
            })
          );
      }
    )
  );

  @Effect()
  deleteMortgage$ = this._actions$.pipe(
    ofType(fromLenderActions.DELETE_LENDER),
    map((actions: fromLenderActions.DeleteLender) => actions.payload),
    switchMap((id: string) =>
      this._store.select(fromSelectors.getMortgageEntityById(id)).pipe(take(1))
    ),
    switchMap(entity =>
      this._productsService
        .getNonRentalPropertyProductSelectedWithoutError()
        .pipe(
          take(1),
          map(productId => [entity, productId])
        )
    ),
    switchMap(([entity, productId]) =>
      this._store
        .select(fromSelectors.buildMortgageRequest(entity, productId))
        .pipe(
          take(1),
          map(request => [request, productId])
        )
    ),
    tap(([request, productId]: [any, string]) =>
      this._loggingService.log('deleteMortgage request', request)
    ),
    switchMap(([request, productId]: [any, string]) =>
      this._mortgageAdapter.deleteMortgage(request, productId).pipe(
        tap(response =>
          this._loggingService.log('deleteMortgage Response', response)
        ),
        switchMap(response => {
          return from([
            new fromLenderActions.DeleteLenderSuccess(request.lender),
            new fromLenderActions.InvalidateQuote(productId),
          ]);
        }),
        catchError(error => {
          const safeError = this._errorHelper.sanitizeError(error);
          this._loggingService.log('deleteAdditionalInterest Error', safeError);
          return of(new fromLenderActions.DeleteLenderFail(safeError));
        })
      )
    )
  );

  @Effect()
  retrieveMortgages$ = this._actions$.pipe(
    ofType(fromLenderActions.RETRIEVE_MORTGAGES),
    map((action: fromLenderActions.RetrieveMortgages) => action.payload),
    switchMap(quoteId =>
      this._productsService
        .getNonRentalPropertyProductSelectedWithoutError()
        .pipe(
          take(1),
          map(productId => [quoteId, productId])
        )
    ),
    switchMap(([quoteId, productId]: [string, string]) =>
      this._mortgageAdapter.getMortgages(quoteId, productId).pipe(
        take(1),
        map(response => [response, productId])
      )
    ),
    mergeMap(([response, productId]: [AddMortgageResponse[], string]) => {
      this._loggingService.log('get mortgage response', response);
      const hasMortgage = response.length ? true : false;
      return from([
        ...response.map(mortgage => {
          return new fromLenderActions.AddLender({
            id: mortgage.mortgageId,
            mortgage: { ...mortgage },
          });
        }),
        productId === ProductTypes.HOMEOWNERS
          ? new fromLenderActions.SetHomeownersMortgageFlag(
              <boolean>(<unknown>hasMortgage)
            )
          : new fromLenderActions.SetCondoMortgageFlag(
              <boolean>(<unknown>hasMortgage)
            ),
      ]);
    }),
    catchError(error => {
      const safeError = this._errorHelper.sanitizeError(error);
      this._loggingService.log('get mortgage error', safeError);
      return from([]);
    })
  );

  private composeAddMortgageRequest(
    payload: any,
    product: Product,
    token: string,
    lenders: LendersAndProduct[]
  ): AddMortgageRequest {
    const request = <any>{};

    request.accessToken = token;
    request.sessionId = product.sessionId;
    request.quoteId = product.quoteId;

    request.address = payload.mortgage.address;
    request.escrow = payload.mortgage.escrow;
    request.loanNumber = payload.mortgage.loanNumber;
    request.name = payload.mortgage.name;
    request.companyId = payload.mortgage.companyId;
    request.mortgageId = payload.mortgage.mortgageId;
    request.mortgageType = payload.mortgage.mortgageType;
    request.type = payload.mortgage.type
      ? payload.mortgage.type
      : lenders.length < 2
      ? MortgageeTypes.PRIMARY_MORTGAGEE
      : MortgageeTypes.MORTGAGEE;

    return request;
  }
}
