import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@core/validators/custom-validators';
import { CoveredLocation } from '@core/store/reducers/covered-location.reducer';
import { HO_PROP_INFO_FORM_ENG } from '@shared/constants/app-constants';
import { PropertyHelper } from '@core/services/helpers/property.helper';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

@Component({
  selector: 'mq-oil-tank-form',
  templateUrl: './oil-tank-form.component.html',
  styleUrls: ['./oil-tank-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OilTankFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() stateSpecificFlags: StateSpecificFlagsObject;
  @Input() coveredLocation: CoveredLocation;
  @Input() needConstructionInfo: boolean;
  @Input() set _options(value) {
    if (value) {
      this.options = value;
    }
  }

  options: any;
  requiredOilTank: boolean;
  currentYear: number = new Date().getFullYear();
  unsubscribe = new Subject();

  constructor(private _propertyHelper: PropertyHelper) {}

  ngOnInit(): void {
    this.requiredOilTank = this.stateSpecificFlags.requiredOilTank;
    this.patchForm();
    this.setValidators();
    this.listenForValueChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coveredLocation) {
      this.patchForm();
    }
  }

  patchForm(): void {
    if (this.requiredOilTank && this.coveredLocation) {
      this.form.patchValue({
        hasOilTank: this.coveredLocation.hasOilTank,
      });
      if (this.coveredLocation.hasOilTank && this.coveredLocation.oilTank) {
        this.form.patchValue({
          yearTankInstalled: this.coveredLocation.oilTank.yearTankInstalled,
          tankMaterial: this.coveredLocation.oilTank.tankMaterial,
          tankLocation: this.coveredLocation.oilTank.tankLocation,
          isClosureFillCertified:
            this.coveredLocation.oilTank.isClosureFillCertified,
        });
      }
    }
  }

  setValidators(): void {
    if (this.requiredOilTank) {
      this.hasOilTank.setValidators([Validators.required]);
      if (this.hasOilTank.value) {
        this.yearTankInstalled.setValidators([
          Validators.required,
          Validators.max(this.currentYear),
          this._propertyHelper.validateAfterYearBuilt.bind(this),
        ]);

        this.tankLocation.setValidators([
          Validators.required,
          CustomValidators.PicklistRequired,
        ]);

        this.tankMaterial.setValidators([
          Validators.required,
          CustomValidators.PicklistRequired,
        ]);

        if (this.tankLocation.value === 'OutdoorsBelowGround') {
          this.isClosureFillCertified.setValidators([Validators.required]);
        } else {
          this.isClosureFillCertified.setValidators([]);
        }
      } else {
        this.yearTankInstalled.setValidators([]);
        this.tankLocation.setValidators([]);
        this.tankMaterial.setValidators([]);
        this.isClosureFillCertified.setValidators([]);
      }
    } else {
      this.hasOilTank.setValidators([]);
      this.yearTankInstalled.setValidators([]);
      this.tankLocation.setValidators([]);
      this.tankMaterial.setValidators([]);
      this.isClosureFillCertified.setValidators([]);
    }
    this.hasOilTank.updateValueAndValidity();
    this.yearTankInstalled.updateValueAndValidity();
    this.tankLocation.updateValueAndValidity();
    this.tankMaterial.updateValueAndValidity();
    this.isClosureFillCertified.updateValueAndValidity();
  }

  listenForValueChanges(): void {
    this.hasOilTank.valueChanges
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe(() => this.setValidators());
    this.tankLocation.valueChanges
      .pipe(takeUntil(this.unsubscribe), distinctUntilChanged())
      .subscribe(() => this.setValidators());
  }

  showHasOilTankOptions(): boolean {
    return this.hasOilTank ? this.hasOilTank.value : false;
  }

  showIsClosureFillCertified(): boolean {
    return (
      this.hasOilTank &&
      this.hasOilTank.value &&
      this.tankLocation.value === 'OutdoorsBelowGround'
    );
  }

  getOilTankErrorText(): string {
    const errors = this.form.get('yearTankInstalled').errors;
    if (errors && errors['beforeYearBuilt']) {
      return `Must be after year built (${errors['yearBuilt']})`;
    }
    if (errors && errors['max']) {
      return HO_PROP_INFO_FORM_ENG.GET_RENO_ERROR.FUTURE_NOT_ALLOWED;
    }
    return HO_PROP_INFO_FORM_ENG.GET_RENO_ERROR.INCORRECT_FORMAT;
  }

  get hasOilTank(): AbstractControl {
    return this.form.get('hasOilTank');
  }

  get tankMaterial(): AbstractControl {
    return this.form.get('tankMaterial');
  }

  get yearTankInstalled(): AbstractControl {
    return this.form.get('yearTankInstalled');
  }

  get tankLocation(): AbstractControl {
    return this.form.get('tankLocation');
  }

  get isClosureFillCertified(): AbstractControl {
    return this.form.get('isClosureFillCertified');
  }
}
