import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AgencyService } from '@core/services';
import { take, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgentPersistenceGuard implements CanActivate {
  readonly SESSION_STORAGE_KEY = 'agentIdentity';
  readonly SESSION_STORAGE_KEY_JWT = 'jwt';
  readonly SESSION_STORAGE_KEY_ACCESS_TOKEN = 'access_token';
  private _sessionStorage: any; // Keeping this where unit tests can reach it.

  constructor(private _agencyService: AgencyService) {
    this._sessionStorage = window.sessionStorage;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._agencyService.getAgency().pipe(
      take(1),
      switchMap(agency => {
        if (agency) {
          this._sessionStorage.setItem(
            this.SESSION_STORAGE_KEY,
            JSON.stringify(agency)
          );
        } else {
          const serialFromStorage = this._sessionStorage.getItem(
            this.SESSION_STORAGE_KEY
          );
          if (serialFromStorage) {
            try {
              const agencyModel = JSON.parse(serialFromStorage);
              this._agencyService.updateAgency(agencyModel);
            } catch (e) {}
          }
          const jwt = this._sessionStorage.getItem(
            this.SESSION_STORAGE_KEY_JWT
          );
          const accessToken = this._sessionStorage.getItem(
            this.SESSION_STORAGE_KEY_ACCESS_TOKEN
          );
          if (jwt && accessToken) {
            this._agencyService.updateAgentJwt(JSON.parse(jwt), accessToken);
          }
        }
        return of(true);
      })
    );
  }
}
