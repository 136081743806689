import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { Observable } from 'rxjs';

import {} from 'rxjs/operators';

import { AgencyModel } from '@core/models/agency/agency.model';

import { AgencyProxy } from '@agency/proxies/agency.proxy';
import { EuaHelper } from '@core/services';
import { ErrorSettableComponent } from '@shared/components/error-settable-component';

@Component({
  selector: 'mq-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyComponent implements OnInit, ErrorSettableComponent {
  agency$: Observable<AgencyModel>;
  isAppLoading$: Observable<boolean>;
  states$: Observable<string[]>;
  activeStates$: Observable<string[]>;
  agencyChannels$: Observable<string[]>;

  constructor(
    private _proxy: AgencyProxy,
    private _router: Router,
    private _euaHelper: EuaHelper
  ) {}

  ngOnInit(): void {
    this._proxy.setTitle('Nationwide get Agency param');
    this.states$ = this._proxy.getStatesData();
    this.activeStates$ = this._proxy.getActiveStatesData();
    this.isAppLoading$ = this._proxy.isAppLoading();
    this.agencyChannels$ = this._proxy.getAgencyChannelsData();
    this._proxy.logPageLoaded();
    this.agency$ = this._proxy.getAgency();
  }

  onBack(event: any): void {}

  onSubmit(event: AgencyModel): void {
    this._proxy.updateAgency(event);
    this._proxy.logPageSubmit(event);

    const extras: NavigationExtras = {
      queryParams: {
        state: event.quoteState,
      },
    };

    this._proxy.updateQuoteState(event.quoteState);
    this._router.navigate(['/multi-quote/getting-started'], extras);
  }

  setErrorFromGuard(err: any): void {
    // Pass thru for now.
  }
}
