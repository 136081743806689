import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import { TelematicsVehiclePremium } from '@core/models/telematics/telematics.model';

@Component({
  selector: 'mq-no-program-enrollment',
  templateUrl: './no-program-enrollment.component.html',
  styleUrls: ['../../../../../../assets/scss/telematics-panel.scss'],
})
export class NoProgramEnrollmentComponent implements OnInit {
  @Input() vehicles: VehicleEntity[];
  @Input() vehiclePremiums: TelematicsVehiclePremium[];
  @Input() isIneligible: boolean;

  @Output() noProgramEnrollmentChanged = new EventEmitter<VehicleEntity>();

  panelHeading: string;
  panelSubHeading: string;

  ngOnInit(): void {
    if (this.isIneligible) {
      this.panelHeading = 'Ineligible for participation in programs to save';
      this.panelSubHeading =
        'This vehicle is incompatible with the device required for program participation.';
    } else {
      this.panelHeading = 'Not enrolled in a program to save';
      this.panelSubHeading = '';
    }
  }

  changeEnrollment(vehicle: VehicleEntity) {
    this.noProgramEnrollmentChanged.emit(vehicle);
  }
}
