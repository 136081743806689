import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers';
import * as fromPolicyholder from '@core/store/selectors/policyholder.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import * as fromAgency from '@core/store/selectors/agency.selector';
import * as fromUserContext from '@core/store/selectors/user-context.selector';
import { ProducerRequest } from '@core/models/request/producer-request.model';
import { ProducerUtils } from '@shared/utils/producer.utils';
import {
  AccountHolderRequest,
  AccountRequest,
} from '@core/models/request/account-request.model';

export const getAccountState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.account
);

export const getAccountLoaded = createSelector(
  getAccountState,
  (state): boolean => state.loaded
);

export const getAccountLoading = createSelector(
  getAccountState,
  (state): boolean => state.loading
);

export const getAccountId = createSelector(
  getAccountState,
  state => state.accountId
);

export const getProductIdsForNewBusinessCall = createSelector(
  getAccountState,
  state => state.productIdsForNewBusinessCall
);

export const getAccountHolder = createSelector(
  getAccountState,
  state => state.accountHolder
);

export const buildCreateAccountRequest = createSelector(
  fromPolicyholder.getPrimaryNamedInsured,
  fromPolicyAddress.selectPolicyAddressEntities,
  fromAgency.getAgency,
  fromUserContext.getUserContext,
  (policyholder, policyAddress, agent, userContext) => {
    if (!policyholder) {
      return null;
    }

    const address = policyAddress[policyholder.addressId];

    if (!address) {
      return null;
    }

    const producerRequest: ProducerRequest = {
      producerCode: ProducerUtils.getQuoteProducerCode(agent, address.state),
      type: ProducerUtils.getProducerType(agent),
    };

    const accountHolderRequest: AccountHolderRequest = {
      person: policyholder.person,
      address: address,
      emailAddress: policyholder.emailAddress,
      homeNumber: policyholder.homeNumber,
    };

    return {
      accountHolder: accountHolderRequest,
      producer: producerRequest,
      userContext: userContext,
    } as AccountRequest;
  }
);

export const validateProductIdsForNewBusinessCall = (productId: string) =>
  createSelector(
    getAccountId,
    getProductIdsForNewBusinessCall,
    (accountId, readytoProcessProductId) => {
      if (
        accountId ||
        (readytoProcessProductId?.length &&
          productId === readytoProcessProductId[0])
      ) {
        return false;
      } else {
        return true;
      }
    }
  );
