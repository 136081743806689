<label
  id="label_{{ fieldId }}"
  class="control-label"
  aria-hidden="false"
  [ngClass]="labelHTMLClass"
  htmlFor="{{ fieldId }}"
>
  <span [innerHTML]="labelInnerHTML" class="ng-binding help-label"></span>&nbsp;
  <button
    *ngIf="helpTextInnerHTML"
    type="button"
    class="nw-inline-help nw-icon-inline-help"
    [ngClass]="{ collapsed: !openHelpText }"
    (click)="openHelpText = !openHelpText"
    id="{{ fieldId }}HelpLink"
    role="button"
    aria-label="Help"
  ></button>
</label>
<div
  *ngIf="helpTextInnerHTML && openHelpText"
  class="nw-inline-help__content-container"
  [ngClass]="{ open: openHelpText }"
>
  <div class="nw-inline-help__content" id="contextualHelp_{{ fieldId }}">
    <p>
      <span [innerHTML]="helpTextInnerHTML"></span>
    </p>
  </div>
</div>
