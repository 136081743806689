import { SelectOption } from './../../models/select-option.model';
import { createSelector } from '@ngrx/store';

import * as fromFeature from '@core/store/reducers';
import * as fromBilling from '@core/store/reducers/billing.reducer';

import * as fromProducts from '@core/store/selectors/products.selector';
import * as fromPremium from '@core/store/selectors/premium.selector';
import * as fromPolicyAddress from '@core/store/selectors/policy-address.selector';
import * as fromTokens from '@core/store/selectors/token.selector';
import * as fromPolicyholders from '@core/store/selectors/policyholder.selector';
import * as fromAgents from '@core/store/selectors/agency.selector';
import * as fromLenders from '@core/store/selectors/lenders.selector';
import * as fromRetrieve from '@core/store/selectors/retrieve.selector';
import * as fromTelematics from '@core/store/selectors/telematics-enrollments.selector';
import * as fromMetaData from '@core/store/selectors/metadata.selector';
import { BillingSummaryDisplay } from '@core/models/billing/billing-summary-display.model';
import {
  isPayingThroughEscrow,
  isPayingThroughEscrowFromOverall,
} from './lenders.selector';
import { ProductDisplay } from '@core/models/display/quote-coverages/products/product-display.model';
import {
  BillingPaymentMethod,
  BillingPaymentPlan,
  DEFAULT_ID,
  DiscountDescriptions,
  eligiblePayplansAndPayMethods,
  ModifierValues,
  ProductTypes,
  TelematicsEnrollmentStatus,
  TelematicsProgram,
} from '@shared/constants/app-constants';
import { Product } from '@core/models/products/product.model';
import { AddressEntity } from '@core/models/entities/address.entity';
import {
  BillingModel,
  BillingPaymentPlanModel,
} from '@core/models/billing/billing.model';
import {
  Address,
  BankCardPaymentInfo,
  EftPaymentInfo,
} from '@core/models/down-payment/down-payment.model';
import { StateUtils } from '@shared/utils/state.utils';
import { PolicyholderEntity } from '@core/models/entities/policyholder.entity';
import { StandardizedAddress } from '@core/models/address/standardized-address.model';
import { BankCardUtils } from '@shared/utils/bank-card.utils';
import { DateUtils } from '@shared/utils/date.utils';
import { AgencyModel } from '@core/models/agency/agency.model';
import {
  DownPaymentExperienceRequest,
  PoliciesAndQuotes,
} from '@core/models/billing/down-payment/down-payment-experience-request.model';
import { PayPlanFeesDisplay } from '@core/models/display/quote-coverages/fees/payplan-fees-display.model';
import {
  DownPaymentExperienceResponse,
  EligiblePayplansAndPayMethods,
} from '@core/models/billing/down-payment/down-payment-expereience-response.model';
import {
  TextingPreference,
  EmailPreference,
  AccountHolder,
  PaymentSetupAccount,
  PaymentTransaction,
  Payer,
  SetupAccountExperience,
  SetupAccountExperienceRequest,
} from '@core/models/billing/setup-account/setup-account-experience-request.model';
import { Dictionary } from '@ngrx/entity';
import { AccessToken } from '../reducers/token.reducer';
import * as fromModifiers from '@core/store/selectors/modifier.selector';
import * as fromDiscounts from '@core/store/selectors/discount.selector';
import * as fromPrivateLabel from '@core/store/selectors/private-label.selector';
import { CustomUIElements } from '@shared/constants/private-label-constants';
import { PrivateLabelContentHelper } from '@shared/utils/private-label-content.helper';

export const getBillingState = createSelector(
  fromFeature.getAppState,
  (state: fromFeature.AppState) => state.billing
);

export const getBillingInfo = createSelector(
  getBillingState,
  state => state.billing
);

export const getPaymentPlanLoaded = createSelector(
  getBillingState,
  fromBilling.getPaymentPlanLoaded
);

export const getPaymentPlanLoading = createSelector(
  getBillingState,
  fromBilling.getPaymentPlanLoading
);

export const getPaymentPlanError = createSelector(
  getBillingState,
  fromBilling.getPaymentPlanError
);

export const getPaymentPlanStatus = createSelector(
  getPaymentPlanLoaded,
  getPaymentPlanLoading,
  getPaymentPlanError,
  (loaded, loading, error) => {
    if (error) return 'ERROR';
    if (loading) return 'PENDING';
    if (loaded) return 'LOADED';
    return 'NONE';
  }
);

export const getPaymentPlan = createSelector(
  getBillingState,
  fromBilling.getPaymentPlan
);

export const getDownPaymentLoaded = createSelector(
  getBillingState,
  fromBilling.getDownPaymentLoaded
);

export const getDownPaymentLoading = createSelector(
  getBillingState,
  fromBilling.getDownPaymentLoading
);

export const getBillingPaymentInfo = createSelector(
  getBillingInfo,
  state => state?.payment
);

export const getBillingBankCardInfo = createSelector(
  getBillingPaymentInfo,
  state => state?.bankCard
);

export const getBillingPciV2Loaded = createSelector(
  getBillingBankCardInfo,
  state => state?.pciLoaded
);

export const getBillingPciV2Loading = createSelector(
  getBillingBankCardInfo,
  state => state?.pciLoading
);

export const getDsmDownPaymentState = createSelector(
  getBillingState,
  state => state?.dsmDownPayment
);

export const getDownPaymentsFullPayMultiProducts = createSelector(
  getBillingState,
  state => state?.dsmDownPaymentsFullPayMultiProducts
);

export const getAccountSetupState = createSelector(
  getBillingState,
  fromBilling.getAccountSetupState
);

export const getAccountSetupResponseData = createSelector(
  getAccountSetupState,
  state => state.account
);
export const getAccountSetupResponseLoaded = createSelector(
  getAccountSetupState,
  state => state.loaded
);

export const getAccountSetupResponseLoading = createSelector(
  getAccountSetupState,
  state => state.loading
);

export const getAccountSetupResponseError = createSelector(
  getAccountSetupState,
  state => state.error
);

export const getInitializePaymentPlanRequired = createSelector(
  getBillingState,
  state => state.isInitializePaymentPlanRequired
);

export const getTriggerEditBillingPopup = createSelector(
  getBillingState,
  state => state.triggerEditBillingPopup
);
// DSM
// Update for escrow
export const dsmGetBillingSummaryInfo = createSelector(
  getBillingInfo,
  fromProducts.getSelectedProductsWithoutErrors,
  getDsmDownPaymentState,
  isPayingThroughEscrowFromOverall,
  getDownPaymentsFullPayMultiProducts,

  (
    billing,
    products,
    downPayment,
    escrow,
    downPaymentsFullPayMultiProducts
  ) => {
    if (downPayment) {
      products = products.filter(
        product => product.id !== ProductTypes.TERMLIFE
      );
      return products.map(product => {
        const BANK_ACCOUNT_SUFFIX = -4;
        const summary = {} as BillingSummaryDisplay;
        if (
          (product.id === ProductTypes.HOMEOWNERS && escrow) ||
          (product.id === ProductTypes.CONDO && escrow)
        ) {
          summary.productName = product.name;
          summary.productImgUrl = product.imageUrl;
          summary.creditCardDigits = '';
          summary.bankAccountDigits = '';
          summary.paymentMethod = '';
          summary.billingPremium = 0;
          summary.frequency = '';
          summary.escrow = true;
        } else if (billing && billing.payment) {
          const policy = downPayment.policyLevel.find(
            policy =>
              policy.displayPolicyNumber.trim() === product.policyNumber.trim()
          );
          const isFullPay =
            billing.payment.paymentPlan === BillingPaymentPlan.FULL_PAY;
          if (isFullPay) {
            summary.billingPremium = Number(policy.termPremium);
          } else {
            summary.billingPremium = Number(policy.downpayment);
          }
          summary.productName = product.name;
          summary.productImgUrl = product.imageUrl;
          summary.paymentMethod = billing.payment.paymentMethod;
          summary.creditCardDigits = billing.payment.bankCard
            ? billing.payment.bankCard.last4Digits
            : '';
          summary.bankAccountDigits = billing.payment.bankAccount
            ? billing.payment.bankAccount.accountNumber.slice(
                BANK_ACCOUNT_SUFFIX
              )
            : '';
          summary.frequency = isFullPay
            ? BillingPaymentPlan.FULL_PAY
            : BillingPaymentPlan.MONTHLY;
        } else {
          summary.productName = product.name;
          summary.productImgUrl = product.imageUrl;
        }
        return summary;
      });
    } else if (downPaymentsFullPayMultiProducts) {
      products = products.filter(
        product => product.id !== ProductTypes.TERMLIFE
      );
      return products.map(product => {
        const BANK_ACCOUNT_SUFFIX = -4;
        const summary = {} as BillingSummaryDisplay;
        const downpaymentByProduct = downPaymentsFullPayMultiProducts.find(
          downpayment => downpayment.productId === product.id
        );
        if (
          (product.id === ProductTypes.HOMEOWNERS && escrow) ||
          (product.id === ProductTypes.CONDO && escrow)
        ) {
          summary.productName = product.name;
          summary.productImgUrl = product.imageUrl;
          summary.creditCardDigits = '';
          summary.bankAccountDigits = '';
          summary.paymentMethod = '';
          summary.billingPremium = 0;
          summary.frequency = '';
          summary.escrow = true;
        } else if (billing && billing.payment) {
          const policy = downpaymentByProduct.policyLevel.find(
            policyInfo =>
              policyInfo.displayPolicyNumber.trim() ===
              product.policyNumber.trim()
          );
          const isFullPay =
            billing.payment.paymentPlan === BillingPaymentPlan.FULL_PAY;
          if (isFullPay) {
            summary.billingPremium = Number(policy.termPremium);
          } else {
            summary.billingPremium = Number(policy.downpayment);
          }
          summary.productName = product.name;
          summary.productImgUrl = product.imageUrl;
          summary.paymentMethod = billing.payment.paymentMethod;
          summary.creditCardDigits = billing.payment.bankCard
            ? billing.payment.bankCard.last4Digits
            : '';
          summary.bankAccountDigits = billing.payment.bankAccount
            ? billing.payment.bankAccount.accountNumber.slice(
                BANK_ACCOUNT_SUFFIX
              )
            : '';
          summary.frequency = isFullPay
            ? BillingPaymentPlan.FULL_PAY
            : BillingPaymentPlan.MONTHLY;
        } else {
          summary.productName = product.name;
          summary.productImgUrl = product.imageUrl;
        }
        return summary;
      });
    }
  }
);

// DSM
export const getBillingProducts = createSelector(
  fromProducts.getSelectedProductsWithoutErrors,
  fromPremium.selectPremiumEntities,
  getDsmDownPaymentState,
  getDownPaymentsFullPayMultiProducts,
  (products, premiums, downPayment, downPaymentsFullPayMultiProducts) => {
    if (downPayment) {
      products = products.filter(
        product => product.id !== ProductTypes.TERMLIFE
      );
      return products.map(product => {
        const policy = downPayment.policyLevel.find(
          policy =>
            policy.displayPolicyNumber.trim() === product.policyNumber?.trim()
        ) || {
          downpayment: 0,
          termPremium: 0,
        };
        const premium = premiums[product.id];

        return <ProductDisplay>{
          policyEffectiveDate: product.effectiveDate,
          billingMonthlyPremium: Number(policy.downpayment),
          billingTotalPremium: Number(policy.termPremium),
          imageUrl: product.imageUrl,
          name: product.name,
          term: premium.termMonths,
          id: product.id,
        };
      });
    } else if (downPaymentsFullPayMultiProducts?.length) {
      products = products.filter(
        product => product.id !== ProductTypes.TERMLIFE
      );
      return products.map(product => {
        const downpaymentByProduct = downPaymentsFullPayMultiProducts.find(
          downpayment => downpayment.productId === product.id
        );
        const policy = downpaymentByProduct?.policyLevel.find(
          policylevel =>
            policylevel.displayPolicyNumber.trim() ===
            product.policyNumber?.trim()
        ) || {
          downpayment: 0,
          termPremium: 0,
        };
        const premium = premiums[product.id];
        return <ProductDisplay>{
          policyEffectiveDate: product.effectiveDate,
          billingMonthlyPremium: Number(policy.downpayment),
          billingTotalPremium: Number(policy.termPremium),
          imageUrl: product.imageUrl,
          name: product.name,
          term: premium.termMonths,
          id: product.id,
        };
      });
    }
  }
);

export const getBillingPayPlanFees = createSelector(
  getDsmDownPaymentState,
  getDownPaymentsFullPayMultiProducts,
  (downPayment, downPaymentsFullPayMultiProducts) => {
    let payPlanFeesDisplay;
    if (downPayment) {
      const payPlans = downPayment.eligiblePayplansAndPayMethods;
      payPlanFeesDisplay = <PayPlanFeesDisplay>{
        directDownPaymentFee:
          payPlans.find(
            payPlan =>
              payPlan.payMethod === eligiblePayplansAndPayMethods.MONTHLY_DIRECT
          )?.downPaymentFee || 0,
        directMonthlyFees:
          payPlans.find(
            payPlan =>
              payPlan.payMethod === eligiblePayplansAndPayMethods.MONTHLY_DIRECT
          )?.monthlyFees || 0,
        eftDownPaymentFee:
          payPlans.find(
            payPlan =>
              payPlan.payMethod === eligiblePayplansAndPayMethods.MONTHLY_EFT
          )?.downPaymentFee || 0,
        eftMonthlyFees:
          payPlans.find(
            payPlan =>
              payPlan.payMethod === eligiblePayplansAndPayMethods.MONTHLY_EFT
          )?.monthlyFees || 0,
        bankcardDownPaymentFee:
          payPlans.find(
            payPlan =>
              payPlan.payMethod ===
              eligiblePayplansAndPayMethods.MONTHLY_RECURRING_BANKCARD
          )?.downPaymentFee || 0,
        bankcardMonthlyFees:
          payPlans.find(
            payPlan =>
              payPlan.payMethod ===
              eligiblePayplansAndPayMethods.MONTHLY_RECURRING_BANKCARD
          )?.monthlyFees || 0,
        imageUrl: '',
      };
    } else if (downPaymentsFullPayMultiProducts?.length) {
      let directDownPaymentFee = 0,
        directMonthlyFees = 0,
        eftDownPaymentFee = 0,
        eftMonthlyFees = 0,
        bankcardDownPaymentFee = 0,
        bankcardMonthlyFees = 0;
      downPaymentsFullPayMultiProducts.forEach(downpayment => {
        const payPlans = downpayment.eligiblePayplansAndPayMethods;
        directDownPaymentFee =
          directDownPaymentFee +
            Number(
              payPlans.find(
                payPlan =>
                  payPlan.payMethod ===
                  eligiblePayplansAndPayMethods.MONTHLY_DIRECT
              )?.downPaymentFee
            ) || 0;
        directMonthlyFees = directMonthlyFees
          ? directMonthlyFees
          : 0 +
              Number(
                payPlans.find(
                  payPlan =>
                    payPlan.payMethod ===
                    eligiblePayplansAndPayMethods.MONTHLY_DIRECT
                )?.monthlyFees
              ) || 0;
        eftDownPaymentFee =
          eftDownPaymentFee +
            Number(
              payPlans.find(
                payPlan =>
                  payPlan.payMethod ===
                  eligiblePayplansAndPayMethods.MONTHLY_EFT
              )?.downPaymentFee
            ) || 0;
        eftMonthlyFees =
          eftMonthlyFees +
            Number(
              payPlans.find(
                payPlan =>
                  payPlan.payMethod ===
                  eligiblePayplansAndPayMethods.MONTHLY_EFT
              )?.monthlyFees
            ) || 0;
        bankcardDownPaymentFee =
          bankcardDownPaymentFee +
          Number(
            payPlans.find(
              payPlan =>
                payPlan.payMethod ===
                eligiblePayplansAndPayMethods.MONTHLY_RECURRING_BANKCARD
            )?.downPaymentFee
          );
        bankcardMonthlyFees =
          bankcardMonthlyFees +
            Number(
              payPlans.find(
                payPlan =>
                  payPlan.payMethod ===
                  eligiblePayplansAndPayMethods.MONTHLY_RECURRING_BANKCARD
              )?.monthlyFees
            ) || 0;
      });

      payPlanFeesDisplay = <PayPlanFeesDisplay>{
        directDownPaymentFee: directDownPaymentFee.toString(),
        directMonthlyFees: directMonthlyFees.toString(),
        eftDownPaymentFee: eftDownPaymentFee.toString(),
        eftMonthlyFees: eftMonthlyFees.toString(),
        bankcardDownPaymentFee: bankcardDownPaymentFee.toString(),
        bankcardMonthlyFees: bankcardMonthlyFees.toString(),
        imageUrl: '',
      };
    }

    return payPlanFeesDisplay;
  }
);

export const buildDownPaymentExperienceRequest = createSelector(
  fromProducts.getSelectedProductsWithoutErrors,
  fromTokens.selectTokenEntities,
  fromLenders.isPayingThroughEscrowFromOverall,
  (products, tokens, escrow) => {
    const isPowersportsSelected = products.some(product => {
      return product.id === ProductTypes.POWERSPORTS ? true : false;
    });
    const isTermLifeSelected = products.some(product => {
      return product.id === ProductTypes.TERMLIFE ? true : false;
    });
    if (isPowersportsSelected && isTermLifeSelected) {
      const powersports = products.pop();
      products.unshift(powersports);
    }
    const downPaymentRequest = <DownPaymentExperienceRequest>{};
    downPaymentRequest.accessToken = tokens[products[0]?.id]?.accessToken;
    downPaymentRequest.body = {
      distributionChannel: 'INTERNET',
      policiesAndQuotes: _buildPoliciesAndQuotes(products, escrow),
    };

    // In case we dropped a product due to escrow or whatever, try to pull an access token from the final list.
    if (downPaymentRequest.body.policiesAndQuotes.length > 0) {
      const firstProduct = products.find(
        p => p.quoteId === downPaymentRequest.body.policiesAndQuotes[0].quoteId
      );
      if (firstProduct) {
        downPaymentRequest.accessToken = tokens[firstProduct.id].accessToken;
      }
    }

    return downPaymentRequest;
  }
);
export const buildDownpaymentFullPayMultiProductResponseEntity = (
  response: DownPaymentExperienceResponse
) =>
  createSelector(fromProducts.getProductsWithPolicyNumber, products => {
    let productFound;
    products.forEach(product => {
      productFound = response.policyLevel.find(
        policy => policy.displayPolicyNumber === product.policyNumber
      );
      if (productFound) {
        response.productId = product.id;
      }
    });
    return response;
  });

export const buildDownPaymentExperienceFullPayMultiProductRequest =
  createSelector(
    fromProducts.getSelectedProductsWithoutErrors,
    fromTokens.selectTokenEntities,
    fromLenders.isPayingThroughEscrowFromOverall,
    (products, tokens, escrow) => {
      const downPaymentRequestList = [];
      products.forEach(product => {
        const downPaymentRequest = <DownPaymentExperienceRequest>{};
        if (tokens[product.id]?.accessToken) {
          downPaymentRequest.accessToken = tokens[product.id].accessToken;
          downPaymentRequest.body = {
            distributionChannel: 'INTERNET',
            policiesAndQuotes: _buildFullPayMultiProductPolicyAndQuote(
              product,
              escrow
            ),
          };

          // In case we dropped a product due to escrow or whatever, try to pull an access token from the final list.
          if (downPaymentRequest.body.policiesAndQuotes?.length > 0) {
            const firstProduct = products.find(
              p =>
                p.quoteId ===
                downPaymentRequest.body.policiesAndQuotes[0].quoteId
            );
            if (firstProduct) {
              downPaymentRequest.accessToken =
                tokens[firstProduct.id].accessToken;
            }
          }

          downPaymentRequestList.push(downPaymentRequest);
        }
      });
      return downPaymentRequestList;
    }
  );

const _buildPostalAddress = (policyAddress: AddressEntity) => {
  const address: Address = {
    addressLineOne: policyAddress.addressLine1,
    addressLineTwo: policyAddress.addressLine2,
    city: policyAddress.city,
    state: StateUtils.mapAbbreviationToFullName(policyAddress.state),
    postalCode: policyAddress.postalCode,
  };

  return address;
};

const _buildPayorAddress = (
  address: StandardizedAddress,
  unitNumber: string
): Address => {
  const payorAddress = {} as Address;

  payorAddress.addressLineOne = address.street;
  payorAddress.addressLineTwo = unitNumber;
  payorAddress.city = address.city;
  payorAddress.state = StateUtils.mapAbbreviationToFullName(address.state);
  payorAddress.postalCode = address.zipCode;

  return payorAddress;
};

const _cleanPhoneNumber = (phone: string) => {
  if (phone) {
    const clean = phone.split('-');
    return `${clean[0]}${clean[1]}${clean[2]}`;
  }
  return '';
};

const _buildBankCardPaymentInfo = (billing: BillingModel) => {
  if (!billing.payment.bankCard) {
    return null;
  }
  const bankCard = {} as BankCardPaymentInfo;

  bankCard.cardBrand = BankCardUtils.mapCardToCardType(
    billing.payment.bankCard.type
  );
  bankCard.ccLastFour = billing.payment.bankCard.last4Digits;
  bankCard.cvvCode = billing.payment.bankCard.cvv2;

  if (bankCard.cardBrand === 'American Express') {
    bankCard.cvvCode = billing.payment.bankCard.cid;
  }

  bankCard.encryptedPan = billing.payment.bankCard.encryptedNumber;
  bankCard.encryptedPanKey = billing.payment.bankCard.sessionKey;
  bankCard.encryptedPanFingerprint =
    billing.payment.bankCard.recipientCertificateFingerprint;
  bankCard.expirationDate = DateUtils.buildPaymentCreditCardExpirationDate(
    billing.payment.bankCard.expYear,
    billing.payment.bankCard.expMonth
  );
  return bankCard;
};

const _buildEftPaymentInfo = (billing: BillingModel) => {
  if (!billing.payment.bankAccount) {
    return null;
  }

  const bankAccount = {} as EftPaymentInfo;

  bankAccount.bankName = billing.payment.bankAccount.bankName;
  bankAccount.bankAccountType = 'checking';
  bankAccount.bankRoutingNumber = billing.payment.bankAccount.routingNumber;
  bankAccount.bankAccountNumber = billing.payment.bankAccount.accountNumber;

  return bankAccount;
};

const _buildAgentCode = (agent: AgencyModel, state: string) => {
  if (!agent) {
    return StateUtils.defaultBillingAgencyCode;
  }

  if (agent.agencyChannel === 'IA') {
    const leadingZeros = 3;
    return agent.agencyCode.slice(leadingZeros);
  } else if (agent.agencyChannel === 'EA') {
    const trailingAgentNumber = 7;
    if (agent.producerCode.length > trailingAgentNumber) {
      return agent.producerCode.slice(
        agent.producerCode.length - trailingAgentNumber
      );
    } else {
      return agent.producerCode;
    }
  } else {
    return agent.producerCode;
  }
};

export const getPolicyData = createSelector(
  fromPolicyholders.getPolicyholder(DEFAULT_ID),
  fromPolicyAddress.getPolicyAddress(DEFAULT_ID),
  (policyholder, policyAddress) => ({ policyholder, policyAddress })
);

function _buildTextingPreference(
  mobilePhoneNumber: string
): TextingPreference[] {
  const textingPreferences = [];
  const textingPref = {} as TextingPreference;
  textingPref.preferenceType = 'Payment Reminder Text';
  textingPref.preferenceValue = 'No';
  textingPref.deliveryMethod = 'BillingTextDelivery';
  textingPref.deliveryInformation = mobilePhoneNumber;
  textingPreferences.push(textingPref);
  textingPreferences.push({
    ...textingPref,
    preferenceType: 'Payment Notification Text',
  });
  return textingPreferences;
}

function _buildEmailPreference(emailAddress: string): EmailPreference {
  const emailPreference = {} as EmailPreference;
  emailPreference.consentStatus = 'Y';
  emailPreference.paperlessPreference = 'EMAL';
  emailPreference.emailAddress = emailAddress;
  emailPreference.paymentReminderPreference = 'Y';
  emailPreference.paymentConfirmationPreference = 'Y';
  return emailPreference;
}

function _buildAccountHolder(
  policyholder: PolicyholderEntity,
  policyAddress: AddressEntity,
  suffixesData: SelectOption[]
): AccountHolder {
  const accountHolder = {} as AccountHolder;
  accountHolder.name = {
    person: {
      firstName: policyholder.person.firstName,
      middleName: policyholder.person.middleName
        ? policyholder.person.middleName.trim().charAt(0)
        : '',
      lastName: policyholder.person.lastName,
      suffix: policyholder.person.suffix
        ? _getSuffix(policyholder.person.suffix, suffixesData)
        : '',
    },
  };
  accountHolder.address = {
    optOutFromNCOAAddressUpdate: 'Y',
    domesticAddress: {
      addressCleansingBypassReason: 'Use customer preferred city',
      addressLine1: policyAddress.addressLine1,
      addressLine2: policyAddress.addressLine2,
      city: policyAddress.city,
      state: policyAddress.state,
      zip: policyAddress.postalCode.replace('-', ''),
    },
  };
  return accountHolder;
}

function _buildPoliciesAndQuotes(
  products: Product[],
  isPayingThroughEscrow: boolean
): PoliciesAndQuotes[] {
  return products
    .filter(product => product.id !== ProductTypes.TERMLIFE)
    .filter(
      product =>
        !(
          (product.id === ProductTypes.HOMEOWNERS && isPayingThroughEscrow) ||
          (product.id === ProductTypes.CONDO && isPayingThroughEscrow)
        )
    )
    .map(product => {
      const policyAndQuotes = {} as PoliciesAndQuotes;
      policyAndQuotes.policyNumber = product.policyNumber;
      policyAndQuotes.quoteId = product.quoteId;
      return policyAndQuotes;
    });
}

function _buildPaymentTransaction(billing: BillingModel): PaymentTransaction {
  const paymentTrans = {} as PaymentTransaction;
  if (billing.payment.paymentMethod === 'EFT') {
    paymentTrans.paymentMethod = 'electronicFunds';
    paymentTrans.electronicFunds = {
      bankName: billing.payment.bankAccount.bankName,
      bankRoutingNumber: billing.payment.bankAccount.routingNumber,
      bankAccountNumber: billing.payment.bankAccount.accountNumber,
      bankAccountType: 'CHECKING',
    };
  } else {
    paymentTrans.paymentMethod = 'bankCardWithClearPan';
    paymentTrans.bankCardWithClearPan = {
      cardNumber: billing.payment.bankCard.cardNumber,
      cardType: BankCardUtils.mapCardToCardType(billing.payment.bankCard.type),
      cardVerificationValue: billing.payment.bankCard.cvv2,
      expirationDate: DateUtils.buildPaymentCreditCardExpirationDate(
        billing.payment.bankCard.expYear,
        billing.payment.bankCard.expMonth
      ),
    };

    if (paymentTrans.bankCardWithClearPan.cardType === 'American Express') {
      paymentTrans.bankCardWithClearPan.cardVerificationValue =
        billing.payment.bankCard.cid;
    }
  }
  return paymentTrans;
}

function _buildPayer(
  billing: BillingModel,
  policyholder: PolicyholderEntity
): Payer {
  const payer = {} as Payer;
  payer.firstName = billing.name.firstName;
  payer.middleName = billing.name.middleName
    ? billing.name.middleName.trim().charAt(0)
    : '';
  payer.lastName = billing.name.lastName;
  payer.displayName =
    billing.name.firstName +
    ' ' +
    (billing.name.middleName
      ? billing.name.middleName.trim().charAt(0) + ' '
      : '') +
    billing.name.lastName;
  payer.addressLine1 = billing.address.standardizedAddresses.street;
  payer.addressLine2 = billing.address.unitNumber;
  payer.city = billing.address.standardizedAddresses.city;
  payer.state = billing.address.standardizedAddresses.state;
  payer.postalCode = billing.address.standardizedAddresses.zipCode;
  payer.phone = policyholder.homeNumber;
  payer.emailAddress = policyholder.emailAddress;
  return payer;
}

function _buildPayment(
  billing: BillingModel,
  downPayment: DownPaymentExperienceResponse,
  policyholder: PolicyholderEntity,
  isPayingThroughEscrow: boolean
): PaymentSetupAccount {
  const payment = {} as PaymentSetupAccount;
  payment.businessChannel = 'INTERNET-ELECTRONIC BILL';

  switch (billing?.payment?.paymentMethod) {
    case BillingPaymentMethod.EFT: {
      if (billing?.payment?.recurring) {
        const payPlan = _eligiblePayplansAndPayMethods(
          downPayment,
          eligiblePayplansAndPayMethods.MONTHLY_EFT
        );
        payment.amount =
          billing.payment.paymentPlan === BillingPaymentPlan.FULL_PAY
            ? payPlan?.fullPayAmount?.trim()?.replace(/,/g, '')
            : payPlan.totalDownPaymentWithFees.trim().replace(/,/g, '');
      } else {
        const payPlan = _eligiblePayplansAndPayMethods(
          downPayment,
          eligiblePayplansAndPayMethods.MONTHLY_DIRECT
        );
        payment.amount =
          billing.payment.paymentPlan === BillingPaymentPlan.FULL_PAY
            ? payPlan?.fullPayAmount?.trim()?.replace(/,/g, '')
            : payPlan.totalDownPaymentWithFees.trim().replace(/,/g, '');
      }
      break;
    }

    case BillingPaymentMethod.BANKCARD: {
      if (billing?.payment?.recurring) {
        const payPlan = _eligiblePayplansAndPayMethods(
          downPayment,
          eligiblePayplansAndPayMethods.MONTHLY_RECURRING_BANKCARD
        );
        payment.amount =
          billing.payment.paymentPlan === BillingPaymentPlan.FULL_PAY
            ? payPlan?.fullPayAmount?.trim()?.replace(/,/g, '')
            : payPlan.totalDownPaymentWithFees.trim().replace(/,/g, '');
      } else {
        const payPlan = _eligiblePayplansAndPayMethods(
          downPayment,
          eligiblePayplansAndPayMethods.MONTHLY_DIRECT
        );
        payment.amount =
          billing.payment.paymentPlan === BillingPaymentPlan.FULL_PAY
            ? payPlan?.fullPayAmount?.trim()?.replace(/,/g, '')
            : payPlan.totalDownPaymentWithFees.trim().replace(/,/g, '');
      }
      break;
    }

    default:
      break;
  }
  payment.paymentTransaction = _buildPaymentTransaction(billing);
  payment.payer = _buildPayer(billing, policyholder);
  return payment;
}

export const getAgent = createSelector(
  fromAgents.getAgency,
  fromAgents.isAgent,
  (agency, isAgent) => ({ agency, isAgent })
);

export const buildSetupAccountExperienceRequest = (
  tokens: Dictionary<AccessToken>
) =>
  createSelector(
    getPolicyData,
    fromProducts.getSelectedProductsWithoutErrors,
    getBillingInfo,
    getDsmDownPaymentState,
    getAgent,
    fromLenders.isPayingThroughEscrowFromOverall,
    fromTelematics.getEnrollments,
    fromMetaData.getSuffixData,
    (
      policyData,
      products,
      billing,
      downPayment,
      agent,
      isPayingThroughEscrow,
      getEnrollments,
      getSuffixData
    ) => {
      const setupAccountExperienceRequest = {} as SetupAccountExperienceRequest;
      const setupAccountRequest = {} as SetupAccountExperience;
      const autoProduct = products.find(
        product => product.id === ProductTypes.AUTO
      );
      if (autoProduct) {
        setupAccountRequest.dayofMonthDue = _getSmartMilesAccountDueDate(
          autoProduct,
          billing,
          getEnrollments
        );
      } else {
        setupAccountRequest.dayofMonthDue =
          agent && agent.isAgent
            ? billing.payment.paymentDayOfMonth
            : products[0].effectiveDate.split('-')[2];
      }
      setupAccountRequest.accountPayMethod =
        _getBillingAccountPaymentMethod(billing);
      setupAccountRequest.payPlan =
        billing.payment.paymentPlan === BillingPaymentPlan.MONTHLY
          ? downPayment.eligiblePayplansAndPayMethods.find(value =>
              value.payPlan.includes(
                eligiblePayplansAndPayMethods.COMBINED_MONTHLY
              )
            )?.payPlan
          : downPayment.eligiblePayplansAndPayMethods.find(value =>
              value.payPlan.includes(
                eligiblePayplansAndPayMethods.FULL_PAYMENT_PLAN
              )
            )?.payPlan;
      // to do - need to remove below code once DSM provides full payment option for other states
      setupAccountRequest.payPlan = !setupAccountRequest.payPlan
        ? eligiblePayplansAndPayMethods.COMBINED_MONTHLY
        : setupAccountRequest.payPlan;
      setupAccountRequest.producerNumber = _buildAgentCode(
        agent.agency,
        policyData.policyAddress.state
      );
      if (agent && agent.agency && agent.agency.state) {
        setupAccountRequest.producerState = agent.agency.state;
      } else {
        setupAccountRequest.producerState = policyData.policyAddress.state;
      }
      setupAccountRequest.distributionChannel = _getDistributionChannel(
        agent.agency
      );
      setupAccountRequest.accountHolder = _buildAccountHolder(
        policyData.policyholder,
        policyData.policyAddress,
        getSuffixData
      );
      setupAccountRequest.emailPreference = _buildEmailPreference(
        policyData.policyholder.emailAddress
      );
      setupAccountRequest.textingPreference = _buildTextingPreference(
        policyData.policyholder.homeNumber
      );
      setupAccountRequest.payment = _buildPayment(
        billing,
        downPayment,
        policyData.policyholder,
        isPayingThroughEscrow
      );
      setupAccountRequest.policiesAndQuotes = _buildPoliciesAndQuotes(
        products,
        isPayingThroughEscrow
      );

      setupAccountExperienceRequest.body = setupAccountRequest;

      setupAccountExperienceRequest.accessToken =
        tokens[products[0].id].accessToken;
      if (setupAccountRequest.policiesAndQuotes?.length > 0) {
        const product = products.find(
          p => p.quoteId === setupAccountRequest.policiesAndQuotes[0].quoteId
        );
        if (product) {
          setupAccountExperienceRequest.accessToken =
            tokens[product.id].accessToken;
        }
      }

      return setupAccountExperienceRequest;
    }
  );

function _getBillingAccountPaymentMethod(billing: BillingModel): string {
  // Type is boolean, but it's a string at runtime.
  const recurring = billing.payment.recurring;

  if (!recurring) {
    return 'Direct Bill';
  } else if (billing.payment.paymentMethod === 'BANKCARD') {
    return 'Recurring BankCard';
  } else {
    return 'Recurring EFT';
  }
}

function _getDistributionChannel(agency: AgencyModel) {
  if (agency) {
    switch (agency.agencyChannel) {
      case 'EA':
        return 'EXCLUSIVE AGENT - FULL';
      case 'IA':
        return 'INDEPENDENT AGENT';
      case 'NSS':
        return 'NSS';
      case 'Internet':
      default:
        return 'INTERNET';
    }
  } else {
    return 'INTERNET';
  }
}

function _getSmartMilesAccountDueDate(
  product: Product,
  billing: any,
  getEnrollments: any
): string {
  const effectiveDate = product.effectiveDate;
  let dueDate = effectiveDate.split('-')[2];
  let isSmartMilesEnrolled = false;
  if (billing && billing.payment && billing.payment.paymentDayOfMonth) {
    return billing.payment.paymentDayOfMonth;
  }
  if (
    getEnrollments &&
    getEnrollments.vehicleEnrollment &&
    getEnrollments.vehicleEnrollment.vehicles.length
  ) {
    getEnrollments.vehicleEnrollment.vehicles.forEach(vehicle => {
      if (
        vehicle.enrollmentStatus === 'Enrolled' &&
        vehicle.vehicleProgram === 'SmartMiles'
      ) {
        return (isSmartMilesEnrolled = true);
      }
    });
    if (isSmartMilesEnrolled) {
      const numDays = 5;
      const date = new Date(effectiveDate);
      date.setDate(parseInt(dueDate, 10) + numDays);
      dueDate = date.getDate().toString().padStart(2, '0');
    }
  }
  return dueDate;
}

function _getSuffix(suffix: string, suffixData: SelectOption[]) {
  const displaySuffix = suffixData.find(({ value }) => value === suffix);
  return displaySuffix ? displaySuffix.display : '';
}

export const getAccountVerifyHitCount = createSelector(
  getBillingState,
  state => state.accountVerifyHitCount
);

export const getIsInvalidAccountNachaResponseCode = createSelector(
  getBillingState,
  state => state.isInvalidAccountNachaResponseCode
);

export const getAccountSetupFullPayMultiProducts = createSelector(
  getBillingState,
  fromBilling.getAccountSetupFullPayMultiProducts
);

export const getAccountsSetupResponseData = createSelector(
  getAccountSetupFullPayMultiProducts,
  state => {
    const accountSetupFullPayMultiProductsResponse = [];
    state.forEach(value =>
      accountSetupFullPayMultiProductsResponse.push(value.account)
    );
    return accountSetupFullPayMultiProductsResponse;
  }
);

export const getAccountsSetupResponseLoaded = createSelector(
  getAccountSetupFullPayMultiProducts,
  state => state.find(value => value.loaded === true).loaded
);

export const getAccountsSetupResponseLoading = createSelector(
  getAccountSetupFullPayMultiProducts,
  state => state.find(value => value.loaded === true).loading
);

export const getAccountsSetupResponseError = createSelector(
  getAccountSetupFullPayMultiProducts,
  state => state.find(value => value.error === true).error
);

function _buildFullPayMultiProductPolicyAndQuote(
  product: Product,
  isPayingThroughEscrowSelected: boolean
): PoliciesAndQuotes[] {
  if (product.id !== ProductTypes.TERMLIFE) {
    if (
      !(
        (product.id === ProductTypes.HOMEOWNERS &&
          isPayingThroughEscrowSelected) ||
        (product.id === ProductTypes.CONDO && isPayingThroughEscrowSelected)
      )
    ) {
      const policyAndQuotes = {} as PoliciesAndQuotes;
      policyAndQuotes.policyNumber = product.policyNumber;
      policyAndQuotes.quoteId = product.quoteId;
      return [policyAndQuotes];
    }
  }
}

export const buildSetupAccountExperienceFullPayMultiProductRequest = (
  tokens: Dictionary<AccessToken>
) =>
  createSelector(
    getPolicyData,
    fromProducts.getSelectedProductsWithoutErrors,
    getBillingInfo,
    getDownPaymentsFullPayMultiProducts,
    getAgent,
    fromLenders.isPayingThroughEscrowFromOverall,
    fromTelematics.getEnrollments,
    fromMetaData.getSuffixData,
    (
      policyData,
      products,
      billing,
      downPaymentsFullPayMultiProducts,
      agent,
      isPayingThroughEscrowFromOverall,
      getEnrollments,
      getSuffixData
    ) => {
      const setupAccountExperienceRequestList: SetupAccountExperienceRequest[] =
        [];
      products.forEach(product => {
        if (
          !(
            product.id === ProductTypes.HOMEOWNERS &&
            isPayingThroughEscrowFromOverall
          ) &&
          !(
            product.id === ProductTypes.CONDO &&
            isPayingThroughEscrowFromOverall
          )
        ) {
          const setupAccountRequest = {} as SetupAccountExperience;
          const setupAccountExperienceRequest =
            {} as SetupAccountExperienceRequest;
          const downPayment = downPaymentsFullPayMultiProducts.find(
            downPaymentValue => downPaymentValue.productId === product.id
          );

          if (downPayment) {
            if (product.id === ProductTypes.AUTO) {
              setupAccountRequest.dayofMonthDue = _getSmartMilesAccountDueDate(
                product,
                billing,
                getEnrollments
              );
            } else {
              setupAccountRequest.dayofMonthDue =
                agent && agent.isAgent
                  ? billing.payment.paymentDayOfMonth
                  : products[0].effectiveDate.split('-')[2];
            }
            setupAccountRequest.accountPayMethod =
              _getBillingAccountPaymentMethod(billing);
            setupAccountRequest.payPlan =
              billing.payment.paymentPlan === BillingPaymentPlan.MONTHLY
                ? downPayment?.eligiblePayplansAndPayMethods?.find(value =>
                    value.payPlan.includes(
                      eligiblePayplansAndPayMethods.COMBINED_MONTHLY
                    )
                  )?.payPlan
                : downPayment?.eligiblePayplansAndPayMethods?.find(value =>
                    value.payPlan.includes(
                      eligiblePayplansAndPayMethods.FULL_PAYMENT_PLAN
                    )
                  )?.payPlan;
            // to do - need to remove below code once DSM provides full payment option for other states
            setupAccountRequest.payPlan = !setupAccountRequest.payPlan
              ? eligiblePayplansAndPayMethods.COMBINED_MONTHLY
              : setupAccountRequest.payPlan;
            setupAccountRequest.producerNumber = _buildAgentCode(
              agent.agency,
              policyData.policyAddress.state
            );
            if (agent && agent.agency && agent.agency.state) {
              setupAccountRequest.producerState = agent.agency.state;
            } else {
              setupAccountRequest.producerState =
                policyData.policyAddress.state;
            }
            setupAccountRequest.distributionChannel = _getDistributionChannel(
              agent.agency
            );
            setupAccountRequest.accountHolder = _buildAccountHolder(
              policyData.policyholder,
              policyData.policyAddress,
              getSuffixData
            );
            setupAccountRequest.emailPreference = _buildEmailPreference(
              policyData.policyholder.emailAddress
            );
            setupAccountRequest.textingPreference = _buildTextingPreference(
              policyData.policyholder.homeNumber
            );
            setupAccountRequest.payment = _buildPayment(
              billing,
              downPayment,
              policyData.policyholder,
              isPayingThroughEscrowFromOverall
            );

            setupAccountRequest.policiesAndQuotes =
              _buildFullPayMultiProductPolicyAndQuote(
                product,
                isPayingThroughEscrowFromOverall
              );

            setupAccountExperienceRequest.body = setupAccountRequest;

            setupAccountExperienceRequest.accessToken =
              tokens[product.id].accessToken;
            if (
              setupAccountRequest.policiesAndQuotes?.length > 0 &&
              products.find(
                p =>
                  p.quoteId === setupAccountRequest.policiesAndQuotes[0].quoteId
              )
            ) {
              setupAccountExperienceRequest.accessToken =
                tokens[product.id].accessToken;
            }

            setupAccountExperienceRequestList.push(
              setupAccountExperienceRequest
            );
          }
        }
      });

      return setupAccountExperienceRequestList;
    }
  );

export const isRemoveEftOptionRequired = createSelector(
  fromMetaData.getStateSpecificFlagsObject,
  fromAgents.isAgent,
  fromPrivateLabel.getPrivateLabelConfiguration,
  (stateSpecificFlags, isAgent, privateLabelConfig) => {
    const isPlaidDisableForPrivateLabel =
      PrivateLabelContentHelper.getConfigValue(
        privateLabelConfig,
        CustomUIElements.DISABLE_PLAID
      ) === 'true'
        ? true
        : false;
    const isRemoveEftOptionRequired =
      stateSpecificFlags.removeEftPaymentOption && !isAgent;
    if (
      isRemoveEftOptionRequired &&
      (isAgent || isPlaidDisableForPrivateLabel)
    ) {
      return true;
    }
    return false;
  }
);

export const buildInitializeBillingPaymentPlan = createSelector(
  fromProducts.getSelectedProductsWithoutErrors,
  fromLenders.isPayingThroughEscrowFromOverall,
  fromModifiers.getBillingPaymentMethodModifier(),
  fromTelematics.getEnrollments,
  fromDiscounts.getAllSelectedDiscounts,
  isRemoveEftOptionRequired,
  (
    products,
    isPayingThroughEscrowSelected,
    billingPaymentMethodModifier,
    enrollments,
    discounts,
    isRemoveEftOptionRequired
  ): BillingPaymentPlanModel => {
    let isSmartMilesAvailable = false;

    const isPaidInFullDiscountSelected = discounts.some(discount => {
      return discount.description === DiscountDescriptions.PaidInFullDiscount &&
        discount.isDiscountApplied
        ? true
        : false;
    });
    isSmartMilesAvailable = enrollments?.vehicleEnrollment?.vehicles.some(
      vehicle => {
        return vehicle.vehicleProgram === TelematicsProgram.SMART_MILES &&
          vehicle.enrollmentStatus === TelematicsEnrollmentStatus.ENROLLED
          ? true
          : false;
      }
    );
    const isHomeownersSelected = products.some(product => {
      return product.id === ProductTypes.HOMEOWNERS && !product.hasError
        ? true
        : false;
    });
    const isCondoSelected = products.some(product => {
      return product.id === ProductTypes.CONDO && !product.hasError
        ? true
        : false;
    });
    if (
      (isHomeownersSelected || isCondoSelected) &&
      isPayingThroughEscrowSelected &&
      products.length === 1
    ) {
      return {
        paymentPlan: '',
        recurring: false,
        paymentMethod: '',
      } as BillingPaymentPlanModel;
    } else if (
      isPaidInFullDiscountSelected &&
      !isSmartMilesAvailable &&
      isRemoveEftOptionRequired
    ) {
      return {
        paymentPlan: BillingPaymentPlan.FULL_PAY,
        recurring: false,
        paymentMethod: BillingPaymentMethod.BANKCARD,
      } as BillingPaymentPlanModel;
    } else if (isPaidInFullDiscountSelected && !isSmartMilesAvailable) {
      return {
        paymentPlan: BillingPaymentPlan.FULL_PAY,
        recurring: false,
        paymentMethod: BillingPaymentMethod.EFT,
      } as BillingPaymentPlanModel;
    } else if (isRemoveEftOptionRequired) {
      return {
        paymentPlan: BillingPaymentPlan.MONTHLY,
        recurring: true,
        paymentMethod: BillingPaymentMethod.BANKCARD,
      } as BillingPaymentPlanModel;
    } else
      return {
        paymentPlan: BillingPaymentPlan.MONTHLY,
        recurring: true,
        frequency: BillingPaymentPlan.MONTHLY,
        paymentMethod: BillingPaymentMethod.EFT,
      } as BillingPaymentPlanModel;
  }
);

export const isMultiProductFullPayApplied = createSelector(
  getBillingPaymentInfo,
  fromProducts.getProductsWithPolicyNumber,
  (billingPaymentInfo, product) => {
    if (
      product.length > 1 &&
      billingPaymentInfo.paymentPlan === BillingPaymentPlan.FULL_PAY
    ) {
      return true;
    }
    return false;
  }
);

function _eligiblePayplansAndPayMethods(
  downPayment: DownPaymentExperienceResponse,
  eligiblePayMethod: string
): EligiblePayplansAndPayMethods {
  return downPayment.eligiblePayplansAndPayMethods?.find(
    payPlan => payPlan.payMethod === eligiblePayMethod
  );
}

export const isMonolinePropertyFullPaySelected = createSelector(
  getBillingPaymentInfo,
  fromProducts.getProductsWithPolicyNumber,
  (billingPaymentInfo, products) => {
    const isPropertyProductAvailable = products.every(
      product =>
        product.id === ProductTypes.HOMEOWNERS ||
        product.id === ProductTypes.CONDO ||
        product.id === ProductTypes.RENTERS
    );
    if (
      isPropertyProductAvailable &&
      products.length === 1 &&
      billingPaymentInfo.paymentPlan === BillingPaymentPlan.FULL_PAY
    ) {
      return true;
    }
    return false;
  }
);

export const getRebindWhenInvalidOnBillingChange = createSelector(
  getBillingState,
  state => {
    return state.rebindWhenInvalidOnBillingChange;
  }
);

export const getRecurringBankCardForSmartMiles = createSelector(
  fromTelematics.getEnrollments,
  isRemoveEftOptionRequired,
  (enrollments, removeEftOption) => {
    const isSmartMilesAvailable = enrollments?.vehicleEnrollment?.vehicles.some(
      vehicle => {
        return vehicle.vehicleProgram === TelematicsProgram.SMART_MILES &&
          vehicle.enrollmentStatus === TelematicsEnrollmentStatus.ENROLLED
          ? true
          : false;
      }
    );
    if (isSmartMilesAvailable && removeEftOption) {
      return true;
    }
    return false;
  }
);

export const _75PercentDownPayment = createSelector(
  fromTelematics.getEnrollments,
  fromProducts.getSelectedProductsWithoutErrors,
  getBillingPaymentInfo,
  getDsmDownPaymentState,
  (enrollments, products, billingInfo, downPayment) => {
    const isSmartMilesAvailable = enrollments?.vehicleEnrollment?.vehicles.some(
      vehicle => {
        return vehicle.vehicleProgram === TelematicsProgram.SMART_MILES &&
          vehicle.enrollmentStatus === TelematicsEnrollmentStatus.ENROLLED
          ? true
          : false;
      }
    );
    let _75percentDownPaymentAmount;
    if (
      !isSmartMilesAvailable &&
      billingInfo?.paymentPlan !== BillingPaymentPlan.FULL_PAY &&
      products &&
      products?.length === 1 &&
      products[0]?.id === ProductTypes.AUTO
    ) {
      return true;
    } else return false;
  }
);
