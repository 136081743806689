import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { VehicleEntity } from '@core/models/entities/vehicle.entity';
import {
  TelematicsProgram,
  TelematicsEnrollmentStatus,
  SmartMilesMethods,
  SmartMiles_ConnectedCar,
} from '@shared/constants/app-constants';
import {
  TelematicsEnrollmentRecommendationResponse,
  TelematicsVehicle,
  PreQualifiedVehicleInfo,
} from '@core/models/telematics/telematics.model';

import { SessionService } from '@core/services';
import { take } from 'rxjs/operators';
import { StateSpecificFlagsObject } from '@core/store/reducers/metadata.reducer';

@Component({
  selector: 'mq-telematics-change-modal',
  templateUrl: './telematics-change-modal.component.html',
  styleUrls: ['./telematics-change-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelematicsChangeModalComponent implements OnInit {
  @Input() vehicle: VehicleEntity;
  @Input() recommendation: TelematicsEnrollmentRecommendationResponse;
  @Input() mobileAppEnrolled: boolean;
  @Input() enrollmentVehicle: TelematicsVehicle;
  @Input() isPreQualifiedVehicle: boolean;
  @Input() discountPercentage: number;
  @Input() preQualifiedVehicleInfo: PreQualifiedVehicleInfo;
  @Input() isDeviceOnlyState: boolean;

  public form: UntypedFormGroup;
  public typicalAnnualMileage = 12000; // fallback TAM from API recommendation
  discount: string;
  isVehicleMiles = false;
  @HostBinding('attr.role') role = 'main';

  constructor(
    public modal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private _sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      selectedProgram: this._fb.control(this.getSelection(), []),
    });

    if (
      this.recommendation &&
      this.recommendation?.qualifyingInformation &&
      this.recommendation?.qualifyingInformation.vehicles
    ) {
      const recommendationVehicle =
        this.recommendation?.qualifyingInformation.vehicles.find(
          vehicle =>
            parseInt(vehicle.vehicleId, 10) ===
            parseInt(this.vehicle.vehicleId, 10)
        );
      if (recommendationVehicle && recommendationVehicle.typicalAnnualMileage) {
        this.typicalAnnualMileage = recommendationVehicle.typicalAnnualMileage;
      }
    }

    if (this.discountPercentage) {
      this.discount = this.discountPercentage + '%';
    } else if (
      this.preQualifiedVehicleInfo &&
      this.preQualifiedVehicleInfo.discount
    ) {
      this.discount = this.preQualifiedVehicleInfo.discount + '%';
    }
    this.isVehicleMilesApplicable();
  }

  getSelection(): string {
    if (
      this.enrollmentVehicle?.vehicleProgram &&
      this.enrollmentVehicle?.enrollmentStatus ===
        TelematicsEnrollmentStatus.NOT_ENROLLED
    ) {
      return TelematicsEnrollmentStatus.NOT_ENROLLED;
    }
    if (this.mobileAppEnrolled) {
      if (this.vehicle?.isSmartRideDeviceEligible) {
        return TelematicsProgram.SMARTRIDE_DEVICE;
      }
      return 'SmartRideMobile';
    }
    if (this.enrollmentVehicle?.vehicleProgram === 'SmartRide') {
      return TelematicsProgram.SMARTRIDE_DEVICE;
    }
    if (!this.enrollmentVehicle || !this.enrollmentVehicle.vehicleProgram) {
      return TelematicsEnrollmentStatus.NOT_ENROLLED;
    }
    if (this.shouldOfferPreQualifiedVehicle()) {
      return TelematicsEnrollmentStatus.PRE_QUALIFIED;
    }
    if (
      this.enrollmentVehicle?.vehicleProgram ===
        TelematicsProgram.SMART_MILES &&
      this.enrollmentVehicle?.dataCollectionMethod ===
        SmartMilesMethods.ConnectedCar
    )
      return SmartMiles_ConnectedCar;
    return this.enrollmentVehicle.vehicleProgram;
  }

  shouldOfferSmartMiles(): boolean {
    let smartmilesEligible = false;
    if (
      this.recommendation?.recommendedProgram === 'Vehicle Program' &&
      this.recommendation?.qualifyingInformation.vehicles.find(
        vehicle => vehicle.availablePrograms.length > 0
      )
    ) {
      this.recommendation.qualifyingInformation.vehicles.forEach(vehicle => {
        vehicle.availablePrograms.forEach(availableProgram => {
          if (
            availableProgram.name === TelematicsProgram.SMART_MILES &&
            availableProgram?.dataCollectionMethod !==
              SmartMilesMethods.ConnectedCar &&
            vehicle?.vehicleId == this.vehicle.vehicleId
          ) {
            smartmilesEligible = true;
          }
        });
      });
    }
    return smartmilesEligible;
  }

  shouldOfferSmartRide(): boolean {
    let smartRideEligible = false;
    if (
      this.recommendation?.recommendedProgram === 'Vehicle Program' &&
      this.recommendation?.qualifyingInformation.vehicles.find(
        vehicle => vehicle.availablePrograms.length > 0
      )
    ) {
      this.recommendation.qualifyingInformation.vehicles.forEach(vehicle => {
        vehicle.availablePrograms.forEach(availableProgram => {
          if (
            availableProgram.name === TelematicsProgram.SMARTRIDE_DEVICE &&
            vehicle?.vehicleId == this.vehicle.vehicleId
          ) {
            smartRideEligible = true;
          }
        });
      });
    } else if (
      this.recommendation?.recommendedProgram === 'MobileApp' ||
      this.recommendation?.recommendedProgram === 'Mobile App Program' ||
      this.recommendation?.recommendedProgram ===
        'Mobile App Program - Early Discount' ||
      this.recommendation?.recommendedProgram === 'MobileAppEarlyDiscount'
    ) {
      smartRideEligible = true;
    }
    return smartRideEligible;
  }

  shouldOfferConnectedCar(): boolean {
    let isConnectedCarEligible = false;
    if (
      this.recommendation?.recommendedProgram === 'Vehicle Program' &&
      this.recommendation?.qualifyingInformation.vehicles.find(
        vehicle => vehicle.availablePrograms.length > 0
      )
    ) {
      this.recommendation?.qualifyingInformation.vehicles.forEach(vehicle => {
        vehicle.availablePrograms.forEach(availableProgram => {
          if (
            availableProgram.name === TelematicsProgram.CONNECTED_CAR &&
            vehicle.vehicleId == this.vehicle.vehicleId
          ) {
            isConnectedCarEligible = true;
          }
        });
      });
    }
    return isConnectedCarEligible;
  }

  shouldOfferPreQualifiedVehicle(): boolean {
    let isSmartRideInstantEligible = false;
    if (
      this.isPreQualifiedVehicle ||
      (!!this.preQualifiedVehicleInfo &&
        !!this.preQualifiedVehicleInfo.vehicleId)
    ) {
      isSmartRideInstantEligible = true;
    } else if (
      this.recommendation?.recommendedProgram === 'Vehicle Program' &&
      this.recommendation?.qualifyingInformation.vehicles.find(
        vehicle => vehicle.availablePrograms.length > 0
      )
    ) {
      this.recommendation?.qualifyingInformation.vehicles.forEach(vehicle => {
        vehicle.availablePrograms.forEach(availableProgram => {
          if (
            availableProgram.name === TelematicsProgram.SMARTRIDE_INSTANT &&
            vehicle?.vehicleId == this.vehicle.vehicleId
          ) {
            isSmartRideInstantEligible = true;
          }
        });
      });
    }
    return isSmartRideInstantEligible;
  }

  shouldOfferSelfReported(): boolean {
    let isSelfReportedEligible = false;
    if (
      this.recommendation?.recommendedProgram === 'Vehicle Program' &&
      this.recommendation?.qualifyingInformation.vehicles.find(
        vehicle => vehicle.availablePrograms.length > 0
      )
    ) {
      this.recommendation?.qualifyingInformation.vehicles.forEach(vehicle => {
        vehicle.availablePrograms.forEach(availableProgram => {
          if (
            availableProgram.name === TelematicsProgram.SELF_REPORTED &&
            vehicle?.vehicleId == this.vehicle.vehicleId
          ) {
            isSelfReportedEligible = true;
          }
        });
      });
    }
    return isSelfReportedEligible;
  }

  isVehicleMilesApplicable(): void {
    this._sessionService
      .getQuoteState()
      .pipe(take(1))
      .subscribe(state => (this.isVehicleMiles = state === 'CA'));
  }

  shouldOfferSMconnectedCar(): boolean {
    let smartmilesCCEligible = false;
    if (
      this.recommendation?.recommendedProgram === 'Vehicle Program' &&
      this.recommendation?.qualifyingInformation.vehicles.find(
        vehicle => vehicle.availablePrograms.length > 0
      )
    ) {
      this.recommendation.qualifyingInformation.vehicles.forEach(vehicle => {
        vehicle.availablePrograms.forEach(availableProgram => {
          if (
            availableProgram.name === TelematicsProgram.SMART_MILES &&
            availableProgram?.dataCollectionMethod ===
              SmartMilesMethods.ConnectedCar &&
            vehicle?.vehicleId == this.vehicle.vehicleId
          ) {
            smartmilesCCEligible = true;
          }
        });
      });
    }
    return smartmilesCCEligible;
  }
}
