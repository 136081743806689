import { Action } from '@ngrx/store';
import { PremiumEntity } from '@core/models/entities/premium.entity';

export const enum PremiumActionTypes {
  UPDATE_PREMIUM = '[Premium] Update Premium',
}

export class UpdatePremium implements Action {
  readonly type = PremiumActionTypes.UPDATE_PREMIUM;
  constructor(public payload: PremiumEntity) {}
}

export type PremiumActions = UpdatePremium;
