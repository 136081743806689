import * as fromHomeowners from '@core/store/actions/homeowners.action';

import { HomeownersModel } from '@core/models/homeowners/homeowners.model';

export interface HomeownersState {
  homeowners: HomeownersModel;
  offeringType: string;
}

export const initialState: HomeownersState = {
  homeowners: null,
  offeringType: null,
};

export function reducer(
  state = initialState,
  action: fromHomeowners.HomeownersActions
): HomeownersState {
  switch (action.type) {
    case fromHomeowners.ADD_HOMEOWNERS: {
      const homeowners = action.payload;

      return {
        ...state,
        homeowners,
      };
    }

    case fromHomeowners.SET_HOMEOWNERS_MORTGAGE_FLAG: {
      return {
        ...state,
        homeowners: {
          ...state.homeowners,
          hasMortgage: action.hasMortgage,
        },
      };
    }

    case fromHomeowners.UPDATE_OFFERING_TYPE: {
      return {
        ...state,
        offeringType: action.offeringType,
      };
    }
  }
  return state;
}

export const getHomeownersState = (state: HomeownersState) => state.homeowners;
