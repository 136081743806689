import { Injectable } from '@angular/core';
import { merge, Observable, of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@core/store';
import * as fromSelectors from '@core/store/selectors';
import * as fromActions from '@core/store/actions';
import { CRUDService } from '@core/services/crud.service';
import { DriverEntity } from '@core/models/entities/driver.entity';
import { Update } from '@ngrx/entity';
import { EligibleDiscountEntity } from '@core/models/entities/eligible-discount.entity';
import { DsmResetEventService } from '@core/services/reset.service';
import { Actions, ofType } from '@ngrx/effects';
import { filter, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DriverDao
  implements CRUDService<DriverEntity>, DsmResetEventService
{
  constructor(
    private _store: Store<fromStore.AppState>,
    private _actions$: Actions
  ) {}

  add(entity: DriverEntity): void {
    this._store.dispatch(new fromActions.AddDriver(entity, false));
  }

  dispatchAddPowersportsDriver(entity: DriverEntity): void {
    this._store.dispatch(new fromActions.AddPowersportsDriver(entity));
  }

  addAll?(entity: DriverEntity[]): void {
    throw new Error('Method not implemented.');
  }

  delete(entityId: string): void {
    this._store.dispatch(new fromActions.DeleteDriver(entityId));
  }

  dispatchDeletePowersportsDriver(entityId: string): void {
    this._store.dispatch(new fromActions.DeletePowersportsDriver(entityId));
  }

  deleteIncident(driverId: string): void {
    this._store.dispatch(new fromActions.DeleteDriverIncident(driverId));
  }
  update(entity: DriverEntity): void {
    this._store.dispatch(new fromActions.UpdateDriver(entity));
  }
  updatePowersportsDriver(entity: DriverEntity): void {
    this._store.dispatch(new fromActions.UpdatePowersportsDriver(entity));
  }

  dispatchUpdatePowersportsDriver(entity: DriverEntity): void {
    this._store.dispatch(new fromActions.UpdatePowersportsDriver(entity));
  }

  updateDriverAndRespond(entity: DriverEntity): Observable<any> {
    const success = this._actions$.pipe(
      ofType(fromActions.DriverActionTypes.UPDATE_DRIVER_SUCCESS),
      filter((action: any) => action.payload.id === entity.driverId)
    );
    const failure = this._actions$.pipe(
      ofType(fromActions.DriverActionTypes.UPDATE_DRIVER_FAIL),
      filter(
        (action: any) => action.request.driver.driverId === entity.driverId
      )
    );
    const response = merge(success, failure).pipe(
      take(1),
      switchMap(action => {
        if (action.type === fromActions.DriverActionTypes.UPDATE_DRIVER_FAIL) {
          return throwError({
            payload: action.payload,
            request: action.request,
          });
        } else {
          return of(action.payload);
        }
      })
    );
    this._store.dispatch(new fromActions.UpdateDriver(entity));
    return response;
  }

  updatePowersportsDriverAndRespond(entity: DriverEntity): Observable<any> {
    const success = this._actions$.pipe(
      ofType(fromActions.DriverActionTypes.UPDATE_POWERSPORTS_DRIVER_SUCCESS),
      filter((action: any) => action.payload.id === entity.driverId)
    );
    const failure = this._actions$.pipe(
      ofType(fromActions.DriverActionTypes.UPDATE_POWERSPORTS_DRIVER_FAIL),
      filter(
        (action: any) => action.request.driver.driverId === entity.driverId
      )
    );
    const response = merge(success, failure).pipe(
      take(1),
      switchMap(action => {
        if (
          action.type ===
          fromActions.DriverActionTypes.UPDATE_POWERSPORTS_DRIVER_FAIL
        ) {
          return throwError({
            payload: action.payload,
            request: action.request,
          });
        } else {
          return of(action.payload);
        }
      })
    );
    this._store.dispatch(new fromActions.UpdatePowersportsDriver(entity));
    return response;
  }

  updateAndRespond(entity: DriverEntity): Observable<any> {
    const success = this._actions$.pipe(
      ofType(fromActions.DriverActionTypes.UPDATE_DRIVER_SUCCESS),
      filter((action: any) => action.payload.id === entity.driverId)
    );
    const failure = this._actions$.pipe(
      ofType(fromActions.DriverActionTypes.UPDATE_DRIVER_FAIL),
      filter(
        (action: any) => action.request.driver.driverId === entity.driverId
      )
    );
    const response = merge(success, failure).pipe(
      take(1),
      switchMap(action => {
        if (action.type === fromActions.DriverActionTypes.UPDATE_DRIVER_FAIL) {
          return throwError({
            payload: action.payload,
            request: action.request,
          });
        } else {
          return of(action.payload);
        }
      })
    );
    this._store.dispatch(new fromActions.UpdateDriver(entity));
    return response;
  }

  updateEvent(entity: DriverEntity): void {
    const updated: Update<DriverEntity> = {
      id: entity.driverId,
      changes: entity,
    };

    this._store.dispatch(new fromActions.UpdateDriverDirectly(updated));
  }

  get?(entityId: string): Observable<DriverEntity> {
    throw new Error('Method not implemented.');
  }
  getAll(): Observable<DriverEntity[]> {
    return this._store.select(fromSelectors.selectAllDrivers);
  }

  getAllPowersportsDrivers(): Observable<DriverEntity[]> {
    return this._store.select(fromSelectors.selectAllPowersportsDrivers);
  }

  getAllPowersportsDriversIfSelected(): Observable<DriverEntity[]> {
    return this._store.select(
      fromSelectors.selectAllPowersportsDriversIfSelected
    );
  }

  getAutoAndPowersportsDriversMappping(): Observable<
    Map<string, DriverEntity>
  > {
    return this._store.select(
      fromSelectors.getAutoAndPowersportsDriversMapping
    );
  }

  updateDriverInStore(driver: Update<DriverEntity>): void {
    this._store.dispatch(new fromActions.UpdateDriverDirectly(driver));
  }

  getPniDriver(): Observable<DriverEntity> {
    return this._store.select(fromSelectors.getPniDriver);
  }

  getPowersportsPniDriver(): Observable<DriverEntity> {
    return this._store.select(fromSelectors.getPowersportsPniDriver);
  }

  isMarriedDriverExists(): Observable<boolean> {
    return this._store.select(fromSelectors.isMarriedDriverExists);
  }

  isSpouseDriverExists(): Observable<boolean> {
    return this._store.select(fromSelectors.isSpouseDriverExists);
  }

  getDriverTrainingByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getDriverTrainingFromDriverByDriverId(driverId)
    );
  }

  getAdvancedDriverTrainingByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getAdvancedDriverTrainingFromDriverByDriverId(driverId)
    );
  }

  getUSArmedForcesByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getUSArmedForcesFromDriverByDriverId(driverId)
    );
  }

  getDriverInfractions(): Observable<string[]> {
    return this._store.select(fromSelectors.getDriverInfractions());
  }

  getDriverOccupationByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getDriverOccupationByDriverId(driverId)
    );
  }

  isDriverMarriedWithDriverOccupation(driverId: string): Observable<boolean> {
    return this._store.select(
      fromSelectors.isDriverMarriedWithDriverOccupation(driverId)
    );
  }

  getDriverRelationToPni(driverFormData: any): Observable<string> {
    return this._store.select(
      fromSelectors.getDriverRelationToPni(driverFormData)
    );
  }

  getPowersportsDriverRelationToPni(driverFormData: any): Observable<string> {
    return this._store.select(
      fromSelectors.getPowersportsDriverRelationToPni(driverFormData)
    );
  }

  getAllDriversWithVehicleAssignment(): Observable<DriverEntity[]> {
    return this._store.select(
      fromSelectors.selectAllDriversWithVehicleAssignment
    );
  }

  getAllDriversIncludingPowersports(): Observable<DriverEntity[]> {
    return this._store.select(
      fromSelectors.selectAllDriversIncludingPowersports
    );
  }

  getDriversLoading(): Observable<boolean> {
    return this._store.select(fromSelectors.getDriversLoading);
  }

  //Powersports Eligible Discounts

  getSafetyCourseByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getSafetyCourseByDriverId(driverId)
    );
  }

  getPowersportsDriverTrainingFromDriverByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getPowersportsDriverTrainingFromDriverByDriverId(driverId)
    );
  }

  getPowersportsAdvancedDriverTrainingFromDriverByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getPowersportsAdvancedDriverTrainingFromDriverByDriverId(
        driverId
      )
    );
  }

  getPowersportsGoodStudentFromDriverByDriverId(
    driverId: string
  ): Observable<EligibleDiscountEntity> {
    return this._store.select(
      fromSelectors.getPowersportsGoodStudentFromDriverByDriverId(driverId)
    );
  }

  // getAllVehicleDrivers(): Observable<DriverEntity[]> {
  //   this._store.select(fromSelectors.selectAllDrivers).pipe(
  //     take(1),
  //     withLatestFrom(
  //       this._store.select(fromSelectors.selectAllPowersportsDrivers)
  //     ),
  //     map(drivers => {
  //       return drivers;
  //     })
  //   );
  // }
}
