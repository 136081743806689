import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DriverVehiclePrefillRequest } from '@core/models/driver-vehicle-prefill/driver-vehicle-prefill-request.model';
import { Observable } from 'rxjs';
import { DriverVehiclePrefillResponse } from '@core/models/driver-vehicle-prefill/driver-vehicle-prefill-response.model';
import { AppConfigService, FeatureService } from '@core/services';
import { HeadersUtils } from '@shared/utils/headers.utils';

@Injectable({
  providedIn: 'root',
})
export class DriverVehiclePrefillAdapter {
  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService,
    private _featureService: FeatureService
  ) {}

  customersData(
    request: DriverVehiclePrefillRequest
  ): Observable<DriverVehiclePrefillResponse> {
    const resource = '/customers-info';

    // if the env is dev/test/stage and the header "isDataMockingRequired" is true
    // then it will default to prefill mocked data which includes 4 drivers and 3 vehicles

    if (!this._appConfig.isProd()) {
      // DO NOT PUSH THIS FLAG AS TRUE TO GIT
      request.body.isDataMockingRequired = false;
    }

    const url = this._appConfig.config.driverVehiclePrefillUrl + resource;

    const headers = HeadersUtils.buildHeaderForDriverVehiclePrefill(
      request.accessToken,
      this._appConfig.config.apiKey
    );

    return this._http.post<DriverVehiclePrefillResponse>(url, request.body, {
      headers,
    });
  }
}
