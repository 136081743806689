<form [formGroup]="searchForm" (ngSubmit)="onSubmit(searchForm)">
  <div *ngIf="errorMessage" class="row error-alert">
    <div class="header alert alert-danger col" role="alert">
      <a class="icon error"></a>
      <span>{{ errorMessage }}</span>
    </div>
  </div>

  <div class="row">
    <h1 class="heading-h3 col-sm-auto">Search your quotes by</h1>
  </div>

  <div class="form-group">
    <ul
      ngbNav
      #nav="ngbNav"
      class="nav-tabs"
      (navChange)="beforeTabChange($event)"
    >
      <li ngbNavItem *isIndependentOrExclusiveAgent>
        <a ngbNavLink>Timeframe</a>
        <ng-template ngbNavContent>
          <div class="form-group col-sm-auto">
            <div
              class="btn-group btn-group-toggle d-flex text-center"
              name="timeRange"
            >
              <label
                for="md-timeRange-1day"
                class="btn btn-default col-6 col-sm-3"
                [ngClass]="{
                  activetoggle: searchForm.value.timeRange == '1'
                }"
              >
                <input
                  type="radio"
                  aria-describedby="timeRange-error"
                  value="1"
                  name="timeRange"
                  formControlName="timeRange"
                  id="md-timeRange-1day"
                />
                Today
              </label>
              <label
                for="md-timeRange-7day"
                class="btn btn-default col-6 col-sm-3"
                [ngClass]="{
                  activetoggle: searchForm.value.timeRange == '7'
                }"
              >
                <input
                  type="radio"
                  aria-describedby="timeRange-error"
                  value="7"
                  name="timeRange"
                  formControlName="timeRange"
                  id="md-timeRange-7day"
                />
                Past 7 days
              </label>
              <label
                for="md-timeRange-30day"
                class="btn btn-default col-6 col-sm-3"
                [ngClass]="{
                  activetoggle: searchForm.value.timeRange == '30'
                }"
              >
                <input
                  type="radio"
                  aria-describedby="timeRange-error"
                  value="30"
                  name="timeRange"
                  formControlName="timeRange"
                  id="md-timeRange-30day"
                />
                Past 30 days
              </label>
            </div>
            <div
              *ngIf="
                timeRange.invalid && (timeRange.dirty || timeRange.touched)
              "
              class="col-12 col-sm-6 no-padding"
            >
              <div
                *ngIf="timeRange.errors?.required"
                class="alert alert-danger"
                role="alert"
              >
                <a class="icon error"></a>
                <span id="timeRange-error">Time range is required.</span>
              </div>
            </div>
          </div></ng-template
        >
      </li>

      <li ngbNavItem>
        <a ngbNavLink>Last&nbsp;name&nbsp;&amp;&nbsp;zip</a>
        <ng-template ngbNavContent>
          <div class="row g-2">
            <div class="form-group col-sm-15 col-md-5 col-lg-5">
              <label for="input-lastName">Primary insured last name</label>
              <input
                type="text"
                id="input-lastName"
                formControlName="lastName"
                class="form-control"
              />
              <div
                *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
              >
                <div
                  *ngIf="lastName.errors?.required"
                  class="alert alert-danger"
                  role="alert"
                >
                  <a class="icon error"></a>
                  <span id="last-name-error"
                    >Primary insured last name is required.</span
                  >
                </div>
                <div
                  *ngIf="
                    lastName.errors?.stringFormat && !lastName.errors?.required
                  "
                  class="alert alert-danger"
                  role="alert"
                >
                  <a class="icon error"></a>
                  <span id="last-name-error">Field is invalid.</span>
                </div>
              </div>
            </div>

            <div class="form-group col-sm-10 col-md-4 col-lg-4">
              <label for="input-postalCode">Zip code</label>
              <input
                type="text"
                id="input-postalCode"
                formControlName="postalCode"
                class="form-control"
              />
              <div
                *ngIf="
                  postalCode.invalid && (postalCode.dirty || postalCode.touched)
                "
              >
                <div
                  *ngIf="postalCode.errors?.required"
                  class="alert alert-danger"
                  role="alert"
                >
                  <a class="icon error"></a>
                  <span id="postal-code-error">Zip code is required.</span>
                </div>
                <div
                  *ngIf="
                    postalCode.errors?.zipFormat && !postalCode.errors?.required
                  "
                  class="alert alert-danger"
                  role="alert"
                >
                  <a class="icon error"></a>
                  <span id="postal-code-error">Field is invalid.</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>

      <li ngbNavItem>
        <a ngbNavLink>Phone number</a>
        <ng-template ngbNavContent>
          <div class="form-group col-sm-12 col-md-4 col-lg-5">
            <label for="phone">Phone number</label>
            <input
              id="phone"
              type="tel"
              class="form-control"
              aria-describedby="phone-error"
              phoneNumber
              formControlName="phone"
              placeholder="555-555-5555"
              maxlength="12"
              mask="000-000-0000"
              [dropSpecialCharacters]="false"
              autofocus
            />
            <div *ngIf="phone.invalid && (phone.dirty || phone.touched)">
              <div
                *ngIf="phone.errors?.required"
                class="alert alert-danger"
                role="alert"
              >
                <a class="icon error"></a>
                <span id="phone-error">Phone number is required.</span>
              </div>
              <div
                *ngIf="
                  phone.errors?.phoneNumberFormat && !phone.errors?.required
                "
                class="alert alert-danger"
                role="alert"
              >
                <a class="icon error"></a>
                <span id="phone-error">Field is invalid.</span>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>

  <div class="row justify-content-end">
    <button type="submit" class="btn btn-primary" id="dp-search">
      {{ submitButtonText }}
    </button>
  </div>
</form>
