import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  URL_MAP,
  PRIVATE_LABEL_DOMAIN_MAP,
  PRIVATE_LABEL_PATH_URL_MAP,
  NWX_ENVS_S3_MAPPINGS,
} from '@shared/constants/app-constants';

export interface AppConfig {
  apiKey: string;
  appName: string;
  billingApiUrl: string;
  billingTargetEnv: string;
  captchaApiUrl: string;
  captchaEnabled: boolean;
  captchaSiteToken: string;
  clickToChatUrl: string;
  contentfulAccessToken: string;
  contentfulPreviewAccessToken: string;
  contentfulBasePath: string;
  contentfulEnvironment: string;
  contentfulHost: string;
  contentfulSpaceId: string;
  distributedDocumentsTargetEnv: string;
  distributedDocumentsUrl: string;
  distributionPartnerManagementUrl: string;
  distributionPartnerManagementScope: string;
  downPaymentUrl: string;
  driverVehiclePrefillUrl: string;
  driverVehiclePrefillVendor: string;
  emailQuoteEstimateUrl: string;
  giactVerifyUrl: string;

  // DSM
  environmentName: string;
  escrowServiceUrl: string;
  euaUrl: string;
  internetRegistrationUrl: string;
  kubernetesEndpoint?: string;
  moneyProcessingApiUrl: string;
  moneyProcessingUrl: string;
  mortgageCompaniesUrl: string;
  multiproductApi: string;
  myLifeProtectedUrl: string;
  natGenApiUrl: string;
  natGenBridgeUrl: string;
  partnerBridgingUtilityUrl: string;
  pcEdgeAgentAccountManagementUrl: string;
  pcEdgeAutoUrl: string;
  pcEdgeAutoTAndCUrl: string;
  pcEdgeCompraterRetrieveUrl: string;
  pcEdgeCondoUrl: string;
  pcEdgeHomeownersUrl: string;
  pcEdgePersonalLinesUtility: string;
  pcEdgePolicyIssueUrl: string;
  pcEdgePowersportsUrl: string;
  pcEdgeRentersUrl: string;
  pcEdgeSearchQuotesUrl: string;
  pcEdgeTermLifeUrl: string;
  pcEdgeUmbrellaUrl: string;
  pcExternalDeepLaunchUrl: string;
  pcInternalDeepLaunchUrl: string;
  pciV2Url: string;
  personalLinesBillingApiUrl: string;
  plaidEnv: string;
  powersportsApiUrl: string;
  powersportsVehicleInquiryUrl: string;
  preferencesUrl: string;
  production: boolean;
  quoteAccountManagement: string;
  semafoneClientReference: string;
  semafoneFragmentUrl: string;
  semafoneLicenseCode: string;
  semafoneResponseUrl: string;
  splunkApiUrl: string;
  splunkCloudApiUrl: string;
  splunkCloudToken: string;
  splunkToken: string;
  standardizedAddressPartnerId: string;
  standardizedAddressUrl: string;
  suggestAddressUrl: string;
  targetEnv?: string;
  targetEnvPCType?: string;
  vehicleDetailsUrl: string;
  vehicleInquiryCodeType: string;
  vehicleInquiryCompany: string;
  vehicleInquiryCoveredObject: string;
  vehicleInquiryEchoBack: string;
  vehicleInquiryProductCode: string;
  vehicleInquiryRatingType: string;
  vehicleInquiryState: string;
  vehicleInquiryUrl: string;
  vehicleInquiryUsername: string;
  fullRegistrationUrl: string;
  ignoreRiskFilter: string;
  ignoreCurrentCarrierValidation: string;
  ignoreProductDisablement: string;
  ignoreAutoUWRules: string;
}

@Injectable()
export class AppConfigService {
  private _appConfig: AppConfig;

  constructor(private _http: HttpClient) {}

  async loadAppConfig(): Promise<AppConfig> {
    const data = await this._http
      .get<AppConfig>(`/assets/config/environment-${this.host}.json`)
      .toPromise();
    this._appConfig = data;
    this._addBootstrapTags(data);

    return data;
  }

  get config(): AppConfig {
    return this._appConfig;
  }

  set ignoreRiskFilter(ignoreRiskFilter: string) {
    this.config.ignoreRiskFilter = ignoreRiskFilter;
  }

  set ignoreAutoUWRules(ignoreAutoUWRules: string) {
    this.config.ignoreAutoUWRules = ignoreAutoUWRules;
  }

  set ignoreCurrentCarrierValidation(ignoreCurrentCarrierValidation: string) {
    this.config.ignoreCurrentCarrierValidation = ignoreCurrentCarrierValidation;
  }

  set ignoreProductDisablement(ignoreProductDisablement: string) {
    this.config.ignoreProductDisablement = ignoreProductDisablement;
  }

  set targetEnv(targetEnv: string) {
    this.config.targetEnv = targetEnv || '';
  }

  set targetEnvPCType(type: string) {
    this.config.targetEnvPCType = type;
  }

  set driverVehicleVendorType(type: string) {
    this.config.driverVehiclePrefillVendor = type;
  }

  get host(): string {
    const host = window.location.host;
    const urlMappings = {
      ...URL_MAP,
      ...PRIVATE_LABEL_DOMAIN_MAP,
      ...PRIVATE_LABEL_PATH_URL_MAP,
    };

    if (!urlMappings[host]) {
      return 'stage2';
    }

    if (host === 'multiproducttest.awspubliccloud.nationwide.com') {
      return this.getEnvBasedOnAwsRouterCookieValue();
    }
    return urlMappings[host];
  }

  get agencyIdentifier(): string {
    const mockIdentifier = sessionStorage.getItem('privateLabelIdentifier');
    if (this.isTest() && mockIdentifier) {
      return mockIdentifier;
    } else {
      const [agencyDomain] = window.location.hostname.split('.');

      return agencyDomain;
    }
  }

  getEnvBasedOnAwsRouterCookieValue(): string {
    const cookie = RegExp('aws_router' + '=[^;]+').exec(document.cookie);
    const env = decodeURIComponent(
      !!cookie ? cookie.toString().replace(/^[^=]+./, '') : ''
    );
    if (env.length > 0) {
      return NWX_ENVS_S3_MAPPINGS[env];
    } else {
      //Defaults to Stage2 for no-cookie or empty cookie value for aws_router...
      // this indicates prolike pcEnv of pls=19
      return 'stage2';
    }
  }

  isPrivateLabelDomain(): boolean {
    return Object.keys(PRIVATE_LABEL_DOMAIN_MAP).some(domain => {
      const [agencyDomain] = domain.split('.');
      return this.agencyIdentifier === agencyDomain;
    });
  }

  isProd(): boolean {
    return this.host === 'prod';
  }

  isTest(): boolean {
    return this.host !== 'prod';
  }

  isLocal(): boolean {
    return this.host === 'localdesktop';
  }

  getUrlParameters(): HttpParams {
    const url = window.location.href;
    if (url.includes('?')) {
      return new HttpParams({
        fromString: decodeURIComponent(url.split('?')[1]),
      });
    }
    return null;
  }

  private _addBootstrapTags(data: AppConfig): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    if (data.production) {
      script.src = '//tags.nationwide.com/Bootstrap.js';
    } else {
      script.src = '//tags.nationwide.com/test/Bootstrap.js';
    }

    document.getElementsByTagName('head')[0].appendChild(script);
  }

  getAwsRouterCookieValue(): string {
    const host = window.location.host;

    if (
      host?.startsWith('localhost') ||
      host === 'multiproducttest.awspubliccloud.nationwide.com'
    ) {
      const cookie = RegExp('aws_router' + '=[^;]+').exec(document.cookie);
      const env = decodeURIComponent(
        !!cookie ? cookie.toString().replace(/^[^=]+./, '') : ''
      );
      if (!env) {
        return '';
      } else {
        return env;
      }
    }
  }
}
