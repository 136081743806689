import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '@core/services/app-config.service';
import {
  PowersportsVehicleModelMakesResponse,
  VehicleModelMakesResponse,
} from '@core/models/auto/vehicle-make.model';
import {
  PowersportsVehicleModelsWithDescriptionResponse,
  VehicleModelsWithDescriptionResponse,
} from '@core/models/auto/vehicle-model.model';
import { AutoYearsResponse } from '@core/models/auto/vehicle-years.model';
import {
  GetVinDetailsRequest,
  PowersportsVehicleVinDetailsResponse,
  VehicleVinDetailsResponse,
} from '@core/models/auto/vehicle-vin.model';
import { DateUtils } from '@shared/utils/date.utils';
import { VehicleSeriesRatesOdbiResponse } from '@core/models/auto/vehicle-seriesRates.model';
import { VehicleModelSeries } from '@core/models/response/vehicle-series.model';

import { VehicleTypesResponse } from '@core/models/auto/vehicle-types.model';
import { StringUtils } from '@shared/utils/string.utils';
import { VehiclePrimaryUseOptions } from '@core/models/auto/vehicle-primary-use.model';

@Injectable()
export class AutoAdapter {
  constructor(private _http: HttpClient, private appConfig: AppConfigService) {}

  getYears(): Observable<AutoYearsResponse> {
    const url = '../../../assets/api/metadata/auto/auto-years.json';

    return this._http
      .get<AutoYearsResponse>(url)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getVehicleTypes(): Observable<VehicleTypesResponse> {
    const url = '../../../assets/api/metadata/vehicles/vehicle-types.json';

    return this._http
      .get<VehicleTypesResponse>(url)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getPowersportsPrimaryUse(): Observable<VehiclePrimaryUseOptions> {
    const url =
      '../../../assets/api/metadata/powersports/powersports-primary-use.json';

    return this._http
      .get<VehiclePrimaryUseOptions>(url)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getMakesByYear(modelYear: string): Observable<VehicleModelMakesResponse> {
    const url = `${this.appConfig.config.vehicleInquiryUrl}/vehicleModelMakes`;

    const params = new HttpParams({
      fromObject: {
        modelYear,
        client_id: this.appConfig.config.apiKey,
        userName: this.appConfig.config.vehicleInquiryUsername,
        coveredObject: this.appConfig.config.vehicleInquiryCoveredObject,
        echoBack: this.appConfig.config.vehicleInquiryEchoBack,
      },
    });
    return this._http
      .get<VehicleModelMakesResponse>(url, { params })
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getPowersportsMakesByYear(
    modelYear: string
  ): Observable<PowersportsVehicleModelMakesResponse> {
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/motorcycles/available-years/${modelYear}/available-makes`;
    const messageID = StringUtils.generateUUID();

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('X-NW-Message-ID', messageID)
      .set('client_id', this.appConfig.config.apiKey);

    return this._http
      .get<PowersportsVehicleModelMakesResponse>(url, { headers })
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getModelsByYearAndMake(
    modelYear: string,
    make: string
  ): Observable<VehicleModelsWithDescriptionResponse> {
    const url = `${this.appConfig.config.vehicleInquiryUrl}/vehicleModelsWithDescription`;
    const params = new HttpParams({
      fromObject: {
        modelYear,
        make,
        client_id: this.appConfig.config.apiKey,
        userName: this.appConfig.config.vehicleInquiryUsername,
        coveredObject: this.appConfig.config.vehicleInquiryCoveredObject,
        echoBack: this.appConfig.config.vehicleInquiryEchoBack,
      },
    });
    return this._http
      .get<VehicleModelsWithDescriptionResponse>(url, { params })
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getPowersportsModelsByYearAndMake(
    modelYear: string,
    make: string
  ): Observable<PowersportsVehicleModelsWithDescriptionResponse> {
    make = btoa(make);
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/motorcycles/available-years/${modelYear}/available-makes/${make}/available-models`;
    const messageID = StringUtils.generateUUID();
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);

    return this._http
      .get<PowersportsVehicleModelsWithDescriptionResponse>(url, { headers })
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  getVinDetails(
    getVinDetailsRequest: GetVinDetailsRequest
  ): Observable<VehicleVinDetailsResponse> {
    const url = `${this.appConfig.config.vehicleInquiryUrl}/17DigitVehicleIdentificationNumberOdbi`;

    const effectiveDate = DateUtils.buildCCYYMMDD(new Date());
    const vin = getVinDetailsRequest.vehicleIdentificationNumber;
    let params = new HttpParams()
      .set('vehicleIdentificationNumber', vin)
      .set('client_id', this.appConfig.config.apiKey)
      .set('codeType', this.appConfig.config.vehicleInquiryCodeType)
      .set('company', this.appConfig.config.vehicleInquiryCompany)
      .set('state', this.appConfig.config.vehicleInquiryState)
      .set('productCode', this.appConfig.config.vehicleInquiryProductCode)
      .set('effectiveDate', effectiveDate)
      .set('coveredObject', this.appConfig.config.vehicleInquiryCoveredObject)
      .set('echoBack', this.appConfig.config.vehicleInquiryEchoBack)
      .set('ratingType', this.appConfig.config.vehicleInquiryRatingType);

    if (vin.indexOf('*') >= 0) {
      if (getVinDetailsRequest.prepopulatedInsuredId) {
        params = params.set(
          'prepopulatedInsuredId',
          getVinDetailsRequest.prepopulatedInsuredId
        );
      } else if (getVinDetailsRequest.partialQuoteId) {
        params = params.set(
          'partialQuoteId',
          getVinDetailsRequest.partialQuoteId
        );
      } else {
        params = params.set('quoteId', getVinDetailsRequest.quoteId);
      }
      params = params.set('vehicleId', getVinDetailsRequest.vehicleId);
    }
    return this._http
      .get<VehicleVinDetailsResponse>(url, { params })
      .pipe(catchError((error: any) => of(null)));
  }

  getPowersportsVinDetails(
    vehicleIdentificationNumber: string
  ): Observable<PowersportsVehicleVinDetailsResponse> {
    const url = `${this.appConfig.config.powersportsVehicleInquiryUrl}/motorcycle-models/vin`;

    const messageID = StringUtils.generateUUID();
    const params = new HttpParams({
      fromObject: {
        vin: vehicleIdentificationNumber,
      },
    });

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('client_id', this.appConfig.config.apiKey)
      .set('X-NW-Message-ID', messageID);

    return this._http
      .get<PowersportsVehicleVinDetailsResponse>(url, { headers, params })
      .pipe(catchError((error: any) => of(null)));
  }

  retrieveVehicleSeriesRatesOdbi(modelYear, make, model) {
    const url = `${this.appConfig.config.vehicleInquiryUrl}/vehicleSeriesRatesOdbi`;

    const effectiveDate = DateUtils.buildCCYYMMDD(new Date());

    const params = new HttpParams({
      fromObject: {
        modelYear,
        make,
        model,
        client_id: this.appConfig.config.apiKey,
        codeType: this.appConfig.config.vehicleInquiryCodeType,
        company: this.appConfig.config.vehicleInquiryCompany,
        state: this.appConfig.config.vehicleInquiryState,
        productCode: this.appConfig.config.vehicleInquiryProductCode,
        effectiveDate: effectiveDate,
        coveredObject: this.appConfig.config.vehicleInquiryCoveredObject,
        echoBack: this.appConfig.config.vehicleInquiryEchoBack,
        ratingType: this.appConfig.config.vehicleInquiryRatingType,
        vin_override: 'true',
      },
    });

    return this._http
      .get<VehicleSeriesRatesOdbiResponse>(url, { params })
      .pipe(catchError((error: any) => of(null)));
  }

  retrieveVehicleModelSeries(modelYear, make, model) {
    const url = `${this.appConfig.config.vehicleDetailsUrl}/vehicleSeriesOdbi/undefined`;

    const effectiveDate = DateUtils.buildCCYYMMDD(new Date());

    const params = new HttpParams({
      fromObject: {
        modelYear,
        make,
        model,
        client_id: this.appConfig.config.apiKey,
        // codeType: this.appConfig.config.vehicleInquiryCodeType,
        // company: this.appConfig.config.vehicleInquiryCompany,
        state: this.appConfig.config.vehicleInquiryState,
        productCode: this.appConfig.config.vehicleInquiryProductCode,
        effectiveDate: effectiveDate,
        coveredObject: this.appConfig.config.vehicleInquiryCoveredObject,
        // echoBack: this.appConfig.config.vehicleInquiryEchoBack,
        // ratingType: this.appConfig.config.vehicleInquiryRatingType,
        vin_override: 'true',
      },
    });

    return this._http
      .get<VehicleModelSeries | VehicleModelSeries[]>(url, { params })
      .pipe(catchError((error: any) => of(null)));
  }
}
