import { Action } from '@ngrx/store';

export const ADD_HOMEOWNERS = '[Core] Add Homeowners';
export const SET_HOMEOWNERS_MORTGAGE_FLAG =
  '[Core] Set Homeowners Mortgage Flag';
export const UPDATE_OFFERING_TYPE = '[Core] Update Offeringtype';
export class AddHomeowners implements Action {
  readonly type = ADD_HOMEOWNERS;
  constructor(public payload: any) {}
}

export class SetHomeownersMortgageFlag implements Action {
  readonly type = SET_HOMEOWNERS_MORTGAGE_FLAG;
  constructor(public hasMortgage: boolean) {}
}

export class UpdateOfferingType implements Action {
  readonly type = UPDATE_OFFERING_TYPE;
  constructor(public offeringType: string) {}
}

export type HomeownersActions =
  | AddHomeowners
  | SetHomeownersMortgageFlag
  | UpdateOfferingType;
