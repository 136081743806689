import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IsNssAgentDirective } from './is-nss-agent.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [IsNssAgentDirective],
  exports: [IsNssAgentDirective],
})
export class IsNssAgentModule {}
